import React, { useEffect, useState } from "react";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import systemStore from "../../stores/SystemStore";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import ISOReportDetail from "./ISOReportDetail";
import ISOReportIndividualDetail from "./ISOReportIndividualDetail";
import ISOReportOverview from "./ISOReportOverview";
import ReportFilter, { IReportFilters } from "./ReportFilter";
import toastStore from "../../stores/ToastStore";

function ReportWrapper(props: any) {
  const reportId: any = Number(props?.match?.params?.id) || null;
  const reports = useNfirsGetData(
    ApsServices.http.isoReport.getAllReports,
    "Reports"
  );

  const [report, setReport] = useState<ApsModels.IIsoReportListItemDto>();
  const [showReport, setShowReport] = useState(false);
  const [filters, setFilters] = useState<IReportFilters>();

  useEffect(() => {
    if (systemStore.isoReports.length > 0) {
      reports.setData(systemStore.isoReports);
      setReport(systemStore.isoReports.find((r) => r.id === reportId));
    } else {
      if (reportId === 3) {
        props.history.push("/reports/3/NFIRS");
      } else {
        reports.getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      systemStore.isoReports.length === 0 &&
      reports.data &&
      reports.status === NfirsFetchStatus.Complete
    ) {
      systemStore.setIsoReports(reports.data);
      const rpt = reports.data.find((r) => r.id === reportId);
      setReport(rpt);
      if (!rpt) {
        toastStore.showToast("Report Not Found", "warning");
        props.history.push("/reports");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports.status, reports.data]);

  const [selectedPersonnel, setSelectedPersonnel] =
    useState<ApsModels.IIsoReportDetailItemDto>();
  const [lastOverviewFilter, setLastOverviewFilter] =
    useState<IReportFilters>();

  return (
    <div
      className="flex flex-1 flex-column"
      style={{ marginBottom: "-60px", position: "relative" }}
    >
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div className="py-2">
              <span className="h4 mb-0 font-size-18 text-uppercase">
                {selectedPersonnel
                  ? "ISO Detail Individual Report"
                  : report?.name}
              </span>
            </div>
            <div className="p-0">
              {showReport && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    if (selectedPersonnel) {
                      setSelectedPersonnel(undefined);
                    } else {
                      if (lastOverviewFilter) {
                        setLastOverviewFilter(undefined);
                        reports.data?.forEach((rpt) => {
                          if (
                            rpt.isoReportTypeEnum ===
                              ApsModels.IsoReportTypeEnum.IsoOverviewReport &&
                            commonService.canViewISOOverviewReport()
                          ) {
                            setReport(rpt);
                            setFilters(lastOverviewFilter);
                          }
                        });
                      } else {
                        setShowReport(false);
                      }
                    }
                  }}
                >
                  Cancel and Close
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row h-full flex-1">
          <div className="col-12 col-lg-12 flex flex-col">
            <div className={`${showReport ? "display-none" : "card"}`}>
              <div className="card-body flex flex-col p-4">
                <div>
                  <ReportFilter
                    apply={(data) => {
                      setShowReport(true);
                      setFilters(data);
                    }}
                  ></ReportFilter>
                </div>
              </div>
            </div>
            {showReport && filters && report && (
              <>
                {report.isoReportTypeEnum ===
                  ApsModels.IsoReportTypeEnum.IsoOverviewReport &&
                  commonService.canViewISOOverviewReport() && (
                    <ISOReportOverview
                      filters={filters}
                      viewDetail={(category) => {
                        reports.data?.forEach((rpt) => {
                          if (
                            rpt.isoReportTypeEnum ===
                              ApsModels.IsoReportTypeEnum.IsoDetailReport &&
                            commonService.canViewISODetailReport()
                          ) {
                            setLastOverviewFilter(filters);
                            setReport(rpt);
                            setFilters((prev) => {
                              if (!prev) return prev;
                              return {
                                ...prev,
                                trainingCategories: [category],
                              };
                            });
                          }
                        });
                      }}
                    ></ISOReportOverview>
                  )}
                {report.isoReportTypeEnum ===
                  ApsModels.IsoReportTypeEnum.IsoDetailReport &&
                  commonService.canViewISODetailReport() && (
                    <>
                      <ISOReportDetail
                        filters={filters}
                        hide={!!selectedPersonnel}
                        showIndividualReport={(item) => {
                          setSelectedPersonnel(item);
                        }}
                      ></ISOReportDetail>
                      {selectedPersonnel && (
                        <ISOReportIndividualDetail
                          filters={{
                            ...filters,
                            personnelIds: [selectedPersonnel.userId],
                            singlePersonnelName:
                              selectedPersonnel.personnelName,
                          }}
                          personnelRecord={selectedPersonnel}
                        ></ISOReportIndividualDetail>
                      )}
                    </>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportWrapper;
