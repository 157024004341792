import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSArsonInvestigationServices {
  get = async (reportId: any) => {
    const url = `/api/NFIRSArsonInvestigation/${reportId}`;
    return fetcher.get<ApsModels.INfirsArsonInvestigationOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsArsonInvestigationInputDto) => {
    const url = `/api/NFIRSArsonInvestigation`;
    return fetcher.post<ApsModels.INfirsArsonInvestigationOutputDto>(url, data);
  };
}

const nfirsArsonInvestigation = new NFIRSArsonInvestigationServices();
export default nfirsArsonInvestigation;
