import React from "react";
import { cloneDeep } from "lodash";
import { AnswerDto, AnswerOptionReferenceDto, AnswerType, AnswerUserReferenceDto, QuestionGroupDto, WestCovinaModuleOrder } from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { UserDto } from "../../../../data/User";
import { GroupService } from "../../../../services/GroupService";
import { OptionGroupService } from "../../../../services/OptionGroupService";
import { OptionService } from "../../../../services/OptionService";
import { UserService } from "../../../../services/UserService";
import { Card } from "./Card";
import { Select } from "./Select";
import { TextInput } from "./TextInput";
import "./REMSTeam.scss";
import { IsolatedSelect } from "../../CustomInput/IsolatedSelect";

type Props = {
    questionGroup?: QuestionGroupDto
    departmentId: number
    crewSize: number
    moduleOrder: number
    setData: (qg: QuestionGroupDto | undefined, order: number) => void
    date: Date
}

type State = {
    options: OptionDto[]
    crew: UserDto[]
    selectedOptionId: number | null 
    selectedCrewIdsOrNames: (number | string)[]
    optionLookup: Map<number, OptionDto> | null
    isIncidentVisible: boolean
    incidentName: string
    ready: boolean
}

export class REMSTeam extends React.Component<Props, State> {

    state: State = {
        options: [],
        crew: [],
        selectedOptionId: null,
        selectedCrewIdsOrNames: [], 
        optionLookup: null,
        isIncidentVisible: false,
        incidentName: '',
        ready: false
    } 

    constructor(props: Props) {
        super(props);
        this.state.selectedCrewIdsOrNames = Array(this.props.crewSize);
    }

    componentDidMount = async () => {
        const remsGroup = await GroupService.getAllGroups().then(groups => groups.find(g => g.name === "REMS")); 
        const crew = await UserService.getUsersInGroupByDepartmentId(remsGroup!.groupId, this.props.departmentId);
        const optionGroups = await OptionGroupService.getAllOptionGroupsByDepartment(this.props.departmentId);
        const stOptionGroup = optionGroups.find(g => g.name === 'REMSTeamOptions');
        const options = await OptionService.getGroupOptions(stOptionGroup!.optionGroupId);
        const optionLookup = new Map<number, OptionDto>(options.map(o => [o.optionId, o]));
        this.setState({ options, crew, optionLookup })

        // Attempt to load existing answer
        // answer[0] = option dropdown
        // answer[1] = incident name
        // answer[2-5] = crew ids
        // See `handleUpdate` for more info on the logic
        const question = this.props.questionGroup!.questions[0];
        if (question.answers[0]) {
            // Parse the option dropdown answer
            const optionAnswer = question.answers[0].option?.optionId;
            this.setState({ selectedOptionId: optionAnswer ?? null });

            // Parse the incident name answer
            const incidentNameAnswer = question.answers[1].textAnswerValue;
            if(incidentNameAnswer && optionAnswer && optionLookup.get(optionAnswer)?.name === "Deployed"){
                this.setState({ 
                    incidentName: incidentNameAnswer,
                    isIncidentVisible: true
                });
            } 

            // Parse the crew dropdowns
            question.answers.slice(2).forEach((a, i) => { // assume all other answers are crew member dropdowns
                const selectedCrewIdsOrNames = [...this.state.selectedCrewIdsOrNames];
                if (a.answerType === AnswerType.User) {
                    if (a.user) {
                        selectedCrewIdsOrNames[i] = a.user!.userId!;
                    }
                } else {
                    selectedCrewIdsOrNames[i] = a.textAnswerValue ?? '';
                }
    
                this.setState({ selectedCrewIdsOrNames: selectedCrewIdsOrNames });
            });
        }
        
        this.setState({ ready: true })
    }

    /*
    This function ultimately needs to modify the apporpriate QuestionGroup with an
    AnswerDto[]. To do this, we spoof an AnswerDto[] by creating an AnswerDto for each
    Select. Assume the following order for AnswerDtos:
        AnswerDto 0: The availablity status dropdown
        AnswerDto 1: The incident name 
        AnswerDtos 2-5: The selected crew member for each crew dropdown, respectively
    Note that not all answers will have selected input -- this will be indicated by a 
    null value in the appropriate place. For example, if no incident name has been
    provided, then AnswerDto2 will have type Text but a textAnswerValue of null. 
    */
    handleUpdate = () => {
        const questionGroup = cloneDeep(this.props.questionGroup);
        const question = questionGroup!.questions[0];
        
        const formAnswers: AnswerDto[] = [];
        const defaultData = {
            answerId: 0,
            questionId: question.questionId,
            order: 0,
            date: this.props.date,
            dateCreated: this.props.date,
            dateUpdated: this.props.date,
        };

        // Create AnswerDto for availability dropdown
        const option = this.state.options.find(o => o.optionId === this.state.selectedOptionId);
        formAnswers.push({
            ...defaultData,
            answerType: AnswerType.Option,
            option: { 
                answerId: 0, 
                optionId: option?.optionId,
                optionName: option?.name
            } as AnswerOptionReferenceDto
        } as AnswerDto);

        // Create AnswerDto for the incident name
        formAnswers.push({
            ...defaultData,
            answerType: AnswerType.Text,
            textAnswerValue: this.state.incidentName
        } as AnswerDto)

        // Create AnswerDto for each crew member dropdown
        this.state.selectedCrewIdsOrNames.forEach((idOrName) => {
            if (typeof idOrName === 'number'){
                const id = idOrName;
                const user = this.state.crew.find(c => c.userId === id);
                if (user) {
                    formAnswers.push({
                        ...defaultData,
                        answerType: AnswerType.User,
                        user: {
                            answerId: 0,
                            userId: user.userId,
                            userName: user.userName
                        } as AnswerUserReferenceDto
                    } as AnswerDto);
                }
            } else {
                const name = idOrName;
                formAnswers.push({
                    ...defaultData,
                    answerType: AnswerType.Text,
                    textAnswerValue: name
                })
            }
        });

        question.answers = formAnswers;
        this.props.setData(questionGroup, this.props.moduleOrder);
    }

    handleOptionUpdate = (value: string) => {
        const optionId = Number(value);
        let isIncidentVisible = false;
        let incidentName = '';
        if (this.state.optionLookup?.get(optionId)?.name === "Deployed") {
            isIncidentVisible = true;
            incidentName = this.state.incidentName;
        } 
        this.setState({ 
            selectedOptionId: optionId, 
            isIncidentVisible, 
            incidentName }, this.handleUpdate);
    }

    handleCrewUpdate = ( selectId: number, crewId?: number, crewName?: string ) => {
        const selectedCrewIdsOrNames = [...this.state.selectedCrewIdsOrNames]
        if (crewName) {
            selectedCrewIdsOrNames[selectId] = crewName;
        } else if (crewId) {
            selectedCrewIdsOrNames[selectId] = crewId;
        }
        this.setState({ selectedCrewIdsOrNames }, this.handleUpdate);
    }

    handleIncidentNameUpdate = (value: string) => {
        this.setState({ incidentName: value }, this.handleUpdate);
    }

    getName = (index: number): string => {
        const idOrName = this.state.selectedCrewIdsOrNames[index];
        if (typeof idOrName === 'number') {
            const c = this.state.crew.find(c => c.userId === idOrName)!;
            return `${c.firstName} ${c.lastName}`;
        } else {
            return idOrName;
        }
    }

    getId = (index: number): number | undefined => {
        const idOrName = this.state.selectedCrewIdsOrNames[index];
        if (typeof idOrName === 'number') {
            return idOrName;
        }
    }

    render = () => {
        return (
            <Card>
                <h4 className={"font-size-16"}>REMS Team</h4>
                {!this.state.ready ? "Loading" :
                   <div id="rems-team"> 
                        <div id="option-dropdown">
                            <Select<OptionDto>
                                items={this.state.options}
                                onChange={this.handleOptionUpdate}
                                getValue={(o: OptionDto) => o.optionId.toString()}
                                getDisplayedContent={(o: OptionDto) => o.name}
                                value={this.state.selectedOptionId?.toString() || ''}
                                placeholderOption={''}
                            />
                        </div>
                        {this.state.isIncidentVisible && <div id="incident-name">
                            <TextInput 
                                value={this.state.incidentName}
                                onChange={this.handleIncidentNameUpdate}
                                placeholderText={"Incident Name"}
                                debounce
                            />
                            </div>}
                        <div id="crew-dropdown-section">
                            { Array(this.props.crewSize).fill(null).map((_, i) => (
                                <div className={"crew-dropdown"}>
                                    <IsolatedSelect 
                                        onChange={(id, name) => this.handleCrewUpdate(i, id, name)}
                                        name={this.getName(i)}
                                        id={this.getId(i)}
                                        availableOptions={this.state.crew.map(c => { 
                                            return {id: c.userId, name: `${c.firstName} ${c.lastName}`}
                                        })}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </Card>
        )
    }
}