import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import Pagination, { usePaging } from "../Common/Pagination";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import NFIRSDynamicReportListDialog from "./NFIRSDynamicReportListDialog";

function NFIRSDynamicReportList(props: { dialog?: boolean }) {
  const [paging, setPaging] = usePaging(1, props.dialog ? 10 : 5);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const reports = useNfirsGetData(async () => {
    return ApsServices.http.nfirsDynamicReport.listReports();
  }, "Report List");

  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    reports.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, showDialog]);

  return (
    <div>
      {showDialog && (
        <NFIRSDynamicReportListDialog
          onClose={() => {
            setShowDialog(false);
          }}
        ></NFIRSDynamicReportListDialog>
      )}
      {reports.status === NfirsFetchStatus.InProgress && (
        <CommonSpinner></CommonSpinner>
      )}
      {reports.status === NfirsFetchStatus.Complete && reports.data && (
        <div>
          <table className="table table-sm m-0">
            <thead style={{ backgroundColor: "#eee" }}>
              <tr>
                {props.dialog ? (
                  <>
                    <th className="pl-3">Processed On</th>
                    <th>Requested On</th>
                  </>
                ) : (
                  <>
                    <th className="pl-3 no-wrap">
                      <small>Requested On</small>
                    </th>
                    <th>
                      <small className="no-wrap">Processed On</small>
                    </th>
                  </>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!reports.data.length && (
                <tr>
                  <td className="p-2 px-3">
                    <small>No Record(s) Found</small>
                  </td>
                </tr>
              )}
              {!!reports.data.length &&
                reports.data
                  .filter((item, i) => {
                    if (props.dialog) {
                      return true;
                    }
                    return i < 10;
                  })
                  .map((item) => (
                    <tr key={item.requestedOn.toString()}>
                      {props.dialog ? (
                        <>
                          <td className="pl-3">
                            {item.requestedOn &&
                              moment
                                .utc(item.requestedOn)
                                .local()
                                .format("MMM DD, YYYY hh:mm a")}
                          </td>
                          <td>
                            {moment(item.processedOn || "0001-01-01").year() <
                            1900
                              ? "Processing..."
                              : item.processedOn &&
                                moment
                                  .utc(item.processedOn)
                                  .local()
                                  .format("MMM DD, YYYY hh:mm a")}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="pl-3 no-wrap">
                            <small>
                              {item.requestedOn &&
                                moment
                                  .utc(item.requestedOn)
                                  .local()
                                  .format("MMM DD, YYYY hh:mm a")}
                            </small>
                          </td>
                          <td className="no-wrap">
                            <small>
                              {moment(item.processedOn || "0001-01-01").year() <
                              1900
                                ? "Processing..."
                                : item.processedOn &&
                                  moment
                                    .utc(item.processedOn)
                                    .local()
                                    .format("MMM DD, YYYY hh:mm a")}
                            </small>
                          </td>
                        </>
                      )}
                      <td>
                        <div className="pr-3">
                          {!item.outputFileName ? (
                            <>
                              {moment(item.processedOn || "0001-01-01").year() <
                              1900 ? (
                                <i className="fa fa-spin fa-spinner text-warning"></i>
                              ) : (
                                <i className="fa fa-exclamation-triangle text-danger"></i>
                              )}
                            </>
                          ) : (
                            <>
                              {item.processedOn ? (
                                <i
                                  className="fa fa-download text-primary pointer"
                                  onClick={(e) => {
                                    ApsServices.http.nfirsDynamicReport
                                      .downloadReport(item.outputFileName)
                                      .then((data) => {
                                        const file = commonService.b64toBlob(
                                          data.fileContents,
                                          "text/plain"
                                        );
                                        FileSaver.saveAs(
                                          file,
                                          data.fileDownloadName
                                        );
                                      })
                                      .catch((err) => {
                                        toastStore.showError(
                                          "Failed Downloading Reports",
                                          err
                                        );
                                      });
                                  }}
                                ></i>
                              ) : (
                                <i className="fa fa-spin fa-spinner"></i>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {props.dialog ? (
            <div className="px-2">
              <div className="flex flex-center gap-5">
                <div className="flex-1">
                  {/* <Pagination
                      size="sm"
                      length={reports?.data?.length || 0}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      pageChange={pageChange}
                      showingOfWhatLabel="Reports"
                      sizes={[5, 10, 20, 50]}
                    ></Pagination> */}
                </div>
                <div className="pt-3">
                  <button
                    className="btn btn-sm btn-outline-primary mr-2"
                    title="Refresh"
                    onClick={(e) => {
                      reports.getData();
                    }}
                  >
                    Refresh <i className="fa fa-sync"></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-right py-2 px-3">
              <button
                className="btn btn-sm btn-outline-primary mr-2"
                title="Refresh"
                onClick={(e) => {
                  reports.getData();
                }}
              >
                <i className="fa fa-sync"></i>
              </button>
              <button
                className="btn btn-sm btn-primary"
                onClick={(e) => {
                  setShowDialog(true);
                }}
              >
                View More
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NFIRSDynamicReportList;
