import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import { FgSelect, FgUseForm, useFgModel } from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import CheckboxList from "../../../Common/CheckboxList";

function NFIRSArsonDetails(props: INFIRSTabProps<any>) {
  const saveAction = (): Promise<any> => {
    //do model pre-processing here
    const copy = Object.assign({}, model.new);
    if (copy.laboratoryUsedIds?.find((o) => o === -1)) {
      copy.laboratoryUsedIds = [];
    }
    return ApsServices.http.nfirsArsonDetail.update(copy);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsArsonDetailInputDto>({
    objectName: "Details",
    nfirsSectionTab: "Arson/Details",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsArsonDetailInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Arson", "Details", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Arson", "Details", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const apparentGroups = useNfirsGetData<
    ApsModels.INfirsArsonApparentGroupInvolvementEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonApparentGroupInvolvementEntries,
    "Apparent Groups"
  );
  const methods = useNfirsGetData<
    ApsModels.INfirsArsonEntryMethodEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService.getNfirsArsonEntryMethodEntries,
    "Entry Methods"
  );
  const extentOfFireInvolvements = useNfirsGetData<
    ApsModels.INfirsArsonExtentOfFireInvolvementArrivalEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonExtentOfFireInvolvementArrivalEntries,
    "Extent of Fire Involvements"
  );
  const containers = useNfirsGetData<
    ApsModels.INfirsArsonIncendiaryDeviceEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonIncendiaryDeviceEntries,
    "Containers"
  );
  const devices = useNfirsGetData<
    ApsModels.INfirsArsonIgnitionDelayDeviceEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonIgnitionDelayDeviceEntries,
    "Ignition Delay Devices"
  );
  const fuels = useNfirsGetData<ApsModels.INfirsArsonFuelEntryOutputDto[]>(
    ApsServices.http.nfirsGenericLookupService.getNfirsArsonFuelEntries,
    "Fuels"
  );

  const otherInvestigationInfos = useNfirsGetData<
    ApsModels.INfirsArsonOtherInvestigationInfoEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonOtherInvestigationInfoEntries,
    "Other Investigations"
  );

  const initialObservations = useNfirsGetData<
    ApsModels.INfirsArsonInitialObservationEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonInitialObservationEntries,
    "Initial Observations"
  );

  const laboratoryUses = useNfirsGetData<
    ApsModels.INfirsArsonLaboratoryUsedEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonLaboratoryUsedEntries,
    "Laboratory Uses"
  );

  const propertyOwners = useNfirsGetData<
    ApsModels.INfirsArsonPropertyOwnershipEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonPropertyOwnershipEntries,
    "Laboratory Uses"
  );

  useEffect(() => {
    setProgress({ loading: true });
    apparentGroups.getData();
    methods.getData();
    extentOfFireInvolvements.getData();
    devices.getData();
    containers.getData();
    fuels.getData();
    otherInvestigationInfos.getData();
    initialObservations.getData();
    laboratoryUses.getData();
    propertyOwners.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsArsonDetail
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
            laboratoryUsedIds: !data.laboratoryUsedIds?.length
              ? [-1]
              : data.laboratoryUsedIds,
            _propertyOwnershipId: data.propertyOwnershipIds
              ? data.propertyOwnershipIds[0] || null
              : null,
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Details", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      apparentGroups.status === NfirsFetchStatus.Complete &&
      methods.status === NfirsFetchStatus.Complete &&
      extentOfFireInvolvements.status === NfirsFetchStatus.Complete &&
      devices.status === NfirsFetchStatus.Complete &&
      containers.status === NfirsFetchStatus.Complete &&
      fuels.status === NfirsFetchStatus.Complete &&
      otherInvestigationInfos.status === NfirsFetchStatus.Complete &&
      initialObservations.status === NfirsFetchStatus.Complete &&
      laboratoryUses.status === NfirsFetchStatus.Complete &&
      propertyOwners.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    apparentGroups.status,
    methods.status,
    extentOfFireInvolvements.status,
    devices.status,
    containers.status,
    fuels.status,
    otherInvestigationInfos.status,
    initialObservations.status,
    laboratoryUses.status,
    propertyOwners.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsArsonApparentGroupInvolvementEntryId: {
      displayName: "Apparent Group Involvement",
      validation: {
        required: false,
      },
    },
    nfirsArsonEntryMethodEntryId: {
      displayName: "Entry Method",
      validation: {
        required: false,
      },
    },
    nfirsArsonExtentOfFireInvolvementArrivalEntryId: {
      displayName: "Extent of Fire Involvement on Arrival",
      validation: {
        required: false,
      },
    },
    nfirsArsonIncendiaryDeviceEntryId: {
      displayName: "Container",
      validation: {
        required: false,
      },
    },
    nfirsArsonIgnitionDelayDeviceEntryId: {
      displayName: "Ignition/Delay Device",
      validation: {
        required: false,
      },
    },
    nfirsArsonFuelEntryId: {
      displayName: "Fuel",
      validation: {
        required: false,
      },
    },
    _propertyOwnershipId: {
      displayName: "Property Ownership",
      validation: {
        required: false,
      },
    },
  });

  const [groups, setGroups] = useState<number[]>([]);
  useEffect(() => {
    const items = [
      model.new.nfirsArsonApparentGroupInvolvementEntryId,
      model.new.nfirsArsonApparentGroupInvolvementEntryId2,
      model.new.nfirsArsonApparentGroupInvolvementEntryId3,
    ].filter((a) => !!a);
    setGroups(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    onModelChange({
      ...model.new,
      nfirsArsonApparentGroupInvolvementEntryId: groups[0] || null,
      nfirsArsonApparentGroupInvolvementEntryId2: groups[1] || null,
      nfirsArsonApparentGroupInvolvementEntryId3: groups[2] || null,
    } as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 col-lg-6">
          <div className="row">
            <div className="col-sm-12">
              <strong>Details</strong>
            </div>
          </div>
          <div className="mb-2 mt-3">
            <label className="m-0">Apparent Group Involvement</label>
            <div className="actionTakenList mt-2">
              {groups.map((actionId, i) => (
                <div key={i}>
                  <div>
                    {
                      apparentGroups.data?.find((a) => actionId === a.id)
                        ?.externalId
                    }{" "}
                    {
                      apparentGroups.data?.find((a) => actionId === a.id)
                        ?.description
                    }
                  </div>
                  <div>
                    <i
                      className="fa fa-times pointer"
                      onClick={() => {
                        const items = [...groups];
                        items.splice(items.indexOf(actionId), 1);
                        setGroups(items);
                      }}
                    ></i>
                  </div>
                </div>
              ))}
            </div>

            {groups?.length < 3 &&
              [groups.length].map((item) => (
                <div key={item}>
                  <FgSelect
                    id="actionsTaken"
                    selectMessage="Select Group"
                    formState={formState}
                    options={(apparentGroups.data || [])
                      ?.filter((item) =>
                        apparentGroups.data?.find((x) => {
                          return groups.indexOf(item.id) === -1;
                        })
                      )
                      ?.map((item) => {
                        return {
                          label: `${item.externalId} ${item.description}`,
                          value: item.id,
                        };
                      })}
                    onChange={(data) => {
                      if (groups.length < 3 && Number(data)) {
                        const items = [...groups];
                        items.push(Number(data));
                        setGroups(items);
                      }
                    }}
                  ></FgSelect>
                </div>
              ))}
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsArsonEntryMethodEntryId"
              label="Entry Method"
              selectMessage="Select Method"
              registeredField={registry.nfirsArsonEntryMethodEntryId}
              formState={formState}
              options={(methods.data || []).map((o) => {
                return {
                  label: `${o.externalId} ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsArsonEntryMethodEntryId: Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsArsonExtentOfFireInvolvementArrivalEntryId"
              label="Extent of Fire Involvement on Arrival"
              selectMessage="Select Extent"
              registeredField={
                registry.nfirsArsonExtentOfFireInvolvementArrivalEntryId
              }
              formState={formState}
              options={(extentOfFireInvolvements.data || []).map((o) => {
                return {
                  label: `${o.id} ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsArsonExtentOfFireInvolvementArrivalEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="row">
            <div className="col-sm-12">
              <strong>Incendiary Devices</strong>
            </div>
          </div>
          <div className="mt-3">
            <FgSelect
              id="nfirsArsonIncendiaryDeviceEntryId"
              label="Container"
              selectMessage="Select Container"
              registeredField={registry.nfirsArsonIncendiaryDeviceEntryId}
              formState={formState}
              options={(containers.data || []).map((o) => {
                return {
                  label: `${o.externalId} ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsArsonIncendiaryDeviceEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>
          <div className="mt-3">
            <FgSelect
              id="nfirsArsonIgnitionDelayDeviceEntryId"
              label="Ignition/Delay Device"
              selectMessage="Select Ignition/Delay Device"
              registeredField={registry.nfirsArsonIgnitionDelayDeviceEntryId}
              formState={formState}
              options={(devices.data || []).map((o) => {
                return {
                  label: `${o.externalId} ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsArsonIgnitionDelayDeviceEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>
          <div className="mt-3">
            <FgSelect
              id="nfirsArsonFuelEntryId"
              label="Fuel"
              selectMessage="Select Fuel"
              registeredField={registry.nfirsArsonFuelEntryId}
              formState={formState}
              options={(fuels.data || []).map((o) => {
                return {
                  label: `${o.externalId} ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsArsonFuelEntryId: Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>
          <div className="mt-3">
            <FgSelect
              id="_propertyOwnershipId"
              label="Property Ownership"
              selectMessage="Select Property Ownership"
              registeredField={registry._propertyOwnershipId}
              formState={formState}
              options={(propertyOwners.data || []).map((o) => {
                return {
                  label: `${o.externalId} ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  propertyOwnershipIds: Number(data) ? [Number(data)] : [],
                })
              }
            ></FgSelect>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <strong>Other</strong>
            </div>
          </div>

          {!progress.loading && (
            <>
              <div className="row mt-3">
                <div className="col-sm-6 col-md-4 mb-3">
                  <div className="mb-2">Other Investigative Information</div>
                  <CheckboxList
                    id="otherInvestigationIds"
                    noContainer={true}
                    noWrapLabel={true}
                    data={(otherInvestigationInfos.data || []).map((o) => {
                      return {
                        label: o.description,
                        value:
                          (model.new.otherInvestigationIds || []).indexOf(
                            o.id
                          ) > -1,
                        id: o.id,
                      };
                    })}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        otherInvestigationIds: data
                          .filter((o) => o.value)
                          .map((o) => o.id),
                      });
                    }}
                  ></CheckboxList>
                </div>
                <div className="col-sm-6 col-md-4 mb-3">
                  <div className="mb-2">Initial Observations</div>
                  <CheckboxList
                    id="initialObservationIds"
                    noContainer={true}
                    noWrapLabel={true}
                    data={(initialObservations.data || []).map((o) => {
                      return {
                        label: o.description,
                        value:
                          (model.new.initialObservationIds || []).indexOf(
                            o.id
                          ) > -1,
                        id: o.id,
                      };
                    })}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        initialObservationIds: data
                          .filter((o) => o.value)
                          .map((o) => o.id),
                      });
                    }}
                  ></CheckboxList>
                </div>
                <div className="col-sm-6 col-md-4 mb-3">
                  <div className="mb-2">Laboratory Used</div>
                  <CheckboxList
                    id="laboratoryUsedIds"
                    noContainer={true}
                    noWrapLabel={true}
                    data={[
                      { description: "None", id: -1 },
                      ...(laboratoryUses.data || []),
                    ].map((o) => {
                      return {
                        label: o.description,
                        value:
                          (model.new.laboratoryUsedIds || []).indexOf(o.id) >
                          -1,
                        id: o.id,
                      };
                    })}
                    disabledIds={
                      (model.new.laboratoryUsedIds || []).indexOf(-1) > -1
                        ? (laboratoryUses.data || []).map((o) => o.id)
                        : []
                    }
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        laboratoryUsedIds: data.find(
                          (o) => o.value && o.id === -1
                        )
                          ? [-1]
                          : data.filter((o) => o.value).map((o) => o.id),
                      });
                    }}
                  ></CheckboxList>
                </div>
                {/* <div className="col-sm-6 col-md-4 mb-3">
                  <div className="mb-2">Property Ownership</div>
                  <CheckboxList
                    id="propertyOwnershipIds"
                    noContainer={true}
                    noWrapLabel={true}
                    data={(propertyOwners.data || []).map((o) => {
                      return {
                        label: o.description,
                        value:
                          (model.new.propertyOwnershipIds || []).indexOf(o.id) >
                          -1,
                        id: o.id,
                      };
                    })}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        propertyOwnershipIds: data
                          .filter((o) => o.value)
                          .map((o) => o.id),
                      });
                    }}
                  ></CheckboxList>
                </div> */}
              </div>
            </>
          )}
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSArsonDetails;
