import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ApsModels from "../../../models";
import ApsServices from "../../../services";

function CourseRecordTypeahead(props: {
  libType: ApsModels.CredLibraryTypeEnum;
  onChange: (data: any[], ref: any) => void;
  placeholder?: string;
  forAssignmentPage?: boolean;
}) {
  const [items, setItems] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.createRef<AsyncTypeahead<any>>();
  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setItems([]);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    await (props.forAssignmentPage
      ? ApsServices.http.assignment
      : ApsServices.http.credCourseRecord
    )
      .typeAheadCourse({
        search: query,
        isAnd: false,
        recordCount: 10,
        credLibraryTypeEnum: props.libType,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.name} (${i.number})`,
          model: { ...i },
        }));
        setItems(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setIsLoading(false);
      });
  };

  return (
    <AsyncTypeahead
      id="basic-typeahead-search-course"
      labelKey="name"
      onSearch={handleSearch}
      onChange={(data) => {
        props.onChange(data, ref);
      }}
      searchText={"Searching..."}
      isLoading={isLoading}
      options={items}
      placeholder={props.placeholder || "Search Existing Course"}
      minLength={1}
      delay={500}
      useCache={false}
      ref={ref}
    />
  );
}

export default CourseRecordTypeahead;
