import React, { useEffect, useState } from "react";
import FileUpload from "./FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

function FileDragNDrop(props: {
  label?: string;
  onChange?: (files: FileList) => void;
  disabled?: boolean;
  accepts?: {
    extensions: string;
    pattern: RegExp;
  };
  dragClickLabel?: string;
}) {
  const [onDrag, setOnDrag] = useState(false);
  const [fileList, setFileList] = useState<FileList>();
  const setDrag = (e: any, drag: boolean) => {
    setOnDrag(drag || false);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={`file-dragndrop ${onDrag ? "is-dragover" : ""}`}
      onDrag={(e) => setDrag(e, true)}
      onDragStart={(e) => setDrag(e, true)}
      onDragOver={(e) => setDrag(e, true)}
      onDragEnter={(e) => setDrag(e, true)}
      onDragEnd={(e) => setDrag(e, false)}
      onDragLeave={(e) => setDrag(e, false)}
      onDrop={(e) => {
        setDrag(e, false);
        if (e.dataTransfer.files) {
          setFileList(e.dataTransfer.files);
        }
      }}
    >
      <FileUpload {...props} filesFromDrop={fileList}></FileUpload>
      <div className="pb-2">
        <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" />
        <div
          dangerouslySetInnerHTML={{
            __html:
              props.dragClickLabel || "Drag files here or click to upload",
          }}
        ></div>
      </div>
    </div>
  );
}

export default FileDragNDrop;
