import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import { FgInput, FgUseForm, useFgModel } from "../../../Common/FormGroups";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";

function NFIRSBasicActions(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsBasicActionsService.update(model.new);
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsBasicActionInputDto>({
    objectName: "Actions",
    nfirsSectionTab: "Basic/Actions",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsBasicActionInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const [actionsTaken, setActionsTaken] = useState<number[]>([]);

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsBasicActionsService
      .get(props.id)
      .then((data) => {
        const mdl = {
          ...model.new,
          ...data,
          reportId: Number(props.id),
        };

        initModels(mdl);
        setValuesFromModel(mdl);
        setProgress({ loading: false });
        props.onChange(mdl);

        const tmp = [];
        if (data.nfirsActionTaken1Id) {
          tmp.push(data.nfirsActionTaken1Id);
        }
        if (data.nfirsActionTaken2Id) {
          tmp.push(data.nfirsActionTaken2Id);
        }
        if (data.nfirsActionTaken3Id) {
          tmp.push(data.nfirsActionTaken3Id);
        }
        if (tmp.length > 0) {
          setActionsTaken(tmp);
        }
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Actions", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Basic", "Actions", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Basic", "Actions", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    actionsTaken: {
      displayName: "actionsTaken",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return actionsTaken.length > 0;
            }
            return true;
          },
        },
      },
    },
    suppressionPersonnel: {
      displayName: "Personnel",
      validation: {
        required: false,
        max: 9999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    suppressionApparatus: {
      displayName: "Apparatus",
      validation: {
        required: false,
        max: 9999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    emsPersonnel: {
      displayName: "Personnel",
      validation: {
        required: false,
        max: 9999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    emsApparatus: {
      displayName: "Apparatus",
      validation: {
        required: false,
        max: 9999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    otherPersonnel: {
      displayName: "Personnel",
      validation: {
        required: false,
        max: 9999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    otherApparatus: {
      displayName: "Apparatus",
      validation: {
        required: false,
        max: 9999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    fireServiceDeaths: {
      displayName: "Deaths",
      validation: {
        required: false,
        max: 999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    fireServiceInjuries: {
      displayName: "Injuries",
      validation: {
        required: false,
        max: 999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    civilianDeaths: {
      displayName: "Deaths",
      validation: {
        required: false,
        max: 999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    civilianInjuries: {
      displayName: "Injuries",
      validation: {
        required: false,
        max: 999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
  });

  const actions = useNfirsGetData<
    ApsModels.INfirsActionTakenFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getActionsTaken(false),
    "Actions Taken"
  );

  useEffect(() => {
    actions.getData();
    setProgress({ loading: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!progress.errorCode && actions.status === NfirsFetchStatus.Complete) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress.errorCode, actions.status]);

  useEffect(() => {
    const copy = { ...model.new, actionsTaken: null };
    copy.nfirsActionTaken1Id = actionsTaken[0] || (null as any);
    copy.nfirsActionTaken2Id = actionsTaken[1] || (null as any);
    copy.nfirsActionTaken3Id = actionsTaken[2] || (null as any);
    onModelChange(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsTaken]);

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />

      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}

      {!progress.loading && (
        <form className="row" onSubmit={handleSubmit(submit)} ref={formRef}>
          <div className="col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <strong>Actions</strong>
              </div>
            </div>

            <div className="mt-2 mb-4">
              <label>
                <span className="required-label">*</span>Actions Taken
              </label>
              <div className="actionTakenList">
                {actionsTaken.map((action) => (
                  <div key={action}>
                    <div>
                      {actions.data?.find((a) => action === a.id)?.description}{" "}
                      ({actions.data?.find((a) => action === a.id)?.externalId})
                    </div>
                    <div>
                      <i
                        className="fa fa-times pointer"
                        onClick={() => {
                          const list =
                            commonService.deepCloneJsonArray(actionsTaken);
                          const idx = list.indexOf(action);
                          list.splice(idx, 1);
                          setActionsTaken(list);
                        }}
                      ></i>
                    </div>
                  </div>
                ))}
              </div>

              <div
                className={`${actionsTaken?.length < 3 ? "" : "display-none"}`}
              >
                <AdvancedDropdown
                  domId="actionsTaken"
                  value={null}
                  searchPlaceholder="Search and Add More Actions"
                  favoritesHandler={{
                    add: ApsServices.http.nfirsFavorite.addFavoriteActionTaken,
                    remove:
                      ApsServices.http.nfirsFavorite.removeFavoriteActionTaken,
                    afterUpdate: (list: any[]) => actions.setData(list),
                  }}
                  disabledOptionValues={[
                    getModel?.new?.nfirsActionTaken1Id,
                    getModel?.new?.nfirsActionTaken2Id,
                    getModel?.new?.nfirsActionTaken3Id,
                  ]}
                  options={(actions?.data || [])
                    .sort(commonService.sortByStringProperty("description"))
                    .map((o) => {
                      return {
                        label: `${o.description} (${o.externalId}) `,
                        value: o.id,
                        originalObject: o,
                        isFavorite: o.isFavorite,
                        groupId: 0,
                        subGroupId: 0,
                      };
                    })}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      actionsTaken: Number(data) || (null as any),
                    } as any);
                    if (data) {
                      setActionsTaken([...actionsTaken, Number(data)]);
                    }
                  }}
                ></AdvancedDropdown>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mb-2">
                <strong>Resources</strong>
              </div>

              <div className="col-sm-12 mt-2">
                <label>
                  <span className="required-label">*</span>Suppression
                </label>
                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="suppressionPersonnel"
                      placeHolder="Personnel"
                      label="Personnel"
                      registeredField={registry.suppressionPersonnel}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          suppressionPersonnel: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="suppressionApparatus"
                      placeHolder="Apparatus"
                      label="Apparatus"
                      registeredField={registry.suppressionApparatus}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          suppressionApparatus: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                </div>

                <label>
                  <span className="required-label">*</span>EMS
                </label>
                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="emsPersonnel"
                      placeHolder="Personnel"
                      label="Personnel"
                      registeredField={registry.emsPersonnel}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          emsPersonnel: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="emsApparatus"
                      placeHolder="Apparatus"
                      label="Apparatus"
                      registeredField={registry.emsApparatus}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          emsApparatus: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                </div>

                <label>
                  <span className="required-label">*</span>Other
                </label>
                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="otherPersonnel"
                      placeHolder="Personnel"
                      label="Personnel"
                      registeredField={registry.otherPersonnel}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          otherPersonnel: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="otherApparatus"
                      placeHolder="Apparatus"
                      label="Apparatus"
                      registeredField={registry.otherApparatus}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          otherApparatus: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <strong>Casualties</strong>
              </div>

              <div className="col-sm-12 mt-2">
                <label>
                  <span className="required-label">*</span>Fire Service
                </label>
                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="fireServiceDeaths"
                      placeHolder="Deaths"
                      label="Deaths"
                      registeredField={registry.fireServiceDeaths}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          fireServiceDeaths: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="fireServiceInjuries"
                      placeHolder="Injuries"
                      label="Injuries"
                      registeredField={registry.fireServiceInjuries}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          fireServiceInjuries: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                </div>

                <label>
                  <span className="required-label">*</span>Civilian
                </label>
                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="civilianDeaths"
                      placeHolder="Deaths"
                      label="Deaths"
                      registeredField={registry.civilianDeaths}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          civilianDeaths: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="civilianInjuries"
                      placeHolder="Injuries"
                      label="Injuries"
                      registeredField={registry.civilianInjuries}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          civilianInjuries: Number(data) || 0,
                        } as any)
                      }
                      type="number"
                    ></FgInput>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" hidden></button>
        </form>
      )}
    </>
  );
}

export default NFIRSBasicActions;
