import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import Flag from '../../../assets/images/flags/flag.jpg';
import UsaFlag from '../../../assets/images/flags/american-flag.png';
import { AnswerDto, AnswerType, QuestionDto, QuestionGroupDto } from '../../../data/DailyOperational';
import { AppContext } from "../../../AppContext";

type State = {
    question1: boolean | undefined,
    question2: boolean | undefined
}
type Props = {
    questionGroup?: QuestionGroupDto
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    date: Date,
    moduleOrder: number
}
export class Flags extends React.Component<Props, State> {
    static contextType = AppContext;
    state: State = {
        question1: false,
        question2: false,
    }

    componentDidMount() {
        if (this.props.questionGroup) {
            this.setState({
                question1: this.props.questionGroup?.questions.find(x => x.order === 0)?.answers.find(x => x.order === 0)?.flagAnswerValue,
                question2: this.props.questionGroup?.questions.find(x => x.order === 1)?.answers.find(x => x.order === 0)?.flagAnswerValue
            });
        }
    }

    private updateQuestionGroup(newQuestionValue: QuestionDto) {
        const questionGroup = cloneDeep(this.props.questionGroup)!;
        const questionIndex = questionGroup.questions.findIndex(q => q.order == newQuestionValue.order);
        questionGroup.questions[questionIndex] = newQuestionValue;
        this.props.setData(questionGroup, this.props.moduleOrder);
    }



    handleHalfStaffChanged = (): void => {
        this.setState({ question1: !this.state.question1 })
        const questionOrder = 0;
        const question: QuestionDto = { ...this.props.questionGroup!.questions.find(q => q.order == questionOrder)! };
        const isAnswerExists = question.answers.length > 0;
        const answerId: number = question!.answers[0] ? question!.answers[0].answerId! : 0;

        let answer: AnswerDto;

        if (isAnswerExists || answerId > 0) {
            answer = question.answers.find(a => a.answerId == answerId)!;
            answer.flagAnswerValue = !this.state.question1;
            answer.date = this.props.date;
            answer.dateCreated = this.props.date;
            answer.dateUpdated = this.props.date;
        } else {
            answer = {
                answerId: answerId,
                answerType: AnswerType.Flag,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                flagAnswerValue: !this.state.question1,
                order: 0,
                questionId: question.questionId ?? 0
            }
            question.answers.push(answer);
        }
        this.updateQuestionGroup(question);
    }

    handleAdvisoryChanged = (): void => {
        this.setState({ question2: !this.state.question2 })
        const questionOrder = 1;
        const question: QuestionDto = { ...this.props.questionGroup!.questions.find(q => q.order == questionOrder)! };
        const isAnswerExists = question.answers.length > 0;
        const answerId: number = question!.answers[0] ? question!.answers[0].answerId! : 0;

        let answer: AnswerDto;

        if (isAnswerExists || answerId > 0) {
            answer = question.answers.find(a => a.answerId == answerId)!;
            answer.flagAnswerValue = !this.state.question2;
            answer.date = this.props.date;
            answer.dateCreated = this.props.date;
            answer.dateUpdated = this.props.date;
        } else {
            answer = {
                answerId: answerId,
                answerType: AnswerType.Flag,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                flagAnswerValue: !this.state.question2,
                order: 0,
                questionId: question.questionId ?? 0
            }
            question.answers.push(answer);
        }
        this.updateQuestionGroup(question);
    }

    render() {
        return (
            <div className="daily-operational-card card box-card">
                <div className="card-body d-print-none">
                    <h4 className="mb-0 font-size-16">{this.props.questionGroup?.name || ''}</h4>
                    <div className="d-flex">
                        <div className="mt-4">
                            <div className="form-check form-check-inline">
                                <input disabled={!this.context.isEditableDailyOperational} className="form-check-input" type="checkbox" id={`inlineCheckbox1-${this.props.questionGroup?.questionGroupId}`} onChange={this.handleHalfStaffChanged} checked={this.state.question1} />
                                <label htmlFor={`inlineCheckbox1-${this.props.questionGroup?.questionGroupId}`} className="form-check-label">{this.props.questionGroup?.questions.find(x => x.order === 0)?.questionText}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input disabled={!this.context.isEditableDailyOperational} className="form-check-input" type="checkbox" id={`inlineCheckbox2-${this.props.questionGroup?.questionGroupId}`} onChange={this.handleAdvisoryChanged} checked={this.state.question2} />
                                <label htmlFor={`inlineCheckbox2-${this.props.questionGroup?.questionGroupId}`} className="form-check-label">{this.props.questionGroup?.questions.find(x => x.order === 1)?.questionText}</label>
                            </div>
                        </div>
                        {this.state.question2 && this.state.question1 ?
                            <div className="d-flex">
                                    <img src={Flag} className="max-h-14" alt="Flag" />
                                    <img src={UsaFlag} className="max-h-14" alt="USA Flag" />
                            </div>
                            :
                            <div className="d-flex">
                                {this.state.question2 ? <img src={Flag} alt="Flag" className="max-h-14" /> : null}
                                {this.state.question1 ? <img src={UsaFlag} alt="USA Flag" className="max-h-14" /> : null}
                            </div>
                        }
                    </div>
                </div>
                <div className="card-body d-none childsection1">
                    <div className="d-flex">
                        <h4 className="mb-0 font-size-22"> <b>{this.props.questionGroup?.name || ''}:</b> </h4>
                        {this.state.question2 && this.state.question1 ?
                            <div className="d-flex">
                                    <img src={Flag} className="max-h-14" alt="Flag" />
                                    <img src={UsaFlag} className="max-h-14" alt="USA Flag" />
                            </div>
                            :
                            <div className="d-flex">
                                {this.state.question2 ? <img src={Flag} alt="Flag" className="max-h-14" /> : null}
                                {this.state.question1 ? <img src={UsaFlag} alt="USA Flag" className="max-h-14" /> : null}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
