import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSFireProperty {
  get = async (reportId: any) => {
    const url = `/api/NfirsFireProperty/${reportId}`;
    return fetcher.get<ApsModels.INfirsFirePropertyOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsFirePropertyInputDto) => {
    const url = `/api/NfirsFireProperty`;
    return fetcher.post<ApsModels.INfirsFirePropertyOutputDto>(url, data);
  };
}

const nfirsFireProperty = new NFIRSFireProperty();
export default nfirsFireProperty;
