import { faEye, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { UserService } from "../../../services/UserService";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService, { SystemTypes } from "../../services/CommonService";
import nfirsStore from "../../stores/NFIRSStore";
import { useGridFilters } from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import { usePaging } from "../Common/Pagination";
import NFIRSSummaryDialog from "./Entry/NFIRSSummaryDialog";
import { NfirsFetchStatus, useNfirsGetData } from "./NFIRSHelper";
import FileSaver from "file-saver";
import { Button } from "reactstrap";
import ConfirmDialog from "../Common/ConfirmDialog";
import CommonSpinner from "../Common/CommonSpinner";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import NFIRSReportQueueTable from "./NFIRSReportQueueTable";
import LoadMoreIndicator from "../Common/LoadMoreIndicator";

function NFIRSList(props: any) {
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentId > 0) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [dateFrom, setDateFrom] = useState(
    moment().add(-6, "month").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(moment().endOf("day").toDate());
  const [showDates, setShowDates] = useState(false);

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filters, setFilters] = useState<ApsModels.INfirsReportSearchInputDto>({
    isMyReportsOnly: true,
    // systemStore.nfirsListFilters
    //   ? systemStore.nfirsListFilters.isMyReportsOnly
    //   : true,
    isOpenOnly: true,
    // systemStore.nfirsListFilters
    //   ? systemStore.nfirsListFilters.isOpenOnly
    //   : true,
    startDateString: null as any,
    endDateString: null as any,
    query: null as any,
    moduleId: null as any,
    tabId: null as any,
    columnId: null as any,
    status: ApsModels.NfirsReportStatusEnum.Any,
  });
  const [lastfilters, setLastFilters] =
    useState<ApsModels.INfirsReportSearchInputDto>(filters);

  const [list, setList] = useState<ApsModels.INfirsReportGridOutputDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridFilters.init(params);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    // if (gridApi && gridColumnApi && list) {
    //   setTimeout(() => {
    //     gridApi.sizeColumnsToFit();
    //     let allColumnIds: any[] = [];
    //     gridColumnApi.getAllColumns()?.forEach(function (column: any) {
    //       allColumnIds.push(column.colId);
    //     });
    //     gridColumnApi.autoSizeColumns(allColumnIds, false);
    //   }, 500);
    // }
  };

  const EditButtonCellComponent = (rowProps: {
    data: ApsModels.INfirsReportOutputDto;
  }) => {
    const buttonClicked = () => {
      props.history.push(`/nfirs/${rowProps.data.id}`);
    };

    let canEdit = true; //TODO:

    return (
      <>
        {canEdit && (
          <button
            type="button"
            className="btn btn-sm btn-link"
            onClick={() => buttonClicked()}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
        )}
        {!canEdit && (
          <button
            type="button"
            className="btn btn-sm btn-outlined"
            onClick={() => buttonClicked()}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        )}

        {claims?.nfirs?.canDelete && (
          <button
            type="button"
            className="btn btn-sm btn-outlined"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setReportToDelete(rowProps.data.id);
            }}
          >
            <FontAwesomeIcon className="text-danger" icon={faTrash} />
          </button>
        )}
      </>
    );
  };

  const StatusCellComponent = (rowProps: {
    data: ApsModels.INfirsReportOutputDto;
  }) => {
    return (
      <span
        style={{
          padding: "0 5px",
        }}
        className={
          rowProps.data.isOpen ? "alert alert-success" : "alert alert-warning"
        }
      >
        <small>{rowProps.data.isOpen ? "Open" : "Locked"}</small>
      </span>
    );
  };

  const AsrsonReportComponent = (rowProps: {
    data: ApsModels.INfirsReportOutputDto;
  }) => {
    return (
      <>
        <i
          className={`fa ${
            rowProps.data.isArsonReport
              ? "fa-check-square text-primary"
              : "fa-minus-square"
          }`}
        ></i>
      </>
    );
  };

  // const ViewSummaryComponent = (rowProps: {
  //   data: ApsModels.INfirsReportOutputDto;
  // }) => {
  //   return (
  //     <>
  //       <button
  //         className="btn btn-sm btn-primary"
  //         onClick={(event) => {
  //           event.preventDefault();
  //           event.stopPropagation();
  //           setReportIdToView(rowProps.data.id);
  //         }}
  //       >
  //         View Summary
  //       </button>
  //     </>
  //   );
  // };

  const CompleteCellComponent = (rowProps: {
    data: ApsModels.INfirsReportOutputDto;
  }) => {
    return (
      <span
        style={{
          padding: "0 5px",
        }}
        className={
          rowProps.data.isComplete
            ? "alert alert-primary"
            : "alert alert-danger"
        }
      >
        <small>{rowProps.data.isComplete ? "Yes" : "No"}</small>
      </span>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          return true; // (params?.data?.participantId || 0) !== 0;
        },
        field: "_selected",
        headerName: "",
        ...commonService.agGridHelpers.checkboxColDef,
      },
      {
        field: "number",
        headerName: "Number",
      },
      {
        field: "_date",
        headerName: "Date",
        ...commonService.agGridHelpers.dateValueFormatter("date"),
      },
      {
        field: "location",
        headerName: "Location",
      },
      {
        field: "officerInCharge",
        headerName: "Officer in Charge",
      },
      {
        field: "incidentType",
        headerName: "Incident Type",
      },
      {
        field: "fireDepartmentId",
        headerName: "FDID",
      },
      {
        field: "stateAbbreviation",
        headerName: "State",
      },
      {
        field: "exposure",
        headerName: "Exposure",
      },

      {
        field: "isArsonReport",
        headerName: "Arson Report",
        cellRenderer: "asrsonReportComponent",
      },
      {
        field: "_isOpen",
        headerName: "Status",
        cellRenderer: "statusCellComponent",
      },
      {
        field: "complete",
        headerName: "Complete",
        cellRenderer: "completeCellComponent",
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "editButtonCellComponent",
        width: 100,
        ...commonService.agGridHelpers.actionColPinnedDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      minWidth: 100,
    },
    frameworkComponents: {
      editButtonCellComponent: EditButtonCellComponent,
      statusCellComponent: StatusCellComponent,
      asrsonReportComponent: AsrsonReportComponent,
      //viewSummaryComponent: ViewSummaryComponent,
      completeCellComponent: CompleteCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as any[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const gridFilters = useGridFilters("nfirs-table", gridApi, (others) => {
    if (others) {
      setDateFrom(moment(others?.startDateString).toDate());
      setDateTo(moment(others?.endDateString).toDate());
      setFilters((prev) => {
        return {
          ...(prev || {}),
          ...(others as any),
        };
      });
    }
    setShowDates(true);
  });
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageToLoad, setPageToLoad] = useState(1);

  const getList = async () => {
    if (!validateValue()) {
      return;
    }

    gridApi && gridApi.showLoadingOverlay();

    const copy = { ...filters };
    copy.query = getValidValue(copy.query || "");
    setLoading(true);
    setPageToLoad(1);

    await ApsServices.http.nfirsReport
      .list(1, commonService.gridPageSize.firstPage, copy)
      .then((data) => {
        setList(data);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        }
        gridFilters.saveOthers({ ...copy });
      })
      .catch((err) => {
        toastStore.showError("Failed getting NFIRS Reports", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);
      });
  };

  const loadMore = () => {
    if (list && list.totalRecords > list.nfirsReportOutputDtos?.length) {
      const copy = { ...filters };
      copy.query = getValidValue(copy.query || "");
      setLoadingMore(true);
      setLoading(false);

      let pageNum = pageToLoad + 1;
      if (
        commonService.gridPageSize.firstPage <
          commonService.gridPageSize.otherPages &&
        list.nfirsReportOutputDtos?.length ===
          commonService.gridPageSize.firstPage
      ) {
        pageNum = 1;
      }

      setPageToLoad(pageNum);
      ApsServices.http.nfirsReport
        .list(pageNum, commonService.gridPageSize.otherPages, copy)
        .then((data) => {
          setList((prev) => {
            return {
              totalRecords: prev?.totalRecords || data.totalRecords,
              nfirsReportOutputDtos:
                pageNum === 1
                  ? data.nfirsReportOutputDtos
                  : [
                      ...(prev?.nfirsReportOutputDtos || []),
                      ...data.nfirsReportOutputDtos,
                    ],
            };
          });
        })
        .catch((err) => {
          toastStore.showError("Failed getting NFIRS Reports", err);
          setLoadingMore(false);
        })
        .finally(() => {
          setLoadingMore(false);
          setLoading(false);
        });
    } else {
      gridFilters.reApplyFilters(); //need to reapply filter after adding more data
      toggleColSizes();
      setLoadingMore(false);
      setLoading(false);
    }
  };

  const [keyFilters, setKeyFilters] = useState<{
    moduleId?: string;
    tabId?: string;
    columnId?: string;
    query?: string;
    status?: ApsModels.NfirsReportStatusEnum;
  }>({});
  const [lastKeyFilters, setLastKeyFilters] = useState(keyFilters);

  const [exportFilter, setExportFilters] = useState<{
    year?: number;
    month?: number;
  }>({});

  const [rowsSelectedCount, setRowSelectedCount] = useState(0);

  const months = [
    ...Array.from({ length: 12 }, (_, i) => {
      return {
        value: i + 1,
        label: moment().startOf("year").add(i, "month").format("MMMM"),
      };
    }),
  ];
  const years = [
    ...Array.from({ length: 4 }, (_, i) =>
      moment()
        .add(-1 * i, "year")
        .year()
    ),
  ];

  // useEffect(() => {
  //   // if (systemStore.nfirsListFilters) {
  //   //   setDateFrom(
  //   //     moment(systemStore.nfirsListFilters.startDateString).toDate()
  //   //   );
  //   //   setDateTo(moment(systemStore.nfirsListFilters.endDateString).toDate());
  //   //   setKeyFilters({
  //   //     moduleId: systemStore.nfirsListFilters.moduleId || undefined,
  //   //     tabId: systemStore.nfirsListFilters.tabId || undefined,
  //   //     columnId: systemStore.nfirsListFilters.columnId || undefined,
  //   //     query: systemStore.nfirsListFilters.query || undefined,
  //   //     status: systemStore.nfirsListFilters.status || 0,
  //   //   });
  //   // }
  //   //setShowDates(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (loading || loadingMore) {
      return;
    }
    if (list?.totalRecords) {
      gridApi && gridApi.hideOverlay();
    }
    setGridState({
      ...gridState,
      rowData: (list?.nfirsReportOutputDtos || []).map((row) => {
        return {
          ...row,
          _date: commonService.agGridHelpers.toExportDate(row.date),
          _isOpen: row.isOpen ? "Open" : "Locked",
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, loadingMore]);

  useEffect(() => {
    if (gridApi && gridFilters.ready) {
      if (filters.startDateString || filters.endDateString) {
        if (!filters.startDateString || !filters.endDateString) {
          toastStore.showToast(
            "Either both or no dates must be entered",
            "warning"
          );
          return;
        }
      }

      let doNothing = false;
      if (
        filters.query === lastfilters.query &&
        !(filters.query || "").trim()
      ) {
        const ref1 = {
          ...filters,
          moduleId: "",
          tabId: "",
          columnId: "",
          query: "",
        };
        const ref2 = {
          ...lastfilters,
          moduleId: "",
          tabId: "",
          columnId: "",
          query: "",
        };
        if (commonService.isEqual(ref1, ref2)) {
          doNothing = true;
        }
      }
      if (!doNothing) {
        if (paging.page === 1) {
          getList();
        } else {
          pageChange(1, paging.pageSize);
        }
      }
      setLastFilters({ ...filters });
      //systemStore.setNfirsListFilters({ ...filters });
      //gridFilters.saveOthers({ ...filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, filters, showDates, gridFilters.ready]);

  // useEffect(() => {
  //   if (gridApi) {
  //     if (filters.startDateString && filters.endDateString) {
  //       getList();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paging]);

  useEffect(() => {
    setFilters({
      ...filters,
      ...keyFilters,
      startDateString: dateFrom
        ? moment(dateFrom).format("YYYY-MM-DDT00:00:00")
        : (null as any),
      endDateString: dateTo
        ? moment(dateTo).format("YYYY-MM-DDT23:59:59")
        : (null as any),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, keyFilters]);

  const sections = useNfirsGetData(
    ApsServices.http.nfirsSearchFilter.getModules,
    "Modules"
  );
  const tabs = useNfirsGetData(() => {
    return ApsServices.http.nfirsSearchFilter.getTabs(keyFilters.moduleId || 0);
  }, "Tabs");

  const fields = useNfirsGetData(() => {
    return ApsServices.http.nfirsSearchFilter.getColumns(
      keyFilters.moduleId || 0,
      keyFilters.tabId || 0
    );
  }, "Fields");

  const [claims, setClaims] = useState(commonService.friendlyClaims);
  useEffect(() => {
    setClaims(commonService.friendlyClaims);
    sections.getData();

    const el = document.getElementById("nfrisSearchKeyword") as any;
    // if (systemStore.nfirsListFilters && el) {
    //   el.value = systemStore.nfirsListFilters.query || "";
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [placeholder, setPlaceholder] = useState("Enter keyword - Hit enter");
  const [isValidValue, setIsValidValue] = useState(true);

  useEffect(() => {
    if (
      keyFilters.moduleId &&
      keyFilters.moduleId !== lastKeyFilters.moduleId
    ) {
      tabs.getData();
    }
    if (
      keyFilters.moduleId &&
      keyFilters.tabId &&
      keyFilters.tabId !== lastKeyFilters.tabId
    ) {
      fields.getData();
    }
    setLastKeyFilters({ ...keyFilters });

    validateValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyFilters]);

  useEffect(() => {
    validateValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.data]);

  const [reporIdToView, setReportIdToView] = useState<number>();
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  const validateValue = (value?: string) => {
    let rtn = true;
    if (fields.data && fields.data?.length > 0 && keyFilters.columnId) {
      fields.data.forEach((item, i) => {
        if (item.id === keyFilters.columnId) {
          const sysType = item.propertyType as SystemTypes;
          if (sysType === "System.Boolean") {
            setPlaceholder("Enter yes/no - Hit enter");
          } else if (
            sysType === "System.Decimal" ||
            sysType === "System.Int32"
          ) {
            setPlaceholder("Enter a number - Hit enter");
          } else if (sysType === "System.DateTime") {
            setPlaceholder("MM/DD/YYYY - Hit enter");
          } else {
            setPlaceholder("Enter keyword - Hit enter");
          }
          rtn = commonService.isValidValue(
            item.propertyType as any,
            value || keyFilters.query || ""
          );
          setIsValidValue(rtn);
        }
      });
    }
    return rtn;
  };

  const getValidValue = (value: string) => {
    let rtn = value || "";
    if (fields.data && fields.data?.length > 0) {
      fields.data.forEach((item, i) => {
        if (item.id === keyFilters.columnId) {
          const sysType = item.propertyType as SystemTypes;
          if (sysType === "System.Boolean") {
            rtn = commonService.getValidBoolValue(value);
          }
        }
      });
    }
    return rtn;
  };

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    return gridApi.getSelectedRows().filter((row) => {
      return (
        !isAnyFilterPresent ||
        !!filteredRows.find((r: any) => r.data.id === row.id)
      );
    });
  };

  const [submittingCalStat, setSubmittingCalStat] = useState(false);

  const startSubmitCalStat = async () => {
    if (!exportFilter.year || !exportFilter.month) {
      return;
    }

    // const rtn = getSelectedRows();
    // const dtos = [] as number[];
    // rtn.forEach((p) => {
    //   dtos.push(p.id);
    // });

    setSubmittingCalStat(true);
    await ApsServices.http.nfirsReport
      .calStatExport({ year: exportFilter.year, month: exportFilter.month })
      .then((data) => {
        setSubmittingCalStat(false);
        // const file = commonService.b64toBlob(data.fileContents, "text/plain");
        // FileSaver.saveAs(file, data.fileDownloadName);
        toastStore.showToast("Request is now sent on queue.", "success");
      })
      .catch((err) => {
        setSubmittingCalStat(false);
        toastStore.showError("Failed exporting reports", err);
      });
  };

  const [exporting, setExporting] = useState(false);
  const [showExportNoImplemented, setShowExportNoImplemented] = useState(false);
  const startExport = async () => {
    const rtn = (getSelectedRows() || []).map((row) => row.id);
    if (rtn.length === 0) {
      return;
    }
    setExporting(true);
    await ApsServices.http.nfirsReport
      .pdfExport(rtn)
      .then((data) => {
        setExporting(false);
        const file = commonService.b64toBlob(data.fileContents, "text/plain");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((err) => {
        setExporting(false);
        toastStore.showError("Failed exporting reports", err);
      });
  };

  const [reportToDelete, setReportToDelete] = useState<number>();
  const [deletingReport, setDeletingReport] = useState(false);
  const deleteReport = async (id: number) => {
    setDeletingReport(true);
    await ApsServices.http.nfirsReport
      .delete(id)
      .then((data) => {
        getList();
        toastStore.showToast("Report Deleted.", "success");
        setDeletingReport(false);
      })
      .catch((error) => {
        toastStore.showError("Failed to Delete Report", error);
        setDeletingReport(false);
      });
  };

  return (
    <>
      <ConfirmDialog
        show={showExportNoImplemented}
        message={`
          Contact <a href="mailto:support@advanceproblemsolving.org">
            support@advanceproblemsolving.org</a> to get your report exported.`}
        title="Contact Support"
        done={(rtn) => {
          setShowExportNoImplemented(false);
        }}
        buttons="okonly"
      ></ConfirmDialog>

      <ConfirmDialog
        show={!!reportToDelete}
        message={`Are you sure you want to delete report <strong>${
          list?.nfirsReportOutputDtos?.find((r) => r.id === reportToDelete)
            ?.number || reportToDelete
        }</strong>?`}
        title="Delete Report"
        done={(rtn) => {
          if (rtn === "yes" && reportToDelete) {
            deleteReport(reportToDelete);
          }
          setReportToDelete(undefined);
        }}
        buttons="yesno"
      ></ConfirmDialog>

      {reporIdToView && (
        <NFIRSSummaryDialog
          data={{ reportId: reporIdToView }}
          onClose={() => setReportIdToView(undefined)}
          onChange={() => setReportIdToView(undefined)}
        ></NFIRSSummaryDialog>
      )}
      <div
        className="flex flex-1 flex-column nfirs-list"
        style={{ marginBottom: "-60px", position: "relative" }}
      >
        {deletingReport && <CommonSpinner overlay={true}></CommonSpinner>}

        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  NFIRS
                </span>
              </div>
              <div></div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div
                    className="flex flex-row mb-3"
                    style={{ flexWrap: "wrap", gap: "10px" }}
                  >
                    <div
                      className="flex-1 flex"
                      style={{
                        flexWrap: "wrap",
                        gap: "10px",
                        alignItems: "center",
                        minWidth: "250px",
                      }}
                    >
                      <div className="mr-4">
                        <Dropdown drop="down">
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdownNfirsActions"
                            disabled={loading}
                          >
                            Select Action
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            align="left"
                            style={{ minWidth: "250px" }}
                          >
                            <Dropdown.Item
                              onClick={(e) => {
                                gridFilters.exportAllCSV();
                              }}
                            >
                              Export All Data
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                gridFilters.exportCSV();
                              }}
                            >
                              Export Visible Data
                            </Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Header className="px-3">
                              Export PDF NFIRS (up to 5 reports)
                            </Dropdown.Header>
                            <div
                              className="text-right px-3 no-wrap pb-2"
                              style={{ minWidth: "300px" }}
                            >
                              <Button
                                color="primary"
                                size="sm"
                                disabled={
                                  submittingCalStat ||
                                  exporting ||
                                  rowsSelectedCount === 0 ||
                                  rowsSelectedCount > 5
                                }
                                onClick={() => {
                                  const rtn = getSelectedRows();
                                  if (rtn.length === 0) {
                                    toastStore.showToast(
                                      "No selected item(s) to export",
                                      "warning"
                                    );
                                  } else {
                                    startExport();
                                  }
                                }}
                              >
                                {exporting
                                  ? "Exporting..."
                                  : `Export PDF NFIRS (${rowsSelectedCount})`}
                              </Button>
                            </div>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Header className="px-3">
                              Create eNFIRS Export
                            </Dropdown.Header>
                            <div className="px-3 pb-3">
                              <div className="flex gap-5">
                                <div>
                                  <label>Year</label>
                                  <select
                                    className="form-control mb-2"
                                    value={exportFilter.year || ""}
                                    disabled={submittingCalStat || exporting}
                                    onChange={(e) => {
                                      if (!e || !e.target) {
                                        return;
                                      }
                                      setExportFilters({
                                        ...exportFilter,
                                        year:
                                          Number(e.target?.value) || undefined,
                                      });
                                    }}
                                  >
                                    <option>- Year -</option>
                                    {years.map((y) => (
                                      <option key={y} value={y}>
                                        {y}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="flex-1">
                                  <label>Month</label>
                                  <select
                                    className="form-control"
                                    value={exportFilter.month || ""}
                                    disabled={submittingCalStat || exporting}
                                    onChange={(e) => {
                                      if (!e || !e.target) {
                                        return;
                                      }
                                      setExportFilters({
                                        ...exportFilter,
                                        month:
                                          Number(e.target?.value) || undefined,
                                      });
                                    }}
                                  >
                                    <option>- Month -</option>
                                    {months?.map((q) => (
                                      <option key={q.value} value={q.value}>
                                        {q.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div
                              className="text-right px-3 no-wrap pb-2"
                              style={{ minWidth: "225px" }}
                            >
                              <Button
                                color="primary"
                                size="sm"
                                className="ml-2"
                                disabled={
                                  submittingCalStat ||
                                  exporting ||
                                  !exportFilter.year ||
                                  !exportFilter.month
                                }
                                onClick={() => {
                                  startSubmitCalStat();
                                }}
                              >
                                {submittingCalStat
                                  ? "Submitting..."
                                  : "Create eNFIRS Export"}
                              </Button>
                            </div>
                            {!submittingCalStat && (
                              <div className="pt-2" key={departmentId}>
                                <NFIRSReportQueueTable></NFIRSReportQueueTable>
                              </div>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div>
                        <strong>Filter</strong>
                      </div>
                      {showDates && (
                        <>
                          <DateTimePickerV2
                            dateOnly={true}
                            onChange={(data) => {
                              setDateFrom(data);
                            }}
                            data={dateFrom}
                            style={{ width: "100px" }}
                            disabled={loading}
                            // dayPickerProps={{
                            //   disabledDays: dateTo
                            //     ? [
                            //         {
                            //           after: dateTo,
                            //         },
                            //       ]
                            //     : [],
                            // }}
                          />
                          <div>
                            <strong>to</strong>
                          </div>
                          <DateTimePickerV2
                            dateOnly={true}
                            onChange={(data) => {
                              setDateTo(data);
                            }}
                            data={dateTo}
                            style={{ width: "100px" }}
                            disabled={loading}
                            // dayPickerProps={{
                            //   disabledDays: dateFrom
                            //     ? [
                            //         {
                            //           before: dateFrom,
                            //         },
                            //       ]
                            //     : [],
                            // }}
                          />
                        </>
                      )}

                      <button
                        className="btn btn-outline-secondary"
                        onClick={getList}
                        disabled={loading}
                      >
                        <i className="fa fa-sync"></i>
                      </button>

                      <Dropdown drop="down">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdownNfirsFilter"
                          disabled={loading}
                        >
                          {filters.isMyReportsOnly &&
                            filters.isOpenOnly &&
                            "My Reports (Open)"}
                          {filters.isMyReportsOnly &&
                            !filters.isOpenOnly &&
                            "My Reports (All)"}
                          {!filters.isMyReportsOnly &&
                            filters.isOpenOnly &&
                            "Department Reports (Open)"}
                          {!filters.isMyReportsOnly &&
                            !filters.isOpenOnly &&
                            "Department Reports (All)"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="right">
                          <Dropdown.Item
                            onClick={() => {
                              setFilters({
                                ...filters,
                                isMyReportsOnly: true,
                                isOpenOnly: true,
                              });
                            }}
                          >
                            My Reports (Open)
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setFilters({
                                ...filters,
                                isMyReportsOnly: true,
                                isOpenOnly: false,
                              });
                            }}
                          >
                            My Reports (All)
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setFilters({
                                ...filters,
                                isMyReportsOnly: false,
                                isOpenOnly: true,
                              });
                            }}
                          >
                            Department Reports (Open)
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setFilters({
                                ...filters,
                                isMyReportsOnly: false,
                                isOpenOnly: false,
                              });
                            }}
                          >
                            Department Reports (All)
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <div>
                        {/* <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={(e) => {
                            gridFilters.reset();
                            setShowDates(false);
                            setDateFrom(
                              moment().add(-6, "month").startOf("day").toDate()
                            );
                            setDateTo(moment().endOf("day").toDate());
                            setFilters({
                              isMyReportsOnly: true,
                              isOpenOnly: true,
                              startDateString: null as any,
                              endDateString: null as any,
                              query: null as any,
                              moduleId: null as any,
                              tabId: null as any,
                              columnId: null as any,
                              status: ApsModels.NfirsReportStatusEnum.Any,
                            });
                            setTimeout(() => {
                              setShowDates(true);
                            }, 100);
                            toggleColSizes();
                          }}
                          disabled={loading}
                        >
                          Reset
                        </button> */}
                      </div>
                    </div>
                    <div
                      className="flex-0"
                      style={{ display: "flex", flexFlow: "row", gap: "10px" }}
                    >
                      {claims.nfirs.canSearch && (
                        <a
                          style={{
                            display: "flex",
                            flexFlow: "row",
                            gap: "10px",
                          }}
                          id="nfrisSearchKeywordWrapper"
                          onFocus={(e) => {
                            //console.log("a focus");
                            if (
                              showFilterDropdown &&
                              e.target.id === "dropdownNfirsMoreFilters"
                            ) {
                              setShowFilterDropdown(false);
                              return;
                            }
                            setShowFilterDropdown(true);
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              if (
                                document
                                  .getElementById("nfrisSearchKeywordWrapper")
                                  ?.contains(document.activeElement)
                              ) {
                                return;
                              }
                              setShowFilterDropdown(false);
                            }, 100);
                          }}
                        >
                          <input
                            type="text"
                            id="nfrisSearchKeyword"
                            className={`form-control ${
                              isValidValue ? "" : "invalid-value"
                            }`}
                            placeholder={placeholder}
                            style={{ minWidth: "190px" }}
                            onChange={(e) => {
                              validateValue(e.currentTarget.value);
                            }}
                            onKeyDown={(e) => {
                              const val = e.currentTarget.value;
                              if (e.key === "Enter" && validateValue(val)) {
                                setKeyFilters({
                                  ...filters,
                                  query: val || "",
                                });
                                document.body.blur();
                                setShowFilterDropdown(false);
                              }
                            }}
                            disabled={loading}
                            autoComplete="off"
                          ></input>

                          <div>
                            <Dropdown drop="down" show={showFilterDropdown}>
                              <Dropdown.Toggle
                                variant="outline-secondary"
                                id="dropdownNfirsMoreFilters"
                                disabled={loading}
                                onFocus={(e) => {
                                  //console.log("btn focus");
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                onClick={(e) => {
                                  if (showFilterDropdown) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    document.body.blur();
                                    setShowFilterDropdown(false);
                                    return;
                                  }
                                  document
                                    .getElementById("nfrisSearchKeyword")
                                    ?.focus();
                                }}
                              >
                                <i className="fa fa-filter"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu align="right" className="mt-1">
                                <label className="pt-1 px-3 text-primary">
                                  {placeholder}
                                </label>
                                <hr className="my-1" />
                                <div
                                  className="py-1 px-3"
                                  style={{ minWidth: "250px" }}
                                >
                                  <div className="pb-2">
                                    <label>Filter by Status</label>
                                    <select
                                      className="form-control"
                                      value={keyFilters.status || 0}
                                      onChange={(data) => {
                                        const val = data.target.value;
                                        setKeyFilters({
                                          ...keyFilters,
                                          status: Number(val) || 0,
                                        });
                                      }}
                                    >
                                      {[
                                        {
                                          id: ApsModels.NfirsReportStatusEnum
                                            .Any,
                                          name: "Any",
                                        },
                                        {
                                          id: ApsModels.NfirsReportStatusEnum
                                            .Open,
                                          name: "Open",
                                        },
                                        {
                                          id: ApsModels.NfirsReportStatusEnum
                                            .Locked,
                                          name: "Locked",
                                        },
                                      ].map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="pb-2">
                                    <label>Search in Module</label>
                                    <select
                                      className="form-control"
                                      disabled={
                                        sections.status ===
                                        NfirsFetchStatus.InProgress
                                      }
                                      value={keyFilters.moduleId || ""}
                                      onChange={(data) => {
                                        const val = data.target.value;
                                        setKeyFilters({
                                          ...keyFilters,
                                          moduleId: val || "",
                                          tabId: "",
                                          columnId: "",
                                        });
                                      }}
                                    >
                                      <option value="">
                                        {sections.status ===
                                        NfirsFetchStatus.InProgress
                                          ? "Loading..."
                                          : "Any"}
                                      </option>
                                      {sections?.data?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="pb-2">
                                    <label>Search in Tab</label>
                                    <select
                                      className="form-control"
                                      disabled={
                                        !keyFilters.moduleId ||
                                        tabs.status ===
                                          NfirsFetchStatus.InProgress
                                      }
                                      value={keyFilters.tabId || ""}
                                      onChange={(data) => {
                                        const val = data.target.value;
                                        setKeyFilters({
                                          ...keyFilters,
                                          tabId: val || "",
                                          columnId: "",
                                        });
                                      }}
                                    >
                                      <option value="">
                                        {tabs.status ===
                                        NfirsFetchStatus.InProgress
                                          ? "Loading..."
                                          : "Any"}
                                      </option>
                                      {tabs?.data?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="pb-2">
                                    <label>Search in Field</label>
                                    <select
                                      className="form-control"
                                      disabled={
                                        !keyFilters.tabId ||
                                        fields.status ===
                                          NfirsFetchStatus.InProgress
                                      }
                                      value={keyFilters.columnId || ""}
                                      onChange={(data) => {
                                        const val = data.target.value;
                                        setKeyFilters({
                                          ...keyFilters,
                                          columnId: val || "",
                                        });
                                      }}
                                    >
                                      <option value="">
                                        {fields.status ===
                                        NfirsFetchStatus.InProgress
                                          ? "Loading..."
                                          : "Any"}
                                      </option>
                                      {fields?.data?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={(e) => {
                                        e.currentTarget.blur();
                                        setShowFilterDropdown(false);
                                        (
                                          document.getElementById(
                                            "nfrisSearchKeyword"
                                          ) as any
                                        ).value = "";
                                        setKeyFilters({
                                          ...keyFilters,
                                          columnId: "",
                                          tabId: "",
                                          moduleId: "",
                                          query: "",
                                        });
                                        setIsValidValue(true);
                                      }}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </a>
                      )}
                    </div>
                  </div>

                  <div style={{ width: "100%", height: "100%" }}>
                    <div
                      id="myGrid"
                      style={{
                        height: "100%",
                        minHeight: "300px",
                      }}
                      className="ag-theme-alpine flex-1"
                    >
                      <AgGridReact
                        columnDefs={gridState.columnDefs}
                        defaultColDef={gridState.defaultColDef}
                        autoGroupColumnDef={gridState.autoGroupColumnDef}
                        enableRangeSelection={true}
                        animateRows={true}
                        onGridReady={onGridReady}
                        rowSelection={"multiple"}
                        rowMultiSelectWithClick={false}
                        suppressRowDeselection={true}
                        suppressRowClickSelection={true}
                        frameworkComponents={gridState.frameworkComponents}
                        rowData={gridState.rowData || []}
                        onRowDoubleClicked={(event) => {
                          nfirsStore.setCurrentNfirsReport(event.data);
                          props.history.push(`/nfirs/${event.data.id}`);
                        }}
                        overlayLoadingTemplate={
                          gridState.overlayLoadingTemplate
                        }
                        overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
                        onRowSelected={() => {
                          setRowSelectedCount(getSelectedRows().length);
                        }}
                        onRowDataChanged={() => {
                          setRowSelectedCount(getSelectedRows().length);
                        }}
                        onFilterChanged={() => {
                          setRowSelectedCount(getSelectedRows().length);
                        }}
                        {...gridFilters.props}
                      />
                    </div>
                  </div>
                  <LoadMoreIndicator
                    loadingMore={loadingMore}
                    currentCount={list?.nfirsReportOutputDtos?.length}
                    totalExpected={list?.totalRecords}
                  ></LoadMoreIndicator>
                  {/* <Pagination
                    length={list?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="Reports"
                    sizes={[
                      10,
                      20,
                      50,
                      100,
                      ...(claims.nfirs.allowSelect1kRows ? [1000] : []),
                    ]}
                  ></Pagination> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NFIRSList;
