import moment from "moment";
import React, { useEffect } from "react";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import { useNfirsGetData } from "../NFIRS/NFIRSHelper";
import { IReportFilters } from "./ReportFilter";
import ReportFiltersAppliedDisplay from "./ReportFiltersAppliedDisplay";

function ISOReportOverview(props: {
  filters: IReportFilters;
  viewDetail: (category: ApsModels.IsoReportCategoryEnum) => void;
}) {
  const reportData = useNfirsGetData(async () => {
    return ApsServices.http.isoReport.overviewReport({
      users: props.filters.personnelIds,
      isoReportCategoryEnums: props.filters.trainingCategories,
      fromDateString: moment(props.filters.fromDate).format("YYYY-MM-DD"),
      toDateString: moment(props.filters.toDate).format("YYYY-MM-DD"),
    });
  }, "Report");

  useEffect(() => {
    reportData.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body flex flex-col p-4">
          <div>
            <div className="pb-4 px-3" style={{ fontSize: "16px" }}>
              <ReportFiltersAppliedDisplay
                filters={props.filters}
              ></ReportFiltersAppliedDisplay>
            </div>
            <div className="col-sm-8 cols-md-6">
              <table className="table">
                <tbody>
                  {reportData.data?.isoReportOverviewItemDtos?.map((data) => (
                    <React.Fragment key={data.category}>
                      <tr>
                        <td>
                          <strong>{data.category}</strong>
                        </td>
                        <td>
                          <strong>Number</strong>
                        </td>
                      </tr>
                      {data.isoReportOverviewItemDetailDtos?.map((row) => (
                        <tr key={row.description}>
                          <td>
                            {["Number of Drivers", "Number of Officers"].find(
                              (i) =>
                                i.toLowerCase().trim() ===
                                row.description.toLowerCase().trim()
                            ) ? (
                              <span
                                className="text-primary pointer"
                                onClick={(e) => {
                                  props.viewDetail(data.isoReportCategoryEnum);
                                }}
                              >
                                {row.description}
                              </span>
                            ) : (
                              <>{row.description}</>
                            )}
                          </td>
                          <td>{commonService.toNumberWithComma(row.number)}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ISOReportOverview;
