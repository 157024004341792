import React from 'react';
import { IsolatedInputList } from '../CustomInput/IsolatedInputList';
import { OptionService } from '../../../services/OptionService';
import { OptionDto } from '../../../data/Option';
import {
    AnswerDto,
    QuestionDto,
    QuestionGroupDto,
    QuestionUserGroupFilter
} from '../../../data/DailyOperational';
import { GroupService } from '../../../services/GroupService';
import { UserDto } from '../../../data/User';
import { SelectOption } from './SelectOption';
import { PullFromPreviousDayButton } from "../CustomInput/PullFromPreviousDayButton";
import { OptionGroupDto } from '../../../data/OptionGroup';
import { PreviousDayService } from "../../../services/PreviousDayService";
import { UserService } from "../../../services/UserService";
import CommonSpinner from '../../../aps2/components/Common/CommonSpinner';
import { Departments } from '../../../data/Department';

type Props = {
    questionGroup?: QuestionGroupDto,
    questionUserGroupFilter?: QuestionUserGroupFilter,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    date: Date,
    optionGroups: OptionGroupDto[],
    moduleOrder: number,
    departmentId: number,
    assignmentLength: number | undefined
}

interface FilteredUserDto {
    0?: UserDto[],
    1?: UserDto[],
    2?: UserDto[],
    3?: UserDto[],
    4?: UserDto[],
    5?: UserDto[]
}



type State = {
    wsaOptions: OptionDto[]
    userDto: FilteredUserDto
    newOptionId: number | null
    newOptionGroupId: number | null
    questionDto: QuestionDto | null,
    optionId: number | null,
    ready: boolean,
}

export class WildlandSpecialtyApparatus extends React.Component<Props, State>{
    private readonly otherOption = 'Other';
    private readonly default_rows = 4;

    state: State = {
        wsaOptions: new Array<OptionDto>(),
        userDto: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: []
        },        newOptionId: null,
        newOptionGroupId: null,
        questionDto: null,
        optionId: null,
        ready: false,
    }

    componentDidMount = async () => {
        const allGroup = await GroupService.getAllGroups();
        const captainsGroup = allGroup.find(x => x.name === "Captains");
        const engineerGroup = allGroup.find(x => x.name === "Engineers");
        const fireFighterGroup = allGroup.find(x => x.name === "FireFighter");
        const groupIds = [captainsGroup?.groupId ?? 0, engineerGroup?.groupId ?? 0, fireFighterGroup?.groupId ?? 0];
        const users = await UserService.getUsersInMultipleGroupsByDepartmentId(groupIds, this.props.departmentId);

        let userDto: FilteredUserDto = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: []
        }

        for (let key in userDto) {
            const property = parseInt(key) as keyof FilteredUserDto;
            if (this.props.questionUserGroupFilter?.hasOwnProperty(property)) {
                userDto[property] = await UserService.getUsersInMultipleGroupsByDepartmentId(this.props.questionUserGroupFilter[property] ?? groupIds, this.props.departmentId)
            } else {
                userDto[property] = users
            }
        }

        this.setState({ userDto: userDto })

        const wsaOptions = await this.getOptions();
        let newOptionId = null;
        let newOptionGroupId = null;
        wsaOptions.forEach((wsaOption) => {
            if (wsaOption.name === this.otherOption) {
                newOptionId = wsaOption.optionId;
                newOptionGroupId = wsaOption.optionGroupId;
            }
        })
        this.setState({
            wsaOptions: wsaOptions,
            newOptionId: newOptionId,
            newOptionGroupId: newOptionGroupId,
            ready: true
        });

    }

    componentDidUpdate = async (prevProps: Readonly<Props>) => {
        if (!(JSON.stringify(prevProps.optionGroups) === JSON.stringify(this.props.optionGroups))) {
            this.setState({wsaOptions: await this.getOptions() });
        }
    }

    private async getOptions(): Promise<OptionDto[]> {
        const wsaOptionsGroup = this.props.optionGroups.find(g => g.name === 'WSA Options');
        return wsaOptionsGroup ? await OptionService.getGroupOptions(wsaOptionsGroup.optionGroupId) : [];
    }

    factorySubGroup = (value: QuestionDto | undefined) => {
        if (value?.answers.find((answer) => {
            return answer?.option?.optionId === this.state.newOptionId;
        }) != null) {
            this.setState({
                questionDto: value
            })
        } else {
            this.saveValue(value);
        }
    }

    private saveValue(value: QuestionDto | undefined) {
        const questionGroup = this.props.questionGroup
        const index = this.props.questionGroup?.questions.findIndex(x => x.order === value?.order)
        if (index !== undefined) {
            if (questionGroup?.questions[index] && value) {
                questionGroup.questions[index] = value
            }
        }
        this.props.setData(questionGroup, this.props.moduleOrder);
    }

    getRowsNumber = (expected: number, actual: AnswerDto[] | undefined, limit?: number) => {
        if (actual?.length) {
            const actualRows = (actual.length - 1)
            if (expected <= actualRows) {
                let newRowsSize = actualRows + 1
                if (limit) {
                    if (limit <= newRowsSize) {
                        return limit
                    }else{
                        return newRowsSize
                    }
                } else {
                    return newRowsSize
                }
            }
        }
        return expected
    }

    handlePullFromPreviousDay = async (newQuestionGroupValue: QuestionGroupDto) => {
        this.props.setData(newQuestionGroupValue, this.props.moduleOrder);
    }

    render() {
        return (
            <>
                <div className="card box-card flex-grow-1">
                    {!this.state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
                    <div className="card-body">
                        <div className="row">
                            <h4 className="mb-0 font-size-16 col-xl-11 col-10">{this.props.questionGroup?.name || ''}</h4>
                            {PreviousDayService.isFeatureAvailable(this.props.date) && <PullFromPreviousDayButton questionGroupId={this.props.questionGroup?.questionGroupId!} calendarDate={this.props.date} setData={this.handlePullFromPreviousDay} />}
                        </div>
                        <div className="mt-3">Volunteers agree to minimum {this.props.assignmentLength} day assignment.</div>
                        <div className="row mt-4">
                            <div className="col-xl-6 section-column section-column-1">
                                <div className="row align-items-baseline">
                                    <div className="col-xl-5">{this.props.questionGroup?.questions.find(x => x.order === 0)?.questionText}:</div>
                                    <div className="col-xl-7 pr-0">
                                        <SelectOption setData={this.factorySubGroup} order={0} wsaOptions={this.state.wsaOptions} questionGroup={this.props.questionGroup} question={this.props.questionGroup?.questions.find(x => x.order === 0)} date={this.props.date} moduleOrder={this.props.moduleOrder} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    {this.props.questionGroup?.questions.find(x => x.order === 0)?.answers && <IsolatedInputList setData={this.factorySubGroup} defaultItemsCount={this.props.questionGroup?.questions.find(x => x.order === 0)?.limit?? this.default_rows} answerDto={this.props.questionGroup?.questions.find(x => x.order === 0)?.answers} userDto={this.state.userDto[0]} isOption={false} question={this.props.questionGroup?.questions.find(x => x.order === 0)} date={this.props.date} />}
                                </div>
                                <div className="row mt-3 align-items-baseline">
                                    <div className="col-xl-5">{this.props.questionGroup?.questions.find(x => x.order === 1)?.questionText}:</div>
                                    <div className="col-xl-7 pr-0">
                                        <SelectOption setData={this.factorySubGroup} order={1} wsaOptions={this.state.wsaOptions} questionGroup={this.props.questionGroup} question={this.props.questionGroup?.questions.find(x => x.order === 1)} date={this.props.date} moduleOrder={this.props.moduleOrder} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   {this.props.questionGroup?.questions.find(x => x.order === 1)?.answers && <IsolatedInputList setData={this.factorySubGroup} defaultItemsCount={this.props.questionGroup?.questions.find(x => x.order === 1)?.limit?? this.default_rows} answerDto={this.props.questionGroup?.questions.find(x => x.order === 1)?.answers} userDto={this.state.userDto[1]} isOption={false} question={this.props.questionGroup?.questions.find(x => x.order === 1)} date={this.props.date} />}
                                </div>
                                <div className="row mt-3 align-items-baseline">
                                    <div className="col-xl-5">{this.props.questionGroup?.questions.find(x => x.order === 2)?.questionText}:</div>
                                    <div className="col-xl-7 pr-0">
                                        <SelectOption setData={this.factorySubGroup} order={2} wsaOptions={this.state.wsaOptions} questionGroup={this.props.questionGroup} question={this.props.questionGroup?.questions.find(x => x.order === 2)} date={this.props.date} moduleOrder={this.props.moduleOrder} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                   {this.props.questionGroup?.questions.find(x => x.order === 2)?.answers && <IsolatedInputList setData={this.factorySubGroup} defaultItemsCount={this.props.questionGroup?.questions.find(x => x.order === 2)?.limit?? this.default_rows} answerDto={this.props.questionGroup?.questions.find(x => x.order === 2)?.answers} userDto={this.state.userDto[1]} isOption={false} question={this.props.questionGroup?.questions.find(x => x.order === 2)} date={this.props.date} />}
                                </div>
                            </div>
                            <div className="col-xl-6 section-column section-column-2">
                                <div className="col-xl-12 pl-0 pr-0">
                                    <div className="row align-items-baseline">
                                        <div className="col-xl-5">{this.props.questionGroup?.questions.find(x => x.order === 3)?.questionText}</div>
                                        <div className="col-xl-7 pr-0">
                                            <SelectOption setData={this.factorySubGroup} order={3} wsaOptions={this.state.wsaOptions} questionGroup={this.props.questionGroup} question={this.props.questionGroup?.questions.find(x => x.order === 3)} date={this.props.date} moduleOrder={this.props.moduleOrder}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    {this.props.questionGroup?.questions.find(x => x.order === 3)?.answers && <IsolatedInputList setData={this.factorySubGroup} defaultItemsCount={this.props.questionGroup?.questions.find(x => x.order === 3)?.limit?? this.default_rows} answerDto={this.props.questionGroup?.questions.find(x => x.order === 3)?.answers} userDto={this.state.userDto[3]} isOption={false} question={this.props.questionGroup?.questions.find(x => x.order === 3)} date={this.props.date} />}
                                </div>
                                <div className="col-xl-12 mt-3 pl-0 pr-0">
                                    <div className="row align-items-baseline">
                                        <div className="col-xl-5">{this.props.questionGroup?.questions.find(x => x.order === 4)?.questionText}:</div>
                                        <div className="col-xl-7 pr-0">
                                            <SelectOption setData={this.factorySubGroup} order={4} wsaOptions={this.state.wsaOptions} questionGroup={this.props.questionGroup} question={this.props.questionGroup?.questions.find(x => x.order === 4, this.props.questionGroup?.questions.find(x => x.order === 4)?.limit)} date={this.props.date} moduleOrder={this.props.moduleOrder}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    {this.props.questionGroup?.questions.find(x => x.order === 4)?.answers && <IsolatedInputList setData={this.factorySubGroup} defaultItemsCount={this.props.questionGroup?.questions.find(x => x.order === 4)?.limit?? this.default_rows} answerDto={this.props.questionGroup?.questions.find(x => x.order === 4)?.answers} userDto={this.state.userDto[4]} isOption={false} question={this.props.questionGroup?.questions.find(x => x.order === 4)} date={this.props.date} />}
                                </div>
                                <div className="col-xl-12 mt-3 pl-0 pr-0">
                                    <div className="row align-items-baseline">
                                        <div className="col-xl-5">{this.props.questionGroup?.questions.find(x => x.order === 5)?.questionText}:</div>
                                        <div className="col-xl-7 pr-0">
                                            <SelectOption setData={this.factorySubGroup} order={5} wsaOptions={this.state.wsaOptions} questionGroup={this.props.questionGroup} question={this.props.questionGroup?.questions.find(x => x.order === 5, this.props.questionGroup?.questions.find(x => x.order === 5)?.limit)} date={this.props.date} moduleOrder={this.props.moduleOrder}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    {this.props.questionGroup?.questions.find(x => x.order === 5)?.answers && <IsolatedInputList setData={this.factorySubGroup} defaultItemsCount={this.props.questionGroup?.questions.find(x => x.order === 5)?.limit?? this.default_rows} answerDto={this.props.questionGroup?.questions.find(x => x.order === 5)?.answers} userDto={this.state.userDto[5]} isOption={false} question={this.props.questionGroup?.questions.find(x => x.order === 5)} date={this.props.date} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
