import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  FgCheckbox,
  FgInput,
  FgUseForm,
} from "../../../aps2/components/Common/FormGroups";
import commonService from "../../../aps2/services/CommonService";
import ApsModels from "../../../aps2/models";
import ApsServices from "../../../aps2/services";
import toastStore from "../../../aps2/stores/ToastStore";
import {
  NfirsFetchStatus,
  useNfirsGetData,
} from "../../../aps2/components/NFIRS/NFIRSHelper";
import CommonSpinner from "../../../aps2/components/Common/CommonSpinner";

const DefaultNotifications = forwardRef(
  (
    props: {
      saving: (saving: boolean) => void;
      allowSave: (allowSave: boolean, allowClear: boolean) => void;
      hasChanges: (hasChanges: boolean) => void;
    },
    ref
  ) => {
    const [saving, setSaving] = useState(false);
    const [model, setModel] =
      useState<ApsModels.IDefaultCredentialNotificationDto>({} as any);
    const [origModel, setOrigModel] =
      useState<ApsModels.IDefaultCredentialNotificationDto>({} as any);
    const [ignoreChanges, setIgnorechanges] = useState(false);

    const current = useNfirsGetData(
      () => ApsServices.http.credDefaultNotification.get(),
      "Default Notification"
    );

    useEffect(() => {
      current.getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (current.data && current.status === NfirsFetchStatus.Complete) {
        const newData = {
          ...current.data,
          _daysBeforeAssignmentDueDate: !commonService.isNullOrWhitespace(
            current.data.daysBeforeAssignmentDueDate
          ),
          _daysAfterAssignmentDueDate: !commonService.isNullOrWhitespace(
            current.data.daysAfterAssignmentDueDate
          ),
        };
        setModel({
          ...newData,
        } as any);
        setOrigModel({
          ...newData,
        } as any);
        setValuesFromModel({
          ...current.data,
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current.status]);

    const {
      registry,
      handleSubmit,
      formState,
      setValuesFromModel,
      formRef,
      triggerSubmitForm,
    } = FgUseForm({
      /*
       * This is where we add fields for validation
       */
      notifyOtherEmailAddresses: {
        displayName: "Notify Other Email(s)",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if (model.notifyOther) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateEmailCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
      daysBeforeAssignmentDueDate: {
        displayName: "Days Before Assignment Due Date",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if ((model as any)._daysBeforeAssignmentDueDate) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateNumbersOnlyCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
      daysAfterAssignmentDueDate: {
        displayName: "Days After Assignment Due Date",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if ((model as any)._daysAfterAssignmentDueDate) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateNumbersOnlyCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
    });

    const submit = async (form: any) => {
      if (current.status !== NfirsFetchStatus.Complete) {
        return;
      }
      setSaving(true);
      props.saving(true);

      const data = { ...model };
      if (!(model as any)._daysBeforeAssignmentDueDate) {
        data.daysBeforeAssignmentDueDate = "";
      }
      if (!(model as any)._daysAfterAssignmentDueDate) {
        data.daysAfterAssignmentDueDate = "";
      }

      await ApsServices.http.credDefaultNotification
        .save(data)
        .then((data) => {
          toastStore.showToast(
            "Default notifications have been submitted",
            "success"
          );
          setOrigModel({ ...model });
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Default Notifications", err);
        })
        .finally(() => {
          setSaving(false);
          props.saving(false);
        });
    };

    useImperativeHandle(ref, () => ({
      save() {
        triggerSubmitForm();
      },
      reset() {
        //current.getData();
        setModel({
          disableNotifications: model.disableNotifications || false,
          notifyAccountAdmins: false,
          notifyImmediateSupervisors: false,
          notifyOnAssignmentDate: false,
          notifyOther: false,
          notifyOtherEmailAddresses: "",
          notifyParticipants: false,
          notifyUponCompletion: false,
          notifyUponEnrollment: false,
          daysAfterAssignmentDueDate: "",
          daysBeforeAssignmentDueDate: "",
        });
      },
    }));

    const hasValues = useMemo(() => {
      if (model.disableNotifications) {
        return true;
      }
      return (
        [
          commonService.isNullOrWhitespace(model.daysBeforeAssignmentDueDate)
            ? ""
            : "1",
          commonService.isNullOrWhitespace(model.daysAfterAssignmentDueDate)
            ? ""
            : "1",
          model.notifyOnAssignmentDate ? "1" : "",
          model.notifyUponEnrollment ? "1" : "",
          model.notifyUponCompletion ? "1" : "",
        ].filter((x) => !commonService.isNullOrEmpty(x)).length > 0 &&
        [
          model.notifyAccountAdmins ? "1" : "",
          model.notifyImmediateSupervisors ? "1" : "",
          model.notifyParticipants ? "1" : "",
          model.notifyOther ? model.notifyOtherEmailAddresses : "",
        ].filter((x) => !commonService.isNullOrEmpty(x)).length > 0
      );
    }, [model]);

    useEffect(() => {
      props.allowSave(hasValues, !model.disableNotifications);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasValues, model]);

    useEffect(() => {
      if (ignoreChanges) {
        props.hasChanges(false);
      }
      props.hasChanges(!commonService.isEqual(model || {}, origModel || {}));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, origModel, ignoreChanges]);

    const canEdit = () => {
      return commonService.getAccessNotifications() === "All";
    };

    return (
      <div className="p-4">
        <div>
          <i>
            Set up a one-time default email notification for the entire
            department. Note that you cannot create multiple rules for default
            notifications.
          </i>
        </div>
        {current.status === NfirsFetchStatus.InProgress && (
          <div className="p-4">
            <CommonSpinner message="Loading..."></CommonSpinner>
          </div>
        )}
        <form
          className={`${
            current.status === NfirsFetchStatus.InProgress ? "display-none" : ""
          }`}
          onSubmit={handleSubmit(submit)}
          ref={formRef}
        >
          <div className="pt-4">
            <strong>
              <h5 className="mb-0">Credentials</h5>
            </strong>
          </div>
          <div className="py-4 flex flex-center">
            <label className="switch flex-none m-0">
              <input
                type="checkbox"
                name="disableNotifications"
                defaultChecked={!model.disableNotifications}
                checked={!model.disableNotifications}
                disabled={saving || !canEdit()}
                onChange={(e) => {
                  if (e.target) {
                    const val = !e.target.checked;
                    setModel((p) => {
                      return {
                        ...p,
                        disableNotifications: val,
                      };
                    });
                  }
                }}
              />
              <span
                className={`slider round flex-none ${
                  canEdit() ? "" : "disabled"
                }`}
              ></span>
            </label>
            <label className="mb-1 px-2">Enable Default Notifications</label>
            {/* <FgCheckbox
              id="disableNotifications"
              label="Enable Notifications"
              data={!model.disableNotifications}
              onChange={(data) => {
                setModel((p) => {
                  return {
                    ...p,
                    disableNotifications: !(data || false),
                  };
                });
              }}
              disabled={saving}
            /> */}
          </div>
          <div
            className={`row mb-4 ${
              model.disableNotifications ? "display-none" : ""
            }`}
          >
            <div className="col col-sm-12 col-md-6 col-lg-4">
              <div className="mb-3">
                <strong className="text-primary">When to Notify</strong>
              </div>

              <FgCheckbox
                id="notifyUponEnrollment"
                label="Notify upon assignment or renewal assignment"
                data={model.notifyUponEnrollment}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      notifyUponEnrollment: data || false,
                    };
                  });
                }}
                disabled={saving || !canEdit()}
              />
              <div className="mb-1"></div>

              <div className="flex flex-column mb-1">
                <div className="pt-1 mb-1">
                  <FgCheckbox
                    id="_daysBeforeAssignmentDueDate"
                    label="Notify days before due date"
                    data={(model as any)._daysBeforeAssignmentDueDate || false}
                    onChange={(data) => {
                      setModel((p) => {
                        return {
                          ...p,
                          _daysBeforeAssignmentDueDate: data || false,
                        };
                      });
                    }}
                    disabled={saving || !canEdit()}
                  />
                </div>
                <div>
                  {!!(model as any)._daysBeforeAssignmentDueDate && (
                    <FgInput
                      id="daysBeforeAssignmentDueDate"
                      formGroupClass="mb-1"
                      registeredField={registry.daysBeforeAssignmentDueDate}
                      formState={formState}
                      disabled={
                        saving ||
                        !canEdit() ||
                        !(model as any)._daysBeforeAssignmentDueDate ||
                        false
                      }
                      placeHolder="Enter number of day separated by comma (#,#,#,#)"
                      onChange={(val) => {
                        setModel((p) => {
                          return {
                            ...p,
                            daysBeforeAssignmentDueDate: val,
                          };
                        });
                      }}
                    />
                  )}
                </div>
              </div>

              <FgCheckbox
                id="notifyOnAssignmentDate"
                label="Notify on due date"
                data={model.notifyOnAssignmentDate}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      notifyOnAssignmentDate: data || false,
                    };
                  });
                }}
                disabled={saving || !canEdit()}
              />
              <div className="mb-1"></div>

              <div className="flex flex-column mb-1">
                <div className="pt-1 mb-1">
                  <FgCheckbox
                    id="_daysAfterAssignmentDueDate"
                    label="Notify days after due date"
                    data={(model as any)._daysAfterAssignmentDueDate || false}
                    onChange={(data) => {
                      setModel((p) => {
                        return {
                          ...p,
                          _daysAfterAssignmentDueDate: data || false,
                        };
                      });
                    }}
                    disabled={saving || !canEdit()}
                  />
                </div>
                <div>
                  {!!(model as any)._daysAfterAssignmentDueDate && (
                    <FgInput
                      id="daysAfterAssignmentDueDate"
                      formGroupClass="mb-1"
                      registeredField={registry.daysAfterAssignmentDueDate}
                      formState={formState}
                      disabled={
                        saving ||
                        !canEdit() ||
                        !(model as any)._daysAfterAssignmentDueDate ||
                        false
                      }
                      placeHolder="Enter number of day separated by comma (#,#,#,#)"
                      onChange={(val) => {
                        setModel((p) => {
                          return {
                            ...p,
                            daysAfterAssignmentDueDate: val,
                          };
                        });
                      }}
                    />
                  )}
                </div>
              </div>

              <FgCheckbox
                id="notifyUponCompletion"
                label="Notify upon completion or renewal completion"
                data={model.notifyUponCompletion}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      notifyUponCompletion: data || false,
                    };
                  });
                }}
                disabled={saving || !canEdit()}
              />
            </div>
          </div>
          <div
            className={`row mb-4 ${
              model.disableNotifications ? "display-none" : ""
            }`}
          >
            <div className="col col-sm-12 col-md-6 col-lg-4">
              <div className="mb-3 text-primary">
                <strong>Who to Notify</strong>
              </div>

              <FgCheckbox
                id="notifyAccountAdmins"
                label="Notify account admin(s)"
                data={model.notifyAccountAdmins}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      notifyAccountAdmins: data || false,
                    };
                  });
                }}
                disabled={saving || !canEdit()}
              ></FgCheckbox>
              <div className="mb-2"></div>
              <FgCheckbox
                id="notifyImmediateSupervisors"
                label="Notify immediate supervisor(s)"
                data={model.notifyImmediateSupervisors}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      notifyImmediateSupervisors: data || false,
                    };
                  });
                }}
                disabled={saving || !canEdit()}
              ></FgCheckbox>
              <div className="mb-2"></div>
              <FgCheckbox
                id="notifyParticipants"
                label="Notify participant(s)"
                data={model.notifyParticipants}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      notifyParticipants: data || false,
                    };
                  });
                }}
                disabled={saving || !canEdit()}
              ></FgCheckbox>
              <div className="mb-2"></div>
              <FgCheckbox
                id="notifyOther"
                label="Notify specific individual(s)"
                data={model.notifyOther}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      notifyOther: data || false,
                    };
                  });
                }}
                disabled={saving || !canEdit()}
              ></FgCheckbox>
              <div className="mb-2"></div>
              {model.notifyOther && (
                <FgInput
                  formGroupClass="mt-2"
                  id="notifyOtherEmailAddresses"
                  placeHolder="Enter email addresseses of each individual separated by comma"
                  registeredField={registry.notifyOtherEmailAddresses}
                  formState={formState}
                  rows={3}
                  disabled={!model.notifyOther || saving || !canEdit()}
                  onChange={(val) => {
                    setModel((p) => {
                      return {
                        ...p,
                        notifyOtherEmailAddresses: val,
                      };
                    });
                  }}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
);

export default DefaultNotifications;
