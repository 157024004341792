import moment from "moment";
import React from "react";
import { IReportFilters } from "./ReportFilter";

function ReportFiltersAppliedDisplay(props: { filters: IReportFilters }) {
  return (
    <div>
      {props.filters.personnelIds.length > 0
        ? props.filters.singlePersonnelName ||
          `${props.filters.personnelIds.length} Personnel(s)`
        : "All Personnel"}{" "}
      /{" "}
      {props.filters.trainingCategories.length > 0
        ? props.filters.trainingCategories.length === 1
          ? props.filters.allTrainingCategories?.find(
              (c) => c.value === props.filters.trainingCategories[0]
            )?.label || ""
          : `${props.filters.trainingCategories.length} Training Categories`
        : "All Training Categories"}{" "}
      /{" "}
      {`${moment(props.filters.fromDate).format("MMM DD, YYYY")} - ${moment(
        props.filters.toDate
      ).format("MMM DD, YYYY")}`}
    </div>
  );
}

export default ReportFiltersAppliedDisplay;
