import React from 'react';
import PreviousDayIcon from '../../../assets/images/PreviousDayIcon.png';
import {AppContext} from '../../../AppContext';
import {PreviousDayDto, QuestionGroupDto} from '../../../data/DailyOperational';
import {FormService} from '../../../services/FormService';

type Props = {
    questionGroupId: number,
    calendarDate: Date,
    setData: (newQuestionGroupValue: QuestionGroupDto) => void
}

type State = {}

export class PullFromPreviousDayButton extends React.Component<Props, State> {
    static contextType = AppContext;

    handlePullFromPreviousDay = async () => {
        const pullFromPreviousDayDto: PreviousDayDto = {
            questionGroupId: this.props.questionGroupId,
            calendarDate: this.props.calendarDate
        };

        const prevDayQuestionGroup: QuestionGroupDto = await FormService.pullFromPreviousDay(pullFromPreviousDayDto)!;
        this.props.setData(prevDayQuestionGroup);
    }

    render() {
        return (
            <div className='d-print-none'>
                {this.context.isEditableDailyOperational && <img src={PreviousDayIcon} alt="refresh button" className="refresh-button" onClick={this.handlePullFromPreviousDay} />}
                {!this.context.isEditableDailyOperational && <img src={PreviousDayIcon} alt="refresh button" className="refresh-button-disabled" />}
            </div>
        )
    }
}
