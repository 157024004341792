import React from "react";
import { IChildComponentProps } from ".";
import "./FgCheckbox.scss";

interface IFgCheckboxProps extends IChildComponentProps<boolean> {
  id: string;
  label: string;
  secondLabel?: string;
  disabled?: boolean;
  noWrapLabel?: boolean;
}

export function FgCheckbox({
  id,
  label,
  secondLabel,
  disabled,
  data,
  onChange,
  noWrapLabel,
}: IFgCheckboxProps) {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };
  //console.log("Render: FgCheckbox");
  return (
    <>
      {secondLabel ? (
        <div className="fgCheckboxWith2ndLabel">
          <span>{label}</span>
          <div className="fgCheckbox">
            <input
              type="checkbox"
              id={id}
              checked={data || false}
              onChange={onInputChange}
              disabled={disabled}
            ></input>
            <label
              htmlFor={id}
              style={noWrapLabel ? { whiteSpace: "normal" } : {}}
            >
              {secondLabel}
            </label>
          </div>
        </div>
      ) : (
        <div className="fgCheckbox">
          <input
            type="checkbox"
            id={id}
            checked={data || false}
            onChange={onInputChange}
            disabled={disabled}
          ></input>
          <label
            htmlFor={id}
            style={noWrapLabel ? { whiteSpace: "normal" } : {}}
          >
            {label}
          </label>
        </div>
      )}
    </>
  );
}
