import ApsModels from "../../models";
import fetcher from "./Fetcher";

class NfirsDynamicReportService {
  getModules = async () => {
    const url = `/api/NfirsDynamicReport/GetModules`;
    return fetcher.get<ApsModels.IDynamicReportModuleOutputDto[]>(url);
  };

  getTabs = async () => {
    const url = `/api/NfirsDynamicReport/GetTabs`;
    return fetcher.get<ApsModels.IDynamicReportTabOutputDto[]>(url);
  };

  getFields = async () => {
    const url = `/api/NfirsDynamicReport/GetFields`;
    return fetcher.get<ApsModels.IDynamicReportFieldOutputDto[]>(url);
  };

  requestReport = async (data: ApsModels.INfirsDynamicReportRequestDto) => {
    const url = `/api/NfirsDynamicReport/RequestReport`;
    return fetcher.post<any>(url, data);
  };

  checkRequestLimit = async (
    data: ApsModels.IDynamicNfirsCheckRequestLimitInputDto
  ) => {
    const url = `/api/NfirsDynamicReport/CheckRequestLimit`;
    return fetcher.post<any>(url, data);
  };

  saveTemplate = async (data: ApsModels.IDynamicReportTemplateSaveDto) => {
    const url = `/api/NfirsDynamicReport/SaveTemplate`;
    return fetcher.post<any>(url, data);
  };

  getReportTemplate = async (reportId: number) => {
    const url = `/api/NfirsDynamicReport/GetReportTemplate/${reportId}`;
    return fetcher.get<ApsModels.IDynamicReportTemplateOutputDto[]>(url);
  };

  listReportTemplates = async (page: number, pageSize: number) => {
    const url = `/api/NfirsDynamicReport/ListReportTemplates/${page}/${pageSize}`;
    return fetcher.get<ApsModels.IDynamicReportTemplateGridOutputDto>(url);
  };

  deleteReportTemplate = async (reportId: number) => {
    const url = `/api/NfirsDynamicReport/DeleteReportTemplate/${reportId}`;
    return fetcher.get<any>(url);
  };

  listReports = async () => {
    const url = `/api/NfirsDynamicReport/ListReports`;
    return fetcher.get<ApsModels.IReportDisplayOutputDto[]>(url);
  };

  downloadReport = async (filename: string) => {
    const url = `/api/NfirsDynamicReport/DownloadReport/${filename}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };
}

const nfirsDynamicReport = new NfirsDynamicReportService();
export default nfirsDynamicReport;
