import React, { useEffect, useState } from "react";
import {
  AnswerDto,
  AnswerUserReferenceDto,
  IsolatedInputDetails,
  QuestionDto,
} from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { UserDto } from "../../../../data/User";
import { UserService } from "../../../../services/UserService";
import { IsolatedInput } from "../../CustomInput/IsolatedInput";
import { IsolatedInputTextArea } from "../../CustomInput/IsolatedInputTextArea";

interface IIsolatedInputListProps {
  defaultItemsCount: number;
  preventExpanding?: boolean;
  answerDto?: AnswerDto[];
  userDto?: UserDto[];
  optionsDto?: OptionDto[];
  userSelected?: UserDto;
  usersSelected?: UserDto[];
  isDropdown: boolean;
  question?: QuestionDto;
  setData?: (value: QuestionDto | undefined) => void;
  date: Date;
  questionGroup?: QuestionDto[];
}

interface IIsolatedInputListState {
  items: IsolatedInputDetails[];
  userDto?: UserDto[];
  optionsDto?: OptionDto[];
  userSelected?: UserDto | null;
  usersSelected?: UserDto[];
  answer: AnswerDto[] | undefined;
}

function GenericIsolatedInputList(props: IIsolatedInputListProps) {
  const [state, setState] = useState<Partial<IIsolatedInputListState>>({
    items: new Array<IsolatedInputDetails>(),
    userDto: [],
    optionsDto: [],
    userSelected: undefined,
    usersSelected: [],
    answer: new Array<AnswerDto>(),
  });

  const getUserId = async (
    user: AnswerUserReferenceDto | undefined
  ): Promise<UserDto> => {
    return await UserService.getUser(user?.userId ?? 0);
  };

  const initialize = async () => {
    setState({
      items: [],
      answer: undefined,
    });
    for (let i = 0; i < props.defaultItemsCount; i++) {
      const item: IsolatedInputDetails = {
        isFirst: i === 0,
        isLast: i === props.defaultItemsCount - 1,
      };
      setState((prevState) => ({
        ...prevState,
        items: [...(prevState.items || []), item],
        answer: props.question?.answers,
      }));
    }

    if (props.answerDto) {
      const user = props.answerDto?.find((x) => x.order === 0)?.user;
      if (user?.userId) {
        const rtn = await getUserId(user);
        setState((prevState) => {
          return {
            ...prevState,
            userSelected: rtn,
          };
        });
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            userSelected: null,
          };
        });
      }
    }

    if (props.usersSelected) {
      setState((prevState) => {
        return {
          ...prevState,
          usersSelected: props.usersSelected,
        };
      });
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answerDto]);

  const handleSectionInputChanged = (isLast: boolean): void => {
    if (props.preventExpanding) return;
    if (!isLast) return;

    const items = state.items || [];
    items[items.length - 1].isLast = false;
    items.push({ isFirst: false, isLast: true });

    setState({
      items: items,
    });
  };

  const setData = (question: QuestionDto | undefined) => {
    if (props.setData) {
      props.setData(question);
    }
  };

  return (
    <>
      {state.items?.map((item, index) => {
        return props.isDropdown ? (
          <IsolatedInput
            key={index}
            userDto={props.userDto}
            optionsDto={props.optionsDto}
            isFirst={item.isFirst}
            isLast={item.isLast}
            question={props.question}
            answerDto={state.answer?.find((x) => x.order === index)}
            onChange={handleSectionInputChanged}
            setData={setData}
            order={index}
            date={props.date}
          />
        ) : (
          <IsolatedInputTextArea
            key={index}
            userDto={props.userDto}
            optionsDto={props.optionsDto}
            isFirst={item.isFirst}
            isLast={item.isLast}
            question={props.question}
            answerDto={state.answer?.find((x) => x.order === index)}
            onChange={handleSectionInputChanged}
            setData={setData}
            order={index}
            questionGroup={props.questionGroup}
            date={props.date}
          />
        );
      })}
    </>
  );
}
export default GenericIsolatedInputList;
