import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSHazmatChemicalService {
  get = async (reportId: any) => {
    const url = `/api/NfirsHazmatChemical/${reportId}`;
    return fetcher.get<ApsModels.INfirsHazmatChemicalOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsHazmatChemicalInputDto) => {
    const url = `/api/NfirsHazmatChemical`;
    return fetcher.post<ApsModels.INfirsHazmatChemicalOutputDto>(url, data);
  };
}

const nfirsHazmatChemical = new NFIRSHazmatChemicalService();
export default nfirsHazmatChemical;
