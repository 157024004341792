import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSGenericEnumLookupService {
  nfirsGenericEnumLookUp = async (enumName: any) => {
    const url = `/api/NfirsGenericEnumLookUp/NfirsGenericEnumLookUp/${enumName}`;
    return fetcher.get<ApsModels.ILookupIntDto[]>(url);
  };
}

const nfirsGenericEnumLookupService = new NFIRSGenericEnumLookupService();
export default nfirsGenericEnumLookupService;
