import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import Pagination, { usePaging } from "../../../Common/Pagination";
import { INFIRSTabProps, useSaveOnNext } from "../NFIRSEntry";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import commonService from "../../../../services/CommonService";
import NFIRSDialogInvolved from "./Dialogs/NFIRSDialogInvolved";

function NFIRSBasicInvolved(props: INFIRSTabProps<any>) {
  useSaveOnNext("Basic", "Involved", () => {
    setTimeout(() => {
      nfirsStore.setMoveToNextTab();
    }, 200);
  });

  const [paging, setPaging] = usePaging(1, 20);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [list, setList] =
    useState<ApsModels.INfirsBasicInvolvedGridOutputDto>();
  const [gridApi, setGridApi] = useState(null as any);
  const [gridColumnApi, setGridColumnApi] = useState(null as any);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && list) {
      setTimeout(() => {
        gridApi.sizeColumnsToFit();
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach(function (column: any) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
      }, 500);
    }
  };

  const ActionButtonsCellComponent = (rowProps: {
    data: ApsModels.INfirsBasicInvolvedOutputDto;
  }) => {
    const editClicked = () => {
      setShowDialog({ show: true, id: rowProps.data.id });
    };

    const deleteClicked = () => {
      setItemToDelete(rowProps.data);
    };

    return (
      <>
        <button
          type="button"
          className="btn btn-sm btn-link mr-2"
          onClick={() => editClicked()}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
        <button
          type="button"
          className="btn btn-sm btn-link text-danger"
          onClick={() => deleteClicked()}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        field: "involvementDescription",
        headerName: "Involvement",
        valueFormatter: (param: any) => {
          if (param.data.involvementDescription === "Other") {
            return param.data.otherTypeInvolved || "";
          }
          return param.data.involvementDescription;
        },
      },
      {
        field: "name",
        headerName: "Name",
      },
      {
        field: "businessName",
        headerName: "Business Name",
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
      },
      {
        field: "addressNumber",
        headerName: "Complete Address",
        valueFormatter: (param: any) => {
          return [
            param.data.addressNumber,
            param.data.streetOrHighway,
            param.data.city,
            param.data.zipcode,
          ]
            .filter((a) => !!a)
            .join(", ");
        },
      },
      {
        field: "remarks",
        headerName: "Remarks",
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "actionButtonsCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    },
    frameworkComponents: {
      actionButtonsCellComponent: ActionButtonsCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as any[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();

    await ApsServices.http.nfirsBasicInvolved
      .list(paging.page, paging.pageSize, props.id)
      .then((data) => {
        setList(data);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
        }
      })
      .catch((err) => {
        toastStore.showError("Failed getting Involved List", err);
        gridApi && gridApi.hideOverlay();
      });
  };

  useEffect(() => {
    setGridState({
      ...gridState,
      rowData: list?.nfirsBasicInvolvedOutputDtos || [],
    });
    toggleColSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (gridApi) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, gridApi]);

  const [showDialog, setShowDialog] = useState({ show: false } as {
    show: boolean;
    id?: number;
  });

  const [itemToDelete, setItemToDelete] =
    useState<ApsModels.INfirsBasicInvolvedOutputDto>();

  const deleteItem = async () => {
    if (itemToDelete) {
      await ApsServices.http.nfirsBasicInvolved
        .delete(itemToDelete.id, props.id, itemToDelete.concurrencyToken)
        .then(() => {
          getList();
          nfirsStore.setManualValidateModule("Basic");
          toastStore.showToast("Involved Person has been deleted.", "success");
        })
        .catch((error) => {
          if (commonService.getErrorStatusCode(error) === 404) {
            getList();
          }
          toastStore.showError("Error deleting Involved Person", error);
        });
    }
  };

  return (
    <>
      {itemToDelete && (
        <ConfirmDialog
          show={true}
          title="Delete Involved Person"
          message="Do you really want to delete this Involved Person?"
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              deleteItem();
            }
            setItemToDelete(undefined);
          }}
        ></ConfirmDialog>
      )}

      {showDialog.show && (
        <NFIRSDialogInvolved
          data={{
            reportId: props.id,
            id: showDialog.id,
          }}
          onClose={(saved) => {
            if (saved) {
              getList();
              nfirsStore.setManualValidateModule("Basic");
            }
            setShowDialog({ show: false, id: undefined });
          }}
        ></NFIRSDialogInvolved>
      )}
      <div className="nfirs-entry-grid">
        <div className="nfirs-entry-grid-header">
          <div>
            <strong>Involved</strong>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowDialog({ show: true, id: undefined })}
            >
              Add New Person
            </button>
          </div>
        </div>

        <div className="nfirs-entry-grid-content">
          <div className="mt-3">
            <div style={{ width: "100%", height: "100%" }}>
              <div
                id="myGrid"
                style={{
                  height: "100%",
                  minHeight: "300px",
                }}
                className="ag-theme-alpine flex-1"
              >
                <AgGridReact
                  columnDefs={gridState.columnDefs}
                  defaultColDef={gridState.defaultColDef}
                  autoGroupColumnDef={gridState.autoGroupColumnDef}
                  enableRangeSelection={true}
                  animateRows={true}
                  onGridReady={onGridReady}
                  rowMultiSelectWithClick={false}
                  suppressRowDeselection={true}
                  suppressRowClickSelection={true}
                  frameworkComponents={gridState.frameworkComponents}
                  rowData={gridState.rowData || []}
                  onRowDoubleClicked={(event) => {
                    setShowDialog({ show: true, id: event.data.id });
                  }}
                  overlayLoadingTemplate={gridState.overlayLoadingTemplate}
                  overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
                />
              </div>
            </div>
          </div>

          <Pagination
            length={list?.totalRecords || 0}
            page={paging.page}
            pageSize={paging.pageSize}
            pageChange={pageChange}
            showingOfWhatLabel="Involved"
            sizes={[10, 20, 50, 100]}
          ></Pagination>
        </div>
      </div>
    </>
  );
}

export default NFIRSBasicInvolved;
