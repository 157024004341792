import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import DateTimePickerV2 from "../../../Common/DateTimePickerV2";
import FormError from "../../../Common/FormError";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import { reaction } from "mobx";

function NFIRSBasicDates(props: INFIRSTabProps<any>) {
  const saveAction = (): Promise<any> => {
    //do model pre-processing here
    const copy = commonService.deepCloneJsonObject(model.new);
    if (!copy.shift || ["A", "B", "C"].indexOf(copy.shift) > -1) {
      //do nothing...
    } else {
      copy.shift = (copy as any)["shiftOther"];
    }
    return ApsServices.http.nfirsBasicDatesService.update(copy);
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsBasicDateInputDto>({
    objectName: "Dates",
    nfirsSectionTab: "Basic/Dates",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsBasicDateInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsBasicDatesService
      .get(props.id)
      .then((data) => {
        const mdl = {
          ...model.new,
          ...data,
          reportId: Number(props.id),
          shift: data.shift
            ? ["A", "B", "C", "Other"].indexOf(data.shift) > -1
              ? data.shift
              : "Other"
            : "",
          shiftOther: data.shift
            ? ["A", "B", "C", "Other"].indexOf(data.shift) > -1
              ? ""
              : data.shift
            : "",
        };

        initModels(mdl);
        setValuesFromModel(mdl);
        setProgress({ loading: false });
        props.onChange(mdl);

        //trigger();
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Dates", err);
        setProgress({ loading: false });
      });
  };

  const incident = useNfirsGetData(async () => {
    return await ApsServices.http.nfirsBasicIncidentService.get(props.id);
  }, "Incident");

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Basic", "Dates", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Basic", "Dates", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    if (!progress.errorCode) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress.errorCode]);

  const [canAcccessModules, setCanAcccessModules] = useState(
    nfirsStore.canAcccessModules
  );
  const [nfirsBasicIncidentType, setNfirsBasicIncidentType] = useState(
    nfirsStore.nfirsBasicIncidentType
  );

  useEffect(() => {
    if (nfirsBasicIncidentType === undefined) {
      incident.getData();
    }

    const disposer = reaction(
      () => nfirsStore.nfirsBasicIncidentType,
      (data) => {
        setNfirsBasicIncidentType(data);
      }
    );

    const disposer2 = reaction(
      () => nfirsStore.canAcccessModules,
      (data) => {
        setCanAcccessModules(data);
      }
    );

    return () => {
      disposer();
      disposer2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      incident.status === NfirsFetchStatus.Complete &&
      incident.data &&
      nfirsBasicIncidentType === undefined
    ) {
      setNfirsBasicIncidentType(incident.data.nfirsIncidentTypeId || 0);
    }
  }, [incident.status]);

  const isControlledDateRequired = () => {
    if (nfirsBasicIncidentType) {
      return (
        [561, 631, 632].indexOf(nfirsBasicIncidentType) > -1 ||
        (nfirsBasicIncidentType >= 100 && nfirsBasicIncidentType < 200)
      );
    }
    return false;
  };

  const isClearedDateRequired = () => {
    // if (
    //   canAcccessModules[ApsModels.NfirsValidationModulesEnum.Fire] &&
    //   !canAcccessModules[ApsModels.NfirsValidationModulesEnum.Wildland]
    // ) {
    //   return true;
    // }
    if (canAcccessModules[ApsModels.NfirsValidationModulesEnum.Wildland]) {
      return false;
    }
    if (nfirsBasicIncidentType === 611) {
      return false;
    }
    return true;
  };

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    alarmDate: {
      displayName: "Alarm",
      validation: {
        required: false,
        validate: {
          invalidDate: (val: any) => {
            if (val && getModel.new) {
              if (
                getModel.new.arrivalDate &&
                moment(val).isAfter(getModel.new.arrivalDate)
              ) {
                return "Alarm Date/Time cannot be greater than Arrival Date/Time";
              }
              if (
                getModel.new.incidentControlledDate &&
                moment(val).isAfter(getModel.new.incidentControlledDate)
              ) {
                return "Alarm Date/Time cannot be greater than Incident Controlled Date/Time";
              }
              if (
                getModel.new.lastUnitClearedDate &&
                moment(val).isAfter(getModel.new.lastUnitClearedDate)
              ) {
                return "Alarm Date/Time cannot be greater than Last Unit Cleared Date/Time";
              }
            }
            return true;
          },
        },
      },
    },
    arrivalDate: {
      displayName: "Arrival",
      validation: {
        required: false,
        validate: {
          invalidDate: (val: any) => {
            if (val && getModel.new) {
              if (
                getModel.new.incidentControlledDate &&
                moment(val).isAfter(getModel.new.incidentControlledDate)
              ) {
                return "Arrival Date/Time cannot be greater than Incident Controlled Date/Time";
              }
              if (
                getModel.new.lastUnitClearedDate &&
                moment(val).isAfter(getModel.new.lastUnitClearedDate)
              ) {
                return "Arrival Date/Time cannot be greater than Last Unit Cleared Date/Time";
              }
            }
            return true;
          },
        },
      },
    },
    incidentControlledDate: {
      displayName: "Incident Controlled",
      validation: {
        required: false,
        validate: {
          invalidDate: (val: any) => {
            if (val && getModel.new) {
              if (
                getModel.new.lastUnitClearedDate &&
                moment(val).isAfter(getModel.new.lastUnitClearedDate)
              ) {
                return "Incident Controlled Date/Time cannot be greater than Last Unit Cleared Date/Time";
              }
            }
            return true;
          },
        },
      },
    },
    lastUnitClearedDate: {
      displayName: "Last Unit Cleared",
      validation: {
        required: false,
      },
    },
    shift: {
      displayName: "Shift",
      validation: {
        required: false,
      },
    },
    shiftOther: {
      displayName: "Shift",
      validation: {
        required: false,
        maxLength: 20,
      },
    },
    alarms: {
      displayName: "Alarms",
      validation: {
        required: false,
        max: 99,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    district: {
      displayName: "District",
      maxLength: 3,
      validation: {
        required: false,
      },
    },
    _alarmDate: { ...commonService.forms.datePicker },
    _arrivalDate: { ...commonService.forms.datePicker },
    _incidentControlledDate: { ...commonService.forms.datePicker },
    _lastUnitClearedDate: { ...commonService.forms.datePicker },
  });

  const shifts: { label: string; value: any }[] = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "Other", value: "Other" },
  ];

  const [parentCaseNumber] = useState<string | undefined>(
    nfirsStore.currentNfirsReport?.parentCaseNumber
  );

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />

      {(progress.loading ||
        incident.status === NfirsFetchStatus.InProgress) && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}

      {!(
        progress.loading || incident.status === NfirsFetchStatus.InProgress
      ) && (
        <form className="row" onSubmit={handleSubmit(submit)} ref={formRef}>
          <div className="col-sm-12 mb-2">
            <strong>Dates</strong>
          </div>
          <div className="col-sm-12 col-lg-6">
            <div className="mt-2">
              <label>
                <span className="required-label">*</span>Alarm
              </label>
              <DateTimePickerV2
                onChange={(data, invalid) => {
                  const val = data
                    ? moment(data).format("YYYY-MM-DD HH:mm:ss")
                    : (undefined as any);
                  onModelChange({
                    ...model.new,
                    alarmDate: val,
                    _alarmDate: invalid,
                  } as any);
                  setValue("alarmDate", val);
                  setValue("_alarmDate", invalid);
                  //trigger();
                }}
                data={model.new.alarmDate}
                showAllMinuteOptions={true}
                showSeconds={true}
                readOnlyDate={!!parentCaseNumber}
              />
              <FormError
                field={registry._alarmDate.name}
                formState={formState}
                fieldDisplayName="Date"
              ></FormError>
              <FormError
                field={registry.alarmDate.name}
                formState={formState}
                fieldDisplayName="Alarm"
              ></FormError>
              <div className="mt-4"></div>
              <label>
                {nfirsBasicIncidentType !== 611 && (
                  <span className="required-label">*</span>
                )}
                Arrival
              </label>
              <DateTimePickerV2
                onChange={(data, invalid) => {
                  const val = data
                    ? moment(data).format("YYYY-MM-DD HH:mm:ss")
                    : (undefined as any);
                  onModelChange({
                    ...model.new,
                    arrivalDate: val,
                    _arrivalDate: invalid,
                  } as any);
                  setValue("arrivalDate", val);
                  setValue("_arrivalDate", invalid);
                  //trigger();
                }}
                data={model.new.arrivalDate}
                showAllMinuteOptions={true}
                showSeconds={true}
              />
              <FormError
                field={registry._arrivalDate.name}
                formState={formState}
                fieldDisplayName="Date"
              ></FormError>
              <FormError
                field={registry.arrivalDate.name}
                formState={formState}
                fieldDisplayName="Arrival"
              ></FormError>
              <div className="mt-4"></div>
              <label>
                {isControlledDateRequired() && (
                  <span className="required-label">*</span>
                )}
                Incident Controlled
              </label>
              <DateTimePickerV2
                onChange={(data, invalid) => {
                  const val = data
                    ? moment(data).format("YYYY-MM-DD HH:mm:ss")
                    : (undefined as any);
                  onModelChange({
                    ...model.new,
                    incidentControlledDate: val,
                    _incidentControlledDate: invalid,
                  } as any);
                  setValue("incidentControlledDate", val);
                  setValue("_incidentControlledDate", invalid);
                  //trigger();
                }}
                data={model.new.incidentControlledDate}
                showAllMinuteOptions={true}
                showSeconds={true}
              />
              <FormError
                field={registry._incidentControlledDate.name}
                formState={formState}
                fieldDisplayName="Date"
              ></FormError>
              <FormError
                field={registry.incidentControlledDate.name}
                formState={formState}
                fieldDisplayName="Incident Controlled"
              ></FormError>
              <div className="mt-4"></div>
              <label>
                {isClearedDateRequired() && (
                  <span className="required-label">*</span>
                )}
                Last Unit Cleared
              </label>
              <DateTimePickerV2
                onChange={(data, invalid) => {
                  const val = data
                    ? moment(data).format("YYYY-MM-DD HH:mm:ss")
                    : (undefined as any);
                  onModelChange({
                    ...model.new,
                    lastUnitClearedDate: val,
                    _lastUnitClearedDate: invalid,
                  } as any);
                  setValue("lastUnitClearedDate", val);
                  setValue("_lastUnitClearedDate", invalid);
                  //trigger();
                }}
                data={model.new.lastUnitClearedDate}
                showAllMinuteOptions={true}
                showSeconds={true}
              />
              <FormError
                field={registry._lastUnitClearedDate.name}
                formState={formState}
                fieldDisplayName="Date"
              ></FormError>
              <FormError
                field={registry.lastUnitClearedDate.name}
                formState={formState}
                fieldDisplayName="Last Unit Cleared"
              ></FormError>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <div className="mt-2"></div>
            <FgSelect
              id="shift"
              label="Shift"
              selectMessage="- Select Shift -"
              registeredField={registry.shift}
              formState={formState}
              options={shifts}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  shift: data || (undefined as any),
                })
              }
            ></FgSelect>
            {model?.new?.shift === "Other" && (
              <FgInput
                id="shiftOther"
                label="Other Shift"
                placeHolder="Other Shift"
                registeredField={registry.shiftOther}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    shiftOther: data || (undefined as any),
                  } as any)
                }
              ></FgInput>
            )}

            <div className="mt-4">
              <FgInput
                id="alarms"
                placeHolder="Number of Alarms"
                label="Alarms"
                registeredField={registry.alarms}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    alarms: Number(data) || (undefined as any),
                  } as any)
                }
                type="number"
                step="1"
              ></FgInput>
            </div>
            <div className="mt-4">
              <FgInput
                id="district"
                placeHolder="District"
                label="District"
                registeredField={registry.district}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    district: data,
                  } as any)
                }
                //maxlength={3}
              ></FgInput>
            </div>
          </div>
          <button type="submit" hidden></button>
        </form>
      )}
    </>
  );
}

export default NFIRSBasicDates;
