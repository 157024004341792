import React from 'react';
import {
    AnswerDto,
    AnswerStrikeTeamReferenceDto,
    ReferenceDetails,
    StrikeTeamType,
    StrikeTeamsAnswerOrder,
    StrikeTeamsOptionOrder,
    StrikeTeamUpdateOptions
} from "../../../../data/DailyOperational";
import {StrikeTeamStatusSelect} from "../../CustomInput/StrikeTeamStatusSelect";
import {OptionDto} from "../../../../data/Option";
import {AppContext} from "../../../../AppContext";
import {StrikeTeamOptionSelect} from "../../CustomInput/StrikeTeamOptionSelect";
import {IsolatedReferenceList} from "../../CustomInput/IsolatedReferenceList";
import {UserDto} from "../../../../data/User";
import cloneDeep from 'lodash/cloneDeep';

type Props = {
    answer: AnswerDto,
    stOptions: OptionDto[],
    wsaOptions: OptionDto[],
    engines: OptionDto[],
    stenGroupUsers: UserDto[],
    stenTGroupUsers: UserDto[],
    onChange(answer: AnswerDto): void,
    onDelete(answerOrder: number): void,
    defaultItemsCount: number
}

type State = {
    selectedOptionId: number | null
}

export class StrikeTeamAnswer extends React.Component<Props, State>{
    static contextType = AppContext;

    state = {
        selectedOptionId: null
    }

    private readonly deployedStatusName: string = 'Deployed';

    updateAnswer(options: StrikeTeamUpdateOptions): void {
        if (!this.props.answer) return;
        let answer = cloneDeep(this.props.answer);

        if (options.incidentName !== undefined) {
            answer.strikeTeamIncidentName = options.incidentName;
        }

        if (options.statusOptionId) {
            answer.strikeTeamOptionId = options.statusOptionId;
        }

        if (options.referenceDetails) {
            this.updateStrikeTeamOption(answer, options.referenceDetails!);
        }

        this.props.onChange(answer);
    }

    updateStrikeTeamOption(answer: AnswerDto, referenceDetails: ReferenceDetails) {
        if (!answer.strikeTeamReferences) return;

        let reference: AnswerStrikeTeamReferenceDto | undefined = answer.strikeTeamReferences?.find(o => o.order === referenceDetails.order);
        if (reference) {
            reference.answerId = answer.answerId!;
            reference.optionId = referenceDetails.optionId;
            reference.userId = referenceDetails.userId;
            reference.optionName = referenceDetails.optionName;
            reference.order = referenceDetails.order;
            reference.type = referenceDetails.strikeTeamType!;
        } else {
            answer.strikeTeamReferences.push(this.createStrikeTeamOption(answer.answerId!, referenceDetails));
        }
    }

    private createStrikeTeamOption(answerId: number, referenceDetails: ReferenceDetails): AnswerStrikeTeamReferenceDto {
        return {
            answerStrikeTeamOptionReferenceId: 0,
            answerId: answerId,
            optionId: referenceDetails.optionId,
            userId: referenceDetails.userId,
            optionName: referenceDetails.optionName,
            order: referenceDetails.order,
            type: referenceDetails.strikeTeamType!
        };
    }

    private getStrikeTeamOptionId(): number | undefined {
        return this.props.answer?.strikeTeamReferences?.find(a => a.order === StrikeTeamsOptionOrder.StrikeTeam)?.optionId;
    }

    private getStrikeTeamOptionName(): string | undefined {
        return this.props.answer?.strikeTeamReferences?.find(a => a.order === StrikeTeamsOptionOrder.StrikeTeam)?.optionName;
    }

    private isIncidentNameVisible(): boolean {
        const selectedOptionId = this.props.answer?.strikeTeamOptionId;
        if (!selectedOptionId) return false;

        const selectedOption: OptionDto | undefined = this.props.stOptions.find(o => o.optionId === selectedOptionId);

        if (!selectedOption) return false;

        return selectedOption.name === this.deployedStatusName;
    }

    private getEngineReferences(): ReferenceDetails[] {
        const references = this.props.answer?.strikeTeamReferences;
        if (!references) return [];

        return references.map(r => {
            return { optionId: r.optionId, userId: r.userId, optionName: r.optionName, order: r.order }
        });
    }

    handleStStatusChanged = (value: string) => {
        const optionId = Number(value);
        const selectedOption = this.props.stOptions.find(o => o.optionId === optionId)!;

        let options: StrikeTeamUpdateOptions = {
            statusOptionId: selectedOption.optionId
        };

        if (selectedOption.name !== this.deployedStatusName) {
            options.incidentName = '';
        }

        if (!selectedOption) return;

        this.updateAnswer(options);
    }

    handleStrikeTeamOptionChanged = (optionId?: number, optionName?: string) => {
        const optionDetails: ReferenceDetails = {
            optionId: optionId,
            optionName: optionName,
            order: StrikeTeamsOptionOrder.StrikeTeam,
            strikeTeamType: StrikeTeamType.StrikeTeam
        };

        this.updateAnswer({
            referenceDetails: optionDetails
        });
    }

    handleEngineOptionChanged = (referenceDetails: ReferenceDetails) => {
        this.updateAnswer({
            referenceDetails: {
                ...referenceDetails,
                strikeTeamType: StrikeTeamType.Engine
            }
        });
    }

    handleIncidentNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') return;

       this.updateAnswer({
           incidentName: e.target.value
       });
    }

    handleAnswerDeleted = () => {
        this.props.onDelete(this.props.answer.order);
    }

    render() {
        const isIncidentVisible = this.isIncidentNameVisible();

        return (
            <div className="mt-3">
                <div className="row">
                    <div className="col-2">
                        {this.context.isEditableDailyOperational && <i className="delete-strike-team-btn mt-2 col-2 fas fa-trash" onClick={this.handleAnswerDeleted} />}
                        {!this.context.isEditableDailyOperational && <i className="delete-strike-team-btn-disabled col-2 mt-2 fas fa-trash" />}
                    </div>
                    <div className="col-10 pr-0">
                        <StrikeTeamStatusSelect options={this.props.stOptions} value={this.props.answer.strikeTeamOptionId} onChange={this.handleStStatusChanged} />
                    </div>
                </div>
                <div className="row mt-3 align-items-baseline">
                    <div className="col-lg-5 pr-0">Strike Team:</div>
                    <div className="col-lg-7 pr-0">
                        <StrikeTeamOptionSelect
                            enableEmptyValue={true}
                            optionId={this.getStrikeTeamOptionId()}
                            optionName={this.getStrikeTeamOptionName()}
                            options={this.props.wsaOptions}
                            onChange={this.handleStrikeTeamOptionChanged}/>
                    </div>
                </div>
                <div className="row mt-3">
                    {isIncidentVisible && <input
                        placeholder="Incident Name"
                        disabled={!this.context.isEditableDailyOperational}
                        className="form-control col-12 incident-input"
                        defaultValue={this.props.answer.strikeTeamIncidentName}
                        onChange={this.handleIncidentNameChanged}
                    />}
                </div>
                <div className="row mt-3">
                        <IsolatedReferenceList
                            availableOptions={this.props.engines}
                            sten={this.props.stenGroupUsers}
                            stenT={this.props.stenTGroupUsers}
                            defaultItemsCount={this.props.defaultItemsCount}
                            initialOrder={StrikeTeamsAnswerOrder.StrikeTeam}
                            references={this.getEngineReferences()}
                            onChange={this.handleEngineOptionChanged}
                        />
                </div>
            </div>
        )
    }
}
