import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../models";
import { GroupService } from '../../../services/GroupService';
import { GroupDto } from '../../../data/Group';
import Select, { ActionMeta, OptionTypeBase, ValueType } from 'react-select'
import { UserService } from '../../../services/UserService';
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import { Label } from "reactstrap";

type State = {
    groupIds: number[],
    availableGroups: GroupDto[] | null,
    originalData: any
}

interface GroupOptionType extends OptionTypeBase {
    label: string,
    value: number,
}

function UserGroups(props: {id: any}) {
    const [collapse, setCollapse] = useState(true);
    const [loading, setLoading] = useState(false);
    const [groupIds, setGroupIds] = useState<number[]>([]);
    const [availableGroups, setAvailableGroups] = useState<GroupDto[] | null>([]);
    const [origData, setOrigData] = useState<number[]>([]);

    const handleSubmit = async () => {
        await UserService.saveGroups(props.id, groupIds)
            .then(() => {
                toastStore.showToast("User groups saved successfully.", "success");
                setOrigData(groupIds);
            })
            .catch((err) => {
                toastStore.showError("Failed saving user groups.", err);
            });
    }

    const reset = () => {
        setGroupIds(origData);
    }

    const hasChanges = () => {
        return JSON.stringify(origData) !== JSON.stringify(groupIds);
    }

    const handleGroupsChange = (values: ValueType<GroupOptionType, true>, actionMeta: ActionMeta<GroupOptionType>) => {
        const newGroups = values?.map(x => x.value);
        setGroupIds(newGroups ?? []);
    }

    const getGroupIds = async () => {
        const userGroups = await UserService.getGroups(props.id);
        setGroupIds(userGroups);
        setOrigData(userGroups);
    }

    const getAvailableGroups = async () => {
        const groups = await GroupService.getAllGroups();
        setAvailableGroups(groups);
    }

    const mounted = async () => {
        await getGroupIds();
        await getAvailableGroups();
    }

    useEffect(() => {
        mounted();
    }, [props.id]);

    return (
        <div className="container-fluid flex-card-container">
            <div className="flex-0">
                <div className="headerControls">
                    <div>
                        <span className="pointer" onClick={() => setCollapse(!collapse)}>
                        <i
                            className={`fa fa-chevron-${
                            collapse ? "up" : "down"
                            } pl-2 pr-2`}
                        ></i>
                        <h4 className="pt-3 pl-2 m-0 inline-block">
                            Daily Operational / Groups
                        </h4>
                        </span>
                    </div>
                    <div className="pt-2 pb-0">
                    </div>
                </div>
            </div>

            {loading && (
                <div className="p-1 pb-3">
                    <CommonSpinner />
                </div>
            )}

            <div className={`${collapse || loading ? "display-none" : ""}`}>
                <div className="row">
                    <div
                        style={{
                            minHeight: "150px",
                            maxHeight: "250px",
                            minWidth: "450px",
                            maxWidth: "550px",
                            padding: "20px 0 0 25px"
                        }}
                    >
                        <Label>Groups</Label>
                        <Select
                            isClearable
                            isMulti
                            tabSelectsValue
                            value={availableGroups
                                ?.filter(x => groupIds?.includes(x.groupId))
                                ?.map(y => ({
                                    label: y.name,
                                    value: y.groupId
                                } as GroupOptionType))}
                            options={availableGroups?.map(x => ({
                                label: x.name,
                                value: x.groupId
                            } as GroupOptionType))}
                            onChange={handleGroupsChange}
                            classNamePrefix="select2-selection"
                        />
                    </div>
                </div>

                <div className="flex-0">
                    <div className="headerControls">
                        <div></div>
                        <div>
                            <button type="button" className="btn btn-danger"
                                disabled={!hasChanges()} onClick={() => reset()}>
                                Reset
                            </button>
                            <button onClick={handleSubmit} className="btn btn-primary">
                                <span className="font-size-12">Save Section</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserGroups;
