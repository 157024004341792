import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";

const LOCATION_PREFIXES_SUFFIXES = [
  { value: "E ", label: "East" },
  { value: "N ", label: "North" },
  { value: "S ", label: "South" },
  { value: "W ", label: "West" },
  { value: "NE", label: "Northeast" },
  { value: "NW", label: "Northwest" },
  { value: "SE", label: "Southeast" },
  { value: "SW", label: "Southwest" },
];

function NFIRSBasicLocation(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsBasicLocationService.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsBasicLocationInputDto>({
    objectName: "Location",
    nfirsSectionTab: "Basic/Location",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsBasicLocationInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsBasicLocationService
      .get(props.id)
      .then((data) => {
        const mdl = { ...model.new, ...data, reportId: Number(props.id) };
        initModels(mdl);
        setValuesFromModel(data);
        setProgress({ loading: false });
        props.onChange(mdl);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Location", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Basic", "Location", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Basic", "Location", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const streetTypes = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsStreetTypes,
    "Street Types"
  );
  const locTypes = useNfirsLookupEnum(
    "NfirsLocationTypeEnum",
    "Location Types"
  );
  const states = useNfirsGetData(
    async () => ApsServices.http.genericLookupService.getStates(1),
    "States"
  );

  useEffect(() => {
    if (
      !progress.errorCode &&
      streetTypes.status === NfirsFetchStatus.Complete &&
      locTypes.status === NfirsFetchStatus.Complete &&
      states.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress.errorCode, locTypes.status, states.status, streetTypes.status]);

  useEffect(() => {
    setProgress({ loading: true });
    states.getData();
    locTypes.getData();
    streetTypes.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsLocationTypeId: {
      displayName: "Location Type",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsStreetTypeEntryId: {
      displayName: "Street Type",
      validation: {
        required: false,
        maxLength: 64,
      },
    },
    numberMilePost: {
      displayName: "Number/Milepost",
      validation: {
        required: false,
        maxLength: 8,
      },
    },
    prefix: {
      displayName: "Prefix",
      validation: {
        required: false,
        maxLength: 2,
      },
    },
    streetHwy: {
      displayName: "Street/Highway",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
        maxLength: 30,
      },
    },
    suffix: {
      displayName: "Suffix",
      validation: {
        required: false,
        maxLength: 2,
      },
    },
    suite: {
      displayName: "Suite",
      validation: {
        required: false,
        maxLength: 15,
      },
    },
    city: {
      displayName: "City",
      validation: {
        validate: {
          lettersDashAndSpace: commonService.validations.lettersDashAndSpace,
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
        maxLength: 20,
      },
    },
    lookupStateId: {
      displayName: "State",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    zipCode: {
      displayName: "Zip Code",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
          zipLength: (val: any) => {
            const data = `${val}`.trim();
            if (data.length === 5 || data.length === 9) {
              return true;
            }
            if (data.length > 0) {
              return "Zip Code must be 5 or 9 digits";
            }
            return true;
          },
        },
        maxLength: 9,
      },
    },
    crossStreetDirections: {
      displayName: "Cross Street/Directions",
      validation: {
        required: false,
        maxLength: 30,
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 mb-2">
          <strong>Location Details</strong>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="mt-2">
            <FgSelect
              id="nfirsLocationTypeId"
              label="Location Type"
              selectMessage="- Select Location Type -"
              registeredField={registry.nfirsLocationTypeId}
              formState={formState}
              showRequiredLabel={true}
              options={(locTypes?.data || []).map((o) => {
                const label =
                  o.label === "USNational Grid" ? "US National Grid" : o.label; //P23172-432: APS-301
                return {
                  label: `${o.value} ${label}`,
                  value: o.value,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsLocationTypeId: Number(data) || (null as any),
                })
              }
            ></FgSelect>
            <FgSelect
              id="nfirsStreetTypeEntryId"
              label="Street Type"
              selectMessage="- Select Street Type -"
              registeredField={registry.nfirsStreetTypeEntryId}
              formState={formState}
              options={(streetTypes?.data || []).map((o) => {
                return {
                  value: o.id,
                  label: `${o.abbreviation} ${o.description}`,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsStreetTypeEntryId: Number(data) || (null as any),
                })
              }
            ></FgSelect>

            <FgInput
              id="numberMilePost"
              label="Number/Milepost"
              placeHolder="Number/Milepost"
              registeredField={registry.numberMilePost}
              formState={formState}
              value={model.new.numberMilePost}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  numberMilePost: data,
                })
              }
            ></FgInput>
            <FgSelect
              id="prefix"
              label="Prefix"
              selectMessage="- Select Prefix -"
              registeredField={registry.prefix}
              formState={formState}
              value={model.new.prefix}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  prefix: data,
                })
              }
              options={LOCATION_PREFIXES_SUFFIXES.map((sp) => {
                return {
                  value: sp.value,
                  label: `${sp.value} ${sp.label}`,
                };
              })}
            ></FgSelect>

            <FgInput
              id="streetHwy"
              label="Street/Highway"
              placeHolder="Street/Highway (Required)"
              registeredField={registry.streetHwy}
              formState={formState}
              showRequiredLabel={true}
              value={model.new.streetHwy}
              onChange={(data) =>
                onModelChange({ ...model.new, streetHwy: data })
              }
            ></FgInput>
            <FgSelect
              id="suffix"
              label="Suffix"
              selectMessage="- Select Suffix -"
              registeredField={registry.suffix}
              formState={formState}
              value={model.new.suffix}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  suffix: data,
                })
              }
              options={LOCATION_PREFIXES_SUFFIXES.map((sp) => {
                return {
                  value: sp.value,
                  label: `${sp.value} - ${sp.label}`,
                };
              })}
            ></FgSelect>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="mt-2">
            <FgInput
              id="suite"
              label="Suite"
              placeHolder="Suite"
              registeredField={registry.suite}
              formState={formState}
              value={model.new.suite}
              onChange={(data) => onModelChange({ ...model.new, suite: data })}
              //maxlength={15}
            ></FgInput>
            <FgInput
              id="city"
              label="City"
              placeHolder="City (Required)"
              registeredField={registry.city}
              formState={formState}
              showRequiredLabel={true}
              value={model.new.city}
              onChange={(data) => onModelChange({ ...model.new, city: data })}
              //maxlength={20}
            ></FgInput>
            <FgSelect
              id="lookupStateId"
              label="State"
              selectMessage="- Select State -"
              registeredField={registry.lookupStateId}
              formState={formState}
              showRequiredLabel={true}
              value={model.new.lookupStateId}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  lookupStateId: Number(data) || (null as any),
                })
              }
              options={(states?.data || []).map((s) => {
                return {
                  label: `${s.abbreviation} - ${s.description}`,
                  value: s.id,
                };
              })}
            ></FgSelect>
            <FgInput
              id="zipCode"
              label="Zip Code"
              placeHolder="Zip Code"
              registeredField={registry.zipCode}
              formState={formState}
              showRequiredLabel={true}
              value={model.new.zipCode}
              onChange={(data) =>
                onModelChange({ ...model.new, zipCode: data })
              }
            ></FgInput>
            <FgInput
              id="crossStreetDirections"
              label="Cross Street/Directions"
              placeHolder="Cross Street/Directions"
              registeredField={registry.crossStreetDirections}
              formState={formState}
              value={model.new.crossStreetDirections}
              onChange={(data) =>
                onModelChange({ ...model.new, crossStreetDirections: data })
              }
              //rows={5}
            ></FgInput>
          </div>
        </div>
        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSBasicLocation;
