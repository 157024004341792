import cloneDeep from 'lodash/cloneDeep';
import max from 'lodash/max';
import flatten from 'lodash/flatten';
import clone from 'lodash/clone';
import React from 'react';
import {
    AnswerDto, AnswerType,
    IsolatedTableColumnDetails,
    IsolatedTableRowDetails, OptionSelectionDetails,
    QuestionDto
} from '../../../data/DailyOperational';
import { OptionDto } from '../../../data/Option';
import { OptionService } from '../../../services/OptionService';
import { IsolatedTableRow } from './IsolatedTableRow';
import { OptionGroupDto } from '../../../data/OptionGroup';

type Props = {
    defaultItemsCount: number,
    questions: QuestionDto[],
    updateQuestionGroup: (question: QuestionDto) => void,
    optionGroups: OptionGroupDto[],
    date: Date,
    reload?: boolean,
    needsToAutoField: boolean
}

type State = {
    rows: IsolatedTableRowDetails[],
    option: Map<number, OptionDto[]>
}

export class IsolatedTableRowList extends React.Component<Props, State> { // TODO: Refactor this component and nested components to use react context instead components props passing to children
    state: State = {
        rows: new Array<IsolatedTableRowDetails>(),
        option: new Map<number, OptionDto[]>()
    }

    private async getOptions(groupId: number): Promise<OptionDto[]> {
        const optionsGroup = this.props.optionGroups.find(g => g.optionGroupId === groupId);
        return optionsGroup ? await OptionService.getGroupOptions(optionsGroup.optionGroupId) : [];
    }

    componentDidUpdate = async (prevProps: Props) => {
        if(prevProps.reload !== this.props.reload){
            this.setState({
                rows: [],
            });
            await this.fillAvailableOptions();
            this.initializeTable();
        }
        
        if (prevProps.optionGroups.length === 0 && this.props.optionGroups.length > 0) {
            await this.fillAvailableOptions();
            this.initializeTable();
        }

        if (prevProps.questions !== this.props.questions) {
            this.state.rows.forEach((row, rowIndex) => {
                row.columns.forEach((column, colIndex) => {
                    const answer = this.props.questions[colIndex].answers.find(a => a.order == rowIndex);
                    if (answer) {
                        column.optionId = answer.option?.optionId;
                        column.optionName = answer.option?.optionName;
                    }
                });

            })
        }
    }

    private initializeTable() {
        const rowsCount: number = this.getRowsCount();

        for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
            const row: IsolatedTableRowDetails = this.createNewRow(rowIndex);

            this.setState(prevState => ({
                rows: [...prevState.rows, row],
            }));

            if (rowIndex === rowsCount - 1 && row.columns.every(c => c.optionName || c.optionId)) {
                this.addNewRow();
            }
        }
    }

    private createNewRow(rowIndex: number): IsolatedTableRowDetails {
        let result: IsolatedTableRowDetails = { rowIndex: rowIndex, columns: [] };

        for (let colIndex = 0; colIndex < this.props.questions.length; colIndex++) {
            let column: IsolatedTableColumnDetails = {
                availableOptions: this.state.option.get(colIndex) ?? [],
                columnIndex: colIndex
            };

            const answer = this.props.questions[colIndex].answers.find(a => a.order == rowIndex);

            if (answer) {
                column.optionId = answer.option?.optionId;
                column.optionName = answer.option?.optionName;
            }

            result.columns.push(column);
        }
        return result;
    }

    private getRowsCount(): number {
        const answers: AnswerDto[] = flatten(this.props.questions.map(q => q.answers));

        if (answers.length === 0) return this.props.defaultItemsCount;

        const maxOrder: number = max(answers.map(a => a.order))! + 1;
        return maxOrder <= this.props.defaultItemsCount ? this.props.defaultItemsCount : maxOrder;
    }

    isIndexValid = (column: IsolatedTableColumnDetails, index: number, columnIndex: number) => {
        return column.columnIndex === columnIndex && index < column.availableOptions.length
    }

    handleSectionInputChanged = (rowIndex: number, selectionDetails: OptionSelectionDetails): void => {
        const row = this.state.rows[rowIndex];
        const column = row.columns[selectionDetails.columnIndex];
        column.optionId = selectionDetails.optionId;
        column.optionName = selectionDetails.optionName;

        this.updateItemValue(rowIndex, selectionDetails);
        this.updateQuestion(rowIndex, selectionDetails);
        

        if(this.props.needsToAutoField){
                    if ((selectionDetails.index !== undefined) && this.isIndexValid(column, selectionDetails.index, 0)) {
            if (selectionDetails.index !== -1) {
                const nextColumn = row.columns[column.columnIndex + 1];
                if(nextColumn.availableOptions[selectionDetails.index]) {
                    nextColumn.optionId = nextColumn.availableOptions[selectionDetails.index].optionId;
                    const selectionDetailsFirst: OptionSelectionDetails = {
                        columnIndex: 1,
                        index: selectionDetails.index,
                        optionId: nextColumn.availableOptions[selectionDetails.index!].optionId
                    }
                    this.updateItemValue(rowIndex, selectionDetailsFirst);
                    this.updateQuestion(rowIndex, selectionDetailsFirst);                
                }
            }
        }

        if ((selectionDetails.index !== undefined) && this.isIndexValid(column, selectionDetails.index, 1)) {
            if (selectionDetails.index !== -1) {
                const nextColumn = row.columns[column.columnIndex - 1];
                nextColumn.optionId = nextColumn.availableOptions[selectionDetails.index].optionId;
                const selectionDetailsFirst: OptionSelectionDetails = {
                    columnIndex: 0,
                    index: selectionDetails.index,
                    optionId: nextColumn.availableOptions[selectionDetails.index!].optionId,
                }
                this.updateItemValue(rowIndex, selectionDetailsFirst);
                this.updateQuestion(rowIndex, selectionDetailsFirst);
            }
        }
        }


        const lastRow = this.state.rows[this.state.rows.length - 1];
        const isLastRowFilled = lastRow.columns.every(column => column.optionId || column.optionName)

        if (isLastRowFilled) {
            this.addNewRow();
        }
    }

    private addNewRow(): void {
        const columns: IsolatedTableColumnDetails[] = [];

        for (let i = 0; i < this.props.questions.length; i++) {
            columns.push({ columnIndex: this.props.questions[i].order, availableOptions: this.state.option.get(i) ?? [] });
        }

        this.setState(prevState => ({
            rows: [...prevState.rows, { rowIndex: this.state.rows.length, columns: columns }]
        }));
    }

    private updateItemValue(rowIndex: number, selectionDetails: OptionSelectionDetails): void {
        let rows = [...this.state.rows];
        rows[rowIndex].columns[selectionDetails.columnIndex].optionName = selectionDetails.optionName;
        rows[rowIndex].columns[selectionDetails.columnIndex].optionId = selectionDetails.optionId;
    }

    private updateQuestion(rowIndex: number, selectionDetails: OptionSelectionDetails): void {
        const question: QuestionDto = cloneDeep(this.props.questions.find(q => q.order == selectionDetails.columnIndex)!);
        const existingAnswer: AnswerDto = question.answers.find(a => a.order == rowIndex)!;
        let answer: AnswerDto;

        if (existingAnswer) {
            answer = question.answers.find(a => a.order == existingAnswer.order)!;
            answer.date = this.props.date;
            answer.dateCreated = this.props.date;
            answer.dateUpdated = this.props.date;
            answer.option!.optionId = selectionDetails.optionId;
            answer.option!.optionName = selectionDetails.optionName;
        } else {
            answer = this.createNewAnswer(rowIndex, question, selectionDetails);
            question.answers.push(answer);
        }

        this.props.updateQuestionGroup(question);
    }

    private createNewAnswer(rowIndex: number, question: QuestionDto, selectionDetails: OptionSelectionDetails) {
        return {
            answerId: 0,
            date: this.props.date,
            dateCreated: this.props.date,
            dateUpdated: this.props.date,
            flagAnswerValue: false,
            order: rowIndex,
            answerType: AnswerType.Option,
            questionId: question.questionId ?? 0,
            option: { optionId: selectionDetails.optionId, optionName: selectionDetails.optionName, answerId: 0 }
        };
    }

    private async fillAvailableOptions(): Promise<void> {
        for (let i = 0; i <= this.props.questions.length; i++) {
            if (this.props.questions?.[i]) {
                const element = await this.getOptions(Number(this.props.questions?.[i].optionGroupId));
                let optionMap = cloneDeep(this.state.option);
                optionMap.set(this.props.questions?.[i].order, element);
                this.setState({
                    option: optionMap
                });
            }
        }
    }

    render() {
        return (
            <>  
                {this.state.rows.map((row, index) => {
                    return <IsolatedTableRow key={index} row={row} onChange={this.handleSectionInputChanged} />
                })}
            </>
        )
    }
}
