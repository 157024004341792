import fetchHelper from '../aps2/services/http/FetchHelper';
import {OptionDto} from '../data/Option';

class OptionService {
    async getGroupOptions(optionGroupId: number): Promise<OptionDto[]> {
        return fetchHelper.fetchUseExisting<OptionDto[]>(`/Api/Option/Options/${optionGroupId}`);
    }

    async addOption(optionDto: OptionDto): Promise<number> {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(optionDto),
        };
        const response = await fetch(`/Api/Option/Add`, options);
        return  await response.json() as number;
    }
}

const optionService = new OptionService();
export { optionService as OptionService }
