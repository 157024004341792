import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import toastStore from "../../../stores/ToastStore";
import { FgInput, FgUseForm, IBasicDialogProps } from "../../Common/FormGroups";
import FormModal from "../../Common/FormModal";

function DuplicateCourseTemplateDialog(
  props: IBasicDialogProps<{ name: string; id: any }>
) {
  const [model, setModel] = useState(
    {} as ApsModels.ICredCourseTemplateCopyDto
  );
  const [saving, setSaving] = useState(false);
  const { registry, handleSubmit, formState, setValue } = FgUseForm({
    name: {
      displayName: "Template Name",
      validation: { required: true },
    },
  });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const submit = async (form: any) => {
    setSaving(true);
    await ApsServices.http.credTemplate
      .copyCourseTemplate(model)
      .then((data) => {
        toastStore.showToast("Course Template Copied", "success");
        props.onChange &&
          props.onChange({
            name: data.name,
            id: data.id,
          });
        setSaving(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Copying Course Template", err);
        setSaving(false);
      });
  };

  useEffect(() => {
    const newName = props.data?.name ? `${props.data?.name} - Copy` : "";
    setValue("name", newName);
    setModel((p) => {
      return {
        ...p,
        name: newName,
        fromCourseId: props.data?.id,
      };
    });
    setTimeout(() => {
      document.getElementById("copy-template-name")?.focus();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title="Save as New Template"
      isOpen={true}
      close={() => close()}
      submit={handleSubmit(submit)}
      submitButtonLabel={saving ? "Saving..." : "Save"}
      closeButtonLabel="Cancel"
      disableSubmit={saving}
    >
      <div className="row">
        <div className="col-sm-12 pb-4">
          You have recently made updates to your organization's template. Would
          you like to save it as a new template?
        </div>
        <div className="col-sm-12">
          <FgInput
            id="copy-template-name"
            label="Template Name"
            placeHolder="Template Name"
            registeredField={registry.name}
            formState={formState}
            onChange={(data) => {
              setModel({
                ...model,
                name: data,
              });
            }}
            //showRequiredLabel={true}
          ></FgInput>
        </div>
      </div>
    </FormModal>
  );
}

export default DuplicateCourseTemplateDialog;
