import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { UserService } from "../../../services/UserService";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import { usePaging } from "../Common/Pagination";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import { IReportFilters } from "./ReportFilter";
import ReportFiltersAppliedDisplay from "./ReportFiltersAppliedDisplay";

const HoursCompletedLabel = (props: {
  now: number;
  max: number;
  loading: boolean;
}) => {
  return (
    <>
      {props.loading ? (
        <>
          <CommonSpinner></CommonSpinner>
        </>
      ) : (
        <>
          <h1>{commonService.toNumberWithComma(props.now)}</h1>
          completed hours out of {commonService.toNumberWithComma(props.max)}
        </>
      )}
    </>
  );
};

function ISOReportIndividualDetail(props: {
  filters: IReportFilters;
  personnelRecord: ApsModels.IIsoReportDetailItemDto;
}) {
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentId > 0) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [paging, setPaging] = usePaging(1, 100);
  // const pageChange = (page: number, pageSize: number) => {
  //   setPaging({ ...paging, page: page, pageSize: pageSize });
  // };

  const [list, setList] = useState<ApsModels.IIsoIndividualDetailReportDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && list) {
      setTimeout(() => {
        gridApi.sizeColumnsToFit();
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach((column: any, i: number) => {
          i !== 0 && allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
      }, 500);
    }
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        field: "personnelName",
        headerName: "Personnel Name",
        suppressSizeToFit: true,
        minWidth: 180,
      },
      {
        field: "personnelId",
        headerName: "Personnel ID",
        suppressSizeToFit: true,
      },
      {
        field: "rank",
        headerName: "Rank",
        suppressSizeToFit: true,
      },
      {
        field: "trainingCategories",
        headerName: "Training Category",
        suppressSizeToFit: true,
      },
      {
        field: "course",
        headerName: "Drill/Course Name",
        suppressSizeToFit: true,
      },
      {
        field: "date",
        headerName: "Date",
        suppressSizeToFit: true,
        valueFormatter: (param: any) => {
          return moment(param.data.date).format("MMM DD, YYYY");
        },
      },
      {
        field: "hours",
        headerName: "Hours",
        suppressSizeToFit: true,
      },
      {
        field: "location",
        headerName: "Location",
        suppressSizeToFit: true,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    },
    frameworkComponents: {},
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.IIsoIndividualDetailReportItemDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();
    await ApsServices.http.isoReport
      .isoIndividualDetailCoursesReport({
        userId: props.filters.personnelIds[0],
        fromDateString: moment(props.filters.fromDate).format("YYYY-MM-DD"),
        toDateString: moment(props.filters.toDate).format("YYYY-MM-DD"),
        isoReportCategoryEnums: props.filters.trainingCategories,
      })
      .then((data) => {
        setList(data);
        if (data.isoIndividualDetailReportItemDtos.length === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
        }
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Course Records", err);
        gridApi && gridApi.hideOverlay();
      });
  };

  //const [rowsSelectedCount, setRowSelectedCount] = useState(0);

  useEffect(() => {
    setGridState({
      ...gridState,
      rowData: list?.isoIndividualDetailReportItemDtos || [],
    });
    toggleColSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (gridApi) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  useEffect(() => {
    if (gridApi) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    return gridApi.getSelectedRows().filter((row) => {
      return (
        !isAnyFilterPresent ||
        !!filteredRows.find((r: any) => r.data.id === row.id)
      );
    });
  };

  const getISOReport = useNfirsGetData(async () => {
    return ApsServices.http.isoReport.userDashboardReportAsync(
      props.filters.personnelIds[0]
    );
  }, "ISO Report");

  useEffect(() => {
    getISOReport.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [exporting, setExporting] = useState(false);
  const startExport = async () => {
    setExporting(true);
    await ApsServices.http.isoReport
      .extractIsoIndividualDetailCoursesReport({
        userId: props.filters.personnelIds[0],
        isoReportCategoryEnums: props.filters.trainingCategories,
        fromDateString: moment(props.filters.fromDate).format("YYYY-MM-DD"),
        toDateString: moment(props.filters.toDate).format("YYYY-MM-DD"),
      })
      .then((data) => {
        const file = commonService.b64toBlob(data.fileContents, "text/plain");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((error) => {
        toastStore.showError("Failed ISO Individual Detail Report", error);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  return (
    <>
      <div className="row iso-report-individual-detail">
        <div className="col-sm-6 col-md-3">
          <div className="card p-4">
            <div className="flex flex-center gap-5">
              <div className="text-primary">
                <i className="fa fa-adjust fa-2x"></i>
              </div>
              <h4 className="flex-1 m-0">Company Training</h4>
            </div>
            <div className="text-center pt-3">
              <HoursCompletedLabel
                loading={getISOReport.status !== NfirsFetchStatus.Complete}
                now={getISOReport.data?.companyTraining || 0}
                max={getISOReport.data?.maxCompanyTraining || 0}
              ></HoursCompletedLabel>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="card p-4">
            <div className="flex flex-center gap-5">
              <div className="text-primary">
                <i className="fa fa-adjust fa-2x"></i>
              </div>
              <h4 className="flex-1 m-0">Facilities Training</h4>
            </div>
            <div className="text-center pt-3">
              <HoursCompletedLabel
                loading={getISOReport.status !== NfirsFetchStatus.Complete}
                now={getISOReport.data?.facilitiesTraining || 0}
                max={getISOReport.data?.maxFacilitiesTraining || 0}
              ></HoursCompletedLabel>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="card p-4">
            <div className="flex flex-center gap-5">
              <div className="text-primary">
                <i className="fa fa-adjust fa-2x"></i>
              </div>
              <h4 className="flex-1 m-0">{getISOReport.data?.displayNewDriverTraining ? "New Driver Training" : "Existing Driver Training"}</h4>
            </div>
            <div className="text-center pt-3">
              <HoursCompletedLabel
                loading={getISOReport.status !== NfirsFetchStatus.Complete}
                now={(getISOReport.data?.displayNewDriverTraining ? getISOReport.data?.newDriverTraining : getISOReport.data?.driverTraining) || 0}
                max={(getISOReport.data?.displayNewDriverTraining ? getISOReport.data?.maxNewDriverTraining : getISOReport.data?.maxDriverTraining) || 0}
              ></HoursCompletedLabel>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="card p-4">
            <div className="flex flex-center gap-5">
              <div className="text-primary">
                <i className="fa fa-adjust fa-2x"></i>
              </div>
              <h4 className="flex-1 m-0">HAZMAT Training</h4>
            </div>
            <div className="text-center pt-3">
              <HoursCompletedLabel
                loading={getISOReport.status !== NfirsFetchStatus.Complete}
                now={getISOReport.data?.hazmatTraining || 0}
                max={getISOReport.data?.maxHazmatTraining || 0}
              ></HoursCompletedLabel>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body flex flex-col p-4">
          <div className="flex flex-column h-100">
            <div className="pb-4 flex flex-wrap" style={{ fontSize: "16px" }}>
              <div className="flex-1">
                <button
                  type="button"
                  className="btn btn-outline-secondary no-wrap"
                  disabled={exporting}
                  onClick={(e) => {
                    startExport();
                  }}
                >
                  {exporting ? "Export..." : "Export to CSV"}
                </button>
              </div>
              <div className="mt-1">
                <ReportFiltersAppliedDisplay
                  filters={props.filters}
                ></ReportFiltersAppliedDisplay>
              </div>
            </div>
            <div className="flex-1">
              <div style={{ width: "100%", height: "100%" }}>
                <div
                  id="myGrid"
                  style={{
                    height: "100%",
                    minHeight: "300px",
                  }}
                  className="ag-theme-alpine flex-1"
                >
                  <AgGridReact
                    columnDefs={gridState.columnDefs}
                    defaultColDef={gridState.defaultColDef}
                    autoGroupColumnDef={gridState.autoGroupColumnDef}
                    enableRangeSelection={true}
                    animateRows={true}
                    onGridReady={onGridReady}
                    rowSelection={"multiple"}
                    rowMultiSelectWithClick={false}
                    suppressRowDeselection={true}
                    suppressRowClickSelection={true}
                    frameworkComponents={gridState.frameworkComponents}
                    rowData={gridState.rowData || []}
                    onRowDoubleClicked={(event) => {
                      //TODO
                    }}
                    overlayLoadingTemplate={gridState.overlayLoadingTemplate}
                    overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
                    // onRowSelected={() => {
                    //   setRowSelectedCount(getSelectedRows().length);
                    // }}
                    // onRowDataChanged={() => {
                    //   setRowSelectedCount(getSelectedRows().length);
                    // }}
                    // onFilterChanged={() => {
                    //   setRowSelectedCount(getSelectedRows().length);
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ISOReportIndividualDetail;
