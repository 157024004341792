import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSGenericLookupService {
  getNfirsActionTakens = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsActionTakens`;
    return fetcher.get<ApsModels.INfirsActionTakenOutputDto[]>(url);
  };

  getNfirsHazardousMaterialReleasedTypes = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsHazardousMaterialReleasedTypes`;
    return fetcher.get<
      ApsModels.INfirsHazardousMaterialReleasedTypeOutputDto[]
    >(url);
  };

  getNfirsMixedUseTypes = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsMixedUseTypes`;
    return fetcher.get<ApsModels.INfirsMixedUseTypeOutputDto[]>(url);
  };

  getNfirsPropertyUsedTypes = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsPropertyUsedTypes`;
    return fetcher.get<ApsModels.INfirsPropertyUsedTypeOutputDto[]>(url);
  };

  getNfirsApparatusTypes = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsApparatusTypes`;
    return fetcher.get<ApsModels.INfirsApparatusTypeOutputDto[]>(url);
  };

  getNfirsApparatusUseTypes = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsApparatusUseTypes`;
    return fetcher.get<ApsModels.INfirsApparatusUseTypeOutputDto[]>(url);
  };

  getNfirsOnSiteMaterialEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsOnSiteMaterialEntries`;
    return fetcher.get<ApsModels.INfirsOnSiteMaterialEntryOutputDto[]>(url);
  };

  getNfirsOnSiteMaterialsStorageUseEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsOnSiteMaterialsStorageUseEntries`;
    return fetcher.get<
      ApsModels.INfirsOnSiteMaterialStorageUseEntryOutputDto[]
    >(url);
  };

  getNfirsAreaOfOriginEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsAreaOfOriginEntries`;
    return fetcher.get<ApsModels.INfirsAreaOfOriginEntryOutputDto[]>(url);
  };

  // getNfirsHeatSourceEntries = async () => {
  //   const url = `/api/NfirsGenericLookup/GetNfirsHeatSourceEntries`;
  //   return fetcher.get<ApsModels.INfirsHeatSourceEntryOutputDto[]>(url);
  // };

  getNfirsItemFirstIgnitedEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsItemFirstIgnitedEntries`;
    return fetcher.get<ApsModels.INfirsItemFirstIgnitedEntryOutputDto[]>(url);
  };

  getNfirsTypeOfMaterialEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsTypeOfMaterialEntries`;
    return fetcher.get<ApsModels.INfirsTypeOfMaterialEntryOutputDto[]>(url);
  };

  getNfirsCauseOfIgnitionEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsCauseOfIgnitionEntries`;
    return fetcher.get<ApsModels.INfirsCauseOfIgnitionEntryOutputDto[]>(url);
  };

  getNfirsContributingFactorEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsContributingFactorEntries`;
    return fetcher.get<ApsModels.INfirsContributingFactorEntryOutputDto[]>(url);
  };

  getNfirsEquipmentTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsEquipmentTypeEntries`;
    return fetcher.get<ApsModels.INfirsEquipmentTypeEntryOutputDto[]>(url);
  };

  // getNfirsFireSuppressionEntries = async () => {
  //   const url = `/api/NfirsGenericLookup/GetNfirsFireSuppressionEntries`;
  //   return fetcher.get<ApsModels.INfirsFireSuppressionEntryOutputDto[]>(url);
  // };

  getNfirsMobilePropertyTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsMobilePropertyTypeEntries`;
    return fetcher.get<ApsModels.INfirsMobilePropertyTypeEntryOutputDto[]>(url);
  };

  getNfirsMobilePropertyMakeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsMobilePropertyMakeEntries`;
    return fetcher.get<ApsModels.INfirsMobilePropertyMakeEntryOutputDto[]>(url);
  };

  getStructureTypeEntryEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetStructureTypeEntryEntries`;
    return fetcher.get<ApsModels.INfirsStructureTypeEntryOutputDto[]>(url);
  };

  getStructureBuildingTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetStructureBuildingTypeEntries`;
    return fetcher.get<ApsModels.INfirsStructureBuildingTypeEntryOutputDto[]>(
      url
    );
  };

  getNfirsHazmatActionTakenEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsHazmatActionTakenEntries`;
    return fetcher.get<ApsModels.INfirsHazmatActionTakenEntryOutputDto[]>(url);
  };

  getNfirsHazmatUnitsEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsHazmatUnitsEntries`;
    return fetcher.get<ApsModels.INfirsHazmatUnitsEntryOutputDto[]>(url);
  };

  getNfirsHazmatCauseOfReleaseEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsHazmatCauseOfReleaseEntries`;
    return fetcher.get<ApsModels.INfirsHazmatCauseOfReleaseEntryOutputDto[]>(
      url
    );
  };

  getNfirsHazmatDotClassificationEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsHazmatDotClassificationEntries`;
    return fetcher.get<ApsModels.INfirsHazmatDotClassificationEntryOutputDto[]>(
      url
    );
  };

  getNfirsIgnitionOrReleaseEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsIgnitionOrReleaseEntries`;
    return fetcher.get<ApsModels.INfirsIgnitionOrReleaseEntryOutputDto[]>(url);
  };

  getNfirsFactorsContributingToReleaseEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsFactorsContributingToReleaseEntries`;
    return fetcher.get<
      ApsModels.INfirsFactorsContributingToReleaseEntryOutputDto[]
    >(url);
  };

  getNfirsFactorsContributingToMitigationEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsFactorsContributingToMitigationEntries`;
    return fetcher.get<
      ApsModels.INfirsFactorsContributingToMitigationEntryOutputDto[]
    >(url);
  };

  getNfirsChemicalContainerTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsChemicalContainerTypeEntries`;
    return fetcher.get<ApsModels.INfirsChemicalContainerTypeEntryOutputDto[]>(
      url
    );
  };

  getNfirsPhysicalStateEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsPhysicalStateEntries`;
    return fetcher.get<ApsModels.INfirsPhysicalStateEntryOutputDto[]>(url);
  };

  getTypeOfMaterialContributingToFlameSpreadEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetTypeOfMaterialContributingToFlameSpreadEntries`;
    return fetcher.get<
      ApsModels.INfirsTypeOfMaterialContributingToFlameSpreadEntryOutputDto[]
    >(url);
  };

  getStructureFireDetectorPresenceEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetStructureFireDetectorPresenceEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireDetectorPresenceEntryOutputDto[]
    >(url);
  };

  getStructureFireDetectorTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetStructureFireDetectorTypeEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireDetectorTypeEntryOutputDto[]
    >(url);
  };

  getStructureFireDetectorPowerSupplyEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetStructureFireDetectorPowerSupplyEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireDetectorPowerSupplyEntryOutputDto[]
    >(url);
  };

  getStructureFireDetectorOperationEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetStructureFireDetectorOperationEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireDetectorOperationEntryOutputDto[]
    >(url);
  };

  getStructureFireDetectorEffectivenessEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetStructureFireDetectorEffectivenessEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireDetectorEffectivenessEntryOutputDto[]
    >(url);
  };

  getStructureFireDetectorFailureReasonEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetStructureFireDetectorFailureReasonEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireDetectorFailureReasonEntryOutputDto[]
    >(url);
  };

  getNfirsStructureFireAesPresenceEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsStructureFireAesPresenceEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireAesPresenceEntryOutputDto[]
    >(url);
  };

  getNfirsStructureFireAesTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsStructureFireAesTypeEntries`;
    return fetcher.get<ApsModels.INfirsStructureFireAesTypeEntryOutputDto[]>(
      url
    );
  };

  getNfirsStructureFireAesOperationEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsStructureFireAesOperationEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireAesOperationEntryOutputDto[]
    >(url);
  };

  getNfirsStructureFireAesFailureReasonEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsStructureFireAesFailureReasonEntries`;
    return fetcher.get<
      ApsModels.INfirsStructureFireAesFailureReasonEntryOutputDto[]
    >(url);
  };

  getNfirsIncidentGroups = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsIncidentGroups`;
    return fetcher.get<ApsModels.INfirsIncidentGroupOutputDto[]>(url);
  };

  getNfirsIncidentTypes = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsIncidentTypes`;
    return fetcher.get<ApsModels.INfirsIncidentTypeOutputDto[]>(url);
  };

  getNfirsAidGivenReceivedEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsAidGivenReceivedEntries`;
    return fetcher.get<ApsModels.INfirsAidGivenReceivedEntryOutputDto[]>(url);
  };

  getNfirsAvailabilityOfFirstMaterialIgnitedEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsAvailabilityOfFirstMaterialIgnitedEntries`;
    return fetcher.get<
      ApsModels.INfirsAvailabilityOfFirstMaterialIgnitedEntryOutputDto[]
    >(url);
  };

  getNfirsArsonSuspectedMotivationFactorEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonSuspectedMotivationFactorEntries`;
    return fetcher.get<
      ApsModels.INfirsArsonSuspectedMotivationFactorEntryOutputDto[]
    >(url);
  };

  getNfirsArsonApparentGroupInvolvementEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonApparentGroupInvolvementEntries`;
    return fetcher.get<
      ApsModels.INfirsArsonApparentGroupInvolvementEntryOutputDto[]
    >(url);
  };

  getNfirsArsonEntryMethodEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonEntryMethodEntries`;
    return fetcher.get<ApsModels.INfirsArsonEntryMethodEntryOutputDto[]>(url);
  };

  getNfirsArsonExtentOfFireInvolvementArrivalEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonExtentOfFireInvolvementArrivalEntries`;
    return fetcher.get<
      ApsModels.INfirsArsonExtentOfFireInvolvementArrivalEntryOutputDto[]
    >(url);
  };

  getNfirsArsonIncendiaryDeviceEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonIncendiaryDeviceEntries`;
    return fetcher.get<ApsModels.INfirsArsonIncendiaryDeviceEntryOutputDto[]>(
      url
    );
  };

  getNfirsArsonIgnitionDelayDeviceEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonIgnitionDelayDeviceEntries`;
    return fetcher.get<
      ApsModels.INfirsArsonIgnitionDelayDeviceEntryOutputDto[]
    >(url);
  };

  getNfirsArsonFuelEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonFuelEntries`;
    return fetcher.get<ApsModels.INfirsArsonFuelEntryOutputDto[]>(url);
  };

  getNfirsArsonOtherInvestigationInfoEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonOtherInvestigationInfoEntries`;
    return fetcher.get<
      ApsModels.INfirsArsonOtherInvestigationInfoEntryOutputDto[]
    >(url);
  };

  getNfirsArsonInitialObservationEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonInitialObservationEntries`;
    return fetcher.get<ApsModels.INfirsArsonInitialObservationEntryOutputDto[]>(
      url
    );
  };

  getNfirsArsonLaboratoryUsedEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonLaboratoryUsedEntries`;
    return fetcher.get<ApsModels.INfirsArsonLaboratoryUsedEntryOutputDto[]>(
      url
    );
  };

  getNfirsArsonPropertyOwnershipEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsArsonPropertyOwnershipEntries`;
    return fetcher.get<ApsModels.INfirsArsonPropertyOwnershipEntryOutputDto[]>(
      url
    );
  };

  getNfirsFdReceivedAidEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsFdReceivedAidEntries`;
    return fetcher.get<ApsModels.INfirsFdReceivedAidEntryOutputDto[]>(url);
  };

  getNfirsPowerSourceEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsPowerSourceEntries`;
    return fetcher.get<ApsModels.INfirsPowerSourceEntryOutputDto[]>(url);
  };

  getNfirsJuvenileRaceEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsJuvenileRaceEntries`;
    return fetcher.get<ApsModels.INfirsJuvenileRaceEntryOutputDto[]>(url);
  };

  getNfirsJuvenileFamilyTypeEntryRaceEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsJuvenileFamilyTypeEntryRaceEntries`;
    return fetcher.get<ApsModels.INfirsJuvenileFamilyTypeEntryOutputDto[]>(url);
  };

  getNfirsJuvenileRiskFactorEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsJuvenileRiskFactorEntries`;
    return fetcher.get<ApsModels.INfirsJuvenileRiskFactorEntryOutputDto[]>(url);
  };

  getNfirsJuvenileDispositionEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsJuvenileDispositionEntries`;
    return fetcher.get<ApsModels.INfirsJuvenileDispositionEntryOutputDto[]>(
      url
    );
  };

  getNfirsLocationSubsectionEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsLocationSubsectionEntries`;
    return fetcher.get<ApsModels.INfirsLocationSubsectionEntryOutputDto[]>(url);
  };

  getNfirsMeridianEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsMeridianEntries`;
    return fetcher.get<ApsModels.INfirsMeridianEntryOutputDto[]>(url);
  };

  getNfirsAreaTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsAreaTypeEntries`;
    return fetcher.get<ApsModels.INfirsAreaTypeEntryOutputDto[]>(url);
  };

  getNfirsWildlandFireCauseEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsWildlandFireCauseEntries`;
    return fetcher.get<ApsModels.INfirsWildlandFireCauseEntryOutputDto[]>(url);
  };

  getNfirsHumanFactorsEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsHumanFactorsEntries`;
    return fetcher.get<ApsModels.INfirsHumanFactorsEntryOutputDto[]>(url);
  };

  getNfirsWeatherTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsWeatherTypeEntries`;
    return fetcher.get<ApsModels.INfirsWeatherTypeEntryOutputDto[]>(url);
  };

  getNfirsWindDirectionEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsWindDirectionEntries`;
    return fetcher.get<ApsModels.INfirsWindDirectionEntryOutputDto[]>(url);
  };

  getNfirsFuelModelEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsFuelModelEntries`;
    return fetcher.get<ApsModels.INfirsFuelModelEntryOutputDto[]>(url);
  };

  getNfirsPersonResponsibleForFireEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsPersonResponsibleForFireEntries`;
    return fetcher.get<
      ApsModels.INfirsPersonResponsibleForFireEntryOutputDto[]
    >(url);
  };

  getNfirsActivityOfPersonInvolvedEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsActivityOfPersonInvolvedEntries`;
    return fetcher.get<
      ApsModels.INfirsActivityOfPersonInvolvedEntryOutputDto[]
    >(url);
  };

  getNfirsTypeOfRightOfWayEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsTypeOfRightOfWayEntries`;
    return fetcher.get<ApsModels.INfirsTypeOfRightOfWayEntryOutputDto[]>(url);
  };

  getNfirsPositionOnSlopeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsPositionOnSlopeEntries`;
    return fetcher.get<ApsModels.INfirsPositionOnSlopeEntryOutputDto[]>(url);
  };

  getNfirsAspectEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsAspectEntries`;
    return fetcher.get<ApsModels.INfirsAspectEntryOutputDto[]>(url);
  };

  getNfirsFireDangerRatingEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsFireDangerRatingEntries`;
    return fetcher.get<ApsModels.INfirsFireDangerRatingEntryOutputDto[]>(url);
  };

  getNfirsPrivateTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsPrivateTypeEntries`;
    return fetcher.get<ApsModels.INfirsPrivateTypeEntryOutputDto[]>(url);
  };

  getNfirsPublicTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsPublicTypeEntries`;
    return fetcher.get<ApsModels.INfirsPublicTypeEntryOutputDto[]>(url);
  };

  getNfirsMobilePropertyInvolvementEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsMobilePropertyInvolvementEntries`;
    return fetcher.get<
      ApsModels.INfirsMobilePropertyInvolvementEntryOutputDto[]
    >(url);
  };

  getNfirsStreetTypes = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsStreetTypes`;
    return fetcher.get<ApsModels.INfirsStreetTypeEntryOutputDto[]>(url);
  };

  getNfirsInvolvementEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsInvolvementEntries`;
    return fetcher.get<ApsModels.INfirsInvolvementEntryOutputDto[]>(url);
  };

  getNfirsApparatusPersonnelActionTaken = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsApparatusPersonnelActionTaken`;
    return fetcher.get<ApsModels.INfirsActionTakenOutputDto[]>(url);
  };

  getNfirsBasicActionActionTaken = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsBasicActionActionTaken`;
    return fetcher.get<ApsModels.INfirsActionTakenOutputDto[]>(url);
  };

  getNfirsDetectorAlertedEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsDetectorAlertedEntries`;
    return fetcher.get<ApsModels.INfirsDetectorAlertedEntryOutputDto[]>(url);
  };

  getNfirsBasicAuthorityAssignmentTypeEntries = async () => {
    const url = `/api/NfirsGenericLookup/GetNfirsBasicAuthorityAssignmentTypeEntries`;
    return fetcher.get<
      ApsModels.INfirsBasicAuthorityAssignmentTypeEntryOutputDto[]
    >(url);
  };
}

const nfirsGenericLookupService = new NFIRSGenericLookupService();
export default nfirsGenericLookupService;
