import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSFireMobileProperty {
  get = async (reportId: any) => {
    const url = `/api/NfirsFireMobileProperty/${reportId}`;
    return fetcher.get<ApsModels.INfirsFireMobilePropertyOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsFireMobilePropertyInputDto) => {
    const url = `/api/NfirsFireMobileProperty`;
    return fetcher.post<ApsModels.INfirsFireMobilePropertyOutputDto>(url, data);
  };
}

const nfirsFireMobileProperty = new NFIRSFireMobileProperty();
export default nfirsFireMobileProperty;
