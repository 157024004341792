import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import { useDepartmentId } from "../../stores/SystemStore";
import CourseRecordList from "../CourseRecords/CourseRecordList";
import Drills from "../Drills";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import CredentialListForEmployee from "./CredentialListForEmployee";

function CredentialsViewByEmployee(props: RouteComponentProps<any>) {
  const departmentId = useDepartmentId();
  const [claims] = useState(commonService.friendlyClaims);
  const empInfo = useNfirsGetData(async () => {
    return ApsServices.http.personnel.getUserPersonnelInfo(
      props.match?.params?.employeeid || commonService.userInfo.userId
    );
  }, "Employee Info");

  useEffect(() => {
    if (departmentId.current > 0) {
      if (departmentId.changed && departmentId.previous > 0) {
        props.history.push("/credentials");
        return; //exit
      }
      empInfo.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  useEffect(() => {
    if (
      empInfo.status === NfirsFetchStatus.Failed &&
      empInfo.errorCode === 404
    ) {
      props.history.push("/credentials");
      return; //exit
    }
  }, [empInfo.status]);

  return (
    <div className="flex flex-column w-100 px-2">
      <div className="mb-2">
        {claims.credentials.canViewAll && (
          <h4 className="mb-4">View By Employee</h4>
        )}
        <div className="row mb-4 credential-employee-header">
          <div className="col col-sm-12 col-md-6 col-lg-4 flex flex-wrap">
            <div className="bg-white flex-1 credential-employee-banner">
              <div>
                <h4 className="m-0">
                  {empInfo.data?.firstName} {empInfo.data?.lastName}
                </h4>
                <div>{empInfo.data?.rankTitle}</div>
                <div>{empInfo.data?.personnelId}</div>
                <div className="employee-photo">
                  <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                </div>
              </div>
              <div>
                <div>
                  <strong className="no-wrap">Work Email</strong>
                  <div style={{ wordBreak: "break-all" }}>
                    <a href={`mailto:${empInfo.data?.email}`}>
                      {empInfo.data?.email}
                    </a>
                  </div>
                </div>
                {/* <div>
                  <strong className="no-wrap">Work Phone</strong>
                  <div>
                    {empInfo.data?.phoneNumber ? (
                      <a href={`tel:${empInfo.data?.phoneNumber}`}>
                        {empInfo.data?.phoneNumber}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
                {/* <div>
                  <strong className="no-wrap">Acting Role</strong>
                  <div>-</div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="col col-sm-12 col-lg-4 flex">
            <div className="bg-white p-4 flex-1">
              <strong>QUALIFICATION</strong>
              <div className="pt-2">
                <div className="pt-2">
                  <strong>Degree</strong>
                  <div>MBA</div>
                </div>
                <div className="pt-2">
                  <strong>Experience</strong>
                  <div>3 years</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-sm-12 col-lg-4 flex">
            <div className="bg-white p-4 flex-1">
              <strong>RECENT ACTIVITY</strong>
              <div className="pt-2">
                <div className="pt-2">
                  <strong>Last Logged:</strong> MM/DD/YYYY
                </div>
                <div className="pt-2">
                  <strong>Last Modified:</strong> MM/DD/YYYY
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {empInfo.data && empInfo.status === NfirsFetchStatus.Complete && (
        <>
          <div
            className="bg-white flex flex-column flex-1 mb-4"
            style={{ minHeight: "550px" }}
          >
            <div className="px-4 pt-4">
              <strong>CREDENTIALS</strong>
            </div>
            <CredentialListForEmployee
              {...props}
              employeeId={empInfo.data.userId}
            ></CredentialListForEmployee>
          </div>

          {claims.course.canAccess && (
            <div
              className="flex flex-column flex-1"
              style={{ minHeight: "550px" }}
            >
              <CourseRecordList
                {...props}
                employeeId={empInfo.data.userId}
                asChild={true}
              ></CourseRecordList>
            </div>
          )}

          {(claims.canReadOwn || claims.canReadAll) && (
            <div
              className="flex flex-column flex-1"
              style={{ minHeight: "550px" }}
            >
              <Drills
                {...props}
                employeeId={empInfo.data.userId}
                asChild={true}
              ></Drills>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CredentialsViewByEmployee;
