import React, { Component, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, ValueFormatterParams, SetFilterValuesFuncParams } from 'ag-grid-community';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { DepartmentService } from '../../../services/DepartmentService';
import { AgGridService } from '../../../services/AgGridService';
import { DepartmentSummaryDto } from '../../../data/Department';
import { AgGridPageSizeSelector } from '../../AgGridPageSizeSelector';
import { RouteComponentProps } from 'react-router-dom';
import { useGridFilters } from '../../../aps2/stores/SystemStore';
import commonService from '../../../aps2/services/CommonService';
import { Dropdown } from 'react-bootstrap';

class DepartmentsServerSideDatasource implements IServerSideDatasource {    
    onReady: (total: number) => void = () => {};
    constructor(onReady: (total: number)=> void) {
         this.onReady = onReady;
    }
    getRows = async (params: IServerSideGetRowsParams) => {
        const fopParameters = AgGridService.convertRequest(params.request);
        //fopParameters.paginate.take = 10000;
        const result = await DepartmentService.getSummary(fopParameters);

        // we need to change module names and order
        const modulesMap = Object.entries({
            "Dashboard": "Home",
            "User Console": "Links",
            "Daily Operational": "Daily Operational",
            "Custom IFrame": "", // Dynamically assigned
            "Assignments": "Assignments",
            "Training Portal": "Online Training",
            "Log Drills": "Drills",
            "Course Records": "Courses",
            "Credentials": "Credentials",
            "NFIRS": "NFIRS",
            "Personnel": "Personnel",
            "Data Visualization": "Data Visualization",
            "ISO Training": "Reports",
            "Templates": "Templates"
        });

        // go through each department summary
        result.items.map((value, index) => {
            const modAccessArr = value.moduleAccess.split(",");
            let orderedModules: string[] = new Array();

            // actual re-ordering and renaming
            modulesMap.map((value, index) => {
                if(value[0] === "Custom IFrame") {
                    // Module name is dynamic. Instead of "Custom IFrame", use the module name that 
                    // begins with "@"
                    const customIframeModuleName = modAccessArr.find(m => m.startsWith("@") && m.length > 1);
                    if (customIframeModuleName) {
                        orderedModules.push(customIframeModuleName.substring(1));
                    }
                } else {
                    const res = modAccessArr.find(m => m == value[0]);
                    if (res) {
                        orderedModules.push(value[1]);
                    } 
                }
            });

            value.moduleAccess = orderedModules.join(","); // set new access
        });                
        params.successCallback(result.items.map(row => {
            return {
                ...row,
                _lastModified: commonService.agGridHelpers.toExportDate(row.lastModified),
                _isActive: row.isActive ? "Active" : "Inactive"
            }
        }), result.total);            
        this.onReady(result.total);
    }
}

type CellRendererProps = {
    context: Departments,
    data: DepartmentSummaryDto,
}

class StatusCellRenderer extends Component<CellRendererProps> {
    render() {
        const isActive = this.props.data.isActive;

        return (
            <span className={`badge ${isActive ? 'badge-success' : 'badge-danger'}`}>
                {isActive ? 'Active' : 'Inactive'}
            </span>
        )
    }
}

class ActionCellRenderer extends Component<CellRendererProps> {
    onEdit = () => {
        (this.props.context as Departments).onEdit(this.props.data);
    }

    render() {
        return (
            <>
                { /* eslint-disable-next-line */ }
                <a onClick={this.onEdit} className="text-primary">
                    <FontAwesomeIcon icon={faPencilAlt} />
                </a>
            </>
        )
    }
}

type Props = RouteComponentProps & {}

type State = {
    datasource: DepartmentsServerSideDatasource,
    gridFilterProps?: any;
    gridEvent?: GridReadyEvent;
    resetKey?: string;
    exportKey?: string;
    exportAllKey?: string;    
    total?: number;
}

export class Departments extends Component<Props, State> {
    static displayName = Departments.name;
    private _gridApi?: GridApi;

    state: State = {
        datasource: new DepartmentsServerSideDatasource(total => {
            this.setState({ total: total }); 
         }),
    }

    onGridReady = (e: GridReadyEvent) => {        
        this._gridApi = e.api;
        this._gridApi?.setServerSideDatasource(this.state.datasource);
        this.setState({ gridEvent: e });
    }

    onEdit = (department: DepartmentSummaryDto) => {
        this.props.history.push(`/admin/department/${department.departmentId}`)
    }

    onAdd = () => {
        this.props.history.push("/admin/department")
    }

    onPageSizeChange = (pageSize: number) => {
        this._gridApi?.paginationSetPageSize(pageSize);
    }

    loadDepartmentValues = async (params: SetFilterValuesFuncParams) => {
        const items = await DepartmentService.getAllForFilter();
        params.success(items);
    }

    formatIsActive = (params: ValueFormatterParams) => {
        const value = params.value === 'true' ? 'Active' : 'Inactive';
        return value;
    }

    formatDate = (params: ValueFormatterParams) => {
        const value = params.value ? moment(params.value).format('MM/DD/YYYY h:mm A') : '';
        return value;
    }
    
    startExport = async () => {        
        this.setState({ exportKey: commonService.getUniqueId() });
    };

    startExportAll = () => {
        this.setState({ exportAllKey: commonService.getUniqueId() });
    }

    render() {
        return (
            <div className="container-fluid h-full">
                <div className="row">
                    <div className="col">
                        <div className="page-title-box">
                            <h4 className="mb-0 font-size-18">DEPARTMENTS</h4>
                        </div>
                    </div>
                </div>
                <div className="row h-full">
                    <div className="col">
                        <div className="card h-full">
                            <div className="card-body h-full flex flex-col">
                                <div className="flex flex-row mb-3">
                                    <div className="flex-1 me-2">
                                        {/* <button type="button" className="btn btn-outline-danger" 
                                            onClick={e => {
                                                this.setState({ resetKey: commonService.getUniqueId() });
                                            }}>
                                            Reset
                                        </button> */}
                                        <Dropdown drop="down" className="me-2">
                                            <Dropdown.Toggle
                                                variant="outline-secondary"
                                                id="dropdownExportMenu"
                                            >
                                                Export
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={(e) => {
                                                        this.startExportAll();
                                                    }}
                                                >
                                                    Export All Data
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={(e) => {
                                                        this.startExport();
                                                    }}
                                                >
                                                    Export Visible Data
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>                                        
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" onClick={this.onAdd}>
                                            Add New Department
                                        </button>
                                    </div>
                                </div>
                                <div className="ag-theme-alpine h-full flex-initial">
                                    <AgGridReact
                                        onGridReady={this.onGridReady}
                                        rowModelType="serverSide"
                                        pagination={false}
                                        paginationPageSize={10000}
                                        cacheBlockSize={10000}
                                        defaultColDef={{
                                            sortable: true,
                                            menuTabs: ['filterMenuTab'],
                                            filterParams: { buttons: ['reset'] },                                            
                                            flex: 1,
                                        }}
                                        enableCellTextSelection={true}
                                        suppressCellSelection={true}
                                        suppressMultiRangeSelection={true}
                                        suppressRowDeselection={true}
                                        suppressRowClickSelection={true}
                                        //suppressContextMenu={true}
                                        context={this}
                                        frameworkComponents={{
                                            statusCellRenderer: StatusCellRenderer,
                                            actionCellRenderer: ActionCellRenderer,
                                        }}
                                        onRowDoubleClicked={e=> {
                                            this.props.history.push(`/admin/department/${e.data.departmentId}`);                                            
                                        }}
                                        enableRangeSelection={true}
                                        rowSelection="multiple"                                        
                                        {...this.state.gridFilterProps}
                                        getContextMenuItems={params => {
                                            return commonService.agGridHelpers.getContextMenuItems(params, "departments-table");
                                        }}
                                    >
                                        <AgGridColumn
                                            headerName=""
                                            field="_selected"
                                            checkboxSelection={true}
                                            headerCheckboxSelection={true}
                                            suppressMenu={true}
                                            width={25}
                                            menuTabs={[]}
                                            {...commonService.agGridHelpers.checkboxColDef}
                                        />                                        
                                        <AgGridColumn
                                            headerName="ID"
                                            field="departmentId"
                                            suppressMenu={true}
                                            flex={0} />
                                        <AgGridColumn
                                            headerName="Department"
                                            field="name"
                                            filter="agSetColumnFilter"
                                            filterParams={{ values: this.loadDepartmentValues }}
                                            minWidth={125}
                                            flex={1} />
                                        <AgGridColumn
                                            menuTabs={[]}
                                            headerName="Access"
                                            field="moduleAccess"
                                            sortable={false}
                                            minWidth={150}
                                            flex={1} />
                                        <AgGridColumn
                                            headerName="Last Modified"
                                            field="_lastModified"
                                            filter="agDateColumnFilter"
                                            filterParams={{ filterOptions: AgGridService.defaultDateFilterOptions }}
                                            valueFormatter={param => {
                                                return this.formatDate({
                                                    ...param,
                                                    value: param.data.lastModified
                                                });
                                            }}
                                            width={180} />
                                        <AgGridColumn
                                            headerName="Status"
                                            field="_isActive"
                                            //filter="agSetColumnFilter"
                                            //filterParams={{ values: [true, false], valueFormatter: this.formatIsActive }}
                                            cellRenderer="statusCellRenderer"
                                            suppressMenu={true}
                                            width={100} />
                                        <AgGridColumn
                                            headerName="Action"
                                            cellRenderer="actionCellRenderer"
                                            sortable={false}
                                            width={100}
                                            menuTabs={[]}
                                            {...commonService.agGridHelpers.actionColPinnedDef} />
                                    </AgGridReact>
                                </div>
                                {/* <AgGridPageSizeSelector onPageSizeChange={this.onPageSizeChange} /> */}
                                
                                <div className="pt-2">
                                    {commonService.toNumberWithComma(this.state.total, 0)} Record(s) Found
                                </div>
                                
                                {this.state.gridEvent && <GridFilterRenderer 
                                        gridEvent={this.state.gridEvent}
                                        reset={this.state.resetKey}
                                        exportFlag={this.state.exportKey}
                                        exportAllFlag={this.state.exportAllKey}
                                        ready={props => {
                                        this.setState({ gridFilterProps: props });
                                }} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const GridFilterRenderer = ({ gridEvent, ready, reset, exportFlag, exportAllFlag } : 
    { gridEvent: GridReadyEvent, ready: (props: any)=> void, reset?: string, exportFlag?: string, exportAllFlag?: string }) => {
    const gridFilters = useGridFilters("departments-table", gridEvent.api, (other) => { });
    useEffect(() => {        
        ready(gridFilters.props);
    }, [])
    useEffect(() => {        
        if(gridFilters.ready) {
            gridFilters.init(gridEvent);
        }
    }, [gridFilters.ready])
    useEffect(() => {        
        if(reset) {
            gridFilters.reset();
        }
    }, [reset])
    useEffect(() => {        
        if(exportFlag) {
            gridFilters.exportCSV();
        }
    }, [exportFlag]);
    useEffect(() => {        
        if(exportAllFlag) {
            gridFilters.exportAllCSV();
        }
    }, [exportAllFlag]);
    return <></>;
}