import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  AnswerDto,
  AnswerType,
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";
import { AppContext } from "../../../../AppContext";
import { PullFromPreviousDayButton } from "../../CustomInput/PullFromPreviousDayButton";
import { PreviousDayService } from "../../../../services/PreviousDayService";

interface IGenericRadioboxProps {
  questionGroup?: QuestionGroupDto;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  date: Date;
  moduleOrder: number;
}

//interface IGenericRadioboxState {}

function GenericRadiobox(props: IGenericRadioboxProps) {
  const context = useContext(AppContext);
  //const [state, setState] = useState<Partial<IGenericRadioboxState>>({});

  const updateQuestionGroup = (newQuestionValue: QuestionDto) => {
    const questionGroup = cloneDeep(props.questionGroup)!;
    const questionIndex = questionGroup.questions.findIndex(
      (q) => q.order == newQuestionValue.order
    );
    questionGroup.questions[questionIndex] = newQuestionValue;
    props.setData(questionGroup, props.moduleOrder);
  };

  const handleBadgeBandChanged = (value: boolean): void => {
    const questionOrder = 0;
    const question: QuestionDto = cloneDeep(
      props.questionGroup!.questions.find((q) => q.order == questionOrder)!
    );
    const isAnswerExists = question.answers.length > 0;
    const answerId: number = question!.answers[0]
      ? question!.answers[0].answerId!
      : 0;

    let answer: AnswerDto;

    if (isAnswerExists || answerId > 0) {
      answer = question.answers.find((a) => a.answerId == answerId)!;
      answer.flagAnswerValue = value;
      answer.date = props.date;
      answer.dateCreated = props.date;
      answer.dateUpdated = props.date;
    } else {
      answer = {
        answerId: answerId,
        answerType: AnswerType.Flag,
        date: props.date,
        dateCreated: props.date,
        dateUpdated: props.date,
        flagAnswerValue: value,
        order: 0,
        questionId: question.questionId ?? 0,
      };
      question.answers.push(answer);
    }
    updateQuestionGroup(question);
  };

  const getFlagAnswerValue = (questionGroup: QuestionGroupDto | undefined) => {
    return (
      questionGroup?.questions
        .find((x) => x.order === 0)
        ?.answers.find((x) => x.order === 0)?.flagAnswerValue || false
    );
  };

  const isAnswerExists = (): boolean => {
    const answer = props.questionGroup?.questions
      .find((x) => x.order === 0)
      ?.answers.find((x) => x.order === 0);
    return answer !== undefined;
  };

  const handlePullFromPreviousDay = async (
    newQuestionGroupValue: QuestionGroupDto
  ) => {
    props.setData(newQuestionGroupValue, props.moduleOrder);
  };

  const value = useMemo(() => {
    return getFlagAnswerValue(props.questionGroup);
  }, [props.questionGroup]);

  useEffect(() => {
    const flagAnswerValue = getFlagAnswerValue(props.questionGroup);
    if (!isAnswerExists()) {
      handleBadgeBandChanged(flagAnswerValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="daily-operational-card card box-card">
      <div className="card-body ">
        <div className="row">
          <div className="col-sm-12 flex">
            <h4 className="mb-0 font-size-16 flex-1">
              {props.questionGroup?.name || ""}
            </h4>
            {PreviousDayService.isFeatureAvailable(props.date) && (
              <PullFromPreviousDayButton
                questionGroupId={props.questionGroup?.questionGroupId!}
                calendarDate={props.date}
                setData={handlePullFromPreviousDay}
              />
            )}
          </div>
        </div>
        <div className="mt-4">
          <div className="form-check form-check-inline mr-5">
            <input
              disabled={!context.isEditableDailyOperational}
              className="form-check-input"
              type="radio"
              name={`badgeBandOptions-${props.questionGroup?.questionGroupId}`}
              onChange={() => handleBadgeBandChanged(true)}
              id={`inlineRadio1-${props.questionGroup?.questionGroupId}`}
              checked={value}
            />
            <label
              className="form-check-label"
              htmlFor={`inlineRadio1-${props.questionGroup?.questionGroupId}`}
            >
              {
                props.questionGroup?.questions.find((x) => x.order === 0)
                  ?.questionText
              }
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              disabled={!context.isEditableDailyOperational}
              className="form-check-input"
              type="radio"
              name={`badgeBandOptions-${props.questionGroup?.questionGroupId}`}
              onChange={() => handleBadgeBandChanged(false)}
              id={`inlineRadio2-${props.questionGroup?.questionGroupId}`}
              checked={!value}
            />
            <label
              className="form-check-label"
              htmlFor={`inlineRadio2-${props.questionGroup?.questionGroupId}`}
            >
              {
                props.questionGroup?.questions.find((x) => x.order === 1)
                  ?.questionText
              }
            </label>
          </div>
        </div>
      </div>
      <div className="d-none childsection1" >
        <div className="row">
          <div className="col-sm-12 flex">
            <span className="mb-0 font-size-22 flex-1">
              <b>{props.questionGroup?.name || ""} :</b> {value ? "Yes" : "No"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenericRadiobox;
