import React, { useEffect, useState } from "react";
import commonService from "../../../../aps2/services/CommonService";
import {
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";
import { OptionGroupDto } from "../../../../data/OptionGroup";
import { PreviousDayService } from "../../../../services/PreviousDayService";
import { IsolatedTableRowList } from "../../CustomInput/IsolatedTableRowList";
import { PullFromPreviousDayButton } from "../../CustomInput/PullFromPreviousDayButton";

interface IGenericReserveApparatusProps {
  questionGroup?: QuestionGroupDto;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  date: Date;
  optionGroups: OptionGroupDto[];
  moduleOrder: number;
  needsToAutopopulate: boolean;
  columnNumber: number;
  instructions?: string;
}

interface IGenericReserveApparatusState {
  optionGroups: OptionGroupDto[];
  reload: boolean;
}

function GenericReserveApparatus(props: IGenericReserveApparatusProps) {
  const [state, setState] = useState<IGenericReserveApparatusState>({
    optionGroups: [],
    reload: false,
  });

  const intialize = async () => {
    const optionGroups: OptionGroupDto[] = props.optionGroups;
    setState((prev) => {
      return {
        ...prev,
        optionGroups: optionGroups,
      };
    });
  };

  useEffect(() => {
    intialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.optionGroups]);

  const updateQuestionGroup = (newQuestionValue: QuestionDto) => {
    const questionIndex = props.questionGroup?.questions.findIndex(
      (q) => q.questionId == newQuestionValue.questionId
    );
    if (questionIndex !== undefined) {
      props.questionGroup!.questions[questionIndex] = newQuestionValue;
      props.setData(props.questionGroup, props.moduleOrder);
    }
  };

  const handlePullFromPreviousDay = async (
    newDayQuestionGroup: QuestionGroupDto
  ) => {
    setState((prev) => {
      return {
        ...prev,
        reload: !state.reload,
      };
    });

    props.setData(newDayQuestionGroup, props.moduleOrder);
  };

  const getPropsValue = (propName: string) => {
    return ((props || ({} as any)) as { [key: string]: any })[propName];
  };

  return (
    <div className="grid-item apparatus-status card box-card flex-grow-1">
      <div className="card-body childsection3">
        <div className="row">
          <span className="mb-0 font-size-22 col-xl-11 col-10 d-none d-print-block mb-4 ">
             {<b>{props.questionGroup?.name}:</b> ?? ""}
          </span>
          <h4 className="mb-0 font-size-16 col-xl-11 col-10 d-print-none">
            {props.questionGroup?.name ?? ""}
          </h4>
          {PreviousDayService.isFeatureAvailable(props.date) && (
            <PullFromPreviousDayButton
              questionGroupId={props.questionGroup?.questionGroupId!}
              calendarDate={props.date}
              setData={handlePullFromPreviousDay}
            />
          )}
        </div>
        <div id="instructions" className="mt-3 d-print-none">{props.instructions || ""}</div>
        <div className="d-print-none">
          {[0, 1, 2, 3].map((idx) => (
            <React.Fragment key={idx}>
              <table
                className={`reserve-apparatus-status-table table mt-3  ${getPropsValue(`hideQuestion${idx}`) ? "display-none" : ""
                  }`}
              >
                <thead>
                  <tr>
                    {props.questionGroup?.questions
                      .slice(
                        idx * props.columnNumber,
                        (idx + 1) * props.columnNumber
                      )
                      .map((x, i) => {
                        return (
                          <th key={x.order} className="col w-25 no-wrap">
                            {x.questionText}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {props.questionGroup?.questions.slice(
                    idx * props.columnNumber,
                    (idx + 1) * props.columnNumber
                  ) && (

                      <IsolatedTableRowList
                        needsToAutoField={props.needsToAutopopulate}
                        optionGroups={state.optionGroups}
                        defaultItemsCount={4}
                        questions={
                          props.questionGroup.questions.slice(
                            idx * props.columnNumber,
                            (idx + 1) * props.columnNumber
                          ) as QuestionDto[]
                        }
                        updateQuestionGroup={updateQuestionGroup}
                        date={props.date}
                        reload={state.reload}
                      />
                    )}
                </tbody>
              </table>
            </React.Fragment>
          ))}
        </div>
        <div className="d-print-flex flex-wrap gap-10 d-none table-section-print">
          {[0, 1, 2, 3].map((idx) => (
            <React.Fragment key={idx}>
              <table
                className={`reserve-apparatus-status-table h-0 font-size-20 mb-2 ${getPropsValue(`hideQuestion${idx}`) ? "display-none" : ""
                  }`}
              >
                <thead>
                  <tr>
                    {props.questionGroup?.questions
                      .slice(
                        idx * props.columnNumber,
                        (idx + 1) * props.columnNumber
                      )
                      .map((x, i) => {
                        return (
                          <th key={x.order} className="col w-25 text-break">
                            {x.questionText}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {props.questionGroup?.questions.slice(
                    idx * props.columnNumber,
                    (idx + 1) * props.columnNumber
                  ) && (

                      <IsolatedTableRowList
                        needsToAutoField={props.needsToAutopopulate}
                        optionGroups={state.optionGroups}
                        defaultItemsCount={4}
                        questions={
                          props.questionGroup.questions.slice(
                            idx * props.columnNumber,
                            (idx + 1) * props.columnNumber
                          ) as QuestionDto[]
                        }
                        updateQuestionGroup={updateQuestionGroup}
                        date={props.date}
                        reload={state.reload}
                      />
                    )}
                </tbody>
              </table>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}


export default GenericReserveApparatus;
