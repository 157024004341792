import React from 'react';
import {
    IsolatedSelectItem,
    NewModelId,
    OtherModelId,
    StrikeTeamsIsolatedSelectItem
} from "../../../data/DailyOperational";
import { CreateOtherModal } from "../Modal/CreateOtherModal";
import { AppContext } from "../../../AppContext";
import './IsolatedSelect.scss';

type Props = {
    onChange: (id?: string, name?: string) => void,
    availableOptions: StrikeTeamsIsolatedSelectItem[],
    id?: string,
    name?: string,
    className?: string,
    showDefaultOptionName?: boolean
}
type State = {
    items: StrikeTeamsIsolatedSelectItem[],
    isOpenModal: boolean
}

export class StrikeTeamsReferenceSelect extends React.Component<Props, State> {
    static contextType = AppContext;
    state: State = {
        items: [],
        isOpenModal: false
    }

    private static otherOption: StrikeTeamsIsolatedSelectItem = {
        id: String(OtherModelId),
        name: 'Other'
    };

    private static newOption: StrikeTeamsIsolatedSelectItem = {
        id: String(NewModelId),
        name: ''
    };

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.availableOptions && props.availableOptions.length > 0) {
            const options = [...props.availableOptions];
            options.push(StrikeTeamsReferenceSelect.otherOption);
            options.push(StrikeTeamsReferenceSelect.newOption);
            options[options.length - 1].name = props.name ? props.name : '';

            return {
                items: options
            }
        }

        return null;
    }

    private getTemporarySelectedValue(): string | null {
        return this.state.items ? this.state.items[this.state.items.length - 1]?.name : null;
    }

    triggerModal = (): void => {
        this.setState({ isOpenModal: !this.state.isOpenModal });
    }

    handleModalSubmit = (name: string): void => {
        const items = [...this.state.items!];
        const newModelItem = items.find(i => i.id === String(NewModelId))!;
        newModelItem.name = name;

        this.setState({
            items: items
        });

        this.triggerModal();
        this.props.onChange(undefined, name);
    }

    handleModalClose = (): void => {
        this.triggerModal();

        if (!this.props.id && !this.props.name) return;

        if (this.props.id || this.props.name) {
            this.props.onChange(this.props.id, this.props.name);
        } else {
            this.props.onChange();
        }

    }

    handleOptionChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === StrikeTeamsReferenceSelect.otherOption.id) {
            this.triggerModal();
        } else {
            let items = [...this.state.items];
            items[items.length - 1].name = '';
            this.props.onChange(e.target.value);
        }
    }

    render() {
        const value = this.props.name ? StrikeTeamsReferenceSelect.newOption.id : this.props.id;
        const className = this.props.className || '';
        return (
            <>
                <select disabled={!this.context.isEditableDailyOperational}
        className={`${className} isolated-select-border custom-select-without-arrow`}
        value={value || ''}
        onChange={this.handleOptionChanged}>
        <option value="">{this.props.showDefaultOptionName ? 'Select One' : ''}</option>
        {this.state.items && this.state.items.map(o =>
            <option hidden={o.id === StrikeTeamsReferenceSelect.newOption.id} key={o.id} value={String(o.id)}>{o.name}</option>
        )}
        </select>
        <CreateOtherModal isOpen={this.state.isOpenModal} submit={this.handleModalSubmit} previousValue={this.getTemporarySelectedValue()} close={this.handleModalClose}/>
        </>
    )
    }
}
