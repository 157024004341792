import React, { Component, CSSProperties } from "react";

type Props = {
    id?: string
    title?: string
    titleStyle?: CSSProperties
    barStyle?: CSSProperties
    collapse?: boolean
    onCollapseChange?: (collapsed: boolean) => void
};

type State = {
    collapse: boolean
};

export class ExpandCollapse extends Component<Props, State>{

    state: State = {
        collapse: this.props.collapse ?? true
    };

    toggleCollapse = () => {
        if (this.props.onCollapseChange) {
            this.props.onCollapseChange(!this.state.collapse);
        }
        this.setState({ collapse: !this.state.collapse });
    }
    
    render() {
        return (
            <div className="container-fluid flex-card-container" style={this.props.barStyle}>
                <div className="flex-0">
                    <div className="headerControls">
                        <div>
                            <span className="pointer" id={this.props.id} onClick={this.toggleCollapse}>
                                <i
                                    className={`fa fa-chevron-${this.props.collapse ?? this.state.collapse ? "up" : "down"
                                        } pl-2 pr-2`}
                                ></i>
                                <div className={`pt-3 pl-2 m-0 inline-block`} style={this.props.titleStyle}>
                                    {this.props.title ?? ''}
                                </div>
                            </span>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                <div style={(this.props.collapse ?? this.state.collapse) ? { display: "none" } : {}}>
                   {this.props.children}
                </div>
            </div>
        );
    }
}

export default ExpandCollapse;