import React, { useEffect, useState } from "react";
import { ModulesDto } from "../../../data/Department";
import { DepartmentService } from "../../../services/DepartmentService";
import ApsModels from "../../models";
import { IUserClaimOptionDto, IUserClaimValueOptionOutputDto } from "../../models/_all";
import ApsServices from "../../services";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";

const UserClaims = ({
  userId,
  departmentId,
  onChange,
  ready,
  modules,
  children,
}: {
  userId: number;
  departmentId?: number;
  onChange?: (data: ApsModels.IUserSecurityOutputDto) => void;
  ready?: (data: ApsModels.IUserSecurityOutputDto) => void;
  modules?: ModulesDto[];
  children?: any;
}) => {
  const [userClaimTypes, setUserClaimTypes] = useState(
    [] as ApsModels.IUserClaimTypeOutputDto[]
  );
  const [userClaimValues, setUserClaimValues] = useState(
    [] as ApsModels.IUserClaimValueOutputDto[]
  );
  const [userSec, setUserSec] = useState(
    {} as ApsModels.IUserSecurityOutputDto
  );
  const [depModules, setDepModules] = useState([] as ModulesDto[]);

  useEffect(() => {
    const getClaimTypes = () => {
      ApsServices.http.userSecurity
        .getUserClaimTypes()
        .then((data) => {
          setUserClaimTypes(data);
        })
        .catch((err) => {
          console.log(err);
          toastStore.showError("Failed Getting User Claim Types", err);
        });
    };
    const getClaimsValues = () => {
      ApsServices.http.userSecurity
        .getUserClaimValues()
        .then((data) => {
          setUserClaimValues(data);
        })
        .catch((err) => {
          console.log(err);
          toastStore.showError("Failed Getting User Claim Values", err);
        });
    };
    const getUserSec = () => {
      ApsServices.http.userSecurity
        .get(userId)
        .then((data) => {
          setUserSec(data);
          onChange && onChange(data);
          ready && ready(data);
        })
        .catch((err) => {
          console.log(err);
          toastStore.showError("Failed Getting User Security Info", err);
        });
    };
    getClaimsValues();
    getClaimTypes();
    if (userId) {
      getUserSec();
    }

    setDepModules(modules || []);
  }, []);

  useEffect(() => {
    const getModules = async () => {
      if (departmentId) {
        try {
          const rtn = await DepartmentService.getModulesByDepartmentId(
            departmentId
          );
          setDepModules(rtn);
        } catch (error) {
          toastStore.showError("Failed getting Department Modules", error);
        }
      }
    };
    getModules();
  }, [departmentId]);

  const isSelected = (
    claimType: ApsModels.UserClaimTypeEnum,
    claimValue: ApsModels.UserClaimValueEnum
  ) => {
    if (userSec?.userClaims?.length > 0) {
      if (claimValue === ApsModels.UserClaimValueEnum.Remove) {
        if (
          !userSec.userClaims.filter((c) => c.claimType === claimType).length
        ) {
          return true;
        }
      }
      return (
        userSec.userClaims.filter(
          (c) => c.claimType === claimType && c.claimValue === claimValue
        ).length > 0
      );
    }
    if (claimValue === ApsModels.UserClaimValueEnum.Remove) {
      return true;
    }
    return false;
  };

  const loading = () => {
    if (userClaimTypes?.length > 0 && userClaimValues?.length > 0) {
      if (userId) {
        return !userSec?.userId;
      } else {
        return false;
      }
    }
    return true;
  };

  const showSection = (typ: ApsModels.UserClaimTypeEnum) => {
    if (typ === ApsModels.UserClaimTypeEnum.LogDrills) {
      return !!depModules?.find((m) => m.isSelected && m.name === "Log Drills");
    }
    if (typ === ApsModels.UserClaimTypeEnum.TrainingPortal) {
      return !!depModules?.find(
        (m) => m.isSelected && m.name === "Training Portal"
      );
    }
    if (typ === ApsModels.UserClaimTypeEnum.Personnel) {
      return !!depModules?.find((m) => m.isSelected && m.name === "Personnel");
    }
    if (typ === ApsModels.UserClaimTypeEnum.Nfirs) {
      return !!depModules?.find((m) => m.isSelected && m.name === "NFIRS");
    }
    if (
      typ === ApsModels.UserClaimTypeEnum.Dashboard ||
      typ === ApsModels.UserClaimTypeEnum.CourseRecords ||
      typ === ApsModels.UserClaimTypeEnum.CredTemplates ||
      typ === ApsModels.UserClaimTypeEnum.Credentials ||
      typ === ApsModels.UserClaimTypeEnum.DataVisualization ||
      typ === ApsModels.UserClaimTypeEnum.CustomIFrame ||
      typ === ApsModels.UserClaimTypeEnum.IsoTraining ||
      typ === ApsModels.UserClaimTypeEnum.Groups ||
      typ === ApsModels.UserClaimTypeEnum.Assignments ||
      typ === ApsModels.UserClaimTypeEnum.Notifications
    ) {
      //TODO: For now, show these modules
      return true;
    }
    return false;
  };

  const getDescription = (typ: ApsModels.UserClaimTypeEnum) => {
    if (typ === ApsModels.UserClaimTypeEnum.LogDrills) {
      return "Drills";
    }
    if (typ === ApsModels.UserClaimTypeEnum.TrainingPortal) {
      return "Online Training (LMS)";
    }
    return null;
  };

    const moduleOrder = [
    ApsModels.UserClaimTypeEnum.Dashboard,
    ApsModels.UserClaimTypeEnum.CustomIFrame,
    ApsModels.UserClaimTypeEnum.Assignments,
    ApsModels.UserClaimTypeEnum.LogDrills,
    ApsModels.UserClaimTypeEnum.CourseRecords,
    ApsModels.UserClaimTypeEnum.TrainingPortal,
    ApsModels.UserClaimTypeEnum.Credentials,
    ApsModels.UserClaimTypeEnum.Nfirs,
    ApsModels.UserClaimTypeEnum.DataVisualization,
    ApsModels.UserClaimTypeEnum.IsoTraining,
    ApsModels.UserClaimTypeEnum.Personnel,
    ApsModels.UserClaimTypeEnum.Groups,
    ApsModels.UserClaimTypeEnum.Notifications,
    ApsModels.UserClaimTypeEnum.CredTemplates,
  ];

  return (
    <>
      {loading() && <CommonSpinner />}
      {!loading() && (
        <>
          {moduleOrder
            .map((m) => {
              return (
                userClaimTypes.find((t) => t.userClaimTypeEnum === m) || {
                  userClaimTypeEnum: 0,
                  description: "",
                }
              );
            })
            .filter((t) => showSection(t.userClaimTypeEnum))
            .map((t) => {
              return {
                ...t,
                description:
                  getDescription(t.userClaimTypeEnum) || t.description,
              };
            })
            .map((t, i) => {
                let claimOptions: IUserClaimOptionDto[] | undefined;
                let claimValueOptions: IUserClaimValueOptionOutputDto[] | undefined;
                let claimValue: Number;

              return (
                <React.Fragment key={i}>
                  
                  {/* As per the wireframes, where Dashboard is the first section,
                  we're inserting Links and Daily Operations as children to UserClaims component
                  so we can render them to desired order. */}                                    
                  {i === 0 &&
                    t.userClaimTypeEnum !==
                      ApsModels.UserClaimTypeEnum.Dashboard && <>{children}</>}

                  <div>
                    <h6 className="mb-3">{t.description}</h6>
                    <div className="user-roles-container">
                      {userClaimValues
                        .filter((v) => {
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.TrainingPortal
                          ) {
                            return (
                              v.userClaimValueEnum > 0 &&
                              v.userClaimValueEnum <= 4
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.LogDrills
                          ) {
                            return (
                              v.userClaimValueEnum > 4 &&
                              v.userClaimValueEnum <= 12
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.Personnel
                          ) {
                            return v.userClaimValueEnum === 13;
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.Nfirs
                          ) {
                            return (
                              v.userClaimValueEnum > 13 &&
                              v.userClaimValueEnum <= 15
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.CourseRecords
                          ) {
                            return (
                              v.userClaimValueEnum > 15 &&
                              v.userClaimValueEnum <= 18
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.Credentials
                          ) {
                            return (
                              (v.userClaimValueEnum > 18 && v.userClaimValueEnum <= 21) || 
                              v.userClaimValueEnum === 30
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.CredTemplates
                          ) {
                            return (
                              v.userClaimValueEnum > 21 &&
                              v.userClaimValueEnum <= 22
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.Dashboard
                          ) {
                            return (
                              v.userClaimValueEnum > 22 &&
                              v.userClaimValueEnum <= 24
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.DataVisualization
                          ) {
                            return (
                              v.userClaimValueEnum > 24 &&
                              v.userClaimValueEnum <= 25
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.CustomIFrame
                          ) {
                            return (
                              v.userClaimValueEnum > 25 &&
                              v.userClaimValueEnum <= 26
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.IsoTraining
                          ) {
                            return (
                              v.userClaimValueEnum > 26 &&
                              v.userClaimValueEnum <= 27
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.Groups
                          ) {
                            return (
                              v.userClaimValueEnum > 28 &&
                              v.userClaimValueEnum <= 29
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.Assignments
                          ) {
                            return (
                              v.userClaimValueEnum > 27 &&
                              v.userClaimValueEnum <= 28
                            );
                          }
                          if (
                            t.userClaimTypeEnum ===
                            ApsModels.UserClaimTypeEnum.Notifications
                          ) {
                            return (
                              v.userClaimValueEnum > 30 &&
                              v.userClaimValueEnum <= 32
                            );
                          }                          

                          return false;
                        })
                        .concat(
                          userClaimValues.filter(
                            (v) => v.userClaimValueEnum === 0
                          )
                        )
                        .map((v, ii) => {
                            let checked: boolean = isSelected(
                                t.userClaimTypeEnum,
                                v.userClaimValueEnum
                            );

                            if (checked) {
                                claimOptions = userSec.userClaims?.find(s => s.claimValue == v.userClaimValueEnum)?.userClaimOptions;
                                claimValueOptions = v.userClaimValueOptions;
                                claimValue = v.userClaimValueEnum;
                            }

                          return (
                            <React.Fragment key={ii}>
                              <div className="custom-control custom-radio mb-3">
                                <input
                                  id={`claimValue${t.userClaimTypeEnum}-${v.userClaimValueEnum}`}
                                  name={`claimType${t.userClaimTypeEnum}`}
                                  type="radio"
                                  className="custom-control-input cursor-pointer"
                                  value={v.userClaimValueEnum}
                                  checked={checked}
                                  onChange={(e) => {
                                    let sec: ApsModels.IUserSecurityOutputDto =
                                      {
                                        ...userSec,
                                        userClaims: userSec.userClaims || [],
                                      };
                                    sec.userClaims = sec.userClaims.filter(
                                      (c) => c.claimType != t.userClaimTypeEnum
                                      );
                                      let options: ApsModels.IUserClaimOptionDto[] = []
                                      userClaimValues.find(s => s.userClaimValueEnum == Number(e.target.value))?.userClaimValueOptions.forEach((v) => {
                                          options.push({
                                              userClaimValueOptionId: v.id
                                              })
                                      })
                                    sec.userClaims.push({
                                      claimType: t.userClaimTypeEnum,
                                        claimValue: Number(e.target.value),
                                        userClaimOptions: options,
                                    });
                                    setUserSec(sec);
                                    onChange && onChange(sec);
                                  }}
                                ></input>
                                <label
                                  className="custom-control-label cursor-pointer"
                                  htmlFor={`claimValue${t.userClaimTypeEnum}-${v.userClaimValueEnum}`}
                                >
                                  {v.description}
                                </label>
                              </div>
                            </React.Fragment>
                          );
                        })}
                        {claimValueOptions?.map((v, i) => {
                            return (
                                <div className="custom-control custom-checkbox mb-3">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input cursor-pointer"
                                        id={`${v.userClaimValueEnum}-${i}`}
                                        value={v.id}
                                        checked={claimOptions?.findIndex(s => s.userClaimValueOptionId == v.id) != -1}
                                        onChange={(e) => {
                                            let sec: ApsModels.IUserSecurityOutputDto =
                                            {
                                                ...userSec,
                                                userClaims: userSec.userClaims || [],
                                            };
                                            if (e.target.checked)
                                            {
                                                sec.userClaims.find(s => s.claimValue == claimValue)?.userClaimOptions.push({
                                                    userClaimValueOptionId: Number(e.target.value)
                                                });
                                            }
                                            else {
                                                let claim: ApsModels.IUserClaimDto | undefined = sec.userClaims.find(s => s.claimValue == claimValue);
                                                sec.userClaims = sec.userClaims.filter(
                                                    (c) => c.claimValue != claimValue
                                                );
                                                let options: IUserClaimOptionDto[] | undefined = claim?.userClaimOptions.filter(
                                                    (c) => c.userClaimValueOptionId != Number(e.target.value)
                                                );
                                                if (claim != undefined && options != undefined) {
                                                    claim.userClaimOptions = options
                                                    sec.userClaims.push(claim);
                                                }
                                            }
                                            setUserSec(sec);
                                            onChange && onChange(sec);
                                        }}
                                    ></input>
                                    <label
                                        className="custom-control-label cursor-pointer"
                                        htmlFor={`${v.userClaimValueEnum}-${i}`}
                                    >
                                        {v.description}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                    <div className="mb-3"></div>
                  </div>
                  
                  {/* As per the wireframes, where Dashboard is the first section,
                  we're inserting Links and Daily Operations as children to UserClaims component
                  so we can render them to desired order. */}
                  {i === 0 &&
                    t.userClaimTypeEnum ===
                      ApsModels.UserClaimTypeEnum.Dashboard && <>{children}</>}
                </React.Fragment>
                );
            })}
        </>
      )}
    </>
  );
};

export default UserClaims;
