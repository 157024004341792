import React, { Component } from 'react';
import { RouteComponentProps } from "react-router";
import { Link } from 'react-router-dom';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AgGridPageSizeSelector } from '../AgGridPageSizeSelector';
import { GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { AgGridService } from '../../services/AgGridService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services/UserService';
import { Users } from './Users';
import { UserSummaryDto } from '../../data/User';
import { GroupDto } from '../../data/Group';
import { GroupService } from '../../services/GroupService';
import { DepartmentService } from '../../services/DepartmentService';
import moment from 'moment';
import toastStore from '../../aps2/stores/ToastStore';
import { Button } from 'react-bootstrap';
import commonService from '../../aps2/services/CommonService';

class GroupsUsersServerSideDatasource implements IServerSideDatasource {
    groupId: number;

    constructor(groupId: number){
        this.groupId = groupId;
    }
    
    getRows = async (params: IServerSideGetRowsParams) => {
        const fopParameters = AgGridService.convertRequest(params.request);        
        if(this.groupId){
            try {
                    const result = await UserService.getGroupUsersSummary(fopParameters, this.groupId);
                    params.successCallback(result.items, result.total);
                } catch (error) {
                    params.successCallback([], 0);
            }        
        }
    }
}

type CellRendererProps = {
    context: Users,
    data: UserSummaryDto,
}

class StatusCellRenderer extends Component<CellRendererProps> {
    render() {
        const isActive = this.props.data.isActive;

        return (
            <span className={`badge ${isActive ? 'badge-success' : 'badge-danger'}`}>
                {isActive ? 'Active' : 'Inactive'}
            </span>
        )
    }
}

class ActionCellRenderer extends Component<CellRendererProps> {
    onEdit = () => {
        (this.props.context as Users).onEdit(this.props.data);
    }

    render() {
        return (
            <Link to={`/admin/user/${this.props.data.userId}`}>
                { /* eslint-disable-next-line */ }
                <a onClick={this.onEdit}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                </a>
            </Link>
        )
    }
}

type ParamsId = { groupId?: string };

type Props = RouteComponentProps<ParamsId> & {}

type State = {
    name: string,
    isActive : boolean,
    lastModified: Date,
    validationErrors: ValidationErrors,
    datasource: GroupsUsersServerSideDatasource,
    showOnSearch: boolean,
    isNew: boolean
}

type ValidationErrors = {
    name?: string,
}
export class GroupAddEdit extends Component<Props, State> {
    static displayName = GroupAddEdit.name;
    private _gridApi?: GridApi;

    constructor(props : any){
        super(props);
        //this.getGroup();
    }

    state: State = {
        name: "",
        isActive : true,
        lastModified : new Date(),
        validationErrors: {},
        datasource: new GroupsUsersServerSideDatasource(parseInt(this.props.match.params.groupId!)),
        showOnSearch: false,
        isNew: true
    }

    componentDidMount(): void {
        this.setState({
            isNew: !this.props.match.params.groupId
        });
        this.getGroup();
    }

    onGridReady = (e: GridReadyEvent) => {
        this._gridApi = e.api;
        this._gridApi?.setServerSideDatasource(this.state.datasource);
    }

    loadDepartmentValues = async (params: SetFilterValuesFuncParams) => {
        const items = await DepartmentService.getAllForFilter();
        params.success(items);
    }

    formatDate = (params: ValueFormatterParams) => {
        const value = params.value ? moment(params.value).format('MM/DD/YYYY h:mm A') : '';
        return value;
    }

    formatIsActive = (params: ValueFormatterParams) => {
        const value = params.value === 'true' ? 'Active' : 'Inactive';
        return value;
    }

    onPageSizeChange = (pageSize: number) => {
        this._gridApi?.paginationSetPageSize(pageSize);
    }

    handleGroupNameChange =   (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ name: e.target.value })
        if(e.target.value === ""){
            this.validate();
        }
    }  

    handleIsActiveChange = () => {
        this.setState({ isActive: !this.state.isActive })
    }  

    handleShowOnSearchChange = () => {
        this.setState({ showOnSearch: !this.state.showOnSearch })
    }

    async getGroup() {
        if(this.props.match.params.groupId != null){
            try {
                let group : GroupDto = await GroupService.getGroup(Number(this.props.match.params.groupId));
                this.setState({
                    name : group.name,
                    isActive : group.isActive,
                    showOnSearch: group.showOnSearch           
                });                
            } catch (error) {
                this.props.history.push('/admin/groups');
            }
        }
        else {
            this.setState({
                name : "",
                isActive : true
            });
        }
    }

    cancelClose = () => {
        this.props.history.push('/admin/groups')
    }

    handleSubmit = async () => {
        if(this.validate() === false) {
            return;
        } 

        const groupDTO : GroupDto = {
            groupId : Number(this.props.match.params.groupId),
            isActive : this.state.isActive,
            name : this.state.name,
            lastModified : this.state.lastModified,
            showOnSearch: this.state.showOnSearch
        }

        await GroupService.save(groupDTO).then(data => {
            toastStore.showToast("Group Saved.", "success");
            this.props.history.push('/admin/groups' + commonService.doneUrls.submitted);
        }).catch(err => {
            toastStore.showError("Failed Saving Group", err);
        });
    }

    validate() {
        var validationErrors: ValidationErrors = {};
        if(this.state.name.trim() === ""){
            validationErrors.name = "A Name is required."
        }

        this.setState({ validationErrors: validationErrors });
        return Object.entries(validationErrors).length === 0;
    }

    render() {
        return (
            <div className="container-fluid h-full">
                <div className="row">
                    <div className="col">
                        <div className="page-title-box">
                            <h4 className="mb-0 font-size-18">{this.props.match.params.groupId ? 'EDIT GROUP' : 'ADD GROUP'}</h4>
                        </div>
                    </div>

                    <div className="col">
                        <div>
                            <button name="submit" className="btn btn-primary float-right" onClick={this.handleSubmit}>
                                Submit
                            </button>
                        </div>
                        <span className=" float-right" >&nbsp;&nbsp;</span>
                        <div>
                            <button className="btn btn-secondary float-right" onClick={this.cancelClose}>
                                Cancel and Close
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-xl-4">
                        <div className="card h-40">
                            <div className="card-body">
                                <span><b>1. Group</b></span>
                                <div className="mt-3"></div>
                                <span>Group</span>
                                <div className="mt-2"></div>
                                <input 
                                    type="text" 
                                    className ={`col-12 border rounded-10 h-8 ${this.state.validationErrors.name ? 'is-invalid' : ''}`} 
                                    onChange={this.handleGroupNameChange} 
                                    value={this.state.name}
                                    placeholder="Enter Group Name"
                                    required 
                                    disabled={!this.state.isNew}
                                />
                                {this.state.validationErrors.name && (
                                <div className="invalid-feedback">
                                    {this.state.validationErrors.name}
                                </div>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="card h-40">
                            <div className="card-body">
                                <span><b>2. Add to Participants</b></span>
                                <div className="mt-3"></div>
                                <div className="container">
                                    <div className="row">
                                        <span className="mr-2 cursor-pointer" onClick={this.handleShowOnSearchChange}>No</span>
                                        <div className="custom-control custom-switch cursor-pointer">
                                            <input 
                                            type="checkbox"
                                            className="custom-control-input cursor-pointer" 
                                            id="customSwitch2" 
                                            checked={this.state.showOnSearch} />
                                            <label className="custom-control-label cursor-pointer" htmlFor="customSwitch2" onClick={this.handleShowOnSearchChange}></label>
                                        </div>
                                        <span className="float-right cursor-pointer" onClick={this.handleShowOnSearchChange}>Yes</span>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="card h-40">
                            <div className="card-body">
                                <span><b>3. Status</b></span>
                                <div className="mt-3"></div>
                                <div className="container">
                                    <div className = "row">
                                        <span className="mr-2 cursor-pointer" onClick={this.handleIsActiveChange}>Inactive</span>
                                        <div className={'custom-control custom-switch cursor-pointer'}>
                                            <input 
                                            type="checkbox"
                                            className="custom-control-input cursor-pointer" 
                                            id="customSwitch" 
                                            checked={this.state.isActive} />
                                            <label className="custom-control-label cursor-pointer" htmlFor="customSwitch" onClick={this.handleIsActiveChange}></label>
                                        </div>
                                        <span className="float-right cursor-pointer" onClick={this.handleIsActiveChange}>Active</span>
                                    </div>                               
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className="row h-full">
                    <div className="col-12">
                        <div className={`card h-full ${this.state.isNew ? 'display-none' : ''}`}>
                            <div className="card-body h-full flex-col flex">
                                <div className="row">
                                    <div className="col">
                                        <span><b>3. Associated Users</b></span>
                                    </div>

                                    <div className="col">
                                        <div>
                                            <button
                                                name="submit"
                                                className="btn btn-primary float-right"
                                                onClick={(e) => {
                                                    this.props.history.push(`${this.props.match.params.groupId}/users`);
                                                }}
                                            >
                                                Add Users to Group
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3"></div>
                                <div className="ag-theme-alpine h-full flex-initial">
                                    <AgGridReact
                                        onGridReady={this.onGridReady}
                                        rowModelType="serverSide"
                                        pagination={true}
                                        paginationPageSize={100}
                                        defaultColDef={{
                                            sortable: true,
                                            menuTabs: ['filterMenuTab'],
                                            filterParams: { buttons: ['reset'] },
                                        }}
                                        enableCellTextSelection={true}
                                        suppressCellSelection={true}
                                        suppressContextMenu={true}
                                        context={this}
                                        frameworkComponents={{
                                            statusCellRenderer: StatusCellRenderer,
                                            actionCellRenderer: ActionCellRenderer,
                                        }}
                                    >
                                        <AgGridColumn
                                            headerName="User ID"
                                            field="userId"
                                            filter="agNumberColumnFilter"
                                            width={125} />
                                        <AgGridColumn
                                            headerName="First Name"
                                            field="firstName"
                                            filter="agTextColumnFilter"
                                            minWidth={150}
                                            flex={1} />
                                        <AgGridColumn
                                            headerName="Last Name"
                                            field="lastName"
                                            filter="agTextColumnFilter"
                                            minWidth={150}
                                            flex={1} />
                                        <AgGridColumn
                                            headerName="Department"
                                            field="department"
                                            filter="agSetColumnFilter"
                                            filterParams={{ values: this.loadDepartmentValues }}
                                            minWidth={150}
                                            flex={1} />
                                        <AgGridColumn
                                            headerName="Email"
                                            field="email"
                                            filter="agTextColumnFilter"
                                            minWidth={350}
                                            flex={1} />
                                        <AgGridColumn
                                            headerName="Last Logged In"
                                            field="lastLoggedIn"
                                            filter="agDateColumnFilter"
                                            filterParams={{ filterOptions: AgGridService.defaultDateFilterOptions }}
                                            valueFormatter={this.formatDate}
                                            width={180} />
                                        <AgGridColumn
                                            headerName="Status"
                                            field="isActive"
                                            filter="agSetColumnFilter"
                                            filterParams={{ values: [true, false], valueFormatter: this.formatIsActive }}
                                            cellRenderer="statusCellRenderer"
                                            width={100} />
                                        <AgGridColumn
                                            headerName="Action"
                                            cellRenderer="actionCellRenderer"
                                            sortable={false}
                                            width={100}
                                            menuTabs={[]} />
                                    </AgGridReact>
                                </div>
                                <AgGridPageSizeSelector onPageSizeChange={this.onPageSizeChange} />
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}