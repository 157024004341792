import ApsModels from "../../models";
import fetcher from "./Fetcher";

class UserLogDrillEligibilityService {
  get = async (id: number, userId: number) => {
    const url = `/api/UserLogDrillsEligibility/${id}/${userId}`;
    return fetcher.get<ApsModels.IUserLogDrillEligibilityOutputDto>(url);
  };

  delete = async (id: number, userId: number, concurrencyToken: string) => {
    const url = `/api/UserLogDrillsEligibility/${id}/${userId}/${concurrencyToken}`;
    return fetcher.delete<ApsModels.IUserLogDrillEligibilityOutputDto>(url);
  };

  list = async (page: number, pageSize: number, userId: number) => {
    const url = `/api/UserLogDrillsEligibility/List/${page}/${pageSize}/${userId}`;
    return fetcher.get<ApsModels.IUserLogDrillEligibilityGridDto>(url);
  };

  save = async (data: ApsModels.IUserLogDrillEligibilityInputDto) => {
    const url = "/api/UserLogDrillsEligibility/Save";
    return fetcher.post<ApsModels.IUserLogDrillEligibilityOutputDto>(url, data);
  };

  saveJacId = async (jacId: string, userId: number) => {
    const url = `/api/UserLogDrillsEligibility/JacId/${userId}/${jacId}`;
    const response = await fetch(url, {method: 'POST'});
    return response.text();
  };

  getJacId = async (userId: number) => {
    const url = `/api/UserLogDrillsEligibility/JacId/${userId}`;
    const response = await fetch(url);
    return response.text();
  };
}

const userEligibility = new UserLogDrillEligibilityService();
export default userEligibility;
