import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApsModels from "../../../../models";
import CustomTabs from "../../../Common/CustomTabs";
import { INFIRSSectionProps, useSectionTabs } from "../NFIRSEntry";
import NFIRSValidationButton from "../NFIRSValidationButton";
import NFIRSFireEquipmentInvolved from "./NFIRSFireEquipmentInvolved";
import NFIRSFireIgnition from "./NFIRSFireIgnition";
import NFIRSFireMobileProperty from "./NFIRSFireMobileProperty";
import NFIRSFireProperty from "./NFIRSFireProperty";

function NFIRSSectionFire(props: INFIRSSectionProps) {
  const { onTabSelect, activeTab, loadedTabs, changeTabStatus, getTabLabel } =
    useSectionTabs(props, "Fire", "Property");

  return (
    <>
      <NFIRSValidationButton
        module={ApsModels.NfirsValidationModulesEnum.Fire}
        onVerify={props.onVerify}
      ></NFIRSValidationButton>
      <CustomTabs onMenuTabClick={onTabSelect} activeTab={activeTab}>
        <Tabs id="nfirs-fire-page" activeKey={activeTab} onSelect={onTabSelect}>
          <Tab eventKey="Property" title={getTabLabel("Property", "Property")}>
            {loadedTabs["Property"] && (
              <div className="pt-4">
                <NFIRSFireProperty
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Property", hasChanges ? 1 : 0);
                  }}
                ></NFIRSFireProperty>
              </div>
            )}
          </Tab>
          <Tab eventKey="Ignition" title={getTabLabel("Ignition", "Ignition")}>
            {loadedTabs["Ignition"] && (
              <div className="pt-4">
                <NFIRSFireIgnition
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Ignition", hasChanges ? 1 : 0);
                  }}
                ></NFIRSFireIgnition>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="EquipmentInvolved"
            title={getTabLabel("EquipmentInvolved", "Equipment Involved")}
          >
            {loadedTabs["EquipmentInvolved"] && (
              <div className="pt-4">
                <NFIRSFireEquipmentInvolved
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("EquipmentInvolved", hasChanges ? 1 : 0);
                  }}
                ></NFIRSFireEquipmentInvolved>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="MobileProperty"
            title={getTabLabel("MobileProperty", "Mobile Property")}
          >
            {loadedTabs["MobileProperty"] && (
              <div className="pt-4">
                <NFIRSFireMobileProperty
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("MobileProperty", hasChanges ? 1 : 0);
                  }}
                ></NFIRSFireMobileProperty>
              </div>
            )}
          </Tab>
        </Tabs>
      </CustomTabs>
    </>
  );
}

export default NFIRSSectionFire;
