import React, { useEffect, useState } from "react";
import moment from "moment";
import { IChildComponentProps } from "./FormGroups";
import DatePicker, { DayValue } from "react-modern-calendar-datepicker";

const MIN_YEAR = moment().year() - 150;
const MAX_YEAR = moment().year() + 50;
const FORMAT = "MM/dd/yyyy";
const MINUTES = ["00", "15", "30", "45"];
const HOURS: string[] = [];
let cnt = 0;
while (cnt < 24) {
  let i = `${cnt}`;
  if (i.length === 1) {
    i = `0${i}`;
  }
  HOURS.push(i);
  cnt++;
}
const ONE_TO_59: string[] = [];
cnt = 0;
while (cnt < 60) {
  let i = `${cnt}`;
  if (i.length === 1) {
    i = `0${i}`;
  }
  ONE_TO_59.push(i);
  cnt++;
}
export interface IDateTimePickerProps extends IChildComponentProps<any> {
  dateOnly?: boolean;
  disabled?: boolean;
  style?: object;
  showAllMinuteOptions?: boolean;
  showSeconds?: boolean;
  onChange: (data: any, invalid?: boolean) => void;
  className?: string;
  readOnlyDate?: boolean;
}

function DateTimePickerV2(props: IDateTimePickerProps) {
  const [datePart, setDatePart] = useState(
    props?.data ? moment(props.data).format("YYYY-MM-DD") : undefined
  );
  const [hhPart, setHHPart] = useState(
    props?.data ? moment(props.data).format("HH") : "00"
  );
  const [mmPart, setMMPart] = useState(
    props?.data ? moment(props.data).format("mm") : "00"
  );
  const [ssPart, setSSPart] = useState(
    props?.data ? moment(props.data).format("ss") : "00"
  );
  const [selectedDay, setSelectedDay] = useState<DayValue>(
    props.data
      ? {
          year: moment(props.data).year(),
          month: moment(props.data).month() + 1,
          day: moment(props.data).date(),
        }
      : null
  );
  const [dateText, setDateText] = useState(
    props.data ? moment(props.data).format(FORMAT.toUpperCase()) : ""
  );
  const [firstSet, setFirstSet] = useState(false);

  const isValid = () => {
    if (dateText.length > 0) {
      if (!selectedDay) {
        return false;
      }
      return selectedDay.year >= MIN_YEAR && selectedDay.year <= MAX_YEAR;
    }
    return true;
  };

  useEffect(() => {
    if (!firstSet) {
      setFirstSet(true);
      return;
    }
    if (datePart && props?.onChange) {
      const date = moment(`${datePart}T${hhPart}:${mmPart}:${ssPart}`).toDate();
      props.onChange(date, !isValid() || undefined);
    }
  }, [datePart, hhPart, mmPart, ssPart]);

  const getDateValue = (val?: DayValue) => {
    val = val || selectedDay;
    if (val) {
      return new Date(val.year, val.month - 1, val.day, 12, 0, 0);
    }
    return new Date();
  };

  const formatInputValue = (val: DayValue | null) => {
    if (!val) return "";
    return moment(getDateValue(val)).format(FORMAT.toUpperCase());
  };

  useEffect(() => {
    if (selectedDay) {
      setDatePart(moment(getDateValue()).format("YYYY-MM-DD"));
    }
  }, [selectedDay]);

  const [inFocus, setInFocus] = useState(false);
  const rendererInput = ({ ref }: any) => {
    return (
      <input
        ref={ref}
        className="form-control modern-calendar-input"
        style={{ ...props.style }}
        placeholder={FORMAT.toUpperCase()}
        value={dateText}
        disabled={props.disabled || props.readOnlyDate || false}
        onKeyDown={(e) => {
          if (e.key.length > 1) return;
          if ("0123456789/".indexOf(e.key) === -1) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => setInFocus(true)}
        onBlur={() => setInFocus(false)}
        onChange={(e) => {
          const val = e.target.value || "";
          setDateText(val);
          const arr = val.split("/");

          if (!val.trim()) {
            props && props.onChange(null);
            setDatePart(null as any);
            setSelectedDay(null);
            return;
          } else {
            if (
              `${arr[2] || ""}`.length < 4 || //YEAR
              Number(arr[0] || 0) === 0 || //MONTH
              Number(arr[1] || 0) === 0 //DAY
            ) {
              props && props.onChange(null, true);
              setDatePart(null as any);
              setSelectedDay(null);
              return;
            }
          }

          if (arr.length === 3) {
            setSelectedDay({
              year: Number(arr[2] || moment().year()),
              month: Number(arr[0] || 1),
              day: Number(arr[1] || 1),
            });
          } else {
            setSelectedDay(null);
          }
        }}
      ></input>
    );
  };

  return (
    <div className={`apsDateTimePicker ${props.className || ""}`}>
      <div>
        <DatePicker
          value={
            selectedDay
              ? selectedDay.year < MIN_YEAR
                ? { ...selectedDay, year: MIN_YEAR }
                : selectedDay.year > MAX_YEAR
                ? { ...selectedDay, year: MAX_YEAR }
                : selectedDay
              : null
          }
          onChange={(data) => {
            setSelectedDay(data);
            setDateText(formatInputValue(data));
          }}
          shouldHighlightWeekends
          renderInput={rendererInput}
          onDisabledDayError={(e) => {}}
          selectorStartingYear={MIN_YEAR}
          selectorEndingYear={MAX_YEAR}
          wrapperClassName={`modern-calendar ${inFocus ? "focus" : ""} ${
            props.readOnlyDate ? "readonly" : ""
          }`}
          calendarClassName="responsive-calendar"
        ></DatePicker>
      </div>
      {!props.dateOnly && (
        <div>
          <div>
            <select
              className={`form-control ${!datePart ? "custom-disabled" : ""}`}
              value={hhPart}
              onChange={(e) => {
                setHHPart(e.target.value);
              }}
              disabled={props.disabled || !datePart}
            >
              {!!datePart &&
                HOURS.map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <select
              className={`form-control ${!datePart ? "custom-disabled" : ""}`}
              value={mmPart}
              onChange={(e) => {
                setMMPart(e.target.value);
              }}
              disabled={props.disabled || !datePart}
            >
              {!!datePart &&
                (props.showAllMinuteOptions ? ONE_TO_59 : MINUTES).map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
            </select>
          </div>
          {props.showSeconds && (
            <div>
              <select
                className={`form-control ${!datePart ? "custom-disabled" : ""}`}
                value={ssPart}
                onChange={(e) => {
                  setSSPart(e.target.value);
                }}
                disabled={props.disabled || !datePart}
              >
                {!!datePart &&
                  (props.showAllMinuteOptions ? ONE_TO_59 : MINUTES).map(
                    (i) => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    )
                  )}
              </select>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DateTimePickerV2;
