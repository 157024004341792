import moment from "moment";
import React from "react";
import { useEffect } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import CommonSpinner from "../../Common/CommonSpinner";
import { IBasicDialogProps } from "../../Common/FormGroups";
import FormModal from "../../Common/FormModal";
import Pagination, { usePaging } from "../../Common/Pagination";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRSHelper";

function NFIRSChangeLogDialog(
  props: IBasicDialogProps<{
    reportId: number;
    tab?: ApsModels.NfirsValidationTabsEnum;
  }>
) {
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };
  const [paging, setPaging] = usePaging(1, 10);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const list = useNfirsGetData(() => {
    return ApsServices.http.nfirsChangeLog.list(paging.page, paging.pageSize, {
      reportId: Number(props.data?.reportId || 0),
      tab: null as any,
      userId: null as any, //commonService.userInfo.userId,
    });
  }, "Change Logs");

  useEffect(() => {
    list.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  return (
    <div>
      <FormModal
        title="Change Logs"
        isOpen={true}
        close={() => close()}
        submit={() => close()}
        size="lg"
        submitButtonLabel="Done"
        primaryButtonOnly={true}
      >
        <div>
          <table className="table table-striped table-hover border">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Description</th>
              </tr>
            </thead>
            {(list.status === NfirsFetchStatus.Complete || !!list.data) && (
              <tbody>
                {(list?.data?.nfirsChangeLogOutputDtos || []).map((log) => (
                  <tr key={log.id}>
                    <td>{log.userId}</td>
                    <td>{log.personName}</td>
                    <td>{moment(log.dateChanged).format("MMM DD, YYYY")}</td>
                    <td>{moment(log.dateChanged).format("hh:mm a")}</td>
                    <td>{log.description}</td>
                    <td>
                      <i
                        className="fa fa-eye txt-primary pointer"
                        title="View"
                        onClick={() => {
                          if (props.onChange) {
                            props.onChange({
                              ...(props.data || ({} as any)),
                              tab: log.nfirsLoggingTabsEnum,
                            });
                          }
                        }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
            {!list.data && list.status === NfirsFetchStatus.InProgress && (
              <tbody>
                <tr>
                  <td className="p-4 text-center" colSpan={6}>
                    <h4 className="pt-4">&nbsp;</h4>
                  </td>
                </tr>
              </tbody>
            )}
          </table>

          {list.status === NfirsFetchStatus.InProgress && (
            <CommonSpinner overlay={true}></CommonSpinner>
          )}
        </div>

        <Pagination
          length={list?.data?.totalRecords || 0}
          page={paging.page}
          pageSize={paging.pageSize}
          pageChange={pageChange}
          sizes={[10, 20, 50, 100]}
          disabled={list.status === NfirsFetchStatus.InProgress}
        ></Pagination>
      </FormModal>
    </div>
  );
}

export default NFIRSChangeLogDialog;
