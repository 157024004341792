import { FilterOrderPaginateParameters } from '../data/FilterOrderPaginateParameters';
import { PaginatedResult } from '../data/PaginatedResult';
import { DepartmentDto, DepartmentSummaryDto, ModulesDto } from '../data/Department';
import ApsModels from "../aps2/models";
import { Observable } from '../common/Observable';

class DepartmentService {
    public readonly isDepartmentUpdated = new Observable<boolean>(false);
    public readonly modules = new Observable<ModulesDto[]>([]);
    
    getAllForFilter = async (): Promise<string[]> => {
        const response = await fetch('/Api/Department/AllForFilter');
        const value = await response.json() as string[];
        return value;
    }

    getSummary = async (params: FilterOrderPaginateParameters): Promise<PaginatedResult<DepartmentSummaryDto>> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        };

        const response = await fetch('/Api/Department/Summary', options);
        const value = await response.json() as PaginatedResult<DepartmentSummaryDto>;
        return value;
    }

    save = async (department: DepartmentDto): Promise<void> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(department),
        };

        await fetch('/Api/Department/Save', options);
        //window.location.replace("/admin/departments"); 
    }


    getModulesByDepartmentId = async (departmentId: number): Promise<ModulesDto[]> => {
        const response = await fetch(`/Api/Department/${departmentId}/Modules`);
        const value = await response.json() as ModulesDto[];
        return value;
    }

    // Get all esri dashboards associated with a specified department
    getEsriDashboardsByDepartmentId = async (departmentId: number): Promise<ApsModels.IEsriDashboardDto[]> => {
        const response = await fetch(`/Api/Department/${departmentId}/EsriDashboards`);
        const value = await response.json() as ApsModels.IEsriDashboardDto[];
        return value;
    }

    getModules = async (): Promise<ModulesDto[]> => {
        const response = await fetch("/Api/Department/Modules");
        const value = await response.json() as ModulesDto[];
        return value;
    }

    getDepartmentById = async (departmentId: number): Promise<DepartmentSummaryDto> => {
        const response = await fetch(`/Api/Department/${departmentId}`);
        const value = await response.json() as DepartmentSummaryDto;
        return value;
    }

    getDepartmentId = async (): Promise<string> => {
        const response = await fetch("/Api/Department/Current");
        const value = await response.text();
        return value;
    }

    getAllDepartments = async (): Promise<DepartmentDto[]> => {
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        const response = await fetch('/Api/Department/GetAllDepartments', options);
        const value = await response.json() as DepartmentDto[];
        return value;
    }
}

const departmentService = new DepartmentService();
export { departmentService as DepartmentService };