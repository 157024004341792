import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import toastStore from "../../../stores/ToastStore";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  IBasicDialogProps,
} from "../../Common/FormGroups";
import FormModal from "../../Common/FormModal";

function NewCredentialCategoryDialog(props: IBasicDialogProps<any>) {
  const [model, setModel] = useState({
    credCategoryType: ApsModels.CredCategoryTypeEnum.General,
  } as ApsModels.ICredCredentialCategoryEntryAddDto);
  const [saving, setSaving] = useState(false);
  const { registry, handleSubmit, formState } = FgUseForm({
    name: {
      displayName: "Description",
      validation: { required: true },
    },
    credCategoryType: {
      displayName: "Credential Category Type",
      validation: { required: true },
    },
  });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const submit = async (form: any) => {
    setSaving(true);
    await ApsServices.http.credCredential
      .addNewCategory(model)
      .then((data) => {
        toastStore.showToast("Category Saved", "success");
        props.onChange && props.onChange(data);
        setSaving(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Category", err);
        setSaving(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("description")?.focus();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title="New Credential Category"
      isOpen={true}
      close={() => close()}
      submit={(e) => {
        handleSubmit(submit)(e);
      }}
      submitButtonLabel={saving ? "Saving..." : "Save"}
      closeButtonLabel="Cancel"
      disableSubmit={saving}
    >
      <div className="row">
        <div className="col-sm-12">
          <FgInput
            id="description"
            label="Description"
            placeHolder="Description"
            registeredField={registry.name}
            formState={formState}
            onChange={(data) => {
              setModel({
                ...model,
                name: data,
              });
            }}
            showRequiredLabel={true}
          ></FgInput>
          <FgSelect
            noPlaceholder={true}
            registeredField={registry.credCategoryType}
            formState={formState}
            id={"categoryType"}
            label="Credential Category Type"
            options={[
              {
                label: "General",
                value: ApsModels.CredCategoryTypeEnum.General,
              },
              { label: "EMS", value: ApsModels.CredCategoryTypeEnum.Ems },
              // {
              //   label: "Existing External Credential",
              //   value:
              //     ApsModels.CredCategoryTypeEnum.ExistingExternalCredential,
              // },
            ]}
            onChange={(data) => {
              setModel({
                ...model,
                credCategoryType: Number(data),
              });
            }}
          />
        </div>
      </div>
    </FormModal>
  );
}

export default NewCredentialCategoryDialog;
