import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import nfirsStore from "../../../stores/NFIRSStore";
import toastStore from "../../../stores/ToastStore";
import CommonSpinner from "../../Common/CommonSpinner";
import FileUpload from "../../Common/FileUpload";
import { FgInput, FgUseForm, IBasicDialogProps } from "../../Common/FormGroups";
import FormModal from "../../Common/FormModal";

function NFIRSRemarkDialog(
  props: IBasicDialogProps<{
    reportId: number;
    section: ApsModels.NfirsRemarkSectionEnum;
    item?: ApsModels.INfirsRemarkOutputDto;
  }>
) {
  const { registry, handleSubmit, formState, setValuesFromModel } = FgUseForm({
    remarks: {
      displayName: "Remarks",
      validation: { required: true },
    },
  });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const [deleteFile, setDeleteFile] = useState(false);
  const [saving, setSaving] = useState(false);
  const submit = async (form: any) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });

    if (!props.data?.item?.id) {
      const data = {
        ...form,
        reportId: Number(props.data?.reportId),
        remarkSection: props.data?.section,
      };
      formData.append("jsonData", JSON.stringify(data));

      setSaving(true);
      await ApsServices.http.nfirsRemark
        .create(formData)
        .then((rtn) => {
          nfirsStore.setManualValidateModule("Basic");
          toastStore.showToast("Remarks added.", "success");
          close(true);
        })
        .catch((error) => {
          setSaving(false);
          toastStore.showError("Failed adding Remarks", error);
        });
      return;
    }

    const data = {
      ...model,
      ...form,
      reportId: Number(props.data?.reportId),
      isDeleteFile: deleteFile,
    };
    formData.append("jsonData", JSON.stringify(data));

    setSaving(true);
    await ApsServices.http.nfirsRemark
      .update(formData)
      .then((rtn) => {
        toastStore.showToast("Remarks saved.", "success");
        close(true);
      })
      .catch((error) => {
        setSaving(false);
        toastStore.showError("Failed saving Remarks", error);
      });
  };

  const [model, setModel] = useState({} as ApsModels.INfirsRemarkOutputDto);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<File[]>([]);

  const setModelToEdit = () => {
    if (props.data?.reportId && props.data?.item?.id) {
      setModel(props.data.item);
      setValuesFromModel(props.data.item);
    }
    setLoading(false);
  };

  useEffect(() => {
    setModelToEdit();
    setTimeout(() => {
      document.getElementById("remarks")?.focus();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FormModal
        title={props?.data?.item?.id ? "Edit Remarks" : "Add New Remarks"}
        isOpen={true}
        close={() => close()}
        submit={handleSubmit(submit)}
        size="lg"
        submitButtonLabel={
          saving
            ? "Saving..."
            : props?.data?.item?.id
            ? "Yes, Update"
            : "Yes, Add"
        }
        closeButtonLabel="No, Cancel"
        disableSubmit={loading || saving}
      >
        <div className={`p-3 ${loading ? "" : "display-none"}`}>
          <CommonSpinner></CommonSpinner>
        </div>
        {!loading && (
          <div>
            <div className="row">
              {props.data?.item?.id && (
                <div className="col-sm-12 pb-3">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <label>Remark ID:</label> {props.data.item.id}
                      <br />
                      <label>Name:</label> {props.data.item.name}
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <label>Published:</label>{" "}
                      {moment(props.data.item.datePublished).format(
                        "MMM DD, YYYY hh:mm a"
                      )}
                      <br />
                      <label>Updated:</label>{" "}
                      {moment(props.data.item.dateUpdated).format(
                        "MMM DD, YYYY hh:mm a"
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="col-sm-12">
                <FgInput
                  id="remarks"
                  label="Remarks"
                  placeHolder="Remarks"
                  registeredField={registry.remarks}
                  formState={formState}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      remarks: data,
                    });
                  }}
                  rows={8}
                  showRequiredLabel={true}
                ></FgInput>
              </div>
              <div className="col-sm-12">
                <FileUpload
                  label="Add File"
                  disabled={saving}
                  onChange={(fileList) => {
                    if (fileList.length > 1) {
                      toastStore.showToast(
                        "Please select one file only.",
                        "warning"
                      );
                      return;
                    }
                    if (fileList.length > 0) {
                      const list = [];
                      let cnt = 0;
                      while (cnt < fileList.length) {
                        const file = fileList[cnt];
                        list.push(file);
                        cnt++;
                      }
                      setFiles(list);
                      if (list[0]) {
                        setModel({
                          ...model,
                          fileName: list[0].name,
                        });
                        setDeleteFile(false);
                      }
                    }
                  }}
                />
                {!deleteFile &&
                  files?.map((f, i) => (
                    <span key={i} className="alert alert-info ml-2 p-2">
                      <span>{f.name}</span>
                      {!saving && (
                        <i
                          className="ml-2 fa fa-times pointer"
                          onClick={() => {
                            const list = [...files];
                            list.splice(i, 1);
                            setFiles(list);
                            setDeleteFile(true);
                          }}
                        ></i>
                      )}
                    </span>
                  ))}
                {!deleteFile && model?.fileName && !files?.length && (
                  <span className="alert alert-info ml-2 p-2">
                    <span>{model.fileName}</span>
                    {!saving && (
                      <i
                        className="ml-2 fa fa-times pointer"
                        onClick={() => {
                          setDeleteFile(true);
                        }}
                      ></i>
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </FormModal>
    </div>
  );
}

export default NFIRSRemarkDialog;
