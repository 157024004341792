export interface GroupSummaryDto {
    groupId: number,
    name: string,
    lastModified: Date,
    isActive: boolean,
    isNotEditable: boolean
}

export interface GroupDto {
    groupId: number,
    name: string,
    lastModified: Date,
    isActive: boolean,
    showOnSearch: boolean
}

export enum GroupSystemGeneratedEnum {
    All = 0,
    SystemGenerated = 1,
    UserCreated = 2,
}