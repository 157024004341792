import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CredCommonService {
  credentialsTypeAheadUser = async (
    data: ApsModels.ICourseRecordsUserTypeAheadInputDto
  ) => {
    const url = `/api/CredCommon/CredentialsTypeAheadUser`;
    return fetcher.post<ApsModels.ICourseRecordParticipantOutputDto[]>(
      url,
      data
    );
  };

  coursesTypeAheadUser = async (
    data: ApsModels.ICourseRecordsUserTypeAheadInputDto
  ) => {
    const url = `/api/CredCommon/CoursesTypeAheadUser`;
    return fetcher.post<ApsModels.ICourseRecordParticipantOutputDto[]>(
      url,
      data
    );
  };

  dashboardTypeAheadUser = async (
    data: ApsModels.ICourseRecordsUserTypeAheadInputDto
  ) => {
    const url = `/api/CredCommon/DashboardTypeAheadUser`;
    return fetcher.post<ApsModels.ICourseRecordParticipantOutputDto[]>(
      url,
      data
    );
  };

  credGenericEnumLookUp = async (enumName: string) => {
    const url = `/api/CredCommon/CredGenericEnumLookUp/${enumName}`;
    return fetcher.get<ApsModels.ILookupIntDto[]>(url);
  };

  credentialStatuses = async () => {
    return this.credGenericEnumLookUp("CredStatusEnum");
  };

  getTrainingType = async () => {
    const url = `/api/CredCommon/GetTrainingType`;
    return fetcher.get<ApsModels.ITrainingTypeOutputDto[]>(url);
  };

  allRanks = async () => {
    const url = `/api/CredCommon/AllRanks`;
    return fetcher.get<ApsModels.IUserCredSettingsRankOutputBaseDto[]>(url);
  };

  courseTypeAheadInstructor = async (
    data: ApsModels.ICourseInstructorsTypeAheadDto
  ) => {
    const url = `/api/CredCommon/CourseTypeAheadInstructor`;
    return fetcher.post<ApsModels.ICourseRecordParticipantOutputDto[]>(
      url,
      data
    );
  };

  getAllEmployees = async (rank?: number) => {
    const url = `/api/CredCommon/GetAllEmployees${rank ? `/${rank}` : ""}`;
    return fetcher.get<ApsModels.ICourseRecordParticipantOutputDto[]>(url);
  };
}

const credCommon = new CredCommonService();
export default credCommon;
