import { UserConsoleSectionDto } from '../data/UserConsole';
import { UserConsoleAdminSectionDto } from '../data/UserConsoleAdmin';

class UserConsoleService {
    getSummary = async (departmentId: number): Promise<UserConsoleAdminSectionDto[]> => {
        const response = await fetch(`/Api/UserConsole/Sections/${departmentId}`)
        const value = await response.json() as UserConsoleAdminSectionDto[];
        return value
    }
}

const userConsoleService = new UserConsoleService();
export { userConsoleService as UserConsoleService };