import React from 'react';
import { OptionDto } from '../../../data/Option';
import {AppContext} from "../../../AppContext";
import {IsolatedSelect} from "./IsolatedSelect";
import {IsolatedSelectItem} from "../../../data/DailyOperational";

type Props = {
    options: OptionDto[],
    enableEmptyValue?: boolean,
    optionId?: number,
    optionName?: string,
    onChange(optionId: number | undefined, name: string | undefined): void,
}
type State = {}

export class StrikeTeamOptionSelect extends React.Component<Props, State>{
    handleOptionChanged = (optionId: number | undefined, name: string | undefined) => {
        this.props.onChange(optionId, name);
    }

    render() {
        const items: IsolatedSelectItem[] = this.props.options.map(o => { return { id: o.optionId, name: o.name }});

        return (
            <IsolatedSelect onChange={this.handleOptionChanged} availableOptions={items} id={this.props.optionId} name={this.props.optionName} className="form-control"/>
        )
    }
}
