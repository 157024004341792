import { SsoService, SsoServiceDto } from "../data/SSO";

class SingleSignOnService {

    getAvailableSsoServices = async (departmentId: number) => {
        const response = await fetch(`/Api/SSO/Services/${departmentId}`);
        const ssoServiceDtos: SsoServiceDto[] = await response.json();
        const ssoServices: SsoService[] = ssoServiceDtos.map(this.convertFromSsoServiceDto);
        return ssoServices;
    }
    
    updateSsoServices = async (departmentId: number, dtos: SsoServiceDto[]) => {
        const options: RequestInit = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dtos)
        }
        await fetch(`/Api/SSO/Services/${departmentId}`, options);
    }

    convertToSsoServiceDto = (service: SsoService): SsoServiceDto => {
        return {
            ssoServiceId: service.ssoServiceId,
            ssoServiceName: service.ssoServiceName,
            ssoServiceEnabled: service.ssoServiceEnabled
        }
    }
    
    convertFromSsoServiceDto = (dto: SsoServiceDto): SsoService => {
        return {
            ssoServiceId: dto.ssoServiceId,
            ssoServiceName: dto.ssoServiceName,
            ssoServiceEnabled: dto.ssoServiceEnabled
        }
    }
}

const ssoService = new SingleSignOnService();
export { ssoService as SingleSignOnService };