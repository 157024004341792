import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSHazmatMobilePropertyService {
  get = async (reportId: any) => {
    const url = `/api/NfirsHazmatMobileProperty/${reportId}`;
    return fetcher.get<ApsModels.INfirsHazmatMobilePropertyOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsHazmatMobilePropertyInputDto) => {
    const url = `/api/NfirsHazmatMobileProperty`;
    return fetcher.post<ApsModels.INfirsHazmatMobilePropertyOutputDto>(
      url,
      data
    );
  };
}

const nfirsHazmatMobileProperty = new NFIRSHazmatMobilePropertyService();
export default nfirsHazmatMobileProperty;
