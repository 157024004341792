import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";

function NFIRSBasicProperty(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsBasicPropertyService.update(model.new);
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsBasicPropertyInputDto>({
    objectName: "Property",
    nfirsSectionTab: "Basic/Property",
    promise: saveAction,
    afterSave: (data) => {
      const oldHazMatId = model.old.nfirsHazardousMaterialReleasedId;
      const newHazMatId = data.nfirsHazardousMaterialReleasedId;
      if (
        oldHazMatId !== newHazMatId &&
        (isOtherHazMatReleased(oldHazMatId) ||
          isOtherHazMatReleased(newHazMatId))
      ) {
        nfirsStore.doManualValidateAll();
      }
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsBasicPropertyInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsBasicPropertyService
      .get(props.id)
      .then((data) => {
        const mdl = {
          ...model.new,
          ...data,
          reportId: Number(props.id),
        };

        initModels(mdl);
        setValuesFromModel(mdl);
        setProgress({ loading: false });
        props.onChange(mdl);

        setReady(true);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Property", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Basic", "Property", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Basic", "Property", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }

    if (model.new) {
      nfirsStore.setNfirsPropertyUseTypeId(model.new.nfirsPropertyUseTypeId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsPropertyUseTypeId: {
      displayName: "Property Used",
      validation: {
        required: false,
      },
    },
    nfirsHazardousMaterialReleasedId: {
      displayName: "Hazardous Material Released",
      validation: {
        required: false,
      },
    },
    nfirsMixedUsedId: {
      displayName: "Mixed Used",
      validation: {
        required: false,
      },
    },
    propertyLost: {
      displayName: "Property Lost",
      validation: {
        required: false,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
        max: 999999999,
      },
    },
    propertyValue: {
      displayName: "Property Value",
      validation: {
        required: false,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
        max: 999999999,
      },
    },
    nfirsDetectorAlertedEntryId: {
      displayName: "Detector Alerted Occupants",
      validation: {
        required: false,
      },
    },
    contentLost: {
      displayName: "Contents Lost",
      validation: {
        required: false,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
        max: 999999999,
      },
    },
    contentValue: {
      displayName: "Contents Value",
      validation: {
        required: false,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
        max: 999999999,
      },
    },
    totalPropertySaved: {
      displayName: "Total Property Saved",
      validation: {
        required: false,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
        max: 999999999,
      },
    },
  });

  const hazardousMaterials = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsHazardousMaterialReleasedTypes,
    "Harzardous Materials List"
  );
  const mixedUseList = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsMixedUseTypes,
    "Mixed Use List"
  );
  const propertyUseTypes = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getPropertyUseTypes(false),
    "Property Use Types"
  );
  const detectorAlertedOccupantsOptions = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsDetectorAlertedEntries,
    "Detector Alerted Occupants Options"
  );

  useEffect(() => {
    hazardousMaterials.getData();
    mixedUseList.getData();
    propertyUseTypes.getData();
    detectorAlertedOccupantsOptions.getData();

    setProgress({ loading: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !progress.errorCode &&
      hazardousMaterials?.status === NfirsFetchStatus.Complete &&
      mixedUseList?.status === NfirsFetchStatus.Complete &&
      propertyUseTypes?.status === NfirsFetchStatus.Complete &&
      detectorAlertedOccupantsOptions.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    hazardousMaterials.status,
    mixedUseList.status,
    propertyUseTypes.status,
    detectorAlertedOccupantsOptions.status,
  ]);

  const isOtherHazMatReleased = (id: number) => {
    return !!hazardousMaterials.data?.find(
      (item) => item.id === id && item.description === "Other"
    );
  };

  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!ready) {
      return;
    }
    if (getModel.new) {
      const id = getModel.new.nfirsHazardousMaterialReleasedId;
      nfirsStore.setCanAcccessModule(
        ApsModels.NfirsValidationModulesEnum.Hazmat,
        isOtherHazMatReleased(id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getModel.new.nfirsHazardousMaterialReleasedId, ready]);

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />

      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}

      {!progress.loading && (
        <form className="row" onSubmit={handleSubmit(submit)} ref={formRef}>
          <div className="col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <strong>Property</strong>
              </div>
            </div>

            <div className="mt-2">
              <div className="mb-3">
                <label className="required-label">Property Use</label>
                <AdvancedDropdown
                  domId="nfirsPropertyUseTypeId"
                  value={model.new.nfirsPropertyUseTypeId}
                  favoritesHandler={{
                    add: ApsServices.http.nfirsFavorite
                      .addFavoritePropertyUseType,
                    remove:
                      ApsServices.http.nfirsFavorite
                        .removeFavoritePropertyUseType,
                    afterUpdate: (list: any[]) =>
                      propertyUseTypes.setData(list),
                  }}
                  options={(propertyUseTypes.data || [])
                    .sort(commonService.sortByStringProperty("description"))
                    .map((o) => {
                      return {
                        label: `${o.description} (${o.externalId})`,
                        value: o.id,
                        originalObject: o,
                        isFavorite: o.isFavorite,
                        groupId: 0,
                        subGroupId: 0,
                      };
                    })}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      nfirsPropertyUseTypeId: Number(data) || (null as any),
                    });
                  }}
                ></AdvancedDropdown>
              </div>

              <FgSelect
                id="nfirsHazardousMaterialReleasedId"
                label="Hazardous Material Released"
                selectMessage="- Select Hazardous Material Released -"
                registeredField={registry.nfirsHazardousMaterialReleasedId}
                formState={formState}
                options={(hazardousMaterials.data || [])?.map((item) => {
                  return {
                    label: `${item.externalId} ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    nfirsHazardousMaterialReleasedId:
                      Number(data) || (null as any),
                  });
                }}
              ></FgSelect>
              <FgSelect
                id="nfirsMixedUsedId"
                label="Mixed Use"
                selectMessage="- Select Mixed Use -"
                registeredField={registry.nfirsMixedUsedId}
                formState={formState}
                options={(mixedUseList.data || [])?.map((item) => {
                  return {
                    label: `${item.externalId} ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    nfirsMixedUsedId: Number(data) || (null as any),
                  });
                }}
              ></FgSelect>

              <div className="mt-3">
                <FgSelect
                  id="nfirsDetectorAlertedEntryId"
                  label="Detector Alerted Occupants"
                  selectMessage="- Select Detector Alerted -"
                  registeredField={registry.nfirsDetectorAlertedEntryId}
                  formState={formState}
                  options={(detectorAlertedOccupantsOptions.data || [])?.map(
                    (item) => {
                      return {
                        label: `${item.description} (${item.externalId})`,
                        value: item.id,
                      };
                    }
                  )}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      nfirsDetectorAlertedEntryId:
                        Number(data) || (null as any),
                    });
                  }}
                ></FgSelect>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <strong>Estimated Values</strong>
              </div>

              <div className="col-sm-12 mt-2">
                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="propertyLost"
                      placeHolder="Property Lost"
                      label="Property Lost"
                      registeredField={registry.propertyLost}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          propertyLost: Number(data) || (null as any),
                        } as any)
                      }
                      type="number"
                      step="1"
                      prefix="$"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="propertyValue"
                      placeHolder="Property Value"
                      label="Property Value"
                      registeredField={registry.propertyValue}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          propertyValue: Number(data) || (null as any),
                        } as any)
                      }
                      type="number"
                      step="1"
                      prefix="$"
                    ></FgInput>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="contentLost"
                      placeHolder="Content Lost"
                      label="Content Lost"
                      registeredField={registry.contentLost}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          contentLost: Number(data) || (null as any),
                        } as any)
                      }
                      type="number"
                      step="1"
                      prefix="$"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="contentValue"
                      placeHolder="Content Value"
                      label="Content Value"
                      registeredField={registry.contentValue}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          contentValue: Number(data) || (null as any),
                        } as any)
                      }
                      type="number"
                      step="1"
                      prefix="$"
                    ></FgInput>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="totalPropertySaved"
                      placeHolder="Total Property Saved"
                      label="Total Property Saved"
                      registeredField={registry.totalPropertySaved}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          totalPropertySaved: Number(data) || (null as any),
                        } as any)
                      }
                      type="number"
                      step="1"
                      prefix="$"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6"></div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" hidden></button>
        </form>
      )}
    </>
  );
}

export default NFIRSBasicProperty;
