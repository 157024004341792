import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import Pagination, { usePaging } from "../Common/Pagination";
import { NfirsFetchStatus, useNfirsGetData } from "./NFIRSHelper";
import NFIRSReportQueueDialog from "./NFIRSReportQueueDialog";

function NFIRSReportQueueTable(props: { dialog?: boolean }) {
  const [paging, setPaging] = usePaging(1, props.dialog ? 10 : 5);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const reportQueueList = useNfirsGetData(async () => {
    return ApsServices.http.nfirsReport.listCalStatBatchReportQueue(
      paging.page,
      paging.pageSize
    );
  }, "CalStat Queue List");

  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    reportQueueList.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, showDialog]);

  return (
    <div>
      {showDialog && (
        <NFIRSReportQueueDialog
          onClose={() => {
            setShowDialog(false);
          }}
        ></NFIRSReportQueueDialog>
      )}
      {reportQueueList.status === NfirsFetchStatus.InProgress && (
        <CommonSpinner></CommonSpinner>
      )}
      {reportQueueList.status === NfirsFetchStatus.Complete &&
        reportQueueList.data &&
        reportQueueList.data.totalRecords > 0 && (
          <div>
            <table className="table table-sm m-0">
              <thead style={{ backgroundColor: "#eee" }}>
                <tr>
                  {props.dialog ? (
                    <>
                      <th>
                        <span className="pl-3">Month/Year</span>
                      </th>
                      <th>Requested On</th>
                      <th>Started On</th>
                      <th>Processed On</th>
                    </>
                  ) : (
                    <>
                      <th>
                        <small className="pl-3">Month/Year</small>
                      </th>
                      <th>
                        <small>Requested On</small>
                      </th>
                    </>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {reportQueueList.data.nfirsCatBatchReportGridItemsDtos.map(
                  (item) => (
                    <tr key={item.id}>
                      <td>
                        <div className="pl-3">
                          {moment()
                            .month(item.month - 1)
                            .format("MMM")}{" "}
                          {item.year}
                        </div>
                      </td>
                      {props.dialog ? (
                        <>
                          <td>
                            {item.requestedOn &&
                              moment
                                .utc(item.requestedOn)
                                .local()
                                .format("MMM DD, YYYY hh:mm a")}
                          </td>
                          <td>
                            {item.processStarted &&
                              moment
                                .utc(item.processStarted)
                                .local()
                                .format("MMM DD, YYYY hh:mm a")}
                          </td>
                          <td>
                            {item.processedOn &&
                              moment
                                .utc(item.processedOn)
                                .local()
                                .format("MMM DD, YYYY hh:mm a")}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            <small>
                              {item.requestedOn &&
                                moment
                                  .utc(item.requestedOn)
                                  .local()
                                  .format("MMM DD, YYYY hh:mm a")}
                            </small>
                          </td>
                        </>
                      )}
                      <td>
                        <div className="pr-3">
                          {item.errorMessage ? (
                            <i
                              title={item.errorMessage}
                              className="fa fa-exclamation-triangle text-danger"
                            ></i>
                          ) : (
                            <>
                              {item.processedOn ? (
                                <i
                                  className="fa fa-download text-primary pointer"
                                  onClick={(e) => {
                                    ApsServices.http.nfirsReport
                                      .downloadCalStatReport(item.id)
                                      .then((data) => {
                                        const file = commonService.b64toBlob(
                                          data.fileContents,
                                          "text/plain"
                                        );
                                        FileSaver.saveAs(
                                          file,
                                          data.fileDownloadName
                                        );
                                      })
                                      .catch((err) => {
                                        toastStore.showError(
                                          "Failed exporting reports",
                                          err
                                        );
                                      });
                                  }}
                                ></i>
                              ) : (
                                <i className="fa fa-spin fa-spinner"></i>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {props.dialog ? (
              <div className="px-2">
                <div className="flex flex-center gap-5">
                  <div className="flex-1">
                    <Pagination
                      size="sm"
                      length={reportQueueList?.data?.totalRecords || 0}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      pageChange={pageChange}
                      showingOfWhatLabel="Reports"
                      sizes={[5, 10, 20, 50]}
                    ></Pagination>
                  </div>
                  <div className="pt-3">
                    <button
                      className="btn btn-sm btn-outline-primary mr-2"
                      title="Refresh"
                      onClick={(e) => {
                        reportQueueList.getData();
                      }}
                    >
                      <i className="fa fa-sync"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-right py-2 px-3">
                <button
                  className="btn btn-sm btn-outline-primary mr-2"
                  title="Refresh"
                  onClick={(e) => {
                    reportQueueList.getData();
                  }}
                >
                  <i className="fa fa-sync"></i>
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    setShowDialog(true);
                  }}
                >
                  View More
                </button>
              </div>
            )}
          </div>
        )}
    </div>
  );
}

export default NFIRSReportQueueTable;
