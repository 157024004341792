import { cloneDeep } from 'lodash';
import React from 'react';
import {
    AnswerDto,
    AnswerType,
    QuestionDto,
    QuestionGroupDto,
    SaveTimeoutInMilliseconds
} from '../../../data/DailyOperational';
import { AppContext } from "../../../AppContext";

type Props = {
    questionGroup?: QuestionGroupDto,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    date: Date,
    moduleOrder: number
}

type State = {
    text: string,
    typingTimeout: ReturnType<typeof setTimeout> | null
}

export class CommunityRoom extends React.Component<Props, State> {
    static contextType = AppContext;

    state: State = {
        text: '',
        typingTimeout: null
    }

    componentDidMount = async () => {
        const answer: AnswerDto | undefined = this.props.questionGroup?.questions[0]?.answers[0];

        if (answer !== undefined) {
            this.setState({
                text: answer.textAnswerValue ?? ''
            });
        }
    }

    private handleTextChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            text: e.target.value,
            typingTimeout: setTimeout(this.updateAnswer, SaveTimeoutInMilliseconds)
        });
    }

    private updateAnswer = () => {
        const questionOrder = 0;
        const question: QuestionDto = cloneDeep(this.props.questionGroup!.questions.find(q => q.order == questionOrder)!);
        const isAnswerExists = question.answers.length > 0;
        const answerId: number = question!.answers[0] ? question!.answers[0].answerId! : 0;

        let answer: AnswerDto;

        if (isAnswerExists || answerId > 0) {
            answer = question.answers.find(a => a.answerId == answerId)!;
            answer.textAnswerValue = this.state.text;
            answer.date = this.props.date;
            answer.dateCreated = this.props.date;
            answer.dateUpdated = this.props.date;
        } else {
            answer = {
                answerId: answerId,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                flagAnswerValue: false,
                order: 0,
                answerType: AnswerType.Text,
                questionId: question.questionId ?? 0,
                textAnswerValue: this.state.text
            }
            question.answers.push(answer);
        }

        this.updateQuestionGroup(question);
    }

    private updateQuestionGroup(newQuestionValue: QuestionDto) {
        const questionGroup = cloneDeep(this.props.questionGroup)!;
        const questionIndex = questionGroup.questions.findIndex(q => q.order == newQuestionValue.order);
        questionGroup.questions[questionIndex] = newQuestionValue;
        this.props.setData(questionGroup, this.props.moduleOrder);
    }

    render() {
        return (
            <div className="grid-item card box-card">
                <div className="card-body">
                    <h4 className="mb-0 font-size-16">{this.props.questionGroup?.name || ''}</h4>
                    <div className="mt-4"></div>
                    <textarea disabled={!this.context.isEditableDailyOperational} className="form-control" value={this.state.text} rows={5} onChange={this.handleTextChanged} />
                </div>
            </div>
        )
    }
}
