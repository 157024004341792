export type Subscription<T> = (value: T) => void;
export type Unsubscriber = () => void;

export class Observable<T> {
    private _subscriptions: Subscription<T>[] = [];

    constructor(private _value: T) { }

    get(): T {
        return this._value;
    }

    set(value: T) {
        if (this._value !== value) {
            this._value = value;
            this._subscriptions.forEach(s => s(value))
        }
    }

    subscribe(subscriber: Subscription<T>): Unsubscriber {
        this._subscriptions.push(subscriber);
        return () => {
            this._subscriptions = this._subscriptions.filter(s => s !== subscriber);
        }
    }
}