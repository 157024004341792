import React, { Component, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ProfileMenu } from './ProfileMenu';
import './Header.scss';
import Logo from '../../assets/images/aps-logo-white-original.png';
import LogoSmall from '../../assets/images/aps-logo-white-cut.png';
import { UserService } from "../../services/UserService";
import { DepartmentService } from "../../services/DepartmentService";
import { DepartmentDto } from '../../data/Department';
import { AppContext } from '../../AppContext';
import { Unsubscriber } from '../../common/Observable';
import systemStore, { useDepartmentId } from '../../aps2/stores/SystemStore';
import { updateBreadcrumb } from '../../common/SEO';
import { IUserDepartmentRelationDto } from '../../aps2/models/_all';
import { userInfo } from 'os';
import { AuthenticationService } from '../../services/AuthenticationService';

type Props = {
    toggleSideBar: () => void;
    isSideBarVisible: boolean;
};

type State = {
    isUserAdmin: boolean,
    departmentId: number,
    departmentName: string | undefined,
    departments: DepartmentDto[] | null,
    userDeptRelations: IUserDepartmentRelationDto[] | null,
    logo: string,
    reload: boolean
};

export const DepartmentChangedNavigator = (props: { done?: () => void }) => {
    const deptId = useDepartmentId();
    const nav = useHistory();
    const [lastId, setLastId] = useState(deptId.current);    

    useEffect(()=> { 
        if(deptId) {
            if(lastId) {
                nav.replace("/home"); 
                props.done && props.done();
            }
            setLastId(deptId.current);
        }
    }, [deptId]); 
    return <></>;
}

export class Header extends Component<Props, State>{
    static displayName = Header.name;
    static contextType = AppContext;
    private _isDepartmentUpdated: Unsubscriber | null = null;

    sendMessage = (departmentId: number, name: string | undefined) => {
        UserService.currentDepartment.set(departmentId);
        UserService.currentDepartmentName.set(name);
        systemStore.setInitialDepartmentIdReady();
    }

    toogleSideBar = () => {
        this.props.toggleSideBar();
    };

    handleDepartmentOption = async () => {
        const departmentId = await DepartmentService.getDepartmentId()
        const allDepartments = await DepartmentService.getAllDepartments()
        const userDeptRelations = await UserService.getCurrUserDeptRelations()
        const name = allDepartments?.find(x => x?.departmentId === Number(departmentId))?.name
        if (departmentId) {
            this.setState({ departmentId: Number(departmentId), departmentName: name })
            this.sendMessage(Number(departmentId), name)
        }
        this.setState({ logo: allDepartments.find(x => x.departmentId === Number(departmentId))?.logo ?? "" })
        this.setState({ isUserAdmin: this.context.isAdmin, departments: allDepartments, userDeptRelations: userDeptRelations })
        updateBreadcrumb();
    }
    async componentDidMount() {
        await this.handleDepartmentOption();
    }

    state: State = {
        isUserAdmin: false,
        departmentId: 1,
        departmentName: "",
        departments: [],
        userDeptRelations: [],
        logo: "",
        reload: false
    }

    handleDepartmentChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        let value = parseInt(event.target.value);
        const name = this.state.departments?.find(x => x?.departmentId === value)?.name
        await AuthenticationService.SetDepartment(value);
        this.setState({
            departmentId: value,
            departmentName: name,
        })
        this.sendMessage(value, name)
        this.setState({ reload: true })
        updateBreadcrumb();
    }

    render() {
        // if (this.state.reload) {
        //     this.setState({ reload: false })            
        //     return <Redirect to="/" />;
        // }

        return (
            <header id="page-topbar">
                <DepartmentChangedNavigator done={() => this.setState({ reload: false })} />
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box d-flex justify-content-center align-items-center">
                            <Link to="/links" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={LogoSmall} alt="Advanced Problem Solving Logo" />
                                </span>
                                <span className="logo-lg">
                                    <img src={Logo} alt="Advanced Problem Solving Logo" />
                                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect waves-light"
                            data-toggle="collapse"
                            onClick={this.toogleSideBar}
                            data-target="#topnav-menu-content">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                        {this.state.departments?.find(x => x.departmentId === this.state.departmentId)?.logo &&
                            <div className="department-logo d-flex align-items-center">
                                <img src={this.state.departments?.find(x => x.departmentId === this.state.departmentId)?.logo} alt="Logo" />
                            </div>}
                        <div className="d-flex align-items-center font-size-16">
                            <select name="department"
                                value={this.state.departmentId}
                                className="col-12 rounded-10 h-8 font-size-22"
                                onChange={this.handleDepartmentChange}>
                                {this.state.isUserAdmin
                                    ? this.state.departments?.map((x, i) => {
                                        return (
                                            <option key={x.departmentId} value={x.departmentId}>{!x ? null : x.name}</option>
                                        )
                                    })
                                    : this.state.departments?.map((x, i) => {
                                        if (this.state.userDeptRelations?.find(d => d.departmentId == x.departmentId)) {
                                            return (
                                                <option key={x.departmentId} value={x.departmentId}>{!x ? null : x.name}</option>
                                            )
                                        }
                                    })
                                }
                            </select>
                        </div>
                        {/*this.state.isUserAdmin ?
                            <div className="d-flex align-items-center font-size-16">
                                <select name="department"
                                    value={this.state.departmentId}
                                    className="col-12 rounded-10 h-8 font-size-22"
                                    onChange={this.handleDepartmentChange}>
                                    {this.state.departments?.map((x, i) => {
                                        return (
                                            <option key={x.departmentId} value={x.departmentId}>{!x ? null : x.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            :
                            <div className="d-flex align-items-center font-size-16">
                                <span>{this.state.departmentName}</span>
                            </div>
                        */}
                    </div>
                    <div className="d-flex">
                        <ProfileMenu />
                    </div>
                </div>
            </header>
        );
    }
}
