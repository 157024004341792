import ApsServices from "../../aps2/services";
import commonService from "../../aps2/services/CommonService";
import toastStore from "../../aps2/stores/ToastStore";
import { QuestionDto, QuestionGroupDto } from "../../data/DailyOperational";
import { OptionGroupDto } from "../../data/OptionGroup";
import { BuiltInDOCComponents } from "./ComponentGalleryDefinitions";
import { DOCLayoutColumn, DOCLayoutRow } from "./DailyOperationalDesigner";

export const DefaultLayoutColumnIds = {
  optionsBC: "col1",
  badgeBnd: "col2",
  flags: "col3",
  shiftActivity: "col4",
  wildlandStatus: "col5",
  strikeTeams: "col6",
  onCallList: "col7",
  usrStatus: "col8",
  usrResponse: "col9",
  additionalEmsResponse: "col10",
  reserveApparatusStatus: "col11",
  communityRiskReduction: "col12",
  emergencyOperationsCenter: "col13",
};

export default class DefaultLayoutInitializer {
  private formId = 0;
  private orderCnt = -1;
  private optionGroups: OptionGroupDto[] = [];

  private newQuestionGroup(name: string) {
    this.orderCnt = this.orderCnt + 1;
    return {
      name: name,
      formId: this.formId,
      order: this.orderCnt,
      questionGroupId: undefined as any,
      questions: [],
    } as QuestionGroupDto;
  }

  private newQuestion(order: number, groupId: number, text: string) {
    return {
      order: order,
      questionId: undefined as any,
      questionGroupId: groupId,
      questionText: text,
      answers: [],
    } as QuestionDto;
  }

  constructor(
    formId: number,
    startOrder: number,
    optionGroups?: OptionGroupDto[]
  ) {
    this.formId = formId;
    this.orderCnt = startOrder;
    this.optionGroups = optionGroups || [];
  }

  getDefaultLayout = () => {
    return {
      //APS106-23: Default Layout Based on Monterey Park FD
      rows: [
        new DOCLayoutRow([
          new DOCLayoutColumn(
            undefined,
            undefined,
            DefaultLayoutColumnIds.optionsBC
          ),
          new DOCLayoutColumn(
            undefined,
            undefined,
            DefaultLayoutColumnIds.badgeBnd
          ),
          new DOCLayoutColumn(
            undefined,
            undefined,
            DefaultLayoutColumnIds.flags
          ),
        ]),
        new DOCLayoutRow([
          new DOCLayoutColumn(
            undefined,
            undefined,
            DefaultLayoutColumnIds.shiftActivity
          ),
        ]),
        new DOCLayoutRow([
          new DOCLayoutColumn(
            [
              {
                id: commonService.getUniqueId(),
                type: "core-layout",
                data: {
                  rows: [
                    new DOCLayoutRow([
                      new DOCLayoutColumn(
                        undefined,
                        undefined,
                        DefaultLayoutColumnIds.wildlandStatus
                      ),
                      new DOCLayoutColumn([
                        {
                          id: commonService.getUniqueId(),
                          type: "core-layout",
                          data: {
                            rows: [
                              new DOCLayoutRow([
                                new DOCLayoutColumn(
                                  undefined,
                                  undefined,
                                  DefaultLayoutColumnIds.strikeTeams
                                ),
                              ]),
                              new DOCLayoutRow([
                                new DOCLayoutColumn(
                                  undefined,
                                  undefined,
                                  DefaultLayoutColumnIds.onCallList
                                ),
                              ]),
                            ],
                          },
                        },
                      ]),
                    ]),
                    new DOCLayoutRow([
                      new DOCLayoutColumn(
                        undefined,
                        undefined,
                        DefaultLayoutColumnIds.usrStatus
                      ),
                    ]),
                    new DOCLayoutRow([
                      new DOCLayoutColumn(
                        undefined,
                        undefined,
                        DefaultLayoutColumnIds.usrResponse
                      ),
                    ]),
                    new DOCLayoutRow([
                      new DOCLayoutColumn(
                        undefined,
                        undefined,
                        DefaultLayoutColumnIds.additionalEmsResponse
                      ),
                    ]),
                  ],
                },
              },
            ],
            2 //Col-Span
          ),
          new DOCLayoutColumn(
            undefined,
            undefined,
            DefaultLayoutColumnIds.reserveApparatusStatus
          ),
        ]),
        new DOCLayoutRow([
          new DOCLayoutColumn(
            undefined,
            undefined,
            DefaultLayoutColumnIds.communityRiskReduction
          ),
          new DOCLayoutColumn(
            undefined,
            undefined,
            DefaultLayoutColumnIds.emergencyOperationsCenter
          ),
        ]),
      ],
    };
  };

  private createQuestions = async (
    component: string,
    label: string,
    questionLables?: string[]
  ) => {
    const compObj = BuiltInDOCComponents.find((b) => b.id === component);
    if (compObj) {
      //Create Question Group
      const questionGroup = await ApsServices.http.questionGroup
        .save(this.newQuestionGroup(label))
        .then((data) => data)
        .catch((error) => {
          toastStore.showError(`Failed Saving Question Group`, error);
        });
      if (questionGroup) {
        const compQtns = compObj.questionGroup?.questions || [];
        const questions: QuestionDto[] = [];

        //Create Question(s)
        for (let idx = 0; idx < compQtns.length; idx++) {
          const def = compQtns[idx];
          let optionGroupId = 0;

          //Create Options Group if required from definitions
          if (def.optionGroupName) {
            optionGroupId =
              this.optionGroups.find((x) => x.name === def.optionGroupName)
                ?.optionGroupId || 0;

            if (!optionGroupId) {
              const optGrp = await ApsServices.http.optionGroup
                .save({ name: def.optionGroupName } as any)
                .then((data) => data)
                .catch((error) => {
                  toastStore.showError(`Failed Saving Option Group`, error);
                });
              if (optGrp) {
                optionGroupId = optGrp.optionGroupId;
              }
            } else {
              console.log(`EXISTING OPTION GROUP: ${def.optionGroupName}`);
            }
          }

          const mdl = this.newQuestion(
            def.order,
            questionGroup.questionGroupId,
            (questionLables || [])[idx] || def?.questionText || "Question Label"
          );
          const qtn = await ApsServices.http.question
            .save(
              optionGroupId
                ? {
                    ...mdl,
                    optionGroupId: optionGroupId,
                  }
                : mdl
            )
            .then((data) => data)
            .catch((error) => {
              toastStore.showError(`Failed Saving Question`, error);
            });
          qtn && questions.push(qtn);
        }
        return Promise.resolve({
          questionGroup: questionGroup,
          questions: questions,
          component: commonService.deepCloneJsonObject(compObj),
        });
      }
    }
  };

  addOperationsBC = async () => {
    return this.createQuestions("Dropdown", "Operations BC");
  };

  addBadgeBand = async () => {
    return this.createQuestions("Radiobox", "Badge Band");
  };

  addFlags = async () => {
    return this.createQuestions("Flags", "Flags");
  };

  addShiftActivity = async () => {
    return this.createQuestions("ShiftActivity", "Shift Activity");
  };

  addWildlandStatus = async () => {
    return this.createQuestions("WildlandField", "Wildland Status");
  };

  addStrikeTeams = async () => {
    return this.createQuestions("StrikeTeams", "Strike Teams");
  };

  addOnCallList = async () => {
    return this.createQuestions("OnCallList", "On-Call List");
  };

  addReserveApparatus = async () => {
    return this.createQuestions(
      "ReserveApparatus",
      "Reserve Apparatus Status",
      [
        "Engines",
        "",
        "",
        "",
        "Trucks/US&R",
        "",
        "",
        "",
        "ALS Rescue Ambulance",
        "",
        "",
        "",
        "Utilities",
        "",
        "",
        "",
      ]
    );
  };

  addUSRStatus = async () => {
    return this.createQuestions("UsarStatus", "US&R Status");
  };

  addUSRResponse = async () => {
    return this.createQuestions("ResponseComments", "US&R Response");
  };

  addAdditionalEMS = async () => {
    return this.createQuestions("ResponseComments", "Additional EMS Response");
  };

  addCommunityRiskEducation = async () => {
    return this.createQuestions("Text", "Community Risk Reduction");
  };

  addEOC = async () => {
    return this.createQuestions("Text", "Emergency Operations Center (EOC)");
  };
}
