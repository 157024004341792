import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import { FgSelect, FgUseForm, useFgModel } from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";

function NFIRSArsonInvestigation(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsArsonInvestigation.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsArsonInvestigationInputDto>({
    objectName: "Investigation",
    nfirsSectionTab: "Arson/Investigation",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsArsonInvestigationInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Arson", "Investigation", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Arson", "Investigation", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const materials = useNfirsGetData<
    ApsModels.INfirsAvailabilityOfFirstMaterialIgnitedEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsAvailabilityOfFirstMaterialIgnitedEntries,
    "Materials Ignited"
  );
  const statuses = useNfirsLookupEnum(
    "NfirsArsonInvestigationStatusEnum",
    "Case Statuses"
  );
  const factors = useNfirsGetData<
    ApsModels.INfirsArsonSuspectedMotivationFactorEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsArsonSuspectedMotivationFactorEntries,
    "Motivation Factors"
  );

  useEffect(() => {
    setProgress({ loading: true });
    materials.getData();
    statuses.getData();
    factors.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsArsonInvestigation
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Investigation Info", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      materials.status === NfirsFetchStatus.Complete &&
      statuses.status === NfirsFetchStatus.Complete &&
      factors.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress.errorCode, materials.status, statuses.status, factors.status]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsArsonInvestigationStatus: {
      displayName: "Case Status",
      validation: {
        required: false,
      },
    },
    nfirsAvailabilityOfFirstMaterialIgnitedEntryId: {
      displayName: "Availability of Material First Ignited",
      validation: {
        required: false,
      },
    },
    // nfirsArsonSuspectedMotivationFactorEntry1Id: {
    //   displayName: "Suspected Motivation Factors",
    //   validation: {
    //     required: false,
    //   },
    // },
    // nfirsArsonSuspectedMotivationFactorEntry2Id: {
    //   displayName: "Suspected Motivation Factors",
    //   validation: {
    //     required: false,
    //   },
    // },
    // nfirsArsonSuspectedMotivationFactorEntry3Id: {
    //   displayName: "Suspected Motivation Factors",
    //   validation: {
    //     required: false,
    //   },
    // },
  });

  const [currentFactors, setCurrentReleaseFactors] = useState<number[]>([]);
  useEffect(() => {
    const items = [
      model.new.nfirsArsonSuspectedMotivationFactorEntry1Id,
      model.new.nfirsArsonSuspectedMotivationFactorEntry2Id,
      model.new.nfirsArsonSuspectedMotivationFactorEntry3Id,
    ].filter((a) => !!a);
    setCurrentReleaseFactors(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    onModelChange({
      ...model.new,
      nfirsArsonSuspectedMotivationFactorEntry1Id: currentFactors[0] || null,
      nfirsArsonSuspectedMotivationFactorEntry2Id: currentFactors[1] || null,
      nfirsArsonSuspectedMotivationFactorEntry3Id: currentFactors[2] || null,
    } as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFactors]);

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12">
          <strong>Investigation</strong>
        </div>

        <div className="col-sm-12 col-lg-6">
          <div className="mt-3">
            <FgSelect
              id="nfirsArsonInvestigationStatus"
              label="Case Status"
              selectMessage="Select Status"
              registeredField={registry.nfirsArsonInvestigationStatus}
              formState={formState}
              options={(statuses.data || []).map((o) => {
                return {
                  label: `${o.value}. ${o.label}`,
                  value: o.value,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsArsonInvestigationStatus: Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsAvailabilityOfFirstMaterialIgnitedEntryId"
              label="Availability of Material First Ignited"
              selectMessage="Select Material Ignited"
              registeredField={
                registry.nfirsAvailabilityOfFirstMaterialIgnitedEntryId
              }
              formState={formState}
              options={(materials.data || []).map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsAvailabilityOfFirstMaterialIgnitedEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="mb-2">
            <label className="m-0">Suspected Motivation Factors</label>
            <div className="actionTakenList mt-2">
              {currentFactors.map((actionId, i) => (
                <div key={i}>
                  <div>
                    {factors.data?.find((a) => actionId === a.id)?.description}{" "}
                    ({factors.data?.find((a) => actionId === a.id)?.externalId})
                  </div>
                  <div>
                    <i
                      className="fa fa-times pointer"
                      onClick={() => {
                        const items = [...currentFactors];
                        items.splice(items.indexOf(actionId), 1);
                        setCurrentReleaseFactors(items);
                      }}
                    ></i>
                  </div>
                </div>
              ))}
            </div>

            {currentFactors?.length < 3 &&
              [currentFactors?.length].map((item) => (
                <div key={item}>
                  <FgSelect
                    id="actionsTaken"
                    selectMessage="Select Factor"
                    formState={formState}
                    options={(factors.data || [])
                      ?.filter((item) =>
                        factors.data?.find((x) => {
                          return currentFactors.indexOf(item.id) === -1;
                        })
                      )
                      ?.map((item) => {
                        return {
                          label: `${item.description} (${item.externalId}) `,
                          value: item.id,
                        };
                      })}
                    onChange={(data) => {
                      if (currentFactors.length < 3 && Number(data)) {
                        const items = [...currentFactors];
                        items.push(Number(data));
                        setCurrentReleaseFactors(items);
                      }
                    }}
                  ></FgSelect>
                </div>
              ))}
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="row">
            <div className="col-sm-6"></div>
            <div className="col-sm-6"></div>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSArsonInvestigation;
