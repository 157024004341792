import React from 'react';
import {
    IsolatedTableRowDetails,
    OptionSelectionDetails
} from '../../../data/DailyOperational';
import {IsolatedTableRowInput} from './IsolatedTableRowInput';

type Props = {
    row: IsolatedTableRowDetails,
    onChange: (rowIndex: number, selectionDetails: OptionSelectionDetails) => void
}
type State = {}

export class IsolatedTableRow extends React.Component<Props, State> {
    handleSectionInputChanged = (selectionDetails: OptionSelectionDetails): void => {
        this.props.onChange(this.props.row.rowIndex, selectionDetails);
    }

    render() {
        return (
            <tr>
                {this.props.row.columns.map((column, index) => {
                    return <IsolatedTableRowInput key={index} column={column} onChange={this.handleSectionInputChanged} />
                })}
            </tr>
        )
    }
}
