import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../../AppContext";
import commonService from "../../../../aps2/services/CommonService";
import {
  AnswerDto,
  AnswerType,
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";
import { UserDto } from "../../../../data/User";
import { GroupService } from "../../../../services/GroupService";
import { UserService } from "../../../../services/UserService";
import { IsolatedSelect } from "../../CustomInput/IsolatedSelect";
import { RichEditor } from "../../CustomInput/RichEditor";

interface IGenericShiftActivityProps {
  questionGroup?: QuestionGroupDto;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  date: Date;
  moduleOrder: number;
  departmentId: number;
  isVisibleShiftInvestigator: boolean;
  groupName?: string | string[];
}

interface IGenericShiftActivityState {
  users: UserDto[] | null;
  selectedUserId: number | null;
}

function GenericShiftActivity(props: IGenericShiftActivityProps) {
  const context = useContext(AppContext);
  const [state, setState] = useState<Partial<IGenericShiftActivityState>>({
    users: null,
    selectedUserId: null,
  });

  const getPropsValue = (propName: string) => {
    return ((props || ({} as any)) as { [key: string]: any })[propName];
  };

  const initialize = async () => {
    const allGroup = await GroupService.getAllGroups();
    const groupName = props.groupName || []; //New Renderer could send Array
    const grpList = Array.isArray(groupName) ? [...groupName] : [groupName];
    const users = await UserService.getUsersInMultipleGroupsByDepartmentId(
      grpList
        .map((grpName) => {
          return allGroup.find((gx) => gx.name === grpName)?.groupId || 0;
        })
        .filter((g) => g > 0),
      props.departmentId
    );
    setState({
      users: users.sort(commonService.sortByStringProperty("firstName")),
    });
  };

  const componentDidUpdate = () => {
    setState({ selectedUserId: null });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    componentDidUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date]);

  const updateQuestionGroup = (newQuestionValue: QuestionDto) => {
    const questionGroup = cloneDeep(props.questionGroup)!;
    const questionIndex = questionGroup.questions.findIndex(
      (q) => q.order == newQuestionValue.order
    );
    questionGroup.questions[questionIndex] = newQuestionValue;
    props.setData(questionGroup, props.moduleOrder);
  };

  const handleShiftInvestigatorChanged = (
    userId?: number,
    userName?: string
  ) => {
    const questionOrder = 0;
    const question: QuestionDto = cloneDeep(
      props.questionGroup!.questions.find((q) => q.order == questionOrder)!
    );
    const isAnswerExists = question.answers.length > 0;
    const answerId: number = question!.answers[0]
      ? question!.answers[0].answerId!
      : 0;

    let answer: AnswerDto;

    if (isAnswerExists || answerId > 0) {
      answer = question.answers.find((a) => a.answerId == answerId)!;
      answer.user!.userId = userId;
      answer.user!.userName = userName;
      answer.date = props.date;
      answer.dateCreated = props.date;
      answer.dateUpdated = props.date;
    } else {
      answer = createNewAnswer(answerId, question, userId, userName);
      question.answers.push(answer);
    }
    updateQuestionGroup(question);
  };

  const createNewAnswer = (
    answerId: number,
    question: QuestionDto,
    userId?: number,
    userName?: string
  ) => {
    return {
      answerId: answerId,
      answerType: AnswerType.User,
      date: props.date,
      dateCreated: props.date,
      dateUpdated: props.date,
      flagAnswerValue: false,
      order: 0,
      questionId: question.questionId ?? 0,
      user: { userId: userId, userName: userName, answerId: 0 },
    };
  };
  const getAnswerUserId = (questions: QuestionDto[] | undefined) => {
    const shiftInvestigatorOrder: number = 0;
    return (
      questions &&
      questions
        .find((x) => x.order === shiftInvestigatorOrder)
        ?.answers.find((y) => y.order === 0)?.user?.userId
    );
  };

  const getAnswerUserName = (
    questions: QuestionDto[] | undefined
  ): string | undefined => {
    const operationsBC: number = 0;
    return (
      questions &&
      questions
        .find((x) => x.order === operationsBC)
        ?.answers.find((y) => y.order === 0)?.user?.userName
    );
  };

  const userId = useMemo(() => {
    return getAnswerUserId(props.questionGroup?.questions);
  }, [props.questionGroup]);

  const userName = useMemo(() => {
    return getAnswerUserName(props.questionGroup?.questions);
  }, [props.questionGroup]);

  const items = useMemo(() => {
    return state.users
      ? state.users!.map((u) => {
        return { id: u.userId, name: `${u.firstName} ${u.lastName}` };
      })
      : [];
  }, [state.users]);

  return (
    <div className="daily-operational-card card box-card">
      <div className="card-body">
        <div className="row m-1">
          <div className="col-8 col-xl-8">
            <h4 className="mb-0 font-size-16">
              {props.questionGroup?.name || ""}
            </h4>
          </div>
          {!getPropsValue("hideQuestion0") ? (
            <div className="col-4 col-xl-4">
              <div className="row align-items-center shift-investigator-select ">
                <div className="col-md-6 font-weight-bold">
                  <div className="float-right align-items-center">
                    {
                      props.questionGroup?.questions.find((x) => x.order === 0)
                        ?.questionText
                    }
                  </div>
                </div>
                <IsolatedSelect
                  onChange={handleShiftInvestigatorChanged}
                  availableOptions={items}
                  id={userId}
                  name={userName}
                  showDefaultOptionName={true}
                  className="col-xl-6 border rounded-10 h-8 form-control"
                />
              </div>
              <div className="row align-items-center shift-investigator-select">
                <div className="col-md-6 font-weight-bold">
                  <div className="float-right align-items-center d-none childsection1">
                    <span className="mb-0 font-size-22"> <b> {props.questionGroup?.questions.find((x) => x.order === 0)
                      ?.questionText}</b> : {items.find(x => x.id == userId)?.name ?? userName} </span>
                  </div>
                </div>

              </div>
            </div>

          ) : null}
        </div>
        <div className="childsection2">
          <h4 className="d-none d-print-block mb-2 mt-2 font-size-22">
            <b> {props.questionGroup?.name || ""}</b>
          </h4>
          <div className="richeditor-pdf">
            <RichEditor
              isEditable={!context.isEditableDailyOperational}
              setData={props.setData}
              questionGroup={props.questionGroup}
              date={props.date}
              moduleOrder={props.moduleOrder}
              departmentId={props.departmentId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenericShiftActivity;
