import React, { useEffect, useState } from "react";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";

function FileUpload(props: {
  label?: string;
  onChange?: (files: FileList) => void;
  disabled?: boolean;
  accepts?: {
    extensions: string;
    pattern: RegExp;
  };
  filesFromDrop?: FileList;
}) {
  const [files, setFiles] = useState<FileList>();
  const inputId = `FileInput${commonService.getUniqueId()}`;

  const onChange = (fileList: FileList | null) => {
    if (fileList) {
      if (props.accepts) {
        let cnt = 0;
        while (cnt < fileList.length) {
          const file = fileList[cnt];
          if (!props.accepts.pattern.exec(file.name)) {
            toastStore.showToast(
              `Invalid file type. Please select files with the following extension(s): <strong>${props.accepts.extensions}</strong>`,
              "warning"
            );
            return;
          }
          cnt++;
        }
      }
      setFiles(fileList);
      props?.onChange && props.onChange(fileList);
    }
  };

  useEffect(() => {
    if (props.filesFromDrop) {
      onChange(props.filesFromDrop);
    }
  }, [props.filesFromDrop]);

  return (
    <div className="btn-file-upload" style={{ display: "inline-block" }}>
      <div className="form-group" style={{ display: "inline-block" }}>
        <input
          hidden
          type="file"
          multiple
          onChange={(event) => {
            const fileList = event.target.files;
            onChange(fileList);
          }}
          id={inputId}
          accept={props?.accepts?.extensions || undefined}
          value={""}
        />
        <button
          type="button"
          className="btn btn-outline-primary"
          disabled={props?.disabled || false}
          onClick={() => {
            document.getElementById(inputId)?.click();
          }}
        >
          {props?.label || "Upload"}
          {/* {files ? ` (${files.length})` : ""} */}
        </button>
      </div>
    </div>
  );
}

export default FileUpload;
