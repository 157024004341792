import React, { useEffect, useMemo, useState } from "react";
import commonService from "../../../services/CommonService";
type RecType = "CT" | "RE" | "GR" | "JS"; //"CR" |

const RequirementTypes = [
  "Credentials",
  "Courses",
  "Task Book",
  "Supporting Documentation",
  "Other",
];

interface IRowItem {
  id: string;
  recordType: RecType;
  //isAPS: string;
  //credCode: string;
  credCategoryNameCode: string;
  nameOrType: string;
  //expInYearsOrType: string;
  stateAbbrevOrIsEMS: string;
  totalHours: string;
  instructions: string;
  selected?: boolean;
  databaseId?: any;
  deleted?: string;
}

interface IGroupItem {
  parent: string;
  children: string[];
  operator: "AND" | "OR";
}

const RowItem = (props: { children: any }) => {
  return <>{props.children}</>;
};

function CredentialRequirementsTemp(props: {
  csvLines: string[];
  onChange: (list: string[]) => void;
  onError: (hasError: boolean) => void;
}) {
  const [rows, setRows] = useState<Partial<IRowItem>[]>([
    // {
    //   id: commonService.getUniqueId(),
    //   recordType: "CT",
    //   stateAbbrevOrIsEMS: "CA",
    // },
  ]);

  const addRow = (idx?: number, addRE?: boolean) => {
    const newId = commonService.getUniqueId();
    if (idx !== null && idx !== undefined) {
      setRows((prev) => {
        const arr = [...prev];
        //const ref = arr[idx];
        if (addRE) {
          arr.splice(idx + 1, 0, {
            id: newId,
            recordType: addRE ? "RE" : "CT",
            stateAbbrevOrIsEMS: addRE ? "0" : "",
          });
        } else {
          arr.splice(idx + 1, 0, {
            id: newId,
            recordType: addRE ? "RE" : "CT",
            stateAbbrevOrIsEMS: addRE ? "0" : "",
          });
        }
        return arr;
      });
      return newId;
    }
    setRows((prev) => {
      return [
        ...prev,
        {
          id: newId,
          recordType: "CT",
          stateAbbrevOrIsEMS: "",
        },
      ];
    });
    return newId;
  };

  const delRow = (idx: number, id?: string) => {
    setRows((prev) => {
      return prev.filter((r, i) => i !== idx);
    });
    if (id) {
      setGroups((prev) => {
        return prev
          .map((g) => {
            return {
              ...g,
              children: g.children.filter((c) => c !== id),
            };
          })
          .filter((g) => g.children.length > 1);
      });
    }
  };

  const sanitize = (val: any) => {
    const newVal = `${val || ""}`.trim();
    if (newVal.indexOf(",") > -1) {
      return `"${newVal}"`.replace(/""/g, '"');
    }
    return newVal;
  };

  //const [initialData, setInitialData] = useState<string[]>([]);
  const [origProps, setOrigProps] = useState<string[][]>([]);

  const getRowInfo = (row: string) => {
    try {
      const data = (
        commonService.CSVToArray(`${row || ""}`.trim()) as string[][]
      ).filter((d) => d.join("").trim() !== "")[0];
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (props.csvLines?.length) {
        loadFromProps();
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [groups, setGroups] = useState<IGroupItem[]>([]);
  const [confirmReset, setConfirmReset] = useState(false);

  const isEms = (val: any) => {
    return (
      Number(val) === 1 ||
      `${val || ""}`.trim().toLowerCase() === "y" ||
      `${val || ""}`.trim().toLowerCase() === "yes"
    );
  };

  const isNumberAndNotEmpty = (val: string) => {
    if (`${val}`.trim() === "" || val === undefined || val === null) {
      return false;
    }
    if (isNaN(val as any)) {
      return false;
    }
    return true;
  };

  const requiredRowsForCategory = useMemo(() => {
    return rows.filter((r) => {
      return (
        r.recordType === "RE" && !isNumberAndNotEmpty(r.instructions || "")
      );
    }).length;
  }, [rows]);

  const isCodeRequired = (row: Partial<IRowItem>) => {
    return (
      row.recordType === "RE" &&
      [0, 1].includes(Number(row.instructions)) &&
      (row.credCategoryNameCode || "").trim() === ""
    );
  };

  const isCodeInvalid = (row: Partial<IRowItem>) => {
    return (
      row.recordType === "RE" &&
      [0, 1].includes(Number(row.instructions)) &&
      ((row.credCategoryNameCode || "").toUpperCase().indexOf(`{OR}`) > -1 ||
        (row.credCategoryNameCode || "").toUpperCase().indexOf(`{AND}`) > -1)
    );
  };

  const requiredRowsCode = useMemo(() => {
    return rows.filter((r) => {
      return isCodeRequired(r);
    }).length;
  }, [rows]);

  const invalidRowsCode = useMemo(() => {
    return rows.filter((r) => {
      return isCodeInvalid(r);
    }).length;
  }, [rows]);

  const selectedRows = useMemo(() => {
    return rows.filter((r) => {
      return r.selected;
    }).length;
  }, [rows]);

  const areValidSelections = useMemo(() => {
    let idx = -1;
    let run = true;
    rows.forEach((r, i) => {
      const diff = i - idx;
      if (idx > -1 && r.selected && run) {
        run = diff === 1;
      }
      if (r.selected) {
        idx = i;
      }
    });
    if (run) {
      const selectedIds = JSON.stringify(
        rows.filter((r) => r.selected).map((r) => r.id || "")
      );
      run = !groups.find((g) => JSON.stringify(g.children) === selectedIds);
    }
    return run;
  }, [rows]);

  const isDescendant = (parentId: string, childId: string): boolean => {
    return !!groups.find((g) => {
      if (g.parent === parentId && g.children.includes(childId)) {
        return true;
      }
      return false;
    });
  };

  const groupLines = useMemo(() => {
    const grps = [...groups].sort(
      (a, b) => b.children.length - a.children.length
    );
    let ops: string[] = [];
    grps.forEach((g, i) => {
      const strVal = g.children.join(` {${g.operator}} `);
      const forAND = g.children.join(` {AND} `);
      const forOR = g.children.join(` {OR} `);
      const match = ops.filter((o) => o.includes(forAND) || o.includes(forOR));
      if (match.length > 0) {
        ops = ops.map((o) =>
          o.replace(forAND, `( ${strVal} )`).replace(forOR, `(${strVal})`)
        );
      } else {
        ops.push(`${g.parent}|${strVal}`);
      }
    });

    rows.forEach((r) => {
      if (r.recordType === "RE" && r.id) {
        ops = ops.map((o) =>
          r.id ? o.replace(r.id, r.credCategoryNameCode || "[UNKOWN]") : o
        );
      }
    });
    return ops;
  }, [groups, rows]);

  const closestCT = (rowId: any) => {
    const idx = rows.findIndex((x) => x.id === rowId);
    if (idx > -1) {
      const rst = rows.filter((x, i) => x.recordType === "CT" && i < idx);
      if (rst.length > 0) {
        return rst[rst.length - 1];
      }
    }
    return null;
  };

  const getCsvData = () => {
    const list: string[] = [];
    document.querySelectorAll("#csv-rendered > div")?.forEach((e) => {
      e.textContent && list.push(e.textContent);
    });
    return list;
  };

  const triggerChange = () => {
    const list = getCsvData().map((n) => {
      const nData = getRowInfo(n);
      if (nData[0] === "G3") {
        const oG3 = origProps.find(
          (oData) => oData[0] === "G3" && oData[3] === nData[3]
        );
        if (oG3) {
          return oG3.filter((o, i) => i < oG3.length - 1).join(",") + ",0";
        }
      }
      return n;
    });
    const oldG3s = origProps
      .filter((oData) => {
        if (oData[0] === "G3") {
          if (
            !list.find((n) => {
              const nData = getRowInfo(n);
              if (nData[0] === "G3" && oData[3] === nData[3]) {
                return true;
              }
              return false;
            })
          ) {
            return true;
          }
        }
        return false;
      })
      .map((oData) => {
        return oData.filter((o, i) => i < oData.length - 1).join(",") + ",1";
      });

    const oldRows = origProps
      .map((oData) => {
        if (oData[0] === "CT" || oData[0] === "RE") {
          if (
            !rows.find(
              (r) =>
                r.recordType === oData[0] &&
                r.databaseId === oData[oData.length - 2]
            )
          ) {
            return (
              oData.filter((o, i) => i < oData.length - 1).join(",") + ",1"
            );
          }
        }
        return "";
      })
      .filter((o) => !commonService.isNullOrWhitespace(o));

    props.onChange([...list, ...oldG3s, ...oldRows]);
  };

  useEffect(() => {
    triggerChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    props.onError(requiredRowsCode > 0 || requiredRowsForCategory > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredRowsCode, requiredRowsForCategory]);

  const loadFromProps = () => {
    const data = (
      commonService.CSVToArray(
        `${(props.csvLines || []).join("\n") || ""}`.trim()
      ) as string[][]
    ).filter((d) => d.join("").trim() !== "");

    setOrigProps([...data]);

    const list = [
      ...data
        .filter((row) => ["CT", "RE"].includes(row[0]))
        .map((row, i) => {
          if (sanitize(row[0]) === "RE") {
            const mdl: IRowItem = {
              id: sanitize(row[6]) || commonService.getUniqueId(),
              recordType: sanitize(row[0]) as any,
              credCategoryNameCode: sanitize(row[1]),
              nameOrType: sanitize(row[2]),
              stateAbbrevOrIsEMS:
                sanitize(row[3]) === "True"
                  ? "1"
                  : sanitize(row[3]) === "False"
                  ? "0"
                  : sanitize(row[3]),
              totalHours: sanitize(row[4]),
              instructions: sanitize(row[5]),
              databaseId: sanitize(row[7]),
              deleted: "",
            };
            return mdl;
          } else {
            const mdl: IRowItem = {
              id: sanitize(row[3]) || commonService.getUniqueId(),
              recordType: sanitize(row[0]) as any,
              credCategoryNameCode: sanitize(row[1]),
              nameOrType: sanitize(row[2]),
              stateAbbrevOrIsEMS: null as any,
              totalHours: null as any,
              instructions: null as any,
              databaseId: sanitize(row[4]),
              deleted: "",
            };
            return mdl;
          }
        }),
    ];

    setRows(list);

    setGroups((prev) => {
      return [
        ...data
          .filter((row) => ["JS"].includes(row[0]))
          .map((row, i) => {
            const str = row[1].replace(/'/g, '"');
            const json = JSON.parse(str) as IGroupItem;
            return json;
          }),
      ];
    });

    // setTimeout(() => {
    //   setInitialData(getCsvData());
    // }, 200);

    setTimeout(() => {
      triggerChange();
    }, 300);
  };

  return (
    <div className="cred-temp-requirement-block">
      <div className="px-0 py-1">
        <table className="table table-sm">
          <thead className="text-danger pb-1">
            <tr>
              <th colSpan={3} style={{ borderTop: "none" }}></th>
              <th colSpan={1} style={{ borderTop: "none" }}>
                {requiredRowsCode > 0 && (
                  <small>Required: {requiredRowsCode}</small>
                )}
                <div>
                  {invalidRowsCode > 0 && (
                    <small>Invalid: {invalidRowsCode}</small>
                  )}
                </div>
              </th>
              <th colSpan={3} style={{ borderTop: "none" }}></th>
              <th colSpan={2} style={{ borderTop: "none" }}>
                {requiredRowsForCategory > 0 && (
                  <small>Required: {requiredRowsForCategory}</small>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, curRowIndex) => (
              <RowItem key={row.id}>
                <tr
                  style={{
                    backgroundColor:
                      row.recordType === "CT" ? "lightblue" : "#fff",
                  }}
                >
                  <td>
                    {row.recordType === "CT" && (
                      <button
                        type="button"
                        className="btn btn-warning"
                        disabled={!(selectedRows > 1 && areValidSelections)}
                        onClick={(e) => {
                          const newId = commonService.getUniqueId();
                          setGroups((prev) => {
                            return [
                              ...prev,
                              {
                                parent: newId,
                                children: rows
                                  .filter((r) => r.selected && r.id)
                                  .map((r) => r.id || ""),
                                operator: "AND",
                              },
                            ];
                          });

                          setRows((prev) => {
                            return prev.map((r) => {
                              return {
                                ...r,
                                selected: false,
                              };
                            });
                          });
                        }}
                      >
                        <i className="fa fa-object-group"></i>
                      </button>
                    )}
                    {row.recordType === "RE" && (
                      <div className="flex">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={(e) => {
                            const grpsIn = groups.filter((x) =>
                              x.children.includes(row.id || "")
                            );
                            const newVal = !row.selected;
                            setRows((prev) => {
                              return prev.map((r, ii) => {
                                if (
                                  curRowIndex === ii ||
                                  grpsIn.find((x) =>
                                    x.children.includes(r.id || "")
                                  )
                                ) {
                                  return {
                                    ...r,
                                    selected: newVal,
                                  };
                                }
                                return r;
                              });
                            });
                          }}
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            <i
                              style={{
                                fontWeight: "normal",
                              }}
                              className={`fa ${
                                row.selected ? "fa-check-square" : "fa-square"
                              }`}
                            ></i>
                          </span>
                        </button>
                      </div>
                    )}
                  </td>
                  <td
                    className="no-wrap text-left"
                    style={{
                      padding: 0,
                    }}
                  >
                    <div
                      className="flex group-line"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      {groups
                        .filter((g) => row.id && isDescendant(g.parent, row.id))
                        .sort((a, b) => {
                          return b.children.length - a.children.length;
                        })
                        .map((g) => (
                          <div
                            key={g.parent}
                            style={{
                              position: "relative",
                              display: "inline-block",
                              minWidth: "55px",
                              marginLeft: "5px",
                              height: "20px",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                borderLeft: "solid 2px #1e7e34",
                                borderTop:
                                  g.children.indexOf(row.id || "") > 0
                                    ? "none"
                                    : "solid 2px #1e7e34",
                                borderBottom:
                                  g.children.indexOf(row.id || "") ===
                                  g.children.length - 1
                                    ? "solid 2px #1e7e34"
                                    : "none",
                                top: "0",
                                height:
                                  g.children.indexOf(row.id || "") ===
                                  g.children.length - 1
                                    ? "25px"
                                    : "50px",
                                width: "100%",
                              }}
                            >
                              {g.children.indexOf(row.id || "") === 0 && (
                                <div
                                  className="pl-1 pt-1"
                                  style={{
                                    zIndex: 10,
                                  }}
                                >
                                  <select
                                    className="form-control form-control-sm px-0"
                                    style={{
                                      fontSize: "12px",
                                      maxWidth: "55px",
                                    }}
                                    value={g.operator}
                                    onChange={(e) => {
                                      setGroups((prev) => {
                                        return prev.map((gg) => {
                                          if (g.parent === gg.parent) {
                                            return {
                                              ...gg,
                                              operator: (e.target.value ||
                                                "AND") as any,
                                            };
                                          }
                                          return gg;
                                        });
                                      });
                                      setRows((p) => [...p]); //Just to refresh rules.
                                    }}
                                  >
                                    <option value="AND">AND</option>
                                    <option value="OR">OR</option>
                                  </select>
                                </div>
                              )}
                              {g.children.indexOf(row.id || "") === 1 && (
                                <>
                                  <div className="px-2">
                                    <i
                                      className="fa fa-trash pointe text-danger pointer"
                                      onClick={(e) => {
                                        setGroups((prev) => {
                                          return prev.filter((gg, ii) => {
                                            return ii !== prev.indexOf(g);
                                          });
                                        });
                                        setRows((prev) => {
                                          return prev.map((r) => {
                                            return {
                                              ...r,
                                              selected: false,
                                            };
                                          });
                                        });
                                      }}
                                    ></i>
                                  </div>
                                </>
                              )}
                              {g.children.indexOf(row.id || "") !== 0 && (
                                <>
                                  <span style={{ opacity: 0 }}>
                                    {groups.indexOf(g)}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </td>
                  <td>
                    <select
                      className="form-control"
                      value={row.recordType}
                      style={{ width: "130px" }}
                      onChange={(e) => {
                        const val = (e.target.value || "") as RecType;
                        setRows((prev) => {
                          return prev.map((r, ii) => {
                            if (curRowIndex === ii) {
                              if (val === "RE") {
                                return {
                                  ...r,
                                  recordType: val,
                                  stateAbbrevOrIsEMS: "1",
                                  instructions: "",
                                };
                              }
                              return {
                                ...r,
                                recordType: val,
                                stateAbbrevOrIsEMS: "",
                                instructions: "",
                              };
                            }
                            return r;
                          });
                        });
                      }}
                    >
                      <option value="CT">Heading</option>
                      <option value="RE">Requirement</option>
                    </select>
                  </td>
                  <td>
                    <input
                      id={`input4-${row.id}`}
                      maxLength={row.recordType === "RE" ? 64 : 2048}
                      type="text"
                      autoComplete="new-password"
                      placeholder={row.recordType === "RE" ? "Code" : "Name"}
                      className={`form-control ${
                        isCodeRequired(row) || isCodeInvalid(row)
                          ? "required"
                          : ""
                      }`}
                      value={row.credCategoryNameCode}
                      onChange={(e) => {
                        const val = (e.target.value || "") as any;
                        setRows((prev) => {
                          return prev.map((r, ii) => {
                            if (curRowIndex === ii) {
                              return {
                                ...r,
                                credCategoryNameCode: val,
                              };
                            }
                            return r;
                          });
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      autoComplete="new-password"
                      placeholder={row.recordType === "RE" ? "Name" : "Type"}
                      className={`form-control ${
                        row.recordType === "CT" ? "display-none" : ""
                      }`}
                      value={row.nameOrType}
                      onChange={(e) => {
                        const val = (e.target.value || "") as any;
                        setRows((prev) => {
                          return prev.map((r, ii) => {
                            if (curRowIndex === ii) {
                              return {
                                ...r,
                                nameOrType: val,
                              };
                            }
                            return r;
                          });
                        });
                      }}
                    />
                  </td>

                  <td>
                    {row.recordType === "RE" && (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) => {
                          setRows((prev) => {
                            return prev.map((r, ii) => {
                              if (curRowIndex === ii) {
                                return {
                                  ...r,
                                  stateAbbrevOrIsEMS: isEms(
                                    r.stateAbbrevOrIsEMS
                                  )
                                    ? "0"
                                    : "1",
                                };
                              }
                              return r;
                            });
                          });
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          <i
                            style={{
                              fontWeight: "normal",
                            }}
                            className={`fa mr-2 ${
                              isEms(row.stateAbbrevOrIsEMS)
                                ? "fa-check-square"
                                : "fa-square"
                            }`}
                          ></i>
                          Is EMS?
                        </span>
                      </button>
                    )}
                  </td>
                  <td style={{ width: "80px" }}>
                    {row.recordType !== "CT" && (
                      <input
                        type="text"
                        autoComplete="new-password"
                        placeholder={row.recordType === "RE" ? "Hours" : ""}
                        className="form-control"
                        value={row.totalHours}
                        onChange={(e) => {
                          const val = (e.target.value || "") as any;
                          setRows((prev) => {
                            return prev.map((r, ii) => {
                              if (curRowIndex === ii) {
                                return {
                                  ...r,
                                  totalHours: val,
                                };
                              }
                              return r;
                            });
                          });
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {row.recordType === "RE" && (
                      <select
                        value={row.instructions || ""}
                        className={`form-control ${
                          isNumberAndNotEmpty(row.instructions || "")
                            ? ""
                            : "required"
                        }`}
                        onChange={(e) => {
                          const val = (e.target.value || "") as any;
                          setRows((prev) => {
                            return prev.map((r, ii) => {
                              if (curRowIndex === ii) {
                                return {
                                  ...r,
                                  instructions: val,
                                };
                              }
                              return r;
                            });
                          });
                        }}
                      >
                        <option value="">- Select Category -</option>
                        {RequirementTypes.map((c, ci) =>
                          ci === 3 ? (
                            <React.Fragment key={c}></React.Fragment>
                          ) : (
                            <option key={c} value={ci}>
                              {ci} {c}
                            </option>
                          )
                        )}
                      </select>
                    )}
                  </td>
                  <td className="no-wrap">
                    {(row.recordType === "CT" || row.recordType === "RE") && (
                      <button
                        type="button"
                        className="btn btn-success mr-2"
                        onClick={(e) => {
                          const id = addRow(curRowIndex, true);
                          setTimeout(() => {
                            document.getElementById(`input4-${id}`)?.focus();
                          }, 300);
                        }}
                      >
                        <i className="fa fa-plus pr-1"></i>
                        <strong>REQ</strong>
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      onClick={(e) => {
                        addRow(curRowIndex);
                      }}
                    >
                      <i className="fa fa-plus"></i> <strong>HEAD</strong>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={(e) => {
                        delRow(curRowIndex, row.id);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              </RowItem>
            ))}
          </tbody>
        </table>

        <div className="px-1 pt-1">
          {confirmReset ? (
            <>
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={(e) => {
                  setConfirmReset(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => {
                  setRows([]);
                  setGroups([]);
                  setConfirmReset(false);
                }}
              >
                YES, RESET!
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={(e) => {
                  addRow();
                }}
              >
                Add Row
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => {
                  setConfirmReset(true);
                }}
                disabled={rows.length < 2}
              >
                Reset
              </button>
            </>
          )}
        </div>
        <div id="csv-rendered" className="card p-3 mt-4 display-none">
          {rows.map((row, i) => (
            <React.Fragment key={row.id}>
              {row.recordType === "RE" &&
                groups
                  .filter(
                    (g) =>
                      row.id &&
                      groupLines.find((gl) => gl.includes(g.parent)) &&
                      g.children.indexOf(row.id) === 0
                  )
                  .map((g) => (
                    <div key={g.parent}>
                      G3,(
                      {
                        groupLines
                          .find((gl) => gl.includes(g.parent))
                          ?.split("|")[1]
                      }
                      ),{closestCT(row.id)?.id},{g.children.join("|")},,0
                    </div>
                  ))}
              {row.recordType === "RE" &&
                groups.filter(
                  (g) =>
                    row.id &&
                    groupLines.find((gl) => gl.includes(g.parent)) &&
                    g.children.indexOf(row.id) > -1
                ).length === 0 && (
                  <div>
                    G3,,{closestCT(row.id)?.id},{row.id},,0
                  </div>
                )}
              <div>
                {row.recordType === "CT" ? (
                  <>
                    {sanitize(row.recordType)},
                    {sanitize(row.credCategoryNameCode)},
                    {sanitize(row.nameOrType)},
                  </>
                ) : (
                  <>
                    {sanitize(row.recordType)},
                    {sanitize(row.credCategoryNameCode)},
                    {sanitize(row.nameOrType)},
                    {sanitize(row.stateAbbrevOrIsEMS)},
                    {sanitize(row.totalHours)},{sanitize(row.instructions)},
                  </>
                )}
                {row.id},{row?.databaseId || ""},{row?.deleted || "0"}
              </div>
            </React.Fragment>
          ))}
          {groups.map((g) => (
            <div key={g.parent}>
              JS,"{JSON.stringify(g).replace(/"/g, "'")}"
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CredentialRequirementsTemp;
