import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay, faRunning, faUsers, faShareAlt, faAddressBook, faUser, faDesktop, faFire, faClipboardList, faHome, faMap, faChartBar, faSuitcase, faClipboardCheck, faBell } from '@fortawesome/free-solid-svg-icons'
import { faBookmark, faIdCard } from '@fortawesome/free-regular-svg-icons'
import { DepartmentService } from "../../services/DepartmentService";
import { ModulesDto } from "../../data/Department";
import { AppContext } from "../../AppContext";
import commonService from "../../aps2/services/CommonService";
import ApsServices from "../../aps2/services";

type Props = RouteComponentProps & {    
    menuClick: (menu?: any) => void
}

type State = {
    modules: ModulesDto[] | null,
    isAdmin: boolean,
    isAccountAdmin: boolean,
    reload: boolean,
    preContext: number
    customIframeModuleName: string | null
}

class SideBarContent extends Component<Props, State> {
    static displayName = SideBarContent.name;
    static contextType = AppContext;

    state: State = {
        modules: null,
        isAdmin: false,
        isAccountAdmin: false,
        reload: false,
        preContext: 1,
        customIframeModuleName: null
    }

    getMenuItemClassName = (pathname: string) => {
        return `waves-effect ${this.props.location.pathname.toLowerCase().indexOf(pathname.toLowerCase()) === 0 ? 'mm-active' : ''}`;
    }

    isModuleActive = (moduleName: string) => {
        if (!this.state.modules?.find(x => x.name === moduleName)?.isSelected) {
            return false
        }
        return true
    }

    handleUserModules = async (departmentId: number) => {        
        const modules = await DepartmentService.getModulesByDepartmentId(departmentId);

        this.setState({ 
            modules: modules, 
            preContext: this.context.module,
        }, async () => {
            if (this.isModuleActive("Custom IFrame") && commonService.canAccessCustomIFrame()) {
                const customIframeSettings = await ApsServices.http.customIframe.get(departmentId);
                const customIframeModuleName = customIframeSettings.iframeModuleName;
                this.setState({customIframeModuleName})
            }
        });   
             
        DepartmentService.modules.set(modules);
    }

    handleAdminModules = async () => {
        const modules = await DepartmentService.getModules();
        const modulesAdmin: ModulesDto[] = new Array<ModulesDto>();
        modules.map(x => {
            return modulesAdmin.push({
                moduleId: x.moduleId,
                name: x.name,
                isSelected: true
            })
        })
        this.setState({ modules: modulesAdmin });
    }

    async componentDidMount() {
        const departmentId = await DepartmentService.getDepartmentId();
        if (departmentId) {
            this.handleUserModules(Number(departmentId));
        } else {
            this.handleUserModules(this.context.module);
        }
        this.setState({ isAdmin: this.context.isAdmin, isAccountAdmin: this.context.isAccountAdmin })
        this.handleReload()
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.preContext != this.context.module) {
            await this.handleUserModules(this.context.module)
        }
    }

    handleReload = () => {
        this.setState({ reload: !this.state.reload })
    }

    render() {
        return (
            <div>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">Menu</li>

                        {this.isModuleActive("Dashboard") && commonService.canAccessDashboard() &&
                            <li onClick={this.props.menuClick}>
                                <Link to="/home" className={this.getMenuItemClassName("/Home")}>
                                    <FontAwesomeIcon icon={faHome} className="icon"/>
                                    <span>Home</span>
                                </Link>
                            </li>}

                        {this.isModuleActive("User Console") && (this.state.isAdmin || this.context.isVisibleUserConsole) && <li onClick={this.props.menuClick}>
                            <Link to="/links" className={this.getMenuItemClassName("/Links")}>
                                <FontAwesomeIcon icon={faBookmark as IconProp} className="icon"/>
                                <span>Links</span>
                            </Link>
                        </li>}

                        {this.isModuleActive("Daily Operational") && (this.context.isVisibleDailyOperational || this.context.isEditableDailyOperational) && <li onClick={this.props.menuClick}>
                            <Link to="/dailyoperational" className={this.getMenuItemClassName("/DailyOperational")}>
                                <FontAwesomeIcon icon={faCalendarDay} className="icon" />
                                <span>Daily Operational</span>
                            </Link>
                        </li>}

                        {this.isModuleActive("Custom IFrame") && commonService.canAccessCustomIFrame() && (this.state.customIframeModuleName !== null) &&
                            <li onClick={this.props.menuClick}>
                                <Link to={`/${this.state.customIframeModuleName.toLowerCase()}`} className={this.getMenuItemClassName(`/${this.state.customIframeModuleName}`)}>
                                    <FontAwesomeIcon icon={faClipboardList} className="icon" />
                                    <span>{`${this.state.customIframeModuleName}`}</span>
                                </Link>
                            </li>}

                        {this.isModuleActive("Assignments") && commonService.canAccessAssignments() &&
                            <li onClick={this.props.menuClick}>
                                <Link to="/assignments" className={this.getMenuItemClassName("/Assignments")}>
                                    <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
                                    <span>Assignments</span>
                                </Link>
                            </li>}

                        {this.isModuleActive("Log Drills") && commonService.canAccessLogDrills() &&
                            <li onClick={this.props.menuClick}>
                                <Link to="/drills" className={this.getMenuItemClassName("/Drills")}>
                                    <FontAwesomeIcon icon={faRunning} className="icon"/>
                                    <span>Drills</span>
                                </Link>                                
                            </li>}

                        {this.isModuleActive("Course Records") && commonService.canAccessCourseRecords() &&
                            <li onClick={this.props.menuClick}>
                                <Link to="/courses" className={this.getMenuItemClassName("/Courses")}>
                                    <FontAwesomeIcon icon={faClipboardList} className="icon"/>
                                    <span>Courses</span>
                                </Link>
                            </li>}

                        {this.isModuleActive("Training Portal") && commonService.canAccessTrainingPortal() &&
                            <li onClick={this.props.menuClick}>
                                <a href={`trainingportal/redirect/${this.context.module}`} target="_blank" className={this.getMenuItemClassName("/TrainingPortal")}>
                                    <FontAwesomeIcon icon={faDesktop} className="icon" />
                                    <span>Online Training</span>
                                </a>
                            </li>}

                        {this.isModuleActive("Credentials") && commonService.canAccessCredentials() &&
                            <li onClick={this.props.menuClick}>
                                <Link to="/credentials" className={this.getMenuItemClassName("/Credentials")}>
                                    <FontAwesomeIcon icon={faIdCard as IconProp} className="icon"/>
                                    <span>Credentials</span>
                                </Link>
                            </li>}

                        {this.isModuleActive("NFIRS") && commonService.canAccessNfirs() &&
                        <li onClick={this.props.menuClick}>                                
                            <Link to="/nfirs" className={this.getMenuItemClassName("/NFIRS")}>
                                <FontAwesomeIcon icon={faFire} className="icon"/>
                                <span>NFIRS</span>
                            </Link>                                
                        </li>}

                        {this.isModuleActive("Data Visualization") && commonService.canAccessDataVisualization() && <>
                            <li>
                                <Link to="/datavisualization" className={this.getMenuItemClassName("/DataVisualization")}>
                                    <FontAwesomeIcon icon={faMap} className="icon" />
                                    <span>Data Visualization</span>
                                </Link>
                            </li>
                        </>}

                        {this.isModuleActive("ISO Training") && commonService.canAccessIsoTraining() && <>
                            <li>
                                <Link to="/reports" className={this.getMenuItemClassName("/Reports")}>
                                    <FontAwesomeIcon icon={faChartBar} className="icon" />
                                    <span>Reports</span>
                                </Link>
                            </li>
                        </>}

                        {((this.isModuleActive("Personnel") && commonService.canAccessPersonnel()) ||
                            (this.isModuleActive("Templates") && commonService.canAccessTemplates())) &&
                            <li className="menu-title">Account Admin</li>
                        }
                        {this.isModuleActive("Personnel") && commonService.canAccessPersonnel() &&
                            <>
                                <li onClick={this.props.menuClick}>
                                    <Link to="/personnel" className={this.getMenuItemClassName("/Personnel")}>
                                        <FontAwesomeIcon icon={faAddressBook} className="icon" />
                                        <span>Personnel</span>
                                    </Link>
                                </li>
                            </>
                        }

                        {commonService.canAccessGroups() && 
                            <li onClick={this.props.menuClick}>
                                <Link to="/admin/groups" className={this.getMenuItemClassName("/Admin/Groups")}>
                                    <FontAwesomeIcon icon={faUsers} className="icon"/>
                                    <span>Groups</span>
                                </Link>
                            </li>
                        }

                        {this.isModuleActive("Notifications") && commonService.canAccessNotifications() &&
                            <>
                                <li onClick={this.props.menuClick}>
                                    <Link to="/notifications" className={this.getMenuItemClassName("/Notifications")}>
                                        <FontAwesomeIcon icon={faBell} className="icon" />
                                        <span>Notifications</span>
                                    </Link>
                                </li>
                            </>
                        }

                        {this.isModuleActive("Templates") && commonService.canAccessTemplates() &&
                            <>
                                <li onClick={this.props.menuClick}>
                                    <Link to="/templates" className={this.getMenuItemClassName("/Templates")}>
                                        <FontAwesomeIcon icon={faSuitcase} className="icon" />
                                        <span>Templates</span>
                                    </Link>
                                </li>
                            </>
                        }

                        {this.state.isAdmin &&
                            <>
                                <li className="menu-title">Admin</li>
                                <li onClick={this.props.menuClick}>
                                    <Link to="/admin/departments" className={this.getMenuItemClassName("/Admin/Departments")}>
                                        <FontAwesomeIcon icon={faShareAlt} className="icon"/>
                                        <span>Departments</span>
                                    </Link>
                                </li>
                                <li onClick={this.props.menuClick}>
                                    <Link to="/admin/users" className={this.getMenuItemClassName("/Admin/Users")}>
                                        <FontAwesomeIcon icon={faUser} className="icon"/>
                                        <span>Users</span>
                                    </Link>
                                </li>
                            </>
                        }

                    </ul>
                </div>
            </div>
        );
    }
}

const WrappedSideBarContent = withRouter(SideBarContent);
export { WrappedSideBarContent as SideBarContent };
