import React, { useEffect, useState } from "react";
import { IBasicDialogProps } from "../Common/FormGroups";
import FormModal from "../Common/FormModal";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";

function EnterReportNameDialog(props: IBasicDialogProps<string>) {
  const [reportName, setReportName] = useState(props.data || "");
  useEffect(() => {
    commonService.focusInput("reportName", 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title="Set Report Name"
      isOpen={true}
      close={() => {
        props.onClose();
      }}
      submit={() => {
        if (commonService.isNullOrWhitespace(reportName)) {
          toastStore.showToast("Please select enter Report Name", "warning");
        } else {
          props.onChange && props.onChange(reportName);
          props.onClose();
        }
      }}
      closeButtonLabel="Cancel"
      submitButtonLabel="Continue"
      disableSubmit={commonService.isNullOrWhitespace(reportName)}
    >
      <div>
        <input
          type="text"
          autoComplete="new-password"
          className="form-control"
          placeholder="Report Name"
          value={reportName}
          id="reportName"
          onChange={(e) => {
            const val = e.target.value || "";
            setReportName(val);
          }}
        />
      </div>
    </FormModal>
  );
}

export default EnterReportNameDialog;
