import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsLookupEnum } from "../../NFIRSHelper";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import { reaction } from "mobx";

function NFIRSEntryDamage(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsStructureFireDamage.update({
      ...model.new,
      isBelowGrade: model.new.isBelowGrade || false,
    });
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsStructureFireDamageInputDto>({
    objectName: "Damage",
    nfirsSectionTab: "Structure Fire/Damage",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsStructureFireDamageInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Structure Fire",
    "Damage",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Structure Fire", "Damage", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const fireSpreads = useNfirsLookupEnum(
    "NfirsStructureFireFireSpreadEnum",
    "Fire Spread Options"
  );

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();
  const [requiredFieldEnforced, setRequiredFieldEnforced] = useState(
    nfirsStore.nfirsStructureFireRequiredEnforced
  );
  useEffect(() => {
    setProgress({ loading: true });
    fireSpreads.getData();

    const disposer = reaction(
      () => nfirsStore.nfirsStructureFireRequiredEnforced,
      (n, p, r) => {
        setRequiredFieldEnforced(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsStructureFireDamage
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Detector Info", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      fireSpreads.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress.errorCode, fireSpreads.status]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    storyOfFireOrigin: {
      displayName: "Story of Fire Origin",
      validation: {
        required: false,
        max: 999,
        validate: {
          required: (val: any) => {
            if (requiredFieldEnforced && cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsStructureFireFireSpreadEnum: {
      displayName: "Fire Spread",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (requiredFieldEnforced && cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsTypeOfMaterialEntryId: {
      displayName: "Item Contributing Most to Flame Spread",
      validation: {
        required: false,
      },
    },
    nfirsTypeOfMaterialContributingToFlameSpreadEntryId: {
      displayName: "Type of Material Contributing Most to Flame Spread",
      validation: {
        required: false,
      },
    },
    noStoriesWithMinorDamage: {
      displayName: "Stories with Minor Damage",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    noStoriesWithSignificantDamage: {
      displayName: "Stories with Significant Damage",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    noStoriesWithHeavyDamage: {
      displayName: "Stories with Heavy Damage",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    noStoriesWithExtremeDamage: {
      displayName: "Stories with Extreme Damage",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 ">
          <strong>Damage</strong>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div>
            <FgInput
              id="storyOfFireOrigin"
              label="Story of Fire Origin"
              placeHolder="Story of Fire Origin"
              registeredField={registry.storyOfFireOrigin}
              formState={formState}
              showRequiredLabel={
                isOtherModuleDataRequired && requiredFieldEnforced
              }
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  storyOfFireOrigin: data === "" ? (null as any) : Number(data),
                });
              }}
              max={999}
              type="number"
            ></FgInput>

            <div className="mt-3 mb-3">
              <label>Is it below grade?</label>
              {!progress.loading && (
                <div className="inline-radio-box-group">
                  <div>
                    <input
                      type="radio"
                      name="isBelowGrade"
                      id="isBelowGradeYes"
                      value="1"
                      checked={model.new.isBelowGrade}
                      onChange={(event) => {
                        onModelChange({
                          ...model.new,
                          isBelowGrade: Number(event.target.value) === 1,
                        });
                      }}
                    ></input>
                    <label htmlFor="isBelowGradeYes">Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="isBelowGrade"
                      id="isBelowGradeNo"
                      value="0"
                      checked={!model.new.isBelowGrade}
                      onChange={(event) => {
                        onModelChange({
                          ...model.new,
                          isBelowGrade: Number(event.target.value) === 1,
                        });
                      }}
                    ></input>
                    <label htmlFor="isBelowGradeNo">No</label>
                  </div>
                </div>
              )}
            </div>

            <FgSelect
              id="nfirsStructureFireFireSpreadEnum"
              label="Fire Spread"
              selectMessage="Select Fire Spread"
              registeredField={registry.nfirsStructureFireFireSpreadEnum}
              formState={formState}
              showRequiredLabel={
                isOtherModuleDataRequired && requiredFieldEnforced
              }
              options={(fireSpreads.data || []).map((o) => {
                return {
                  label: `${o.value}. ${o.label}`,
                  value: o.value,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsStructureFireFireSpreadEnum:
                    Number(data) || (undefined as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="mt-3"></div>
        </div>
        <div className={`col-sm-12 col-lg-6 mt-3`}>
          <FgInput
            id="noStoriesWithMinorDamage"
            label="Stories with Minor Damage"
            placeHolder="Stories with Minor Damage"
            registeredField={registry.noStoriesWithMinorDamage}
            formState={formState}
            onChange={(data) => {
              onModelChange({
                ...model.new,
                noStoriesWithMinorDamage:
                  data === "" ? (null as any) : Number(data),
              });
            }}
            type="number"
          ></FgInput>
          <FgInput
            id="noStoriesWithSignificantDamage"
            label="Stories with Significant Damage"
            placeHolder="Stories with Significant Damage"
            registeredField={registry.noStoriesWithSignificantDamage}
            formState={formState}
            onChange={(data) => {
              onModelChange({
                ...model.new,
                noStoriesWithSignificantDamage:
                  data === "" ? (null as any) : Number(data),
              });
            }}
            type="number"
          ></FgInput>
          <FgInput
            id="noStoriesWithHeavyDamage"
            label="Stories with Heavy Damage"
            placeHolder="Stories with Heavy Damage"
            registeredField={registry.noStoriesWithHeavyDamage}
            formState={formState}
            onChange={(data) => {
              onModelChange({
                ...model.new,
                noStoriesWithHeavyDamage:
                  data === "" ? (null as any) : Number(data),
              });
            }}
            type="number"
          ></FgInput>
          <FgInput
            id="noStoriesWithExtremeDamage"
            label="Stories with Extreme Damage"
            placeHolder="Stories with Extreme Damage"
            registeredField={registry.noStoriesWithExtremeDamage}
            formState={formState}
            onChange={(data) => {
              onModelChange({
                ...model.new,
                noStoriesWithExtremeDamage:
                  data === "" ? (null as any) : Number(data),
              });
            }}
            type="number"
          ></FgInput>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSEntryDamage;
