import React, { useEffect, useState } from "react";
import CourseRecordTypeahead from "../CourseRecords/Typeaheads/CourseRecordTypeahead";
import ApsModels from "../../models";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ApsServices from "../../services";
import toastStore from "../../stores/ToastStore";
import { FgSelect, FgUseForm } from "../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import commonService from "../../services/CommonService";
import FormError from "../Common/FormError";
import { useDepartmentId } from "../../stores/SystemStore";
import ConfirmDialog from "../Common/ConfirmDialog";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import moment from "moment";
import { RouteComponentProps } from "react-router";

function Assignments(props: RouteComponentProps<any>) {
  const departmentId = useDepartmentId();
  const [items, setItems] = useState<
    {
      id: any;
      label: string;
      type: "Course" | "Credential" | "Drill" | "OnlineTrainingCourse";
    }[]
  >([]);
  const [model, setModel] = useState<ApsModels.IAssignAdminItemsDto>({
    userIds: [],
    courseIds: [],
    credentialIds: [],
    drillIds: [],
    onlineTrainingCourseDtos: [],
    assignmentDateString: moment().add(1, "days").format("YYYY-MM-DD"),
    saveIndividual: false,
  });
  const [type, setType] = useState<
    "Courses" | "Credentials" | "Drills" | "OnlineTraining" | ""
  >("");

  const [creds, setCreds] = useState([] as any[]);
  const [isLoadingCreds, setIsLoadingCreds] = useState(false);
  const refCreds = React.createRef<AsyncTypeahead<any>>();

  const [onlineTrainingCourses, setOnlineTrainingCourses] = useState(
    [] as any[]
  );
  const [isLoadingOnlineTrainingCourses, setIsLoadingOnlineTrainingCourses] =
    useState(false);
  const refOnlineTrainingCourses = React.createRef<AsyncTypeahead<any>>();

  const refDrill = React.createRef<AsyncTypeahead<any>>();
  const [openDrillDropdown, setOpenDrillDropdown] = useState(false);

  const [instanceId, setInstanceId] = useState(commonService.getUniqueId());
  const reset = () => {
    setEmployees([]);
    setItems([]);
    setModel({
      userIds: [],
      courseIds: [],
      credentialIds: [],
      drillIds: [],
      onlineTrainingCourseDtos: [],
      assignmentDateString: moment().add(1, "days").format("YYYY-MM-DD"),
      saveIndividual: false,
    });
    setValue(
      "assignmentDateString",
      moment().add(1, "days").format("YYYY-MM-DD")
    );
    setInstanceId(commonService.getUniqueId());
  };

  const handleSearchCreds = async (query: string) => {
    if ((query || "").trim() === "") {
      setCreds([]);
      setIsLoadingCreds(false);
      return;
    }

    setIsLoadingCreds(true);
    await ApsServices.http.assignment
      .typeAheadCredential({
        search: query,
        isAnd: false,
        recordCount: 10,
        libraryTypeEnum: ApsModels.CredLibraryTypeEnum.All,
        categoryId: null as any,
        onlyRenewal: false,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          displayName: `${i.category} - ${i.name} (${i.code})`,
          name: `${i.name}`,
          code: `${i.code}`,
        }));

        setCreds(options);
        setIsLoadingCreds(false);
      })
      .catch((err) => {
        setCreds([]);
        setIsLoadingCreds(false);
      });
  };

  const handleSearchOnlineTraingCourses = async (query: string) => {
    if ((query || "").trim() === "") {
      setOnlineTrainingCourses([]);
      setIsLoadingOnlineTrainingCourses(false);
      return;
    }

    setIsLoadingOnlineTrainingCourses(true);
    await ApsServices.http.assignment
      .typeAheadOnlineTrainingCourse({
        search: query,
        isAnd: false,
        recordCount: 10,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: `${i.id || ""},${i.lmsCourseId || ""}`,
          name: `${i.name} (${i.number})`,
          displayName: `${i.category} - ${i.name}`,
          code: `${i.number}`,
          model: { ...i },
        }));
        setOnlineTrainingCourses(options);
        setIsLoadingOnlineTrainingCourses(false);
      })
      .catch((err) => {
        setOnlineTrainingCourses([]);
        setIsLoadingOnlineTrainingCourses(false);
      });
  };

  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<
    {
      name: string;
      id: number;
    }[]
  >([]);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const [openEmpDropdown, setOpenEmpDropdown] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const drillTemplate = useNfirsGetData(
    ApsServices.http.assignment.getDrillForSubmission,
    "Drills"
  );

  const getEmployees = useNfirsGetData(
    async () => ApsServices.http.group.getAllUsers(selectedGroup),
    "Employees"
  );

  const [selectedGroup, setSelectedGroup] = useState<number>();
  const groups = useNfirsGetData(
    ApsServices.http.group.listAllGroups,
    "Groups"
  );

  useEffect(() => {
    groups.getData();
    drillTemplate.getData();
    setValue(
      "assignmentDateString",
      moment().add(1, "days").format("YYYY-MM-DD")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const tmo = setTimeout(() => {
      setIsLoading(false);
      if (groups.status === NfirsFetchStatus.Complete) {
        getEmployees.getData();
      }
    }, 500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, departmentId, groups.status]);

  useEffect(() => {
    setIsLoading(getEmployees.status !== NfirsFetchStatus.Complete);
    if (
      getEmployees.status === NfirsFetchStatus.Complete &&
      getEmployees.data
    ) {
      setUsers((list) => {
        return [
          ...(getEmployees.data || []).map((i) => {
            return {
              id: i.id,
              name: `${i.firstName} ${i.lastName}`,
              firstName: i.firstName,
              lastName: i.lastName,
            };
          }),
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployees.status]);

  const { registry, handleSubmit, formState, setValue, formRef } = FgUseForm({
    assignments: {
      displayName: "Assignments",
      validation: {
        required: false,
        validate: {
          assignments: (val: any) => {
            if (items.length === 0) {
              return "Please Select Assignment(s)";
            }
            return true;
          },
        },
      },
    },
    employeeName: {
      displayName: "User Name",
      validation: {
        required: false,
        validate: {
          employees: (val: any) => {
            if (employees.length === 0) {
              return "Please Select User(s)";
            }
            return true;
          },
        },
      },
    },
    assignmentDateString: {
      //Invalid date
      displayName: "Assignment Due Date",
      validation: {
        required: true,
        validate: {
          invalid: (val: any) => {
            if (val === "Invalid date") {
              return "Invalid Date";
            }
            if (moment().startOf("day").isAfter(val)) {
              return "Date must be greater than today";
            }
            return true;
          },
        },
      },
    },
  });

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [savedWithErrors, setSavedWithErrors] = useState<any[]>([]);

  const submit = async (data: ApsModels.IAssignAdminItemsDto) => {
    setSaving(true);
    await ApsServices.http.assignment
      .assignAdminToUsers({
        assignmentDateString: commonService.toStartOfDayDateString(
          data.assignmentDateString
        ),
        userIds: employees.map((e) => e.id),
        courseIds: items.filter((i) => i.type === "Course").map((i) => i.id),
        credentialIds: items
          .filter((i) => i.type === "Credential")
          .map((i) => i.id),
        drillIds: items.filter((i) => i.type === "Drill").map((i) => i.id),
        saveIndividual: model.saveIndividual,
        onlineTrainingCourseDtos: items
          .filter((i) => i.type === "OnlineTrainingCourse")
          .map((i) => {
            const ids = `${i.id || ""}`.split(",");
            return {
              credCourseId: ids[0] || (null as any),
              lmsCourseId: ids[1] || (null as any),
            };
          }),
      })
      .then((data) => {
        toastStore.showToast("Assignments Saved", "success");
        setSaved(true);
        props.history.replace("/assignments" + commonService.doneUrls.assigned);
      })
      .catch((err) => {
        const fname = "Successful-Assignment";
        const errList = [...(err.Errors || [])];
        const errSavedSuccess = errList.find((e) => e.FieldName === fname);
        if (errSavedSuccess) {
          setSaved(true);
          setSavedWithErrors(errList);
          toastStore.showError("Failed Saving Some Assignments", {
            ...err,
            Errors: errList.filter((e) => e.FieldName !== fname),
          });
          toastStore.showToast(errSavedSuccess.Message, "success");
        } else {
          toastStore.showError("Failed Saving Assignments", err);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <ConfirmDialog
        show={deleteAll}
        buttons="yesno"
        title="Confirmation"
        message="Do you reall want to delete all users selected?"
        done={(rtn) => {
          if (rtn === "yes") {
            setEmployees([]);
          }
          setDeleteAll(false);
        }}
      />
      <ConfirmDialog
        show={saved}
        buttons={[
          {
            label: "OK",
            value: "ok",
            variant: "primary",
          },
        ]}
        title="Assignments Saved"
        message={
          savedWithErrors.length > 0
            ? savedWithErrors
                .map((e, i) => (i === 0 ? `${e.Message}<br/>` : e.Message))
                .join("<br/>")
            : "Assigments has been saved successfully."
        }
        done={(rtn) => {
          reset();
          setSaved(false);
          props.history.replace("/assignments");
        }}
      />
      <form
        id="assignmentform"
        className={`flex flex-1 flex-col`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  Assignments
                </span>
              </div>
              <div>
                {/* <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={saving}
                  onClick={(e) => {
                    reset();
                  }}
                >
                  Cancel and Close
                </button> */}
                <button
                  id="assign-update"
                  className="btn btn-success"
                  type="submit"
                  disabled={saving}
                >
                  {saving ? "Assigning..." : "Assign"}
                </button>
              </div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <strong>1. Select Assignment</strong>

                  <div className="pt-4">
                    <label className="required-label">Search Assignment</label>
                    <select
                      className="form-control"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value as any);
                      }}
                    >
                      <option value="">Select Assignment Type</option>
                      <option value="Credentials">Credentials</option>
                      <option value="Courses">Courses</option>
                      <option value="Drills">Drills</option>
                      <option value="OnlineTraining">Online Training</option>
                    </select>
                    <div
                      className={`pt-2 ${
                        type === "Courses" ? "" : "display-none"
                      }`}
                    >
                      <CourseRecordTypeahead
                        forAssignmentPage={true}
                        placeholder="Search Course"
                        libType={ApsModels.CredLibraryTypeEnum.All}
                        onChange={(data, ref) => {
                          if (data && data.length > 0) {
                            ref.current?.clear();
                            const course = data[0]
                              .model as ApsModels.ICourseTypeAheadOutputDto;
                            if (course) {
                              if (
                                items.find(
                                  (i) =>
                                    i.type === "Course" && i.id === course.id
                                )
                              ) {
                                toastStore.showToast(
                                  `Selected Course '${course.name}' already exists.`,
                                  "warning"
                                );
                                return;
                              }
                              setItems([
                                ...items,
                                {
                                  type: "Course",
                                  id: course.id,
                                  label: `${course.number} - ${course.name}`,
                                },
                              ]);
                            }
                          }
                        }}
                      ></CourseRecordTypeahead>
                    </div>
                    <div
                      className={`pt-2 ${
                        type === "Credentials" ? "" : "display-none"
                      }`}
                      id="typeaheadSearchCredentialContainer"
                    >
                      <AsyncTypeahead
                        id="typeaheadSearchCredential"
                        labelKey="displayName"
                        onSearch={handleSearchCreds}
                        onChange={(data) => {
                          if (data && data.length > 0) {
                            (refCreds.current as any)?.clear();
                            const cred = data[0];
                            if (cred) {
                              if (
                                items.find(
                                  (i) =>
                                    i.type === "Credential" && i.id === cred.id
                                )
                              ) {
                                toastStore.showToast(
                                  `Selected Credential '${cred.name}' already exists.`,
                                  "warning"
                                );
                                return;
                              }
                              setItems([
                                ...items,
                                {
                                  type: "Credential",
                                  id: cred.id,
                                  label: `${cred.code} - ${cred.name}`,
                                },
                              ]);
                            }
                          }
                        }}
                        searchText={"Searching..."}
                        isLoading={isLoadingCreds}
                        options={creds}
                        placeholder="Search Credential"
                        minLength={1}
                        delay={500}
                        useCache={false}
                        ref={refCreds}
                      />
                    </div>
                    <div
                      className={`pt-2 ${
                        type === "Drills" ? "" : "display-none"
                      }`}
                      id="typeaheadSearchDrillContainer"
                    >
                      <AsyncTypeahead
                        id="basic-typeahead-drill"
                        labelKey="name"
                        onSearch={(e) => {
                          setOpenDrillDropdown(true);
                        }} //handleSearch
                        open={openDrillDropdown}
                        onFocus={(e) => setOpenDrillDropdown(true)}
                        onBlur={(e) => setOpenDrillDropdown(false)}
                        onChange={(data) => {
                          if (data && data.length > 0) {
                            (refDrill.current as any)?.clear();
                            const drill = data[0];
                            if (drill) {
                              if (
                                items.find(
                                  (i) => i.type === "Drill" && i.id === drill.id
                                )
                              ) {
                                toastStore.showToast(
                                  `Selected Drill '${drill.name}' already exists.`,
                                  "warning"
                                );
                                return;
                              }
                              setItems([
                                ...items,
                                {
                                  type: "Drill",
                                  id: drill.id,
                                  label: `${drill.name}`,
                                },
                              ]);
                              setOpenDrillDropdown(false);
                              document.body.blur();
                            }
                          }
                        }}
                        searchText={"Searching..."}
                        isLoading={false}
                        options={(drillTemplate.data?.drills || []).sort(
                          commonService.sortByName
                        )}
                        placeholder="Search Drill"
                        promptText={
                          drillTemplate.status === NfirsFetchStatus.InProgress
                            ? "Loading..."
                            : "No Records Found"
                        }
                        minLength={1}
                        delay={500}
                        useCache={false}
                        ref={refDrill}
                      />
                    </div>
                    <div
                      className={`pt-2 ${
                        type === "OnlineTraining" ? "" : "display-none"
                      }`}
                      id="typeaheadSearchOnlineTrainingContainer"
                    >
                      <AsyncTypeahead
                        id="typeaheadSearchOnlineTraining"
                        labelKey={(option) => option.name}
                        onSearch={handleSearchOnlineTraingCourses}
                        onChange={(data) => {
                          if (data && data.length > 0) {
                            (refOnlineTrainingCourses.current as any)?.clear();
                            const otCourse = data[0];
                            if (otCourse) {
                              if (
                                items.find(
                                  (i) =>
                                    i.type === "OnlineTrainingCourse" &&
                                    i.id === otCourse.id
                                )
                              ) {
                                toastStore.showToast(
                                  `Selected Online Training Course '${otCourse.name}' already exists.`,
                                  "warning"
                                );
                                return;
                              }
                              setItems([
                                ...items,
                                {
                                  type: "OnlineTrainingCourse",
                                  id: otCourse.id,
                                  label: `${otCourse.code} - ${otCourse.name}`,
                                },
                              ]);
                            }
                          }
                        }}
                        searchText={"Searching..."}
                        isLoading={isLoadingOnlineTrainingCourses}
                        options={onlineTrainingCourses}
                        placeholder="Search Online Training Courses"
                        minLength={1}
                        delay={500}
                        useCache={false}
                        ref={refOnlineTrainingCourses}
                      />
                    </div>
                    <FormError
                      field={registry.assignments.name}
                      formState={formState}
                    ></FormError>

                    <div className="currentParticipants mt-4">
                      {items?.map((x) => (
                        <div key={x.id}>
                          <section className="pe-2">
                            {x.type} | {x.label}
                          </section>
                          <section
                            title="Remove"
                            onClick={() => {
                              setItems((p) => {
                                return [
                                  ...p.filter(
                                    (item) =>
                                      !(
                                        item.type === x.type && item.id === x.id
                                      )
                                  ),
                                ];
                              });
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </section>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <strong>2. Select Users</strong>
                  <div className="pt-4">
                    <>
                      <div>
                        <label className="required-label">Search Users</label>
                      </div>
                      <FgSelect
                        id="_rank"
                        label=""
                        noPlaceholder={true}
                        groups={[
                          { id: 0, label: "By Users" },
                          { id: -1, label: "By Group" },
                        ]}
                        options={[
                          {
                            label: "Users",
                            value: 0,
                            groupId: 0,
                          },
                          ...(groups.data || []).map((cat) => {
                            return {
                              label: cat.name,
                              value: cat.groupId,
                              groupId: -1,
                            };
                          }),
                        ]}
                        disabledOptionValues={[-1]}
                        onChange={(data) => {
                          setSelectedGroup(commonService.getNumber(data));
                        }}
                        formState={formState}
                      />

                      <div className="text-primary mt-1 px-1 flex">
                        <div className="flex-1">
                          <span
                            className="pointer"
                            onClick={(e) => {
                              const emps = users
                                .filter(
                                  (emp) =>
                                    !employees.find((i) => i.id === emp.id)
                                )
                                .map((i) => {
                                  return {
                                    id: i.id,
                                    name: i.name,
                                  };
                                });
                              setEmployees((list) => {
                                return [...list, ...emps];
                              });
                              if (emps.length > 0) {
                                toastStore.showToast(
                                  emps.length === 1
                                    ? "1 User Added"
                                    : `${emps.length} Users Added`,
                                  "success"
                                );
                              } else {
                                toastStore.showToast(
                                  "No User(s) Added",
                                  "warning"
                                );
                              }
                            }}
                          >
                            {selectedGroup
                              ? `Add All ${
                                  (groups?.data || []).find(
                                    (x) => x.groupId === selectedGroup
                                  )?.name || ""
                                }`
                              : "Add All Users"}
                          </span>
                        </div>
                        <div>
                          {employees.length > 0 && (
                            <span
                              className="text-danger pointer"
                              onClick={(e) => {
                                setDeleteAll(true);
                              }}
                            >
                              Delete All
                            </span>
                          )}
                        </div>
                      </div>

                      <div>
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          onSearch={(e) => {}} //handleSearch
                          open={openEmpDropdown}
                          onFocus={(e) => setOpenEmpDropdown(true)}
                          onBlur={(e) => setOpenEmpDropdown(false)}
                          onChange={(data) => {
                            if (data && data.length > 0) {
                              const prts = [...employees];
                              if (
                                prts.filter((p) => p.id === data[0].id)
                                  .length === 0
                              ) {
                                prts.push({
                                  id: data[0].id,
                                  name: data[0].name,
                                });
                                setEmployees(prts);
                              } else {
                                toastStore.showToast(
                                  `${data[0].name} is already in the list.`,
                                  "warning"
                                );
                              }

                              //setUsers(data);
                              (ref.current as any)?.clear();
                              ref.current?.blur();
                            }
                          }}
                          searchText={"Searching..."}
                          isLoading={isLoading}
                          options={isLoading ? [] : users}
                          placeholder="Add or Search Users"
                          promptText={
                            isLoading ? "Loading..." : "No Records Found"
                          }
                          minLength={1}
                          delay={500}
                          useCache={false}
                          ref={ref}
                        />
                      </div>
                      <FormError
                        field={registry.employeeName.name}
                        formState={formState}
                      ></FormError>
                      <div className="mt-3"></div>

                      <div className="currentParticipants mb-3">
                        {employees
                          ?.sort(commonService.sortByStringProperty("name"))
                          ?.map((p, i) => {
                            return (
                              <div key={i}>
                                <section>{`${p.name}`}</section>
                                <section
                                  title="Remove"
                                  onClick={() => {
                                    const prts = [...employees];
                                    prts.splice(i, 1);
                                    setEmployees(prts);
                                  }}
                                >
                                  <i className="fa fa-times"></i>
                                </section>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <strong>3. Assign Date</strong>
                  <div className="pt-4">
                    <div className="mb-3">
                      <label className="required-label">Assignment Due Date</label>
                      <DateTimePickerV2
                        key={instanceId}
                        data={model.assignmentDateString || ""}
                        onChange={(data, invalid) => {
                          const val = invalid
                            ? "Invalid date"
                            : data
                            ? moment(data).format("YYYY-MM-DD")
                            : ("" as any);

                          setModel((p) => {
                            return {
                              ...p,
                              assignmentDateString: val,
                            };
                          });
                          setValue("assignmentDateString", val);
                        }}
                        dateOnly={true}
                        className="flex-1"
                      ></DateTimePickerV2>
                      <FormError
                        formState={formState}
                        field={registry.assignmentDateString.name}
                      ></FormError>
                    </div>
                  </div>
                  {type === "Drills" && (
                    <div className="flex flex-center">
                      <input
                        type="checkbox"
                        id="checkSaveIndividual"
                        className="me-2"
                        checked={!model.saveIndividual}
                        onChange={(e) => {
                          const chk = !(e.target.checked || false);
                          setModel((p) => {
                            return {
                              ...p,
                              saveIndividual: chk,
                            };
                          });
                        }}
                      />
                      <label htmlFor="checkSaveIndividual" className="m-0">
                        Mark as completed for all participants
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Assignments;
