export interface DepartmentSummaryDto {
    departmentId: number,
    name: string,
    departmentCode: string,
    timeZoneId?: number,
    lastModified: Date | null,
    isActive: boolean,
    moduleAccess: string,
    learnUponUrl: string,
    logo: string
}

export interface DepartmentDto {
    departmentId?: number,
    name?: string,
    departmentCode?: string,
    timeZoneId?: number,
    learnUponUrl: string,
    isActive: boolean,
    modules: ModulesDto[],
    logo: string
}

export interface ModulesDto {
    moduleId : number,
    name : string,
    isSelected : boolean
}

export enum Departments {
    Glendale = 2,
    MontereyPark = 20001,
    Monrovia = 30000,
    WestCovina = 40001,
    Avalon = 70000
}