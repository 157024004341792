import React from 'react';
import {
    AnswerDto,
    AnswerUserReferenceDto,
    IsolatedInputDetails,
    QuestionDto,
    QuestionGroupDto
} from '../../../data/DailyOperational';
import { OptionDto } from '../../../data/Option';
import { GroupService } from '../../../services/GroupService';
import { UserDto } from '../../../data/User';
import { UserService } from '../../../services/UserService';
import { IsolatedInput } from '../CustomInput/IsolatedInput';
import { IsolatedInputTextArea } from '../CustomInput/IsolatedInputTextArea';
import { PullFromPreviousDayButton } from "../CustomInput/PullFromPreviousDayButton";
import { PreviousDayService } from "../../../services/PreviousDayService";
import CommonSpinner from '../../../aps2/components/Common/CommonSpinner';
import { Departments } from '../../../data/Department';

type Props = {
    questionGroup?: QuestionGroupDto,
    date: Date,
    setData: (value: QuestionGroupDto | undefined, order: number) => void
    moduleOrder: number,
    departmentId: number
}

type State = {
    text?: string,
    userDto: UserDto[],
    wsaOptions: OptionDto[],
    ready: boolean
}


type IsolatedInputListProps = {
    defaultItemsCount: number,
    preventExpanding?: boolean,
    answerDto?: AnswerDto[],
    userDto?: UserDto[],
    optionsDto?: OptionDto[],
    usersSelected?: UserDto[],
    isDropdown: boolean,
    question?: QuestionDto,
    setData?: (value: QuestionDto | undefined) => void,
    date: Date,
    questionGroup?: QuestionDto[]
}

type IsolatedInputListState = {
    items: IsolatedInputDetails[],
    userDto?: UserDto[],
    optionsDto?: OptionDto[],
    usersSelected?: UserDto[],
    answer: AnswerDto[] | undefined
}


export class IsolatedInputListUsarResponse extends React.Component<IsolatedInputListProps, IsolatedInputListState> {
    state = {
        items: new Array<IsolatedInputDetails>(),
        userDto: [],
        optionsDto: [],
        usersSelected: [],
        answer: new Array<AnswerDto>()
    }

    componentDidMount = async () => {
        for (let i = 0; i < this.props.defaultItemsCount; i++) {
            const item: IsolatedInputDetails = ({ isFirst: i === 0, isLast: i === this.props.defaultItemsCount - 1 });
            this.setState(prevState => ({
                items: [...prevState.items, item]
            }));
        }
    }

    componentDidUpdate = async (prevProps: IsolatedInputListProps) => {
        if (this.props !== prevProps) {
            this.setState({
                items: [],
                answer: undefined
            });
            for (let i = 0; i < this.props.defaultItemsCount; i++) {
                const item: IsolatedInputDetails = ({ isFirst: i === 0, isLast: i === this.props.defaultItemsCount - 1 });
                this.setState(prevState => ({
                    items: [...prevState.items, item],
                    answer: this.props.question?.answers
                }));
            }
        }
    }

    handleSectionInputChanged = (isLast: boolean): void => {
        if (this.props.preventExpanding) return;
        if (!isLast) return;

        const items = this.state.items;
        items[items.length - 1].isLast = false;
        items.push({ isFirst: false, isLast: true });

        this.setState({
            items: items,
        });
    }

    setData = (question: QuestionDto | undefined) => {
        if (this.props.setData) {
            this.props.setData(question)
        }
    }

    render() {
        return (
            this.state.items.map((item, index) => {
                return this.props.isDropdown ? (
                    <IsolatedInput
                        key={index}
                        userDto={this.props.userDto}
                        optionsDto={this.props.optionsDto}
                        isFirst={item.isFirst}
                        isLast={item.isLast}
                        question={this.props.question}
                        answerDto={this.state.answer?.find(x => x.order === index)}
                        onChange={this.handleSectionInputChanged}
                        setData={this.setData}
                        order={index}
                        date={this.props.date}
                    />
                ) : (
                        <IsolatedInputTextArea
                            key={index}
                            userDto={this.props.userDto}
                            optionsDto={this.props.optionsDto}
                            isFirst={item.isFirst}
                            isLast={item.isLast}
                            question={this.props.question}
                            answerDto={this.state.answer?.find(x => x.order === index)}
                            onChange={this.handleSectionInputChanged}
                            setData={this.setData}
                            order={index}
                            questionGroup={this.props.questionGroup}
                            date={this.props.date}
                        />
                    )
            })
        )
    }
}

export class UsarResponse extends React.Component<Props, State> {
    state: State = {
        text: "",
        userDto: [],
        wsaOptions: new Array<OptionDto>(),
        ready: false
    }

    componentDidMount = async () => {
        const allGroup = await GroupService.getAllGroups();
        const usar = allGroup.find(x => x.name === "USAR");
        const users = await UserService.getUsersInGroupByDepartmentId(usar?.groupId ?? 0, this.props.departmentId);
        this.setState({
            userDto: users
        });
        this.setState({ ready: true });
    }


    getRowsNumber = (expected: number, actual: AnswerDto[] | undefined) => {
        const orders = new Array<number>();
        if (actual?.length) {
            actual.map(x => orders.push(x.order))
            const maxNumber = Math.max(...orders)
            if (expected <= actual.length) {
                if (actual.length <= maxNumber) {
                    return maxNumber + 1
                }
                return actual.length + 1
            }
        }
        return expected
    }


    factorySubGroup = (value: QuestionDto | undefined) => {
        const questionGroup = this.props.questionGroup
        const index = this.props.questionGroup?.questions.findIndex(x => x.order === value?.order)
        if (index !== undefined) {
            if (questionGroup?.questions[index] && value) {
                questionGroup.questions[index] = value
            }
        }
        this.props.setData(questionGroup, this.props.moduleOrder)
    }

    handlePullFromPreviousDay = async (newDayQuestionGroup: QuestionGroupDto) => {
        this.props.setData(newDayQuestionGroup, this.props.moduleOrder);
    }

    render() {
        return (
            <div className="grid-item card box-card flex-grow-1">
                {!this.state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
                <div className="card-body">
                    <div className="row">
                        <h4 className="mb-0 font-size-16 col-xl-11 col-10">{this.props.questionGroup?.name || ''}</h4>
                        {PreviousDayService.isFeatureAvailable(this.props.date) && <PullFromPreviousDayButton questionGroupId={this.props.questionGroup?.questionGroupId!} calendarDate={this.props.date} setData={this.handlePullFromPreviousDay} />}
                    </div>
                    <div className="row mt-3">
                        <div className="response-first-column col-lg-3">
                            <h4 className="font-size-16">Response</h4>
                            {this.props.questionGroup?.questions.find(x => x.order === 0)?.answers && <IsolatedInputListUsarResponse setData={this.factorySubGroup} defaultItemsCount={this.getRowsNumber(2, this.props.questionGroup?.questions.find(x => x.order === 0)?.answers)} question={this.props.questionGroup?.questions.find(x => x.order === 0)} answerDto={this.props.questionGroup?.questions.find(x => x.order === 0)?.answers} userDto={this.state.userDto} isDropdown={true} date={this.props.date} />}
                        </div>
                        <div className="response-first-column col-lg-3 mt-7">
                            {this.props.questionGroup?.questions.find(x => x.order === 1)?.answers && <IsolatedInputListUsarResponse setData={this.factorySubGroup} defaultItemsCount={this.getRowsNumber(2, this.props.questionGroup?.questions.find(x => x.order === 1)?.answers)} question={this.props.questionGroup?.questions.find(x => x.order === 1)} answerDto={this.props.questionGroup?.questions.find(x => x.order === 1)?.answers} userDto={this.state.userDto} isDropdown={true} date={this.props.date} />}
                        </div>
                        <div className="response-first-column col-lg-3 mt-7">
                            {this.props.questionGroup?.questions.find(x => x.order === 2)?.answers && <IsolatedInputListUsarResponse setData={this.factorySubGroup} defaultItemsCount={this.getRowsNumber(2, this.props.questionGroup?.questions.find(x => x.order === 2)?.answers)} question={this.props.questionGroup?.questions.find(x => x.order === 2)} answerDto={this.props.questionGroup?.questions.find(x => x.order === 2)?.answers} userDto={this.state.userDto} isDropdown={true} date={this.props.date} />}
                        </div>
                        <div className="col-lg-3">
                            <h4 className="font-size-16">Comments</h4>
                            {this.props.questionGroup?.questions.find(x => x.order === 3)?.answers && <IsolatedInputListUsarResponse setData={this.factorySubGroup} defaultItemsCount={this.getRowsNumber(1, undefined)} preventExpanding={true} question={this.props.questionGroup?.questions.find(x => x.order === 3)} answerDto={this.props.questionGroup?.questions.find(x => x.order === 3)?.answers} userDto={this.state.userDto} isDropdown={false} date={this.props.date} questionGroup={this.props.questionGroup.questions} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
