import { QuestionGroupDto } from "../../../../data/DailyOperational";
import fetcher from "../Fetcher";
class QuestionGroupService {
  save = async (data: QuestionGroupDto) => {
    const url = `/api/QuestionGroup/Save`;
    return fetcher.post<QuestionGroupDto>(url, data);
  };

  update = async (data: QuestionGroupDto) => {
    const url = `/api/QuestionGroup/Update`;
    return fetcher.put<QuestionGroupDto>(url, data);
  };

  delete = async (id: number) => {
    const url = `/api/QuestionGroup/Delete/${id}`;
    return fetcher.delete<any>(url);
  };
}

const questionGroup = new QuestionGroupService();
export default questionGroup;
