import React, { Component } from "react";
import SimpleBar from "simplebar-react";
import 'simplebar/dist/simplebar.min.css';
import './SideBar.css';
import { SideBarContent } from "./SideBarContent";

interface Props {
    isSideBarVisible: boolean;
    menuClick: (menu: any) => void
}

export class SideBar extends Component<Props> {
    static displayName = SideBar.name;

    render() {
        return (
            <div className="vertical-menu sidebar-outer">
                <div data-simplebar="true" className="h-100 sidebar-inner">
                    {this.props.isSideBarVisible
                        ?
                            <SimpleBar>
                                <SideBarContent menuClick={menu => this.props.menuClick(menu)} />
                            </SimpleBar>
                        : <SideBarContent menuClick={menu => this.props.menuClick(menu)} />
                    }
                </div>
            </div>
        )
    }
}