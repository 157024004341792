import ApsModels from "../../models";
import fetcher from "./Fetcher";

class DrillService {
  save = async (data: ApsModels.IDrillInputDto) => {
    const url = "/api/Drill/Save";
    return fetcher.post<ApsModels.IDrillOutputDto>(url, data);
  };

  getAll = async (deptId: number) => {
    const url = `/api/Drill/GetAll/${deptId}`;
    return fetcher.get<ApsModels.IDrillOutputDto[]>(url);
  };

  get = async (drillId: number) => {
    const url = `/api/Drill/${drillId}`;
    return fetcher.get<ApsModels.IExtraDrillInformationDto>(url);
  };
 
}

const drill = new DrillService();
export default drill;
