import ApsModels from "../../models";
import fetcher from "./Fetcher";

class TrainingReportService {
  trainingReportColumns = async () => {
    const url = `/api/TrainingReport/TrainingReportColumns`;
    return fetcher.get<ApsModels.ITrainingReportColumnsDto>(url);
  };

  get = async (id: number) => {
    const url = `/api/TrainingReport/${id}`;
    return fetcher.get<ApsModels.ITrainingReportOutputDto>(url);
  };

  create = async (data: ApsModels.ITrainingReportInputDto) => {
    const url = `/api/TrainingReport`;
    return fetcher.post<ApsModels.ITrainingReportOutputDto>(url, data);
  };

  update = async (id: number, data: ApsModels.ITrainingReportInputDto) => {
    const url = `/api/TrainingReport/${id}`;
    return fetcher.put<ApsModels.ITrainingReportOutputDto>(url, data);
  };

  delete = async (id: number) => {
    const url = `/api/TrainingReport/${id}`;
    return fetcher.delete<any>(url);
  };

  list = async (page: number, pageSize: number) => {
    const url = `/api/TrainingReport/List/${page}/${pageSize}`;
    return fetcher.get<ApsModels.ITrainingReportGridDto>(url);
  };

  extractReportData = async (
    data: ApsModels.ITrainingReportExtractSettingsDto
  ) => {
    const url = `/api/TrainingReport/ExtractReportData`;
    return fetcher.post<ApsModels.ITrainingReportExtractGridDto>(url, data);
  };
}

const trainingReport = new TrainingReportService();
export default trainingReport;
