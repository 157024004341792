import React, { ChangeEvent, Component } from 'react';

type Props = {
    onPageSizeChange: (pageSize: number) => void;
}

type State = { }

export class AgGridPageSizeSelector extends Component<Props, State> {
    onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const pageSize = Number(e.target.value);
        this.props.onPageSizeChange(pageSize);
    }

    render() {
        return (
            <div className="position-absolute" style={{ left: '38px', bottom: '30px' }}>
                Show
                <select className="mx-1" onChange={this.onChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100" selected={true}>100</option>
                </select>
                entries
            </div>
        );
    }
}