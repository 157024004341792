import {
    IScheduleInputDto, IScheduleOutputDto, IShiftOutputDto,
  } from "../aps2/models/_all";
  
class ShiftSchedulerService {

    getScheduleForDepartment = async (departmentId: number) => {
        const response = await fetch(`/api/ShiftScheduler/Schedule/${departmentId}`);
        if (response.ok) {
            // Unfortunately, the typewriter plugin doesn't detect that a .NET DateTimeOffset
            // should be represented as a JSON string. Here we convert the saved start date
            // into a string, stripping the timezone information, then casting it back to a date
            const dto = await response.json() as IScheduleOutputDto
            const date = dto.initialStartDate as unknown as string; // is really a string
            return {
                ...dto,
                initialStartDate: new Date(date.substring(0, "yyyy-MM-ddThh:mm".length))
            }
        }
    }

    createSchedule = async (dto: IScheduleInputDto) => {
        const initialStartDate = this.convertDateStrToDepartmentTimeZone(dto.initialStartDate, dto.departmentId);
        const options: RequestInit = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({...dto, initialStartDate}),
        };
        const response = await fetch(`/api/ShiftScheduler/Schedule`, options);
        return (await response.json()) as IScheduleOutputDto;
    }

    getShiftForDepartmentByDate = async (departmentId: number, date: string) => {
        const response = await fetch(`/api/ShiftScheduler/Shift/${departmentId}?datetime=${date}`);
        return await response.json() as IShiftOutputDto
    }

    /*
        Expects "yyyy-mm-ddThh:mm:ss" format
    */
    public convertDateStrToDepartmentTimeZone(date: string, departmentId: number) {
        const departmentTimeZoneOffset = '-07:00'; // In the future, we may need to query this dynamically
        const toReturn = new Date(date)
            .toLocaleString('sv') // Sweden format -- like ISO8601 but without the timezone
            .replace(' ', 'T') + departmentTimeZoneOffset;
        return toReturn;
    }
}

const shiftSchedulerService = new ShiftSchedulerService();
export { shiftSchedulerService as ShiftSchedulerService };