import React from 'react';
import {IsolatedSelectItem, IsolatedTableColumnDetails, OptionSelectionDetails} from '../../../data/DailyOperational';
import {AppContext} from "../../../AppContext";
import {IsolatedSelect} from "./IsolatedSelect";

type Props = {
    column: IsolatedTableColumnDetails,
    onChange: (selectionDetails: OptionSelectionDetails) => void
}

type State = {}

export class IsolatedTableRowInput extends React.Component<Props, State> {
    static contextType = AppContext;

    handleSectionInputChanged = (optionId?: number, optionName?: string): void => {
        const selectionDetails: OptionSelectionDetails = {
            columnIndex: this.props.column.columnIndex,
            optionId: optionId,
            optionName: optionName,
            index : optionId ? this.props.column.availableOptions.findIndex(x => x.optionId === optionId!) : undefined
        }
        this.props.onChange(selectionDetails);
    }

    render() {
        const items: IsolatedSelectItem[] = this.props.column.availableOptions.map(o => {return { id: o.optionId, name: o.name}});
        return (
    
                <td className="p-0">
                   <span className="col-12 d-none d-print-block">{ this.props.column.availableOptions.find(x => x.optionId === this.props.column.optionId)?.name ?? this.props.column.optionName}</span>
                    <div className='d-print-none' >
                        <IsolatedSelect onChange={this.handleSectionInputChanged} availableOptions={items} id={this.props.column.optionId} name={this.props.column.optionName}  className="col-12 rounded-10 h-8"/>
                    </div> 
                </td>
        )
    }
}
