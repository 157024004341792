import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import ConfirmDialog from "./ConfirmDialog";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  message?: string;
  title?: string;
}

function RouteChangedGuard({
  when,
  navigate,
  shouldBlockNavigation,
  message,
  title,
}: Props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt
        when={when}
        message={(loc) => handleBlockedNavigation(loc as any)}
      />
      <ConfirmDialog
        show={modalVisible}
        title={title || "Confirmation"}
        message={
          message ||
          "You have unsaved changes, do you really want to leave the page and discard changes?"
        }
        buttons="yesno"
        done={(resp) => {
          if (resp === "yes") {
            handleConfirmNavigationClick();
          } else {
            closeModal();
          }
        }}
      ></ConfirmDialog>
    </>
  );
}
export default RouteChangedGuard;
