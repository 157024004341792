import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import { usePaging } from "../Common/Pagination";
import CredentialImportDialog from "./CredentialImportDialog";
import CredentialExpirationDateCell from "./CredentialExpirationDateCell";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { CredLibraryTypeEnum } from "../../models/_all";
import { useGridFilters } from "../../stores/SystemStore";
import LoadMoreIndicator from "../Common/LoadMoreIndicator";

function CredentialListByCredential(
  props: RouteComponentProps<any> & { byLastModified?: boolean }
) {
  const employeeid = props.match?.params?.employeeid;
  const [credentialid, setCredentialid] = useState<{
    name: string;
    id: number;
  }>();
  const [claims] = useState(commonService.friendlyClaims);
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentId > 0) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [list, setList] =
    useState<ApsModels.ICredCredentialByCredentialGridOutputDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridFilters.init(params);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && list) {
      setTimeout(() => {
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach(function (column: any) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
        gridApi.sizeColumnsToFit();
      }, 500);
    }
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByCredentialGridItemOutputDto;
  }) => {
    if (claims.credentials.canViewAll) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-outlined text-primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.history.push(
              `/credentials/credential/${rowProps.data.credentialId}`
            );
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
      );
    }
    return <span></span>;
  };

  const NameCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByCredentialGridItemOutputDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.history.push(
            `/credentials/credential/${rowProps.data.credentialId}`
          );
        }}
      >
        {rowProps.data.credentialName}
      </span>
    );
  };

  const ExpirationCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByCredentialGridItemOutputDto;
  }) => {
    if (!rowProps.data.expirationDate) {
      return <span>Not Applicable</span>;
    }
    return (
      <CredentialExpirationDateCell
        date={rowProps.data.expirationDate}
      ></CredentialExpirationDateCell>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          return true;
        },
        field: "_selected",
        headerName: "",
        ...commonService.agGridHelpers.checkboxColDef,
      },
      {
        field: "credentialName",
        headerName: "Credential Name",
        cellRenderer: "nameCellComponent",
        minWidth: 250,
        suppressSizeToFit: false,
      },

      {
        field: "credentialCategory",
        headerName: "Credential Category",
      },
      {
        field: "credentialCode",
        headerName: "Credential Code",
      },
      {
        field: "numOfActive",
        headerName: "# Active",
      },
      {
        field: "numOfInProgress",
        headerName: "# In Progress (Earn / Renew)",
      },
      {
        field: "numOfExpired",
        headerName: "# Expired",
      },
      {
        field: "lastModifiedString",
        headerName: "Last Modified Date",
        ...commonService.agGridHelpers.dateValueFormatter("lastModified"),
      },
      {
        field: "numEmployees",
        headerName: "# Users",
        hide: true,
      },
      {
        field: "dueDateString",
        headerName: "Credential Due Date",
        ...commonService.agGridHelpers.dateValueFormatter(
          "dateIssued",
          "Not Applicable"
        ),
        hide: true,
      },
      {
        field: "expirationDateString",
        headerName: "Expiration Date",
        cellRenderer: "expirationCellComponent",
        width: 100,
        suppressSizeToFit: true,
        hide: true,
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "viewButtonCellComponent",
        width: 100,
        ...commonService.agGridHelpers.actionColPinnedDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      expirationCellComponent: ExpirationCellComponent,
      nameCellComponent: NameCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ICredCredentialByCredentialGridItemOutputDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const [isMyReportsOnly, setIsMyReportsOnly] = useState(true);

  const [loading, setLoading] = useState(false);
  const gridFilters = useGridFilters(
    "credentials-by-credential",
    gridApi,
    (others) => {
      if (others) {
        setIsMyReportsOnly(
          others.isMyReportsOnly === undefined ? true : others.isMyReportsOnly
        );
        if (others.credentialId) {
          setCredentialid({
            id: others.credentialId,
            name: others.credentialName,
          });
        }
      }
    }
  );
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageToLoad, setPageToLoad] = useState(1);

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();
    setLoading(true);

    const searchDto: ApsModels.ICredCredentialByCredentialSearchInputDto = {
      userId: Number(employeeid) || (null as any),
      credStatusEnum: ApsModels.CredStatusEnum.Active,
      isMyReportsOnly: isMyReportsOnly,
      byLastModified: props.byLastModified || false,
      credentialId: credentialid?.id || (null as any),
    };

    await (claims.credentials.canViewAll
      ? ApsServices.http.credCredential.listCredentialsByCredentialForAdminManager(
          1,
          commonService.gridPageSize.firstPage,
          searchDto
        )
      : ApsServices.http.credCredential.listCredentialsByCredentialForUser(
          1,
          commonService.gridPageSize.firstPage,
          searchDto
        )
    )
      .then((data) => {
        setList(data as any);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
        }

        gridFilters.saveOthers({
          userId: null as any,
          credStatusEnum: null as any,
          isMyReportsOnly: isMyReportsOnly,
          credentialId: credentialid?.id || (null as any),
          credentialName: credentialid?.name || (null as any),
        });
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Credentials", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);
      });
  };

  const loadMore = async () => {
    if (
      list &&
      list.totalRecords >
        list.credCredentialByCredentialGridItemOutputDtos?.length
    ) {
      setLoadingMore(true);
      setLoading(false);

      let pageNum = pageToLoad + 1;
      if (
        commonService.gridPageSize.firstPage <
          commonService.gridPageSize.otherPages &&
        list.credCredentialByCredentialGridItemOutputDtos?.length ===
          commonService.gridPageSize.firstPage
      ) {
        pageNum = 1;
      }

      setPageToLoad(pageNum);

      const searchDto: ApsModels.ICredCredentialByCredentialSearchInputDto = {
        userId: Number(employeeid) || (null as any),
        credStatusEnum: ApsModels.CredStatusEnum.Active,
        isMyReportsOnly: isMyReportsOnly,
        byLastModified: props.byLastModified || false,
        credentialId: credentialid?.id || (null as any),
      };

      await (claims.credentials.canViewAll
        ? ApsServices.http.credCredential.listCredentialsByCredentialForAdminManager(
            pageNum,
            commonService.gridPageSize.otherPages,
            searchDto
          )
        : ApsServices.http.credCredential.listCredentialsByCredentialForUser(
            pageNum,
            commonService.gridPageSize.otherPages,
            searchDto
          )
      )
        .then((data) => {
          setList((prev) => {
            return {
              totalRecords: prev?.totalRecords || data.totalRecords,
              credCredentialByCredentialGridItemOutputDtos:
                pageNum === 1
                  ? data.credCredentialByCredentialGridItemOutputDtos
                  : [
                      ...(prev?.credCredentialByCredentialGridItemOutputDtos ||
                        []),
                      ...data.credCredentialByCredentialGridItemOutputDtos,
                    ],
            };
          });
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Credentials", err);
          setLoadingMore(false);
        })
        .finally(() => {
          setLoadingMore(false);
          setLoading(false);
        });
    } else {
      gridFilters.reApplyFilters(); //need to reapply filter after adding more data
      toggleColSizes();
      setLoadingMore(false);
      setLoading(false);
    }
  };

  const [rowsSelectedCount, setRowSelectedCount] = useState(0);
  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (loading || loadingMore) {
      return;
    }
    if (list?.totalRecords) {
      gridApi && gridApi.hideOverlay();
    }
    setGridState({
      ...gridState,
      rowData: list?.credCredentialByCredentialGridItemOutputDtos || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, loadingMore]);

  useEffect(() => {
    if (gridApi && gridFilters.ready) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, paging, isMyReportsOnly, credentialid, gridFilters.ready]);

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    return gridApi.getSelectedRows().filter((row) => {
      return (
        !isAnyFilterPresent ||
        !!filteredRows.find(
          (r: any) => r.data.credentialId === row.credentialId
        )
      );
    });
  };

  const canAddCredentials = () => {
    if (claims.credentials.canAddAll) {
      return true;
    }
    return (
      claims.credentials.canAccess &&
      (!employeeid ||
        (Number(employeeid) === commonService.userInfo.userId &&
          claims.credentials.canEditOwn))
    );
  };

  const [showImport, setShowImport] = useState(false);
  const [exporting, setExporting] = useState(false);
  const startExport = async () => {
    gridFilters.exportCSV();

    // setExporting(true);
    // await ApsServices.http.credCredential
    //   .extractCredentialsByCredential({
    //     ids: getSelectedRows().map((r) => r.credentialId),
    //   })
    //   .then((data) => {
    //     const file = commonService.b64toBlob(data.fileContents, "text/plain");
    //     FileSaver.saveAs(file, data.fileDownloadName);
    //   })
    //   .catch((error) => {
    //     toastStore.showError("Failed Exporting Credentials", error);
    //   })
    //   .finally(() => {
    //     setExporting(false);
    //   });
  };

  const [isLoading, setIsLoading] = useState(false);
  const ref = React.createRef<AsyncTypeahead<any>>();
  const [credentials, setCredentials] = useState([] as any[]);

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setCredentials([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await ApsServices.http.credCredential
      .typeAheadTakenCredential({
        search: query,
        isAnd: false,
        recordCount: 10,
        libraryTypeEnum: CredLibraryTypeEnum.All,
        categoryId: null as any,
        onlyRenewal: false,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: i.name,
        }));

        setCredentials(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setCredentials([]);
        setIsLoading(false);
      });
  };

  return (
    <>
      {showImport && (
        <CredentialImportDialog
          onClose={(e) => {
            setShowImport(false);
          }}
        ></CredentialImportDialog>
      )}
      <div className="flex flex-col h-100 p-4">
        <div
          className="flex flex-row mb-3"
          style={{ flexWrap: "wrap", gap: "10px" }}
        >
          <div
            className="flex-1 flex"
            style={{
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              minWidth: "250px",
            }}
          >
            <div className="mr-2">
              {/* {!claims.credentials.canAddAll && (
                <button
                  className="btn btn-outline-secondary"
                  onClick={(e) => {
                    startExport();
                  }}
                  disabled={rowsSelectedCount === 0 || exporting}
                >
                  {exporting
                    ? `Exporting...`
                    : `Export to CSV (${rowsSelectedCount})`}
                </button>
              )} */}
              <Dropdown drop="down">
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id={`ddMenu-${gridFilters.props._key}Picker`}
                  disabled={loading}
                >
                  {exporting ? "Exporting..." : "Export or Import"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => {
                      gridFilters.exportAllCSV();
                    }}
                  >
                    Export All Data
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      gridFilters.exportCSV();
                    }}
                  >
                    Export Visible Data
                  </Dropdown.Item>
                  {claims.credentials.canAddAll && (
                    <Dropdown.Item
                      onClick={(e) => {
                        setShowImport(true);
                      }}
                    >
                      Import CSV
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {claims.credentials.canViewAll && (
              <div className="flex flex-center">
                <strong className="pr-2">View</strong>
                <Dropdown drop="down">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdownCredentialsViewFilter"
                  >
                    {isMyReportsOnly ? "My Credentials" : "All Credentials"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right">
                    <Dropdown.Item
                      onClick={(e) => {
                        setIsMyReportsOnly(true);
                      }}
                    >
                      My Credentials
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setIsMyReportsOnly(false);
                      }}
                    >
                      All Credentials
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            <div style={{ minWidth: "200px" }}>
              <AsyncTypeahead
                id="basic-typeahead-single"
                labelKey="name"
                onSearch={handleSearch}
                onChange={(data) => {
                  if (data && data.length > 0) {
                    setCredentialid({
                      id: data[0].id,
                      name: data[0].name,
                    });
                    setCredentials(data);
                    (ref.current as any)?.clear();
                  }
                }}
                searchText={"Searching..."}
                isLoading={isLoading}
                options={credentials}
                placeholder="Search Credential"
                minLength={1}
                delay={500}
                useCache={false}
                ref={ref}
              />
            </div>
            {!!credentialid && (
              <div className="alert alert-secondary m-0 p-2">
                {credentialid?.name}{" "}
                <i
                  className="fa fa-times ml-2 pointer"
                  onClick={() => {
                    setCredentialid(undefined);
                  }}
                ></i>
              </div>
            )}
            <div>
              {/* <button
                type="button"
                className="btn btn-outline-danger"
                onClick={(e) => {
                  setCredentialid(undefined);
                  setIsMyReportsOnly(true);
                  gridFilters.reset();
                  toggleColSizes();
                }}
              >
                Reset
              </button> */}
            </div>

            <div>{/* <strong>Filter</strong> */}</div>

            {/* <button
              className="btn btn-outline-secondary"
              onClick={getList}
              disabled={loading}
            >
              <i className="fa fa-sync"></i>
            </button> */}

            {/* <Dropdown drop="down">
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdownTeamsFilter"
              disabled={loading}
            >
              All Teams
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>To Do...</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          </div>

          <div>
            {canAddCredentials() && (
              <Button
                variant="primary"
                type="button"
                onClick={(e) => {
                  props.history.push(`/credentials/new`);
                }}
              >
                Add Credential
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              minHeight: "300px",
            }}
            className="ag-theme-alpine flex-1"
          >
            <AgGridReact
              columnDefs={gridState.columnDefs}
              defaultColDef={gridState.defaultColDef}
              autoGroupColumnDef={gridState.autoGroupColumnDef}
              enableRangeSelection={true}
              animateRows={true}
              onGridReady={onGridReady}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={false}
              suppressRowDeselection={true}
              suppressRowClickSelection={true}
              frameworkComponents={gridState.frameworkComponents}
              rowData={(gridState.rowData || []).map(
                (
                  row: ApsModels.ICredCredentialByCredentialGridItemOutputDto
                ) => {
                  return {
                    ...row,
                    dueDateString: commonService.agGridHelpers.toExportDate(
                      row.dateIssued
                    ),
                    expirationDateString:
                      commonService.agGridHelpers.toExportDate(
                        row.expirationDate
                      ),
                    lastModifiedString:
                      commonService.agGridHelpers.toExportDate(
                        row.lastModified
                      ),
                  };
                }
              )}
              onRowDoubleClicked={(event: {
                data: ApsModels.ICredCredentialByCredentialGridItemOutputDto;
              }) => {
                if (claims.credentials.canViewAll) {
                  props.history.push(
                    `/credentials/credential/${event.data.credentialId}`
                  );
                }
              }}
              overlayLoadingTemplate={gridState.overlayLoadingTemplate}
              overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
              onRowSelected={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onRowDataChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onFilterChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              {...gridFilters.props}
            />
          </div>
        </div>
        <LoadMoreIndicator
          loadingMore={loadingMore}
          currentCount={
            list?.credCredentialByCredentialGridItemOutputDtos?.length
          }
          totalExpected={list?.totalRecords}
        ></LoadMoreIndicator>
        {/* {
          <Pagination
            length={list?.totalRecords || 0}
            page={paging.page}
            pageSize={paging.pageSize}
            pageChange={pageChange}
            showingOfWhatLabel="Records"
            sizes={[10, 20, 50, 100]}
          ></Pagination>
        } */}
      </div>
    </>
  );
}

export default CredentialListByCredential;
