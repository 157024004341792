import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSStructureFireDamageService {
  get = async (reportId: any) => {
    const url = `/api/NfirsStructureFireDamage/${reportId}`;
    return fetcher.get<ApsModels.INfirsStructureFireDamageOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsStructureFireDamageInputDto) => {
    const url = `/api/NfirsStructureFireDamage`;
    return fetcher.post<ApsModels.INfirsStructureFireDamageOutputDto>(
      url,
      data
    );
  };
}

const nfirsStructureFireDamage = new NFIRSStructureFireDamageService();
export default nfirsStructureFireDamage;
