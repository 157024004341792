import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";

function NFIRSEntryStructureType(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsStructureFireStructureType.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsStructureFireStructureTypeInputDto>({
    objectName: "Structure Type",
    nfirsSectionTab: "Structure Fire/StructureType",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsStructureFireStructureTypeInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
    if (
      newModel.nfirsStructureTypeEntryId !== nfirsStore.nfirsStructureFireType
    ) {
      nfirsStore.setNfirsStructureFireType(newModel.nfirsStructureTypeEntryId);
    }
  };

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();
  const requireOthers = () => {
    if (!isOtherModuleDataRequired) {
      return false;
    }
    return (
      model.new.nfirsStructureTypeEntryId === 1 ||
      model.new.nfirsStructureTypeEntryId === 2
    );
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Structure Fire",
    "StructureType",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Structure Fire", "StructureType", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const [structureTypes, setStructureTypes] = useState<
    ApsModels.INfirsStructureTypeEntryOutputDto[]
  >([]);
  const getTypes = async () => {
    await ApsServices.http.nfirsGenericLookupService
      .getStructureTypeEntryEntries()
      .then((rtn) => {
        setStructureTypes(rtn);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Structure Types", err);
        setProgress({ loading: false });
      });
  };

  const [buildingTypes, setBuildingTypes] = useState<
    ApsModels.INfirsStructureBuildingTypeEntryOutputDto[]
  >([]);
  const getBuildingTypes = async () => {
    await ApsServices.http.nfirsGenericLookupService
      .getStructureBuildingTypeEntries()
      .then((rtn) => {
        setBuildingTypes(rtn);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Building Statuses", err);
        setProgress({ loading: false });
      });
  };

  const itemFirstIgnitedList = useNfirsGetData<
    ApsModels.INfirsItemFirstIgnitedFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getItemsFirstIgnited(false),
    "Item First Ignited List"
  );
  const itemFirstIgnitedSections = useNfirsLookupEnum(
    "NfirsItemFirstIgnitedSectionEnum",
    "Item Contributing Most to Flame Spread Sections"
  );
  const types = useNfirsGetData<
    ApsModels.INfirsTypeOfMaterialContributingToFlameSpreadFavoriteOutputDto[]
  >(
    () =>
      ApsServices.http.nfirsFavorite.getTypesOfMaterialContributingToFlameSpread(
        false
      ),
    "Types of Material Contributing Most to Flame Spread"
  );
  const typeSections = useNfirsLookupEnum(
    "NfirsTypeOfMaterialContributingToFlameSpreadEntryEnum",
    "Type of Material Contributing Most to Flame Spread Sections"
  );

  useEffect(() => {
    setProgress({ loading: true });
    getTypes();
    getBuildingTypes();
    itemFirstIgnitedList.getData();
    itemFirstIgnitedSections.getData();
    types.getData();
    typeSections.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsStructureFireStructureType
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);

          nfirsStore.setNfirsStructureFireType(data.nfirsStructureTypeEntryId);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Structure Type", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      structureTypes.length > 0 &&
      buildingTypes.length > 0 &&
      itemFirstIgnitedList.status === NfirsFetchStatus.Complete &&
      itemFirstIgnitedSections.status === NfirsFetchStatus.Complete &&
      types.status === NfirsFetchStatus.Complete &&
      typeSections.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    structureTypes,
    buildingTypes,
    itemFirstIgnitedList.status,
    itemFirstIgnitedSections.status,
    types.status,
    typeSections.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValue,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsStructureTypeEntryId: {
      displayName: "Structure Type",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },

    nfirsItemFirstIgnitedEntryId: {
      displayName: "Type of Material",
      validation: {
        required: false,
      },
    },
    nfirsTypeOfMaterialContributingToFlameSpreadEntryId: {
      displayName: "Type of Material",
      validation: {
        required: false,
      },
    },

    nfirsStructureBuildingStatusTypeEntryId: {
      displayName: "Building Status",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock && requireOthers()) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    storiesAboveGrade: {
      displayName: "Building Stories Above Grade",
      validation: {
        required: false,
        max: 999,
        validate: {
          required: (val: any) => {
            if (cntLock && requireOthers()) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    storiesBelowGrade: {
      displayName: "Building Stories Below Grade",
      validation: {
        required: false,
        max: 99,
        validate: {
          required: (val: any) => {
            if (cntLock && requireOthers()) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    totalSquareFeet: {
      displayName: "Total Square Feet",
      validation: {
        required: false,
        max: 99999999,
      },
    },
    length: {
      displayName: "Length",
      validation: {
        required: false,
        max: 9999,
        min: 1,
        validate: {
          required: (val: any) => {
            if (cntLock && requireOthers()) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    width: {
      displayName: "Width",
      validation: {
        required: false,
        max: 9999,
        min: 1,
        validate: {
          required: (val: any) => {
            if (cntLock && requireOthers()) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 ">
          <strong>Structure Type</strong>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div>
            <FgSelect
              id="nfirsStructureTypeEntryId"
              label="Structure Type"
              selectMessage="Select Type"
              registeredField={registry.nfirsStructureTypeEntryId}
              formState={formState}
              showRequiredLabel={isOtherModuleDataRequired}
              options={structureTypes.map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsStructureTypeEntryId: Number(data) || (undefined as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="mt-3">
            <label className={requireOthers() ? "required-label" : ""}>
              Item Contributing Most to Flame Spread
            </label>
            <AdvancedDropdown
              domId="nfirsItemFirstIgnitedEntryId"
              value={model.new.nfirsItemFirstIgnitedEntryId}
              favoritesHandler={{
                add: ApsServices.http.nfirsFavorite.addFavoriteItemFirstIgnited,
                remove:
                  ApsServices.http.nfirsFavorite.removeFavoriteItemFirstIgnited,
                afterUpdate: (list: any[]) =>
                  itemFirstIgnitedList.setData(list),
              }}
              options={(itemFirstIgnitedList.data || []).map((o) => {
                return {
                  label: `${o.description} (${o.externalId})`,
                  value: o.id,
                  originalObject: o,
                  isFavorite: o.isFavorite,
                  groupId: o.nfirsItemFirstIgnitedSection,
                  subGroupId: 0,
                };
              })}
              groups={(itemFirstIgnitedSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  groupLabel: o.label,
                };
              })}
              subGroups={(itemFirstIgnitedSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  subGroupId: 0,
                };
              })}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  nfirsItemFirstIgnitedEntryId:
                    Number(data) || (undefined as any),
                });
              }}
            ></AdvancedDropdown>
          </div>

          <div className="mt-3">
            <label className={requireOthers() ? "required-label" : ""}>
              Type of Material Contributing Most to Flame Spread
            </label>
            <AdvancedDropdown
              domId="nfirsTypeOfMaterialContributingToFlameSpreadEntryId"
              value={
                model.new.nfirsTypeOfMaterialContributingToFlameSpreadEntryId
              }
              favoritesHandler={{
                add: ApsServices.http.nfirsFavorite
                  .addFavoriteTypeOfMaterialContributingToFlameSpread,
                remove:
                  ApsServices.http.nfirsFavorite
                    .removeFavoriteTypeOfMaterialContributingToFlameSpread,
                afterUpdate: (list: any[]) => types.setData(list),
              }}
              options={(types.data || []).map((o) => {
                return {
                  label: `${o.description} (${o.externalId})`,
                  value: o.id,
                  originalObject: o,
                  isFavorite: o.isFavorite,
                  groupId:
                    o.nfirsTypeOfMaterialContributingToFlameSpreadEntryEnum,
                  subGroupId: 0,
                };
              })}
              groups={(typeSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  groupLabel: o.label,
                };
              })}
              subGroups={(typeSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  subGroupId: 0,
                };
              })}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  nfirsTypeOfMaterialContributingToFlameSpreadEntryId:
                    Number(data) || (undefined as any),
                });
              }}
            ></AdvancedDropdown>
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsStructureBuildingStatusTypeEntryId"
              label="Building Status"
              selectMessage="Select Status"
              registeredField={registry.nfirsStructureBuildingStatusTypeEntryId}
              formState={formState}
              showRequiredLabel={requireOthers()}
              options={buildingTypes.map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsStructureBuildingStatusTypeEntryId:
                    Number(data) || (undefined as any),
                })
              }
            ></FgSelect>
          </div>
          <div className="mt-3"></div>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <FgInput
            id="storiesAboveGrade"
            label="Building Stories Above Grade"
            placeHolder="Building Stories Above Grade"
            registeredField={registry.storiesAboveGrade}
            formState={formState}
            showRequiredLabel={requireOthers()}
            onChange={(data) => {
              onModelChange({
                ...model.new,
                storiesAboveGrade: commonService.getNumber(data),
              });
            }}
            type="number"
          ></FgInput>
          <FgInput
            id="storiesBelowGrade"
            label="Building Stories Below Grade"
            placeHolder="Building Stories Below Grade"
            registeredField={registry.storiesBelowGrade}
            formState={formState}
            showRequiredLabel={requireOthers()}
            onChange={(data) => {
              onModelChange({
                ...model.new,
                storiesBelowGrade: commonService.getNumber(data),
              });
            }}
            type="number"
          ></FgInput>

          <div className="mt-3">
            <label>Main Floor Size</label>
            <div className="row">
              <div className="col-md-12 col-lg-7">
                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="length"
                      label="Length"
                      placeHolder="Length"
                      registeredField={registry.length}
                      formState={formState}
                      showRequiredLabel={requireOthers()}
                      onChange={(data) => {
                        const total = Number(data) * Number(model.new.width);
                        onModelChange({
                          ...model.new,
                          length: Number(data) || (undefined as any),
                          totalSquareFeet: total,
                        } as any);
                        setValue("totalSquareFeet", total);
                      }}
                      type="number"
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="width"
                      label="Width"
                      placeHolder="Width"
                      registeredField={registry.width}
                      formState={formState}
                      showRequiredLabel={requireOthers()}
                      onChange={(data) => {
                        const total = Number(data) * Number(model.new.length);
                        onModelChange({
                          ...model.new,
                          width: Number(data) || (undefined as any),
                          totalSquareFeet: total,
                        } as any);
                        setValue("totalSquareFeet", total);
                      }}
                      type="number"
                    ></FgInput>
                  </div>
                </div>
              </div>
              {/* <div
                className="col-md-12 col-lg-1 text-center"
                style={{ padding: "20px 0" }}
              >
                <label>=</label>
              </div> */}
              <div className="col-md-12 col-lg-5">
                <FgInput
                  id="totalSquareFeet"
                  label="Total Square Feet"
                  placeHolder="Total Square Feet"
                  registeredField={registry.totalSquareFeet}
                  formState={formState}
                  onChange={(data) => {}}
                  type="number"
                  readOnly={true}
                ></FgInput>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSEntryStructureType;
