import React, { useEffect, useMemo, useState } from "react";
import ApsServices from "../../aps2/services";
import { useDepartmentId } from "../../aps2/stores/SystemStore";
import toastStore from "../../aps2/stores/ToastStore";
import {
  CalendarDto,
  FormCalendarDto,
  FormDto,
  QuestionGroupDto,
  ShiftDto,
} from "../../data/DailyOperational";
import { IDOCLayoutModel } from "../DailyOperationalDesigner/DailyOperationalDesigner";
import { Button } from "react-bootstrap";
import CoreLayout from "../DailyOperationalDesigner/CoreLayout";
import CommonSpinner from "../../aps2/components/Common/CommonSpinner";
import { Shift } from "../../data/Shift";
import { ShiftColor } from "../../data/ShiftColor";
import { OptionGroupDto } from "../../data/OptionGroup";
import moment, { Moment } from "moment";
import { ShiftService } from "../../services/ShiftService";
import { ShiftColorService } from "../../services/ShiftColorService";
import { FormService } from "../../services/FormService";
import cloneDeep from "lodash/cloneDeep";
import { QuestionGroupTemporaryStorageService } from "../../services/QuestionGroupTemporaryStorageService";
import commonService from "../../aps2/services/CommonService";
import SweetAlert from "react-bootstrap-sweetalert";
import RouteChangedGuard from "../../aps2/components/Common/RouteChangedGuard";
import ConfirmDialog from "../../aps2/components/Common/ConfirmDialog";
import { BuiltInDOCComponents } from "../DailyOperationalDesigner/ComponentGalleryDefinitions";
import { ShiftSchedulerService } from "../../services/ShiftSchedulerService";
import ApsDatePicker2 from "../Shared/ApsDatePicker2";

type State = {
  selectedDate: Date;
  shift: Shift;
  shiftColor: ShiftColor;
  form: FormDto | null;
  originalForm: FormDto | null;
  departmentId: number;
  isCalendarDisabled: boolean;
  showAlert: boolean;
  optionGroups: OptionGroupDto[];
  isMobile: boolean;
  showReportError: boolean;
  showSaveConfirmation: boolean;
  isAllowPrint: boolean;
  saving: boolean;
  showAskUnsavedChanges: boolean;
  onDiscardChanges?: () => void;
  loading: boolean;
  shiftColorStr: string;
  shiftNameStr: string;
};

function DailyOperationalRenderer(props: any) {
  const departmentId = useDepartmentId();
  const [model, setModel] = useState<IDOCLayoutModel>({
    rows: [],
  });

  // const [form, setForm] = useState<CalendarDto>();
  // const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [state, doSetState] = useState<State>({
    selectedDate: moment
      .utc(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")
      .toDate(),
    shift: ShiftService.getShift(
      moment.utc(moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
      -1 as any
    ),
    shiftColor: ShiftColorService.getShiftColorBySpecificDay(
      moment.utc(moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
      -1
    ),
    form: null,
    originalForm: null,
    departmentId: 0,
    isCalendarDisabled: false,
    showAlert: false,
    optionGroups: [],
    isMobile: false,
    showReportError: false,
    showSaveConfirmation: false,
    isAllowPrint: false,
    saving: false,
    showAskUnsavedChanges: false,
    onDiscardChanges: () => { },
    loading: true,
    shiftColorStr: "",
    shiftNameStr: ""
  });

  const setState = (data: Partial<State>) => {
    doSetState((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
  };

  const handleResize = () => {
    const tabletMaxWidth = 767;
    setState({
      isMobile:
        window.screen.width <= tabletMaxWidth ||
        window.innerWidth <= tabletMaxWidth,
    });
  };

  const [refresh, setRefresh] = useState<string>();
  const init = async (departmentName: string) => {
    setRefresh(undefined);

    await setCalendar();
    await setDepartmentId(departmentId.current);

    const shiftStr = moment.utc(state.selectedDate).format('dddd, MMMM D, YYYY');
    const shiftDate = ShiftSchedulerService.convertDateStrToDepartmentTimeZone(shiftStr, departmentId.current);
    const shift = await ShiftSchedulerService.getShiftForDepartmentByDate(departmentId.current, shiftDate);
    setState({ shiftColorStr: shift.color, shiftNameStr: shift.name })

    handleResize();

    const optionGroups: OptionGroupDto[] =
      await ApsServices.http.optionGroup.getAllOptionGroupsByDepartment(
        departmentId.current
      );

    setState({
      optionGroups: optionGroups,
      shift: ShiftService.getShift(
        moment.utc(moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
        departmentId.current
      ),
      shiftColor: ShiftColorService.getShiftColorBySpecificDay(
        moment.utc(moment().format("YYYY-MM-DD"), "YYYY-MM-DD"),
        departmentId.current
      ),
    });

    setRefresh(commonService.getUniqueId());
    setLoading(false);
  };

  const setDepartmentId = async (departmentId: number) => {
    setState({ loading: true });
    try {
      const form = await FormService.getDailyOperationalForm(
        state.selectedDate,
        departmentId
      );
      setState({
        form: form,
        originalForm: cloneDeep(form),
        departmentId: departmentId,
      });
    } catch {
      setState({ form: null, originalForm: null, departmentId: 0 });
    }
    setState({ loading: false });
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleTabClosed);
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hasLayout, setHasLayout] = useState(false);

  useEffect(() => {
    if (departmentId.current > 0) {
      setLoading(true);
      ApsServices.http.dailyConsoleTemplate
        .getTemplate()
        .then((data) => {
          if (!data.layoutModel) {
            init("");
            setHasLayout(false);
          } else {
            setModel(JSON.parse(data.layoutModel));
            init("");
            setHasLayout(true);
          }
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Template", error);
          setHasLayout(false);
        });
    } else {
      setHasLayout(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const save = async (): Promise<void> => {
    await setCalendar();

    const questionGroups: QuestionGroupDto[] =
      QuestionGroupTemporaryStorageService.getAll();
    if (questionGroups.length === 0) return;

    setState({ saving: true });
    const questionGroupsUpdated: QuestionGroupDto[] =
      await FormService.updateQuestionGroups({
        questionGroups: questionGroups,
        calendarDate: state.selectedDate,
      });
    setState({ saving: false });
    QuestionGroupTemporaryStorageService.clear();

    if (questionGroupsUpdated && state.form) {
      let form = cloneDeep(state.form);
      const questionGroupsIds: number[] = questionGroupsUpdated.map(
        (q) => q.questionGroupId
      );

      for (let i = 0; i < form.questionGroups.length; i++) {
        if (
          questionGroupsIds.indexOf(form.questionGroups[i].questionGroupId) !==
          -1
        ) {
          form.questionGroups[i] = questionGroupsUpdated.find(
            (g) => g.questionGroupId === form.questionGroups[i].questionGroupId
          )!;
        }
      }
      setState({ form: form, originalForm: cloneDeep(form) });
    }
  };

  const padL = (nr: number, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);


  const printDocument = async () => {

    const dt = new Date();
    
    let date = (`${
        padL(dt.getMonth()+1)}/${
        padL(dt.getDate())}/${
        dt.getFullYear()} ${
        padL(dt.getHours())}:${
        padL(dt.getMinutes())}`
    );

    document.getElementById("date-pdf")!.innerHTML = `<span class='font-size-10' style="font-style: italic">${date}</span>`;

    document.getElementById("firstsection")!.innerHTML = "";
    document.getElementById("secondsection")!.innerHTML = "";
    document.getElementById("thirdsection")!.innerHTML = "";
    document.getElementById("forthsection")!.innerHTML = "";
    document.getElementById("fifthsection")!.innerHTML = "";


    document.querySelectorAll(".childsection1").forEach(x => {
      document.getElementById("firstsection")!.innerHTML = document.getElementById("firstsection")!.innerHTML + `<div class='mr-4'> ${x.innerHTML} </div>`
    })
    if(document.getElementById("firstsection")!.innerHTML === "") {
      document.getElementById("firstsection")!.innerHTML = `<section style="width: 0"> </section>`;
    }

    document.querySelectorAll(".childsection2").forEach(x => {
      document.getElementById("secondsection")!.innerHTML = document.getElementById("secondsection")!.innerHTML +`<div class='mb-4'> ${x.innerHTML} </div>`
    })
    if(document.getElementById("secondsection")!.innerHTML === "") {
      document.getElementById("secondsection")!.innerHTML = `<section style="width: 0"> </section>`;
    }

    document.querySelectorAll(".childsection3").forEach(x => {
      document.getElementById("thirdsection")!.innerHTML = document.getElementById("thirdsection")!.innerHTML + `<div class='mb-2'> ${x.innerHTML} </div>`
    })
    if(document.getElementById("thirdsection")!.innerHTML === "") {
      document.getElementById("thirdsection")!.innerHTML = `<section style="width: 0"> </section>`;
    }

    document.querySelectorAll(".childsection4").forEach(x => {
      document.getElementById("forthsection")!.innerHTML = document.getElementById("forthsection")!.innerHTML + `<div class='mb-4'> ${x.innerHTML} </div>`
    })
    if(document.getElementById("forthsection")!.innerHTML === "") {
      document.getElementById("forthsection")!.innerHTML = `<section style="width: 0"> </section>`;
    }

    document.querySelectorAll(".childsection5").forEach(x => {
      document.getElementById("fifthsection")!.innerHTML = document.getElementById("fifthsection")!.innerHTML + `<div class='mb-4'> ${x.innerHTML} </div> `
    })
    if(document.getElementById("fifthsection")!.innerHTML === "") {
      document.getElementById("fifthsection")!.innerHTML = `<section style="width: 0"> </section>`;
    }
    
    let html = document.getElementById("pdf")!.innerHTML || "";
    html = html.replace("rdw-editor-main", "");

    document.getElementById("printWrapper")!.innerHTML = html;
    window.print()
    document.getElementById("date-pdf")!.innerHTML = "";
    document.getElementById("printWrapper")!.innerHTML = "";
  };

  const saveClicked = async (): Promise<void> => {
    await save();
    setState({ showSaveConfirmation: true });
    // await handleTodayClicked()
    props.history.replace("/dailyoperational" + commonService.doneUrls.submitted);
  };

  const refreshForm = async (selectedDate: Moment) => {
    const date = selectedDate.toDate();
    setState({
      form: null,
      loading: true,
    });

    const shiftStr = moment.utc(selectedDate.format('YYYY-MM-DD'), 'YYYY-MM-DD').format("yyyy-MM-DDThh:mm:ss");
    const shiftDate = ShiftSchedulerService.convertDateStrToDepartmentTimeZone(shiftStr, state.departmentId);
    const shift = await ShiftSchedulerService.getShiftForDepartmentByDate(state.departmentId, shiftDate);
    setState({ shiftColorStr: shift.color, shiftNameStr: shift.name })

    QuestionGroupTemporaryStorageService.clear();

    const form = await FormService.getDailyOperationalForm(
      date,
      departmentId.current
    );

    setState({
      form: form as any,
      originalForm: cloneDeep(form) as any,
      selectedDate: date,
      shift: ShiftService.getShift(
        selectedDate,
        departmentId.current
      ),
      shiftColor: ShiftColorService.getShiftColorBySpecificDay(
        selectedDate,
        departmentId.current
      ),
      loading: false,
    });

    await setCalendar(form);    
  };

  const getBarColor = (shiftColor: ShiftColor) => {
    switch (shiftColor) {
      case ShiftColor.Blue:
        return "blue-bar";
      case ShiftColor.Green:
        return "green-bar";
      case ShiftColor.Red:
        return "red-bar";
    }
  };

  const getBarButtonColor = (shiftColor: ShiftColor) => {
    switch (shiftColor) {
      case ShiftColor.Blue:
        return "blue-button";
      case ShiftColor.Green:
        return "green-button";
      case ShiftColor.Red:
        return "red-button";
    }
  };

  const handleTabClosed = (event: any) => {
    if (hasChanges()) {
      return "You have some unsaved changes";
    }
  };

  const setShowAlertMessage = () => {
    setState({ showAlert: !state.showAlert });
  };

  const handleReportErrorMessageClosed = () => {
    setState({ showReportError: !state.showReportError });
  };

  const handleSaveConfirmationClosed = () => {
    setState({ showSaveConfirmation: !state.showSaveConfirmation });
    props.history.replace("/dailyoperational");
  };

  const hasChanges = () => {
    const currentData = QuestionGroupTemporaryStorageService.getAll();
    if (currentData.length === 0) {
      return false;
    }
    let hasChanges = false;
    currentData.forEach((qp, i) => {
      if (!hasChanges && state.originalForm?.questionGroups) {
        var origData =
          state.originalForm?.questionGroups?.find(
            (q) => q.questionGroupId === qp.questionGroupId
          ) || {};
        var newData = qp;
        hasChanges =
          JSON.stringify(commonService.replaceEmptyString(newData, null)) !==
          JSON.stringify(commonService.replaceEmptyString(origData, null));
      }
    });
    return hasChanges;
  };

  const checkAndAskForUnsavedChanges = (actionToDo: () => void) => {
    if (hasChanges()) {
      setState({
        showAskUnsavedChanges: true,
        onDiscardChanges: actionToDo,
      });
    } else {
      setState({
        showAskUnsavedChanges: false,
        onDiscardChanges: undefined,
      });
      actionToDo();
    }
  };

  const handleDateChanged = async (
    date: Date,
    shiftColor: ShiftColor
  ): Promise<void> => {
    checkAndAskForUnsavedChanges(async () => {
      setState({
        isCalendarDisabled: true,
      });
      setState({ selectedDate: date, shiftColor: shiftColor });
      await refreshForm(moment.utc(date));
      setState({ isCalendarDisabled: false });
    });
  };

  const handleYesterdayClicked = async (): Promise<void> => {
    checkAndAskForUnsavedChanges(async () => {
      setState({ isCalendarDisabled: true });
      const yesterday: Moment = moment.utc(state.selectedDate).add(-1, "days");
      await refreshForm(yesterday);
      setState({ isCalendarDisabled: false });
    });
  };

  const handleTomorrowClicked = async (): Promise<void> => {
    checkAndAskForUnsavedChanges(async () => {
      setState({ isCalendarDisabled: true });
      const tomorrow = moment.utc(state.selectedDate).add(1, "days");
      await refreshForm(tomorrow);
      setState({ isCalendarDisabled: false });
    });
  };

  const handleTodayClicked = async (): Promise<void> => {
    checkAndAskForUnsavedChanges(async () => {
      setState({ isCalendarDisabled: true });
      const today = moment.utc(moment().format("YYYY-MM-DD"), "YYYY-MM-DD");
      await refreshForm(today);
      setState({ isCalendarDisabled: false });
    });
  };

  const setCalendar = async (form?: FormDto) => { //CSM-193 Added 'form' to make sure it carries over updated values
    if (!state.form) return;

    const calendarDate: Moment = moment(state.selectedDate);
    const existingCalendar: CalendarDto | undefined = (form || state.form).calendars.find(
      (c) =>
        c.day === calendarDate.date() &&
        c.year === calendarDate.year() &&
        c.month === calendarDate.month() + 1 &&
        c.shifts.find((s) => s.color === state.shiftColor)
    );

    if (!existingCalendar) {
      const calendar: CalendarDto = createCalendar(calendarDate);
      setState({ isAllowPrint: false });
      await saveFormCalendar(calendar, form).then((x) => {
        setState({ isAllowPrint: true });
      });
    }
  };

  const saveFormCalendar = async (calendar: CalendarDto, form?: FormDto) => {
    if (!state.form) return;

    const formCalendarDto: FormCalendarDto = {
      formId: (form || state.form).formId,
      calendar: calendar,
    };

    const newFormCalendar: CalendarDto = await FormService.createCalendar(
      formCalendarDto
    );
    const formCopy: FormDto = cloneDeep((form || state.form));

    formCopy.calendars = [newFormCalendar];

    setState({
      form: formCopy,
      originalForm: cloneDeep(formCopy),
    });
  };

  const createCalendar = (calendarDate: Moment) => {
    const calendar: CalendarDto = {
      calendarId: 0,
      date: state.selectedDate,
      year: calendarDate.year(),
      month: calendarDate.month() + 1,
      day: calendarDate.date(),
      quarter: calendarDate.quarter(),
      week: calendarDate.week(),
      dayOfWeek: calendarDate.day(),
      shifts: [],
    };

    const shift: ShiftDto = {
      shiftId: 0,
      name: "test",
      color: state.shiftColor,
    };

    calendar.shifts.push(shift);
    return calendar;
  };

  const factoryForm = (
    value: QuestionGroupDto | undefined,
    order: number,
    componentName?: string
  ): void => {
    if (value && state.form) {
      let form = cloneDeep(state.form);
      for (let i = 0; i < form.questionGroups.length; i++) {
        if (form.questionGroups[i].order === order) {
          form.questionGroups[i] = value;

          if (QuestionGroupTemporaryStorageService.exists(value)) {
            QuestionGroupTemporaryStorageService.update(value);
          } else {
            QuestionGroupTemporaryStorageService.add(value);

            if (
              componentName &&
              BuiltInDOCComponents.filter((b) => b.createsDefaultAnswer)
                .map((b) => b.id)
                .includes(componentName)
            ) {
              // console.log(
              //   `Created default answer for ${componentName} ${order}`
              // );
              if (state.originalForm) {
                // let origForm = cloneDeep(state.originalForm);
                // const ogFormQG = origForm.questionGroups[i];
                // if (!ogFormQG.questions.find((x) => x.answers.length > 0)) {
                //   origForm.questionGroups[i] = cloneDeep(value);
                //   setState({ originalForm: origForm });
                // }
                doSetState((prev) => {
                  if (!prev.originalForm) return prev;
                  const ogFormQG = prev.originalForm.questionGroups[i];
                  if (!ogFormQG.questions.find((x) => x.answers.length > 0)) {
                    return {
                      ...prev,
                      originalForm: {
                        ...prev.originalForm,
                        questionGroups: prev.originalForm.questionGroups?.map(
                          (qg) => {
                            if (qg.order === value.order) {
                              return value;
                            }
                            return qg;
                          }
                        ),
                      },
                    };
                  }
                  return prev;
                });
              }
            }
          }
        }
      }
      doSetState((prev) => {
        return {
          ...prev,
          form: {
            ...(prev.form || ({} as any)),
            questionGroups: prev.form
              ? prev.form.questionGroups.map((grp) => {
                if (grp.order === order) {
                  return {
                    ...grp,
                    ...form.questionGroups
                      .map((g) => {
                        if (g.order !== order) {
                          return g;
                        }
                        return {
                          ...g,
                          questions: g.questions.map((q) => {
                            return {
                              ...q,
                              answers: [...q.answers],
                            };
                          }),
                        };
                      })
                      .find((g) => g.order === order),
                  };
                }
                return grp;
              })
              : [],
          },
        };
      });
    }
  };

  const readToRenderLayout = useMemo(() => {
    return !!refresh && hasLayout && !loading && !state.loading;
  }, [refresh, hasLayout, loading, state.loading]);

  return (
    <>
      <RouteChangedGuard
        when={true}
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => {
          return hasChanges();
        }}
        title="Confirmation"
        message="Do you want to discard unsaved changes?"
      />

      <ConfirmDialog
        show={state.showAskUnsavedChanges}
        buttons="yesno"
        title="Confirmation"
        message="Do you want to discard unsaved changes?"
        done={(rtn) => {
          if (rtn === "yes") {
            state.onDiscardChanges && state.onDiscardChanges();
            setState({
              showAskUnsavedChanges: false,
              onDiscardChanges: undefined,
            });
            return;
          }
          setState({ showAskUnsavedChanges: false });
        }}
      ></ConfirmDialog>

      {(loading || state.loading) && (
        <div className="py-4 w-100">
          <CommonSpinner></CommonSpinner>
        </div>
      )}

      {readToRenderLayout && (
        <div
          className={`doc-designer w-100 ${
            hasLayout || !loading ? "" : "display-none"
          }`}
        >
          <div className="flex flex-center mb-4 d-print-none">
            <h4 className="flex-1">DAILY OPERATIONAL</h4>
            <div className={loading ? "display-none" : ""}>
              <Button
                variant="secondary"
                type="button"
                className="print-button mr-2"
                onClick={printDocument}
                disabled={state.saving || !state.form}
              >
                &nbsp;&nbsp;Export
              </Button>
              <Button
                variant="primary"
                onClick={saveClicked}
                disabled={state.saving || !hasChanges()}
              >
                {state.saving ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
          {loading && (
            <div>
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          <div className={loading ? "display-none" : ""}>
            <div className="row h-full d-print-none">
              <div className="col">
                {!state.isCalendarDisabled && state.shiftColorStr !== "" && (
                  <div
                    className={`daily-operational-form card`}
                    style={{backgroundColor: state.shiftColorStr}}
                  >
                    {state.isMobile ? (
                      <>
                        <div className="card-body">
                          <div className="row">
                            <div className="col d-flex justify-content-center">
                              <button
                                disabled={state.isCalendarDisabled}
                                className={`btn btn-white mr-4 daily-operational-btn`}
                                style={{color: state.shiftColorStr}}
                                onClick={handleTodayClicked}
                              >
                                Today
                              </button>
                              <div className="d-flex">
                                <button
                                  disabled={state.isCalendarDisabled}
                                  className={`btn btn-white daily-operational-btn`}
                                  style={{color: state.shiftColorStr}}
                                  onClick={handleYesterdayClicked}
                                >
                                  <i className="fas fa-chevron-left" />
                                </button>
                                <ApsDatePicker2
                                  isSelectionDisabled={state.isCalendarDisabled}
                                  onChange={handleDateChanged}
                                  value={state.selectedDate}
                                  departmentId={departmentId.current}
                                  todayColor={state.shiftColorStr}
                                />
                                <button
                                  disabled={state.isCalendarDisabled}
                                  className={`btn btn-white daily-operational-btn`}
                                  style={{color: state.shiftColorStr}}
                                  onClick={handleTomorrowClicked}
                                >
                                  <i className="fas fa-chevron-right" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="card-body">
                        <div className="row d-none d-print-block">
                          <div className="col d-flex justify-content-center">
                            <div className="calendar-disabled calendar-display-date text-nowrap text-uppercase w-100">
                              {state.form?.name} DAILY OPERATIONAL
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col d-flex justify-content-center">
                            <div className="d-flex">
                              <button
                                disabled={state.isCalendarDisabled}
                                className={`btn btn-white mr-4 daily-operational-btn d-print-none`}
                                style={{color: state.shiftColorStr}}
                                onClick={handleTodayClicked}
                              >
                                Today
                              </button>
                              <div className="d-flex">
                                <button
                                  disabled={state.isCalendarDisabled}
                                  className={`btn btn-white daily-operational-btn d-print-none`}
                                  style={{color: state.shiftColorStr}}
                                  onClick={handleYesterdayClicked}
                                >
                                  <i className="fas fa-chevron-left" />
                                </button>
                                <div className="d-print-none">
                                  <ApsDatePicker2
                                    isSelectionDisabled={
                                      state.isCalendarDisabled
                                    }
                                    onChange={handleDateChanged}
                                    value={state.selectedDate}
                                    departmentId={departmentId.current}
                                    todayColor={state.shiftColorStr}
                                  />
                                </div>
                                <div className="d-none d-print-inline">
                                  <div className="calendar-disabled calendar-display-date justify-content-start text-uppercase">
                                    {moment
                                      .utc(state.selectedDate)
                                      .format("dddd, MMMM D, YYYY")}
                                    , SHIFT {state.shift?.name}
                                  </div>
                                </div>
                                <button
                                  disabled={state.isCalendarDisabled}
                                  className={`btn btn-white daily-operational-btn d-print-none`}
                                  style={{color: state.shiftColorStr}}
                                  onClick={handleTomorrowClicked}
                                >
                                  <i className="fas fa-chevron-right" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {(loading || state.loading) && (
              <div>
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            <br />
            <div id="pdf" className="pdf  d-none  d-print-block">
              <style>{`.bar-background { background-color: ${state.shiftColorStr} }`}</style>
              <div id="date-pdf"></div>
              <div
                className={`card-body text-nowrap bar-background mb-3`}
              >
                <div className="row ">
                  <div className="col d-flex justify-content-center">
                    <div className="calendar-disabled calendar-display-date text-nowrap w-100">
                      {state.form?.name.toString().replace("Form", "")} Daily
                      Operational
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div className="d-flex">
                      <div className="d-flex">
                        <div className=" d-print-inline">
                          <div className="calendar-disabled calendar-display-date justify-content-start ">
                            {moment
                              .utc(state.selectedDate)
                              .format("dddd, MMMM D, YYYY")}
                            , {state.shiftNameStr}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex justify-content-between flex-wrap gap-10"
                id="firstsection"
              ></div>
              <div className="mb-3 " id="secondsection"></div>
              <div className="mb-3 " id="thirdsection"></div>
              <div className="d-flex  flex-wrap gap-10" id="forthsection"></div>
              <div className="d-flex flex-wrap " id="fifthsection"></div>
            </div>
            <br />            
            {
              <CoreLayout
                departmentId={departmentId.current}
                model={model}
                formDto={state.form || undefined}
                optionGroups={state.optionGroups}
                factoryForm={factoryForm}
                selectedDate={state.selectedDate}
                onChange={(data) => {}}
                onDelete={(data) => {}}
                root={true}
              ></CoreLayout>
            }
            {state.showSaveConfirmation ? (
              <SweetAlert
                custom
                showCancel={false}
                showCloseButton={false}
                title="Form saved!"
                onConfirm={handleSaveConfirmationClosed}
                onCancel={handleSaveConfirmationClosed}
              ></SweetAlert>
            ) : null}
            {state.showAlert ? (
              <SweetAlert
                custom
                showCancel={false}
                showCloseButton={false}
                showConfirm={false}
                customIcon="https://alabama.aoa.org/events-register/images/Loading.gif"
                title="Generating PDF Report..."
                onConfirm={setShowAlertMessage}
                onCancel={setShowAlertMessage}
              ></SweetAlert>
            ) : null}
            {state.showReportError ? (
              <SweetAlert
                custom
                showCancel={false}
                showCloseButton={false}
                title="Error on generating report"
                onConfirm={handleReportErrorMessageClosed}
                onCancel={handleReportErrorMessageClosed}
              ></SweetAlert>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

export default DailyOperationalRenderer;
