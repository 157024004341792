import ApsModels from "../../models";
import fetcher from "./Fetcher";

class LogDrillAreaOfStudy {
  get = async (id: number) => {
    const url = `/api/LogDrillAreaOfStudy/${id}`;
    return fetcher.get<ApsModels.ILogDrillAreaOfStudyOutputDto[]>(url);
  };

  getAll = async (subjectId: number, deptId: number) => {
    const url = `/api/LogDrillAreaOfStudy/GetAll/${subjectId}/${deptId}`;
    return fetcher.get<ApsModels.ILogDrillAreaOfStudyOutputDto[]>(url);
  };
}

const logDrillAreaOfStudy = new LogDrillAreaOfStudy();
export default logDrillAreaOfStudy;
