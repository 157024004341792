import ApsModels from "../../models";
import fetcher from "./Fetcher";

class UserSecurityService {
  get = async (userId: number) => {
    const url = `/api/UserSecurity/${userId}`;
    return fetcher.get<ApsModels.IUserSecurityOutputDto>(url);
  };

  getUserClaimTypes = async () => {
    const url = `/api/UserSecurity/GetUserClaimTypes`;
    return fetcher.get<ApsModels.IUserClaimTypeOutputDto[]>(url);
  };

  getUserClaimValues = async () => {
    const url = `/api/UserSecurity/GetUserClaimValues`;
    return fetcher.get<ApsModels.IUserClaimValueOutputDto[]>(url);
  };

  save = async (data: ApsModels.IUserSecurityInputDto) => {
    const url = "/api/UserSecurity/Save";
    return fetcher.post<ApsModels.IUserSecurityOutputDto>(url, data);
  };
}

const userSecurity = new UserSecurityService();
export default userSecurity;
