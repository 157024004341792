import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApsModels from "../../../../models";
import CustomTabs from "../../../Common/CustomTabs";
import { INFIRSSectionProps, useSectionTabs } from "../NFIRSEntry";
import NFIRSValidationButton from "../NFIRSValidationButton";
import NFIRSHazmatArea from "./NFIRSHazmatArea";
import NFIRSHazmatCause from "./NFIRSHazmatCause";
import NFIRSHazmatChemical from "./NFIRSHazmatChemical";
import NFIRSHazmatEquipmentInvolved from "./NFIRSHazmatEquipmentInvolved";
import NFIRSHazmatMobileProperty from "./NFIRSHazmatMobileProperty";

function NFIRSSectionHazmat(props: INFIRSSectionProps) {
  const { onTabSelect, activeTab, loadedTabs, changeTabStatus, getTabLabel } =
    useSectionTabs(props, "HAZMAT", "Area");

  return (
    <>
      <NFIRSValidationButton
        module={ApsModels.NfirsValidationModulesEnum.Hazmat}
        onVerify={props.onVerify}
      ></NFIRSValidationButton>
      <CustomTabs onMenuTabClick={onTabSelect} activeTab={activeTab}>
        <Tabs
          id="nfirs-hazmat-page"
          activeKey={activeTab}
          onSelect={onTabSelect}
        >
          <Tab eventKey="Area" title={getTabLabel("Area", "Area")}>
            {loadedTabs["Area"] && (
              <div className="pt-4">
                <NFIRSHazmatArea
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Area", hasChanges ? 1 : 0);
                  }}
                ></NFIRSHazmatArea>
              </div>
            )}
          </Tab>
          <Tab eventKey="Cause" title={getTabLabel("Cause", "Cause")}>
            {loadedTabs["Cause"] && (
              <div className="pt-4">
                <NFIRSHazmatCause
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Cause", hasChanges ? 1 : 0);
                  }}
                ></NFIRSHazmatCause>
              </div>
            )}
          </Tab>
          <Tab eventKey="Chemical" title={getTabLabel("Chemical", "Chemical")}>
            {loadedTabs["Chemical"] && (
              <div className="pt-4">
                <NFIRSHazmatChemical
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Chemical", hasChanges ? 1 : 0);
                  }}
                ></NFIRSHazmatChemical>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="MobileProperty"
            title={getTabLabel("MobileProperty", "Mobile Property")}
          >
            {loadedTabs["MobileProperty"] && (
              <div className="pt-4">
                <NFIRSHazmatMobileProperty
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("MobileProperty", hasChanges ? 1 : 0);
                  }}
                ></NFIRSHazmatMobileProperty>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="EquipmentInvolved"
            title={getTabLabel("EquipmentInvolved", "Equipment Involved")}
          >
            {loadedTabs["EquipmentInvolved"] && (
              <div className="pt-4">
                <NFIRSHazmatEquipmentInvolved
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("EquipmentInvolved", hasChanges ? 1 : 0);
                  }}
                ></NFIRSHazmatEquipmentInvolved>
              </div>
            )}
          </Tab>
        </Tabs>
      </CustomTabs>
    </>
  );
}

export default NFIRSSectionHazmat;
