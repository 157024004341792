import { cloneDeep } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { AppContext } from "../../../../AppContext";
import {
  AnswerDto,
  AnswerStrikeTeamReferenceDto,
  ReferenceDetails,
  StrikeTeamsAnswerOrder,
  StrikeTeamsOptionOrder,
  StrikeTeamType,
  StrikeTeamUpdateOptions,
} from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { UserDto } from "../../../../data/User";
import { IsolatedReferenceList } from "../../CustomInput/IsolatedReferenceList";
import { StrikeTeamOptionSelect } from "../../CustomInput/StrikeTeamOptionSelect";
import { StrikeTeamStatusSelect } from "../../CustomInput/StrikeTeamStatusSelect";

interface IGenericStrikeTeamAnswerProps {
  answer: AnswerDto;
  stOptions: OptionDto[];
  wsaOptions: OptionDto[];
  engines: OptionDto[];
  stenGroupUsers: UserDto[];
  stenTGroupUsers: UserDto[];
  onChange(answer: AnswerDto): void;
  onDelete(answerOrder: number): void;
  defaultItemsCount: number;
  strikeTeamLabel?: string;
}

// interface IGenericStrikeTeamAnswerState {
//   selectedOptionId: number | null;
// }

const deployedStatusName: string = "Deployed";

function GenericStrikeTeamAnswer(props: IGenericStrikeTeamAnswerProps) {
  const context = useContext(AppContext);
  //   const [state, setState] = useState<IGenericStrikeTeamAnswerState>({
  //     selectedOptionId: null,
  //   });

  const updateAnswer = (options: StrikeTeamUpdateOptions): void => {
    if (!props.answer) return;
    let answer = cloneDeep(props.answer);

    if (options.incidentName !== undefined) {
      answer.strikeTeamIncidentName = options.incidentName;
    }

    if (options.statusOptionId) {
      answer.strikeTeamOptionId = options.statusOptionId;
    }

    if (options.referenceDetails) {
      updateStrikeTeamOption(answer, options.referenceDetails!);
    }

    props.onChange(answer);
  };

  const updateStrikeTeamOption = (
    answer: AnswerDto,
    referenceDetails: ReferenceDetails
  ) => {
    if (!answer.strikeTeamReferences) return;

    let reference: AnswerStrikeTeamReferenceDto | undefined =
      answer.strikeTeamReferences?.find(
        (o) => o.order === referenceDetails.order
      );
    if (reference) {
      reference.answerId = answer.answerId!;
      reference.optionId = referenceDetails.optionId;
      reference.userId = referenceDetails.userId;
      reference.optionName = referenceDetails.optionName;
      reference.order = referenceDetails.order;
      reference.type = referenceDetails.strikeTeamType!;
    } else {
      answer.strikeTeamReferences.push(
        createStrikeTeamOption(answer.answerId!, referenceDetails)
      );
    }
  };

  const createStrikeTeamOption = (
    answerId: number,
    referenceDetails: ReferenceDetails
  ): AnswerStrikeTeamReferenceDto => {
    return {
      answerStrikeTeamOptionReferenceId: 0,
      answerId: answerId,
      optionId: referenceDetails.optionId,
      userId: referenceDetails.userId,
      optionName: referenceDetails.optionName,
      order: referenceDetails.order,
      type: referenceDetails.strikeTeamType!,
    };
  };

  const getStrikeTeamOptionId = (): number | undefined => {
    return props.answer?.strikeTeamReferences?.find(
      (a) => a.order === StrikeTeamsOptionOrder.StrikeTeam
    )?.optionId;
  };

  const getStrikeTeamOptionName = (): string | undefined => {
    return props.answer?.strikeTeamReferences?.find(
      (a) => a.order === StrikeTeamsOptionOrder.StrikeTeam
    )?.optionName;
  };

  const isIncidentNameVisible = (): boolean => {
    const selectedOptionId = props.answer?.strikeTeamOptionId;
    if (!selectedOptionId) return false;

    const selectedOption: OptionDto | undefined = props.stOptions.find(
      (o) => o.optionId === selectedOptionId
    );

    if (!selectedOption) return false;

    return selectedOption.name === deployedStatusName;
  };

  const getEngineReferences = (): ReferenceDetails[] => {
    const references = props.answer?.strikeTeamReferences;
    if (!references) return [];

    return references.map((r) => {
      return {
        optionId: r.optionId,
        userId: r.userId,
        optionName: r.optionName,
        order: r.order,
      };
    });
  };

  const handleStStatusChanged = (value: string) => {
    const optionId = Number(value);
    const selectedOption = props.stOptions.find(
      (o) => o.optionId === optionId
    )!;

    let options: StrikeTeamUpdateOptions = {
      statusOptionId: selectedOption.optionId,
    };

    if (selectedOption.name !== deployedStatusName) {
      options.incidentName = "";
    }

    if (!selectedOption) return;

    updateAnswer(options);
  };

  const handleStrikeTeamOptionChanged = (
    optionId?: number,
    optionName?: string
  ) => {
    const optionDetails: ReferenceDetails = {
      optionId: optionId,
      optionName: optionName,
      order: StrikeTeamsOptionOrder.StrikeTeam,
      strikeTeamType: StrikeTeamType.StrikeTeam,
    };

    updateAnswer({
      referenceDetails: optionDetails,
    });
  };

  const handleEngineOptionChanged = (referenceDetails: ReferenceDetails) => {
    updateAnswer({
      referenceDetails: {
        ...referenceDetails,
        strikeTeamType: StrikeTeamType.Engine,
      },
    });
  };

  const handleIncidentNameChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === "") return;

    updateAnswer({
      incidentName: e.target.value,
    });
  };

  const handleAnswerDeleted = () => {
    props.onDelete(props.answer.order);
  };

  const isIncidentVisible = useMemo(() => {
    return isIncidentNameVisible();
  }, [props.answer, props.stOptions]);

  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-2">
          {context.isEditableDailyOperational && (
            <i
              className="delete-strike-team-btn mt-2 col-2 fas fa-trash"
              onClick={handleAnswerDeleted}
            />
          )}
          {!context.isEditableDailyOperational && (
            <i className="delete-strike-team-btn-disabled col-2 mt-2 fas fa-trash" />
          )}
        </div>
        <div className="col-10 pr-0">
          <StrikeTeamStatusSelect
            options={props.stOptions}
            value={props.answer.strikeTeamOptionId}
            onChange={handleStStatusChanged}
          />
        </div>
      </div>
      <div className="row mt-3 align-items-baseline">
        <div className="col-lg-5 pr-0">
          {props.strikeTeamLabel || "Strike Team"}:
        </div>
        <div className="col-lg-7 pr-0">
          <StrikeTeamOptionSelect
            enableEmptyValue={true}
            optionId={getStrikeTeamOptionId()}
            optionName={getStrikeTeamOptionName()}
            options={props.wsaOptions}
            onChange={handleStrikeTeamOptionChanged}
          />
        </div>
      </div>
      <div className="row mt-3">
        {isIncidentVisible && (
          <input
            placeholder="Incident Name"
            disabled={!context.isEditableDailyOperational}
            className="form-control col-12 incident-input"
            defaultValue={props.answer.strikeTeamIncidentName}
            onChange={handleIncidentNameChanged}
          />
        )}
      </div>
      <div className="row mt-3">
        <IsolatedReferenceList
          availableOptions={props.engines}
          sten={props.stenGroupUsers}
          stenT={props.stenTGroupUsers}
          defaultItemsCount={props.defaultItemsCount}
          initialOrder={StrikeTeamsAnswerOrder.StrikeTeam}
          references={getEngineReferences()}
          onChange={handleEngineOptionChanged}
        />
      </div>
    </div>
  );
}

export default GenericStrikeTeamAnswer;
