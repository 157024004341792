import { IToolPanelComp, IToolPanelParams } from "ag-grid-community";
import commonService from "../../services/CommonService";

export interface CustomStatsToolPanelParams extends IToolPanelParams {
  title: string;
}

export class ResetGridToolPanel implements IToolPanelComp {
  eGui!: HTMLDivElement;
  id: string = "";
  init(params: CustomStatsToolPanelParams) {
    this.eGui = document.createElement("div");
    this.id = commonService.getUniqueId();
    const renderStats = () => {
      this.eGui.innerHTML = this.renderButton(params);

      document
        .getElementById(`btn${this.id}`)
        ?.addEventListener("click", (e) => {
          params.api.dispatchEvent({ type: "customResetColumnsAndFilters" });
        });
    };
    params.api.addEventListener("modelUpdated", renderStats);
  }

  getGui() {
    return this.eGui;
  }

  refresh(): void {}

  renderButton(params: CustomStatsToolPanelParams) {
    return `<div class="py-3 px-4">      
      <div class="pb-2">
        <strong>
          Reset Columns & Filters
        </strong>
      </div>
      <button type="button" class="btn btn-outline-danger" id="btn${this.id}">
        Reset
      </button>
    </div>`;
  }
}
