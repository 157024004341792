import React, { useContext } from "react";
import { SectionList } from "../../../data/UserConsoleAdmin";
import { UCAdminContext } from "./UserConsoleAdmin";
import UserConsoleAdminSection from "./UserConsoleAdminSection";
var dnd = require('react-beautiful-dnd');

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "white" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: `${grid}px 0`
});

type Props = {
    path: number[]
    data: SectionList
    departmentName: string
}

const UserConsoleAdminSectionList: React.FC<Props> = ({ path, data, departmentName }) => {
    const { columnIndex, children } = data;
    const { addSection } = useContext(UCAdminContext);

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <div>
                            <h6>{`Column ${columnIndex + 1}`}</h6>
                        </div>
                        <button
                            className="btn btn-primary"
                            onClick={() => addSection(path, true)}
                        >
                            Add Section
                        </button>
                        <div className="mb-3"></div>
                        <dnd.Droppable key={`${path}`} droppableId={`${path}`} type="SECTIONS">
                            {(provided: any, snapshot: any) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {children.map((s, i) => (
                                        <dnd.Draggable key={`${[...path, i]}`} draggableId={`${[...path, i]}`} index={i}>
                                            {(provided: any, snapshot: any) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <UserConsoleAdminSection
                                                        departmentName={departmentName}
                                                        path={[...path, i]}
                                                        data={s}
                                                        key={`UCAdminSection-${s.sectionId}`}
                                                    />
                                                </div>
                                            )}
                                        </dnd.Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </dnd.Droppable>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserConsoleAdminSectionList;