import ApsModels from "../../models";
import fetcher from "./Fetcher";

class TrainingTypeService {
  save = async (data: ApsModels.ITrainingTypeInputDto) => {
    const url = "/api/TrainingType/Save";
    return fetcher.post<ApsModels.ITrainingTypeOutputDto>(url, data);
  };

  getAll = async (deptId: number) => {
    const url = `/api/TrainingType/GetAll/${deptId}`;
    return fetcher.get<ApsModels.ITrainingTypeOutputDto[]>(url);
  };
}

const trainingType = new TrainingTypeService();
export default trainingType;
