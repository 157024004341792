import React, { useState } from "react";
import { Pagination as BsPagination } from "react-bootstrap";

export interface IPagination {
  size?: "sm" | "lg";
  length: number;
  page: number;
  pageSize: number;
  maxPagesToShow?: number;
  pageChange: (page: number, pageSize: number) => void;
  showingOfWhatLabel?: string;
  disabled?: boolean;
  sizes?: number[];
  totalRows?: number;
}

export const usePaging = (page: number, pageSize: number) => {
  return useState({ page, pageSize });
};

function Pagination(props: IPagination) {
  let active = props?.page || 1;
  let items = [];
  const totalPages = Math.ceil(props.length / props.pageSize);
  let maxPages = props?.maxPagesToShow || 5;

  if (active > totalPages) {
    active = totalPages;
  }

  const maxPageFloor = Math.floor(maxPages / 2);
  let firstVisiblePage = active - maxPageFloor;
  if (firstVisiblePage < maxPageFloor) {
    firstVisiblePage = 1;
  }
  if (firstVisiblePage + maxPages > totalPages) {
    firstVisiblePage = totalPages - maxPages + 1;
  }

  if (maxPages > totalPages) {
    maxPages = totalPages;
    firstVisiblePage = 1;
  }

  for (
    let number = firstVisiblePage;
    number <= firstVisiblePage + maxPages - 1;
    number++
  ) {
    items.push(
      <BsPagination.Item
        key={number}
        active={number === active}
        onClick={() => props.pageChange(number, props.pageSize)}
        disabled={number === active || props.disabled}
      >
        {number}
      </BsPagination.Item>
    );
  }

  const getPagingMessage = () => {
    if (!props.length) {
      return "";
    }
    const from = props.pageSize * (props.page - 1) + 1;
    const to = props.pageSize * props.page;

    let ttlMsg = "";
    if (props.totalRows && props.totalRows > 0) {
      ttlMsg = `. Total of ${Number(props.totalRows).toLocaleString()} rows`;
    }

    return `Showing ${from.toLocaleString()} to ${(to > props.length
      ? props.length
      : to
    ).toLocaleString()} of ${props.length.toLocaleString()} ${
      props?.showingOfWhatLabel || ""
    }${ttlMsg}`;
  };

  return (
    <>
      <div
        className="pt-3 flex flex-row flex-wrap"
        style={{ gap: "15px", alignItems: "center" }}
      >
        <div className="flex flex-row" style={{ alignItems: "center" }}>
          <span className="pr-2">Show</span>
          <div className="form-group" style={{ margin: 0 }}>
            <select
              className={`form-control ${
                props.size === "sm" ? "form-control-sm" : ""
              }`}
              value={props.pageSize}
              onChange={(event) =>
                props.pageChange(1, Number(event.target.value))
              }
              disabled={props.disabled || false}
            >
              {(props?.sizes || [10, 20, 50, 100]).map((size) => (
                <option value={size} key={size}>
                  {size.toLocaleString()}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex-1 nowrap" style={{ textAlign: "left" }}>
          <span>{getPagingMessage()}</span>
        </div>

        {!!props?.length && (
          <div>
            <BsPagination size={props.size} style={{ margin: 0 }}>
              {totalPages > maxPages && (
                <>
                  <BsPagination.First
                    onClick={() => props.pageChange(1, props.pageSize)}
                    disabled={props.page === 1 || props.disabled}
                  />
                  <BsPagination.Prev
                    onClick={() =>
                      props.pageChange(props.page - 1, props.pageSize)
                    }
                    disabled={props.page === 1 || props.disabled}
                  />
                </>
              )}
              {items}
              {totalPages > maxPages && (
                <>
                  <BsPagination.Next
                    onClick={() =>
                      props.pageChange(props.page + 1, props.pageSize)
                    }
                    disabled={props.page === totalPages || props.disabled}
                  />
                  <BsPagination.Last
                    onClick={() => props.pageChange(totalPages, props.pageSize)}
                    disabled={props.page === totalPages || props.disabled}
                  />
                </>
              )}
            </BsPagination>
          </div>
        )}
      </div>
    </>
  );
}

export default Pagination;
