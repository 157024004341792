import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { IDrillReportProps } from ".";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import systemStore from "../../../stores/SystemStore";
import { FgSelect, IChildCardProps } from "../../Common/FormGroups";
import { FgInput } from "../../Common/FormGroups/FgInput";

function DrillExternalPartners(props: IChildCardProps<IDrillReportProps>) {
  const template = (props?.data?.template ||
    {}) as ApsModels.IDrillTemplateOutputDto;

  const [calJacs, setCalJacs] = useState(
    [] as ApsModels.ICalJacTrainingTypeOutputDto[]
  );
  const [subjects, setSubjects] = useState(
    [] as ApsModels.ILogDrillSubjectOutputDto[]
  );
  const [studies, setStudies] = useState(
    [] as ApsModels.ILogDrillAreaOfStudyOutputDto[]
  );

  const [selectedSubject, setSelectedSubject] = useState(null as any);

  let isaCourses = template.isaCourses || [];
  isaCourses.sort(ApsServices.common.sortByName);

  const setValue = (name: string, val: any) => {
    if (props.setValue) {
      (props.setValue as any)(name, val);
    }
  };

  const [showOtherISACourse, setShowOtherISACourse] = useState(false);

  const toggleOtherItems = () => {
    let hasMatch = false;
    //ISA Course
    isaCourses.forEach((item) => {
      if (
        props.getValues &&
        !hasMatch &&
        Number(item.id) === Number(props.getValues("isaCourseId")) &&
        item.name.toLowerCase().trim() === "other"
      ) {
        hasMatch = true;
      }
    });
    setShowOtherISACourse(hasMatch);
    setValue("_reqOtherISACourse", hasMatch);
  };

  const [subjectsReady, setSubjectsReady] = useState(false);
  const getSubjects = async () => {
    if (template.departmentId) {
      await ApsServices.http.logDrillSubject
        .getAll(template.departmentId)
        .then((data) => {
          setSubjects(data);
          if (!subjectsReady) {
            setSubjectsReady(true);
            if (props?.data?.report && props.setValue) {
              props.setValue(
                "logDrillSubjectId",
                props.data.report.logDrillSubjectId
              );
              setSelectedSubject(props.data.report.logDrillSubjectId);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setSubjects([]);
        });
    } else {
      setSubjects([]);
    }
  };

  const [studiesReady, setStudiesReady] = useState(false);
  const getStudies = async () => {
    setStudies([]);
    if (template.departmentId && selectedSubject) {
      await ApsServices.http.logDrillAreaOfStudy
        .getAll(selectedSubject, template.departmentId)
        .then((data) => {
          setStudies(data);

          let studyId: number | null;
          if (!studiesReady && props?.data?.report?.logDrillAreaOfStudyId) {
            studyId = props?.data?.report?.logDrillAreaOfStudyId || null;
            setStudiesReady(true);
          } else {
            studyId = systemStore.extraDrillInfo?.logDrillAreaOfStudyId || null;
          }

          if (props.setValue) {
            props.setValue("logDrillAreaOfStudyId", studyId);
          }
        })
        .catch((err) => {
          console.log(err);
          setStudies([]);
        });
    }
  };

  const [calJacsReady, setCalJacsReady] = useState(false);
  const getCalJacs = async () => {
    if (template.departmentId) {
      await ApsServices.http.calJacTrainingType
        .getAll(template.departmentId)
        .then((data) => {
          setCalJacs(data);

          let calJacId: number | null;

          if (!calJacsReady) {
            setCalJacsReady(true);
            calJacId = props?.data?.report?.calJacTrainingTypeId || null;
          } else {
            calJacId = systemStore.extraDrillInfo?.calJacTrainingTypeId || null;
          }

          if (props.setValue) {
            props.setValue("calJacTrainingTypeId", calJacId);
          }
        })
        .catch((err) => {
          console.log(err);
          setCalJacs([]);
        });
    } else {
      setCalJacs([]);
    }
  };

  useEffect(() => {
    getCalJacs();
    getSubjects();
    toggleOtherItems();
  }, []);

  useEffect(() => {
    getStudies();
  }, [selectedSubject]);

  useEffect(() => {
    setSelectedSubject(systemStore?.extraDrillInfo?.logDrillSubjectId);
  }, [systemStore.extraDrillInfo]);

  const hideInputs = () => {
    return (
      !props?.data?.template?.isDrillEnabled ||
      (!props?.data?.template?.isJacEnabled &&
        !props?.data?.template?.isIsaEnabled)
    );
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <strong>{props?.data?.sectionNumber}. External Partners</strong>

          <div className="mt-4"></div>
          {hideInputs() && (
            <div>
              Your agency currently does not report to external partners
            </div>
          )}

          {props?.data?.template?.isDrillEnabled &&
            props?.data?.template?.isJacEnabled && (
              <>
                <label>CAL-JAC Code</label>
                <div className="mt-2"></div>

                <FgSelect
                  id={props.form.logDrillSubjectId.name}
                  label="Primary Subject"
                  showRequiredLabel={true}
                  formState={props.formState}
                  registeredField={props.form.logDrillSubjectId}
                  options={subjects?.map((c) => {
                    return {
                      label: c.name,
                      value: c.id,
                    };
                  })}
                  onChange={(data) => {
                    const id = parseInt(data) || null;
                    setSelectedSubject(id);
                  }}
                  disabled={!props.data.allowEdit}
                />

                <div className="mt-4"></div>

                <FgSelect
                  id={props.form.logDrillAreaOfStudyId.name}
                  label="Specific Area of Study"
                  showRequiredLabel={true}
                  formState={props.formState}
                  registeredField={props.form.logDrillAreaOfStudyId}
                  options={studies?.map((c) => {
                    return {
                      label: c.name,
                      value: c.id,
                    };
                  })}
                  disabled={!props.data.allowEdit}
                />

                <div className="mt-4"></div>

                <FgSelect
                  id={props.form.calJacTrainingTypeId.name}
                  label="Type of Training"
                  showRequiredLabel={true}
                  formState={props.formState}
                  registeredField={props.form.calJacTrainingTypeId}
                  options={calJacs?.map((c) => {
                    return {
                      label: c.name,
                      value: c.id,
                    };
                  })}
                  disabled={!props.data.allowEdit}
                />
              </>
            )}

          {props?.data?.template?.isDrillEnabled &&
            props?.data?.template?.isIsaEnabled && (
              <>
                <div className="mt-4"></div>
                <FgSelect
                  id={props.form.isaCourseId.name}
                  label="ISA Details Course"
                  showRequiredLabel={true}
                  formState={props.formState}
                  registeredField={props.form.isaCourseId}
                  options={isaCourses?.map((c) => {
                    return {
                      label: c.name,
                      value: c.id,
                    };
                  })}
                  disabled={!props.data.allowEdit}
                  onChange={toggleOtherItems}
                />

                {showOtherISACourse && (
                  <div>
                    <div className="mt-2"></div>
                    <FgInput
                      label="Other ISA Course"
                      id={props.form.otherIsaCourse.name}
                      formState={props.formState}
                      registeredField={props.form.otherIsaCourse}
                      readOnly={!props.data.allowEdit}
                    />
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </>
  );
}

export default observer(DrillExternalPartners);
