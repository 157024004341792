import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ApsModels from "../../../../../models";
import ApsServices from "../../../../../services";
import toastStore from "../../../../../stores/ToastStore";
import CommonSpinner from "../../../../Common/CommonSpinner";
import FormModal from "../../../../Common/FormModal";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  IBasicDialogProps,
} from "../../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../../NFIRSHelper";
import commonService from "../../../../../services/CommonService";

function NFIRSDialogInvolved(
  props: IBasicDialogProps<{ reportId: number; id?: number }>
) {
  const isOther = () => {
    return !!(
      model &&
      invovlements?.data &&
      invovlements.data.find(
        (inv) =>
          inv.id === model.nfirsInvolvementEntryId &&
          inv.description === "Other"
      )
    );
  };

  const { registry, handleSubmit, formState, setValuesFromModel } = FgUseForm({
    nfirsInvolvementEntryId: {
      displayName: "Involvement",
      validation: { required: false },
    },
    otherTypeInvolved: {
      displayName: "Other",
      validation: { required: false, maxLength: 24 },
    },
    businessName: {
      displayName: "Business Name",
      validation: { required: false, maxLength: 25 },
    },
    name: {
      displayName: "Name",
      validation: { required: false },
    },
    phoneNumber: {
      displayName: "Phone Number",
      validation: { required: false },
    },
    addressNumber: {
      displayName: "Address Number",
      validation: { required: false },
    },
    streetOrHighway: {
      displayName: "Street or Highway",
      validation: { required: false },
    },
    city: {
      displayName: "City",
      validation: { required: false },
    },
    zipcode: {
      displayName: "Zip Code",
      validation: { required: false },
    },
    remarks: {
      displayName: "Remarks",
      validation: { required: false },
    },
  });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const disableSaveButton = () => {
    return (
      commonService.isNullOrWhitespace(
        `${model.nfirsInvolvementEntryId || ""}`
      ) &&
      commonService.isNullOrWhitespace(model.otherTypeInvolved) &&
      commonService.isNullOrWhitespace(model.name) &&
      commonService.isNullOrWhitespace(model.businessName) &&
      commonService.isNullOrWhitespace(model.phoneNumber) &&
      commonService.isNullOrWhitespace(model.addressNumber) &&
      commonService.isNullOrWhitespace(model.streetOrHighway) &&
      commonService.isNullOrWhitespace(model.city) &&
      commonService.isNullOrWhitespace(model.zipcode) &&
      commonService.isNullOrWhitespace(model.remarks)
    );
  };

  const [saving, setSaving] = useState(false);
  const submit = async (form: any) => {
    const data = {
      ...form,
      ...model,
      reportId: Number(props.data?.reportId),
      nfirsInvolvementEntryId: model.nfirsInvolvementEntryId
        ? Number(model.nfirsInvolvementEntryId)
        : null,
    };

    setSaving(true);
    await ApsServices.http.nfirsBasicInvolved
      .update({
        ...data,
        otherTypeInvolved: isOther() ? data.otherTypeInvolved : undefined,
      })
      .then((rtn) => {
        close(true);
        toastStore.showToast("Involved Person saved.", "success");
      })
      .catch((error) => {
        toastStore.showError("Failed saving Involved Person", error);
        setSaving(false);
      });
  };

  const [model, setModel] = useState(
    {} as ApsModels.INfirsBasicInvolvedInputDto
  );
  const [loading, setLoading] = useState(true);

  const getInvolvedPerson = async () => {
    if (props.data?.reportId && props.data?.id) {
      setLoading(true);
      await ApsServices.http.nfirsBasicInvolved
        .get(props.data?.id, props.data?.reportId)
        .then((rtn) => {
          setModel(rtn);
          setValuesFromModel(rtn);
          setLoading(false);
        })
        .catch((error) => {
          toastStore.showError("Failed getting Involved Person", error);
          close();
        });
    } else {
      setLoading(false);
    }
  };

  const invovlements = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsInvolvementEntries,
    "Invovlements"
  );

  useEffect(() => {
    invovlements.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invovlements.status === NfirsFetchStatus.Complete) {
      getInvolvedPerson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invovlements.status]);

  return (
    <div>
      <FormModal
        title={props?.data?.id ? "Edit Person" : "Add New Person"}
        isOpen={true}
        close={() => close()}
        submit={handleSubmit(submit)}
        size="lg"
        submitButtonLabel={
          saving ? "Saving..." : props?.data?.id ? "Yes, Update" : "Yes, Add"
        }
        closeButtonLabel="No, Cancel"
        disableSubmit={loading || saving || disableSaveButton()}
      >
        <div className={`p-3 ${loading ? "" : "display-none"}`}>
          <CommonSpinner></CommonSpinner>
        </div>
        {!loading && (
          <div>
            <h4 className="txt-primary">
              <i>Person Involved</i>
            </h4>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FgSelect
                  id="nfirsInvolvementEntryId"
                  label="Involvement"
                  selectMessage="- Select Involvement -"
                  registeredField={registry.nfirsInvolvementEntryId}
                  formState={formState}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      nfirsInvolvementEntryId:
                        Number(data) || (undefined as any),
                    });
                  }}
                  options={(invovlements?.data || []).map((o) => {
                    return {
                      label: o.description,
                      value: o.id,
                    };
                  })}
                ></FgSelect>

                <div className={isOther() ? "" : "display-none"}>
                  <FgInput
                    id="otherTypeInvolved"
                    label="Other"
                    placeHolder="Other (please specify)"
                    registeredField={registry.otherTypeInvolved}
                    formState={formState}
                    onChange={(data) => {
                      setModel({
                        ...model,
                        otherTypeInvolved: data,
                      });
                    }}
                    showRequiredLabel={true}
                  ></FgInput>
                </div>

                <FgInput
                  id="businessName"
                  label="Business Name (If Applicable)"
                  placeHolder="Business Name"
                  registeredField={registry.businessName}
                  formState={formState}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      businessName: data,
                    });
                  }}
                ></FgInput>

                <FgInput
                  id="name"
                  label="Name"
                  placeHolder="Name"
                  registeredField={registry.name}
                  formState={formState}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      name: data,
                    });
                  }}
                ></FgInput>

                <FgInput
                  id="phoneNumber"
                  label="Phone Number"
                  placeHolder="Phone Number"
                  registeredField={registry.phoneNumber}
                  formState={formState}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      phoneNumber: data,
                    });
                  }}
                  //maxlength={10}
                ></FgInput>
              </div>
              <div className="col-sm-12 col-md-6">
                <FgInput
                  id="addressNumber"
                  label="Address Number"
                  placeHolder="Address Number"
                  registeredField={registry.addressNumber}
                  formState={formState}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      addressNumber: data,
                    });
                  }}
                ></FgInput>

                <FgInput
                  id="streetOrHighway"
                  label="Street or Highway"
                  placeHolder="Street or Highway"
                  registeredField={registry.streetOrHighway}
                  formState={formState}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      streetOrHighway: data,
                    });
                  }}
                ></FgInput>

                <div className="row">
                  <div className="col-sm-8">
                    <FgInput
                      id="city"
                      label="City"
                      placeHolder="City"
                      registeredField={registry.city}
                      formState={formState}
                      onChange={(data) => {
                        setModel({
                          ...model,
                          city: data,
                        });
                      }}
                    ></FgInput>
                  </div>
                  <div className="col-sm-4">
                    <FgInput
                      id="zipcode"
                      label="Zip Code"
                      placeHolder="Zip Code"
                      registeredField={registry.zipcode}
                      formState={formState}
                      onChange={(data) => {
                        setModel({
                          ...model,
                          zipcode: data,
                        });
                      }}
                    ></FgInput>
                  </div>
                </div>

                <div className="mb-2">
                  <label>Was there a casualty?</label>

                  <div className="inline-radio-box-group">
                    <div>
                      <input
                        type="radio"
                        name="isCasualty"
                        id="isCasualtyYes"
                        value="1"
                        checked={model.isCasualty}
                        onChange={(event) => {
                          setModel({
                            ...model,
                            isCasualty: Number(event.target.value) === 1,
                          });
                        }}
                      ></input>
                      <label htmlFor="isCasualtyYes">Yes</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="isCasualty"
                        id="isCasualtyNo"
                        value="0"
                        checked={!model.isCasualty}
                        onChange={(event) => {
                          setModel({
                            ...model,
                            isCasualty: Number(event.target.value) === 1,
                          });
                        }}
                      ></input>
                      <label htmlFor="isCasualtyNo">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <FgInput
                  id="remarks"
                  label="Remarks"
                  placeHolder="Remarks"
                  registeredField={registry.remarks}
                  formState={formState}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      remarks: data,
                    });
                  }}
                  rows={5}
                ></FgInput>
              </div>
            </div>
          </div>
        )}
      </FormModal>
    </div>
  );
}

export default NFIRSDialogInvolved;
