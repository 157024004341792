import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSValidationService {
  validateReport = async (reportId: number) => {
    const url = `/api/NfirsValidation/ValidateReport/${reportId}`;
    return fetcher.get<ApsModels.INfirsValidationOutputDto>(url);
  };

  validateBasic = async (reportId: number) => {
    const url = `/api/NfirsValidation/ValidateBasic/${reportId}`;
    return fetcher.get<ApsModels.INfirsValidationModuleOutputDto>(url);
  };

  validateApparatusPersonnel = async (reportId: number) => {
    const url = `/api/NfirsValidation/ValidateApparatusPersonnel/${reportId}`;
    return fetcher.get<ApsModels.INfirsValidationModuleOutputDto>(url);
  };

  validateFire = async (reportId: number) => {
    const url = `/api/NfirsValidation/ValidateFire/${reportId}`;
    return fetcher.get<ApsModels.INfirsValidationModuleOutputDto>(url);
  };

  validateStructureFire = async (reportId: number) => {
    const url = `/api/NfirsValidation/ValidateStructureFire/${reportId}`;
    return fetcher.get<ApsModels.INfirsValidationModuleOutputDto>(url);
  };

  validateHazmat = async (reportId: number) => {
    const url = `/api/NfirsValidation/ValidateHazmat/${reportId}`;
    return fetcher.get<ApsModels.INfirsValidationModuleOutputDto>(url);
  };

  validateArson = async (reportId: number) => {
    const url = `/api/NfirsValidation/ValidateArson/${reportId}`;
    return fetcher.get<ApsModels.INfirsValidationModuleOutputDto>(url);
  };

  validateWildland = async (reportId: number) => {
    const url = `/api/NfirsValidation/ValidateWildland/${reportId}`;
    return fetcher.get<ApsModels.INfirsValidationModuleOutputDto>(url);
  };
}

const nfirsValidation = new NFIRSValidationService();
export default nfirsValidation;
