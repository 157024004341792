import React, { useEffect } from "react";
import { IsoReportTypeEnum } from "../../models/_all";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import systemStore from "../../stores/SystemStore";
import CommonSpinner from "../Common/CommonSpinner";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import SavedReports from "../BuildReports/SavedReports";

function ISOReports(props: any) {
  const reports = useNfirsGetData(
    ApsServices.http.isoReport.getAllReports,
    "Reports"
  );

  useEffect(() => {
    systemStore.setNewTrainingReport(undefined);

    if (systemStore.isoReports.length > 0) {
      reports.setData(systemStore.isoReports);
    } else {
      reports.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (
  //     systemStore.isoReports.length === 0 &&
  //     reports.data &&
  //     reports.status === NfirsFetchStatus.Complete
  //   ) {
  //     systemStore.setIsoReports(reports.data);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reports.status, reports.data]);

  return (
    <div
      className="flex flex-1 flex-column"
      style={{ marginBottom: "-60px", position: "relative" }}
    >
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="h4 mb-0 font-size-18 text-uppercase">
                Reports
              </span>
            </div>
            <div></div>
          </div>
        </div>
        <div className="row h-full flex-1">
          <div className="col-12 col-lg-12 flex flex-col">
            <div className="card">
              <div className="card-body flex flex-col p-4">
                <h4 className="pb-2">Create a New Report</h4>
                {reports.status === NfirsFetchStatus.InProgress && (
                  <CommonSpinner></CommonSpinner>
                )}
                {reports.status !== NfirsFetchStatus.InProgress && (
                  <div className="row">
                    {[
                      ...(reports.data || []),
                      {
                        id: -1,
                        name: "Training Hours",
                        description:
                          "View training hours pertaining to Courses, Drills and Online Training. Filter by course characteritics to gain insights.",
                        isoReportTypeEnum: -1,
                      },
                    ].map((data) => {
                      if (
                        data.isoReportTypeEnum ==
                          IsoReportTypeEnum.TrainingHours ||
                        (data.isoReportTypeEnum ==
                          IsoReportTypeEnum.IsoDetailReport &&
                          commonService.canViewISODetailReport()) ||
                        (data.isoReportTypeEnum ==
                          IsoReportTypeEnum.IsoOverviewReport &&
                          commonService.canViewISOOverviewReport()) ||
                        (data.isoReportTypeEnum ==
                          IsoReportTypeEnum.NfirsDyanamicReport &&
                          commonService.canViewNfirsDyanamicReport())
                      ) {
                        return (
                          <div
                            key={data.id}
                            className="col-sm-12 col-md-4 col-lg-3 mb-4"
                          >
                            <div
                              className="card-hover p-3 flex flex-column"
                              style={{
                                border: "solid 1px #ddd",
                                backgroundColor: "#F8F8F8",
                                height: "100%",
                              }}
                              onClick={(e) => {
                                if (
                                  data.id === IsoReportTypeEnum.TrainingHours
                                ) {
                                  props.history.push(`/reports/build/new`);
                                  return;
                                }
                                if (
                                  data.isoReportTypeEnum ==
                                  IsoReportTypeEnum.NfirsDyanamicReport
                                ) {
                                  props.history.push(
                                    `/reports/${data.id}/NFIRS`
                                  );
                                } else {
                                  props.history.push(`/reports/${data.id}`);
                                }
                              }}
                            >
                              <h4 className="text-primary">{data.name}</h4>
                              <div className="flex-1">{data.description}</div>
                              <div className="text-primary pt-2">
                                {data.isoReportTypeEnum ===
                                IsoReportTypeEnum.TrainingHours ? (
                                  <label className="m-0">Build Report</label>
                                ) : (
                                  <label className="m-0">
                                    {data.isoReportTypeEnum ===
                                    IsoReportTypeEnum.NfirsDyanamicReport
                                      ? "Generate Report"
                                      : "View Report"}
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}

                <div className="flex-1 flex flex-col">
                  <SavedReports {...props}></SavedReports>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ISOReports;
