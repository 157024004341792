import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ApsModels from "../../models";
import ApsServices from "../../services";
import toastStore from "../../stores/ToastStore";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";

export interface IReportFilters {
  personnelIds: number[];
  singlePersonnelName?: string;
  trainingCategories: ApsModels.IsoReportCategoryEnum[];
  allTrainingCategories?: ApsModels.ILookupIntDto[];
  fromDate: string;
  toDate: string;
}
export interface IReportFilterProps {
  apply: (data: IReportFilters) => void;
}

interface IOption {
  id: number;
  name: string;
}

function ReportFilter(props: IReportFilterProps) {
  const [filter, setFilter] = useState<IReportFilters>({
    personnelIds: [],
    trainingCategories: [], //ApsModels.IsoReportCategoryEnum.All,
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
  });

  //USERS TYPEAHEAD
  const [users, setUsers] = useState([] as IOption[]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([] as IOption[]);
  const refUser = React.createRef<AsyncTypeahead<any>>();
  const handleSearchUser = async (query: string) => {
    if ((query || "").trim() === "") {
      setUsers([]);
      setIsLoadingUsers(false);
      return;
    }
    setIsLoadingUsers(true);
    await ApsServices.http.isoCommon
      .typeAheadUser({ search: query, isAnd: false, recordCount: 10 })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));

        setUsers(options);
        setIsLoadingUsers(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoadingUsers(false);
      });
  };

  //CATEGORIES TYPEAHEAD
  const [categories, setCategories] = useState([] as IOption[]);
  const [selectedCategories, setSelectedCategories] = useState([] as IOption[]);
  const refCategory = React.createRef<AsyncTypeahead<any>>();
  const handleSearchCategory = async (query: string) => {
    let list = (
      storedCategories.data?.map((d) => {
        return {
          id: d.value,
          name: d.label,
        };
      }) || []
    ).filter((d) => !!d.id && d.name !== "No Match");

    if ((query || "").trim() === "") {
      setCategories(list);
      return list;
    } else {
      list = list.filter(
        (d) => d.name.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
      setCategories(list);
      return list;
    }
  };

  const storedCategories = useNfirsGetData(
    async () =>
      ApsServices.http.isoCommon.genericLookup("IsoReportCategoryEnum"),
    "Report Categories"
  );

  useEffect(() => {
    storedCategories.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      storedCategories.data &&
      storedCategories.status === NfirsFetchStatus.Complete
    ) {
      setCategories(
        (
          storedCategories.data?.map((d) => {
            return {
              id: d.value,
              name: d.label.replace("Hazmat", "HAZMAT"),
            };
          }) || []
        ).filter((d) => !!d.id && d.name !== "No Match")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedCategories.status, storedCategories.data]);

  const isValidDataRange = useMemo(() => {
    return !moment(filter.toDate).isBefore(filter.fromDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.toDate, filter.fromDate]);

  return (
    <div>
      <div className="row">
        <div className="col-sm-4">
          <strong>Select Personnel</strong>
          <div className="mt-2">
            <AsyncTypeahead
              id="basic-typeahead-single"
              labelKey="name"
              onSearch={handleSearchUser}
              onChange={(data) => {
                if (data && data.length > 0) {
                  const prts = [...selectedUsers];
                  if (prts.filter((p) => p.id === data[0].id).length === 0) {
                    prts.push({
                      id: data[0].id,
                      name: `${data[0].firstName} ${data[0].lastName}`,
                    });
                    setSelectedUsers(prts);
                    setFilter((p) => {
                      return {
                        ...p,
                        personnelIds: prts.map((p) => p.id),
                      };
                    });
                  }
                  setUsers(data);
                  (refUser.current as any)?.clear();
                }
              }}
              searchText={"Searching..."}
              isLoading={isLoadingUsers}
              options={users}
              placeholder="Search..."
              minLength={1}
              delay={500}
              useCache={false}
              ref={refUser}
            />
          </div>
          <div className="currentParticipants mt-2 mb-4">
            {selectedUsers?.map((p, i) => {
              return (
                <div key={i}>
                  <section>{`${p.name}`}</section>
                  <section
                    title="Remove"
                    onClick={() => {
                      const prts = [...selectedUsers];
                      prts.splice(i, 1);
                      setSelectedUsers(prts);

                      setFilter((p) => {
                        return {
                          ...p,
                          personnelIds: prts.map((p) => p.id),
                        };
                      });
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </section>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <strong>Select Training Category</strong>
          <div className="mt-2">
            <AsyncTypeahead
              id="basic-typeahead-single2"
              labelKey="name"
              onSearch={handleSearchCategory}
              onChange={(data) => {
                if (data && data.length > 0) {
                  const prts = [...selectedCategories];
                  if (prts.filter((p) => p.id === data[0].id).length === 0) {
                    prts.push({
                      id: data[0].id,
                      name: data[0].name,
                    });
                    setSelectedCategories(prts);
                    setFilter((p) => {
                      return {
                        ...p,
                        trainingCategories: prts.map((p) => p.id),
                      };
                    });
                  }
                  (refCategory.current as any)?.clear();
                }
              }}
              searchText={"Searching..."}
              isLoading={
                storedCategories.status === NfirsFetchStatus.InProgress
              }
              options={categories}
              placeholder="Search..."
              minLength={0}
              delay={500}
              useCache={false}
              ref={refCategory}
            />
          </div>
          <div className="currentParticipants mt-2 mb-4">
            {selectedCategories?.map((p, i) => {
              return (
                <div key={i}>
                  <section>{`${p.name}`}</section>
                  <section
                    title="Remove"
                    onClick={() => {
                      const prts = [...selectedCategories];
                      prts.splice(i, 1);
                      setSelectedCategories(prts);
                      setFilter((p) => {
                        return {
                          ...p,
                          trainingCategories: prts.map((p) => p.id),
                        };
                      });
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </section>
                </div>
              );
            })}
          </div>
          {/* <div className="mt-2">
            <select
              className="form-control"
              value={filter.trainingCategory}
              onChange={(e) => {
                e.persist();
                setFilter((p) => {
                  return {
                    ...p,
                    trainingCategory: Number(e.target?.value) || 0,
                  };
                });
              }}
            >
              {categories.status === NfirsFetchStatus.InProgress && (
                <option>Loading...</option>
              )}
              {categories.status !== NfirsFetchStatus.InProgress &&
                categories.data?.map((o) => {
                  return (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  );
                })}
            </select>
          </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <strong>Select Date Range</strong>
          <div className="mt-2 flex report-filter-daterange">
            <DateTimePickerV2
              dateOnly={true}
              onChange={(data) => {
                setFilter((p) => {
                  return {
                    ...p,
                    fromDate: data,
                  };
                });
              }}
              data={filter.fromDate}
            />
            <section className="px-2"> </section>
            <DateTimePickerV2
              dateOnly={true}
              onChange={(data) => {
                setFilter((p) => {
                  return {
                    ...p,
                    toDate: data,
                  };
                });
              }}
              data={filter.toDate}
            />
          </div>
          {!isValidDataRange && (
            <div className="text-danger pt-1">
              To Date must be greater or equal than From Date
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <button
          type="button"
          className="btn btn-primary px-4"
          disabled={!isValidDataRange}
          onClick={(e) => {
            if (!isValidDataRange) {
              toastStore.showToast(
                "To Date must be greater or equal than From Date",
                "warning"
              );
              return;
            }
            props.apply({
              ...filter,
              allTrainingCategories: storedCategories.data,
            });
          }}
        >
          Generate Report
        </button>
      </div>
    </div>
  );
}

export default ReportFilter;
