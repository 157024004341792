import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CredTemplateService {
  getCourseTemplate = async (id: number) => {
    const url = `/api/CredTemplate/GetCourseTemplate/${id}`;
    return fetcher.get<ApsModels.ICredCourseTemplateOutputDto>(url);
  };

  getCredentialTemplate = async (id: number) => {
    const url = `/api/CredTemplate/GetCredentialTemplate/${id}`;
    return fetcher.get<ApsModels.ICredCredentialTemplateOutputDto>(url);
  };

  createCourseTemplate = async (formData: FormData) => {
    const url = "/api/CredTemplate/CreateCourseTemplate";
    return fetcher.postFormData<ApsModels.ICredCourseTemplateOutputDto>(
      url,
      formData
    );
  };

  createCredentialTemplate = async (
    data: ApsModels.ICredCredentialTemplateInsertDto
  ) => {
    const url = "/api/CredTemplate/CreateCredentialTemplate";
    return fetcher.post<ApsModels.ICredCredentialTemplateOutputDto>(url, data);
  };

  listTemplates = async (data: ApsModels.ICredTemplateListInputDto) => {
    const url = "/api/CredTemplate/ListTemplates";
    return fetcher.post<ApsModels.ICredTemplateGridDto>(url, data);
  };

  updateCourseTemplate = async (id: number, formData: FormData) => {
    const url = `/api/CredTemplate/UpdateCourseTemplate/${id}`;
    return fetcher.postFormData<ApsModels.ICredCourseTemplateOutputDto>(
      url,
      formData
    );
  };

  updateCredentialTemplate = async (
    id: number,
    data: ApsModels.ICredCredentialTemplateUpdateDto
  ) => {
    const url = `/api/CredTemplate/UpdateCredentialTemplate/${id}`;
    return fetcher.put<ApsModels.ICredCredentialTemplateOutputDto>(url, data);
  };

  deleteCourseTemplate = async (id: number) => {
    const url = `/api/CredTemplate/DeleteCourseTemplate/${id}`;
    return fetcher.delete<any>(url);
  };

  deleteCredentialTemplate = async (id: number) => {
    const url = `/api/CredTemplate/DeleteCredentialTemplate/${id}`;
    return fetcher.delete<any>(url);
  };

  downloadCourseFile = async (id: number, fileId: number) => {
    const url = `/api/CredTemplate/DownloadCourseFile/${id}/${fileId}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  listCourseTemplateFiles = async (id: number) => {
    const url = `/api/CredTemplate/ListCourseTemplateFiles/${id}`;
    return fetcher.get<ApsModels.ICredCourseFileOutputDto[]>(url);
  };

  copyCourseTemplate = async (data: ApsModels.ICredCourseTemplateCopyDto) => {
    const url = "/api/CredTemplate/CopyCourseTemplate";
    return fetcher.post<ApsModels.ICredCourseTemplateOutputDto>(url, data);
  };

  displayCredentialTemplatesFlag = async () => {
    const url = `/api/CredTemplate/DisplayCredentialTemplatesFlag`;
    return fetcher.post<boolean>(url);
  };

  typeAheadCourseCategory = async (
    data: ApsModels.ICourseCategoryTypeAheadDto
  ) => {
    const url = `/api/CredTemplate/TypeAheadCourseCategory`;
    return fetcher.post<ApsModels.ICredCourseCategoryOutputDto[]>(url, data);
  };

  copyCredentialTemplate = async (
    data: ApsModels.ICredCredentialTemplateCopyDto
  ) => {
    const url = "/api/CredTemplate/CopyCredentialTemplate";
    return fetcher.post<ApsModels.ICredCredentialTemplateOutputDto>(url, data);
  };

  displayNotificationSectionInTemplatesFlag = async () => {
    const url = `/api/CredTemplate/DisplayNotificationSectionInTemplatesFlag`;
    return fetcher.post<any>(url);
  };

  saveCredentialNotificationSetting = async (
    templateId: number,
    data: ApsModels.ICredNotificationDetailDto
  ) => {
    const url = `/api/CredTemplate/SaveCredentialNotificationSetting/${templateId}`;
    return fetcher.post<any>(url, data);
  };

  enableDisableCredentialNotification = async (
    templateId: number,
    enabled: boolean
  ) => {
    const url = `/api/CredTemplate/EnableDisableCredentialNotification/${templateId}/${enabled}`;
    return fetcher.post<any>(url);
  };

  deleteCredentialNotificationSetting = async (
    templateId: number,
    notificationId: string
  ) => {
    const url = `/api/CredTemplate/DeleteCredentialNotificationSetting/${templateId}/${notificationId}`;
    return fetcher.delete<any>(url);
  };

  deleteCustomCredentialNotifications = async (templateId: number) => {
    const url = `/api/CredTemplate/DeleteCustomCredentialNotifications/${templateId}`;
    return fetcher.delete<any>(url);
  };

  credentialNotificationSetting = async (templateId: number) => {
    const url = `/api/CredTemplate/CredentialNotificationSetting/${templateId}`;
    return fetcher.get<ApsModels.ICredNotificationSettingOutputDto>(url);
  };

  saveCourseNotificationSetting = async (
    templateId: number,
    data: ApsModels.ICredNotificationDetailDto
  ) => {
    const url = `/api/CredTemplate/SaveCourseNotificationSetting/${templateId}`;
    return fetcher.post<any>(url, data);
  };

  deleteCourseNotificationSetting = async (
    templateId: number,
    notificationId: string
  ) => {
    const url = `/api/CredTemplate/DeleteCourseNotificationSetting/${templateId}/${notificationId}`;
    return fetcher.delete<any>(url);
  };

  courseNotificationSetting = async (templateId: number) => {
    const url = `/api/CredTemplate/CourseNotificationSetting/${templateId}`;
    return fetcher.get<ApsModels.ICredNotificationSettingDto>(url);
  };

  listNotificationSettings = async (
    data: ApsModels.ICredTemplateNotificationsListInputDto
  ) => {
    const url = `/api/CredTemplate/ListNotificationSettings`;
    return fetcher.post<ApsModels.ICredTemplateNotificationGridDto>(url, data);
  };

  allCredentialTemplatesAsync = async () => {
    const url = `/api/CredTemplate/AllCredentialTemplatesAsync`;
    return fetcher.get<ApsModels.ICredTemplateIdNameOutputDto[]>(url);
  };

  saveCustomNotifications = async (
    data: ApsModels.ICredCustomNotificationInputDto
  ) => {
    const url = `/api/CredTemplate/SaveCustomNotifications`;
    return fetcher.post<ApsModels.ICredCustomNotificationOutputErrorsDto>(
      url,
      data
    );
  };

  bulkDisableCredentials = async (
    data: ApsModels.ICredCustomNotificationBulkDisableDetailDto[]
  ) => {
    const url = `/api/CredTemplate/BulkDisableCredentials`;
    return fetcher.post<ApsModels.ICredCustomNotificationOutputErrorsDto>(
      url,
      data
    );
  };
}

const credTemplate = new CredTemplateService();
export default credTemplate;
