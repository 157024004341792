import fetchHelper from "../aps2/services/http/FetchHelper";
import { OptionGroupDto } from "../data/OptionGroup";

class OptionGroupService {
    getAllOptionGroupsByDepartment = async (departmentId: number): Promise<OptionGroupDto[]> => {
        return await fetchHelper.fetchUseExisting<OptionGroupDto[]>(`/Api/OptionGroup/OptionGroups/${departmentId}`);
    }
}


const optionGroupService = new OptionGroupService();
export { optionGroupService as OptionGroupService }
