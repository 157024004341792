import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomTabs from "../../../Common/CustomTabs";
import NFIRSEntryApparatus from "./NFIRSEntryApparatus";
import NFIRSEntryPersonnel from "./NFIRSEntryPersonnel";
import { INFIRSSectionProps, useSectionTabs } from "../NFIRSEntry";
import NFIRSValidationButton from "../NFIRSValidationButton";
import ApsModels from "../../../../models";

function NFIRSSectionApparatus(props: INFIRSSectionProps) {
  const { onTabSelect, activeTab, loadedTabs, changeTabStatus } =
    useSectionTabs(props, "Apparatus/Personnel", "Apparatus");

  return (
    <>
      <NFIRSValidationButton
        module={ApsModels.NfirsValidationModulesEnum.ApparatusPersonnel}
        onVerify={props.onVerify}
      ></NFIRSValidationButton>
      <CustomTabs
        onMenuTabClick={onTabSelect}
        activeTab={activeTab}
        className="custom-tab-with-grid"
      >
        <Tabs
          id="nfirs-apparatus-page"
          activeKey={activeTab}
          onSelect={onTabSelect}
        >
          <Tab eventKey="Apparatus" title="Apparatus">
            {loadedTabs["Apparatus"] && (
              <div className="pt-4">
                <NFIRSEntryApparatus
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Apparatus", hasChanges ? 1 : 0);
                  }}
                />
              </div>
            )}
          </Tab>
          <Tab eventKey="Personnel" title="Personnel">
            {loadedTabs["Personnel"] && (
              <div className="pt-4">
                <NFIRSEntryPersonnel
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Personnel", hasChanges ? 1 : 0);
                  }}
                />
              </div>
            )}
          </Tab>
        </Tabs>
      </CustomTabs>
    </>
  );
}

export default NFIRSSectionApparatus;
