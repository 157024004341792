import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import User from '../../assets/images/user-profile-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../../services/AuthenticationService';
import packageInfo from './../../../package.json';

type Props = {}

type State = {
    isOpen: boolean,
    firstName?: string,
    lastName?: string,
};

export class ProfileMenu extends Component<Props, State>{
    static displayName = ProfileMenu.name;

    state: State = {
        isOpen: false,
        firstName: '',
        lastName: '',
    };

    toogleDropdown = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    async componentDidMount() {
        const user = await AuthenticationService.initialize();
        this.setState({
            firstName: user?.firstName,
            lastName: user?.lastName,
        })
    }

    render() {

        let userName = this.state.firstName + ' ' + this.state.lastName;

        return (
            <div>
                <Dropdown isOpen={this.state.isOpen} onClick={this.toogleDropdown} className="d-inline-block" toggle={() => { }}>
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="d-sm-none rounded-circle header-profile-user" src={User} alt="Header Avatar" />
                        <span className="d-none d-sm-inline-block ml-2 mr-1">{userName}</span>
                        <FontAwesomeIcon className="d-none d-sm-inline-block" icon={faChevronDown} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <Link to="/account/profile" className="dropdown-item">
                            <span>Profile</span>
                        </Link>
                        <Link to="/account/logout" className="dropdown-item">
                            <span>Logout</span>
                        </Link>
                        <DropdownItem disabled={true}>
                            <small>v{packageInfo.version}</small>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    };
};