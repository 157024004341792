import systemStore from "../../stores/SystemStore";
import commonService from "../CommonService";

class Fetcher {
  private _processReq = async <T>(url: string, options?: any) => {
    options = options || {};
    options.headers = {
      ...this.getHeaders(), //set default header
      ...options.headers, //set custom headers
    };
    //console.log(options.headers);

    if (options?.headers["Content-Type"] === undefined) {
      delete options.headers["Content-Type"];
    }

    try {
      const req = await fetch(url, options);
      if (!req.ok) {
        try {
          return Promise.reject(await req.json());
        } catch (err) {
          return Promise.reject(`Error ${req.status}`);
        }
      }

      if (
        req.redirected &&
        req.url.toLowerCase().indexOf("account/accessdenied") > -1
      ) {
        systemStore.showSystemError("access-denied");
        throw "Access Denied"; //ERROR 302
      }

      const txt = await req.clone().text();
      if ((txt || "").trim() === "") {
        return {} as T;
      }

      try {
        const data = await req.json();
        return data as T;
      } catch (err) {
        //return {} as T;
        const idx = url.length - 30;
        throw `Url: ...${url.substr(
          idx > -1 ? idx : 0,
          30
        )} returned a non-JSON data`;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getHeaders() {
    return {
      "Content-Type": "application/json",
      "x-departmentid": commonService.currentDepartment,
    };
  }

  get = async <T>(url: string, moreOptions?: any) => {
    return await this._processReq<T>(url, moreOptions);
  };

  post = async <T>(url: string, formData?: any, moreOptions?: any) => {
    const options = {
      method: "POST",
      body: formData ? JSON.stringify(formData) : null,
      ...(moreOptions || {}),
    };
    return await this._processReq<T>(url, options);
  };

  put = async <T>(url: string, formData?: any, moreOptions?: any) => {
    const options = {
      method: "PUT",
      body: formData ? JSON.stringify(formData) : null,
      ...(moreOptions || {}),
    };
    return await this._processReq<T>(url, options);
  };

  delete = async <T>(url: string, formData?: any, moreOptions?: any) => {
    const options = {
      method: "DELETE",
      body: formData ? JSON.stringify(formData) : null,
      ...(moreOptions || {}),
    };
    return await this._processReq<T>(url, options);
  };

  postFormData = async <T>(url: string, formData?: any) => {
    const options = {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": undefined,
      },
    };
    return await this._processReq<T>(url, options);
  };

  putFormData = async <T>(url: string, formData?: any) => {
    const options = {
      method: "PUT",
      body: formData,
      headers: {
        "Content-Type": undefined,
      },
    };
    return await this._processReq<T>(url, options);
  };

  getFormData(files: File[], jsonData: any, fileFieldName = "file") {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(fileFieldName || "file", file);
    });
    formData.append("jsonData", JSON.stringify({ ...jsonData }));
    return formData;
  }

  mockPost = async (data: any) => {
    console.log(
      "Mock POST called. Make sure to replace this with correct API calls."
    );
    return await Promise.resolve(data);
  };

  mockGet = async (data: any) => {
    console.log(
      "Mock GET called. Make sure to replace this with correct API calls."
    );
    return await Promise.resolve(data);
  };
}

const fetcher = new Fetcher();
export default fetcher;
