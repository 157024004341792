import { SyntheticEvent, useRef, useState } from "react";
import {
  FieldValues,
  FormState,
  useForm,
  UseFormGetValues,
  UseFormRegisterReturn,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import commonService from "../../../services/CommonService";
import nfirsStore from "../../../stores/NFIRSStore";
import toastStore from "../../../stores/ToastStore";

//Export Child Components
export * from "./FgCheckbox";
export * from "./FgSelect";
export * from "./FgInput";
export interface IChildComponentProps<T> {
  onChange: (data: T, hasChanges?: boolean) => void;
  data: T;
}

export interface IChildCardProps<T> extends IChildComponentProps<T> {
  formState: FormState<FieldValues>;
  form: {
    [key: string]: {
      name: string;
      ref: UseFormRegisterReturn;
    };
  };
  setValue?: (name: string, val: any) => void;
  getValues?: UseFormGetValues<FieldValues>;
  trigger?: UseFormTrigger<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
}

export interface IBasicDialogProps<T> {
  onChange?: (data: T) => void;
  data?: T;
  existingItems?: string[];
  onClose: (saved?: boolean) => void;
}

export interface IFgUseFormValiation {
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  validate?: any;
}

interface IProgressModel {
  loading?: boolean;
  saving?: boolean;
  errorCode?: number;
}

export interface ISaveActionModel {
  objectName: string;
  promise: () => Promise<any>;
  afterSave: (data: any) => void;
  nfirsSectionTab?: string;
  onError?: (error: any) => void;
}

export const useFgModel = <T>(saveActionOpt?: ISaveActionModel) => {
  const [model, setModel] = useState<{
    new: T;
    old: T;
  }>({ new: {} as any, old: {} as any });
  const [progress, doSetProgress] = useState<IProgressModel>({
    loading: false,
    saving: false,
    errorCode: 0,
  });
  const saveData = async (doNotMoveTab?: boolean) => {
    //console.log("Save Data,,,");

    if (saveActionOpt) {
      if (progress.saving) {
        console.log(`Still saving ${saveActionOpt.objectName}...`);
        return;
      }

      doSetProgress({ ...progress, saving: true });
      if (saveActionOpt.nfirsSectionTab) {
        nfirsStore.setSectionTabBeingSaved(saveActionOpt.nfirsSectionTab);
      }

      return await saveActionOpt
        .promise()
        .then((data) => {
          if (doNotMoveTab) {
            //this delay resolves issues on * marker for unsaved changes not updating on time
            //when saving data on tab change (not Next button)
            return new Promise((resolve) =>
              setTimeout(() => resolve(data), 200)
            );
          }
          return data;
        })
        .then((data) => {
          toastStore.showToast(`${saveActionOpt.objectName} saved.`, "success");
          saveActionOpt.afterSave(data);
          if (
            !doNotMoveTab &&
            saveActionOpt.nfirsSectionTab &&
            !nfirsStore.currentTabForSaveAll
          ) {
            nfirsStore.setMoveToNextTab();
            setTimeout(() => {
              nfirsStore.fullResetMoveTabSection();
            }, 500);
          }
          if (nfirsStore.currentTabForSaveAll) {
            nfirsStore.updateCurrentTabForSaveAll(false, true);
          }
          doSetProgress({ ...progress, saving: false });
        })
        .catch((err) => {
          if (saveActionOpt.nfirsSectionTab) {
            nfirsStore.fullResetMoveTabSection();
          }
          if (nfirsStore.currentTabForSaveAll) {
            nfirsStore.updateCurrentTabForSaveAll(true);
          }
          const errCode = commonService.getErrorStatusCode(err);
          if (errCode === 409) {
            doSetProgress({ ...progress, errorCode: errCode });
          } else {
            doSetProgress({ ...progress, saving: false });
            if (saveActionOpt.onError) {
              saveActionOpt.onError(err);
            } else {
              toastStore.showError(
                `Failed saving ${saveActionOpt.objectName}`,
                err
              );
            }
          }
        })
        .finally(() => {
          if (saveActionOpt.nfirsSectionTab) {
            nfirsStore.setSectionTabBeingSaved();
          }
        });
    } else {
      console.error("Undefined saveActionOpt");
    }
  };
  return {
    model,
    getModel: model,
    setModel,
    progress,
    setProgress: (data: IProgressModel) => {
      doSetProgress({ ...progress, ...data });
    },
    hasChanges: () => {
      return !commonService.isEqual(model.new, model.old, true);
    },
    initModels: (data: T) => {
      //setTimeout(() => {
      setModel((prev) => {
        const copy = commonService.deepCloneJsonObject(data);
        return {
          ...prev,
          new: { ...copy },
          old: { ...copy },
        };
      });
      //}, 100);
    },
    saveData: async (doNotMoveTab?: boolean) => {
      await saveData(doNotMoveTab);
    },
  };
};

type ReturnedObject<T> = Record<keyof T, any>;
interface Args {
  [key: string]: { displayName: string; validation: IFgUseFormValiation };
}

export function FgUseForm<T extends Args>(fields: T) {
  const {
    register,
    handleSubmit,
    formState,
    trigger,
    setValue,
    getValues,
    watch,
    unregister,
    reset,
    clearErrors,
  } = useForm();

  const define = (displayName: string, validation: IFgUseFormValiation) => {
    let data = {} as any;
    if (validation.required) {
      data.required = `${displayName} is required`;
    }
    if (validation.min !== null && validation.min !== undefined) {
      data.min = {
        value: validation.min,
        message: `Min Value is ${validation.min}`,
      };
    }
    if (validation.max !== null && validation.max !== undefined) {
      data.max = {
        value: validation.max,
        message: `Max Value is ${validation.max}`,
      };
    }
    if (validation.minLength !== null && validation.minLength !== undefined) {
      data.minLength = {
        value: validation.minLength,
        message: `Min Length is ${validation.minLength} characters`,
      };
    }
    if (validation.maxLength !== null && validation.maxLength !== undefined) {
      data.maxLength = {
        value: validation.maxLength,
        message: `Max Length is ${validation.maxLength} characters`,
      };
    }
    if (validation.validate !== null && validation.validate !== undefined) {
      data.validate = validation.validate;
      // data.validate = {
      //   value: validation.validate,
      //   message: `Already exists`,
      // };
    }
    return data;
  };

  let registry = {} as ReturnedObject<T>;
  for (let f in fields) {
    registry[f] = register(
      f as any,
      define(fields[f].displayName, fields[f].validation)
    );
  }

  const newSetValue = (name: string, val: any) => {
    if (setValue) {
      (setValue as any)(name, val);
    }
    if (trigger && formState?.isSubmitted) {
      (trigger as any)(name);
    }
  };

  const formRef = useRef<any>();
  const triggerSubmitForm = () => {
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));

      if (
        nfirsStore.currentTabForSaveAll &&
        !nfirsStore.currentTabForSaveAll.next &&
        !nfirsStore.currentTabForSaveAll.failed
      ) {
        setTimeout(() => {
          let hasError = false;
          for (let e in formState.errors) {
            if (!hasError && formState.errors[e]) {
              hasError = true;
              nfirsStore.resetMoveTabSection(true);
            }
          }
        }, 100);
      }
    } else {
      console.error("formRef is not set");
    }
  };

  return {
    register: (
      fieldName: string,
      displayName: string,
      validation: IFgUseFormValiation
    ) => {
      const reg = (register as any)(fieldName, define(displayName, validation));
      (registry as any)[fieldName] = reg;
      return reg;
    },
    //register,
    handleSubmit: (submitHandler: (form: any) => void) => {
      return (event: SyntheticEvent) => {
        handleSubmit(submitHandler)(event);
        event.preventDefault();
        event.stopPropagation();
      };
    },
    formState,
    registry,
    trigger,
    setValue: newSetValue,
    setValuesFromModel: (model: any) => {
      for (let p in registry) {
        if (model[p] !== (getValues as any)(p)) {
          newSetValue(p, model[p]);
        }
      }
    },
    getValues,
    watch,
    unregister,
    reset,
    formRef,
    triggerSubmitForm,
    clearErrors,
  };
}
