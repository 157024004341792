import moment from "moment";
import React, { useEffect, useState } from "react";
import nfirsStore from "../../../../stores/NFIRSStore";
import DateTimePickerV2 from "../../../Common/DateTimePickerV2";
import FormError from "../../../Common/FormError";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgCheckbox,
  FgUseForm,
  useFgModel,
  FgSelect,
} from "../../../Common/FormGroups";
import ApsServices from "../../../../services";
import ApsModels from "../../../../models";
import toastStore from "../../../../stores/ToastStore";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import commonService from "../../../../services/CommonService";
import CommonSpinner from "../../../Common/CommonSpinner";
import { AsyncTypeahead } from "react-bootstrap-typeahead"; // ES2015
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
interface IOption {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
}

function NFIRSBasicAuthority(props: INFIRSTabProps<any>) {
  const saveAction = (): Promise<any> => {
    //do model pre-processing here
    const copy = { ...getModel.new };

    copy.officerInChargeDate = date1
      ? moment.utc(date1).toDate()
      : (null as any);
    copy.reportingOfficerDate = date2
      ? moment.utc(date2).toDate()
      : (null as any);

    if (sameAsOic || !copy.reportingOfficerAgencyId) {
      copy.reportingOfficerAgencyId = null as any;
      copy.reportingOfficerDate = null as any;
      copy.nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId =
        undefined as any;
    }

    return ApsServices.http.nfirsBasicAuthority.update(copy as any);
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsBasicAuthorityOutputDto>({
    objectName: "Authority",
    nfirsSectionTab: "Basic/Authority",
    promise: saveAction,
    afterSave: (data) => {
      const mdl = {
        ...model.new,
        concurrencyToken: data.concurrencyToken,
        reportingOfficerAgencyId: data.reportingOfficerAgencyId || undefined,
        reportingOfficerDate: data.reportingOfficerDate,
        nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId:
          data.nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId ||
          undefined,
        ...getFullNamesAndToggleDates(data),
      };
      initModels(mdl);
      setValuesFromModel(mdl);
      setSameAsOic(!data.reportingOfficerAgencyId);
    },
    onError: (error) => {
      if (commonService.getErrorStatusCode(error) === 404) {
        const errMsg = commonService.parseErrorMessage(error);
        if (errMsg === "Officer In Charge") {
          toastStore.showError(
            "Failed saving Authority",
            "Officer In Charge not found"
          );
          return;
        }
        if (errMsg === "Reporting Officer") {
          toastStore.showError(
            "Failed saving Authority",
            "Reporting Officer not found"
          );
          return;
        }
      }
      toastStore.showError("Failed saving Authority", error);
    },
  });

  const [sameAsOic, setSameAsOic] = useState(true);
  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    officeInChargedAgencyId: {
      displayName: "Personnel ID",
      validation: {
        required: false,
      },
    },
    officerInChargeFullName: {
      displayName: "Full Name",
      validation: {
        required: false,
      },
    },
    nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId: {
      displayName: "Assignment",
      validation: {
        required: false,
        // maxLength: 10,
        // validate: {
        //   lettersDashAndSpace: commonService.validations.lettersDashAndSpace,
        // },
      },
    },
    reportingOfficerAgencyId: {
      displayName: "Personnel ID",
      validation: {
        required: false,
      },
    },
    reportingOfficerFullName: {
      displayName: "Full Name",
      validation: {
        required: false,
      },
    },
    nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId: {
      displayName: "Assignment",
      validation: {
        required: false,
        // maxLength: 10,
        // validate: {
        //   lettersDashAndSpace: commonService.validations.lettersDashAndSpace,
        // },
      },
    },
    _dateInvalid1: { ...commonService.forms.datePicker },
    _dateInvalid2: { ...commonService.forms.datePicker },
  });

  const onModelChange = (newModel: ApsModels.INfirsBasicAuthorityOutputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const [date1, setDate1] = useState<Date | null>(null);
  const [date2, setDate2] = useState<Date | null>(null);

  const getFullNamesAndToggleDates = (data: any) => {
    setDate1(data.officerInChargeDate || null);
    setDate2(data.reportingOfficerDate || null);
    setSameAsOic(!data.reportingOfficerAgencyId);

    return {
      officerInChargeFullName: [
        data.officerInChargeFirstName,
        data.officerInChargeLastName,
      ]
        .filter((n) => !!n)
        .join(" "),
      reportingOfficerFullName: [
        data.reportingOfficerFirstName,
        data.reportingOfficerLastName,
      ]
        .filter((n) => !!n)
        .join(" "),
      _date1: data.officerInChargeDate
        ? moment(data.officerInChargeDate).format("YYYY-MM-DD")
        : null,
      _date2: data.reportingOfficerDate
        ? moment(data.reportingOfficerDate).format("YYYY-MM-DD")
        : null,
      _sameAsOic: !data.reportingOfficerAgencyId,
    };
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsBasicAuthority
      .get(props.id)
      .then((data) => {
        const mdl = {
          ...model.new,
          ...data,
          reportId: Number(props.id),
          ...getFullNamesAndToggleDates(data),
        };

        initModels(mdl);
        setValuesFromModel(mdl);
        setProgress({ loading: false });
        props.onChange(mdl);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Authority", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    const prev = tabChanged.isSaveOnTabChanged;
    if (!hasChanges()) {
      if (!prev) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Basic", "Authority", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Basic", "Authority", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      setSameAsOic(!model.new?.reportingOfficerAgencyId);
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const assignments = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsBasicAuthorityAssignmentTypeEntries,
    "Assignments"
  );

  useEffect(() => {
    if (assignments.status === NfirsFetchStatus.Complete) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignments.status]);

  useEffect(() => {
    assignments.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSearchingPersonnel, setIsSearchingPersonnel] = useState(false);
  const refPersonnelId = React.createRef<AsyncTypeahead<any>>();
  const [userPersonnelResults, setUserPersonnelResults] = useState(
    [] as IOption[]
  );
  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setUserPersonnelResults([]);
      setIsSearchingPersonnel(false);
      return;
    }
    setIsSearchingPersonnel(true);
    await ApsServices.http.nfirsBasicAuthority
      .typeAheadUser({ search: query, isAnd: false, recordCount: 10 })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.personnelId,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));
        setUserPersonnelResults(options);
        setIsSearchingPersonnel(false);
      })
      .catch((err) => {
        setUserPersonnelResults([]);
        setIsSearchingPersonnel(false);
      });
  };

  const [isSearchingPersonnel2, setIsSearchingPersonnel2] = useState(false);
  const refPersonnelId2 = React.createRef<AsyncTypeahead<any>>();
  const [userPersonnelResults2, setUserPersonnelResults2] = useState(
    [] as IOption[]
  );
  const handleSearch2 = async (query: string) => {
    if ((query || "").trim() === "") {
      setUserPersonnelResults2([]);
      setIsSearchingPersonnel2(false);
      return;
    }
    setIsSearchingPersonnel2(true);
    await ApsServices.http.nfirsBasicAuthority
      .typeAheadUser({ search: query, isAnd: false, recordCount: 10 })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.personnelId,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));
        setUserPersonnelResults2(options);
        setIsSearchingPersonnel2(false);
      })
      .catch((err) => {
        setUserPersonnelResults2([]);
        setIsSearchingPersonnel2(false);
      });
  };

  const getDefaultAssignment = () => {
    return (
      (assignments.data || []).find((o) => o.externalId === "1")?.id ||
      (null as any)
    );
  };

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />

      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      {!progress.loading && (
        <form className="row" onSubmit={handleSubmit(submit)} ref={formRef}>
          <div className="col-sm-12 col-lg-6">
            <strong>Officer In Charge</strong>
            <div className="mt-1" style={{ lineHeight: "19px", opacity: 0 }}>
              -
            </div>
            <div className="mt-2">
              <div className="row">
                <div className="col-sm-4">
                  <FgInput
                    id="officeInChargedAgencyId"
                    label="Personnel ID"
                    placeHolder="Personnel ID"
                    registeredField={registry.officeInChargedAgencyId}
                    formState={formState}
                    autoFocus={true}
                    onChange={(data) => {
                      const pid = `${data || ""}`.trim();
                      if (pid) {
                        onModelChange({
                          ...model.new,
                          officeInChargedAgencyId: pid,
                        } as any);
                      } else {
                        setValue(
                          "nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId",
                          undefined
                        );
                        //setValue("officerInChargeFullName", undefined);
                        onModelChange({
                          ...model.new,
                          officeInChargedAgencyId: pid,
                          nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId:
                            undefined,
                        } as any);
                      }
                    }}
                  ></FgInput>
                </div>
                <div className="col-sm-8">
                  <div className="mb-3">
                    <label>Search Officer In Charge</label>
                    <AsyncTypeahead
                      id="basic-auth-personnel"
                      labelKey="name"
                      onSearch={handleSearch}
                      onChange={(data: IOption[]) => {
                        (refPersonnelId.current as any)?.clear();
                        if (data && data.length > 0) {
                          setValue("officeInChargedAgencyId", data[0].id);
                          setValue(
                            "officerInChargeFullName",
                            `${data[0].firstName} ${data[0].lastName}`
                          );
                          if (
                            !model.new
                              .nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId
                          ) {
                            setValue(
                              "nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId",
                              getDefaultAssignment()
                            );
                          }

                          onModelChange({
                            ...model.new,
                            officeInChargedAgencyId: data[0].id,
                            officerInChargeFirstName: data[0].firstName,
                            officerInChargeLastName: data[0].lastName,
                            ...(model.new
                              .nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId
                              ? {}
                              : {
                                  nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId:
                                    getDefaultAssignment(),
                                }),
                          });
                        }
                      }}
                      searchText={"Searching..."}
                      isLoading={isSearchingPersonnel}
                      options={userPersonnelResults}
                      placeholder="Search Officer In Charge..."
                      minLength={1}
                      delay={500}
                      useCache={false}
                      ref={refPersonnelId}
                    />
                  </div>
                </div>
              </div>

              <FgInput
                id="officerInChargeFullName"
                label="Full Name"
                placeHolder="Full Name"
                registeredField={registry.officerInChargeFullName}
                formState={formState}
                disabled={true}
              ></FgInput>

              <div className="mb-3">
                <label>Date</label>
                <DateTimePickerV2
                  data={date1}
                  onChange={(data, invalid) => {
                    setDate1(data || null);
                    setValue("_dateInvalid1", invalid);
                    onModelChange({
                      ...model.new,
                      _date1: data ? moment(data).format("YYYY-MM-DD") : null,
                      _dateInvalid1: invalid,
                    } as any);
                  }}
                  dateOnly={true}
                ></DateTimePickerV2>
                <FormError
                  field="_dateInvalid1"
                  formState={formState}
                  fieldDisplayName={"Date"}
                ></FormError>
              </div>

              <FgSelect
                id="nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId"
                label="Assignment"
                placeHolder="Assignment"
                selectMessage="- Select Assignment -"
                registeredField={
                  registry.nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId
                }
                formState={formState}
                options={(assignments.data || [])
                  .sort(commonService.sortByNumericProperty("id"))
                  .map((opt) => {
                    return {
                      label: `${opt.externalId}. ${opt.description}`,
                      value: opt.id,
                    };
                  })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId:
                      Number(data) || (undefined as any),
                  })
                }
                disabled={!model.new.officeInChargedAgencyId}
              ></FgSelect>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <strong>Reporting Officer</strong>
            <div className="mt-1">
              <FgCheckbox
                id="sameAsOfficerInCharge"
                label="Same as Officer In Charge"
                data={sameAsOic}
                onChange={(val) => {
                  setSameAsOic(val);
                  onModelChange({
                    ...model.new,
                    _sameAsOic: val,
                  } as any);
                }}
              ></FgCheckbox>
            </div>
            <div className={`mt-2 ${sameAsOic ? "display-none" : ""}`}>
              <div className="row">
                <div className="col-sm-4">
                  <FgInput
                    id="reportingOfficerAgencyId"
                    label="Personnel ID"
                    placeHolder="Personnel ID"
                    registeredField={registry.reportingOfficerAgencyId}
                    formState={formState}
                    onChange={(data) => {
                      const pid = `${data || ""}`.trim();
                      if (pid) {
                        onModelChange({
                          ...model.new,
                          reportingOfficerAgencyId: pid,
                        } as any);
                      } else {
                        setValue(
                          "nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId",
                          undefined
                        );
                        onModelChange({
                          ...model.new,
                          reportingOfficerAgencyId: pid,
                          nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId:
                            undefined,
                        } as any);
                      }
                    }}
                  ></FgInput>
                </div>
                <div className="col-sm-8">
                  <div className="mb-3">
                    <label>Search Reporting Officer</label>
                    <AsyncTypeahead
                      id="basic-auth-personnel"
                      labelKey="name"
                      onSearch={handleSearch2}
                      onChange={(data: IOption[]) => {
                        (refPersonnelId2.current as any)?.clear();
                        if (data && data.length > 0) {
                          setValue("reportingOfficerAgencyId", data[0].id);
                          setValue(
                            "reportingOfficerFullName",
                            `${data[0].firstName} ${data[0].lastName}`
                          );
                          if (
                            !model.new
                              .nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId
                          ) {
                            setValue(
                              "nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId",
                              getDefaultAssignment()
                            );
                          }
                          onModelChange({
                            ...model.new,
                            reportingOfficerAgencyId: data[0].id,
                            reportingOfficerFirstName: data[0].firstName,
                            reportingOfficerLastName: data[0].lastName,
                            ...(model.new
                              .nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId
                              ? {}
                              : {
                                  nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId:
                                    getDefaultAssignment(),
                                }),
                          });
                        }
                      }}
                      searchText={"Searching..."}
                      isLoading={isSearchingPersonnel2}
                      options={userPersonnelResults2}
                      placeholder="Search Reporting Officer..."
                      minLength={1}
                      delay={500}
                      useCache={false}
                      ref={refPersonnelId2}
                    />
                  </div>
                </div>
              </div>

              <FgInput
                id="reportingOfficerFullName"
                label="Full Name"
                placeHolder="Full Name"
                registeredField={registry.reportingOfficerFullName}
                formState={formState}
                disabled={true}
              ></FgInput>

              <div className="mb-3">
                <label>Date</label>
                <DateTimePickerV2
                  data={date2}
                  onChange={(data, invalid) => {
                    setDate2(data || null);
                    setValue("_dateInvalid2", invalid);
                    onModelChange({
                      ...model.new,
                      _date2: data ? moment(data).format("YYYY-MM-DD") : null,
                      _dateInvalid2: invalid,
                    } as any);
                  }}
                  dateOnly={true}
                ></DateTimePickerV2>
                <FormError
                  field="_dateInvalid2"
                  formState={formState}
                  fieldDisplayName={"Date"}
                ></FormError>
              </div>

              <FgSelect
                id="nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId"
                label="Assignment"
                placeHolder="Assignment"
                selectMessage="- Select Assignment -"
                registeredField={
                  registry.nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId
                }
                formState={formState}
                options={(assignments.data || [])
                  .sort(commonService.sortByNumericProperty("id"))
                  .map((opt) => {
                    return {
                      label: `${opt.externalId}. ${opt.description}`,
                      value: opt.id,
                    };
                  })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId:
                      Number(data) || (undefined as any),
                  })
                }
                disabled={!model.new.reportingOfficerAgencyId}
              ></FgSelect>
            </div>
          </div>
          <button type="submit" hidden></button>
        </form>
      )}
    </>
  );
}

export default NFIRSBasicAuthority;
