import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgCheckbox,
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";

function NFIRSHazmatArea(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsHazmatArea.update({
      ...model.new,
      storyReleasedOn: model.new.isReleasedInsideStructure
        ? Number(model.new.storyReleasedOn) || (null as any)
        : (null as any),
    });
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsHazmatAreaInputDto>({
    objectName: "Area",
    nfirsSectionTab: "HAZMAT/Area",
    promise: saveAction,
    afterSave: (data) => {
      const mdl = {
        ...model.new,
        concurrencyToken: data.concurrencyToken,
        storyReleasedOn: data.storyReleasedOn || ("" as any),
      };
      initModels(mdl);
      setValuesFromModel(mdl);
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsHazmatAreaInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("HAZMAT", "Area", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("HAZMAT", "Area", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const [densities, setDensities] = useState<ApsModels.ILookupIntDto[]>([]);
  const getDensities = async () => {
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp("NfirsPopulationDensityEnum")
      .then((rtn) => {
        setDensities(rtn.sort(commonService.sortByNumericProperty("value")));
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Population Densities", err);
        setProgress({ loading: false });
      });
  };

  const [affectedUnits, setAffectedUnits] = useState<ApsModels.ILookupIntDto[]>(
    []
  );
  const getAffectedUnits = async () => {
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp("NfirsAreaAffectedUnitsEnum")
      .then((rtn) => {
        setAffectedUnits(
          rtn.sort(commonService.sortByNumericProperty("value"))
        );
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Area Affected Units", err);
        setProgress({ loading: false });
      });
  };

  const [evacuatedUnits, setEvacuatedUnits] = useState<
    ApsModels.ILookupIntDto[]
  >([]);
  const getEvacuatedUnits = async () => {
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp("NfirsAreaEvacuatedUnitsEnum")
      .then((rtn) => {
        setEvacuatedUnits(
          rtn.sort(commonService.sortByNumericProperty("value"))
        );
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Area Evacuated Units", err);
        setProgress({ loading: false });
      });
  };

  const actionsTaken = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getHazmatActionsTaken(false),
    "HAZMAT Actions"
  );

  const [ignitionOrReleaseOptions, setIgnitionOrReleaseOptions] = useState<
    ApsModels.INfirsIgnitionOrReleaseEntryOutputDto[]
  >([]);
  const getIgnitionOrReleaseOptions = async () => {
    await ApsServices.http.nfirsGenericLookupService
      .getNfirsIgnitionOrReleaseEntries()
      .then((rtn) => {
        setIgnitionOrReleaseOptions(rtn);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Options", err);
        setProgress({ loading: false });
      });
  };

  useEffect(() => {
    setProgress({ loading: true });
    getDensities();
    actionsTaken.getData();
    getAffectedUnits();
    getEvacuatedUnits();
    getIgnitionOrReleaseOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsHazmatArea
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Area", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      densities.length > 0 &&
      actionsTaken.status === NfirsFetchStatus.Complete &&
      affectedUnits.length > 0 &&
      evacuatedUnits.length > 0 &&
      ignitionOrReleaseOptions.length > 0
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    densities,
    actionsTaken.status,
    affectedUnits,
    evacuatedUnits,
    ignitionOrReleaseOptions,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    populationDensity: {
      displayName: "Population Density",
      validation: {
        required: false,
      },
    },
    nfirsHazmatActionTakenEntry1Id: {
      displayName: "HAZMAT Action Taken",
      validation: {
        required: false,
      },
    },
    nfirsHazmatActionTakenEntry2Id: {
      displayName: "HAZMAT Action Taken",
      validation: {
        required: false,
      },
    },
    nfirsHazmatActionTakenEntry3Id: {
      displayName: "HAZMAT Action Taken",
      validation: {
        required: false,
      },
    },
    areaAffectedUnits: {
      displayName: "Area Affected",
      validation: {
        required: false,
      },
    },
    areaAffected: {
      displayName: "Area Affected Units",
      validation: {
        required: false,
        max: 9999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    areaEvacuatedUnits: {
      displayName: "Area Evacuated",
      validation: {
        required: false,
      },
    },
    areaEvacuated: {
      displayName: "Area Evacuated Units",
      validation: {
        required: false,
        max: 9999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    numberPeopleEvacuated: {
      displayName: "People Evacuated",
      validation: {
        required: false,
        max: 999999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    numberBuildingEvacuated: {
      displayName: "Buildings Evacuated",
      validation: {
        required: false,
        max: 9999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsIgnitionOrReleaseEntryId: {
      displayName: "Fire or Explosion",
      validation: {
        required: false,
      },
    },
    storyReleasedOn: {
      displayName: "Story of Release",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
  });

  const adjustMultiSelectItems = (data: ApsModels.INfirsHazmatAreaInputDto) => {
    const items = [
      data.nfirsHazmatActionTakenEntry1Id,
      data.nfirsHazmatActionTakenEntry2Id,
      data.nfirsHazmatActionTakenEntry3Id,
    ].filter((i) => !!i);
    onModelChange({
      ...model.new,
      ...data,
      nfirsHazmatActionTakenEntry1Id: items[0],
      nfirsHazmatActionTakenEntry2Id: items[1],
      nfirsHazmatActionTakenEntry3Id: items[2],
    });
  };

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 ">
          <strong>Area</strong>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          {!progress.loading && (
            <div className="mt-2">
              <label>Where was the chemical released from?</label>
              <div className="mt-2">
                <FgCheckbox
                  id="isReleasedBelowGrade"
                  label="Below grade"
                  data={model.new.isReleasedBelowGrade}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      isReleasedBelowGrade: data,
                    });
                  }}
                ></FgCheckbox>
              </div>
              <div className="mt-3 row">
                <div className="col-sm-12 col-md-6">
                  <FgCheckbox
                    id="isReleasedInsideStructure"
                    label="Inside/on structure"
                    data={model.new.isReleasedInsideStructure}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        isReleasedInsideStructure: data,
                      });
                    }}
                  ></FgCheckbox>
                  {/* <div className="pb-2"></div> */}
                </div>
                <div
                  className={`col-sm-12 col-md-6 ${
                    model.new.isReleasedInsideStructure ? "" : "display-none"
                  }`}
                >
                  <FgInput
                    id="storyReleasedOn"
                    label="Story of Release"
                    placeHolder="Story of Release"
                    registeredField={registry.storyReleasedOn}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        storyReleasedOn: Number(data) || (null as any),
                      });
                    }}
                    type="number"
                    disabled={!model.new.isReleasedInsideStructure}
                  ></FgInput>
                </div>
              </div>
              <div className="mt-3">
                <FgCheckbox
                  id="isReleasedOutStructure"
                  label="Outside of structure"
                  data={model.new.isReleasedOutStructure}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      isReleasedOutStructure: data,
                    });
                  }}
                ></FgCheckbox>
              </div>
            </div>
          )}
          <div className="pt-4">
            <FgSelect
              id="populationDensity"
              label="Population Density"
              selectMessage="Population Density"
              registeredField={registry.populationDensity}
              formState={formState}
              options={densities.map((o) => {
                return {
                  label: `${o.value}. ${o.label}`,
                  value: o.value,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  populationDensity: Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="mt-2 mb-4">
            <label>HAZMAT Actions Taken</label>
            <div className="actionTakenList">
              {[
                getModel?.new?.nfirsHazmatActionTakenEntry1Id,
                getModel?.new?.nfirsHazmatActionTakenEntry2Id,
                getModel?.new?.nfirsHazmatActionTakenEntry3Id,
              ].map((item, i) => (
                <React.Fragment key={i}>
                  {item && (
                    <div>
                      <div>
                        {
                          actionsTaken.data?.find((a) => item === a.id)
                            ?.description
                        }{" "}
                        (
                        {
                          actionsTaken.data?.find((a) => item === a.id)
                            ?.externalId
                        }
                        )
                      </div>
                      <div>
                        <i
                          className="fa fa-times pointer"
                          onClick={() => {
                            if (i === 0) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsHazmatActionTakenEntry1Id: null as any,
                              });
                            } else if (i === 1) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsHazmatActionTakenEntry2Id: null as any,
                              });
                            } else if (i === 2) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsHazmatActionTakenEntry3Id: null as any,
                              });
                            }
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>

            {(!getModel?.new?.nfirsHazmatActionTakenEntry1Id ||
              !getModel?.new?.nfirsHazmatActionTakenEntry2Id ||
              !getModel?.new?.nfirsHazmatActionTakenEntry3Id) && (
              <AdvancedDropdown
                domId="nfirsHazmatActionTakenEntryId"
                value={null}
                searchPlaceholder="Search and Add More Actions"
                favoritesHandler={{
                  add: ApsServices.http.nfirsFavorite
                    .addFavoriteHazmatActionTaken,
                  remove:
                    ApsServices.http.nfirsFavorite
                      .removeFavoriteHazmatActionTaken,
                  afterUpdate: (list: any[]) => actionsTaken.setData(list),
                }}
                disabledOptionValues={[
                  getModel?.new?.nfirsHazmatActionTakenEntry1Id,
                  getModel?.new?.nfirsHazmatActionTakenEntry2Id,
                  getModel?.new?.nfirsHazmatActionTakenEntry3Id,
                ]}
                options={(actionsTaken.data || []).map((o) => {
                  return {
                    label: `${o.description} (${o.externalId})`,
                    value: o.id,
                    originalObject: o,
                    isFavorite: o.isFavorite,
                    groupId: 0,
                    subGroupId: 0,
                  };
                })}
                onChange={(data) => {
                  if (!getModel?.new?.nfirsHazmatActionTakenEntry1Id) {
                    onModelChange({
                      ...model.new,
                      nfirsHazmatActionTakenEntry1Id:
                        Number(data) || (null as any),
                    });
                  } else if (!getModel?.new?.nfirsHazmatActionTakenEntry2Id) {
                    onModelChange({
                      ...model.new,
                      nfirsHazmatActionTakenEntry2Id:
                        Number(data) || (null as any),
                    });
                  } else if (!getModel?.new?.nfirsHazmatActionTakenEntry3Id) {
                    onModelChange({
                      ...model.new,
                      nfirsHazmatActionTakenEntry3Id:
                        Number(data) || (null as any),
                    });
                  }
                }}
              ></AdvancedDropdown>
            )}
          </div>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div className="row">
            <div className="col-sm-6">
              <FgSelect
                id="areaAffectedUnits"
                label="Area Affected"
                selectMessage="Select Area"
                registeredField={registry.areaAffectedUnits}
                formState={formState}
                options={affectedUnits.map((o) => {
                  return {
                    label: `${o.value}. ${o.label}`,
                    value: o.value,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    areaAffectedUnits: Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className="col-sm-6">
              <FgInput
                id="areaAffected"
                label="Area Affected Units"
                placeHolder="Area Affected Units"
                registeredField={registry.areaAffected}
                formState={formState}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    areaAffected: Number(data) || (null as any),
                  });
                }}
                type="number"
              ></FgInput>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FgSelect
                id="areaEvacuatedUnits"
                label="Area Evacuated"
                selectMessage="Select Area"
                registeredField={registry.areaEvacuatedUnits}
                formState={formState}
                options={evacuatedUnits.map((o) => {
                  return {
                    label: `${o.value}. ${o.label}`,
                    value: o.value,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    areaEvacuatedUnits: Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className="col-sm-6">
              <FgInput
                id="areaEvacuated"
                label="Area Evacuated Units"
                placeHolder="Area Evacuated Units"
                registeredField={registry.areaEvacuated}
                formState={formState}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    areaEvacuated: Number(data) || (null as any),
                  });
                }}
                type="number"
              ></FgInput>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FgInput
                id="numberPeopleEvacuated"
                label="People Evacuated (Estimation)"
                placeHolder="People Evacuated"
                registeredField={registry.numberPeopleEvacuated}
                formState={formState}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    numberPeopleEvacuated: Number(data) || (null as any),
                  });
                }}
                type="number"
              ></FgInput>
            </div>
            <div className="col-sm-6">
              <FgInput
                id="numberBuildingEvacuated"
                label="Buildings Evacuated"
                placeHolder="Buildings Evacuated"
                registeredField={registry.numberBuildingEvacuated}
                formState={formState}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    numberBuildingEvacuated: Number(data) || (null as any),
                  });
                }}
                type="number"
              ></FgInput>
            </div>
          </div>

          <div>
            <FgSelect
              id="nfirsIgnitionOrReleaseEntryId"
              label="If fire or explosion is involved with a release, which occured first?"
              selectMessage="Select Option"
              registeredField={registry.nfirsIgnitionOrReleaseEntryId}
              formState={formState}
              options={ignitionOrReleaseOptions.map((o) => {
                return {
                  label: `${o.externalId} ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsIgnitionOrReleaseEntryId: Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSHazmatArea;
