import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridService } from './services/AgGridService';
import { Root } from './Root'


const license = AgGridService.getLicense()

license.then(x => {
    LicenseManager.setLicenseKey(x)
})

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Root />
    </BrowserRouter>,
    rootElement);

