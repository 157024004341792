import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSHazmatAreaService {
  get = async (reportId: any) => {
    const url = `/api/NfirsHazmatArea/${reportId}`;
    return fetcher.get<ApsModels.INfirsHazmatAreaOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsHazmatAreaInputDto) => {
    const url = `/api/NfirsHazmatArea`;
    return fetcher.post<ApsModels.INfirsHazmatAreaOutputDto>(url, data);
  };
}

const nfirsHazmatArea = new NFIRSHazmatAreaService();
export default nfirsHazmatArea;
