import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSBasicLocationService {
  get = async (id: any) => {
    const url = `/api/NfirsBasicLocation/${id}`;
    return fetcher.get<ApsModels.INfirsBasicLocationOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsBasicLocationInputDto) => {
    const url = `/api/NfirsBasicLocation`;
    return fetcher.post<ApsModels.INfirsBasicLocationOutputDto>(url, data);
  };
}

const nfirsBasicLocationService = new NFIRSBasicLocationService();
export default nfirsBasicLocationService;
