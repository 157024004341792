import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CompanyService {
  save = async (data: ApsModels.ICompanyInputDto) => {
    const url = "/api/Company/Save";
    return fetcher.post<ApsModels.ICompanyOutputDto>(url, data);
  };

  getAll = async (deptId: number) => {
    const url = `/api/Company/GetAll/${deptId}`;
    return fetcher.get<ApsModels.ICompanyOutputDto[]>(url);
  };
}

const company = new CompanyService();
export default company;
