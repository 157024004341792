import React, { useEffect, useMemo, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import { RouteComponentProps } from "react-router-dom";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  FgCheckbox,
} from "../../Common/FormGroups";
//import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Label } from "reactstrap";
import FileDragNDrop from "../../Common/FileDragNDrop";
import CourseCategoryTypeahead from "../../CourseRecords/Typeaheads/CourseCategoryTypeahead";
import NewCourseCategoryDialog from "../../CourseRecords/Dialogs/NewCourseCategoryDialog";
import { useDepartmentId } from "../../../stores/SystemStore";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRS/NFIRSHelper";
import commonService from "../../../services/CommonService";
import toastStore from "../../../stores/ToastStore";
import CommonSpinner from "../../Common/CommonSpinner";
import FileSaver from "file-saver";
import FormError from "../../Common/FormError";
import ConfirmDialog from "../../Common/ConfirmDialog";
import DuplicateCourseTemplateDialog from "./DuplicateCourseTemplateDialog";
import CheckboxList from "../../Common/CheckboxList";
import AddCourseNotificationDialog from "./AddCourseNotificationDialog";

function CourseTemplate(props: RouteComponentProps<any>) {
  const [id, setId] = useState(
    Number(props.match?.params?.id) || (null as any)
  );
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  const [newCategory, setNewCategory] = useState(false);
  const [model, setModel] = useState<ApsModels.ICredCourseTemplateOutputDto>({
    active: true,
  } as any);
  // const [isAPScategory, setIsApsCategory] = useState(false);
  // const ref = React.createRef<AsyncTypeahead<any>>();

  const navigateBack = (doneUrl?: string) => {
    if (props.history.action !== "POP") {
      commonService.routeGoBack(props, doneUrl);
    } else {
      props.history.push("/templates" + (doneUrl || ""));
    }
  };

  const departmentId = useDepartmentId();
  useEffect(() => {
    if (departmentId.current > 0) {
      setLoading(true);
      courseCategories.getData();
      trainingTypesList.getData();
      //displayNotification.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const courseCategories = useNfirsGetData(
    async () =>
      ApsServices.http.credCourseRecord.getCourseCategories(
        ApsModels.CredLibraryTypeEnum.Department
      ),
    "Course Categories"
  );

  const trainingTypesList = useNfirsGetData(
    ApsServices.http.credCommon.getTrainingType,
    "Training Types"
  );

  // const displayNotification = useNfirsGetData(
  //   ApsServices.http.credTemplate.displayNotificationSectionInTemplatesFlag,
  //   "Display Notification"
  // );

  const getTemplate = async () => {
    setLoading(true);
    await ApsServices.http.credTemplate
      .getCourseTemplate(id)
      .then((data) => {
        setModel({ ...data, _category: data.credCourseCategoryEntryId } as any);
        setValuesFromModel({ ...data });
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Course Template", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      courseCategories.status === NfirsFetchStatus.Complete &&
      courseCategories.data &&
      trainingTypesList.status === NfirsFetchStatus.Complete &&
      trainingTypesList.data
      //&& displayNotification.status === NfirsFetchStatus.Complete
    ) {
      if (id) {
        getTemplate();
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    courseCategories.status,
    trainingTypesList.status,
    //displayNotification.status,
  ]);

  const clearCourseDetails = () => {
    /*
            Need to implement this
        */
  };

  const submit = async (data: ApsModels.ICredCourseTemplateInsertDto) => {
    if (addNotifOnly) {
      toastStore.showToast(
        "The course you're trying to update is an Online Course. Please update it in the LMS. Only Notifications can be updated for Online Courses.",
        "warning"
      );
      return;
    }

    //console.log(model);
    setSaving(true);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    if (id) {
      formData.append(
        "jsonData",
        JSON.stringify({
          ...model,
          idOfFilesToDelete: filesToDelete,
        })
      );
      await ApsServices.http.credTemplate
        .updateCourseTemplate(id, formData)
        .then((data) => {
          setModel((prev) => {
            return {
              ...prev,
              data,
            };
          });
          toastStore.showToast("Course Template Saved", "success");
          navigateBack(commonService.doneUrls.submitted);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Template", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      formData.append(
        "jsonData",
        JSON.stringify({
          ...model,
        })
      );
      await ApsServices.http.credTemplate
        .createCourseTemplate(formData)
        .then((data) => {
          setModel((prev) => {
            return {
              ...prev,
              data,
            };
          });
          toastStore.showToast("Course Template Saved", "success");
          setId(data.id);
          navigateBack(commonService.doneUrls.created);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Template", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const {
    registry,
    handleSubmit,
    formState,
    setValue,
    setValuesFromModel,
    formRef,
  } = FgUseForm({
    /*
     * This is where we add fields for validation
     */
    courseLibrary: {
      displayName: "Course Library",
      validation: {
        required: false,
      },
    },
    credCourseCategoryEntryId: {
      displayName: "Course Category",
      validation: {
        required: true,
      },
    },
    name: {
      displayName: "Course Name",
      validation: {
        required: true,
      },
    },
    number: {
      displayName: "Course Number",
      validation: {
        required: true,
      },
    },
    providerNumber: {
      displayName: "CE Provider Number",
      validation: {
        required: false,
      },
    },
    jacCode: {
      displayName: "JAC Code",
      validation: {
        required: false,
        validate: {
          lettersOnly: (val: string) => {
            if (!commonService.validations.lettersOnly(val)) {
              return "Please enter 3 letters only";
            }
            return true;
          },
        },
      },
    },
    hours: {
      displayName: "Course Hours",
      validation: {
        required: true,
      },
    },
    trainingTypeId: {
      displayName: "Training Type",
      validation: {
        required: false,
      },
    },
    lmsCourseId: {
      displayName: "LMS Course ID",
      validation: {
        required: false,
      },
    },
    // courseQualificationEnum: {
    //   displayName: "Course Characteristics",
    //   validation: {
    //     required: true,
    //   },
    // },
    courseDescription: {
      displayName: "Course Description",
      validation: {
        required: false,
      },
    },
    courseObjective: {
      displayName: "Course Objectives",
      validation: {
        required: false,
      },
    },
    otherValidatorEmailAddresses: {
      displayName: "Other Validation Email(s)",
      validation: {
        required: false,
        validate: {
          required: (val: string) => {
            if (model.canOtherValidate) {
              if (commonService.isNullOrEmpty(val)) {
                return false;
              } else {
                return commonService.validateEmailCSV(val);
              }
            }
            return true;
          },
        },
      },
    },
    notifyOtherEmailAddresses: {
      displayName: "Notify Other Email(s)",
      validation: {
        required: false,
        validate: {
          required: (val: string) => {
            if (model.notifyOther) {
              if (commonService.isNullOrEmpty(val)) {
                return false;
              } else {
                return commonService.validateEmailCSV(val);
              }
            }
            return true;
          },
        },
      },
    },
    dateBeforeScheduledDateToNotify: {
      displayName: "Days Before Scheduled Date",
      validation: {
        required: false,
        validate: {
          required: (val: string) => {
            if (model.notifyBeforeScheduledDate) {
              if (commonService.isNullOrEmpty(val)) {
                return false;
              } else {
                return commonService.validateNumbersOnlyCSV(val);
              }
            }
            return true;
          },
        },
      },
    },
    dateAfterScheduledDateToNotify: {
      displayName: "Days After Scheduled Date",
      validation: {
        required: false,
        validate: {
          required: (val: string) => {
            if (model.notifyAfterScheduledDate) {
              if (commonService.isNullOrEmpty(val)) {
                return false;
              } else {
                return commonService.validateNumbersOnlyCSV(val);
              }
            }
            return true;
          },
        },
      },
    },
  });

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [duplicateTemplate, setDuplicateTemplate] = useState(false);

  const [showAddNotif, setShowAddNotif] = useState<
    ApsModels.ICredNotificationDetailDto | undefined
  >();
  const notifications = useNfirsGetData(
    async () => ApsServices.http.credTemplate.courseNotificationSetting(id),
    "Notification Settings"
  );

  const [notifToDelete, setNotifToDelete] = useState<string>("");
  const [notifToDeleting, setNotifToDeleting] = useState(false);

  useEffect(() => {
    if (id) {
      notifications.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const addNotifOnly = useMemo(() => {
    if (id && model.isFromLms) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, model.isFromLms]);

  return (
    <>
      {newCategory && (
        <NewCourseCategoryDialog
          onClose={(saved) => {
            setNewCategory(false);
          }}
          onChange={(data) => {
            const categ = data as ApsModels.ICredCourseCategoryOutputDto;
            courseCategories.setData((list) => {
              return [...(list || []), categ];
            });
            courseCategories.getData();
            if (categ) {
              setValue("credCourseCategoryEntryId", categ.id);
              setModel((p) => {
                return {
                  ...p,
                  _category: commonService.getNumber(categ.id),
                  credCourseId: undefined,
                  credCourseCategoryEntryId: categ.id,
                } as any;
              });
              clearCourseDetails();
            }
            setNewCategory(false);
          }}
        ></NewCourseCategoryDialog>
      )}
      {duplicateTemplate && (
        <DuplicateCourseTemplateDialog
          data={{
            name: model.name,
            id: model.id,
          }}
          onClose={(saved) => {
            setDuplicateTemplate(false);
          }}
          onChange={(data) => {
            if (data) {
              props.history.push(`/templates` + commonService.doneUrls.created);
            }
            setDuplicateTemplate(false);
          }}
        ></DuplicateCourseTemplateDialog>
      )}
      <ConfirmDialog
        show={confirmDelete}
        buttons="yesno"
        title="Confirmation"
        message={`Do you really want to delete this Course Template?`}
        done={(rtn) => {
          setConfirmDelete(false);
          if (rtn === "yes") {
            setLoading(true);
            ApsServices.http.credTemplate
              .deleteCourseTemplate(model.id)
              .then((data) => {
                toastStore.showToast("Course Template Deleted", "success");
                setLoading(false);
                props.history.push(
                  `/templates` + commonService.doneUrls.deleted
                );
              })
              .catch((error) => {
                toastStore.showError("Failed Deleting Course Template", error);
                setLoading(false);
              });
          }
        }}
      />
      {loading && <CommonSpinner overlay={true}></CommonSpinner>}
      {showAddNotif && model.id && (
        <AddCourseNotificationDialog
          data={{
            templateId: model.id,
            model: showAddNotif,
          }}
          onClose={(saved) => {
            if (saved) {
              notifications.getData();
            }
            setShowAddNotif(undefined);
          }}
        ></AddCourseNotificationDialog>
      )}
      <ConfirmDialog
        show={!!notifToDelete}
        buttons="yesno"
        title="Confirmation"
        message={`Do you really want to delete this Course Notification?`}
        done={(rtn) => {
          setConfirmDelete(false);
          if (rtn === "yes") {
            setNotifToDeleting(true);
            ApsServices.http.credTemplate
              .deleteCourseNotificationSetting(model.id, notifToDelete)
              .then((data) => {
                toastStore.showToast("Course Notification Deleted", "success");
                notifications.getData();
                setNotifToDeleting(false);
              })
              .catch((error) => {
                toastStore.showError(
                  "Failed Deleting Course Notification",
                  error
                );
                setNotifToDeleting(false);
              });
          }
          setNotifToDelete("");
        }}
      />
      {loading && <CommonSpinner overlay={true}></CommonSpinner>}
      {notifToDeleting && (
        <CommonSpinner
          overlay={true}
          message="Deleting Course Notification"
        ></CommonSpinner>
      )}
      <form
        className={`flex flex-1 flex-col ${loading ? "display-none" : ""}`}
        onSubmit={(e) => {
          //console.log(model);
          //console.log(formState.errors);
          handleSubmit(submit)(e);
        }}
        ref={formRef}
      >
        <div className="container-fluid">
          <div className="flex-0">
            <div className="headerControls">
              <div className="flex-1">
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  {id ? "EDIT" : "ADD"} COURSE TEMPLATE
                </span>
              </div>
              <div
                className="flex-0 flex flex-wrap"
                style={{ justifyContent: "right" }}
              >
                {!!id && (
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    disabled={saving}
                    onClick={(e) => {
                      if (
                        addNotifOnly &&
                        !commonService.friendlyClaims.isAPSAdmin
                      ) {
                        toastStore.showToast(
                          "The course you're trying to delete is an Online Course. Please delete it in the LMS.",
                          "warning"
                        );
                        return;
                      }
                      setConfirmDelete(true);
                    }}
                  >
                    Delete
                  </button>
                )}
                <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={saving}
                  onClick={(e) => {
                    navigateBack();
                  }}
                >
                  Cancel and Close
                </button>
                {!!id && (
                  <button
                    className="btn btn-success"
                    type="button"
                    disabled={saving}
                    onClick={(e) => {
                      if (addNotifOnly) {
                        toastStore.showToast(
                          "The course you're trying to copy is an Online Course. Please create a new one in the LMS.",
                          "warning"
                        );
                        return;
                      }
                      setDuplicateTemplate(true);
                    }}
                  >
                    Save as New Template
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={saving}
                >
                  <span className="px-2">
                    {saving ? "Saving..." : "Submit"}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col">
              <div className="card box-card h-100">
                <div className="card-body">
                  <h4 className="mb-0 font-size-16">
                    {"1. Course Information"}
                  </h4>
                  {id ? (
                    <>
                      <div className="mt-4">
                        <label>Course Category</label>
                      </div>
                      <div className="mt-1">
                        {courseCategories.data
                          ?.filter(
                            (x) => x.id === model.credCourseCategoryEntryId
                          )
                          ?.map((x) => (
                            <div
                              key={x.id}
                              className="alert alert-sm alert-secondary px-3"
                            >
                              <section>{`${x.description}`}</section>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-4"></div>
                      {/* <label>Course Library</label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setLibType(Number(e.target.value) || 0);
                          setModel((p) => {
                            return {
                              ...p,
                              _category: undefined,
                            } as any;
                          });
                          setValue("courseCategory", undefined);
                          clearCourseDetails();
                        }}
                      >
                        {ListCredLibraryTypes.map((i) => (
                          <option key={i.value} value={i.value}>
                            {i.label}
                          </option>
                        ))}
                      </select>
                      <div className="mt-3"></div> */}
                      <div className="flex flex-center">
                        <label className="flex-1">Course Category</label>
                        <div>
                          <span
                            className="text-primary pointer"
                            onClick={(e) => {
                              setNewCategory(true);
                            }}
                          >
                            New Category
                          </span>
                        </div>
                      </div>
                      <CourseCategoryTypeahead
                        forTemplate={true}
                        libType={ApsModels.CredLibraryTypeEnum.Department}
                        onChange={(data, ref) => {
                          if (data && data.length > 0) {
                            ref.current?.clear();
                            const categ = data[0]
                              .model as ApsModels.ICredCourseCategoryOutputDto;
                            //setIsApsCategory(categ.agency === "APS");
                            if (categ) {
                              setModel((p) => {
                                return {
                                  ...p,
                                  _category: commonService.getNumber(categ.id),
                                  credCourseCategoryEntryId: categ.id,
                                } as any;
                              });
                              setValue("credCourseCategoryEntryId", categ.id);
                              clearCourseDetails();
                            }
                          }
                        }}
                      ></CourseCategoryTypeahead>
                      <FormError
                        field="credCourseCategoryEntryId"
                        formState={formState}
                        fieldDisplayName="Course Category"
                      ></FormError>
                      <div className="currentParticipants mt-2">
                        {courseCategories.data
                          ?.filter((x) => x.id === (model as any)._category)
                          ?.map((x) => (
                            <div key={x.id}>
                              <section>{`${x.description}`}</section>
                              <section
                                title="Remove"
                                onClick={() => {
                                  setModel((p) => {
                                    return {
                                      ...p,
                                      _category: undefined,
                                      credCourseId: undefined,
                                    } as any;
                                  });
                                  setValue("courseCategory", undefined);
                                  clearCourseDetails();
                                  //setIsApsCategory(false);
                                }}
                              >
                                <i className="fa fa-times"></i>
                              </section>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                  <div className="mt-3"></div>
                  <FgInput
                    id={"course-name"}
                    label="Course Name"
                    placeHolder="Add course name"
                    registeredField={registry.name}
                    formState={formState}
                    readOnly={addNotifOnly}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          name: val,
                        };
                      });
                    }}
                  />
                  <FgInput
                    id={"course-code"}
                    label="Course Code"
                    placeHolder="Add course code"
                    registeredField={registry.number}
                    formState={formState}
                    readOnly={addNotifOnly}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          number: val,
                        };
                      });
                    }}
                  />
                  <FgInput
                    id={"course-hours"}
                    label="Course Hours"
                    placeHolder="Add course hours"
                    type="Number"
                    registeredField={registry.hours}
                    formState={formState}
                    readOnly={addNotifOnly}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          hours: commonService.getNumber(val),
                        };
                      });
                    }}
                  />
                  {/* <FgSelect
                    id="courseQualificationEnum"
                    label="Course Characteristics"
                    selectMessage="- Select Course Characteristics -"
                    options={[
                      {
                        label: "EMS CE",
                        value: ApsModels.CourseQualificationEnum.EmsCe,
                      },
                      {
                        label: "Instructor Qualifications",
                        value:
                          ApsModels.CourseQualificationEnum
                            .InstructorQualifications,
                      },
                      {
                        label: "ISO",
                        value: ApsModels.CourseQualificationEnum.Iso,
                      },
                      {
                        label: "None of the Above",
                        value: ApsModels.CourseQualificationEnum.NoneOfTheAbove,
                      },
                    ]}
                    onChange={(data) => {
                      setModel((p) => {
                        return {
                          ...p,
                          courseQualificationEnum:
                            commonService.getNumber(data),
                          qualifyForEmsCe:
                            commonService.getNumber(data) ===
                            ApsModels.CourseQualificationEnum.EmsCe,
                        } as any;
                      });
                    }}
                    registeredField={registry.courseQualificationEnum}
                    formState={formState}
                  /> */}
                  <label>Course Characteristics</label>
                  <CheckboxList
                    id="courseCharacteristics"
                    data={[
                      {
                        label: "EMS CE",
                        value: model.courseCharacteristicsDto?.isEmsCe || false,
                      },
                      {
                        label: "ISA",
                        value: model.courseCharacteristicsDto?.isIsa || false,
                      },
                      {
                        label: "ISO",
                        value: model.courseCharacteristicsDto?.isIso || false,
                      },
                      {
                        label: "JAC",
                        value: model.courseCharacteristicsDto?.isJac || false,
                      },
                    ]}
                    disabled={addNotifOnly}
                    onChange={(data) => {
                      setModel((prev) => {
                        return {
                          ...prev,
                          courseCharacteristicsDto: {
                            ...prev.courseCharacteristicsDto,
                            isEmsCe:
                              data.find((i) => i.label === "EMS CE")?.value ||
                              false,
                            isIsa:
                              data.find((i) => i.label === "ISA")?.value ||
                              false,
                            isIso:
                              data.find((i) => i.label === "ISO")?.value ||
                              false,
                            isJac:
                              data.find((i) => i.label === "JAC")?.value ||
                              false,
                          },
                        };
                      });
                    }}
                  ></CheckboxList>
                  <div className="py-2"></div>
                  <div
                    className={
                      model.courseCharacteristicsDto?.isEmsCe
                        ? ""
                        : "display-none"
                    }
                  >
                    <FgInput
                      id={"course-ce-provider"}
                      label="CE Provider Number"
                      placeHolder="Add number"
                      registeredField={registry.providerNumber}
                      formState={formState}
                      readOnly={addNotifOnly}
                      onChange={(val) => {
                        setModel((p) => {
                          return {
                            ...p,
                            providerNumber: val,
                          };
                        });
                      }}
                    />
                  </div>
                  <div
                    className={
                      model.courseCharacteristicsDto?.isIso
                        ? ""
                        : "display-none"
                    }
                  >
                    <FgSelect
                      id="trainingType"
                      label="ISO Training Type"
                      selectMessage="- Select Training Type -"
                      options={
                        trainingTypesList.data?.map((cat) => {
                          return {
                            label: cat.name,
                            value: cat.id,
                          };
                        }) || []
                      }
                      onChange={(data) => {
                        setModel((p) => {
                          return {
                            ...p,
                            trainingTypeId: commonService.getNumber(data),
                          };
                        });
                      }}
                      registeredField={registry.trainingTypeId}
                      formState={formState}
                      disabled={addNotifOnly}
                    />
                  </div>
                  <div
                    className={
                      model.courseCharacteristicsDto?.isJac
                        ? ""
                        : "display-none"
                    }
                  >
                    <FgInput
                      id={"jac-code-provider"}
                      label="JAC Code"
                      placeHolder="Add number"
                      registeredField={registry.jacCode}
                      formState={formState}
                      readOnly={addNotifOnly}
                      maxLength={3}
                      onChange={(data) => {
                        setValue("jacCode", data.toUpperCase());
                        setModel((p: any) => {
                          return {
                            ...p,
                            jacCode: data.toUpperCase(),
                          };
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label>Current Status</label>
                  </div>
                  <label>Active</label>
                  <label className="switch flex-none">
                    <input
                      type="checkbox"
                      name="course-active"
                      defaultChecked={model.active || false}
                      checked={model.active || false}
                      disabled={addNotifOnly}
                      onChange={(e) => {
                        if (e.target) {
                          const val = e.target.checked || false;
                          setModel((p) => {
                            return {
                              ...p,
                              active: val,
                            };
                          });
                        }
                      }}
                    />
                    <span className="slider round flex-none"></span>
                  </label>
                  <label>Inactive</label>

                  {model.lmsCourseId &&
                    commonService.friendlyClaims.isAPSAdmin && (
                      <div className="mt-2">
                        <FgInput
                          id="lms-course-id"
                          label="LMS Course ID"
                          placeHolder="LMS Course ID"
                          registeredField={registry.lmsCourseId}
                          formState={formState}
                          readOnly={true}
                          onChange={(data) => {}}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div
              className="col col-lg-8"
              //className={`col ${displayNotification.data ? "" : "col-lg-8"}`}
            >
              <div className="card box-card h-100">
                <div className="card-body">
                  <h4 className="mb-0 font-size-16">
                    {"2. Course Curriculum"}
                  </h4>
                  <div className="mt-4"></div>
                  <FgInput
                    id={"course-description"}
                    label="Course Description"
                    placeHolder="Enter description"
                    registeredField={registry.courseDescription}
                    formState={formState}
                    readOnly={addNotifOnly}
                    rows={7}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          courseDescription: val,
                        };
                      });
                    }}
                  />
                  <FgInput
                    id={"course-objectives"}
                    label="Course Objectives"
                    placeHolder="Enter objectives"
                    registeredField={registry.courseObjective}
                    formState={formState}
                    readOnly={addNotifOnly}
                    rows={7}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          courseObjective: val,
                        };
                      });
                    }}
                  />
                  <Label>Course Media or Attachments</Label>
                  <div className={addNotifOnly ? "display-none" : ""}>
                    <FileDragNDrop
                      label="Add File"
                      disabled={saving}
                      accepts={{
                        extensions:
                          "image/*, video/*, .doc, .docx, .xls, .xlsx, .txt, .rtf, .csv, .pdf",
                        pattern:
                          /\.(gif|jpe?g|png|bmp|doc|docx|xls|xlsx|txt|rtf|csv|pdf|mp4|mpeg|mkv|wmv|mov|avi|)$/i,
                      }}
                      dragClickLabel="Drop or upload files here <br/> (Take a photo for mobile)"
                      onChange={(fileList) => {
                        if (fileList.length > 0) {
                          const list = [...files];
                          let cnt = 0;
                          while (cnt < fileList.length) {
                            const file = fileList[cnt];
                            list.push(file);
                            cnt++;
                          }
                          setFiles(list);
                        }
                      }}
                    />
                  </div>
                  <div>
                    {model.courseFileOutputDtos?.map((cf) => (
                      <span
                        key={cf.id}
                        className="alert alert-secondary m-0 mt-2 flex flex-center"
                      >
                        <i
                          title="Download File"
                          className="mr-2 fa fa-download text-primary pointer"
                          onClick={() => {
                            if (model.id) {
                              ApsServices.http.credTemplate
                                .downloadCourseFile(model.id, cf.id)
                                .then((data) => {
                                  const file = commonService.b64toBlob(
                                    data.fileContents,
                                    "text/plain"
                                  );
                                  FileSaver.saveAs(file, data.fileDownloadName);
                                })
                                .catch((error) => {
                                  toastStore.showError(
                                    "Failed to Download File",
                                    error
                                  );
                                });
                            }
                          }}
                        ></i>
                        <span className="flex-1 overflow-hidden">
                          {filesToDelete.includes(cf.id) ? (
                            <s className="text-danger">
                              <i>{cf.fileName}</i>
                            </s>
                          ) : (
                            <span>{cf.fileName}</span>
                          )}
                        </span>
                        {filesToDelete.includes(cf.id) ? (
                          <i
                            title="Undo"
                            className="ml-2 fa fa-undo text-primary pointer"
                            onClick={() => {
                              setFilesToDelete(
                                filesToDelete.filter((f) => f !== cf.id)
                              );
                            }}
                          ></i>
                        ) : addNotifOnly ? (
                          <></>
                        ) : (
                          <i
                            title="Delete File"
                            className="ml-2 fa fa-trash text-danger pointer"
                            onClick={() => {
                              setFilesToDelete([...filesToDelete, cf.id]);
                            }}
                          ></i>
                        )}
                      </span>
                    ))}
                    {files?.map((f, i) => (
                      <span
                        key={i}
                        className="alert alert-info p-2 px-3 m-0 mt-2 flex flex-center"
                      >
                        <span className="flex-1 overflow-hidden">{f.name}</span>
                        {!saving && (
                          <i
                            title="Remove File"
                            className="mr-1 fa fa-times pointer"
                            onClick={() => {
                              const list = [...files];
                              list.splice(i, 1);
                              setFiles(list);
                            }}
                          ></i>
                        )}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* {displayNotification.data && (
              <div className="col">
                <div className="card box-card h-100">
                  <div className="card-body">
                    <h4 className="mb-0 font-size-16">
                      3. Course Notifications
                    </h4>
                    <div className="mt-2"></div>
                    <div className="text-right mb-2">
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled={saving || !id}
                        onClick={(e) => {
                          setShowAddNotif({} as any);
                        }}
                      >
                        Add Notification
                      </button>
                    </div>
                    <table className="table table-sm table-hover">
                      <thead>
                        <tr>
                          <th>When</th>
                          <th>Who</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {notifications.status ===
                          NfirsFetchStatus.InProgress && (
                          <tr>
                            <td colSpan={3}>Loading...</td>
                          </tr>
                        )}
                        {notifications.status === NfirsFetchStatus.Complete &&
                          !notifications.data?.credNotificationDetails
                            ?.length && (
                            <tr>
                              <td colSpan={3}>
                                <small>No Record(s) Found</small>
                              </td>
                            </tr>
                          )}
                        {notifications.status === NfirsFetchStatus.Complete &&
                          notifications.data?.credNotificationDetails?.map(
                            (row) => (
                              <tr
                                key={row.detailId}
                                className="pointer"
                                onClick={(e) => {
                                  setShowAddNotif({
                                    ...row,
                                  });
                                }}
                              >
                                <td>
                                  {[
                                    commonService.isNullOrWhitespace(
                                      row.daysBeforeAssignmentDueDate
                                    )
                                      ? ""
                                      : `${row.daysBeforeAssignmentDueDate} days before`,
                                    commonService.isNullOrWhitespace(
                                      row.daysAfterAssignmentDueDate
                                    )
                                      ? ""
                                      : `${row.daysAfterAssignmentDueDate} days after`,
                                    row.notifyOnAssignmentDate
                                      ? "On assignment due date"
                                      : "",
                                    row.notifyOnExpirationDate
                                      ? "On expiration date"
                                      : "",
                                    row.notifyUponEnrollment
                                      ? "Upon enrollment"
                                      : "",
                                    row.notifyUponCompletion
                                      ? "Upon completion"
                                      : "",
                                  ]
                                    .filter(
                                      (x) => !commonService.isNullOrEmpty(x)
                                    )
                                    .join(", ")}
                                </td>
                                <td>
                                  {[
                                    row.notifyAccountAdmins
                                      ? "Account Admin(s)"
                                      : "",
                                    row.notifyImmediateSupervisors
                                      ? "Immediate Supervisor(s)"
                                      : "",
                                    row.notifyParticipants
                                      ? "Participants(s)"
                                      : "",
                                    row.notifyOther
                                      ? row.notifyOtherEmailAddresses
                                      : "",
                                  ]
                                    .filter(
                                      (x) => !commonService.isNullOrEmpty(x)
                                    )
                                    .join(", ")}
                                </td>
                                <td>
                                  <i
                                    className="fa fa-trash text-danger pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setNotifToDelete(row.detailId);
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </form>
    </>
  );
}

export default CourseTemplate;
