import ApsModels from "../../models";
import fetcher from "./Fetcher";

class DrillReportService {
  get = async (drillId: number) => {
    const url = `/api/DrillReport/${drillId}`;
    return fetcher.get<ApsModels.IDrillReportOutputDto>(url);
  };

  saveDraft = async (data: ApsModels.IDrillReportInputDto) => {
    const url = "/api/DrillReport/SaveDraft";
    return fetcher.post<ApsModels.IDrillReportOutputDto>(url, data);
  };

  saveSubmitted = async (data: ApsModels.IDrillReportInputDto) => {
    const url = "/api/DrillReport/SaveSubmitted";
    return fetcher.post<ApsModels.IDrillReportOutputDto>(url, data);
  };

  typeAheadUser = async (data: ApsModels.IDrillReportUserTypeAheadInputDto) => {
    const url = "/api/DrillReport/TypeAheadUser";
    return fetcher.post<ApsModels.IDrillReportParticipantOutputDto[]>(
      url,
      data
    );
  };

  listLastUsedUsers = async () => {
    const url = "/api/DrillReport/ListLastUsedUsers";
    return fetcher.get<ApsModels.IDrillReportParticipantOutputDto[]>(url);
  };

  getAll = async (data: ApsModels.IDrillReportGridInputDto) => {
    const url = `/api/DrillReport/List`;
    return fetcher.post<ApsModels.IDrillReportGridDto>(url, data || {});
  };

  getDrillReportsForUser = async (
    data: ApsModels.IUserDrillReportGridInputDto
  ) => {
    const url = `/api/DrillReport/List/User`;
    return fetcher.post<ApsModels.IDrillReportGridDto>(url, data || {});
  };

  validateBeforeSubmit = async (
    data: ApsModels.IDrillReportSubmittedInputDto
  ) => {
    const url = "/api/DrillReport/ValidateBeforeSubmit";
    return fetcher.post<ApsModels.IDrillReportVerificationDto>(url, data);
  };

  processReportValidation = async (
    data: ApsModels.IDrillReportVerificationDto
  ) => {
    const url = "/api/DrillReport/ProcessReportValidation";
    return fetcher.post<any>(url, data);
  };

  deleteReport(id: any, concurrencyToken: any) {
    const url = `/api/DrillReport/${id}/${concurrencyToken}`;
    return fetcher.delete<any>(url);
  }

  export(data: ApsModels.IDrillReportExtractInputDto) {
    const url = "/api/DrillReport/Export";
    return fetcher.post<any>(url, data, { responseType: "blob" });
  }

  sendLogDrillReport(data: ApsModels.IDrillReportGridInputDto) {
    const url = "/api/DrillReport/SendLogDrillReport";
    return fetcher.post<any>(url, data);
  }

  listLastCompanyAndShift() {
    const url = "/api/DrillReport/ListLastCompanyAndShift";
    return fetcher.get<ApsModels.IDrillReportLastCompanyNameShiftOutputDto>(
      url
    );
  }
}

const drillReport = new DrillReportService();
export default drillReport;
