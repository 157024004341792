import React, { useEffect, useMemo, useRef, useState } from "react";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import ApsServices from "../../services";
import CustomTabs from "../Common/CustomTabs";
import { Tab, Tabs } from "react-bootstrap";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import moment from "moment";
import ApsModels from "../../models";
import { FgSelect } from "../Common/FormGroups";
import commonService, { IAgGridState } from "../../services/CommonService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import toastStore from "../../stores/ToastStore";
import ConfirmDialog from "../Common/ConfirmDialog";
import CourseRecordTypeahead from "../CourseRecords/Typeaheads/CourseRecordTypeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faBan } from "@fortawesome/free-solid-svg-icons";
import { ColumnState } from "ag-grid-community";
import systemStore, { useNewTrainingReport } from "../../stores/SystemStore";
import EnterReportNameDialog from "./EnterReportNameDialog";
import ReportScheduleTab from "./ReportScheduleTab";
import CommonSpinner from "../Common/CommonSpinner";

export const DATE_FIELDS = [
  "TakenToDate",
  "TakenFromDate",
  "DrillEndDate",
  "DrillStartDate",
  "LastModifiedOn",
];

function BuildReports(props: any) {
  const id = Number(props.match?.params?.id) || (null as any);
  const tab = props.match?.params?.tab;
  const newReport = useNewTrainingReport();

  const cols = useNfirsGetData(
    () => ApsServices.http.trainingReport.trainingReportColumns(),
    "Columns"
  );

  const [activeTab, setActiveTab] = useState<"filters" | "layout" | "schedule">(
    "filters"
  );
  const [scheduleLoaded, setScheduleLoaded] = useState(false);
  const [scheduleError, setScheduleError] = useState("");

  const [filter, setFilter] = useState<ApsModels.ITrainingReportInputDto>(
    {} as any
  );

  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<
    {
      name: string;
      id: number;
    }[]
  >([]);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const [openEmpDropdown, setOpenEmpDropdown] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const drillTemplate = useNfirsGetData(
    ApsServices.http.assignment.getDrillForSubmission,
    "Drills"
  );

  const getEmployees = useNfirsGetData(
    async () => ApsServices.http.group.getAllUsers(selectedGroup),
    "Employees"
  );

  const [selectedGroup, setSelectedGroup] = useState<number>();
  const groups = useNfirsGetData(
    ApsServices.http.group.listAllGroups,
    "Groups"
  );

  const [items, setItems] = useState<
    {
      id: any;
      label: string;
      type: "Course" | "Drill" | "OnlineTrainingCourse";
    }[]
  >([]);

  const report = useNfirsGetData(
    async () => ApsServices.http.trainingReport.get(id as any),
    "Report"
  );

  const [onlineTrainingCourses, setOnlineTrainingCourses] = useState(
    [] as any[]
  );
  const [isLoadingOnlineTrainingCourses, setIsLoadingOnlineTrainingCourses] =
    useState(false);
  const refOnlineTrainingCourses = React.createRef<AsyncTypeahead<any>>();

  const refDrill = React.createRef<AsyncTypeahead<any>>();
  const [openDrillDropdown, setOpenDrillDropdown] = useState(false);

  const handleSearchOnlineTraingCourses = async (query: string) => {
    if ((query || "").trim() === "") {
      setOnlineTrainingCourses([]);
      setIsLoadingOnlineTrainingCourses(false);
      return;
    }

    setIsLoadingOnlineTrainingCourses(true);
    await ApsServices.http.assignment
      .typeAheadOnlineTrainingCourse({
        search: query,
        isAnd: false,
        recordCount: 10,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.name} (${i.number})`,
          displayName: `${i.category} - ${i.name}`,
          code: `${i.number}`,
          model: { ...i },
        }));
        setOnlineTrainingCourses(options);
        setIsLoadingOnlineTrainingCourses(false);
      })
      .catch((err) => {
        setOnlineTrainingCourses([]);
        setIsLoadingOnlineTrainingCourses(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const tmo = setTimeout(() => {
      setIsLoading(false);
      if (groups.status === NfirsFetchStatus.Complete) {
        getEmployees.getData();
      }
    }, 500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, groups.status]);

  useEffect(() => {
    setIsLoading(getEmployees.status !== NfirsFetchStatus.Complete);
    if (
      getEmployees.status === NfirsFetchStatus.Complete &&
      getEmployees.data
    ) {
      setUsers((list) => {
        return [
          ...(getEmployees.data || []).map((i) => {
            return {
              id: i.id,
              name: `${i.firstName} ${i.lastName}`,
              firstName: i.firstName,
              lastName: i.lastName,
            };
          }),
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployees.status]);

  useEffect(() => {
    setFilter((p) => {
      return {
        ...p,
        trainingReportSettingsDto: {
          ...p.trainingReportSettingsDto,
          userIds: employees.map((e) => e.id),
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  useEffect(() => {
    setFilter((p) => {
      return {
        ...p,
        trainingReportSettingsDto: {
          ...p.trainingReportSettingsDto,
          drillNameIds: items
            .filter((i) => i.type === "Drill")
            .map((i) => i.id),
          courseIds: items
            .filter(
              (i) => i.type === "Course" || i.type === "OnlineTrainingCourse"
            )
            .map((i) => i.id),
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    cols.getData();
    groups.getData();
    drillTemplate.getData();

    if (!id) {
      if (newReport) {
        report.setDataAndComplete({ ...newReport }); //COPY NEW REPORT MODEL
        setTimeout(() => {
          systemStore.setNewTrainingReport(undefined); //CLEAR TO NOT BE CONFUSED WHEN USER NAVIGATES OUT
        }, 200);
      } else {
        setFilter((prev) => {
          return {
            ...prev,
            trainingReportSettingsDto: {
              ...(prev.trainingReportSettingsDto || ({} as any)),
              fromDate: moment().startOf("month").format("YYYY-MM-DD"),
              toDate: moment().endOf("month").format("YYYY-MM-DD"),
            },
          };
        });
      }
    } else {
      report.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (report.status === NfirsFetchStatus.Complete && report.data) {
      setFilter(report.data);

      const list = employees;
      list.splice(0, list.length);
      report.data?.usersInReport?.forEach((emp) => {
        list.push({
          id: emp.id,
          name: `${emp.name} ${emp.lastName}`,
        });
      });
      setEmployees(list);

      const itemList = [...items].splice(0, items.length);
      report.data?.coursesInReport?.forEach((course) => {
        itemList.push({
          id: course.id,
          label: course.name,
          type: "Course",
        });
      });
      report.data?.drillsInReport?.forEach((drill) => {
        itemList.push({
          id: drill.id,
          label: drill.name,
          type: "Drill",
        });
      });
      setItems(itemList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.status]);

  const ready = useMemo(() => {
    if (newReport) {
      return false;
    }
    if (!id && filter.trainingReportSettingsDto && !newReport) {
      return true;
    }
    if (
      report.data &&
      report.status === NfirsFetchStatus.Complete &&
      filter.trainingReportSettingsDto
    ) {
      return true;
    }
    return false;
  }, [id, report.status, filter, newReport]);

  const addCols = (col: ApsModels.ITrainingReportColumnsDetailsDto) => {
    setFilter((p) => {
      return {
        ...p,
        trainingReportSettingsDto: {
          ...p.trainingReportSettingsDto,
          columnIds: p.trainingReportSettingsDto?.columnIds?.find(
            (c) => c === col.id
          )
            ? p.trainingReportSettingsDto?.columnIds?.filter(
                (c) => c !== col.id
              )
            : [...(p.trainingReportSettingsDto?.columnIds || []), col.id],
        },
      };
    });
  };

  const [showName, setShowName] = useState(false);
  const save = (saveOnly = false) => {
    if (
      !filter.trainingReportSettingsDto.typeDrills &&
      !filter.trainingReportSettingsDto.typeCourses &&
      !filter.trainingReportSettingsDto.typeOnlineTraining
    ) {
      toastStore.showToast(
        "Please select at least one Training Type",
        "warning"
      );
      setActiveTab("filters");
      return;
    }
    if (!filter.trainingReportSettingsDto?.columnIds?.length) {
      toastStore.showToast(
        "Please select at least one column for your report.",
        "warning"
      );
      setActiveTab("layout");
      return;
    }

    if (
      report.data &&
      report.data.id > 0 &&
      !commonService.isNullOrWhitespace(scheduleError)
    ) {
      toastStore.showToast(scheduleError, "warning");
      setActiveTab("schedule");
      setScheduleLoaded(true);
      return;
    }

    doSave(saveOnly);
  };

  const [saving, setSaving] = useState(0); // 0 Default, 1 Save Only, 2 Save & Preview
  const doSave = async (saveOnly = false) => {
    setSaving(saveOnly ? 1 : 2);
    if (!id) {
      systemStore.setNewTrainingReport({
        ...filter,
        nextTimeScheduleRun: null as any,
        usersInReport: [
          ...employees.map((r) => {
            return {
              id: r.id,
              name: r.name,
              lastName: "",
            };
          }),
        ],
        drillsInReport: [
          ...items
            .filter((i) => i.type === "Drill")
            .map((i) => {
              return {
                id: i.id,
                name: i.label,
                lastName: "",
              };
            }),
        ],
        coursesInReport: [
          ...items
            .filter(
              (i) => i.type === "Course" || i.type === "OnlineTrainingCourse"
            )
            .map((i) => {
              return {
                id: i.id,
                name: i.label,
                lastName: "",
              };
            }),
        ],
        id: 0,
      });
      props.history.push(`/reports/view/new`);
    } else {
      await ApsServices.http.trainingReport
        .update(id, filter)
        .then((data) => {
          toastStore.showToast("Report Saved", "success");
          if (!saveOnly) {
            props.history.push(`/reports/view/${data.id}`);
          }
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Report", err);
        })
        .finally(() => {
          setSaving(0);
        });
    }
  };

  const [colSearch, setColSearch] = useState("");
  const filterCol = (col: ApsModels.ITrainingReportColumnsDetailsDto) =>
    col.columLabel.toLowerCase().indexOf(colSearch.toLowerCase().trim()) > -1;

  const dragItem = useRef<string>();
  const dragOverItem = useRef<string>();

  const clearDragClasses = (onEnd = false) => {
    document
      .querySelectorAll(
        ".drag-above, .drag-below, .dragging-item, #columnDropAreaAbove"
      )
      .forEach((el) => {
        el.classList.remove("drag-above");
        el.classList.remove("drag-below");
        if (onEnd) {
          el.classList.remove("show");
          el.classList.remove("dragging-item");
        }
      });
  };

  const handleSort = (addToStart = false) => {
    if (dragItem.current && dragOverItem.current !== undefined) {
      let list = [...(filter.trainingReportSettingsDto?.columnIds || [])];

      if (addToStart) {
        list.unshift(dragItem.current);
      } else if (dragOverItem.current === "") {
        list.push(dragItem.current);
      } else {
        const idx = list.indexOf(dragItem.current);
        const idxOver = list.indexOf(dragOverItem.current);

        if (idx > -1 && idx === idxOver) return; //do nothing...
        if (idx > -1) {
          list.splice(idx, 1);
        }

        if (idx > idxOver) {
          const newIdx = list.indexOf(dragOverItem.current);
          list.splice(newIdx, 0, dragItem.current);
        } else {
          const newIdx = list.indexOf(dragOverItem.current);
          list.splice(newIdx + 1, 0, dragItem.current);
        }
      }

      setFilter((prev) => {
        const agGridData = JSON.parse(
          prev.trainingReportSettingsDto.agGridSettings || "{}"
        ) as IAgGridState;

        agGridData.columnState = agGridData.columnState || [];
        const newList: ColumnState[] = [];
        list.forEach((colName) => {
          const col = agGridData.columnState.find((c) => c.colId === colName);
          newList.push({
            ...(col || {}),
            colId: colName,
          });
        });
        agGridData.columnState = newList;

        return {
          ...prev,
          trainingReportSettingsDto: {
            ...prev.trainingReportSettingsDto,
            columnIds: list,
            agGridSettings: JSON.stringify(agGridData),
          },
        };
      });
    }
    dragItem.current = undefined;
    dragOverItem.current = undefined;
  };

  useEffect(() => {
    if (
      ready &&
      !scheduleLoaded &&
      !!report.data?.id &&
      cols.data?.isScheduleReportAvailable &&
      tab === "schedule"
    ) {
      setScheduleLoaded(true);
      setActiveTab("schedule");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, scheduleLoaded, report.data, cols.data]);

  return (
    <>
      <ConfirmDialog
        show={deleteAll}
        buttons="yesno"
        title="Confirmation"
        message="Do you really want to delete all users selected?"
        done={(rtn) => {
          if (rtn === "yes") {
            setEmployees([]);
          }
          setDeleteAll(false);
        }}
      />
      {showName && (
        <EnterReportNameDialog
          data={filter.reportName}
          onClose={() => {
            setShowName(false);
          }}
          onChange={(name) => {
            setShowName(false);
            if (!id) {
              doSave();
            } else {
              setFilter((prev) => {
                return {
                  ...prev,
                  reportName: name,
                };
              });
            }
          }}
        ></EnterReportNameDialog>
      )}
      <div
        className="flex flex-1 flex-column"
        style={{ marginBottom: "-60px" }}
      >
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls pb-0">
              <div>
                <span className="h4 mb-0 font-size-18">
                  {!id && (
                    <span className="me-4 text-uppercase">
                      Build Your Training Time Report
                    </span>
                  )}
                  {!!id && filter.reportName && (
                    <h4
                      className="m-0 py-2 pe-2 pointer"
                      onClick={(e) => {
                        setShowName(true);
                      }}
                    >
                      <span className="me-2">{filter.reportName}</span>
                      <i className="fa fa-edit text-primary"></i>
                    </h4>
                  )}
                </span>
              </div>
              <div>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={(e) => {
                    props.history.push(`/reports`);
                  }}
                >
                  Cancel
                </button>
                {report.data && report.data?.id > 0 && (
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={(e) => {
                      save(true);
                    }}
                    disabled={!!saving}
                  >
                    <span className="px-2">
                      {saving === 1 ? "Saving..." : <>Save</>}
                    </span>
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => {
                    save();
                  }}
                  disabled={!!saving}
                >
                  <span className="px-2">
                    {saving === 2 ? (
                      "Saving..."
                    ) : (
                      <>{id ? "Save and Preview" : "Create Report"}</>
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card">
                {!ready && (
                  <CommonSpinner
                    overlay={true}
                    message="Loading..."
                  ></CommonSpinner>
                )}
                <div className="card-body flex flex-col p-0 pt-2">
                  <CustomTabs
                    onMenuTabClick={(tab) => {
                      setActiveTab(tab as any);
                    }}
                    activeTab={activeTab}
                  >
                    <Tabs
                      id="notifications-list-page"
                      activeKey={activeTab}
                      onSelect={(tab) => {
                        setActiveTab(tab as any);
                        if (!scheduleLoaded && tab === "schedule") {
                          setScheduleLoaded(true);
                        }
                      }}
                    >
                      <Tab eventKey="filters" title="Filters">
                        <div className="p-4">
                          <div>
                            <i>
                              Refine the data for your report by using any of
                              the fields below. If you prefer not to filter your
                              data, you can proceed directly to the Layout
                              section. If a field is left blank, the report will
                              include all available data for that filter type.
                            </i>
                          </div>
                          <div>
                            {ready && (
                              <>
                                <div
                                  className="row pt-4"
                                  key={filter.reportName || "-"}
                                >
                                  <div className="col-sm-4 pb-4">
                                    <div className="flex flex-row">
                                      <div className="flex-1">
                                        <strong>From</strong>
                                      </div>
                                      <section className="px-2"></section>
                                      <div className="flex-1">
                                        <strong>To</strong>
                                      </div>
                                    </div>
                                    <div className="mt-2 flex report-filter-daterange">
                                      <DateTimePickerV2
                                        dateOnly={true}
                                        onChange={(data) => {
                                          setFilter((p) => {
                                            return {
                                              ...p,
                                              trainingReportSettingsDto: {
                                                ...p.trainingReportSettingsDto,
                                                fromDate: data,
                                              },
                                            };
                                          });
                                        }}
                                        data={
                                          filter.trainingReportSettingsDto
                                            .fromDate
                                        }
                                      />
                                      <section className="px-2"> </section>
                                      <DateTimePickerV2
                                        dateOnly={true}
                                        onChange={(data) => {
                                          setFilter((p) => {
                                            return {
                                              ...p,
                                              trainingReportSettingsDto: {
                                                ...p.trainingReportSettingsDto,
                                                toDate: data,
                                              },
                                            };
                                          });
                                        }}
                                        data={
                                          filter.trainingReportSettingsDto
                                            .toDate
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-4 pb-4">
                                    <div className="pb-2">
                                      <strong>Training Types</strong>
                                    </div>
                                    <div className="flex flex-row">
                                      <div className="pointer flex flex-row flex-center me-4">
                                        <input
                                          type="checkbox"
                                          id="chkTTDrills"
                                          className="me-2"
                                          checked={
                                            filter.trainingReportSettingsDto
                                              ?.typeDrills || false
                                          }
                                          onChange={(e) => {
                                            const val =
                                              e.target.checked || false;
                                            setFilter((p) => {
                                              return {
                                                ...p,
                                                trainingReportSettingsDto: {
                                                  ...p.trainingReportSettingsDto,
                                                  typeDrills: val,
                                                },
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          htmlFor="chkTTDrills"
                                          className="mb-0"
                                        >
                                          Drills
                                        </label>
                                      </div>
                                      <div className="pointer flex flex-row flex-center me-4">
                                        <input
                                          type="checkbox"
                                          id="chkTTCourses"
                                          className="me-2"
                                          checked={
                                            filter.trainingReportSettingsDto
                                              ?.typeCourses || false
                                          }
                                          onChange={(e) => {
                                            const val =
                                              e.target.checked || false;
                                            setFilter((p) => {
                                              return {
                                                ...p,
                                                trainingReportSettingsDto: {
                                                  ...p.trainingReportSettingsDto,
                                                  typeCourses: val,
                                                },
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          htmlFor="chkTTCourses"
                                          className="mb-0"
                                        >
                                          Courses
                                        </label>
                                      </div>
                                      <div className="pointer flex flex-row flex-center me-4">
                                        <input
                                          type="checkbox"
                                          id="chkTTOnlineTraining"
                                          className="me-2"
                                          checked={
                                            filter.trainingReportSettingsDto
                                              ?.typeOnlineTraining || false
                                          }
                                          onChange={(e) => {
                                            const val =
                                              e.target.checked || false;
                                            setFilter((p) => {
                                              return {
                                                ...p,
                                                trainingReportSettingsDto: {
                                                  ...p.trainingReportSettingsDto,
                                                  typeOnlineTraining: val,
                                                },
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          htmlFor="chkTTOnlineTraining"
                                          className="mb-0"
                                        >
                                          Online Training
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="pb-3 row">
                                  <div className="col-sm-4">
                                    <div className="pb-2">
                                      <strong>Training Types</strong>
                                    </div>
                                    <div className="flex flex-row">
                                      <div className="pointer flex flex-row flex-center me-4">
                                        <input
                                          type="checkbox"
                                          id="chkTTCourses"
                                          className="me-2"
                                          checked={
                                            filter.trainingReportSettingsDto
                                              ?.typeCourses || false
                                          }
                                          onChange={(e) => {
                                            const val =
                                              e.target.checked || false;
                                            setFilter((p) => {
                                              return {
                                                ...p,
                                                trainingReportSettingsDto: {
                                                  ...p.trainingReportSettingsDto,
                                                  typeCourses: val,
                                                },
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          htmlFor="chkTTCourses"
                                          className="mb-0"
                                        >
                                          Courses
                                        </label>
                                      </div>
                                      <div className="pointer flex flex-row flex-center me-4">
                                        <input
                                          type="checkbox"
                                          id="chkTTDrills"
                                          className="me-2"
                                          checked={
                                            filter.trainingReportSettingsDto
                                              ?.typeDrills || false
                                          }
                                          onChange={(e) => {
                                            const val =
                                              e.target.checked || false;
                                            setFilter((p) => {
                                              return {
                                                ...p,
                                                trainingReportSettingsDto: {
                                                  ...p.trainingReportSettingsDto,
                                                  typeDrills: val,
                                                },
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          htmlFor="chkTTDrills"
                                          className="mb-0"
                                        >
                                          Drills
                                        </label>
                                      </div>
                                      <div className="pointer flex flex-row flex-center me-4">
                                        <input
                                          type="checkbox"
                                          id="chkTTOnlineTraining"
                                          className="me-2"
                                          checked={
                                            filter.trainingReportSettingsDto
                                              ?.typeOnlineTraining || false
                                          }
                                          onChange={(e) => {
                                            const val =
                                              e.target.checked || false;
                                            setFilter((p) => {
                                              return {
                                                ...p,
                                                trainingReportSettingsDto: {
                                                  ...p.trainingReportSettingsDto,
                                                  typeOnlineTraining: val,
                                                },
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          htmlFor="chkTTOnlineTraining"
                                          className="mb-0"
                                        >
                                          Online Training
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="pt-0 row">
                                  <div className="col-sm-4">
                                    <div
                                      className={`pb-2 ${
                                        filter.trainingReportSettingsDto
                                          ?.typeDrills
                                          ? ""
                                          : "display-none"
                                      }`}
                                      id="typeaheadSearchDrillContainer"
                                    >
                                      <div className="pb-1">
                                        <strong>Drills</strong>
                                      </div>
                                      <AsyncTypeahead
                                        id="basic-typeahead-drill"
                                        labelKey="name"
                                        onSearch={(e) => {
                                          setOpenDrillDropdown(true);
                                        }}
                                        open={openDrillDropdown}
                                        onFocus={(e) =>
                                          setOpenDrillDropdown(true)
                                        }
                                        onBlur={(e) =>
                                          setOpenDrillDropdown(false)
                                        }
                                        onChange={(data) => {
                                          if (data && data.length > 0) {
                                            (refDrill.current as any)?.clear();
                                            const drill = data[0];
                                            if (drill) {
                                              if (
                                                items.find(
                                                  (i) =>
                                                    i.type === "Drill" &&
                                                    i.id === drill.id
                                                )
                                              ) {
                                                toastStore.showToast(
                                                  `Selected Drill '${drill.name}' already exists.`,
                                                  "warning"
                                                );
                                                return;
                                              }
                                              setItems([
                                                ...items,
                                                {
                                                  type: "Drill",
                                                  id: drill.id,
                                                  label: `${drill.name}`,
                                                },
                                              ]);
                                              setOpenDrillDropdown(false);
                                              document.body.blur();
                                            }
                                          }
                                        }}
                                        searchText={"Searching..."}
                                        isLoading={false}
                                        options={(
                                          drillTemplate.data?.drills || []
                                        ).sort(commonService.sortByName)}
                                        placeholder="Search to filter drills"
                                        promptText={
                                          drillTemplate.status ===
                                          NfirsFetchStatus.InProgress
                                            ? "Loading..."
                                            : "No Records Found"
                                        }
                                        minLength={1}
                                        delay={500}
                                        useCache={false}
                                        ref={refDrill}
                                      />
                                    </div>

                                    <div
                                      className={`pb-2 ${
                                        filter.trainingReportSettingsDto
                                          ?.typeCourses
                                          ? ""
                                          : "display-none"
                                      }`}
                                    >
                                      <div className="pb-1">
                                        <strong>Courses</strong>
                                      </div>
                                      <CourseRecordTypeahead
                                        forAssignmentPage={true}
                                        placeholder="Search to filter courses"
                                        libType={
                                          ApsModels.CredLibraryTypeEnum.All
                                        }
                                        onChange={(data, ref) => {
                                          if (data && data.length > 0) {
                                            ref.current?.clear();
                                            const course = data[0]
                                              .model as ApsModels.ICourseTypeAheadOutputDto;
                                            if (course) {
                                              if (
                                                items.find(
                                                  (i) =>
                                                    i.type === "Course" &&
                                                    i.id === course.id
                                                )
                                              ) {
                                                toastStore.showToast(
                                                  `Selected Course '${course.name}' already exists.`,
                                                  "warning"
                                                );
                                                return;
                                              }
                                              setItems([
                                                ...items,
                                                {
                                                  type: "Course",
                                                  id: course.id,
                                                  label: `${course.number} - ${course.name}`,
                                                },
                                              ]);
                                            }
                                          }
                                        }}
                                      ></CourseRecordTypeahead>
                                    </div>

                                    <div
                                      className={`pb-2 ${
                                        filter.trainingReportSettingsDto
                                          ?.typeOnlineTraining
                                          ? ""
                                          : "display-none"
                                      }`}
                                      id="typeaheadSearchOnlineTrainingContainer"
                                    >
                                      <div className="pb-1">
                                        <strong>Online Training</strong>
                                      </div>
                                      <AsyncTypeahead
                                        id="typeaheadSearchOnlineTraining"
                                        labelKey={(option) => option.name}
                                        onSearch={
                                          handleSearchOnlineTraingCourses
                                        }
                                        onChange={(data) => {
                                          if (data && data.length > 0) {
                                            (
                                              refOnlineTrainingCourses.current as any
                                            )?.clear();
                                            const otCourse = data[0];
                                            if (otCourse) {
                                              if (
                                                items.find(
                                                  (i) =>
                                                    i.type ===
                                                      "OnlineTrainingCourse" &&
                                                    i.id === otCourse.id
                                                )
                                              ) {
                                                toastStore.showToast(
                                                  `Selected Online Training Course '${otCourse.name}' already exists.`,
                                                  "warning"
                                                );
                                                return;
                                              }
                                              setItems([
                                                ...items,
                                                {
                                                  type: "OnlineTrainingCourse",
                                                  id: otCourse.id,
                                                  label: `${otCourse.code} - ${otCourse.name}`,
                                                },
                                              ]);
                                            }
                                          }
                                        }}
                                        searchText={"Searching..."}
                                        isLoading={
                                          isLoadingOnlineTrainingCourses
                                        }
                                        options={onlineTrainingCourses}
                                        placeholder="Search to filter online trainings"
                                        minLength={1}
                                        delay={500}
                                        useCache={false}
                                        ref={refOnlineTrainingCourses}
                                      />
                                    </div>

                                    {items.length > 0 && (
                                      <div className="currentParticipants mt-2 mb-2">
                                        {items?.map((x) => (
                                          <div key={x.id}>
                                            <section className="pe-2">
                                              {x.type === "OnlineTrainingCourse"
                                                ? "Online Training"
                                                : x.type}{" "}
                                              | {x.label}
                                            </section>
                                            <section
                                              title="Remove"
                                              onClick={() => {
                                                setItems((p) => {
                                                  return [
                                                    ...p.filter(
                                                      (item) =>
                                                        !(
                                                          item.type ===
                                                            x.type &&
                                                          item.id === x.id
                                                        )
                                                    ),
                                                  ];
                                                });
                                              }}
                                            >
                                              <i className="fa fa-times"></i>
                                            </section>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="pt-2 pb-4 row">
                                  <div className="col-sm-4">
                                    <div className="pb-2">
                                      <strong>Employees</strong>
                                    </div>
                                    <FgSelect
                                      id="_rank"
                                      label=""
                                      noPlaceholder={true}
                                      groups={[
                                        { id: 0, label: "By Users" },
                                        { id: -1, label: "By Group" },
                                      ]}
                                      options={[
                                        {
                                          label: "Users",
                                          value: 0,
                                          groupId: 0,
                                        },
                                        ...(groups.data || []).map((cat) => {
                                          return {
                                            label: cat.name,
                                            value: cat.groupId,
                                            groupId: -1,
                                          };
                                        }),
                                      ]}
                                      disabledOptionValues={[-1]}
                                      onChange={(data) => {
                                        setSelectedGroup(
                                          commonService.getNumber(data)
                                        );
                                      }}
                                    />

                                    <div className="text-primary mt-1 px-1 flex">
                                      <div className="flex-1">
                                        <span
                                          className="pointer"
                                          onClick={(e) => {
                                            const emps = users
                                              .filter(
                                                (emp) =>
                                                  !employees.find(
                                                    (i) => i.id === emp.id
                                                  )
                                              )
                                              .map((i) => {
                                                return {
                                                  id: i.id,
                                                  name: i.name,
                                                };
                                              });
                                            setEmployees((list) => {
                                              return [...list, ...emps];
                                            });
                                            if (emps.length > 0) {
                                              toastStore.showToast(
                                                emps.length === 1
                                                  ? "1 User Added"
                                                  : `${emps.length} Users Added`,
                                                "success"
                                              );
                                            } else {
                                              toastStore.showToast(
                                                "No User(s) Added",
                                                "warning"
                                              );
                                            }
                                          }}
                                        >
                                          {selectedGroup
                                            ? `Add All ${
                                                (groups?.data || []).find(
                                                  (x) =>
                                                    x.groupId === selectedGroup
                                                )?.name || ""
                                              }`
                                            : "Add All Users"}
                                        </span>
                                      </div>
                                      <div>
                                        {employees.length > 0 && (
                                          <span
                                            className="text-danger pointer"
                                            onClick={(e) => {
                                              setDeleteAll(true);
                                            }}
                                          >
                                            Delete All
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <AsyncTypeahead
                                        id="basic-typeahead-single"
                                        labelKey="name"
                                        onSearch={(e) => {}} //handleSearch
                                        open={openEmpDropdown}
                                        onFocus={(e) =>
                                          setOpenEmpDropdown(true)
                                        }
                                        onBlur={(e) =>
                                          setOpenEmpDropdown(false)
                                        }
                                        onChange={(data) => {
                                          if (data && data.length > 0) {
                                            const prts = [...employees];
                                            if (
                                              prts.filter(
                                                (p) => p.id === data[0].id
                                              ).length === 0
                                            ) {
                                              prts.push({
                                                id: data[0].id,
                                                name: data[0].name,
                                              });
                                              setEmployees(prts);
                                            } else {
                                              toastStore.showToast(
                                                `${data[0].name} is already in the list.`,
                                                "warning"
                                              );
                                            }

                                            (ref.current as any)?.clear();
                                            ref.current?.blur();
                                          }
                                        }}
                                        searchText={"Searching..."}
                                        isLoading={isLoading}
                                        options={isLoading ? [] : users}
                                        placeholder="Add or Search Users"
                                        promptText={
                                          isLoading
                                            ? "Loading..."
                                            : "No Records Found"
                                        }
                                        minLength={1}
                                        delay={500}
                                        useCache={false}
                                        ref={ref}
                                      />
                                    </div>
                                    <div className="mt-3"></div>

                                    <div className="currentParticipants mb-3">
                                      {employees
                                        ?.sort(
                                          commonService.sortByStringProperty(
                                            "name"
                                          )
                                        )
                                        ?.map((p, i) => {
                                          return (
                                            <div key={i}>
                                              <section>{`${p.name}`}</section>
                                              <section
                                                title="Remove"
                                                onClick={() => {
                                                  const prts = [...employees];
                                                  prts.splice(i, 1);
                                                  setEmployees(prts);
                                                }}
                                              >
                                                <i className="fa fa-times"></i>
                                              </section>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="layout" title="Layout">
                        <div className="p-4">
                          <i>
                            Drag the columns from left to right to add them to
                            your report. You can drag them up or down to reorder
                            them.
                          </i>
                          <div className="row pt-4">
                            <div className="col-md-8">
                              <div className="alert alert-sm alert-secondary mb-0">
                                <div className="row pb-4">
                                  <div className="col-sm-12 col-md-6">
                                    <strong>AVAILABLE DATA</strong>
                                    <input
                                      type="text"
                                      placeholder="Search"
                                      className="form-control mt-2"
                                      value={colSearch}
                                      onChange={(e) => {
                                        setColSearch(e.target.value || "");
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ position: "relative" }}
                                  draggable={!!dragItem.current}
                                  onDragOver={(e) => {
                                    e.preventDefault();
                                    const el =
                                      e.currentTarget as HTMLDivElement;
                                    if (
                                      dragItem.current &&
                                      !!filter.trainingReportSettingsDto?.columnIds?.includes(
                                        dragItem.current
                                      )
                                    ) {
                                      el.classList.add("drag-remove");
                                    }
                                  }}
                                  onDragLeave={(e) => {
                                    const el =
                                      e.currentTarget as HTMLDivElement;
                                    el.classList.remove("drag-remove");
                                  }}
                                  onDrop={(e) => {
                                    const el =
                                      e.currentTarget as HTMLDivElement;
                                    el.classList.remove("drag-remove");
                                    const val = dragItem.current;
                                    setFilter((prev) => {
                                      return {
                                        ...prev,
                                        trainingReportSettingsDto: {
                                          ...prev.trainingReportSettingsDto,
                                          columnIds:
                                            prev.trainingReportSettingsDto?.columnIds?.filter(
                                              (c) => c !== val
                                            ) || [],
                                        },
                                      };
                                    });
                                    dragItem.current = undefined;
                                  }}
                                >
                                  <div className="drag-column-remove">
                                    <div>Remove column from your report</div>
                                  </div>
                                  <div className="col-sm-12 col-md-6">
                                    <div className="mb-2">
                                      <span>Common</span>
                                    </div>
                                    <div>
                                      {!cols.data?.trainingReportGroupDtos
                                        ?.find(
                                          (col) =>
                                            col.groupName ===
                                            ApsModels
                                              .TrainingReportGroupNameEnum
                                              .General
                                        )
                                        ?.trainingReportColumnsDetailsDtos?.find(
                                          filterCol
                                        ) && (
                                        <div>
                                          <i>No Matching Columns</i>
                                        </div>
                                      )}
                                      {cols.data?.trainingReportGroupDtos
                                        ?.find(
                                          (col) =>
                                            col.groupName ===
                                            ApsModels
                                              .TrainingReportGroupNameEnum
                                              .General
                                        )
                                        ?.trainingReportColumnsDetailsDtos?.filter(
                                          filterCol
                                        )
                                        ?.map((col) => (
                                          <div
                                            key={col.id}
                                            className="col-drag-item alert alert-sm alert-light mb-2 flex flex-row flex-center p-2 px-3"
                                            style={
                                              !filter.trainingReportSettingsDto?.columnIds?.includes(
                                                col.id
                                              )
                                                ? {
                                                    cursor: "move",
                                                  }
                                                : {
                                                    opacity: "0.6",
                                                  }
                                            }
                                            draggable={
                                              !filter.trainingReportSettingsDto?.columnIds?.includes(
                                                col.id
                                              )
                                            }
                                            onDrag={(e) => {
                                              e.preventDefault();
                                              dragItem.current = col.id;
                                              const el =
                                                e.currentTarget as HTMLDivElement;
                                              el.classList.add("dragging-item");
                                              document
                                                .getElementById(
                                                  "columnDropAreaAbove"
                                                )
                                                ?.classList?.add("show");
                                            }}
                                            onDragEnd={(e) => {
                                              dragItem.current = undefined;
                                              clearDragClasses(true);
                                            }}
                                          >
                                            <div className="pe-2">
                                              {filter.trainingReportSettingsDto?.columnIds?.includes(
                                                col.id
                                              ) ? (
                                                <FontAwesomeIcon icon={faBan} />
                                              ) : (
                                                <FontAwesomeIcon
                                                  icon={faArrowsAlt}
                                                />
                                              )}
                                            </div>
                                            <div className="flex-1">
                                              {col.columLabel}
                                            </div>
                                            <div>
                                              {filter.trainingReportSettingsDto?.columnIds?.indexOf(
                                                col.id
                                              ) > -1 ? (
                                                <i
                                                  title="Remove from Report"
                                                  className="fa fa-minus pointer text-danger"
                                                  onClick={(e) => {
                                                    addCols(col);
                                                    clearDragClasses(true);
                                                  }}
                                                ></i>
                                              ) : (
                                                <i
                                                  title="Add to Report"
                                                  className="fa fa-plus pointer text-primary"
                                                  onClick={(e) => {
                                                    addCols(col);
                                                    clearDragClasses(true);
                                                  }}
                                                ></i>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6">
                                    {cols.data?.trainingReportGroupDtos
                                      ?.filter((col) => {
                                        if (
                                          filter.trainingReportSettingsDto
                                            .typeDrills &&
                                          col.groupName ===
                                            ApsModels
                                              .TrainingReportGroupNameEnum
                                              .Drills
                                        ) {
                                          return true;
                                        }
                                        if (
                                          (filter.trainingReportSettingsDto
                                            .typeCourses ||
                                            filter.trainingReportSettingsDto
                                              .typeOnlineTraining) &&
                                          col.groupName ===
                                            ApsModels
                                              .TrainingReportGroupNameEnum
                                              .Courses
                                        ) {
                                          return true;
                                        }
                                        return false;
                                      })
                                      .map((grp) => (
                                        <div
                                          key={grp.groupName}
                                          className="mb-4"
                                        >
                                          <div className="mb-2">
                                            <span>
                                              {grp.groupName ===
                                              ApsModels
                                                .TrainingReportGroupNameEnum
                                                .Courses
                                                ? [
                                                    filter
                                                      .trainingReportSettingsDto
                                                      .typeCourses
                                                      ? "Courses"
                                                      : "",
                                                    filter
                                                      .trainingReportSettingsDto
                                                      .typeOnlineTraining
                                                      ? "Online Training"
                                                      : "",
                                                  ]
                                                    .filter((t) => !!t)
                                                    .join(" / ")
                                                : "Drills"}
                                            </span>
                                          </div>
                                          <div>
                                            {!grp?.trainingReportColumnsDetailsDtos?.find(
                                              filterCol
                                            ) && (
                                              <div>
                                                <i>No Matching Columns</i>
                                              </div>
                                            )}
                                            {grp?.trainingReportColumnsDetailsDtos
                                              ?.filter(filterCol)
                                              ?.map((col) => (
                                                <div
                                                  key={col.id}
                                                  className="col-drag-item alert alert-sm alert-light mb-2 flex flex-row flex-center p-2 px-3"
                                                  style={
                                                    !filter.trainingReportSettingsDto?.columnIds?.includes(
                                                      col.id
                                                    )
                                                      ? {
                                                          cursor: "move",
                                                        }
                                                      : {
                                                          opacity: "0.6",
                                                        }
                                                  }
                                                  draggable={
                                                    !filter.trainingReportSettingsDto?.columnIds?.includes(
                                                      col.id
                                                    )
                                                  }
                                                  onDrag={(e) => {
                                                    e.preventDefault();
                                                    dragItem.current = col.id;
                                                    const el =
                                                      e.currentTarget as HTMLDivElement;
                                                    el.classList.add(
                                                      "dragging-item"
                                                    );
                                                    document
                                                      .getElementById(
                                                        "columnDropAreaAbove"
                                                      )
                                                      ?.classList?.add("show");
                                                  }}
                                                  onDragEnd={(e) => {
                                                    dragItem.current =
                                                      undefined;
                                                    clearDragClasses(true);
                                                  }}
                                                >
                                                  <div className="pe-2">
                                                    {filter.trainingReportSettingsDto?.columnIds?.includes(
                                                      col.id
                                                    ) ? (
                                                      <FontAwesomeIcon
                                                        icon={faBan}
                                                      />
                                                    ) : (
                                                      <FontAwesomeIcon
                                                        icon={faArrowsAlt}
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="flex-1">
                                                    {col.columLabel}
                                                  </div>
                                                  <div>
                                                    {filter.trainingReportSettingsDto?.columnIds?.indexOf(
                                                      col.id
                                                    ) > -1 ? (
                                                      <i
                                                        title="Remove from Report"
                                                        className="fa fa-minus pointer text-danger"
                                                        onClick={(e) => {
                                                          addCols(col);
                                                          clearDragClasses(
                                                            true
                                                          );
                                                        }}
                                                      ></i>
                                                    ) : (
                                                      <i
                                                        title="Add to Report"
                                                        className="fa fa-plus pointer text-primary"
                                                        onClick={(e) => {
                                                          addCols(col);
                                                          clearDragClasses(
                                                            true
                                                          );
                                                        }}
                                                      ></i>
                                                    )}
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="alert alert-sm alert-primary mb-0 h-100 flex flex-column">
                                <div className="row pb-2 flex flex-column flex-1">
                                  <div className="col-sm-12 flex flex-column flex-1">
                                    <div className="pb-3">
                                      <strong>YOUR REPORT</strong>
                                    </div>
                                    <div>
                                      {!filter.trainingReportSettingsDto
                                        ?.columnIds?.length ? (
                                        <div className="text-center">
                                          <i>
                                            Please pick column(s) for your
                                            report
                                          </i>
                                        </div>
                                      ) : (
                                        <div
                                          id="columnDropAreaAbove"
                                          draggable
                                          onDrop={(e) => {
                                            e.preventDefault();
                                            handleSort(true);
                                            clearDragClasses(true);
                                          }}
                                          onDragOver={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          Drop Here
                                        </div>
                                      )}
                                    </div>

                                    {[
                                      ...(filter.trainingReportSettingsDto
                                        ?.columnIds?.length
                                        ? []
                                        : [""]),
                                      ...(filter.trainingReportSettingsDto
                                        ?.columnIds || []),
                                    ]?.map((col, idx) => (
                                      <div
                                        key={col}
                                        className={`py-1 col-drag-item ${
                                          !!(
                                            col === "" ||
                                            filter.trainingReportSettingsDto
                                              ?.columnIds?.length -
                                              1 ===
                                              idx
                                          )
                                            ? `flex-1 ${
                                                col === ""
                                                  ? " drop-initial"
                                                  : ""
                                              }`
                                            : ""
                                        }`}
                                        style={{
                                          cursor: "move",
                                        }}
                                        draggable
                                        onDragStart={(e) => {
                                          dragItem.current = col;
                                          const el =
                                            e.currentTarget as HTMLDivElement;
                                          el.classList.add("dragging-item");
                                        }}
                                        onDragEnter={(e) => {
                                          dragOverItem.current = col;
                                        }}
                                        onDragOver={(e) => {
                                          e.preventDefault();
                                          if (col === dragItem.current) {
                                            return;
                                          }
                                          clearDragClasses();
                                          const el =
                                            e.currentTarget as HTMLDivElement;

                                          const list =
                                            filter.trainingReportSettingsDto
                                              ?.columnIds || [];

                                          if (dragItem.current) {
                                            if (
                                              col === "" ||
                                              list.indexOf(col) >
                                                list.indexOf(dragItem.current)
                                            ) {
                                              el.classList.add("drag-below");
                                            } else {
                                              el.classList.add("drag-above");
                                            }
                                          }
                                        }}
                                        onDrop={(e) => {
                                          e.preventDefault();
                                          handleSort();
                                          clearDragClasses(true);
                                        }}
                                        onDragLeave={(e) => {
                                          e.preventDefault();
                                          clearDragClasses();
                                        }}
                                        onDragExit={(e) => {
                                          e.preventDefault();
                                          clearDragClasses();
                                        }}
                                      >
                                        {col === "" ? (
                                          <></>
                                        ) : (
                                          <div className="alert alert-sm alert-light flex flex-row flex-center p-2 px-3 mb-0">
                                            <div className="pe-2">
                                              <FontAwesomeIcon
                                                icon={faArrowsAlt}
                                              />
                                            </div>
                                            <div className="flex-1">
                                              {
                                                cols.data?.trainingReportGroupDtos
                                                  ?.map(
                                                    (col) =>
                                                      col.trainingReportColumnsDetailsDtos
                                                  )
                                                  ?.find(
                                                    (grp) =>
                                                      !!grp.find(
                                                        (c) => c.id === col
                                                      )
                                                  )
                                                  ?.find((c) => c.id === col)
                                                  ?.columLabel
                                              }
                                            </div>
                                            <div>
                                              <i
                                                title="Remove"
                                                className="fa fa-times text-danger pointer"
                                                onClick={(e) => {
                                                  setFilter((p) => {
                                                    return {
                                                      ...p,
                                                      trainingReportSettingsDto:
                                                        {
                                                          ...p.trainingReportSettingsDto,
                                                          columnIds:
                                                            p.trainingReportSettingsDto?.columnIds?.filter(
                                                              (c) => c !== col
                                                            ),
                                                        },
                                                    };
                                                  });
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      {ready &&
                        !!report.data?.id &&
                        cols.data?.isScheduleReportAvailable && (
                          <Tab eventKey="schedule" title="Schedule">
                            {scheduleLoaded && (
                              <ReportScheduleTab
                                model={filter.trainingReportScheduleDto || {}}
                                onChange={(model) => {
                                  setFilter((prev) => {
                                    return {
                                      ...prev,
                                      trainingReportScheduleDto: model,
                                    };
                                  });
                                }}
                                onError={(err) => {
                                  setScheduleError(err);
                                }}
                              ></ReportScheduleTab>
                            )}
                          </Tab>
                        )}
                    </Tabs>
                  </CustomTabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuildReports;
