import React, { useEffect, useMemo, useState } from "react";
import CommonSpinner from "../../aps2/components/Common/CommonSpinner";
import ConfirmDialog from "../../aps2/components/Common/ConfirmDialog";
import ApsServices from "../../aps2/services";
import commonService from "../../aps2/services/CommonService";
import designerStore from "../../aps2/stores/DesignerStore";
import toastStore from "../../aps2/stores/ToastStore";
import {
  FormDto,
  QuestionDto,
  QuestionGroupDto,
} from "../../data/DailyOperational";
import {
  BuiltInDOCComponents,
  IBaseDOCComponent,
} from "./ComponentGalleryDefinitions";
import CoreLayout from "./CoreLayout";
import DefaultLayoutInitializer, {
  DefaultLayoutColumnIds,
} from "./DefaultLayoutInitializer";
export interface IDOCLayoutColumn {
  id: string;
  span?: number;
  components: IDOCComponent[];
}

export interface IDOCLayoutRow {
  id: string;
  columns: IDOCLayoutColumn[];
}

export interface IDOCLayoutModel {
  departmentId?: any;
  departmentName?: string;
  rows: IDOCLayoutRow[];
}

export class DOCLayoutColumn implements IDOCLayoutColumn {
  id: string;
  components: IDOCComponent[];
  span?: number;
  constructor(components?: IDOCComponent[], span?: number, id?: string) {
    this.id = id || commonService.getUniqueId();
    this.components = components || [];
    this.span = span;
  }
}

export class DOCLayoutRow implements IDOCLayoutRow {
  id: string;
  columns: IDOCLayoutColumn[];
  constructor(cols: IDOCLayoutColumn[]) {
    this.id = commonService.getUniqueId();
    this.columns = [...cols];
  }
}

export interface IDOCComponent {
  id: string;
  type: "core-layout" | "component";
  data: any;
}

class LayoutHelper {
  checkIfRowHasComponents = (
    grid: IDOCLayoutModel,
    row?: IDOCLayoutRow,
    col?: IDOCLayoutColumn,
    subGrid?: IDOCLayoutColumn
  ): boolean => {
    const self = this;
    return (
      grid.rows.filter((r) => {
        if (row && r.id !== row.id) {
          return false;
        }
        if (
          r.columns.filter(
            (c) =>
              (!col || col.id === c.id) &&
              c.components.filter((cpt) => cpt.type !== "core-layout").length >
                0
          ).length > 0
        ) {
          return true;
        } else {
          return (
            r.columns.filter((c) => {
              if (col && col.id !== c.id) {
                return false;
              }
              return (
                c.components.filter((cpt) => {
                  if (subGrid && subGrid.id !== cpt.id) {
                    return false;
                  }
                  return (
                    cpt.type === "core-layout" &&
                    cpt.data &&
                    (cpt.data as IDOCLayoutModel).rows.filter((r) =>
                      self.checkIfRowHasComponents(cpt.data, r)
                    ).length > 0
                  );
                }).length > 0
              );
            }).length > 0
          );
        }
        return false;
      }).length > 0
    );
  };
}

export const layoutHelper = new LayoutHelper();

function DailyOperationalDesigner(props: { departmentId: number }) {
  const [form, setForm] = useState<FormDto>();
  const [model, setModel] = useState<IDOCLayoutModel>({
    rows: [],
  });
  const [saving, setSaving] = useState(false);
  const [hasPending, setHasPending] = useState(false);
  const saveTemplate = async () => {
    if (saving) {
      setHasPending(true);
      return;
    }
    setSaving(true);
    await ApsServices.http.dailyConsoleTemplate
      .saveTemplate({
        departmentId: props.departmentId,
        layoutModel: JSON.stringify(model),
      })
      .then(() => new Promise((resolve) => setTimeout(resolve, 200)))
      .then((data) => {
        toastStore.showToast("Template Saved", "success");
      })
      .catch((error) => {
        toastStore.showError("Failed Saving Template", error);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    //ensures that no multiple calls are made.
    if (!saving && hasPending) {
      setHasPending(false);
      saveTemplate();
    }
  }, [saving, hasPending]);

  const getLastQuestionGroupOrderNum = () => {
    if (form?.questionGroups && form.questionGroups?.length > 0) {
      return Math.max(...form.questionGroups.map((grp) => grp.order));
    }
    return 0;
  };

  const prepareData = (
    component: IBaseDOCComponent,
    questionGroup: QuestionGroupDto,
    questions: QuestionDto[]
  ): IBaseDOCComponent => {
    return {
      ...component,
      questionGroup: {
        ...component.questionGroup,
        ...questionGroup,
        questions: [
          ...(component.questionGroup?.questions || questions || []),
        ].map((q, i) => {
          return {
            ...q,
            ...questions?.find((qq, ii) => ii === i),
            answers: [],
          };
        }),
      },
    };
  };

  const triggerAddComponent = (
    columnId: string,
    data?: {
      component: IBaseDOCComponent;
      questionGroup: QuestionGroupDto;
      questions: QuestionDto[];
    }
  ) => {
    if (data?.component && data?.questionGroup && data?.questions) {
      designerStore.setAddComponent({
        columnId: columnId,
        component: prepareData(
          data.component,
          data.questionGroup,
          data.questions
        ),
      });
    }
  };

  const initialize = () => {
    setModel({
      rows: [new DOCLayoutRow([new DOCLayoutColumn(), new DOCLayoutColumn()])],
    });
  };

  const [confirmInit, setConfirmInit] = useState(false);
  const showInitDefaultLayout = useMemo(() => {
    if (model?.rows?.length) {
      return !layoutHelper.checkIfRowHasComponents(model);
    }
    return false;
  }, [model]);

  const [initMsg, setInitMsg] = useState<string>(); //ensures saving template is called one time
  const initDefaultLayout = async () => {
    if (form) {
      setInitMsg("Initialization Started...");

      const optionGroups = await ApsServices.http.optionGroup
        .getAllOptionGroupsByDepartment(props.departmentId)
        .then((data) => data)
        .catch((error) => {
          toastStore.showToast("Failed Getting Option Groups", error);
        });

      const cls = new DefaultLayoutInitializer(
        form.formId,
        getLastQuestionGroupOrderNum(),
        optionGroups || []
      );

      setModel({ ...cls.getDefaultLayout() });

      setInitMsg("Adding 'Operations BC' field");
      await cls
        .addOperationsBC()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.optionsBC, data)
        );

      setInitMsg("Adding 'Badge Band' field");
      await cls
        .addBadgeBand()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.badgeBnd, data)
        );

      setInitMsg("Adding 'Flags' field");
      await cls
        .addFlags()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.flags, data)
        );

      setInitMsg("Adding 'Shift Activity' field");
      await cls
        .addShiftActivity()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.shiftActivity, data)
        );

      setInitMsg("Adding 'Wildland Status' field");
      await cls
        .addWildlandStatus()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.wildlandStatus, data)
        );

      setInitMsg("Adding 'Strike Teams' field");
      await cls
        .addStrikeTeams()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.strikeTeams, data)
        );

      setInitMsg("Adding 'On-Call List' field");
      await cls
        .addOnCallList()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.onCallList, data)
        );

      setInitMsg("Adding 'Reserve Apparatus Status' field");
      await cls
        .addReserveApparatus()
        ?.then((data) =>
          triggerAddComponent(
            DefaultLayoutColumnIds.reserveApparatusStatus,
            data
          )
        );

      setInitMsg("Adding 'US&R Status' field");
      await cls
        .addUSRStatus()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.usrStatus, data)
        );

      setInitMsg("Adding 'US&R Response' field");
      await cls
        .addUSRResponse()
        ?.then((data) =>
          triggerAddComponent(DefaultLayoutColumnIds.usrResponse, data)
        );

      setInitMsg("Adding 'Additional EMS Response' field");
      await cls
        .addAdditionalEMS()
        ?.then((data) =>
          triggerAddComponent(
            DefaultLayoutColumnIds.additionalEmsResponse,
            data
          )
        );

      setInitMsg("Adding 'Community Risk Reduction' field");
      await cls
        .addCommunityRiskEducation()
        ?.then((data) =>
          triggerAddComponent(
            DefaultLayoutColumnIds.communityRiskReduction,
            data
          )
        );

      setInitMsg("Adding 'Emergency Operations Center (EOC)' field");
      await cls
        .addEOC()
        ?.then((data) =>
          triggerAddComponent(
            DefaultLayoutColumnIds.emergencyOperationsCenter,
            data
          )
        );

      setInitMsg(undefined);
    } else {
      toastStore.showToast(
        "Form not found. Try again after refreshing this page.",
        "warning"
      );
    }
  };

  const [previouslyLoaded, setPreviouslyLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [collapse, setCollapse] = useState(true);
  const [formStatus, setFormStatus] = useState<string>();

  useEffect(() => {
    if (!loading && previouslyLoaded && !collapse && !formStatus && !initMsg) {
      //console.log(">>> AUTO-SAVE");
      saveTemplate();
    }
  }, [model, initMsg]);

  useEffect(() => {
    if (props.departmentId > 0 && !collapse && !previouslyLoaded) {
      setPreviouslyLoaded(true);

      setFormStatus("Getting Form...");
      ApsServices.http.form
        .getForm({
          date: new Date(),
          departmentId: props.departmentId,
        })
        .then((data) => {
          if (data && data.formId) {
            setForm(data);
            setFormStatus(undefined);
          } else {
            toastStore.showToast(
              "No form record yet for this department. Creating record automatically...",
              "info"
            );
            setFormStatus("Creating Form...");
            ApsServices.http.form
              .saveTemplate(props.departmentId)
              .then((data) => {
                setForm(data);
                setFormStatus(undefined);
                toastStore.showToast(
                  "Form record has been created for this department. You can now start designing Daily Operational form.",
                  "success"
                );
              })
              .catch((error) => {
                toastStore.showError("Failed Creating Form Record", error);
              });
          }
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Form", error);
        });

      setLoading(true);
      ApsServices.http.dailyConsoleTemplate
        .getTemplateAdmin(props.departmentId)
        .then((data) => {
          if (!data.layoutModel) {
            initialize();
          } else {
            setModel(JSON.parse(data.layoutModel));
          }
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Template", error);
          initialize();
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.departmentId, collapse]);

  return (
    <>
      {confirmInit && (
        <ConfirmDialog
          show={true}
          title="Confirm Initialization"
          message="This will replace your current layout. Do you really want to continue?"
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              initDefaultLayout();
            }
            setConfirmInit(false);
          }}
        ></ConfirmDialog>
      )}
      <div className="flex flex-1 flex-col">
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span
                  className="pointer"
                  onClick={() => setCollapse(!collapse)}
                >
                  <i
                    className={`fa fa-chevron-${
                      collapse ? "up" : "down"
                    } pl-2 pr-2`}
                  ></i>
                  <h4 className="pt-3 pl-2 m-0 inline-block">
                    Daily Operational{" "}
                    {saving && <small>{"(saving template...)"}</small>}
                    {/* {hasChanges && <span style={{ color: "red" }}>*</span>} */}
                  </h4>
                </span>
              </div>
              <div></div>
            </div>
          </div>

          <div
            className={`doc-designer w-100 pb-4 ${
              collapse ? "display-none" : ""
            }`}
          >
            {initMsg && (
              <div className="design-initialization-progress">
                <CommonSpinner></CommonSpinner>
                <div>{initMsg}</div>
              </div>
            )}

            {/* <div className="flex flex-center mb-4">
            <h4 className="flex-1">DAILY OPERATIONAL DESIGNER</h4>
            <div className={loading ? "display-none" : ""}>
              <Button variant="secondary" type="button" className="mr-2">
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={saving}
                onClick={(e) => {
                  saveTemplate();
                }}
              >
                {saving ? "Saving..." : "Save"}
              </Button>
            </div>
          </div> */}
            {(loading || !!formStatus) && (
              <div>
                <CommonSpinner message={formStatus}></CommonSpinner>
              </div>
            )}
            <div className={loading || !!formStatus ? "display-none" : ""}>
              {showInitDefaultLayout && (
                <button
                  className="mb-3 btn btn-danger"
                  type="button"
                  onClick={(e) => {
                    setConfirmInit(true);
                  }}
                >
                  Initialize Default Layout
                </button>
              )}
              <CoreLayout
                departmentId={props.departmentId}
                model={model}
                onChange={(data) => {
                  setModel(data);
                }}
                onDelete={(data) => {
                  setModel(data);
                }}
                root={true}
                designMode={true}
                /* NEED TO CONFIRM IF THIS IS REALLY NECESSARY SINCE WE HAVE */
                // saveResetButtons={{
                //   saveLabel: "Save",
                //   resetLabel: "Reset",
                //   onSave() {
                //     saveTemplate();
                //   },
                //   onReset() {},
                // }}
              ></CoreLayout>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DailyOperationalDesigner;
