import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSArsonAgencyReferralServices {
  get = async (reportId: any) => {
    const url = `/api/NFIRSArsonAgencyReferral/${reportId}`;
    return fetcher.get<ApsModels.INfirsArsonAgencyReferralOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsArsonAgencyReferralInputDto) => {
    const url = `/api/NFIRSArsonAgencyReferral`;
    return fetcher.post<ApsModels.INfirsArsonAgencyReferralOutputDto>(
      url,
      data
    );
  };
}

const nfirsArsonAgencyReferral = new NFIRSArsonAgencyReferralServices();
export default nfirsArsonAgencyReferral;
