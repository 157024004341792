import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type Props = {
    isOpen: boolean,
    previousValue: string | null,
    submit: (name: string) => void,
    close: () => void
}
type State = {
    name: string | null
}

export class CreateOtherModal extends Component<Props, State>{
    state: State = {
        name: null
    }

    handleOptionNameChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            name: e.target.value
        });
    }

    handleSubmitClicked = async (): Promise<void> => {
        if (!this.state.name) return;

        this.props.submit(this.state.name!);
    }

    componentWillUnmount() {
        this.setState({
            name: null
        })
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} role="dialog" autoFocus={true} centered={true} tabIndex={-1}>
                <div className="modal-content">
                    <ModalHeader>
                        Other
                    </ModalHeader>
                    <ModalBody>
                        <input type="text" className="form-control" defaultValue={this.props.previousValue || ''} onChange={this.handleOptionNameChanged} />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" onClick={this.props.close} color="secondary">Close</Button>
                        <Button type="button" onClick={this.handleSubmitClicked} color="primary">Submit</Button>
                    </ModalFooter>
                </div>
            </Modal>
        )
    }
}
