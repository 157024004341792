import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
//import commonService from "../../../services/CommonService";
import toastStore from "../../../stores/ToastStore";
import {
  FgInput,
  //FgSelect,
  FgUseForm,
  IBasicDialogProps,
} from "../../Common/FormGroups";
import FormModal from "../../Common/FormModal";
import { useNfirsGetData } from "../../NFIRS/NFIRSHelper";

function NewCourseDialog(
  props: IBasicDialogProps<{
    category?: ApsModels.ICredCourseCategoryOutputDto;
  }>
) {
  const [model, setModel] = useState({} as ApsModels.ICredCourseInsertDto);
  const [saving, setSaving] = useState(false);
  const { registry, handleSubmit, formState } = FgUseForm({
    name: {
      displayName: "Course Name",
      validation: { required: true },
    },
    number: {
      displayName: "Course Number",
      validation: { required: true },
    },
    // courseQualificationEnum: {
    //   displayName: "Course Qualifications",
    //   validation: { required: true },
    // },
    // hours: {
    //   displayName: "Hours",
    //   validation: { required: false },
    // },
    // trainingTypeId: {
    //   displayName: "Training Type (ISO Codes)",
    //   validation: { required: false },
    // },
    // courseDescription: {
    //   displayName: "Course Description",
    //   validation: { required: false },
    // },
    // courseObjective: {
    //   displayName: "Course Objectives",
    //   validation: { required: false },
    // },
  });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const submit = async (form: any) => {
    setSaving(true);
    await ApsServices.http.credCourseRecord
      .addNewCourse({
        ...model,
        credCourseCategoryEntryId: props.data?.category?.id || (null as any),
        courseCharacteristicsDto: {
          isEmsCe: false,
          isIsa: false,
          isIso: false,
          isJac: false,
        },
      })
      .then((data) => {
        toastStore.showToast("Course Saved", "success");
        props.onChange && props.onChange(data as any);
        setSaving(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Course", err);
        setSaving(false);
      });
  };

  const trainingTypesList = useNfirsGetData(
    ApsServices.http.credCommon.getTrainingType,
    "Training Types"
  );

  useEffect(() => {
    trainingTypesList.getData();
    setTimeout(() => {
      document.getElementById("courseName")?.focus();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title="New Course"
      isOpen={true}
      close={() => close()}
      submit={handleSubmit(submit)}
      submitButtonLabel={saving ? "Saving..." : "Save"}
      closeButtonLabel="Cancel"
      disableSubmit={saving}
    >
      <div className="row">
        <div className="col-sm-12">
          {props.data?.category && (
            <>
              <label>Category</label>
              <div className="alert alert-sm alert-secondary">
                <div>{props.data?.category.description}</div>
              </div>
            </>
          )}
        </div>
        <div className="col-sm-12">
          <FgInput
            id="courseName"
            label="Course Name"
            placeHolder="Course Name"
            registeredField={registry.name}
            formState={formState}
            onChange={(data) => {
              setModel({
                ...model,
                name: data,
              });
            }}
            showRequiredLabel={true}
          ></FgInput>
          <FgInput
            id="courseNumber"
            label="Course Code"
            placeHolder="Course Code"
            registeredField={registry.number}
            formState={formState}
            onChange={(data) => {
              setModel({
                ...model,
                number: data,
              });
            }}
            showRequiredLabel={true}
          ></FgInput>
          {/* <FgSelect
            id="courseQualificationEnum"
            label="Course Qualifications"
            selectMessage="- Select Course Qualifications -"
            showRequiredLabel={true}
            options={[
              {
                label: "EMS CE",
                value: ApsModels.CourseQualificationEnum.EmsCe,
              },
              {
                label: "ISO",
                value: ApsModels.CourseQualificationEnum.Iso,
              },
              {
                label: "Instructor Qualifications",
                value:
                  ApsModels.CourseQualificationEnum.InstructorQualifications,
              },
            ]}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  courseQualificationEnum: commonService.getNumber(data),
                };
              });
            }}
            registeredField={registry.courseQualificationEnum}
            formState={formState}
          />
          <FgInput
            id="hours"
            label="Course Hours"
            placeHolder="Course Hours"
            registeredField={registry.hours}
            formState={formState}
            autoFocus={true}
            onChange={(data) => {
              setModel((p: any) => {
                return {
                  ...p,
                  hours: commonService.getNumber(data),
                };
              });
            }}
            type="number"
            step="0.01"
          ></FgInput>

          <div
            className={
              model.courseQualificationEnum ===
              ApsModels.CourseQualificationEnum.Iso
                ? ""
                : "display-none"
            }
          >
            <FgSelect
              id="trainingType"
              label="Training Type (ISO Codes)"
              selectMessage="- Select Training Type -"
              options={
                trainingTypesList.data?.map((cat) => {
                  return {
                    label: cat.name,
                    value: cat.id,
                  };
                }) || []
              }
              onChange={(data) => {
                setModel((p) => {
                  return {
                    ...p,
                    trainingTypeId: commonService.getNumber(data),
                  };
                });
              }}
              registeredField={registry.trainingTypeId}
              formState={formState}
            />
          </div> */}
        </div>
        {/* <div className="col-sm-12 col-md-6">
          <FgInput
            id="courseDescription"
            label="Course Description"
            placeHolder="Course Description"
            registeredField={registry.courseDescription}
            formState={formState}
            autoFocus={true}
            onChange={(data) => {
              setModel((p: any) => {
                return {
                  ...p,
                  courseDescription: data,
                };
              });
            }}
            maxLength={64}
            rows={5}
          ></FgInput>
          <FgInput
            id="courseObjective"
            label="Course Objectives"
            placeHolder="Course Objectives"
            registeredField={registry.courseObjective}
            formState={formState}
            autoFocus={true}
            onChange={(data) => {
              setModel((p: any) => {
                return {
                  ...p,
                  courseObjective: data,
                };
              });
            }}
            maxLength={64}
            rows={5}
          ></FgInput>
        </div> */}
      </div>
    </FormModal>
  );
}

export default NewCourseDialog;
