import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import { reaction } from "mobx";

function NFIRSEntryAES(props: INFIRSTabProps<any>) {
  const showOthers = () => {
    return (
      (model?.new?.nfirsStructureFireAesPresenceEntryId || null) === 2 ||
      (model?.new?.nfirsStructureFireAesPresenceEntryId || null) === 3
    );
  };

  const saveAction = () => {
    //do model pre-processing here

    const data = { ...model.new };
    if (showOthers()) {
      if (data.nfirsStructureFireAesOperationEntryId === 4) {
        data.numSprinklers = undefined as any;
        setValue("numSprinklers", undefined);
      } else if (
        data.nfirsStructureFireAesOperationEntryId === 1 ||
        data.nfirsStructureFireAesOperationEntryId === 2
      ) {
        data.nfirsStructureFireAesFailureReasonEntryId = undefined as any;
        setValue("nfirsStructureFireAesFailureReasonEntryId", undefined);
      } else {
        data.nfirsStructureFireAesFailureReasonEntryId = undefined as any;
        data.numSprinklers = undefined as any;
        setValue("numSprinklers", undefined);
        setValue("nfirsStructureFireAesFailureReasonEntryId", undefined);
      }
    } else {
      data.numSprinklers = undefined as any;
      data.nfirsStructureFireAesFailureReasonEntryId = undefined as any;
      data.nfirsStructureFireAesOperationEntryId = undefined as any;
      data.nfirsStructureFireAesTypeEntryId = undefined as any;
      setValue("numSprinklers", undefined);
      setValue("nfirsStructureFireAesFailureReasonEntryId", undefined);
      setValue("nfirsStructureFireAesOperationEntryId", undefined);
      setValue("nfirsStructureFireAesTypeEntryId", undefined);
    }
    return ApsServices.http.nfirsStructureFireAesService.update(data);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsStructureFireAesInputDto>({
    objectName: "AES Information",
    nfirsSectionTab: "Structure Fire/AES",
    promise: saveAction,
    afterSave: (data) => {
      initModels({
        ...model.new,
        concurrencyToken: data.concurrencyToken,
        numSprinklers: data.numSprinklers,
        nfirsStructureFireAesFailureReasonEntryId:
          data.nfirsStructureFireAesFailureReasonEntryId,
        nfirsStructureFireAesOperationEntryId:
          data.nfirsStructureFireAesOperationEntryId,
        nfirsStructureFireAesTypeEntryId: data.nfirsStructureFireAesTypeEntryId,
      });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsStructureFireAesInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Structure Fire", "AES", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Structure Fire", "AES", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const presenceList = useNfirsGetData<
    ApsModels.INfirsStructureFireAesPresenceEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsStructureFireAesPresenceEntries,
    "Presence of AES"
  );
  const types = useNfirsGetData<
    ApsModels.INfirsStructureFireAesTypeEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsStructureFireAesTypeEntries,
    "Type of AES List"
  );
  const operations = useNfirsGetData<
    ApsModels.INfirsStructureFireDetectorOperationEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsStructureFireAesOperationEntries,
    "Operations of AES List"
  );
  const failureReasons = useNfirsGetData<
    ApsModels.INfirsStructureFireAesFailureReasonEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsStructureFireAesFailureReasonEntries,
    "Reason of AES Failures"
  );

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();
  const [requiredFieldEnforced, setRequiredFieldEnforced] = useState(
    nfirsStore.nfirsStructureFireRequiredEnforced
  );

  useEffect(() => {
    setProgress({ loading: true });
    presenceList.getData();
    types.getData();
    operations.getData();
    failureReasons.getData();

    const disposer = reaction(
      () => nfirsStore.nfirsStructureFireRequiredEnforced,
      (n, p, r) => {
        setRequiredFieldEnforced(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsStructureFireAesService
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting AES info", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      presenceList.status === NfirsFetchStatus.Complete &&
      types.status === NfirsFetchStatus.Complete &&
      operations.status === NfirsFetchStatus.Complete &&
      failureReasons.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    presenceList.status,
    types.status,
    operations.status,
    failureReasons.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    nfirsStructureFireAesPresenceEntryId: {
      displayName: "Presence of AES",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (requiredFieldEnforced && cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsStructureFireAesTypeEntryId: {
      displayName: "Type of AES",
      validation: {
        required: false,
      },
    },
    nfirsStructureFireAesOperationEntryId: {
      displayName: "Operation of AES",
      validation: {
        required: false,
      },
    },
    numSprinklers: {
      displayName: "Number of Sprinkler Heads Operating",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsStructureFireAesFailureReasonEntryId: {
      displayName: "Reason of AES Failure",
      validation: {
        required: false,
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 ">
          <strong>AES</strong>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div>
            <FgSelect
              id="nfirsStructureFireAesPresenceEntryId"
              label="Presence of AES"
              selectMessage="Select Presence of AES"
              registeredField={registry.nfirsStructureFireAesPresenceEntryId}
              formState={formState}
              showRequiredLabel={
                isOtherModuleDataRequired && requiredFieldEnforced
              }
              options={(presenceList.data || []).map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsStructureFireAesPresenceEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>

            <div className={`${showOthers() ? "" : "display-none"} mt-3`}>
              <FgSelect
                id="nfirsStructureFireAesTypeEntryId"
                label="Type of AES"
                selectMessage="Select Type"
                registeredField={registry.nfirsStructureFireAesTypeEntryId}
                formState={formState}
                options={(types.data || []).map((o) => {
                  return {
                    label: `${o.externalId}. ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsStructureFireAesTypeEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>

          <div className="mt-3"></div>
        </div>
        <div
          className={`${showOthers() ? "" : "display-none"} col-sm-12 col-lg-6`}
        >
          <div>
            <div className="mt-3 mb-3">
              <FgSelect
                id="nfirsStructureFireAesOperationEntryId"
                label="Operation of AES"
                selectMessage="Select Operation of AES"
                registeredField={registry.nfirsStructureFireAesOperationEntryId}
                formState={formState}
                options={(operations.data || []).map((o, i) => {
                  return {
                    label: `${o.externalId}. ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsStructureFireAesOperationEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>

            <div
              className={`${
                [1, 2].indexOf(
                  model?.new?.nfirsStructureFireAesOperationEntryId ||
                    (null as any)
                ) === -1
                  ? "display-none"
                  : ""
              }`}
            >
              <FgInput
                id="numSprinklers"
                label="Number of Sprinkler Heads Operating"
                placeHolder="Number of Sprinkler Heads Operating"
                registeredField={registry.numSprinklers}
                formState={formState}
                showRequiredLabel={isOtherModuleDataRequired}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    numSprinklers: data === "" ? (null as any) : Number(data),
                  });
                }}
                type="number"
              ></FgInput>
            </div>

            <div
              className={`mt-3 ${
                (model?.new?.nfirsStructureFireAesOperationEntryId || null) !==
                4
                  ? "display-none"
                  : ""
              }`}
            >
              <FgSelect
                id="nfirsStructureFireAesFailureReasonEntryId"
                label="Reason of AES Failure"
                selectMessage="Select Reason"
                registeredField={
                  registry.nfirsStructureFireAesFailureReasonEntryId
                }
                formState={formState}
                showRequiredLabel={isOtherModuleDataRequired}
                options={(failureReasons.data || []).map((o, i) => {
                  return {
                    label: `${o.externalId}. ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsStructureFireAesFailureReasonEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSEntryAES;
