import ApsModels from "../../models";
import fetcher from "./Fetcher";

class ShiftService {
  save = async (data: ApsModels.ILogDrillShiftInputDto) => {
    const url = "/api/LogDrillShift/Save";
    return fetcher.post<ApsModels.ILogDrillShiftOutputDto>(url, data);
  };

  getAll = async (deptId: number) => {
    const url = `/api/LogDrillShift/GetAll/${deptId}`;
    return fetcher.get<ApsModels.ILogDrillShiftOutputDto[]>(url);
  };
}

const shift = new ShiftService();
export default shift;
