import ApsModels from "../../models";
import fetcher from "./Fetcher";

class ISOCommonService {
  typeAheadUser = async (data: ApsModels.IIsoTrainingUserTypeAheadInputDto) => {
    const url = `/api/IsoCommon/IsoTrainingTypeAheadUser`;
    return fetcher.post<ApsModels.IIsoTrainingParticipantOutputDto[]>(
      url,
      data
    );
  };

  genericLookup = async (enumName: string) => {
    const url = `/api/IsoCommon/IsoTrainingGenericEnumLookUp/${enumName}`;
    return fetcher.get<ApsModels.ILookupIntDto[]>(url);
  };
}

const isoCommon = new ISOCommonService();
export default isoCommon;
