import React, { useEffect, useState } from "react";
import CommonSpinner from "../../Common/CommonSpinner";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRS/NFIRSHelper";
import ApsServices from "../../../services";
import { useDepartmentId } from "../../../stores/SystemStore";
import ApsModels from "../../../models";
import commonService from "../../../services/CommonService";
import FileSaver from "file-saver";
import toastStore from "../../../stores/ToastStore";

function ViewCourseTemplate(props: any) {
  const [id, setId] = useState(props.match?.params?.id);
  const departmentId = useDepartmentId();

  const navigateBack = () => {
    if (props.history.action !== "POP") {
      props.history.goBack();
    } else {
      props.history.push("/templates");
    }
  };

  const template = useNfirsGetData(
    async () => ApsServices.http.credTemplate.getCourseTemplate(id),
    "Course Template"
  );

  useEffect(() => {
    if (departmentId.current > 0) {
      if (id) {
        if (departmentId.changed && departmentId.previous > 0) {
          props.history.push("/templates");
          return; //exit
        }
        template.getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  return (
    <>
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="h4 mb-0 font-size-18 text-uppercase">
                View Course Template
              </span>
            </div>
            <div>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  navigateBack();
                }}
              >
                Cancel and Close
              </button>

              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  id && props.history.push(`/templates/course/${id}`);
                }}
              >
                <span className="px-2">Edit</span>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`row justify-content-md-center h-full flex-1 ${
            template.status === NfirsFetchStatus.Complete ? "" : "display-none"
          }`}
        >
          {template.status === NfirsFetchStatus.InProgress && (
            <CommonSpinner overlay={true}></CommonSpinner>
          )}
          <div className="view-course-template col-12 col-sm-12 col-lg-8 flex flex-col">
            <div className="card">
              <div className="card-body flex flex-col p-0">
                <div className="generic-view-page-header p-4 flex">
                  <div className="flex-1">
                    <h4 className="m-0">{template.data?.name}</h4>
                    <div>{template.data?.number}</div>
                    <div>[Department]</div>
                  </div>
                  <div className="text-right">
                    <div>[Employee Name]</div>
                    <div>[EID]</div>
                  </div>
                </div>
                <div className="p-4">
                  <div className="alert alert-secondary">
                    <strong>COURSE COMPLETION SUMMARY</strong>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col col-sm-12 col-md-6">
                          <strong>Date Completed</strong>
                          <h5
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            MM/DD/YYYY-MM/DD/YYYY
                          </h5>
                        </div>
                        <div className="col col-sm-12 col-md-6">
                          <strong>Course Hours</strong>
                          <h5
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            {template?.data?.hours || 0}
                          </h5>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col col-sm-12 col-md-6">
                          <strong>Location</strong>
                          <h5
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            [Address]
                          </h5>
                        </div>
                        <div className="col col-sm-12 col-md-6">
                          <strong>Course Qualifications</strong>
                          <h5
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            {template?.data?.courseCharacteristicsDto.isEmsCe &&
                              "EMS CE"}
                            {template?.data?.courseCharacteristicsDto.isIso &&
                              "ISO"}
                            {/* 
                            TODO:
                            {template?.data?.courseQualificationEnum ===
                              ApsModels.CourseQualificationEnum
                                .InstructorQualifications &&
                              "Instructor Qualifications"}
                            {!template?.data?.courseQualificationEnum && "None"} */}
                          </h5>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col col-sm-12 col-md-6">
                          <strong>Instructor</strong>
                          <h5
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            [Instructor Name]
                          </h5>
                        </div>
                        <div className="col col-sm-12 col-md-6"></div>
                      </div>
                    </div>
                  </div>

                  <div className="alert alert-secondary pb-4">
                    <strong>COURSE CURRICULUM</strong>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col col-sm-12 col-md-6">
                          <strong>Course Description</strong>
                          <h5
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            {template?.data?.courseDescription}
                          </h5>
                        </div>
                        <div className="col col-sm-12 col-md-6">
                          <strong>Course Objectives</strong>
                          <h5
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            {template?.data?.courseObjective}
                          </h5>
                        </div>
                      </div>
                      <div className="mt-4">
                        <strong>Course Media or Attachments</strong>
                      </div>
                      <div className="row mt-2">
                        {template.data?.courseFileOutputDtos?.map((cf) => (
                          <div key={cf.id} className="col col-sm-12 col-md-6">
                            <span className="alert alert-secondary m-0 mt-2 flex flex-center">
                              <i
                                title="Download File"
                                className="mr-2 fa fa-download text-primary pointer"
                                onClick={() => {
                                  if (template.data?.id) {
                                    ApsServices.http.credTemplate
                                      .downloadCourseFile(
                                        template.data?.id,
                                        cf.id
                                      )
                                      .then((data) => {
                                        const file = commonService.b64toBlob(
                                          data.fileContents,
                                          "text/plain"
                                        );
                                        FileSaver.saveAs(
                                          file,
                                          data.fileDownloadName
                                        );
                                      })
                                      .catch((error) => {
                                        toastStore.showError(
                                          "Failed to Download File",
                                          error
                                        );
                                      });
                                  }
                                }}
                              ></i>
                              <span className="flex-1 overflow-hidden">
                                <span>{cf.fileName}</span>
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewCourseTemplate;
