import React, { useEffect, useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils, DayPickerProps } from "react-day-picker";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import moment from "moment";
import { IChildComponentProps } from "./FormGroups";

const FORMAT = "MM/dd/yyyy";
const MINUTES = ["00", "15", "30", "45"];
const HOURS: string[] = [];
let cnt = 0;
while (cnt < 24) {
  let i = `${cnt}`;
  if (i.length === 1) {
    i = `0${i}`;
  }
  HOURS.push(i);
  cnt++;
}
const ONE_TO_59: string[] = [];
cnt = 0;
while (cnt < 60) {
  let i = `${cnt}`;
  if (i.length === 1) {
    i = `0${i}`;
  }
  ONE_TO_59.push(i);
  cnt++;
}
export interface IDateTimePickerProps extends IChildComponentProps<any> {
  dayPickerProps?: DayPickerProps;
  dateOnly?: boolean;
  disabled?: boolean;
  style?: object;
  showAllMinuteOptions?: boolean;
  showSeconds?: boolean;
}

function DateTimePicker(props: IDateTimePickerProps) {
  function parseDate(str: string, format: string, locale: any) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function formatDate(date: Date, format: string, locale: any) {
    return dateFnsFormat(date, format, { locale });
  }

  const [datePart, setDatePart] = useState(
    props?.data ? moment(props.data).format("YYYY-MM-DD") : undefined
  );
  const [hhPart, setHHPart] = useState(
    props?.data ? moment(props.data).format("HH") : "00"
  );
  const [mmPart, setMMPart] = useState(
    props?.data ? moment(props.data).format("mm") : "00"
  );
  const [ssPart, setSSPart] = useState(
    props?.data ? moment(props.data).format("ss") : "00"
  );

  useEffect(() => {
    if (datePart && props?.onChange) {
      const date = moment(`${datePart}T${hhPart}:${mmPart}:${ssPart}`).toDate();
      props.onChange(date);
      //console.log(date);
    }
  }, [datePart, hhPart, mmPart, ssPart]);

  return (
    <div className="apsDateTimePicker">
      <div>
        <DayPickerInput
          formatDate={formatDate}
          format={FORMAT}
          parseDate={parseDate}
          placeholder={FORMAT.toUpperCase()}
          value={
            props?.data ? moment(props.data).format("MM/DD/y") : undefined
          }
          onDayChange={(day) => {
            if (!day) {
              props && props.onChange(null);
              setDatePart(null as any); // !!!
              return;
            }
            setDatePart(moment(day).format("YYYY-MM-DD"));
          }}
          dayPickerProps={props?.dayPickerProps}
          inputProps={{ disabled: props.disabled }}
          style={props?.style}
        />
      </div>
      {!props.dateOnly && (
        <div>
          <div>
            <select
              className={`form-control ${!datePart ? "custom-disabled" : ""}`}
              value={hhPart}
              onChange={(e) => {
                setHHPart(e.target.value);
              }}
              disabled={props.disabled || !datePart}
            >
              {!!datePart &&
                HOURS.map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <select
              className={`form-control ${!datePart ? "custom-disabled" : ""}`}
              value={mmPart}
              onChange={(e) => {
                setMMPart(e.target.value);
              }}
              disabled={props.disabled || !datePart}
            >
              {!!datePart &&
                (props.showAllMinuteOptions ? ONE_TO_59 : MINUTES).map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
            </select>
          </div>
          {props.showSeconds && (
            <div>
              <select
                className={`form-control ${!datePart ? "custom-disabled" : ""}`}
                value={ssPart}
                onChange={(e) => {
                  setSSPart(e.target.value);
                }}
                disabled={props.disabled || !datePart}
              >
                {!!datePart &&
                  (props.showAllMinuteOptions ? ONE_TO_59 : MINUTES).map(
                    (i) => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    )
                  )}
              </select>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DateTimePicker;
