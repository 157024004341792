import { OptionGroupDto } from "../../../../data/OptionGroup";
import fetcher from "../Fetcher";

class OptionGroupService {
  save = async (data: OptionGroupDto) => {
    const url = `/api/OptionGroup/Save`;
    return fetcher.post<OptionGroupDto>(url, data);
  };

  update = async (data: OptionGroupDto) => {
    const url = `/api/OptionGroup/Update`;
    return fetcher.put<OptionGroupDto>(url, data);
  };

  delete = async (id: number) => {
    const url = `/api/OptionGroup/Delete/${id}`;
    return fetcher.delete<any>(url);
  };

  getAllOptionGroupsByDepartment = async (departmentId: number) => {
    const url = `/api/OptionGroup/OptionGroups/${departmentId}`;
    return fetcher.get<OptionGroupDto[]>(url);
  };
}

const optionGroup = new OptionGroupService();
export default optionGroup;
