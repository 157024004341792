import {Observable, Subject} from "rxjs";

export enum AccessRightsType {
    DailyOperational,
    UserConsole
}

export interface AccessRights {
    type: AccessRightsType,
    edit: boolean,
    view: boolean,
    noAccess: boolean
}

class AccessRightsInteractionService {
    private readonly accessRights: Subject<AccessRights[]> = new Subject<AccessRights[]>();
    public accessRights$: Observable<any> = this.accessRights.asObservable();

    public changeAccessRights(value: AccessRights[]): void {
        this.accessRights.next(value);
    }
}

const accessRightsInteractionService = new AccessRightsInteractionService();
export {accessRightsInteractionService as AccessRightsInteractionService}
