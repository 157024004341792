import React, { useState } from "react";
import ApsServices from "../../services";
import toastStore from "../../stores/ToastStore";
import FileDragNDrop from "../Common/FileDragNDrop";
import { IBasicDialogProps } from "../Common/FormGroups";
import FormModal from "../Common/FormModal";

function CredentialImportDialog(props: IBasicDialogProps<any>) {
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  return (
    <div>
      <FormModal
        title="Import CSV"
        isOpen={true}
        close={() => close()}
        submit={() => {
          const formData = new FormData();
          files.forEach((file) => {
            formData.append("file", file);
          });

          setSaving(true);
          ApsServices.http.credCredential
            .importTakenCredentials(formData)
            .then(() => {
              toastStore.showToast(
                "Credentials taken have been scheduled for import",
                "success"
              );
              close();
            })
            .catch((error) => {
              toastStore.showError("Failed Importing File", error);
            })
            .finally(() => {
              setSaving(false);
            });
        }}
        size="md"
        closeButtonLabel="Cancel"
        submitButtonLabel={saving ? "Uploading..." : "Import"}
        disableSubmit={saving || files.length === 0}
      >
        <div>
          Please{" "}
          <a href="/templates/TakenCredentialImport.csv" download>
            download template
          </a>{" "}
          and update CSV with your information. Make sure to retain all labels
          as per the template then reupload.
          <div className="pt-3">
            <label>Upload CSV</label>
            <div>
              <FileDragNDrop
                label="Add File"
                disabled={saving}
                accepts={{
                  extensions: "CSV",
                  pattern: /.+(\.csv)$/,
                }}
                onChange={(fileList) => {
                  if (fileList.length > 1) {
                    toastStore.showToast(
                      "Please select one file only.",
                      "warning"
                    );
                    return;
                  }
                  if (fileList.length > 0) {
                    const list = [];
                    let cnt = 0;
                    while (cnt < fileList.length) {
                      const file = fileList[cnt];
                      list.push(file);
                      cnt++;
                    }
                    setFiles(list);
                  }
                }}
              />
            </div>
            {files?.map((f, i) => (
              <span
                key={i}
                className="alert alert-info p-2 mt-2 flex flex-center"
              >
                <span className="flex-1 overflow-hidden">{f.name}</span>
                {!saving && (
                  <i
                    title="Remove File"
                    className="ml-2 fa fa-times pointer"
                    onClick={() => {
                      const list = [...files];
                      list.splice(i, 1);
                      setFiles(list);
                    }}
                  ></i>
                )}
              </span>
            ))}
          </div>
        </div>
      </FormModal>
    </div>
  );
}

export default CredentialImportDialog;
