import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSRemarkService {
  get = async (id: any, reportId: any) => {
    const url = `/api/NfirsRemark/${id}/${reportId}`;
    return fetcher.get<ApsModels.INfirsRemarkOutputDto>(url);
  };

  delete = async (id: any, reportId: any, concurrencyToken: any) => {
    const url = `/api/NfirsRemark/${id}/${reportId}/${concurrencyToken}`;
    return fetcher.delete<any>(url);
  };

  getAll = async (reportId: any, section: ApsModels.NfirsRemarkSectionEnum) => {
    const url = `/api/NfirsRemark/GetAll/${reportId}/${section}`;
    return fetcher.get<ApsModels.INfirsRemarkOutputDto[]>(url);
  };

  create = async (formData: FormData) => {
    const url = `/api/NfirsRemark/Create`;
    return fetcher.postFormData<any>(url, formData);
  };

  update = async (formData: FormData) => {
    const url = `/api/NfirsRemark/Update`;
    return fetcher.postFormData<any>(url, formData);
  };

  download = (id: number, reportId: number) => {
    const url = `/api/NfirsRemark/Download/${id}/${reportId}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };
  
  thumbnail = (id: number, reportId: number) => {
    const url = `/api/NfirsRemark/Thumbnail/${id}/${reportId}`;
    return fetcher.get<any>(url);
  };
}

const nfirsRemark = new NFIRSRemarkService();
export default nfirsRemark;
