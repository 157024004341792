import { faEye, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import Pagination, { usePaging } from "../Common/Pagination";
import { RouteComponentProps } from "react-router-dom";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import CredentialStatusCellLabel from "./CredentialStatusCellLabel";
import { useNfirsGetData } from "../NFIRS/NFIRSHelper";
import CredentialExpirationDateCell from "./CredentialExpirationDateCell";
import { useDepartmentId, useGridFilters } from "../../stores/SystemStore";
import { GetCredStatusEnumStatusLabel } from "./CredentialList";
import LoadMoreIndicator from "../Common/LoadMoreIndicator";

const GRID_FILTER_VIEWBYCREDENTIAL = "aps.grid.filter.view.by.credential";
interface IGridFilter {
  pageSize?: number;
  userId?: any;
  userName?: string;
  status?: number;
  model?: any;
}

const GetGridFilters = () => {
  const fdata = localStorage.getItem(GRID_FILTER_VIEWBYCREDENTIAL);
  return JSON.parse(fdata || "{}") as IGridFilter;
};

const ClearGridFilters = () => {
  const existingData = GetGridFilters();
  localStorage.setItem(
    GRID_FILTER_VIEWBYCREDENTIAL,
    JSON.stringify({
      pageSize: existingData.pageSize,
    })
  );
};

const SetGridFilters = (newData: IGridFilter) => {
  const existingData = GetGridFilters();
  localStorage.setItem(
    GRID_FILTER_VIEWBYCREDENTIAL,
    JSON.stringify({
      ...existingData,
      ...newData,
    })
  );
};

function EmployeeListForCredential(
  props: RouteComponentProps<any> & {
    credentialId: any;
    sendCredInfo?: (name: string, code: string, state: string) => void;
  }
) {
  const [claims] = useState(commonService.friendlyClaims);
  const departmentId = useDepartmentId();

  const [showResetFilter, setShowResetFilter] = useState(false);
  const toggleShowResetFilter = () => {
    if (
      gridApi?.isAnyFilterPresent() ||
      filters?.userId ||
      filters.credStatusEnum !== null
    ) {
      claims.isAPSAdmin && setShowResetFilter(true);
    } else {
      setShowResetFilter(false);
    }
  };

  const statuses = useNfirsGetData(
    ApsServices.http.credCommon.credentialStatuses,
    "Statuses"
  );

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
    claims.isAPSAdmin && SetGridFilters({ pageSize: pageSize });
  };

  useEffect(() => {
    statuses.getData();

    if (claims.isAPSAdmin) {
      const fdata = GetGridFilters();
      if (fdata.pageSize) {
        setPaging({ ...paging, pageSize: fdata.pageSize });
      }
      setFilters((f) => {
        return {
          ...f,
          userId: fdata.userId || (null as any),
          credStatusEnum:
            fdata.status === undefined ? (null as any) : fdata.status,
        };
      });
      if (fdata.userId && fdata.userName) {
        setMember({
          id: fdata.userId,
          name: fdata.userName,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [list, setList] =
    useState<ApsModels.ICredCredentialEmployeesInCredentialGridDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridFilters.init(params);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && list) {
      // setTimeout(() => {
      //   let allColumnIds: any[] = [];
      //   gridColumnApi.getAllColumns()?.forEach(function (column: any) {
      //     allColumnIds.push(column.colId);
      //   });
      //   gridColumnApi.autoSizeColumns(allColumnIds, false);
      //   gridApi.sizeColumnsToFit();
      // }, 500);
    }
  };

  const AttachmentCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
  }) => {
    if (rowProps.data.hasAttachment) {
      return <FontAwesomeIcon icon={faFile} />;
    }
    return null;
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
  }) => {
    if (claims.credentials.canViewAll) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-outlined text-primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            //props.history.push(`/credentials/employee/${rowProps.data.userId}`);
            // if (
            //   rowProps.data.credCategoryTypeEnum ===
            //   ApsModels.CredCategoryTypeEnum.ExistingExternalCredential
            // ) {
            //   props.history.push(
            //     `/Credential/${rowProps.data.takenCredentialId}`
            //   );
            // } else {
            //   props.history.push(
            //     `/credentials/view/${rowProps.data.takenCredentialId}`
            //   );
            // }
            props.history.push(
              `/credentials/view/${rowProps.data.takenCredentialId}`
            );
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
      );
    }
    return <span></span>;
  };

  const NameCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          //props.history.push(`/credentials/employee/${rowProps.data.userId}`);
          // if (
          //   rowProps.data.credCategoryTypeEnum ===
          //   ApsModels.CredCategoryTypeEnum.ExistingExternalCredential
          // ) {
          //   props.history.push(
          //     `/Credential/${rowProps.data.takenCredentialId}`
          //   );
          // } else {
          //   props.history.push(
          //     `/credentials/view/${rowProps.data.takenCredentialId}`
          //   );
          // }
          props.history.push(
            `/credentials/view/${rowProps.data.takenCredentialId}`
          );
        }}
      >
        {rowProps.data.employeeName}
      </span>
    );
  };

  const ExpirationCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
  }) => {
    if (!rowProps.data.expirationDate) {
      return <span>Not Applicable</span>;
    }
    return (
      <CredentialExpirationDateCell
        date={rowProps.data.expirationDate}
      ></CredentialExpirationDateCell>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          return true;
        },
        field: "_selected",
        headerName: "",
        width: 10,
        ...commonService.agGridHelpers.checkboxColDef,
      },
      // {
      //   field: "employeeName",
      //   headerName: "Employee Name",
      //   cellRenderer: "nameCellComponent",
      //   minWidth: 250,
      //   suppressSizeToFit: false,
      // },
      {
        field: "lastName",
        headerName: "Last Name",
        suppressSizeToFit: false,
      },
      {
        field: "firstName",
        headerName: "First Name",
        suppressSizeToFit: false,
      },
      {
        field: "percentageComplete",
        headerName: "% Complete",
        valueFormatter: (param: {
          data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
        }) => {
          return `${commonService.toNumberWithComma(
            param.data.percentageComplete || 0
          )}%`;
        },
      },
      {
        field: "statusName",
        headerName: "Status",
        cellRenderer: "statusCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: "personnelId",
        headerName: "EID",
        hide: true,
      },
      {
        field: "hasAttachment",
        headerName: "Attachment",
        cellRenderer: "attachmentCellComponent",
        hide: true,
      },
      {
        field: "dateStartedString",
        headerName: "Date Started",
        valueFormatter: (param: {
          data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
        }) => {
          return param.data.dateStarted
            ? `${moment(param.data.dateStarted).format("MMM DD, YYYY")}`
            : "";
        },
        hide: true,
      },
      {
        field: "dueDateString",
        headerName: "Assignment Due Date",
        valueFormatter: (param: {
          data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
        }) => {
          return param.data.dueDate
            ? `${moment(param.data.dueDate).format("MMM DD, YYYY")}`
            : "Not Applicable";
        },
        hide: true,
      },
      {
        field: "dateIssuedString",
        headerName: "Effective Date",
        valueFormatter: (param: {
          data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
        }) => {
          return param.data.dateIssued
            ? `${moment(param.data.dateIssued).format("MMM DD, YYYY")}`
            : "Not Applicable";
        },
        hide: true,
      },
      {
        field: "expirationDateString",
        headerName: "Expiration Date",
        cellRenderer: "expirationCellComponent",
        width: 100,
        suppressSizeToFit: true,
        hide: true,
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "viewButtonCellComponent",
        width: 100,
        ...commonService.agGridHelpers.actionColPinnedDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      suppressSizeToFit: true,
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      expirationCellComponent: ExpirationCellComponent,
      statusCellComponent: CredentialStatusCellLabel,
      nameCellComponent: NameCellComponent,
      attachmentCellComponent: AttachmentCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ICredCredentialEmployeesInCredentialItemDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });
  const gridFilters = useGridFilters(
    "employe-list-for-credential-table",
    gridApi,
    (others) => {
      if (others) {
        if (others.userId) {
          setMember({
            id: others.userId,
            name: others.userName,
          });
        }
        setFilters((prev) => {
          return {
            ...prev,
            userId: others.userId || (null as any),
            credStatusEnum: others.credStatusEnum || (null as any),
          };
        });
      }
    }
  );

  const [loadingMore, setLoadingMore] = useState(false);
  const [pageToLoad, setPageToLoad] = useState(1);

  const [loading, setLoading] = useState(false);
  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();
    setLoading(true);
    await ApsServices.http.credCredential
      .listEmployeesInCredential(
        1,
        commonService.gridPageSize.firstPage,
        Number(props.credentialId),
        filters
      )
      .then((data) => {
        setList(data as any);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
        }
        setLoading(false);
        if (props.sendCredInfo && data) {
          props.sendCredInfo(data.name, data.code, data.state);
        }

        if (claims.isAPSAdmin) {
          const fdata = GetGridFilters();
          gridApi.setFilterModel(fdata.model || {});
        }
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Credentials", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);

        if (commonService.getErrorStatusCode(err) === 404) {
          props.history.push("/credentials");
        }
      });
  };

  const loadMore = async () => {
    if (
      list &&
      list.totalRecords >
        list.credCredentialEmployeesInCredentialItemDtos?.length
    ) {
      setLoadingMore(true);
      setLoading(false);

      let pageNum = pageToLoad + 1;
      if (
        commonService.gridPageSize.firstPage <
          commonService.gridPageSize.otherPages &&
        list.credCredentialEmployeesInCredentialItemDtos?.length ===
          commonService.gridPageSize.firstPage
      ) {
        pageNum = 1;
      }

      setPageToLoad(pageNum);
      await ApsServices.http.credCredential
        .listEmployeesInCredential(
          pageNum,
          commonService.gridPageSize.otherPages,
          Number(props.credentialId),
          filters
        )
        .then((data) => {
          setList((prev) => {
            return {
              ...data,
              totalRecords: prev?.totalRecords || data.totalRecords,
              credCredentialEmployeesInCredentialItemDtos:
                pageNum === 1
                  ? data.credCredentialEmployeesInCredentialItemDtos
                  : [
                      ...(prev?.credCredentialEmployeesInCredentialItemDtos ||
                        []),
                      ...data.credCredentialEmployeesInCredentialItemDtos,
                    ],
            };
          });
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Credentials", err);
          setLoadingMore(false);
        })
        .finally(() => {
          setLoadingMore(false);
          setLoading(false);
        });
    } else {
      gridFilters.reApplyFilters(); //need to reapply filter after adding more data
      toggleColSizes();
      setLoadingMore(false);
      setLoading(false);
    }
  };

  const [rowsSelectedCount, setRowSelectedCount] = useState(0);
  const [filters, setFilters] =
    useState<ApsModels.ICredCredentialByCredentialSearchInputDto>({
      userId: null as any,
      credStatusEnum: null as any,
      isMyReportsOnly: false,
      byLastModified: false,
      credentialId: null as any,
    });

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (loading || loadingMore) {
      return;
    }
    if (list?.totalRecords) {
      gridApi && gridApi.hideOverlay();
    }
    setGridState({
      ...gridState,
      rowData: (list?.credCredentialEmployeesInCredentialItemDtos || []).map(
        (row) => {
          return {
            ...row,
            lastName: row.employeeName.split(",")[0] || "",
            firstName: row.employeeName.split(",")[1] || "",
          };
        }
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, loadingMore]);

  useEffect(() => {
    if (gridApi) {
      if (departmentId.current > 0) {
        if (departmentId.changed && departmentId.previous > 0) {
          props.history.push("/credentials");
          return; //exit
        }
        getList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, paging, filters, departmentId]);

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    return gridApi.getSelectedRows().filter((row) => {
      return (
        !isAnyFilterPresent ||
        !!filteredRows.find((r: any) => r.data.userId === row.userId)
      );
    });
  };

  const canAddCredentials = () => {
    if (claims.credentials.canAddAll) {
      return true;
    }
    return (
      claims.credentials.canAccess &&
      (!props.credentialId ||
        Number(props.credentialId) === commonService.userInfo.userId)
    );
  };

  const [exporting, setExporting] = useState(false);
  const startExport = async () => {
    gridFilters.exportCSV();
    // setExporting(true);
    // await ApsServices.http.credCredential
    //   .extractEmployeesInCredentials(props.credentialId, {
    //     ids: getSelectedRows().map((r) => r.userId),
    //   })
    //   .then((data) => {
    //     const file = commonService.b64toBlob(data.fileContents, "text/plain");
    //     FileSaver.saveAs(file, data.fileDownloadName);
    //   })
    //   .catch((error) => {
    //     toastStore.showError("Failed Exporting Credentials", error);
    //   })
    //   .finally(() => {
    //     setExporting(false);
    //   });
  };

  const [member, setMember] = useState<{
    name: string;
    id: number;
  }>();
  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setUsers([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await ApsServices.http.credCommon
      .credentialsTypeAheadUser({
        search: query,
        isAnd: false,
        recordCount: 10,
        shiftId: null as any,
        rankId: null as any,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));

        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (claims.isAPSAdmin) {
      toggleShowResetFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, paging, gridApi]);

  return (
    <>
      <div className="flex flex-col h-100 p-4">
        <div
          className="flex flex-row mb-3"
          style={{ flexWrap: "wrap", gap: "10px" }}
        >
          <div
            className="flex-1 flex"
            style={{
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              minWidth: "250px",
            }}
          >
            <div className="mr-4">
              <button
                className="btn btn-outline-secondary"
                onClick={(e) => {
                  startExport();
                }}
                disabled={rowsSelectedCount === 0 || exporting}
              >
                {exporting
                  ? `Exporting...`
                  : `Export to CSV (${rowsSelectedCount})`}
              </button>
            </div>

            {claims.credentials.canViewAll && (
              <>
                <div>
                  <strong>Filter</strong>
                </div>
                <Dropdown drop="down">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="dropdownStatusesFilter"
                    disabled={loading}
                  >
                    {statuses.data?.find(
                      (s) => s.value === filters.credStatusEnum
                    )?.label || "All Statuses"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        setFilters((f) => {
                          return {
                            ...f,
                            credStatusEnum: null as any,
                          };
                        });
                        if (claims.isAPSAdmin) {
                          SetGridFilters({
                            status: null as any,
                          });
                        }
                      }}
                    >
                      All Statuses
                    </Dropdown.Item>
                    {statuses.data?.map((s) => (
                      <Dropdown.Item
                        key={s.value}
                        onClick={(e) => {
                          setFilters((f) => {
                            return {
                              ...f,
                              credStatusEnum: s.value,
                            };
                          });
                          if (claims.isAPSAdmin) {
                            SetGridFilters({
                              status: s.value,
                            });
                          }
                        }}
                      >
                        {s.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div style={{ minWidth: "200px" }}>
                  <AsyncTypeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onSearch={handleSearch}
                    onChange={(data) => {
                      if (data && data.length > 0) {
                        setMember({
                          id: data[0].id,
                          name: data[0].name,
                        });
                        setFilters((f) => {
                          return {
                            ...f,
                            userId: data[0].id,
                          };
                        });
                        if (claims.isAPSAdmin) {
                          SetGridFilters({
                            userId: data[0].id,
                            userName: data[0].name,
                          });
                        }
                        setUsers(data);
                        (ref.current as any)?.clear();
                      }
                    }}
                    searchText={"Searching..."}
                    isLoading={isLoading}
                    options={users}
                    placeholder="Search User"
                    minLength={1}
                    delay={500}
                    useCache={false}
                    ref={ref}
                  />
                </div>
                {!!member && (
                  <div className="alert alert-secondary m-0 p-2">
                    {member?.name}{" "}
                    <i
                      className="fa fa-times ml-2 pointer"
                      onClick={() => {
                        setMember(undefined);
                        setFilters((f) => {
                          return {
                            ...f,
                            userId: null as any,
                          };
                        });
                        if (claims.isAPSAdmin) {
                          SetGridFilters({
                            userId: null as any,
                            userName: null as any,
                          });
                        }
                      }}
                    ></i>
                  </div>
                )}
                {showResetFilter && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={(e) => {
                      gridApi.setFilterModel({});
                      setMember(undefined);
                      setFilters((f) => {
                        return {
                          ...f,
                          userId: null as any,
                          credStatusEnum: null as any,
                        };
                      });
                      ClearGridFilters();
                    }}
                  >
                    Remove Filters <i className="fa fa-ban"></i>
                  </button>
                )}
                <div>
                  {/* <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={(e) => {
                      gridApi.setFilterModel({});
                      setMember(undefined);
                      setFilters((f) => {
                        return {
                          ...f,
                          userId: null as any,
                          credStatusEnum: null as any,
                        };
                      });
                      ClearGridFilters();
                      gridFilters.reset();
                      toggleColSizes();
                    }}
                  >
                    Reset
                  </button> */}
                </div>
              </>
            )}
          </div>

          <div>
            {canAddCredentials() && (
              <Button
                variant="primary"
                type="button"
                onClick={(e) => {
                  // props.history.push(
                  //   `/Credential/EarnOrRenew/${props.credentialId}`
                  // );
                  props.history.push(`/credentials/${props.credentialId}`);
                }}
              >
                Add Employee
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              minHeight: "300px",
            }}
            className="ag-theme-alpine flex-1"
          >
            <AgGridReact
              columnDefs={gridState.columnDefs}
              defaultColDef={gridState.defaultColDef}
              autoGroupColumnDef={gridState.autoGroupColumnDef}
              enableRangeSelection={true}
              animateRows={true}
              onGridReady={onGridReady}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={false}
              suppressRowDeselection={true}
              suppressRowClickSelection={true}
              frameworkComponents={gridState.frameworkComponents}
              rowData={(gridState.rowData || []).map(
                (
                  row: ApsModels.ICredCredentialEmployeesInCredentialItemDto
                ) => {
                  return {
                    ...row,
                    statusName: GetCredStatusEnumStatusLabel(row.status),
                    dueDateString: row.dueDate
                      ? moment(row.dueDate).format("YYYY-MM-DD")
                      : "Not Applicable",
                    expirationDateString: row.expirationDate
                      ? moment(row.expirationDate).format("YYYY-MM-DD")
                      : "Not Applicable",
                    dateIssuedString: row.dateIssued
                      ? moment(row.dateIssued).format("YYYY-MM-DD")
                      : "Not Applicable",
                    dateStartedString: row.dateStarted
                      ? moment(row.dateStarted).format("YYYY-MM-DD")
                      : "",
                  };
                }
              )}
              onRowDoubleClicked={(event: {
                data: ApsModels.ICredCredentialEmployeesInCredentialItemDto;
              }) => {
                // props.history.push(
                //   `/credentials/employee/${event.data.userId}`
                // );
                // if (
                //   event.data.credCategoryTypeEnum ===
                //   ApsModels.CredCategoryTypeEnum.ExistingExternalCredential
                // ) {
                //   props.history.push(
                //     `/Credential/${event.data.takenCredentialId}`
                //   );
                // } else {
                //   props.history.push(
                //     `/credentials/view/${event.data.takenCredentialId}`
                //   );
                // }
                props.history.push(
                  `/credentials/view/${event.data.takenCredentialId}`
                );
              }}
              overlayLoadingTemplate={gridState.overlayLoadingTemplate}
              overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
              onRowSelected={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onRowDataChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onFilterChanged={(event) => {
                setRowSelectedCount(getSelectedRows().length);
                if (claims.isAPSAdmin) {
                  const fdata = event.api.getFilterModel();
                  SetGridFilters({
                    model: fdata,
                  });
                  toggleShowResetFilter();
                }
              }}
              {...gridFilters.props}
            />
          </div>
        </div>
        <LoadMoreIndicator
          loadingMore={loadingMore}
          currentCount={
            list?.credCredentialEmployeesInCredentialItemDtos?.length
          }
          totalExpected={list?.totalRecords}
        ></LoadMoreIndicator>
        {/* <Pagination
          length={list?.totalRecords || 0}
          page={paging.page}
          pageSize={paging.pageSize}
          pageChange={pageChange}
          showingOfWhatLabel="Records"
          sizes={[10, 20, 50, 100, ...(claims.isAPSAdmin ? [300] : [])]}
        ></Pagination> */}
      </div>
    </>
  );
}

export default EmployeeListForCredential;
