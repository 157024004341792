import React from "react";

type TextInputState = {
    value: string
}

type TextInputProps = {
    value: string
    placeholderText?: string
    debounce?: boolean
    onChange: (value: string) => void
}

export class TextInput extends React.Component<TextInputProps, TextInputState> {

    timer: number | undefined; // type issue resolved by: https://stackoverflow.com/q/51040703

    constructor(props: TextInputProps) {
        super(props);
        this.state = { value: this.props.value }
        this.timer = undefined;
    }

    handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ value: e.target.value });
        if (this.props.debounce) {
            if (this.timer) window.clearTimeout(this.timer);
            const saved: string = e.target.value;
            this.timer = window.setTimeout(() => this.props.onChange(saved), 1000);
        } else {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        return (
            <input type="text" value={this.state.value} placeholder={this.props.placeholderText} onChange={this.handleInput} />
        )
    }
}