import React, { Component } from "react";
import { UserConsoleSectionDto, UserConsoleSectionItemDto } from "../../data/UserConsole";
import './UserConsole.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { UserConsoleAdminSectionDto } from "../../data/UserConsoleAdmin";

type Props = {
    items: UserConsoleAdminSectionDto,
    i: number,
    marginBottom? : string
}
type State = {
    isActive: boolean,
    isActive1: boolean
};

interface ListProps {
    childItems: UserConsoleSectionItemDto,
    i: number
}

class ListWithItemChildren extends Component<ListProps, State>  {

    state: State = {
        isActive: false,
        isActive1: false
    }
    handleIsActive = () => {
        this.setState({
            isActive: !this.state.isActive,
        })
    }
    handleIsActive1 = () => {
        this.setState({
            isActive1: !this.state.isActive1,
        })
    }
    render() {
        return (
            <li key={this.props.i} className="list-item">
                <span className="list-content-child-text">{this.props.childItems.name}
                    <FontAwesomeIcon color="#0472a7" icon={!this.state.isActive ? faChevronDown : faChevronUp} onClick={this.handleIsActive} className="cursor-pointer float-right subChevAlign" />
                </span>
                {this.state.isActive ? <div className="container-fluid p-3">
                {this.props.childItems.childSectionItems?.map((element, i) =>
                    !element.childSectionItems?.length ?
                        <li key={i} className="list-item">
                            <a className="link-section" style={ !element.url ? {pointerEvents: "none"} : {pointerEvents: "unset"}} href={element.url} rel="noopener noreferrer" target="_blank">
                                <span className="cursor-pointer list-content-text">{element.name}</span>
                            </a>
                        </li>
                        :
                        <ListWithItemChildren childItems={element} i={i} />
                    )}
                </div> : null}

            </li>
        )
    }

}
export class SectionList extends Component<Props, State> {
    render() {
        return (
            <div key={this.props.i}>
                <div className="card box-card" >
                    <div className="card-body">
                        <h4 className="mb-0 font-size-16">{this.props.items.name}</h4>
                        <div className="mt-3"></div>
                        <div className="container-fluid">
                            <div className="row-fluid">
                                <ul className="list-container" style={{ paddingBottom: this.props.items.formatting + 'px' }}>
                                    {this.props.items.sectionItems?.sort(function(a, b) {  return a.sortOrder - b.sortOrder; })?.map((element, i) =>
                                        !element.childSectionItems.length ?
                                            <li key={i} className={`list-item`} >
                                                <a className="link-section" href={element.url} rel="noopener noreferrer" target="_blank">
                                                    <span className="cursor-pointer list-content-text">{element.name}</span>
                                                </a>
                                            </li>
                                            :
                                            <ListWithItemChildren childItems={element} i={i} key={i} />
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
