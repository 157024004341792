import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";

function NFIRSWildlandDetails(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsWildlandDetail.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsWildlandDetailInputDto>({
    objectName: "Details",
    nfirsSectionTab: "Wildland Fire/Details",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsWildlandDetailInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsWildlandDetail
      .get(props.id)
      .then((data) => {
        const mdl = { ...model.new, ...data, reportId: Number(props.id) };
        initModels(mdl);
        setValuesFromModel(data);
        setProgress({ loading: false });
        props.onChange(mdl);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Details", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Wildland Fire",
    "Details",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Wildland Fire", "Details", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const rightOfWays = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsTypeOfRightOfWayEntries,
    "Type of Right-of-Ways"
  );
  const relativePosition = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsPositionOnSlopeEntries,
    "Relative Positions on Slope"
  );
  const aspects = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsAspectEntries,
    "Aspects"
  );

  useEffect(() => {
    setProgress({ loading: true });
    rightOfWays.getData();
    relativePosition.getData();
    aspects.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      rightOfWays.status === NfirsFetchStatus.Complete &&
      relativePosition.status === NfirsFetchStatus.Complete &&
      aspects.status === NfirsFetchStatus.Complete
    ) {
      setProgress({ loading: true });
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightOfWays.status, relativePosition.status, aspects.status]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    distanceFromRightOfWay: {
      displayName: "Distance from Right-of-way",
      validation: {
        required: false,
        max: 99,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsTypeOfRightOfWayEntryId: {
      displayName: "Type of Right-of-way",
      validation: {
        required: false,
      },
    },
    elevation: {
      displayName: "Elevation",
      validation: {
        required: false,
        max: 99999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsPositionOnSlopeEntryId: {
      displayName: "Relative Position on Stage",
      validation: {
        required: false,
      },
    },
    nfirsAspectEntryId: {
      displayName: "Aspect",
      validation: {
        required: false,
      },
    },
    flameLength: {
      displayName: "Flame Length",
      validation: {
        required: false,
        max: 99,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    rateOfSpeed: {
      displayName: "Rate of Speed",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 mb-2">
          <strong>Type of Right-of-Way</strong>
        </div>
        <div className="col-sm-12">
          <div className="row mt-2">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FgInput
                id="distanceFromRightOfWay"
                label="Distance from Right-of-way (in feet)"
                placeHolder="Distance from Right-of-way (in feet)"
                registeredField={registry.distanceFromRightOfWay}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    distanceFromRightOfWay: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
              ></FgInput>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FgSelect
                id="nfirsTypeOfRightOfWayEntryId"
                label="Type of Right-of-way"
                selectMessage="- Select Type -"
                registeredField={registry.nfirsTypeOfRightOfWayEntryId}
                formState={formState}
                options={(rightOfWays?.data || []).map((item) => {
                  return {
                    label: `${item.externalId}. ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsTypeOfRightOfWayEntryId:
                      Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FgInput
                id="elevation"
                label="Elevation (in feet)"
                placeHolder="Elevation (in feet)"
                registeredField={registry.elevation}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    elevation: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
              ></FgInput>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FgSelect
                id="nfirsPositionOnSlopeEntryId"
                label="Relative Position on Slope"
                selectMessage="- Select Position on Slope -"
                registeredField={registry.nfirsPositionOnSlopeEntryId}
                formState={formState}
                options={(relativePosition?.data || []).map((item) => {
                  return {
                    label: `${item.externalId}. ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsPositionOnSlopeEntryId:
                      Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>{" "}
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FgSelect
                id="nfirsAspectEntryId"
                label="Aspect"
                selectMessage="- Select Aspect -"
                registeredField={registry.nfirsAspectEntryId}
                formState={formState}
                options={(aspects?.data || []).map((item) => {
                  return {
                    label: `${item.externalId}. ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsAspectEntryId: Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FgInput
                id="flameLength"
                label="Flame Length (in feet)"
                placeHolder="Flame Length (in feet)"
                registeredField={registry.flameLength}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    flameLength: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
              ></FgInput>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <FgInput
                id="rateOfSpeed"
                label="Rate of Speed (Chain per hour)"
                placeHolder="Rate of Speed (Chain per hour)"
                registeredField={registry.rateOfSpeed}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    rateOfSpeed: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
              ></FgInput>
            </div>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSWildlandDetails;
