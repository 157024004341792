import React, { useEffect, useState } from "react";
import ApsServices from "../../services";
import { useDepartmentId } from "../../stores/SystemStore";

type CustomIframeSettings = {
    iframeModuleName: string;
    iframeLink: string;
    id: number;
    concurrencyToken: string;
    departmentId: number;
};

type State = {
    ready: boolean;
    customIframeSettings?: CustomIframeSettings;
};

const CustomIframe = () => {
    const [state, setState] = useState<State>({ ready: false });

    const departmentId = useDepartmentId();
    useEffect(() => {
        const init = async () => {
            const customIframeSettings =
                await ApsServices.http.customIframe.get(
                    departmentId.current
                );
            setState({
                ...state,
                ready: true,
                customIframeSettings: {
                    iframeModuleName: customIframeSettings.iframeModuleName,
                    iframeLink: customIframeSettings.iframeLink,
                    id: customIframeSettings.id,
                    concurrencyToken: customIframeSettings.concurrencyToken,
                    departmentId: departmentId.current,
                },
            });
        };
        init();
    }, []);

    return (
        <div className="h-100 w-100 d-inline-block">
            {state.ready ? (
                <>
                    <iframe
                        className="h-100 w-100 d-inline-block"
                        src={state.customIframeSettings?.iframeLink}
                        title={state.customIframeSettings?.iframeModuleName}>
                    </iframe>
                </>
            ) : (
                "Loading..."
            )}
        </div>
    );
};

export default CustomIframe;
