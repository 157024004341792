import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ApsModels from "../../../../../models";
import ApsServices from "../../../../../services";
import toastStore from "../../../../../stores/ToastStore";
import CommonSpinner from "../../../../Common/CommonSpinner";
import FormModal from "../../../../Common/FormModal";
import {
  FgInput,
  FgCheckbox,
  FgUseForm,
  IBasicDialogProps,
  FgSelect,
} from "../../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../../NFIRSHelper";
import commonService from "../../../../../services/CommonService";
import { useIsOtherModuleDataRequired } from "../../NFIRSEntry";

function NFIRSDialogPersonnel(
  props: IBasicDialogProps<{ reportId: number; id?: number }>
) {
  const { registry, handleSubmit, formState, setValue, setValuesFromModel } =
    FgUseForm({
      personnelEmpId: {
        displayName: "Personnel ID",
        validation: { required: false, maxLength: 9 },
      },
      firstName: {
        displayName: "First Name",
        validation: {
          required: false, //, maxLength: 15
        },
      },
      lastName: {
        displayName: "Last Name",
        validation: {
          required: false, //, maxLength: 25
        },
      },
      rank: {
        displayName: "Rank/Game",
        validation: { required: false, maxLength: 10 },
      },
      nfirsActionTakenId: {
        displayName: "Action Taken",
        validation: { required: false },
      },
    });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const [saving, setSaving] = useState(false);
  const submit = async (form: any) => {
    const data = {
      ...model,
      ...form,
      reportId: Number(props.data?.reportId),
      nfirsActionTakenId: form.nfirsActionTakenId
        ? parseInt(form.nfirsActionTakenId)
        : null,
    };

    setSaving(true);
    await ApsServices.http.nfirsPersonnelService
      .update(data)
      .then((rtn) => {
        close(true);
        toastStore.showToast("Pesonnel saved.", "success");
      })
      .catch((error) => {
        setSaving(false);
        if (commonService.getErrorStatusCode(error) === 404) {
          const errMsg = commonService.parseErrorMessage(error);
          if (errMsg === "Person") {
            toastStore.showError("Failed saving Pesonnel", "Person not found");
            return;
          }
        }
        toastStore.showError("Failed saving Pesonnel", error);
      });
  };

  const [model, setModel] = useState({
    attend: false,
  } as ApsModels.INfirsPersonnelOutputDto);
  const [loading, setLoading] = useState(true);

  const actionsTaken = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsApparatusPersonnelActionTaken,
    "Actions Taken"
  );

  const actionsTakenGroup = useNfirsGetData(
    async () =>
      ApsServices.http.nfirsGenericEnumLookupService.nfirsGenericEnumLookUp(
        "NfirsActionsTakenSubsectionEnum"
      ),
    "Actions Taken Subsections"
  );

  const getDropdowns = async () => {
    await actionsTaken.getData();
    await actionsTakenGroup.getData();
  };

  const getPersonnel = async () => {
    if (props.data?.reportId && props.data?.id) {
      setLoading(true);
      await ApsServices.http.nfirsPersonnelService
        .get(props.data?.id, props.data?.reportId)
        .then((rtn) => {
          setModel(rtn);
          setValuesFromModel(rtn);
        })
        .catch((error) => {
          toastStore.showError("Failed getting Personnel", error);
          close();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDropdowns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      actionsTaken.status === NfirsFetchStatus.Complete &&
      actionsTakenGroup.status === NfirsFetchStatus.Complete
    ) {
      getPersonnel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsTaken.status, actionsTakenGroup.status]);

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  return (
    <div>
      <FormModal
        title={props?.data?.id ? "Edit Personnel" : "Add New Personnel"}
        isOpen={true}
        close={() => close()}
        submit={handleSubmit(submit)}
        size="md"
        submitButtonLabel={
          saving ? "Saving..." : props?.data?.id ? "Yes, Update" : "Yes, Add"
        }
        closeButtonLabel="No, Cancel"
        disableSubmit={loading || saving}
      >
        <div className={`p-3 ${loading ? "" : "display-none"}`}>
          <CommonSpinner></CommonSpinner>
        </div>
        {!loading && (
          <div>
            <h4 className="txt-primary">
              <i>Personnel Information</i>
            </h4>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FgInput
                  id="personnelEmpId"
                  label="Personnel ID"
                  placeHolder="Personnel ID"
                  registeredField={registry.personnelEmpId}
                  formState={formState}
                  onChange={(data) => {}}
                  showRequiredLabel={isOtherModuleDataRequired}
                ></FgInput>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6">
                    <FgInput
                      id="firstName"
                      label="First Name"
                      placeHolder="First Name"
                      registeredField={registry.firstName}
                      formState={formState}
                      onChange={(data) => {}}
                      readOnly={true}
                    ></FgInput>
                  </div>
                  <div className="col-sm-6">
                    <FgInput
                      id="lastName"
                      label="Last Name"
                      placeHolder="Last Name"
                      registeredField={registry.lastName}
                      formState={formState}
                      onChange={(data) => {}}
                      readOnly={true}
                    ></FgInput>
                  </div>
                </div>
              </div>
              <div className="col-8 col-md-6">
                <FgInput
                  id="rank"
                  label="Rank/Grade"
                  placeHolder="Rank/Grade"
                  registeredField={registry.rank}
                  formState={formState}
                  onChange={(data) => {}}
                ></FgInput>
              </div>
              <div
                className="col-4 col-md-6"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FgCheckbox
                  id="attend"
                  label="Attend"
                  data={model.attend}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      attend: data,
                    });
                    setValue("attend", data);
                  }}
                ></FgCheckbox>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <FgSelect
                  id="nfirsActionTakenId"
                  label="Action Taken"
                  selectMessage="- Select Action Taken -"
                  registeredField={registry.nfirsActionTakenId}
                  formState={formState}
                  onChange={(data) => {}}
                  options={(actionsTaken?.data || []).map((opt) => {
                    return {
                      label: `${opt.externalId}. ${opt.description}`,
                      value: opt.id,
                      groupId: opt.nfirsActionsTakenSubsectionEnum,
                    };
                  })}
                  hideGroupIfEmpty={true}
                  groups={(actionsTakenGroup?.data || []).map((opt) => {
                    return {
                      id: opt.value,
                      label: opt.label,
                    };
                  })}
                ></FgSelect>
              </div>
            </div>
          </div>
        )}
      </FormModal>
    </div>
  );
}

export default NFIRSDialogPersonnel;
