import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CredDashboardService {
  getOwnEmsCredentials = async () => {
    const url = "/api/CredDashboard/GetOwnEmsCredentials";
    return fetcher.get<ApsModels.ICredDashboardEmsItemDto[]>(url);
  };

  getOwnExpiringCategory = async () => {
    const url = `/api/CredDashboard/GetOwnExpiringCategory`;
    return fetcher.get<ApsModels.ICredDashboardExpiringCredentialsDto[]>(url);
  };

  getOwnDrillHours = async (
    filter: ApsModels.CredDashboardDrillTimeFilterEnum
  ) => {
    const url = `/api/CredDashboard/GetOwnDrillHours/${filter}`;
    return fetcher.get<ApsModels.ICredDashboardDrillDto>(url);
  };

  getOwnLmsIncompleteCourses = async () => {
    const url = "/api/CredDashboard/GetOwnLmsIncompleteCourses";
    return fetcher.get<number>(url);
  };

  getUserEmsCredentials = async (userId: number) => {
    const url = `/api/CredDashboard/GetUserEmsCredentials/${userId}`;
    return fetcher.get<ApsModels.ICredDashboardEmsItemDto[]>(url);
  };

  getUserExpiringCategory = async (userId: number) => {
    const url = `/api/CredDashboard/GetUserExpiringCategory/${userId}`;
    return fetcher.get<ApsModels.ICredDashboardExpiringCredentialsDto[]>(url);
  };

  getUserDrillHours = async (
    userId: number,
    filter: ApsModels.CredDashboardDrillTimeFilterEnum
  ) => {
    const url = `/api/CredDashboard/GetUserDrillHours/${userId}/${filter}`;
    return fetcher.get<ApsModels.ICredDashboardDrillDto>(url);
  };

  getUserLmsIncompleteCourses = async (userId: number) => {
    const url = `/api/CredDashboard/GetUserLmsIncompleteCourses/${userId}`;
    return fetcher.get<number>(url);
  };

  getOwnAssigned = async () => {
    const url = `/api/CredDashboard/GetOwnAssigned`;
    return fetcher.get<ApsModels.ICredDashboardAssignedDto[]>(url);
  };

  getUserAssigned = async (userId: number) => {
    const url = `/api/CredDashboard/GetUserAssigned/${userId}`;
    return fetcher.get<ApsModels.ICredDashboardAssignedDto[]>(url);
  };
}

const credDashboard = new CredDashboardService();
export default credDashboard;
