import ApsModels from "../../models";
import fetcher from "./Fetcher";

class DemoDataService {
  getInformation = async (deptId: number) => {
    const url = `/api/DemoData/GetInformation/${deptId}`;
    return fetcher.get<ApsModels.IDemoDataInformationOutputDto>(url);
  };

  loadAllData = async (deptId: number) => {
    const url = `/api/DemoData/LoadAllData/${deptId}`;
    return fetcher.get<ApsModels.IDemoDataInformationOutputDto>(url);
  };
}

const demoData = new DemoDataService();
export default demoData;
