import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ApsServices from "../../services";
import toastStore from "../../stores/ToastStore";
import FileDragNDrop from "../Common/FileDragNDrop";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import CommonSpinner from "../Common/CommonSpinner";
import commonService from "../../services/CommonService";
import ApsModels from "../../models";
import moment from "moment";
import FileSaver from "file-saver";
import CredentialRequirementDialog from "./CredentialRequirementDialog";
import { FgCheckbox } from "../Common/FormGroups";
import { useDepartmentId } from "../../stores/SystemStore";
import CredentialStatusCellLabel from "./CredentialStatusCellLabel";

export const EncodeSafeCodeForRule = (code: string) => {
  return code.replaceAll("(", "<lt<").replaceAll(")", ">gt>");
};

export const DecodeSafeCodeForRule = (code: string) => {
  return code.replaceAll("<lt<", "(").replaceAll(">gt>", ")");
};

function ViewCredential(props: RouteComponentProps<any>) {
  const departmentId = useDepartmentId();
  const credentialTakenId = props.match?.params?.credentialid;
  const [updateCourses, setUpdateCourses] = useState<"hide" | "show" | "fetch">(
    "hide"
  );
  const credTaken = useNfirsGetData(
    async () =>
      ApsServices.http.credCredential.getTakenCredential(
        credentialTakenId,
        updateCourses === "fetch"
      ),
    "Taken Credential"
  );

  useEffect(() => {
    if (departmentId.current > 0) {
      if (credentialTakenId) {
        if (departmentId.changed && departmentId.previous > 0) {
          props.history.push("/credentials");
          return; //exit
        }
        setFiles([]);
        credTaken.getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  useEffect(() => {
    if (credTaken.status === NfirsFetchStatus.Complete && credTaken.data) {
      if (
        credTaken.data.credCategoryTypeEnum ===
        ApsModels.CredCategoryTypeEnum.ExistingExternalCredential
      ) {
        //DO NOTHING FOR NOW
        //just in case, but this category type should not use this page
        // props.history.push(`/credential/${credTaken.data.id}`);
        // return;
      }

      const copy = { ...taken };
      credTaken.data.credTakenCredentialRequirementCategoryOutputDtos?.map(
        (categ) => {
          categ?.credTakenCredentialRequirementGroupOutputDtos?.map((req) => {
            req.credTakenCredentialRequirementOutputDtos?.map((c) => {
              copy[req.id] = c.emsTaken;
            });
          });
        }
      );
      setTaken(copy);
    }
    if (
      credTaken.status === NfirsFetchStatus.Failed &&
      credTaken.errorCode === 404
    ) {
      props.history.push("/credentials");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credTaken.status]);

  const [saving, setSaving] = useState(false);

  const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [taken, setTaken] = useState<{ [key: string]: boolean }>({});
  const [claims] = useState(commonService.friendlyClaims);

  // const getRequirements = () => {
  //   const list: ApsModels.ICredTakenCredentialRequirementsUpdateDto[] = [];
  //   credTaken.data?.credTakenCredentialRequirementCategoryOutputDtos?.map(
  //     (categ) => {
  //       categ.credTakenCredentialRequirementOutputDtos?.map((req) => {
  //         list.push({
  //           id: req?.id || null,
  //           concurrencyToken: req?.concurrencyToken || null,
  //           credCredentialRequirementId: req.credCredentialRequirementId,
  //           taken: taken[req.id] || false,
  //         } as ApsModels.ICredTakenCredentialRequirementsUpdateDto);
  //       });
  //     }
  //   );
  //   return list;
  // };

  const submit = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    setSaving(true);
    if (
      claims.credentials.canAddAll &&
      credTaken.data &&
      credTaken.data.userId !== commonService.userInfo.userId
    ) {
      formData.append(
        "jsonData",
        JSON.stringify({
          id: credTaken.data?.id || null,
          concurrencyToken: credTaken.data?.concurrencyToken || null,
          credCredentialId: credTaken.data?.credCredentialId,
          lookupStateId: credTaken.data.lookupStateId || 1,
          userId: credTaken.data.userId,
          credTakenCredentialRequirementsUpdateDtos: [], // [...getRequirements()],
          idOfFilesToDelete: filesToDelete,
          isAssigned: credTaken.data.isAssigned,
          identificationNumber: credTaken.data.identificationNumber,
          dateIssued: credTaken.data.dateIssued,
          renewalDate: credTaken.data.expirationDate,
          dueDate: null as any,
        } as ApsModels.ICredTakenCredentialAdminUpdateDto)
      );

      await ApsServices.http.credCredential
        .adminUploadTakenCredentialFiles(formData)
        .then(() => {
          toastStore.showToast("Credential Saved", "success");
          setSaving(false);
          navigateBack();
        })
        .catch((error) => {
          toastStore.showError("Failed Saving Credential", error);
          setSaving(false);
        });
      return;
    }

    formData.append(
      "jsonData",
      JSON.stringify({
        id: credTaken.data?.id || null,
        concurrencyToken: credTaken.data?.concurrencyToken || null,
        credCredentialId: credTaken.data?.credCredentialId,
        lookupStateId: credTaken.data?.lookupStateId || 1,
        credTakenCredentialRequirementsUpdateDtos: [], // [...getRequirements()],
        idOfFilesToDelete: filesToDelete,
        isAssigned: credTaken.data?.isAssigned,
        identificationNumber: credTaken.data?.identificationNumber,
        dateIssued: credTaken.data?.dateIssued,
        renewalDate: credTaken.data?.expirationDate,
        dueDate: null as any,
      } as ApsModels.ICredTakenCredentialUpdateDto)
    );

    await ApsServices.http.credCredential
      .ownUploadTakenCredentialFiles(formData)
      .then(() => {
        toastStore.showToast("Credential Saved", "success");
        setSaving(false);
        navigateBack();
      })
      .catch((error) => {
        toastStore.showError("Failed Saving Credential", error);
        setSaving(false);
      });
  };

  const navigateBack = () => {
    if (props.history.action != "POP") {
      props.history.goBack();
    } else {
      if (claims.credentials.canViewAll) {
        props.history.push(`/credentials/employee/${credTaken.data?.userId}`);
      } else {
        props.history.push("/credentials");
      }
    }
  };

  const canUpdate = () => {
    return (
      claims.course.canAddAll ||
      (credTaken.data &&
        credTaken.data.userId === commonService.userInfo.userId &&
        claims.credentials.canEditOwn)
    );
  };

  const [showReqDialog, setShowReqDialog] = useState<{
    id?: number;
    category: ApsModels.CredentialRequirementEnum;
    requirement: ApsModels.ICredTakenCredentialRequirementOutputDto;
  }>();

  const hasNoRequirement = () => {
    return (
      credTaken.data?.credTakenCredentialRequirementCategoryOutputDtos
        ?.length === 1 &&
      !!credTaken.data?.credTakenCredentialRequirementCategoryOutputDtos.find(
        (c) => c.id === -1
      )
    );
  };

  const getRules = (
    req: ApsModels.ICredTakenCredentialRequirementGroupOutputDto
  ) => {
    if (commonService.isNullOrWhitespace(req.rules)) {
      return `(${req.credTakenCredentialRequirementOutputDtos
        ?.map((x) => {
          return EncodeSafeCodeForRule(x.code);
        })
        .join("")})`;
    }
    return req.rules;
  };

  const isRenewable = () =>
    !!credTaken.data && credTaken.data.expiresInYears > 0;

  const getDateLabel = () => {
    if (credTaken.data?.status === ApsModels.CredStatusEnum.InProgress) {
      return "Assignment Due Date";
    }
    if (isRenewable()) {
      return "Expiration Date";
    }
    return "Effective Date";
  };

  useEffect(() => {
    let tmo: any;
    if (updateCourses === "fetch") {
      credTaken.getData();
    }
    if (updateCourses === "hide") {
      tmo = setTimeout(() => {
        setUpdateCourses("show");
      }, 5000);
    }
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCourses]);

  return (
    <>
      {showReqDialog && (
        <CredentialRequirementDialog
          data={{
            id: showReqDialog.id,
            credentialRequirementCategory: showReqDialog.category,
            requirement: showReqDialog.requirement,
          }}
          onClose={(done) => {
            if (done) {
              credTaken.getData();
            }
            setShowReqDialog(undefined);
          }}
        ></CredentialRequirementDialog>
      )}
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="h4 mb-0 font-size-18 text-uppercase">
                View Credential
              </span>
            </div>
            <div
              className={`${
                credTaken.status === NfirsFetchStatus.Complete
                  ? ""
                  : "display-none"
              }`}
            >
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  navigateBack();
                }}
              >
                Cancel and Close
              </button>

              {canUpdate() && (
                <>
                  <button
                    className="btn btn-primary"
                    type="button"
                    disabled={saving || hasNoRequirement()}
                    onClick={(e) => {
                      credentialTakenId &&
                        props.history.push(
                          `/credentials/edit/${credentialTakenId}`
                        );
                    }}
                  >
                    <span className="px-2">Edit</span>
                  </button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={saving || hasNoRequirement()}
                    onClick={(e) => {
                      submit();
                    }}
                  >
                    {saving ? "Saving..." : "Submit"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {credTaken.status === NfirsFetchStatus.InProgress && (
          <CommonSpinner></CommonSpinner>
        )}
        <div
          className={`row justify-content-md-center h-full flex-1 ${
            credTaken.status === NfirsFetchStatus.Complete ? "" : "display-none"
          }`}
        >
          {saving && <CommonSpinner overlay={true}></CommonSpinner>}
          <div className={`col-12 col-sm-12 col-lg-8 flex flex-col`}>
            <div className="card">
              <div className="card-body flex flex-col p-0">
                <div className="earn-renew-credential-header p-4 flex">
                  <div className="flex-1">
                    <h4 className="m-0">{credTaken.data?.credentialName}</h4>
                    <div>{credTaken.data?.credentialCode}</div>
                    <div>{credTaken.data?.departmentName}</div>
                  </div>
                  <div className="text-right">
                    <div>{credTaken.data?.employeeName}</div>
                    <div>{credTaken.data?.personnelId}</div>
                  </div>
                </div>
                <div className="p-4">
                  <div className="earn-renew-credential-progress">
                    <strong className="text-uppercase">
                      <span>Credential</span>{" "}
                      {credTaken.data && (
                        <CredentialStatusCellLabel
                          data={credTaken.data}
                          labelOnly={true}
                        ></CredentialStatusCellLabel>
                      )}
                    </strong>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <strong>Category</strong>
                        <div>{credTaken.data?.category}</div>
                      </div>
                      {credTaken?.data?.credCategoryTypeEnum ===
                        ApsModels.CredCategoryTypeEnum.Ems && (
                        <>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <strong>Hours Required</strong>
                            <div>
                              {commonService.toNumberWithComma(
                                credTaken.data.hoursNeeded
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <strong>Hours Completed</strong>
                            <div>
                              {commonService.toNumberWithComma(
                                credTaken.data.hoursTaken
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {credTaken?.data?.credCategoryTypeEnum !==
                        ApsModels.CredCategoryTypeEnum.Ems && (
                        <>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <strong>State Issued</strong>
                            <div>{credTaken.data?.stateIssued}</div>
                          </div>
                          {/* <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                            <strong>
                              Date Issued (or Assignment Due Date)
                            </strong>
                            <div>
                              {credTaken.data?.dateIssued
                                ? moment(credTaken.data?.dateIssued).format(
                                    "MM/DD/YYYY"
                                  )
                                : "MM/DD/YYYY"}
                            </div>
                          </div> */}
                        </>
                      )}
                      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <strong>{getDateLabel()}</strong>
                        <div>
                          {isRenewable() ? (
                            <>
                              {credTaken.data?.status ===
                              ApsModels.CredStatusEnum.InProgress ? (
                                <>
                                  {credTaken.data?.dueDate
                                    ? moment(credTaken.data?.dueDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "MM/DD/YYYY"}
                                </>
                              ) : (
                                <>
                                  {credTaken.data?.expirationDate
                                    ? moment(
                                        credTaken.data?.expirationDate
                                      ).format("MM/DD/YYYY")
                                    : "MM/DD/YYYY"}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {credTaken.data?.status ===
                              ApsModels.CredStatusEnum.InProgress ? (
                                <>
                                  {credTaken.data?.dueDate
                                    ? moment(credTaken.data?.dueDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "MM/DD/YYYY"}
                                </>
                              ) : (
                                <>
                                  {credTaken.data?.dateIssued
                                    ? moment(credTaken.data?.dateIssued).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "MM/DD/YYYY"}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-4">
                    {/* <strong>Instruction or Introduction: </strong>{" "} */}
                    {credTaken.data?.credentialInstructions}
                  </div>

                  <div className="pt-4">
                    <table className="table earn-renew-credential-table">
                      <tbody>
                        {!credTaken.data
                          ?.credTakenCredentialRequirementCategoryOutputDtos
                          ?.length && (
                          <tr>
                            <td>No Credential Requirements Found</td>
                          </tr>
                        )}
                        {credTaken.data?.credTakenCredentialRequirementCategoryOutputDtos?.map(
                          (reqCat, rcIdx) => (
                            <React.Fragment key={reqCat.id}>
                              <tr
                                className={`${
                                  hasNoRequirement() ? "text-danger" : ""
                                }`}
                              >
                                <td
                                  colSpan={5}
                                  className="requirement-row-header"
                                >
                                  <div className="flex flex-row flex-center">
                                    <div className="flex-1">
                                      <strong>{reqCat.name}</strong>
                                    </div>
                                    <div>
                                      {rcIdx === 0 &&
                                        credTaken.data?.status ===
                                          ApsModels.CredStatusEnum
                                            .InProgress && (
                                          <>
                                            {updateCourses !== "hide" && (
                                              <span>
                                                <i
                                                  title="Refresh"
                                                  className="fa fa-sync pointer btn-refresh"
                                                  onClick={(e) => {
                                                    if (
                                                      updateCourses === "show"
                                                    ) {
                                                      setUpdateCourses("fetch");
                                                    } else {
                                                      credTaken.getData();
                                                    }
                                                  }}
                                                ></i>
                                              </span>
                                            )}
                                            {updateCourses === "hide" && (
                                              <i style={{ color: "#aaa" }}>
                                                <strong>Working...</strong>
                                              </i>
                                            )}
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {reqCat.credTakenCredentialRequirementGroupOutputDtos
                                .filter(
                                  (x) =>
                                    x.credTakenCredentialRequirementOutputDtos
                                      .length > 0
                                )
                                .map((req) => (
                                  <React.Fragment key={req.id}>
                                    <tr key={req.id}>
                                      <td
                                        className="requirement-taken-column"
                                        style={{
                                          width: "20px",
                                        }}
                                      >
                                        {(reqCat.emsTakenRequirementOutputDtos?.find(
                                          (e) =>
                                            req.credTakenCredentialRequirementOutputDtos.find(
                                              (c) => e.id === c.id
                                            )
                                        )?.emsTaken ||
                                          req.taken) && (
                                          <i className="fa fa-check-square"></i>
                                        )}
                                        {!(
                                          reqCat.emsTakenRequirementOutputDtos?.find(
                                            (e) =>
                                              req.credTakenCredentialRequirementOutputDtos.find(
                                                (c) => e.id === c.id
                                              )
                                          )?.emsTaken || req.taken
                                        ) && (
                                          <FgCheckbox
                                            id={`chk${req.id}`}
                                            label=""
                                            data={taken[req.id]}
                                            onChange={(data) => {
                                              setTaken((p) => {
                                                const copy = { ...p };
                                                copy[req.id] = data || false;
                                                return copy;
                                              });
                                            }}
                                            disabled={true} //!canUpdate()}
                                          ></FgCheckbox>
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          paddingRight: 0,
                                          paddingLeft: 0,
                                        }}
                                      >
                                        {canUpdate() && (
                                          <>
                                            <RequirementByRule
                                              key={credTaken.status}
                                              rules={getRules(req)}
                                              items={req.credTakenCredentialRequirementOutputDtos.map(
                                                (ri) => {
                                                  return {
                                                    ...ri,
                                                    code: EncodeSafeCodeForRule(
                                                      ri.code
                                                    ),
                                                  };
                                                }
                                              )}
                                              credId={credTaken?.data?.id}
                                              clickable={true}
                                              onClick={(c) => {
                                                if (
                                                  credTaken.data &&
                                                  credTaken.data
                                                    ?.credCategoryTypeEnum ===
                                                    ApsModels
                                                      .CredCategoryTypeEnum.Ems
                                                ) {
                                                  props.history.push(
                                                    `/courses/${c.id}`
                                                  );
                                                  return;
                                                }
                                                setShowReqDialog({
                                                  id: credTaken.data?.id,
                                                  category:
                                                    c.credentialRequirementEnum,
                                                  requirement: { ...c },
                                                });
                                              }}
                                            ></RequirementByRule>
                                            {/* {!req.rules ? (
                                              <>
                                                {req.credTakenCredentialRequirementOutputDtos.map(
                                                  (c) => (
                                                    <span
                                                      key={c.id}
                                                      className="text-primary pointer"
                                                      style={{
                                                        paddingLeft: "5px",
                                                      }}
                                                      onClick={(e) => {
                                                        if (
                                                          credTaken.data &&
                                                          credTaken.data
                                                            ?.credCategoryTypeEnum ===
                                                            ApsModels
                                                              .CredCategoryTypeEnum
                                                              .Ems
                                                        ) {
                                                          props.history.push(
                                                            `/CourseRecord/${c.id}`
                                                          );
                                                          return;
                                                        }
                                                        setShowReqDialog({
                                                          id: credTaken.data
                                                            ?.id,
                                                          category:
                                                            c.credentialRequirementEnum,
                                                          requirement: {
                                                            ...c,
                                                          },
                                                        });
                                                      }}
                                                    >
                                                      {c.name}{" "}
                                                      {c.code
                                                        ? `(${c.code})`
                                                        : ""}
                                                    </span>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <RequirementByRule
                                                key={credTaken.status}
                                                rules={req.rules}
                                                items={
                                                  req.credTakenCredentialRequirementOutputDtos
                                                }
                                                credId={credTaken?.data?.id}
                                                clickable={true}
                                                onClick={(c) => {
                                                  if (
                                                    credTaken.data &&
                                                    credTaken.data
                                                      ?.credCategoryTypeEnum ===
                                                      ApsModels
                                                        .CredCategoryTypeEnum
                                                        .Ems
                                                  ) {
                                                    props.history.push(
                                                      `/CourseRecord/${c.id}`
                                                    );
                                                    return;
                                                  }
                                                  setShowReqDialog({
                                                    id: credTaken.data?.id,
                                                    category:
                                                      c.credentialRequirementEnum,
                                                    requirement: { ...c },
                                                  });
                                                }}
                                              ></RequirementByRule>
                                            )} */}
                                          </>
                                        )}
                                        {!canUpdate() && (
                                          <span>
                                            <RequirementByRule
                                              rules={getRules(req)}
                                              items={req.credTakenCredentialRequirementOutputDtos.map(
                                                (ri) => {
                                                  return {
                                                    ...ri,
                                                    code: EncodeSafeCodeForRule(
                                                      ri.code
                                                    ),
                                                  };
                                                }
                                              )}
                                              credId={credTaken?.data?.id}
                                            ></RequirementByRule>
                                          </span>
                                        )}
                                      </td>
                                      {/* <td className="text-right">
                                        {req.credTakenCredentialRequirementOutputDtos?.map(
                                          (c) => (
                                            <div
                                              key={c.id}
                                              className="nowrap pb-2"
                                            >
                                              {!!c.code && (
                                                <span className="alert no-wrap alert-primary px-1 py-0 text-center mr-2">
                                                  {c.code}
                                                </span>
                                              )}
                                              <span className="alert alert-secondary px-1 py-0 text-center mr-2">
                                                {c.dateCompleted
                                                  ? moment(
                                                      c.dateCompleted
                                                    ).format("MM/DD/YYYY")
                                                  : "MM/DD/YYYY"}
                                              </span>
                                              <span className="alert alert-secondary px-1 py-0 text-center nowrap">
                                                {commonService.toNumberWithComma(
                                                  c.hours
                                                )}
                                                {c.hours > 1 ? " hrs" : " hr"}
                                              </span>
                                              {c.credFileDtos?.length > 0 && (
                                                <span className="cred-req-attachments ml-2">
                                                  <Dropdown
                                                    drop="down"
                                                    style={{
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    <Dropdown.Toggle
                                                      variant="outline-light"
                                                      id="dropdownFiles"
                                                      style={{
                                                        padding: 0,
                                                      }}
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faPaperclip}
                                                      ></FontAwesomeIcon>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                      align={"right"}
                                                    >
                                                      {c.credFileDtos.map(
                                                        (cf) => (
                                                          <Dropdown.Item
                                                            key={cf.id}
                                                            onClick={() => {
                                                              ApsServices.http.credCredential
                                                                .downloadTakenCredentialRequirementCertificate(
                                                                  c.id,
                                                                  credTaken.data
                                                                    ?.id as any,
                                                                  cf.id
                                                                )
                                                                .then(
                                                                  (data) => {
                                                                    const file =
                                                                      commonService.b64toBlob(
                                                                        data.fileContents,
                                                                        "text/plain"
                                                                      );
                                                                    FileSaver.saveAs(
                                                                      file,
                                                                      data.fileDownloadName
                                                                    );
                                                                  }
                                                                )
                                                                .catch(
                                                                  (error) => {
                                                                    toastStore.showError(
                                                                      "Failed to Download File",
                                                                      error
                                                                    );
                                                                  }
                                                                );
                                                            }}
                                                          >
                                                            {cf.fileName}
                                                          </Dropdown.Item>
                                                        )
                                                      )}
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </span>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </td> */}
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>

                  {!hasNoRequirement() && (
                    <div className="pt-2 row">
                      <div className="mb-3 col-12 col-sm-12 col-lg-6">
                        <label>Attach Certificate of Completion</label>
                        <div>
                          <FileDragNDrop
                            label="Add File"
                            disabled={saving}
                            onChange={(fileList) => {
                              if (fileList.length > 0) {
                                const list = [...files];
                                let cnt = 0;
                                while (cnt < fileList.length) {
                                  const file = fileList[cnt];
                                  list.push(file);
                                  cnt++;
                                }
                                setFiles(list);
                              }
                            }}
                          />
                        </div>
                        <div>
                          {credTaken.data?.certificateFiles?.map((cf) => (
                            <span
                              key={cf.id}
                              className="alert alert-secondary m-0 mt-2 flex flex-center"
                            >
                              <i
                                title="Download File"
                                className="mr-2 fa fa-download text-primary pointer"
                                onClick={() => {
                                  if (credTaken.data?.id) {
                                    ApsServices.http.credCredential
                                      .downloadTakenCredentialCertificate(
                                        credTaken.data.id,
                                        cf.id
                                      )
                                      .then((data) => {
                                        const file = commonService.b64toBlob(
                                          data.fileContents,
                                          "text/plain"
                                        );
                                        FileSaver.saveAs(
                                          file,
                                          data.fileDownloadName
                                        );
                                      })
                                      .catch((error) => {
                                        toastStore.showError(
                                          "Failed to Download File",
                                          error
                                        );
                                      });
                                  }
                                }}
                              ></i>
                              <span className="flex-1 overflow-hidden">
                                {filesToDelete.includes(cf.id) ? (
                                  <s className="text-danger">
                                    <i>{cf.fileName}</i>
                                  </s>
                                ) : (
                                  <span>{cf.fileName}</span>
                                )}
                              </span>
                              {filesToDelete.includes(cf.id) ? (
                                <i
                                  title="Undo"
                                  className="ml-2 fa fa-undo text-primary pointer"
                                  onClick={() => {
                                    setFilesToDelete(
                                      filesToDelete.filter((f) => f !== cf.id)
                                    );
                                  }}
                                ></i>
                              ) : (
                                <i
                                  title="Delete File"
                                  className="ml-2 fa fa-trash text-danger pointer"
                                  onClick={() => {
                                    setFilesToDelete([...filesToDelete, cf.id]);
                                  }}
                                ></i>
                              )}
                            </span>
                          ))}
                          {files?.map((f, i) => (
                            <span
                              key={i}
                              className="alert alert-info p-2 px-3 m-0 mt-2 flex flex-center"
                            >
                              <span className="flex-1 overflow-hidden">
                                {f.name}
                              </span>
                              {!saving && (
                                <i
                                  title="Remove File"
                                  className="mr-1 fa fa-times pointer"
                                  onClick={() => {
                                    const list = [...files];
                                    list.splice(i, 1);
                                    setFiles(list);
                                  }}
                                ></i>
                              )}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    {/* FOR TESTING ONLY */}
                    <label>Notifications (For APS Admin Only)</label>
                    <div
                      className="p-2"
                      style={{
                        backgroundColor: "#efefef",
                        maxHeight: "500px",
                        overflow: "auto",
                        minHeight: "30px",
                      }}
                    >
                      {(credTaken.data?.credNotificationTriggerDtos?.length
                        ? credTaken.data?.credNotificationTriggerDtos
                        : []
                      ).map((data, i) => (
                        <div key={i}>
                          <JsonViewer obj={data} noSep={true}></JsonViewer>
                          <div className="pt-4"></div>
                          <hr
                            className="my-4 pb-4"
                            style={{
                              borderColor: "#777",
                              borderWidth: "2px",
                            }}
                          />
                        </div>
                      ))}
                      {/* <textarea
                        className="w-100 form-control"
                        style={{
                          backgroundColor: "#eee",
                        }}
                        readOnly={true}
                        rows={10}
                        value={JSON.stringify(
                          credTaken.data?.credNotificationTriggerDtos || [],
                          undefined,
                          2
                        )}
                      ></textarea> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const RequirementByRule = (props: {
  rules: string;
  items: ApsModels.ICredTakenCredentialRequirementOutputDto[];
  clickable?: boolean;
  onClick?: (req: ApsModels.ICredTakenCredentialRequirementOutputDto) => void;
  credId?: any;
}) => {
  const [uid] = useState(commonService.getUniqueId());
  const [ready, setReady] = useState(false);
  const [str, setStr] = useState("");
  useEffect(() => {
    let tmpRules = props.rules === "()" ? "(NORULE)" : props.rules;
    if (props.items.length === 0) {
      tmpRules = "";
    }
    let tmp = tmpRules
      .replaceAll(
        "(",
        `<div class="rule-group ${
          props.items.length === 1 ? "single-requirement" : ""
        } c${uid}">`
      )
      .replaceAll(")", "</div>");
    props.items.forEach((i) => {
      tmp = tmp.replace(
        props.rules === "()" ? "NORULE" : i.code,
        `<span class="${i.dateCompleted ? "taken" : ""}">
          <span id="___req___${i.id}" class="${
          props.clickable ? "pointer text-primary" : ""
        }">
          ${i.name} ${i.code ? `(${DecodeSafeCodeForRule(i.code)})` : ""}
          </span>
          <span class="text-center text-secondary">${
            i.dateCompleted
              ? moment(i.dateCompleted).format("MM/DD/YYYY")
              : "MM/DD/YYYY"
          }</span>
          <span class="text-center text-secondary"> 
            ${commonService.toNumberWithComma(i.hours)}
            ${i.hours > 1 ? " hours" : " hour"}
         </span>
         <span class="text-center text-secondary ${
           i.credFileDtos?.length > 0 ? "has-files" : ""
         }"> 
          <span class="dropdown" id="ddcon${i.id}">
            <button role="button" id="dd${i.id}" 
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-paperclip"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dd${
              i.id
            }" id="ddmenu${i.id}">
              ${i.credFileDtos
                ?.map(
                  (f) =>
                    `<span class="pointer dropdown-item" id="file${f.id}">${f.fileName}</span>`
                )
                .join("")}
            </div>            
          </span>
         </span>
        </span>`
      );
    });
    tmp = tmp
      .replaceAll("{OR}", `<strong class="rule-or"></strong>`) //OR
      .replaceAll("{AND}", `<strong class="rule-and"></strong>`); //AND

    setStr(tmp);

    setTimeout(() => {
      setReady(true);
    }, 500);

    const onDocumentClick = () => {
      props.items.forEach((i) => {
        const ddmenu = document.getElementById(`ddmenu${i.id}`);
        if (ddmenu) {
          ddmenu.classList.remove("show");
        }
      });
    };
    document.addEventListener("click", onDocumentClick);
    return () => {
      document.removeEventListener("click", onDocumentClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ready) {
      document.querySelectorAll(`div.c${uid}`)?.forEach((el, i) => {
        let hasDiv = false;
        for (let index = 0; index < el.children.length; index++) {
          const chEl = el.children.item(index);
          if (chEl && chEl?.className === "rule-or") {
            el.className = `${el.className} group-or`;
          } else if (chEl && chEl?.className === "rule-and") {
            el.className = `${el.className} group-and`;
          }
          if (!hasDiv && chEl) {
            hasDiv = chEl.nodeName === "DIV";
          }
        }
        if (hasDiv) {
          el.className = `${el.className} has-div`;
        }

        if (el.nodeName === "DIV" && el.children.length > 0) {
          if (
            el.querySelectorAll(":scope > .rule-and").length > 0 &&
            el.querySelectorAll(":scope > .taken").length ===
              el.querySelectorAll(":scope > span").length
          ) {
            el.className = `${el.className} taken`;
          } else if (
            el.querySelectorAll(":scope > .rule-or").length > 0 &&
            el.querySelectorAll(":scope > .taken").length > 0
          ) {
            el.className = `${el.className} taken`;
          }
        }
      });

      props.items.forEach((i) => {
        document
          .getElementById(`___req___${i.id}`)
          ?.addEventListener("click", (e) => props.onClick && props.onClick(i));
      });

      props.items.forEach((i) => {
        const onDDClick = (e: any) => {
          if (i.credFileDtos?.length > 0) {
            const ddmenu = document.getElementById(`ddmenu${i.id}`);
            if (ddmenu) {
              ddmenu.classList.add("show");
              e.preventDefault();
              e.stopPropagation();
            }
          }
        };

        document
          .getElementById(`dd${i.id}`)
          ?.addEventListener("click", onDDClick);

        i.credFileDtos?.forEach((f) => {
          const onDownloadFile = (e: any) => {
            e.preventDefault();

            if (i.emsTaken) {
              ApsServices.http.credCourseRecord
                .downloadTakenCourseCertificate(i.id, f.id)
                .then((data) => {
                  const file = commonService.b64toBlob(
                    data.fileContents,
                    "text/plain"
                  );
                  FileSaver.saveAs(file, data.fileDownloadName);
                })
                .catch((error) => {
                  toastStore.showError("Failed to Download File", error);
                });
            } else {
              ApsServices.http.credCredential
                .downloadTakenCredentialRequirementCertificate(
                  i.id,
                  props.credId,
                  f.id
                )
                .then((data) => {
                  const file = commonService.b64toBlob(
                    data.fileContents,
                    "text/plain"
                  );
                  FileSaver.saveAs(file, data.fileDownloadName);
                })
                .catch((error) => {
                  toastStore.showError("Failed to Download File", error);
                });
            }
          };
          document
            .getElementById(`file${f.id}`)
            ?.addEventListener("click", onDownloadFile);
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  return (
    <div
      className="rule-group-root"
      id={uid}
      dangerouslySetInnerHTML={{
        __html: str,
      }}
    ></div>
  );
};

const JsonViewer = (props: { obj: any; noSep?: boolean }) => {
  return (
    <>
      <table className="table table-sm table-bordered mb-0">
        <tbody>
          {Object.getOwnPropertyNames(props.obj).map((nm) => {
            if (Array.isArray(props.obj[`${nm}`])) {
              const arr: any[] = props.obj[`${nm}`];
              return (
                <React.Fragment key={nm}>
                  {arr.map((data, i) => (
                    <React.Fragment key={i}>
                      {i === 0 && (
                        <tr>
                          <td colSpan={2}>{nm}</td>
                        </tr>
                      )}
                      <tr>
                        <td></td>
                        <td>
                          <JsonViewer obj={data} />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={nm}>
                {Object.prototype.toString.call(props.obj[`${nm}`]) ===
                "[object Object]" ? (
                  <tr>
                    <td>{nm}</td>
                    <td>
                      <div className="p-1">
                        {<JsonViewer obj={props.obj[`${nm}`]}></JsonViewer>}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>{nm}</td>
                    <td>{String(props.obj[`${nm}`])}</td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {!props.noSep && <hr />}
    </>
  );
};

export default ViewCredential;
