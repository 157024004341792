import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
  Highlighter,
  Menu,
  MenuItem,
  Typeahead,
} from "react-bootstrap-typeahead";
import toastStore from "../../stores/ToastStore";

interface IAdvancedDropdown {
  domId: string;
  groups?: {
    groupId?: any;
    groupLabel?: string;
    groupSelectable?: boolean;
  }[];
  subGroups?: {
    groupId?: any;
    subGroupId?: any;
    subGroupLabel?: string;
    subGroupSelectable?: boolean;
  }[];
  options: {
    groupId?: any;
    subGroupId?: any;
    label: string;
    value: any;
    originalObject: any;
    isFavorite?: boolean;
  }[];
  value: any;
  onChange: (val: any) => void;
  favoritesHandler?: {
    add: (fav: any) => Promise<any[]>;
    remove: (fav: any) => Promise<any[]>;
    afterUpdate: (newList: any) => void;
  };
  searchPlaceholder?: string;
  disabledOptionValues?: any[];
}

function AdvancedDropdown(props: IAdvancedDropdown) {
  const refPropertUse = useRef<any>();

  const [selectedValueDisplay, setSelectedValueDisaply] = useState<string>();
  useEffect(() => {
    let hasMatch = false;
    if (props.value) {
      props.options.find((o) => {
        if (o.value === props.value) {
          setSelectedValueDisaply(o.label);
          hasMatch = true;
          return true;
        }
      });
    }
    if (!hasMatch) {
      setSelectedValueDisaply(undefined);
    }
  }, [props.value, props.options]);

  const [showFavsOnly, setShowFavsOnly] = useState(false);
  const [markingFavorites, setMarkingFavorites] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleMarkingFavorite = (id: number, val: boolean) => {
    setMarkingFavorites((data) => {
      const copy = { ...data };
      copy[id] = val;
      return {
        ...copy,
      };
    });
  };

  const [optionsLoaded, setOptionsLoaded] = useState(false);
  useEffect(() => {
    if (!optionsLoaded && props.options.length > 0) {
      setOptionsLoaded(true);
      setShowFavsOnly(props.options.filter((o) => o.isFavorite).length > 0);
    }
  }, [props.options]);

  return (
    <div className="advanced-dropdown">
      {selectedValueDisplay && (
        <div className="advanced-dropdown-selected-item">
          <label className="typeaheadSelectedValueLabel">
            <span>{selectedValueDisplay}</span>
            <i
              className="fa fa-times"
              onClick={() => {
                props.onChange(null);
              }}
            ></i>
          </label>
        </div>
      )}
      {optionsLoaded && (
        <Typeahead
          maxResults={props?.options?.length || 100}
          className={
            props?.favoritesHandler
              ? "typeaheadWithFavorites"
              : "typeaheadNoFavorites"
          }
          id={props.domId}
          labelKey="label"
          placeholder={props.searchPlaceholder || "Search..."}
          onChange={(data) => {
            if (data && data.length > 0) {
              const item = data[0];
              (refPropertUse.current as any).clear();
              (refPropertUse.current as any).blur();
              props.onChange(item.value);
            }
          }}
          options={props.options}
          ref={refPropertUse}
          renderMenu={(results, menuProps, state) => {
            const groups = props.groups || [{ groupId: 0 }];
            const subGroups = props.subGroups || [
              { groupId: 0, subGroupId: 0 },
            ];

            const options: JSX.Element[] = [];
            let index = 0;
            groups.forEach((g) => {
              const match = results.find((o) => o.groupId === g.groupId);
              if (!match) {
                return;
              }

              let hasLabel = false;

              if (g.groupLabel) {
                const filtered = results.filter(
                  (o) =>
                    o.groupId === g.groupId &&
                    (!showFavsOnly || (showFavsOnly && o.isFavorite))
                );

                if (filtered.length === 0) {
                  return;
                }

                if (index !== 0) {
                  options.push(<Menu.Divider key={`dvd${g.groupId}`} />);
                }

                options.push(
                  <Menu.Header key={`g${g.groupId}`} classID="grouplabel">
                    {g.groupLabel}
                  </Menu.Header>
                );
                hasLabel = true;
              }
              subGroups
                .filter((sg) => sg.groupId === g.groupId)
                .forEach((sg) => {
                  const filtered = results.filter(
                    (o) =>
                      o.groupId === sg.groupId &&
                      o.subGroupId === sg.subGroupId &&
                      (!showFavsOnly || (showFavsOnly && o.isFavorite))
                  );

                  if (filtered.length === 0) {
                    return;
                  }

                  let hasSubGroup = false;
                  if (sg.subGroupLabel) {
                    options.push(
                      <Menu.Header
                        key={`sg${sg.subGroupId}`}
                        classID="subgrouplabel"
                      >
                        {sg.subGroupLabel}
                      </Menu.Header>
                    );
                    hasSubGroup = true;
                  }

                  filtered.forEach((i) => {
                    options.push(
                      <MenuItem
                        key={index}
                        option={i}
                        position={index}
                        className={`${hasLabel ? "childlabel" : ""}  ${
                          hasSubGroup ? "subchildlabel" : ""
                        }`}
                        disabled={
                          props.disabledOptionValues
                            ? props.disabledOptionValues.indexOf(i.value) > -1
                            : false
                        }
                      >
                        <span>
                          <Highlighter search={state.text}>
                            {i.label}
                          </Highlighter>
                        </span>

                        {props.favoritesHandler && (
                          <i
                            className={`fa ${
                              markingFavorites[i.value]
                                ? "fa-spin fa-spinner"
                                : "fa-star"
                            } ${
                              !markingFavorites[i.value] && i.isFavorite
                                ? "is-favorite"
                                : ""
                            }`}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();

                              if (markingFavorites[i.value]) {
                                return;
                              }

                              toggleMarkingFavorite(i.value, true);
                              if (i.isFavorite) {
                                props.favoritesHandler
                                  ?.remove({
                                    ...i.originalObject,
                                    id: i.value,
                                  })
                                  .then((list) => {
                                    props.favoritesHandler?.afterUpdate(list);
                                    toggleMarkingFavorite(i.value, false);
                                  })
                                  .catch((error) => {
                                    toastStore.showError(
                                      "Failed Removing Favorite",
                                      error
                                    );
                                    toggleMarkingFavorite(i.value, false);
                                  });
                              } else {
                                props.favoritesHandler
                                  ?.add({
                                    ...i.originalObject,
                                    id: i.value,
                                  })
                                  .then((list) => {
                                    props.favoritesHandler?.afterUpdate(list);
                                    toggleMarkingFavorite(i.value, false);
                                  })
                                  .catch((error) => {
                                    toastStore.showError(
                                      "Failed Adding Favorite",
                                      error
                                    );
                                    toggleMarkingFavorite(i.value, false);
                                  });
                              }
                            }}
                          ></i>
                        )}
                      </MenuItem>
                    );
                    index += 1;
                  });
                });
            });

            //   index = 0;
            //   const regions = [
            //     {
            //       name: "favorites",
            //       items: results,
            //     },
            //   ];
            //   const items = regions.map((region, i) => (
            //     <React.Fragment key={region.name}>
            //       {index !== 0 && <Menu.Divider />}
            //       {region.items
            //         .filter((i) => !!i.value)
            //         .map((i) => {
            //           const item = (
            //             <MenuItem key={index} option={i} position={index}>
            //               <span>
            //                 <Highlighter search={state.text}>{i.label}</Highlighter>
            //               </span>
            //             </MenuItem>
            //           );
            //           index += 1;
            //           return item;
            //         })}
            //     </React.Fragment>
            //   ));

            return (
              <>
                {props.favoritesHandler && (
                  <div className="my-favorites-menu">
                    <div>
                      <button
                        type="button"
                        className={`btn btn-sm ${
                          showFavsOnly ? "btn-primary" : "btn-outline-secondary"
                        }`}
                        onClick={(event) => setShowFavsOnly(true)}
                      >
                        My Favorites
                      </button>
                      <button
                        type="button"
                        className={`btn btn-sm ${
                          !showFavsOnly
                            ? "btn-primary"
                            : "btn-outline-secondary"
                        }`}
                        onClick={(event) => setShowFavsOnly(false)}
                      >
                        All
                      </button>
                    </div>
                  </div>
                )}
                <Menu {...menuProps}>{options}</Menu>
              </>
            );
          }}
        />
      )}
    </div>
  );
}

export default AdvancedDropdown;
