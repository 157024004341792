import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSFireIgnition {
  get = async (reportId: any) => {
    const url = `/api/NfirsFireIgnition/${reportId}`;
    return fetcher.get<ApsModels.INfirsFireIgnitionOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsFireIgnitionInputDto) => {
    const url = `/api/NfirsFireIgnition`;
    return fetcher.post<ApsModels.INfirsFireIgnitionOutputDto>(url, data);
  };
}

const nfirsFireIgnition = new NFIRSFireIgnition();
export default nfirsFireIgnition;
