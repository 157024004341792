import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import DateTimePickerV2 from "../../../Common/DateTimePickerV2";
import FormError from "../../../Common/FormError";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";

function NFIRSWildlandPeopleInvolved(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsWildlandPeopleInvolved.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsWildlandPeopleInvolvedInputDto>({
    objectName: "People Involved",
    nfirsSectionTab: "Wildland Fire/PeopleInvolved",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsWildlandPeopleInvolvedInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsWildlandPeopleInvolved
      .get(props.id)
      .then((data) => {
        const mdl = { ...model.new, ...data, reportId: Number(props.id) };
        initModels(mdl);
        setValuesFromModel(data);
        setProgress({ loading: false });
        props.onChange(mdl);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting People Involved", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Wildland Fire",
    "PeopleInvolved",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Wildland Fire", "PeopleInvolved", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const fuelModels = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsFuelModelEntries,
    "Fuel Models at Origin"
  );
  const personResponsibleList = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsPersonResponsibleForFireEntries,
    "Person Responsible List"
  );
  const activities = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsActivityOfPersonInvolvedEntries,
    "Activities of Person Involved"
  );

  const privateTypes = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsPrivateTypeEntries,
    "Private Types"
  );
  const publicTypes = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsPublicTypeEntries,
    "Public Types"
  );

  useEffect(() => {
    setProgress({ loading: true });
    fuelModels.getData();
    personResponsibleList.getData();
    activities.getData();
    privateTypes.getData();
    publicTypes.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      fuelModels.status === NfirsFetchStatus.Complete &&
      personResponsibleList.status === NfirsFetchStatus.Complete &&
      activities.status === NfirsFetchStatus.Complete &&
      privateTypes.status === NfirsFetchStatus.Complete &&
      publicTypes.status === NfirsFetchStatus.Complete
    ) {
      setProgress({ loading: true });
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activities.status,
    personResponsibleList.status,
    fuelModels.status,
    privateTypes.status,
    publicTypes.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    undetermined: {
      displayName: "Undetermined",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsPrivateTypeEntryId: {
      displayName: "Private",
      validation: {
        required: false,
      },
    },
    privatePercentage: {
      displayName: "Private Percentage",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsPublicTypeEntryId: {
      displayName: "Public",
      validation: {
        required: false,
      },
    },
    publicPercentage: {
      displayName: "Public Percentage",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    wildlandFederalAgencyCode: {
      displayName: "Wildland Federal Agency Code",
      validation: {
        required: false,
      },
    },
    nfirsFuelModelEntryId: {
      displayName: "NFDRS Fuel Model at Origin",
      validation: {
        required: false,
      },
    },
    nfirsPersonResponsibleForFireEntryId: {
      displayName: "Person Responsible for Fire",
      validation: {
        required: false,
      },
    },
    maleFemaleEnum: {
      displayName: "Gender of Person Involved",
      validation: {
        required: false,
      },
    },
    age: {
      displayName: "Age",
      validation: {
        required: false,
      },
    },
    dateOfBirth: {
      displayName: "Date of Birth",
      validation: {
        required: false,
      },
    },
    nfirsActivityOfPersonInvolvedEntryId: {
      displayName: "Activity of Person Involved",
      validation: {
        required: false,
      },
    },
    _dateOfBirth: { ...commonService.forms.datePicker },
  });

  const [resetDate, setResetDate] = useState(Math.random() * 12345689);

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      {!progress.loading && (
        <form
          className={`row ${progress.loading ? "display-none" : ""}`}
          onSubmit={handleSubmit(submit)}
          ref={formRef}
        >
          <div className="col-sm-12 mb-2">
            <strong>People Involved</strong>
          </div>
          <div className="col-sm-12">
            <div className="row mt-2">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-4 col-md-3">
                    <FgInput
                      id="undetermined"
                      label="Undetermined"
                      placeHolder="Undetermined"
                      registeredField={registry.undetermined}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          undetermined: Number(data) || (undefined as any),
                        })
                      }
                      type="number"
                      step="1"
                      suffix="%"
                    ></FgInput>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-4 col-md-3">
                    <FgSelect
                      id="nfirsPrivateTypeEntryId"
                      label="Private"
                      selectMessage="- Select Private Type -"
                      registeredField={registry.nfirsPrivateTypeEntryId}
                      formState={formState}
                      options={(privateTypes?.data || []).map((item) => {
                        return {
                          value: item.id,
                          label: `${item.externalId}. ${item.description}`,
                        };
                      })}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          nfirsPrivateTypeEntryId:
                            Number(data) || (undefined as any),
                        })
                      }
                    ></FgSelect>
                  </div>
                  <div className="col-sm-4 col-md-3">
                    <FgInput
                      id="privatePercentage"
                      label="Percentage"
                      placeHolder="Percentage"
                      registeredField={registry.privatePercentage}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          privatePercentage: Number(data) || (undefined as any),
                        })
                      }
                      type="number"
                      step="1"
                      suffix="%"
                    ></FgInput>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-4 col-md-3">
                    <FgSelect
                      id="nfirsPublicTypeEntryId"
                      label="Public"
                      selectMessage="- Select Public Type -"
                      registeredField={registry.nfirsPublicTypeEntryId}
                      formState={formState}
                      options={(publicTypes?.data || []).map((item) => {
                        return {
                          value: item.id,
                          label: `${item.externalId}. ${item.description}`,
                        };
                      })}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          nfirsPublicTypeEntryId:
                            Number(data) || (undefined as any),
                        })
                      }
                    ></FgSelect>
                  </div>
                  <div className="col-sm-4 col-md-3">
                    <FgInput
                      id="publicPercentage"
                      label="Percentage"
                      placeHolder="Percentage"
                      registeredField={registry.publicPercentage}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          publicPercentage: Number(data) || (undefined as any),
                        })
                      }
                      type="number"
                      step="1"
                      suffix="%"
                    ></FgInput>
                  </div>
                  <div className="col-sm-4 col-md-3">
                    <FgInput
                      id="wildlandFederalAgencyCode"
                      label="Wildland Federal Agency Code (if applicable)"
                      placeHolder="Wildland Federal Agency Code"
                      registeredField={registry.wildlandFederalAgencyCode}
                      formState={formState}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          wildlandFederalAgencyCode: data,
                        })
                      }
                      type="number"
                      step="1"
                    ></FgInput>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FgSelect
                  id="nfirsFuelModelEntryId"
                  label="NFDRS Fuel Model at Origin"
                  selectMessage="- Select NFDRS Fuel Model at Origin -"
                  registeredField={registry.nfirsFuelModelEntryId}
                  formState={formState}
                  options={(fuelModels?.data || []).map((item) => {
                    return {
                      value: item.id,
                      label: `${item.externalId}. ${item.description}`,
                    };
                  })}
                  onChange={(data) =>
                    onModelChange({
                      ...model.new,
                      nfirsFuelModelEntryId: Number(data) || (undefined as any),
                    })
                  }
                ></FgSelect>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6">
                <FgSelect
                  id="nfirsPersonResponsibleForFireEntryId"
                  label="Person Responsible for Fire"
                  selectMessage="- Select Person Responsible for Fire -"
                  registeredField={
                    registry.nfirsPersonResponsibleForFireEntryId
                  }
                  formState={formState}
                  options={(personResponsibleList?.data || []).map((item) => {
                    return {
                      value: item.id,
                      label: `${item.externalId}. ${item.description}`,
                    };
                  })}
                  onChange={(data) =>
                    onModelChange({
                      ...model.new,
                      nfirsPersonResponsibleForFireEntryId:
                        Number(data) || (undefined as any),
                    })
                  }
                ></FgSelect>
              </div>
              <div className="col-sm-6 col-md-3">
                <FgSelect
                  id="maleFemaleEnum"
                  label="Gender of Person Involved"
                  selectMessage="- Select Gender of Person Involved -"
                  registeredField={registry.maleFemaleEnum}
                  formState={formState}
                  options={[
                    {
                      label: "Female",
                      value: ApsModels.NfirsJuvenileMaleFemaleEnum.Female,
                    },
                    {
                      label: "Male",
                      value: ApsModels.NfirsJuvenileMaleFemaleEnum.Male,
                    },
                  ]}
                  onChange={(data) =>
                    onModelChange({
                      ...model.new,
                      maleFemaleEnum: Number(data) || (undefined as any),
                    })
                  }
                ></FgSelect>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 col-md-2">
                <FgInput
                  id="age"
                  label="Age"
                  placeHolder="Age"
                  registeredField={registry.age}
                  formState={formState}
                  onChange={(data) => {
                    const val = Number(data) || (undefined as any);
                    if (val) {
                      setResetDate(Math.random() * 12345689);
                      setValue("dateOfBirth", undefined);
                      setValue("_dateOfBirth", undefined);
                      onModelChange({
                        ...model.new,
                        age: val,
                        dateOfBirth: null,
                        _dateOfBirth: null,
                      } as any);
                    } else {
                      onModelChange({
                        ...model.new,
                        age: val,
                      });
                    }
                  }}
                  type="number"
                  step="1"
                ></FgInput>
              </div>
              <div className="col-sm-1 col-md-1 text-center">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  OR
                </label>
              </div>
              <div className="col-sm-4 col-md-3 pb-2">
                <label>Date of Birth</label>

                {[resetDate].map((i) => (
                  <DateTimePickerV2
                    key={i}
                    onChange={(data, invalid) => {
                      const val = data
                        ? moment(data).format("YYYY-MM-DD")
                        : (null as any);
                      if (!invalid && data) {
                        setValue("age", undefined);
                        onModelChange({
                          ...model.new,
                          dateOfBirth: val,
                          _dateOfBirth: invalid,
                          age: undefined,
                        } as any);
                      } else {
                        onModelChange({
                          ...model.new,
                          dateOfBirth: val,
                          _dateOfBirth: invalid,
                        } as any);
                      }
                      setValue("_dateOfBirth", invalid);
                    }}
                    data={model.new.dateOfBirth}
                    dateOnly={true}
                    style={{ width: "100%" }}
                  />
                ))}
                <FormError
                  field={registry._dateOfBirth.name}
                  formState={formState}
                  fieldDisplayName="Date of Birth"
                ></FormError>
              </div>
              <div className="col-sm-4 col-md-3">
                <FgSelect
                  id="nfirsActivityOfPersonInvolvedEntryId"
                  label="Activity of Person Involved"
                  selectMessage="- Select Activity of Person Involved -"
                  registeredField={
                    registry.nfirsActivityOfPersonInvolvedEntryId
                  }
                  formState={formState}
                  options={(activities?.data || []).map((item) => {
                    return {
                      value: item.id,
                      label: `${item.externalId}. ${item.description}`,
                    };
                  })}
                  onChange={(data) =>
                    onModelChange({
                      ...model.new,
                      nfirsActivityOfPersonInvolvedEntryId:
                        Number(data) || (undefined as any),
                    })
                  }
                ></FgSelect>
              </div>
            </div>
          </div>

          <button type="submit" hidden></button>
        </form>
      )}
    </>
  );
}

export default NFIRSWildlandPeopleInvolved;
