import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CalJacTrainingType {
  get = async (id: number) => {
    const url = `/api/CalJacTrainingType/${id}`;
    return fetcher.get<ApsModels.ICalJacTrainingTypeOutputDto[]>(url);
  };

  getAll = async (deptId: number) => {
    const url = `/api/CalJacTrainingType/GetAll/${deptId}`;
    return fetcher.get<ApsModels.ICalJacTrainingTypeOutputDto[]>(url);
  };
}

const calJacTrainingType = new CalJacTrainingType();
export default calJacTrainingType;
