import React from "react";

export class Card extends React.Component {
    render = () => {
        return <>
        <div className="daily-operational-card card box-card">
            <div className="card-body">
                {this.props.children}
            </div>
        </div>
        </>
    }
}