import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";

function NFIRSBasicIncident(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    let data = { ...model.new } as any;
    if (!requireFDIDandIncidentNum()) {
      data = {
        ...model.new,
        nfirsFDReceivedAidEntryId: null,
        incidentNumberOfFireDeptReceivingAid: null,
        lookupStateId: null,
        otherFDId: null,
      } as any;
    }
    return ApsServices.http.nfirsBasicIncidentService.update(data);
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsBasicIncidentInputDto>({
    objectName: "Incident",
    nfirsSectionTab: "Basic/Incident",
    promise: saveAction,
    afterSave: (data) => {
      const oldAidId = model.old.nfirsAidGivenReceivedEntryId;
      const newAidId = data.nfirsAidGivenReceivedEntryId;
      const oldIncId = model.old.nfirsIncidentTypeId;
      const newIncId = data.nfirsIncidentTypeId;

      if (
        //ReValidate everything if nfirsAidGivenReceivedEntryId changes
        oldAidId !== newAidId ||
        oldIncId !== newIncId
      ) {
        nfirsStore.setNfirsAidGivenReceivedEntryId(newAidId);
        nfirsStore.setNfirsBasicIncidentType(newIncId);
        if (
          oldIncId !== newIncId || //always revalidate when incident type changes
          ([3, 4].includes(newAidId) && ![3, 4].includes(oldAidId)) ||
          (![3, 4].includes(newAidId) && [3, 4].includes(oldAidId))
        ) {
          nfirsStore.doManualValidateAll();
        }
      }
      initModels({
        ...model.new,
        nfirsFDReceivedAidEntryId: data.nfirsFDReceivedAidEntryId,
        incidentNumberOfFireDeptReceivingAid:
          data.incidentNumberOfFireDeptReceivingAid,
        lookupStateId: data.lookupStateId,
        otherFDId: data.otherFDId,
        concurrencyToken: data.concurrencyToken,
      });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsBasicIncidentInputDto) => {
    setModel({ ...model, new: { ...newModel } });
    //setValuesFromModel(newModel); //VALID FOR THIS TAB
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      nfirsStore.setMoveToNextTab();
      return;
    }

    if (getExposureDifference() > 0) {
      nfirsStore.resetMoveTabSection();
      setShowExposureChange(true);
      return;
    }

    saveData(tabChanged.isSaveOnTabChanged);
  };

  const getExposureDifference = () => {
    return (
      Number(getModel.new.exposure || 0) - Number(getModel.old.exposure || 0)
    );
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Basic", "Incident", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Basic", "Incident", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  const [fireModuleShow, setFireModuleShow] = useState(false);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!ready) {
      return;
    }
    if (getModel.new) {
      const incTyp = getModel.new.nfirsIncidentTypeId;
      const forFire = [
        100,
        111,
        112,
        ...Array.from({ length: 24 }, (_, i) => i + 120), //120-143
        ...Array.from({ length: 14 }, (_, i) => i + 160), //160-173
        ...Array.from({ length: 6 }, (_, i) => i + 113), //113-118
      ];
      const forStrucFire = [
        ...Array.from({ length: 2 }, (_, i) => i + 111), //111-112
        ...Array.from({ length: 4 }, (_, i) => i + 120), //120-123
      ];
      const forWildland = [
        160,
        561,
        631,
        632,
        ...Array.from({ length: 4 }, (_, i) => i + 140), //140-143
        ...Array.from({ length: 4 }, (_, i) => i + 170), //170-173
      ];

      nfirsStore.setCanAcccessModule(
        ApsModels.NfirsValidationModulesEnum.Wildland,
        nfirsStore.currentNfirsReport.isWildlandEnabled &&
          forWildland.indexOf(incTyp) > -1
      );

      if (
        nfirsStore.currentNfirsReport.isWildlandEnabled &&
        forWildland.indexOf(incTyp) > -1
      ) {
        nfirsStore.setCanAcccessModule(
          ApsModels.NfirsValidationModulesEnum.Fire,
          false
        );
        nfirsStore.setCanAcccessModule(
          ApsModels.NfirsValidationModulesEnum.Arson,
          false
        );
        setFireModuleShow(false);
      } else {
        const showFire = forFire.indexOf(incTyp) > -1;
        nfirsStore.setCanAcccessModule(
          ApsModels.NfirsValidationModulesEnum.Fire,
          showFire
        );
        if (!showFire) {
          nfirsStore.setCanAcccessModule(
            ApsModels.NfirsValidationModulesEnum.Arson,
            false
          );
        }
        setFireModuleShow(showFire);
      }

      nfirsStore.setCanAcccessModule(
        ApsModels.NfirsValidationModulesEnum.StructureFire,
        forStrucFire.indexOf(incTyp) > -1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getModel.new.nfirsIncidentTypeId, ready]);

  useEffect(() => {
    //This checks exising Fire/Ignition data to check if Arson module should be displayed.
    if (fireModuleShow) {
      ApsServices.http.nfirsFireIgnition
        .get(props.id)
        .then((data) => {
          if (
            data.nfirsCauseOfIgnitionEntryId === 1 ||
            data.nfirsCauseOfIgnitionEntryId === 5
          ) {
            nfirsStore.setCanAcccessModule(
              ApsModels.NfirsValidationModulesEnum.Arson,
              true
            );
          }
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Ignition", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fireModuleShow]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const groups = useNfirsGetData<ApsModels.INfirsIncidentGroupOutputDto[]>(
    ApsServices.http.nfirsGenericLookupService.getNfirsIncidentGroups,
    "Incident Groups"
  );
  const types = useNfirsGetData<
    ApsModels.INfirsIncidentTypeFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getIncidentTypes(false),
    "Incident Types"
  );

  const states = useNfirsGetData<ApsModels.ILookupStatesOutputDto[]>(
    async () => {
      return await ApsServices.http.genericLookupService.getStates(1);
    },
    "States"
  );
  const aidList = useNfirsGetData<
    ApsModels.INfirsAidGivenReceivedEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService.getNfirsAidGivenReceivedEntries,
    "Aid List"
  );
  const fdidReceivedAidList = useNfirsGetData<
    ApsModels.INfirsFdReceivedAidEntryOutputDto[]
  >(async () => {
    return await ApsServices.http.nfirsGenericLookupService.getNfirsFdReceivedAidEntries();
  }, "FDID Given or Received Aid List");

  const requireFDIDandIncidentNum = () => {
    return (
      getModel.new?.nfirsAidGivenReceivedEntryId === 3 ||
      getModel.new?.nfirsAidGivenReceivedEntryId === 4
    );
  };

  const isOtherFIDDGivenReceived = () => {
    return (
      requireFDIDandIncidentNum() &&
      !!(fdidReceivedAidList.data || []).find(
        (a) =>
          a.externalId === "Other" &&
          getModel.new?.nfirsFDReceivedAidEntryId === a.id
      )
    );
  };

  useEffect(() => {
    setProgress({ loading: true });
    groups.getData();
    types.getData();
    aidList.getData();
    states.getData();
    fdidReceivedAidList.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsBasicIncidentService
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
            caseNumber: props.header?.number,
            eventNumber: props.header?.eventNumber,
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);

          setReady(true);

          nfirsStore.setNfirsBasicIncidentType(data.nfirsIncidentTypeId || 0);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Incident detail", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      states.status === NfirsFetchStatus.Complete &&
      aidList.status === NfirsFetchStatus.Complete &&
      groups.status === NfirsFetchStatus.Complete &&
      types.status === NfirsFetchStatus.Complete &&
      fdidReceivedAidList.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    groups.status,
    types.status,
    states.status,
    aidList.status,
    fdidReceivedAidList.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    caseNumber: {
      displayName: "Case Number/Department Number",
      validation: {
        required: false,
      },
    },
    eventNumber: {
      displayName: "Event Number",
      validation: {
        required: false,
      },
    },
    exposure: {
      displayName: "Exposure",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsIncidentGroupId: {
      displayName: "Incident Group",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsIncidentTypeId: {
      displayName: "Incident Type",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsAidGivenReceivedEntryId: {
      displayName: "Aid Given or Received",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsFDReceivedAidEntryId: {
      displayName: "FDID Given or Received Aid",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            // if (getModel.new && requireFDIDandIncidentNum()) {
            //   return true;
            // }
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    lookupStateId: {
      displayName: "State",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    incidentNumberOfFireDeptReceivingAid: {
      displayName: "Incident # of FDID Given Aid",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            // if (getModel.new && requireFDIDandIncidentNum()) {
            //   return true;
            // }
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    otherFDId: {
      displayName: "FDID of Other Aid Given",
      validation: {
        required: false,
        minLength: 5,
        maxLength: 5,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
  });

  const [initialAidGivenReady, setInitialAidGivenReady] = useState(false);
  useEffect(() => {
    if (getModel.new && !progress.loading) {
      if (requireFDIDandIncidentNum()) {
        setValue(
          "nfirsFDReceivedAidEntryId",
          model.new.nfirsFDReceivedAidEntryId || undefined
        );
        setValue(
          "incidentNumberOfFireDeptReceivingAid",
          model.new.incidentNumberOfFireDeptReceivingAid || undefined
        );
        setValue("lookupStateId", model.new.lookupStateId || undefined);
        setValue("otherFDId", (model.new as any).otherFDId || undefined);
      } else {
        setValue("nfirsFDReceivedAidEntryId", undefined);
        setValue("incidentNumberOfFireDeptReceivingAid", undefined);
        setValue("lookupStateId", undefined);
        setValue("otherFDId", undefined);
      }

      if (initialAidGivenReady) {
        // nfirsStore.setNfirsAidGivenReceivedEntryId(
        //   getModel.new.nfirsAidGivenReceivedEntryId
        // );
      } else {
        setInitialAidGivenReady(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getModel.new.nfirsAidGivenReceivedEntryId]);

  const [showExposureChange, setShowExposureChange] = useState(false);
  const [parentCaseNumber] = useState<string | undefined>(
    nfirsStore.currentNfirsReport?.parentCaseNumber
  );

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />

      <ConfirmDialog
        show={showExposureChange && getExposureDifference() <= 5}
        buttons="yesno"
        title={"Exposure Changed"}
        message={`Warning: By changing the exposure to 
        <strong>${getModel.new.exposure}</strong>, 
        you are about to create 
        <strong>${getExposureDifference()}</strong> new report(s). 
        Are you sure you want to do this?`}
        done={(rtn) => {
          if (rtn === "yes") {
            saveData();
          } else {
            nfirsStore.fullResetMoveTabSection();
            if (nfirsStore.currentTabForSaveAll) {
              nfirsStore.updateCurrentTabForSaveAll(true);
            }
          }
          setShowExposureChange(false);
        }}
      />

      <ConfirmDialog
        show={showExposureChange && getExposureDifference() > 5}
        buttons="okonly"
        title={"Exposure Changed"}
        message={`Warning: By changing the exposure to 
        <strong>${getModel.new.exposure}</strong>, 
        you are about to create 
        <strong>${getExposureDifference()}</strong> new report(s). <br/><br/>
        <span style="color: red;">You may only increase 'Exposure' by a maximum of <strong>5</strong> at the time.</span>`}
        done={(rtn) => {
          nfirsStore.fullResetMoveTabSection();
          if (nfirsStore.currentTabForSaveAll) {
            nfirsStore.updateCurrentTabForSaveAll(true);
          }
          setShowExposureChange(false);
        }}
      />

      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 col-lg-6">
          <div className="row">
            <div className="col-sm-12">
              <strong>Incident Details</strong>
            </div>
          </div>

          <div className="mt-3">
            <FgInput
              id="caseNumber"
              label="Case Number/Department Number"
              placeHolder="Case Number/Department Number"
              registeredField={registry.caseNumber}
              formState={formState}
              showRequiredLabel={true}
              onChange={(data) => {}}
              readOnly={true}
            ></FgInput>
          </div>

          <div className="mt-3">
            <FgInput
              id="exposure"
              label="Exposure"
              placeHolder="Exposure"
              registeredField={registry.exposure}
              formState={formState}
              showRequiredLabel={true}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  exposure: data ? Number(data) : (undefined as any),
                });
              }}
              type="number"
              step="1"
              disabled={!!parentCaseNumber}
            ></FgInput>
          </div>

          <div className="mt-3">
            <FgInput
              id="eventNumber"
              label="Event Number / CAD Number"
              placeHolder="Event Number / CAD Number"
              registeredField={registry.eventNumber}
              formState={formState}
              showRequiredLabel={true}
              onChange={(data) => {}}
              readOnly={true}
            ></FgInput>
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsIncidentGroupId"
              label="Incident Group"
              selectMessage="- Select Incident Group -"
              registeredField={registry.nfirsIncidentGroupId}
              formState={formState}
              //showRequiredLabel={true}
              options={(groups.data || []).map((o) => {
                return {
                  label: `${o.id} - ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsIncidentGroupId: Number(data) || (undefined as any),
                  nfirsIncidentTypeId: null as any,
                })
              }
            ></FgSelect>
          </div>

          <div className="mt-3">
            <label>
              <span className="required-label">*</span>Incident Type
            </label>
            <AdvancedDropdown
              domId="nfirsIncidentTypeId"
              value={model.new.nfirsIncidentTypeId}
              favoritesHandler={{
                add: ApsServices.http.nfirsFavorite.addFavoriteIncidentType,
                remove:
                  ApsServices.http.nfirsFavorite.removeFavoriteIncidentType,
                afterUpdate: (list: any[]) => types.setData(list),
              }}
              options={(types.data || [])
                .filter(
                  (o) =>
                    o.nfirsIncidentGroupId === model.new.nfirsIncidentGroupId
                )
                .sort(commonService.sortByStringProperty("description"))
                .map((o) => {
                  return {
                    label: `${o.description} (${o.id})`,
                    value: o.id,
                    originalObject: o,
                    isFavorite: o.isFavorite,
                    groupId: 0,
                    subGroupId: 0,
                  };
                })}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  nfirsIncidentTypeId: Number(data) || (null as any),
                });

                //nfirsStore.setNfirsBasicIncidentType(Number(data) || 0);
              }}
            ></AdvancedDropdown>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="row">
            <div className="col-sm-12">
              <strong>Aid</strong>
            </div>
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsAidGivenReceivedEntryId"
              label="Aid Given or Received"
              selectMessage="- Select Aid -"
              registeredField={registry.nfirsAidGivenReceivedEntryId}
              formState={formState}
              showRequiredLabel={model.new.nfirsIncidentTypeId !== 611}
              options={(aidList.data || []).map((o) => {
                return {
                  label: `${o.externalId} ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsAidGivenReceivedEntryId:
                    Number(data) || (undefined as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsFDReceivedAidEntryId"
              label="FDID Given or Received Aid"
              selectMessage="- Select FDID Given or Received Aid -"
              registeredField={registry.nfirsFDReceivedAidEntryId}
              formState={formState}
              showRequiredLabel={false} //requireFDIDandIncidentNum()
              options={(fdidReceivedAidList.data || []).map((o) => {
                return {
                  label: `${o.description} ${o.externalId}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsFDReceivedAidEntryId: Number(data) || (undefined as any),
                })
              }
              disabled={!requireFDIDandIncidentNum()}
            ></FgSelect>
          </div>

          <div
            className={`mt-3 ${
              isOtherFIDDGivenReceived() ? "" : "display-none"
            }`}
          >
            <FgInput
              id="otherFDId"
              label="FDID of Other Aid Given"
              placeHolder="FDID of Other Aid Given"
              registeredField={registry.otherFDId}
              formState={formState}
              showRequiredLabel={false}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  otherFDId: data || null,
                } as any);
              }}
            ></FgInput>
          </div>

          <div className="mt-3">
            <FgSelect
              id="lookupStateId"
              label="State"
              selectMessage="- Select a State -"
              registeredField={registry.lookupStateId}
              formState={formState}
              showRequiredLabel={false} //requireFDIDandIncidentNum()
              options={(states.data || []).map((o) => {
                return {
                  label: `${o.abbreviation} - ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  lookupStateId: Number(data) || (undefined as any),
                })
              }
              disabled={!requireFDIDandIncidentNum()}
            ></FgSelect>
          </div>

          <div className="mt-3">
            <FgInput
              id="incidentNumberOfFireDeptReceivingAid"
              label="Incident # of FDID Given Aid"
              placeHolder="Incident # of FDID Given Aid"
              registeredField={registry.incidentNumberOfFireDeptReceivingAid}
              formState={formState}
              showRequiredLabel={false} //requireFDIDandIncidentNum()
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  incidentNumberOfFireDeptReceivingAid: data,
                });
              }}
              readOnly={!requireFDIDandIncidentNum()}
            ></FgInput>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSBasicIncident;
