import React, { useEffect, useState } from "react";
import { Schedule, Shift } from "../../data/ShiftScheduler";
import { ShiftSchedulerService } from "../../services/ShiftSchedulerService";

const minNumberOfShifts = 0;

type State = {
  numShifts: number;
  collapse: boolean;
  schedule: Schedule;
  numShiftError: boolean;
  ready: boolean;
  formDisabled: boolean;
};

/*
https://stackoverflow.com/a/65758103
Returns a string like 2021-01-17T01:59:57
Datetime input only accepts values in the following format:
  "yyyy-MM-ddThh:mm" followed by optional ":ss"
Chopping off the :ss prevents a console warning in Chrome
*/
function dateToISOButLocal(date: Date) {
  return date.toLocaleString("sv").replace(" ", "T").substring(0, "yyyy-MM-ddThh:mm".length);
}

const defaultSchedule: Schedule = {
  initialStartDate: "2021-08-01T00:00", // Initial start date for District C
  shifts: [
    {
      name: "Shift A",
      duration: 48,
      color: "#db2a00",
    },
    {
      name: "Shift B",
      duration: 48,
      color: "#189ad6",
    },
    {
      name: "Shift C",
      duration: 48,
      color: "#2DB539",
    },
  ],
};

const defaultState: State = {
  numShifts: 3,
  schedule: defaultSchedule,
  collapse: true,
  numShiftError: false,
  ready: false,
  formDisabled: false, // Disable form if department already has schedule
};

const createShifts = (n: number) => {
  const shifts: Array<Shift> = [];
  for (let i = 0; i < n; i++) {
    shifts.push({ name: "", duration: 24, color: "#db2a00" });
  }
  return shifts;
};

function ShiftScheduler(props: { departmentId: number }) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const init = async () => {
      if (!state.ready) {
        let schedule = await ShiftSchedulerService.getScheduleForDepartment(
          props.departmentId
        );
        if (schedule) {
          setState({
            ...state,
            schedule: {
              ...schedule,
              initialStartDate: dateToISOButLocal(
                new Date(schedule.initialStartDate)
              ),
            },
            formDisabled: true,
            ready: true,
          });
        } else {
          setState({ ...state, ready: true });
        }
      }
    };
    init();
  }, []);

  const onSaveClicked = () => {
    if (!state.formDisabled) {
      setState({ ...state, formDisabled: true });
      ShiftSchedulerService.createSchedule({
        departmentId: props.departmentId,
        initialStartDate: state.schedule.initialStartDate,
        shifts: state.schedule.shifts,
      } as any);
    }
  };

  const onNumShiftChange = (e: string) => {
    const n = Math.floor(Number(e));
    if (Number.isNaN(n) || n < minNumberOfShifts) {
      setState({ ...state, numShiftError: true });
      return;
    }

    const shifts = state.schedule.shifts;
    if (n < shifts.length) {
      setState({
        ...state,
        numShifts: n,
        schedule: {
          ...state.schedule,
          shifts: shifts.slice(0, n),
        },
      });
    } else if (n > shifts.length) {
      const newShifts = createShifts(n - shifts.length);
      setState({
        ...state,
        numShifts: n,
        schedule: {
          ...state.schedule,
          shifts: [...shifts, ...newShifts],
        },
      });
    }
  };

  const onCollapseChange = () =>
    setState({ ...state, collapse: !state.collapse });

  const onStartDateChange = (e: string) => {
    setState({
      ...state,
      schedule: { ...state.schedule, initialStartDate: e },
    });
  };

  const onNameChange = (e: string, index: number) => {
    setState({
      ...state,
      schedule: {
        ...state.schedule,
        shifts: state.schedule.shifts.map((s, i) =>
          i === index ? { ...s, name: e } : s
        ),
      },
    });
  };

  const onDurationChange = (e: string, index: number) => {
    const n = Math.floor(Number(e));
    if (Number.isNaN(n) || n < 0) {
      return;
    }
    setState({
      ...state,
      schedule: {
        ...state.schedule,
        shifts: state.schedule.shifts.map((s, i) =>
          i === index ? { ...s, duration: n } : s
        ),
      },
    });
  };

  const onColorChange = (e: string, index: number) => {
    setState({
      ...state,
      schedule: {
        ...state.schedule,
        shifts: state.schedule.shifts.map((s, i) =>
          i === index ? { ...s, color: e } : s
        ),
      },
    });
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="pointer" onClick={onCollapseChange}>
                <i
                  className={`fa fa-chevron-${
                    state.collapse ? "up" : "down"
                  } pl-2 pr-2`}
                ></i>
                <h4 className="pt-3 pl-2 m-0 inline-block">Shift Scheduler</h4>
              </span>
            </div>
            <div></div>
          </div>
        </div>
        <div
          className={`doc-designer w-100 pb-4 ${
            state.collapse ? "display-none" : ""
          }`}
        >
          {state.ready ? (
            <>
              <div className="pb-4">
                <h6>Shift Overview</h6>
                <div className="pb-2 row">
                  <div className="col-md-4">
                    How many shifts are there?
                    <input
                      className="form-control"
                      type="number"
                      name="numShifts"
                      id="numShifts"
                      value={state.numShifts}
                      onChange={(e) => onNumShiftChange(e.target.value)}
                      disabled={state.formDisabled}
                    />
                    {state.numShiftError && (
                      <small>
                        Invalid number of shifts. Please enter a non-negative
                        number.
                      </small>
                    )}
                  </div>
                </div>
                <div className="pb-2 row">
                  <div className="col-md-4">
                    When should the shift pattern start?
                    <input
                      className="form-control"
                      type="datetime-local"
                      name="shiftStart"
                      id="shiftStart"
                      value={state.schedule.initialStartDate}
                      onChange={(e) => onStartDateChange(e.target.value)}
                      disabled={state.formDisabled}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h6>Shift Details</h6>
                <div>
                  {state.schedule.shifts.map((s, i) => (
                    <div className="pb-2" key={`shift-item-${i}`}>
                      <h6>Shift {i + 1}</h6>
                      <div className="row">
                        <div className="pb-2 col-md-4">
                          Shift Name
                          <div>
                            <input
                              className="form-control"
                              type="text"
                              name="shiftName"
                              id="shiftName"
                              value={s.name}
                              onChange={(e) => onNameChange(e.target.value, i)}
                              disabled={state.formDisabled}
                            />
                          </div>
                        </div>
                        <div className="pb-2 col-md-4">
                          Shift Duration (Hours)
                          <div>
                            <input
                              className="form-control"
                              type="number"
                              name="shiftDuration"
                              id="shiftDuration"
                              min={0}
                              value={s.duration}
                              onChange={(e) =>
                                onDurationChange(e.target.value, i)
                              }
                              disabled={state.formDisabled}
                            />
                          </div>
                        </div>
                        <div className="pb-2 col-md-4">
                          Shift Color
                          <div>
                            <input
                              type="color"
                              name="shiftColor"
                              id="shiftColor"
                              value={s.color}
                              onChange={(e) => onColorChange(e.target.value, i)}
                              disabled={state.formDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary float-right"
                  onClick={onSaveClicked}
                  disabled={state.formDisabled}
                >
                  <span className="font-size-12">Save</span>
                </button>
                <span className="float-right pt-2 mr-2">
                  Note: A schedule can only be saved once.
                </span>
              </div>
            </>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div>
  );
}

export default ShiftScheduler;
