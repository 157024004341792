import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import CustomTabs from "../../../aps2/components/Common/CustomTabs";
import CustomNotifications from "./CustomNotifications";
import DefaultNotifications from "./DefaultNotifications";
import RouteChangedGuard from "../../../aps2/components/Common/RouteChangedGuard";
import commonService from "../../../aps2/services/CommonService";

function Notifications(props: RouteComponentProps<any> & {}) {
  const tabParam = `${props.match?.params?.type || "defaults"}`.toLowerCase();

  const [activeTab, setActiveTab] = useState<"defaults" | "custom">(
    (tabParam as any) || "defaults"
  );
  const [customLoaded, setCustomLoaded] = useState(tabParam === "custom");
  const [saving, setSaving] = useState(false);
  const [allowSave, setAllowSave] = useState(false);
  const [allowClear, setAllowClear] = useState(false);
  const defaultsTabRef = useRef();

  const [hasChanges, setHasChanges] = useState(false);

  const canEdit = () => {
    return commonService.getAccessNotifications() === "All";
  };

  return (
    <>
      <RouteChangedGuard
        when={true}
        navigate={(path) => {
          props.history.push(path);
        }}
        shouldBlockNavigation={(location) => {
          if (
            [
              "/notifications",
              "/notifications/defaults",
              "/notifications/custom",
            ].includes(location.pathname)
          ) {
            return false;
          }
          return hasChanges;
        }}
        message="You have unsaved changes, do you really want to leave the page and discard changes?"
      />
      <div
        className="flex flex-1 flex-column credentials-records-list"
        style={{ marginBottom: "-60px", position: "relative" }}
      >
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  Notifications
                </span>
              </div>
              <div
                className="flex-0 flex flex-wrap p-0"
                style={{ justifyContent: "right" }}
              >
                {activeTab === "defaults" && canEdit() && (
                  <>
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={(e) => {
                        (defaultsTabRef?.current as any)?.reset();
                      }}
                      disabled={!allowClear}
                    >
                      Clear Fields
                    </button>
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={(e) => {
                        (defaultsTabRef?.current as any)?.save();
                      }}
                      disabled={saving || !allowSave}
                    >
                      <span className="px-2">
                        {saving ? "Submitting..." : "Submit"}
                      </span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col p-0 pt-2">
                  <CustomTabs
                    onMenuTabClick={(tab) => {
                      setActiveTab(tab as any);
                    }}
                    activeTab={activeTab}
                  >
                    <Tabs
                      id="notifications-list-page"
                      activeKey={activeTab}
                      onSelect={(tab) => {
                        setActiveTab(tab as any);
                        if (!customLoaded && tab === "custom") {
                          setCustomLoaded(true);
                        }
                        props.history.replace(`/notifications/${tab}`);
                      }}
                    >
                      <Tab eventKey="defaults" title="Default Notifications">
                        <DefaultNotifications
                          ref={defaultsTabRef}
                          saving={(saving) => setSaving(saving)}
                          allowSave={(allowSave, allowClear) => {
                            setAllowSave(allowSave);
                            setAllowClear(allowClear);
                          }}
                          hasChanges={(rtn) => setHasChanges(rtn)}
                        ></DefaultNotifications>
                      </Tab>
                      <Tab eventKey="custom" title="Custom Notifications">
                        {customLoaded && (
                          <CustomNotifications {...props}></CustomNotifications>
                        )}
                      </Tab>
                    </Tabs>
                  </CustomTabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;
