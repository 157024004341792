import ApsModels from "../../models";
import fetcher from "./Fetcher";

class AssigmentService {
  assignAdminToUsers = async (data: ApsModels.IAssignAdminItemsDto) => {
    const url = `/api/Assignment/AssignAdminToUsers`;
    return fetcher.post<any>(url, data);
  };

  assignOwn = async (data: ApsModels.IAssignOwnItemsDto) => {
    const url = `/api/Assignment/AssignOwn`;
    return fetcher.post<any>(url, data);
  };

  typeAheadCredential = async (
    data: ApsModels.ICredCredentialTypeAheadInputDto
  ) => {
    const url = `/api/Assignment/TypeAheadCredential`;
    return fetcher.post<ApsModels.ICredCredentialTypeAheadOutputDto[]>(
      url,
      data
    );
  };

  typeAheadOnlineTrainingCourse = async (
    data: ApsModels.IOnlineTrainingCourseTypeAheadInputDto
  ) => {
    const url = `/api/Assignment/TypeAheadOnlineTrainingCourse`;
    return fetcher.post<ApsModels.IOnlineTrainingCourseTypeAheadOutputDto[]>(
      url,
      data
    );
  };

  typeAheadCourse = async (data: ApsModels.ICourseTypeAheadDto) => {
    const url = `/api/Assignment/TypeAheadCourse`;
    return fetcher.post<ApsModels.ICourseTypeAheadOutputDto[]>(url, data);
  };

  getDrillForSubmission = async () => {
    const url = `/api/Assignment/GetDrillForSubmission`;
    return fetcher.get<ApsModels.IDrillTemplateOutputDto>(url);
  };

  allRanks = async () => {
    const url = `/api/Assignment/AllRanks`;
    return fetcher.get<ApsModels.IUserCredSettingsRankOutputBaseDto[]>(url);
  };

  getAllEmployees = async (rank?: number) => {
    const url = `/api/Assignment/GetAllEmployees${rank ? `/${rank}` : ""}`;
    return fetcher.get<ApsModels.ICourseRecordParticipantOutputDto[]>(url);
  };
}

const assignment = new AssigmentService();
export default assignment;
