import { IServerSideGetRowsRequest } from "ag-grid-community";
import { FilterOrderPaginateParameters, FilterParameter, OrderParameter } from '../data/FilterOrderPaginateParameters';
import moment from 'moment';

class AgGridService {
    defaultDateFilterOptions = ['greaterThanOrEqual', 'lessThan', 'inRange'];

    getLicense = async (): Promise<string> => {
        const response = await fetch('/Api/Account/License');
        return response.text()
    }

    convertRequest = (request: IServerSideGetRowsRequest): FilterOrderPaginateParameters => {
        const filter: FilterParameter[] = [];

        const filterModel = request.filterModel || {};
        for (const key in filterModel) {
            if (filterModel.hasOwnProperty(key)) {
                const filterModelItem = filterModel[key];

                const filterParameter = this.convertFilterModelItem(key, filterModelItem);

                filter.push(filterParameter);
            }
        }

        const order: OrderParameter[] = (request.sortModel as any[] || []).map(item => {
            return {
                field: item.colId,
                direction: item.sort,
            };
        });

        const paginate = {
            skip: request.startRow,
            take: request.endRow - request.startRow,
        };

        return {
            filter: filter,
            order: order,
            paginate: paginate,
        }
    }

    convertFilterModelItem = (field: string, filterModelItem: any): FilterParameter => {
        const filterParameter: FilterParameter = {
            filterType: filterModelItem.filterType as 'text' | 'number' | 'date' | 'set',
            field: field,
            operator: filterModelItem.filterType === 'set' ? 'in' : (filterModelItem.type ?? filterModelItem.operator),
        };

        if (filterModelItem.condition1 && filterModelItem.condition2) {
            filterParameter.condition1 = this.convertFilterModelItem(field, filterModelItem.condition1);
            filterParameter.condition2 = this.convertFilterModelItem(field, filterModelItem.condition2);
        }
        else {
            if (filterModelItem.filterType === 'text') {
                filterParameter.value = filterModelItem.filter;
            }
            else if (filterModelItem.filterType === 'set') {
                filterParameter.values = filterModelItem.values;
            }
            else if (filterModelItem.filterType === 'number') {
                filterParameter.numberFrom = Number(filterModelItem.filter);
                if (filterModelItem.filterTo !== null && filterModelItem.filterTo !== undefined) {
                    filterParameter.numberTo = Number(filterModelItem.filterTo);
                }
            }
            else if (filterModelItem.filterType === 'date') {
                filterParameter.dateFrom = moment(filterModelItem.dateFrom).toDate();
                if (filterModelItem.dateTo !== null && filterModelItem.dateTo !== undefined) {
                    filterParameter.dateTo = moment(filterModelItem.dateTo).toDate();
                }
            }
            else {
                throw new Error(`Unsupported filter type: ${filterModelItem.filterType}`);
            }
        }

        return filterParameter;
    }
}

const agGridService = new AgGridService();
export { agGridService as AgGridService };