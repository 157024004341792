import React, { useEffect } from "react";
import systemStore, { useShowNotification } from "../../stores/SystemStore";

function SysNotification(props: any) {
  const notif = useShowNotification();

  useEffect(() => {
    const $layout = document.getElementById("layout-wrapper");
    const $toolbar = document.getElementById("page-topbar");
    const $sidebar = document.getElementById("sidebar-menu");

    if ($layout?.style && $toolbar?.style && $sidebar?.style) {
      if (notif.show) {
        const $notif = document.getElementById("sys-notification");
        const height = `${$notif?.clientHeight || 0}px`;
        $layout.style.paddingTop = height;
        $toolbar.style.top = height;
        $sidebar.style.marginTop = height;
      } else {
        $layout.style.paddingTop = "0";
        $toolbar.style.top = "0";
        $sidebar.style.marginTop = "0";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notif]);

  return (
    <div
      id="sys-notification"
      className={`notification-wrapper ${notif.show ? "" : "display-none"}`}
    >
      <div>{notif.message}</div>
      <div>
        <i
          className="fa fa-times pointer"
          onClick={(e) => {
            systemStore.setShowNotification(false, "");
          }}
        ></i>
      </div>
    </div>
  );
}

export default SysNotification;
