import React from "react";
import FormModal from "../Common/FormModal";

function AddTemplateDialog(props: {
  onClose: (action?: "course" | "credential" | undefined) => void;
}) {
  return (
    <>
      <FormModal
        title="Add Template"
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        submit={() => {
          props.onClose();
        }}
        size="md"
        primaryButtonOnly={true}
        submitButtonLabel="Close"
      >
        <div className="add-template-options">
          <div onClick={(e) => props.onClose("course")}>
            Add Course
            <br />
            Template
          </div>
          <div onClick={(e) => props.onClose("credential")}>
            Add Credential <br />
            Template
          </div>
        </div>
      </FormModal>
    </>
  );
}

export default AddTemplateDialog;
