import React, { createContext, useEffect, useState } from "react";
import { SsoService } from "../../../data/SSO";
import { Group, Section, SectionItem, SectionList } from "../../../data/UserConsoleAdmin";
import { GroupService } from "../../../services/GroupService";
import { SingleSignOnService } from "../../../services/SsoService";
import { UserConsoleAdminService } from "../../../services/UserConsoleAdminService";
import ExpandCollapse from "../../Shared/ExpandCollapse";
import UserConsoleAdminSectionList from "./UserConsoleAdminSectionList";
import toastStore from '../../../aps2/stores/ToastStore';
var dnd = require('react-beautiful-dnd');

type Root = {
    children: SectionList[]
}

type Node = Root | SectionList | Section | SectionItem

type Props = {
    departmentId: number,
    departmentName : string,
    hasSectionChanges: (hasSectionChanges: boolean) => void;
    hasLinkChanges: (hasSectionChanges: boolean) => void;
}

// Adapted from https://inprod.dev/blog/2020-04-06-groupby-helper/
const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) => {
    let map = new Map<K, T[]>();
    list.forEach(item => {
        let group = getKey(item);
        if (!map.has(group)) map.set(group, [])
        map.get(group)!.push(item)
    })
    return map;
}

interface IUCAdminContext {
    addLink: (path: number[], nested?: boolean, persist?: boolean) => void
    deleteLink: (path: number[], persist: boolean) => void
    updateLink: (path: number[], link: SectionItem, persist: boolean) => void
    addSection: (path: number[], persist: boolean) => void
    deleteSection: (path: number[], persist: boolean) => void
    updateSection: (path: number[], section: Section, persist: boolean) => void
    allGroups: Group[]
    ssoServices: SsoService[]
    updateSectionChangeStatus: (val: boolean) => void
    updateLinkChangeStatus: (val: boolean) => void
}

export const UCAdminContext = createContext({} as IUCAdminContext)

export const UserConsoleAdmin: React.FC<Props> = ({ departmentId, departmentName, hasSectionChanges, hasLinkChanges }) => {
    const [children, setChildren] = useState<SectionList[]>([]);
    const [allGroups, setAllGroups] = useState<Group[]>([]);
    const [ssoServices, setSsoServices] = useState<SsoService[]>([]);
    const [ready, setReady] = useState(false);
    const [_, update] = useState({});
    const root: Root = { children }
    const path: number[] = [];

    useEffect(() => {
        const init = async () => {
            const dtos = await UserConsoleAdminService.getSections(departmentId);
            const allSections = dtos.map(UserConsoleAdminService.convertFromSectionDto);
            const grouped = groupBy(allSections, s => s.columnIndex);
            const sectionLists = [0, 1, 2].map(i => {
                const children = grouped.get(i) ?? [];
                const sectionList: SectionList = {
                    departmentId,
                    children: children.sort((a, b) => a.sortOrder - b.sortOrder),
                    columnIndex: i
                }
                return sectionList;
            });
            // make sure links are in their sort order
            sectionLists.forEach((column) => {
                column.children.forEach((section) => {
                    section.children = section.children.sort((a, b) => a.sortOrder - b.sortOrder)
                })
            })
            const allGroupDtos = await GroupService.getAllGroups();
            const allGroups = allGroupDtos.map(UserConsoleAdminService.convertFromGroupDto);
            const allSSOs = await SingleSignOnService.getAvailableSsoServices(departmentId);
            setSsoServices(allSSOs);
            setAllGroups(allGroups);
            setChildren(sectionLists);
            setReady(true);
        }
        init();
    }, []);

    const findNode = (path: number[], node: Node): Node => {
        const selected = node.children[path[0]];
        if (path.length === 1) {
            return selected;
        } else {
            return findNode(path.slice(1), selected);
        }
    }

    const findParent = (path: number[]): Node => {
        const parent = findNode(path.slice(0, -1), root);
        return parent;
    }

    const addLink = async (path: number[], nested: boolean = false, persist: boolean = true) => {
        let sectionOrLink: Section | SectionItem;
        if (nested) {
            sectionOrLink = findNode(path, root) as SectionItem;
        } else {
            sectionOrLink = findNode(path, root) as Section;
        }

        let newSectionItem: SectionItem = {
            itemId: -1, // Not needed
            parentId: nested ? (sectionOrLink as SectionItem).itemId : null,
            sectionId: sectionOrLink.sectionId,
            itemName: "New Link",
            url: "",
            sortOrder: sectionOrLink.children.length,
            ssoServiceId: null,
            children: [], // Not needed
            groups: [], // Not needed,
            blob: "",
            fileName: ""
            
        };
        const dto = UserConsoleAdminService.convertToSectionItemDto(newSectionItem);

        if (persist) {
            const newSectionItemDto = await UserConsoleAdminService.insertSectionItem(dto);
            newSectionItem = UserConsoleAdminService.convertFromSectionItemDto(newSectionItemDto);
        }

        sectionOrLink.children.push(newSectionItem);
        update({});

        // click new link to expand it
        const collapseId = path.join("");
        document.getElementById(newSectionItem.itemName + collapseId + (sectionOrLink.children.length - 1).toString())?.click();
    }

    const deleteLink = async (path: number[], persist: boolean) => {
        const parent = findParent(path) as Section | SectionItem;
        const idx = path[path.length - 1]
        const itemId = parent.children[idx].itemId;

        if (persist) {
            await UserConsoleAdminService.deleteSectionItem(itemId);
        }

        const newSection = parent.children.filter((_, i) => {
            return i !== idx;
        }).map((item, i) => {
            return { ...item, sortOrder: i };
        });

        if (persist) {
            const dtos = newSection.map(UserConsoleAdminService.convertToSectionItemDto)
            await UserConsoleAdminService.updateSectionItems(dtos);
        }

        parent.children = newSection;
        update({});
    }

    const updateLink = async (path: number[], link: SectionItem, persist: boolean) => {
        updateLinkChangeStatus(false);
        if (persist) {
            const dto = UserConsoleAdminService.convertToSectionItemDto(link);
            const response = await UserConsoleAdminService.updateSectionItems([dto]);
            if (response.status === 200) {
                toastStore.showToast("User Console link has been updated", "success");
            } else {
                const errorJson = await response.json();
                toastStore.showError("Error saving User Console link", errorJson.Errors ? errorJson.Errors[0].Message : "");
                return;
            }
        }
        const parent = findParent(path);
        const idx = path[path.length - 1];
        parent.children[idx] = link;
        update({});
    }

    const addSection = async (path: number[], persist?: boolean) => {
        const sectionList = findNode(path, root) as SectionList;
        let newSection: Section = {
            sectionId: -1, // Not needed
            departmentId: departmentId,
            columnIndex: path[0],
            sectionName: "New Section",
            formatting: "",
            sortOrder: sectionList.children.length,
            children: [] // Not needed
        };
        const dto = UserConsoleAdminService.convertToSectionDto(newSection);

        if (persist) {
            const newSectionDto = await UserConsoleAdminService.insertSection(dto);
            newSection = UserConsoleAdminService.convertFromSectionDto(newSectionDto);
        }

        sectionList.children.push(newSection);
        update({});

        // click new section to expand it
        document.getElementById(newSection.sectionName + path[0].toString() + (sectionList.children.length - 1).toString())?.click();
    }

    const deleteSection = async (path: number[], persist: boolean) => {
        const parent = findParent(path) as SectionList;
        const idx = path[path.length - 1];
        const sectionId = parent.children[idx].sectionId;

        if (persist) {
            await UserConsoleAdminService.deleteSection(sectionId);
        }

        // Remove element and reindex
        const newSectionList = parent.children.filter((_, i) => {
            return i !== idx;
        }).map((s, i) => {
            return { ...s, sortOrder: i }
        });

        if (persist) {
            const dtos = newSectionList.map(UserConsoleAdminService.convertToSectionDto);
            await UserConsoleAdminService.updateSections(dtos);
        }

        parent.children = newSectionList;
        update({});
    }

    const updateSection = async (path: number[], section: Section, persist: boolean) => {
        updateSectionChangeStatus(false);
        if (persist) {
            const dto = UserConsoleAdminService.convertToSectionDto(section);
            const response = await UserConsoleAdminService.updateSections([dto]);
            if (response.status === 200) {
                toastStore.showToast("User Console section has been updated", "success");
            } else {
                const errorJson = await response.json();
                toastStore.showError("Error saving User Console section", errorJson.Errors ? errorJson.Errors[0].Message : "");
                return;
            }
        }
        const parent = findParent(path);
        const idx = path[path.length - 1];
        parent.children[idx] = section;
        update({});
    }

    const updateSectionChangeStatus = (val: boolean) => {
        hasSectionChanges(val);
    }

    const updateLinkChangeStatus = (val: boolean) => {
        hasLinkChanges(val);
    }

    const context: IUCAdminContext = {
        addLink,
        deleteLink,
        updateLink,
        addSection,
        deleteSection,
        updateSection,
        allGroups,
        ssoServices,
        updateSectionChangeStatus,
        updateLinkChangeStatus
    }

    // logic ffor swapping as needed ind
    const dragEndSameCol = async (srcCol: number, srcIndex: number, destCol: number, destIndex: number) => {
        // this case we are bringing and element to earlier position and pushing everything back
        if (destIndex < srcIndex) {
            let toInsert: Section = children[srcCol].children[srcIndex];
            for (var i = destIndex; i <= srcIndex; i++) {
                toInsert.sortOrder = i
                const temp: Section = children[destCol].children[i];
                await updateSection([destCol, i], toInsert, false);
                toInsert = temp
            }
        }
        // now we are putting an element in a later position and moving everything forward 1
        else {
            let toInsert: Section = children[srcCol].children[srcIndex];
            for (var i = destIndex; i >= srcIndex; i--) {
                toInsert.sortOrder = i
                const temp: Section = children[destCol].children[i];
                await updateSection([destCol, i], toInsert, false);
                toInsert = temp
            }
        }
        // Batch updates
        const dtos = children[destCol].children.map(UserConsoleAdminService.convertToSectionDto);
        await UserConsoleAdminService.updateSections(dtos);
    }

    // this is the logic for draggging from one column to the next
    const dragEndDiffCol = async (srcCol: number, srcIndex: number, destCol: number, destIndex: number) => {
        // get a copy of the section to be moved over, and delete from old column
        let temp: Section = children[srcCol].children[srcIndex];
        await deleteSection([srcCol, srcIndex], false);

        // add new section, and update that one to be temp
        await addSection([destCol], false);
        await updateSection([destCol, children[destCol].children.length - 1], { ...temp, columnIndex: destCol, sortOrder: children[destCol].children.length - 1 }, true);
        update({});


        // now we pretend the drag is happening from last to its new wanted position
        await dragEndSameCol(destCol, children[destCol].children.length - 1, destCol, destIndex);
    }

    const onDragEndSection = async (srcPath: number[], srcIndex: number, destPath: number[], destIndex: number) => {

        const srcCol = srcPath[0];
        const destCol = destPath[0];

        // break if we landed in same position
        if (destIndex === srcIndex && destCol === srcCol)
            return

        // are we staying in the same column
        if (srcCol == destCol)
            await dragEndSameCol(srcCol, srcIndex, destCol, destIndex);

        // this case we are dragging between columns
        else
            await dragEndDiffCol(srcCol, srcIndex, destCol, destIndex);
        update({});
    }

    // logic ffor swapping as needed ind
    const dragEndSameSect = async (srcPath: number[], srcIndex: number, destPath: number[], destIndex: number) => {
        let parent: Section = children[srcPath[0]].children[srcPath[1]];

        // this case we are bringing and element to earlier position and pushing everything back
        if (destIndex < srcIndex) {
            let toInsert: SectionItem = parent.children[srcIndex];
            for (var i = destIndex; i <= srcIndex; i++) {
                toInsert.sortOrder = i
                const temp: SectionItem = parent.children[i];
                await updateLink([...destPath, i], toInsert, false);
                toInsert = temp
            }
        }
        // now we are putting an element in a later position and moving everything forward 1
        else {
            let toInsert: SectionItem = parent.children[srcIndex];
            for (var i = destIndex; i >= srcIndex; i--) {
                toInsert.sortOrder = i
                const temp: SectionItem = parent.children[i];
                await updateLink([...destPath, i], toInsert, false);
                toInsert = temp
            }
        }

        // Batch updates
        const dtos = parent.children.map(UserConsoleAdminService.convertToSectionItemDto);
        await UserConsoleAdminService.updateSectionItems(dtos);
    }

    const dragEndDiffSect = async (srcPath: number[], srcIndex: number, destPath: number[], destIndex: number) => {
        let srcParent: Section = children[srcPath[0]].children[srcPath[1]];
        let destParent: Section = children[destPath[0]].children[destPath[1]];

        // get a copy of the section to be moved over, and delete from old column
        let temp: SectionItem = srcParent.children[srcIndex];
        await deleteLink([...srcPath, srcIndex], false);

        // add new section, and update that one to be temp
        await addLink(destPath, false, false);
        await updateLink([...destPath, destParent.children.length - 1], { ...temp, sectionId: destParent.sectionId, sortOrder: destParent.children.length - 1 }, true);
        update({});


        // now we pretend the drag is happening from last to its new wanted position
        await dragEndSameSect(destPath, destParent.children.length - 1, destPath, destIndex);
    }

    const onDragEndSectionItem = async (srcPath: number[], srcIndex: number, destPath: number[], destIndex: number) => {
        // break if we landed in same position
        if (destIndex === srcIndex && destPath[1] === srcPath[1])
            return

        // same section
        if (srcPath[1] == destPath[1])
            await dragEndSameSect(srcPath, srcIndex, destPath, destIndex)

         // this case we are dragging between sections
        else
            await dragEndDiffSect(srcPath, srcIndex, destPath, destIndex)

        update({});
    }

    // this is the logic for handling the end of a draggable elemenresult: anyts dragging action
    const onDragEnd = async (result: any) => {
        const parsePath = (pathStr: string) => {
            return pathStr.split(',').map(Number);
        }

        // dropped outside the list or same position as it lefft
        if (!result.destination)
            return;

        // get index and path information
        var srcIndex = result.source.index
        var srcPath: number[] = parsePath(result.source.droppableId);
        var destIndex = result.destination.index
        var destPath: number[] = parsePath(result.destination.droppableId);

        if (result.type == "SECTIONS")
            await onDragEndSection(srcPath, srcIndex, destPath, destIndex);
        else
            await onDragEndSectionItem(srcPath, srcIndex, destPath, destIndex)
    }

    return (
        <UCAdminContext.Provider value={context}>
            <ExpandCollapse
                title="Links"
                titleStyle={{ fontWeight: 500, fontSize: '1.21875rem' }} // hack to simulate <h4>
            >
                <dnd.DragDropContext onDragEnd={onDragEnd}>
                    {ready && children.map((sl, i) => (
                        <UserConsoleAdminSectionList
                            departmentName={departmentName}
                            path={[...path, i]}
                            data={sl}
                            key={`UCSectionList-${i}`}
                        />
                    ))}
                </dnd.DragDropContext>
            </ExpandCollapse>
        </UCAdminContext.Provider>
    )
}