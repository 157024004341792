import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ConfirmDialog from "../../Common/ConfirmDialog";
import ApsServices from "../../../services";
import toastStore from "../../../stores/ToastStore";
import commonService from "../../../services/CommonService";
import NFIRSRemarkDialog from "./NFIRSRemarkDialog";
import nfirsStore from "../../../stores/NFIRSStore";
import FileSaver from "file-saver";

function NFIRSRemarkItem(props: {
  id: any;
  item: ApsModels.INfirsRemarkOutputDto;
  section: ApsModels.NfirsRemarkSectionEnum;
  refresh: () => void;
}) {
  const [editRemark, setEditRamark] = useState(false);
  const [deleteRemark, setDeleteRamark] = useState(false);

  const doDeleteItem = async () => {
    if (deleteRemark) {
      await ApsServices.http.nfirsRemark
        .delete(props.item.id, props.id, props.item.concurrencyToken)
        .then(() => {
          props.refresh();
          toastStore.showToast("Remark has been deleted.", "success");
          nfirsStore.setManualValidateModule("Basic");
        })
        .catch((error) => {
          if (commonService.getErrorStatusCode(error) === 404) {
            props.refresh();
          }
          toastStore.showError("Error deleting Remark", error);
        });
    }
  };

  const [claims, setClaims] = useState(commonService.friendlyClaims);
  const [img, setImg] = useState("");
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (props.item && props.item.fileName) {
      ApsServices.http.nfirsRemark
        .thumbnail(props.item.id, props.id)
        .then((data) => {
          setImg(data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  return (
    <>
      {editRemark && (
        <NFIRSRemarkDialog
          data={{
            reportId: props.id,
            item: props.item,
            section: props.section,
          }}
          onClose={(saved) => {
            if (saved) {
              props.refresh();
            }
            setEditRamark(false);
          }}
        ></NFIRSRemarkDialog>
      )}
      {deleteRemark && (
        <ConfirmDialog
          show={true}
          title="Delete Remark"
          message="Do you really want to delete this Remarks?"
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              doDeleteItem();
            }
            setDeleteRamark(false);
          }}
        ></ConfirmDialog>
      )}
      <div className="remark-item">
        <div>
          <div className="flex">
            <div className="flex-1">
              <strong>{props.item.id}</strong> - {props.item.name}
            </div>

            {(claims.nfirs.canChangeAllComments ||
              (!claims.nfirs.canChangeAllComments &&
                props.item.userId === commonService.userInfo.userId)) && (
              <div className="flex-0 remark-item-actions">
                <i
                  className="fa fa-edit mr-2"
                  onClick={() => setEditRamark(true)}
                ></i>
                <i
                  className="fa fa-trash"
                  onClick={() => setDeleteRamark(true)}
                ></i>
              </div>
            )}
          </div>
          <div>
            <small className="nowrap mr-2">
              Published:{" "}
              {moment(props.item.datePublished).format("MMM DD, YYYY hh:mm a")}
            </small>
            <span className="mr-2">-</span>
            <small className="nowrap">
              Updated:{" "}
              {moment(props.item.dateUpdated).format("MMM DD, YYYY hh:mm a")}
            </small>
          </div>
          <div className="pt-3 pb-2 flex">
            <div>
              {!!props.item.fileName && !!img && (
                <div
                  className="pe-3"
                  style={{
                    width: "100px",
                  }}
                >
                  <img
                    alt={props.item.fileName}
                    src={`data:image/png;base64,${img}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <div className="text-primary pt-1 no-wrap">
                    {downloading ? (
                      <span className="text-primary">
                        <small>
                          <i className="fa fa-spin fa-spinner"></i>{" "}
                          Downloading...
                        </small>
                      </span>
                    ) : (
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDownloading(true);
                          ApsServices.http.nfirsRemark
                            .download(props.item.id, props.id)
                            .then((data) => {
                              const file = commonService.b64toBlob(
                                data.fileContents,
                                "text/plain"
                              );
                              FileSaver.saveAs(file, data.fileDownloadName);
                            })
                            .catch((error) => {
                              toastStore.showError(
                                "Failed to Download File",
                                error
                              );
                            })
                            .finally(() => setDownloading(false));
                        }}
                      >
                        <small>
                          <i className="fa fa-download"></i> Download File
                        </small>
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="flex-1 flex">
              <i
                className="fa fa-quote-left mr-2"
                style={{ color: "#bbb" }}
              ></i>
              <div className="pt-0">{props.item.remarks}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NFIRSRemarkItem;
