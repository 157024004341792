import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ApsModels from "../../../../../models";
import ApsServices from "../../../../../services";
import toastStore from "../../../../../stores/ToastStore";
import CommonSpinner from "../../../../Common/CommonSpinner";
import DateTimePickerV2 from "../../../../Common/DateTimePickerV2";
import FormError from "../../../../Common/FormError";
import FormModal from "../../../../Common/FormModal";
import {
  FgInput,
  FgCheckbox,
  FgSelect,
  FgUseForm,
  IBasicDialogProps,
} from "../../../../Common/FormGroups";
import commonService from "../../../../../services/CommonService";
import { NfirsFetchStatus, useNfirsGetData } from "../../../NFIRSHelper";
import moment, { invalid } from "moment";
import { useIsOtherModuleDataRequired } from "../../NFIRSEntry";

function NFIRSDialogApparatus(
  props: IBasicDialogProps<{ reportId: number; id?: number }>
) {
  const { registry, handleSubmit, formState, setValue, setValuesFromModel } =
    FgUseForm({
      apparatusId: {
        displayName: "Apparatus ID",
        validation: { required: false },
      },
      nfirsApparatusTypeId: {
        displayName: "Apparatus Type ID",
        validation: { required: false },
      },
      dispatchDateTime: {
        displayName: "Dispatch Date/Time",
        validation: { required: false },
      },
      clearDateTime: {
        displayName: "Clear Date/Time",
        validation: { required: false },
      },
      arrivalDateTime: {
        displayName: "Arrival Date/Time",
        validation: { required: false },
      },
      numberOfPeople: {
        displayName: "Number of People",
        validation: {
          required: false,
          validate: {
            numberOnly: commonService.validations.numberOnly,
          },
          max: 999,
        },
      },
      nfirsApparatusUseTypeId: {
        displayName: "Apparatus Use",
        validation: { required: false },
      },
      nfirsActionTaken1Id: {
        displayName: "Action Taken",
        validation: { required: false },
      },
      nfirsActionTaken2Id: {
        displayName: "Action Taken",
        validation: { required: false },
      },
      _dispatchDateTime: { ...commonService.forms.datePicker },
      _clearDateTime: { ...commonService.forms.datePicker },
      _arrivalDateTime: { ...commonService.forms.datePicker },
    });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const [saving, setSaving] = useState(false);
  const submit = async (form: any) => {
    const data = {
      ...model,
      ...form,
      reportId: Number(props.data?.reportId),
      apparatusId: form.apparatusId,
      nfirsApparatusTypeId: form.nfirsApparatusTypeId
        ? parseInt(form.nfirsApparatusTypeId)
        : null,
      nfirsApparatusUseTypeId: form.nfirsApparatusUseTypeId
        ? parseInt(form.nfirsApparatusUseTypeId)
        : null,
      nfirsActionTaken1Id: form.nfirsActionTaken1Id
        ? parseInt(form.nfirsActionTaken1Id)
        : null,
      nfirsActionTaken2Id: form.nfirsActionTaken2Id
        ? parseInt(form.nfirsActionTaken2Id)
        : null,
      numberOfPeople: form.numberOfPeople
        ? parseInt(form.numberOfPeople)
        : null,
      dispatchDateTime: form.dispatchDateTime
        ? moment(form.dispatchDateTime).format("YYYY-MM-DDTHH:mm")
        : null,
      arrivalDateTime: form.arrivalDateTime
        ? moment(form.arrivalDateTime).format("YYYY-MM-DDTHH:mm")
        : null,
      clearDateTime: form.clearDateTime
        ? moment(form.clearDateTime).format("YYYY-MM-DDTHH:mm")
        : null,
    };

    setSaving(true);
    await ApsServices.http.nfirsApparatusService
      .update(data)
      .then((rtn) => {
        close(true);
        toastStore.showToast("Apparatus saved.", "success");
      })
      .catch((error) => {
        toastStore.showError("Failed saving Apparatus", error);
        setSaving(false);
      });
  };

  const [model, setModel] = useState({
    isSent: true,
  } as ApsModels.INfirsApparatusOutputDto);
  const [loading, setLoading] = useState(true);

  // const apparatusIds = useNfirsGetData(
  //   ApsServices.http.nfirsApparatusService.getApparatusIds,
  //   "Apparatus IDs"
  // );

  const apparatusTypes = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsApparatusTypes,
    "Apparatus Types"
  );

  const apparatusUseTypes = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsApparatusUseTypes,
    "Apparatus Use Types"
  );

  const actionsTaken = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsApparatusPersonnelActionTaken,
    "Actions Taken"
  );

  const actionsTakenGroup = useNfirsGetData(
    async () =>
      ApsServices.http.nfirsGenericEnumLookupService.nfirsGenericEnumLookUp(
        "NfirsActionsTakenSubsectionEnum"
      ),
    "Actions Taken Subsections"
  );

  const getDropdowns = async () => {
    //await apparatusIds.getData();
    await apparatusTypes.getData();
    await apparatusUseTypes.getData();
    await actionsTaken.getData();
    await actionsTakenGroup.getData();
  };

  const getApparatus = async () => {
    if (props.data?.reportId && props.data?.id) {
      setLoading(true);
      await ApsServices.http.nfirsApparatusService
        .get(props.data?.id, props.data?.reportId)
        .then((rtn) => {
          rtn = { ...rtn, numberOfPeople: `${rtn.numberOfPeople || 0}` as any };
          setModel(rtn);
          setValuesFromModel(rtn);
        })
        .catch((error) => {
          toastStore.showError("Failed getting Apparatus", error);
          close();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDropdowns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      //apparatusIds.status === NfirsFetchStatus.Complete &&
      apparatusTypes.status === NfirsFetchStatus.Complete &&
      apparatusUseTypes.status === NfirsFetchStatus.Complete &&
      actionsTaken.status === NfirsFetchStatus.Complete &&
      actionsTakenGroup.status === NfirsFetchStatus.Complete
    ) {
      getApparatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //apparatusIds.status,
    apparatusTypes.status,
    apparatusUseTypes.status,
    actionsTaken.status,
    actionsTakenGroup.status,
  ]);

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  return (
    <div>
      <FormModal
        title={props?.data?.id ? "Edit Apparatus" : "Add New Apparatus"}
        isOpen={true}
        close={() => close()}
        submit={handleSubmit(submit)}
        size="md"
        submitButtonLabel={
          saving ? "Saving..." : props?.data?.id ? "Yes, Update" : "Yes, Add"
        }
        closeButtonLabel="No, Cancel"
        disableSubmit={loading || saving}
      >
        <div className={`p-3 ${loading ? "" : "display-none"}`}>
          <CommonSpinner></CommonSpinner>
        </div>
        {!loading && (
          <div>
            <h4 className="txt-primary">
              <i>Apparatus</i>
            </h4>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FgInput
                  id="apparatusId"
                  label="Apparatus ID"
                  placeHolder="Apparatus ID"
                  registeredField={registry.apparatusId}
                  formState={formState}
                  onChange={(data) => {}}
                ></FgInput>
              </div>
              <div className="col-sm-12 col-md-6">
                <FgSelect
                  id="nfirsApparatusTypeId"
                  label="Apparatus Type ID"
                  selectMessage="- Select Apparatus Type ID -"
                  registeredField={registry.nfirsApparatusTypeId}
                  formState={formState}
                  onChange={(data) => {}}
                  options={(apparatusTypes?.data || []).map((opt) => {
                    return {
                      label: opt.description,
                      value: opt.id,
                    };
                  })}
                  showRequiredLabel={isOtherModuleDataRequired}
                ></FgSelect>
              </div>
              <div className="col-sm-12">
                <FgCheckbox
                  id="sent"
                  label="Sent"
                  data={model.isSent}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      isSent: data,
                    });
                    setValue("isSent", data);
                  }}
                ></FgCheckbox>
              </div>
            </div>
            <h4 className="txt-primary mt-3">
              <i>Dates &amp; Times</i>
            </h4>

            <div className="row mt-3">
              <div className="col-sm-12">
                <label>Dispatch Date &amp; Time</label>
                <DateTimePickerV2
                  onChange={(data, invalid) => {
                    setModel({
                      ...model,
                      dispatchDateTime: data,
                      _dispatchDateTime: invalid,
                    } as any);
                    setValue("dispatchDateTime", data);
                    setValue("_dispatchDateTime", invalid);
                  }}
                  data={model.dispatchDateTime}
                  showAllMinuteOptions={true}
                />
                <FormError
                  field={registry._dispatchDateTime.name}
                  formState={formState}
                ></FormError>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <label>Arrival Date &amp; Time</label>
                <DateTimePickerV2
                  onChange={(data, invalid) => {
                    setModel({
                      ...model,
                      arrivalDateTime: data,
                      _arrivalDateTime: invalid,
                    } as any);
                    setValue("arrivalDateTime", data);
                    setValue("_arrivalDateTime", invalid);
                  }}
                  data={model.arrivalDateTime}
                  showAllMinuteOptions={true}
                />
                <FormError
                  field={registry._arrivalDateTime.name}
                  formState={formState}
                ></FormError>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <label>Clear Date &amp; Time</label>
                <DateTimePickerV2
                  onChange={(data, invalid) => {
                    setModel({
                      ...model,
                      clearDateTime: data,
                      _clearDateTime: invalid,
                    } as any);
                    setValue("clearDateTime", data);
                    setValue("_clearDateTime", invalid);
                  }}
                  data={model.clearDateTime}
                  showAllMinuteOptions={true}
                />
                <FormError
                  field={registry._clearDateTime.name}
                  formState={formState}
                ></FormError>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 col-md-6">
                <FgInput
                  id="numberOfPeople"
                  label="Number of People"
                  placeHolder="Number of People"
                  registeredField={registry.numberOfPeople}
                  formState={formState}
                  onChange={(data) => {}}
                  type="number"
                  showRequiredLabel={isOtherModuleDataRequired}
                ></FgInput>
              </div>
              <div className="col-sm-12 col-md-6">
                <FgSelect
                  id="nfirsApparatusUseTypeId"
                  label="Apparatus Use"
                  selectMessage="- Select Apparatus Use -"
                  registeredField={registry.nfirsApparatusUseTypeId}
                  formState={formState}
                  onChange={(data) => {}}
                  options={(apparatusUseTypes?.data || []).map((opt) => {
                    return {
                      label: `${opt.id}. ${opt.description}`,
                      value: opt.id,
                    };
                  })}
                  showRequiredLabel={isOtherModuleDataRequired}
                ></FgSelect>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FgSelect
                  id="nfirsActionTaken1Id"
                  label="Action Taken"
                  selectMessage="- Select Action Taken -"
                  registeredField={registry.nfirsActionTaken1Id}
                  formState={formState}
                  onChange={(data) => {}}
                  options={(actionsTaken?.data || []).map((opt) => {
                    return {
                      label: `${opt.externalId}. ${opt.description}`,
                      value: opt.id,
                      groupId: opt.nfirsActionsTakenSubsectionEnum,
                    };
                  })}
                  hideGroupIfEmpty={true}
                  groups={(actionsTakenGroup?.data || []).map((opt) => {
                    return {
                      id: opt.value,
                      label: opt.label,
                    };
                  })}
                ></FgSelect>
              </div>
              <div className="col-sm-12 col-md-6">
                <FgSelect
                  id="nfirsActionTaken2Id"
                  label="Action Taken"
                  selectMessage="- Select Action Taken -"
                  registeredField={registry.nfirsActionTaken2Id}
                  formState={formState}
                  onChange={(data) => {}}
                  options={(actionsTaken?.data || []).map((opt) => {
                    return {
                      label: `${opt.externalId}. ${opt.description}`,
                      value: opt.id,
                      groupId: opt.nfirsActionsTakenSubsectionEnum,
                    };
                  })}
                  hideGroupIfEmpty={true}
                  groups={(actionsTakenGroup?.data || []).map((opt) => {
                    return {
                      id: opt.value,
                      label: opt.label,
                    };
                  })}
                ></FgSelect>
              </div>
            </div>
          </div>
        )}
      </FormModal>
    </div>
  );
}

export default NFIRSDialogApparatus;
