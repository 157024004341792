import ApsModels from "../../models";
import fetcher from "./Fetcher";

class ISOReportService {
  getAllReports = async () => {
    const url = `/api/IsoReport/GetAllReports`;
    return fetcher.get<ApsModels.IIsoReportListItemDto[]>(url);
  };

  overviewReport = async (data: ApsModels.IIsoTrainingReportParametersDto) => {
    const url = `/api/IsoReport/IsoOverviewReport`;
    return fetcher.post<ApsModels.IIsoReportOverviewDto>(url, data);
  };

  detailReport = async (data: ApsModels.IIsoTrainingReportParametersDto) => {
    const url = `/api/IsoReport/IsoDetailReport`;
    return fetcher.post<ApsModels.IIsoReportDetailDto>(url, data);
  };

  ownDashboardReportAsync = async () => {
    const url = `/api/IsoReport/OwnDashboardReportAsync`;
    return fetcher.get<ApsModels.IIsoReportDetailItemDto>(url);
  };

  userDashboardReportAsync = async (userId: number) => {
    const url = `/api/IsoReport/UserDashboardReportAsync/${userId}`;
    return fetcher.get<ApsModels.IIsoReportDetailItemDto>(url);
  };

  extractIsoDetailReport = async (
    data: ApsModels.IIsoTrainingReportParametersDto
  ) => {
    const url = `/api/IsoReport/ExtractIsoDetailReport`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  isoIndividualDetailCoursesReport = async (
    data: ApsModels.IIsoIndividualReportParametersDto
  ) => {
    const url = `/api/IsoReport/IsoIndividualDetailCoursesReport`;
    return fetcher.post<ApsModels.IIsoIndividualDetailReportDto>(url, data);
  };

  extractIsoIndividualDetailCoursesReport = async (
    data: ApsModels.IIsoIndividualReportParametersDto
  ) => {
    const url = `/api/IsoReport/ExtractIsoIndividualDetailCoursesReport`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };
}

const isoReport = new ISOReportService();
export default isoReport;
