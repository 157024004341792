class PdfService {
    async downloadPdf(date: Date, departmentId:Number): Promise<Response> {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ date: date, departmentId: departmentId, timeStamp: (new Date()).toLocaleString() })
        };
        return fetch(`/Api/Pdf/download`, options);
    }
}

const pdfService = new PdfService();
export { pdfService as PdfService }
