import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import FileDragNDrop from "../Common/FileDragNDrop";
import FormError from "../Common/FormError";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  IBasicDialogProps,
} from "../Common/FormGroups";
import FormModal from "../Common/FormModal";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";

function CredentialRequirementDialog(
  props: IBasicDialogProps<{
    id?: number;
    credentialRequirementCategory: ApsModels.CredentialRequirementEnum;
    requirement: ApsModels.ICredTakenCredentialRequirementOutputDto;
  }>
) {
  const isCourse = () => {
    return (
      props.data?.credentialRequirementCategory ===
      ApsModels.CredentialRequirementEnum.Courses
    );
  };

  const isOther = () => {
    return (
      props.data?.credentialRequirementCategory ===
      ApsModels.CredentialRequirementEnum.Other
    );
  };

  const [loading, setLoading] = useState(true);
  const categories = useNfirsGetData(
    async () =>
      ApsServices.http.credCommon.credGenericEnumLookUp(
        "CredentialRequirementCategoryEnum"
      ),
    "Categories"
  );

  const [takenDates, setTakenDates] = useState<{
    takenFromDate: Date;
    takenToDate: Date;
  }>();

  const credReq = useNfirsGetData(async () => {
    return ApsServices.http.credCredential.getIndividualTakenRequirement(
      props.data?.requirement.id as any,
      props.data?.id as any
    );
  }, "Credential Requirement");

  const trainingTypesList = useNfirsGetData(
    ApsServices.http.credCommon.getTrainingType,
    "Training Types"
  );

  useEffect(() => {
    credReq.getData();
    const req = props.data?.requirement;
    setModel((p) => {
      return {
        ...p,
        ...req,
      };
    });

    categories.getData();
    if (isCourse()) {
      trainingTypesList.getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (credReq.status === NfirsFetchStatus.Complete && credReq.data) {
      setModel((p) => {
        return {
          ...p,
          ...credReq.data,
          takenToDateString: credReq.data?.takenToDate
            ? commonService.toStartOfDayDateString(credReq.data?.takenToDate)
            : (undefined as any),
          takenFromDateString: credReq.data?.takenFromDate
            ? commonService.toStartOfDayDateString(credReq.data?.takenFromDate)
            : (undefined as any),
          instructor: credReq.data?.instructor || "",
          locationTaken: credReq.data?.locationTaken || "",
          additionalInformation: credReq.data?.additionalInformation || "",
        };
      });
      setValuesFromModel({
        ...credReq.data,
        instructor: credReq.data?.instructor || "",
        locationTaken: credReq.data?.locationTaken || "",
        additionalInformation: credReq.data?.additionalInformation || "",
      });

      setTakenDates({
        takenFromDate: credReq.data.takenFromDate,
        takenToDate: credReq.data.takenToDate,
      });

      setLoading(false);

      setTimeout(() => {
        document.getElementById("hours")?.focus();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credReq.status]);

  const { registry, handleSubmit, formState, setValuesFromModel } = FgUseForm({
    additionalInformation: {
      displayName: "Additional Information",
      validation: {
        required: false,
      },
    },
    hours: {
      displayName: "Hours",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (isCourse() && model.courseCharacteristicsDto?.isEmsCe) {
              return (Number(val) || 0) > 0;
            }
            return true;
          },
        },
      },
    },
    providerNumber: {
      displayName: "CE Provider Number",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (isCourse() && model.courseCharacteristicsDto?.isEmsCe) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    dateTaken: {
      displayName: "Date Taken",
      validation: {
        required: false,
        validate: {
          dateTakenError: (val: any) => {
            if (commonService.isNullOrEmpty(model.takenFromDateString)) {
              return "Start and End Dates are required";
            }
            if (commonService.isNullOrEmpty(model.takenToDateString)) {
              return "Both Dates are required";
            }
            if (model.takenFromDateString > model.takenToDateString) {
              return "End Date must be greater than Start Date";
            }
            return true;
          },
        },
      },
    },
    locationTaken: {
      displayName: "Location Taken",
      validation: {
        required: false,
      },
    },
    instructor: {
      displayName: "Instructor",
      validation: {
        required: false,
      },
    },
    trainingTypeId: {
      displayName: "Training Type",
      validation: {
        required: false,
      },
    },
  });

  const [model, setModel] =
    useState<ApsModels.ICredIndividualRequirementUpdateDto>({} as any);

  const [saving, setSaving] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const submit = async (data: ApsModels.ICredTakenCourseUpdateDto) => {
    let action: Promise<any>;
    const req = props.data?.requirement;
    const jsonData = {
      id: req?.id,
      takenCredentialId: props.data?.id,
      credentialRequirementEnum: props.data?.credentialRequirementCategory,
      hours: model.hours,      
      providerNumber: model.providerNumber,
      takenFromDateString: model.takenFromDateString,
      takenToDateString: model.takenToDateString,
      idOfFilesToDelete: filesToDelete,
      concurrencyToken: req?.concurrencyToken,
      instructor: model.instructor || "",
      locationTaken: model.locationTaken || "",
      trainingTypeId: model.trainingTypeId || null,
      additionalInformation: model.additionalInformation,
      courseCharacteristicsDto: {        
        isEmsCe: false,
        isIsa: false,
        isIso: false,
        isJac: false,
      },
    } as ApsModels.ICredIndividualRequirementUpdateDto;

    if (claims.course.canAddAll) {
      action = ApsServices.http.credCredential.adminUpdateIndividualRequirement(
        files,
        jsonData
      );
    } else {
      action = ApsServices.http.credCredential.updateIndividualRequirement(
        files,
        jsonData
      );
    }

    setSaving(true);
    await action
      .then((rtn) => {
        setSaving(false);
        setFiles([]);
        toastStore.showToast("Course Saved", "success");
        props.onClose(true);
      })
      .catch((err) => {
        setSaving(false);
        const errCode = commonService.getErrorStatusCode(err);
        if (errCode === 409 || errCode === 404) {
          props.onClose();
        } else {
          toastStore.showError("Failed Saving Course.", err);
        }
      });
  };

  const [claims] = useState(commonService.friendlyClaims);

  return (
    <div>
      <FormModal
        title="Requirement"
        isOpen={true}
        close={() => props.onClose()}
        submit={handleSubmit(submit)}
        size="md"
        closeButtonLabel="Cancel"
        submitButtonLabel={saving ? "Submitting..." : "Submit"}
        disableSubmit={saving}
      >
        <div>
          {loading && <CommonSpinner message="Loading..."></CommonSpinner>}
          <form
            className={`flex flex-1 flex-col ${loading ? "display-none" : ""}`}
            onSubmit={handleSubmit(submit)}
          >
            <div>
              <div className="pb-2">
                <label>
                  {categories.data?.find(
                    (c) => c.value === props.data?.credentialRequirementCategory
                  )?.label || "..."}
                  :
                </label>{" "}
                {props.data?.requirement.name}
              </div>

              <FgInput
                id="hours"
                label="Hours"
                placeHolder="Hours"
                showRequiredLabel={
                  isCourse() && model.courseCharacteristicsDto?.isEmsCe
                }
                registeredField={registry.hours}
                formState={formState}
                autoFocus={true}
                onChange={(data) => {
                  setModel((p: any) => {
                    return {
                      ...p,
                      hours: commonService.getNumber(data),
                    };
                  });
                }}
                type="number"
                step="0.01"
              ></FgInput>

              {isCourse() && (
                <>
                  <div className="mb-3">
                    <label>Course Qualify for EMS CE</label>
                    <div className="inline-radio-box-group">
                      <div>
                        <input
                          type="radio"
                          name="qualifyForEmsCe"
                          id="qualifyForEmsCeYes"
                          value="1"
                          checked={
                            model.courseCharacteristicsDto?.isEmsCe || false
                          }
                          onChange={(event) => {
                            setModel((p: any) => {
                              return {
                                ...p,
                                courseCharacteristicsDto: {
                                  ...(p.courseCharacteristicsDto || {}),
                                  isEmsCe: true,
                                },
                              };
                            });
                          }}
                        ></input>
                        <label htmlFor="qualifyForEmsCeYes">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="qualifyForEmsCe"
                          id="qualifyForEmsCeNo"
                          value="0"
                          checked={
                            !model.courseCharacteristicsDto?.isEmsCe || false
                          }
                          onChange={(event) => {
                            setModel((p: any) => {
                              return {
                                ...p,
                                courseCharacteristicsDto: {
                                  ...(p.courseCharacteristicsDto || {}),
                                  isEmsCe: false,
                                },
                              };
                            });
                          }}
                        ></input>
                        <label htmlFor="qualifyForEmsCeNo">No</label>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      model.courseCharacteristicsDto?.isEmsCe
                        ? ""
                        : "display-none"
                    }
                  >
                    <FgInput
                      id="providerNumber"
                      label="CE Provider Number"
                      placeHolder="CE Provider Number"
                      registeredField={registry.providerNumber}
                      formState={formState}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            providerNumber: data,
                          };
                        });
                      }}
                      showRequiredLabel={true}
                    ></FgInput>
                  </div>
                </>
              )}
            </div>

            <div>
              {!loading && (
                <div className="mb-3">
                  <label className="required-label">Date Taken</label>
                  <div className="flex">
                    <DateTimePickerV2
                      data={takenDates?.takenFromDate || null}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            takenFromDateString:
                              commonService.toStartOfDayDateString(data),
                            takenToDateString:
                              commonService.toStartOfDayDateString(data),
                          };
                        });
                        setTakenDates((prev) => {
                          return {
                            ...(prev || ({} as any)),
                            takenToDate: data,
                          };
                        });
                      }}
                      dateOnly={true}
                      className="flex-1"
                    ></DateTimePickerV2>
                    <div className="flex-0 py-2 px-1">-</div>
                    <DateTimePickerV2
                      key={`${takenDates?.takenToDate || ""}`}
                      data={takenDates?.takenToDate || null}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            takenToDateString:
                              commonService.toStartOfDayDateString(data),
                          };
                        });
                      }}
                      dateOnly={true}
                      className="flex-1"
                    ></DateTimePickerV2>
                  </div>
                  <FormError
                    formState={formState}
                    field={registry.dateTaken.name}
                  ></FormError>
                </div>
              )}

              {isCourse() && (
                <>
                  <FgInput
                    id="locationTaken"
                    label="Location"
                    placeHolder="Location"
                    registeredField={registry.locationTaken}
                    formState={formState}
                    autoFocus={true}
                    onChange={(data) => {
                      setModel((p: any) => {
                        return {
                          ...p,
                          locationTaken: data,
                        };
                      });
                    }}
                    maxLength={64}
                  ></FgInput>
                  <FgInput
                    id="instructor"
                    label="Instructor Name"
                    placeHolder="Instructor Name"
                    registeredField={registry.instructor}
                    formState={formState}
                    autoFocus={true}
                    onChange={(data) => {
                      setModel((p: any) => {
                        return {
                          ...p,
                          instructor: data,
                        };
                      });
                    }}
                    maxLength={64}
                  ></FgInput>
                  <FgSelect
                    id="trainingType"
                    label="Training Type (ISO Codes)"
                    selectMessage="- Select Training Type -"
                    options={
                      trainingTypesList.data?.map((cat) => {
                        return {
                          label: cat.name,
                          value: cat.id,
                        };
                      }) || []
                    }
                    onChange={(data) => {
                      setModel((p) => {
                        return {
                          ...p,
                          trainingTypeId: commonService.getNumber(data),
                        } as any;
                      });
                    }}
                    registeredField={registry.trainingTypeId}
                    formState={formState}
                  />
                </>
              )}

              {isOther() && (
                <FgInput
                  id="additionalInformation"
                  label="Additional Information"
                  placeHolder="Additional Information"
                  registeredField={registry.additionalInformation}
                  formState={formState}
                  autoFocus={true}
                  onChange={(data) => {
                    setModel((p: any) => {
                      return {
                        ...p,
                        additionalInformation: data,
                      };
                    });
                  }}
                  rows={4}
                ></FgInput>
              )}

              <div className="mb-3">
                <label>Requirement Documents</label>
                <div>
                  <FileDragNDrop
                    label="Add File"
                    disabled={saving}
                    onChange={(fileList) => {
                      if (fileList.length > 0) {
                        const list = [...files];
                        let cnt = 0;
                        while (cnt < fileList.length) {
                          const file = fileList[cnt];
                          list.push(file);
                          cnt++;
                        }
                        setFiles(list);
                      }
                    }}
                  />
                </div>
                <div>
                  {props.data?.requirement?.credFileDtos?.map((cf) => (
                    <span
                      key={cf.id}
                      className="alert alert-secondary m-0 mt-2 flex flex-center"
                    >
                      <i
                        title="Download File"
                        className="mr-2 fa fa-download text-primary pointer"
                        onClick={() => {
                          if (props.data?.id && props.data?.requirement) {
                            ApsServices.http.credCredential
                              .downloadTakenCredentialRequirementCertificate(
                                props.data?.requirement.id,
                                props.data.id,
                                cf.id
                              )
                              .then((data) => {
                                const file = commonService.b64toBlob(
                                  data.fileContents,
                                  "text/plain"
                                );
                                FileSaver.saveAs(file, data.fileDownloadName);
                              })
                              .catch((error) => {
                                toastStore.showError(
                                  "Failed to Download File",
                                  error
                                );
                              });
                          }
                        }}
                      ></i>
                      <span className="flex-1 overflow-hidden">
                        {filesToDelete.includes(cf.id) ? (
                          <s className="text-danger">
                            <i>{cf.fileName}</i>
                          </s>
                        ) : (
                          <span>{cf.fileName}</span>
                        )}
                      </span>
                      {filesToDelete.includes(cf.id) ? (
                        <i
                          title="Undo"
                          className="ml-2 fa fa-undo text-primary pointer"
                          onClick={() => {
                            setFilesToDelete(
                              filesToDelete.filter((f) => f !== cf.id)
                            );
                          }}
                        ></i>
                      ) : (
                        <i
                          title="Delete File"
                          className="ml-2 fa fa-trash text-danger pointer"
                          onClick={() => {
                            setFilesToDelete([...filesToDelete, cf.id]);
                          }}
                        ></i>
                      )}
                    </span>
                  ))}
                  {files?.map((f, i) => (
                    <span
                      key={i}
                      className="alert alert-info p-2 px-3 m-0 mt-2 flex flex-center"
                    >
                      <span className="flex-1 overflow-hidden">{f.name}</span>
                      {!saving && (
                        <i
                          title="Remove File"
                          className="mr-1 fa fa-times pointer"
                          onClick={() => {
                            const list = [...files];
                            list.splice(i, 1);
                            setFiles(list);
                          }}
                        ></i>
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </form>
        </div>
      </FormModal>
    </div>
  );
}

export default CredentialRequirementDialog;
