import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSPersonnelService {
  get = async (id: any, reportId: any) => {
    const url = `/api/NfirsPersonnel/${id}/${reportId}`;
    return fetcher.get<ApsModels.INfirsPersonnelOutputDto>(url);
  };

  delete = async (id: any, reportId: any, concurrencyToken: any) => {
    const url = `/api/NfirsPersonnel/${id}/${reportId}/${concurrencyToken}`;
    return fetcher.delete<any>(url);
  };

  list = async (page: any, pageSize: any, reportId: any) => {
    const url = `/api/NfirsPersonnel/List/${page}/${pageSize}/${reportId}`;
    return fetcher.get<ApsModels.INfirsPersonnelGridOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsPersonnelInputDto) => {
    const url = `/api/NfirsPersonnel`;
    return fetcher.post<ApsModels.INfirsPersonnelInputDto>(url, data);
  };
}

const nfirsPersonnelService = new NFIRSPersonnelService();
export default nfirsPersonnelService;
