import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import toastStore from "../../../stores/ToastStore";
import { FgInput, FgUseForm, IBasicDialogProps } from "../../Common/FormGroups";
import FormModal from "../../Common/FormModal";

function NewCourseCategoryDialog(props: IBasicDialogProps<any>) {
  const [model, setModel] = useState(
    {} as ApsModels.ICredCourseCategoryEntryAddDto
  );
  const [saving, setSaving] = useState(false);
  const { registry, handleSubmit, formState } = FgUseForm({
    description: {
      displayName: "Description",
      validation: { required: true },
    },
  });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const submit = async (form: any) => {
    setSaving(true);
    await ApsServices.http.credCourseRecord
      .addNewCategory(model)
      .then((data) => {
        toastStore.showToast("Category Saved", "success");
        props.onChange && props.onChange(data);
        setSaving(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Category", err);
        setSaving(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("description")?.focus();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title="New Course Category"
      isOpen={true}
      close={() => close()}
      submit={handleSubmit(submit)}
      submitButtonLabel={saving ? "Saving..." : "Save"}
      closeButtonLabel="Cancel"
      disableSubmit={saving}
    >
      <div className="row">
        <div className="col-sm-12">
          <FgInput
            id="description"
            label="Description"
            placeHolder="Description"
            registeredField={registry.description}
            formState={formState}
            onChange={(data) => {
              setModel({
                ...model,
                description: data,
              });
            }}
            showRequiredLabel={true}
          ></FgInput>
        </div>
      </div>
    </FormModal>
  );
}

export default NewCourseCategoryDialog;
