import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface IFormModalProps {
  children: any;
  isOpen: boolean;
  close: () => void;
  submit: (data: any) => void;
  deleteAction?: () => void;
  title: string;
  submitButtonLabel?: string;
  closeButtonLabel?: string;
  size?: string;
  titleIcon?: string;
  disableSubmit?: boolean;
  showDeleteButton?: boolean;
  deleteButtonLabel?: string;
  disabledDeleteButton?: boolean;
  moveBehind?: boolean;
  primaryButtonOnly?: boolean;
}

function FormModal({
  children,
  isOpen,
  close,
  submit,
  deleteAction,
  title,
  submitButtonLabel,
  closeButtonLabel,
  size,
  titleIcon,
  disableSubmit,
  showDeleteButton,
  deleteButtonLabel,
  disabledDeleteButton,
  moveBehind,
  primaryButtonOnly,
}: IFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      tabIndex={-1}
      keyboard={true}
      size={size || "md"}
    >
      {moveBehind && <div className="formModalOverlay"></div>}
      <div className="modal-content">
        <ModalHeader>
          {titleIcon && <i className={`pr-2 fa fa-${titleIcon} mr-1`}></i>}
          {title}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {showDeleteButton && !primaryButtonOnly && (
            <>
              <button
                type="button"
                className="btn btn-outline-danger"
                disabled={disabledDeleteButton}
                onClick={deleteAction}
              >
                {deleteButtonLabel || "Delete"}
              </button>
            </>
          )}
          {!primaryButtonOnly && (
            <Button type="button" onClick={close} color="secondary">
              {closeButtonLabel || "Close"}
            </Button>
          )}
          <Button
            type="button"
            onClick={submit}
            color="primary"
            disabled={disableSubmit}
          >
            {submitButtonLabel || "Submit"}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

export default FormModal;
