import React, { useEffect, useMemo, useState } from "react";
import {
  FgCheckbox,
  FgInput,
  FgUseForm,
  IBasicDialogProps,
} from "../../../aps2/components/Common/FormGroups";
import ApsModels from "../../../aps2/models";
import commonService from "../../../aps2/services/CommonService";
import FormModal from "../../../aps2/components/Common/FormModal";

function AddCustomNotificationDialog(
  props: IBasicDialogProps<{
    templateId: any;
    model?: ApsModels.ICredNotificationDetailDto;
  }>
) {
  const [model, setModel] = useState<ApsModels.ICredNotificationDetailDto>(
    {} as any
  );

  const isNewRow = (id: string) => {
    return id.indexOf("NEW-") === 0;
  };

  useEffect(() => {
    if (props.data?.model) {
      const newModel = {
        ...props.data.model,
        // enableNotifications:
        //   props.data.model?.enableNotifications === undefined
        //     ? true
        //     : props.data.model?.enableNotifications,
      };

      setModel({
        ...newModel,
        _daysBeforeAssignmentDueDate: !commonService.isNullOrWhitespace(
          newModel.daysBeforeAssignmentDueDate
        ),
        _daysAfterAssignmentDueDate: !commonService.isNullOrWhitespace(
          newModel.daysAfterAssignmentDueDate
        ),
      } as any);

      setValuesFromModel({
        ...newModel,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { registry, handleSubmit, formState, setValuesFromModel, formRef } =
    FgUseForm({
      /*
       * This is where we add fields for validation
       */
      notifyOtherEmailAddresses: {
        displayName: "Notify Other Email(s)",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if (model.notifyOther) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateEmailCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
      daysBeforeAssignmentDueDate: {
        displayName: "Days Before Assignment Due Date",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if ((model as any)._daysBeforeAssignmentDueDate) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateNumbersOnlyCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
      daysAfterAssignmentDueDate: {
        displayName: "Days After Assignment Due Date",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if ((model as any)._daysAfterAssignmentDueDate) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateNumbersOnlyCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
    });

  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const submit = async (form: any) => {
    if (props.onChange) {
      const data = { ...model };
      if (!(model as any)._daysAfterAssignmentDueDate) {
        data.daysAfterAssignmentDueDate = "";
      }
      if (!(model as any)._daysBeforeAssignmentDueDate) {
        data.daysBeforeAssignmentDueDate = "";
      }
      props.onChange({
        templateId: 0,
        model: data,
      });
    }
  };

  const hasValues = useMemo(() => {
    return (
      [
        commonService.isNullOrWhitespace(model.daysBeforeAssignmentDueDate)
          ? ""
          : "1",
        commonService.isNullOrWhitespace(model.daysAfterAssignmentDueDate)
          ? ""
          : "1",
        model.notifyOnAssignmentDate ? "1" : "",
        model.notifyUponEnrollment ? "1" : "",
        model.notifyUponCompletion ? "1" : "",
        //model.notifyOnExpirationDate ? "1" : "",
      ].filter((x) => !commonService.isNullOrEmpty(x)).length > 0 &&
      [
        model.notifyAccountAdmins ? "1" : "",
        model.notifyImmediateSupervisors ? "1" : "",
        model.notifyParticipants ? "1" : "",
        model.notifyOther ? model.notifyOtherEmailAddresses : "",
      ].filter((x) => !commonService.isNullOrEmpty(x)).length > 0
    );
  }, [model]);

  return (
    <FormModal
      title="Setup Notification"
      isOpen={true}
      close={() => close()}
      submit={handleSubmit(submit)}
      submitButtonLabel={isNewRow(model.detailId || "") ? "Add" : "Update"}
      closeButtonLabel="Cancel"
      disableSubmit={!hasValues}
      size="lg"
    >
      {/* <div className="row">
        <div className="col col-sm-12 pt-2 pb-4">
          <FgCheckbox
            id="enableNotifications"
            label="Enable notifications"
            data={model.enableNotifications || false}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  enableNotifications: data || false,
                };
              });
            }}
            disabled={false}
          />
        </div>
      </div> */}
      <div className="row pt-2">
        <div className="col col-sm-12 col-md-6">
          <div className="mb-3 text-primary">
            <strong>When to Notify</strong>
          </div>

          <FgCheckbox
            id="notifyUponEnrollment"
            label="Notify upon assignment or renewal assignment"
            data={model.notifyUponEnrollment}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyUponEnrollment: data || false,
                };
              });
            }}
            disabled={false}
          />
          <div className="mb-3"></div>

          <div className="mb-3">
            <div className="pe-0 mb-1">
              <FgCheckbox
                id="_daysBeforeAssignmentDueDate"
                label="Notify days before due date"
                data={(model as any)._daysBeforeAssignmentDueDate || false}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      _daysBeforeAssignmentDueDate: data || false,
                    };
                  });
                }}
                disabled={false}
              />
            </div>
            <div
              className={
                (model as any)._daysBeforeAssignmentDueDate
                  ? "pt-1"
                  : "display-none"
              }
            >
              <FgInput
                id="daysBeforeAssignmentDueDate"
                formGroupClass="mb-1"
                registeredField={registry.daysBeforeAssignmentDueDate}
                formState={formState}
                disabled={!(model as any)._daysBeforeAssignmentDueDate || false}
                placeHolder="Enter number of day separated by comma (#,#,#,#)"
                onChange={(val) => {
                  setModel((p) => {
                    return {
                      ...p,
                      daysBeforeAssignmentDueDate: val,
                    };
                  });
                }}
              />
            </div>
          </div>

          <FgCheckbox
            id="notifyOnAssignmentDate"
            label="Notify on due date"
            data={model.notifyOnAssignmentDate}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyOnAssignmentDate: data || false,
                };
              });
            }}
            disabled={false}
          />
          <div className="mb-3"></div>

          <div className="mb-3">
            <div className="pe-0 mb-1">
              <FgCheckbox
                id="_daysAfterAssignmentDueDate"
                label="Notify days after due date"
                data={(model as any)._daysAfterAssignmentDueDate || false}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      _daysAfterAssignmentDueDate: data || false,
                    };
                  });
                }}
                disabled={false}
              />
            </div>
            <div
              className={
                (model as any)._daysAfterAssignmentDueDate
                  ? "mb-1"
                  : "display-none"
              }
            >
              <FgInput
                id="daysAfterAssignmentDueDate"
                formGroupClass="pt-1"
                registeredField={registry.daysAfterAssignmentDueDate}
                formState={formState}
                disabled={!(model as any)._daysAfterAssignmentDueDate || false}
                placeHolder="Enter number of day separated by comma (#,#,#,#)"
                onChange={(val) => {
                  setModel((p) => {
                    return {
                      ...p,
                      daysAfterAssignmentDueDate: val,
                    };
                  });
                }}
              />
            </div>
          </div>

          {/* <FgCheckbox
            id="notifyUponEnrollment"
            label="Upon enrollment"
            data={model.notifyUponEnrollment}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyUponEnrollment: data || false,
                };
              });
            }}
            disabled={false}
          />
          <div className="mb-1"></div> */}
          <FgCheckbox
            id="notifyUponCompletion"
            label="Notify upon completion or renewal completion"
            data={model.notifyUponCompletion}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyUponCompletion: data || false,
                };
              });
            }}
            disabled={false}
          />
          <div className="mb-3"></div>
        </div>
        <div className="col col-sm-12 col-md-6">
          <div className="mb-3 text-primary">
            <strong>Who to Notify</strong>
          </div>

          <FgCheckbox
            id="notifyAccountAdmins"
            label="Notify account admin(s)"
            data={model.notifyAccountAdmins}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyAccountAdmins: data || false,
                };
              });
            }}
            disabled={false}
          ></FgCheckbox>
          <div className="mb-3"></div>
          <FgCheckbox
            id="notifyImmediateSupervisors"
            label="Notify immediate supervisor(s)"
            data={model.notifyImmediateSupervisors}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyImmediateSupervisors: data || false,
                };
              });
            }}
            disabled={false}
          ></FgCheckbox>
          <div className="mb-3"></div>
          <FgCheckbox
            id="notifyParticipants"
            label="Notify participant(s)"
            data={model.notifyParticipants}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyParticipants: data || false,
                };
              });
            }}
            disabled={false}
          ></FgCheckbox>
          <div className="mb-3"></div>
          <FgCheckbox
            id="notifyOther"
            label="Notify specific individual(s)"
            data={model.notifyOther}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyOther: data || false,
                };
              });
            }}
            disabled={false}
          ></FgCheckbox>
          <div className="mb-2"></div>
          <div className={model.notifyOther ? "" : "display-none"}>
            <FgInput
              formGroupClass="mt-1"
              id="notifyOtherEmailAddresses"
              placeHolder="Enter email addresseses of each individual separated by comma"
              registeredField={registry.notifyOtherEmailAddresses}
              formState={formState}
              rows={3}
              disabled={!model.notifyOther}
              onChange={(val) => {
                setModel((p) => {
                  return {
                    ...p,
                    notifyOtherEmailAddresses: val,
                  };
                });
              }}
            />
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default AddCustomNotificationDialog;
