import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import commonService from "../../../services/CommonService";
import toastStore from "../../../stores/ToastStore";
import CommonSpinner from "../../Common/CommonSpinner";
import ConfirmDialog from "../../Common/ConfirmDialog";
import DateTimePicker from "../../Common/DateTimePicker";
import {
  FgSelect,
  FgUseForm,
  IBasicDialogProps,
} from "../../Common/FormGroups";
import FormModal from "../../Common/FormModal";

function UserEligibilityDialog(
  props: IBasicDialogProps<{ id: number; userdId: number }>
) {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState(
    {} as ApsModels.IUserLogDrillEligibilityInputDto
  );
  const { registry, handleSubmit, formState, setValue } = FgUseForm({
    eligibilityType: {
      displayName: "Type",
      validation: {
        required: true,
      },
    },
    startDate: {
      displayName: "Start Date",
      validation: {
        required: false,
      },
    },
    endDate: {
      displayName: "End Date",
      validation: {
        required: false,
      },
    },
  });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };
  const [errorCode, setErrorCode] = useState(0);
  const [forDelete, setForDelete] = useState(false);

  const submit = async (form: ApsModels.IUserLogDrillEligibilityInputDto) => {
    setSaving(true);
    await ApsServices.http.userEligibility
      .save({
        id: Number(props?.data?.id) || (null as any),
        userId: Number(props?.data?.userdId),
        eligibilityType: Number(form.eligibilityType),
        startTimeString: model.startTimeString
          ? moment(model.startTimeString).format("YYYY-MM-DDT00:00:00")
          : (null as any),
        endTimeString: model.endTimeString
          ? moment(model.endTimeString).format("YYYY-MM-DDT23:59:59")
          : (null as any),
        concurrencyToken: model.concurrencyToken,
      })
      .then((data) => {
        //props.onChange && props.onChange({} as any);
        toastStore.showToast("Eligibility record saved", "success");
        close(true);
      })
      .catch((err) => {
        setSaving(false);
        const errCode = commonService.getErrorStatusCode(err);
        if (errCode === 409 || errCode === 404) {
          setForDelete(false);
          setErrorCode(errCode);
        } else {
          toastStore.showError("Failed saving User Eligibility", err);
        }
      });
  };

  const getData = async () => {
    if (props?.data?.id && props?.data?.userdId) {
      setLoading(true);
      await ApsServices.http.userEligibility
        .get(props.data.id, props.data.userdId)
        .then((data) => {
          if (data.startTime && !data.startTimeString) {
            data.startTimeString = moment(data.startTime).format("YYYY-MM-DD");
          }
          if (data.endTime && !data.endTimeString) {
            data.endTimeString = moment(data.endTime).format("YYYY-MM-DD");
          }

          setModel({
            ...(data as any),
          });
          setValue("eligibilityType", data.eligibilityType);
          setValue("startTimeString", data.startTimeString);
          setValue("endTimeString", data.endTimeString);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toastStore.showError("Failed getting User Eligibility record", err);
        });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteRecord = async () => {
    setDeleting(true);
    await ApsServices.http.userEligibility
      .delete(model.id, model.userId, model.concurrencyToken)
      .then((data) => {
        toastStore.showToast("Eligibility record deleted", "success");
        setDeleting(false);
        close(true);
      })
      .catch((err) => {
        setDeleting(false);
        const errCode = commonService.getErrorStatusCode(err);
        if (errCode === 409 || errCode === 404) {
          setForDelete(true);
          setErrorCode(errCode);
        } else {
          toastStore.showError("Failed deleting Eligibility record", err);
        }
      });
  };

  return (
    <div>
      <ConfirmDialog
        show={!!errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(errorCode)}
        message={commonService.getConcurrencyMessage(errorCode, forDelete)}
        done={(rtn) => {
          if (errorCode === 404) {
            close(true);
          } else {
            getData();
            setErrorCode(0);
          }
        }}
      />

      <ConfirmDialog
        show={showDelete}
        buttons="yesno"
        title="Delete Eligibility"
        message="Do you really want to delete this Eligibility record?"
        done={(rtn) => {
          if (rtn === "yes") {
            deleteRecord();
          }
          setShowDelete(false);
        }}
      />
      <FormModal
        title={
          props?.data?.id
            ? `Edit Eligibility (${props?.data?.id})`
            : "New Eligibility"
        }
        isOpen={true}
        close={() => close()}
        submit={handleSubmit(submit)}
        titleIcon="user"
        submitButtonLabel={saving ? "Saving..." : "Save"}
        disableSubmit={loading || saving || deleting}
        showDeleteButton={!!props?.data?.id}
        deleteAction={() => {
          setShowDelete(true);
        }}
        deleteButtonLabel={deleting ? "Deleting..." : "Delete"}
        disabledDeleteButton={loading || saving || deleting}
        moveBehind={showDelete || errorCode > 0}
      >
        {loading && <CommonSpinner></CommonSpinner>}
        {!loading && (
          <form onSubmit={handleSubmit(submit)}>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FgSelect
                  id="eligibilityType"
                  placeHolder="Type"
                  label="Type"
                  registeredField={registry.eligibilityType}
                  formState={formState}
                  selectMessage="- Select Type -"
                  options={[
                    {
                      label: "JAC Eligible",
                      value: ApsModels.UserLogDrillEligibilityEnum.JacEligible,
                    },
                    {
                      label: "ISA Eligible",
                      value: ApsModels.UserLogDrillEligibilityEnum.IsaEligible,
                    },
                  ]}
                />
              </div>
            </div>

            <div className="mt-2"></div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label>Start Date</label>
                <DateTimePicker
                  dateOnly={true}
                  style={{ width: "100%" }}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      startTimeString: data,
                    });
                    if (data) {
                      setValue("startDate", moment(data).format("YYYY-MM-DD"));
                    } else {
                      setValue("startDate", null);
                    }
                  }}
                  data={model.startTimeString}
                  dayPickerProps={{
                    disabledDays: model.endTimeString
                      ? [
                          {
                            after: moment(model.endTimeString).toDate(),
                          },
                        ]
                      : [],
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <label>End Date</label>
                <DateTimePicker
                  dateOnly={true}
                  style={{ width: "100%" }}
                  onChange={(data) => {
                    setModel({
                      ...model,
                      endTimeString: data,
                    });
                    if (data) {
                      setValue("endDate", moment(data).format("YYYY-MM-DD"));
                    } else {
                      setValue("endDate", null);
                    }
                  }}
                  data={model.endTimeString}
                  dayPickerProps={{
                    disabledDays: model.startTimeString
                      ? [
                          {
                            before: moment(model.startTimeString).toDate(),
                          },
                        ]
                      : [],
                  }}
                />
              </div>
            </div>

            <div className="mt-4"></div>
          </form>
        )}
      </FormModal>
    </div>
  );
}

export default UserEligibilityDialog;
