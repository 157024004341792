import { cloneDeep } from 'lodash';
import React from 'react';
import {
    AnswerDto,
    AnswerType,
    QuestionDto,
    QuestionGroupDto
} from '../../../data/DailyOperational';
import { AppContext } from "../../../AppContext";
import { PullFromPreviousDayButton } from "../CustomInput/PullFromPreviousDayButton";
import { PreviousDayService } from '../../../services/PreviousDayService';

type Props = {
    questionGroup?: QuestionGroupDto,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    date: Date
    moduleOrder: number;
}

type State = {}

export class BadgeBand extends React.Component<Props, State> {
    static contextType = AppContext;

    componentDidMount = () => {
        const flagAnswerValue = this.getFlagAnswerValue(this.props.questionGroup);

        if (!this.isAnswerExists()) {
            this.handleBadgeBandChanged(flagAnswerValue);
        }
    }

    componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
        const previousFlagValue = this.getFlagAnswerValue(prevProps.questionGroup);
        const currentFlagValue = this.getFlagAnswerValue(this.props.questionGroup);

        if (previousFlagValue !== currentFlagValue) {
            this.handleBadgeBandChanged(currentFlagValue);
        }
    }

    private updateQuestionGroup(newQuestionValue: QuestionDto) {
        const questionGroup = cloneDeep(this.props.questionGroup)!;
        const questionIndex = questionGroup.questions.findIndex(q => q.order == newQuestionValue.order);
        questionGroup.questions[questionIndex] = newQuestionValue;
        this.props.setData(questionGroup, this.props.moduleOrder);
    }

    private handleBadgeBandChanged = (value: boolean): void => {
        const questionOrder = 0;
        const question: QuestionDto = cloneDeep(this.props.questionGroup!.questions.find(q => q.order == questionOrder)!);
        const isAnswerExists = question.answers.length > 0;
        const answerId: number = question!.answers[0] ? question!.answers[0].answerId! : 0;

        let answer: AnswerDto;

        if (isAnswerExists || answerId > 0) {
            answer = question.answers.find(a => a.answerId == answerId)!;
            answer.flagAnswerValue = value;
            answer.date = this.props.date;
            answer.dateCreated = this.props.date;
            answer.dateUpdated = this.props.date;
        } else {
            answer = {
                answerId: answerId,
                answerType: AnswerType.Flag,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                flagAnswerValue: value,
                order: 0,
                questionId: question.questionId ?? 0
            }
            question.answers.push(answer);
        }
        this.updateQuestionGroup(question);
    }

    getFlagAnswerValue(questionGroup: QuestionGroupDto | undefined) {
        return questionGroup?.questions.find(x => x.order === 0)?.answers.find(x => x.order === 0)?.flagAnswerValue || false;
    }

    isAnswerExists(): boolean {
        const answer = this.props.questionGroup?.questions.find(x => x.order === 0)?.answers.find(x => x.order === 0);
        return answer !== undefined;
    }

    handlePullFromPreviousDay = async (newQuestionGroupValue: QuestionGroupDto) => {
        this.props.setData(newQuestionGroupValue, this.props.moduleOrder);
    }

    render() {
        const value: boolean = this.getFlagAnswerValue(this.props.questionGroup);
        return (
            <div className="daily-operational-card card box-card">
                <div className="card-body">
                    <div className="row">
                        <h4 className="mb-0 font-size-16 col-xl-10 col-10">{this.props.questionGroup?.name || ''}</h4>
                        {PreviousDayService.isFeatureAvailable(this.props.date) && <PullFromPreviousDayButton questionGroupId={this.props.questionGroup?.questionGroupId!} calendarDate={this.props.date} setData={this.handlePullFromPreviousDay} />}
                    </div>
                    <div className="mt-4">
                        <div className="form-check form-check-inline mr-5">
                            <input disabled={!this.context.isEditableDailyOperational} className="form-check-input" type="radio" name="badgeBandOptions" onChange={() => this.handleBadgeBandChanged(true)} id="inlineRadio1" checked={value} />
                            <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input disabled={!this.context.isEditableDailyOperational} className="form-check-input" type="radio" name="badgeBandOptions" onChange={() => this.handleBadgeBandChanged(false)} id="inlineRadio2" checked={!value} />
                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
