import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSSearchFilterService {
  getModules = async () => {
    const url = `/api/NFIRSSearchFilter/GetModules`;
    return fetcher.get<ApsModels.INfirsSearchFieldsModuleDto[]>(url);
  };

  getTabs = async (moduleId: any) => {
    const url = `/api/NFIRSSearchFilter/GetTabs/${moduleId}`;
    return fetcher.get<ApsModels.INfirsSearchFieldsTabDto[]>(url);
  };

  getColumns = async (moduleId: any, tabId: any) => {
    const url = `/api/NFIRSSearchFilter/GetColumns/${moduleId}/${tabId}`;
    return fetcher.get<ApsModels.INfirsSearchFieldsColumnDto[]>(url);
  };
}

const nfirsSearchFilter = new NFIRSSearchFilterService();
export default nfirsSearchFilter;
