import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSWildlandIgnitionService {
  get = async (id: any) => {
    const url = `/api/NfirsWildlandIgnition/${id}`;
    return fetcher.get<ApsModels.INfirsWildlandIgnitionOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsWildlandIgnitionInputDto) => {
    const url = `/api/NfirsWildlandIgnition`;
    return fetcher.post<ApsModels.INfirsWildlandIgnitionOutputDto>(url, data);
  };
}

const nfirsWildlandIgnition = new NFIRSWildlandIgnitionService();
export default nfirsWildlandIgnition;
