import React, { Component } from 'react';
import { Row, Col, CardBody, Card, Container, Alert } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Logo from '../../assets/images/aps.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../AppContext';
import { AuthenticationService } from '../../services/AuthenticationService';
import moment from 'moment';
import { AccountInfo } from '@azure/msal-browser';
import { MicrosoftAuthenticationButton } from "../../sso/microsoft/microsoft-authentication-component";
import packageInfo from "./../../../package.json"

type Props = RouteComponentProps & {};

type State = {
    username: string,
    password: string,
    isSubmitting: boolean,
    errorLoggingIn: boolean,
    isMicrosoftSubmitting: boolean,
    errorMicrosoftLoggingIn: boolean,
};

class Login extends Component<Props, State>{
    static displayName = Login.name;
    static contextType = AppContext;

    state: State = {
        username: "",
        password: "",
        isSubmitting: false,
        errorLoggingIn: false,
        isMicrosoftSubmitting: false,
        errorMicrosoftLoggingIn: false
    };

    handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            username: e.target.value,
        })
    };

    handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: e.target.value,
        })
    };

    handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState({ errorLoggingIn: false });

        this.setState({ isSubmitting: true });

        const result = await AuthenticationService.login(this.state.username, this.state.password);
        this.setState({ isSubmitting: false });
        const params = new URLSearchParams(this.props.location.search);
        const returnUrl = params.get('ReturnUrl');

        if (result.isVerified) {
            if (returnUrl) {
                if (returnUrl.startsWith('/connect/authorize/callback')) {
                    window.location.href = returnUrl;
                }
                else {
                    this.props.history.push(returnUrl);
                }
            }
            else {
                this.props.history.push('/');
            }

        }
        else if (result.userRequiresPasswordReset) {
            this.props.history.push(`/account/changepassword/?username=${encodeURIComponent(this.state.username)}&returnUrl=${encodeURIComponent(returnUrl ?? "")}`);
        }
        else {
            this.setState({ errorLoggingIn: true });
        }
    }
    
    handleMicrosoftLogin = async(userAccountInfo: AccountInfo | null, idToken: string | null) => {
        this.setState({ errorMicrosoftLoggingIn: false });

        this.setState({ isMicrosoftSubmitting: true });

        const result = await AuthenticationService.microsoftLogin(idToken ?? "");
        this.setState({ isMicrosoftSubmitting: false });
        const params = new URLSearchParams(this.props.location.search);
        const returnUrl = params.get('ReturnUrl');

        if (result.isVerified) {
            if (returnUrl) {
                if (returnUrl.startsWith('/connect/authorize/callback')) {
                    window.location.href = returnUrl;
                }
                else {
                    this.props.history.push(returnUrl);
                }
            }
            else {
                this.props.history.push('/');
            }

        }
        else {
            this.setState({ errorMicrosoftLoggingIn: true });
        }
    }

    render() {
        return (
            <div className="login-container">
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-12">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Welcome Back!</h5>
                                                    <p className="text-p">Sign in to continue to APS.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-sidebar-dark">
                                                    <img src={Logo} alt="" className="rounded-circle w-16" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-2">

                                            <form onSubmit={this.handleSubmit} className="form-horizontal">

                                                <div className="form-group text-secondary">
                                                    <label>Email</label>
                                                    <input
                                                        name="email"
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        onChange={this.handleUsernameChange}
                                                        required
                                                    />
                                                </div>

                                                <div className="form-group text-secondary">
                                                    <label htmlFor="password">Password</label>
                                                    <input
                                                        name="password"
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        onChange={this.handlePasswordChange}
                                                        required
                                                    />
                                                </div>

                                                {this.state.errorLoggingIn &&
                                                    <Alert color="danger" className="mt-3">
                                                        Login failed
                                                    </Alert>
                                                }

                                                <div className="mt-3">
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                        disabled={this.state.isSubmitting}
                                                    >
                                                        {this.state.isSubmitting && (
                                                            <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" />
                                                        )}
                                                        Login
                                                    </button>
                                                </div>

                                                {/*We're not removing the feature, but hiding it. Keep this commented
                                                so we don't reinvent the wheel when we bring it back in*/}
                                                {/* <div className="mb-2 mt-2 text-center">
                                                    OR
                                                </div>
                                                
                                                <MicrosoftAuthenticationButton
                                                    isProfile={false}
                                                    onAuthenticated={this.handleMicrosoftLogin}
                                                    loginButtonText={"Log in with Microsoft Account"}
                                                    logoutButtonText={"Log out of Microsoft Account"}/> */}

                                                <div className="mt-4 text-center">
                                                    <Link to="/account/forgotpassword" className="text-muted text-secondary">
                                                        <FontAwesomeIcon icon={faLock} size="1x" className="mr-1" />
                                                        Forgot your password?
                                                    </Link>
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center text-secondary">
                                    <p>
                                        Contact <a href="mailto:support@advancedproblemsolving.org" className="font-medium text-primary">support@advancedproblemsolving.org</a> <br />to gain access to this portal. <br /> 
                                    </p>
                                    <p>
                                        &copy; {moment().year().toString()} APS.
                                    </p>
                                    <p>
                                        v{packageInfo.version}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    };
};

const WrappedLogin = withRouter(Login);
export { WrappedLogin as Login };
