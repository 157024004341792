import { FormDto } from "../../../../data/DailyOperational";
import fetcher from "../Fetcher";

class FormService {
  getForm = async (calendarDepartmentDto: {
    date: Date;
    departmentId?: number;
  }) => {
    const url = `/api/Form/DailyOperational`;
    return fetcher.post<FormDto>(url, calendarDepartmentDto);
  };

  saveTemplate = async (departmentId: number) => {
    const url = `/api/Form/Create/${departmentId}`;
    return fetcher.post<any>(url, {});
  };
}

const form = new FormService();
export default form;
