import React, { Component } from "react";
import { SectionList } from "./SectionList"
import './UserConsole.scss';
import { UserConsoleService } from "../../services/UserConsoleService";
import { UserConsoleSectionItemDto, UserConsoleSectionDto } from "../../data/UserConsole";
import { UserService } from "../../services/UserService";
import { Unsubscriber } from "../../common/Observable";
import { AppContext } from "../../AppContext";
import { Departments } from "../../data/Department";
import { UserConsoleAdminService } from "../../services/UserConsoleAdminService";
import { Group, Section, SectionItem, UserConsoleAdminSectionDto, UserConsoleAdminSectionItemDto, UserConsoleAdminSectionItemGroupDto } from  '../../../src/data/UserConsoleAdmin'
import CommonSpinner from "../../aps2/components/Common/CommonSpinner";

type Props = {
    column1: Array<JSX.Element>,
    column2: Array<JSX.Element>,
    column3: Array<JSX.Element>,
}



type StatePrincipal = {
    column1: Array<JSX.Element>,
    column2: Array<JSX.Element>,
    column3: Array<JSX.Element>,
    list: UserConsoleAdminSectionDto[] | null,
    isTablet: boolean,
    isLoading?: boolean
};

type State = {};

class DesktopView extends Component<Props, State> {
    render() {
        return (
            <div className="section-container masonry-layout columns-3">
                <div className="masonry-column column-1">
                    {this.props.column1}
                </div>
                <div className="masonry-column column-2">
                    {this.props.column2}
                </div>
                <div className="masonry-column column-3">
                    {this.props.column3}
                </div>
            </div>
        )
    }
}

class TabletView extends Component<Props, State> {
    render() {
        // Distribute the items in the third column evenly among the first and second column
        const firstHalf = this.props.column3.slice(0, Math.floor(this.props.column3.length / 2));
        const secondHalf = this.props.column3.slice(Math.floor(this.props.column3.length / 2));
        return (
            <div className="section-container masonry-layout columns-2">
                <div className="masonry-column column-1">
                    {this.props.column1.concat(firstHalf)}
                </div>
                <div className="masonry-column column-2">
                    {this.props.column2.concat(secondHalf)}
                </div>
            </div>
        )
    }
}

class MobileView extends Component<Props, State> {
    render() {
        return (
            <div className="section-container masonry-layout columns-2">
                <div className="masonry-column column-1">
                    {this.props.column1}
                </div>
                <div className="masonry-column column-2">
                    {this.props.column2}
                </div>
            </div>
        )
    }
}

export class UserConsole extends Component<Props, StatePrincipal>{
    static displayName = UserConsole.name;
    static contextType = AppContext;

    state: StatePrincipal = {
        column1: new Array<JSX.Element>(),
        column2: new Array<JSX.Element>(),
        column3: new Array<JSX.Element>(),
        list: null,
        isTablet: false
    }

    private _isAdminDepartmentUnsubscriber: Unsubscriber | null = null;

    private handleResize = () => {
        this.handleResizeColumns();
    };

    getColumns = (): number => {
        switch (this.state.list?.length) {
            case 1:
                return 1
            case 2:
                return 2
            default:
                if (this.state.isTablet) {
                    return 2;
                } else {
                    return 3;
                }

        }
    };

    getCount = (column: JSX.Element[]): number => {
        var val = 0
        column.map(x =>{
            var value = 0;
            if(this.context.module == Departments.Monrovia){
                value = value + x.props.items.childSectionItems.length;
            }else{
                column.map(x => (Object.values(x.props.items).map(x => value += ((x as UserConsoleSectionItemDto[]).length))))
            }
            val = value
        })
        return val
    }

    getLowerQuantityItemsColumns = (column: number) => {
        let columns: number[] = new Array<number>();

        if (column === 3) {
            columns = new Array(this.getCount(this.state.column1), this.getCount(this.state.column2), this.getCount(this.state.column3))
        } else {
            columns = new Array(this.getCount(this.state.column1), this.getCount(this.state.column2))
        }
        const minor = Math.min(...columns);
        return columns.findIndex(columnValue => columnValue === minor);
    }



    getColumnWithLessItems = (jsx: JSX.Element) => {
        switch (this.getColumns() === 3 ? this.getLowerQuantityItemsColumns(3) : this.getColumns() === 2 ? this.getLowerQuantityItemsColumns(2) : null) {
            case 0:
                this.state.column1.push(jsx)
                break;
            case 1:
                this.state.column2.push(jsx)
                break;
            case 2:
                this.state.column3.push(jsx)
                break;
            default:
                this.state.column1.push(jsx)
                break;
        }
    }

    handleResizeColumns = () => {
        if (window.screen.width >= 768 && window.screen.width <= 1024) {
            this.setState({ isTablet: true })
        } else {
            this.setState({ isTablet: false })
        }
        this.setState({ column1: [], column2: [], column3: [] })
    }

    handleDepartmentId = async (departmentId: number) => {        
        this.setState({ list: [], isLoading: true });
        const section = await UserConsoleService.getSummary(departmentId);
        
        if(Array.isArray(section)) {
            this.setState({  list: section, isLoading: false, column1: [],  column2: [], column3: [] });
        } else {
            this.setState({  list: [], column1: [],  column2: [], column3: [] });
        }
    }

    async componentDidMount() {
        this.handleResize()
        this.handleDepartmentId(this.context.module)
        this._isAdminDepartmentUnsubscriber = UserService.currentDepartment.subscribe(x => this.handleDepartmentId(x));
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
        this._isAdminDepartmentUnsubscriber?.();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if ((prevState.column1 === this.state.column1) && (prevState.column2 === this.state.column2) && (prevState.column3 === this.state.column3)) {
            this.setState({ column1: [], column2: [], column3: [] })
        }
    }

    render() {
        return (
            <div className="userconsole-parent">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="page-title-box">
                                <h1 className="mb-0 font-size-20">LINKS</h1>
                            </div>
                        </div>
                    </div>
                </div>                
                {this.state.isLoading ? <>
                    <CommonSpinner></CommonSpinner>
                </> : 
                <>
                {(!this.state.isTablet && (this.state.list?.length ?? 0) >= 3) ?
                    <DesktopView column1={this.state.column1} column2={this.state.column2} column3={this.state.column3} />
                    :
                    <TabletView column1={this.state.column1} column2={this.state.column2} column3={this.state.column3} />
                }
                {this.state.list?.sort(function(a, b) {  return a.sortOrder - b.sortOrder; })?.map((subSection, i) => {
                    if(subSection.sectionColumn == 0){
                        this.state.column1.push(<SectionList key={i} items={subSection} marginBottom={subSection.formatting} i={i} />);
                    }
                    if(subSection.sectionColumn == 1){
                        this.state.column2.push(<SectionList key={i} items={subSection} marginBottom={subSection.formatting} i={i} />);
                    }
                    if(subSection.sectionColumn == 2){
                        this.state.column3.push(<SectionList key={i} items={subSection} marginBottom={subSection.formatting} i={i} />);
                    }
                })}
                </>}
            </div>
        )
    }
}
