import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CredDefaultNotificationService {
  get = async () => {
    const url = `/api/CredDefaultNotification`;
    return fetcher.get<ApsModels.IDefaultCredentialNotificationDto>(url);
  };

  save = async (data: ApsModels.IDefaultCredentialNotificationDto) => {
    const url = `/api/CredDefaultNotification`;
    return fetcher.put<any>(url, data);
  };
}

const credDefaultNotification = new CredDefaultNotificationService();
export default credDefaultNotification;
