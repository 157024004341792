import React, { useEffect, useMemo, useState } from "react";
import FormModal from "../../Common/FormModal";
import {
  FgCheckbox,
  FgInput,
  FgUseForm,
  IBasicDialogProps,
} from "../../Common/FormGroups";
import ApsModels from "../../../models";
import commonService from "../../../services/CommonService";
import ApsServices from "../../../services";
import toastStore from "../../../stores/ToastStore";
function AddCourseNotificationDialog(
  props: IBasicDialogProps<{
    templateId: any;
    model?: ApsModels.ICredNotificationDetailDto;
  }>
) {
  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState<ApsModels.ICredNotificationDetailDto>(
    {} as any
  );

  useEffect(() => {
    if (props.data?.model) {
      setModel({
        ...props.data.model,
        _daysBeforeAssignmentDueDate: !commonService.isNullOrWhitespace(
          props.data.model.daysBeforeAssignmentDueDate
        ),
        _daysAfterAssignmentDueDate: !commonService.isNullOrWhitespace(
          props.data.model.daysAfterAssignmentDueDate
        ),
      } as any);

      setValuesFromModel({
        ...props.data.model,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { registry, handleSubmit, formState, setValuesFromModel, formRef } =
    FgUseForm({
      /*
       * This is where we add fields for validation
       */
      notifyOtherEmailAddresses: {
        displayName: "Notify Other Email(s)",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if (model.notifyOther) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateEmailCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
      daysBeforeAssignmentDueDate: {
        displayName: "Days Before Assignment Due Date",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if ((model as any)._daysBeforeAssignmentDueDate) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateNumbersOnlyCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
      daysAfterAssignmentDueDate: {
        displayName: "Days After Assignment Due Date",
        validation: {
          required: false,
          validate: {
            required: (val: string) => {
              if ((model as any)._daysAfterAssignmentDueDate) {
                if (commonService.isNullOrEmpty(val)) {
                  return false;
                } else {
                  return commonService.validateNumbersOnlyCSV(val);
                }
              }
              return true;
            },
          },
        },
      },
    });

  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const submit = async (form: any) => {
    setSaving(true);
    await ApsServices.http.credTemplate
      .saveCourseNotificationSetting(props.data?.templateId, model)
      .then((data) => {
        toastStore.showToast("Course Ntification Saved", "success");
        close(true);
        setSaving(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Course Ntification", err);
        setSaving(false);
      });
  };

  const hasValues = useMemo(() => {
    return (
      [
        commonService.isNullOrWhitespace(model.daysBeforeAssignmentDueDate)
          ? ""
          : "1",
        commonService.isNullOrWhitespace(model.daysAfterAssignmentDueDate)
          ? ""
          : "1",
        model.notifyOnAssignmentDate ? "1" : "",
        //model.notifyOnExpirationDate ? "1" : "",
        model.notifyUponEnrollment ? "1" : "",
        model.notifyUponCompletion ? "1" : "",
        model.notifyAccountAdmins ? "1" : "",
        model.notifyImmediateSupervisors ? "1" : "",
        model.notifyParticipants ? "1" : "",
        model.notifyOther ? model.notifyOtherEmailAddresses : "",
      ].filter((x) => !commonService.isNullOrEmpty(x)).length > 0
    );
  }, [model]);

  return (
    <FormModal
      title={`${model.detailId ? "Edit" : "Add"} Notification`}
      isOpen={true}
      close={() => close()}
      submit={handleSubmit(submit)}
      submitButtonLabel={saving ? "Saving..." : "Submit"}
      closeButtonLabel="Cancel"
      disableSubmit={saving || !hasValues}
      size="lg"
    >
      <div className="row">
        <div className="col col-sm-12 col-md-6">
          <div className="mb-2">
            <strong>When to Notify</strong>
          </div>

          <div className="flex">
            <div className="pt-2 pe-0">
              <FgCheckbox
                id="_daysBeforeAssignmentDueDate"
                label=""
                data={(model as any)._daysBeforeAssignmentDueDate || false}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      _daysBeforeAssignmentDueDate: data || false,
                    };
                  });
                }}
                disabled={false}
              />
            </div>
            <div
              className="pe-2"
              style={{
                maxWidth: "120px",
              }}
            >
              <FgInput
                id="daysBeforeAssignmentDueDate"
                formGroupClass="mb-1"
                registeredField={registry.daysBeforeAssignmentDueDate}
                formState={formState}
                disabled={!(model as any)._daysBeforeAssignmentDueDate || false}
                placeHolder="#, #, #, #"
                onChange={(val) => {
                  setModel((p) => {
                    return {
                      ...p,
                      daysBeforeAssignmentDueDate: val,
                    };
                  });
                }}
              />
            </div>
            <div className="flex-1 pt-1">
              <label>days before assignment due date</label>
            </div>
          </div>

          <div className="flex mb-1">
            <div className="pt-2 pe-0">
              <FgCheckbox
                id="_daysAfterAssignmentDueDate"
                label=""
                data={(model as any)._daysAfterAssignmentDueDate || false}
                onChange={(data) => {
                  setModel((p) => {
                    return {
                      ...p,
                      _daysAfterAssignmentDueDate: data || false,
                    };
                  });
                }}
                disabled={false}
              />
            </div>
            <div
              className="pe-2"
              style={{
                maxWidth: "120px",
              }}
            >
              <FgInput
                id="daysAfterAssignmentDueDate"
                formGroupClass="mb-1"
                registeredField={registry.daysAfterAssignmentDueDate}
                formState={formState}
                disabled={!(model as any)._daysAfterAssignmentDueDate || false}
                placeHolder="#, #, #, #"
                onChange={(val) => {
                  setModel((p) => {
                    return {
                      ...p,
                      daysAfterAssignmentDueDate: val,
                    };
                  });
                }}
              />
            </div>
            <div className="flex-1 pt-1">
              <label>days after assignment due date</label>
            </div>
          </div>

          <FgCheckbox
            id="notifyOnAssignmentDate"
            label="On assignment due date"
            data={model.notifyOnAssignmentDate}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyOnAssignmentDate: data || false,
                };
              });
            }}
            disabled={false}
          />
          <div className="mb-1"></div>
          {/* <FgCheckbox
            id="notifyOnExpirationDate"
            label="On expiration date"
            data={model.notifyOnExpirationDate}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyOnExpirationDate: data || false,
                };
              });
            }}
            disabled={false}
          />
          <div className="mb-1"></div> */}
          <FgCheckbox
            id="notifyUponEnrollment"
            label="Upon enrollment"
            data={model.notifyUponEnrollment}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyUponEnrollment: data || false,
                };
              });
            }}
            disabled={false}
          />
          <div className="mb-1"></div>
          <FgCheckbox
            id="notifyUponCompletion"
            label="Upon completion"
            data={model.notifyUponCompletion}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyUponCompletion: data || false,
                };
              });
            }}
            disabled={false}
          />
        </div>
        <div className="col col-sm-12 col-md-6">
          <div className="mb-2">
            <strong>Who to Notify</strong>
          </div>

          <FgCheckbox
            id="notifyAccountAdmins"
            label="Account Admin(s)"
            data={model.notifyAccountAdmins}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyAccountAdmins: data || false,
                };
              });
            }}
            disabled={false}
          ></FgCheckbox>
          <div className="mb-1"></div>
          <FgCheckbox
            id="notifyImmediateSupervisors"
            label="Immediate Supervisor(s)"
            data={model.notifyImmediateSupervisors}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyImmediateSupervisors: data || false,
                };
              });
            }}
            disabled={false}
          ></FgCheckbox>
          <div className="mb-1"></div>
          <FgCheckbox
            id="notifyParticipants"
            label="Participant(s)"
            data={model.notifyParticipants}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyParticipants: data || false,
                };
              });
            }}
            disabled={false}
          ></FgCheckbox>
          <div className="mb-1"></div>
          <FgCheckbox
            id="notifyOther"
            label="Other"
            data={model.notifyOther}
            onChange={(data) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyOther: data || false,
                };
              });
            }}
            disabled={false}
          ></FgCheckbox>
          <div className="mb-1"></div>
          <FgInput
            formGroupClass="mt-2"
            id="notifyOtherEmailAddresses"
            placeHolder="Enter email addresses separated by comma"
            registeredField={registry.notifyOtherEmailAddresses}
            formState={formState}
            rows={3}
            disabled={!model.notifyOther}
            onChange={(val) => {
              setModel((p) => {
                return {
                  ...p,
                  notifyOtherEmailAddresses: val,
                };
              });
            }}
          />
        </div>
      </div>
    </FormModal>
  );
}

export default AddCourseNotificationDialog;
