import moment from "moment";
import React, { useState } from "react";
import commonService from "../../services/CommonService";
import { IBasicDialogProps } from "../Common/FormGroups";
import FormModal from "../Common/FormModal";

function ConfirmCourseAssignmentDialog(
  props: IBasicDialogProps<{
    fromDate: any;
    toDate: any;
    employees: {
      name: string;
      id: number;
    }[];
  }>
) {
  const [employees, setEmployees] = useState<
    {
      name: string;
      id: number;
    }[]
  >([...(props.data?.employees || [])]);

  return (
    <div>
      <FormModal
        title="Confirm Course Assignment"
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        submit={() => {
          props.onChange &&
            props.onChange({
              ...(props.data || ({} as any)),
              employees: [...employees],
            });
        }}
        closeButtonLabel="Go back and edit"
        submitButtonLabel="Yes, please assign"
      >
        <div>
          <div>
            This course is scheduled for{" "}
            <strong>
              {moment(props.data?.fromDate).format("MM/DD/YYYY")}-
              {moment(props.data?.toDate).format("MM/DD/YYYY")}
            </strong>{" "}
            for the following participants:
          </div>
          <div className="pt-4">
            <div className="currentParticipants mb-3">
              {employees
                .sort(commonService.sortByStringProperty("name"))
                ?.map((p, i) => {
                  return (
                    <div key={i}>
                      <section>{`${p.name}`}</section>

                      <section
                        className={`${
                          employees.length > 1 ? "" : "display-none"
                        }`}
                        title="Remove"
                        onClick={() => {
                          const prts = [...employees];
                          prts.splice(i, 1);
                          setEmployees(prts);
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </section>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </FormModal>
    </div>
  );
}

export default ConfirmCourseAssignmentDialog;
