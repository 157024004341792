import React, { useContext, useState } from "react";
import ExpandCollapse from "../../Shared/ExpandCollapse";
import { UserConsoleAdminSectionItem } from "./UserConsoleAdminSectionItem";
import { Section } from '../../../data/UserConsoleAdmin';
import { UCAdminContext } from "./UserConsoleAdmin";
var dnd = require('react-beautiful-dnd');

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "white" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: `${grid}px 0`
});

type Props = {
    path: number[]
    data: Section
    departmentName: string
}

const UserConsoleAdminSection: React.FC<Props> = ({ path, data, departmentName }) => {
    const [sectionName, updateSectionName] = useState(data.sectionName);
    const [formatting, updateFormatting] = useState(data.formatting);
    const { children } = data;
    const { deleteSection, updateSection, addLink, updateSectionChangeStatus } = useContext(UCAdminContext);
    const [collapsed, setCollapsed] = useState<boolean | undefined>(undefined);

    const onSaveClicked = () => {
        updateSection(path, { ...data, sectionName, formatting }, true)
        setCollapsed(true);
    }

    return (
        <ExpandCollapse
            id={sectionName + path[0].toString() + path[1].toString()}
            title={sectionName}
            titleStyle={{ fontSize: '16px' }}
            barStyle={{
                border: "2px solid gray",
                borderRadius: "8px",
                marginBottom: "1em"
            }}
            collapse={collapsed}
            onCollapseChange={()=>setCollapsed(undefined)}
        >
            <div className="row">
                <div className="col">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="ucSectionTitle" className="form-control-label">
                                Section Title
                            </label>
                            <input
                                type="text"
                                id="ucSectionTitle"
                                value={sectionName}
                                style={{ display: "block" }}
                                className='form-control'
                                onChange={(e) => {
                                    updateSectionName(e.target.value);
                                    updateSectionChangeStatus(true);
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="ucFormatting" className="form-control-label">
                                Empty Space Between Last Link and Border of Section
                            </label>
                            <input
                                type="number"
                                id="ucFormatting"
                                value={formatting}
                                style={{ display: "block" }}
                                className='form-control'
                                onChange={(e) => {
                                    updateFormatting(e.target.value);
                                    updateSectionChangeStatus(true);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <button
                                className="btn btn-primary"
                                onClick={() => addLink(path)}
                            >
                                Add Link
                            </button>
                        </div>
                    </div>
                    <dnd.Droppable key={`${path}`} droppableId={`${path}`} type="SECTIONITEMS">
                        {(provided: any, snapshot: any) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {children.map((si, i) => (
                                    <dnd.Draggable key={`${[...path, i]}`} draggableId={`${[...path, i]}`} index={i}>
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <UserConsoleAdminSectionItem
                                                    departmentName={departmentName}
                                                    path={[...path, i]}
                                                    data={si}
                                                    key={`UCAdminSectionItem-${si.itemId}`}
                                                />
                                            </div>
                                        )}
                                    </dnd.Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </dnd.Droppable>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button
                                    type="button"
                                    className="btn btn-danger me-md-2"
                                    onClick={() => deleteSection(path, true)}
                                >
                                    Delete Section
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={onSaveClicked}
                                >
                                    Save Section
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ExpandCollapse>
    )
}

export default UserConsoleAdminSection;
