import React, { Component } from 'react';
import { UserService } from '../../services/UserService';
import { AppContext } from "../../AppContext";
import { Unsubscriber } from '../../common/Observable';
import { RouteComponentProps } from 'react-router';
import { GlendaleDailyOperational } from './Glendale/GlendaleDailyOperational';
import { Departments } from '../../data/Department';
import { MontereyParkDailyOperational } from './MontereyPark/MontereyParkDailyOperational';
import { MonroviaDailyOperational } from './Monrovia/MonroviaDailyOperational';
import { WestCovinaDailyOperational } from './WestCovina/WestCovinaDailyOperational';
import { AvalonDailyOperational } from './Avalon/AvalonDailyOperational';
import DailyOperationalRenderer from '../DailyOperationalRenderer/DailyOperationalRenderer';

type Props = RouteComponentProps<{}> & {}

type State = {
    departmentId: number,
}

export class DailyOperational extends Component<Props, State> {
    static contextType = AppContext;
    private _isAdminDepartmentUnsubscriber: Unsubscriber | null = null;

    state: State = {
        departmentId: 0,
    }

    setDepartmentId = async (departmentId: number) => {        
        this.setState({
            departmentId: departmentId
        });
    }

    componentDidMount = async () => {
        this._isAdminDepartmentUnsubscriber = UserService.currentDepartment.subscribe(async (x) => await this.setDepartmentId(x))
        this.setDepartmentId(this.context.module);
    }

    componentWillUnmount = async () => {
        this._isAdminDepartmentUnsubscriber?.();
    }

    selectDailyOperationalComponent = (departmentId: number) => {
        switch (departmentId) {
            case Departments.Glendale:
                return <GlendaleDailyOperational history={this.props.history} location={this.props.location} match={this.props.match} />
            case Departments.MontereyPark:
                return <MontereyParkDailyOperational history={this.props.history} location={this.props.location} match={this.props.match} />
            // case Departments.Monrovia: //CSM-390
            //     return <MonroviaDailyOperational history={this.props.history} location={this.props.location} match={this.props.match} />
            case Departments.WestCovina:
                return <WestCovinaDailyOperational history={this.props.history} location={this.props.location} match={this.props.match} />
            case Departments.Avalon:
                return <AvalonDailyOperational history={this.props.history} location={this.props.location} match={this.props.match} />
            default:
                return <DailyOperationalRenderer {...this.props}></DailyOperationalRenderer>;
        }
    }

    render() {
        return (
            <>
            { this.selectDailyOperationalComponent(this.state.departmentId) }
            </>
        )
    }
}
