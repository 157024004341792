import React, { useEffect } from "react";
import { FieldValues, FormState, UseFormRegisterReturn } from "react-hook-form";
import FormError from "../FormError";

export interface IFormInput {
  id: string;
  registeredField: any; //UseFormRegisterReturn;
  formState: FormState<FieldValues>;
  label?: string;
  placeHolder?: string;
  value?: any;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange?: (data: string) => void;
  rows?: number;
  readOnly?: boolean;
  type?: string;
  step?: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  onKeyEnter?: (event: any) => void;
  prefix?: string;
  suffix?: string;
  showRequiredLabel?: boolean;
  formGroupClass?: string;
}

export const FgInput = ({
  id,
  registeredField,
  value,
  label,
  placeHolder,
  formState,
  disabled,
  autoFocus,
  onChange,
  rows,
  readOnly,
  type,
  step,
  minLength,
  maxLength,
  min,
  max,
  onKeyEnter,
  prefix,
  suffix,
  showRequiredLabel,
  formGroupClass,
}: IFormInput) => {
  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (autoFocus) {
      ref.current?.querySelector("input")?.focus();
    }
  }, []);

  return (
    <div className={`form-group ${formGroupClass || ""}`} ref={ref}>
      {label && (
        <label htmlFor={id} className="label-no-wrap" title={label}>
          {showRequiredLabel && <span className="required-label">*</span>}
          {label}
        </label>
      )}

      {(!rows || rows === 1) && (
        <div className={`${prefix || suffix ? "input-group" : ""}`}>
          {!!prefix && (
            <div className="input-group-prepend">
              <span className="input-group-text">{prefix}</span>
            </div>
          )}

          <input
            id={id}
            {...(registeredField ? {} : { value: value })} //DO NOT ALLOW REACT-HOOK-FORM TO HAVE VALUE PROPS
            {...registeredField}
            placeholder={placeHolder}
            className="form-control"
            disabled={disabled || false}
            onChange={(event) => {
              registeredField && registeredField.onChange(event);
              onChange && onChange(event.target.value);
            }}
            readOnly={readOnly}
            type={type ? type : "text"}
            minLength={minLength || undefined}
            maxLength={maxLength || undefined}
            min={min || undefined}
            max={max || undefined}
            onPaste={(event) => {
              if (type === "number") {
                const dataPasted = event.clipboardData.getData("Text");
                if (isNaN(dataPasted as any)) {
                  event.stopPropagation();
                  event.preventDefault();
                  return;
                }
              }
            }}
            onKeyDown={(event) => {
              if (type === "number") {
                if (["e", "E", "-", "+"].indexOf(event.key) > -1) {
                  event.stopPropagation();
                  event.preventDefault();
                  return;
                }
                if (!step || `${step}` === "1") {
                  if (event.key === ".") {
                    event.stopPropagation();
                    event.preventDefault();
                    return;
                  }
                }
              }
              if (event.key === "Enter" && onKeyEnter) {
                onKeyEnter(event);
              }
            }}
            {...(step ? { step: step } : {})}
            style={{
              backgroundColor: disabled || readOnly ? "#eee" : "unset",
            }}
            autoComplete="new-password" //prevent autocomplete/autofill
          />

          {!!suffix && (
            <div className="input-group-append">
              <span className="input-group-text">{suffix}</span>
            </div>
          )}
        </div>
      )}

      {rows && rows > 1 && (
        <textarea
          id={id}
          {...(registeredField ? {} : { value: value })} //DO NOT ALLOW REACT-HOOK-FORM TO HAVE VALUE PROPS
          {...registeredField}
          placeholder={placeHolder}
          className="form-control"
          disabled={disabled || false}
          onChange={(event) => {
            registeredField && registeredField.onChange(event);
            onChange && onChange(event.target.value);
          }}
          rows={rows}
          style={{
            resize: "none",
            backgroundColor: disabled || readOnly ? "#eee" : "unset",
          }}
          readOnly={readOnly}
        ></textarea>
      )}

      <FormError
        field={registeredField.name}
        formState={formState}
        fieldDisplayName={label}
      ></FormError>
    </div>
  );
};
