import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSHazmatCauseService {
  get = async (reportId: any) => {
    const url = `/api/NfirsHazmatCause/${reportId}`;
    return fetcher.get<ApsModels.INfirsHazmatCauseOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsHazmatCauseInputDto) => {
    const url = `/api/NfirsHazmatCause`;
    return fetcher.post<ApsModels.INfirsHazmatCauseOutputDto>(url, data);
  };
}

const nfirsHazmatCause = new NFIRSHazmatCauseService();
export default nfirsHazmatCause;
