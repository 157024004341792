import React from 'react';
import { AnswerDto, AnswerType, AnswerUserReferenceDto, IsolatedInputDetails, QuestionDto } from '../../../data/DailyOperational';
import { OptionDto } from '../../../data/Option';
import { UserDto } from '../../../data/User';
import AutoTextArea from "../../../common/AutoTextArea"
import { UserService } from '../../../services/UserService';

type Props = {
    key: number,
    isFirst: boolean,
    isLast: boolean,
    onChange: (isLast: boolean) => void,
    setData: (question: QuestionDto | undefined) => void,
    answerDto?: AnswerDto,
    userDto?: UserDto[],
    optionsDto?: OptionDto[],
    userSelected?: UserDto,
    usersSelected?: UserDto[],
    question?: QuestionDto,
    questionGroup?: QuestionDto[],
    order: number,
    date: Date
}

type State = {
    userSelected: UserDto | null,
    optionsSelected: OptionDto | null,
    userDto?: UserDto[],
    optionsDto?: OptionDto[],
    usersSelected?: UserDto[]
}

export class IsolatedInputTextArea extends React.Component<Props, State> {

    state: State = {
        userSelected: null,
        optionsSelected: null,
        userDto: [],
        optionsDto: [],
        usersSelected: new Array<UserDto>()
    }

    getClassName(): string {
        if (this.props.isFirst) {
            return 'rounded-top';
        }

        if (this.props.isLast) {
            return 'rounded-bottom';
        }

        return '';
    }

    handleInputChanged = (e: React.KeyboardEvent<HTMLTextAreaElement>, order: number): void => {
        const question = this.props.question
        const answer = this.props.question?.answers.find(x => x.order === order)
        if (answer) {
            const answerDto: AnswerDto | undefined = {
                ...answer,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                textAnswerValue: e.currentTarget.value
            }
            const index = question?.answers.findIndex(x => x.order === answerDto.order)
            if ((index !== undefined) && question) {
                if (question.answers[index]) {
                    question.answers[index] = answerDto
                }
            }
        } else {
            const answerDto: AnswerDto | undefined = {
                answerId: 0,
                answerType: AnswerType.Text,
                questionId: Number(question?.questionId),
                order: order,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                textAnswerValue: e.currentTarget.value
            }
            question?.answers.push(answerDto)
        }
        this.props.setData(question)
        this.props.onChange(this.props.isLast);
    }

    private getAnswerText(questions: QuestionDto[] | undefined) {
        const shiftActivityOrder: number = 3;
        return questions && questions.find(x => x.order === shiftActivityOrder)?.answers.find(y => y.order === this.props.order)?.textAnswerValue
    }

    render() {
        const textAnswerValue: string = this.getAnswerText(this.props.questionGroup) || '';
        return (
            <AutoTextArea className="form-control" rows={1} defaultValue={textAnswerValue} onKeyUp={e => this.handleInputChanged(e, this.props.order)}  />
        )
    }
}
