import React from "react";
import commonService from "../../services/CommonService";

function LoadMoreIndicator({
  loadingMore,
  currentCount,
  totalExpected,
  unknownTotal,
}: {
  loadingMore: boolean;
  currentCount?: number;
  totalExpected?: number;
  unknownTotal?: boolean;
}) {
  return (
    <>
      {loadingMore ? (
        <div className="pt-2">
          {commonService.toNumberWithComma(currentCount, 0)}{" "}
          {unknownTotal ? (
            <></>
          ) : (
            <>of {commonService.toNumberWithComma(totalExpected, 0)}</>
          )}
          - Loading... <i>filters, if any, will be applied after</i>
        </div>
      ) : (
        <div className="pt-2">
          {commonService.toNumberWithComma(totalExpected, 0)} Record(s) Found
        </div>
      )}
    </>
  );
}

export default LoadMoreIndicator;
