import React, { useEffect, useMemo, useState } from "react";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import ApsServices from "../../services";
import { AgGridReact } from "ag-grid-react";
import LoadMoreIndicator from "../Common/LoadMoreIndicator";
import ApsModels from "../../models";
import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from "ag-grid-community";
import commonService, { IAgGridState } from "../../services/CommonService";
import systemStore, {
  useGridFilters,
  useNewTrainingReport,
} from "../../stores/SystemStore";
import { DATE_FIELDS } from "./BuildReports";
import { Dropdown } from "react-bootstrap";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import EnterReportNameDialog from "./EnterReportNameDialog";
import { RouteComponentProps } from "react-router";

function SavedReportPreview(props: RouteComponentProps<any>) {
  const id = Number(props.match?.params?.id) || (null as any);
  const newReport = useNewTrainingReport();
  const report = useNfirsGetData(
    async () => ApsServices.http.trainingReport.get(id),
    "Report"
  );

  const cols = useNfirsGetData(
    () => ApsServices.http.trainingReport.trainingReportColumns(),
    "Columns"
  );

  useEffect(() => {
    if (report.status === NfirsFetchStatus.Complete) {
      return; //do nothing...
    }

    cols.getData();
    if (id) {
      report.getData();
    } else {
      if (newReport) {
        report.setDataAndComplete({ ...newReport }); //COPY NEW REPORT MODEL
      } else {
        props.history.push(`/reports/build/new`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      report.data &&
      report.status === NfirsFetchStatus.Complete &&
      cols.data &&
      cols.status === NfirsFetchStatus.Complete
    ) {
      setGridState((prev) => {
        return {
          ...prev,
          columnDefs: [
            {
              headerCheckboxSelection: true,
              checkboxSelection: (params: any) => {
                return true;
              },
              field: "_selected",
              headerName: "",
              width: 10,
              ...commonService.agGridHelpers.checkboxColDef,
            },
            ...(report.data?.trainingReportSettingsDto?.columnIds || []).map(
              (col) => {
                return {
                  field: commonService.camelize(col),
                  colId: col,

                  headerName:
                    cols.data?.trainingReportGroupDtos
                      ?.find((grp) =>
                        grp.trainingReportColumnsDetailsDtos.find(
                          (c) => c.id === col
                        )
                      )
                      ?.trainingReportColumnsDetailsDtos?.find(
                        (c) => c.id === col
                      )?.columLabel || col,
                  ...(DATE_FIELDS.indexOf(col) > -1
                    ? {
                        ...commonService.agGridHelpers.dateValueFormatter(
                          DATE_FIELDS.includes(col)
                            ? `_${commonService.camelize(col)}`
                            : commonService.camelize(col)
                        ),
                      }
                    : {}),
                };
              }
            ),
          ],
        };
      });
      getList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.status, cols.status]);

  const [gridState, setGridState] = useState<GridOptions>({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          return true;
        },
        field: "_selected",
        headerName: "",
        width: 10,
        ...commonService.agGridHelpers.checkboxColDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      minWidth: 100,
    },
    frameworkComponents: {},
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ITrainingReportExtractDetailDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridFilters.init(params);
  };

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<ApsModels.ITrainingReportExtractGridDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const [gridChanged, setGridChanged] = useState("");
  const [randomId] = useState(commonService.getUniqueId());
  const gridFilters = useGridFilters(
    `saved-report-${randomId}-table`,
    gridApi,
    (others) => {},
    true,
    () => {
      setGridChanged(commonService.getUniqueId());
    },
    report.data?.reportName
  );

  const getList = async (initial = false) => {
    if (report.data) {
      setLoading(true);
      gridApi?.showLoadingOverlay();
      await ApsServices.http.trainingReport
        .extractReportData({
          ...report.data.trainingReportSettingsDto,
          lastCourseId: initial
            ? (null as any)
            : list?.lastCourseId || (null as any),
          lastDrillId: initial
            ? (null as any)
            : list?.lastDrillId || (null as any),
        })
        .then((data) => {
          setList((prev) => {
            if (initial) {
              return data;
            }
            if (prev) {
              return {
                ...data,
                trainingReportExtractDetailDtos: [
                  ...prev.trainingReportExtractDetailDtos,
                  ...data.trainingReportExtractDetailDtos,
                ],
              };
            }
            return data;
          });
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Report", err);
        });
    }
  };

  const applyColFilters = () => {
    if (
      gridApi &&
      gridColumnApi &&
      report.data &&
      (gridState.columnDefs?.length || 0) > 1
    ) {
      const agGridData = JSON.parse(
        report.data?.trainingReportSettingsDto?.agGridSettings || "{}"
      ) as IAgGridState;
      gridColumnApi.applyColumnState({
        state: agGridData.columnState || [],
        applyOrder: true,
      });
      gridApi.setFilterModel(agGridData.filterModel);
    }
  };

  useEffect(() => {
    let tmo: any;
    if (list && (list.lastCourseId > -1 || list.lastDrillId > -1)) {
      getList();
    } else {
      setLoading(false);

      if (list?.lastCourseId === -1 && list?.lastDrillId === -1) {
        tmo = setTimeout(() => {
          applyColFilters();
        }, 1500);
      }
    }
    return () => {
      tmo && clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const [init, setInit] = useState(false);
  useEffect(() => {
    if (
      !init &&
      gridApi &&
      gridColumnApi &&
      report.data &&
      (gridState.columnDefs?.length || 0) > 1
    ) {
      setInit(true);
      applyColFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.data, gridState.columnDefs, gridColumnApi, gridApi, init]);

  useEffect(() => {
    if (loading) {
      return;
    }
    setGridState({
      ...gridState,
      rowData: (list?.trainingReportExtractDetailDtos || []).map((row) => {
        const data = { ...row } as any;
        DATE_FIELDS.forEach((col) => {
          const colName = commonService.camelize(col);
          const val = data[colName];
          if (val) {
            data[`_${colName}`] = val; //store old value
            data[colName] = commonService.agGridHelpers.toExportDate(val);
          }
        });
        return data;
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading]);

  const getNewModel = () => {
    const colState = gridColumnApi?.getColumnState() || [];
    const filterModel = gridApi?.getFilterModel() || {};
    const agGridData: IAgGridState = {
      columnState: colState,
      filterModel: filterModel,
    };
    if (report.data) {
      const model = {
        ...report.data,
        trainingReportSettingsDto: {
          ...report.data.trainingReportSettingsDto,
          columnIds: colState
            .filter((x) => !!x.colId)
            .map((c) => c.colId || "")
            .filter((c) => c.indexOf("_") !== 0),
          agGridSettings: JSON.stringify(agGridData),
        },
      };
      return model;
    }
    return undefined;
  };

  const [saving, setSaving] = useState(false);
  const [makingCopy, setMakingCopy] = useState(false);

  const saveReport = async (reportName?: string, saveOnly = false) => {
    const model = getNewModel();
    if (model) {
      model.usersInReport = [];
      model.drillsInReport = [];
      model.coursesInReport = [];

      if (!saveOnly) {
        if (reportName) {
          setSaving(true);
          setMakingCopy(true);
          await ApsServices.http.trainingReport
            .create({
              ...model,
              reportName: reportName,
            })
            .then((data) => {
              toastStore.showToast("Report Saved", "success");
              props.history.replace(`/reports/view/${data.id}`);
              report.setData(data);
            })
            .catch((err) => {
              toastStore.showError("Failed Saving Report", err);
            })
            .finally(() => {
              setSaving(false);
              setMakingCopy(false);
              systemStore.setNewTrainingReport(undefined);
            });
        } else {
          setShowName(true);
        }
      } else {
        setSaving(true);
        await ApsServices.http.trainingReport
          .update(model.id, {
            ...model,
          })
          .then((data) => {
            toastStore.showToast("Report Saved", "success");
            report.setData(data);
          })
          .catch((err) => {
            toastStore.showError("Failed Saving Report", err);
          })
          .finally(() => {
            setSaving(false);
            systemStore.setNewTrainingReport(undefined);
          });
      }
    }
  };

  const [showName, setShowName] = useState(false);
  const hasChanges = useMemo(() => {
    if (!saving && gridChanged) {
      if (report.data?.id === 0) {
        return true;
      }
      const model = getNewModel();
      if (
        model &&
        report.data &&
        list &&
        list.lastCourseId === -1 &&
        list.lastDrillId === -1
      ) {
        return (
          model.trainingReportSettingsDto.agGridSettings !==
          report.data.trainingReportSettingsDto.agGridSettings
        );
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving, list, report.data, gridApi, gridColumnApi, gridChanged]);

  return (
    <>
      {showName && (
        <EnterReportNameDialog
          //data={report.data?.reportName}
          onClose={() => {
            setShowName(false);
          }}
          onChange={(name) => {
            setShowName(false);
            saveReport(name);
          }}
        ></EnterReportNameDialog>
      )}
      <div
        className="flex flex-1 flex-column"
        style={{ marginBottom: "-60px" }}
      >
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls pb-0">
              <div className="flex-1">
                <span className="h4 mb-0 font-size-18">
                  {report.data?.id === 0 ? (
                    <span className="text-uppercase">New Report</span>
                  ) : (
                    <span>{report.data?.reportName}</span>
                  )}
                </span>
              </div>
              <div className="flex-0 text-right">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={(e) => {
                    gridFilters.exportAllCSV();
                  }}
                >
                  Export
                </button>
                {/* <Dropdown drop="down" className="inline-block">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id={`ddMenu-${gridFilters.props._key}Picker`}
                    disabled={loading}
                  >
                    Export
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right">
                    <Dropdown.Item
                      onClick={(e) => {
                        gridFilters.exportAllCSV();
                      }}
                    >
                      Export All Data
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        gridFilters.exportCSV();
                      }}
                    >
                      Export Visible Data
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={(e) => {
                    const model = getNewModel();
                    if (!id && report.data?.id === 0) {
                      systemStore.setNewTrainingReport(model);
                      props.history.push(`/reports/build/new`);
                    } else {
                      props.history.push(`/reports/build/${id}`);
                    }
                  }}
                >
                  Edit
                </button>

                {/* START -- FOR SAVE ONLY */}
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={loading || saving}
                  onClick={(e) => {
                    saveReport();
                  }}
                >
                  <span className="px-2">
                    {saving ? "Saving..." : "Save Report "}
                  </span>
                </button>
                {/* END -- FOR SAVE ONLY */}

                {/* START -- FOR SAVE AND MAKE A COPY */}
                {/* {!!report.data?.id && (
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    disabled={loading || saving || makingCopy}
                    onClick={(e) => {
                      saveReport(undefined, true);
                    }}
                  >
                    <span className="px-2">
                      {!!(saving && !makingCopy) ? "Saving..." : "Save Report "}
                      {hasChanges && <span className="required-label">*</span>}
                    </span>
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={loading || saving || makingCopy}
                  onClick={(e) => {
                    saveReport();
                  }}
                >
                  <span className="px-2">
                    {report.data?.id ? (
                      <>{makingCopy ? "Making Copy..." : "Make a Copy "}</>
                    ) : (
                      <>{saving ? "Saving..." : "Save Report "}</>
                    )}
                  </span>
                </button> */}
                {/* END -- FOR SAVE AND MAKE A COPY */}
              </div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card p-4">
                <div className="card-body flex flex-col p-0 pt-2">
                  {!!(
                    report.status === NfirsFetchStatus.Complete &&
                    !!report.data?.trainingReportSettingsDto &&
                    (gridState.columnDefs?.length || 0) > 1
                  ) ? (
                    <>
                      <div style={{ width: "100%", height: "100%" }}>
                        <div
                          id="myGrid"
                          style={{
                            height: "100%",
                            minHeight: "400px",
                          }}
                          className="ag-theme-alpine flex-1"
                        >
                          <AgGridReact
                            columnDefs={gridState.columnDefs}
                            defaultColDef={gridState.defaultColDef}
                            autoGroupColumnDef={gridState.autoGroupColumnDef}
                            enableRangeSelection={true}
                            animateRows={true}
                            onGridReady={onGridReady}
                            rowSelection="multiple"
                            rowMultiSelectWithClick={false}
                            suppressRowDeselection={true}
                            suppressRowClickSelection={true}
                            frameworkComponents={gridState.frameworkComponents}
                            rowData={gridState.rowData || []}
                            onRowDoubleClicked={(event) => {
                              props.history.push(
                                `/reports/view/${event.data.id}`
                              );
                            }}
                            overlayLoadingTemplate={
                              gridState.overlayLoadingTemplate
                            }
                            overlayNoRowsTemplate={
                              gridState.overlayNoRowsTemplate
                            }
                            {...gridFilters.props}
                          />
                        </div>
                      </div>
                      <LoadMoreIndicator
                        loadingMore={loading}
                        currentCount={
                          list?.trainingReportExtractDetailDtos?.length
                        }
                        totalExpected={
                          list?.trainingReportExtractDetailDtos?.length
                        }
                        unknownTotal={true}
                      ></LoadMoreIndicator>
                    </>
                  ) : (
                    <div>
                      <CommonSpinner
                        overlay={true}
                        message="Loading..."
                      ></CommonSpinner>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SavedReportPreview;
