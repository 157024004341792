import React from "react";
import { FieldValues, FormState } from "react-hook-form";
import FormError from "../FormError";

export interface IFormSelect {
  id: string;
  registeredField?: any; //UseFormRegisterReturn;
  formState?: FormState<FieldValues>;
  label?: string;
  placeHolder?: string;
  value?: any;
  options: {
    label: string;
    value: any;
    groupId?: number;
  }[];
  selectMessage?: string;
  selectMessageValue?: any;
  onChange?: (data: string) => void;
  disabled?: boolean;
  disabledOptionValues?: any[];
  groups?: { id: any; label: string }[];
  showRequiredLabel?: boolean;
  hideGroupIfEmpty?: boolean;
  noPlaceholder?: boolean;
}

interface IFormSelectOption {
  disabledOptionValues?: any[];
  options: {
    label: string;
    value: any;
    groupId?: number;
  }[];
}

const FgOptions = ({ options, disabledOptionValues }: IFormSelectOption) => {
  return (
    <>
      {(options || []).map((o, i) => {
        return (
          <option
            key={i}
            value={o.value}
            disabled={(disabledOptionValues || []).find(
              (item) => item === o.value
            )}
            style={
              (disabledOptionValues || []).find((item) => item === o.value)
                ? {
                    cursor: "not-allowed",
                    backgroundColor: "#eee",
                    color: "#bbb",
                  }
                : {}
            }
          >
            {o.label}
          </option>
        );
      })}
    </>
  );
};

export const FgSelect = ({
  id,
  registeredField,
  value,
  label,
  placeHolder,
  formState,
  options,
  selectMessage,
  selectMessageValue,
  onChange,
  disabled,
  disabledOptionValues,
  groups,
  showRequiredLabel,
  hideGroupIfEmpty,
  noPlaceholder,
}: IFormSelect) => {
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={id} className="label-no-wrap" title={label}>
          {showRequiredLabel && <span className="required-label">*</span>}
          {label}
        </label>
      )}
      <select
        id={id}
        {...(registeredField ? {} : { value: value })} //DO NOT ALLOW REACT-HOOK-FORM TO HAVE VALUE PROPS
        {...(registeredField || {})}
        placeholder={placeHolder}
        className="form-control"
        onChange={(event) => {
          registeredField && registeredField.onChange(event);
          onChange && onChange(event.target.value);
        }}
        disabled={disabled}
      >
        {!noPlaceholder && (
          <option value={selectMessageValue || ""} style={{ color: "#bbb" }}>
            {selectMessage || ` `}
          </option>
        )}

        {groups?.length &&
          groups
            .filter((g) => {
              if (!hideGroupIfEmpty) return true;
              return !!options.find((o) => o.groupId === g.id);
            })
            .map((g) => (
              <optgroup label={g.label} key={g.id}>
                <FgOptions
                  options={options.filter((o) => o.groupId === g.id)}
                  disabledOptionValues={disabledOptionValues}
                ></FgOptions>
              </optgroup>
            ))}

        {!groups?.length && (
          <FgOptions
            options={options}
            disabledOptionValues={disabledOptionValues}
          ></FgOptions>
        )}
      </select>
      {formState && (
        <FormError
          field={id}
          formState={formState}
          fieldDisplayName={label}
        ></FormError>
      )}
    </div>
  );
};
