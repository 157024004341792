import React, {Component} from 'react';
import './EditUser.scss';
import {ModulePermissionDto, NewPasswordDto, RolesDto, UserDto, UserRolesPermissionDto} from '../../data/User';
import {UserService} from '../../services/UserService';
import {Link, RouteComponentProps} from 'react-router-dom';
import moment from 'moment';
import {AuthenticationService} from '../../services/AuthenticationService';
import {DepartmentService} from '../../services/DepartmentService';
import {DepartmentDto} from '../../data/Department';
import {OptionTypeBase} from 'react-select';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {
    AccessRights,
    AccessRightsType,
    AccessRightsInteractionService
} from "../../services/AccessRightsInteractionService";
import UserClaims from '../../aps2/components/User/UserClaims';
import ApsModels from '../../aps2/models';
import ApsServices from '../../aps2/services';
import toastStore from '../../aps2/stores/ToastStore';
import commonService from '../../aps2/services/CommonService';
import UserEligibility from '../../aps2/components/User/UserEligibility';
import UserGroups from '../../aps2/components/User/UserGroups';
import RouteChangedGuard from '../../aps2/components/Common/RouteChangedGuard';
import ConfirmDialog from '../../aps2/components/Common/ConfirmDialog';
import UserCredentialSettings from './UserCredentialSettings';
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { IUserDepartmentRelationDto } from '../../aps2/models/_all';
import { AppContext } from '../../AppContext';

type ParamsId = { id?: string };

type Props = RouteComponentProps<ParamsId> & {
    data: UserDto,
    today: Date,
}

interface GroupOptionType extends OptionTypeBase {
    label: string,
    value: number,
}

enum ItemsPermission {
    VIEW = 1,
    ACCESS = 2,
    EDIT = 3
}

enum Module {
    USER_CONSOLE = 1,
    DAILY_OPERATIONAL = 2,
    LOG_DRILLS = 3,
    TRAINING_PORTAL = 4
}

const userRolesPermission: UserRolesPermissionDto[] = [
    {
        moduleId: 1,
        name: "Links", //"User Console",
        items: [
            {
                itemPermission: ItemsPermission.VIEW,
                name: "View all",
                isActive: true
            },
            {
                itemPermission: ItemsPermission.ACCESS,
                name: "Remove access",
                isActive: false
            }]
    },
    {
        moduleId: 2,
        name: "Daily Operational",
        items: [
            {
                itemPermission: ItemsPermission.EDIT,
                name: "View all, submit all, edit all",
                isActive: false

            },
            {
                itemPermission: ItemsPermission.VIEW,
                name: "View all",
                isActive: true

            },
            {
                itemPermission: ItemsPermission.ACCESS,
                name: "Remove access",
                isActive: false
            }
        ]
    },
]

type State = {
    userId: number | null,
    userName: string,
    firstName: string,
    lastName: string,
    departmentId: number,
    departments: DepartmentDto[] | null,
    email: string,
    roles: number[],
    availableRoles: RolesDto[] | null,
    active: boolean,
    newPassword: string,
    lastLoggedIn: Date | null,
    lastModified: Date | null,
    lastEmailed: Date | null,
    otherStatus: string | null,
    confirmPassword: string;
    resetPasswordSection: boolean,
    reload: boolean,
    validationErrors: ValidationErrors,
    disableSubmit: boolean,
    isOpenModal: boolean,
    permissionModule: ModulePermissionDto | null,
    showAlert: boolean,
    userClaims?: ApsModels.IUserClaimDto[],
    collapse: boolean,
    originalData: any,
    hasEligibilityChanges: boolean,
    showDiscardOthers: boolean,
    ignoreChanges: boolean,
    ready: boolean,
    userDepartmentRelations: IUserDepartmentRelationDto[] | null,
    otherDepartments: DepartmentDto[] | null,
    openOtherDeptDropdown: boolean
}

type ValidationErrors = {
    newPassword?: string,
    confirmNewPassword?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    department?: string,
}

export class EditUser extends Component<Props, State> {

    static contextType = AppContext;

    private displayName = window.location.pathname.split('/').reverse()[1] == 'Personnel' ? 'Personnel' : 'User';
    private isPersonnel = window.location.pathname.split('/').reverse()[1]?.toLowerCase()?.trim() === "personnel";

    state: State = {
        userId: 0,
        userName: '',
        firstName: '',
        lastName: '',
        departmentId: 0,
        departments: [],
        email: '',
        roles: [],
        availableRoles: [],
        active: true,
        lastLoggedIn: null,
        lastModified: null,
        lastEmailed: null,
        otherStatus: '',
        newPassword: '',
        confirmPassword: '',
        resetPasswordSection: false,
        reload: false,
        validationErrors: {},
        disableSubmit: true,
        isOpenModal: false,
        permissionModule: null,
        showAlert: false,
        userClaims: undefined,
        collapse: false,
        originalData: {},
        hasEligibilityChanges: false,
        showDiscardOthers: false,
        ignoreChanges: false,
        ready: false,
        userDepartmentRelations: [],
        openOtherDeptDropdown: false,
        otherDepartments: []
    }

    async componentDidMount() {

        let data = {} as State;

        if (this.props.match.params.id && this.props.match.params.id !== 'add') {
            let id: number = Number(this.props.match.params.id);
            let user = await UserService.getUser(id);
            let modulesPermission = await UserService.getModulesPermissionByUser(id);

            data = {
                userId: user.userId,
                userName: user.userName,
                firstName: user.firstName,
                lastName: user.lastName,
                departmentId: user.departmentId,
                email: user.email,
                active: user.isActive,
                lastLoggedIn: user.lastLoggedIn,
                lastEmailed: user.sentInvite,
                permissionModule: { 
                    userId: user.userId, 
                    userRolesItemPermissionDto: !modulesPermission.length ? userRolesPermission : 
                    modulesPermission.map(m => {
                        return {
                            ...m,
                            name: userRolesPermission.find(u => u.moduleId === m.moduleId)?.name || m.name,
                            items: m.name === "Daily Operational" 
                                ? userRolesPermission.find(e => e.name == "Daily Operational")?.items.map(v => {
                                    return m.items.find(e => e.itemPermission == v.itemPermission)
                                })
                                : m.items 
                        }
                    })},
                ready: true,
                userDepartmentRelations: user.userDepartmentRelations,
                otherDepartments: [] as unknown
            } as State;
        } else {
            const deptId = await DepartmentService.getDepartmentId();
            data = {
                firstName: "",
                lastName: "",
                email: "",
                active: true,
                departmentId: Number(deptId),
                permissionModule: { userId: 0, userRolesItemPermissionDto: userRolesPermission },
                ready: true
            } as State;      
        }
        let roles: RolesDto[] = [];
        if (Number(this.props.match.params.id)) {
            roles = await UserService.getRolesByUser(Number(this.props.match.params.id));
        } else {
            roles = await UserService.getRoles();
        }
        const departments = await DepartmentService.getAllDepartments();
        data.userDepartmentRelations?.forEach(p => {
            let dept = departments.find(d => d.departmentId === p.departmentId)
            if (dept !== undefined) {
                data.otherDepartments?.push({ ...dept })
            }
        })

        data = {
            ...data,
            availableRoles: roles,
            departments: departments,
        }

        data = {
            ...data,
            originalData: commonService.deepCloneJsonObject(data)
        }

        this.setState(data);
    }

    handleReload = () => {
        this.setState({ reload: !this.state.reload })
    }

    removeOption = (moduleId: number, itemPermition: number) => {
        this.state.permissionModule?.userRolesItemPermissionDto?.map(x => {
            if (x.moduleId == moduleId) {
                x.items.map(y => {
                    if (y.itemPermission != itemPermition) {
                        y.isActive = false
                    }
                })
            }
        })
    }

    handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            firstName: e.target.value,
        })
    }

    handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            lastName: e.target.value,
        })
    }

    handleDepartmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(event.target.value);
        this.setState({
            departmentId: value,
        })
    }

    handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            showAlert: false,
            email: e.target.value,
        })
    }

    handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            active: !this.state.active,
        })
    }

    handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            newPassword: e.target.value,
        })
    }

    handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            confirmPassword: e.target.value,
        })
        if (this.state.confirmPassword !== this.state.newPassword) {
            this.setState({
                disableSubmit: false
            })
        } else {
            this.setState({
                disableSubmit: true,
            })
        }
    }

    toggleResetPassword = () => {
        this.setState({
            resetPasswordSection: !this.state.resetPasswordSection,
        })
    }

    openModal = () => {
        this.setState({ isOpenModal: !this.state.isOpenModal })
    }

    setOpenOtherDeptDropdown = (open: boolean) => {
        this.setState({ openOtherDeptDropdown: open })
    }

    submitUserClaims = async (newUserId?: number) => {
        try{
            const userId = newUserId || this.state.userId;
            if(this.state.userClaims && userId){
                await ApsServices.http.userSecurity.save({ userId: userId, userClaims: this.state.userClaims } as any);                
                const userInfo = { ...commonService.userInfo };
                if(userInfo.userId === Number(this.props.match.params.id)){
                    userInfo.userClaims = this.state.userClaims || [];
                    AuthenticationService.isAuthenticatedObservable.set(userInfo as any);
                } 
                !newUserId && toastStore.showToast("User access rights have been updated correctly.", "success");                 
            }
        } catch(err) {
            toastStore.showError("Error saving user access rights.", err);
        }        
    }

    submitModulePermission = async () => {
        await this.submitUserClaims();
        
        await UserService.saveModulePermission({ userId: this.state.userId, userRolesItemPermissionDto: this.state.permissionModule?.userRolesItemPermissionDto });
        this.setState({ isOpenModal: false });

        if (this.state.permissionModule?.userRolesItemPermissionDto === undefined) return;

        const accessRights: AccessRights[] = this.state.permissionModule?.userRolesItemPermissionDto.map(p  => {
            return {
                type: p.name === "Daily Operational" ? AccessRightsType.DailyOperational : AccessRightsType.UserConsole,
                view: p.items.find(i => i.itemPermission === 1)!.isActive,
                noAccess: p.items.find(i => i.itemPermission === 2)!.isActive,
                edit: p.items.find(i => i.itemPermission === 3)!.isActive
            }
        });

        AccessRightsInteractionService.changeAccessRights(accessRights);
    }

    lastLoggedInFormatDate = () => {
        const value = moment(this.state.lastLoggedIn).format('MM/DD/YYYY h:mm A');
        return value;
    }

    lastEmailedFormatDate = () => {
        const value = moment(this.state.lastEmailed).format('MM/DD/YYYY h:mm A');
        return value;
    }

    submitNewPassword = async () => {
        if (this.validateNewPassword() === false) {
            return;
        }

        const newPassword: NewPasswordDto = {
            newPassword: this.state.newPassword,
            username: this.state.userName
        }

        await AuthenticationService.updatePassword(newPassword);
        this.setState({
            resetPasswordSection: false,
        });
        toastStore.showToast("User's password has been updated.", "success");
    }

    handleSubmit = async () => {
        if (this.validate() === false) {
            return;
        }

        const userDepartmentRelations: IUserDepartmentRelationDto[] = [];
        this.state.otherDepartments?.forEach(p => {
            userDepartmentRelations.push({
                departmentId: p.departmentId,
                userId: this.state.userId || 0
            } as IUserDepartmentRelationDto)
        })

        const editUser: UserDto = {
            userId: this.state.userId,
            userName: this.state.userName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            departmentId: this.state.departmentId,
            isActive: this.state.active,
            lastLoggedIn: this.state.lastLoggedIn,
            roles: this.state.availableRoles,
            sentInvite: this.state.lastEmailed,
            userRolesPermission: { userId: this.state.userId, userRolesItemPermissionDto: this.state.permissionModule?.userRolesItemPermissionDto },
            userDepartmentRelations: userDepartmentRelations
        }
        try {
            const userId = await UserService.saveUser(editUser);
            if(!this.state.userId) {
                await this.submitUserClaims(userId);
            }            
            
            this.setState({
                ignoreChanges: true
            });
            const url = this.isPersonnel ? '/personnel': '/admin/users';
            const doneUrl = (this.props.match.params.id || "") === "add" ? commonService.doneUrls.created : commonService.doneUrls.submitted;
            this.props.history.push(url + doneUrl);
        } catch (e: any) {
            this.setState({ showAlert: true })
            toastStore.showError("Failed Saving User", e.message);
        }
    }

    validate() {
        let emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var validationErrors: ValidationErrors = {};
        if (this.state.firstName.trim() === "") {
            validationErrors.firstName = "First Name is required."
        }
        if (this.state.lastName.trim() === "") {
            validationErrors.lastName = "Last Name is required."
        }
        if (this.state.email.trim() === "" || !emailRegex.test(this.state.email)) {
            validationErrors.email = "An email is required."
        }
        if (this.state.departmentId === 0) {
            validationErrors.department = "A Department is required."
        }
        this.setState({ validationErrors: validationErrors });
        return Object.entries(validationErrors).length === 0;
    }

    validateNewPassword() {
        var validationErrors: ValidationErrors = {};
        if (this.state.newPassword.length < 8) {
            validationErrors.newPassword = 'Your password must be at least 8 characters';
        }
        if (this.state.newPassword !== this.state.confirmPassword || this.state.newPassword.trim() === '') {
            validationErrors.confirmNewPassword = 'The passwords do not match';
        }
        this.setState({ validationErrors: validationErrors });
        return Object.entries(validationErrors).length === 0;
    }

    hasChanges() {
        const orig = { 
            ...this.state.originalData
        } as State;
        const cur = {
            ...this.state
        };
        
        if(!this.state.ready || !cur || !this.state.originalData){
            return false;
        }

        if(orig.firstName !== cur.firstName 
            || orig.lastName !== cur.lastName 
            || orig.departmentId !== cur.departmentId 
            || orig.email !== cur.email 
            || orig.active !== cur.active
            || JSON.stringify(orig.availableRoles || []) !== JSON.stringify(cur.availableRoles || [])
            ) {
            return true;
        }

        return false; 
    }

    reset() {
        const orig = { 
            ...this.state.originalData
        } as State;
        this.setState({
            firstName: orig.firstName,
            lastName: orig.lastName,
            departmentId: orig.departmentId,
            email: orig.email,
            active: orig.active,
            availableRoles: orig.availableRoles
        });
    }

    getConfirmMessage() {
        const sections: string[] = [];
        if(this.hasChanges()){
            sections.push("User Settings");
        }
        if(this.state.hasEligibilityChanges){
            sections.push("Drills / JAC & ISA Eligibility");
        }
        if(sections.length === 0){
            return "";
        }
        const msg = `You have unsaved changes to the following section(s), 
        do you really want to leave the page and discard changes?
        <br/><br/>
        ${sections.map((s, i)=> `${i + 1}. ${s}`).join('<br/>')}`;        
        return msg;
    }

    getConfirmMessageSaveSettings() {
        return "test...";
    }

    render() {
        const ref = React.createRef<AsyncTypeahead<any>>();

        const resetPassword = () => {
            if (this.state.resetPasswordSection) {
                return (
                    <>
                        <div className="contact-input">
                            <div className="mt-3"></div>
                            <span>New Password</span>
                            <div className="mt-2"></div>
                            <input type="password"
                                data-lpignore="true"
                                name="newPassword"
                                placeholder="New Password"
                                className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.newPassword ? 'is-invalid' : ''}`}
                                onChange={this.handleNewPasswordChange} />
                            {this.state.validationErrors.newPassword && (
                                <div className='invalid-feedback'>
                                    {this.state.validationErrors.newPassword}
                                </div>
                            )}
                        </div>
                        <div className="contact-input">
                            <div className="mt-3"></div>
                            <span>Confirm Password</span>
                            <div className="mt-2"></div>
                            <input type="password"
                                data-lpignore="true"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.confirmNewPassword ? 'is-invalid' : ''}`}
                                onChange={this.handleConfirmPasswordChange} />
                            {this.state.validationErrors.confirmNewPassword && (
                                <div className='invalid-feedback'>
                                    {this.state.validationErrors.confirmNewPassword}
                                </div>
                            )}
                        </div>
                        <button className={`pass-reset-button my-2 ${this.state.disableSubmit ? 'disableColor' : ''}`}
                            disabled={this.state.disableSubmit}
                            onClick={this.submitNewPassword}>Submit</button>
                    </>
                )
            }
            return
        }

        return (
            <div className="container-fluid h-full">        

                <RouteChangedGuard
                    when={true} 
                    navigate={path => this.props.history.push(path)}
                    shouldBlockNavigation={location => {                     
                        return !this.state.ignoreChanges && (this.state.hasEligibilityChanges || this.hasChanges());
                    }} 
                    message={this.getConfirmMessage()}
                /> 

                <ConfirmDialog 
                    show={this.state.showDiscardOthers}
                    buttons="yesno"
                    title="Confirmation" 
                    message={this.getConfirmMessageSaveSettings()}
                    done={rtn => {                        
                        if(rtn === "yes"){
                            //this.doSubmit();
                            this.setState({ showDiscardOthers: false, hasEligibilityChanges: false });
                        } else {
                            this.setState({ showDiscardOthers: false });
                        }
                }}></ConfirmDialog>


                <div className="page-title-box">
                    <div className="max-w-full">
                        <div className="row">
                            <div className="col-4 col-xl-4">
                                <h4 className="mb-0 font-size-18">{this.props.match.params.id && this.props.match.params.id != "add" ? "EDIT " + this.displayName : "ADD " + this.displayName}</h4>
                            </div>
                            <div className="col-8 col-xl-8">
                                {/* <div>
                                    <button className="btn btn-primary float-right" onClick={this.handleSubmit}>Submit</button>
                                </div> */}
                                <span className=" float-right" >&nbsp;&nbsp;</span>
                                <div>
                                    <Link to={this.isPersonnel ? "/personnel" : "/admin/users"}><button className="btn btn-secondary float-right">Cancel and Close</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="container-fluid flex-card-container">

                                <div className="flex-0">
                                    <div className="headerControls">
                                        <div>
                                        <span className="pointer" onClick={() =>  this.setState({ collapse: !this.state.collapse }) }>
                                            <i
                                            className={`fa fa-chevron-${
                                                this.state.collapse ? "up" : "down"
                                            } pl-2 pr-2`}
                                            ></i>
                                            <h4 className="pt-3 pl-2 m-0 inline-block">
                                                User Settings
                                                {this.hasChanges() && <span style={{ color: "red" }}>*</span>}
                                            </h4>
                                        </span>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>

                                <div className={`row ${this.state.collapse ? "display-none" : ""}`}>

                                    <div className="col-12 col-xl-4">
                                        <div className="card h-95">
                                            <div className="card-body">
                                                <span><b>1. Contact Information</b></span>
                                                <div className="contact-input">
                                                    <div className="mt-3"></div>
                                                    <label className='required-label'>First Name</label>
                                                    <div className="mt-2"></div>
                                                    <input type="text"
                                                        data-lpignore="true"
                                                        name="firstName"
                                                        placeholder="First Name"
                                                        className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.firstName ? 'is-invalid' : ''}`}
                                                        value={this.state.firstName}
                                                        onChange={this.handleFirstNameChange}
                                                        required
                                                    />
                                                    {this.state.validationErrors.firstName && (
                                                        <div className='invalid-feedback'>
                                                            {this.state.validationErrors.firstName}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="contact-input">
                                                    <div className="mt-3"></div>
                                                    <label className='required-label'>Last Name</label>
                                                    <div className="mt-2"></div>
                                                    <input type="text"
                                                        data-lpignore="true"
                                                        name="lastName"
                                                        placeholder="Last Name"
                                                        className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.lastName ? 'is-invalid' : ''}`}
                                                        value={this.state.lastName}
                                                        onChange={this.handleLastNameChange}
                                                        required
                                                    />
                                                    {this.state.validationErrors.lastName && (
                                                        <div className='invalid-feedback'>
                                                            {this.state.validationErrors.lastName}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="contact-input">
                                                    <div className="mt-3"></div>
                                                    <label className='required-label'>Email Address</label>
                                                    <div className="mt-2"></div>
                                                    <input type="email"
                                                        data-lpignore="true"
                                                        name="email"
                                                        value={this.state.email}
                                                        placeholder="Email Address"
                                                        className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.email ? 'is-invalid' : ''}`}
                                                        onChange={this.handleEmailChange}
                                                        required
                                                    />
                                                    {this.state.validationErrors.email && (
                                                        <div className='invalid-feedback'>
                                                            {this.state.validationErrors.email}
                                                        </div>
                                                    )}
                                                    {/* {this.state.showAlert &&
                                                        <div className="invalid-feedback d-block">
                                                            The email is already registered
                                                        </div>} */}
                                                </div>
                                                <div className="contact-input">
                                                    <div className="mt-3"></div>
                                                    <label className='required-label'>Main Department</label>
                                                    <div className="mt-2"></div>
                                                    <select name="department"
                                                        value={this.state.departmentId}
                                                        required
                                                        className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.department ? 'is-invalid' : ''}`}
                                                        onChange={this.handleDepartmentChange}
                                                        disabled={this.isPersonnel}>
                                                        <option></option>
                                                        {this.state.departments?.map((x, i) => {
                                                            return (
                                                                <option key={x.departmentId} value={x.departmentId}>{!x ? null : x.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    {this.state.validationErrors.department && (
                                                        <div className='invalid-feedback'>
                                                            {this.state.validationErrors.department}
                                                        </div>
                                                    )}
                                                </div>
                                                {
                                                    !this.isPersonnel &&
                                                    <>
                                                        <div className="mt-3"></div>
                                                        <span>Visibility of Other Departments</span>
                                                        <div className="mt-2"></div>
                                                        <div>
                                                            <AsyncTypeahead
                                                                id="basic-typeahead-single"
                                                                labelKey="name"
                                                                onSearch={(e) => { }} //handleSearch
                                                                open={this.state.openOtherDeptDropdown}
                                                                onFocus={(e) => this.setOpenOtherDeptDropdown(true)}
                                                                onBlur={(e) => this.setOpenOtherDeptDropdown(false)}
                                                                onChange={(data) => {
                                                                    if (data && data.length > 0) {
                                                                        const prts = [...(this.state.otherDepartments || [])];
                                                                        if (data[0].departmentId === this.state.departmentId) {
                                                                            toastStore.showToast(
                                                                                `${data[0].name} is the home department.`,
                                                                                "warning"
                                                                            );
                                                                        }
                                                                        else if (
                                                                            prts.filter((p) => p.departmentId === data[0].departmentId)
                                                                                .length === 0
                                                                        ) {
                                                                            prts.push(...data);
                                                                            this.setState({ otherDepartments: prts });
                                                                        } else {
                                                                            toastStore.showToast(
                                                                                `${data[0].name} is already in the list.`,
                                                                                "warning"
                                                                            );
                                                                        }

                                                                        //setUsers(data);
                                                                        (ref.current as any)?.clear();
                                                                        ref.current?.blur();
                                                                    }
                                                                }}
                                                                searchText={"Searching..."}
                                                                isLoading={false}
                                                                options={this.state.departments || []}
                                                                placeholder="Search departments"
                                                                minLength={1}
                                                                delay={500}
                                                                useCache={false}
                                                                ref={ref}
                                                            />
                                                        </div>
                                                        <div className="mt-3"></div>
                                                        <div className="currentParticipants mb-3">
                                                            {this.state.otherDepartments
                                                                ?.sort(commonService.sortByStringProperty("name"))
                                                                ?.map((p, i) => {
                                                                    return (
                                                                        <div key={i}>
                                                                            <section>{`${p.name}`}</section>
                                                                            <section
                                                                                title="Remove"
                                                                                onClick={() => {
                                                                                    const prts = [...this.state.otherDepartments || []];
                                                                                    prts.splice(i, 1);
                                                                                    this.setState({ otherDepartments: prts });
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-times"></i>
                                                                            </section>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                        {/*
                                                            <select name="department"
                                                                value={this.state.departmentId}
                                                                className="col-12 rounded-10 h-8 font-size-22"
                                                                onChange={this.handleDepartmentChange}>
                                                                {this.state.isUserAdmin
                                                                    ? this.state.departments?.map((x, i) => {
                                                                        return (
                                                                            <option key={x.departmentId} value={x.departmentId}>{!x ? null : x.name}</option>
                                                                        )
                                                                    })
                                                                    : this.state.departments?.map((x, i) => {
                                                                        if (this.state.userDeptRelations?.find(d => d.departmentId == x.departmentId)) {
                                                                            return (
                                                                                <option key={x.departmentId} value={x.departmentId}>{!x ? null : x.name}</option>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </select>
                                                        */}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4">
                                        <div className="card h-90">
                                            <div className="card-body">
                                                <span><b>2. Role Access</b></span>
                                                <div className="mt-3"></div>
                                                <div className="section-checkbox">
                                                    <div className="row">
                                                        <div className="px-2 col-xl-7 flex flex-nowrap">
                                                            <label>Inactive</label>
                                                            <label className="switch flex-none">
                                                                <input type="checkbox" checked={this.state.active} onChange={this.handleActiveChange} />
                                                                <span className="slider round flex-none"></span>
                                                            </label>
                                                            <label>Active</label>
                                                        </div>
                                                        <button className="pass-reset-button float-right"
                                                            onClick={this.toggleResetPassword}>Reset Password
                                                        </button>
                                                    </div>
                                                </div>
                                                <span>Role</span>
                                                <div className="mt-3"></div>
                                                <div className="roles-container">
                                                    {this.state.availableRoles?.filter(x => {
                                                        if (x.roleId == 1) { // If role is Aps Admin

                                                            //Hide APS Admin role in Personnel Page
                                                            if (this.isPersonnel) {
                                                                return false;
                                                            }

                                                            // Diplay role is user is admin and currently in Aps Department
                                                            return this.context.isAdmin && UserService.currentDepartment.get() === 1;
                                                        }
                                                        return true;
                                                    })?.map((x, i) => {
                                                        return (
                                                            <div className="roles" key={x.roleId}>
                                                                <input type="checkbox"
                                                                    id={`chkRole-${x.roleId}`}
                                                                    name={x.name}
                                                                    checked={x.isActive}
                                                                    className="roles-checkbox"
                                                                    onChange={() => {
                                                                        x.isActive = !x.isActive;
                                                                        this.handleReload();
                                                                    }}
                                                                />
                                                                <label htmlFor={`chkRole-${x.roleId}`}>{x.name}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <button className="pass-reset-button mt-5"
                                                    onClick={this.openModal}>Customize Role
                                                </button>
                                                {resetPassword()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4">
                                        <div className="card h-90">
                                            <div className="card-body">
                                                <span><b>3. Status</b></span>
                                                <div className="mt-4"></div>
                                                <div>
                                                    <div className="status-container">
                                                        <span>Last Logged In</span>
                                                        <div className="mt-2"></div>
                                                        <input type="text"
                                                            placeholder="10/20/2020 by Name"
                                                            value={this.lastLoggedInFormatDate()}
                                                            className="col-12 border rounded-10 h-8"
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="mt-4"></div>
                                                    <div className="status-container">
                                                        <span>Last Modified</span>
                                                        <div className="mt-2"></div>
                                                        <input type="text"
                                                            placeholder="10/20/2020 by Name"
                                                            className="col-12 border rounded-10 h-8"
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="mt-4"></div>
                                                    <div className="status-container">
                                                        <span>Last Emailed</span>
                                                        <div className="mt-2"></div>
                                                        <input type="text"
                                                            placeholder="Date by Name"
                                                            value={this.lastEmailedFormatDate()}
                                                            className="col-12 border rounded-10 h-8"
                                                            readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            
                                </div>

                                <div className={`flex-0 ${this.state.collapse ? "display-none" : ""}`}>
                                    <div className="headerControls">
                                        <div></div>
                                        <div>
                                            {this.props.match?.params?.id && 
                                            <button type="button" className="btn btn-danger" 
                                                disabled={!this.hasChanges()} onClick={() => this.reset()}>
                                                Reset
                                            </button>}
                                            <button onClick={this.handleSubmit} className="btn btn-primary">
                                                <span className="font-size-12">Save Section</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>  

                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.isOpenModal} role="dialog" autoFocus={true} centered={true} tabIndex={-1} size="xl">
                    <div className="modal-content">
                        <ModalHeader >
                            Adjust Individual Access Rights
                        </ModalHeader >
                        <ModalBody>
                            <h6 className="mb-3">How much access do you want this user to have?</h6>
                            <div className="personnelRoleDialogGrid">
                                <UserClaims userId={Number(this.props.match.params.id)} departmentId={this.state.departmentId}
                                    onChange={data => {
                                        this.setState({ userClaims: data.userClaims });
                                    }}>
                                    {/* As per the wireframes, where Dashboard is the first section,
                                    we're inserting Links and Daily Operations as children to UserClaims component
                                    so we can render them to desired order. */}
                                    {this.state.permissionModule?.userRolesItemPermissionDto?.map((element, i) => {
                                        return (
                                            (element.moduleId != Module.LOG_DRILLS && element.moduleId != Module.TRAINING_PORTAL) && //According to the user story, we must ignore training portal and log drills
                                            <div className="role-grid-item" key={element.moduleId}>
                                                <h6 className="mb-3">{element.name}</h6>
                                                <div className="user-roles-container">
                                                    {element.items.map((item, i) => {
                                                        return (
                                                            userRolesPermission.find(x => x.moduleId === element.moduleId)?.items.find(x => x.itemPermission === item.itemPermission)?.name &&
                                                            <div className="custom-control custom-radio mb-3" key={`${item.name}-${i}`}>
                                                                <input type="radio" className="custom-control-input cursor-pointer" checked={item.isActive} onChange={(e) => {}} />
                                                                <label className="custom-control-label cursor-pointer" onClick={() => {
                                                                    this.removeOption(element.moduleId, item.itemPermission);
                                                                    item.isActive = !item.isActive;
                                                                    this.handleReload();                                                                    
                                                                }}>{userRolesPermission.find(x => x.moduleId === element.moduleId)?.items.find(x => x.itemPermission === item.itemPermission)?.name}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="mb-3"></div>
                                            </div>
                                        )
                                    })} 
                                </UserClaims>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" onClick={this.openModal} color="secondary">Close</Button>
                            {!Number(this.props.match.params.id) ?
                                <Button type="button" onClick={this.openModal} color="primary">Save</Button>
                                : <Button type="button" onClick={this.submitModulePermission} color="primary">Submit</Button>}
                        </ModalFooter>
                    </div>
                </Modal>

                {/* User Credential Settings */}
                {this.props.match.params.id && this.props.match.params.id != "add" && (this.state.userId !== 0 && this.state.userId !== null) && <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <UserCredentialSettings departmentId={this.props.match.params.id} userId={this.state.userId}/>
                        </div>                        
                    </div>                    
                </div>}
            
            
                {/* USER ELIGIBILITY */}
                {this.props.match.params.id && this.props.match.params.id != "add" && <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <UserEligibility id={this.props.match.params.id} hasEligibilityChanges={(val) => {
                                this.setState({ hasEligibilityChanges: val });
                            }} />
                        </div>                        
                    </div>                    
                </div>}

                {/* USER GROUPS */}
                {this.props.match.params.id && this.props.match.params.id != "add" && <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <UserGroups id={this.props.match.params.id} />
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}
