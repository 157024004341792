import React from "react";
import ApsModels from "../../../models";
import { useNfirsValidations } from "../../../stores/NFIRSStore";
import { NfirsFetchStatus } from "../NFIRSHelper";

function NFIRSValidationButton(props: {
  module: ApsModels.NfirsValidationModulesEnum;
  onVerify?: () => void;
}) {
  const validation = useNfirsValidations(props.module);

  return (
    <>
      {validation.status[props.module] === NfirsFetchStatus.InProgress ? (
        <div className="nfirs-verify p-1">
          <i className="fa fa-spin fa-2x fa-spinner text-primary"></i>
        </div>
      ) : (
        <>
          {validation.data && validation.data.valid !== undefined && (
            <button
              className={`btn nfirs-verify ${
                validation.valid() ? "btn-success" : "btn-warning"
              }`}
              onClick={props.onVerify}
              title={validation.valid() ? "Valid" : "Validate"}
            >
              <i
                className={`fa ${
                  validation.valid()
                    ? "fa-check-circle"
                    : "fa-exclamation-triangle"
                }`}
              ></i>
            </button>
          )}
        </>
      )}
    </>
  );
}

export default NFIRSValidationButton;
