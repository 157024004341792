import { ICredDepartmentSettingsInputDto, ICredDepartmentSettingsOutputDto } from "../aps2/models/_all";

class CredentialDepartmentSettingsService {

    getDepartmentSettings = async (departmentId: number) => {
        const response = await fetch(`/api/CredSettings/${departmentId}`);
        return (await response.json() as ICredDepartmentSettingsOutputDto)
    }

    saveDepartmentSettings = async (dto: ICredDepartmentSettingsInputDto) => {
        const options: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dto)
        };
        const response = await fetch(`/api/CredSettings`, options);
        return (await response.json() as ICredDepartmentSettingsOutputDto)
    }
}

export default new CredentialDepartmentSettingsService() as CredentialDepartmentSettingsService;