import { SsoService, SsoServiceDto } from '../data/SSO';
import { Group, Section, SectionItem, UserConsoleAdminSectionDto, UserConsoleAdminSectionItemDto, UserConsoleAdminSectionItemGroupDto } from '../data/UserConsoleAdmin'
class UserConsoleAdminService {


    getSections = async (departmentId: number): Promise<UserConsoleAdminSectionDto[]> => {
        const response = await fetch(`/Api/UserConsoleAdmin/Sections/${departmentId}`)
        const value = await response.json() as UserConsoleAdminSectionDto[];
        return value
    }

    insertSectionItem = async (sectionItemDto: UserConsoleAdminSectionItemDto) => {
        const options: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(sectionItemDto)
        };
        const response = await fetch('/Api/UserConsoleAdmin/SectionItem', options);
        const newSection = await response.json() as UserConsoleAdminSectionItemDto;
        return newSection;
    }

    updateSectionItems = async (items: UserConsoleAdminSectionItemDto[]) => {
        const options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(items)
        };
        const response = await fetch('/Api/UserConsoleAdmin/SectionItems', options);
        return response;
    }

    insertSection = async (sectionDto: UserConsoleAdminSectionDto) => {
        const options: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(sectionDto)
        };
        const response = await fetch('/Api/UserConsoleAdmin/Section', options);
        const newSection = await response.json() as UserConsoleAdminSectionDto;
        return newSection;
    }

    updateSections = async (sectionDtos: UserConsoleAdminSectionDto[]) => {
        const options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(sectionDtos)
        };
        const response = await fetch('/Api/UserConsoleAdmin/Sections', options);
        return response;
    }

    deleteSection = async (deleteId: number) => {
        await fetch(`/Api/UserConsoleAdmin/Section/${deleteId}`, { method: 'DELETE' });
    }

    deleteSectionItem = async (deleteId: number) => {
        await fetch(`/Api/UserConsoleAdmin/SectionItem/${deleteId}`, { method: 'DELETE' });
    }

    convertToGroupDto = (group: Group) => {
        const dto: UserConsoleAdminSectionItemGroupDto = {
            name: group.groupName,
            groupId: group.groupId
        }
        return dto;
    }

    convertToSectionItemDto = (item: SectionItem) => {
        const items = item.children.map(this.convertToSectionItemDto);
        const groups = item.groups.map(this.convertToGroupDto);
        const dto: UserConsoleAdminSectionItemDto = {
            userConsoleSectionItemId: item.itemId,
            name: item.itemName,
            url: item.url,
            sortOrder: item.sortOrder,
            userConsoleSectionId: item.sectionId,
            userConsoleSectionItemParentId: item.parentId,
            isRestricted: false,
            ssoTypeId: item.ssoServiceId,
            childSectionItems: items,
            blob: item.blob,
            fileName: item.fileName,
            sectionGroups: groups
        }
        return dto;
    }

    convertToSectionDto = (section: Section) => {
        const items = section.children.map(this.convertToSectionItemDto);
        const dto: UserConsoleAdminSectionDto = {
            userConsoleSectionId: section.sectionId,
            departmentId: section.departmentId,
            name: section.sectionName,
            sectionColumn: section.columnIndex,
            sortOrder: section.sortOrder,
            formatting: section.formatting,
            sectionItems: items
        }
        return dto;
    }

    convertFromGroupDto = (dto: UserConsoleAdminSectionItemGroupDto) => {
        const group: Group = {
            groupName: dto.name,
            groupId: dto.groupId,
        }
        return group
    }

    convertFromSectionItemDto = (dto: UserConsoleAdminSectionItemDto) => {
        const items: SectionItem[] = dto.childSectionItems.map(this.convertFromSectionItemDto);
        const groups: Group[] = dto.sectionGroups.map(this.convertFromGroupDto);
        const item: SectionItem = {
            itemId: dto.userConsoleSectionItemId,
            parentId: dto.userConsoleSectionItemParentId,
            sectionId: dto.userConsoleSectionId,
            itemName: dto.name,
            url: dto.url,
            sortOrder: dto.sortOrder,
            ssoServiceId: dto.ssoTypeId,
            children: items,
            groups: groups,
            blob : dto.blob,
            fileName: dto.fileName
        }
        return item;
    }

    convertFromSectionDto = (dto: UserConsoleAdminSectionDto) => {
        const items: SectionItem[] = dto.sectionItems.map(this.convertFromSectionItemDto);
        const section: Section = {
            sectionId: dto.userConsoleSectionId,
            departmentId: dto.departmentId,
            columnIndex: dto.sectionColumn,
            sectionName: dto.name,
            formatting: dto.formatting,
            sortOrder: dto.sortOrder,
            children: items
        }
        return section;
    }
}

const userConsoleAdminService = new UserConsoleAdminService();
export { userConsoleAdminService as UserConsoleAdminService };