import { makeAutoObservable, reaction } from "mobx";
import { useEffect, useState } from "react";
import { NfirsFetchStatus } from "../components/NFIRS/NFIRSHelper";
import ApsModels from "../models";
import commonService from "../services/CommonService";

interface ITabSection {
  section: string;
  tab: string;
  lock?: boolean;
  saveAll?: boolean;
}
export interface IMoveTabSection {
  next: ITabSection | null;
  prev: ITabSection | null;
  navBack?: boolean;
}

class NFIRSStore {
  private _activeTabSection: ITabSection = {} as any;
  private _moveTabSection: IMoveTabSection = {} as any;
  private _currentNfirsReport: ApsModels.INfirsReportOutputDto = {} as any;
  private _nextTab = 0;
  private _sectionTabBeingSaved?: string;
  private _unsavedTabs: { [key: string]: string[] } = {};
  private _canAcccessModules = {
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  };
  private _moduleValidationStatus: { [key: number]: NfirsFetchStatus } = {};

  private _currentTabForSaveAll: {
    section: string;
    tab: string;
    failed?: boolean;
    next?: boolean;
  } | null = null;
  private _nfirsStructureFireType?: number;
  private _manualValidateModule?: string;
  private _nfirsBasicIncidentType?: number;
  private _nfirsAidGivenReceivedEntryId?: number;
  private _nfirsPropertyUseTypeId?: number;
  private _nfirsValidations?: ApsModels.INfirsValidationOutputDto;
  private _gotoValidatedTab?: ApsModels.NfirsValidationTabsEnum;
  private _manualValidateAll = 0;
  private _tabChangedOnClick = {
    section: "",
    tab: "",
  };
  private _preventNextModuleValidation = false;

  constructor() {
    makeAutoObservable(this);
  }

  get moveTabSection() {
    return this._moveTabSection;
  }

  get currentNfirsReport() {
    return this._currentNfirsReport;
  }

  get activeTabSection() {
    return this._activeTabSection;
  }

  get nextTab() {
    return this._nextTab;
  }

  get sectionTabBeingSaved() {
    return this._sectionTabBeingSaved;
  }

  get unsavedTabs() {
    return this._unsavedTabs;
  }

  get canAcccessModules() {
    return this._canAcccessModules;
  }

  get currentTabForSaveAll() {
    return this._currentTabForSaveAll;
  }

  get nfirsStructureFireType() {
    return this._nfirsStructureFireType;
  }

  get nfirsStructureFireRequiredEnforced() {
    return (
      this._nfirsStructureFireType === 1 || this._nfirsStructureFireType === 2
    );
  }

  get manualValidateModule() {
    return this._manualValidateModule;
  }

  get nfirsBasicIncidentType() {
    return this._nfirsBasicIncidentType;
  }

  get nfirsAidGivenReceivedEntryId() {
    return this._nfirsAidGivenReceivedEntryId;
  }

  get nfirsPropertyUseTypeId() {
    return this._nfirsPropertyUseTypeId;
  }

  get isOtherModuleDataRequired() {
    if (
      !!this._nfirsAidGivenReceivedEntryId &&
      [3, 4].includes(this._nfirsAidGivenReceivedEntryId) //remove 5; https://aps-software.atlassian.net/browse/APS104-21
    ) {
      // if (
      //   this._nfirsAidGivenReceivedEntryId === 5 &&
      //   this._nfirsBasicIncidentType === 111
      // ) {
      //   return true;
      // }
      return false;
    }
    return true;
  }

  get nfirsValidations() {
    return this._nfirsValidations;
  }

  get gotoValidatedTab() {
    return this._gotoValidatedTab;
  }

  get manualValidateAll() {
    return this._manualValidateAll;
  }

  get moduleValidationStatus() {
    return this._moduleValidationStatus;
  }

  get tabChangedOnClick() {
    return this._tabChangedOnClick;
  }

  get preventNextModuleValidation() {
    return this._preventNextModuleValidation;
  }

  resetMoveTabSection(becauseOfInvalidForm = false) {
    this._moveTabSection = { ...this._moveTabSection, next: null, prev: null };

    if (becauseOfInvalidForm && this._currentTabForSaveAll) {
      this.updateCurrentTabForSaveAll(true);
    }
  }

  fullResetMoveTabSection() {
    this._moveTabSection = { next: null, prev: null };
  }

  setMoveTabSection(data: IMoveTabSection) {
    this._moveTabSection = commonService.deepCloneJsonObject(data);
  }

  setMoveToNextTab() {
    if (!this._moveTabSection.prev) {
      this._nextTab = this._nextTab + 1;
    }
    const data = commonService.deepCloneJsonObject(this._moveTabSection);
    data.prev = null;
    this._moveTabSection.navBack = false;
    this._moveTabSection = data;
  }

  setCurrentNfirsReport(data: ApsModels.INfirsReportOutputDto) {
    this._currentNfirsReport = data;
  }

  setActiveTabSection(section: string, tab: string) {
    this._activeTabSection = {
      section,
      tab,
    };
  }

  setSectionTabBeingSaved(moduleTabBeingSaved?: string) {
    this._sectionTabBeingSaved = moduleTabBeingSaved || undefined;
  }

  setUnsavedTabs(section: string, tabsUnsaved: string[]) {
    const data = {} as any;
    data[section] = tabsUnsaved;
    this._unsavedTabs = { ...this._unsavedTabs, ...data };
  }

  clearUnsavedTabs() {
    this._unsavedTabs = {};
  }

  setCanAcccessModule(sec: ApsModels.NfirsValidationModulesEnum, val: boolean) {
    const copy = { ...this._canAcccessModules };
    copy[sec] = val;
    this._canAcccessModules = copy;
  }

  updateCurrentTabForSaveAll(markAsFailed = false, next = false) {
    if ((markAsFailed || next) && this._currentTabForSaveAll) {
      this._currentTabForSaveAll = {
        ...this._currentTabForSaveAll,
        failed: markAsFailed,
        next: next,
      };
    } else {
      this._currentTabForSaveAll = null;
    }
  }

  setCurrentTabForSaveAll(section: string, tab: string, failed = false) {
    this._currentTabForSaveAll = {
      section: section,
      tab: tab,
      failed: failed,
    };
  }

  setNfirsStructureFireType(type?: number) {
    this._nfirsStructureFireType = type;
  }

  setManualValidateModule(manualValidateModule?: string) {
    this._manualValidateModule = manualValidateModule;
  }

  setNfirsBasicIncidentType(type?: number) {
    this._nfirsBasicIncidentType = type;
  }

  setNfirsAidGivenReceivedEntryId(id?: number) {
    this._nfirsAidGivenReceivedEntryId = id;
  }

  setNfirsPropertyUseTypeId(id?: number) {
    this._nfirsPropertyUseTypeId = id;
  }

  setNfirsValidations(val?: ApsModels.INfirsValidationOutputDto) {
    this._nfirsValidations = val;
  }

  setGotoValidatedTab(gotoValidatedTab?: ApsModels.NfirsValidationTabsEnum) {
    this._gotoValidatedTab = gotoValidatedTab;
    setTimeout(() => {
      this.clearGotoValidatedTab();
    }, 1000);
  }

  clearGotoValidatedTab() {
    this._gotoValidatedTab = undefined;
  }

  setModuleValidationStatus(data: { [key: number]: NfirsFetchStatus }) {
    this._moduleValidationStatus = data;
  }

  doManualValidateAll() {
    this._manualValidateAll++;
  }

  /**
   * We want to only call/use this when user tries to change tab
   * by clicking tab header, module or from validation dialogs.
   * This is to allow saving data without clicking Next button
   * which was the original design for this app
   * https://aps-software.atlassian.net/browse/APS104-18
   * @param section
   * @param tab
   */
  setTabChangedOnClick(section: string, tab: string) {
    this._tabChangedOnClick = {
      section,
      tab,
    };
  }

  setPreventNextModuleValidation(val: boolean) {
    this._preventNextModuleValidation = val;
  }
}

const nfirsStore = new NFIRSStore();
export default nfirsStore;

export const useNfirsValidations = (
  section: ApsModels.NfirsValidationModulesEnum
) => {
  const [validation, setValidation] = useState(nfirsStore.nfirsValidations);
  const [validationStatus, setValidationStatus] = useState(
    nfirsStore.moduleValidationStatus
  );
  useEffect(() => {
    const disposer = reaction(
      () => nfirsStore.nfirsValidations,
      (n, p, i) => {
        setValidation(n);
      }
    );
    const disposer2 = reaction(
      () => nfirsStore.moduleValidationStatus,
      (n, p, i) => {
        setValidationStatus(n);
      }
    );
    return () => {
      disposer();
      disposer2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    data: validation,
    status: validationStatus,
    valid: () => {
      if (validation && validation.nfirsVerificationModuleOutputDtos) {
        const opt = validation.nfirsVerificationModuleOutputDtos.find(
          (o) => section === o.nfirsValidationModulesEnum
        );
        if (opt) {
          return opt.valid;
        }
        return true;
      }
    },
  };
};

export const useGotoValidatedTab = () => {
  const [goto, setGoto] = useState(nfirsStore.gotoValidatedTab);
  useEffect(() => {
    const disposer = reaction(
      () => nfirsStore.gotoValidatedTab,
      (n, p, i) => {
        setGoto(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return goto;
};
