import ApsModels from "../../models";
import fetcher from "./Fetcher";
class GenericLookupService {
  getStates = async (countryId: any) => {
    const url = `/api/GenericLookup/GetStates/${countryId}`;
    return fetcher.get<ApsModels.ILookupStatesOutputDto[]>(url);
  };

  getGridPageSizes = async () => {
    const url = `/api/GenericLookup/GetGridPageSizes`;
    return fetcher.get<ApsModels.IGridPageSizesDto>(url);
  };
}

const genericLookupService = new GenericLookupService();
export default genericLookupService;
