import React, { useEffect, useState } from "react";
import CommonSpinner from "../../../../aps2/components/Common/CommonSpinner";
import commonService from "../../../../aps2/services/CommonService";
import { questionGroup } from "../../../../aps2/services/http/_all";
import {
  AnswerDto,
  AnswerOptionReferenceDto,
  AnswerUserReferenceDto,
  QuestionDto,
  QuestionGroupDto,
  QuestionUserGroupFilter,
} from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { OptionGroupDto } from "../../../../data/OptionGroup";
import { UserDto } from "../../../../data/User";
import { GroupService } from "../../../../services/GroupService";
import { OptionService } from "../../../../services/OptionService";
import { PreviousDayService } from "../../../../services/PreviousDayService";
import { UserService } from "../../../../services/UserService";
import { IsolatedInputList } from "../../CustomInput/IsolatedInputList";
import { PullFromPreviousDayButton } from "../../CustomInput/PullFromPreviousDayButton";
import { SelectOption } from "../SelectOption";

interface IGenericWildlandFieldProps {
  questionGroup?: QuestionGroupDto;
  questionUserGroupFilter?: QuestionUserGroupFilter;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  date: Date;
  optionGroups: OptionGroupDto[];
  moduleOrder: number;
  departmentId: number;
  assignmentLength: number | undefined;
  groupName?: string | string[];
  instructions?: string;
}

interface FilteredUserDto {
  0?: UserDto[];
  1?: UserDto[];
  2?: UserDto[];
  3?: UserDto[];
  4?: UserDto[];
}

interface IGenericWildlandFieldState {
  wsaOptions: OptionDto[];
  userDto: FilteredUserDto;
  newOptionId: number | null;
  newOptionGroupId: number | null;
  questionDto: QuestionDto | null;
  optionId: number | null;
  ready: boolean;
}



function GenericWildlandField(props: IGenericWildlandFieldProps) {
  const otherOption = "Other";
  const default_rows = 4;

  const [state, setState] = useState<IGenericWildlandFieldState>({
    wsaOptions: new Array<OptionDto>(),
    userDto: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
    },
    newOptionId: null,
    newOptionGroupId: null,
    questionDto: null,
    optionId: null,
    ready: false,
  });

  const initialize = async () => {
    const allGroup = await GroupService.getAllGroups();

    const groupName = props.groupName ?? "BC Group"; //New Renderer could send Array
    const grpList = Array.isArray(groupName) ? [...groupName] : [groupName];
    const groupIds = grpList
      .map((grpName) => {
        return allGroup.find((gx) => gx.name === grpName)?.groupId || 0;
      })
      .filter((g) => g > 0);
    const users = await UserService.getUsersInMultipleGroupsByDepartmentId(
      groupIds,
      props.departmentId
    );

    let userDto: FilteredUserDto = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
    };

    for (let key in userDto) {
      const property = parseInt(key) as keyof FilteredUserDto;
      if (props.questionUserGroupFilter?.hasOwnProperty(property)) {
        userDto[property] =
          await UserService.getUsersInMultipleGroupsByDepartmentId(
            props.questionUserGroupFilter[property] ?? groupIds,
            props.departmentId
          );
      } else {
        userDto[property] = users;
      }
    }

    setState((prev) => {
      return {
        ...prev,
        userDto: userDto,
      };
    });

    const wsaOptions = await getOptions();
    let newOptionId: any = null;
    let newOptionGroupId: any = null;
    wsaOptions.forEach((wsaOption) => {
      if (wsaOption.name === otherOption) {
        newOptionId = wsaOption.optionId;
        newOptionGroupId = wsaOption.optionGroupId;
      }
    });

    setState((prev) => {
      return {
        ...prev,
        wsaOptions: wsaOptions,
        newOptionId: newOptionId,
        newOptionGroupId: newOptionGroupId,
        ready: true,
      };
    });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOptions = async (): Promise<OptionDto[]> => {
    const wsaOptionsGroup = props.optionGroups.find(
      (g) => g.name === "WSA Options"
    );
    return wsaOptionsGroup
      ? await OptionService.getGroupOptions(wsaOptionsGroup.optionGroupId)
      : [];
  };

  const factorySubGroup = (value: QuestionDto | undefined) => {
    if (
      value?.answers.find((answer) => {
        return answer?.option?.optionId === state.newOptionId;
      }) != null
    ) {
      setState((prev) => {
        return {
          ...prev,
          questionDto: value,
        };
      });
    } else {
      saveValue(value);
    }
  };

  const saveValue = (value: QuestionDto | undefined) => {
    const questionGroup = props.questionGroup;
    const index = props.questionGroup?.questions.findIndex(
      (x) => x.order === value?.order
    );
    if (index !== undefined) {
      if (questionGroup?.questions[index] && value) {
        questionGroup.questions[index] = value;
      }
    }
    props.setData(questionGroup, props.moduleOrder);
  };

  const handlePullFromPreviousDay = async (
    newQuestionGroupValue: QuestionGroupDto
  ) => {
    props.setData(newQuestionGroupValue, props.moduleOrder);
  };

  const getPropsValue = (propName: string) => {
    return ((props || ({} as any)) as { [key: string]: any })[propName];
  };

  function formatName(answer: AnswerUserReferenceDto | undefined) {
    return (answer?.userId ? `${state.userDto[0]?.find(y => y.userId == answer.userId)?.firstName} ${state.userDto[0]?.find(y => y.userId == answer.userId)?.lastName}` : answer?.userName)
  }

  function formatOption(answer: AnswerOptionReferenceDto | undefined) {
    return (answer?.optionId ? `${state.wsaOptions?.find(y => y.optionId == answer.optionId)?.name}}` : answer?.optionName)
  }


  return (
    <div className="card box-card flex-grow-1">
      {!state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
      <div  className="card-body ">
        <div className="row">
          <h4 className="mb-0 font-size-16 col-xl-11 col-10">
            {props.questionGroup?.name || ""}
          </h4>
          {PreviousDayService.isFeatureAvailable(props.date) && (
            <PullFromPreviousDayButton
              questionGroupId={props.questionGroup?.questionGroupId!}
              calendarDate={props.date}
              setData={handlePullFromPreviousDay}
            />
          )}
        </div>
        <div className="mt-3">{props.instructions || ""}</div>
        <div className="row mt-4">
          {props.questionGroup?.questions
            ?.filter((q) => !!(state.userDto as any)[q.order])
            ?.map((qtn, i) => (
              <div
                key={`${qtn.order}-${i}`}
                className={`col-xl-6 mb-3 ${
                  getPropsValue(`hideQuestion${qtn.order}`)
                    ? "display-none"
                    : ""
                }`}
              >
                <div className="row px-2 align-items-baseline">
                  <div className="col-xl-5">{qtn.questionText}:</div>
                  <div className="col-xl-7 pr-0">
                    <SelectOption
                      setData={factorySubGroup}
                      order={qtn.order}
                      wsaOptions={state.wsaOptions}
                      questionGroup={props.questionGroup}
                      question={qtn}
                      date={props.date}
                      moduleOrder={props.moduleOrder}
                    />
                  </div>
                </div>
                <div className="row px-2 mt-3">
                  {qtn.answers && (
                    <IsolatedInputList
                      setData={factorySubGroup}
                      defaultItemsCount={qtn?.limit ?? default_rows}
                      answerDto={qtn.answers}
                      userDto={(state.userDto as any)[qtn.order]}
                      isOption={false}
                      question={qtn}
                      date={props.date}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="card box-card flex-grow-1 d-none childsection3">
          <div className="row">
            <h4 className="mb-2 font-size-22 col-xl-12 col-12">
              <span>{props.questionGroup?.name || ""}</span>
              <hr  className="mt-4 mb-4" />
            </h4>
          </div>
          <div className="row font-size-20">
            {props.questionGroup?.questions
              ?.filter((q) => !!(state.userDto as any)[q.order])
              ?.map((qtn, i) => (
                <div
                  key={qtn.order}
                  className={`d-flex mx-3 ${
                    getPropsValue(`hideQuestion${qtn.order}`)
                      ? "d-print-none"
                      : ""
                  }`}
                >
                  <div className="mb-3">
                    <span><b> {qtn.questionText} :</b> {qtn.answers[0]?.option ? formatOption(qtn.answers[0].option) : ""} </span>

                    <div className="grid-pdf">
                      {qtn.answers.map(x => {
                        return (
                          <span> {commonService.getUserShortName(formatName(x.user) ?? "")}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
    </div>
  )
}

export default GenericWildlandField;
