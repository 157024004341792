import React from "react";
import { FieldValues, FormState } from "react-hook-form";

export interface IFormError {
  formState: FormState<FieldValues>;
  field: string;
  fieldDisplayName?: string;
}

function FormError({ formState, field, fieldDisplayName }: IFormError) {
  let error = formState?.errors[field];
  let message = error?.message;

  if (!message) {
    switch (error?.type) {
      case "required":
        message = fieldDisplayName
          ? `${fieldDisplayName} is required`
          : `Required`;
        break;
      case "maxLength":
        message = `Max Length is ${(error?.ref as any)?.maxLength} characters`;
        break;
      case "minLength":
        message = `Min Length is ${(error?.ref as any)?.minLength} characters`;
        break;
      case "exists":
        message = `Already exists`;
        break;
      case "time":
        message = `Invalid Time Value. Use HH:MM (00:00 to 23:59)`;
        break;
      case "duration":
        message = `Drill duration must be between 0.25 and 6 hours`;
        break;
      case "email":
        message = `Invalid Email Format`;
        break;
      case "passwordNotMactch":
        message = `The passwords do not match`;
        break;
      case "numberOnly":
        message = `Numbers Only`;
        break;
      case "twoDecimalsOnly":
        message = `Invalid Format (0.00)`;
        break;
      case "oneDecimalsOnly":
        message = `Invalid Format (0.0)`;
        break;
      case "lettersDashAndSpace":
        message = `Letters, spaces and dashes only`;
        break;
      case "date":
        message = `Invalid Date`;
        break;
    }
  }

  //console.log(errors);
  return (
    <>
      {formState?.errors[field] && (
        <div>
          <small className="txt-danger">{message || "Invalid"}</small>
        </div>
      )}
    </>
  );
}

export default FormError;
