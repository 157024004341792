import ApsModels from "../../models";
import commonService from "../../services/CommonService";

class NFIRSDynamicReportsHelper {
  allTabsSelected = (
    moduleId: string,
    tabIds: string[],
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) => {
    return (
      tabIds.length > 0 &&
      tabIds.length ===
        tabIds.filter((id) => this.isTabSelected(id, moduleId, temp)).length
    );
  };

  allFieldSelected = (
    moduleId: string,
    fieldIds: { id: string; tabId: string }[],
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) => {
    return (
      fieldIds.length > 0 &&
      fieldIds.length ===
        fieldIds.filter((f) =>
          this.isFieldSelected(f.id, f.tabId, moduleId, temp)
        ).length
    );
  };

  allTabFieldSelected = (
    moduleId: string,
    tabId: string,
    fieldIds: string[],
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) => {
    return (
      fieldIds.length > 0 &&
      fieldIds.length ===
        fieldIds.filter((id) => this.isFieldSelected(id, tabId, moduleId, temp))
          .length
    );
  };

  isModuleSelected = (
    moduleId: string,
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) => {
    const modName = `is${moduleId}ModuleIncluded`;
    return (temp as any)[modName] || false;
  };

  setModuleSelected = (
    moduleId: string,
    val: boolean,
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) => {
    let copy = { ...temp } as any;
    const modName = `is${moduleId}ModuleIncluded`;
    copy[modName] = val || false;
    return copy;
  };

  isTabSelected = (
    tabId: string,
    moduleId: string,
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) => {
    const modName = `${commonService.camelize(moduleId)}DynamicRequestDto`;
    const tabName =
      tabId.indexOf(moduleId) > -1
        ? `is${tabId}TabIncluded`
        : `is${moduleId}${tabId}TabIncluded`;
    return (
      this.isModuleSelected(moduleId, temp) &&
      ((temp as any)?.[modName]?.[tabName] || false)
    );
  };

  setTabSelected = (
    tabId: string,
    moduleId: string,
    val: boolean,
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) => {
    let copy = { ...temp } as any;
    const modName = `${commonService.camelize(moduleId)}DynamicRequestDto`;
    const tabName =
      tabId.indexOf(moduleId) > -1
        ? `is${tabId}TabIncluded`
        : `is${moduleId}${tabId}TabIncluded`;
    copy[modName] = {
      ...copy[modName],
    };
    copy[modName][tabName] = {
      ...copy[modName][tabName],
    };
    copy[modName][tabName] = val || false;
    return copy;
  };

  isFieldSelected = (
    fieldId: string,
    tabId: string,
    moduleId: string,
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) => {
    const modName = `${commonService.camelize(moduleId)}DynamicRequestDto`;
    const tabName = `${commonService.camelize(tabId)}DynamicRequestDto`;
    const fieldName = `${commonService.camelize(fieldId)}`;
    if ((temp as any)[modName] && (temp as any)[modName][tabName]) {
      return (
        (this.isTabSelected(tabId, moduleId, temp) &&
          (temp as any)[modName][tabName][fieldName]) ||
        false
      );
    }
    return false;
  };

  setFieldSelected(
    fieldId: string,
    tabId: string,
    moduleId: string,
    val: boolean,
    temp: ApsModels.INfirsDynamicReportRequestDto
  ) {
    let copy = { ...temp } as any;
    const modName = `${commonService.camelize(moduleId)}DynamicRequestDto`;
    const tabName = `${commonService.camelize(tabId)}DynamicRequestDto`;
    const fieldName = `${commonService.camelize(fieldId)}`;
    copy[modName] = {
      ...copy[modName],
    };
    copy[modName][tabName] = {
      ...copy[modName][tabName],
    };
    copy[modName][tabName][fieldName] = val || false;
    return copy;
  }
}

const nfirsDynamicReportsHelper = new NFIRSDynamicReportsHelper();
export default nfirsDynamicReportsHelper;
