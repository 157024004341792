import React, { useEffect } from "react";
import { IDrillReportProps } from ".";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import { FgSelect, IChildCardProps } from "../../Common/FormGroups";
import PreviousDayIcon from "../../../../assets/images/PreviousDayIcon.png";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRS/NFIRSHelper";

function DrillCompanyShift(props: IChildCardProps<IDrillReportProps>) {
  const template = (props?.data?.template ||
    {}) as ApsModels.IDrillTemplateOutputDto;

  let companies = template.companies || [];
  let shifts = template.logDrillShifts || [];

  companies.sort(ApsServices.common.sortByName);
  shifts.sort(ApsServices.common.sortByName);

  const getLastCompanyShift = useNfirsGetData(
    ApsServices.http.drillReport.listLastCompanyAndShift,
    "Company and Shift"
  );

  useEffect(() => {
    if (
      getLastCompanyShift.data &&
      getLastCompanyShift.status === NfirsFetchStatus.Complete &&
      props.setValue
    ) {
      if (getLastCompanyShift.data.companyId) {
        props.setValue("companyId", getLastCompanyShift.data.companyId);
      }
      if (getLastCompanyShift.data.logDrillShiftId) {
        props.setValue(
          "logDrillShiftId",
          getLastCompanyShift.data.logDrillShiftId
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLastCompanyShift.status]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="flex flex-center">
          <div className="flex-1">
            <strong>{props?.data?.sectionNumber}. Company and Shift</strong>
          </div>
          <div>
            <img
              src={PreviousDayIcon}
              alt="refresh button"
              className="refresh-button"
              onClick={(e) => {
                if (
                  getLastCompanyShift.status !== NfirsFetchStatus.InProgress
                ) {
                  getLastCompanyShift.getData();
                }
              }}
            />
          </div>
        </div>
        {props?.data?.template?.isCompanyEnabled && (
          <>
            <div className="mt-4"></div>
            <FgSelect
              id={props.form.companyId.name}
              label="Company"
              showRequiredLabel={true}
              formState={props.formState}
              registeredField={props.form.companyId}
              options={companies?.map((c) => {
                return {
                  label: c.name,
                  value: c.id,
                };
              })}
              disabled={!props.data.allowEdit}
            />
          </>
        )}

        {props?.data?.template?.isShiftEnabled && (
          <>
            <div className="mt-4"></div>
            <FgSelect
              id={props.form.logDrillShiftId.name}
              label="Shift"
              showRequiredLabel={true}
              formState={props.formState}
              registeredField={props.form.logDrillShiftId}
              options={shifts?.map((c) => {
                return {
                  label: c.name,
                  value: c.id,
                };
              })}
              disabled={!props.data.allowEdit}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default DrillCompanyShift;
