//PersonnelService

import ApsModels from "../../models";
import fetcher from "./Fetcher";

class PersonnelService {
  save = async (data: ApsModels.IPersonnelInputDto) => {
    const url = "/api/Personnel/Save";
    return fetcher.post<ApsModels.IPersonnelOutputDto>(url, data);
  };

  list = async (page: number, pageSize: number) => {
    const url = `/api/Personnel/List/${page}/${pageSize}`;
    return fetcher.get<ApsModels.IPersonnelGridOutputDto>(url);
  };

  get = async (userId: number) => {
    const url = `/api/Personnel/${userId}`;
    return fetcher.get<ApsModels.IPersonnelOutputDto>(url);
  };

  saveModulePermission = async (
    userId: number,
    data: ApsModels.IPersonnelModuleSecurityDto
  ) => {
    const url = `/api/Personnel/SaveModulePermission/${userId}`;
    return fetcher.post<any>(url, data);
  };

  getUserPersonnelInfo = async (userId: number) => {
    const url = `/api/Personnel/GetUserPersonnelInfo/${userId}`;
    return fetcher.get<ApsModels.IPersonnelUserInfoOutputDto>(url);
  };
}

const personnel = new PersonnelService();
export default personnel;
