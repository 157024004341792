import React, { Component } from 'react';
import { ApsDatePicker } from '../../Shared/ApsDatePicker';
import '../DailyOperational.scss';
import moment, { Moment } from 'moment';
import { StaffCaptain } from '../Sections/StaffCaptain';
import { Dropdown} from '../Sections/Dropdown';
import { BadgeBand } from '../Sections/BadgeBand';
import { Flags } from '../Sections/Flags';
import { ShiftActivity } from '../Sections/ShiftActivity';
import { WildlandSpecialtyApparatus } from '../Sections/WildlandSpecialtyApparatus';
import { ReserveApparatusStatus } from "../Sections/ReserveApparatusStatus";
import { OnCallList } from "../Sections/OnCallList";
import { UsarResponse } from "../Sections/UsarResponse";
import { HazmatResponse } from "../Sections/HazmatResponse";
import { PublicEducation } from "../Sections/PublicEducation";
import { CommunityRoom } from "../Sections/CommunityRoom";
import { SmallConferenceRoom } from '../Sections/SmallConferenceRoom';
import { ShiftColorService } from '../../../services/ShiftColorService'
import { ShiftColor } from "../../../data/ShiftColor";
import { FormService } from "../../../services/FormService";
import { QuestionGroupTemporaryStorageService } from "../../../services/QuestionGroupTemporaryStorageService";
import {
    CalendarDto,
    FormCalendarDto,
    FormDto,
    GlendaleModuleOrder,
    QuestionGroupDto,
    ShiftDto
} from "../../../data/DailyOperational";
import { UserService } from '../../../services/UserService';
import { AppContext } from "../../../AppContext";
import { Unsubscriber } from '../../../common/Observable';
import { StrikeTeams } from "../Sections/StrikeTeams/StrikeTeams";
import cloneDeep from 'lodash/cloneDeep';
import SweetAlert from "react-bootstrap-sweetalert";
import { OptionGroupService } from '../../../services/OptionGroupService';
import { OptionGroupDto } from '../../../data/OptionGroup';
import { PdfService } from "../../../services/PdfService";
import { FileDownloadService } from "../../../services/FileDownloadService";
import ConfirmDialog from '../../../aps2/components/Common/ConfirmDialog';
import commonService from '../../../aps2/services/CommonService';
import RouteChangedGuard from '../../../aps2/components/Common/RouteChangedGuard';
import { RouteComponentProps } from 'react-router';
import ApsDatePicker2 from '../../Shared/ApsDatePicker2';
import { ShiftSchedulerService } from '../../../services/ShiftSchedulerService';

type Props = RouteComponentProps<{}> & {}

type State = {
    selectedDate: Date,
    shiftColor: ShiftColor,
    form: FormDto | null,
    originalForm: FormDto | null,
    departmentId: number,
    isCalendarDisabled: boolean,
    showAlert: boolean,
    optionGroups: OptionGroupDto[],
    isMobile: boolean,
    showReportError: boolean,
    showSaveConfirmation: boolean,
    isAllowPrint: boolean,
    saving: boolean,
    showAskUnsavedChanges: boolean,
    onDiscardChanges?: () => void,
    loading: boolean
    shiftColorStr: string
}

export class GlendaleDailyOperational extends Component<Props, State> {
    static contextType = AppContext;
    private _isAdminDepartmentUnsubscriber: Unsubscriber | null = null;

    state: State = {
        selectedDate: moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate(),
        shiftColor: ShiftColorService.getShiftColorBySpecificDay(moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'), -1),
        form: null,
        originalForm: null,
        departmentId: 0,
        isCalendarDisabled: false,
        showAlert: false,
        optionGroups: [],
        isMobile: false,
        showReportError: false,
        showSaveConfirmation: false,
        isAllowPrint: false,
        saving: false,
        showAskUnsavedChanges: false,
        onDiscardChanges: () => {},
        loading: true,
        shiftColorStr: ""
    }

    private handleResize = () => {
        const tabletMaxWidth = 767;
        this.setState({
            isMobile: window.screen.width <= tabletMaxWidth || window.innerWidth <= tabletMaxWidth
        });
    };

    setDepartmentId = async (departmentId: number) => {        
        this.setState({ loading : true });
        try {
            const form = await FormService.getDailyOperationalForm(this.state.selectedDate, departmentId);
            this.setState({
                form: form,
                originalForm: cloneDeep(form),
                departmentId: departmentId
            });
        } catch {
            this.setState({
                form: null,
                originalForm: null,
                departmentId: 0
            });
        }
        this.setState({ loading : false });
    }

    componentDidMount = async () => {
        this._isAdminDepartmentUnsubscriber = UserService.currentDepartment.subscribe(async (x) => await this.setDepartmentId(x))
        window.addEventListener("beforeunload", this.handleTabClosed);
        window.addEventListener('resize', this.handleResize);

        await this.setCalendar();
        await this.setDepartmentId(this.context.module);

        const shiftStr = moment.utc(this.state.selectedDate).format('dddd, MMMM D, YYYY');
        const shiftDate = ShiftSchedulerService.convertDateStrToDepartmentTimeZone(shiftStr, this.state.departmentId);
        const shift = await ShiftSchedulerService.getShiftForDepartmentByDate(this.state.departmentId, shiftDate);
        this.setState({ shiftColorStr: shift.color })

        this.handleResize()
        const optionGroups: OptionGroupDto[] = await OptionGroupService.getAllOptionGroupsByDepartment(this.state.departmentId);
        this.setState({ 
            optionGroups: optionGroups, 
            shiftColor: ShiftColorService.getShiftColorBySpecificDay(moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'), this.state.departmentId) 
        })        
    }

    componentWillUnmount = async () => {
        this._isAdminDepartmentUnsubscriber?.();
        window.removeEventListener("beforeunload", this.handleTabClosed);
        window.removeEventListener('resize', this.handleResize);
        QuestionGroupTemporaryStorageService.clear();
    }

    printDocument = async () => {
        await this.save()
        this.setState({ showAlert: true });
        const date = moment.utc(this.state.selectedDate).toDate();
        let printDate = moment.utc(this.state.selectedDate).format('MMDDYYYY');
        PdfService.downloadPdf(date, this.context.module).then(async (response) => {
            if (response.status === 200) {
                const blob = await response.blob();
                FileDownloadService.downloadFile(blob, `Daily Operational ${ printDate }.pdf`, 'application/pdf');
            } else {
                this.setState({
                    showReportError: true
                });
            }
        })
        .finally(() => {
            this.setState({ showAlert: false });
        });
    }

    saveClicked = async (): Promise<void> => {
        await this.save();
        this.setState({ showSaveConfirmation: true });
        this.props.history.replace("/dailyoperational" + commonService.doneUrls.submitted);
    }

    refreshForm = async (selectedDate: Moment) => {
        const date = selectedDate.toDate();
        this.setState({
            form: null,
            loading : true
        });

        const shiftStr = moment.utc(selectedDate.format('YYYY-MM-DD'), 'YYYY-MM-DD').format("yyyy-MM-DDThh:mm:ss");
        const shiftDate = ShiftSchedulerService.convertDateStrToDepartmentTimeZone(shiftStr, this.state.departmentId);
        const shift = await ShiftSchedulerService.getShiftForDepartmentByDate(this.state.departmentId, shiftDate);
        this.setState({ shiftColorStr: shift.color })
 
        const form = await FormService.getDailyOperationalForm(date, this.state.departmentId);

        this.setState({
            form: form,
            originalForm: cloneDeep(form),
            selectedDate: date,
            shiftColor: ShiftColorService.getShiftColorBySpecificDay(selectedDate, this.state.departmentId),
            loading: false
        });

        await this.setCalendar();

        QuestionGroupTemporaryStorageService.clear();
    }

    factoryForm = (value: QuestionGroupDto | undefined, order: number): void => {
        if (value && this.state.form) {
            let form = cloneDeep(this.state.form);

            for (let i = 0; i < form.questionGroups.length; i++) {
                if (form.questionGroups[i].order === order) {
                    form.questionGroups[i] = value;

                    if (QuestionGroupTemporaryStorageService.exists(value)) {
                        QuestionGroupTemporaryStorageService.update(value);
                    }
                    else {
                        QuestionGroupTemporaryStorageService.add(value);

                        if(order === GlendaleModuleOrder.StrikeTeams || order === GlendaleModuleOrder.BadgeBand) { //StrikeTeams and BadgeBand creates default answer
                            if(this.state.originalForm) {
                                let origForm = cloneDeep(this.state.originalForm);
                                const ogFormQG = origForm.questionGroups[i];
                                if(!ogFormQG.questions.find(x => x.answers.length > 0)) {
                                    origForm.questionGroups[i] = cloneDeep(value);
                                    this.setState({ originalForm: origForm });
                                }                                
                            }                            
                        }
                    }
                }
            }
            this.setState({ form: form });
        }
    }

    setShowAlertMessage = () => {
        this.setState({ showAlert: !this.state.showAlert })
    }

    handleReportErrorMessageClosed = () => {
        this.setState({ showReportError: !this.state.showReportError })
    }

    handleSaveConfirmationClosed = () => {
        this.setState({ showSaveConfirmation: !this.state.showSaveConfirmation });
        this.props.history.replace("/dailyoperational");
    }

    private async setCalendar(): Promise<void> {
        if (!this.state.form) return;

        const calendarDate: Moment = moment(this.state.selectedDate);
        const existingCalendar: CalendarDto | undefined = this.state.form.calendars.find(c => c.day === calendarDate.date() &&
            c.year === calendarDate.year() &&
            c.month === calendarDate.month() + 1 &&
            c.shifts.find(s => s.color === this.state.shiftColor));

        if (!existingCalendar) {
            const calendar: CalendarDto = this.createCalendar(calendarDate);
            this.setState({isAllowPrint : false})
            await this.saveFormCalendar(calendar)
            .then(x => {
                this.setState({isAllowPrint : true})
            })
        }
    }

    private async saveFormCalendar(calendar: CalendarDto): Promise<void> {
        if (!this.state.form) return;

        const formCalendarDto: FormCalendarDto = {
            formId: this.state.form.formId,
            calendar: calendar
        };

        const newFormCalendar: CalendarDto = await FormService.createCalendar(formCalendarDto);
        const form: FormDto = cloneDeep(this.state.form);

        form.calendars = [newFormCalendar];

        this.setState({
            form: form,
            originalForm: cloneDeep(form)
        });
    }

    private createCalendar(calendarDate: Moment) {
        const calendar: CalendarDto = {
            calendarId: 0,
            date: this.state.selectedDate,
            year: calendarDate.year(),
            month: calendarDate.month() + 1,
            day: calendarDate.date(),
            quarter: calendarDate.quarter(),
            week: calendarDate.week(),
            dayOfWeek: calendarDate.day(),
            shifts: []
        };

        const shift: ShiftDto = {
            shiftId: 0,
            name: 'test',
            color: this.state.shiftColor
        };

        calendar.shifts.push(shift);
        return calendar;
    }

    private getQuestionGroup(moduleOrder: GlendaleModuleOrder): QuestionGroupDto | undefined {
        return this.state.form?.questionGroups.find(x => x.order === moduleOrder);
    }

    private save = async (): Promise<void> => {
        await this.setCalendar();

        const questionGroups: QuestionGroupDto[] = QuestionGroupTemporaryStorageService.getAll();
        if (questionGroups.length === 0) return;

        this.setState({ saving: true });
        const questionGroupsUpdated: QuestionGroupDto[] = await FormService.updateQuestionGroups({
            questionGroups: questionGroups,
            calendarDate: this.state.selectedDate
        });
        this.setState({ saving: false });
        QuestionGroupTemporaryStorageService.clear();

        if (questionGroupsUpdated && this.state.form) {
            let form = cloneDeep(this.state.form);
            const questionGroupsIds: number[] = questionGroupsUpdated.map(q => q.questionGroupId);

            for (let i = 0; i < form.questionGroups.length; i++) {
                if (questionGroupsIds.indexOf(form.questionGroups[i].questionGroupId) !== -1) {
                    form.questionGroups[i] = questionGroupsUpdated.find(g => g.questionGroupId === form.questionGroups[i].questionGroupId)!;
                }
            }
            this.setState({ form: form, originalForm: cloneDeep(form) });
        }
    }

    private handleTabClosed = (event: any) => {
        if(this.hasChanges()) {
            return "You have some unsaved changes";
        }        
    }

    private hasChanges = () => {
        const currentData = QuestionGroupTemporaryStorageService.getAll();
        if(currentData.length === 0) {
            return false;
        }         
        let hasChanges = false;
        currentData.forEach((qp, i) => {
            if(!hasChanges && this.state.originalForm?.questionGroups) {
                var origData = this.state.originalForm?.questionGroups?.find(q => q.questionGroupId === qp.questionGroupId) || {};
                var newData = qp;
                hasChanges = JSON.stringify(commonService.replaceEmptyString(newData, null)) !== JSON.stringify(commonService.replaceEmptyString(origData, null));
            }
        });
        return hasChanges;
    }

    checkAndAskForUnsavedChanges = (actionToDo: () => void) => {
        if(this.hasChanges()) {
            this.setState({ showAskUnsavedChanges: true, onDiscardChanges: actionToDo });
        } else {
            this.setState({ showAskUnsavedChanges: false, onDiscardChanges: undefined });
            actionToDo();
        }
    }

    handleDateChanged = async (date: Date, shiftColor: ShiftColor): Promise<void> => {          
        this.checkAndAskForUnsavedChanges(async () => {
            this.setState({
                isCalendarDisabled: true
            });
            this.setState({ selectedDate: date, shiftColor: shiftColor });
            await this.refreshForm(moment.utc(date));
            this.setState({
                isCalendarDisabled: false
            });
        });
    }

    handleYesterdayClicked = async (): Promise<void> => {
        this.checkAndAskForUnsavedChanges(async () => {
            this.setState({
                isCalendarDisabled: true
            });
            const yesterday: Moment = moment.utc(this.state.selectedDate).add(-1, 'days');
            await this.refreshForm(yesterday);
            this.setState({
                isCalendarDisabled: false
            });
        });
    }

    handleTomorrowClicked = async (): Promise<void> => {
        this.checkAndAskForUnsavedChanges(async () => {
            this.setState({
                isCalendarDisabled: true
            });
            const tomorrow = moment.utc(this.state.selectedDate).add(1, 'days');
            await this.refreshForm(tomorrow);
            this.setState({
                isCalendarDisabled: false
            });
        });
    }

    handleTodayClicked = async (): Promise<void> => {
        this.checkAndAskForUnsavedChanges(async () => {        
            this.setState({
                isCalendarDisabled: true
            });
            const today = moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
            await this.refreshForm(today);
            this.setState({
                isCalendarDisabled: false
            });
        });
    }

    render() {
        const questionsMap: Map<number, React.ReactNode> = new Map();
        questionsMap.set(GlendaleModuleOrder.OperationsBC, <Dropdown 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.OperationsBC)} 
            setData={this.factoryForm} date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.OperationsBC}
            departmentId={this.state.departmentId} />)
        questionsMap.set(GlendaleModuleOrder.StaffCaptain, <StaffCaptain 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.StaffCaptain)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.StaffCaptain}
            departmentId={this.state.departmentId} />)
        questionsMap.set(GlendaleModuleOrder.BadgeBand, <BadgeBand 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.BadgeBand)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.BadgeBand} />)
        questionsMap.set(GlendaleModuleOrder.Flags, <Flags 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.Flags)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate} 
            moduleOrder={GlendaleModuleOrder.Flags} />)
        questionsMap.set(GlendaleModuleOrder.ShiftActivity, <ShiftActivity 
            isVisibleShiftInvestigator={true}
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.ShiftActivity)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.ShiftActivity}
            departmentId={this.state.departmentId} />)
        questionsMap.set(GlendaleModuleOrder.WildlandSpecialtyApparatus, <WildlandSpecialtyApparatus 
            optionGroups={this.state.optionGroups} 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.WildlandSpecialtyApparatus)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.WildlandSpecialtyApparatus}
            departmentId={this.state.departmentId} 
            assignmentLength={14}/>)
        questionsMap.set(GlendaleModuleOrder.StrikeTeams, <StrikeTeams 
            optionGroups={this.state.optionGroups} 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.StrikeTeams)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.StrikeTeams}
            departmentId={this.state.departmentId} />)
        questionsMap.set(GlendaleModuleOrder.OnCallList, <OnCallList 
            optionGroups={this.state.optionGroups} 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.OnCallList)} 
            date={this.state.selectedDate} 
            setData={this.factoryForm}
            moduleOrder={GlendaleModuleOrder.OnCallList}
            departmentId={this.state.departmentId} />)
        questionsMap.set(GlendaleModuleOrder.UsarResponse, <UsarResponse 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.UsarResponse)} 
            date={this.state.selectedDate} 
            setData={this.factoryForm}
            moduleOrder={GlendaleModuleOrder.UsarResponse}
            departmentId={this.state.departmentId} />)
        questionsMap.set(GlendaleModuleOrder.HazmatResponse, <HazmatResponse 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.HazmatResponse)} 
            date={this.state.selectedDate} 
            setData={this.factoryForm}
            moduleOrder={GlendaleModuleOrder.HazmatResponse}
            departmentId={this.state.departmentId} />)
        questionsMap.set(GlendaleModuleOrder.ReserveApparatusStatus, <ReserveApparatusStatus 
            columnNumber={4}
            needsToAutopopulate={true}
            optionGroups={this.state.optionGroups} 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.ReserveApparatusStatus)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.ReserveApparatusStatus} />)
        questionsMap.set(GlendaleModuleOrder.PublicEducation, <PublicEducation 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.PublicEducation)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.PublicEducation} />)
        questionsMap.set(GlendaleModuleOrder.CommunityRoom, <CommunityRoom 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.CommunityRoom)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.CommunityRoom} />)
        questionsMap.set(GlendaleModuleOrder.SmallConferenceRoom, <SmallConferenceRoom 
            questionGroup={this.getQuestionGroup(GlendaleModuleOrder.SmallConferenceRoom)} 
            setData={this.factoryForm} 
            date={this.state.selectedDate}
            moduleOrder={GlendaleModuleOrder.SmallConferenceRoom} />)

        return (
            <>
              
                <RouteChangedGuard 
                    when={true} 
                    navigate={path => this.props.history.push(path)}
                    shouldBlockNavigation={location => {                     
                        return this.hasChanges();
                    }} 
                    title="Confirmation" 
                    message="Do you want to discard unsaved changes?"
                /> 
                
                <ConfirmDialog 
                    show={this.state.showAskUnsavedChanges}
                    buttons="yesno" 
                    title="Confirmation" 
                    message="Do you want to discard unsaved changes?"
                    done={rtn => {
                        if(rtn === "yes") {
                            this.state.onDiscardChanges && this.state.onDiscardChanges();
                            this.setState({ showAskUnsavedChanges: false, onDiscardChanges: undefined });
                            return;
                        }
                        this.setState({ showAskUnsavedChanges: false });
                    }}></ConfirmDialog>
            
                <div id="printPdf" className="container-fluid h-full">
                    <div className="row">
                        <div className="col page-title-box d-flex">
                            <div className="mr-auto align-self-center">
                                <h1 className="mb-0 font-size-20">DAILY OPERATIONAL</h1>
                            </div>
                            <div className="mb-0">
                                <button className="btn submit-button rounded-lg print-button ml-auto" onClick={this.printDocument}
                                    disabled={this.state.saving || !this.state.form}>
                                    &nbsp;&nbsp;Export
                                </button>                 
                                <button className="btn btn-primary rounded-lg ml-2" onClick={this.saveClicked} 
                                    disabled={this.state.saving || !this.hasChanges()}>
                                    {this.state.saving ? "Saving...": "Save"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row h-full">
                        <div className="col">
                            {!this.state.isCalendarDisabled && this.state.shiftColorStr !== "" &&
                                <div className={`daily-operational-form card`} style={{backgroundColor: this.state.shiftColorStr}}>
                                    {this.state.isMobile ?
                                        <>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col d-flex justify-content-center">
                                                    <button disabled={this.state.isCalendarDisabled} className={`btn btn-white mr-4 daily-operational-btn`} style={{color: this.state.shiftColorStr}} onClick={this.handleTodayClicked}>Today</button>
                                                    <div className="d-flex">
                                                    <button disabled={this.state.isCalendarDisabled} className={`btn btn-white daily-operational-btn`} style={{color: this.state.shiftColorStr}} onClick={this.handleYesterdayClicked}>
                                                        <i className="fas fa-chevron-left" />
                                                    </button>
                                                    <ApsDatePicker2 isSelectionDisabled={this.state.isCalendarDisabled} onChange={this.handleDateChanged} value={this.state.selectedDate} departmentId={this.state.departmentId} todayColor={this.state.shiftColorStr}/>
                                                    <button disabled={this.state.isCalendarDisabled} className={`btn btn-white daily-operational-btn`} style={{color: this.state.shiftColorStr}} onClick={this.handleTomorrowClicked}>
                                                        <i className="fas fa-chevron-right" />
                                                    </button>
                                                    </div>
                                                </div>                 
                                            </div>
                                            </div>
                                        </>
                                        :
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col d-flex justify-content-center">
                                                    <div className="d-flex">     
                                                        <button disabled={this.state.isCalendarDisabled} className={`btn btn-white mr-4 daily-operational-btn`} style={{color: this.state.shiftColorStr}} onClick={this.handleTodayClicked}>Today</button>
                                                        <div className="d-flex">
                                                            <button disabled={this.state.isCalendarDisabled} className={`btn btn-white daily-operational-btn`} style={{color: this.state.shiftColorStr}} onClick={this.handleYesterdayClicked}>
                                                                <i className="fas fa-chevron-left" />
                                                            </button>
                                                            <ApsDatePicker2 isSelectionDisabled={this.state.isCalendarDisabled} onChange={this.handleDateChanged} value={this.state.selectedDate} departmentId={this.state.departmentId} todayColor={this.state.shiftColorStr} />
                                                            <button disabled={this.state.isCalendarDisabled} className={`btn btn-white daily-operational-btn`} style={{color: this.state.shiftColorStr}} onClick={this.handleTomorrowClicked}>
                                                                <i className="fas fa-chevron-right" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {this.state.loading && <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h4>
                                            Loading...
                                        </h4>
                                    </div>
                                </div>}
                            {this.state.form?.questionGroups && <div>
                                <div className="row">
                                    <div className="col-xl-3">
                                        {questionsMap.get(GlendaleModuleOrder.OperationsBC)}
                                    </div>
                                    <div className="col-xl-3">
                                        {questionsMap.get(GlendaleModuleOrder.StaffCaptain)}
                                    </div>
                                    <div className="col-xl-3">
                                        {questionsMap.get(GlendaleModuleOrder.BadgeBand)}
                                    </div>
                                    <div className="col-xl-3">
                                        {questionsMap.get(GlendaleModuleOrder.Flags)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        {questionsMap.get(GlendaleModuleOrder.ShiftActivity)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-8 d-flex flex-column">
                                        <div className="row flex-grow-1">
                                            <div className="col-xl-6 col-lg-12 d-flex flex-column">
                                                {questionsMap.get(GlendaleModuleOrder.WildlandSpecialtyApparatus)}
                                            </div>
                                            <div className="col-xl-6 col-lg-12 d-flex flex-column">
                                                {questionsMap.get(GlendaleModuleOrder.StrikeTeams)}
                                                {questionsMap.get(GlendaleModuleOrder.OnCallList)}
                                            </div>
                                        </div>
                                        <div className="row flex-grow-1">
                                            <div className="col-xl-12 d-flex flex-column">{questionsMap.get(GlendaleModuleOrder.UsarResponse)}</div>
                                        </div>
                                        <div className="row flex-grow-1">
                                            <div className="col-xl-12 d-flex flex-column">{questionsMap.get(GlendaleModuleOrder.HazmatResponse)}</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 d-flex flex-column">
                                        {questionsMap.get(GlendaleModuleOrder.ReserveApparatusStatus)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-4">
                                        {questionsMap.get(GlendaleModuleOrder.PublicEducation)}
                                    </div>
                                    <div className="col-xl-4">
                                        {questionsMap.get(GlendaleModuleOrder.CommunityRoom)}
                                    </div>
                                    <div className="col-xl-4">
                                        {questionsMap.get(GlendaleModuleOrder.SmallConferenceRoom)}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                {this.state.showSaveConfirmation ? (
                    <SweetAlert
                        custom
                        showCancel={false}
                        showCloseButton={false}
                        title="Form saved!"
                        onConfirm={this.handleSaveConfirmationClosed}
                        onCancel={this.handleSaveConfirmationClosed}
                    >
                    </SweetAlert>
                ) : null}
                {this.state.showAlert ? (
                    <SweetAlert
                        custom
                        showCancel={false}
                        showCloseButton={false}
                        showConfirm={false}
                        customIcon="https://alabama.aoa.org/events-register/images/Loading.gif"
                        title="Generating PDF Report..."
                        onConfirm={this.setShowAlertMessage}
                        onCancel={this.setShowAlertMessage}
                    >
                    </SweetAlert>
                ) : null}
                {this.state.showReportError ? (
                    <SweetAlert
                        custom
                        showCancel={false}
                        showCloseButton={false}
                        title="Error on generating report"
                        onConfirm={this.handleReportErrorMessageClosed}
                        onCancel={this.handleReportErrorMessageClosed}
                    >
                    </SweetAlert>
                ) : null}
            </>
        )
    }
}
