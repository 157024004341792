import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import { FgUseForm, IBasicDialogProps } from "../../../Common/FormGroups";
import FormModal from "../../../Common/FormModal";

function ExcessiveHoursDialog(
  props: IBasicDialogProps<{
    prev: ApsModels.IDrillReportVerificationDto;
    new: ApsModels.IDrillReportInputDto;
    participants?: {
      [key: number]: boolean;
    };
  }>
) {
  const { handleSubmit } = FgUseForm({});
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const onChange = () => {
    props.onChange &&
      props.data?.prev &&
      props.data?.new &&
      props.onChange({
        prev: { ...props.data?.prev },
        new: { ...props.data?.new },
        participants: { ...participantsToKeep },
      });
  };
  const submit = async (form: any) => {
    close(true);
  };

  const [participantsToKeep, setParticipantsToKeep] = useState(
    {} as {
      [key: number]: boolean;
    }
  );

  useEffect(() => {
    onChange();
  }, [participantsToKeep]);

  const totalHours = (item: ApsModels.IDrillReportExcessiveListDto) => {
    let cnt = 0;
    item?.drillReportVerificationReportDtos?.forEach((d) => {
      if (d.keep) {
        let dur = moment(d.endTime).diff(d.startTime);
        dur = dur / 60 / 60 / 1000;
        cnt = cnt + dur;
      }
    });
    if (participantsToKeep[item.participant.id]) {
      let dur = moment(props?.data?.new?.endTimeString).diff(
        props?.data?.new?.startTimeString
      );
      dur = dur / 60 / 60 / 1000;
      cnt = cnt + dur;
    }
    return cnt;
  };

  const allowConfirm = () => {
    let allow = true;
    props?.data?.prev?.drillReportExcessiveListDtos?.forEach((d) => {
      if (allow) {
        const dur = totalHours(d);
        if (dur > 12 || dur <= 0) {
          allow = false;
        }
      }
    });
    return allow;
  };

  return (
    <div>
      <FormModal
        title="Excessive Hours"
        isOpen={true}
        close={() => close()}
        submit={handleSubmit(submit)}
        size="lg"
        submitButtonLabel="Confirm"
        closeButtonLabel="Cancel"
        titleIcon="exclamation-triangle"
        disableSubmit={!allowConfirm()}
      >
        <div>
          Submitting this drill will result in more than 12 hours total drill
          time in a single day of the following participants. Please select
          which drill(s) to keep for each participant identified below, or click
          "Cancel" on this dialog to make changes to the newly-proposed drill
          before submitting again.
        </div>

        <div className="table-responsive-sm pt-4">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Participant</th>
                <th>Drills</th>
              </tr>
            </thead>
            <tbody>
              {props?.data?.prev?.drillReportExcessiveListDtos?.map(
                (excess, i) => (
                  <tr key={i}>
                    <td>
                      {excess.participant.firstName}{" "}
                      {excess.participant.lastName}
                    </td>
                    <td>
                      {excess.drillReportVerificationReportDtos.map(
                        (item, ii) => (
                          <div className="flex-with-checkbox" key={ii}>
                            <div>
                              <input
                                type="checkbox"
                                id={`excessChkBox${i}-${ii}`}
                                name={`excessChkBox${i}-${ii}`}
                                className="mr-2"
                                onChange={(e) => {
                                  item.keep = e.target.checked;
                                  onChange();
                                }}
                              ></input>
                            </div>
                            <label htmlFor={`excessChkBox${i}-${ii}`}>
                              <span className="pr-2">
                                {moment(moment(item.startTime).toDate()).format(
                                  "MM/DD/YYYY HH:mm"
                                )}
                              </span>
                              <span className="pr-2">to</span>
                              <span className="pr-2">
                                {moment(moment(item.endTime).toDate()).format(
                                  "MM/DD/YYYY HH:mm"
                                )}
                              </span>
                            </label>
                          </div>
                        )
                      )}

                      <div className="flex-with-checkbox">
                        <div>
                          <input
                            type="checkbox"
                            id={`excessChkBox${i}`}
                            name={`excessChkBox${i}`}
                            className="mr-2"
                            onChange={(e) => {
                              const data = {
                                ...participantsToKeep,
                              };
                              data[excess.participant.id] = e.target.checked;
                              setParticipantsToKeep(data);
                            }}
                          ></input>
                        </div>

                        <label htmlFor={`excessChkBox${i}`}>
                          <span className="pr-2">
                            {moment(
                              moment(props.data?.new?.startTimeString).toDate()
                            ).format("MM/DD/YYYY HH:mm")}
                          </span>
                          <span className="pr-2">to</span>
                          <span className="pr-1">
                            {moment(
                              moment(props.data?.new?.endTimeString).toDate()
                            ).format("MM/DD/YYYY HH:mm")}
                          </span>
                          <i>(Proposed Drill)</i>
                        </label>
                      </div>

                      <div className="flex-with-checkbox txt-primary">
                        <div>
                          <input
                            type="checkbox"
                            className="mr-2"
                            style={{
                              opacity: 0,
                              pointerEvents: "none",
                            }}
                          ></input>
                        </div>

                        <label
                          className={`${
                            totalHours(excess) > 12 ? "txt-danger" : ""
                          }`}
                        >
                          Total Hours: {totalHours(excess)}
                        </label>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </FormModal>
    </div>
  );
}

export default ExcessiveHoursDialog;
