import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useDepartmentId } from "../../../stores/SystemStore";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRS/NFIRSHelper";
import ApsServices from "../../../services";
import ApsModels from "../../../models";
import commonService from "../../../services/CommonService";
import {
  EncodeSafeCodeForRule,
  RequirementByRule,
} from "../../Credentials/ViewCredential";
import CommonSpinner from "../../Common/CommonSpinner";

function ViewCredentialTemplate(props: RouteComponentProps<any>) {
  const departmentId = useDepartmentId();
  const templateId = props.match?.params?.id;
  const template = useNfirsGetData(
    async () =>
      ApsServices.http.credCredential.getEmptyTemplateCredential(templateId),
    "Credential Template"
  );

  useEffect(() => {
    if (departmentId.current > 0) {
      if (templateId) {
        if (departmentId.changed && departmentId.previous > 0) {
          props.history.push("/templates");
          return; //exit
        }
        //setFiles([]);
        template.getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  // const [saving, setSaving] = useState(false);
  // const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  // const [files, setFiles] = useState<File[]>([]);
  // const [claims] = useState(commonService.friendlyClaims);

  const navigateBack = () => {
    if (props.history.action != "POP") {
      props.history.goBack();
    } else {
      props.history.push("/templates");
    }
  };

  const hasNoRequirement = () => {
    return false;
  };

  const getRules = (
    req: ApsModels.ICredEmptyCredentialRequirementGroupOutputDto
  ) => {
    if (commonService.isNullOrWhitespace(req.rules)) {
      return `(${req.credEmptyCredentialRequirementOutputDtos
        ?.map((x) => {
          return EncodeSafeCodeForRule(x.code);
        })
        .join("")})`;
    }
    return req.rules;
  };

  return (
    <>
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="h4 mb-0 font-size-18 text-uppercase">
                View Credential Template
              </span>
            </div>
            <div
              className={`${
                template.status === NfirsFetchStatus.Complete
                  ? ""
                  : "display-none"
              }`}
            >
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) => {
                  navigateBack();
                }}
              >
                Cancel and Close
              </button>

              <button
                className="btn btn-primary"
                type="button"
                disabled={hasNoRequirement()} //saving ||
                onClick={(e) => {
                  templateId &&
                    props.history.push(`/templates/credential/${templateId}`);
                }}
              >
                <span className="px-2">Edit</span>
              </button>
            </div>
          </div>
        </div>
        {template.status === NfirsFetchStatus.InProgress && (
          <CommonSpinner></CommonSpinner>
        )}
        <div
          className={`row justify-content-md-center h-full flex-1 ${
            template.status === NfirsFetchStatus.Complete ? "" : "display-none"
          }`}
        >
          {/* {saving && <CommonSpinner overlay={true}></CommonSpinner>} */}
          <div className={`col-12 col-sm-12 col-lg-8 flex flex-col`}>
            <div className="card">
              <div className="card-body flex flex-col p-0">
                <div className="earn-renew-credential-header p-4 flex">
                  <div className="flex-1">
                    <h4 className="m-0">{template.data?.name}</h4>
                    <div>{template.data?.code}</div>
                    <div>[Department]</div>
                  </div>
                  <div className="text-right">
                    <div>[Employee Name]</div>
                    <div>[EID]</div>
                  </div>
                </div>
                <div className="p-4">
                  <div className="earn-renew-credential-progress">
                    <strong className="text-uppercase">
                      Credential Progress Summary
                    </strong>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <strong>Category</strong>
                        <div>{template.data?.credCredentialCategoryOutputDto.name}</div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <strong>Hours Required</strong>
                        <div>
                          {commonService.toNumberWithComma(
                            template.data?.totalHoursNeeded
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <strong>Hours Completed</strong>
                        <div>0</div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                        <strong>Renewal Date</strong>
                        <div>MM/DD/YYYY</div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-4">
                    <strong>Instructions: </strong>
                    {template?.data?.instructions || ""}
                  </div>

                  <div className="pt-4">
                    <table className="table earn-renew-credential-table">
                      <tbody>
                        {!template.data
                          ?.credEmptyCredentialRequirementCategoryOutputDtos
                          ?.length && (
                          <tr>
                            <td>No Credential Requirements Found</td>
                          </tr>
                        )}
                        {template.data?.credEmptyCredentialRequirementCategoryOutputDtos?.map(
                          (reqCat) => (
                            <React.Fragment key={reqCat.id}>
                              <tr
                                className={`${
                                  hasNoRequirement() ? "text-danger" : ""
                                }`}
                              >
                                <td
                                  colSpan={5}
                                  className="requirement-row-header"
                                >
                                  <strong>{reqCat.name}</strong>
                                </td>
                              </tr>
                              {reqCat.credEmptyCredentialRequirementGroupOutputDtos
                                ?.filter(
                                  (x) =>
                                    x.credEmptyCredentialRequirementOutputDtos
                                      .length > 0
                                )
                                ?.map((req) => (
                                  <React.Fragment key={req.id}>
                                    <tr key={req.id}>
                                      <td
                                        className="requirement-taken-column"
                                        style={{
                                          width: "20px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-square"
                                          style={{
                                            fontWeight: "normal",
                                          }}
                                        ></i>
                                      </td>
                                      <td
                                        style={{
                                          paddingRight: 0,
                                          paddingLeft: 0,
                                        }}
                                      >
                                        <span>
                                          <RequirementByRule
                                            rules={getRules(req)}
                                            items={
                                              req.credEmptyCredentialRequirementOutputDtos?.map(
                                                (ri) => {
                                                  return {
                                                    ...ri,
                                                    code: EncodeSafeCodeForRule(
                                                      ri.code
                                                    ),
                                                  } as any; //TODO
                                                }
                                              ) || []
                                            }
                                            credId={template?.data?.id}
                                          ></RequirementByRule>
                                        </span>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>

                  {!hasNoRequirement() && (
                    <div className="pt-2 row">
                      <div className="mb-3 col-12 col-sm-12 col-lg-6">
                        {/* <label>Attach Certificate of Completion</label>
                        <div>
                          <FileDragNDrop
                            label="Add File"
                            disabled={saving}
                            onChange={(fileList) => {
                              if (fileList.length > 0) {
                                const list = [...files];
                                let cnt = 0;
                                while (cnt < fileList.length) {
                                  const file = fileList[cnt];
                                  list.push(file);
                                  cnt++;
                                }
                                setFiles(list);
                              }
                            }}
                          />
                        </div> */}
                        {/* <div>
                          {template.data??.map((cf) => (
                            <span
                              key={cf.id}
                              className="alert alert-secondary m-0 mt-2 flex flex-center"
                            >
                              <i
                                title="Download File"
                                className="mr-2 fa fa-download text-primary pointer"
                                onClick={() => {
                                  if (template.data?.id) {
                                    ApsServices.http.credCredential
                                      .downloadTakenCredentialCertificate(
                                        template.data.id,
                                        cf.id
                                      )
                                      .then((data) => {
                                        const file = commonService.b64toBlob(
                                          data.fileContents,
                                          "text/plain"
                                        );
                                        FileSaver.saveAs(
                                          file,
                                          data.fileDownloadName
                                        );
                                      })
                                      .catch((error) => {
                                        toastStore.showError(
                                          "Failed to Download File",
                                          error
                                        );
                                      });
                                  }
                                }}
                              ></i>
                              <span className="flex-1 overflow-hidden">
                                {filesToDelete.includes(cf.id) ? (
                                  <s className="text-danger">
                                    <i>{cf.fileName}</i>
                                  </s>
                                ) : (
                                  <span>{cf.fileName}</span>
                                )}
                              </span>
                              {filesToDelete.includes(cf.id) ? (
                                <i
                                  title="Undo"
                                  className="ml-2 fa fa-undo text-primary pointer"
                                  onClick={() => {
                                    setFilesToDelete(
                                      filesToDelete.filter((f) => f !== cf.id)
                                    );
                                  }}
                                ></i>
                              ) : (
                                <i
                                  title="Delete File"
                                  className="ml-2 fa fa-trash text-danger pointer"
                                  onClick={() => {
                                    setFilesToDelete([...filesToDelete, cf.id]);
                                  }}
                                ></i>
                              )}
                            </span>
                          ))}
                          {files?.map((f, i) => (
                            <span
                              key={i}
                              className="alert alert-info p-2 px-3 m-0 mt-2 flex flex-center"
                            >
                              <span className="flex-1 overflow-hidden">
                                {f.name}
                              </span>
                              {!saving && (
                                <i
                                  title="Remove File"
                                  className="mr-1 fa fa-times pointer"
                                  onClick={() => {
                                    const list = [...files];
                                    list.splice(i, 1);
                                    setFiles(list);
                                  }}
                                ></i>
                              )}
                            </span>
                          ))}
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewCredentialTemplate;
