import ApsModels from "../../models";
import fetcher from "./Fetcher";

class LogDrillSubject {
  get = async (id: number) => {
    const url = `/api/LogDrillSubject/${id}`;
    return fetcher.get<ApsModels.ILogDrillSubjectOutputDto[]>(url);
  };

  getAll = async (deptId: number) => {
    const url = `/api/LogDrillSubject/GetAll/${deptId}`;
    return fetcher.get<ApsModels.ILogDrillSubjectOutputDto[]>(url);
  };
}

const logDrillSubject = new LogDrillSubject();
export default logDrillSubject;
