import ApsModels from "../../models";
import { IUserCredSettingsInputDto, IUserCredSettingsOutputDto } from "../../models/_all";
import fetcher from "./Fetcher";

class UserCredSettingService {
  save = async (userId: number, data: IUserCredSettingsInputDto) => {
    const url = `/Api/User/${userId}/CredSettings`;
    return await fetcher.post(url, data) as IUserCredSettingsOutputDto;
  };

  get = async (userId: number) => {
    const response = await fetch(`/Api/User/${userId}/CredSettings`);
    return await response.json() as IUserCredSettingsOutputDto;
  };
}

const company = new UserCredSettingService();
export default company;