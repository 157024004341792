import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../../AppContext";
import CommonSpinner from "../../../../aps2/components/Common/CommonSpinner";
import {
  AnswerDto,
  AnswerType,
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { OptionGroupDto } from "../../../../data/OptionGroup";
import { UserDto } from "../../../../data/User";
import { OptionGroupService } from "../../../../services/OptionGroupService";
import { OptionService } from "../../../../services/OptionService";
import { PreviousDayService } from "../../../../services/PreviousDayService";
import { PullFromPreviousDayButton } from "../../CustomInput/PullFromPreviousDayButton";
import GenericStrikeTeamAnswer from "./GenericStrikeTeamAnswer";

interface IGenericStrikeTeamsProps {
  questionGroup?: QuestionGroupDto;
  date: Date;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  optionGroups: OptionGroupDto[];
  departmentId: number;
  moduleOrder: number;
  hideNotice?: boolean;
  instructions?: string;
}

interface IGenericStrikeTeamsState {
  calendarDate: Date | null;
  wsaOptions: OptionDto[];
  stOptions: OptionDto[];
  engines: OptionDto[];
  selectedOptionId: number | null;
  isTablet: boolean;
  stenGroupUsers: UserDto[];
  stenTGroupUsers: UserDto[];
  ready: boolean;
}

const AvailableStatus: string = "Available";
function GenericStrikeTeams(props: IGenericStrikeTeamsProps) {
  const context = useContext(AppContext);
  const [state, setState] = useState<IGenericStrikeTeamsState>({
    calendarDate: null,
    wsaOptions: new Array<OptionDto>(),
    stOptions: new Array<OptionDto>(),
    engines: new Array<OptionDto>(),
    stenGroupUsers: new Array<UserDto>(),
    stenTGroupUsers: new Array<UserDto>(),
    selectedOptionId: null,
    isTablet: false,
    ready: false,
  });

  const initialize = async () => {
    const optionGroups: OptionGroupDto[] = !props.optionGroups.length
      ? await OptionGroupService.getAllOptionGroupsByDepartment(
        props.departmentId
      )
      : props.optionGroups;
    const strike = await getOptions(
      "Strike Teams: Strike Teams List",
      optionGroups
    );
    const stOptions = await getOptions(
      "Strike Teams: ST Options",
      optionGroups
    );
    const engines = await getOptions("Strike Teams: Engines", optionGroups);

    // NOTE: NOT IN USE AS OF NOW
    //const allGroup = await GroupService.getAllGroups();
    // const stenId = allGroup.find((x) => x.name === "STEN");
    // const stenTId = allGroup.find((x) => x.name === "STEN-T");
    // const stenGroupUsers = await UserService.getUsersInGroupByDepartmentId(
    //   stenId!.groupId,
    //   props.departmentId
    // );
    // const stenTGroupUsers = await UserService.getUsersInGroupByDepartmentId(
    //   stenTId!.groupId,
    //   props.departmentId
    // );

    setState((prev) => {
      addFirstAnswer();
      return {
        ...prev,
        calendarDate: props.date,
        stOptions: stOptions,
        engines: engines,
        stenGroupUsers: [], //stenGroupUsers,
        stenTGroupUsers: [], //stenTGroupUsers,
        ready: true,
        wsaOptions: strike,
      };
    });

    window.addEventListener("resize", handleResize);
  };

  useEffect(() => {
    initialize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const tabletMaxWidth = 1550;
    setState((prev) => {
      return {
        ...prev,
        isTablet:
          window.screen.width <= tabletMaxWidth ||
          window.innerWidth <= tabletMaxWidth,
      };
    });
  };

  const updateQuestionGroup = (newQuestions: QuestionDto[]): void => {
    const questionGroup: QuestionGroupDto = cloneDeep(props.questionGroup)!;

    for (let i = 0; i < questionGroup.questions.length; i++) {
      let newQuestion: QuestionDto | undefined = newQuestions.find(
        (q) => q.order === questionGroup.questions[i].order
      );
      if (newQuestion) {
        questionGroup.questions[i] = newQuestion;
      }
    }

    props.setData(questionGroup, props.moduleOrder);
  };

  const addFirstAnswer = () => {
    const questionOrder: number = 0;
    const question: QuestionDto = cloneDeep(
      props.questionGroup!.questions.find((q) => q.order == questionOrder)!
    );
    const answer: AnswerDto | undefined = question.answers.find(
      (a) => a.order === 0
    );

    if (!answer) {
      question.answers.push(createNewAnswer(question, 0));
      updateQuestionGroup([question]);
    }
  };

  const createNewAnswer = (question: QuestionDto, answerOrder: number) => {
    const selectedOption: OptionDto = state.stOptions.find(
      (o) => o.name === AvailableStatus
    )!;

    return {
      answerId: 0,
      answerType: AnswerType.StrikeTeam,
      date: props.date,
      dateCreated: props.date,
      dateUpdated: props.date,
      order: answerOrder,
      questionId: question.questionId || 0,
      strikeTeamOptionId: selectedOption?.optionId,
      strikeTeamReferences: [],
    };
  };

  const getAllAnswers = (): AnswerDto[] => {
    const question: QuestionDto | undefined = props.questionGroup?.questions[0];
    return question ? question.answers : [];
  };

  const getOptions = async (
    name: string,
    optionGroups: OptionGroupDto[]
  ): Promise<OptionDto[]> => {
    const optionsGroup = optionGroups.find((g) => g.name === name);
    return optionsGroup
      ? await OptionService.getGroupOptions(optionsGroup.optionGroupId)
      : [];
  };

  const deleteAnswer = (
    question: QuestionDto,
    answerToDelete: AnswerDto
  ): void => {
    const answerIndex: number = question.answers.indexOf(answerToDelete);
    question.answers.splice(answerIndex, 1);

    for (let i = 0; i < question.answers.length; i++) {
      question.answers[i].order = i;
    }
  };

  const updateAnswer = (question: QuestionDto, answer: AnswerDto) => {
    for (let i = 0; i < question.answers.length; i++) {
      if (question.answers[i].order === answer.order) {
        question.answers[i] = answer;
      }
    }
  };

  const handleNewAnswerAdded = () => {
    const questionOrder: number = 0;
    const question: QuestionDto = cloneDeep(
      props.questionGroup!.questions.find((q) => q.order == questionOrder)!
    );
    const newAnswerOrder: number = question.answers.length;

    question.answers.push(createNewAnswer(question, newAnswerOrder));
    updateQuestionGroup([question]);
  };

  const handleAnswerChanged = (answer: AnswerDto): void => {
    const question: QuestionDto | undefined = props.questionGroup?.questions[0];

    if (!question) return;

    let existingAnswer: AnswerDto | undefined = question.answers.find(
      (a) => a.order === answer.order
    );

    if (existingAnswer) {
      updateAnswer(question, answer);
    } else {
      question.answers.push(answer);
    }

    updateQuestionGroup([question]);
  };

  const handleAnswerDeleted = (answerOrder: number): void => {
    const questionGroup: QuestionGroupDto = cloneDeep(props.questionGroup!);
    const question: QuestionDto = questionGroup.questions[0]!;
    const answerToDelete: AnswerDto | undefined = question.answers.find(
      (a) => a.order === answerOrder
    );

    if (answerToDelete) {
      deleteAnswer(question, answerToDelete);
      props.setData(questionGroup, props.moduleOrder);
    }
  };

  const handlePullFromPreviousDay = async (
    newQuestionGroupValue: QuestionGroupDto
  ) => {
    props.setData(newQuestionGroupValue, props.moduleOrder);
  };

  const answers = useMemo(() => {
    return getAllAnswers();
  }, [props.questionGroup]);

  const isPullFromPreviousDayAvailable = useMemo(() => {
    return PreviousDayService.isFeatureAvailable(props.date);
  }, [props.date]);

  const questionGroupNameColumnClass = useMemo(() => {
    return isPullFromPreviousDayAvailable
      ? "col-xl-9 col-8"
      : "col-xl-10 col-9";
  }, [isPullFromPreviousDayAvailable]);

  const controlsColumnClass = useMemo(() => {
    return isPullFromPreviousDayAvailable ? "col-xl-3 col-4" : "col-xl-2 col-3";
  }, [isPullFromPreviousDayAvailable]);

  return (
    <div className="card box-card flex-grow-1">
      {!state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
      <div className="card-body">
        <div className="row">
          <h4 className={`mb-0 font-size-16 ${questionGroupNameColumnClass}`}>
            {props.questionGroup?.name || ""}
          </h4>
          <div className={`row ml-1 pl-4 ${controlsColumnClass}`}>
            {context.isEditableDailyOperational && (
              <i
                className="fas fa-plus-circle mr-4 add-answer-btn"
                onClick={handleNewAnswerAdded}
              />
            )}
            {!context.isEditableDailyOperational && (
              <i className="fas fa-plus-circle mr-3" />
            )}
            {isPullFromPreviousDayAvailable && (
              <PullFromPreviousDayButton
                questionGroupId={props.questionGroup?.questionGroupId!}
                calendarDate={props.date}
                setData={handlePullFromPreviousDay}
              />
            )}
          </div>
        </div>
        {!props.hideNotice && (
          <div className="mt-3">{props.instructions || ""}</div>
        )}
        {!state.isTablet && (
          <div className="row">
            <div className="col-xl-6 section-column section-column-1">
              {answers
                .filter((a) => a.order % 2 === 0)
                .map((a) => {
                  return (
                    <GenericStrikeTeamAnswer
                      defaultItemsCount={3}
                      key={a.order}
                      answer={a}
                      stOptions={state.stOptions}
                      wsaOptions={state.wsaOptions}
                      engines={state.engines}
                      stenGroupUsers={state.stenGroupUsers}
                      stenTGroupUsers={state.stenTGroupUsers}
                      onChange={handleAnswerChanged}
                      onDelete={handleAnswerDeleted}
                      strikeTeamLabel={
                        props.questionGroup?.questions?.find(
                          (q) => q.order === 0
                        )?.questionText
                      }
                    />
                  );
                })}
            </div>
            <div className="col-xl-6 section-column section-column-2">
              {answers
                .filter((a) => a.order % 2 === 1)
                .map((a) => {
                  return (
                    <GenericStrikeTeamAnswer
                      defaultItemsCount={3}
                      key={a.order}
                      answer={a}
                      stOptions={state.stOptions}
                      wsaOptions={state.wsaOptions}
                      engines={state.engines}
                      stenGroupUsers={state.stenGroupUsers}
                      stenTGroupUsers={state.stenTGroupUsers}
                      onChange={handleAnswerChanged}
                      onDelete={handleAnswerDeleted}
                      strikeTeamLabel={
                        props.questionGroup?.questions?.find(
                          (q) => q.order === 0
                        )?.questionText
                      }
                    />
                  );
                })}
            </div>
          </div>
        )}
        {state.isTablet && (
          <div className="row">
            <div className="col-xl-12">
              {answers.map((a) => {
                return (
                  <GenericStrikeTeamAnswer
                    defaultItemsCount={3}
                    key={a.order}
                    answer={a}
                    stOptions={state.stOptions}
                    wsaOptions={state.wsaOptions}
                    engines={state.engines}
                    stenGroupUsers={state.stenGroupUsers}
                    stenTGroupUsers={state.stenTGroupUsers}
                    onChange={handleAnswerChanged}
                    onDelete={handleAnswerDeleted}
                    strikeTeamLabel={
                      props.questionGroup?.questions?.find((q) => q.order === 0)
                        ?.questionText
                    }
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="card-body d-none childsection3">
        <div className="row">
          <h4 className={`mb-0 font-size-22 col-xl-12`}>
            {props.questionGroup?.name || ""}
            <hr  className="mt-4 mb-4" />
          </h4>
        </div>
          <div className="row font-size-20 mb-4">
            {answers
              .map((a) => {
                return (
                  <div key={a.order} className="d-flex  mx-3">

                    <div className="mt-1">

                      <span><b>Status:</b> {state.stOptions.find(x => x.optionId == a.strikeTeamOptionId)?.name}</span>
                      <br />
                      {(a.strikeTeamReferences?.length ?? 0) > 0 ? 
                        <span><b>Strike Team :</b> {state.wsaOptions.find(x => x.optionId === a.strikeTeamReferences?.[0].optionId)?.name
                          ?? a.strikeTeamReferences?.[0].optionName ?? ""}</span>
                          : ""
                      }
                      <br />
                      {a.strikeTeamReferences?.map((y, i) => {
                        return i != 0 && (
                          <span>{state.engines.find(x => x.optionId === y.optionId)?.name ?? y.optionName} <br /> </span>

                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </div>
      </div>
    </div>
  );
}

export default GenericStrikeTeams;
