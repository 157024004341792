import React, { Component } from "react";
import moment from "moment";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { AuthenticationService } from "../../services/AuthenticationService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../assets/images/aps.jpg';

type Props = RouteComponentProps & {}

type State = {
    isValid: boolean,
    username: string,
    token: string,
    newPassword: string,
    confirmNewPassword: string,
    isSubmitting: boolean,
    isSubmitted: boolean,
    isSuccessful: boolean,
    validationErrors: ValidationErrors,
}

type ValidationErrors = {
    newPassword?: string,
    confirmNewPassword?: string,
}

export class ResetPassword extends Component<Props, State> {
    static displayName = ResetPassword.name;

    state: State = {
        isValid: false,
        username: "",
        token: "",
        newPassword: "",
        confirmNewPassword: "",
        isSubmitting: false,
        isSubmitted: false,
        isSuccessful: false,
        validationErrors: {},
    }

    componentDidMount = async () => {
        const params = new URLSearchParams(this.props.location.search);
        const username = params.get("username");
        const token = params.get("token");

        if (username && token) {
            this.setState({
                isValid: true,
                username: username,
                token: token,
            });
        }
    }

    handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newPassword: e.target.value }, () => this.validateForm());
    }

    handleConfirmNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ confirmNewPassword: e.target.value }, () => this.validateForm());
    }

    handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }

        this.setState({ isSubmitting: true });
        const result = await AuthenticationService.resetPassword(this.state.username, this.state.token, this.state.newPassword);
        this.setState({ isSubmitting: false });
        this.setState({ isSubmitted: true });

        if (result) {
            this.setState({ isSuccessful: true });
        }
    }

    validateForm = () => {
        var validationErrors: ValidationErrors = {};

        if (this.state.newPassword.length < 8) {
            validationErrors.newPassword = 'Your password must be at least 8 characters';
        }
        if (this.state.confirmNewPassword !== '' &&
            this.state.newPassword !== this.state.confirmNewPassword) {
            validationErrors.confirmNewPassword = 'The passwords do not match';
        }

        this.setState({ validationErrors: validationErrors });

        return Object.entries(validationErrors).length === 0;
    }

    render() {
        return (
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-soft-primary">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Reset Password</h5>
                                                <p className="text-p">Update your password with a minimum of 8 characters.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-sidebar-dark">
                                                <img src={Logo} alt="" className="rounded-circle w-16" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="p-2">

                                        {this.state.isValid  && !this.state.isSuccessful && (
                                            <form onSubmit={this.handleSubmit} className="form-horizontal">

                                                <div className={`form-group text-secondary ${this.state.validationErrors.newPassword ? 'text-danger' : ''}`}>
                                                    <label htmlFor="new-password">New Password</label>
                                                    <input
                                                        id="new-password"
                                                        type="password"
                                                        className={`form-control ${this.state.validationErrors.newPassword ? 'is-invalid' : ''}`}
                                                        placeholder="Enter new password"
                                                        onChange={this.handleNewPasswordChange}
                                                        required
                                                    />
                                                    {this.state.validationErrors.newPassword && (
                                                        <div className="invalid-feedback">
                                                            {this.state.validationErrors.newPassword}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className={`form-group text-secondary ${this.state.validationErrors.confirmNewPassword ? 'text-danger' : ''}`}>
                                                    <label htmlFor="confirm-new-password">Confirm New Password</label>
                                                    <input
                                                        id="confirm-new-password"
                                                        type="password"
                                                        className={`form-control ${this.state.validationErrors.confirmNewPassword ? 'is-invalid' : ''}`}
                                                        placeholder="Enter new password"
                                                        onChange={this.handleConfirmNewPasswordChange}
                                                        required
                                                    />
                                                    {this.state.validationErrors.confirmNewPassword && (
                                                        <div className="invalid-feedback">
                                                            {this.state.validationErrors.confirmNewPassword}
                                                        </div>
                                                    )}
                                                </div>

                                                {this.state.isSubmitted && !this.state.isSuccessful && (
                                                    <Alert color="danger">
                                                        There was a problem resetting your password.
                                                    </Alert>
                                                )}

                                                <div className="mt-3">
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                        disabled={this.state.isSubmitting}
                                                    >
                                                        {this.state.isSubmitting && (
                                                            <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" />
                                                        )}
                                                        Reset
                                                    </button>
                                                </div>
                                            </form>
                                        )}

                                        {!this.state.isValid && (
                                            <Alert color="danger">
                                                Invalid request.
                                            </Alert>
                                        )}

                                        {this.state.isSubmitted && this.state.isSuccessful && (
                                            <Alert color="success">
                                                Your password has been reset.<br />
                                                Please <Link to="/account/login" className="font-medium text-primary">login</Link> to access the portal.
                                            </Alert>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center text-secondary">
                                <p>
                                    &copy; {moment().year().toString()} APS.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}