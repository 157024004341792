import React from 'react';
import { QuestionDto, QuestionGroupDto } from '../../../../data/DailyOperational';
import { IsolatedTableRowList } from '../../CustomInput/IsolatedTableRowList';
import { OptionGroupDto } from '../../../../data/OptionGroup';
import { PullFromPreviousDayButton } from "../../CustomInput/PullFromPreviousDayButton";
import { PreviousDayService } from "../../../../services/PreviousDayService";

type Props = {
    questionGroup?: QuestionGroupDto,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    date: Date,
    optionGroups : OptionGroupDto[],
    moduleOrder: number,
    needsToAutopopulate: boolean;  
    columnNumber: number
}

type State = {
    optionGroups: OptionGroupDto[],
    reload: boolean
}
export class WestCovinaReserveAppatatus extends React.Component<Props, State> {
    state: State = {
        optionGroups: [],
        reload: false
    }
    componentDidMount = async () => {
        const optionGroups: OptionGroupDto[] = this.props.optionGroups;
        this.setState({
            optionGroups: optionGroups
        });
    }

    componentDidUpdate = async (prevProps: Props) => {
        if (prevProps !== this.props) {
            const optionGroups: OptionGroupDto[] = this.props.optionGroups;
            this.setState({
                optionGroups: optionGroups
            });
        }
    }

    private updateQuestionGroup = (newQuestionValue: QuestionDto) => {
        const questionIndex = this.props.questionGroup?.questions.findIndex(q => q.questionId == newQuestionValue.questionId);
        if (questionIndex !== undefined) {
            this.props.questionGroup!.questions[questionIndex] = newQuestionValue;
            this.props.setData(this.props.questionGroup, this.props.moduleOrder);
        }

    }

    getName = (groupId?: number) => {
        const element = this.state.optionGroups.find(g => g.optionGroupId === groupId)
        return element?.name
    }

    handlePullFromPreviousDay = async (newDayQuestionGroup: QuestionGroupDto) => {
        this.setState({
            reload: !this.state.reload
        });
        this.props.setData(newDayQuestionGroup, this.props.moduleOrder);
    }

    chunk = (arr: QuestionDto[], size: number) => {
        const result = [];
        for (var i = 0; i < arr.length; i+=size) {
            result.push(arr.slice(i, i+size));
        }
        return result;
    }

    render() {
        const questions = this.props.questionGroup?.questions;
        return (
            <div className="grid-item apparatus-status card box-card flex-grow-1">
                <div className="card-body">
                    <div className="row">
                        <h4 className="mb-0 font-size-16 col-xl-11 col-10">{this.props.questionGroup?.name ?? ''}</h4>
                        {PreviousDayService.isFeatureAvailable(this.props.date) && <PullFromPreviousDayButton questionGroupId={this.props.questionGroup?.questionGroupId!} calendarDate={this.props.date} setData={this.handlePullFromPreviousDay} />}
                    </div>
                    <div className="mt-3">Notify the on-duty AC for Updates.</div>
                    {questions &&
                        this.chunk(questions, 4).map( (chunk, i) => 
                            <table className="reserve-apparatus-status-table table mt-3">
                                <thead>
                                    <tr>
                                        {chunk.map( x => 
                                            <th key={x.order} className="col w-25">{this.getName(x.optionGroupId)}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <IsolatedTableRowList needsToAutoField={this.props.needsToAutopopulate} optionGroups={this.state.optionGroups} defaultItemsCount={4} questions={chunk} updateQuestionGroup={this.updateQuestionGroup} date={this.props.date} reload={this.state.reload} />
                                </tbody>
                            </table>)
                    }
                </div>
            </div>
        )
    }
}
