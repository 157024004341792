import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";

function NFIRSWildlandProperty(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsWildlandProperty.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsWildlandPropertyInputDto>({
    objectName: "Property",
    nfirsSectionTab: "Wildland Fire/Property",
    promise: saveAction,
    afterSave: (data) => {
      initModels({
        ...model.new,
        concurrencyToken: data.concurrencyToken,
      });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsWildlandPropertyInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsWildlandProperty
      .get(props.id)
      .then((data) => {
        const mdl = { ...model.new, ...data, reportId: Number(props.id) };
        initModels(mdl);
        setValuesFromModel(data);
        setProgress({ loading: false });
        props.onChange(mdl);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Property", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Wildland Fire",
    "Property",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Wildland Fire", "Property", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const weatherTypes = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsWeatherTypeEntries,
    "Weather Types"
  );
  const windDirections = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsWindDirectionEntries,
    "Wind Directions"
  );
  const types = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getMobilePropertyTypes(false),
    "Mobile Property Types"
  );
  const typeSections = useNfirsLookupEnum(
    "MobilePropertyTypeSectionEnum",
    "Mobile Property Type Sections"
  );
  const equipments = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getEquipmentTypes(false),
    "Equipment Types"
  );
  const equipmentSections = useNfirsLookupEnum(
    "NfirsEquipmentTypeSectionEnum",
    "Equipment Types Sections"
  );
  const fireDangerRatings = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsFireDangerRatingEntries,
    "Fire Danger Ratings"
  );

  useEffect(() => {
    setProgress({ loading: true });
    weatherTypes.getData();
    windDirections.getData();
    equipments.getData();
    equipmentSections.getData();
    types.getData();
    typeSections.getData();
    fireDangerRatings.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      weatherTypes.status === NfirsFetchStatus.Complete &&
      windDirections.status === NfirsFetchStatus.Complete &&
      equipments.status === NfirsFetchStatus.Complete &&
      equipmentSections.status === NfirsFetchStatus.Complete &&
      types.status === NfirsFetchStatus.Complete &&
      typeSections.status === NfirsFetchStatus.Complete &&
      fireDangerRatings.status === NfirsFetchStatus.Complete
    ) {
      setProgress({ loading: true });
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    windDirections.status,
    weatherTypes.status,
    equipments.status,
    equipmentSections.status,
    types.status,
    typeSections.status,
    fireDangerRatings.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsMobilePropertyTypeEntryId: {
      displayName: "Mobile Property Type",
      validation: {
        required: false,
      },
    },
    nfirsEquipmentTypeEntryId: {
      displayName: "Equipment Involved in Ignition",
      validation: {
        required: false,
      },
    },
    primaryCropsBurned1: {
      displayName: "Primary Crops Burned",
      validation: {
        required: false,
      },
    },
    primaryCropsBurned2: {
      displayName: "Primary Crops Burned",
      validation: {
        required: false,
      },
    },
    primaryCropsBurned3: {
      displayName: "Primary Crops Burned",
      validation: {
        required: false,
      },
    },
    nfirsWeatherTypeEntryId: {
      displayName: "Weather Type",
      validation: {
        required: false,
      },
    },
    nfirsWindDirectionEntryId: {
      displayName: "Wind Direction",
      validation: {
        required: false,
      },
    },
    windSpeed: {
      displayName: "Wind Speed",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    airTemperature: {
      displayName: "Air Temperature",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    relativeHumidity: {
      displayName: "Relative Humidity",
      validation: {
        required: false,
        max: 100,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    fuelMoisture: {
      displayName: "Fuel Moisture",
      validation: {
        required: false,
        max: 99,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsFireDangerRatingEntryId: {
      displayName: "Fire Danger Rating",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    numBuildingIgnited: {
      displayName: "Number of Buildings Ignited",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    numBuildingsThreatened: {
      displayName: "Number of Buildings Threatened",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    totalAcresBurned: {
      displayName: "Total Acres Burned",
      validation: {
        max: 999999999.9,
        validate: {
          twoDecimalsOnly: commonService.validations.twoDecimalsOnly,
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
  });

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 mb-2">
          <strong>Property</strong>
        </div>
        <div className="col-sm-12 col-lg-8">
          <div className="row mt-2">
            <div className="col-sm-6 pb-2">
              <label>Mobile Property Type</label>
              <AdvancedDropdown
                domId="nfirsMobilePropertyTypeEntryId"
                value={model.new.nfirsMobilePropertyTypeEntryId}
                favoritesHandler={{
                  add: ApsServices.http.nfirsFavorite
                    .addFavoriteMobilePropertyType,
                  remove:
                    ApsServices.http.nfirsFavorite
                      .removeFavoriteMobilePropertyType,
                  afterUpdate: (list: any[]) => types.setData(list),
                }}
                options={(types.data || []).map((o) => {
                  return {
                    label: `${o.description} (${o.externalId})`,
                    value: o.id,
                    originalObject: o,
                    isFavorite: o.isFavorite,
                    groupId: o.mobilePropertyTypeSection,
                    subGroupId: 0,
                  };
                })}
                groups={(typeSections.data || []).map((o) => {
                  return {
                    groupId: o.value,
                    groupLabel: o.label,
                  };
                })}
                subGroups={(typeSections.data || []).map((o) => {
                  return {
                    groupId: o.value,
                    subGroupId: 0,
                  };
                })}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    nfirsMobilePropertyTypeEntryId:
                      Number(data) || (null as any),
                  });
                }}
              ></AdvancedDropdown>
            </div>
            <div className="col-sm-6 pb-2">
              <label>Equipment Involved in Ignition</label>
              <AdvancedDropdown
                domId="nfirsEquipmentTypeEntryId"
                value={model.new.nfirsEquipmentTypeEntryId}
                favoritesHandler={{
                  add: ApsServices.http.nfirsFavorite.addFavoriteEquipmentType,
                  remove:
                    ApsServices.http.nfirsFavorite.removeFavoriteEquipmentType,
                  afterUpdate: (list) => equipments.setData(list),
                }}
                options={(equipments.data || [])
                  .sort(commonService.sortByStringProperty("description"))
                  .map((o) => {
                    return {
                      label: `${o.description} (${o.externalId})`,
                      value: o.id,
                      originalObject: o,
                      isFavorite: o.isFavorite,
                      groupId: o.nfirsEquipmentTypeSection,
                      subGroupId: o.nfirsEquipmentTypeSection,
                    };
                  })}
                groups={(equipmentSections.data || []).map((o) => {
                  return {
                    groupId: o.value,
                    groupLabel: o.label,
                  };
                })}
                subGroups={(equipmentSections.data || []).map((o) => {
                  return {
                    groupId: o.value,
                    subGroupId: o.value,
                  };
                })}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    nfirsEquipmentTypeEntryId: Number(data) || (null as any),
                  });
                }}
              ></AdvancedDropdown>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FgSelect
                id="nfirsWeatherTypeEntryId"
                label="Weather Type"
                selectMessage="- Select Weather Type -"
                registeredField={registry.nfirsWeatherTypeEntryId}
                formState={formState}
                options={(weatherTypes?.data || []).map((item) => {
                  return {
                    value: item.id,
                    label: `${item.externalId}. ${item.description}`,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsWeatherTypeEntryId: Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className="col-sm-6">
              <FgSelect
                id="nfirsWindDirectionEntryId"
                label="Wind Direction"
                selectMessage="- Select Wind Direction -"
                registeredField={registry.nfirsWindDirectionEntryId}
                formState={formState}
                options={(windDirections?.data || []).map((item) => {
                  return {
                    value: item.id,
                    label: `${item.externalId}. ${item.description}`,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsWindDirectionEntryId:
                      Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FgInput
                id="windSpeed"
                label="Wind Speed"
                placeHolder="Wind Speed"
                registeredField={registry.windSpeed}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    windSpeed: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
              ></FgInput>
            </div>
            <div className="col-sm-6">
              <FgInput
                id="airTemperature"
                label="Air Temperature"
                placeHolder="Air Temperature"
                registeredField={registry.airTemperature}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    airTemperature: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
              ></FgInput>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FgInput
                id="relativeHumidity"
                label="Relative Humidity"
                placeHolder="Relative Humidity"
                registeredField={registry.relativeHumidity}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    relativeHumidity: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
                suffix="%"
              ></FgInput>
            </div>
            <div className="col-sm-6">
              <FgInput
                id="fuelMoisture"
                label="Fuel Moisture"
                placeHolder="Fuel Moisture"
                registeredField={registry.fuelMoisture}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    fuelMoisture: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
                suffix="%"
              ></FgInput>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FgSelect
                id="nfirsFireDangerRatingEntryId"
                label="Fire Danger Rating"
                selectMessage="- Select Fire Danger Rating -"
                registeredField={registry.nfirsFireDangerRatingEntryId}
                formState={formState}
                options={(fireDangerRatings?.data || []).map((item) => {
                  return {
                    value: item.id,
                    label: `${item.externalId}. ${item.description}`,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsFireDangerRatingEntryId:
                      Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className="col-sm-6"></div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FgInput
                id="numBuildingIgnited"
                label="Number of Buildings Ignited"
                placeHolder="Number of Buildings Ignited"
                registeredField={registry.numBuildingIgnited}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    numBuildingIgnited: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
                max={999}
              ></FgInput>
            </div>
            <div className="col-sm-6">
              <FgInput
                id="numBuildingsThreatened"
                label="Number of Buildings Threatened"
                placeHolder="Number of Buildings Threatened"
                registeredField={registry.numBuildingsThreatened}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    numBuildingsThreatened: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="1"
              ></FgInput>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FgInput
                id="totalAcresBurned"
                label="Total Acres Burned"
                placeHolder="Total Acres Burned"
                registeredField={registry.totalAcresBurned}
                showRequiredLabel={isOtherModuleDataRequired}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    totalAcresBurned: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="0.1"
              ></FgInput>
            </div>
            <div className="col-sm-6"></div>
          </div>
        </div>

        <div className="col-sm-12  col-lg-4">
          <div className="row mt-2">
            <div className="col-sm-12">
              <FgInput
                id="primaryCropsBurned1"
                label="Primary Crops Burned"
                placeHolder="Primary Crops Burned"
                registeredField={registry.primaryCropsBurned1}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    primaryCropsBurned1: data,
                  })
                }
              ></FgInput>
            </div>
            <div className="col-sm-12">
              <FgInput
                id="primaryCropsBurned2"
                placeHolder="Primary Crops Burned"
                registeredField={registry.primaryCropsBurned2}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    primaryCropsBurned2: data,
                  })
                }
              ></FgInput>
            </div>
            <div className="col-sm-12">
              <FgInput
                id="primaryCropsBurned3"
                placeHolder="Primary Crops Burned"
                registeredField={registry.primaryCropsBurned3}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    primaryCropsBurned3: data,
                  })
                }
              ></FgInput>
            </div>
          </div>
        </div>
        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSWildlandProperty;
