import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import { ShiftColor } from "../../data/ShiftColor";
import { ShiftColorService } from "../../services/ShiftColorService";
import { ShiftSchedulerService } from "../../services/ShiftSchedulerService";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';


// There's a bit of lag when pulling in the 3 day shift color window.
// We pass in the today color as a prop so that the outer DatePicker
// color can render at the same time as the calendar bar in the DOC
type Props = {
  onChange: (date: Date, shiftColor: ShiftColor) => void;
  value: Date;
  isSelectionDisabled: boolean;
  departmentId: number;
  todayColor: string;
};

type State = {
  ready: boolean;
  yesterdayColor: string;
  tomorrowColor: string;
  displayedDate: string;
};

const formatDate = (date: Date) => {
  return moment.utc(date).format("dddd, MMMM D, YYYY");
};

const dateFormatString = "yyyy-MM-DDThh:mm:ss";

const ApsDatePicker2 = (props: Props) => {
  const [state, setState] = useState<State>({
    ready: false,
    yesterdayColor: "",
    tomorrowColor: "",
    displayedDate: formatDate(props.value),
  });

  useEffect(() => {
    const init = async () => {
      if (props.departmentId === 0) return;

      const tomorrow = moment.utc(props.value).add(1, "days");
      const tomorrowStr =
        ShiftSchedulerService.convertDateStrToDepartmentTimeZone(
          tomorrow.format(dateFormatString),
          props.departmentId
        );
      const tomorrowShift =
        await ShiftSchedulerService.getShiftForDepartmentByDate(
          props.departmentId,
          tomorrowStr
        );

      const yesterday = moment.utc(props.value).add(-1, "days");
      const yesterdayStr =
        ShiftSchedulerService.convertDateStrToDepartmentTimeZone(
          yesterday.format(dateFormatString),
          props.departmentId
        );
      const yesterdayShift =
        await ShiftSchedulerService.getShiftForDepartmentByDate(
          props.departmentId,
          yesterdayStr
        );
      setState({
        ...state,
        ready: true,
        yesterdayColor: yesterdayShift.color,
        tomorrowColor: tomorrowShift.color,
      });
    };
    init();
  }, [props.departmentId, props.value]);

  const handleDateChanged = (value: DayValue) => {
    let newDate = moment();
    newDate.set("year", value!.year);
    newDate.set("month", value!.month - 1);
    newDate.set("date", value!.day);

    newDate = moment.utc(newDate.format("YYYY-MM-DD"), "YYYY-MM-DD");

    const shiftColor = ShiftColorService.getShiftColorBySpecificDay(
      newDate,
      props.departmentId
    );

    props.onChange(newDate.toDate(), shiftColor);
  };

  const getDayValue = (date: Moment): DayValue => {
    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  };

  const today = getDayValue(moment.utc(props.value));
  const tomorrow = getDayValue(moment.utc(props.value).add(1, "days"))!;
  const yesterday = getDayValue(moment.utc(props.value).add(-1, "days"))!;

  const customDays = [
    {
      year: yesterday.year,
      month: yesterday.month,
      day: yesterday.day,
      className: "yesterday",
    },
    {
      year: tomorrow.year,
      month: tomorrow.month,
      day: tomorrow.day,
      className: "tomorrow",
    },
  ];

  return (
    <>
      <style>{`.yesterday { background-color: ${state.yesterdayColor} }`}</style>
      <style> {`.today { background-color: ${props.todayColor} }`} </style>
      <style>{`.tomorrow { background-color: ${state.tomorrowColor} }`}</style>

      {props.isSelectionDisabled && (
        <div className="calendar-disabled d-flex align-items-center wrapper-calender">
          <div className={`calendar-disabled calendar-display-date`}>
            {state.displayedDate}
          </div>
        </div>
      )}

      {!props.isSelectionDisabled && (
        <DatePicker
          value={today}
          colorPrimary={props.todayColor}
          onChange={handleDateChanged}
          customDaysClassName={state.ready ? customDays : []}
          calendarPopperPosition="bottom"
          formatInputText={() => {
            return moment.utc(props.value).format("dddd, MMMM D, YYYY");
          }}
          inputClassName={`text-center calendar-display-date today`}
          calendarClassName="responsive-calendar"
        />
      )}
    </>
  );
};

export default ApsDatePicker2;
