import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import EmployeeListForCredential from "./EmployeeListForCredential";

function CredentialsViewByCredential(props: RouteComponentProps<any>) {
  const [credInfo, setCredInfo] = useState({ name: "", state: "", code: "" });

  return (
    <div className="flex flex-column w-100 px-2">
      <div className="mb-2">
        <h4 className="mb-4">View By Credential</h4>
        <div className="credential-by-header p-3 mb-2">
          <h4 className="m-0">
            {credInfo.name || "Loading..."}{" "}
            {credInfo.code ? `(${credInfo.code})` : ""}
          </h4>
          {credInfo.state}
        </div>
      </div>
      <div className="bg-white flex flex-column flex-1">
        <div className="px-4 pt-4">
          <strong>EMPLOYEES</strong>
        </div>
        <EmployeeListForCredential
          {...props}
          credentialId={Number(props.match.params.credentialid)}
          sendCredInfo={(n, c, s) =>
            setCredInfo({ name: n, code: c, state: s })
          }
        ></EmployeeListForCredential>
      </div>
    </div>
  );
}

export default CredentialsViewByCredential;
