import React, { Component, useEffect, useState } from 'react';
import { RouteComponentProps } from "react-router";
import { Link } from 'react-router-dom';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AgGridPageSizeSelector } from '../AgGridPageSizeSelector';
import { GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { AgGridService } from '../../services/AgGridService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services/UserService';
import { Users } from './Users';
import { UserDto, UserSummaryDto } from '../../data/User';
import { GroupDto } from '../../data/Group';
import { GroupService } from '../../services/GroupService';
import { DepartmentService } from '../../services/DepartmentService';
import moment from 'moment';
import toastStore from '../../aps2/stores/ToastStore';
import { Button } from 'react-bootstrap';
import commonService from '../../aps2/services/CommonService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import ApsServices from '../../aps2/services';
import { forEach } from 'lodash';
import { option } from '../../aps2/services/http/_all';

type ParamsId = { groupId?: string };

type Props = RouteComponentProps<ParamsId> & {}

interface IOption {
    id: number;
    name: string;
}

const refUser = React.createRef<AsyncTypeahead<any>>();
function GroupAddEditUsers(props: Props) {

    const [users, setUsers] = useState([] as IOption[]);
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([] as IOption[]);

    const getGroupUsers = async() => {
        try {
            let groupUsers: any = await ApsServices.http.group.getAllUsers(Number(props.match.params.groupId));
            const currUsers: IOption[] = groupUsers.map((g: any) => ({
                id: g.id,
                name: g.firstName + " " + g.lastName
            }));
            setSelectedUsers(currUsers);    // here we will need to update the state with the current users            
        } catch (error) {
            props.history.push('/admin/groups');
        }
    }

    useEffect(() => {
        async function fetchMyAPI() {
            await getGroupUsers();
        }

        fetchMyAPI()
    }, [props.match.params.groupId]);

    const cancelClose = () => {
        props.history.push(`/admin/group/${props.match.params.groupId}`)
    }

    const handleSubmit = async () => {
        await ApsServices.http.group.saveGroupUsers(Number(props.match.params.groupId), selectedUsers.map((s: IOption) => s.id))
        .then(() => {
            toastStore.showToast("Group users saved successfully.", "success");
            props.history.push(`/admin/group/${props.match.params.groupId}` + commonService.doneUrls.submitted);
        })
        .catch((err) => {
            toastStore.showError("Failed saving group users.", err);
        });
    }

    const handleSearchUser = async(query: string) => {
        if ((query || "").trim() === "") {
            setUsers([]);
            setIsLoadingUsers(false);
            return;
        }
        setIsLoadingUsers(false);
        await ApsServices.http.isoCommon
            .typeAheadUser({ search: query, isAnd: false, recordCount: 10 })
            .then((items) => {
                const options = items.map((i) => ({
                    id: i.id,
                    name: `${i.firstName} ${i.lastName}`,
                    firstName: i.firstName,
                    lastName: i.lastName,
                }));

                setUsers(options);
                setIsLoadingUsers(false);
            })
            .catch((err) => {
                setUsers([]);
                setIsLoadingUsers(false);
            });
    };

    
    return (
        <div className="container-fluid h-full">
            <div className="row">
                <div className="col">
                    <div className="page-title-box">
                        <h4 className="mb-0 font-size-18">ADD USERS TO GROUP</h4>
                    </div>
                </div>

                <div className="col">
                    <div>
                        <button name="submit" className="btn btn-primary float-right" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                    <span className=" float-right" >&nbsp;&nbsp;</span>
                    <div>
                        <button className="btn btn-secondary float-right" onClick={cancelClose}>
                            Cancel and Close
                        </button>
                    </div>
                </div>
            </div>
            <div className="row h-100">
                <div className="col-12 col-xl-4">
                    <div className="card h-100">
                        <div className="card-body">
                            <span><b>1. Select Users</b></span>
                            <div className="mt-3"></div>
                            <span>Select users to add to group</span>
                            <div className="mt-2">
                                <AsyncTypeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    onSearch={handleSearchUser}
                                    onChange={(data) => {
                                        if (data && data.length > 0) {
                                            const prts = [...selectedUsers];
                                            if (prts.filter((p) => p.id === data[0].id).length === 0) {
                                                prts.push({
                                                    id: data[0].id,
                                                    name: `${data[0].firstName} ${data[0].lastName}`,
                                                });
                                                setSelectedUsers(prts);
                                            }
                                            setUsers(data);
                                            (refUser.current as any)?.clear();
                                        }
                                    }}
                                    searchText={"Searching..."}
                                    isLoading={isLoadingUsers}
                                    options={users}
                                    placeholder="Search..."
                                    minLength={1}
                                    delay={500}
                                    useCache={false}
                                    ref={refUser}
                                />
                            </div>
                            <div className="currentParticipants mt-2 mb-4">
                                {selectedUsers?.map((p, i) => {
                                    return (
                                        <div key={i}>
                                            <section>{`${p.name}`}</section>
                                            <section
                                                title="Remove"
                                                onClick={() => {
                                                    const prts = [...selectedUsers];
                                                    prts.splice(i, 1);
                                                    setSelectedUsers(prts);                                                     
                                                }}
                                            >
                                                <i className="fa fa-times"></i>
                                            </section>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default GroupAddEditUsers;