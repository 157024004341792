import React from "react";
import FormModal from "../Common/FormModal";
import NFIRSReportQueueTable from "./NFIRSReportQueueTable";

function NFIRSReportQueueDialog(props: { onClose?: () => void }) {
  return (
    <div>
      <FormModal
        title="Report Queue"
        isOpen={true}
        close={() => {
          props.onClose && props.onClose();
        }}
        submit={(data) => {
          props.onClose && props.onClose();
        }}
        primaryButtonOnly={true}
        submitButtonLabel="Done"
        size="lg"
      >
        <NFIRSReportQueueTable dialog={true}></NFIRSReportQueueTable>
      </FormModal>
    </div>
  );
}

export default NFIRSReportQueueDialog;
