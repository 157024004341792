import fetcher from "./Fetcher";

class GroupService {
  listAllGroups = async () => {
    const url = `/api/Group/ListAllGroups`;
    return fetcher.get<
      {
        groupId: number;
        name: string;
      }[]
    >(url);
  };

  getAllUsers = async (groupId?: number) => {
    const url = `/api/Group/GetAllUsers/${groupId || ""}`;
    return fetcher.get<
      {
        id: number;
        firstName: string;
        lastName: string;
      }[]
    >(url);
    };

    saveGroupUsers = async (groupId: number, userIds: number[]) => {
        const url = `/api/Group/${groupId}/Users`;
        return fetcher.post<
            {
                id: number;
                firstName: string;
                lastName: string;
            }[]
        >(url, userIds);
    };
}

const group = new GroupService();
export default group;
