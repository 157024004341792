import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import nfirsStore from "../../../../stores/NFIRSStore";
import CustomTabs from "../../../Common/CustomTabs";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import { INFIRSSectionProps, useSectionTabs } from "../NFIRSEntry";
import NFIRSRemarks from "../NFIRSRemarks";
import NFIRSValidationButton from "../NFIRSValidationButton";
import NFIRSBasicActions from "./NFIRSBasicActions";
import NFIRSBasicAuthority from "./NFIRSBasicAuthority";
import NFIRSBasicDates from "./NFIRSBasicDates";
import NFIRSBasicIncident from "./NFIRSBasicIncident";
import NFIRSBasicInvolved from "./NFIRSBasicInvolved";
import NFIRSBasicLocation from "./NFIRSBasicLocation";
import NFIRSBasicProperty from "./NFIRSBasicProperty";

function NFIRSSectionBasic(props: INFIRSSectionProps) {
  const { onTabSelect, activeTab, loadedTabs, changeTabStatus, getTabLabel } =
    useSectionTabs(props, "Basic", "Authority");

  const incident = useNfirsGetData(async () => {
    return await ApsServices.http.nfirsBasicIncidentService.get(props.id);
  }, "Incident");

  useEffect(() => {
    nfirsStore.setNfirsAidGivenReceivedEntryId(undefined);
    incident.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (incident.status === NfirsFetchStatus.Complete && incident.data) {
      nfirsStore.setNfirsAidGivenReceivedEntryId(
        incident.data.nfirsAidGivenReceivedEntryId
      );
      nfirsStore.setNfirsBasicIncidentType(incident.data.nfirsIncidentTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident.status]);

  return (
    <>
      <NFIRSValidationButton
        module={ApsModels.NfirsValidationModulesEnum.Basic}
        onVerify={props.onVerify}
      ></NFIRSValidationButton>
      <CustomTabs
        onMenuTabClick={onTabSelect}
        activeTab={activeTab}
        className="custom-tab-with-grid"
      >
        <Tabs
          id="nfirs-basic-page"
          activeKey={activeTab}
          onSelect={onTabSelect}
        >
          <Tab
            eventKey="Authority"
            title={getTabLabel("Authority", "Authority")}
          >
            {loadedTabs["Authority"] && (
              <div className="pt-4">
                <NFIRSBasicAuthority
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Authority", hasChanges ? 1 : 0);
                  }}
                ></NFIRSBasicAuthority>
              </div>
            )}
          </Tab>
          <Tab eventKey="Incident" title={getTabLabel("Incident", "Incident")}>
            {loadedTabs["Incident"] && (
              <div className="pt-4">
                <NFIRSBasicIncident
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Incident", hasChanges ? 1 : 0);
                  }}
                ></NFIRSBasicIncident>
              </div>
            )}
          </Tab>
          <Tab eventKey="Location" title={getTabLabel("Location", "Location")}>
            {loadedTabs["Location"] && (
              <div className="pt-4">
                <NFIRSBasicLocation
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Location", hasChanges ? 1 : 0);
                  }}
                ></NFIRSBasicLocation>
              </div>
            )}
          </Tab>
          <Tab eventKey="Dates" title={getTabLabel("Dates", "Dates")}>
            {loadedTabs["Dates"] && (
              <div className="pt-4">
                <NFIRSBasicDates
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Dates", hasChanges ? 1 : 0);
                  }}
                ></NFIRSBasicDates>
              </div>
            )}
          </Tab>
          <Tab eventKey="Actions" title={getTabLabel("Actions", "Actions")}>
            {loadedTabs["Actions"] && (
              <div className="pt-4">
                <NFIRSBasicActions
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Actions", hasChanges ? 1 : 0);
                  }}
                ></NFIRSBasicActions>
              </div>
            )}
          </Tab>
          <Tab eventKey="Property" title={getTabLabel("Property", "Property")}>
            {loadedTabs["Property"] && (
              <div className="pt-4">
                <NFIRSBasicProperty
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Property", hasChanges ? 1 : 0);
                  }}
                ></NFIRSBasicProperty>
              </div>
            )}
          </Tab>
          <Tab eventKey="Involved" title={getTabLabel("Involved", "Involved")}>
            {loadedTabs["Involved"] && (
              <div className="pt-4">
                <NFIRSBasicInvolved
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Involved", hasChanges ? 1 : 0);
                  }}
                ></NFIRSBasicInvolved>
              </div>
            )}
          </Tab>
          <Tab eventKey="Remarks" title={getTabLabel("Remarks", "Remarks")}>
            {loadedTabs["Remarks"] && (
              <div className="pt-4">
                <NFIRSRemarks
                  id={props.id}
                  header={props.header}
                  data={{
                    remarkSectionFor: ApsModels.NfirsRemarkSectionEnum.Basic,
                  }}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Remarks", hasChanges ? 1 : 0);
                  }}
                ></NFIRSRemarks>
              </div>
            )}
          </Tab>
        </Tabs>
      </CustomTabs>
    </>
  );
}

export default NFIRSSectionBasic;
