import React, { useEffect, useState } from "react";
import { SsoService, SsoServiceDto } from "../../../data/SSO";
import { SingleSignOnService } from "../../../services/SsoService";
import ExpandCollapse from "../../Shared/ExpandCollapse";

type Props = {
    departmentId: number
}

type ServiceState = {
    serviceId: number
    serviceName: string
    serviceEnabled: boolean
}

const getAvailableSsoServices = async (departmentId: number) => {
    const dtos = await SingleSignOnService.getAvailableSsoServices(departmentId);
    const services = dtos.map(SingleSignOnService.convertFromSsoServiceDto);
    const serviceStates = services.map(s => ({
        serviceId: s.ssoServiceId,
        serviceName: s.ssoServiceName,
        serviceEnabled: Boolean(s.ssoServiceEnabled)
    }));
    return serviceStates
}

type State = {
    ready: boolean
    saved: boolean
    error: boolean
}
export const SSOAdmin: React.FC<Props> = ({ departmentId }) => {
    const [services, setServices] = useState<ServiceState[]>([]);
    const [state, setState] = useState<State>({ready: false, saved: false, error: false});


    useEffect(() => {
        const init = async () => {
            setServices(await getAvailableSsoServices(departmentId));
            setState({...state, ready: true});
        }
        init();
    }, []);

    const onToggle = (idx: number) => {
        setState({...state, saved: false});
        const newServices = [...services];
        newServices[idx].serviceEnabled = !newServices[idx].serviceEnabled;
        setServices(newServices);
    }

    const save = async () => {
        setState({...state, saved: false})
        const dtos = services.map(s => {
            const dto: SsoServiceDto = {
                ssoServiceId: s.serviceId,
                ssoServiceName: s.serviceName,
                ssoServiceEnabled: Number(s.serviceEnabled)
            }
            return dto;
        });
        await SingleSignOnService.updateSsoServices(departmentId, dtos);
        setState({...state, saved: true})
    }

    return (
        <ExpandCollapse
            title="Online Training"
            titleStyle={{ fontWeight: 500, fontSize: '1.21875rem' }} // hack to simulate <h4>
        >
            <div className="px-3 pb-3">
                <div className="mb-3">
                    Available Services:
                </div>
                {state.ready && services.map((s, i) => (
                    <div className="row mb-3" key={`SSOAdmin-${i}`}>
                        <div className="col-md-2">
                            {s.serviceName}
                        </div>
                        <div className="col-md-10">
                            <div className="custom-control custom-switch cursor-pointer">
                                <input
                                    type="checkbox"
                                    className="custom-control-input cursor-pointer"
                                    id="customSwitch2"
                                    checked={s.serviceEnabled}
                                    onChange={() => { }}
                                />
                                <label
                                    className="custom-control-label cursor-pointer"
                                    htmlFor="customSwitch2"
                                    onClick={_ => onToggle(i)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                {state.ready && services.length == 0 &&
                    <div>
                        There are no SSO Services in the database.
                    </div>
                }
                <div>
                    <button className="btn btn-primary"
                        onClick={save}
                    >
                        Save Settings
                    </button>
                    {state.saved && 
                        <span className="ml-3">Saved!</span>
                    }
                </div>
            </div>
        </ExpandCollapse>
    )
}