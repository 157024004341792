import moment, { Moment } from "moment";
import { Departments } from "../data/Department";
import { ShiftColor } from "../data/ShiftColor";

class ShiftColorService {
    private readonly initialRedDay: number[] = [2021, 1, 8]; 

    private getInitialRedDay(departmentId: number) {
        switch (departmentId) {
            case Departments.Glendale:
            case Departments.Monrovia:
            case Departments.MontereyPark:
                return [2021, 1, 8];
            case Departments.WestCovina:
                return [2021, 9, 21];
            default:
                return [2021, 1, 8];
        }
    }

    public getShiftColorBySpecificDay(day: Moment, departmentId: number): ShiftColor {
        // const initialPointDate = moment.utc(this.initialRedDay);
        const initialPointDate = moment.utc(this.getInitialRedDay(departmentId));
        const daysDifference = day.startOf('day').diff(initialPointDate, 'days');
        if (departmentId === -2) {
            let daysAfterRedDay = daysDifference  % 9;
            if (daysAfterRedDay < 0) {
                daysAfterRedDay += 9;
            }
    
            switch (daysAfterRedDay) {
                case 0: return ShiftColor.Red;
                case 1: return ShiftColor.Blue;
                case 2: return ShiftColor.Red;
                case 3: return ShiftColor.Blue;
                case 4: return ShiftColor.Green;
                case 5: return ShiftColor.Blue;
                case 6: return ShiftColor.Green;
                case 7: return ShiftColor.Red;
                case 8: return ShiftColor.Green;
            }
        } else {
            const daysAfterRedDay = (daysDifference / 2) % 3; // This line calculates the pattern of (RR BB GG), In some cases that will give a decimal 0.5, to solve that, a rounding down was implemented
            
            switch (Math.floor(daysAfterRedDay)) {
                case 0: return ShiftColor.Red;
                case 1: return ShiftColor.Blue;
                case 2: return ShiftColor.Green;
                case -1: return ShiftColor.Green;
                case -2: return ShiftColor.Blue;
            }
        }

        return ShiftColor.Red
    }
}

const shiftColorService = new ShiftColorService();
export { shiftColorService as ShiftColorService };
