import React, { useState } from "react";
import { useEffect } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import nfirsStore from "../../../stores/NFIRSStore";
import CommonSpinner from "../../Common/CommonSpinner";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRSHelper";
import { INFIRSTabProps, useSaveOnNext } from "./NFIRSEntry";
import NFIRSRemarkDialog from "./NFIRSRemarkDialog";
import NFIRSRemarkItem from "./NFIRSRemarkItem";

function NFIRSRemarks(
  props: INFIRSTabProps<{ remarkSectionFor: ApsModels.NfirsRemarkSectionEnum }>
) {
  useSaveOnNext(
    props.data?.remarkSectionFor === ApsModels.NfirsRemarkSectionEnum.Basic
      ? "Basic"
      : "Arson",
    "Remarks",
    () => {
      setTimeout(() => {
        nfirsStore.setMoveToNextTab();
      }, 200);
    }
  );

  const list = useNfirsGetData(() => {
    return ApsServices.http.nfirsRemark.getAll(
      props.id,
      props.data?.remarkSectionFor
    );
  }, "Remarks");

  useEffect(() => {
    if (!list.data) {
      list.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addRemark, setAddRemark] = useState(false);

  return (
    <>
      {addRemark && (
        <NFIRSRemarkDialog
          data={{
            reportId: props.id,
            section: props.data.remarkSectionFor,
            item: undefined,
          }}
          onClose={(saved) => {
            if (saved) {
              list.getData();
            }
            setAddRemark(false);
          }}
        ></NFIRSRemarkDialog>
      )}

      {list.status === NfirsFetchStatus.InProgress && (
        <CommonSpinner></CommonSpinner>
      )}
      {list.status === NfirsFetchStatus.Complete && (
        <div>
          <label className="required-label">Remarks</label>
          {list?.data?.map((item) => (
            <React.Fragment key={item.id}>
              <NFIRSRemarkItem
                id={props.id}
                item={item}
                section={props.data.remarkSectionFor}
                refresh={() => list.getData()}
              ></NFIRSRemarkItem>
            </React.Fragment>
          ))}
          {!list?.data?.length && <div className="py-3">No Remarks Found</div>}
          <button
            className="btn btn-primary"
            onClick={() => setAddRemark(true)}
          >
            <i className="fa fa-plus"></i> Add Remark
          </button>
        </div>
      )}
    </>
  );
}

export default NFIRSRemarks;
