import ApsModels from "../../models";
import fetcher from "./Fetcher";

class TimeZoneService {
  getAll = async () => {
    const url = `/api/TimeZones/GetAll/`;
    return fetcher.get<ApsModels.ITimeZoneDto[]>(url);
  };
}

const timezone = new TimeZoneService();
export default timezone;