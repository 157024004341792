import moment from "moment";
import React, { useEffect, useState } from "react";
import CommonSpinner from "../../../../aps2/components/Common/CommonSpinner";
import commonService from "../../../../aps2/services/CommonService";
import {
  AnswerDto,
  AnswerOptionReferenceDto,
  AnswerUserReferenceDto,
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { UserDto } from "../../../../data/User";
import { GroupService } from "../../../../services/GroupService";
import { PreviousDayService } from "../../../../services/PreviousDayService";
import { UserService } from "../../../../services/UserService";
import { PullFromPreviousDayButton } from "../../CustomInput/PullFromPreviousDayButton";
import GenericIsolatedInputList from "./GenericIsolatedInputList";

interface IGenericResponseCommentsProps {
  questionGroup?: QuestionGroupDto;
  date: Date;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  moduleOrder: number;
  departmentId: number;
  groupName?: string | string[];
}
interface IGenericResponseCommentsState {
  text?: string;
  userDto: UserDto[];
  wsaOptions: OptionDto[];
  userSelected?: UserDto;
  ready: boolean;
}

function GenericResponseComments(props: IGenericResponseCommentsProps) {
  const [state, setState] = useState<Partial<IGenericResponseCommentsState>>({
    text: "",
    userDto: [],
    wsaOptions: new Array<OptionDto>(),
    userSelected: undefined,
    ready: false,
  });

  const initialize = async () => {
    const allGroup = await GroupService.getAllGroups();
    const groupName = props.groupName || []; //New Renderer could send Array
    const grpList = Array.isArray(groupName) ? [...groupName] : [groupName];
    const users = await UserService.getUsersInMultipleGroupsByDepartmentId(
      grpList
        .map((grpName) => {
          return allGroup.find((gx) => gx.name === grpName)?.groupId || 0;
        })
        .filter((g) => g > 0),
      props.departmentId
    );

    setState((prev) => {
      return {
        ...prev,
        userDto: users.sort(commonService.sortByStringProperty("firstName")),
      };
    });
    const userId = props.questionGroup?.questions
      .find((x) => x.order === 0)
      ?.answers.find((y) => y.order === 0)?.user?.userId;
    if (userId) {
      const user = await UserService.getUser(userId);

      setState((prev) => {
        return {
          ...prev,
          userSelected: user,
        };
      });
    }
    setState((prev) => {
      return {
        ...prev,
        ready: true,
      };
    });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRowsNumber = (expected: number, actual: AnswerDto[] | undefined) => {
    const orders = new Array<number>();
    if (actual?.length) {
      actual.map((x) => orders.push(x.order));
      const maxNumber = Math.max(...orders);
      if (expected <= actual.length) {
        if (actual.length <= maxNumber) {
          return maxNumber + 1;
        }
        return actual.length + 1;
      }
    }
    return expected;
  };

  function formatName(answer: AnswerUserReferenceDto | undefined) {
    return commonService.getUserShortName((answer?.userId ? `${state.userDto?.find(y => y.userId == answer.userId)?.firstName} ${state.userDto?.find(y => y.userId == answer.userId)?.lastName}` : answer?.userName) ?? "")
  }

  const factorySubGroup = (value: QuestionDto | undefined) => {
    const questionGroup = props.questionGroup;
    const index = props.questionGroup?.questions.findIndex(
      (x) => x.order === value?.order
    );
    if (index !== undefined) {
      if (questionGroup?.questions[index] && value) {
        questionGroup.questions[index] = value;
      }
    }
    props.setData(questionGroup, props.moduleOrder);
  };

  const handlePullFromPreviousDay = async (
    newQuestionGroupValue: QuestionGroupDto
  ) => {
    props.setData(newQuestionGroupValue, props.moduleOrder);
  };

  

  return (
    <>
      {state.ready && (
        <div className="grid-item card box-card flex-grow-1">
          {!state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
          <div className="card-body " >
            <div className="row">
              <div className="col-sm-12 flex">
                <h4 className="mb-0 font-size-16 flex-1">
                  {props.questionGroup?.name || ""}
                </h4>
                {PreviousDayService.isFeatureAvailable(props.date) && (
                  <PullFromPreviousDayButton
                    questionGroupId={props.questionGroup?.questionGroupId!}
                    calendarDate={props.date}
                    setData={handlePullFromPreviousDay}
                  />
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-9">
                <div className="row">
                  <h4 className="col-sm-12 font-size-16">
                    {
                      props.questionGroup?.questions.find((x) => x.order === 0)
                        ?.questionText
                    }
                  </h4>
                </div>
                <div className="row">
                  <div className="response-first-column col-lg-4">
                    {props.questionGroup?.questions.find((x) => x.order === 0)
                      ?.answers && (
                        <GenericIsolatedInputList
                          key={moment(props.date).toString()}
                          setData={factorySubGroup}
                          defaultItemsCount={getRowsNumber(
                            2,
                            props.questionGroup?.questions.find(
                              (x) => x.order === 0
                            )?.answers
                          )}
                          question={props.questionGroup?.questions.find(
                            (x) => x.order === 0
                          )}
                          answerDto={
                            props.questionGroup?.questions.find(
                              (x) => x.order === 0
                            )?.answers
                          }
                          userDto={state.userDto}
                          isDropdown={true}
                          date={props.date}
                        />
                      )}
                  </div>
                  <div className="response-first-column col-lg-4">
                    {props.questionGroup?.questions.find((x) => x.order === 1)
                      ?.answers && (
                        <GenericIsolatedInputList
                          key={moment(props.date).toString()}
                          setData={factorySubGroup}
                          defaultItemsCount={getRowsNumber(
                            2,
                            props.questionGroup?.questions.find(
                              (x) => x.order === 1
                            )?.answers
                          )}
                          question={props.questionGroup?.questions.find(
                            (x) => x.order === 1
                          )}
                          answerDto={
                            props.questionGroup?.questions.find(
                              (x) => x.order === 1
                            )?.answers
                          }
                          userDto={state.userDto}
                          isDropdown={true}
                          date={props.date}
                        />
                      )}
                  </div>
                  <div className="response-first-column col-lg-4">
                    {props.questionGroup?.questions.find((x) => x.order === 2)
                      ?.answers && (
                        <GenericIsolatedInputList
                          key={moment(props.date).toString()}
                          setData={factorySubGroup}
                          defaultItemsCount={getRowsNumber(
                            2,
                            props.questionGroup?.questions.find(
                              (x) => x.order === 2
                            )?.answers
                          )}
                          question={props.questionGroup?.questions.find(
                            (x) => x.order === 2
                          )}
                          answerDto={
                            props.questionGroup?.questions.find(
                              (x) => x.order === 2
                            )?.answers
                          }
                          userDto={state.userDto}
                          isDropdown={true}
                          date={props.date}
                        />
                      )}
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <h4 className="font-size-16">
                  {
                    props.questionGroup?.questions.find((x) => x.order === 3)
                      ?.questionText
                  }
                </h4>
                {props.questionGroup?.questions.find((x) => x.order === 3)
                  ?.answers && (
                    <GenericIsolatedInputList
                      setData={factorySubGroup}
                      defaultItemsCount={getRowsNumber(1, undefined)}
                      preventExpanding={true}
                      question={props.questionGroup?.questions.find(
                        (x) => x.order === 3
                      )}
                      answerDto={
                        props.questionGroup?.questions.find((x) => x.order === 3)
                          ?.answers
                      }
                      userDto={state.userDto}
                      isDropdown={false}
                      date={props.date}
                      questionGroup={props.questionGroup.questions}
                    />
                  )}
              </div>
            </div>
          </div>
          <div className="card-body d-none childsection4">
            <div className="row">
              <div className="col-sm-12 flex">
                <h4 className="mb-0 font-size-22 flex-1">
                  {props.questionGroup?.name || ""}
                  <hr />
                </h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-9">
                <div className="row">
                  <h4 className="col-sm-12">
                    {
                      props.questionGroup?.questions.find((x) => x.order === 0)
                        ?.questionText
                    }
                    <hr />
                  </h4>
                </div>
                <div className="row">
                  <div className="response-first-column col-lg-4  font-size-20" >
                    {props.questionGroup?.questions.find((x) => x.order === 0)
                      ?.answers && (
                        props.questionGroup?.questions.find(
                          (x) => x.order === 0
                        )?.answers.map(x => <>{formatName(x.user)} <br /> </>)
                      )}
                  </div>
                  <div className="response-first-column col-lg-4 font-size-20">
                    {props.questionGroup?.questions.find((x) => x.order === 1)
                      ?.answers && (
                        props.questionGroup?.questions.find(
                          (x) => x.order === 1
                        )?.answers.map(x => <>{formatName(x.user)} <br /> </>)
                      )}
                  </div>
                  <div className="response-first-column col-lg-4 font-size-20">
                    {props.questionGroup?.questions.find((x) => x.order === 2)
                      ?.answers && (
                        props.questionGroup?.questions.find(
                          (x) => x.order === 2
                        )?.answers.map(x => <>{formatName(x.user)} <br /> </>)
                      )}
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <h4 className=" font-size-20">
                  {
                    props.questionGroup?.questions.find((x) => x.order === 3)
                      ?.questionText
                  }

                  <hr />
                </h4>
                {props.questionGroup?.questions.find((x) => x.order === 3)
                  ?.answers && (
                    props.questionGroup?.questions.find(
                      (x) => x.order === 3
                    )?.answers.map(x => <span className=" font-size-20">{x.textAnswerValue} <br /></span>)
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GenericResponseComments;
