import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSReportService {
  list = async (
    page: number,
    pageSize: number,
    data?: ApsModels.INfirsReportSearchInputDto
  ) => {
    const url = `/api/NfirsReport/List/${page}/${pageSize}`;
    return fetcher.post<ApsModels.INfirsReportGridOutputDto>(url, data || {});
  };

  get = async (id: number) => {
    const url = `/api/NfirsReport/${id}`;
    return fetcher.get<ApsModels.INfirsReportOutputDto>(url);
  };

  delete = async (id: number) => {
    const url = `/api/NfirsReport/${id}`;
    return fetcher.delete<any>(url);
  };

  lock = async (data: ApsModels.INfirsReportLockInputDto) => {
    const url = `/api/NfirsReport/Lock`;
    return fetcher.post<any>(url, data);
  };

  calStatExport = async (data: ApsModels.INfirsCalStatReportExportInputDto) => {
    const url = `/api/NfirsReport/CalStatExport`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  pdfExport = async (reportIds: number[]) => {
    const url = `/api/NfirsReport/PdfExport`;
    return fetcher.post<any>(url, reportIds, { responseType: "blob" });
  };

  listCalStatBatchReportQueue = async (page: number, pageSize: number) => {
    const url = `/api/NfirsReport/ListCalStatBatchReportQueue/${page}/${pageSize}`;
    return fetcher.get<ApsModels.INfirsCatBatchReportGridDto>(url);
  };

  downloadCalStatReport = async (queueId: number) => {
    const url = `/api/NfirsReport/DownloadCalStatReport/${queueId}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };
}

const nfirsReport = new NFIRSReportService();
export default nfirsReport;
