import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApsModels from "../../../../models";
import CustomTabs from "../../../Common/CustomTabs";
import { INFIRSSectionProps, useSectionTabs } from "../NFIRSEntry";
import NFIRSRemarks from "../NFIRSRemarks";
import NFIRSValidationButton from "../NFIRSValidationButton";
import NFIRSArsonAgencyReferral from "./NFIRSArsonAgencyReferral";
import NFIRSArsonDetails from "./NFIRSArsonDetails";
import NFIRSArsonInvestigation from "./NFIRSArsonInvestigation";
import NFIRSArsonJuvenile from "./NFIRSArsonJuvenile";

function NFIRSSectionArson(props: INFIRSSectionProps) {
  const { onTabSelect, activeTab, loadedTabs, changeTabStatus, getTabLabel } =
    useSectionTabs(props, "Arson", "Investigation");

  return (
    <>
      <NFIRSValidationButton
        module={ApsModels.NfirsValidationModulesEnum.Arson}
        onVerify={props.onVerify}
      ></NFIRSValidationButton>
      <CustomTabs onMenuTabClick={onTabSelect} activeTab={activeTab}>
        <Tabs
          id="nfirs-arson-page"
          activeKey={activeTab}
          onSelect={onTabSelect}
        >
          <Tab
            eventKey="Investigation"
            title={getTabLabel("Investigation", "Investigation")}
          >
            {loadedTabs["Investigation"] && (
              <div className="pt-4">
                <NFIRSArsonInvestigation
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Investigation", hasChanges ? 1 : 0);
                  }}
                ></NFIRSArsonInvestigation>
              </div>
            )}
          </Tab>
          <Tab eventKey="Details" title={getTabLabel("Details", "Details")}>
            {loadedTabs["Details"] && (
              <div className="pt-4">
                <NFIRSArsonDetails
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Details", hasChanges ? 1 : 0);
                  }}
                ></NFIRSArsonDetails>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="AgencyReferral"
            title={getTabLabel("AgencyReferral", "Agency Referral")}
          >
            {loadedTabs["AgencyReferral"] && (
              <div className="pt-4">
                <NFIRSArsonAgencyReferral
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("AgencyReferral", hasChanges ? 1 : 0);
                  }}
                ></NFIRSArsonAgencyReferral>
              </div>
            )}
          </Tab>
          <Tab eventKey="Juvenile" title={getTabLabel("Juvenile", "Juvenile")}>
            {loadedTabs["Juvenile"] && (
              <div className="pt-4">
                <NFIRSArsonJuvenile
                  id={props.id}
                  header={props.header}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Juvenile", hasChanges ? 1 : 0);
                  }}
                ></NFIRSArsonJuvenile>
              </div>
            )}
          </Tab>
          <Tab eventKey="Remarks" title={getTabLabel("Remarks", "Remarks")}>
            {loadedTabs["Remarks"] && (
              <div className="pt-4">
                <NFIRSRemarks
                  id={props.id}
                  header={props.header}
                  data={{
                    remarkSectionFor: ApsModels.NfirsRemarkSectionEnum.Arson,
                  }}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Remarks", hasChanges ? 1 : 0);
                  }}
                ></NFIRSRemarks>
              </div>
            )}
          </Tab>
        </Tabs>
      </CustomTabs>
    </>
  );
}

export default NFIRSSectionArson;
