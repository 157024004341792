import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSStructureFireStructureTypeService {
  get = async (reportId: any) => {
    const url = `/api/NfirsStructureFireStructureType/${reportId}`;
    return fetcher.get<ApsModels.INfirsStructureFireStructureTypeOutputDto>(
      url
    );
  };

  update = async (data: ApsModels.INfirsStructureFireStructureTypeInputDto) => {
    const url = `/api/NfirsStructureFireStructureType`;
    return fetcher.post<ApsModels.INfirsStructureFireStructureTypeOutputDto>(
      url,
      data
    );
  };
}

const nfirsStructureFireStructureType =
  new NFIRSStructureFireStructureTypeService();
export default nfirsStructureFireStructureType;
