import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApsModels from "../../../../models";
import CustomTabs from "../../../Common/CustomTabs";
import { INFIRSSectionProps, useSectionTabs } from "../NFIRSEntry";
import NFIRSValidationButton from "../NFIRSValidationButton";
import NFIRSWildlandDetails from "./NFIRSWildlandDetails";
import NFIRSWildlandIgnition from "./NFIRSWildlandIgnition";
import NFIRSWildlandLocations from "./NFIRSWildlandLocations";
import NFIRSWildlandPeopleInvolved from "./NFIRSWildlandPeopleInvolved";
import NFIRSWildlandProperty from "./NFIRSWildlandProperty";

function NFIRSSectionWildlandFire(props: INFIRSSectionProps) {
  const { onTabSelect, activeTab, loadedTabs, changeTabStatus, getTabLabel } =
    useSectionTabs(props, "Wildland Fire", "Location");

  return (
    <>
      <NFIRSValidationButton
        module={ApsModels.NfirsValidationModulesEnum.Wildland}
        onVerify={props.onVerify}
      ></NFIRSValidationButton>
      <CustomTabs onMenuTabClick={onTabSelect} activeTab={activeTab}>
        <Tabs
          id="nfirs-wildland-fire-page"
          activeKey={activeTab}
          onSelect={onTabSelect}
        >
          <Tab eventKey="Location" title={getTabLabel("Location", "Location")}>
            {loadedTabs["Location"] && (
              <div className="pt-4">
                <NFIRSWildlandLocations
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Location", hasChanges ? 1 : 0);
                  }}
                ></NFIRSWildlandLocations>
              </div>
            )}
          </Tab>
          <Tab eventKey="Ignition" title={getTabLabel("Ignition", "Ignition")}>
            {loadedTabs["Ignition"] && (
              <div className="pt-4">
                <NFIRSWildlandIgnition
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Ignition", hasChanges ? 1 : 0);
                  }}
                ></NFIRSWildlandIgnition>
              </div>
            )}
          </Tab>
          <Tab eventKey="Property" title={getTabLabel("Property", "Property")}>
            {loadedTabs["Property"] && (
              <div className="pt-4">
                <NFIRSWildlandProperty
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Property", hasChanges ? 1 : 0);
                  }}
                ></NFIRSWildlandProperty>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="PeopleInvolved"
            title={getTabLabel("PeopleInvolved", "People Involved")}
          >
            {loadedTabs["PeopleInvolved"] && (
              <div className="pt-4">
                <NFIRSWildlandPeopleInvolved
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("PeopleInvolved", hasChanges ? 1 : 0);
                  }}
                ></NFIRSWildlandPeopleInvolved>
              </div>
            )}
          </Tab>
          <Tab eventKey="Details" title={getTabLabel("Details", "Details")}>
            {loadedTabs["Details"] && (
              <div className="pt-4">
                <NFIRSWildlandDetails
                  id={props.id}
                  data={null}
                  onChange={(data, hasChanges) => {
                    changeTabStatus("Details", hasChanges ? 1 : 0);
                  }}
                ></NFIRSWildlandDetails>
              </div>
            )}
          </Tab>
        </Tabs>
      </CustomTabs>
    </>
  );
}

export default NFIRSSectionWildlandFire;
