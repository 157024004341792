import ApsModels from "../../models";
import fetcher from "./Fetcher";

class DrillFavoriteService {
  addFavoriteDrillName = async (
    data: ApsModels.ILogDrillNameFavoriteInputDto
  ) => {
    const url = `/api/DrillFavorite/AddFavoriteDrillName`;
    return fetcher.post<ApsModels.IDrillOutputDto[]>(url, data);
  };

  removeFavoriteDrillName = async (
    data: ApsModels.ILogDrillNameFavoriteInputDto
  ) => {
    const url = `/api/DrillFavorite/RemoveFavoriteDrillName`;
    return fetcher.post<ApsModels.IDrillOutputDto[]>(url, data);
  };

  getDrillNames = async (isFavorite: boolean) => {
    const url = `/api/DrillFavorite/GetDrillNames/${isFavorite}`;
    return fetcher.post<ApsModels.IDrillOutputDto[]>(url);
  };
}

const drillFavorite = new DrillFavoriteService();
export default drillFavorite;
