import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgCheckbox,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import commonService from "../../../../services/CommonService";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import { reaction } from "mobx";

function NFIRSFireProperty(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsFireProperty.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsFirePropertyInputDto>({
    objectName: "Property",
    nfirsSectionTab: "Fire/Property",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsFirePropertyInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Fire", "Property", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Fire", "Property", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const siteMaterials = useNfirsGetData<
    ApsModels.INfirsOnSiteMaterialFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getOnSiteMaterials(false),
    "Site Materials"
  );

  const [siteMaterialStorageUses, setSiteMaterialStorageUses] = useState<
    ApsModels.INfirsOnSiteMaterialStorageUseEntryOutputDto[]
  >([]);
  const getSiteMaterialStorageUses = async () => {
    await ApsServices.http.nfirsGenericLookupService
      .getNfirsOnSiteMaterialsStorageUseEntries()
      .then((rtn) => {
        setSiteMaterialStorageUses(rtn);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Site Materials Storage Uses", err);
        setProgress({ loading: false });
      });
  };

  const [materialSuperSections, setMaterialSuperSections] = useState<
    ApsModels.ILookupIntDto[]
  >([]);
  const getMaterialSuperSections = async () => {
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp("OnSiteMaterialSuperSectionEnum")
      .then((rtn) => {
        setMaterialSuperSections(rtn);
      })
      .catch((err) => {
        toastStore.showError(
          "Failed Getting Site Material Super Sections",
          err
        );
        setProgress({ loading: false });
      });
  };

  const [materialSubSections, setMaterialSubSections] = useState<
    ApsModels.ILookupIntDto[]
  >([]);
  const getMaterialSubSections = async () => {
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp("OnSiteMaterialsSubSectionEnum")
      .then((rtn) => {
        setMaterialSubSections(rtn);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Site Material Sub Sections", err);
        setProgress({ loading: false });
      });
  };

  useEffect(() => {
    setProgress({ loading: true });
    siteMaterials.getData();
    getMaterialSuperSections();
    getMaterialSubSections();
    getSiteMaterialStorageUses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsFireProperty
        .get(props.id)
        .then((data) => {
          const defaults = {
            notBuilding: false,
            notResidential: false,
          };
          const mdl = {
            ...defaults,
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Property information", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      siteMaterials.status === NfirsFetchStatus.Complete &&
      materialSuperSections.length > 0 &&
      materialSubSections.length > 0 &&
      siteMaterialStorageUses.length > 0
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    siteMaterials.status,
    siteMaterialStorageUses,
    materialSuperSections,
    materialSubSections,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    numResidentialUnits: {
      displayName: "Number of Residential Units",
      validation: {
        required: false,
        max: 9999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    numBuildingsInvolved: {
      displayName: "Number of Buildings Invovled",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    numAcresBurned: {
      displayName: "Amount",
      validation: {
        required: false,
        max: 999999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsOnSiteMaterialEntryId: {
      displayName: "On Site Materials or Products",
      validation: {
        required: false,
      },
    },
    nfirsOnSiteMaterialStorageUseEntryId: {
      displayName: "On Site Materials Storage Use",
      validation: {
        required: false,
      },
    },
  });

  const onAcresBurnedChanged = (event: any) => {
    const numAcresBurned =
      Number(event.target.value) === 3
        ? model.old.numAcresBurned || undefined
        : undefined;
    onModelChange({
      ...model.new,
      acresBurnedEnum: Number(event.target.value),
      numAcresBurned: numAcresBurned,
    } as any);
    setValue("numAcresBurned", numAcresBurned);
  };

  const basicProperty = useNfirsGetData(async () => {
    return await ApsServices.http.nfirsBasicPropertyService.get(props.id);
  }, "Property");
  const incident = useNfirsGetData(async () => {
    return await ApsServices.http.nfirsBasicIncidentService.get(props.id);
  }, "Incident");

  const [nfirsPropertyUseTypeId, setNfirsPropertyUseTypeId] = useState(
    nfirsStore.nfirsPropertyUseTypeId
  );
  const [nfirsBasicIncidentType, setNfirsBasicIncidentType] = useState(
    nfirsStore.nfirsBasicIncidentType
  );

  useEffect(() => {
    if (nfirsPropertyUseTypeId === undefined) {
      basicProperty.getData();
    }
    if (nfirsBasicIncidentType === undefined) {
      incident.getData();
    }

    const disposer = reaction(
      () => nfirsStore.nfirsPropertyUseTypeId,
      (data) => {
        setNfirsPropertyUseTypeId(data);
      }
    );
    const disposer2 = reaction(
      () => nfirsStore.nfirsBasicIncidentType,
      (data) => {
        setNfirsBasicIncidentType(data);
      }
    );

    return () => {
      disposer();
      disposer2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      basicProperty.status === NfirsFetchStatus.Complete &&
      basicProperty.data &&
      nfirsPropertyUseTypeId === undefined
    ) {
      setNfirsPropertyUseTypeId(basicProperty.data.nfirsPropertyUseTypeId || 0);
    }
  }, [basicProperty.status]);

  useEffect(() => {
    if (
      incident.status === NfirsFetchStatus.Complete &&
      incident.data &&
      nfirsBasicIncidentType === undefined
    ) {
      setNfirsBasicIncidentType(incident.data.nfirsIncidentTypeId || 0);
    }
  }, [incident.status]);

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();
  const requireLivingUnits = () => {
    //https://aps-software.atlassian.net/browse/APS-385?focusedCommentId=12226
    if (
      isOtherModuleDataRequired &&
      nfirsPropertyUseTypeId &&
      nfirsBasicIncidentType
    ) {
      return (
        ((nfirsPropertyUseTypeId >= 400 && nfirsPropertyUseTypeId < 500) || // Must be 400 series
          (nfirsBasicIncidentType >= 100 && nfirsBasicIncidentType < 200)) && // Incident Type must be 100 series
        !(nfirsBasicIncidentType >= 113 && nfirsBasicIncidentType < 119) // but NOT 113 thru 118
      );
    }
    return false;
  };

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 col-lg-6">
          <strong>Property</strong>
          <div className="mt-2">
            <div>
              <label>
                {requireLivingUnits() && (
                  <span className="required-label">*</span>
                )}
                Number of Residential Units
              </label>
              {!progress.loading && (
                <section style={{ float: "right" }}>
                  <FgCheckbox
                    id="notResidential"
                    label="Not Residential"
                    data={model.new.notResidential}
                    onChange={(val) => {
                      const numResidentialUnits = val
                        ? 0
                        : model.old.numResidentialUnits || undefined;
                      onModelChange({
                        ...model.new,
                        notResidential: val,
                        numResidentialUnits: numResidentialUnits,
                      } as any);
                      setValue("numResidentialUnits", numResidentialUnits);
                    }}
                  ></FgCheckbox>
                </section>
              )}
            </div>
            <FgInput
              id="numResidentialUnits"
              placeHolder="Number of Residential Units"
              registeredField={registry.numResidentialUnits}
              formState={formState}
              type="number"
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  numResidentialUnits: Number(data) || (undefined as any),
                } as any)
              }
              disabled={model.new.notResidential}
            ></FgInput>

            <div>
              <label>Number of Buildings Involved</label>

              {!progress.loading && (
                <section style={{ float: "right" }}>
                  <FgCheckbox
                    id="notBuilding"
                    label="Not Building"
                    data={model.new.notBuilding}
                    onChange={(val) => {
                      const numBuildingsInvolved = val
                        ? 0
                        : model.old.numBuildingsInvolved || undefined;
                      onModelChange({
                        ...model.new,
                        notBuilding: val,
                        numBuildingsInvolved: numBuildingsInvolved,
                      } as any);
                      setValue("numBuildingsInvolved", numBuildingsInvolved);
                    }}
                  ></FgCheckbox>
                </section>
              )}
            </div>
            <FgInput
              id="numBuildingsInvolved"
              placeHolder="Number of Buildings Involved"
              registeredField={registry.numBuildingsInvolved}
              formState={formState}
              type="number"
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  numBuildingsInvolved: Number(data) || (undefined as any),
                } as any)
              }
              disabled={model.new.notBuilding}
            ></FgInput>

            <div className="row">
              <div className="col-sm-6">
                <label>Acres Burned?</label>
                <div className="radio-with-label">
                  <input
                    type="radio"
                    name="acresBurnedEnum"
                    id="acresBurnedEnum1"
                    value="1"
                    checked={
                      model.new.acresBurnedEnum ===
                      ApsModels.NfirsFirePropertyAcresBurnedEnum.None
                    }
                    onChange={onAcresBurnedChanged}
                  ></input>
                  <label htmlFor="acresBurnedEnum1">Not Applicable</label>
                </div>
                <div className="radio-with-label">
                  <input
                    type="radio"
                    name="acresBurnedEnum"
                    id="acresBurnedEnum2"
                    value="2"
                    checked={
                      model.new.acresBurnedEnum ===
                      ApsModels.NfirsFirePropertyAcresBurnedEnum.LessThan1
                    }
                    onChange={onAcresBurnedChanged}
                  ></input>
                  <label htmlFor="acresBurnedEnum2">Less than 1 acre</label>
                </div>
                <div className="radio-with-label">
                  <input
                    type="radio"
                    name="acresBurnedEnum"
                    id="acresBurnedEnum3"
                    value="3"
                    checked={
                      model.new.acresBurnedEnum ===
                      ApsModels.NfirsFirePropertyAcresBurnedEnum.MoreThan1
                    }
                    onChange={onAcresBurnedChanged}
                  ></input>
                  <label htmlFor="acresBurnedEnum3">More than 1 acre</label>
                </div>
              </div>
              <div
                className={`col-sm-6 ${
                  model.new.acresBurnedEnum ===
                  ApsModels.NfirsFirePropertyAcresBurnedEnum.MoreThan1
                    ? ""
                    : "display-none"
                }`}
              >
                <FgInput
                  id="numAcresBurned"
                  label="Enter Amount if More than 1 Acre"
                  placeHolder="Amount"
                  registeredField={registry.numAcresBurned}
                  formState={formState}
                  type="number"
                  onChange={(data) => {
                    setModel((prev) => {
                      return {
                        ...prev,
                        new: {
                          ...prev.new,
                          numAcresBurned: Number(data) || (undefined as any),
                        },
                      };
                    });
                  }}
                  disabled={
                    model.new.acresBurnedEnum !==
                    ApsModels.NfirsFirePropertyAcresBurnedEnum.MoreThan1
                  }
                ></FgInput>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="mt-4">
            <label>On Site Materials or Products</label>
            <AdvancedDropdown
              domId="nfirsOnSiteMaterialEntryId"
              value={model.new.nfirsOnSiteMaterialEntryId}
              favoritesHandler={{
                add: ApsServices.http.nfirsFavorite.addFavoriteOnSiteMaterial,
                remove:
                  ApsServices.http.nfirsFavorite.removeFavoriteOnSiteMaterial,
                afterUpdate: (list: any[]) => siteMaterials.setData(list),
              }}
              options={(siteMaterials.data || []).map((o) => {
                return {
                  label: `${o.description} (${o.externalId})`,
                  value: o.id,
                  originalObject: o,
                  isFavorite: o.isFavorite,
                  groupId: o.onSiteMaterialSuperSection,
                  subGroupId: o.onSiteMaterialsSubSection,
                };
              })}
              groups={materialSuperSections.map((o) => {
                return {
                  groupId: o.value,
                  groupLabel: o.label,
                };
              })}
              subGroups={materialSubSections.map((o) => {
                return {
                  subGroupId: o.value,
                  subGroupLabel: o.label,
                  groupId: (siteMaterials.data || []).find(
                    (m) => m.onSiteMaterialsSubSection === o.value
                  )?.onSiteMaterialSuperSection,
                };
              })}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  nfirsOnSiteMaterialEntryId:
                    Number(data) || (undefined as any),
                });
              }}
            ></AdvancedDropdown>
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsOnSiteMaterialStorageUseEntryId"
              label="On Site Material Storage Use"
              selectMessage="Select On Site Material Storage Use"
              registeredField={registry.nfirsOnSiteMaterialStorageUseEntryId}
              formState={formState}
              options={siteMaterialStorageUses.map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsOnSiteMaterialStorageUseEntryId:
                    Number(data) || (undefined as any),
                })
              }
            ></FgSelect>
          </div>
        </div>
        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSFireProperty;
