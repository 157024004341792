import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSBasicActionsService {
  get = async (id: any) => {
    const url = `/api/NfirsBasicAction/${id}`;
    return fetcher.get<ApsModels.INfirsBasicActionOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsBasicActionInputDto) => {
    const url = `/api/NfirsBasicAction`;
    return fetcher.post<ApsModels.INfirsBasicActionOutputDto>(url, data);
  };
}

const nfirsBasicActionsService = new NFIRSBasicActionsService();
export default nfirsBasicActionsService;
