import fetcher from "../Fetcher";

class NFIRSDashboardService {
  myOpenReportsCount = () => {
    const url = `/api/NfirsDashboard/MyOpenReportsCount`;
    return fetcher.get<number>(url);
  };

  userOpenReportsCount = (userId: number) => {
    const url = `/api/NfirsDashboard/UserOpenReportsCount/${userId}`;
    return fetcher.get<number>(url);
  };
}

const nfirsDashboard = new NFIRSDashboardService();
export default nfirsDashboard;
