import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSFavoriteService {
  //Property Use Types
  getPropertyUseTypes = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetPropertyUseTypes/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsPropertyUseTypeFavoriteOutputDto[]>(url);
  };

  addFavoritePropertyUseType = async (
    data: ApsModels.INfirsPropertyUseTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoritePropertyUseType`;
    return fetcher.post<ApsModels.INfirsPropertyUseTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoritePropertyUseType = async (
    data: ApsModels.INfirsPropertyUseTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoritePropertyUseType`;
    return fetcher.post<ApsModels.INfirsPropertyUseTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Incident Types
  getIncidentTypes = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetIncidentTypes/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsIncidentTypeFavoriteOutputDto[]>(url);
  };

  addFavoriteIncidentType = async (
    data: ApsModels.INfirsIncidentTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteIncidentType`;
    return fetcher.post<ApsModels.INfirsIncidentTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteIncidentType = async (
    data: ApsModels.INfirsIncidentTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteIncidentType`;
    return fetcher.post<ApsModels.INfirsIncidentTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Actions Taken

  getActionsTaken = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetActionsTaken/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsActionTakenFavoriteOutputDto[]>(url);
  };

  addFavoriteActionTaken = async (
    data: ApsModels.INfirsActionTakenFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteActionTaken`;
    return fetcher.post<ApsModels.INfirsActionTakenFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteActionTaken = async (
    data: ApsModels.INfirsActionTakenFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteActionTaken`;
    return fetcher.post<ApsModels.INfirsActionTakenFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //On Site Material

  getOnSiteMaterials = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetOnSiteMaterials/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsOnSiteMaterialFavoriteOutputDto[]>(url);
  };

  addFavoriteOnSiteMaterial = async (
    data: ApsModels.INfirsOnSiteMaterialFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteOnSiteMaterial`;
    return fetcher.post<ApsModels.INfirsOnSiteMaterialFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteOnSiteMaterial = async (
    data: ApsModels.INfirsOnSiteMaterialFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteOnSiteMaterial`;
    return fetcher.post<ApsModels.INfirsOnSiteMaterialFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Contributing Factor

  getContributingFactors = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetContributingFactors/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsContributingFactorFavoriteOutputDto[]>(
      url
    );
  };

  addFavoriteContributingFactor = async (
    data: ApsModels.INfirsContributingFactorFavoriteOutputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteContributingFactor`;
    return fetcher.post<ApsModels.INfirsContributingFactorFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteContributingFactor = async (
    data: ApsModels.INfirsContributingFactorFavoriteOutputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteContributingFactor`;
    return fetcher.post<ApsModels.INfirsContributingFactorFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Equipment Type

  getEquipmentTypes = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetEquipmentTypes/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsEquipmentTypeFavoriteOutputDto[]>(url);
  };

  addFavoriteEquipmentType = async (
    data: ApsModels.INfirsEquipmentTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteEquipmentType`;
    return fetcher.post<ApsModels.INfirsEquipmentTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteEquipmentType = async (
    data: ApsModels.INfirsEquipmentTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteEquipmentType`;
    return fetcher.post<ApsModels.INfirsEquipmentTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Fire Suppression Factors

  getFireSuppressions = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetFireSuppressions/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsFireSuppressionFavoriteOutputDto[]>(url);
  };

  addFavoriteFireSuppression = async (
    data: ApsModels.INfirsFireSuppressionFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteFireSuppression`;
    return fetcher.post<ApsModels.INfirsFireSuppressionFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteFireSuppression = async (
    data: ApsModels.INfirsFireSuppressionFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteFireSuppression`;
    return fetcher.post<ApsModels.INfirsFireSuppressionFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Area of Fire Origin

  getAreaOfOrigins = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetAreaOfOrigins/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsAreaOfOriginEntryFavoriteOutputDto[]>(
      url
    );
  };

  addFavoriteAreaOfOrigin = async (
    data: ApsModels.INfirsAreaOfOriginEntryFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteAreaOfOrigin`;
    return fetcher.post<ApsModels.INfirsAreaOfOriginEntryFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteAreaOfOrigin = async (
    data: ApsModels.INfirsAreaOfOriginEntryFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteAreaOfOrigin`;
    return fetcher.post<ApsModels.INfirsAreaOfOriginEntryFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Heat Source

  getHeatSources = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetHeatSources/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsHeatSourceEntryFavoriteOutputDto[]>(url);
  };

  addFavoriteHeatSource = async (
    data: ApsModels.INfirsHeatSourceEntryFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteHeatSource`;
    return fetcher.post<ApsModels.INfirsHeatSourceEntryFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteHeatSource = async (
    data: ApsModels.INfirsHeatSourceEntryFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteHeatSource`;
    return fetcher.post<ApsModels.INfirsHeatSourceEntryFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Item First Ignited

  getItemsFirstIgnited = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetItemsFirstIgnited/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsItemFirstIgnitedFavoriteOutputDto[]>(
      url
    );
  };

  addFavoriteItemFirstIgnited = async (
    data: ApsModels.INfirsItemFirstIgnitedFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteItemFirstIgnited`;
    return fetcher.post<ApsModels.INfirsItemFirstIgnitedFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteItemFirstIgnited = async (
    data: ApsModels.INfirsItemFirstIgnitedFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteItemFirstIgnited`;
    return fetcher.post<ApsModels.INfirsItemFirstIgnitedFavoriteOutputDto[]>(
      url,
      data
    );
  };

  //Type of Material

  getTypeOfMaterials = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetTypeOfMaterials/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsTypeOfMaterialFavoriteOutputDto[]>(url);
  };

  addFavoriteTypeOfMaterial = async (
    data: ApsModels.INfirsTypeOfMaterialFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteTypeOfMaterial`;
    return fetcher.post<ApsModels.INfirsTypeOfMaterialFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteTypeOfMaterial = async (
    data: ApsModels.INfirsTypeOfMaterialFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteTypeOfMaterial`;
    return fetcher.post<ApsModels.INfirsTypeOfMaterialFavoriteOutputDto[]>(
      url,
      data
    );
  };

  // Mobile Property Make

  getMobilePropertyMakes = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetMobilePropertyMakes/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsMobilePropertyMakeFavoriteOutputDto[]>(
      url
    );
  };

  addFavoriteMobilePropertyMake = async (
    data: ApsModels.INfirsMobilePropertyMakeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteMobilePropertyMake`;
    return fetcher.post<ApsModels.INfirsMobilePropertyMakeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteMobilePropertyMake = async (
    data: ApsModels.INfirsMobilePropertyMakeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteMobilePropertyMake`;
    return fetcher.post<ApsModels.INfirsMobilePropertyMakeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  // Mobile Property Type

  getMobilePropertyTypes = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetMobilePropertyTypes/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsMobilePropertyTypeFavoriteOutputDto[]>(
      url
    );
  };

  addFavoriteMobilePropertyType = async (
    data: ApsModels.INfirsMobilePropertyTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteMobilePropertyType`;
    return fetcher.post<ApsModels.INfirsMobilePropertyTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteMobilePropertyType = async (
    data: ApsModels.INfirsMobilePropertyTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteMobilePropertyType`;
    return fetcher.post<ApsModels.INfirsMobilePropertyTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  // Type of Material Contributing To Flame Spread

  getTypesOfMaterialContributingToFlameSpread = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetTypesOfMaterialContributingToFlameSpread/${isFavorite}`;
    return fetcher.get<
      ApsModels.INfirsTypeOfMaterialContributingToFlameSpreadFavoriteOutputDto[]
    >(url);
  };

  addFavoriteTypeOfMaterialContributingToFlameSpread = async (
    data: ApsModels.INfirsTypeOfMaterialContributingToFlameSpreadFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteTypeOfMaterialContributingToFlameSpread`;
    return fetcher.post<
      ApsModels.INfirsTypeOfMaterialContributingToFlameSpreadFavoriteOutputDto[]
    >(url, data);
  };

  removeFavoriteTypeOfMaterialContributingToFlameSpread = async (
    data: ApsModels.INfirsTypeOfMaterialContributingToFlameSpreadFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteTypeOfMaterialContributingToFlameSpread`;
    return fetcher.post<
      ApsModels.INfirsTypeOfMaterialContributingToFlameSpreadFavoriteOutputDto[]
    >(url, data);
  };

  // Hazmat Action Taken

  getHazmatActionsTaken = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetHazmatActionsTaken/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsHazmatActionTakenFavoriteOutputDto[]>(
      url
    );
  };

  addFavoriteHazmatActionTaken = async (
    data: ApsModels.INfirsHazmatActionTakenFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteHazmatActionTaken`;
    return fetcher.post<ApsModels.INfirsHazmatActionTakenFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoriteHazmatActionTaken = async (
    data: ApsModels.INfirsHazmatActionTakenFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteHazmatActionTaken`;
    return fetcher.post<ApsModels.INfirsHazmatActionTakenFavoriteOutputDto[]>(
      url,
      data
    );
  };

  // Factor Contributing to Release

  getFactorsContributingToRelease = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetFactorsContributingToRelease/${isFavorite}`;
    return fetcher.get<
      ApsModels.INfirsFactorContributingToReleaseFavoriteOutputDto[]
    >(url);
  };

  addFavoriteFactorContributingToRelease = async (
    data: ApsModels.INfirsFactorContributingToReleaseFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteFactorContributingToRelease`;
    return fetcher.post<
      ApsModels.INfirsFactorContributingToReleaseFavoriteOutputDto[]
    >(url, data);
  };

  removeFavoriteFactorContributingToRelease = async (
    data: ApsModels.INfirsFactorContributingToReleaseFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteFactorContributingToRelease`;
    return fetcher.post<
      ApsModels.INfirsFactorContributingToReleaseFavoriteOutputDto[]
    >(url, data);
  };

  // Factor Contributing to Mitigation

  getFactorsContributingToMitigation = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetFactorsContributingToMitigation/${isFavorite}`;
    return fetcher.get<
      ApsModels.INfirsFactorContributingToMitigationFavoriteOutputDto[]
    >(url);
  };

  addFavoriteFactorContributingToMitigation = async (
    data: ApsModels.INfirsFactorContributingToMitigationFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteFactorContributingToMitigation`;
    return fetcher.post<
      ApsModels.INfirsFactorContributingToMitigationFavoriteOutputDto[]
    >(url, data);
  };

  removeFavoriteFactorContributingToMitigation = async (
    data: ApsModels.INfirsFactorContributingToMitigationFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteFactorContributingToMitigation`;
    return fetcher.post<
      ApsModels.INfirsFactorContributingToMitigationFavoriteOutputDto[]
    >(url, data);
  };

  // Chemical Container

  getChemicalContainerTypes = async (isFavorite: boolean) => {
    const url = `/api/NFIRSFavorite/GetChemicalContainerTypes/${isFavorite}`;
    return fetcher.get<
      ApsModels.INfirsChemicalContainerTypeFavoriteOutputDto[]
    >(url);
  };

  addFavoriteChemicalContainerType = async (
    data: ApsModels.INfirsChemicalContainerTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/AddFavoriteChemicalContainerType`;
    return fetcher.post<
      ApsModels.INfirsChemicalContainerTypeFavoriteOutputDto[]
    >(url, data);
  };

  removeFavoriteChemicalContainerType = async (
    data: ApsModels.INfirsChemicalContainerTypeFavoriteInputDto
  ) => {
    const url = `/api/NFIRSFavorite/RemoveFavoriteChemicalContainerType`;
    return fetcher.post<
      ApsModels.INfirsChemicalContainerTypeFavoriteOutputDto[]
    >(url, data);
  };
}

const nfirsFavorite = new NFIRSFavoriteService();
export default nfirsFavorite;
