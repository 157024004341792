import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import ApsModels from "../../models";
import commonService from "../../services/CommonService";
import systemStore from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import ConfirmDialog from "./ConfirmDialog";

function CommonSystemErrors(props: any) {
  const getFeatures = () => {
    return commonService.userInfo?.userClaims
      ?.filter(
        (c) =>
          c.claimValue !== ApsModels.UserClaimValueEnum.Remove &&
          !(c as any).inToken
      )
      .map((c) => {
        if (c.claimType === ApsModels.UserClaimTypeEnum.LogDrills) {
          return "* Drills";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.Personnel) {
          return "* Personnel";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.TrainingPortal) {
          return "* Online Training";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.Nfirs) {
          return "* NFIRS";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.Dashboard) {
          return "* Home";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.CourseRecords) {
          return "* Courses";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.Credentials) {
          return "* Credentials";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.CredTemplates) {
          return "* Templates";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.DataVisualization) {
          return "* Data Visualization";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.CustomIFrame) {
          return "* Custom iframe";
        }
        if (c.claimType === ApsModels.UserClaimTypeEnum.IsoTraining) {
          return "* Reports";
        }
        return "Unknown";
      })
      .join("<br/>");
  };

  useEffect(() => {
    toastStore.setSuppressAccessDenied(systemStore.systemError !== "none");
    const disposer = reaction(
      () => systemStore.systemError,
      (n, p, i) => {
        toastStore.setSuppressAccessDenied(systemStore.systemError !== "none");
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {systemStore.systemError !== "none" && (
        <ConfirmDialog
          title="Please log off and log back in to access your new feature(s)"
          message={
            systemStore.systemError === "access-denied"
              ? `
              Please log off and log back in to access the new feature(s) which has been added to your account.<br/><br/>
              If you still can't access the feature(s), please contact support.`
              : `
              Please log off and log back in to access the new feature(s) which has been added to your account.<br/><br/>
              ${getFeatures()}<br/><br/>
              If you still can't access the feature(s), please contact support.
              `
          }
          show={true}
          done={() => {
            systemStore.showSystemError("none");
          }}
          buttons="okonly"
        />
      )}
    </>
  );
}

export default observer(CommonSystemErrors);
