import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSFireEquipmentInvolved {
  get = async (reportId: any) => {
    const url = `/api/NfirsFireEquipmentInvolved/${reportId}`;
    return fetcher.get<ApsModels.INfirsFireEquipmentInvolvedOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsFireEquipmentInvolvedInputDto) => {
    const url = `/api/NfirsFireEquipmentInvolved`;
    return fetcher.post<ApsModels.INfirsFireEquipmentInvolvedOutputDto>(
      url,
      data
    );
  };
}

const nfirsFireEquipmentInvolved = new NFIRSFireEquipmentInvolved();
export default nfirsFireEquipmentInvolved;
