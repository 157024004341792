import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
    AnswerDto,
    AnswerType, IsolatedSelectItem,
    QuestionDto,
    QuestionGroupDto
} from '../../../data/DailyOperational';
import { UserDto } from '../../../data/User';
import { GroupService } from '../../../services/GroupService';
import { IsolatedSelect } from "../CustomInput/IsolatedSelect";
import { UserService } from "../../../services/UserService";
import CommonSpinner from '../../../aps2/components/Common/CommonSpinner';

type Props = {
    questionGroup?: QuestionGroupDto,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    date: Date,
    moduleOrder: number,
    departmentId: number
}

type State = {
    users: UserDto[] | null,
    selectedUserId: number | null,
    isOpenModal: boolean,
    ready: boolean
}
export class StaffCaptain extends Component<Props, State> {
    private static getAnswerUserId(questions: QuestionDto[] | undefined): number | undefined {
        const operationsBC: number = 0;
        return questions && questions.find(x => x.order === operationsBC)?.answers.find(y => y.order === 0)?.user?.userId;
    }

    private static getAnswerUserName(questions: QuestionDto[] | undefined): string | undefined {
        const operationsBC: number = 0;
        return questions && questions.find(x => x.order === operationsBC)?.answers.find(y => y.order === 0)?.user?.userName;
    }

    state: State = {
        users: null,
        selectedUserId: null,
        isOpenModal: false,
        ready: false
    }

    componentDidMount = async () => {
        const allGroup = await GroupService.getAllGroups();
        const group = allGroup.find(x => x.name === "Staff Captains");
        if (group) {
            const users = await UserService.getUsersInGroupByDepartmentId(group.groupId, this.props.departmentId);
            this.setState({
                users: users
            });
        }
        this.setState({ ready: true });
    }

    private updateQuestionGroup(newQuestionValue: QuestionDto) {
        const questionGroup = cloneDeep(this.props.questionGroup)!;
        const questionIndex = questionGroup.questions.findIndex(q => q.order == newQuestionValue.order);
        questionGroup.questions[questionIndex] = newQuestionValue;
        this.props.setData(questionGroup, this.props.moduleOrder);
    }

    private handleOperationStaffChanged = (userId?: number, userName?: string) => {
        const questionOrder = 0;
        const question: QuestionDto = cloneDeep(this.props.questionGroup!.questions.find(q => q.order == questionOrder)!);
        const isAnswerExists = question.answers.length > 0;
        const answerId: number = question!.answers[0] ? question!.answers[0].answerId! : 0;

        let answer: AnswerDto;

        if (isAnswerExists || answerId > 0) {
            answer = question.answers.find(a => a.answerId == answerId)!;
            answer.user!.userId = userId;
            answer.user!.userName = userName;
            answer.date = this.props.date;
            answer.dateCreated = this.props.date;
            answer.dateUpdated = this.props.date;
        } else {
            answer = this.createNewAnswer(answerId, question, userId, userName)
            question.answers.push(answer);
        }
        this.updateQuestionGroup(question);
    }

    private createNewAnswer(answerId: number, question: QuestionDto, userId?: number, userName?: string): AnswerDto {
        return {
            answerId: answerId,
            answerType: AnswerType.User,
            date: this.props.date,
            dateCreated: this.props.date,
            dateUpdated: this.props.date,
            flagAnswerValue: false,
            order: 0,
            questionId: question.questionId ?? 0,
            user: {userId: userId, userName: userName, answerId: 0}
        };
    }

    triggerModal = (): void => {
        this.setState({ isOpenModal: !this.state.isOpenModal });
    }

    handleModalClose = (): void => {
        this.triggerModal();
    }

    render() {
        const userId: number | undefined = StaffCaptain.getAnswerUserId(this.props.questionGroup?.questions);
        const userName: string | undefined = StaffCaptain.getAnswerUserName(this.props.questionGroup?.questions);
        const items: IsolatedSelectItem[] = this.state.users ? this.state.users!.map(u => { return {id: u.userId, name: `${u.firstName} ${u.lastName}`} }) : [];

        return (
            <div className="daily-operational-card card box-card">
                {!this.state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
                <div className="card-body">
                    <h4 className="mb-0 font-size-16">{this.props.questionGroup?.name || ''}</h4>
                    <br/>
                    <div>
                        <IsolatedSelect onChange={this.handleOperationStaffChanged} availableOptions={items} id={userId} name={userName} showDefaultOptionName={true} className="col-xl-12 border rounded-10 h-8 form-control"/>
                    </div>
                </div>
            </div>
        )
    }
}
