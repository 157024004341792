import React, { Component, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, ValueFormatterParams, SetFilterValuesFuncParams } from 'ag-grid-community';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { GroupService } from '../../services/GroupService';
import { AgGridService } from '../../services/AgGridService';
import { GroupSummaryDto, GroupSystemGeneratedEnum } from '../../data/Group';
import { AgGridPageSizeSelector } from '../AgGridPageSizeSelector';
import { RouteComponentProps } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import ApsModels from '../../aps2/models';
import { useGridFilters } from '../../aps2/stores/SystemStore';
import commonService from '../../aps2/services/CommonService';
class GroupsServerSideDatasource implements IServerSideDatasource {
    onReady: (total: number) => void = () => {};
    constructor(onReady: (total: number)=> void) {
         this.onReady = onReady;
    }
    status = ApsModels.ActiveEnum.Active;
    generatedBy = GroupSystemGeneratedEnum.UserCreated;    
    getRows = async (params: IServerSideGetRowsParams) => {
        const fopParameters = AgGridService.convertRequest(params.request);
        //fopParameters.paginate.take = 10000;
        const result = await GroupService.getSummary(fopParameters, this.status, this.generatedBy);        
        params.successCallback(result.items.map(row => {
            return {
                ...row,
                _lastModified: commonService.agGridHelpers.toExportDate(row.lastModified),
                _isActive: row.isActive ? "Active" : "Inactive"
            }
        }), result.total);
        this.onReady(result.total);
    }
}

type CellRendererProps = {
    context: Groups,
    data: GroupSummaryDto,
}

class StatusCellRenderer extends Component<CellRendererProps> {
    render() {
        const isActive = this.props.data.isActive;

        return (
            <span className={`badge ${isActive ? 'badge-success' : 'badge-danger'}`}>
                {isActive ? 'Active' : 'Inactive'}
            </span>
        )
    }
}

class ActionCellRenderer extends Component<CellRendererProps> {
    onEdit = () => {
        (this.props.context as Groups).onEdit(this.props.data);
    }

    render() {
        return (
            <>
                {!this.props.data.isNotEditable && 
                <a onClick={this.onEdit} className="text-primary">
                    <FontAwesomeIcon icon={faPencilAlt} />
                </a>}
            </>
        )
    }
}

type Props = RouteComponentProps & {}

type State = {
    datasource: GroupsServerSideDatasource,
    status: ApsModels.ActiveEnum,
    generatedBy: GroupSystemGeneratedEnum,
    gridFilterProps?: any;
    gridEvent?: GridReadyEvent;
    resetKey?: string;
    exportKey?: string;
    exportAllKey?: string;
    total?: number;
}

export class Groups extends Component<Props, State> {
    static displayName = Groups.name;
    private _gridApi?: GridApi;

    state: State = {
        datasource: new GroupsServerSideDatasource(total => {
            this.setState({ total: total }); 
         }),
        status: ApsModels.ActiveEnum.Active,
        generatedBy: GroupSystemGeneratedEnum.UserCreated
    }

    onGridReady = (e: GridReadyEvent) => {        
        this._gridApi = e.api;
        this._gridApi?.setServerSideDatasource(this.state.datasource);
        this.setState({ gridEvent: e });
    }

    onEdit = (group: GroupSummaryDto) => {
        this.props.history.push(`/admin/group/${group.groupId}`);
    }

    onAdd = () => {
        this.props.history.push("/admin/group");
    }

    onPageSizeChange = (pageSize: number) => {
        this._gridApi?.paginationSetPageSize(pageSize);
    }

    loadGroupValues = async (params: SetFilterValuesFuncParams) => {
        const items = await GroupService.getAllForFilter();
        params.success(items);
    }

    formatIsActive = (params: ValueFormatterParams) => {
        const value = params.value === 'true' ? 'Active' : 'Inactive';
        return value;
    }

    formatDate = (params: ValueFormatterParams) => {
        const value = params.value ? moment(params.value).format('MM/DD/YYYY h:mm A') : '';
        return value;
    }

    setStatus = (status: ApsModels.ActiveEnum) => {
        this.setState({ status: status });                                                                                                               
        this.state.datasource.status = status;
        this._gridApi?.onFilterChanged();
    }

    setGeneratedBy = (generatedBy: GroupSystemGeneratedEnum) => {
        this.setState({ generatedBy: generatedBy });                                                                                                               
        this.state.datasource.generatedBy = generatedBy;
        this._gridApi?.onFilterChanged();
    }

    startExport = async () => {        
        this.setState({ exportKey: commonService.getUniqueId() });
    };

    startExportAll = () => {
        this.setState({ exportAllKey: commonService.getUniqueId() });
    }

    render() {
        return (
            <div className="container-fluid h-full">
                <div className="row">
                    <div className="col">
                        <div className="page-title-box">
                            <h4 className="mb-0 font-size-18">GROUPS</h4>
                        </div>
                    </div>
                </div>
                <div className="row h-full">
                    <div className="col">
                        <div className="card h-full">
                            <div className="card-body h-full flex flex-col">
                                <div className="align-items-center justify-content-between flex-1">
                                    <div className="flex flex-row flex-wrap">
                                        <div className="flex-1 flex flex-row flex-center mb-3">
                                            <Dropdown drop="down" className="me-2">
                                                <Dropdown.Toggle
                                                    variant="outline-secondary"
                                                    id="dropdownExportMenu"
                                                >
                                                    Export
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={(e) => {
                                                            this.startExportAll();
                                                        }}
                                                    >
                                                        Export All Data
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={(e) => {
                                                            this.startExport();
                                                        }}
                                                    >
                                                        Export Visible Data
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>                                            
                                            <div className="me-2">
                                                <strong>View</strong>
                                            </div>
                                            <Dropdown drop="down" className="me-2">
                                                <Dropdown.Toggle variant="secondary" id="dropdownCourseViewFilter">                                                
                                                    <span className="me-1">{["All", "Active", "Inactive"][this.state.status]}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="left">
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.All}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.All)} >
                                                        All
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.Active}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.Active)} >
                                                        Active
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.Inactive}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.Inactive)} >
                                                        Inactive
                                                    </Dropdown.Item>                                                
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown drop="down">
                                                <Dropdown.Toggle variant="secondary" id="dropdownCourseViewFilter">                                                
                                                    <span className="me-1">{["All Groups", "System Groups", "Agency Groups"][this.state.generatedBy]}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="left">
                                                    <Dropdown.Item 
                                                        disabled={this.state.generatedBy === GroupSystemGeneratedEnum.All}
                                                        onClick={(e) => this.setGeneratedBy(GroupSystemGeneratedEnum.All)} >
                                                        All Groups
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.generatedBy === GroupSystemGeneratedEnum.UserCreated}
                                                        onClick={(e) => this.setGeneratedBy(GroupSystemGeneratedEnum.UserCreated)} >
                                                        Agency Groups
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.generatedBy === GroupSystemGeneratedEnum.SystemGenerated}
                                                        onClick={(e) => this.setGeneratedBy(GroupSystemGeneratedEnum.SystemGenerated)} >
                                                        System Groups
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <div className="mx-2">
                                                {/* <button type="button" className="btn btn-outline-danger" 
                                                    onClick={e => {
                                                        this.setState({ resetKey: commonService.getUniqueId() });
                                                    }}>
                                                    Reset
                                                </button> */}
                                            </div>                                            
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-primary no-wrap" onClick={this.onAdd}>
                                                Add New Group
                                            </button>      
                                        </div>                                                                                                                      
                                    </div>
                                </div>
                                <div className="ag-theme-alpine h-full flex-initial">
                                    <AgGridReact
                                        onGridReady={this.onGridReady}
                                        rowModelType="serverSide"
                                        pagination={false}
                                        paginationPageSize={10000}
                                        cacheBlockSize={10000}
                                        defaultColDef={{
                                            sortable: true,
                                            menuTabs: ['filterMenuTab'],
                                            filterParams: { buttons: ['reset'] },
                                            flex: 1,
                                        }}
                                        enableCellTextSelection={true}
                                        suppressCellSelection={true}
                                        suppressMultiRangeSelection={true}
                                        suppressRowDeselection={true}
                                        suppressRowClickSelection={true}
                                        //suppressContextMenu={true}
                                        context={this}
                                        frameworkComponents={{
                                            statusCellRenderer: StatusCellRenderer,
                                            actionCellRenderer: ActionCellRenderer,
                                        }}
                                        enableRangeSelection={true}
                                        rowSelection="multiple"
                                        {...this.state.gridFilterProps}
                                        getContextMenuItems={params => {
                                            return commonService.agGridHelpers.getContextMenuItems(params, "groups-table");
                                        }}
                                    >
                                        <AgGridColumn
                                            headerName=""
                                            field="_selected"
                                            checkboxSelection={true}
                                            headerCheckboxSelection={true}
                                            suppressMenu={true}
                                            width={25}
                                            menuTabs={[]}
                                            {...commonService.agGridHelpers.checkboxColDef}
                                        />
                                        <AgGridColumn
                                            headerName="Group"
                                            field="name"
                                            filter="agSetColumnFilter"
                                            filterParams={{ values: this.loadGroupValues }}
                                            minWidth={125}
                                            flex={1} />
                                        <AgGridColumn
                                            headerName="Last Modified"
                                            field="_lastModified"
                                            filter="agDateColumnFilter"
                                            filterParams={{ filterOptions: AgGridService.defaultDateFilterOptions }}
                                            valueFormatter={param => {
                                                return this.formatDate({
                                                    ...param,
                                                    value: param.data.lastModified
                                                });
                                            }}
                                            width={180} />
                                        <AgGridColumn
                                            headerName="Status"
                                            field="_isActive"
                                            //filter="agSetColumnFilter"
                                            //filterParams={{ values: [true, false], valueFormatter: this.formatIsActive }}
                                            suppressMenu={true}
                                            cellRenderer="statusCellRenderer"
                                            width={100} />
                                        <AgGridColumn
                                            headerName="Action"
                                            cellRenderer="actionCellRenderer"
                                            sortable={false}
                                            width={100}
                                            menuTabs={[]}
                                            {...commonService.agGridHelpers.actionColPinnedDef} />
                                    </AgGridReact>
                                </div>
                                {/* <AgGridPageSizeSelector onPageSizeChange={this.onPageSizeChange} /> */}
                                
                                <div className="pt-2">
                                    {commonService.toNumberWithComma(this.state.total, 0)} Record(s) Found
                                </div>

                                {this.state.gridEvent && <GridFilterRenderer 
                                        gridEvent={this.state.gridEvent}
                                        reset={this.state.resetKey}
                                        exportFlag={this.state.exportKey}
                                        exportAllFlag={this.state.exportAllKey}
                                        ready={props => {
                                            this.setState({ gridFilterProps: props });
                                }} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const GridFilterRenderer = ({ gridEvent, ready, reset, exportFlag, exportAllFlag } : 
    { gridEvent: GridReadyEvent, ready: (props: any)=> void, reset?: string, exportFlag?: string, exportAllFlag?: string }) => {
    const gridFilters = useGridFilters("groups-table", gridEvent.api, (other) => { });
    useEffect(() => {        
        ready(gridFilters.props);
    }, [])
    useEffect(() => {        
        if(gridFilters.ready) {
            gridFilters.init(gridEvent);
        }
    }, [gridFilters.ready])
    useEffect(() => {        
        if(reset) {
            gridFilters.reset();
        }
    }, [reset])
    useEffect(() => {        
        if(exportFlag) {
            gridFilters.exportCSV();
        }
    }, [exportFlag]);    
    useEffect(() => {        
        if(exportFlag) {
            gridFilters.exportCSV();
        }
    }, [exportFlag]);
    useEffect(() => {        
        if(exportAllFlag) {
            gridFilters.exportAllCSV();
        }
    }, [exportAllFlag]);    
    return <></>;
}