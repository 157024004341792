import ApsModels from "../../models";
import { ICustomIframeSettingsInputDto, ICustomIframeSettingsOutputDto } from "../../models/_all";
import fetcher from "./Fetcher";

class CustomIframeSettingService {
    save = async (data: ICustomIframeSettingsInputDto) => {
        const url = `/Api/CustomIframeSettings`;
        return await fetcher.post(url, data) as ICustomIframeSettingsOutputDto;
    };

    get = async (departmentId: number) => {
        const response = await fetch(`/Api/CustomIframeSettings/${departmentId}`);
        return await response.json() as ICustomIframeSettingsOutputDto;
    };
}

const customIframe = new CustomIframeSettingService();
export default customIframe;