import React, { Component } from "react";
import { Link } from "react-router-dom";
import {UserService} from '../../services/UserService';
import {AuthenticationService} from '../../services/AuthenticationService';
import {UserProfileDto, NewPasswordDto} from '../../data/User';
import { Modal, ModalBody, Card, CardBody, Row, Col} from 'reactstrap';
import Logo from '../../assets/images/aps.jpg';
import { MicrosoftAuthenticationButton } from "../../sso/microsoft/microsoft-authentication-component";
import {AccountInfo} from "@azure/msal-browser";

type Props = {}

type State = {
    userId: number | null,
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    validationErrors: ValidationErrors,
    showAlert: boolean,
    saveFail: boolean,
    resetPasswordSection: boolean,
    newPassword: string,
    confirmPassword: string,
    disablePasswordSubmit: boolean,
    isMicrosoftAccountLinked: boolean,
    isMicrosoftAccountLinkedError: boolean,
    isMicrosoftAccountUnlinkedError: boolean
}

type ValidationErrors = {
    firstName?: string,
    lastName?: string,
    phoneNumber?: string,
    newPassword?: string,
    confirmNewPassword?: string,

}
export class Profile extends Component<Props, State> {
    
    state: State = {
        userId: 0,
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        validationErrors: {},
        showAlert: false,
        saveFail: false,
        resetPasswordSection: false,
        newPassword: '',
        confirmPassword: '',
        disablePasswordSubmit: true,
        isMicrosoftAccountLinked: false,
        isMicrosoftAccountLinkedError: false,
        isMicrosoftAccountUnlinkedError: false
    }

    async componentDidMount() {
        let currentUser = await AuthenticationService.initialize();
        let user = await UserService.getUserProfile();
        if (user.userId) {
            this.setState({
                userId: user.userId,
                userName: user.userName,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber?? '',
                isMicrosoftAccountLinked: user.isMicrosoftAccountLinked
            });
        } else {
            this.setState({
                showAlert: true,
                saveFail: true,
            })
        }
    }

    handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            firstName: e.target.value,
        })
    }

    handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            lastName: e.target.value,
        })
    }
    
    handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            phoneNumber: e.target.value,
        })
    }

    handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            newPassword: e.target.value,
        })
    }

    handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            confirmPassword: e.target.value,
        })
        if (this.state.confirmPassword !== this.state.newPassword) {
            this.setState({
                disablePasswordSubmit: false
            })
        } else {
            this.setState({
                disablePasswordSubmit: true,
            })
        }
    }
    
    openResetPasswordModal = () => {
        this.setState({
            resetPasswordSection: true,
        })
    }

    submitNewPassword = async () => {
        if (this.validateNewPassword() === false) {
            return;
        }

        const newPassword: NewPasswordDto = {
            newPassword: this.state.newPassword,
            username: this.state.userName
        }

        try {
            await AuthenticationService.resetProfilePassword(newPassword);
            await UserService.sendPasswordUpdateConfirmation(this.state.userId!);
        } catch (e) {
            this.setState({ 
                saveFail: true,
            })
        }
        this.setState({showAlert: true});
        setTimeout(()=>{ 
            this.setState({
                showAlert: false,
                resetPasswordSection: false,
                disablePasswordSubmit: true,
            }); 
        }, 2000);
        return;
    }

    cancelPasswordReset = () => {
        this.setState({
            resetPasswordSection: false,
            newPassword: '',
            confirmPassword: '',
            disablePasswordSubmit: true,
        });
    }

    handleSubmit = async () => {
        if (this.validate() === false) {
            return;
        }

        const editUserProfile: UserProfileDto = {
            userId: this.state.userId!,
            userName: this.state.userName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber.trim() !== "" ? this.state.phoneNumber.trim() : null,
            isMicrosoftAccountLinked: this.state.isMicrosoftAccountLinked
        }

        try {
            await UserService.updateUserProfile(editUserProfile);
        } catch (e) {
            this.setState({ 
                saveFail: true,
            })
        }
        this.setState({showAlert: true});
        setTimeout(()=>{ this.setState({showAlert: false}); }, 2000);
        return;
    }

    validate() {
        let phoneRegex = /^\d{10}$/;
        var validationErrors: ValidationErrors = {};
        if (this.state.firstName.trim() === "") {
            validationErrors.firstName = "First Name is required.";
        }
        if (this.state.lastName.trim() === "") {
            validationErrors.lastName = "Last Name is required.";
        }
        if (this.state.phoneNumber.trim() !== "" && !phoneRegex.test(this.state.phoneNumber)) {      
            validationErrors.phoneNumber = "Please enter a valid phone number";
        }
        this.setState({validationErrors: validationErrors});
        return Object.entries(validationErrors).length === 0;
    }

    validateNewPassword() {
        var validationErrors: ValidationErrors = {};
        if (this.state.newPassword.length < 8) {
            validationErrors.newPassword = 'Your password must be at least 8 characters';
        }
        if (this.state.newPassword !== this.state.confirmPassword || this.state.newPassword.trim() === '') {
            validationErrors.confirmNewPassword = 'The passwords do not match';
        }
        this.setState({ validationErrors: validationErrors });
        return Object.entries(validationErrors).length === 0;
    }
    
    handleLinkMicrosoftAccount = async (userAccountInfo: AccountInfo | null, idToken: string | null) => {
        this.setState({
            isMicrosoftAccountLinkedError: false
        });
        const success = await AuthenticationService.linkMicrosoftAccount(idToken);
        if (success) {
            this.setState({
                isMicrosoftAccountLinked: true
            });
        } else {
            this.setState({
                isMicrosoftAccountLinkedError: true
            });
        }
    }
    
    handleUnlinkMicrosoftAccount = async () => {
        this.setState({
            isMicrosoftAccountUnlinkedError: false
        });
        const success = await AuthenticationService.unlinkMicrosoftAccount();
        if (success) {
            this.setState({
                isMicrosoftAccountLinked: true
            });
        } else {
            this.setState({
                isMicrosoftAccountUnlinkedError: true
            });
        }
    }

    render() {
        return (
            <div className="w-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-auto">
                            <div className="page-title-box">
                                <h1 className="mb-0 font-size-20">PROFILE</h1>
                            </div>
                        </div>
                        
                        <div className="col d-flex justify-content-end">
                            <div>
                                <Link to="/links"><button className="btn btn-secondary mr-2">Cancel and Close</button></Link>
                            </div>
                            <div>
                                <button className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </div>
                        <div className={`col-12 alert ${this.state.saveFail ? 'alert-danger' : 'alert-success'} ${this.state.showAlert ? 'd-block' : 'd-none'}`}>
                            {this.state.saveFail ? 'Something went wrong, please try again later' : 'Update Saved'}
                        </div>
                    </div>
                </div>
                
                <div className="row h-full">
                    <div className="col">
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="daily-operational-card card box-card">
                                    <div className="card-body">
                                        <h4 className="mb-4 font-size-16">1. Contact Information</h4>
                                        <div className="contact-input">
                                            <div className="mt-3"></div>
                                            <span>First Name</span>
                                            <div className="mt-2"></div>
                                            <input type="text"
                                                name="firstName"
                                                placeholder="First Name"
                                                className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.firstName ? 'is-invalid' : ''}`}
                                                value={this.state.firstName}
                                                onChange={this.handleFirstNameChange}
                                                required
                                            />
                                            {this.state.validationErrors.firstName && (
                                                <div className='invalid-feedback'>
                                                    {this.state.validationErrors.firstName}
                                                </div>
                                            )}
                                        </div>
                                        <div className="contact-input">
                                            <div className="mt-3"></div>
                                            <span>Last Name</span>
                                            <div className="mt-2"></div>
                                            <input type="text"
                                                name="lastName"
                                                placeholder="Last Name"
                                                className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.lastName ? 'is-invalid' : ''}`}
                                                value={this.state.lastName}
                                                onChange={this.handleLastNameChange}
                                                required
                                            />
                                            {this.state.validationErrors.lastName && (
                                                <div className='invalid-feedback'>
                                                    {this.state.validationErrors.lastName}
                                                </div>
                                            )}
                                        </div>
                                        <div className="contact-input">
                                            <div className="mt-3"></div>
                                            <span>Employee #</span>
                                            <div className="mt-2"></div>
                                            <input type="text"
                                                name="employeeNumber"
                                                placeholder="#"
                                                className="col-12 border rounded-10 h-8"
                                                disabled
                                            />
                                        </div>
                                        <div className="contact-input">
                                            <div className="mt-3"></div>
                                            <span>Email</span>
                                            <div className="mt-2"></div>
                                            <input type="text"
                                                name="email"
                                                placeholder="Email"
                                                className="col-12 border rounded-10 h-8"
                                                value={this.state.email}
                                                disabled
                                            />
                                        </div>
                                        <div className="contact-input">
                                            <div className="mt-3"></div>
                                            <span>Phone Number</span>
                                            <div className="mt-2"></div>
                                            <input type="text"
                                                name="phoneNumber"
                                                placeholder="Phone Number"
                                                className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.phoneNumber ? 'is-invalid' : ''}`}
                                                value={this.state.phoneNumber}
                                                onChange={this.handlePhoneNumberChange}
                                            />
                                            {this.state.validationErrors.phoneNumber && (
                                                <div className='invalid-feedback'>
                                                    {this.state.validationErrors.phoneNumber}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="daily-operational-card card box-card">
                                    <div className="card-body">
                                        <h4 className="mb-4 font-size-16">2. Access</h4>
                                        <div className="d-flex justify-content-around">
                                            <div>
                                                <button className="pass-reset-button" onClick={this.openResetPasswordModal}>
                                                    Reset Password
                                                </button>
                                            </div>
                                            {/*We're not removing the feature, but hiding it. Keep this commented
                                            so we don't reinvent the wheel when we bring it back in*/}
                                            {/* <div>
                                                {!this.state.isMicrosoftAccountLinked && (
                                                    <MicrosoftAuthenticationButton
                                                        isProfile={true}
                                                        onAuthenticated={this.handleLinkMicrosoftAccount}
                                                        loginButtonText={"Link Microsoft Account"}
                                                        logoutButtonText={"Unlink Microsoft Account"}
                                                    />
                                                )}
                                                {this.state.isMicrosoftAccountLinked && (
                                                    <div>Microsoft Account Linked</div>
                                                )}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Modal isOpen={this.state.resetPasswordSection}>
                    <ModalBody className="p-0">
                        <Card className="overflow-hidden m-0">
                            <div className="bg-soft-primary">
                                <Row>
                                    <Col className="col-12">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Reset Password</h5>
                                            <p className="text-p">Update your password with a minimum of 8 characters.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-0">
                                <div>
                                    <div className="avatar-md profile-user-wid mb-4">
                                        <span className="avatar-title rounded-circle bg-sidebar-dark">
                                            <img src={Logo} alt="" className="rounded-circle w-16" />
                                        </span>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="contact-input">
                                        <div className="mt-3"></div>
                                        <span>New Password</span>
                                        <div className="mt-2"></div>
                                        <input type="password"
                                            name="newPassword"
                                            placeholder="New Password"
                                            className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.newPassword ? 'is-invalid' : ''}`}
                                            onChange={this.handleNewPasswordChange} />
                                        {this.state.validationErrors.newPassword && (
                                            <div className='invalid-feedback'>
                                                {this.state.validationErrors.newPassword}
                                            </div>
                                        )}
                                    </div>
                                    <div className="contact-input">
                                        <div className="mt-3"></div>
                                        <span>Confirm New Password</span>
                                        <div className="mt-2"></div>
                                        <input type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            className={`col-12 border rounded-10 h-8 ${this.state.validationErrors.confirmNewPassword ? 'is-invalid' : ''}`}
                                            onChange={this.handleConfirmPasswordChange} />
                                        {this.state.validationErrors.confirmNewPassword && (
                                            <div className='invalid-feedback'>
                                                {this.state.validationErrors.confirmNewPassword}
                                            </div>
                                        )}
                                    </div>
                                    <button className={`btn btn-primary float-right mt-3 px-4 ${this.state.disablePasswordSubmit ? 'disableColor' : ''}`}
                                        disabled={this.state.disablePasswordSubmit}
                                        onClick={this.submitNewPassword}>Reset</button>
                                    <button className={`btn btn-danger float-right mt-3 mr-2 px-4 ${this.state.disablePasswordSubmit ? 'disableColor' : ''}`}
                                        onClick={this.cancelPasswordReset}>Cancel</button>
                                </div>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}