import React from 'react';
import { OptionDto } from '../../../data/Option';
import { UserDto } from '../../../data/User';
import { GroupService } from '../../../services/GroupService';
import { AnswerDto, AnswerUserReferenceDto, IsolatedInputDetails, QuestionDto, QuestionGroupDto } from '../../../data/DailyOperational';
import { OptionService } from '../../../services/OptionService';
import { IsolatedInput } from '../CustomInput/IsolatedInput';
import { UserService } from '../../../services/UserService';
import { OptionGroupDto } from '../../../data/OptionGroup';
import CommonSpinner from '../../../aps2/components/Common/CommonSpinner';

type Props = {
    questionGroup?: QuestionGroupDto,
    date: Date,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    optionGroups : OptionGroupDto[],
    moduleOrder: number,
    departmentId: number
}

type State = {
    wsaOptions: OptionDto[],
    userDto: UserDto[],
    ready: boolean
}

type IsolatedInputListProps = {
    defaultItemsCount: number,
    preventExpanding?: boolean,
    answerDto?: AnswerDto[],
    userDto?: UserDto[],
    optionsDto?: OptionDto[],
    userSelected?: UserDto,
    usersSelected?: UserDto[],
    question?: QuestionDto,
    setData?: (value: QuestionDto | undefined) => void,
    date: Date,
    left: boolean,
    separateNumber: number
}

type IsolatedInputListState = {
    items: IsolatedInputDetails[]
}

export class IsolatedInputListCallList extends React.Component<IsolatedInputListProps, IsolatedInputListState> {
    state = {
        items: new Array<IsolatedInputDetails>()
    }

    getUserId = async (user: AnswerUserReferenceDto | undefined): Promise<UserDto> => {
        return await UserService.getUser(user?.userId ?? 0)
    }
    componentDidMount = async () => {
        // this.setState({ userSelected: null })
        for (let i = 0; i < this.props.defaultItemsCount; i++) {
            const item: IsolatedInputDetails = ({ isFirst: i === 0, isLast: i === this.props.defaultItemsCount - 1 });
            this.setState(prevState => ({
                items: [...prevState.items, item]
            }));
        }
    }

    handleSectionInputChanged = (isLast: boolean): void => {
        if (this.props.preventExpanding) return;
        if (!isLast) return;

        const items = this.state.items;
        items[items.length - 1].isLast = false;
        items.push({ isFirst: false, isLast: true });

        this.setState({
            items: items,
        });
    }

    setData = (question: QuestionDto | undefined) => {
        if (this.props.setData) {
            this.props.setData(question)
        }
    }


    render() {
        return (
            this.state.items.map((item, index) => {
                return (
                    <IsolatedInput
                        key={index}
                        userDto={this.props.userDto}
                        optionsDto={this.props.optionsDto}
                        isFirst={item.isFirst}
                        isLast={item.isLast}
                        question={this.props.question}
                        answerDto={this.props.question?.answers?.find(x => x.order === (this.props.separateNumber * (index + 1)))}
                        onChange={this.handleSectionInputChanged}
                        setData={this.setData}
                        order={(this.props.separateNumber * (index + 1))}
                        date={this.props.date}
                    />
                )
            })
        )
    }
}


export class OnCallList extends React.Component<Props, State> {
    state: State = {
        wsaOptions: new Array<OptionDto>(),
        userDto: [],
        ready: false
    }


    componentDidMount = async () => {
        const allGroup = await GroupService.getAllGroups();
        const captainsGroup = allGroup.find(x => x.name === "Captains");
        const engineerGroup = allGroup.find(x => x.name === "Engineers");
        const fireFighterGroup = allGroup.find(x => x.name === "FireFighter");
        const groupIds = [captainsGroup?.groupId ?? 0, engineerGroup?.groupId ?? 0, fireFighterGroup?.groupId ?? 0];
        const users = await UserService.getUsersInMultipleGroupsByDepartmentId(groupIds, this.props.departmentId);
        this.setState({ userDto: users })
        const wsaOptions = await this.getOptions();
        this.setState({
            wsaOptions: wsaOptions,
            ready: true
        });
    }

    private async getOptions(): Promise<OptionDto[]> {
        const wsaOptionsGroup = this.props.optionGroups?.find(g => g.name === 'WSA Options');
        return wsaOptionsGroup ? await OptionService.getGroupOptions(wsaOptionsGroup.optionGroupId) : [];
    }

    getRowsNumber = (expected: number, actual: AnswerDto[] | undefined, left: boolean) => {
        let multiples = 0;
        if (actual?.length) {
            for (let i = 0; i < actual.length; i++) {
                if (actual[i].order % 1000 === 0) {
                    multiples++
                }
            }
            if (left) {
                if (expected <= (actual.length - multiples)) {
                    return (actual.length - multiples) + 1
                }
            } else {
                if (multiples <= actual.length) {
                    return multiples + 1
                }
            }
        }
        return expected
    }

    factorySubGroup = (value: QuestionDto | undefined) => {
        const questionGroup = this.props.questionGroup
        const index = this.props.questionGroup?.questions.findIndex(x => x.order === value?.order)
        if (index !== undefined) {
            if (questionGroup?.questions[index] && value) {
                questionGroup.questions[index] = value
            }
        }
        this.props.setData(questionGroup, this.props.moduleOrder)
    }
    render() {
        return (
            <div className="grid-item card box-card flex-grow-1">
                {!this.state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
                <div className="card-body">
                    <h4 className="mb-0 font-size-16">{this.props.questionGroup?.name || ''}</h4>
                    <div className="row mt-4">
                        <div className="response-first-column col-lg-6">
                            {this.props.questionGroup?.questions.find(x => x.order === 0)?.answers && <IsolatedInputListCallList preventExpanding={false} separateNumber={1} left={true} setData={this.factorySubGroup} defaultItemsCount={this.getRowsNumber(2, this.props.questionGroup?.questions.find(x => x.order === 0)?.answers, true)} answerDto={this.props.questionGroup?.questions.find(x => x.order === 0)?.answers} userDto={this.state.userDto} question={this.props.questionGroup?.questions.find(x => x.order === 0)} date={this.props.date} />}
                        </div>
                        <div className="response-first-column col-lg-6">
                            {this.props.questionGroup?.questions.find(x => x.order === 0)?.answers && <IsolatedInputListCallList preventExpanding={false} separateNumber={1000} left={true} setData={this.factorySubGroup} defaultItemsCount={this.getRowsNumber(2, this.props.questionGroup?.questions.find(x => x.order === 0)?.answers, false)} answerDto={this.props.questionGroup?.questions.find(x => x.order === 0)?.answers} userDto={this.state.userDto} question={this.props.questionGroup?.questions.find(x => x.order === 0)} date={this.props.date} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
