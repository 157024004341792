import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { AnswerDto, AnswerType, IsolatedSelectItem, QuestionDto, QuestionGroupDto } from '../../../data/DailyOperational';
import { UserDto } from '../../../data/User';
import { GroupService } from '../../../services/GroupService';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { RichEditor } from "../CustomInput/RichEditor"
import { AppContext } from "../../../AppContext";
import { IsolatedSelect } from '../CustomInput/IsolatedSelect';
import './Sections.scss';
import { UserService } from "../../../services/UserService";

type Props = {
    questionGroup?: QuestionGroupDto
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    date: Date,
    moduleOrder: number,
    departmentId: number,
    isVisibleShiftInvestigator: boolean 
}

type State = {
    users: UserDto[] | null,
    selectedUserId: number | null
}

export class ShiftActivity extends React.Component<Props, State>{
    static contextType = AppContext;
    private readonly shiftInvestigatorGroupName: string = 'Shift Investigator';

    state: State = {
        users: null,
        selectedUserId: null
    }

    private static getAnswerUserId(questions: QuestionDto[] | undefined) {
        const shiftInvestigatorOrder: number = 0;
        return questions && questions.find(x => x.order === shiftInvestigatorOrder)?.answers.find(y => y.order === 0)?.user?.userId;
    }


    componentDidMount = async () => {
        const allGroup = await GroupService.getAllGroups();
        const group = allGroup.find(x => x.name === this.shiftInvestigatorGroupName);
        if (group) {
            const users = await UserService.getUsersInGroupByDepartmentId(group.groupId, this.props.departmentId);
            this.setState({
                users: users
            });
        }
    }

    updateQuestionGroup(newQuestionValue: QuestionDto) {
        const questionGroup = cloneDeep(this.props.questionGroup)!;
        const questionIndex = questionGroup.questions.findIndex(q => q.order == newQuestionValue.order);
        questionGroup.questions[questionIndex] = newQuestionValue;
        this.props.setData(questionGroup, this.props.moduleOrder);
    }

    private handleShiftInvestigatorChanged = (userId?: number, userName?: string) => {
        const questionOrder = 0;
        const question: QuestionDto = cloneDeep(this.props.questionGroup!.questions.find(q => q.order == questionOrder)!);
        const isAnswerExists = question.answers.length > 0;
        const answerId: number = question!.answers[0] ? question!.answers[0].answerId! : 0;

        let answer: AnswerDto;

        if (isAnswerExists || answerId > 0) {
            answer = question.answers.find(a => a.answerId == answerId)!;
            answer.user!.userId = userId;
            answer.user!.userName = userName;
            answer.date = this.props.date;
            answer.dateCreated = this.props.date;
            answer.dateUpdated = this.props.date;
        } else {
            answer = this.createNewAnswer(answerId, question, userId, userName)
            question.answers.push(answer);
        }
        this.updateQuestionGroup(question);
    }

    private createNewAnswer(answerId: number, question: QuestionDto, userId?: number, userName?: string): AnswerDto {
        return {
            answerId: answerId,
            answerType: AnswerType.User,
            date: this.props.date,
            dateCreated: this.props.date,
            dateUpdated: this.props.date,
            flagAnswerValue: false,
            order: 0,
            questionId: question.questionId ?? 0,
            user: {userId: userId, userName: userName, answerId: 0}
        };
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.date !== this.props.date) {
            this.setState({ selectedUserId: null })
        }
    }

    private static getAnswerUserName(questions: QuestionDto[] | undefined): string | undefined {
        const operationsBC: number = 0;
        return questions && questions.find(x => x.order === operationsBC)?.answers.find(y => y.order === 0)?.user?.userName;
    }

    render() {
        const userId: number | undefined = ShiftActivity.getAnswerUserId(this.props.questionGroup?.questions);
        const userName: string | undefined = ShiftActivity.getAnswerUserName(this.props.questionGroup?.questions);
        const items: IsolatedSelectItem[] = this.state.users ? this.state.users!.map(u => { return { id: u.userId, name: `${u.firstName} ${u.lastName}` } }) : [];
        return (
            <div className="daily-operational-card card box-card">
                <div className="card-body">
                    <div className="row m-1">
                        <div className="col-8 col-xl-8">
                            <h4 className="mb-0 font-size-16">{this.props.questionGroup?.name || ''}</h4>
                        </div> 
                        {this.props.isVisibleShiftInvestigator ? <div className="col-4 col-xl-4">
                            <div className="row align-items-center shift-investigator-select">
                                <div className="col-md-6 font-weight-bold">
                                    <div className="float-right align-items-center">{this.props.questionGroup?.questions.find(x => x.order === 0)?.questionText}</div>
                                </div>
                                <IsolatedSelect
                                    onChange={this.handleShiftInvestigatorChanged}
                                    availableOptions={items}
                                    id={userId}
                                    name={userName}
                                    showDefaultOptionName={true}
                                    className="col-xl-6 border rounded-10 h-8 form-control" />
                            </div>
                        </div> : null}
     
                    </div>
                    <RichEditor isEditable={!this.context.isEditableDailyOperational} setData={this.props.setData} questionGroup={this.props.questionGroup} date={this.props.date} moduleOrder={this.props.moduleOrder}
                                departmentId={this.props.departmentId}/>
                </div>
            </div >
        )
    }
}
