import React from 'react';

type AppContextValue = {
    isAuthenticated: boolean,
    module: number,
    hasAccessUserConsole: boolean,
    hasAccessDailyOperational: boolean,
    isVisibleDailyOperational: boolean,
    isVisibleUserConsole: boolean,
    isEditableDailyOperational: boolean,
    isAdmin: boolean,
    isAccountAdmin: boolean
};

export const AppContext = React.createContext<AppContextValue>({ isAuthenticated: false, module: 1, hasAccessUserConsole: false, hasAccessDailyOperational: false, isEditableDailyOperational: false, isVisibleDailyOperational: false, isVisibleUserConsole: false, isAdmin: false, isAccountAdmin: false });
