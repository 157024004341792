import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class DailyConsoleTemplateService {
  getTemplate = async () => {
    const url = "/api/DailyConsoleTemplate/GetTemplate";
    return fetcher.get<ApsModels.IDailyOperationalConsoleTemplateOutputDto>(
      url
    );
  };

  getTemplateAdmin = async (departmentId: number) => {
    const url = `/api/DailyConsoleTemplate/GetTemplateAdmin/${departmentId}`;
    return fetcher.get<ApsModels.IDailyOperationalConsoleTemplateOutputDto>(
      url
    );
  };

  saveTemplate = async (
    data: ApsModels.IDailyOperationalConsoleTemplateInputDto
  ) => {
    const url = "/api/DailyConsoleTemplate/SaveTemplate";
    return fetcher.post<any>(url, data);
  };
}

const dailyConsoleTemplate = new DailyConsoleTemplateService();
export default dailyConsoleTemplate;
