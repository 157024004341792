import React, { Component } from "react";
import moment from "moment";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { AuthenticationService } from "../../services/AuthenticationService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../assets/images/aps.jpg';

type Props = {}

type State = {
    username: string,
    isSubmitting: boolean,
    isSubmitted: boolean,
}

export class ForgotPassword extends Component<Props, State> {
    static displayName = ForgotPassword.name;

    state: State = {
        username: "",
        isSubmitting: false,
        isSubmitted: false,
    };

    handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ username: e.target.value });
    }

    handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState({ isSubmitting: true });
        await AuthenticationService.forgotPassword(this.state.username);
        this.setState({ isSubmitting: false });
        this.setState({ isSubmitted: true });
    }

    render() {
        return (
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-soft-primary">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Forgot Password</h5>
                                                <p className="text-p">Enter your email and instructions will be sent.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-sidebar-dark">
                                                <img src={Logo} alt="" className="rounded-circle w-16" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        {!this.state.isSubmitted && (
                                            <form onSubmit={this.handleSubmit} className="form-horizontal">

                                                <div className="form-group text-secondary">
                                                    <label>Email</label>
                                                    <input
                                                        name="email"
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        onChange={this.handleUsernameChange}
                                                        required
                                                    />
                                                </div>

                                                <div className="mt-3">
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                        disabled={this.state.isSubmitting}
                                                    >
                                                        {this.state.isSubmitting && (
                                                            <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" />
                                                        )}
                                                        Send Instructions
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                        {this.state.isSubmitted && (
                                            <Alert color="success">
                                                If what you entered matches our records, we'll send you an email soon with instructions to reset your password.
                                            </Alert>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center text-secondary">
                                <p>
                                    Go back to <Link to="/account/login" className="font-medium text-primary">Login</Link>
                                </p>
                                <p>
                                    &copy; {moment().year().toString()} APS.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}