import fetcher from "./Fetcher";
import ApsModels from "../../models";

class UserService {
  importUsers = async (formData: FormData) => {
    const url = "/Api/User/BulkUpload";
    return fetcher.postFormData<ApsModels.ICredTakenCourseOutputDto>(
      url,
      formData
    );
  };

  exportUsers = async () => {
    const url = `/api/UsersDataBatch/UsersExport`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };
}

const user = new UserService();
export default user;