import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSWildlandLocationService {
  get = async (id: any) => {
    const url = `/api/NfirsWildlandLocation/${id}`;
    return fetcher.get<ApsModels.INfirsWildlandLocationOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsWildlandLocationInputDto) => {
    const url = `/api/NfirsWildlandLocation`;
    return fetcher.post<ApsModels.INfirsWildlandLocationOutputDto>(
      url,
      data
    );
  };
}

const nfirsWildlandLocation = new NFIRSWildlandLocationService();
export default nfirsWildlandLocation;
