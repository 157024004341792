import { DepartmentService } from "../services/DepartmentService";
import commonService from '../aps2/services/CommonService';
import { LINK_TO_BREADCRUMB } from './Constants'

type Metadata = {
    title: string;
    metaDescription: string;
}

// modified from https://stackoverflow.com/a/58917312
export function seo(data: Metadata) {
    document.title = data.title;
    let metatag = document.querySelector('meta[name="description"]');
    if (metatag) {
        metatag.setAttribute('content', data.metaDescription);
    }  
}

export async function updateBreadcrumb() {
    try {
        var description;
        var department = await DepartmentService.getDepartmentById(commonService.currentDepartment);
        var path = window.location.pathname.split("/");
    } catch (e) {
        defaultBreadcrumb();
        return;
    }
    
    if (path.length < 2) {
        defaultBreadcrumb();
    } else {
        if (path[1] === "Admin" || path[1] === "Account") {
            description = LINK_TO_BREADCRUMB[path[1]][path[2]];
            if (description) {
                seo({ title: "APS | " + description, metaDescription: description});
            } else {
                defaultBreadcrumb();
            }
        } else {
            description = LINK_TO_BREADCRUMB[path[1]];
            if (description) {
                seo({ title: "APS | " + department.name + " - " + description, metaDescription: description});
            } else{
                defaultBreadcrumb();
            }
        }
    }
}

function defaultBreadcrumb() {
    seo({ title: "APS", metaDescription: "APS"});
}