import React from 'react';
import {
    ReferenceDetails,
    StrikeTeamSelectionType,
    StrikeTeamsIsolatedSelectItem
} from '../../../data/DailyOperational';
import {OptionDto} from '../../../data/Option';
import {AppContext} from "../../../AppContext";
import {UserDto} from '../../../data/User';
import {StrikeTeamsReferenceSelect} from "./StrikeTeamsReferenceSelect";

type Props = {
    key: number,
    isFirst: boolean,
    isLast: boolean,
    onChange: (optionDetails: ReferenceDetails, isLast: boolean) => void,
    availableOptions: OptionDto[],
    reference: ReferenceDetails | undefined,
    order: number,
    sten: UserDto[] | undefined,
    stenT: UserDto[] | undefined
}

type State = {}

export class IsolatedReferenceSelect extends React.Component<Props, State> {
    static contextType = AppContext;
    state: State = {}

    getClassName(): string {
        if (this.props.isFirst) {
            return 'rounded-top';
        }

        if (this.props.isLast) {
            return 'rounded-bottom';
        }

        return '';
    }

    getFullClassName(): string {
        return `col-12 border rounded-10 h-8 ${this.getClassName()}`;
    }

    private getItemId(id: number | null, type: StrikeTeamSelectionType): string {
        if (id === null) return '';
        return `${id}-${Number(type)}`;
    }

    private getSelectedItemId(): string | undefined {
        if (this.props.reference?.optionId) {
            return this.getItemId(this.props.reference?.optionId, StrikeTeamSelectionType.Option);
        }

        if (this.props.reference?.userId) {
            return this.getItemId(this.props.reference?.userId, StrikeTeamSelectionType.User);
        }

        return undefined;
    }

    handleInputChanged = (itemId?: string, itemName?: string): void => {
        let newOption: ReferenceDetails;

        if (itemId !== undefined) {
            const [id, type] = itemId.split('-').map(Number);
            newOption = {optionName: itemName, order: this.props.order};
            if (type === StrikeTeamSelectionType.Option) {
                newOption.optionId = id;
            } else {
                newOption.userId = id;
            }
        } else {
            newOption = {optionName: itemName, order: this.props.order};
        }

        this.props.onChange(newOption, this.props.isLast);
    }

    render() {
        const items: StrikeTeamsIsolatedSelectItem[] = this.props.availableOptions ? this.props.availableOptions?.map(o => { return { id: this.getItemId(o.optionId, StrikeTeamSelectionType.Option), name: o.name } }) : [];

        if (this.props.order == 1) {
            this.props.sten?.map(o => items.push({ id: this.getItemId(o.userId, StrikeTeamSelectionType.User), name: `${o.firstName} ${o.lastName}` }))
        }
        if (this.props.order == 2) {
            this.props.stenT?.map(o => items.push({ id: this.getItemId(o.userId, StrikeTeamSelectionType.User), name: `${o.firstName} ${o.lastName}` }))
        }

        const optionName: string | undefined = this.props.reference?.optionName;
        return (
            <StrikeTeamsReferenceSelect onChange={this.handleInputChanged} availableOptions={items} className={this.getFullClassName()} id={this.getSelectedItemId()} name={optionName} />
        )
    }
}
