import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";

function NFIRSWildlandLocations(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsWildlandLocation.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsWildlandLocationInputDto>({
    objectName: "Location",
    nfirsSectionTab: "Wildland Fire/Location",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsWildlandLocationInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsWildlandLocation
      .get(props.id)
      .then((data) => {
        const mdl = { ...model.new, ...data, reportId: Number(props.id) };
        initModels(mdl);
        setValuesFromModel(data);
        setProgress({ loading: false });
        props.onChange(mdl);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Location", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Wildland Fire",
    "Location",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Wildland Fire", "Location", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const subSections = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsLocationSubsectionEntries,
    "Subsections"
  );

  const areaTypes = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsAreaTypeEntries,
    "Area Types"
  );

  const meridians = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsMeridianEntries,
    "Meridians"
  );

  useEffect(() => {
    setProgress({ loading: true });
    subSections.getData();
    meridians.getData();
    areaTypes.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      subSections.status === NfirsFetchStatus.Complete &&
      meridians.status === NfirsFetchStatus.Complete &&
      areaTypes.status === NfirsFetchStatus.Complete
    ) {
      setProgress({ loading: true });
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subSections.status, meridians.status, areaTypes.status]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsAreaTypeEntryId: {
      displayName: "Area Type",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    latitude: {
      displayName: "Latitude",
      validation: {
        required: false,
        max: 99999.99,
        validate: {
          twoDecimalsOnly: commonService.validations.twoDecimalsOnly,
        },
      },
    },
    longitude: {
      displayName: "Longitude",
      validation: {
        required: false,
        max: 99999.99,
        validate: {
          twoDecimalsOnly: commonService.validations.twoDecimalsOnly,
        },
      },
    },
    township: {
      displayName: "Township",
      validation: {
        required: false,
        max: 999.9,
        validate: {
          oneDecimalsOnly: commonService.validations.oneDecimalsOnly,
        },
      },
    },
    isTownshipNorth: {
      displayName: "Township Direction",
      validation: {
        required: false,
      },
    },
    range: {
      displayName: "Range",
      validation: {
        required: false,
        max: 999,
        validate: { numberOnly: commonService.validations.numberOnly },
      },
    },
    isRangeEast: {
      displayName: "Range Direction",
      validation: {
        required: false,
      },
    },
    section: {
      displayName: "Section",
      validation: {
        required: false,
        maxLength: 2,
        validate: { numberOnly: commonService.validations.numberOnly },
      },
    },
    nfirsLocationSubsectionEntryId: {
      displayName: "Subsection",
      validation: {
        required: false,
      },
    },
    nfirsMeridianEntryId: {
      displayName: "Meridian",
      validation: {
        required: false,
      },
    },
  });

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 mb-2">
          <strong>Alternate Location Specification</strong>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="row mt-2">
            <div className="col-sm-6">
              <FgInput
                id="latitude"
                label="Latitude"
                placeHolder="Latitude"
                registeredField={registry.latitude}
                formState={formState}
                type="number"
                step="0.01"
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    latitude: Number(data) || (undefined as any),
                  })
                }
              ></FgInput>
            </div>
            <div className="col-sm-6">
              <FgInput
                id="longitude"
                label="Longitude"
                placeHolder="Longitude"
                registeredField={registry.longitude}
                formState={formState}
                type="number"
                step="0.01"
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    longitude: Number(data) || (undefined as any),
                  })
                }
              ></FgInput>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div>
            <label>OR</label>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="row mt-2">
            <div className="col-sm-6">
              <FgInput
                id="township"
                label="Township"
                placeHolder="Township"
                registeredField={registry.township}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    township: Number(data) || (undefined as any),
                  })
                }
                type="number"
                step="0.1"
              ></FgInput>
            </div>
            <div className="col-sm-6">
              <FgSelect
                id="isTownshipNorth"
                label="Township Direction"
                selectMessage="- Select Township Direction -"
                registeredField={registry.isTownshipNorth}
                formState={formState}
                options={[
                  { label: "North (N)", value: "true" },
                  { label: "South (S)", value: "false" },
                ]}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    isTownshipNorth: `${data}` === "true",
                  })
                }
              ></FgSelect>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-lg-6">
          <div className="row mt-2">
            <div className="col-sm-6">
              <FgInput
                id="range"
                label="Range"
                placeHolder="Range"
                registeredField={registry.range}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    range: Number(data) || (undefined as any),
                  })
                }
              ></FgInput>
            </div>
            <div className="col-sm-6">
              <FgSelect
                id="isRangeEast"
                label="Range Direction"
                selectMessage="- Select Range Direction -"
                registeredField={registry.isRangeEast}
                formState={formState}
                options={[
                  { label: "East (E)", value: "true" },
                  { label: "West (W)", value: "false" },
                ]}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    isRangeEast: `${data}` === "true",
                  })
                }
              ></FgSelect>
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="row mt-2">
            <div className="col-sm-4 col-md-3">
              <FgInput
                id="section"
                label="Section"
                placeHolder="Section"
                registeredField={registry.section}
                formState={formState}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    section: data,
                  })
                }
              ></FgInput>
            </div>
            <div className="col-sm-4 col-md-3">
              <FgSelect
                id="nfirsLocationSubsectionEntryId"
                label="Subsection"
                selectMessage="- Select Subsection -"
                registeredField={registry.nfirsLocationSubsectionEntryId}
                formState={formState}
                options={(subSections?.data || []).map((item) => {
                  return {
                    label: item.description,
                    value: item.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsLocationSubsectionEntryId:
                      Number(data) || (undefined as any),
                  } as any)
                }
              ></FgSelect>
            </div>
            <div className="col-sm-4 col-md-3">
              <FgSelect
                id="nfirsMeridianEntryId"
                label="Meridian"
                selectMessage="- Select Meridian -"
                registeredField={registry.nfirsMeridianEntryId}
                formState={formState}
                options={(meridians?.data || []).map((item) => {
                  return {
                    label: `${item.externalId}. ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsMeridianEntryId: Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="row mt-2">
            <div className="col-sm-12 col-lg-6">
              <FgSelect
                id="nfirsAreaTypeEntryId"
                label="Area Type"
                selectMessage="- Select Area Type -"
                registeredField={registry.nfirsAreaTypeEntryId}
                formState={formState}
                showRequiredLabel={isOtherModuleDataRequired}
                options={(areaTypes?.data || []).map((item) => {
                  return {
                    label: `${item.externalId}. ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsAreaTypeEntryId: Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSWildlandLocations;
