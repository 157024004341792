export enum ShiftColor {
    Red,
    Blue,
    Green
}

export interface ShiftDetails {
    YesterdayColor: ShiftColor,
    TodayColor: ShiftColor,
    TomorrowColor: ShiftColor
}
