class FetchHelper {
  private _promiseCache: { [key: string]: Promise<any> } = {};

  /**
   * Use this to avoid multiple GET request to same API
   * @param url
   * @param options
   * @returns
   */
  fetchUseExisting = async <T>(url: string, options?: RequestInit) => {
    const matchFound = !!this._promiseCache[url];
    const promise = matchFound
      ? Promise.resolve(this._promiseCache[url])
      : fetch(url, options).then(
          (rtn) => {
            setTimeout(() => {
              delete this._promiseCache[url];
            }, 5000);
            return rtn;
          },
          (err) => {
            delete this._promiseCache[url];
            throw err;
          }
        );
    this._promiseCache[url] = promise; //Comment this line to disable this feature
    const response = await promise;
    const value = (await response.clone().json()) as T;
    return value;
  };
}

const fetchHelper = new FetchHelper();
export default fetchHelper;
