import ApsModels from "../../models";
import fetcher from "./Fetcher";

class DrillTemplate {
  save = async (data: ApsModels.IDrillTemplateInputDto) => {
    const url = "/api/DrillTemplate/Save";
    return fetcher.post<ApsModels.IDrillTemplateOutputDto>(url, data);
  };

  getForSubmission = async () => {
    const url = `/api/DrillTemplate/GetForSubmission`;
    return fetcher.get<ApsModels.IDrillTemplateOutputDto>(url);
  };

  get = async (deptId: number) => {
    const url = `/api/DrillTemplate/${deptId}`;
    return fetcher.get<ApsModels.IDrillTemplateOutputDto>(url);
  };
}

const drillTemplate = new DrillTemplate();
export default drillTemplate;
