class FileDownloadService {
    public downloadFile = (data: Blob, filename: string, type: string) => {
        let a = document.createElement("a");
        const url = URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

const fileDownloadService = new FileDownloadService();
export { fileDownloadService as FileDownloadService }
