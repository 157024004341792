import React from "react";
import { useEffect } from "react";
import ApsModels from "../../models";
import { IBasicDialogProps } from "../Common/FormGroups";
import FormModal from "../Common/FormModal";
import { useNfirsLookupEnum } from "./NFIRSHelper";

function NFIRSAllValidationDialog(
  props: IBasicDialogProps<{
    reportId: number;
    validations: ApsModels.INfirsValidationOutputDto;
    tabNames: ApsModels.ILookupIntDto[];
    selected?: {
      tab: ApsModels.NfirsValidationTabsEnum;
      section: ApsModels.NfirsValidationModulesEnum;
    };
  }>
) {
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const moduleNames = useNfirsLookupEnum(
    "NfirsValidationModulesEnum",
    "Module Names"
  );

  useEffect(() => {
    moduleNames.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FormModal
        title="Oops, you're not quite done!"
        isOpen={true}
        close={() => close()}
        submit={() => close()}
        size="md"
        submitButtonLabel="Done"
        primaryButtonOnly={true}
      >
        <div>
          <label>
            The following sections are missing required information.
          </label>
          <div>
            <i>
              This report cannot be locked without completing the required
              fields for the following reports.
            </i>
            <div className="pb-3"></div>

            {props.data?.validations?.nfirsVerificationModuleOutputDtos
              ?.filter(
                (dto) =>
                  !dto.valid &&
                  dto.nfirsVerificationTabOutputDtos?.find((t) => !t.valid)
              )
              .map((dto) => (
                <div key={dto.nfirsValidationModulesEnum}>
                  {/* <div>
                    <label>
                      {
                        moduleNames?.data?.find(
                          (m) => m.value === dto.nfirsValidationModulesEnum
                        )?.label
                      }
                    </label>
                  </div> */}
                  {dto.nfirsVerificationTabOutputDtos
                    .filter((t) => !t.valid)
                    .map((t) => {
                      const tabName = props.data?.tabNames?.find(
                        (n) => n.value === t.nfirsValidationTabsEnum
                      )?.label;
                      const modName = moduleNames?.data?.find(
                        (m) => m.value === dto.nfirsValidationModulesEnum
                      )?.label;
                      return (
                        <div key={t.nfirsValidationTabsEnum}>
                          <button
                            className="btn-lg-rounded"
                            onClick={() => {
                              if (props.onChange) {
                                props.onChange({
                                  ...(props.data || ({} as any)),
                                  selected: {
                                    tab: t.nfirsValidationTabsEnum,
                                    section: dto.nfirsValidationModulesEnum,
                                  },
                                });
                              }
                            }}
                          >
                            {modName === "Apparatus Personnel"
                              ? tabName?.replace(
                                  "Apparatus",
                                  `${"Apparatus"} - `
                                )
                              : tabName?.replace(
                                  modName || "",
                                  `${modName || ""} - `
                                )}
                          </button>
                        </div>
                      );
                    })}
                </div>
              ))}
          </div>
        </div>
      </FormModal>
    </div>
  );
}

export default NFIRSAllValidationDialog;
