import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import {
  AnswerDto,
  AnswerType,
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";

interface IGenericCheckboxProps {
  questionGroup?: QuestionGroupDto;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  date: Date;
  moduleOrder: number;
}

interface IGenericCheckboxState {
  question1: boolean | undefined;
  question2: boolean | undefined;
  question3: boolean | undefined;
}

function GenericCheckbox(props: IGenericCheckboxProps) {
  const context = useContext(AppContext);
  const [state, setState] = useState<Partial<IGenericCheckboxState>>({
    question1: false,
    question2: false,
    question3: false,
  });

  useEffect(() => {
    if (props.questionGroup) {
      setState({
        question1: props.questionGroup?.questions
          .find((x) => x.order === 0)
          ?.answers.find((x) => x.order === 0)?.flagAnswerValue,
        question2: props.questionGroup?.questions
          .find((x) => x.order === 1)
          ?.answers.find((x) => x.order === 0)?.flagAnswerValue,
        question3: props.questionGroup?.questions
          .find((x) => x.order === 2)
          ?.answers.find((x) => x.order === 0)?.flagAnswerValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPropsValue = (propName: string) => {
    return ((props || ({} as any)) as { [key: string]: any })[propName];
  };

  const updateQuestionGroup = (newQuestionValue: QuestionDto) => {
    const questionGroup = cloneDeep(props.questionGroup)!;
    const questionIndex = questionGroup.questions.findIndex(
      (q) => q.order == newQuestionValue.order
    );
    questionGroup.questions[questionIndex] = newQuestionValue;
    props.setData(questionGroup, props.moduleOrder);
  };

  const handleCheckboxChanged = (val: boolean, questionOrder: number): void => {
    const obj: { [key: string]: boolean } = {};
    obj[`question${questionOrder + 1}`] = val || false;
    const newState = {
      ...state,
      ...obj,
    };
    setState(newState);

    const question: QuestionDto = {
      ...props.questionGroup!.questions.find((q) => q.order == questionOrder)!,
    };
    const isAnswerExists = question.answers.length > 0;
    const answerId: number = question!.answers[0]
      ? question!.answers[0].answerId!
      : 0;

    let answer: AnswerDto;

    if (isAnswerExists || answerId > 0) {
      answer = question.answers.find((a) => a.answerId == answerId)!;
      answer.flagAnswerValue = obj[`question${questionOrder + 1}`];
      answer.date = props.date;
      answer.dateCreated = props.date;
      answer.dateUpdated = props.date;
    } else {
      answer = {
        answerId: answerId,
        answerType: AnswerType.Flag,
        date: props.date,
        dateCreated: props.date,
        dateUpdated: props.date,
        flagAnswerValue: obj[`question${questionOrder + 1}`],
        order: 0,
        questionId: question.questionId ?? 0,
      };
      question.answers.push(answer);
    }
    updateQuestionGroup(question);
  };

  return (
    <div className="daily-operational-card card box-card">
      <div className="card-body ">
        <h4 className="mb-0 font-size-16">{props.questionGroup?.name || ""}</h4>
        <div className="d-flex">
          <div className="mt-4">
            <div
              className={`form-check form-check-inline ${getPropsValue("hideQuestion0") ? "display-none" : ""
                }`}
            >
              <input
                disabled={!context.isEditableDailyOperational}
                className="form-check-input"
                type="checkbox"
                id={`inlineCheckbox1-${props.questionGroup?.questionGroupId}`}
                onChange={(e) => {
                  handleCheckboxChanged(e.target.checked, 0);
                }}
                checked={state.question1}
              />
              <label
                className="form-check-label"
                htmlFor={`inlineCheckbox1-${props.questionGroup?.questionGroupId}`}
              >
                {
                  props.questionGroup?.questions.find((x) => x.order === 0)
                    ?.questionText
                }
              </label>
            </div>
            <div
              className={`form-check form-check-inline ${getPropsValue("hideQuestion1") ? "display-none" : ""
                }`}
            >
              <input
                disabled={!context.isEditableDailyOperational}
                className="form-check-input"
                type="checkbox"
                id={`inlineCheckbox2-${props.questionGroup?.questionGroupId}`}
                onChange={(e) => {
                  handleCheckboxChanged(e.target.checked, 1);
                }}
                checked={state.question2}
              />
              <label
                className="form-check-label"
                htmlFor={`inlineCheckbox2-${props.questionGroup?.questionGroupId}`}
              >
                {
                  props.questionGroup?.questions.find((x) => x.order === 1)
                    ?.questionText
                }
              </label>
            </div>
            <div
              className={`form-check form-check-inline ${getPropsValue("hideQuestion2") ? "display-none" : ""
                }`}
            >
              <input
                disabled={!context.isEditableDailyOperational}
                className="form-check-input"
                type="checkbox"
                id={`inlineCheckbox3-${props.questionGroup?.questionGroupId}`}
                onChange={(e) => {
                  handleCheckboxChanged(e.target.checked, 2);
                }}
                checked={state.question3}
              />
              <label
                className="form-check-label"
                htmlFor={`inlineCheckbox3-${props.questionGroup?.questionGroupId}`}
              >
                {
                  props.questionGroup?.questions.find((x) => x.order === 2)
                    ?.questionText
                }
              </label>
            </div>
          </div>
        </div>
        <div className="mt-2 flex gap-5">
          {[state.question1, state.question2, state.question3].map(
            (answer, i) => (
              <React.Fragment key={i}>
                {answer &&
                  !getPropsValue(`hideQuestion${i}`) &&
                  getPropsValue(`image${i}`) && (
                    <div>
                      <img
                        className="max-h-14"
                        src={getPropsValue(`image${i}`)}
                        alt="Image"
                      />
                    </div>
                  )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
      <div className="d-none card-body childsection1">
        <div className="d-flex">
          <h4 className="mb-0 font-size-22"> <b>{props.questionGroup?.name || ""} :</b> </h4>

          <div className="mt-1 flex gap-5">
            {[state.question1, state.question2, state.question3].map(
              (answer, i) => (
                <React.Fragment key={i}>
                  {answer &&
                    !getPropsValue(`hideQuestion${i}`) &&
                    getPropsValue(`image${i}`) && (
                      <div>
                        <img
                          className="max-h-8"
                          src={getPropsValue(`image${i}`)}
                        />
                      </div>
                    )}
                </React.Fragment>
              )
            )}
            <div>
              <img
                className="max-h-8"
                src={getPropsValue(`image`)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenericCheckbox;
