import React, { useEffect } from 'react';
import { App } from './App';
import { updateBreadcrumb } from './common/SEO';
import { useHistory } from 'react-router-dom';

export const Root = () => { 
    const history = useHistory();

    useEffect(() => {
        return history.listen(() => {
            updateBreadcrumb();
        })
    }, [history]);

    return ( 
        <div>
            <div id="printWrapper" className="d-print-block d-none bg-white"></div>
            <div className="d-print-none">
                <App />
            </div>
        </div>
    ) 
}
