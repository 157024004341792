import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgCheckbox,
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import ConfirmDialog from "../../../Common/ConfirmDialog";

function NFIRSFireEquipmentInvolved(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsFireEquipmentInvolved.update({
      ...model.new,
      year: model.new.year || (null as any),
    });
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsFireEquipmentInvolvedInputDto>({
    objectName: "Equipment Involved",
    nfirsSectionTab: "Fire/EquipmentInvolved",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsFireEquipmentInvolvedInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Fire",
    "EquipmentInvolved",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Fire", "EquipmentInvolved", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const equipments = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getEquipmentTypes(false),
    "Equipment Types"
  );
  const equipmentSections = useNfirsLookupEnum(
    "NfirsEquipmentTypeSectionEnum",
    "Equipment Types Sections"
  );
  const powerSources = useNfirsGetData<
    ApsModels.INfirsPowerSourceEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService.getNfirsPowerSourceEntries,
    "Power Source"
  );
  const powerSourceSections = useNfirsLookupEnum(
    "NfirsPowerSourceSectionEnum",
    "Fire Power Source Sections"
  );

  const factors = useNfirsGetData<
    ApsModels.INfirsFireSuppressionFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getFireSuppressions(false),
    "Fire Suppression Factors"
  );
  const factorSections = useNfirsLookupEnum(
    "NfirsFireSuppressionSectionEnum",
    "Fire Suppression Factor Sections"
  );

  useEffect(() => {
    setProgress({ loading: true });
    equipments.getData();
    equipmentSections.getData();
    powerSources.getData();
    powerSourceSections.getData();
    factors.getData();
    factorSections.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsFireEquipmentInvolved
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
            year: `${data.year || ""}`.trim(),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);

          trigger(); //force validate so users can see error labels
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Equipment invovled", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      equipments.status === NfirsFetchStatus.Complete &&
      equipmentSections.status === NfirsFetchStatus.Complete &&
      powerSources.status === NfirsFetchStatus.Complete &&
      powerSourceSections.status === NfirsFetchStatus.Complete &&
      factors.status === NfirsFetchStatus.Complete &&
      factorSections.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    equipments.status,
    equipmentSections.status,
    powerSources.status,
    powerSourceSections.status,
    factors.status,
    factorSections.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    trigger,
  } = FgUseForm({
    nfirsEquipmentTypeEntryId: {
      displayName: "Equipment Involved",
      validation: {
        required: false,
      },
    },
    brand: {
      displayName: "Brand",
      validation: {
        required: false,
        maxLength: 64,
      },
    },
    model: {
      displayName: "Model",
      validation: {
        required: false,
        maxLength: 64,
      },
    },
    serialNumber: {
      displayName: "Serial Number",
      validation: {
        required: false,
        maxLength: 64,
      },
    },
    year: {
      displayName: "Year",
      validation: {
        required: false,
        minLength: 4,
        maxLength: 4,
        validate: {
          numberOnly: commonService.validations.numberOnly,
          acceptedYearRange: commonService.validations.acceptedYearRange,
        },
      },
    },
    nfirsPowerSourceEntryId: {
      displayName: "Equipment Power Source",
      validation: {
        required: false,
      },
    },
    nfirsFireSuppressionEntry1Id: {
      displayName: "File Suppression Factors",
      validation: {
        required: false,
      },
    },
    nfirsFireSuppressionEntry2Id: {
      displayName: "File Suppression Factors",
      validation: {
        required: false,
      },
    },
    nfirsFireSuppressionEntry3Id: {
      displayName: "File Suppression Factors",
      validation: {
        required: false,
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12">
          <strong>Equipment Involved</strong>

          <div className="mt-3 mb-2">
            <label>Was equipment involved in this incident?</label>
            <div className="inline-radio-box-group">
              <div>
                <input
                  type="radio"
                  name="isEquipmentInvolved"
                  id="isEquipmentInvolvedYes"
                  value="1"
                  checked={model.new.isEquipmentInvolved || false}
                  onChange={(event) => {
                    onModelChange({
                      ...model.new,
                      isEquipmentInvolved: Number(event.target.value) === 1,
                    });
                  }}
                ></input>
                <label htmlFor="isEquipmentInvolvedYes">Yes</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="isEquipmentInvolved"
                  id="isEquipmentInvolvedNo"
                  value="0"
                  checked={!model.new.isEquipmentInvolved || false}
                  onChange={(event) => {
                    onModelChange({
                      ...model.new,
                      isEquipmentInvolved: Number(event.target.value) === 1,
                    });
                  }}
                ></input>
                <label htmlFor="isEquipmentInvolvedNo">No</label>
              </div>
            </div>
          </div>
        </div>

        {model.new.isEquipmentInvolved && (
          <>
            <div className="col-sm-12 col-lg-6">
              <div className="mt-3">
                <label>Equipment Involved</label>
                <AdvancedDropdown
                  domId="nfirsEquipmentTypeEntryId"
                  value={model.new.nfirsEquipmentTypeEntryId}
                  favoritesHandler={{
                    add: ApsServices.http.nfirsFavorite
                      .addFavoriteEquipmentType,
                    remove:
                      ApsServices.http.nfirsFavorite
                        .removeFavoriteEquipmentType,
                    afterUpdate: (list) => equipments.setData(list),
                  }}
                  options={(equipments.data || [])
                    .sort(commonService.sortByStringProperty("description"))
                    .map((o) => {
                      return {
                        label: `${o.description} (${o.externalId})`,
                        value: o.id,
                        originalObject: o,
                        isFavorite: o.isFavorite,
                        groupId: o.nfirsEquipmentTypeSection,
                        subGroupId: o.nfirsEquipmentTypeSection,
                      };
                    })}
                  groups={(equipmentSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      groupLabel: o.label,
                    };
                  })}
                  subGroups={(equipmentSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      subGroupId: o.value,
                    };
                  })}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      nfirsEquipmentTypeEntryId: Number(data) || (null as any),
                    });
                  }}
                ></AdvancedDropdown>
              </div>

              <div className="mt-3">
                <FgInput
                  id="brand"
                  label="Brand"
                  placeHolder="Brand"
                  registeredField={registry.brand}
                  formState={formState}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      brand: data,
                    });
                  }}
                ></FgInput>
              </div>
              <div className="mt-3">
                <FgInput
                  id="model"
                  label="Model"
                  placeHolder="Model"
                  registeredField={registry.model}
                  formState={formState}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      model: data,
                    });
                  }}
                ></FgInput>
              </div>
              <div className="mt-3">
                <FgInput
                  id="serialNumber"
                  label="Serial Number"
                  placeHolder="Serial Number"
                  registeredField={registry.serialNumber}
                  formState={formState}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      serialNumber: data,
                    });
                  }}
                ></FgInput>
              </div>
              <div className="mt-3">
                <FgInput
                  id="year"
                  label="Year"
                  placeHolder="Year"
                  registeredField={registry.year}
                  formState={formState}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      year: data,
                    });
                  }}
                  //maxlength={4}
                ></FgInput>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="mt-3">
                <FgSelect
                  id="nfirsPowerSourceEntryId"
                  label="Equipment Power Source"
                  selectMessage="Select equipment power source"
                  registeredField={registry.nfirsPowerSourceEntryId}
                  formState={formState}
                  groups={powerSourceSections.data?.map((g) => {
                    return { id: g.value, label: g.label };
                  })}
                  options={(powerSources.data || []).map((o) => {
                    return {
                      label: `${o.externalId}. ${o.description}`,
                      value: o.id,
                      groupId: o.nfirsPowerSourceSection,
                    };
                  })}
                  onChange={(data) =>
                    onModelChange({
                      ...model.new,
                      nfirsPowerSourceEntryId: Number(data) || (null as any),
                    })
                  }
                ></FgSelect>
              </div>

              <div className="mt-3">
                <label>Portable or Stationary?</label>
                {!progress.loading && (
                  <div className="inline-radio-box-group">
                    <div>
                      <input
                        type="radio"
                        name="isPortable"
                        id="isPortableYes"
                        value="1"
                        checked={model.new.isPortable}
                        onChange={(event) => {
                          onModelChange({
                            ...model.new,
                            isPortable: Number(event.target.value) === 1,
                          });
                        }}
                      ></input>
                      <label htmlFor="isPortableYes">Portable</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="isPortable"
                        id="isPortableNo"
                        value="0"
                        checked={!model.new.isPortable}
                        onChange={(event) => {
                          onModelChange({
                            ...model.new,
                            isPortable: Number(event.target.value) === 1,
                          });
                        }}
                      ></input>
                      <label htmlFor="isPortableNo">Stationary</label>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-3">
                <label className="flex">
                  <span className="flex-1">Fire Suppression Factors</span>
                  <span>
                    <FgCheckbox
                      id="isFireSuppression"
                      label="None"
                      data={getModel?.new?.isFireSuppression || false}
                      onChange={(data) => {
                        onModelChange({
                          ...model.new,
                          isFireSuppression: data,
                        });
                      }}
                    ></FgCheckbox>
                  </span>
                </label>

                <div className="actionTakenList">
                  {[
                    getModel?.new?.nfirsFireSuppressionEntry1Id,
                    getModel?.new?.nfirsFireSuppressionEntry2Id,
                    getModel?.new?.nfirsFireSuppressionEntry3Id,
                  ].map((item, i) => (
                    <React.Fragment key={i}>
                      {item && (
                        <div>
                          <div>
                            {
                              factors.data?.find((a) => item === a.id)
                                ?.description
                            }{" "}
                            (
                            {
                              factors.data?.find((a) => item === a.id)
                                ?.externalId
                            }
                            )
                          </div>
                          <div>
                            <i
                              className="fa fa-times pointer"
                              onClick={() => {
                                if (i === 0) {
                                  onModelChange({
                                    ...model.new,
                                    nfirsFireSuppressionEntry1Id: null as any,
                                  });
                                } else if (i === 1) {
                                  onModelChange({
                                    ...model.new,
                                    nfirsFireSuppressionEntry2Id: null as any,
                                  });
                                } else if (i === 2) {
                                  onModelChange({
                                    ...model.new,
                                    nfirsFireSuppressionEntry3Id: null as any,
                                  });
                                }
                              }}
                            ></i>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>

                {(!getModel?.new?.nfirsFireSuppressionEntry1Id ||
                  !getModel?.new?.nfirsFireSuppressionEntry2Id ||
                  !getModel?.new?.nfirsFireSuppressionEntry3Id) && (
                  <AdvancedDropdown
                    domId="nfirsFireSuppressionEntryId"
                    value={null}
                    searchPlaceholder="Search and Add More Factors"
                    favoritesHandler={{
                      add: ApsServices.http.nfirsFavorite
                        .addFavoriteFireSuppression,
                      remove:
                        ApsServices.http.nfirsFavorite
                          .removeFavoriteFireSuppression,
                      afterUpdate: (list: any[]) => factors.setData(list),
                    }}
                    options={(factors.data || []).map((o) => {
                      return {
                        label: `${o.description} (${o.externalId})`,
                        value: o.id,
                        originalObject: o,
                        isFavorite: o.isFavorite,
                        groupId: o.nfirsFireSuppressionSection,
                        subGroupId: 0,
                      };
                    })}
                    groups={(factorSections.data || []).map((o) => {
                      return {
                        groupId: o.value,
                        groupLabel: o.label,
                      };
                    })}
                    subGroups={(factorSections.data || []).map((o) => {
                      return {
                        groupId: o.value,
                        subGroupId: 0,
                      };
                    })}
                    onChange={(data) => {
                      if (!getModel?.new?.nfirsFireSuppressionEntry1Id) {
                        onModelChange({
                          ...model.new,
                          nfirsFireSuppressionEntry1Id:
                            Number(data) || (null as any),
                        });
                      } else if (!getModel?.new?.nfirsFireSuppressionEntry2Id) {
                        onModelChange({
                          ...model.new,
                          nfirsFireSuppressionEntry2Id:
                            Number(data) || (null as any),
                        });
                      } else if (!getModel?.new?.nfirsFireSuppressionEntry3Id) {
                        onModelChange({
                          ...model.new,
                          nfirsFireSuppressionEntry3Id:
                            Number(data) || (null as any),
                        });
                      }
                    }}
                  ></AdvancedDropdown>
                )}
              </div>
            </div>
          </>
        )}

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSFireEquipmentInvolved;
