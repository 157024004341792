import React, { Component } from "react";
import { AppContext } from "../../../AppContext";
import { DepartmentService } from "../../../services/DepartmentService";
import ApsModels from "../../../aps2/models";

// setup state and props definitions
type Props = {};
type State = {
    dashboards: ApsModels.IEsriDashboardDto[],
    selectedDashboard: ApsModels.IEsriDashboardDto,
    view: number
};

export class DataVisualization extends Component<Props, State> {
    static contextType = AppContext;
    state: State = {
        dashboards: new Array<ApsModels.IEsriDashboardDto>(),
        selectedDashboard: {id:0,name:"",link:""}, // this had to be here or we get an error for possibility of null,, logic relies on length of dashboards tho
        view: 2
    };

    // set view based on screen width
    private handleResize = () => {
        if (window.screen.width < 768)
            this.setState({ view: 0 }) // mobile
        else if (window.screen.width >= 768 && window.screen.width <= 1024)
            this.setState({ view: 1 }) // tablet
        else
            this.setState({ view: 2 }) // desktop
    };

    // load in this departments dashboards
    async componentDidMount() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize);
        var dashboards = await DepartmentService.getEsriDashboardsByDepartmentId(this.context.module);
        this.setState({ dashboards: dashboards, selectedDashboard: dashboards[0] });
    }

    setSelectedDashboard(dashboard: ApsModels.IEsriDashboardDto) {
        this.setState({ selectedDashboard: dashboard });
    }

    renderDashboardsSection() {
        return (
            <div className="container-fluid">
                <ul className="nav nav-tabs">
                    {
                        this.state.dashboards.map((dashboard) => {
                            // mark arctive for selected
                            if (dashboard == this.state.selectedDashboard) {
                                var value = (
                                    <li className="nav-item">
                                        <button className="nav-link active" aria-current="page" >{dashboard.name}</button>
                                    </li>
                                );
                            }
                            // else lets set up the ability to change
                            else {
                                var value = (
                                    <li className="nav-item">
                                        <button className="nav-link" onClick={() => this.setSelectedDashboard(dashboard)}>{dashboard.name}</button>
                                    </li>
                                );
                            }

                            return value;
                        })
                    }
                </ul>
                <iframe
                    className="h-100 w-100 d-inline-block"
                    src={this.state.selectedDashboard.link}
                    title={this.state.selectedDashboard.name.replace(' ', '_')}>
                </iframe>
            </div>
        );
    }

    render() {
        // if we have dashboards, lets render as needed, else blank page for now,, maybe need a message in the future if access granted but no dashboards
        if (this.state.dashboards.length > 0)
            var page = this.renderDashboardsSection();
        else
            var page = (<div></div>); // blank for now but we will likely want a page here for the error

        return page;
    }
}