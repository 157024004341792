import { OptionDto } from "../../../../data/Option";
import fetcher from "../Fetcher";

class OptionService {
  save = async (data: OptionDto) => {
    const url = `/api/Option/Save`;
    return fetcher.post<OptionDto>(url, data);
  };

  update = async (data: OptionDto) => {
    const url = `/api/Option/Update`;
    return fetcher.put<OptionDto>(url, data);
  };

  delete = async (id: number) => {
    const url = `/api/Option/Delete/${id}`;
    return fetcher.delete<any>(url);
  };

  getGroupOptions(optionGroupId: number): Promise<OptionDto[]> {
    return fetcher.get<OptionDto[]>(`/api/Option/Options/${optionGroupId}`);
  }
}

const option = new OptionService();
export default option;
