export const USER_MUST_CHANGE_PASSWORD_MESSAGE = "USER_MUST_CHANGE_PASSWORD";

export const LINK_TO_BREADCRUMB: {[key: string]: any} = {
    Home: "Home",
    Links: "Links",
    Drills: "Drills",
    DailyOperational: "Daily Operational",
    NFIRS: "NFIRS",
    DataVisualization: "Data Visualization",
    Personnel: "Personnel",
    Courses: "Courses",
    Credentials: "Credentials",
    Credential: "Credential",
    Admin: {
        Department: "Department",
        Departments: "Departments",
        Group: "Group",
        Groups: "Groups",
        User: "User",
        Users: "Users",
    },
    Account: {
        Login: "Login",
        Logout: "Logout",
        ForgotPassword: "Forgot Password",
        ResetPassword: "Reset Password",
        ChangePassword: "Change Password",
        Profile: "Profile",
    },
}