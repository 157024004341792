import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { AppContext } from '../../../../AppContext';
import {
    AnswerDto,
    AnswerType,
    QuestionDto,
    QuestionGroupDto,
} from '../../../../data/DailyOperational';
import { OptionGroupDto } from "../../../../data/OptionGroup";
import { OptionGroupService } from "../../../../services/OptionGroupService";
import { OptionService } from "../../../../services/OptionService";
import { OptionDto } from "../../../../data/Option";
import { PullFromPreviousDayButton } from "../../CustomInput/PullFromPreviousDayButton";
import { GroupService } from '../../../../services/GroupService';
import { UserDto } from '../../../../data/User';
import { StrikeTeamAnswer } from "./StrikeTeamAnswer";
import './StrikeTeams.scss';
import {PreviousDayService} from "../../../../services/PreviousDayService";
import {UserService} from "../../../../services/UserService";
import CommonSpinner from '../../../../aps2/components/Common/CommonSpinner';

type Props = {
    questionGroup?: QuestionGroupDto,
    date: Date,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    optionGroups: OptionGroupDto[],
    departmentId: number,
    moduleOrder: number,
    hideNotice?: boolean
}

type State = {
    calendarDate: Date | null,
    wsaOptions: OptionDto[],
    stOptions: OptionDto[],
    engines: OptionDto[],
    selectedOptionId: number | null,
    isTablet: boolean,
    stenGroupUsers: UserDto[],
    stenTGroupUsers: UserDto[],
    ready: boolean
}

export class StrikeTeams extends React.Component<Props, State> {
    static contextType = AppContext;

    private readonly AvailableStatus: string = 'Available';

    state: State = {
        calendarDate: null,
        wsaOptions: new Array<OptionDto>(),
        stOptions: new Array<OptionDto>(),
        engines: new Array<OptionDto>(),
        stenGroupUsers: new Array<UserDto>(),
        stenTGroupUsers: new Array<UserDto>(),
        selectedOptionId: null,
        isTablet: false,
        ready: false
    }

    private async getOptionsWSA(): Promise<OptionDto[]> {
        const wsaOptionsGroup = this.props.optionGroups.find(g => g.name === 'Strike Teams List');
        return wsaOptionsGroup ? await OptionService.getGroupOptions(wsaOptionsGroup.optionGroupId) : [];
    }

    componentDidMount = async () => {
        const allGroup = await GroupService.getAllGroups();
        const optionGroups: OptionGroupDto[] = !this.props.optionGroups.length ? await OptionGroupService.getAllOptionGroupsByDepartment(this.props.departmentId) : this.props.optionGroups;
        const strike = await this.getOptions('Strike Teams List', optionGroups) 
        const stOptions = await this.getOptions('ST Options', optionGroups);
        const engines = await this.getOptions('Engines', optionGroups);
        const stenId = allGroup.find(x => x.name === 'STEN');
        const stenTId = allGroup.find(x => x.name === 'STEN-T');
        const stenGroupUsers = await UserService.getUsersInGroupByDepartmentId(stenId!.groupId, this.props.departmentId);
        const stenTGroupUsers = await UserService.getUsersInGroupByDepartmentId(stenTId!.groupId, this.props.departmentId);

        this.setState({
            calendarDate: this.props.date,
            stOptions: stOptions,
            engines: engines,
            stenGroupUsers: stenGroupUsers,
            stenTGroupUsers: stenTGroupUsers,
            ready: true,
            wsaOptions: strike
        }, this.addFirstAnswer);

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    private handleResize = () => {
        const tabletMaxWidth = 1550;
        this.setState({
            isTablet: window.screen.width <= tabletMaxWidth || window.innerWidth <= tabletMaxWidth
        });
    };

    updateQuestionGroup(newQuestions: QuestionDto[]): void {
        const questionGroup: QuestionGroupDto = cloneDeep(this.props.questionGroup)!;

        for (let i = 0; i < questionGroup.questions.length; i++) {
            let newQuestion: QuestionDto | undefined = newQuestions.find(q => q.order === questionGroup.questions[i].order);
            if (newQuestion) {
                questionGroup.questions[i] = newQuestion;
            }
        }

        this.props.setData(questionGroup, this.props.moduleOrder);
    }

    addFirstAnswer(): void {
        const questionOrder: number = 0;
        const question: QuestionDto = cloneDeep(this.props.questionGroup!.questions.find(q => q.order == questionOrder)!);
        const answer: AnswerDto | undefined = question.answers.find(a => a.order === 0);

        if (!answer) {
            question.answers.push(this.createNewAnswer(question, 0));
            this.updateQuestionGroup([question]);
        }
    }

    createNewAnswer(question: QuestionDto, answerOrder: number): AnswerDto {
        const selectedOption: OptionDto = this.state.stOptions.find(o => o.name === this.AvailableStatus)!;

        return {
            answerId: 0,
            answerType: AnswerType.StrikeTeam,
            date: this.props.date,
            dateCreated: this.props.date,
            dateUpdated: this.props.date,
            order: answerOrder,
            questionId: question.questionId || 0,
            strikeTeamOptionId: selectedOption?.optionId,
            strikeTeamReferences: []
        }
    }

    getAllAnswers(): AnswerDto[] {
        const question: QuestionDto | undefined = this.props.questionGroup?.questions[0];
        return question ? question.answers : [];
    }

    async getOptions(name: string, optionGroups: OptionGroupDto[]): Promise<OptionDto[]> {
        const optionsGroup = optionGroups.find(g => g.name === name);
        return optionsGroup ? await OptionService.getGroupOptions(optionsGroup.optionGroupId) : [];
    }

    private deleteAnswer(question: QuestionDto, answerToDelete: AnswerDto): void {
        const answerIndex: number = question.answers.indexOf(answerToDelete);
        question.answers.splice(answerIndex, 1);

        for (let i = 0; i < question.answers.length; i++) {
            question.answers[i].order = i;
        }
    }

    private updateAnswer(question: QuestionDto, answer: AnswerDto) {
        for (let i = 0; i < question.answers.length; i++) {
            if (question.answers[i].order === answer.order) {
                question.answers[i] = answer;
            }
        }
    }

    handleNewAnswerAdded = () => {
        const questionOrder: number = 0;
        const question: QuestionDto = cloneDeep(this.props.questionGroup!.questions.find(q => q.order == questionOrder)!);
        const newAnswerOrder: number = question.answers.length;

        question.answers.push(this.createNewAnswer(question, newAnswerOrder));
        this.updateQuestionGroup([question]);
    }

    handleAnswerChanged = (answer: AnswerDto): void => {
        const question: QuestionDto | undefined = this.props.questionGroup?.questions[0];

        if (!question) return;

        let existingAnswer: AnswerDto | undefined = question.answers.find(a => a.order === answer.order);

        if (existingAnswer) {
            this.updateAnswer(question, answer);
        } else {
            question.answers.push(answer);
        }

        this.updateQuestionGroup([question]);
    }

    handleAnswerDeleted = (answerOrder: number): void => {
        const questionGroup: QuestionGroupDto = cloneDeep(this.props.questionGroup!);
        const question: QuestionDto = questionGroup.questions[0]!;
        const answerToDelete: AnswerDto | undefined = question.answers.find(a => a.order === answerOrder);

        if (answerToDelete) {
            this.deleteAnswer(question, answerToDelete);
            this.props.setData(questionGroup, this.props.moduleOrder);
        }
    }

    handlePullFromPreviousDay = async (newQuestionGroupValue: QuestionGroupDto) => {
        this.props.setData(newQuestionGroupValue,this.props.moduleOrder);
    }

    render() {
        const answers = this.getAllAnswers();
        const isPullFromPreviousDayAvailable = PreviousDayService.isFeatureAvailable(this.props.date);
        const questionGroupNameColumnClass = isPullFromPreviousDayAvailable ? 'col-xl-9 col-8' : 'col-xl-10 col-9';
        const controlsColumnClass = isPullFromPreviousDayAvailable ? 'col-xl-3 col-4' : 'col-xl-2 col-3';

        return (
            <div className="card box-card flex-grow-1">
                {!this.state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
                <div className="card-body">
                    <div className="row">
                        <h4 className={`mb-0 font-size-16 ${questionGroupNameColumnClass}`}>{this.props.questionGroup?.name || ''}</h4>
                        <div className={`row ml-1 pl-4 ${controlsColumnClass}`}>
                            {this.context.isEditableDailyOperational && <i className="fas fa-plus-circle mr-4 add-answer-btn" onClick={this.handleNewAnswerAdded} />}
                            {!this.context.isEditableDailyOperational && <i className="fas fa-plus-circle mr-3" />}
                            {isPullFromPreviousDayAvailable && <PullFromPreviousDayButton questionGroupId={this.props.questionGroup?.questionGroupId!} calendarDate={this.props.date} setData={this.handlePullFromPreviousDay} />}
                        </div>
                    </div>
                    {!this.props.hideNotice && <div className="mt-3">Notify Staff Captain by 0700 if not available for deployment.</div>}
                    {!this.state.isTablet && <div className="row">
                        <div className="col-xl-6 section-column section-column-1">
                            {answers.filter(a => a.order % 2 === 0).map(a => {
                                return <StrikeTeamAnswer
                                    defaultItemsCount={3}
                                    key={a.order}
                                    answer={a}
                                    stOptions={this.state.stOptions}
                                    wsaOptions={this.state.wsaOptions}
                                    engines={this.state.engines}
                                    stenGroupUsers={this.state.stenGroupUsers}
                                    stenTGroupUsers={this.state.stenTGroupUsers}
                                    onChange={this.handleAnswerChanged}
                                    onDelete={this.handleAnswerDeleted}
                                />
                            })}
                        </div>
                        <div className="col-xl-6 section-column section-column-2">
                            {answers.filter(a => a.order % 2 === 1).map(a => {
                                return <StrikeTeamAnswer
                                    defaultItemsCount={3}
                                    key={a.order}
                                    answer={a}
                                    stOptions={this.state.stOptions}
                                    wsaOptions={this.state.wsaOptions}
                                    engines={this.state.engines}
                                    stenGroupUsers={this.state.stenGroupUsers}
                                    stenTGroupUsers={this.state.stenTGroupUsers}
                                    onChange={this.handleAnswerChanged}
                                    onDelete={this.handleAnswerDeleted}
                                />
                            })}
                        </div>
                    </div>}
                    {this.state.isTablet && <div className="row">
                        <div className="col-xl-12">
                            {answers.map(a => {
                                return <StrikeTeamAnswer
                                    defaultItemsCount={3}
                                    key={a.order}
                                    answer={a}
                                    stOptions={this.state.stOptions}
                                    wsaOptions={this.state.wsaOptions}
                                    engines={this.state.engines}
                                    stenGroupUsers={this.state.stenGroupUsers}
                                    stenTGroupUsers={this.state.stenTGroupUsers}
                                    onChange={this.handleAnswerChanged}
                                    onDelete={this.handleAnswerDeleted}
                                />
                            })}
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}
