import React from 'react';
import { OptionDto } from '../../../data/Option';
import {AppContext} from "../../../AppContext";
import './StrikeTeamStatusSelect.scss';

type Props = {
    options: OptionDto[],
    value: number | null | undefined,
    onChange(value: string): void,
}
type State = {}

export class StrikeTeamStatusSelect extends React.Component<Props, State>{
    static contextType = AppContext;

    handleOptionChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onChange(e.target.value);
    }

    render() {
        return (
            <select disabled={!this.context.isEditableDailyOperational} className="form-control strike-team-status-select-border custom-select-without-arrow" value={this.props.value ?? ''} onChange={this.handleOptionChanged}>
                {this.props.options.map(option => {
                    return (
                        <option key={option.optionId} value={option.optionId}>{option.name}</option>
                    )
                })}
            </select>
        )
    }
}
