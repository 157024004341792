import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import CommonSpinner from "../../aps2/components/Common/CommonSpinner";
import FileDragNDrop from "../../aps2/components/Common/FileDragNDrop";
import { IBasicDialogProps } from "../../aps2/components/Common/FormGroups";
import FormModal from "../../aps2/components/Common/FormModal";
import {
  NfirsFetchStatus,
  useNfirsGetData,
} from "../../aps2/components/NFIRS/NFIRSHelper";
import ApsServices from "../../aps2/services";
import commonService from "../../aps2/services/CommonService";
import toastStore from "../../aps2/stores/ToastStore";
import { FormDto, QuestionGroupDto } from "../../data/DailyOperational";
import { OptionDto } from "../../data/Option";
import { OptionGroupDto } from "../../data/OptionGroup";
import { FormService } from "../../services/FormService";
import {
  BuiltInDOCComponents,
  FindQuestionDefinition,
  GetDropdownOptionGroupId,
  IBaseDOCComponent,
  MorePropsComponentHanlders,
} from "./ComponentGalleryDefinitions";

interface IComponentGalleryDialogProps
  extends IBasicDialogProps<IBaseDOCComponent> {
  departmentId: number;
  onChange: (data: IBaseDOCComponent, relaunch?: boolean) => void;
}

function ComponentGalleryDialog(props: IComponentGalleryDialogProps) {
  const [component, setComponent] = useState<IBaseDOCComponent>();
  const [form, setForm] = useState<FormDto>();
  const [questionGroupDto, setQuestionGroupDto] = useState<QuestionGroupDto>();
  const [saving, setSaving] = useState<string>();

  //Use componentId in useEffect to only monitor if the component type changes
  const [componentId, setComponentId] = useState<string>();
  useEffect(() => {
    if (component) {
      setComponentId(component.id);
    } else {
      setComponentId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);

  const getForm = async () => {
    const form = await FormService.getDailyOperationalForm(
      new Date(),
      props.departmentId
    );
    setForm(form);
  };

  const optionGroups = useNfirsGetData(async () => {
    return ApsServices.http.optionGroup.getAllOptionGroupsByDepartment(
      props.departmentId
    );
  }, "Option Groups");

  const [newOptionGroups, setNewOptionGroups] = useState<OptionGroupDto[]>([]);
  const allOptionGroups = useMemo(() => {
    return [...(optionGroups.data || []), ...newOptionGroups];
  }, [newOptionGroups, optionGroups.data]);

  const definedOptionGroups = useMemo(() => {
    if (component && component.questionGroup) {
      const lib = BuiltInDOCComponents.find((b) => b.id === component.id);
      if (lib) {
        if (component?.id === "GenericDropdown") {
          const uniqueName = GetDropdownOptionGroupId(
            component.moreProps?.values?.instanceId
          );
          return [uniqueName];
        }
        return (
          lib.questionGroup?.questions
            .filter((q) => q.optionGroupName)
            .map((q) => q.optionGroupName || "") || []
        );
      }
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId]);

  const getOptionGroupId = (index: number) => {
    let optionGroupName = component?.questionGroup?.questions?.find(
      (q, i) => i === index
    )?.optionGroupName;

    if (component?.id === "GenericDropdown") {
      optionGroupName = GetDropdownOptionGroupId(
        component.moreProps?.values?.instanceId
      );
    }

    if (optionGroupName) {
      return (
        allOptionGroups?.find((og) => og.name === optionGroupName)
          ?.optionGroupId || undefined
      );
    }
    return undefined;
  };

  const canAddOptionGroup = useMemo(() => {
    if (
      definedOptionGroups.length > 0 &&
      allOptionGroups &&
      optionGroups.status === NfirsFetchStatus.Complete
    ) {
      return (
        definedOptionGroups.filter(
          (n) => !!allOptionGroups?.find((og) => og.name === n)
        ).length === definedOptionGroups.length
      );
    }
    return false;
  }, [componentId, allOptionGroups, optionGroups.status]);

  useEffect(() => {
    if (props.data) {
      BuiltInDOCComponents.forEach((comp) => {
        if (props.data?.id === comp.id) {
          setComponent({
            ...comp,
            id: props.data.id,
            name: props.data.name,
            questionGroup: props.data.questionGroup,
            moreProps: props.data.moreProps,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (componentId) {
      getForm();
      optionGroups.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId]);

  useEffect(() => {
    if (componentId && form) {
      setQuestionGroupDto(getQuestionGroup());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId, form]);

  const [saveClicked, setSaveClicked] = useState(false);
  useEffect(() => {
    if (canAddOptionGroup && !props.data && !saving && saveClicked) {
      saveQuestionGroup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddOptionGroup, saveClicked]);

  const getQuestionGroup = () => {
    if (form && component) {
      const group = form.questionGroups.find(
        (group) => group.order === component.questionGroup?.order
      );
      if (group) {
        return {
          ...group,
          questions: group.questions.map((q) => {
            return {
              ...q,
              answers: [], //we don't need anwers
            };
          }),
        } as QuestionGroupDto;
      }
      //Create Default if not found
      return {
        ...component.questionGroup,
        questions: component.questionGroup?.questions?.map((q) => {
          return {
            ...q,
            answers: [] as any,
          };
        }),
      } as QuestionGroupDto;
    }
    return undefined;
  };

  const getLastQuestionGroupOrderNum = () => {
    if (form?.questionGroups && form.questionGroups?.length > 0) {
      return Math.max(...form.questionGroups.map((grp) => grp.order)) + 1;
    }
    return 0;
  };

  const saveOptionGroups = async () => {
    // Save Option Groups first if defined
    if (definedOptionGroups.length) {
      let cnt = 0;
      const onDone = () => {
        if (definedOptionGroups.length === cnt) {
          toastStore.showToast("Option Group(s) Saved.", "success");
        }
      };
      for (var i in definedOptionGroups) {
        const og = definedOptionGroups[i];
        if (allOptionGroups?.find((data) => data.name === og)) {
          cnt++;
          onDone();
          return;
        }
        await ApsServices.http.optionGroup
          .save({ name: og } as any)
          .then((data) => {
            cnt++;
            setNewOptionGroups((prev) => {
              return [...prev, data];
            });
            onDone();
          });
      }
      // definedOptionGroups.forEach((og) => {
      //   if (allOptionGroups?.find((data) => data.name === og)) {
      //     cnt++;
      //     onDone();
      //     return;
      //   }
      //   ApsServices.http.optionGroup.save({ name: og } as any).then((data) => {
      //     cnt++;
      //     setNewOptionGroups((prev) => {
      //       return [...prev, data];
      //     });
      //     onDone();
      //   });
      // });
    }
  };

  const saveQuestionGroup = async (relaunch = false) => {
    if (component && questionGroupDto && questionGroupDto.questions) {
      if (
        commonService.isNullOrWhitespace(questionGroupDto.name) ||
        questionGroupDto.questions.find(
          (q, i) =>
            commonService.isNullOrWhitespace(q.questionText) &&
            !FindQuestionDefinition(component.id, q.order, i)?.hideQuestionText
        )
      ) {
        toastStore.showToast("Label(s) cannot be empty.", "warning");
        return;
      }
    }

    if (!saving && component && questionGroupDto) {
      if (questionGroupDto?.order === -1) {
        //NEW
        setSaving("Saving Question Group...");
        await ApsServices.http.questionGroup //CREATE QUESTION GROUP
          .save({
            ...(questionGroupDto as any),
            formId: form?.formId,
            order: getLastQuestionGroupOrderNum(),
          })
          .then(async (grpData) => {
            toastStore.showToast("Question Group Saved.", "success");
            setSaving("Saving Question(s)...");

            let cnt = 0;
            for (
              let index = 0;
              index < questionGroupDto.questions.length;
              index++
            ) {
              const qtn = questionGroupDto.questions[index];
              await ApsServices.http.question
                .save({
                  ...qtn,
                  questionGroupId: grpData.questionGroupId,
                  questionText:
                    qtn.questionText || `Question #${qtn.order + 1}`,
                  optionGroupId: getOptionGroupId(index),
                })
                .then((data) => {
                  //console.log(data);
                  cnt++;
                  if (cnt === questionGroupDto.questions.length) {
                    toastStore.showToast("Question(s) Saved.", "success");
                    props.onChange &&
                      props.onChange(
                        {
                          ...component,
                          questionGroup: {
                            ...(questionGroupDto as any),
                            order: grpData.order,
                          },
                        },
                        relaunch
                      );
                  }
                })
                .catch((error) => {
                  toastStore.showError(
                    `Failed Saving Question: ${qtn.questionText}`,
                    error
                  );
                });
            }
          })
          .catch((error) => {
            toastStore.showError("Failed Saving Question Group", error);
          });
      } else {
        //EXISTING
        setSaving("Updating Question Group...");
        await ApsServices.http.questionGroup
          .update({
            ...(questionGroupDto as any),
            formId: form?.formId,
          })
          .then(async (grpData) => {
            toastStore.showToast("Question Group Updated.", "success");
            setSaving("Updating Question(s)...");

            let cnt = 0;
            for (
              let index = 0;
              index < questionGroupDto.questions.length;
              index++
            ) {
              const qtn = questionGroupDto.questions[index];
              await ApsServices.http.question
                .update({
                  ...qtn,
                  questionText:
                    qtn.questionText || `Question #${qtn.order + 1}`,
                })
                .then((data) => {
                  //console.log(data);
                  cnt++;
                  if (cnt === questionGroupDto.questions.length) {
                    toastStore.showToast("Question(s) Updated.", "success");
                    props.onChange &&
                      props.onChange(
                        {
                          ...component,
                          questionGroup: {
                            ...(questionGroupDto as any),
                            order: grpData.order,
                          },
                        },
                        relaunch
                      );
                  }
                })
                .catch((error) => {
                  toastStore.showError(
                    `Failed Updating Question: ${qtn.questionText}`,
                    error
                  );
                });
            }
          })
          .catch((error) => {
            toastStore.showError("Failed Updating Question Group", error);
          });
      }
    }
  };

  const getHelpText = () => {
    if (component) {
      return (
        BuiltInDOCComponents.find((c) => c.id === component.id)?.helpText || ""
      );
    }
    return undefined;
  };

  const submit = () => {
    if (!!definedOptionGroups.length && !canAddOptionGroup) {
      saveOptionGroups();
    } else {
      saveQuestionGroup();
    }
    setSaveClicked(true);
  };

  return (
    <div>
      <FormModal
        title={props.data ? "Edit Field" : "Add Field"}
        isOpen={true}
        closeButtonLabel="Cancel and Close"
        submitButtonLabel={saving || "Save"}
        disableSubmit={!component || !!saving}
        size="xl"
        close={() => !saving && props.onClose()}
        submit={() => {
          submit();
        }}
      >
        <div className="row">
          {!props.data && (
            <div className="col-md-3">
              <div
                className="alert alert-secondary h-100"
                style={{ maxHeight: "800px", overflow: "auto" }}
              >
                <div className="mb-2">
                  <strong>Generic Fields</strong>
                </div>
                {BuiltInDOCComponents.filter((c) => c.builtIn).map((comp) => (
                  <div
                    key={comp.id}
                    className={`alert alert-sm alert-sm ${
                      comp.id === component?.id
                        ? "alert-primary"
                        : "alert-light"
                    } mb-2 pointer`}
                    onClick={(e) => {
                      if (comp.id === "GenericDropdown") {
                        const copy = commonService.deepCloneJsonObject(comp);
                        setComponent({
                          ...copy,
                          moreProps: {
                            names: copy.moreProps?.names || [],
                            values: {
                              ...(copy.moreProps?.values || {}),
                              instanceId: commonService.getUniqueId(),
                            },
                          },
                        });
                      } else {
                        setComponent(commonService.deepCloneJsonObject(comp));
                      }
                    }}
                  >
                    {comp.name}
                  </div>
                ))}
                <div className="mt-4 mb-2">
                  <strong>Custom Fields</strong>
                </div>
                {BuiltInDOCComponents.filter((c) => !c.builtIn).map((comp) => (
                  <div
                    key={comp.id}
                    className={`alert alert-sm alert-sm ${
                      comp.id === component?.id
                        ? "alert-primary"
                        : "alert-light"
                    } mb-2 pointer`}
                    onClick={(e) => {
                      setComponent(commonService.deepCloneJsonObject(comp));
                    }}
                  >
                    {comp.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div
            className={props.data ? "col-md-12" : "col-md-9"}
            style={{ maxHeight: "800px" }}
          >
            <div
              className="alert alert-sm alert-sm alert-secondary h-100 m-0"
              style={{ overflow: "auto" }}
            >
              <div className="row h-100">
                {!(component && form && questionGroupDto) && (
                  <div className="text-center w-100 p-4">
                    {props.data ? (
                      <CommonSpinner></CommonSpinner>
                    ) : (
                      <small>Choose a Field</small>
                    )}
                  </div>
                )}
                {component && form && questionGroupDto && (
                  <>
                    <div className={props.data ? "col-md-4" : "col-md-5"}>
                      <div className="alert alert-sm alert-sm alert-light">
                        <div className="pb-2">
                          <h4>{component?.name || "..."}</h4>
                        </div>
                        <div className="mb-2">
                          <label>
                            {/* Group Question Name */}
                            Field Label
                          </label>
                          <input
                            className="form-control form-control-sm"
                            value={questionGroupDto?.name || ""}
                            onChange={(e) => {
                              const val = e.target?.value || "";
                              setQuestionGroupDto((prev) => {
                                if (!prev) return prev;
                                return {
                                  ...prev,
                                  name: val,
                                };
                              });
                            }}
                          />
                        </div>

                        {component.needsInstructions && (
                          <div className="mb-2">
                            <label>Instructions</label>
                            <input
                              className="form-control form-control-sm"
                              value={
                                component.moreProps?.values["instructions"] ||
                                ""
                              }
                              onChange={(e) => {
                                const val = e.target?.value || "";
                                setComponent((prev) => {
                                  const vals = {
                                    ...(prev?.moreProps?.values || {}),
                                  };
                                  vals["instructions"] = val;
                                  return {
                                    ...(prev || ({} as any)),
                                    moreProps: {
                                      ...(prev?.moreProps || {}),
                                      values: vals,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        )}

                        {!!questionGroupDto?.questions?.filter(
                          (q, i) =>
                            //Validate from Definitions if Question(s) should be displayed
                            !FindQuestionDefinition(component.id, q.order, i)
                              ?.hideQuestionText
                        ).length && (
                          <>
                            <div className="mt-4">
                              <div className="mb-2">
                                <small>
                                  <strong>Question Labels:</strong>
                                </small>
                              </div>
                              {questionGroupDto?.questions
                                ?.map((q, idx) => {
                                  return {
                                    dfn: FindQuestionDefinition(
                                      component.id,
                                      q.order,
                                      idx
                                    ),
                                    qtn: q,
                                    idx: idx,
                                  };
                                })
                                ?.filter(
                                  (item) =>
                                    //Validate from Definitions if Question(s) should be displayed
                                    !item.dfn?.hideQuestionText
                                )
                                ?.map((item, i) => {
                                  const dfn = item.dfn;
                                  const qtn = item.qtn;

                                  let hideId = `hideQuestion${qtn.order}`;
                                  let canBeHiddenId = `canBeHidden${qtn.order}`;
                                  if (component.id === "ReserveApparatus") {
                                    //Special Case
                                    hideId = `hideQuestion${i}`;
                                    canBeHiddenId = `canBeHidden${i}`;
                                  }

                                  const imgId = `image${qtn.order}`;
                                  return (
                                    <div key={i} className="mb-3">
                                      <div className="flex mb-2">
                                        <label className="mb-0 flex-1">
                                          Question {i + 1} Label
                                        </label>
                                        {dfn?.canBeHidden && (
                                          <div className="flex flex-center flex-0 no-wrap">
                                            <label
                                              className="m-0 mr-1"
                                              htmlFor={canBeHiddenId}
                                            >
                                              Hide
                                            </label>
                                            <input
                                              type="checkbox"
                                              id={canBeHiddenId}
                                              checked={
                                                component?.moreProps?.values[
                                                  hideId
                                                ] || false
                                              }
                                              onChange={(e) => {
                                                if (!e.target) return;
                                                const checked =
                                                  e.target.checked || false;
                                                setComponent((prev) => {
                                                  const vals = {
                                                    ...(prev?.moreProps
                                                      ?.values || {}),
                                                  };
                                                  vals[hideId] = checked;
                                                  return {
                                                    ...(prev || ({} as any)),
                                                    moreProps: {
                                                      ...(prev?.moreProps ||
                                                        {}),
                                                      values: vals,
                                                    },
                                                  };
                                                });
                                              }}
                                            ></input>
                                          </div>
                                        )}
                                      </div>
                                      <input
                                        className="form-control form-control-sm"
                                        value={qtn.questionText || ""}
                                        onChange={(e) => {
                                          const val = e.target?.value || "";
                                          setQuestionGroupDto((prev) => {
                                            if (!prev) return prev;
                                            return {
                                              ...prev,
                                              questions: prev.questions.map(
                                                (question, idx) => {
                                                  if (
                                                    question &&
                                                    question.order ===
                                                      qtn.order &&
                                                    idx === item.idx
                                                  ) {
                                                    return {
                                                      ...question,
                                                      questionText: val,
                                                    };
                                                  }
                                                  return question;
                                                }
                                              ),
                                            };
                                          });
                                        }}
                                      />
                                      {dfn?.canSetImage && (
                                        <div className="mt-2 flex">
                                          <div className="flex-1">
                                            <FileDragNDrop
                                              label="Image"
                                              dragClickLabel="Image"
                                              accepts={{
                                                extensions: "image/*",
                                                pattern:
                                                  /\.(gif|jpe?g|png|bmp)$/i,
                                              }}
                                              onChange={async (fileList) => {
                                                if (fileList.length > 0) {
                                                  const filePromise =
                                                    new Promise<string>(
                                                      (resolve, reject) => {
                                                        var reader =
                                                          new FileReader();
                                                        reader.onloadend =
                                                          function () {
                                                            resolve(
                                                              reader.result as any
                                                            );
                                                          };
                                                        reader.readAsDataURL(
                                                          fileList[0]
                                                        );
                                                      }
                                                    );
                                                  const url = await filePromise;
                                                  setComponent((prev) => {
                                                    const vals = {
                                                      ...(prev?.moreProps
                                                        ?.values || {}),
                                                    };
                                                    vals[imgId] = url;
                                                    return {
                                                      ...(prev || ({} as any)),
                                                      moreProps: {
                                                        ...(prev?.moreProps ||
                                                          {}),
                                                        values: vals,
                                                      },
                                                    };
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="flex-1 ml-1 text-center"
                                            style={{
                                              position: "relative",
                                              backgroundColor: "#eee",
                                              padding: "5px",
                                              borderRadius: "10px",
                                            }}
                                          >
                                            {component.moreProps?.values &&
                                              component.moreProps.values[
                                                imgId
                                              ] && (
                                                <>
                                                  <button
                                                    className="btn btn-sm btn-danger"
                                                    type="button"
                                                    title="Remove Image"
                                                    style={{
                                                      position: "absolute",
                                                      right: "5px",
                                                      top: "5px",
                                                    }}
                                                    onClick={(e) => {
                                                      setComponent((prev) => {
                                                        const vals = {
                                                          ...(prev?.moreProps
                                                            ?.values || {}),
                                                        };
                                                        delete vals[imgId];
                                                        return {
                                                          ...(prev ||
                                                            ({} as any)),
                                                          moreProps: {
                                                            ...(prev?.moreProps ||
                                                              {}),
                                                            values: vals,
                                                          },
                                                        };
                                                      });
                                                    }}
                                                  >
                                                    <i className="fa fa-times"></i>
                                                  </button>
                                                  <img
                                                    style={{
                                                      maxHeight: "90px",
                                                      margin: "auto",
                                                    }}
                                                    src={
                                                      component.moreProps
                                                        .values[imgId]
                                                    }
                                                  />
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        )}

                        <div className="mt-4">
                          {component.moreProps?.names?.map((propName, i) => {
                            const subComponent =
                              MorePropsComponentHanlders.find(
                                (mpch) => mpch.propName === propName
                              )?.component as any;
                            return (
                              <div className="mb-2" key={propName}>
                                {React.createElement(subComponent, {
                                  displayOtherId: component.showDisplayOthers
                                    ? `displayOther${i}`
                                    : undefined,
                                  values: {
                                    ...(component.moreProps?.values || {}),
                                  },
                                  onChange: (values: any) => {
                                    setComponent((prev) => {
                                      if (!prev) return prev;
                                      if (prev.moreProps) {
                                        return {
                                          ...prev,
                                          moreProps: {
                                            ...prev.moreProps,
                                            values: values,
                                          },
                                        };
                                      }
                                      return prev;
                                    });
                                  },
                                })}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {!!definedOptionGroups.length && (
                        <>
                          {!canAddOptionGroup && (
                            <div className="alert alert-sm alert-info px-2 mb-3 flex flex-center">
                              <i className="fa fa-info-circle pr-2"></i>{" "}
                              <span className="flex-1">
                                You can add option(s) to each question after you
                                save the question labels
                              </span>
                            </div>
                          )}
                          <div
                            style={{
                              opacity: canAddOptionGroup ? 1 : 0.5,
                              pointerEvents: canAddOptionGroup ? "all" : "none",
                            }}
                          >
                            {definedOptionGroups
                              .filter((v, i, self) => {
                                return self.indexOf(v) === i;
                              })
                              .map((og, i) => {
                                const optGrp = allOptionGroups?.find(
                                  (item) => item.name === og
                                );
                                const val = og.split(":");
                                const ogName = val[val.length - 1].trim();
                                const displayOtherId = `displayOther${i}`;
                                let displayOther =
                                  component?.moreProps?.values[displayOtherId];
                                if (displayOther === undefined) {
                                  displayOther = true;
                                }
                                return (
                                  <React.Fragment key={og}>
                                    <div className="alert alert-sm alert-sm alert-light mb-2">
                                      <div className="flex flex-center">
                                        <div className="flex-1">
                                          <strong>{ogName}</strong>
                                        </div>
                                        {component.showDisplayOthers && (
                                          <div className="flex flex-center flex-0 no-wrap">
                                            <input
                                              type="checkbox"
                                              id={displayOtherId}
                                              checked={displayOther}
                                              onChange={(e) => {
                                                if (!e.target) return;
                                                const checked =
                                                  e.target.checked || false;
                                                setComponent((prev) => {
                                                  const vals = {
                                                    ...(prev?.moreProps
                                                      ?.values || {}),
                                                  };
                                                  vals[displayOtherId] =
                                                    checked;
                                                  return {
                                                    ...(prev || ({} as any)),
                                                    moreProps: {
                                                      ...(prev?.moreProps ||
                                                        {}),
                                                      values: vals,
                                                    },
                                                  };
                                                });
                                              }}
                                            ></input>
                                            <label
                                              className="m-0 ml-1"
                                              htmlFor={displayOtherId}
                                            >
                                              Display 'Other'
                                            </label>
                                          </div>
                                        )}
                                      </div>

                                      {optGrp ? (
                                        <OptionsTable
                                          name={optGrp.name}
                                          id={optGrp.optionGroupId}
                                        ></OptionsTable>
                                      ) : (
                                        <div>Unavailable</div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={props.data ? "col-md-8 pb-4" : "col-md-7 pb-4"}
                    >
                      {!!getHelpText() && (
                        <div className="alert alert-sm alert-info px-2 mb-2 flex flex-center">
                          <i className="fa fa-info-circle pr-2"></i>{" "}
                          <span className="flex-1">{getHelpText()}</span>
                        </div>
                      )}
                      <div
                        style={{
                          border: "dashed 2px #bbb",
                          padding: "10px",
                          pointerEvents: "none",
                        }}
                      >
                        {React.createElement(
                          BuiltInDOCComponents.find(
                            (b) => b.id === component.id
                          )?.component,
                          {
                            ...{
                              questionGroup:
                                component.id === "StrikeTeams"
                                  ? {
                                      //create temp answer for strike teams
                                      ...questionGroupDto,
                                      questions: [
                                        ...questionGroupDto.questions.map(
                                          (q) => {
                                            return {
                                              ...q,
                                              answers: [
                                                {
                                                  answerId: -1,
                                                  answerType: 4,
                                                  order: 0,
                                                  strikeTeamReferences: [],
                                                },
                                              ],
                                            };
                                          }
                                        ),
                                      ],
                                    }
                                  : questionGroupDto,
                              setData: () => {},
                              date: new Date(),
                              moduleOrder: 0,
                              departmentId: props.departmentId,
                            },
                            ...component.moreProps?.values,
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </FormModal>
    </div>
  );
}

const OptionsTable = (props: { id: number; name: string }) => {
  const options = useNfirsGetData(
    async () => ApsServices.http.option.getGroupOptions(props.id),
    "Options"
  );

  useEffect(() => {
    options.getData();
  }, [props.id]);

  const [option, setOption] = useState({} as OptionDto);

  return (
    <div className="pt-2" key={options.data?.length || 0}>
      {options.status === NfirsFetchStatus.InProgress && <div>loading...</div>}
      {options.status === NfirsFetchStatus.Complete && (
        <div>
          <table className="table table-sm">
            <thead className="display-none">
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {options.data?.map((opt) => (
                <tr key={opt.optionId}>
                  <td className="pl-0">
                    {option.optionId === opt.optionId ? (
                      <>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={option.name || ""}
                          onChange={(e) => {
                            setOption({
                              ...opt,
                              name: e.target.value,
                            });
                          }}
                        ></input>
                      </>
                    ) : (
                      <>{opt.name}</>
                    )}
                  </td>
                  <td className="pr-0 text-right no-wrap">
                    {option.optionId === opt.optionId ? (
                      <>
                        <Button
                          variant="primary"
                          size="sm"
                          disabled={`${option.name || ""}`.trim() === ""}
                          onClick={(e) => {
                            ApsServices.http.option
                              .update({ ...option })
                              .then((data) => {
                                options.getData();
                                setOption({} as OptionDto);
                                toastStore.showToast(
                                  "Option Saved.",
                                  "success"
                                );
                              })
                              .catch((error) => {
                                toastStore.showError(
                                  "Failed Saving Option",
                                  error
                                );
                              });
                          }}
                        >
                          <i className="fa fa-save"></i>
                        </Button>
                      </>
                    ) : (
                      <>
                        <i
                          title="Edit Option"
                          className="fa fa-edit text-primary pointer mr-2"
                          onClick={(e) => {
                            setOption({ ...opt });
                          }}
                        ></i>
                        <i
                          title="Delete Option"
                          className="fa fa-trash text-danger pointer"
                          onClick={(e) => {
                            ApsServices.http.option
                              .delete(opt.optionId)
                              .then((data) => {
                                options.getData();
                                setOption({} as OptionDto);
                                toastStore.showToast(
                                  "Option Deleted.",
                                  "success"
                                );
                              })
                              .catch((error) => {
                                toastStore.showError(
                                  "Failed Deleting Option",
                                  error
                                );
                              });
                          }}
                        ></i>
                      </>
                    )}
                  </td>
                </tr>
              ))}
              {!option.optionId && (
                <tr>
                  <td className="pt-2 pl-0">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={option.name || ""}
                      onChange={(e) => {
                        setOption({
                          ...option,
                          name: e.target.value,
                          optionGroupId: props.id,
                        });
                      }}
                    ></input>
                  </td>
                  <td className="pt-2 pr-0 text-right">
                    <Button
                      variant="primary"
                      size="sm"
                      disabled={`${option.name || ""}`.trim() === ""}
                      onClick={(e) => {
                        ApsServices.http.option
                          .save({ ...option })
                          .then((data) => {
                            options.getData();
                            setOption({} as OptionDto);
                            toastStore.showToast("Option Saved.", "success");
                          })
                          .catch((error) => {
                            toastStore.showError("Failed Saving Option", error);
                          });
                      }}
                    >
                      <i className="fa fa-save"></i>
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ComponentGalleryDialog;
