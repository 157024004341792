import React, { Component } from "react";
import { Redirect } from "react-router";
import { AuthenticationService } from "../../services/AuthenticationService";

type Props = {}

type State = {}

export class Logout extends Component<Props, State> {
    static displayName = Logout.name;

    componentDidMount = async () => {
        await AuthenticationService.logout().finally(() => {
            window.location.pathname = "/account/login";
        });
    }

    render() {
        return (
            <></> // <Redirect to="/account/login" />
        );
    }
}