import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import CheckboxList from "../../Common/CheckboxList";
import { FgCheckbox, IChildComponentProps } from "../../Common/FormGroups";
import DTCompanyDialog from "./Dialogs/DTCompanyDialog";
import DTShiftDialog from "./Dialogs/DTShiftDialog";

function DTCompanyShift(
  props: IChildComponentProps<ApsModels.IDrillTemplateOutputDto>
) {
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);
  const [showShiftDialog, setShowShiftDialog] = useState(false);

  const template = (props?.data || {}) as ApsModels.IDrillTemplateOutputDto;
  let companies = template.companies || [];
  let shifts = template.logDrillShifts || [];

  companies.sort(ApsServices.common.sortByName);
  shifts.sort(ApsServices.common.sortByName);

  return (
    <div className="card">
      <div className="card-body">
        <strong>1. Company and Shift</strong>
        <div className="mt-4"></div>

        <FgCheckbox
          id="enableCompany"
          label="Company"
          secondLabel="Enable"
          onChange={(data) => {
            template.isCompanyEnabled = data;
            props?.onChange(template);
          }}
          data={template.isCompanyEnabled}
        />

        <div className="mt-2"></div>

        <CheckboxList
          id="companyList"
          data={companies?.map((c) => {
            return {
              label: c.name,
              value: c.isEnabled,
              id: c.id
            };
          })}
          onChange={(data) => {
            data.forEach((item, i) => {
              companies[i].isEnabled = item.value;
            });
            props?.onChange(template);
          }}
        ></CheckboxList>

        <Button
          variant="link"
          type="button"
          onClick={() => setShowCompanyDialog(true)}
        >
          + Add New Option
        </Button>
        {showCompanyDialog && (
          <DTCompanyDialog
            onChange={(data) => {
              companies.push(data);
              companies.sort(ApsServices.common.sortByName);
              props?.onChange({
                ...template,
                companies: companies,
              });
            }}
            onClose={(saved) => {
              setShowCompanyDialog(false);
            }}
            existingItems={companies?.map((c) => c.name)}
          ></DTCompanyDialog>
        )}

        <div className="mt-4"></div>

        <FgCheckbox
          id="enableShift"
          label="Shift"
          secondLabel="Enable"
          onChange={(data) => {
            template.isShiftEnabled = data;
            props?.onChange(template);
          }}
          data={template.isShiftEnabled}
        />

        <div className="mt-2"></div>

        <CheckboxList
          id="shiftList"
          data={shifts?.map((c) => {
            return {
              label: c.name,
              value: c.isEnabled,
              id: c.id
            };
          })}
          onChange={(data) => {
            data.forEach((item, i) => {
              shifts[i].isEnabled = item.value;
            });
            props?.onChange(template);
          }}
        ></CheckboxList>

        <Button
          variant="link"
          type="button"
          onClick={() => setShowShiftDialog(true)}
        >
          + Add New Option
        </Button>
        {showShiftDialog && (
          <DTShiftDialog
            onChange={(data) => {
              shifts.push(data);
              shifts.sort(ApsServices.common.sortByName);
              props?.onChange({
                ...template,
                logDrillShifts: shifts,
              });
            }}
            onClose={(saved) => {
              setShowShiftDialog(false);
            }}
            existingItems={shifts?.map((c) => c.name)}
          ></DTShiftDialog>
        )}

        <div className="mt-2"></div>
      </div>
    </div>
  );
}

export default DTCompanyShift;
