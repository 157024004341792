import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CredCourseRecord {
  getCourseCategories = async (type: ApsModels.CredLibraryTypeEnum) => {
    const url = `/api/CredCourseRecord/GetCourseCategories/${type}`;
    return fetcher.get<ApsModels.ICredCourseCategoryOutputDto[]>(url);
  };

  getCoursesByNumber = async (type: ApsModels.CredLibraryTypeEnum) => {
    const url = `/api/CredCourseRecord/GetCoursesByNumber/${type}`;
    return fetcher.get<ApsModels.ICredCourseRecordOutputDto[]>(url);
  };

  getCoursesByName = async (type: ApsModels.CredLibraryTypeEnum) => {
    const url = `/api/CredCourseRecord/GetCoursesByName/${type}`;
    return fetcher.get<ApsModels.ICredCourseRecordOutputDto[]>(url);
  };

  listCourseRecords = async (
    page: number,
    pageSize: number,
    data: ApsModels.ICredTakenCourseInputGridFilterDto
  ) => {
    const url = `/api/CredCourseRecord/ListCourseRecords/${page}/${pageSize}`;
    return fetcher.post<ApsModels.ICredTakenCourseOutputGridDto>(url, data);
  };

  getTakenCourse = async (id: number) => {
    const url = `/api/CredCourseRecord/GetTakenCourse/${id}`;
    return fetcher.get<ApsModels.ICredTakenCourseOutputDto>(url);
  };

  saveOwnTakenCourse = async (formData: FormData) => {
    const url = "/api/CredCourseRecord/SaveOwnTakenCourse";
    return fetcher.postFormData<ApsModels.ICredTakenCourseOutputDto>(
      url,
      formData
    );
  };

  adminAddTakenCourse = async (formData: FormData) => {
    const url = "/api/CredCourseRecord/AdminAddTakenCourse";
    return fetcher.postFormData<any>(url, formData);
  };

  adminUpdateTakenCourse = async (formData: FormData) => {
    const url = "/api/CredCourseRecord/AdminUpdateTakenCourse";
    return fetcher.postFormData<ApsModels.ICredTakenCourseOutputDto>(
      url,
      formData
    );
  };

  downloadTakenCourseCertificate = async (id: number, fileId: number) => {
    const url = `/api/CredCourseRecord/DownloadTakenCourseCertificate/${id}/${fileId}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  importTakenCourses = async (formData: FormData) => {
    const url = "/api/CredCourseRecord/ImportTakenCourses";
    return fetcher.postFormData<ApsModels.ICredTakenCourseOutputDto>(
      url,
      formData
    );
  };

  extractCourseRecords = async (
    data: ApsModels.ICredTakenCourseExtractFilterDto
  ) => {
    const url = `/api/CredCourseRecord/ExtractCourseRecords`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  adminDeleteAssignedOrTakenCourse = async (id: number) => {
    const url = `/api/CredCourseRecord/AdminDeleteAssignedOrTakenCourse/${id}`;
    return fetcher.delete<ApsModels.ICredTakenCourseOutputDto>(url);
  };

  adminAddAssignedCourse = async (formData: FormData) => {
    const url = `/api/CredCourseRecord/AdminAddAssignedCourse`;
    return fetcher.postFormData<any>(url, formData);
  };

  adminUpdateAssignedCourse = async (formData: FormData) => {
    const url = "/api/CredCourseRecord/AdminUpdateAssignedCourse";
    return fetcher.postFormData<ApsModels.ICredTakenCourseOutputDto>(
      url,
      formData
    );
  };

  assignOwnCourse = async (formData: FormData) => {
    const url = "/api/CredCourseRecord/AssignOwnCourse";
    return fetcher.postFormData<ApsModels.ICredTakenCourseOutputDto>(
      url,
      formData
    );
  };

  addNewCategory = async (data: ApsModels.ICredCourseCategoryEntryAddDto) => {
    const url = "/api/CredCourseRecord/AddNewCategory";
    return fetcher.post<ApsModels.ICredCourseCategoryOutputDto>(url, data);
  };

  addNewCourse = async (data: ApsModels.ICredCourseInsertDto) => {
    const url = `/api/CredCourseRecord/AddNewCourse`;
    return fetcher.post<ApsModels.ICredCourseRecordOutputDto>(url, data);
  };

  typeAheadCourse = async (data: ApsModels.ICourseTypeAheadDto) => {
    const url = `/api/CredCourseRecord/TypeAheadCourse`;
    return fetcher.post<ApsModels.ICourseTypeAheadOutputDto[]>(url, data);
  };

  typeAheadCourseCategory = async (
    data: ApsModels.ICourseCategoryTypeAheadDto
  ) => {
    const url = `/api/CredCourseRecord/TypeAheadCourseCategory`;
    return fetcher.post<ApsModels.ICredCourseCategoryOutputDto[]>(url, data);
  };

  typeAheadCourseName = async (data: ApsModels.ICourseNameTypeAheadDto) => {
    const url = `/api/CredCourseRecord/TypeAheadCourseName`;
    return fetcher.post<ApsModels.ICourseTypeAheadOutputDto[]>(url, data);
  };

  typeAheadCourseCode = async (data: ApsModels.ICourseCodeTypeAheadDto) => {
    const url = `/api/CredCourseRecord/TypeAheadCourseCode`;
    return fetcher.post<ApsModels.ICourseTypeAheadOutputDto[]>(url, data);
  };
}

const credCourseRecord = new CredCourseRecord();
export default credCourseRecord;
