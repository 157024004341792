import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  FgCheckbox,
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";

function NFIRSFireIgnition(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsFireIgnition.update({
      ...model.new,
      gender: model.new.wasAgeFactor
        ? model.new.gender || (null as any)
        : (null as any),
      estimatedAge: model.new.wasAgeFactor
        ? Number(model.new.estimatedAge) || (null as any)
        : (null as any),
      isNoFactorContributing: model.new.isNoFactorContributing || false,
      isContainedToObjectOfOrigin:
        model.new.isContainedToObjectOfOrigin || false,
      wasImpaired: model.new.wasImpaired || false,
      wasUnattendedPerson: model.new.wasUnattendedPerson || false,
      wasMentallyDisabled: model.new.wasMentallyDisabled || false,
      wasPhysicallyDisabled: model.new.wasPhysicallyDisabled || false,
      wasMultiplePersonsInvolved: model.new.wasMultiplePersonsInvolved || false,
      wasAgeFactor: model.new.wasAgeFactor || false,
      wasAsleep: model.new.wasAsleep || false,
    });
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsFireIgnitionInputDto>({
    objectName: "Ignition",
    nfirsSectionTab: "Fire/Ignition",
    promise: saveAction,
    afterSave: (data) => {
      const mdl = {
        ...model.new,
        concurrencyToken: data.concurrencyToken,
        gender: data.gender || 0,
        estimatedAge: data.estimatedAge || ("" as any),
      };
      initModels(mdl);
      setValuesFromModel(mdl);
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsFireIgnitionInputDto) => {
    if (
      newModel.isNoFactorContributing &&
      contributingFactors.data &&
      contributingFactors.data?.length > 0
    ) {
      const factor = contributingFactors.data.find(
        (x) => x.externalId === "NN"
      );
      if (factor) {
        newModel.contributingFactorEntry1Id = factor.id;
        newModel.contributingFactorEntry2Id = undefined as any;
      }
    }
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Fire", "Ignition", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Fire", "Ignition", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const origins = useNfirsGetData<
    ApsModels.INfirsAreaOfOriginEntryFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getAreaOfOrigins(false),
    "Area of Origins"
  );
  const originSections = useNfirsLookupEnum(
    "NfirsAreaOfOriginSectionEnum",
    "Area of Origin Sections"
  );
  const heatSources = useNfirsGetData<
    ApsModels.INfirsHeatSourceEntryFavoriteOutputDto[]
  >(() => ApsServices.http.nfirsFavorite.getHeatSources(false), "Heat Sources");
  const heatSourceSections = useNfirsLookupEnum(
    "NfirsHeatSourceSectionEnum",
    "Heat Source Sections"
  );
  const itemFirstIgnitedList = useNfirsGetData<
    ApsModels.INfirsItemFirstIgnitedFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getItemsFirstIgnited(false),
    "Item First Ignited List"
  );
  const itemFirstIgnitedSections = useNfirsLookupEnum(
    "NfirsItemFirstIgnitedSectionEnum",
    "Item First Iginited Sections"
  );
  const typesOfMaterials = useNfirsGetData<
    ApsModels.INfirsTypeOfMaterialFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getTypeOfMaterials(false),
    "Types of Materials"
  );
  const typesOfMaterialSections = useNfirsLookupEnum(
    "NfirsTypeOfMaterialSectionEnum",
    "Types of Material Sections"
  );
  const causesOfIgnitions = useNfirsGetData<
    ApsModels.INfirsCauseOfIgnitionEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService.getNfirsCauseOfIgnitionEntries,
    "Causes of Ignitions"
  );
  const contributingFactors = useNfirsGetData<
    ApsModels.INfirsContributingFactorFavoriteOutputDto[]
  >(
    () => ApsServices.http.nfirsFavorite.getContributingFactors(false),
    "Contributing Factors"
  );
  const contributingFactorSections = useNfirsLookupEnum(
    "NfirsContributingFactorSectionEnum",
    "Contributing Factor Sections"
  );

  useEffect(() => {
    setProgress({ loading: true });
    origins.getData();
    originSections.getData();
    heatSources.getData();
    heatSourceSections.getData();
    itemFirstIgnitedList.getData();
    itemFirstIgnitedSections.getData();
    typesOfMaterials.getData();
    typesOfMaterialSections.getData();
    causesOfIgnitions.getData();
    contributingFactors.getData();
    contributingFactorSections.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsFireIgnition
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
            gender: Number(data.gender) || (0 as any),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);

          setReady(true);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Ignition information", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      origins.status === NfirsFetchStatus.Complete &&
      originSections.status === NfirsFetchStatus.Complete &&
      heatSources.status === NfirsFetchStatus.Complete &&
      heatSourceSections.status === NfirsFetchStatus.Complete &&
      itemFirstIgnitedList.status === NfirsFetchStatus.Complete &&
      itemFirstIgnitedSections.status === NfirsFetchStatus.Complete &&
      typesOfMaterials.status === NfirsFetchStatus.Complete &&
      typesOfMaterialSections.status === NfirsFetchStatus.Complete &&
      causesOfIgnitions.status === NfirsFetchStatus.Complete &&
      contributingFactors.status === NfirsFetchStatus.Complete &&
      contributingFactorSections.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    origins.status,
    originSections.status,
    heatSources.status,
    heatSourceSections.status,
    itemFirstIgnitedList.status,
    itemFirstIgnitedSections.status,
    typesOfMaterials.status,
    typesOfMaterialSections.status,
    causesOfIgnitions.status,
    contributingFactors.status,
    contributingFactorSections.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsAreaOfOriginEntryId: {
      displayName: "Area of Fire Origin",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsHeatSourceEntryId: {
      displayName: "Heat Source",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsItemFirstIgnitedEntryId: {
      displayName: "Item First Ignited",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsTypeOfMaterialEntryId: {
      displayName: "Type of Material Ignited",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsCauseOfIgnitionEntryId: {
      displayName: "Cause of Ignition",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    contributingFactorEntry1Id: {
      displayName: "Factors Contributing to Ignition",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    contributingFactorEntry2Id: {
      displayName: "Factors Contributing to Ignition",
      validation: {
        required: false,
      },
    },
    estimatedAge: {
      displayName: "Estimated Age",
      validation: {
        required: false,
        max: 999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    gender: {
      displayName: "Gender",
      validation: {
        required: false,
      },
    },
  });

  const adjustMultiSelectItems = (
    data: ApsModels.INfirsFireIgnitionInputDto
  ) => {
    const items = [
      data.contributingFactorEntry1Id,
      data.contributingFactorEntry2Id,
    ].filter((i) => !!i);
    onModelChange({
      ...model.new,
      ...data,
      contributingFactorEntry1Id: items[0],
      contributingFactorEntry2Id: items[1],
    });
  };

  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!ready) {
      return;
    }
    if (getModel.new) {
      const id = getModel.new.nfirsCauseOfIgnitionEntryId;
      nfirsStore.setCanAcccessModule(
        ApsModels.NfirsValidationModulesEnum.Arson,
        id === 1 || id === 5
        // !!causesOfIgnitions.data?.find(
        //   (item) =>
        //     item.id === id &&
        //     (item.description === "Intentional" ||
        //       item.description === "Cause under investigation")
        // )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getModel.new.nfirsCauseOfIgnitionEntryId, ready]);

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 col-lg-6">
          <strong>Property</strong>

          <div className="mt-3">
            <label
              className={isOtherModuleDataRequired ? "required-label" : ""}
            >
              Area of Fire Origin
            </label>
            <AdvancedDropdown
              domId="nfirsAreaOfOriginEntryId"
              value={model.new.nfirsAreaOfOriginEntryId}
              favoritesHandler={{
                add: ApsServices.http.nfirsFavorite.addFavoriteAreaOfOrigin,
                remove:
                  ApsServices.http.nfirsFavorite.removeFavoriteAreaOfOrigin,
                afterUpdate: (list: any[]) => origins.setData(list),
              }}
              options={(origins.data || []).map((o) => {
                return {
                  label: `${o.description} (${o.externalId})`,
                  value: o.id,
                  originalObject: o,
                  isFavorite: o.isFavorite,
                  groupId: o.nfirsAreaOfOriginSection,
                  subGroupId: 0,
                };
              })}
              groups={(originSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  groupLabel: o.label,
                };
              })}
              subGroups={(originSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  subGroupId: 0,
                };
              })}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  nfirsAreaOfOriginEntryId: Number(data) || (null as any),
                });
              }}
            ></AdvancedDropdown>
          </div>

          <div className="mt-3">
            <label
              className={isOtherModuleDataRequired ? "required-label" : ""}
            >
              Heat Source
            </label>
            <AdvancedDropdown
              domId="nfirsHeatSourceEntryId"
              value={model.new.nfirsHeatSourceEntryId}
              favoritesHandler={{
                add: ApsServices.http.nfirsFavorite.addFavoriteHeatSource,
                remove: ApsServices.http.nfirsFavorite.removeFavoriteHeatSource,
                afterUpdate: (list: any[]) => heatSources.setData(list),
              }}
              options={(heatSources.data || []).map((o) => {
                return {
                  label: `${o.description} (${o.externalId})`,
                  value: o.id,
                  originalObject: o,
                  isFavorite: o.isFavorite,
                  groupId: o.nfirsHeatSourceSection,
                  subGroupId: 0,
                };
              })}
              groups={(heatSourceSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  groupLabel: o.label,
                };
              })}
              subGroups={(heatSourceSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  subGroupId: 0,
                };
              })}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  nfirsHeatSourceEntryId: Number(data) || (null as any),
                });
              }}
            ></AdvancedDropdown>
          </div>

          <div className="mt-3">
            <label
              className={isOtherModuleDataRequired ? "required-label" : ""}
            >
              Item First Ignited
            </label>
            <AdvancedDropdown
              domId="nfirsItemFirstIgnitedEntryId"
              value={model.new.nfirsItemFirstIgnitedEntryId}
              favoritesHandler={{
                add: ApsServices.http.nfirsFavorite.addFavoriteItemFirstIgnited,
                remove:
                  ApsServices.http.nfirsFavorite.removeFavoriteItemFirstIgnited,
                afterUpdate: (list: any[]) =>
                  itemFirstIgnitedList.setData(list),
              }}
              options={(itemFirstIgnitedList.data || []).map((o) => {
                return {
                  label: `${o.description} (${o.externalId})`,
                  value: o.id,
                  originalObject: o,
                  isFavorite: o.isFavorite,
                  groupId: o.nfirsItemFirstIgnitedSection,
                  subGroupId: 0,
                };
              })}
              groups={(itemFirstIgnitedSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  groupLabel: o.label,
                };
              })}
              subGroups={(itemFirstIgnitedSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  subGroupId: 0,
                };
              })}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  nfirsItemFirstIgnitedEntryId: Number(data) || (null as any),
                });
              }}
            ></AdvancedDropdown>
          </div>

          <div className="mt-3">
            <label>Was it confined to object of origin</label>
            {!progress.loading && (
              <div className="inline-radio-box-group">
                <div>
                  <input
                    type="radio"
                    name="isContainedToObjectOfOrigin"
                    id="isContainedToObjectOfOriginYes"
                    value="1"
                    checked={model.new.isContainedToObjectOfOrigin}
                    onChange={(event) => {
                      onModelChange({
                        ...model.new,
                        isContainedToObjectOfOrigin:
                          Number(event.target.value) === 1,
                      });
                    }}
                  ></input>
                  <label htmlFor="isContainedToObjectOfOriginYes">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="isContainedToObjectOfOrigin"
                    id="isContainedToObjectOfOriginNo"
                    value="0"
                    checked={!model.new.isContainedToObjectOfOrigin}
                    onChange={(event) => {
                      onModelChange({
                        ...model.new,
                        isContainedToObjectOfOrigin:
                          Number(event.target.value) === 1,
                      });
                    }}
                  ></input>
                  <label htmlFor="isContainedToObjectOfOriginNo">No</label>
                </div>
              </div>
            )}
          </div>

          <div className="mt-3">
            <label>Type of Material Ignited</label>
            <AdvancedDropdown
              domId="nfirsTypeOfMaterialEntryId"
              value={model.new.nfirsTypeOfMaterialEntryId}
              favoritesHandler={{
                add: ApsServices.http.nfirsFavorite.addFavoriteTypeOfMaterial,
                remove:
                  ApsServices.http.nfirsFavorite.removeFavoriteTypeOfMaterial,
                afterUpdate: (list: any[]) => typesOfMaterials.setData(list),
              }}
              options={(typesOfMaterials.data || []).map((o) => {
                return {
                  label: `${o.description} (${o.externalId})`,
                  value: o.id,
                  originalObject: o,
                  isFavorite: o.isFavorite,
                  groupId: o.nfirsTypeOfMaterialSection,
                  subGroupId: 0,
                };
              })}
              groups={(typesOfMaterialSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  groupLabel: o.label,
                };
              })}
              subGroups={(typesOfMaterialSections.data || []).map((o) => {
                return {
                  groupId: o.value,
                  subGroupId: 0,
                };
              })}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  nfirsTypeOfMaterialEntryId: Number(data) || (null as any),
                });
              }}
            ></AdvancedDropdown>
          </div>

          <div className="mt-3">
            <FgSelect
              id="nfirsCauseOfIgnitionEntryId"
              label="Cause of Ignition"
              selectMessage="Select cause of ignition"
              registeredField={registry.nfirsCauseOfIgnitionEntryId}
              formState={formState}
              showRequiredLabel={isOtherModuleDataRequired}
              options={(causesOfIgnitions.data || []).map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsCauseOfIgnitionEntryId: Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="mt-1" style={{ lineHeight: "15px", opacity: 0 }}>
            -
          </div>
          <div className=""></div>

          <div className="mt-3">
            <label>Has Contributing Factors</label>
            {!progress.loading && (
              <div className="inline-radio-box-group">
                <div>
                  <input
                    type="radio"
                    name="isNoFactorContributing"
                    id="isNoFactorContributingYes"
                    value="1"
                    checked={!model.new.isNoFactorContributing}
                    onChange={(event) => {
                      onModelChange({
                        ...model.new,
                        isNoFactorContributing:
                          Number(event.target.value) === 0,
                      });
                    }}
                  ></input>
                  <label htmlFor="isNoFactorContributingYes">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="isNoFactorContributing"
                    id="isNoFactorContributingNo"
                    value="0"
                    checked={model.new.isNoFactorContributing}
                    onChange={(event) => {
                      onModelChange({
                        ...model.new,
                        isNoFactorContributing:
                          Number(event.target.value) === 0,
                      });
                    }}
                  ></input>
                  <label htmlFor="isNoFactorContributingNo">No</label>
                </div>
              </div>
            )}
          </div>

          <div className="mt-3">
            <label
              className={isOtherModuleDataRequired ? "required-label" : ""}
            >
              Factors Contributing to Ignition
            </label>

            <div className="actionTakenList">
              {[
                getModel?.new?.contributingFactorEntry1Id,
                getModel?.new?.contributingFactorEntry2Id,
              ].map((item, i) => (
                <React.Fragment key={i}>
                  {item && (
                    <div>
                      <div>
                        {
                          contributingFactors.data?.find((a) => item === a.id)
                            ?.description
                        }{" "}
                        (
                        {
                          contributingFactors.data?.find((a) => item === a.id)
                            ?.externalId
                        }
                        )
                      </div>
                      <div>
                        {!model.new.isNoFactorContributing && (
                          <i
                            className="fa fa-times pointer"
                            onClick={() => {
                              if (i === 0) {
                                adjustMultiSelectItems({
                                  ...model.new,
                                  contributingFactorEntry1Id: null as any,
                                });
                              }
                              if (i === 1) {
                                adjustMultiSelectItems({
                                  ...model.new,
                                  contributingFactorEntry2Id: null as any,
                                });
                              }
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>

            {(!getModel?.new?.contributingFactorEntry1Id ||
              !getModel?.new?.contributingFactorEntry2Id) &&
              !model.new.isNoFactorContributing && (
                <AdvancedDropdown
                  domId="contributingFactorEntryIds"
                  value={null}
                  searchPlaceholder="Search and Add More Factors"
                  disabledOptionValues={[
                    getModel?.new?.contributingFactorEntry1Id,
                    getModel?.new?.contributingFactorEntry2Id,
                  ]}
                  favoritesHandler={{
                    add: ApsServices.http.nfirsFavorite
                      .addFavoriteContributingFactor,
                    remove:
                      ApsServices.http.nfirsFavorite
                        .removeFavoriteContributingFactor,
                    afterUpdate: (list: any[]) =>
                      contributingFactors.setData(list),
                  }}
                  options={(contributingFactors.data || []).map((o) => {
                    return {
                      label: `${o.description} (${o.externalId})`,
                      value: o.id,
                      originalObject: o,
                      isFavorite: o.isFavorite,
                      groupId: o.nfirsContributingFactorSection,
                      subGroupId: 0,
                    };
                  })}
                  groups={(contributingFactorSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      groupLabel: o.label,
                    };
                  })}
                  subGroups={(contributingFactorSections.data || []).map(
                    (o) => {
                      return {
                        groupId: o.value,
                        subGroupId: 0,
                      };
                    }
                  )}
                  onChange={(data) => {
                    if (!getModel?.new?.contributingFactorEntry1Id) {
                      onModelChange({
                        ...model.new,
                        contributingFactorEntry1Id:
                          Number(data) || (null as any),
                      });
                    } else if (!getModel?.new?.contributingFactorEntry2Id) {
                      onModelChange({
                        ...model.new,
                        contributingFactorEntry2Id:
                          Number(data) || (null as any),
                      });
                    }
                  }}
                ></AdvancedDropdown>
              )}
          </div>

          <div className="mt-3">
            <label>
              Human Factors Contributing to Ignition (Check All that Appply)
            </label>

            {!progress.loading && (
              <>
                <div className="mt-2"></div>
                <FgCheckbox
                  id="wasAsleep"
                  label="Asleep"
                  data={model.new.wasAsleep}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      wasAsleep: data,
                    });
                  }}
                ></FgCheckbox>
                <div className="mt-2"></div>
                <FgCheckbox
                  id="wasImpaired"
                  label="Possibly impaired by alcohol or drugs"
                  data={model.new.wasImpaired}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      wasImpaired: data,
                    });
                  }}
                ></FgCheckbox>
                <div className="mt-2"></div>
                <FgCheckbox
                  id="wasUnattendedPerson"
                  label="Unattended person"
                  data={model.new.wasUnattendedPerson}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      wasUnattendedPerson: data,
                    });
                  }}
                ></FgCheckbox>
                <div className="mt-2"></div>
                <FgCheckbox
                  id="wasMentallyDisabled"
                  label="Possibly mentally disabled"
                  data={model.new.wasMentallyDisabled}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      wasMentallyDisabled: data,
                    });
                  }}
                ></FgCheckbox>

                <div className="mt-2"></div>
                <FgCheckbox
                  id="wasPhysicallyDisabled"
                  label="Physically disabled"
                  data={model.new.wasPhysicallyDisabled}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      wasPhysicallyDisabled: data,
                    });
                  }}
                ></FgCheckbox>

                <div className="mt-2"></div>
                <FgCheckbox
                  id="wasMultiplePersonsInvolved"
                  label="Multiple persons involved"
                  data={model.new.wasMultiplePersonsInvolved}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      wasMultiplePersonsInvolved: data,
                    });
                  }}
                ></FgCheckbox>

                <div className="mt-2"></div>
                <FgCheckbox
                  id="wasAgeFactor"
                  label="Age was a factor"
                  data={model.new.wasAgeFactor}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      wasAgeFactor: data,
                    });
                  }}
                ></FgCheckbox>

                <div
                  className={`mt-3 row ${
                    model.new.wasAgeFactor ? "" : "display-none"
                  }`}
                >
                  <div className="col-sm-12 col-md-6 pb-2">
                    <FgInput
                      id="estimatedAge"
                      label="Estimated Age"
                      placeHolder="Estimated Age"
                      registeredField={registry.estimatedAge}
                      formState={formState}
                      onChange={(data) => {
                        onModelChange({
                          ...model.new,
                          estimatedAge: Number(data) || (null as any),
                        });
                      }}
                      type="number"
                      disabled={!model.new.wasAgeFactor}
                    ></FgInput>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <FgSelect
                      id="gender"
                      label="Gender"
                      selectMessage="Gender"
                      selectMessageValue="0"
                      registeredField={registry.gender}
                      formState={formState}
                      options={[
                        {
                          description: "Female",
                          id: ApsModels.NfirsJuvenileMaleFemaleEnum.Female,
                        },
                        {
                          description: "Male",
                          id: ApsModels.NfirsJuvenileMaleFemaleEnum.Male,
                        },
                      ].map((o) => {
                        return {
                          label: `${o.description}`,
                          value: o.id,
                        };
                      })}
                      onChange={(data) =>
                        onModelChange({
                          ...model.new,
                          gender: Number(data) || (0 as any),
                        })
                      }
                      disabled={!model.new.wasAgeFactor}
                    ></FgSelect>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSFireIgnition;
