import moment from 'moment';
import {
    CalendarDto,
    FormCalendarDto,
    FormDto,
    PreviousDayDto,
    QuestionGroupCalendarDto,
    QuestionGroupDto
} from '../data/DailyOperational';

class FormService {
    async getDailyOperationalForm(date: Date, departmentId?: number): Promise<FormDto> {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ date: date, departmentId: departmentId }),
        };
        const response = await fetch(`/Api/Form/DailyOperational`, options);
        const formDto = await response.json();
        return formDto as FormDto;
    }

    async createCalendar(formCalendarDto: FormCalendarDto): Promise<CalendarDto> {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formCalendarDto),
        };
        const response = await fetch(`/Api/Form/Calendar/Create`, options);
        const result = await response.json();
        return result as CalendarDto;
    }

    async updateQuestionGroups(questionGroupCalendarDto: QuestionGroupCalendarDto): Promise<QuestionGroupDto[]> {
        const options: RequestInit = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(questionGroupCalendarDto),
        };
        const response = await fetch(`/Api/Form/QuestionGroup/Update`, options);
        const result = await response.json();
        return result as QuestionGroupDto[];
    }

    async pullFromPreviousDay(previousDayDto: PreviousDayDto): Promise<QuestionGroupDto> {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(previousDayDto),
        };

        const response = await fetch(`/Api/Form/PullFromPreviousDay`, options);
        const questionGroupDto = await response.json();
        return questionGroupDto as QuestionGroupDto;
    }
}

const formService = new FormService();
export { formService as FormService }
