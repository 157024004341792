import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import { FgSelect, FgUseForm, useFgModel } from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import { reaction } from "mobx";

function NFIRSEntryDetector(props: INFIRSTabProps<any>) {
  const showOthers = () => {
    return (
      (model?.new?.nfirsStructureFireDetectorPresenceEntryId || null) === 2
    );
  };

  const saveAction = () => {
    //do model pre-processing here

    const data = { ...model.new };

    if (showOthers()) {
      if (data.nfirsStructureFireDetectorOperationEntryId === 2) {
        data.nfirsStructureFireDetectorFailureReasonEntryId = undefined as any;
        setValue("nfirsStructureFireDetectorFailureReasonEntryId", undefined);
      } else if (data.nfirsStructureFireDetectorOperationEntryId === 3) {
        data.nfirsStructureFireDetectorEffectivenessEntryId = undefined as any;
        setValue("nfirsStructureFireDetectorEffectivenessEntryId", undefined);
      } else {
        data.nfirsStructureFireDetectorEffectivenessEntryId = undefined as any;
        data.nfirsStructureFireDetectorFailureReasonEntryId = undefined as any;
        setValue("nfirsStructureFireDetectorEffectivenessEntryId", undefined);
        setValue("nfirsStructureFireDetectorFailureReasonEntryId", undefined);
      }
    } else {
      data.nfirsStructureFireDetectorEffectivenessEntryId = undefined as any;
      data.nfirsStructureFireDetectorFailureReasonEntryId = undefined as any;
      data.nfirsStructureFireDetectorTypeEntryId = undefined as any;
      data.nfirsStructureFireDetectorPowerSupplyEntryId = undefined as any;
      data.nfirsStructureFireDetectorOperationEntryId = undefined as any;
      setValue("nfirsStructureFireDetectorEffectivenessEntryId", undefined);
      setValue("nfirsStructureFireDetectorFailureReasonEntryId", undefined);
      setValue("nfirsStructureFireDetectorTypeEntryId", undefined);
      setValue("nfirsStructureFireDetectorPowerSupplyEntryId", undefined);
      setValue("nfirsStructureFireDetectorOperationEntryId", undefined);
    }

    return ApsServices.http.nfirsStructureFireDetector.update(data);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsStructureFireDetectorInputDto>({
    objectName: "Detector",
    nfirsSectionTab: "Structure Fire/Detector",
    promise: saveAction,
    afterSave: (data) => {
      initModels({
        ...model.new,
        concurrencyToken: data.concurrencyToken,
        nfirsStructureFireDetectorEffectivenessEntryId:
          data.nfirsStructureFireDetectorEffectivenessEntryId,
        nfirsStructureFireDetectorFailureReasonEntryId:
          data.nfirsStructureFireDetectorFailureReasonEntryId,
        nfirsStructureFireDetectorTypeEntryId:
          data.nfirsStructureFireDetectorTypeEntryId,
        nfirsStructureFireDetectorPowerSupplyEntryId:
          data.nfirsStructureFireDetectorPowerSupplyEntryId,
        nfirsStructureFireDetectorOperationEntryId:
          data.nfirsStructureFireDetectorOperationEntryId,
      });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsStructureFireDetectorInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Structure Fire",
    "Detector",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Structure Fire", "Detector", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const presenceList = useNfirsGetData<
    ApsModels.INfirsStructureFireDetectorPresenceEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getStructureFireDetectorPresenceEntries,
    "Presence of Detectors"
  );
  const types = useNfirsGetData<
    ApsModels.INfirsStructureFireDetectorTypeEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getStructureFireDetectorTypeEntries,
    "Detector Types"
  );
  const powerSupplies = useNfirsGetData<
    ApsModels.INfirsStructureFireDetectorPowerSupplyEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getStructureFireDetectorPowerSupplyEntries,
    "Detector Power Supplies"
  );
  const operations = useNfirsGetData<
    ApsModels.INfirsStructureFireDetectorOperationEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getStructureFireDetectorOperationEntries,
    "Detector Operations"
  );
  const effectivenessList = useNfirsGetData<
    ApsModels.INfirsStructureFireDetectorOperationEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getStructureFireDetectorEffectivenessEntries,
    "Detector Effectiveness List"
  );
  const failureReasons = useNfirsGetData<
    ApsModels.INfirsStructureFireDetectorOperationEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getStructureFireDetectorFailureReasonEntries,
    "Detector Failure Reasons"
  );

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();
  const [requiredFieldEnforced, setRequiredFieldEnforced] = useState(
    nfirsStore.nfirsStructureFireRequiredEnforced
  );

  useEffect(() => {
    setProgress({ loading: true });
    presenceList.getData();
    types.getData();
    powerSupplies.getData();
    operations.getData();
    effectivenessList.getData();
    failureReasons.getData();

    const disposer = reaction(
      () => nfirsStore.nfirsStructureFireRequiredEnforced,
      (n, p, r) => {
        setRequiredFieldEnforced(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsStructureFireDetector
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Detector Info", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      presenceList.status === NfirsFetchStatus.Complete &&
      types.status === NfirsFetchStatus.Complete &&
      powerSupplies.status === NfirsFetchStatus.Complete &&
      operations.status === NfirsFetchStatus.Complete &&
      effectivenessList.status === NfirsFetchStatus.Complete &&
      failureReasons.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    presenceList.status,
    types.status,
    powerSupplies.status,
    operations.status,
    effectivenessList.status,
    failureReasons.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    nfirsStructureFireDetectorPresenceEntryId: {
      displayName: "Presence of Detectors",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (requiredFieldEnforced && cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsStructureFireDetectorTypeEntryId: {
      displayName: "Detector Type",
      validation: {
        required: false,
      },
    },
    nfirsStructureFireDetectorPowerSupplyEntryId: {
      displayName: "Detector Power Supply",
      validation: {
        required: false,
      },
    },
    nfirsStructureFireDetectorOperationEntryId: {
      displayName: "Detector Operation",
      validation: {
        required: false,
      },
    },
    nfirsStructureFireDetectorEffectivenessEntryId: {
      displayName: "Detector Effectiveness",
      validation: {
        required: false,
      },
    },
    nfirsStructureFireDetectorFailureReasonEntryId: {
      displayName: "Detector Failure Reason",
      validation: {
        required: false,
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 ">
          <strong>Detector Operation</strong>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div>
            <FgSelect
              id="nfirsStructureFireDetectorPresenceEntryId"
              label="Presence of Detector"
              selectMessage="Select Presence of Detector"
              registeredField={
                registry.nfirsStructureFireDetectorPresenceEntryId
              }
              formState={formState}
              showRequiredLabel={
                isOtherModuleDataRequired && requiredFieldEnforced
              }
              options={(presenceList.data || []).map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsStructureFireDetectorPresenceEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>

            <div className={`mt-3 ${showOthers() ? "" : "display-none"}`}>
              <FgSelect
                id="nfirsStructureFireDetectorTypeEntryId"
                label="Detector Type"
                selectMessage="Select Type"
                registeredField={registry.nfirsStructureFireDetectorTypeEntryId}
                formState={formState}
                showRequiredLabel={true}
                options={(types.data || []).map((o) => {
                  return {
                    label: `${o.externalId}. ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsStructureFireDetectorTypeEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className={`mt-3 ${showOthers() ? "" : "display-none"}`}>
              <FgSelect
                id="nfirsStructureFireDetectorPowerSupplyEntryId"
                label="Detector Power Supply"
                selectMessage="Select Detector Power Supply"
                registeredField={
                  registry.nfirsStructureFireDetectorPowerSupplyEntryId
                }
                formState={formState}
                showRequiredLabel={true}
                options={(powerSupplies.data || []).map((o, i) => {
                  return {
                    label: `${o.externalId}. ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsStructureFireDetectorPowerSupplyEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>

          <div className="mt-3"></div>
        </div>
        <div
          className={`${
            showOthers() ? "" : "display-none"
          } col-sm-12 col-lg-6 mt-3`}
        >
          <div>
            <FgSelect
              id="nfirsStructureFireDetectorOperationEntryId"
              label="Detector Operation"
              selectMessage="Select Detector Operation"
              registeredField={
                registry.nfirsStructureFireDetectorOperationEntryId
              }
              formState={formState}
              showRequiredLabel={true}
              options={(operations.data || []).map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsStructureFireDetectorOperationEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>

            <div
              className={`mt-3 ${
                (model?.new?.nfirsStructureFireDetectorOperationEntryId ||
                  null) !== 2
                  ? "display-none"
                  : ""
              }`}
            >
              <FgSelect
                id="nfirsStructureFireDetectorEffectivenessEntryId"
                label="Detector Effectiveness"
                selectMessage="Select Detector Effectiveness"
                registeredField={
                  registry.nfirsStructureFireDetectorEffectivenessEntryId
                }
                formState={formState}
                showRequiredLabel={isOtherModuleDataRequired}
                options={(effectivenessList.data || []).map((o) => {
                  return {
                    label: `${o.externalId}. ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsStructureFireDetectorEffectivenessEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
            <div
              className={`mt-3 ${
                (model?.new?.nfirsStructureFireDetectorOperationEntryId ||
                  null) !== 3
                  ? "display-none"
                  : ""
              }`}
            >
              <FgSelect
                id="nfirsStructureFireDetectorFailureReasonEntryId"
                label="Detector Failure Reason"
                selectMessage="Select Reason"
                registeredField={
                  registry.nfirsStructureFireDetectorFailureReasonEntryId
                }
                formState={formState}
                showRequiredLabel={isOtherModuleDataRequired}
                options={(failureReasons.data || []).map((o, i) => {
                  return {
                    label: `${o.externalId}. ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsStructureFireDetectorFailureReasonEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSEntryDetector;
