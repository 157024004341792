import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";
import DateTimePickerV2 from "../../../Common/DateTimePickerV2";
import CheckboxList from "../../../Common/CheckboxList";
import FormError from "../../../Common/FormError";

function NFIRSArsonJuvenile(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsArsonJuvenile.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsArsonJuvenileInputDto>({
    objectName: "Juvenile",
    nfirsSectionTab: "Arson/Juvenile",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsArsonJuvenileInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("Arson", "Juvenile", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("Arson", "Juvenile", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const genders = useNfirsLookupEnum("NfirsJuvenileMaleFemaleEnum", "Genders");
  const ethnicities = useNfirsLookupEnum(
    "NfirsJuvenileEthnicityEnum",
    "Ethnicities"
  );

  const races = useNfirsGetData<ApsModels.INfirsJuvenileRaceEntryOutputDto[]>(
    ApsServices.http.nfirsGenericLookupService.getNfirsJuvenileRaceEntries,
    "Races"
  );
  const familyTypes = useNfirsGetData<
    ApsModels.INfirsJuvenileFamilyTypeEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsJuvenileFamilyTypeEntryRaceEntries,
    "Family Types"
  );
  const riskFactors = useNfirsGetData<
    ApsModels.INfirsJuvenileRiskFactorEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsJuvenileRiskFactorEntries,
    "Risk Factors"
  );
  const dispositions = useNfirsGetData<
    ApsModels.INfirsJuvenileDispositionEntryOutputDto[]
  >(
    ApsServices.http.nfirsGenericLookupService
      .getNfirsJuvenileDispositionEntries,
    "Juvenile Disposition Entries"
  );

  useEffect(() => {
    setProgress({ loading: true });
    genders.getData();
    ethnicities.getData();
    races.getData();
    familyTypes.getData();
    riskFactors.getData();
    dispositions.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dateOfBirth, setDateOfBirth] = useState<Date>();

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsArsonJuvenile
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          setDateOfBirth(data.dateOfBirth);

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Investigation Info", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      genders.status === NfirsFetchStatus.Complete &&
      ethnicities.status === NfirsFetchStatus.Complete &&
      races.status === NfirsFetchStatus.Complete &&
      familyTypes.status === NfirsFetchStatus.Complete &&
      riskFactors.status === NfirsFetchStatus.Complete &&
      dispositions.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    genders.status,
    ethnicities.status,
    races.status,
    familyTypes.status,
    riskFactors.status,
    dispositions.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    subjectNumber: {
      displayName: "Subject Number",
      validation: {
        required: false,
        max: 999,
      },
    },
    age: {
      displayName: "Age",
      validation: {
        required: false,
        max: 18,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    dateOfBirth: {
      displayName: "Date Of Birth",
      validation: {
        required: false,
      },
    },
    gender: {
      displayName: "Gender",
      validation: {
        required: false,
      },
    },
    nfirsJuvenileRaceEntryId: {
      displayName: "Race",
      validation: {
        required: false,
      },
    },
    ethnicity: {
      displayName: "Ethnicity",
      validation: {
        required: false,
      },
    },
    nfirsJuvenileFamilyTypeEntryId: {
      displayName: "Family Type",
      validation: {
        required: false,
      },
    },
    nfirsJuvenileDispositionEntryId: {
      displayName: "Disposition of Person Under 18",
      validation: {
        required: false,
      },
    },
    riskFactorIds: {
      displayName: "Motivation/Risk Factors",
      validation: {
        required: false,
      },
    },
    _dateOfBirth: { ...commonService.forms.datePicker },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      {!progress.loading && (
        <form
          className={`row ${progress.loading ? "display-none" : ""}`}
          onSubmit={handleSubmit(submit)}
          ref={formRef}
        >
          <div className="col-sm-12 mb-3">
            <strong>Juvenile</strong>
          </div>

          <div className="col-sm-12 col-lg-6">
            <FgInput
              id="subjectNumber"
              label="Subject Number"
              placeHolder="Subject Number"
              registeredField={registry.subjectNumber}
              formState={formState}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  subjectNumber: Number(data) || (null as any),
                });
              }}
              type="number"
            ></FgInput>

            <div className="row mt-3">
              <div className="col-sm-3">
                <FgInput
                  id="age"
                  label="Age"
                  placeHolder="Age"
                  registeredField={registry.age}
                  formState={formState}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      age: Number(data) || (null as any),
                    });
                  }}
                  type="number"
                ></FgInput>
              </div>
              <div className="col-sm-9">
                <label>Date</label>
                <DateTimePickerV2
                  data={dateOfBirth}
                  onChange={(data, invalid) => {
                    onModelChange({
                      ...model.new,
                      dateOfBirth: data
                        ? moment(data).format("YYYY-MM-DD") //.startOf("day")
                        : null,
                      _dateOfBirth: invalid,
                    } as any);
                    setValue("_dateOfBirth", invalid);
                  }}
                  dateOnly={true}
                ></DateTimePickerV2>
                <FormError
                  field={registry._dateOfBirth.name}
                  formState={formState}
                  fieldDisplayName="Date of Birth"
                ></FormError>
              </div>
            </div>

            <div>
              <FgSelect
                id="gender"
                label="Gender"
                selectMessage="Select Gender"
                registeredField={registry.gender}
                formState={formState}
                options={(genders.data || []).map((o) => {
                  return {
                    label: `${o.value}. ${o.label}`,
                    value: o.value,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    gender: Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>

            <div className="mt-3">
              <FgSelect
                id="nfirsJuvenileRaceEntryId"
                label="Race"
                selectMessage="Select Race"
                registeredField={registry.nfirsJuvenileRaceEntryId}
                formState={formState}
                options={(races.data || []).map((o) => {
                  return {
                    label: `${o.externalId} ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsJuvenileRaceEntryId: Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>

            <div className="mt-3">
              <FgSelect
                id="ethnicity"
                label="Ethnicity"
                selectMessage="Select Ethnicity"
                registeredField={registry.ethnicity}
                formState={formState}
                options={(ethnicities.data || []).map((o) => {
                  return {
                    label: `${o.value} ${o.label}`,
                    value: o.value,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    ethnicity: data === "" ? (null as any) : Number(data),
                  })
                }
              ></FgSelect>
            </div>

            <div>
              <FgSelect
                id="nfirsJuvenileFamilyTypeEntryId"
                label="Family Type"
                selectMessage="Select Family Type"
                registeredField={registry.nfirsJuvenileFamilyTypeEntryId}
                formState={formState}
                options={(familyTypes.data || []).map((o) => {
                  return {
                    label: `${o.externalId} ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsJuvenileFamilyTypeEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <div>
              <FgSelect
                id="nfirsJuvenileDispositionEntryId"
                label="Disposition of Person Under 18"
                selectMessage="Select Disposition"
                registeredField={registry.nfirsJuvenileDispositionEntryId}
                formState={formState}
                options={(dispositions.data || []).map((o) => {
                  return {
                    label: `${o.externalId} ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsJuvenileDispositionEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>

            <div className="mb-3">
              <div className="mb-2">Motivation/Risk Factors</div>

              <CheckboxList
                id="riskFactorIds1"
                noContainer={true}
                singleSelectOnly={true}
                data={(riskFactors.data || [])
                  .filter((o) => o.id <= 3)
                  .map((o) => {
                    return {
                      label: o.description,
                      value: (model.new.riskFactorIds || []).indexOf(o.id) > -1,
                      id: o.id,
                    };
                  })}
                onChange={(data) => {
                  const idsAbove3 = (model.new.riskFactorIds || []).filter(
                    (o) => o > 3
                  );
                  onModelChange({
                    ...model.new,
                    riskFactorIds: [
                      ...data.filter((o) => o.value).map((o) => o.id),
                      ...idsAbove3,
                    ],
                  });
                }}
              ></CheckboxList>
              <div className="mt-3"></div>
              <CheckboxList
                id="riskFactorIds2"
                noContainer={true}
                data={(riskFactors.data || [])
                  .filter((o) => o.id > 3)
                  .map((o) => {
                    return {
                      label: o.description,
                      value: (model.new.riskFactorIds || []).indexOf(o.id) > -1,
                      id: o.id,
                    };
                  })}
                onChange={(data) => {
                  const ids1to3 = (model.new.riskFactorIds || []).filter(
                    (o) => o <= 3
                  );
                  onModelChange({
                    ...model.new,
                    riskFactorIds: [
                      ...ids1to3,
                      ...data.filter((o) => o.value).map((o) => o.id),
                    ],
                  });
                }}
              ></CheckboxList>
            </div>
          </div>

          <button type="submit" hidden></button>
        </form>
      )}
    </>
  );
}

export default NFIRSArsonJuvenile;
