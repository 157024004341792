import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSBasicPropertyService {
  get = async (id: any) => {
    const url = `/api/NfirsBasicProperty/${id}`;
    return fetcher.get<ApsModels.INfirsBasicPropertyOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsBasicPropertyInputDto) => {
    const url = `/api/NfirsBasicProperty`;
    return fetcher.post<ApsModels.INfirsBasicPropertyOutputDto>(url, data);
  };

  getPropertyUseTypes = async (isFavorite = false) => {
    const url = `/api/NfirsBasicProperty/GetPropertyUseTypes/${isFavorite}`;
    return fetcher.get<ApsModels.INfirsPropertyUseTypeFavoriteOutputDto[]>(url);
  };

  addFavoritePropertyUseType = async (
    data: ApsModels.INfirsPropertyUseTypeFavoriteInputDto
  ) => {
    const url = `/api/NfirsBasicProperty/AddFavoritePropertyUseType`;
    return fetcher.post<ApsModels.INfirsPropertyUseTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };

  removeFavoritePropertyUseType = async (
    data: ApsModels.INfirsPropertyUseTypeFavoriteInputDto
  ) => {
    const url = `/api/NfirsBasicProperty/RemoveFavoritePropertyUseType`;
    return fetcher.post<ApsModels.INfirsPropertyUseTypeFavoriteOutputDto[]>(
      url,
      data
    );
  };
}

const nfirsBasicPropertyService = new NFIRSBasicPropertyService();
export default nfirsBasicPropertyService;
