/**
 * This component was created in reference to SmallConferenceRoom.tsx
 */
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import {
  AnswerDto,
  AnswerType,
  QuestionDto,
  QuestionGroupDto,
  SaveTimeoutInMilliseconds,
} from "../../../../data/DailyOperational";

interface IGenericTextAreaProps {
  questionGroup?: QuestionGroupDto;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  date: Date;
  moduleOrder: number;
}

interface IGenericTextAreaState {
  text: string;
  typingTimeout: ReturnType<typeof setTimeout> | null;
}

function GenericTextArea(props: IGenericTextAreaProps) {
  const context = useContext(AppContext);
  const [state, setState] = useState<Partial<IGenericTextAreaState>>({
    text: "",
    typingTimeout: null,
  });

  useEffect(() => {
    const answer = props.questionGroup?.questions[0]?.answers[0];
    if (answer !== undefined) {
      setState({
        text: answer.textAnswerValue || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateQuestionGroup = (newQuestionValue: QuestionDto) => {
    const questionGroup = cloneDeep(props.questionGroup)!;
    const questionIndex = questionGroup.questions.findIndex(
      (q) => q.order == newQuestionValue.order
    );
    questionGroup.questions[questionIndex] = newQuestionValue;
    props.setData(questionGroup, props.moduleOrder);
  };

    useEffect(() => {
        const tmo = setTimeout(() => updateAnswer(), SaveTimeoutInMilliseconds);
        return () => {
            clearTimeout(tmo);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.text]);

    const handleTextChanged = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setState({
            text: e.target.value,
        });
    };

  const updateAnswer = () => {
    const questionOrder = 0;
    const question: QuestionDto = cloneDeep(
      props.questionGroup!.questions.find((q) => q.order == questionOrder)!
    );
    const isAnswerExists = question.answers.length > 0;
    const answerId: number = question!.answers[0]
      ? question!.answers[0].answerId!
      : 0;

    let answer: AnswerDto;

    if (isAnswerExists || answerId > 0) {
      answer = question.answers.find((a) => a.answerId == answerId)!;
      answer.textAnswerValue = state.text;
      answer.date = props.date;
      answer.dateCreated = props.date;
      answer.dateUpdated = props.date;
    } else {
      answer = {
        answerId: answerId,
        date: props.date,
        dateCreated: props.date,
        dateUpdated: props.date,
        flagAnswerValue: false,
        order: 0,
        answerType: AnswerType.Text,
        questionId: question.questionId ?? 0,
        textAnswerValue: state.text,
      };
      question.answers.push(answer);
    }

    updateQuestionGroup(question);
  };

  return (
    <div className="grid-item card box-card ">
      <div className="card-body" >
        <h4 className="mb-0 font-size-16">{props.questionGroup?.name || ""}</h4>
        <div className="mt-4"></div>
        <textarea
          disabled={!context.isEditableDailyOperational}
          className="form-control"
          value={state.text}
          rows={5}
          onChange={handleTextChanged}
        />
      </div>
      <div className="d-none childsection5">
        <h4 className="mb-0 font-size-22"> <b>{props.questionGroup?.name || ""}</b></h4>
        <div className="mt-2 "></div>
             <span className="font-size-20">{state.text}</span>
         </div>
    </div>
  );
}

export default GenericTextArea;
