import { faEye, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UserService } from "../../../services/UserService";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import Pagination, { usePaging } from "../Common/Pagination";
import CredentialImportDialog from "./CredentialImportDialog";
import { RouteComponentProps } from "react-router-dom";
import CredentialStatusCellLabel from "./CredentialStatusCellLabel";
import CredentialExpirationDateCell from "./CredentialExpirationDateCell";
import { GetCredStatusEnumStatusLabel } from "./CredentialList";
import { useGridFilters } from "../../stores/SystemStore";
import LoadMoreIndicator from "../Common/LoadMoreIndicator";

function CredentialListForEmployee(
  props: RouteComponentProps<any> & { employeeId: any }
) {
  const [claims] = useState(commonService.friendlyClaims);
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentId > 0) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [list, setList] =
    useState<ApsModels.ICredCredentialForEmployeeGridDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridFilters.init(params);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && list) {
      setTimeout(() => {
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach(function (column: any) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
        gridApi.sizeColumnsToFit();
      }, 500);
    }
  };

  const AttachmentCellComponent = (rowProps: {
    data: ApsModels.ICredTakenCourseGridItemOutputDto;
  }) => {
    if (rowProps.data.hasAttachment) {
      return <FontAwesomeIcon icon={faFile} />;
    }
    return null;
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialForEmployeeGridItemDto;
  }) => {
    if (claims.credentials.canViewAll) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-outlined text-primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // if (
            //   rowProps.data.credCategoryTypeEnum ===
            //   ApsModels.CredCategoryTypeEnum.ExistingExternalCredential
            // ) {
            //   props.history.push(`/Credential/${rowProps.data.id}`);
            // } else {
            //   props.history.push(`/credentials/view/${rowProps.data.id}`);
            // }
            props.history.push(`/credentials/view/${rowProps.data.id}`);
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
      );
    }
    return <span></span>;
  };

  const NameCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialForEmployeeGridItemDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // if (
          //   rowProps.data.credCategoryTypeEnum ===
          //   ApsModels.CredCategoryTypeEnum.ExistingExternalCredential
          // ) {
          //   props.history.push(`/credential/${rowProps.data.id}`);
          // } else {
          //   props.history.push(`/credentials/view/${rowProps.data.id}`);
          // }
          props.history.push(`/credentials/view/${rowProps.data.id}`);
        }}
      >
        {rowProps.data.credentialName}
      </span>
    );
  };

  const ExpirationCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialForEmployeeGridItemDto;
  }) => {
    if (!rowProps.data.expirationDate) {
      return <span>Not Applicable</span>;
    }
    return (
      <CredentialExpirationDateCell
        date={rowProps.data.expirationDate}
      ></CredentialExpirationDateCell>
    );
  };

  const DueDateCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialForEmployeeGridItemDto;
  }) => {
    if (!rowProps.data.dueDate) {
      return <span>Not Applicable</span>;
    }
    return (
      <CredentialExpirationDateCell
        date={rowProps.data.dueDate}
      ></CredentialExpirationDateCell>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          return true;
        },
        field: "_selected",
        headerName: "",
        width: 10,
        ...commonService.agGridHelpers.checkboxColDef,
      },
      {
        field: "credentialName",
        headerName: "Credential Name",
        cellRenderer: "nameCellComponent",
        minWidth: 250,
        suppressSizeToFit: false,
      },
      {
        field: "credentialCategory",
        headerName: "Credential Category",
      },
      {
        field: "credentialCode",
        headerName: "Credential Code",
      },
      // {
      //   field: "credentialCategory",
      //   headerName: "Credential Category",
      // },
      {
        field: "expirationDateString",
        headerName: "Activate Expiration Date",
        cellRenderer: "expirationCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: "dueDateString",
        headerName: "In Progress Due Date",
        cellRenderer: "dueDateCellComponent",
        width: 100,
        suppressSizeToFit: true,
        // valueFormatter: (param: {
        //   data: ApsModels.ICredCredentialForEmployeeGridItemDto;
        // }) => {
        //   return param.data.dueDate
        //     ? `${moment(param.data.dueDate).format("MMM DD, YYYY")}`
        //     : "";
        // },
      },
      {
        field: "percentageComplete",
        headerName: "% Complete",
        valueFormatter: (param: {
          data: ApsModels.ICredCredentialForEmployeeGridItemDto;
        }) => {
          return `${commonService.toNumberWithComma(
            param.data.percentageComplete || 0
          )}%`;
        },
      },
      {
        field: "statusName",
        headerName: "Status",
        cellRenderer: "statusCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: "hasAttachment",
        headerName: "Attachment",
        cellRenderer: "attachmentCellComponent",
        hide: true,
      },
      {
        field: "dateIssuedString",
        headerName: "Effective Date",
        valueFormatter: (param: {
          data: ApsModels.ICredCredentialForEmployeeGridItemDto;
        }) => {
          // if (param.data.isAssigned) {
          //   return "";
          // }
          return param.data.dateIssued
            ? `${moment(param.data.dateIssued).format("MMM DD, YYYY")}`
            : "Not Applicable";
        },
        hide: true,
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "viewButtonCellComponent",
        width: 100,
        ...commonService.agGridHelpers.actionColPinnedDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      suppressSizeToFit: true,
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      expirationCellComponent: ExpirationCellComponent,
      statusCellComponent: CredentialStatusCellLabel,
      nameCellComponent: NameCellComponent,
      dueDateCellComponent: DueDateCellComponent,
      attachmentCellComponent: AttachmentCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ICredCredentialForEmployeeGridItemDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });
  const gridFilters = useGridFilters(
    "credential-list-by-employee-table",
    gridApi,
    (others) => {}
  );
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageToLoad, setPageToLoad] = useState(1);

  const [loading, setLoading] = useState(false);

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();
    setLoading(true);
    await ApsServices.http.credCredential
      .listCredentialsForEmployee(
        1,
        commonService.gridPageSize.firstPage,
        Number(props.employeeId)
      )
      .then((data) => {
        setList(data as any);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
        }
        //setLoading(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Credentials", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);
      });
  };

  const loadMore = async () => {
    if (
      list &&
      list.totalRecords > list.credCredentialForEmployeeGridItemDtos?.length
    ) {
      setLoadingMore(true);
      setLoading(false);

      let pageNum = pageToLoad + 1;
      if (
        commonService.gridPageSize.firstPage <
          commonService.gridPageSize.otherPages &&
        list.credCredentialForEmployeeGridItemDtos?.length ===
          commonService.gridPageSize.firstPage
      ) {
        pageNum = 1;
      }

      setPageToLoad(pageNum);
      await ApsServices.http.credCredential
        .listCredentialsForEmployee(
          pageNum,
          commonService.gridPageSize.otherPages,
          Number(props.employeeId)
        )
        .then((data) => {
          setList((prev) => {
            return {
              ...data,
              totalRecords: prev?.totalRecords || data.totalRecords,
              credCredentialForEmployeeGridItemDtos:
                pageNum === 1
                  ? data.credCredentialForEmployeeGridItemDtos
                  : [
                      ...(prev?.credCredentialForEmployeeGridItemDtos || []),
                      ...data.credCredentialForEmployeeGridItemDtos,
                    ],
            };
          });
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Credentials", err);
          setLoadingMore(false);
        })
        .finally(() => {
          setLoadingMore(false);
          setLoading(false);
        });
    } else {
      gridFilters.reApplyFilters(); //need to reapply filter after adding more data
      toggleColSizes();
      setLoadingMore(false);
      setLoading(false);
    }
  };

  const [rowsSelectedCount, setRowSelectedCount] = useState(0);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (loading || loadingMore) {
      return;
    }
    if (list?.totalRecords) {
      gridApi && gridApi.hideOverlay();
    }
    setGridState({
      ...gridState,
      rowData: list?.credCredentialForEmployeeGridItemDtos || [],
    });
    //toggleColSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, loadingMore]);

  useEffect(() => {
    if (gridApi) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, paging]);

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    return gridApi
      .getSelectedRows()
      .filter((row: ApsModels.ICredCredentialForEmployeeGridItemDto) => {
        return (
          !isAnyFilterPresent ||
          !!filteredRows.find(
            (r: { data: ApsModels.ICredCredentialForEmployeeGridItemDto }) =>
              r.data.id === row.id
          )
        );
      });
  };

  const canAddCredentials = () => {
    if (claims.credentials.canAddAll) {
      return true;
    }
    return (
      claims.credentials.canAccess &&
      (!props.employeeId ||
        (Number(props.employeeId) === commonService.userInfo.userId &&
          claims.credentials.canEditOwn))
    );
  };

  const [showImport, setShowImport] = useState(false);
  const [exporting, setExporting] = useState(false);
  const startExport = async () => {
    gridFilters.exportCSV();
    // setExporting(true);
    // await ApsServices.http.credCredential
    //   .extractCredentialsForEmployee(props.employeeId, {
    //     ids: getSelectedRows().map((r) => r.id),
    //   })
    //   .then((data) => {
    //     const file = commonService.b64toBlob(data.fileContents, "text/plain");
    //     FileSaver.saveAs(file, data.fileDownloadName);
    //   })
    //   .catch((error) => {
    //     toastStore.showError("Failed Exporting Credentials", error);
    //   })
    //   .finally(() => {
    //     setExporting(false);
    //   });
  };

  return (
    <>
      {showImport && (
        <CredentialImportDialog
          onClose={(e) => {
            setShowImport(false);
          }}
        ></CredentialImportDialog>
      )}
      <div className="flex flex-col h-100 p-4">
        <div
          className="flex flex-row mb-3"
          style={{ flexWrap: "wrap", gap: "10px" }}
        >
          <div
            className="flex-1 flex"
            style={{
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              minWidth: "250px",
            }}
          >
            <div className="mr-2">
              <button
                className="btn btn-outline-secondary"
                onClick={(e) => {
                  startExport();
                }}
                disabled={rowsSelectedCount === 0 || exporting}
              >
                {exporting
                  ? `Exporting...`
                  : `Export to CSV (${rowsSelectedCount})`}
              </button>
            </div>
            <div>
              {/* <button
                type="button"
                className="btn btn-outline-danger"
                onClick={(e) => {
                  gridFilters.reset();
                  toggleColSizes();
                }}
              >
                Reset
              </button> */}
            </div>
          </div>

          <div>
            {canAddCredentials() && (
              <Button
                variant="primary"
                type="button"
                onClick={(e) => {
                  //setShowAddCred(true);
                  props.history.push(`/credentials/new`);
                }}
              >
                Add Credential
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              minHeight: "300px",
            }}
            className="ag-theme-alpine flex-1"
          >
            <AgGridReact
              columnDefs={gridState.columnDefs}
              defaultColDef={gridState.defaultColDef}
              autoGroupColumnDef={gridState.autoGroupColumnDef}
              enableRangeSelection={true}
              animateRows={true}
              onGridReady={onGridReady}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={false}
              suppressRowDeselection={true}
              suppressRowClickSelection={true}
              frameworkComponents={gridState.frameworkComponents}
              rowData={(gridState.rowData || []).map(
                (row: ApsModels.ICredCredentialForEmployeeGridItemDto) => {
                  return {
                    ...row,
                    statusName: GetCredStatusEnumStatusLabel(row.status),
                    dueDateString: !!(!row.isAssigned || !row.dateIssued)
                      ? "Not Applicable"
                      : moment(row.dateIssued).format("YYYY-MM-DD"),
                    dateIssuedString: row.dateIssued
                      ? moment(row.dateIssued).format("YYYY-MM-DD")
                      : "Not Applicable",
                    expirationDateString: row.expirationDate
                      ? moment(row.expirationDate).format("YYYY-MM-DD")
                      : "Not Applicable",
                  };
                }
              )}
              onRowDoubleClicked={(event: {
                data: ApsModels.ICredCredentialForEmployeeGridItemDto;
              }) => {
                // if (
                //   event.data.credCategoryTypeEnum ===
                //   ApsModels.CredCategoryTypeEnum.ExistingExternalCredential
                // ) {
                //   props.history.push(`/Credential/${event.data.id}`);
                // } else {
                //   props.history.push(`/credentials/view/${event.data.id}`);
                // }
                props.history.push(`/credentials/view/${event.data.id}`);
              }}
              overlayLoadingTemplate={gridState.overlayLoadingTemplate}
              overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
              onRowSelected={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onRowDataChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onFilterChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              {...gridFilters.props}
            />
          </div>
        </div>
        <LoadMoreIndicator
          loadingMore={loadingMore}
          currentCount={list?.credCredentialForEmployeeGridItemDtos?.length}
          totalExpected={list?.totalRecords}
        ></LoadMoreIndicator>
        {/* {
          <Pagination
            length={list?.totalRecords || 0}
            page={paging.page}
            pageSize={paging.pageSize}
            pageChange={pageChange}
            showingOfWhatLabel="Records"
            sizes={[10, 20, 50, 100]}
          ></Pagination>
        } */}
      </div>
    </>
  );
}

export default CredentialListForEmployee;
