import moment from "moment";
import React from "react";

function CredentialExpirationDateCell({
  date,
  defaultClass,
}: {
  date: Date;
  defaultClass?: string;
}) {
  if (!date) {
    return <span></span>;
  }
  const getClassName = () => {
    let diff = 0;
    if (moment().isBefore(date)) {
      diff = moment().diff(date, "day") * -1;
    } else {
      return defaultClass || "";
    }

    if (diff > 90) {
      return "alert alert-success";
    }
    if (diff > 30) {
      return "alert alert-warning";
    }
    return "alert alert-danger";
  };

  return (
    <span
      style={{
        padding: "0 5px",
      }}
      className={getClassName()}
    >
      {getClassName() === "" ? (
        <>{moment(date).format("MMM DD, YYYY")}</>
      ) : (
        <small>{`${moment(date).format("MMM DD, YYYY")}`}</small>
      )}
    </span>
  );
}

export default CredentialExpirationDateCell;
