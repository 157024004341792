import { QuestionDto } from "../../../../data/DailyOperational";
import fetcher from "../Fetcher";
class QuestionService {
  save = async (data: QuestionDto) => {
    const url = `/api/Question/Save`;
    return fetcher.post<QuestionDto>(url, data);
  };

  update = async (data: QuestionDto) => {
    const url = `/api/Question/Update`;
    return fetcher.put<any>(url, data);
  };

  delete = async (id: number) => {
    const url = `/api/Question/Delete/${id}`;
    return fetcher.delete<any>(url);
  };
}

const question = new QuestionService();
export default question;
