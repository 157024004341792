import React from "react";
import { Button } from "react-bootstrap";
import ApsModels from "../../models";
import nfirsStore from "../../stores/NFIRSStore";
import { IBasicDialogProps } from "../Common/FormGroups";
import FormModal from "../Common/FormModal";
import { NFIRS_SECTION } from "./Entry/NFIRSEntry";

function NFIRSModuleValidationDialog(
  props: IBasicDialogProps<{
    reportId: number;
    validations: ApsModels.INfirsValidationModuleOutputDto;
    tabNames: ApsModels.ILookupIntDto[];
    section: NFIRS_SECTION;
  }>
) {
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  return (
    <div>
      <FormModal
        title="Oops, you're not quite done!"
        isOpen={true}
        close={() => close()}
        submit={close}
        size="md"
        submitButtonLabel={"OK"}
        primaryButtonOnly={true}
      >
        <div>
          <label>The following tabs are missing required information.</label>
          <div>
            <i>
              This report cannot be locked without completing the required
              fields for the following reports.
            </i>
            <div className="pb-3"></div>

            {props.data?.tabNames
              ?.filter((tb) =>
                props.data?.validations?.nfirsVerificationTabOutputDtos?.find(
                  (dto) =>
                    dto.nfirsValidationTabsEnum === tb.value &&
                    dto.httpErrorOutputDto?.errors?.length > 0
                )
              )
              .map((tb) => (
                <React.Fragment key={tb.value}>
                  <div className="pb-2">
                    {props.data?.section && (
                      <Button
                        variant="outline-primary"
                        title="Go To Tab"
                        onClick={(e) => {
                          nfirsStore.setGotoValidatedTab(tb.value);
                          close();
                        }}
                      >
                        {tb.label.replace(
                          props.data.section,
                          `${props.data.section} - `
                        )}
                        <i className="fa fa-arrow-right ml-2"></i>
                      </Button>
                    )}
                  </div>
                  {props.data?.validations?.nfirsVerificationTabOutputDtos
                    ?.filter((dto) => dto.nfirsValidationTabsEnum === tb.value)
                    ?.map((dto, i) => (
                      <React.Fragment key={i}>
                        <ul className="mb-4">
                          {dto?.httpErrorOutputDto?.errors?.map((err, ii) => (
                            <li className="pl-2 pb-1" key={ii}>
                              <i className="fa fa-caret-right mr-2"></i>{" "}
                              {err.message}
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
          </div>
        </div>
      </FormModal>
    </div>
  );
}

export default NFIRSModuleValidationDialog;
