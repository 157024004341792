import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSStructureFireDetectorService {
  get = async (reportId: any) => {
    const url = `/api/NfirsStructureFireDetector/${reportId}`;
    return fetcher.get<ApsModels.INfirsStructureFireDetectorOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsStructureFireDetectorInputDto) => {
    const url = `/api/NfirsStructureFireDetector`;
    return fetcher.post<ApsModels.INfirsStructureFireDetectorOutputDto>(
      url,
      data
    );
  };
}

const nfirsStructureFireDetector = new NFIRSStructureFireDetectorService();
export default nfirsStructureFireDetector;
