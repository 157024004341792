import React from 'react';
import { AnswerDto, AnswerUserReferenceDto, IsolatedInputDetails, QuestionDto, QuestionGroupDto } from '../../../data/DailyOperational';
import { OptionDto } from '../../../data/Option';
import { UserDto } from '../../../data/User';
import { UserService } from '../../../services/UserService';
import { IsolatedInput } from './IsolatedInput';

type Props = {
    defaultItemsCount: number,
    preventExpanding?: boolean,
    answerDto?: AnswerDto[],
    userDto?: UserDto[],
    optionsDto?: OptionDto[],
    userSelected?: UserDto,
    usersSelected?: UserDto[],
    isOption: boolean,
    question?: QuestionDto,
    setData?: (value: QuestionDto | undefined) => void,
    date: Date
}

type State = {
    items: IsolatedInputDetails[],
    userDto?: UserDto[],
    optionsDto?: OptionDto[],
    userSelected?: UserDto | null,
    usersSelected?: UserDto[],
    answer: AnswerDto[] | undefined
}

export class IsolatedInputList extends React.Component<Props, State> {
    state = {
        items: new Array<IsolatedInputDetails>(),
        userDto: [],
        optionsDto: [],
        userSelected: undefined,
        usersSelected: [],
        answer: new Array<AnswerDto>()
    }

    getUserId = async (user: AnswerUserReferenceDto | undefined): Promise<UserDto> => {
        return await UserService.getUser(user?.userId ?? 0)
    }
    componentDidMount = async () => {
        this.setState({ userSelected: null })
        for (let i = 0; i < this.props.defaultItemsCount; i++) {
            const item: IsolatedInputDetails = ({ isFirst: i === 0, isLast: i === this.props.defaultItemsCount - 1 });
            this.setState(prevState => ({
                items: [...prevState.items, item]
            }));
        }
    }

    componentDidUpdate = async (prevProps: Props) => {
        if (this.props !== prevProps) {
            this.setState({
                items: [],
                answer: undefined
            });
            for (let i = 0; i < this.props.defaultItemsCount; i++) {
                const item: IsolatedInputDetails = ({ isFirst: i === 0, isLast: i === this.props.defaultItemsCount - 1 });
                this.setState(prevState => ({
                    items: [...prevState.items, item],
                    answer: this.props.question?.answers
                }));
            }
        }
        if (this.props.answerDto !== prevProps.answerDto) {
            const user = this.props.answerDto?.find(x => x.order === 0)?.user
            if (user?.userId) {
                this.setState({ userSelected: await this.getUserId(user) })
            } else {
                this.setState({ userSelected: null })
            }
        }

        if (this.props.usersSelected != prevProps.usersSelected) {
            this.setState({ usersSelected: this.props.usersSelected })
        }
    }

    handleSectionInputChanged = (isLast: boolean): void => {
        if (this.props.preventExpanding) return;
        if (!isLast) return;

        const items = this.state.items;
        items[items.length - 1].isLast = false;
        items.push({ isFirst: false, isLast: true });

        this.setState({
            items: items,
        });
    }

    setData = (question: QuestionDto | undefined) => {
        if (this.props.setData) {
            this.props.setData(question)
        }
    }

    render() {
        return (
            this.state.items.map((item, index) => {
                return (
                    <IsolatedInput
                        key={index}
                        userDto={this.props.userDto}
                        optionsDto={this.props.optionsDto}
                        isFirst={item.isFirst}
                        isLast={item.isLast}
                        question={this.props.question}
                        answerDto={this.state.answer?.find(x => x.order === (!this.props.isOption ? index + 1 : index + 2))}
                        onChange={this.handleSectionInputChanged}
                        setData={this.setData}
                        order={!this.props.isOption ? index + 1 : index + 2}
                        date={this.props.date}
                    />
                )
            })
        )
    }
}
