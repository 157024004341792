import ApsModels from "../../models";
import fetcher from "./Fetcher";

class LocationService {
  save = async (data: ApsModels.ILocationInputDto) => {
    const url = "/api/Location/Save";
    return fetcher.post<ApsModels.ILocationOutputDto>(url, data);
  };

  getAll = async (deptId: number) => {
    const url = `/api/Location/GetAll/${deptId}`;
    return fetcher.get<ApsModels.ILocationOutputDto[]>(url);
  };
}

const location = new LocationService();
export default location;
