import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";

interface ICustomTab {
  title: string;
  offset: number;
  key: string;
}

function CustomTabs({
  activeTab,
  children,
  onMenuTabClick,
  className,
}: {
  activeTab: string;
  children: any;
  onMenuTabClick: (tab: string) => void;
  className?: string;
}) {
  const element = useRef<HTMLDivElement | null>(null);
  const [overflow, setOverflow] = useState(false);
  const [tabs, setTabs] = useState<ICustomTab[]>([]);

  const toggleReize = () => {
    const nav = element.current?.getElementsByClassName(
      "nav-tabs"
    )[0] as HTMLDivElement;

    if (!nav) {
      console.error("nav-tabs not found");
      return;
    }

    const elTabs = element.current?.getElementsByClassName("nav-item");
    let marginOffset = 0;

    if (elTabs && elTabs.length > 0) {
      let newTabs: ICustomTab[] = [];
      let idx = 0;
      let offset = 0;
      while (idx < elTabs.length) {
        const el = elTabs[idx];
        newTabs.push({
          title: el.textContent || "",
          key: el.getAttribute("data-rb-event-key") || "",
          offset: offset,
        });

        if (el.classList.contains("active")) {
          if (
            offset > nav.clientWidth ||
            offset + el.clientWidth > nav.clientWidth
          ) {
            marginOffset = nav.clientWidth - (offset + el.clientWidth);
            if (idx !== elTabs.length - 1) {
              marginOffset -= 50; //Adjustment to have a peek on next tab
            }
          }
          const refAnchor = nav.getElementsByTagName("a")[0];
          refAnchor.style.marginLeft = `${marginOffset}px`;
        }
        offset += el.clientWidth;
        idx++;
      }

      setOverflow(offset > nav.clientWidth);
      setTabs(newTabs);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", toggleReize);
    return () => {
      window.removeEventListener("resize", toggleReize);
    };
  }, [element]);

  useEffect(() => {
    toggleReize();
  }, [activeTab]);

  return (
    <div
      className={`custom-tab-styles ${className || ""} ${
        overflow ? "tab-overflow" : ""
      }`}
      ref={element}
    >
      {overflow && (
        <div className="tab-menu">
          <Dropdown drop="left">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <i className="fa fa-ellipsis-v"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {tabs.map((tab) => (
                <Dropdown.Item
                  key={tab.key}
                  onClick={() => {
                    onMenuTabClick(tab.key);
                  }}
                >
                  {tab.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      {children}
    </div>
  );
}

export default CustomTabs;
