import moment from "moment";
import React, { useEffect } from "react";
import ApsModels from "../../../../models";
import { FgUseForm, IBasicDialogProps } from "../../../Common/FormGroups";
import FormModal from "../../../Common/FormModal";

function OverlappingDrillsDialog(
  props: IBasicDialogProps<{
    prev: ApsModels.IDrillReportVerificationDto;
    new: ApsModels.IDrillReportInputDto;
  }>
) {
  const { handleSubmit } = FgUseForm({});
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const onChange = () => {
    props.onChange &&
      props.data?.prev &&
      props.data?.new &&
      props.onChange({
        prev: { ...props.data?.prev },
        new: { ...props.data?.new },
      });
  };
  const submit = async (form: any) => {
    close(true);
  };

  useEffect(() => {
    props?.data?.prev?.drillReportOverlappingDetailDtos?.forEach((item) => {
      item.drillReportVerificationReportDto.keep = true;
    });
    onChange();
  }, []);

  return (
    <div>
      <FormModal
        title="Overlapping Drills"
        isOpen={true}
        close={() => close()}
        submit={handleSubmit(submit)}
        size="lg"
        submitButtonLabel="Confirm"
        closeButtonLabel="Cancel"
        titleIcon="exclamation-triangle"
      >
        <div>
          A drill has already been logged for the following participants during
          this time period. Please select which drill to keep for each conflict
          below, or click "Cancel" on this dialog to make changes to the
          newly-proposed drill before submitting again.
        </div>
        <div className="table-responsive-sm pt-4">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Participant</th>
                <th>Previous</th>
                <th>New</th>
              </tr>
            </thead>
            <tbody>
              {props?.data?.prev?.drillReportOverlappingDetailDtos?.map(
                (over, i) => (
                  <tr key={i}>
                    <td>
                      {over.participant.firstName} {over.participant.lastName}
                    </td>
                    <td>
                      <div className="flex-with-checkbox">
                        <div>
                          <input
                            type="radio"
                            id={`overlapSelectPrev${i}`}
                            name={`overlapSelect${i}`}
                            value="prev"
                            className="mr-2"
                            onChange={(e) => {
                              over.drillReportVerificationReportDto.keep = true;
                              onChange();
                            }}
                            checked={over.drillReportVerificationReportDto.keep}
                          ></input>
                        </div>
                        <label htmlFor={`overlapSelectPrev${i}`}>
                          <span className="pr-2">
                            {moment(
                              moment(
                                over.drillReportVerificationReportDto.startTime
                              ).toDate()
                            ).format("MM/DD/YYYY HH:mm")}
                          </span>
                          <span className="pr-2">to</span>
                          <span className="pr-2">
                            {moment(
                              moment(
                                over.drillReportVerificationReportDto.endTime
                              ).toDate()
                            ).format("MM/DD/YYYY HH:mm")}
                          </span>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="flex-with-checkbox">
                        <div>
                          <input
                            type="radio"
                            id={`overlapSelectNew${i}`}
                            name={`overlapSelect${i}`}
                            value="new"
                            className="mr-2"
                            onChange={(e) => {
                              over.drillReportVerificationReportDto.keep = false;
                              onChange();
                            }}
                            checked={
                              !over.drillReportVerificationReportDto.keep
                            }
                          ></input>
                        </div>
                        <label htmlFor={`overlapSelectNew${i}`}>
                          <span className="pr-2">
                            {props?.data?.new?.startTimeString &&
                              moment(props.data.new.startTimeString).format(
                                "MM/DD/YYYY HH:mm"
                              )}
                          </span>
                          <span className="pr-2">to</span>
                          <span className="pr-2">
                            {props?.data?.new?.endTimeString &&
                              moment(props.data.new.endTimeString).format(
                                "MM/DD/YYYY HH:mm"
                              )}
                          </span>
                        </label>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </FormModal>
    </div>
  );
}

export default OverlappingDrillsDialog;
