import React, { useEffect, useMemo, useState } from "react";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import ApsServices from "../../services";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import ApsModels from "../../models";
import nfirsDynamicReportsHelper from "./NFIRSDynamicReportsHelper";
import toastStore from "../../stores/ToastStore";
import NFIRSDynamicReportList from "./NFIRSDynamicReportList";
import CommonSpinner from "../Common/CommonSpinner";

const DATE_RANGES = [
  { id: "1", name: "Last Week" },
  { id: "2", name: "Last Month" },
];

function NFIRSDynamicReport(props: any) {
  const modules = useNfirsGetData(
    ApsServices.http.nfirsDynamicReport.getModules,
    "Modules"
  );
  const tabs = useNfirsGetData(
    ApsServices.http.nfirsDynamicReport.getTabs,
    "Tabs"
  );
  const fields = useNfirsGetData(
    ApsServices.http.nfirsDynamicReport.getFields,
    "Fields"
  );

  const templates = useNfirsGetData(
    async () => ApsServices.http.nfirsDynamicReport.listReportTemplates(1, 100),
    "Templates"
  );

  const reports = useNfirsGetData(
    async () => ApsServices.http.nfirsDynamicReport.listReports(),
    "Reports"
  );

  const loading = useMemo(() => {
    return (
      modules.status === NfirsFetchStatus.InProgress ||
      tabs.status === NfirsFetchStatus.InProgress ||
      fields.status === NfirsFetchStatus.InProgress
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules.status, tabs.status, fields.status]);

  useEffect(() => {
    modules.getData();
    tabs.getData();
    fields.getData();
    reports.getData();
    //templates.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dateRange, setDateRange] = useState("1");
  const [dateFrom, setDateFrom] = useState(
    moment().add(-1, "week").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(
    moment().add(-1, "day").endOf("day").toDate()
  );

  const [temp, setTemp] = useState<ApsModels.INfirsDynamicReportRequestDto>({
    status: 0,
  } as any);

  const [allTabSelected, setAllTabSelected] = useState<{
    [key: string]: boolean;
  }>({});
  const [allFieldSelected, setAllFieldSelected] = useState<{
    [key: string]: boolean;
  }>({});
  const [allTabFieldSelected, setAllTabFieldSelected] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const curData = allTabSelected;
    const curData2 = allFieldSelected;
    const curData3 = allTabFieldSelected;

    modules?.data?.forEach((mod) => {
      setAllTabSelected((data) => {
        curData[mod.id] = nfirsDynamicReportsHelper.allTabsSelected(
          mod.id,
          tabs?.data?.filter((t) => t.moduleId === mod.id)?.map((t) => t.id) ||
            [],
          temp
        );
        return {
          ...curData,
        };
      });

      setAllFieldSelected((data) => {
        curData2[mod.id] = nfirsDynamicReportsHelper.allFieldSelected(
          mod.id,
          fields?.data
            ?.filter(
              (f) =>
                f.moduleId === mod.id &&
                nfirsDynamicReportsHelper.isTabSelected(
                  f.tabId,
                  f.moduleId,
                  temp
                )
            )
            ?.map((f) => {
              return {
                id: f.id,
                tabId: f.tabId,
              };
            }) || [],
          temp
        );
        return {
          ...curData2,
        };
      });
    });

    tabs?.data?.forEach((tab) => {
      setAllTabFieldSelected((data) => {
        curData3[tab.id] = nfirsDynamicReportsHelper.allTabFieldSelected(
          tab.moduleId,
          tab.id,
          fields?.data
            ?.filter(
              (f) =>
                f.tabId === tab.id &&
                nfirsDynamicReportsHelper.isTabSelected(
                  f.tabId,
                  f.moduleId,
                  temp
                )
            )
            ?.map((f) => f.id) || [],
          temp
        );
        return {
          ...curData3,
        };
      });
    });

    //console.log(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp]);

  /////DISABLED FOR NOW
  /*
  useEffect(() => {
    if (templates.data?.dynamicReportTemplateOutputDtos?.length) {
      const first =
        templates.data.dynamicReportTemplateOutputDtos[
          templates.data.dynamicReportTemplateOutputDtos.length - 1
        ];

      const data = commonService.toCamel(
        JSON.parse(first.template)
      ) as ApsModels.INfirsDynamicReportRequestDto;
      setDateFrom(data.start);
      setDateTo(data.end);
      setTemp(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates.data]);

  const [saving, setSaving] = useState(false);
  const saveTemplate = async () => {
    setSaving(true);
    await ApsServices.http.nfirsDynamicReport
      .saveTemplate({
        name: "Testing...",
        nfirsDynamicReportRequestDto: {
          ...temp,
          start: dateFrom,
          end: dateTo,
        },
      })
      .then((data) => {
        toastStore.showToast("Template Saved", "success");
        setSaving(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Saving Template", err);
        setSaving(false);
      });
  };
  */

  const hasSelectedFields = () => {
    return (
      (modules.data?.filter((mod) => {
        if (nfirsDynamicReportsHelper.isModuleSelected(mod.id, temp)) {
          return (
            (tabs.data?.filter((tab) => {
              if (
                nfirsDynamicReportsHelper.isTabSelected(
                  tab.id,
                  tab.moduleId,
                  temp
                )
              ) {
                return (
                  (fields?.data?.filter((field) => {
                    return nfirsDynamicReportsHelper.isFieldSelected(
                      field.id,
                      field.tabId,
                      field.moduleId,
                      temp
                    );
                  })?.length || 0) > 0
                );
              }
              return false;
            })?.length || 0) > 0
          );
        }
        return false;
      })?.length || 0) > 0
    );
  };

  const [exporting, setExporting] = useState(false);
  const exportNow = async () => {
    setExporting(true);
    await ApsServices.http.nfirsDynamicReport
      .requestReport({
        ...temp,
        start: moment(dateFrom).utc().format("YYYY-MM-DDTHH:mm:00") as any,
        end: moment(dateTo).utc().format("YYYY-MM-DDTHH:mm:00") as any,
      })
      .then((data) => {
        toastStore.showToast("Request Sent", "success");
        setExporting(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Sending Report Request", err);
        setExporting(false);
      });
  };

  const [includeRef, setIncludeRef] = useState<{ [key: string]: boolean }>({});
  const selectAllFieldsFirstTime = (moduleId: string) => {
    let newData = nfirsDynamicReportsHelper.setModuleSelected(
      moduleId,
      true,
      temp
    );

    tabs.data
      ?.filter((t) => t.moduleId === moduleId)
      ?.forEach((tab) => {
        newData = {
          ...nfirsDynamicReportsHelper.setTabSelected(
            tab.id,
            tab.moduleId,
            true,
            newData
          ),
        };

        fields.data
          ?.filter((f) => f.tabId === tab.id)
          ?.forEach((field) => {
            newData = {
              ...nfirsDynamicReportsHelper.setFieldSelected(
                field.id,
                field.tabId,
                field.moduleId,
                true,
                newData
              ),
            };
          });
      });

    setTemp((p) => {
      return {
        ...newData,
      };
    });

    setIncludeRef((p) => {
      let data = {} as any;
      data[moduleId] = true;
      return { ...p, ...data };
    });
  };

  return (
    <div
      className="flex flex-1 flex-column"
      style={{ marginBottom: "-60px", position: "relative" }}
    >
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="h4 mb-0 font-size-18 text-uppercase">
                NFIRS
              </span>
            </div>
            <div></div>
          </div>
        </div>
        <div className="row h-full flex-1">
          <div className="col-12 col-lg-12 flex flex-col">
            <div className="card">
              {loading && (
                <div className="p-4">
                  <CommonSpinner></CommonSpinner>
                </div>
              )}
              <div
                className={`card-body flex flex-col p-4 ${
                  loading ? "display-none" : ""
                }`}
              >
                <div className="row mb-1">
                  <div className="col-sm-12 pb-2">
                    <div className="flex flex-center mb-2">
                      <div className="pt-1">
                        <label className="p-0 pointer">
                          Select Date and Time Range
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2">
                    <Dropdown drop="down" className="w-100">
                      <Dropdown.Toggle
                        className="w-100 text-left"
                        variant="outline-secondary"
                      >
                        <div
                          style={{
                            display: "inline-block",
                            width: "calc(100% - 20px)",
                          }}
                        >
                          {DATE_RANGES.find((d) => d.id === dateRange)?.name}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="left" style={{ width: "100%" }}>
                        {DATE_RANGES?.map((stat) => (
                          <Dropdown.Item
                            className="px-3"
                            key={stat.id}
                            onClick={(e) => {
                              setDateRange(stat.id);
                              const to = moment().add(-1, "day").endOf("day");
                              setDateTo(to.toDate());
                              if (stat.id === "1") {
                                setDateFrom(
                                  to.add(-1, "week").startOf("day").toDate()
                                );
                              } else if (stat.id === "2") {
                                setDateFrom(
                                  to.add(-1, "month").startOf("day").toDate()
                                );
                              }
                            }}
                          >
                            <div>
                              <label className="m-0">{stat.name}</label>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 flex flex-row flex-center">
                    {reports.status === NfirsFetchStatus.Complete && (
                      <React.Fragment key={dateRange}>
                        <DateTimePickerV2
                          showAllMinuteOptions={true}
                          onChange={(data) => {
                            setDateFrom(data);
                          }}
                          data={dateFrom}
                          className="flex-1"
                        />
                        <div className="flex-0 px-2">-</div>
                        <DateTimePickerV2
                          showAllMinuteOptions={true}
                          onChange={(data) => {
                            setDateTo(data);
                          }}
                          data={dateTo}
                          className="flex-1"
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>

                <div className="row pb-3">
                  <div className="col-sm-12">
                    <div className="flex flex-center mb-2">
                      <div className="pt-1">
                        <label className="p-0 pointer">Include Status</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <Dropdown drop="down" className="w-100">
                      <Dropdown.Toggle
                        className="w-100 text-left"
                        variant="outline-secondary"
                      >
                        <div
                          style={{
                            display: "inline-block",
                            width: "calc(100% - 20px)",
                          }}
                        >
                          {temp.status ===
                            ApsModels.NfirsReportStatusEnum.Locked && "Locked"}
                          {temp.status ===
                            ApsModels.NfirsReportStatusEnum.Open && "Open"}
                          {temp.status ===
                            ApsModels.NfirsReportStatusEnum.Any &&
                            "ALL STATUSES"}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="left" style={{ width: "100%" }}>
                        {[
                          { id: 0, name: "ALL STATUSES" },
                          { id: -1, name: "" },
                          { id: 1, name: "Open" },
                          { id: 2, name: "Locked" },
                        ]?.map((stat) => (
                          <React.Fragment key={stat.id}>
                            {stat.id === -1 ? (
                              <Dropdown.Divider></Dropdown.Divider>
                            ) : (
                              <Dropdown.Item
                                className="px-3"
                                onClick={(e) => {
                                  setTemp((p) => {
                                    return {
                                      ...p,
                                      status: stat.id,
                                    };
                                  });
                                }}
                              >
                                <div>
                                  <label className="m-0">{stat.name}</label>
                                </div>
                              </Dropdown.Item>
                            )}
                          </React.Fragment>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {modules.data?.map((mod) => (
                  <div key={mod.id} className="row pb-1">
                    <div className="col-sm-12">
                      <div className="flex flex-center mb-2">
                        <div className="pr-2">
                          <input
                            type="checkbox"
                            className="pb-1"
                            id={`mod${mod.id}`}
                            checked={nfirsDynamicReportsHelper.isModuleSelected(
                              mod.id,
                              temp
                            )}
                            onChange={(e) => {
                              const checked = e.target.checked || false;
                              if (checked && !includeRef[mod.id]) {
                                selectAllFieldsFirstTime(mod.id);
                              } else {
                                setTemp((p) => {
                                  return nfirsDynamicReportsHelper.setModuleSelected(
                                    mod.id,
                                    checked,
                                    p
                                  );
                                });
                              }
                            }}
                          ></input>
                        </div>
                        <div className="pt-1">
                          <label
                            className="p-0 pointer"
                            htmlFor={`mod${mod.id}`}
                          >
                            Include {mod.name}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2">
                      <Dropdown drop="down" className="w-100">
                        <Dropdown.Toggle
                          className="w-100 text-left"
                          variant="outline-secondary"
                          id={`dropdownTabs${mod.id}`}
                          disabled={
                            !nfirsDynamicReportsHelper.isModuleSelected(
                              mod.id,
                              temp
                            )
                          }
                        >
                          <div
                            style={{
                              display: "inline-block",
                              width: "calc(100% - 20px)",
                            }}
                          >
                            {allTabSelected[mod.id]
                              ? "ALL TABS"
                              : `${
                                  tabs.data?.filter(
                                    (t) =>
                                      t.moduleId === mod.id &&
                                      nfirsDynamicReportsHelper.isTabSelected(
                                        t.id,
                                        t.moduleId,
                                        temp
                                      )
                                  ).length
                                } Tab(s) Selected`}
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="left" style={{ width: "100%" }}>
                          <Dropdown.ItemText className="px-3">
                            <div className="flex flex-center pointer">
                              <input
                                type="checkbox"
                                className="me-2"
                                id={`allTabsFor${mod.id}`}
                                checked={allTabSelected[mod.id] || false}
                                onChange={(e) => {
                                  let curTemp = temp;
                                  tabs.data
                                    ?.filter((t) => t.moduleId === mod.id)
                                    ?.forEach((t) => {
                                      curTemp =
                                        nfirsDynamicReportsHelper.setTabSelected(
                                          t.id,
                                          t.moduleId,
                                          !allTabSelected[mod.id],
                                          curTemp
                                        );
                                    });
                                  setTemp(curTemp);
                                }}
                              />
                              <label
                                className="m-0"
                                htmlFor={`allTabsFor${mod.id}`}
                              >
                                ALL TABS
                              </label>
                            </div>
                          </Dropdown.ItemText>
                          <Dropdown.Divider></Dropdown.Divider>
                          {tabs.data
                            ?.filter((tab) => tab.moduleId === mod.id)
                            ?.map((tab) => (
                              <Dropdown.ItemText className="px-3" key={tab.id}>
                                <div className="flex flex-center pointer">
                                  <input
                                    type="checkbox"
                                    className="me-2"
                                    id={`tab${tab.id}`}
                                    checked={nfirsDynamicReportsHelper.isTabSelected(
                                      tab.id,
                                      tab.moduleId,
                                      temp
                                    )}
                                    onChange={(e) => {
                                      const checked = e.target.checked || false;
                                      setTemp((p) => {
                                        return nfirsDynamicReportsHelper.setTabSelected(
                                          tab.id,
                                          mod.id,
                                          checked,
                                          p
                                        );
                                      });
                                    }}
                                  />
                                  <label
                                    className="m-0"
                                    htmlFor={`tab${tab.id}`}
                                  >
                                    {tab.name}
                                  </label>
                                </div>
                              </Dropdown.ItemText>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 pb-2">
                      <Dropdown drop="down" className="w-100">
                        <Dropdown.Toggle
                          className="w-100 text-left"
                          variant="outline-secondary"
                          id={`dropdownFields${mod.id}`}
                          disabled={
                            !nfirsDynamicReportsHelper.isModuleSelected(
                              mod.id,
                              temp
                            )
                          }
                        >
                          <div
                            style={{
                              display: "inline-block",
                              width: "calc(100% - 20px)",
                            }}
                          >
                            {allFieldSelected[mod.id]
                              ? "ALL FIELDS"
                              : `${
                                  fields.data?.filter(
                                    (f) =>
                                      f.moduleId === mod.id &&
                                      nfirsDynamicReportsHelper.isTabSelected(
                                        f.tabId,
                                        f.moduleId,
                                        temp
                                      ) &&
                                      nfirsDynamicReportsHelper.isFieldSelected(
                                        f.id,
                                        f.tabId,
                                        f.moduleId,
                                        temp
                                      )
                                  ).length
                                } Field(s) Selected`}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          align="left"
                          style={{
                            minWidth: "100%",
                            maxHeight: "400px",
                            overflow: "auto",
                          }}
                        >
                          <Dropdown.ItemText className="px-3 pointer">
                            <div className="flex flex-center">
                              <input
                                type="checkbox"
                                className="me-2"
                                id={`fieldsFor-${mod.id}`}
                                checked={allFieldSelected[mod.id] || false}
                                onChange={(e) => {
                                  let curTemp = temp;
                                  fields.data
                                    ?.filter((f) => f.moduleId === mod.id)
                                    ?.forEach((f) => {
                                      curTemp =
                                        nfirsDynamicReportsHelper.setFieldSelected(
                                          f.id,
                                          f.tabId,
                                          f.moduleId,
                                          !allFieldSelected[mod.id],
                                          curTemp
                                        );
                                    });
                                  setTemp(curTemp);
                                }}
                              />
                              <label
                                className="m-0"
                                htmlFor={`fieldsFor-${mod.id}`}
                              >
                                ALL FIELDS
                              </label>
                            </div>
                          </Dropdown.ItemText>
                          <Dropdown.Divider></Dropdown.Divider>
                          {tabs.data
                            ?.filter((tab) => tab.moduleId === mod.id)
                            ?.map((tab) => (
                              <React.Fragment key={tab.id}>
                                {nfirsDynamicReportsHelper.isTabSelected(
                                  tab.id,
                                  tab.moduleId,
                                  temp
                                ) ? (
                                  <>
                                    <Dropdown.ItemText className="px-3 mt-1">
                                      <div className="flex flex-center">
                                        <input
                                          type="checkbox"
                                          className="me-2"
                                          id={`fieldsFor-${mod.id}-${tab.id}`}
                                          checked={
                                            allTabFieldSelected[tab.id] || false
                                          }
                                          onChange={(e) => {
                                            let curTemp = temp;
                                            fields.data
                                              ?.filter(
                                                (f) =>
                                                  f.moduleId === mod.id &&
                                                  f.tabId === tab.id
                                              )
                                              ?.forEach((f) => {
                                                curTemp =
                                                  nfirsDynamicReportsHelper.setFieldSelected(
                                                    f.id,
                                                    f.tabId,
                                                    f.moduleId,
                                                    !allTabFieldSelected[
                                                      tab.id
                                                    ],
                                                    curTemp
                                                  );
                                              });
                                            setTemp(curTemp);
                                          }}
                                        />
                                        <label
                                          className="m-0 uppercase"
                                          htmlFor={`fieldsFor-${mod.id}-${tab.id}`}
                                        >
                                          {tab.name}
                                        </label>
                                      </div>
                                    </Dropdown.ItemText>
                                    {fields.data
                                      ?.filter(
                                        (field) =>
                                          field.moduleId === mod.id &&
                                          tab.id === field.tabId
                                      )
                                      ?.map((field) => (
                                        <Dropdown.ItemText
                                          key={field.id}
                                          className="pointer"
                                        >
                                          <div className="flex flex-center pl-3">
                                            <input
                                              type="checkbox"
                                              className="me-2"
                                              id={`field${mod.id}-${tab.id}-${field.id}`}
                                              checked={nfirsDynamicReportsHelper.isFieldSelected(
                                                field.id,
                                                field.tabId,
                                                field.moduleId,
                                                temp
                                              )}
                                              onChange={(e) => {
                                                const checked =
                                                  e.target.checked || false;
                                                setTemp((p) => {
                                                  return nfirsDynamicReportsHelper.setFieldSelected(
                                                    field.id,
                                                    field.tabId,
                                                    field.moduleId,
                                                    checked,
                                                    p
                                                  );
                                                });
                                              }}
                                            />
                                            <label
                                              className="m-0 no-wrap"
                                              htmlFor={`field${mod.id}-${tab.id}-${field.id}`}
                                            >
                                              {field.name}
                                            </label>
                                          </div>
                                        </Dropdown.ItemText>
                                      ))}
                                  </>
                                ) : (
                                  <Dropdown.Header className="px-3 mt-1">
                                    <div className="flex flex-center">
                                      <input
                                        type="checkbox"
                                        className="me-2"
                                        checked={false}
                                        disabled={true}
                                      />
                                      <label className="m-0 uppercase">
                                        {tab.name}
                                      </label>
                                    </div>
                                  </Dropdown.Header>
                                )}
                              </React.Fragment>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </div>

              {!loading && (
                <div className="px-4 mb-2 pb-4 flex flex-row">
                  <div className="pe-2">
                    <Dropdown drop="down">
                      <Dropdown.Toggle
                        variant="outline-secondary"
                        id="dropdownNfirsActions"
                      >
                        See Report Requests
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="left" style={{ minWidth: "250px" }}>
                        <Dropdown.Header className="px-3">
                          Reports
                        </Dropdown.Header>
                        <div>
                          <div className="pt-2">
                            <NFIRSDynamicReportList></NFIRSDynamicReportList>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      onClick={(e) => {
                        if (hasSelectedFields()) {
                          //saveTemplate();
                          exportNow();
                        } else {
                          toastStore.showToast(
                            "Please select atleast one field.",
                            "warning"
                          );
                        }
                      }}
                    >
                      {exporting ? "Sending Request..." : "Export to CSV"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NFIRSDynamicReport;
