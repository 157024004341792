import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";

function NFIRSHazmatCause(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsHazmatCause.update(model.new);
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsHazmatCauseInputDto>({
    objectName: "Cause",
    nfirsSectionTab: "HAZMAT/Cause",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsHazmatCauseInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("HAZMAT", "Cause", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("HAZMAT", "Cause", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const releaseFactors = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getFactorsContributingToRelease(false),
    "Factors Contributing to Release"
  );

  const mitigationFactors = useNfirsGetData(
    () =>
      ApsServices.http.nfirsFavorite.getFactorsContributingToMitigation(false),
    "Factors Affecting Mitigation"
  );

  const [causes, setCauses] = useState<
    ApsModels.INfirsHazmatCauseOfReleaseEntryOutputDto[]
  >([]);
  const getCauses = async () => {
    await ApsServices.http.nfirsGenericLookupService
      .getNfirsHazmatCauseOfReleaseEntries()
      .then((rtn) => {
        setCauses(rtn.sort(commonService.sortByStringProperty("externalId")));
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Causes of Release", err);
        setProgress({ loading: false });
      });
  };

  const [dispositions, setDispositions] = useState<ApsModels.ILookupIntDto[]>(
    []
  );
  const getDispositions = async () => {
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp("NfirsHazmatDispositionEnum")
      .then((rtn) => {
        setDispositions(rtn.sort(commonService.sortByNumericProperty("value")));
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Dispositions", err);
        setProgress({ loading: false });
      });
  };

  useEffect(() => {
    setProgress({ loading: true });
    releaseFactors.getData();
    mitigationFactors.getData();
    getCauses();
    getDispositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsHazmatCause
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
            hazmatDisposition: data.hazmatDisposition || null,
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Area", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      mitigationFactors.status === NfirsFetchStatus.Complete &&
      releaseFactors.status === NfirsFetchStatus.Complete &&
      causes.length > 0 &&
      dispositions.length > 0
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    mitigationFactors.status,
    releaseFactors.status,
    causes,
    dispositions,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsHazmatCauseOfReleaseEntryId: {
      displayName: "Cause of Release",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    hazmatDisposition: {
      displayName: "HAZMAT Disposition",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsFactorsContributingToReleaseEntry1Id: {
      displayName: "Factors Contributing to Release",
      validation: {
        required: false,
      },
    },
    nfirsFactorsContributingToReleaseEntry2Id: {
      displayName: "Factors Contributing to Release",
      validation: {
        required: false,
      },
    },
    nfirsFactorsContributingToReleaseEntry3Id: {
      displayName: "Factors Contributing to Release",
      validation: {
        required: false,
      },
    },
    nfirsFactorsContributingToMitigationEntry1Id: {
      displayName: "Factors Contributing to Migration",
      validation: {
        required: false,
      },
    },
    nfirsFactorsContributingToMitigationEntry2Id: {
      displayName: "Factors Contributing to Migration",
      validation: {
        required: false,
      },
    },
    nfirsFactorsContributingToMitigationEntry3Id: {
      displayName: "Factors Contributing to Migration",
      validation: {
        required: false,
      },
    },
    numCivilianInjuries: {
      displayName: "HAZMAT Civilian Injuries",
      validation: {
        required: false,
        max: 9999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    numCivilianDeaths: {
      displayName: "HAZMAT Civilian Deaths",
      validation: {
        required: false,
        max: 9999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
  });

  const adjustMultiSelectItems = (
    data: ApsModels.INfirsHazmatCauseInputDto
  ) => {
    const items = [
      data.nfirsFactorsContributingToReleaseEntry1Id,
      data.nfirsFactorsContributingToReleaseEntry2Id,
      data.nfirsFactorsContributingToReleaseEntry3Id,
    ].filter((i) => !!i);
    const items2 = [
      data.nfirsFactorsContributingToMitigationEntry1Id,
      data.nfirsFactorsContributingToMitigationEntry2Id,
      data.nfirsFactorsContributingToMitigationEntry3Id,
    ].filter((i) => !!i);

    onModelChange({
      ...model.new,
      ...data,
      nfirsFactorsContributingToReleaseEntry1Id: items[0],
      nfirsFactorsContributingToReleaseEntry2Id: items[1],
      nfirsFactorsContributingToReleaseEntry3Id: items[2],
      nfirsFactorsContributingToMitigationEntry1Id: items2[0],
      nfirsFactorsContributingToMitigationEntry2Id: items2[1],
      nfirsFactorsContributingToMitigationEntry3Id: items2[2],
    });
  };

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 ">
          <strong>Cause</strong>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div className="">
            <FgSelect
              id="nfirsHazmatCauseOfReleaseEntryId"
              label="Cause of Release"
              selectMessage="Select Cause"
              registeredField={registry.nfirsHazmatCauseOfReleaseEntryId}
              formState={formState}
              showRequiredLabel={isOtherModuleDataRequired}
              options={causes.map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsHazmatCauseOfReleaseEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="">
            <FgSelect
              id="hazmatDisposition"
              label="HAZMAT Disposition"
              selectMessage="Select Disposition"
              showRequiredLabel={isOtherModuleDataRequired}
              registeredField={registry.hazmatDisposition}
              formState={formState}
              options={dispositions.map((o) => {
                return {
                  label: `${o.value}. ${o.label}`,
                  value: o.value,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  hazmatDisposition: Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="mb-2">
            <label className="m-0">Factors Contributing to Release</label>
            <div className="actionTakenList mt-2">
              {[
                getModel?.new?.nfirsFactorsContributingToReleaseEntry1Id,
                getModel?.new?.nfirsFactorsContributingToReleaseEntry2Id,
                getModel?.new?.nfirsFactorsContributingToReleaseEntry3Id,
              ].map((item, i) => (
                <React.Fragment key={i}>
                  {item && (
                    <div>
                      <div>
                        {
                          (releaseFactors.data || [])?.find(
                            (a) => item === a.id
                          )?.description
                        }{" "}
                        (
                        {
                          (releaseFactors.data || [])?.find(
                            (a) => item === a.id
                          )?.externalId
                        }
                        )
                      </div>
                      <div>
                        <i
                          className="fa fa-times pointer"
                          onClick={() => {
                            if (i === 0) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsFactorsContributingToReleaseEntry1Id:
                                  null as any,
                              });
                            } else if (i === 1) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsFactorsContributingToReleaseEntry2Id:
                                  null as any,
                              });
                            } else if (i === 2) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsFactorsContributingToReleaseEntry3Id:
                                  null as any,
                              });
                            }
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>

            {(!getModel?.new?.nfirsFactorsContributingToReleaseEntry1Id ||
              !getModel?.new?.nfirsFactorsContributingToReleaseEntry2Id ||
              !getModel?.new?.nfirsFactorsContributingToReleaseEntry3Id) && (
              <AdvancedDropdown
                domId="contributingFactorEntryIds"
                value={null}
                searchPlaceholder="Search and Add More Factors"
                disabledOptionValues={[
                  getModel?.new?.nfirsFactorsContributingToReleaseEntry1Id,
                  getModel?.new?.nfirsFactorsContributingToReleaseEntry2Id,
                  getModel?.new?.nfirsFactorsContributingToReleaseEntry3Id,
                ]}
                favoritesHandler={{
                  add: ApsServices.http.nfirsFavorite
                    .addFavoriteFactorContributingToRelease,
                  remove:
                    ApsServices.http.nfirsFavorite
                      .removeFavoriteFactorContributingToRelease,
                  afterUpdate: (list: any[]) => releaseFactors.setData(list),
                }}
                options={(releaseFactors.data || []).map((o) => {
                  return {
                    label: `${o.description} (${o.externalId})`,
                    value: o.id,
                    originalObject: o,
                    isFavorite: o.isFavorite,
                    groupId: 0,
                    subGroupId: 0,
                  };
                })}
                onChange={(data) => {
                  if (
                    !getModel?.new?.nfirsFactorsContributingToReleaseEntry1Id
                  ) {
                    onModelChange({
                      ...model.new,
                      nfirsFactorsContributingToReleaseEntry1Id:
                        Number(data) || (null as any),
                    });
                  } else if (
                    !getModel?.new?.nfirsFactorsContributingToReleaseEntry2Id
                  ) {
                    onModelChange({
                      ...model.new,
                      nfirsFactorsContributingToReleaseEntry2Id:
                        Number(data) || (null as any),
                    });
                  } else if (
                    !getModel?.new?.nfirsFactorsContributingToReleaseEntry3Id
                  ) {
                    onModelChange({
                      ...model.new,
                      nfirsFactorsContributingToReleaseEntry3Id:
                        Number(data) || (null as any),
                    });
                  }
                }}
              ></AdvancedDropdown>
            )}
          </div>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div className="row">
            <div className="col-sm-12">
              <FgInput
                id="numCivilianInjuries"
                label="HAZMAT Civilian Injuries"
                placeHolder="HAZMAT Civilian Injuries"
                registeredField={registry.numCivilianInjuries}
                formState={formState}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    numCivilianInjuries: Number(data) || (null as any),
                  });
                }}
                type="number"
              ></FgInput>
            </div>
            <div className="col-sm-12">
              <FgInput
                id="numCivilianDeaths"
                label="HAZMAT Civilian Deaths"
                placeHolder="HAZMAT Civilian Deaths"
                registeredField={registry.numCivilianDeaths}
                formState={formState}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    numCivilianDeaths: Number(data) || (null as any),
                  });
                }}
                type="number"
              ></FgInput>
            </div>
          </div>

          <div className="mb-2">
            <label className="m-0">Factors Affecting Mitigation</label>
            <div className="actionTakenList mt-2">
              {[
                getModel?.new?.nfirsFactorsContributingToMitigationEntry1Id,
                getModel?.new?.nfirsFactorsContributingToMitigationEntry2Id,
                getModel?.new?.nfirsFactorsContributingToMitigationEntry3Id,
              ].map((item, i) => (
                <React.Fragment key={i}>
                  {item && (
                    <div>
                      <div>
                        {
                          (mitigationFactors.data || [])?.find(
                            (a) => item === a.id
                          )?.description
                        }{" "}
                        (
                        {
                          (mitigationFactors.data || [])?.find(
                            (a) => item === a.id
                          )?.externalId
                        }
                        )
                      </div>
                      <div>
                        <i
                          className="fa fa-times pointer"
                          onClick={() => {
                            if (i === 0) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsFactorsContributingToMitigationEntry1Id:
                                  null as any,
                              });
                            } else if (i === 1) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsFactorsContributingToMitigationEntry2Id:
                                  null as any,
                              });
                            } else if (i === 2) {
                              adjustMultiSelectItems({
                                ...model.new,
                                nfirsFactorsContributingToMitigationEntry3Id:
                                  null as any,
                              });
                            }
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>

            {(!getModel?.new?.nfirsFactorsContributingToMitigationEntry1Id ||
              !getModel?.new?.nfirsFactorsContributingToMitigationEntry2Id ||
              !getModel?.new?.nfirsFactorsContributingToMitigationEntry3Id) && (
              <AdvancedDropdown
                domId="contributingFactorEntryIds"
                value={null}
                searchPlaceholder="Search and Add More Factors"
                disabledOptionValues={[
                  getModel?.new?.nfirsFactorsContributingToMitigationEntry1Id,
                  getModel?.new?.nfirsFactorsContributingToMitigationEntry2Id,
                  getModel?.new?.nfirsFactorsContributingToMitigationEntry3Id,
                ]}
                favoritesHandler={{
                  add: ApsServices.http.nfirsFavorite
                    .addFavoriteFactorContributingToMitigation,
                  remove:
                    ApsServices.http.nfirsFavorite
                      .removeFavoriteFactorContributingToMitigation,
                  afterUpdate: (list: any[]) => mitigationFactors.setData(list),
                }}
                options={(mitigationFactors.data || []).map((o) => {
                  return {
                    label: `${o.description} (${o.externalId})`,
                    value: o.id,
                    originalObject: o,
                    isFavorite: o.isFavorite,
                    groupId: 0,
                    subGroupId: 0,
                  };
                })}
                onChange={(data) => {
                  if (
                    !getModel?.new?.nfirsFactorsContributingToMitigationEntry1Id
                  ) {
                    onModelChange({
                      ...model.new,
                      nfirsFactorsContributingToMitigationEntry1Id:
                        Number(data) || (null as any),
                    });
                  } else if (
                    !getModel?.new?.nfirsFactorsContributingToMitigationEntry2Id
                  ) {
                    onModelChange({
                      ...model.new,
                      nfirsFactorsContributingToMitigationEntry2Id:
                        Number(data) || (null as any),
                    });
                  } else if (
                    !getModel?.new?.nfirsFactorsContributingToMitigationEntry3Id
                  ) {
                    onModelChange({
                      ...model.new,
                      nfirsFactorsContributingToMitigationEntry3Id:
                        Number(data) || (null as any),
                    });
                  }
                }}
              ></AdvancedDropdown>
            )}
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSHazmatCause;
