import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSChangeLogService {
  list = async (
    page: number,
    pageSize: number,
    data: ApsModels.INfirsChangeLogSearchInputDto
  ) => {
    const url = `/api/NFIRSChangeLog/List/${page}/${pageSize}`;
    return fetcher.post<ApsModels.INfirsChangeLogGridOutputDto>(url, data);
  };
}

const nfirsChangeLog = new NFIRSChangeLogService();
export default nfirsChangeLog;
