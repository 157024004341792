import React from 'react';
import { QuestionDto, QuestionGroupDto } from '../../../data/DailyOperational';
import { IsolatedTableRowList } from '../CustomInput/IsolatedTableRowList';
import { OptionGroupDto } from '../../../data/OptionGroup';
import { PullFromPreviousDayButton } from "../CustomInput/PullFromPreviousDayButton";
import { PreviousDayService } from "../../../services/PreviousDayService";

type Props = {
    questionGroup?: QuestionGroupDto,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    date: Date,
    optionGroups : OptionGroupDto[],
    moduleOrder: number,
    needsToAutopopulate: boolean;  
    columnNumber: number
}

type State = {
    optionGroups: OptionGroupDto[],
    reload: boolean
}
export class ReserveApparatusStatus extends React.Component<Props, State> {
    state: State = {
        optionGroups: [],
        reload: false
    }
    componentDidMount = async () => {
        const optionGroups: OptionGroupDto[] = this.props.optionGroups;
        this.setState({
            optionGroups: optionGroups
        });
    }

    componentDidUpdate = async (prevProps: Props) => {
        if (prevProps !== this.props) {
            const optionGroups: OptionGroupDto[] = this.props.optionGroups;
            this.setState({
                optionGroups: optionGroups
            });
        }
    }

    private updateQuestionGroup = (newQuestionValue: QuestionDto) => {
        const questionIndex = this.props.questionGroup?.questions.findIndex(q => q.questionId == newQuestionValue.questionId);
        if (questionIndex !== undefined) {
            this.props.questionGroup!.questions[questionIndex] = newQuestionValue;
            this.props.setData(this.props.questionGroup, this.props.moduleOrder);
        }

    }

    getName = (groupId?: number) => {
        const element = this.state.optionGroups.find(g => g.optionGroupId === groupId)
        return element?.name
    }

    handlePullFromPreviousDay = async (newDayQuestionGroup: QuestionGroupDto) => {
        this.setState({
            reload: !this.state.reload
        });
        this.props.setData(newDayQuestionGroup, this.props.moduleOrder);
    }

    render() {
        return (
            <div className="grid-item apparatus-status card box-card flex-grow-1">
                <div className="card-body">
                    <div className="row">
                        <h4 className="mb-0 font-size-16 col-xl-11 col-10">{this.props.questionGroup?.name ?? ''}</h4>
                        {PreviousDayService.isFeatureAvailable(this.props.date) && <PullFromPreviousDayButton questionGroupId={this.props.questionGroup?.questionGroupId!} calendarDate={this.props.date} setData={this.handlePullFromPreviousDay} />}
                    </div>
                    <div className="mt-3">Notify Staff Captain to update.</div>
                    <table className="reserve-apparatus-status-table table mt-3">
                        <thead>
                            <tr>
                                {this.props.questionGroup?.questions.slice(0 * this.props.columnNumber, 1 * this.props.columnNumber).map((x, i) => {
                                    return (
                                        <th key={x.order} className="col w-25">{this.getName(x.optionGroupId)}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.questionGroup?.questions.slice(0 * this.props.columnNumber, 1 * this.props.columnNumber) && <IsolatedTableRowList needsToAutoField={this.props.needsToAutopopulate} optionGroups={this.state.optionGroups} defaultItemsCount={7} questions={this.props.questionGroup.questions.slice(0 * this.props.columnNumber, 1 * this.props.columnNumber) as QuestionDto[]} updateQuestionGroup={this.updateQuestionGroup} date={this.props.date} reload={this.state.reload} />}
                        </tbody>
                    </table>
                    <table className="reserve-apparatus-status-table table mt-3">
                        <thead>
                            <tr>
                                {this.props.questionGroup?.questions.slice(1 * this.props.columnNumber, 2 * this.props.columnNumber).map((x, i) => {
                                    return (
                                        <th key={x.order} className="col w-25">{this.getName(x.optionGroupId)}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.questionGroup?.questions.slice(1 * this.props.columnNumber, 2 * this.props.columnNumber) && <IsolatedTableRowList  needsToAutoField={this.props.needsToAutopopulate} optionGroups={this.state.optionGroups} defaultItemsCount={1} questions={this.props.questionGroup?.questions.slice(1 * this.props.columnNumber, 2 * this.props.columnNumber) as QuestionDto[]} updateQuestionGroup={this.updateQuestionGroup} date={this.props.date} reload={this.state.reload} />}
                        </tbody>
                    </table>
                    <table className="reserve-apparatus-status-table table mt-3">
                        <thead>
                            <tr>
                                {this.props.questionGroup?.questions.slice(2 * this.props.columnNumber, 3 * this.props.columnNumber).map((x, i) => {
                                    return (
                                        <th key={x.order} className="col w-25">{this.getName(x.optionGroupId)}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.questionGroup?.questions.slice(2 * this.props.columnNumber, 3 * this.props.columnNumber) && <IsolatedTableRowList needsToAutoField={this.props.needsToAutopopulate} optionGroups={this.state.optionGroups} defaultItemsCount={6} questions={this.props.questionGroup?.questions.slice(2 * this.props.columnNumber, 3 * this.props.columnNumber) as QuestionDto[]} updateQuestionGroup={this.updateQuestionGroup} date={this.props.date} reload={this.state.reload}  />}
                        </tbody>
                    </table>
                    <table className="reserve-apparatus-status-table table mt-3">
                        <thead>
                            <tr>
                                {this.props.questionGroup?.questions.slice(3 * this.props.columnNumber ,  4 * this.props.columnNumber).map((x, i) => {
                                    return (
                                        <th key={x.order} className="col w-25">{this.getName(x.optionGroupId)}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.questionGroup?.questions.slice(3 * this.props.columnNumber, 4 * this.props.columnNumber) && <IsolatedTableRowList needsToAutoField={this.props.needsToAutopopulate} optionGroups={this.state.optionGroups} defaultItemsCount={6} questions={this.props.questionGroup?.questions.slice(3 * this.props.columnNumber, 4 * this.props.columnNumber) as QuestionDto[]} updateQuestionGroup={this.updateQuestionGroup} date={this.props.date} reload={this.state.reload} />}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
