import { USER_MUST_CHANGE_PASSWORD_MESSAGE } from "../common/Constants";
import { Observable } from "../common/Observable";
import { NewPasswordDto } from "../data/User";

declare let pendo: any;

export type LoginResponseDto = {
    isVerified: boolean,
    userRequiresPasswordReset: boolean,
};

export type ChangePasswordDto = {
    username: string,
    newPassword: string,
    temporaryPassword: string,
};

export type AccountDto = {
    userId: number,
    firstName: string,
    lastName: string,
    isLoggedIn: boolean,
    hasAccessUserConsole: boolean,
    hasAccessDailyOperational: boolean,
    isVisibleUserConsole: boolean,
    isVisibleDailyOperational: boolean,
    isEditableDailyOperational: boolean,
    isAdmin: boolean,
    isAccountAdmin: boolean,
};

class AuthenticationService {
    readonly isAuthenticatedObservable = new Observable<AccountDto | null>(null);

    initialize = async (): Promise<AccountDto | undefined> => {
        const response = await fetch('/Api/Account');
        if (response.ok) {
            const userAccount = await response.json() as AccountDto;
            pendo.initialize({
                visitor: {
                     id: userAccount.userId
                 },
                account: {
                    id: userAccount.userId
                }
            });
            this.isAuthenticatedObservable.set(userAccount);
            return userAccount;
        }
    }

    login = async (username: string, password: string) => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        };
        const loginResponseDto: LoginResponseDto = { isVerified: false, userRequiresPasswordReset: false }

        try {
            const response = await fetch('/Api/Account/Login', options);
            if (!response.ok) {
                // read the response so it will show up in the network tab for potential trouble-shooting
                const text = await response.text();
                if (text === USER_MUST_CHANGE_PASSWORD_MESSAGE) {
                    loginResponseDto.userRequiresPasswordReset = true;
                }
            }
            const responseJson: AccountDto = await response.json();
            pendo.initialize({
                visitor: {
                     id: responseJson.userId
                 },
                account: {
                    id: responseJson.userId
                }
            });
            this.isAuthenticatedObservable.set(responseJson);
            loginResponseDto.isVerified = true;
        }
        catch (error) {
            console.error(error);
        }
        finally {
            return loginResponseDto;
        }
    }
    
    microsoftLogin = async (microsoftToken: string) => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                microsoftToken: microsoftToken,
            }),
        };
        const loginResponseDto: LoginResponseDto = { isVerified: false, userRequiresPasswordReset: false }
        try {
            const response = await fetch('/Api/Account/LoginWithMicrosoftToken', options);
            if (response.ok) {
                const responseJson: AccountDto = await response.json();
                this.isAuthenticatedObservable.set(responseJson);
                loginResponseDto.isVerified = true;
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            return loginResponseDto;
        }
    }

    logout = async () => {
        await fetch('/Api/Account/Logout');
        this.isAuthenticatedObservable.set({ userId: 0, firstName: "", lastName: "", isLoggedIn: false, isEditableDailyOperational: false, isVisibleDailyOperational: false, isVisibleUserConsole: false, hasAccessDailyOperational: false, hasAccessUserConsole: false, isAdmin: false, isAccountAdmin: false });
    }

    updatePassword = async (newPassword: NewPasswordDto) => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password: newPassword.newPassword,
                username: newPassword.username
            }),
        };
        await fetch("/Api/Account/ResetUserPassword", options);
    }

    resetProfilePassword = async (newPassword: NewPasswordDto) => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password: newPassword.newPassword,
                username: newPassword.username
            }),
        };
        await fetch("/Api/Account/ResetProfilePassword", options);
    }

    forgotPassword = async (username: string) => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
            }),
        };

        await fetch('/Api/Account/ForgotPassword', options);
    }

    resetPassword = async (username: string, token: string, password: string) => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                token: token,
                password: password,
            }),
        };

        try {
            const response = await fetch('/Api/Account/ResetPassword', options);

            if (!response.ok) {
                return false;
            }

            return true;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }

    changePassword = async (dto: ChangePasswordDto) => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: dto.username,
                newPassword: dto.newPassword,
                temporaryPassword: dto.temporaryPassword
            }),
        };
        let success = false;
        try {
            const response = await fetch('/Api/Account/ChangePassword', options);
            if (response.ok) {
                success = true;
                this.isAuthenticatedObservable.set(response.json as unknown as AccountDto);
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            return success;
        }

    }

    linkMicrosoftAccount = async (microsoftToken: string | null) => {
        const options: RequestInit = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                microsoftToken: microsoftToken
            }),
        };
        let success = false;
        try {
            const response = await fetch(`/Api/Account/LinkMicrosoftAccount`, options);
            if (response.ok) {
                success = true;
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            return success;
        }
    }
    
    unlinkMicrosoftAccount = async  () => {
        const options: RequestInit = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        let success = false;
        try {
            const response = await fetch(`/Api/Account/UnlinkMicrosoftAccount`, options);
            if (response.ok) {
                success = true;
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            return success;
        }
    }

    SetDepartment = async (id: number) => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let success = false;
        try {
            const response = await fetch(`/Api/Account/SetDepartment/${id}`, options);
            if (response.ok) {
                success = true;
                const responseJson: AccountDto = await response.json();
                this.isAuthenticatedObservable.set(responseJson);
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            return success;
        }

    }
};

const authenticationService = new AuthenticationService();
export { authenticationService as AuthenticationService };