import React, { Component, useEffect, useState } from 'react';
import CommonSystemErrors from '../aps2/components/Common/CommonSystemErrors';
import Toast from '../aps2/components/Toast/Toast';
import commonService from '../aps2/services/CommonService';
import { Header } from './Header/Header';
import { SideBar } from './SideBar/SideBar';
import SysNotification from '../aps2/components/Notification/SysNotification';
import systemStore from '../aps2/stores/SystemStore';
import ConfirmDialog from '../aps2/components/Common/ConfirmDialog';
import { reaction } from 'mobx';
import { useHistory } from 'react-router';

type Props = {}

type State = {
    isSideBarVisible: boolean;
}

const DevRefView = () => {
    return <div hidden={true} className="DevRef">
        {commonService.devRef}
    </div>
}
export class Layout extends Component<Props, State> {
    static displayName = Layout.name;

    state: State = {
        isSideBarVisible: true,
    };

    componentDidMount = () => {
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.setAttribute('data-topbar', 'light');
    }

    componentDidUpdate = (_prevProps: Props, prevState: State) => {
        const classes = ['vertical-collpsed', 'sidebar-enable'];

        if (prevState.isSideBarVisible !== this.state.isSideBarVisible) {
            if (this.state.isSideBarVisible) {
                document.body.classList.remove(...classes);
            }
            else {
                document.body.classList.add(...classes);
            }
        }
    }

    toggleSideBar = () => {
        this.setState({ isSideBarVisible: !this.state.isSideBarVisible });

        //APS-531 - Uncomment this for testing Sys Notification
        //systemStore.setShowNotification(true, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ");
    }

    render() {
        return (                
            <div id="layout-wrapper" className="h-screen">
                <SysNotification />
                <DevRefView />
                <Toast />
                <CommonSystemErrors/>
                <Header toggleSideBar={this.toggleSideBar} isSideBarVisible={this.state.isSideBarVisible} />
                <SideBar isSideBarVisible={this.state.isSideBarVisible} 
                    menuClick={() => {
                        if(window.innerWidth <= 1285) {
                            this.toggleSideBar();
                        }
                    }} 
                />
                <Error417Dialog />
                <Error408Dialog />
                <div id="main-content"  className="main-content h-full">
                    <div id="main-container-wrapper" className="page-content h-full">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

const Error417Dialog = () => {
    const [has417Error, setHas417Error] = useState<string | null>(null);
    const history = useHistory();
  
    useEffect(() => {
      const disposer = reaction(
        () => systemStore.has417Error,
        (data) => {
          setHas417Error(data);
        }
      );
      return () => {
        disposer();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
      <>
        <ConfirmDialog
          show={!!has417Error}
          title="Unexpected hiccup - we’re on it!"
          message={has417Error || ""}
          buttons="okonly"
          titleStyles={{ fontSize: "16px" }}
          done={(e) => {
            systemStore.setHas417Error(null);
            if(history.action != "POP") {
                history.goBack();
            } else {
                history.push("/home");
            }
          }}
        ></ConfirmDialog>
      </>
    );
  };
  
  const Error408Dialog = () => {
    const [has408Error, setHas408Error] = useState<string | null>(null);
    //const history = useHistory();

    useEffect(() => {
      const disposer = reaction(
        () => systemStore.has408Error,
        (data) => {
          setHas408Error(data);
        }
      );
      return () => {
        disposer();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <ConfirmDialog
          show={!!has408Error}
          title="Temporary Delay - We're on it!"
          message={has408Error || ""}
          buttons="okonly"
          titleStyles={{ fontSize: "16px" }}
          done={(e) => {
            systemStore.setHas408Error(null);
            // if (history.action != "POP") {
            //   history.goBack();
            // } else {
            //   history.push("/home");
            // }
          }}
        ></ConfirmDialog>
      </>
    );
  };