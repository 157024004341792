import React, { useContext, useEffect, useRef, useState } from "react"
import { Collapse } from "reactstrap"
import { SectionItem } from "../../../data/UserConsoleAdmin"
import ExpandCollapse from "../../Shared/ExpandCollapse"
import { UCAdminContext } from "./UserConsoleAdmin"

type Props = {
    path: number[]
    data: SectionItem
    departmentName: string
}

export const UserConsoleAdminSectionItem: React.FC<Props> = ({ path, data, departmentName }) => {
    const [itemName, setItemName] = useState(data.itemName);
    const [url, setUrl] = useState(data.url);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    const [document, setDocument] = useState<string>("");
    const [fileContent, setFileContent] = useState<string  | ArrayBuffer | null>("");

    const [ssoEnabled, setSsoEnabled] = useState("No");
    const [selectedSsoOption, setSelectedSsoOption] = useState<string>("");
    const [collapsed, setCollapsed] = useState<boolean | undefined>(undefined);
    const { children } = data;
    const { updateLink, addLink, deleteLink, allGroups, ssoServices: ssoTypes, updateLinkChangeStatus } = useContext(UCAdminContext);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const ssoAvailable = ssoTypes.length > 0;

    useEffect(() => {
        const selectedGs = data.groups.map(g => g.groupId.toString());
        setSelectedGroups(selectedGs);
        if (data.ssoServiceId) {
            setSelectedSsoOption(data.ssoServiceId.toString());
            setSsoEnabled("Yes");
        } else {
            setSelectedSsoOption("");
            setSsoEnabled("No");
        }
    }, [])

    const onSaveClicked = () => {
        const groups = allGroups.filter(g => selectedGroups.includes(g.groupId.toString()));
        let ssoServiceId: number | null;
        if (ssoEnabled === "No" || selectedSsoOption === "") {
            ssoServiceId = null;
        } else {
            ssoServiceId = Number(selectedSsoOption);
        }
        updateLink(path, { ...data, itemName, url, groups, ssoServiceId, blob: fileContent, fileName: document }, true);
        setCollapsed(true);

    }

    const onSSOOptionsChange = (selected: string) => {
        setSelectedSsoOption(selected);
    }

    function readFile(file: Blob) {
        return new Promise((resolve, reject) => {
          // Create file reader
          let reader = new FileReader()
      
          // Register event listeners
          reader.addEventListener("loadend", e => resolve(e?.target?.result))
          reader.addEventListener("error", reject)
      
          // Read file
          reader.readAsArrayBuffer(file)
        })
      }

    async function getAsByteArray(file: File) {
        const fi = await readFile(file as any);
        return new Uint8Array(fi as any);
      }
    
    const  arrayBufferToBase64String = (buffer: ArrayBuffer) => {
        let binaryString = ''
        var bytes = new Uint8Array(buffer);
        for (var i = 0; i < bytes.byteLength; i++) {
          binaryString += String.fromCharCode(bytes[i]);
        }
      
        return window.btoa(binaryString);
      }
    
    const onDocumentChange = async (files: File[]) => {
        if (files.length == 0) {
            return;
        }

       const bytes =  await getAsByteArray(files[0]);
       const file = arrayBufferToBase64String(bytes)

       setFileContent(file);
       setDocument(departmentName + "/" + files[0].name)
    }

    const onGroupChange = (selectedOptions: HTMLCollectionOf<HTMLOptionElement>) => {
        const options = Array.from(selectedOptions);
        setSelectedGroups(options.map(o => o.value));
    }

    const collapseId = path.join("");

    return (
        <ExpandCollapse
            id={itemName + collapseId}
            title={itemName}
            barStyle={{
                border: "2px solid gray",
                borderRadius: "8px",
                marginBottom: "1em"
            }}
            collapse={collapsed}
            onCollapseChange={()=>setCollapsed(undefined)}
        >
            <div className="row">
                <div className="col">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="linkTitle" className="form-control-label">Link Title</label>
                            <input
                                type="text"
                                id="linkTitle"
                                value={itemName}
                                className="form-control"
                                onChange={e => {
                                    setItemName(e.target.value);
                                    updateLinkChangeStatus(true);
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="linkUrl" className="form-control-label">Link Url</label>
                            <input
                                type="text"
                                id="linkUrl"
                                value={url}
                                className="form-control"
                                onChange={e => {
                                    setUrl(e.target.value);
                                    updateLinkChangeStatus(true);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="linkGroups" className="form-control-label">Link Visible to Which Groups?</label>
                            <select
                                id="linkGroups"
                                className="form-control"
                                onChange={e => {
                                    onGroupChange(e.target.selectedOptions);
                                    updateLinkChangeStatus(true);
                                }}
                                value={selectedGroups}
                                multiple={true}
                            >
                                {allGroups.map(g => (
                                    <option
                                        value={g.groupId}
                                        key={`UCGroups-${g.groupId}`}
                                    >
                                        {g.groupName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label className="form-control-label">Upload Link Document</label>
                            {/* For some reason the form control styling on a regular form input looks really weird, so instead we spoof the file input above */}
                            <div className="input-group">
                                <input type="text" className="form-control" value={document} disabled />
                                <input type="file" hidden className="form-control" id="inputGroupFile02" ref={fileInputRef} onChange={e => {
                                    onDocumentChange(Array.from(e.target.files ?? []));
                                    updateLinkChangeStatus(true);
                                    }}
                                />
                                <button className="input-group-text" onClick={() => fileInputRef.current?.click()}>Upload</button>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="UCLinkSSORadios" className="form-control-label">Is this an SSO Integration?</label>
                            <div id="UCLinkSSORadios" >
                                <div className="form-check form-check-inline">
                                    <input
                                        type="radio"
                                        value={"Yes"}
                                        checked={ssoEnabled === "Yes"}
                                        onChange={e => {
                                            setSsoEnabled(e.target.value);
                                            updateLinkChangeStatus(true);
                                        }}
                                    />
                                    <label className="form-check-label">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        type="radio"
                                        value={"No"}
                                        checked={ssoEnabled === "No"}
                                        onChange={e => {
                                            setSsoEnabled(e.target.value);
                                            updateLinkChangeStatus(true);
                                        }}
                                    />
                                    <label className="form-check-label">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="UCSSOOptionsSelect" className="form-control-label">Select SSO Integration</label>
                            {ssoAvailable ?
                                ssoEnabled === "Yes" ?
                                    <select
                                        id="UCSSOOptionsSelect"
                                        className="form-control"
                                        onChange={e => {
                                            onSSOOptionsChange(e.target.value);
                                            updateLinkChangeStatus(true);
                                        }}
                                        value={selectedSsoOption}
                                    >
                                        <option value="">
                                            Select One
                                        </option>
                                        {ssoTypes.map(s => (
                                            <option
                                                value={s.ssoServiceId}
                                                key={`UCSSOOptions-${s.ssoServiceName}`}
                                            >
                                                {s.ssoServiceName}
                                            </option>
                                        ))}
                                    </select>
                                    :
                                    <div>Enable SSO Integration for this link to see available SSO services.</div>
                                :
                                <div>No SSO services have been enabled for this department.</div>
                            }
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <button
                                className="btn btn-primary"
                                onClick={() => addLink(path, true)}
                            >
                                Add Link
                            </button>
                        </div>
                    </div>
                    {children.map((si, i) => (
                        <UserConsoleAdminSectionItem
                            departmentName={departmentName}
                            path={[...path, i]}
                            data={si}
                            key={`UCAdminSectionItem-${si.itemId}`}
                        />
                    ))}
                    <div className="row mb-3">
                        <div className="col">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button
                                    className="btn btn-danger me-md-2"
                                    onClick={() => deleteLink(path, true)}
                                >
                                    Delete Link
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={onSaveClicked}
                                >
                                    Save Link
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ExpandCollapse>
    )
}


