import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import CommonSpinner from "../../../../aps2/components/Common/CommonSpinner";
import commonService from "../../../../aps2/services/CommonService";
import {
  AnswerDto,
  AnswerType,
  IsolatedSelectItem,
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { OptionGroupDto } from "../../../../data/OptionGroup";
import { OptionGroupService } from "../../../../services/OptionGroupService";
import { OptionService } from "../../../../services/OptionService";
import { GetDropdownOptionGroupId } from "../../../DailyOperationalDesigner/ComponentGalleryDefinitions";
import { IsolatedSelect } from "../../CustomInput/IsolatedSelect";

interface IGenericDropdownProps {
  questionGroup?: QuestionGroupDto;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  date: Date;
  moduleOrder: number;
  departmentId: number;
  optionGroups: OptionGroupDto[];
  instanceId: string;
}

interface IGenericDropdownState {
  users: OptionDto[] | null;
  selectedUserId: number | null;
  isOpenModal: boolean;
  ready: boolean;
}

function GenericDropdown(props: IGenericDropdownProps) {
  const [state, setState] = useState<IGenericDropdownState>({
    users: null,
    selectedUserId: null,
    isOpenModal: false,
    ready: false,
  });

  const getAnswerUserId = (
    questions: QuestionDto[] | undefined
  ): number | undefined => {
    const operationsBC: number = 0;
    return (
      questions &&
      questions
        .find((x) => x.order === operationsBC)
        ?.answers.find((y) => y.order === 0)?.user?.userId
    );
  };

  const getAnswerUserName = (
    questions: QuestionDto[] | undefined
  ): string | undefined => {
    const operationsBC: number = 0;
    return (
      questions &&
      questions
        .find((x) => x.order === operationsBC)
        ?.answers.find((y) => y.order === 0)?.user?.userName
    );
  };

  const getOptions = async (
    name: string,
    optionGroups: OptionGroupDto[]
  ): Promise<OptionDto[]> => {
    const optionsGroup = optionGroups.find((g) => g.name === name);
    return optionsGroup
      ? await OptionService.getGroupOptions(optionsGroup.optionGroupId)
      : [];
  };

  const initialize = async () => {
    const optionGroups: OptionGroupDto[] = !props.optionGroups.length
      ? await OptionGroupService.getAllOptionGroupsByDepartment(
          props.departmentId
        )
      : props.optionGroups;
    const options = await getOptions(
      GetDropdownOptionGroupId(props.instanceId),
      optionGroups
    );
    setState((prev) => {
      return {
        ...prev,
        users: options.sort(commonService.sortByStringProperty("name")),
        ready: true,
      };
    });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateQuestionGroup = (newQuestionValue: QuestionDto) => {
    const questionGroup = cloneDeep(props.questionGroup)!;
    const questionIndex = questionGroup.questions.findIndex(
      (q) => q.order == newQuestionValue.order
    );
    questionGroup.questions[questionIndex] = newQuestionValue;
    props.setData(questionGroup, props.moduleOrder);
  };

  const handleOperationBCChanged = (userId?: number, userName?: string) => {
    const questionOrder = 0;
    const question: QuestionDto = cloneDeep(
      props.questionGroup!.questions.find((q) => q.order == questionOrder)!
    );
    const isAnswerExists = question.answers.length > 0;
    const answerId: number = question!.answers[0]
      ? question!.answers[0].answerId!
      : 0;

    let answer: AnswerDto;

    if (isAnswerExists || answerId > 0) {
      answer = question.answers.find((a) => a.answerId == answerId)!;
      answer.user!.userId = userId;
      answer.user!.userName = userName;
      answer.date = props.date;
      answer.dateCreated = props.date;
      answer.dateUpdated = props.date;
    } else {
      answer = createNewAnswer(answerId, question, userId, userName);
      question.answers.push(answer);
    }
    updateQuestionGroup(question);
  };

  const createNewAnswer = (
    answerId: number,
    question: QuestionDto,
    userId?: number,
    userName?: string
  ): AnswerDto => {
    return {
      answerId: answerId,
      answerType: AnswerType.User,
      date: props.date,
      dateCreated: props.date,
      dateUpdated: props.date,
      flagAnswerValue: false,
      order: 0,
      questionId: question.questionId ?? 0,
      user: { userId: userId, userName: userName, answerId: 0 },
    };
  };

  const triggerModal = (): void => {
    setState((prev) => {
      return {
        ...prev,
        isOpenModal: !prev.isOpenModal,
      };
    });
  };

  const handleModalClose = (): void => {
    triggerModal();
  };

  const userId: number | undefined = getAnswerUserId(
    props.questionGroup?.questions
  );
  const userName: string | undefined = getAnswerUserName(
    props.questionGroup?.questions
  );

  const items: IsolatedSelectItem[] = state.users
    ? state.users!.map((u) => {
        return { id: u.optionId, name: u.name };
      })
    : [];

  const hideOtherOption = (idx: number) => {
    const val = (props as any)[`displayOther${idx}`];
    if(val === undefined) {
      return false;
    }
    return !val;
  }

  return (
    <div className="daily-operational-card card box-card">
      {/* {props.instanceId} */}
      {!state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
      <div className="card-body ">
        <h4 className="mb-0 font-size-16">{props.questionGroup?.name || ""}</h4>
        <br />
        <div>
          <IsolatedSelect
            onChange={handleOperationBCChanged}
            availableOptions={items}
            id={userId}
            name={userName}
            showDefaultOptionName={true}
            className="col-xl-12 border rounded-10 h-8 form-control"
            hideOtherOption={hideOtherOption(0)}
          />
        </div>
      </div>
      <div className="d-none mr-3 mb-2 childsection1">
        <span className="mb-0 font-size-22">
          <b>{props.questionGroup?.name || ""}</b> :{" "}
          {userName ?? items.find((x) => x.id == userId)?.name}
        </span>
      </div>
    </div>
  );
}

export default GenericDropdown;
