import React, { useEffect, useState } from "react";
import moment from "moment";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import CommonSpinner from "../../Common/CommonSpinner";
import { RouteComponentProps } from "react-router";
import { FgUseForm } from "../../Common/FormGroups";
import DrillCompanyShift from "./DrillCompanyShift";
import DrillDetail from "./DrillDetail";
import DrillExternalPartners from "./DrillExternalPartners";
import DrillParticipants from "./DrillParticipants";
import OverlappingDrillsDialog from "./Dialogs/OverlappingDrillsDialog";
import ExcessiveHoursDialog from "./Dialogs/ExcessiveHoursDialog";
import toastStore from "../../../stores/ToastStore";
import ConfirmDialog from "../../Common/ConfirmDialog";
import commonService from "../../../services/CommonService";
import "../../../styles/common.scss";
import { UserService } from "../../../../services/UserService";
export interface IDrillReportProps {
  template: ApsModels.IDrillTemplateOutputDto;
  report: ApsModels.IDrillReportOutputDto;
  sectionNumber: number;
  allowEdit?: boolean;
  allowAddOthers?: boolean;
}

const DEFAULT_TRAINING_TYPE = "Company Training";

function Drill(props: RouteComponentProps<any>) {
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
  }, []);

  useEffect(() => {
    if (departmentId > 0) {
      getDrillTemplate();
    }
  }, [departmentId]);

  const user = commonService.userInfo;
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowAddOthers, setAllowAddOthers] = useState(false);

  const toggleClaims = () => {
    setAllowAddOthers(commonService.friendlyClaims.canSubmitAll);
  };

  const [loading, setLoading] = useState(true);
  const [drillTemplate, setDrillTemplate] = useState(
    {} as ApsModels.IDrillTemplateOutputDto
  );
  const [drillReport, setDrillReport] = useState({
    selectedParticipants: user
      ? [
          {
            id: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
          },
        ]
      : [],
  } as ApsModels.IDrillReportOutputDto);
  const [reportId, setReportId] = useState(
    parseInt(props?.match?.params?.id) || null
  );
  const [reuse] = useState(props?.match?.params?.mode === "reuse");

  const [showCompShiftSection, setShowCompShiftSection] = useState(false);
  const [showExtPartSection, setShowExtPartSection] = useState(false);

  const getDrillTemplate = async () => {
    setLoading(true);
    await ApsServices.http.drillTemplate
      .getForSubmission()
      .then((data) => {
        setShowCompShiftSection(data.isCompanyEnabled || data.isShiftEnabled);
        if (data.isDrillEnabled) {
          setShowExtPartSection(data.isJacEnabled || data.isIsaEnabled);
        } else {
          setShowExtPartSection(false);
        }

        setDrillTemplate(data);
        if (reportId) {
          getDrillReport(reportId, data);
        } else {
          //IF NEW
          const claims = commonService.friendlyClaims;
          setAllowEdit(claims.canEditAll || claims.canEditOwn);

          //SET DEFAULT TRAINING TYPE IF ENABLED
          let defaultTrainingType: number[] = [];
          if (data.isTrainingTypeEnabled) {
            const trnTypeMatch = data.trainingTypes.find(
              (tt) => tt.name === DEFAULT_TRAINING_TYPE
            );
            if (trnTypeMatch && trnTypeMatch.id) {
              defaultTrainingType = [trnTypeMatch.id];
            }
          }

          setDrillReport({
            startTime: moment(
              moment(new Date()).format("YYYY-MM-DDT00:00")
            ).toDate(),
            endTime: moment(
              moment(new Date()).format("YYYY-MM-DDT01:00")
            ).toDate(),
            selectedParticipants: drillReport.selectedParticipants,
            selectedTrainingTypeIds: defaultTrainingType,
          } as any);

          setValue("selectedParticipants", drillReport.selectedParticipants);
          setValue("selectedTrainingTypeIds", defaultTrainingType);

          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toastStore.showError("Unable to get Drill Template.", err);
        setLoading(false);
      });
  };

  const toNearest15min = (
    dateTimeString: string,
    useDurationYesNo: boolean
  ) => {
    if (!dateTimeString) return null;
    const dt = moment(dateTimeString);

    if (useDurationYesNo) {
      return dt.toDate();
    }

    const min = Number(dt.format("mm"));

    if ([0, 15, 30, 45].indexOf(min) > -1) {
      return dt.toDate();
    }

    let rnd = (Math.round(min / 15) * 15) % 60;
    if (min > 45 && rnd === 0) {
      rnd = 60;
    }
    const adj = rnd - min;
    const newDt = moment(dt.toDate()).add(adj, "minute");
    //console.log(`${dt.toString()} -> ${newDt.toString()}`);
    return newDt.toDate();
  };

  const getDrillReport = async (
    id: number,
    template: ApsModels.IDrillTemplateOutputDto
  ) => {
    setLoading(true);
    await ApsServices.http.drillReport
      .get(id)
      .then((data) => {
        data = {
          ...data,
          ...(reuse
            ? {
                startTime: moment(
                  moment(new Date()).format("YYYY-MM-DD") +
                    moment(data.startTimeString).format("THH:mm")
                ).toDate(),
                endTime: moment(
                  moment(new Date()).format("YYYY-MM-DD") +
                    moment(data.endTimeString).format("THH:mm")
                ).toDate(),
              }
            : {
                startTime: toNearest15min(
                  data.startTimeString,
                  template.useDurationYesNo
                ) as any,
                endTime: toNearest15min(
                  data.endTimeString,
                  template.useDurationYesNo
                ) as any,
              }),
        };

        if (commonService.userInfo?.isAdmin) {
          setAllowEdit(true);
        } else {
          const claims = commonService.friendlyClaims;
          let cbc = data?.hasBeenSubmitted ? data.canBeChanged : true;

          if (cbc) {
            if (claims.canEditAll) {
              setAllowEdit(true);
            } else if (claims.canEditOwn) {
              setAllowEdit(
                data.reporterId === commonService.userInfo.userId
                //P23172-183 - EditOwn can now only edit whay I submitted
                // ||
                // !!data?.selectedParticipants?.find(
                //   (p) => p.id === commonService.userInfo.userId
                // )
              );
            }
          } else {
            setAllowEdit(false);
          }
        }

        setDrillReport(data);
        if (data) {
          for (let p in registry) {
            (setValue as any)(p, (data as any)[p]);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.StatusCode === 404) {
          console.error("Drill Report Not Found");
          toastStore.showError(`Couldn't find Drill Report ${id}.`, err);
          props.history.push("/drills");
        } else {
          toastStore.showError("Error getting Drill Report.", err);
        }
      })
      .finally(() => {
        if (reuse) {
          props.history.replace(`/drills/new`);
          setReportId(null);
        }
      });
  };

  const [reqOtherDrill, setReqOtherDrill] = useState(false);
  const [reqOtherLocation, setReqOtherLocation] = useState(false);
  const [reqOtherTrainingType, setReqOtherTrainingType] = useState(false);
  const [reqOtherISACourse, setReqOtherISACourse] = useState(false);

  const durationValidation = (val: any) => {
    const duration = Number(val || "0") || 0;
    if (duration < 0.25 || duration > 12) {
      return "Drill duration must be between 0.25 and 12 hours";
    }
    if (drillTemplate.useDurationYesNo && !!(duration % 0.25)) {
      return "Drill duration must be in increments of 0.25";
    }
    return true;
  };

  const fields = {
    companyId: {
      displayName: "Company",
      validation: { required: drillTemplate?.isCompanyEnabled },
    },
    logDrillShiftId: {
      displayName: "Shift",
      validation: { required: drillTemplate?.isShiftEnabled },
    },
    drillId: {
      displayName: "Drill Name",
      validation: { required: drillTemplate?.isDrillEnabled },
    },
    locationId: {
      displayName: "Location",
      validation: { required: drillTemplate?.isLocationEnabled },
    },
    selectedTrainingTypeIds: {
      displayName: "Training Type",
      validation: { required: drillTemplate?.isTrainingTypeEnabled },
    },
    description: {
      displayName: "Drill Description",
      validation: { required: true },
    },
    logDrillSubjectId: {
      displayName: "Primary Subject",
      validation: {
        required: drillTemplate?.isDrillEnabled && drillTemplate?.isJacEnabled,
      },
    },
    logDrillAreaOfStudyId: {
      displayName: "Specific Area of Study",
      validation: {
        required: drillTemplate?.isDrillEnabled && drillTemplate?.isJacEnabled,
      },
    },
    calJacTrainingTypeId: {
      displayName: "Type of Training",
      validation: {
        required: drillTemplate?.isDrillEnabled && drillTemplate?.isJacEnabled,
      },
    },
    isaCourseId: {
      displayName: "Course",
      validation: { required: drillTemplate?.isIsaEnabled },
    },
    selectedParticipants: {
      displayName: "Participant",
      validation: { required: true },
    },
    startTime: {
      displayName: "Start Date/Time",
      validation: { required: true },
    },
    endTime: {
      displayName: "End Date/Time",
      validation: { required: true },
    },
    duration: {
      displayName: "Duration",
      validation: {
        validate: {
          duration: durationValidation,
        },
      },
    },
    otherDrillName: {
      displayName: "Other Drill Name",
      validation: {
        validate: {
          required: (val: any) => {
            if (reqOtherDrill && (val || "").trim() === "") {
              return false;
            }
            return true;
          },
        },
      },
    },
    instructor: {
      displayName: "Instructor",
      validation: {
        maxLength: 64,
      },
    },
    otherLocation: {
      displayName: "Other Location",
      validation: {
        validate: {
          required: (val: any) => {
            if (reqOtherLocation && (val || "").trim() === "") {
              return false;
            }
            return true;
          },
        },
      },
    },
    otherTrainingType: {
      displayName: "Other Training Type",
      validation: {
        validate: {
          required: (val: any) => {
            if (reqOtherTrainingType && (val || "").trim() === "") {
              return false;
            }
            return true;
          },
        },
      },
    },
    otherIsaCourse: {
      displayName: "Other ISA Course",
      validation: {
        validate: {
          required: (val: any) => {
            if (reqOtherISACourse && (val || "").trim() === "") {
              return false;
            }
            return true;
          },
        },
      },
    },
  };

  const {
    registry,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    reset,
    formRef,
    triggerSubmitForm,
  } = FgUseForm(fields);

  const watchReqOtherDrill = watch("_requireOtherDrillName");
  const watchReqOtherLocation = watch("_requireOtherLocation");
  const watchReqOtherTrainingType = watch("_requireOtherTrainingType");
  const watchReqOtherISACourse = watch("_reqOtherISACourse");

  useEffect(() => {
    setReqOtherDrill(watchReqOtherDrill);
    setReqOtherLocation(watchReqOtherLocation);
    setReqOtherTrainingType(watchReqOtherTrainingType);
    setReqOtherISACourse(watchReqOtherISACourse);
  }, [
    watchReqOtherDrill,
    watchReqOtherLocation,
    watchReqOtherTrainingType,
    watchReqOtherISACourse,
  ]);

  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [saving, setSaving] = useState(false);

  const [drillToSubmit, setDrillToSubmit] = useState(
    {} as ApsModels.IDrillReportInputDto
  );
  const [validationError, setValidationError] = useState(
    {} as ApsModels.IDrillReportVerificationDto
  );
  const [processValidationData, setProcessValidationData] = useState(
    {} as {
      prev: ApsModels.IDrillReportVerificationDto;
      new: ApsModels.IDrillReportInputDto;
      participants?: {
        [key: number]: boolean;
      };
    }
  );
  const [showExcessive, setShowExcessive] = useState(false);
  const [showOverlapping, setShowOverlapping] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [forDelete, setForDelete] = useState(false);

  const submit = (form: any, event?: any, asDraft = false) => {
    //const formAsDto = form as ApsModels.IDrillReportInputDto;

    setSaving(true);
    setSaveAsDraft(!!asDraft);

    const model: ApsModels.IDrillReportInputDto = {
      ...drillReport,
      ...form,
      companyId: Number(form.companyId) || null,
      logDrillShiftId: Number(form.logDrillShiftId) || null,
      drillId: Number(form.drillId) || null,
      locationId: Number(form.locationId) || null,
      logDrillSubjectId: Number(form.logDrillSubjectId) || null,
      logDrillAreaOfStudyId: Number(form.logDrillAreaOfStudyId) || null,
      calJacTrainingTypeId: Number(form.calJacTrainingTypeId) || null,
      isaCourseId: Number(form.isaCourseId) || null,
      participantIds:
        (form as ApsModels.IDrillReportOutputDto)?.selectedParticipants?.map(
          (p) => p.id
        ) || [],
      selectedTrainingTypeIds: (() => {
        const list: number[] =
          typeof form?.selectedTrainingTypeIds === "string"
            ? [Number(form.selectedTrainingTypeIds)]
            : form?.selectedTrainingTypeIds || [];
        return list.filter((p) => !!p).map((p) => p) || [];
      })(),
      startTimeString: form.startTime
        ? moment(form.startTime).format("YYYY-MM-DD HH:mm")
        : null,
      endTimeString: form.endTime
        ? moment(form.endTime).format("YYYY-MM-DD HH:mm")
        : null,
    };

    //Clean Input Model
    delete (model as any).selectedParticipants;
    delete (model as any)._requireOtherDrillName;
    delete (model as any)._requireOtherLocation;
    delete (model as any)._requireOtherTrainingType;
    delete (model as any)._reqOtherISACourse;

    //console.log(JSON.stringify(model));
    //console.log(model.startTime.toString());

    const doSave = () => {
      const data = reuse ? ({ ...model, id: undefined } as any) : model;
      (asDraft
        ? ApsServices.http.drillReport.saveDraft(data)
        : ApsServices.http.drillReport.saveSubmitted(data)
      )
        .then((data) => {
          setSaving(false);
          if (asDraft) {
            toastStore.showToast("Drill has been saved as draft.", "success");
          } else {
            toastStore.showToast("Drill has been submitted.", "success");
          }
          props.history.push(
            "/drills" +
              (reportId
                ? commonService.doneUrls.submitted
                : commonService.doneUrls.created)
          );
        })
        .catch((err) => {
          setSaving(false);
          const errCode = commonService.getErrorStatusCode(err);
          if (errCode === 409 || errCode === 404) {
            setForDelete(false);
            setErrorCode(errCode);
          } else {
            if (asDraft) {
              toastStore.showError("Failed saving drill as draft", err);
            } else {
              toastStore.showError("Failed submitting drill.", err);
            }
          }
        });
    };

    if (asDraft) {
      doSave();
    } else {
      ApsServices.http.drillReport
        .validateBeforeSubmit(model)
        .then((data) => {
          setValidationError(data);
          setDrillToSubmit(model);
          //No Error
          if (
            data.drillReportVerificationEnum ===
            ApsModels.DrillReportVerificationEnum.NoErrors
          ) {
            doSave();
          }
          //Excessive
          if (
            data.drillReportVerificationEnum ===
            ApsModels.DrillReportVerificationEnum.Excessive
          ) {
            setShowExcessive(true);
          }
          //Overlapping
          if (
            data.drillReportVerificationEnum ===
            ApsModels.DrillReportVerificationEnum.Overlapping
          ) {
            setShowOverlapping(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    getDrillTemplate();
    toggleClaims();
  }, []);

  const colClassName = () => {
    return `col-12 col-lg-${showCompShiftSection ? 3 : 4} flex flex-col`;
  };

  const doSubmit = (
    event: any,
    asDraft = false,
    usersToRemove: number[] = []
  ) => {
    const data = {} as any;
    for (let p in registry) {
      data[p] = (getValues as any)(p);
    }

    const form = data as ApsModels.IDrillReportOutputDto;
    if (
      form?.selectedParticipants &&
      processValidationData?.prev?.drillReportExcessiveListDtos?.length > 0
    ) {
      form.selectedParticipants = form.selectedParticipants.filter((p) => {
        if (processValidationData?.participants) {
          return processValidationData.participants[p.id] || false;
        }
        return true;
      });

      if (form.selectedParticipants.length === 0) {
        setDrillReport({
          ...drillReport,
          selectedParticipants: [],
        });
        setValue("selectedParticipants", []);
        triggerSubmitForm();
        return;
      }
    }

    //Check Users to Remove
    if (usersToRemove?.length > 0) {
      form.selectedParticipants = form.selectedParticipants.filter(
        (p) => usersToRemove.indexOf(p.id) === -1
      );
      setDrillReport({
        ...drillReport,
        selectedParticipants: form.selectedParticipants,
      });
      setValue("selectedParticipants", form.selectedParticipants);
      triggerSubmitForm();
      return;
    }

    submit(data, event, asDraft);
  };

  const processValidation = () => {
    const copy = commonService.deepCloneJsonObject(processValidationData.prev);

    const usersToRemove: number[] = [];
    if (copy?.drillReportOverlappingDetailDtos?.length > 0) {
      copy.drillReportOverlappingDetailDtos.forEach((overlap) => {
        if (
          overlap.drillReportVerificationReportDto.keep &&
          overlap.drillReportVerificationReportDto.drillReportId !== reportId
        ) {
          // overlap.drillReportVerificationReportDto.drillReportId = reportId;
          // overlap.drillReportVerificationReportDto.keep = false;
          usersToRemove.push(overlap.participant.id);
        }
      });
    }

    ApsServices.http.drillReport
      .processReportValidation(copy)
      .then((data) => {
        setSaving(false);
        doSubmit(null, false, usersToRemove);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const doDeleteItem = async () => {
    setDeleting(true);
    await ApsServices.http.drillReport
      .deleteReport(drillReport.id, drillReport.concurrencyToken)
      .then((data) => {
        setDeleting(false);
        props.history.push("/drills" + commonService.doneUrls.deleted);
        toastStore.showToast("Drill has been deleted.", "success");
      })
      .catch((err) => {
        setDeleting(false);
        const errCode = commonService.getErrorStatusCode(err);
        if (errCode === 409 || errCode === 404) {
          setForDelete(true);
          setErrorCode(errCode);
        } else {
          toastStore.showError("Failed deleting drill", err);
        }
      });
  };
  const canDeleteReport = () => {
    if (commonService.userInfo?.isAdmin) {
      return true;
    }

    let cbc = drillReport?.hasBeenSubmitted ? drillReport.canBeChanged : true;
    if (!cbc) {
      return false;
    }
    const claims = commonService.friendlyClaims;
    const canDelete =
      claims.canDeleteAll ||
      (claims.canDeleteOwn &&
        commonService.userInfo?.userId === drillReport.reporterId);
    return canDelete;
  };

  return (
    <>
      <ConfirmDialog
        show={!!errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(errorCode)}
        message={commonService.getConcurrencyMessage(errorCode, forDelete)}
        done={(rtn) => {
          if (errorCode === 404) {
            props.history.push("/drills");
          } else {
            getDrillTemplate();
            setErrorCode(0);
          }
        }}
      />

      <ConfirmDialog
        show={showConfirmDelete}
        message="Do you really want to delete this Drill?"
        buttons="yesno"
        done={(response) => {
          setShowConfirmDelete(false);
          if (response === "yes") {
            doDeleteItem();
          }
        }}
      ></ConfirmDialog>

      {showOverlapping && (
        <OverlappingDrillsDialog
          data={{
            prev: { ...validationError },
            new: { ...drillToSubmit },
          }}
          onChange={(data) => {
            setProcessValidationData(data);
          }}
          onClose={(saved) => {
            setShowOverlapping(false);
            if (saved) {
              processValidation();
            } else {
              setSaving(false);
            }
          }}
        ></OverlappingDrillsDialog>
      )}
      {showExcessive && (
        <ExcessiveHoursDialog
          data={{
            prev: { ...validationError },
            new: { ...drillToSubmit },
          }}
          onChange={(data) => {
            setProcessValidationData(data);
          }}
          onClose={(saved) => {
            setShowExcessive(false);
            if (saved) {
              processValidation();
            } else {
              setSaving(false);
            }
          }}
        ></ExcessiveHoursDialog>
      )}
      <form
        onSubmit={handleSubmit(submit)}
        className="flex flex-1 flex-column"
        ref={formRef}
      >
        {loading && <CommonSpinner />}
        {!loading && (
          <div className="container-fluid flex-card-container">
            <div className="flex-0">
              <div className="headerControls">
                <div>
                  <span className="h4 mb-0 font-size-18 text-uppercase">
                    {reportId
                      ? drillReport?.hasBeenSubmitted || !allowEdit
                        ? "View Drill"
                        : "Edit Drill Draft"
                      : "Submit New Drill"}
                  </span>
                </div>
                <div>
                  {!allowEdit && (
                    <>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          props.history.push("/drills");
                        }}
                      >
                        Close
                      </button>
                    </>
                  )}

                  {reportId && drillReport && canDeleteReport() && (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      disabled={saving || deleting}
                      onClick={() => {
                        setShowConfirmDelete(true);
                      }}
                    >
                      <span className="font-size-12">
                        {deleting ? "Deleting..." : "Delete"}
                      </span>
                    </button>
                  )}

                  {allowEdit && (
                    <>
                      {(!reportId || !drillReport?.hasBeenSubmitted) && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          disabled={saving || deleting}
                          onClick={(event) => {
                            const data = {} as any;
                            for (let p in registry) {
                              data[p] = (getValues as any)(p);
                            }
                            for (let p in registry) {
                              if (formState.errors[p]) {
                                (reset as any)(p, { keepValues: true });
                              }
                            }
                            const durMsg = durationValidation(
                              data?.duration || 0
                            );

                            if (durMsg === true) {
                              doSubmit(event, true);
                            } else {
                              toastStore.showToast(durMsg, "warning");
                              //console.log(data.duration);
                            }
                          }}
                        >
                          <span className="font-size-12">
                            {saving && saveAsDraft
                              ? "Saving..."
                              : "Save and Close"}
                          </span>
                        </button>
                      )}

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={saving || deleting}
                        onClick={() => {
                          setSaveAsDraft(false);
                        }}
                      >
                        <span className="font-size-12">
                          {saving && !saveAsDraft ? "Submitting..." : "Submit"}
                        </span>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row h-full flex-1">
              <div className={colClassName()}>
                <DrillParticipants
                  data={{
                    template: drillTemplate,
                    report: drillReport,
                    sectionNumber: 1,
                    allowEdit: allowEdit,
                    allowAddOthers: allowAddOthers,
                  }}
                  onChange={(data) => {}}
                  formState={formState}
                  form={registry}
                  setValue={setValue}
                />
              </div>

              {showCompShiftSection && (
                <div className={colClassName()}>
                  <DrillCompanyShift
                    data={{
                      template: drillTemplate,
                      report: drillReport,
                      sectionNumber: 2,
                      allowEdit: allowEdit,
                      allowAddOthers: allowAddOthers,
                    }}
                    onChange={(data) => {}}
                    formState={formState}
                    form={registry}
                    setValue={setValue}
                  />
                </div>
              )}
              <div className={colClassName()}>
                <DrillDetail
                  data={{
                    template: drillTemplate,
                    report: drillReport,
                    sectionNumber: showCompShiftSection ? 3 : 2,
                    allowEdit: allowEdit,
                    allowAddOthers: allowAddOthers,
                  }}
                  onChange={(data) => {}}
                  formState={formState}
                  form={registry}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>
              <div className={colClassName()}>
                <DrillExternalPartners
                  data={{
                    template: drillTemplate,
                    report: drillReport,
                    sectionNumber: showCompShiftSection ? 4 : 3,
                    allowEdit: allowEdit,
                    allowAddOthers: allowAddOthers,
                  }}
                  onChange={(data) => {}}
                  formState={formState}
                  form={registry}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

export default Drill;
