import ApsModels from "../../models";
import fetcher from "./Fetcher";

class LookupService {
  getDummyLookup = async () => {
    const url = "";
    return fetcher.get<ApsModels.IGridOutputBaseDto>(url);
  };
}

const lookup = new LookupService();
export default lookup;
