import React from 'react';
import { IsolatedOptionDetails, ReferenceDetails } from '../../../data/DailyOperational';
import { OptionDto } from '../../../data/Option';
import { UserDto } from '../../../data/User';
import { IsolatedReferenceSelect } from "./IsolatedReferenceSelect";

type Props = {
    defaultItemsCount: number,
    preventExpanding?: boolean,
    references: ReferenceDetails[],
    availableOptions: OptionDto[],
    initialOrder: number,
    onChange: (optionDetails: ReferenceDetails) => void,
    sten: UserDto[] | undefined,
    stenT: UserDto[] | undefined
}

type State = {
    items: IsolatedOptionDetails[]
}

export class IsolatedReferenceList extends React.Component<Props, State> {
    state = {
        items: new Array<IsolatedOptionDetails>()
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<State>) {
        if (state.items.length === 0 || state.items.length < props.references.length) {
            let itemsCount = IsolatedReferenceList.getInitialItemsCount(props);
            return {
                items: IsolatedReferenceList.getItems(itemsCount, props.initialOrder)
            };
        }
        return null;
    }

    private static getInitialItemsCount(props: Readonly<Props>) {
        let itemsCount = props.references.length >= props.defaultItemsCount ? props.references.length : props.defaultItemsCount;
        if (props.references.length >= props.defaultItemsCount) {
            itemsCount++;
        }
        return itemsCount;
    }

    private static getItems(itemsCount: number, initialOrder: number): IsolatedOptionDetails[] {
        let items: IsolatedOptionDetails[] = [];

        for (let i = 0; i < itemsCount; i++) {
            const item: IsolatedOptionDetails =
            {
                isFirst: i === 0,
                isLast: i === itemsCount - 1,
                order: i + initialOrder
            };
            items.push(item);
        }

        return items;
    }

    handleSectionInputChanged = (optionDetails: ReferenceDetails, isLast: boolean): void => {
        if (this.props.preventExpanding) return;

        if (!isLast) {
            this.props.onChange(optionDetails);
            return;
        }

        const items = [...this.state.items];
        items[items.length - 1].isLast = false;
        items.push({ isFirst: false, isLast: true, order: items.length + this.props.initialOrder });

        this.setState({
            items: items,
        });

        this.props.onChange(optionDetails);
    }


    render() {
        return (
            this.state.items.map((item) => {
                return (
                    <IsolatedReferenceSelect
                        sten={this.props.sten}
                        stenT={this.props.stenT}
                        key={item.order}
                        reference={this.props.references.find(o => o.order == item.order)!}
                        order={item.order}
                        availableOptions={this.props.availableOptions}
                        isFirst={item.isFirst}
                        isLast={item.isLast}
                        onChange={this.handleSectionInputChanged}
                    />
                )
            })
        )
    }
}
