import {QuestionGroupDto} from "../data/DailyOperational";

class QuestionGroupTemporaryStorageService {
    private questionGroups: QuestionGroupDto[] = [];

    public add(questionGroup: QuestionGroupDto): void {
        this.questionGroups.push(questionGroup);
    }

    public update(questionGroup: QuestionGroupDto): void {
        for (let i = 0; i < this.questionGroups.length; i++) {
            if (this.questionGroups[i].questionGroupId === questionGroup.questionGroupId) {
                this.questionGroups[i] = questionGroup;
            }
        }
    }

    public exists(questionGroup: QuestionGroupDto): boolean {
        return this.questionGroups.some(g => g.questionGroupId === questionGroup.questionGroupId);
    }

    public getAll(): QuestionGroupDto[] {
        return this.questionGroups;
    }

    public clear(): void {
        this.questionGroups.length = 0;
    }

    public isEmpty() {
        return this.questionGroups.length === 0;
    }
}

const questionGroupTemporaryStorageService = new QuestionGroupTemporaryStorageService();
export { questionGroupTemporaryStorageService as QuestionGroupTemporaryStorageService }
