import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useDepartmentId } from "../../../aps2/stores/SystemStore";
import ApsModels from "../../../aps2/models";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import commonService from "../../../aps2/services/CommonService";
import ApsServices from "../../../aps2/services";
import {
  NfirsFetchStatus,
  useNfirsGetData,
} from "../../../aps2/components/NFIRS/NFIRSHelper";
import toastStore from "../../../aps2/stores/ToastStore";
import AddCustomNotificationDialog from "./AddCustomNotificationDialog";
import ConfirmDialog from "../../../aps2/components/Common/ConfirmDialog";
import RouteChangedGuard from "../../../aps2/components/Common/RouteChangedGuard";

function EditNotification(props: RouteComponentProps<any>) {
  const [id, setId] = useState(
    Number(props.match?.params?.id) || (null as any)
  );
  const departmentId = useDepartmentId();
  const [items, setItems] = useState<
    {
      id: any;
      label: string;
      type: "Credential" | string;
    }[]
  >([]);

  const [creds, setCreds] = useState([] as any[]);
  const [isLoadingCreds, setIsLoadingCreds] = useState(false);
  const refCreds = React.createRef<AsyncTypeahead<any>>();

  const handleSearchCreds = async (query: string) => {
    if ((query || "").trim() === "") {
      setCreds([]);
      setIsLoadingCreds(false);
      return;
    }

    setIsLoadingCreds(true);
    await ApsServices.http.credCredential
      .typeAheadCredentialNotification({
        search: query,
        isAnd: false,
        recordCount: 10,
        libraryTypeEnum: ApsModels.CredLibraryTypeEnum.All,
        categoryId: null as any,
        onlyRenewal: false,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          displayName: `${i.category} - ${i.name} (${i.code})`,
          name: `${i.name}`,
          code: `${i.code}`,
        }));

        setCreds(options);
        setIsLoadingCreds(false);
      })
      .catch((err) => {
        setCreds([]);
        setIsLoadingCreds(false);
      });
  };

  const ref = React.createRef<AsyncTypeahead<any>>();
  const [saving, setSaving] = useState(false);
  const [showAddNotif, setShowAddNotif] = useState<
    ApsModels.ICredNotificationDetailDto | undefined
  >();
  const notifications = useNfirsGetData(
    async () => ApsServices.http.credTemplate.credentialNotificationSetting(id),
    "Notification Settings"
  );

  useEffect(() => {
    if (id) {
      notifications.getData();
    } else {
      notifications.setData((prev) => {
        return {
          ...(prev || ({} as any)),
          credNotificationSettingDto: {
            ...prev?.credNotificationSettingDto,
            credNotificationDetails: [],
          },
          enableNotifications: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleteAll, setDeleteAll] = useState(false);
  const isNewRow = (id: string) => {
    return id.indexOf("NEW-") === 0;
  };
  const [detailsToDelete, setDetailsToDelete] = useState<string[]>([]);
  const [saveErrorMessage, setSaveErrorMessage] = useState<string>();
  const [ignoreChanges, setIgnorechanges] = useState(false);

  const saveAll = async () => {
    const credList = id
      ? [
          {
            id: id,
            label: notifications.data?.credentialName || "",
            type: "Credential",
          },
        ]
      : [...items];

    //Clean Up Notification Models
    const notifList =
      notifications.data?.credNotificationSettingDto?.credNotificationDetails?.map(
        (cnd) => {
          return {
            ...cnd,
            detailId: isNewRow(cnd.detailId) ? (null as any) : cnd.detailId,
          };
        }
      ) || [];

    if (credList.length === 0) {
      toastStore.showToast("Please select a credential", "warning");
      return;
    }
    if (notifications.data?.enableNotifications && !notifList?.length) {
      toastStore.showToast("Please add a notification", "warning");
      return;
    }

    if (!id) {
      setSaving(true);

      (notifications.data?.enableNotifications
        ? ApsServices.http.credTemplate.saveCustomNotifications({
            credCustomNotificationInputDetailDtos: [...credList].map((cred) => {
              return {
                templateId: cred.id,
                templateName: cred.label,
                credNotificationDetailDtos: notifList,
              } as ApsModels.ICredCustomNotificationInputDetailDto;
            }),
          })
        : ApsServices.http.credTemplate.bulkDisableCredentials([
            ...credList.map((cred) => {
              return {
                templateId: cred.id,
                templateName: cred.label,
              };
            }),
          ])
      )
        .then((data) => {
          if (!data.credCustomNotificationOutputErrorDetailDtos?.length) {
            toastStore.showToast(
              "Custom notifications has been updated",
              "success"
            );
            setIgnorechanges(true);
            setTimeout(() => {
              props.history.push("/notifications/custom");
            }, 300);
          } else {
            if (
              items.length >
              data.credCustomNotificationOutputErrorDetailDtos.length
            ) {
              toastStore.showToast(
                "Custom notifications has been updated, with some error.",
                "success"
              );
            }

            setItems((prev) =>
              prev.filter((c) =>
                data.credCustomNotificationOutputErrorDetailDtos.find(
                  (e) => e.templateId === c.id
                )
              )
            );

            setSaveErrorMessage(
              `The following credentials had an error while saving the custom notifications:
              <div class="pt-2"></div>
              ${data.credCustomNotificationOutputErrorDetailDtos
                .map((e) => `<div>* ${e.templateName}</div>`)
                .join("")}`
            );
          }
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Custom Notification", err);
        })
        .finally(() => {
          setSaving(false);
        });

      return;
    }

    const proms: { action: () => Promise<any> }[] = [];

    //Queue Delete
    detailsToDelete.forEach((dtl) => {
      proms.push({
        action: () =>
          ApsServices.http.credTemplate.deleteCredentialNotificationSetting(
            id,
            dtl
          ),
      });
    });

    //Queue Save
    credList.forEach((data) => {
      notifList
        ?.filter((cnd) => {
          if (detailsToDelete.includes(cnd.detailId)) {
            return false;
          }
          return true;
        })
        ?.forEach((notif) => {
          proms.push({
            action: () =>
              ApsServices.http.credTemplate.saveCredentialNotificationSetting(
                data.id,
                notif
              ),
          });
        });
    });

    proms.push({
      action: () =>
        ApsServices.http.credTemplate.enableDisableCredentialNotification(
          id,
          notifications.data?.enableNotifications || false
        ),
    });

    setSaving(true);
    let hasError = false;
    while (proms.length > 0) {
      await proms[0]
        .action()
        .then((data) => {
          //toastStore.showToast("Notification(s) Saved", "success");
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Custom Notification", err);
          hasError = true;
        });
      proms.splice(0, 1);
    }

    if (proms.length === 0) {
      setTimeout(() => {
        if (!hasError) {
          toastStore.showToast("Custom notification has been added", "success");
          setIgnorechanges(true);
          setTimeout(() => {
            props.history.push("/notifications/custom");
          }, 300);
        }
        setSaving(false);
      }, 500);
    } else {
      setSaving(false);
    }
  };

  const [gettingCreds, setGettingGreds] = useState(false);
  const showSetupToast = () => {
    toastStore.showToast(
      "You've added, updated, or deleted a notification. Click 'Set Notification' to add another. Once you submit, these changes will be permanent.",
      "success"
    );
  };

  const [origNotif, setOrigNotif] =
    useState<ApsModels.ICredNotificationSettingOutputDto>();
  useEffect(() => {
    if (notifications.status === NfirsFetchStatus.Complete) {
      setOrigNotif(notifications.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.status]);

  const hasChanges = () => {
    if (ignoreChanges) return false;
    if (!id) {
      return (
        items.length > 0 ||
        !!notifications.data?.credNotificationSettingDto
          ?.credNotificationDetails?.length
      );
    }
    return (
      detailsToDelete.length > 0 ||
      !commonService.isEqual(notifications.data || {}, origNotif || {})
    );
  };

  const [delId, setDelId] = useState<string>();
  const doDelete = (delId: string) => {
    notifications.setData((prev) => {
      return {
        ...(prev || ({} as any)),
        credNotificationSettingDto: {
          ...prev?.credNotificationSettingDto,
          credNotificationDetails: (
            prev?.credNotificationSettingDto?.credNotificationDetails || []
          ).filter((cnd) => delId !== cnd.detailId),
        },
      };
    });
  };

  const canEdit = () => {
    return commonService.getAccessNotifications() === "All";
  };

  return (
    <>
      <RouteChangedGuard
        when={true}
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => {
          return hasChanges();
        }}
        message="You have unsaved changes, do you really want to leave the page and discard changes?"
      />
      <ConfirmDialog
        show={!!saveErrorMessage}
        buttons="okonly"
        title="Notification Error"
        message={saveErrorMessage || ""}
        done={(rtn) => {
          setSaveErrorMessage(undefined);
        }}
      />
      <ConfirmDialog
        show={deleteAll}
        buttons="yesno"
        title="Confirmation"
        message="Do you really want to delete all credentials selected?"
        done={(rtn) => {
          if (rtn === "yes") {
            setItems([]);
          }
          setDeleteAll(false);
        }}
      />
      <ConfirmDialog
        show={!!delId}
        buttons="yesno"
        title="Confirmation"
        message="Do you really want to delete this notification?"
        done={(rtn) => {
          if (rtn === "yes" && delId) {
            doDelete(delId);
          }
          setDelId(undefined);
        }}
      />
      {showAddNotif && (
        <AddCustomNotificationDialog
          data={{
            templateId: 0,
            model: showAddNotif,
          }}
          onClose={(saved) => {
            if (saved) {
              notifications.getData();
            }
            setShowAddNotif(undefined);
          }}
          onChange={(data) => {
            if (data.model) {
              const currentData: ApsModels.ICredNotificationSettingOutputDto =
                notifications.data || ({} as any);

              if (
                !notifications.data?.credNotificationSettingDto?.credNotificationDetails?.find(
                  (cn) => cn.detailId === data.model?.detailId
                )
              ) {
                notifications.setData({
                  ...currentData,
                  credNotificationSettingDto: {
                    ...currentData.credNotificationSettingDto,
                    credNotificationDetails: [
                      ...(currentData.credNotificationSettingDto
                        ?.credNotificationDetails || []),
                      data.model,
                    ],
                  },
                });
              } else {
                notifications.setData({
                  ...currentData,
                  credNotificationSettingDto: {
                    ...currentData.credNotificationSettingDto,
                    credNotificationDetails: [
                      ...(currentData.credNotificationSettingDto
                        ?.credNotificationDetails || []),
                    ].map((cnd) => {
                      if (cnd.detailId === data.model?.detailId) {
                        return {
                          ...cnd,
                          ...data.model,
                        };
                      }
                      return cnd;
                    }),
                  },
                });
              }
              showSetupToast();
            }
            setShowAddNotif(undefined);
          }}
        ></AddCustomNotificationDialog>
      )}

      <form className="flex flex-1 flex-col">
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls p-0">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  {canEdit() ? <>{id ? "Edit" : "Add"} </> : <>View</>} Custom
                  Notifications
                </span>
              </div>
              <div>
                {canEdit() && (
                  <>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      disabled={saving}
                      onClick={(e) => {
                        props.history.push("/notifications/custom");
                      }}
                    >
                      Cancel and Close
                    </button>
                    <button
                      id="assign-update"
                      className="btn btn-primary"
                      type="button"
                      disabled={saving}
                      onClick={(e) => {
                        saveAll();
                      }}
                    >
                      <span className="mx-2">
                        {saving ? "Submitting..." : "Submit"}
                      </span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <strong>1. Select Credentials</strong>

                  {!id && (
                    <div className="pt-3">
                      <label className="required-label">
                        Search Credentials
                      </label>

                      <div className="text-primary mb-1 px-1 flex">
                        <div className="flex-1">
                          <span
                            className="pointer"
                            onClick={(e) => {
                              if (gettingCreds) return;
                              setGettingGreds(true);
                              ApsServices.http.credTemplate
                                .allCredentialTemplatesAsync()
                                .then((data) => {
                                  if (data?.length) {
                                    setItems([
                                      ...data.map((i) => {
                                        return {
                                          id: i.id,
                                          label: i.templateName,
                                          type: "Credential",
                                        };
                                      }),
                                    ]);
                                  }
                                })
                                .catch((err) => {
                                  toastStore.showError(
                                    "Failed Getting Credentials",
                                    err
                                  );
                                })
                                .finally(() => {
                                  setGettingGreds(false);
                                });
                            }}
                          >
                            {gettingCreds ? "Loading..." : "Select All"}
                          </span>
                        </div>
                        <div>
                          {items.length > 0 && (
                            <span
                              className="text-danger pointer"
                              onClick={(e) => {
                                if (gettingCreds) return;
                                setDeleteAll(true);
                              }}
                            >
                              Delete All
                            </span>
                          )}
                        </div>
                      </div>

                      <div id="typeaheadSearchCredentialContainer">
                        <AsyncTypeahead
                          id="typeaheadSearchCredential"
                          labelKey="displayName"
                          onSearch={handleSearchCreds}
                          onChange={(data) => {
                            if (data && data.length > 0) {
                              (refCreds.current as any)?.clear();
                              const cred = data[0];
                              if (cred) {
                                if (
                                  items.find(
                                    (i) =>
                                      i.type === "Credential" &&
                                      i.id === cred.id
                                  )
                                ) {
                                  toastStore.showToast(
                                    `Selected Credential '${cred.name}' already exists.`,
                                    "warning"
                                  );
                                  return;
                                }
                                setItems([
                                  ...items,
                                  {
                                    type: "Credential",
                                    id: cred.id,
                                    label: `${cred.code} - ${cred.name}`,
                                  },
                                ]);
                              }
                            }
                          }}
                          searchText={"Searching..."}
                          isLoading={isLoadingCreds}
                          options={creds}
                          placeholder="Search Credential"
                          minLength={1}
                          delay={500}
                          useCache={false}
                          ref={refCreds}
                          autoFocus={true}
                        />
                      </div>

                      <div className="currentParticipants mt-4">
                        {items?.map((x) => (
                          <div key={x.id}>
                            <section className="pe-2">{x.label}</section>
                            <section
                              title="Remove"
                              onClick={() => {
                                setItems((p) => {
                                  return [
                                    ...p.filter(
                                      (item) =>
                                        !(
                                          item.type === x.type &&
                                          item.id === x.id
                                        )
                                    ),
                                  ];
                                });
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </section>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {!!id && notifications.data?.credentialName && (
                    <>
                      <div className="alert alert-sm alert-secondary p-2 px-3 mt-3">
                        {notifications.data?.credentialName}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-lg-8 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <strong>
                    2. Set up your preferred notifications for the selected
                    credentials
                  </strong>
                  <div>
                    <div className="flex flex-row flex-center mt-2">
                      <div className="flex-1">
                        <div className="py-2 flex flex-center">
                          <label className="switch flex-none m-0">
                            <input
                              type="checkbox"
                              name="enableNotifications"
                              defaultChecked={
                                notifications.data?.enableNotifications
                              }
                              checked={notifications.data?.enableNotifications}
                              disabled={saving || !canEdit()}
                              onChange={(e) => {
                                if (e.target) {
                                  const val = e.target.checked;
                                  notifications.setData((prev) => {
                                    return {
                                      ...(prev || ({} as any)),
                                      enableNotifications: val,
                                    };
                                  });
                                }
                              }}
                            />
                            <span
                              className={`slider round flex-none ${
                                canEdit() ? "" : "disabled"
                              }`}
                            ></span>
                          </label>
                          <label className="mb-1 px-2">
                            Enable Notifications
                          </label>
                        </div>
                      </div>
                      <div className="text-right mb-2">
                        {canEdit() && (
                          <button
                            className="btn btn-success"
                            type="button"
                            disabled={
                              saving || !notifications.data?.enableNotifications
                            }
                            onClick={(e) => {
                              setShowAddNotif({
                                detailId: `NEW-${commonService.getUniqueId()}`,
                              } as any);
                            }}
                          >
                            Set Notification
                          </button>
                        )}
                      </div>
                    </div>
                    <table
                      className={`table table-hover ${
                        notifications.data?.enableNotifications
                          ? ""
                          : "display-none"
                      }`}
                    >
                      <thead>
                        <tr>
                          <th>When</th>
                          <th>Who</th>
                          {canEdit() && <th className="text-right">Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {notifications.status ===
                          NfirsFetchStatus.InProgress && (
                          <tr>
                            <td colSpan={3}>Loading...</td>
                          </tr>
                        )}
                        {notifications.status === NfirsFetchStatus.Complete &&
                          !notifications.data?.credNotificationSettingDto
                            ?.credNotificationDetails?.length && (
                            <tr>
                              <td colSpan={3}>No Record(s) Found</td>
                            </tr>
                          )}
                        {notifications.status !== NfirsFetchStatus.InProgress &&
                          notifications.data?.credNotificationSettingDto?.credNotificationDetails?.map(
                            (row) => (
                              <tr
                                key={row.detailId}
                                className={`${canEdit() ? "pointer" : ""} ${
                                  isNewRow(row.detailId) ? "notif-new-row" : ""
                                } ${
                                  detailsToDelete.includes(row.detailId)
                                    ? "notif-delete-row"
                                    : ""
                                }`}
                                onDoubleClick={(e) => {
                                  if (
                                    canEdit() &&
                                    !detailsToDelete.includes(row.detailId)
                                  ) {
                                    setShowAddNotif({
                                      ...row,
                                    });
                                  }
                                }}
                              >
                                <td>
                                  {[
                                    commonService.isNullOrWhitespace(
                                      row.daysBeforeAssignmentDueDate
                                    )
                                      ? ""
                                      : `${row.daysBeforeAssignmentDueDate} days before`,
                                    commonService.isNullOrWhitespace(
                                      row.daysAfterAssignmentDueDate
                                    )
                                      ? ""
                                      : `${row.daysAfterAssignmentDueDate} days after`,
                                    row.notifyOnAssignmentDate
                                      ? "On due date"
                                      : "",
                                    row.notifyUponEnrollment
                                      ? "Upon assignment"
                                      : "",
                                    row.notifyUponCompletion
                                      ? "Upon completion"
                                      : "",
                                    // row.notifyOnExpirationDate
                                    //   ? "On due date"
                                    //   : "",
                                  ]
                                    .filter(
                                      (x) => !commonService.isNullOrEmpty(x)
                                    )
                                    .map((x) => (
                                      <div key={x}>{x}</div>
                                    ))}
                                </td>
                                <td>
                                  {[
                                    row.notifyAccountAdmins
                                      ? "Account Admin(s)"
                                      : "",
                                    row.notifyImmediateSupervisors
                                      ? "Immediate Supervisor(s)"
                                      : "",
                                    row.notifyParticipants
                                      ? "Participants(s)"
                                      : "",
                                    row.notifyOther
                                      ? row.notifyOtherEmailAddresses
                                      : "",
                                  ]
                                    .filter(
                                      (x) => !commonService.isNullOrEmpty(x)
                                    )
                                    .join(", ")}
                                </td>
                                {canEdit() && (
                                  <td className="text-right">
                                    {detailsToDelete.includes(row.detailId) ? (
                                      <>
                                        <i
                                          className="fa fa-undo text-success pointer me-1"
                                          title="Undo"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setDetailsToDelete((prev) => {
                                              return prev.filter(
                                                (d) => d !== row.detailId
                                              );
                                            });
                                          }}
                                        ></i>
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          className="fa fa-edit text-primary me-4"
                                          onClick={(e) => {
                                            if (
                                              !detailsToDelete.includes(
                                                row.detailId
                                              )
                                            ) {
                                              setShowAddNotif({
                                                ...row,
                                              });
                                            }
                                          }}
                                        ></i>
                                        <i
                                          className="fa fa-trash text-danger pointer me-1"
                                          title="Delete"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (isNewRow(row.detailId)) {
                                              setDelId(row.detailId);
                                            } else {
                                              setDetailsToDelete((prev) => {
                                                return [...prev, row.detailId];
                                              });
                                              showSetupToast();
                                            }
                                          }}
                                        ></i>
                                      </>
                                    )}
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditNotification;
