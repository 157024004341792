import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import { FgInput, FgUseForm, useFgModel } from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import ConfirmDialog from "../../../Common/ConfirmDialog";

function NFIRSHazmatEquipmentInvolved(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsHazmatEquipmentInvolved.update({
      ...model.new,
      year: model.new.year || (null as any),
    });
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsHazmatEquipmentInvolvedInputDto>({
    objectName: "Equipment Involved",
    nfirsSectionTab: "HAZMAT/EquipmentInvolved",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsHazmatEquipmentInvolvedInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "HAZMAT",
    "EquipmentInvolved",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("HAZMAT", "EquipmentInvolved", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const equipments = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getEquipmentTypes(false),
    "Equipment Types"
  );
  const equipmentSections = useNfirsLookupEnum(
    "NfirsEquipmentTypeSectionEnum",
    "Equipment Types Sections"
  );

  useEffect(() => {
    setProgress({ loading: true });
    equipments.getData();
    equipmentSections.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsHazmatEquipmentInvolved
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
            year: `${data.year || ""}`.trim(),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);

          trigger(); //force validate so users can see error labels
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Equipment Involved", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      equipments.status === NfirsFetchStatus.Complete &&
      equipmentSections.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress.errorCode, equipments.status, equipmentSections.status]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    trigger,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsEquipmentId: {
      displayName: "Equipment Id",
      validation: {
        required: false,
      },
    },
    nfirsEquipmentTypeEntryId: {
      displayName: "Equipment Involved in Release",
      validation: {
        required: false,
      },
    },
    brand: {
      displayName: "Brand",
      validation: {
        required: false,
        maxLength: 25,
      },
    },
    model: {
      displayName: "Model",
      validation: {
        required: false,
        maxLength: 25,
      },
    },
    serialNumber: {
      displayName: "Serial Number",
      validation: {
        required: false,
        maxLength: 25,
      },
    },
    year: {
      displayName: "Year",
      validation: {
        required: false,
        minLength: 4,
        maxLength: 4,
        validate: {
          numberOnly: commonService.validations.numberOnly,
          acceptedYearRange: commonService.validations.acceptedYearRange,
        },
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12">
          <strong>Equipment Involved</strong>

          <div className="mt-3 mb-2">
            <label>Was Equipment Involved involved in this incident?</label>

            <div className="inline-radio-box-group">
              <div>
                <input
                  type="radio"
                  name="isEquipmentInvolved"
                  id="isEquipmentInvolvedYes"
                  value="1"
                  checked={model.new.isEquipmentInvolved || false}
                  onChange={(event) => {
                    onModelChange({
                      ...model.new,
                      isEquipmentInvolved: Number(event.target.value) === 1,
                    });
                  }}
                ></input>
                <label htmlFor="isEquipmentInvolvedYes">Yes</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="isEquipmentInvolved"
                  id="isEquipmentInvolvedNo"
                  value="0"
                  checked={!model.new.isEquipmentInvolved || false}
                  onChange={(event) => {
                    onModelChange({
                      ...model.new,
                      isEquipmentInvolved: Number(event.target.value) === 1,
                    });
                  }}
                ></input>
                <label htmlFor="isEquipmentInvolvedNo">No</label>
              </div>
            </div>
          </div>
        </div>

        {model.new.isEquipmentInvolved && (
          <>
            <div className="col-sm-12 col-lg-6">
              <div className="mt-3">
                <label>Equipment Involved in Release</label>
                <AdvancedDropdown
                  domId="nfirsEquipmentTypeEntryId"
                  value={model.new.nfirsEquipmentTypeEntryId}
                  favoritesHandler={{
                    add: ApsServices.http.nfirsFavorite
                      .addFavoriteEquipmentType,
                    remove:
                      ApsServices.http.nfirsFavorite
                        .removeFavoriteEquipmentType,
                    afterUpdate: (list) => equipments.setData(list),
                  }}
                  options={(equipments.data || [])
                    .sort(commonService.sortByStringProperty("description"))
                    .map((o) => {
                      return {
                        label: `${o.description} (${o.externalId})`,
                        value: o.id,
                        originalObject: o,
                        isFavorite: o.isFavorite,
                        groupId: o.nfirsEquipmentTypeSection,
                        subGroupId: o.nfirsEquipmentTypeSection,
                      };
                    })}
                  groups={(equipmentSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      groupLabel: o.label,
                    };
                  })}
                  subGroups={(equipmentSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      subGroupId: o.value,
                    };
                  })}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      nfirsEquipmentTypeEntryId: Number(data) || (null as any),
                    });
                  }}
                ></AdvancedDropdown>
              </div>

              <div className="row mt-3">
                <div className="col-sm-6">
                  <FgInput
                    id="brand"
                    label="Brand"
                    placeHolder="Brand"
                    registeredField={registry.brand}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        brand: data,
                      });
                    }}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgInput
                    id="model"
                    label="Model"
                    placeHolder="Model"
                    registeredField={registry.model}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        model: data,
                      });
                    }}
                  ></FgInput>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <FgInput
                    id="year"
                    label="Year"
                    placeHolder="Year"
                    registeredField={registry.year}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        year: data,
                      });
                    }}
                    //maxlength={4}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgInput
                    id="serialNumber"
                    label="Serial Number"
                    placeHolder="Serial Number"
                    registeredField={registry.serialNumber}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        serialNumber: data,
                      });
                    }}
                  ></FgInput>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="row">
                <div className="col-sm-6"></div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </>
        )}

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSHazmatEquipmentInvolved;
