import React from "react";

type SelectProps<T> = {
    items: T[]
    value?: string 
    placeholderOption?: string
    onChange: (elemValue: string) => void
    getValue: (elem: T) => string
    getDisplayedContent: (elem: T) => string
}

type SelectState = {
    value: string
}

export class Select<T> extends React.Component<SelectProps<T>, SelectState> {
    constructor(props: SelectProps<T>) {
        super(props);
        this.state = { 
            value: this.props.value || ''
        };
    }

    handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ value: e.target.value });
        this.props.onChange(e.target.value);
    }

    render() {
        return (
            <select onChange={this.handleChange} value={this.state.value}>
                {(this.props.placeholderOption !== undefined) && 
                    <option>{this.props.placeholderOption}</option>
                }
                {this.props.items.map(i => (
                    <option value={this.props.getValue(i)}>{this.props.getDisplayedContent(i)}</option>
                ))}
            </select>
        )
    }
}