import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSBasicAuthorityService {
  get = async (id: any) => {
    const url = `/api/NfirsBasicAuthority/${id}`;
    return fetcher.get<ApsModels.INfirsBasicAuthorityOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsBasicAuthorityInputDto) => {
    const url = `/api/NfirsBasicAuthority`;
    return fetcher.post<ApsModels.INfirsBasicAuthorityOutputDto>(url, data);
  };

  typeAheadUser = async (data: ApsModels.INfirsUserTypeAheadInputDto) => {
    const url = `/api/NfirsBasicAuthority/TypeAheadUser`;
    return fetcher.post<ApsModels.INfirsBasicAuthorityUserOutputDto[]>(
      url,
      data
    );
  };
}

const nfirsBasicAuthority = new NFIRSBasicAuthorityService();
export default nfirsBasicAuthority;
