import React, { useEffect, useMemo, useState } from "react";
import { GroupDto } from "../../data/Group";
import { GroupService } from "../../services/GroupService";
import commonService from "../../aps2/services/CommonService";
import CommonSpinner from "../../aps2/components/Common/CommonSpinner";
import CheckboxList from "../../aps2/components/Common/CheckboxList";

import { Flags } from "../DailyOperational/Sections/Flags";

//GENERIC COMPONENTS
import { Dropdown } from "../DailyOperational/Sections/Dropdown";
import GenericTextArea from "../DailyOperational/Sections/Generics/GenericTextArea";
import GenericCheckbox from "../DailyOperational/Sections/Generics/GenericCheckbox";
import GenericRadiobox from "../DailyOperational/Sections/Generics/GenericRadiobox";
import GenericResponseComments from "../DailyOperational/Sections/Generics/GenericResponseComments";
import GenericOnCallList from "../DailyOperational/Sections/Generics/GenericOnCallList";
import GenericShiftActivity from "../DailyOperational/Sections/Generics/GenericShiftActivity";
import GenericWildlandField from "../DailyOperational/Sections/Generics/GenericWildlandField";
import GenericReserveApparatus from "../DailyOperational/Sections/Generics/GenericReserveApparatus";
import GenericStrikeTeams from "../DailyOperational/Sections/Generics/GenericStrikeTeams";
import GenericUsarStatus from "../DailyOperational/Sections/Generics/GenericUsarStatus";
import GenericDropdown from "../DailyOperational/Sections/Generics/GenericDropdown";
import GenericTextbox from "../DailyOperational/Sections/Generics/GenericTextbox";

interface IMorePropsHandler {
  values: any;
  onChange: (values: any) => void;
  displayOtherId?: string;
}

export interface ILayoutQuestion {
  order: number;
  questionText: string;
  optionGroupName?: string;
  /** Will hide question label in the Designer */
  hideQuestionText?: boolean;
  canBeHidden?: boolean;
  canSetImage?: boolean;
}
export interface IBaseDOCComponent {
  id: string;
  name: string;
  component: any;
  /** FOR NON-GENERIC PROPERTIES */
  moreProps?: { names: string[]; values: { [key: string]: any } };
  builtIn?: boolean;
  createsDefaultAnswer?: boolean; //Tells if this component triggers default answer
  needsInstructions?: boolean;
  showDisplayOthers?: boolean;
  questionGroup?: {
    name: string;
    order: number;
    questions: ILayoutQuestion[];
  };
  helpText?: string;
}

export const FindQuestionDefinition = (
  componentId: string,
  order: number,
  index?: number //for questions with same Order # like Reserve Apparatus
) => {
  return BuiltInDOCComponents.filter((c) => c.id === componentId)
    .map((c) => {
      return c.questionGroup?.questions?.find(
        (bq, i) => bq.order === order && (index === undefined || index === i)
      );
    })
    .find((c) => !!c);
};

export const GetDropdownOptionGroupId = (instanceId: string) => {
  return `Dropdown-${instanceId}:Options`;
};

const CreateReserveApparatusQuestionByIndex = (index: number) => {
  return [
    {
      order: 0,
      questionText: `Options ${index + 1}`,
      optionGroupName: `Reserve Apparatus: Options ${index + 1}`,
      canBeHidden: true,
    },
    {
      order: 1,
      questionText: `City #`,
      optionGroupName: `Reserve Apparatus: City Options ${index + 1}`,
      hideQuestionText: true,
    },
    {
      order: 2,
      questionText: `Status`,
      optionGroupName: `Reserve Apparatus: Status Options ${index + 1}`,
      hideQuestionText: true,
    },
    {
      order: 3,
      questionText: `Location`,
      optionGroupName: `Reserve Apparatus: Location Options ${index + 1}`,
      hideQuestionText: true,
    },
  ];
};

export const BuiltInDOCComponents: IBaseDOCComponent[] = [
  {
    id: "Checkbox",
    name: "Checkbox",
    component: GenericCheckbox,
    builtIn: true,
    questionGroup: {
      name: "Checkbox",
      order: -1,
      questions: [
        {
          order: 0,
          questionText: "Option 1",
          canSetImage: true,
          canBeHidden: true,
        },
        {
          order: 1,
          questionText: "Option 2",
          canSetImage: true,
          canBeHidden: true,
        },
        {
          order: 2,
          questionText: "Option 3",
          canSetImage: true,
          canBeHidden: true,
        },
      ],
    },
  },
  {
    id: "GenericDropdown",
    name: "Dropdown",
    component: GenericDropdown,
    moreProps: {
      names: ["optionGroups"],
      values: {
        optionGroups: [],
        displayOther0: true,
      },
    },
    builtIn: true,
    showDisplayOthers: true,
    questionGroup: {
      name: "Dropdown Label",
      order: -1,
      questions: [
        {
          order: 0,
          questionText: "",
          hideQuestionText: true,
          optionGroupName: "Generic Dropdown: Options",
        },
      ],
    },
  },
  {
    id: "Dropdown",
    name: "Group Dropdown",
    component: Dropdown,
    moreProps: {
      names: ["groupName"],
      values: {
        groupName: "BC Group", //Default Value, can a string or array of strings
      },
    },
    builtIn: true,
    showDisplayOthers: true,
    questionGroup: {
      name: "Dropdown Label",
      order: -1,
      questions: [
        {
          order: 0,
          questionText: "",
          hideQuestionText: true,
        },
      ],
    },
  },
  {
    id: "Radiobox",
    name: "Radiobox",
    component: GenericRadiobox,
    builtIn: true,
    createsDefaultAnswer: true,
    questionGroup: {
      name: "Radiobox",
      order: -1,
      questions: [
        { order: 0, questionText: "Yes" },
        { order: 1, questionText: "No" },
      ],
    },
  },
  {
    id: "ResponseComments",
    name: "Response/Comments",
    component: GenericResponseComments,
    builtIn: true,
    moreProps: {
      names: ["optionGroups", "groupName"],
      values: {
        optionGroups: [],
        groupName: "BC Group", //Default Value
      },
    },
    questionGroup: {
      name: "Response/Comments",
      order: -1,
      questions: [
        {
          order: 0,
          questionText: "Response",
        },
        {
          order: 1,
          questionText: "ResponseCommentsDropdown2",
          hideQuestionText: true,
        },
        {
          order: 2,
          questionText: "ResponseCommentsDropdown3",
          hideQuestionText: true,
        },
        {
          order: 3,
          questionText: "Comments",
        },
      ],
    },
  },
  {
    id: "Text",
    name: "Text Area",
    component: GenericTextArea,
    builtIn: true,
    questionGroup: {
      name: "Text Area Label",
      order: -1,
      questions: [
        {
          order: 0,
          questionText: "",
          hideQuestionText: true,
        },
      ],
    },
  },
  {
    id: "Textbox",
    name: "Textbox",
    component: GenericTextbox,
    builtIn: true,
    questionGroup: {
      name: "Textbox Label",
      order: -1,
      questions: [
        {
          order: 0,
          questionText: "",
          hideQuestionText: true,
        },
      ],
    },
  },
  {
    id: "OnCallList",
    name: "On-Call List",
    component: GenericOnCallList,
    moreProps: {
      names: ["groupName"],
      values: {
        groupName: "BC Group", //Default Value
      },
    },
    questionGroup: {
      name: "On-Call List",
      order: -1,
      questions: [
        {
          order: 0,
          questionText: "On-Call-List",
          hideQuestionText: true,
          //optionGroupName: "WSA Options", //not needed
        },
      ],
    },
  },
  {
    id: "Flags",
    name: "Flags",
    component: Flags,
    questionGroup: {
      name: "Flags",
      order: -1,
      questions: [
        { order: 0, questionText: "Half Flag" },
        { order: 1, questionText: "Red Flag" },
      ],
    },
  },
  {
    id: "ShiftActivity",
    name: "Shift Activity",
    component: GenericShiftActivity,
    moreProps: {
      names: ["groupName"],
      values: {
        groupName: "Shift Investigator", //Default Value
      },
    },
    questionGroup: {
      name: "Shift Activity",
      order: -1,
      questions: [
        { order: 0, questionText: "Shift Investigator", canBeHidden: true },
        { order: 1, questionText: "Shift Activity" },
      ],
    },
  },
  {
    id: "StrikeTeams",
    name: "Strike Teams",
    component: GenericStrikeTeams,
    createsDefaultAnswer: true,
    needsInstructions: true,
    moreProps: {
      names: ["optionGroups"],
      values: {
        optionGroups: [],
        instructions:
          "Notify Staff Captain by 0700 if not available for deployment.",
      },
    },
    questionGroup: {
      name: "Strike Teams",
      order: -1,
      questions: [
        {
          order: 0,
          questionText: "Strike Team",
          optionGroupName: "Strike Teams: Strike Teams List",
        },
        {
          order: -1,
          questionText: "Engines",
          optionGroupName: "Strike Teams: Engines",
          hideQuestionText: true,
        },
        {
          order: -2,
          questionText: "ST Options",
          optionGroupName: "Strike Teams: ST Options",
          hideQuestionText: true,
        },
      ],
    },
  },
  {
    id: "ReserveApparatus",
    name: "Reserve Apparatus",
    component: GenericReserveApparatus,
    needsInstructions: true,
    moreProps: {
      names: ["columnNumber", "needsToAutopopulate", "optionGroups"],
      values: {
        columnNumber: 4,
        needsToAutopopulate: true,
        optionGroups: [],
        instructions: "Notify Staff Captain to update.",
      },
    },
    questionGroup: {
      name: "Reserve Apparatus",
      order: -1,
      questions: [
        ...CreateReserveApparatusQuestionByIndex(0),
        ...CreateReserveApparatusQuestionByIndex(1),
        ...CreateReserveApparatusQuestionByIndex(2),
        ...CreateReserveApparatusQuestionByIndex(3),
      ],
    },
  },
  {
    id: "WildlandField",
    name: "Wildland Apparatus",
    component: GenericWildlandField,
    needsInstructions: true,
    moreProps: {
      names: ["assignmentLength", "optionGroups", "groupName"],
      values: {
        assignmentLength: 14,
        optionGroups: [],
        groupName: [],
        instructions: "Volunteers agree to minimum 14 day assignment.",
      },
    },
    questionGroup: {
      name: "Wildland Apparatus",
      order: -1,
      questions: [
        { order: 0, questionText: "OES E1317", canBeHidden: true },
        { order: 1, questionText: "E321", canBeHidden: true },
        { order: 2, questionText: "OES WT 11", canBeHidden: true },
        { order: 3, questionText: "WT 27", canBeHidden: true },
        { order: 4, questionText: "P22", canBeHidden: true },
        {
          order: -1,
          questionText: "WSA Options",
          optionGroupName: "WSA Options",
          hideQuestionText: true,
        },
      ],
    },
  },
  {
    id: "UsarStatus",
    name: "US&R Status",
    component: GenericUsarStatus,
    needsInstructions: true,
    moreProps: {
      names: ["groupName"],
      values: {
        groupName: "Captains", //Default Value
        instructions: "Volunteers agree to minimum 21 day assignment.",
      },
    },
    questionGroup: {
      name: "US&R Status",
      order: -1,
      questions: [
        { order: 0, questionText: "US&R 63 (Heavy)", hideQuestionText: true },
        { order: 1, questionText: "UT 61" },
        { order: 2, questionText: "UT 63" },
        { order: 3, questionText: "Comments" },
      ],
    },
  },
];

const GroupNamePicker = (props: IMorePropsHandler) => {
  const [groups, setGroups] = useState<GroupDto[]>();
  const [loading, setLoading] = useState(true);

  const getGroups = async () => {
    const allGroup = await GroupService.getAllGroups();
    setGroups(allGroup || []);
    setLoading(false);
  };

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupNames = useMemo<string[]>(() => {
    if (props.values?.groupName) {
      if (Array.isArray(props.values.groupName)) {
        return [...props.values?.groupName];
      }
      return [props.values?.groupName];
    }
    return [];
  }, [props.values]);

  let displayOther = (props.values || {})[props.displayOtherId || ""];
  if (displayOther === undefined) {
    displayOther = true;
  }

  return (
    <div>
      <label className="mb-2" htmlFor="designer-groupName">
        Select Group(s) to Display in Dropdown
      </label>

      {loading ? (
        <CommonSpinner></CommonSpinner>
      ) : (
        <CheckboxList
          id={`groupNamePicker-${commonService.getUniqueId()}`}
          maxHeight={200}
          data={
            (groups || [])
              .sort(commonService.sortByStringProperty("name"))
              .map((grp) => {
                return {
                  id: grp.groupId,
                  label: grp.name,
                  value: groupNames.includes(grp.name),
                };
              }) || []
          }
          onChange={(data) => {
            props.onChange({
              ...props.values,
              groupName: [...data.filter((g) => g.value).map((g) => g.label)],
            });
          }}
        ></CheckboxList>
      )}

      {!!props.displayOtherId && (
        <div className="flex flex-center flex-0 no-wrap mt-2">
          <div className="flex-1"></div>
          <div>
            <input
              type="checkbox"
              id={props.displayOtherId}
              checked={displayOther}
              onChange={(e) => {
                if (!e.target) return;
                const checked = e.target.checked || false;
                const vals = {
                  ...(props?.values || {}),
                };
                if (props.displayOtherId) {
                  vals[props.displayOtherId] = checked;
                  props.onChange({
                    ...props.values,
                    ...vals,
                  });
                }
              }}
            ></input>
            <label className="m-0 ml-1" htmlFor={props.displayOtherId}>
              Display 'Other'
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export const MorePropsComponentHanlders = [
  {
    propName: "optionGroups",
    component: () => <div></div>,
  },
  {
    propName: "groupName",
    component: GroupNamePicker,
  },
  {
    propName: "assignmentLength",
    component: () => <div></div>,
  },
  {
    propName: "columnNumber",
    component: () => <div></div>,
  },
  {
    propName: "needsToAutopopulate",
    component: () => <div></div>,
  },
  {
    propName: "isVisibleShiftInvestigator", //Can be removed eventually
    component: (props: IMorePropsHandler) => {
      return (
        <div className="flex flex-center">
          <input
            type="checkbox"
            value={props.values?.isVisibleShiftInvestigator || false}
            checked={props.values?.isVisibleShiftInvestigator || false}
            onChange={(e) => {
              props.onChange({
                ...props.values,
                isVisibleShiftInvestigator: e.target.checked,
              });
            }}
            id="designer-isVisibleShiftInvestigator"
          ></input>
          <label
            className="pl-2 m-0"
            htmlFor="designer-isVisibleShiftInvestigator"
          >
            Show Shift Investigator
          </label>
        </div>
      );
    },
  },
];
