import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import CheckboxList from "../../Common/CheckboxList";
import { FgCheckbox, IChildComponentProps } from "../../Common/FormGroups";
import DTDrillDialog from "./Dialogs/DTDrillDialog";
import DTLocationDialog from "./Dialogs/DTLocationDialog";
import DTTrainingTypesDialog from "./Dialogs/DTTrainingTypesDialog";

function DTDetail(
  props: IChildComponentProps<ApsModels.IDrillTemplateOutputDto>
) {
  const [showDrillDialog, setShowDrillDialog] = useState(false);
  const [showLocDialog, setShowLocDialog] = useState(false);
  const [showTrainingDialog, setShowTrainingDialog] = useState(false);

  const template = (props?.data || {}) as ApsModels.IDrillTemplateOutputDto;
  let drills = template.drills || [];
  let locations = template.locations || [];
  let trainingTypes = template.trainingTypes || [];

  const [newDrillId, setNewDrillId] = useState(0);

  drills.sort(ApsServices.common.sortByName);
  locations.sort(ApsServices.common.sortByName);
  trainingTypes.sort(ApsServices.common.sortByName);

  return (
    <div className="card">
      <div className="card-body">
        <strong>2. Drill Details</strong>
        <div className="mt-4"></div>

        <FgCheckbox
          id="enableDrill"
          label="Drill Name"
          secondLabel="Enable"
          onChange={(data) => {
            template.isDrillEnabled = data;
            if (!data) {
              template.isJacEnabled = false;
              template.isIsaEnabled = false;
            }
            props?.onChange(template);
          }}
          data={template.isDrillEnabled}
        />
        <div className="mt-2"></div>
        <CheckboxList
          id="drillList"
          data={drills?.map((c) => {
            return {
              label: c.name,
              value: c.isEnabled,
              id: c.id,
            };
          })}
          onChange={(data) => {
            data.forEach((item, i) => {
              drills[i].isEnabled = item.value;
            });
            props?.onChange(template);
          }}
        ></CheckboxList>

        <Button variant="link" onClick={() => setShowDrillDialog(true)}>
          + Add New Option
        </Button>
        {showDrillDialog && (
          <DTDrillDialog
            onChange={(data) => {
              const newData = { ...data, id: newDrillId - 1 };
              drills.push(newData);
              drills.sort(ApsServices.common.sortByName);
              setNewDrillId(newData.id);
              props?.onChange(template);
            }}
            onClose={(saved) => setShowDrillDialog(false)}
            existingItems={drills?.map((c) => c.name)}
          ></DTDrillDialog>
        )}

        <div className="mt-4"></div>

        <FgCheckbox
          id="enableLocation"
          label="Location"
          secondLabel="Enable"
          onChange={(data) => {
            template.isLocationEnabled = data;
            props?.onChange(template);
          }}
          data={template.isLocationEnabled}
        />
        <div className="mt-2"></div>
        <CheckboxList
          id="locationList"
          data={locations?.map((c) => {
            return {
              label: c.name,
              value: c.isEnabled,
              id: c.id,
            };
          })}
          onChange={(data) => {
            data.forEach((item, i) => {
              locations[i].isEnabled = item.value;
            });
            props?.onChange(template);
          }}
        ></CheckboxList>

        <Button variant="link" onClick={() => setShowLocDialog(true)}>
          + Add New Option
        </Button>
        {showLocDialog && (
          <DTLocationDialog
            onChange={(data) => {
              locations.push(data);
              locations.sort(ApsServices.common.sortByName);
              props?.onChange(template);
            }}
            onClose={() => setShowLocDialog(false)}
            existingItems={locations?.map((c) => c.name)}
          ></DTLocationDialog>
        )}

        <div className="mt-4"></div>

        <FgCheckbox
          id="enableTrainingTypes"
          label="ISO Training Type"
          secondLabel="Enable"
          onChange={(data) => {
            template.isTrainingTypeEnabled = data;
            props?.onChange(template);
          }}
          data={template.isTrainingTypeEnabled}
        />
        <div className="mt-2"></div>
        <CheckboxList
          id="trainingTypesList"
          data={trainingTypes?.map((c) => {
            return {
              label: c.name,
              value: c.isEnabled,
              id: c.id,
            };
          })}
          onChange={(data) => {
            data.forEach((item, i) => {
              trainingTypes[i].isEnabled = item.value;
            });
            props?.onChange(template);
          }}
        ></CheckboxList>

        <div
          style={{
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={{ flex: 1 }}>
            <Button variant="link" onClick={() => setShowTrainingDialog(true)}>
              + Add New Option
            </Button>
            {showTrainingDialog && (
              <DTTrainingTypesDialog
                onChange={(data) => {
                  trainingTypes.push(data);
                  trainingTypes.sort(ApsServices.common.sortByName);
                  props?.onChange(template);
                }}
                onClose={() => setShowTrainingDialog(false)}
                existingItems={trainingTypes?.map((c) => c.name)}
              ></DTTrainingTypesDialog>
            )}
          </div>

          <FgCheckbox
            id="enableMultiSelect"
            label="Multi-Select"
            onChange={(data) => {
              template.isTrainingTypeMultiSelectEnabled = data;
              props?.onChange(template);
            }}
            data={template.isTrainingTypeMultiSelectEnabled}
          />
        </div>

        <div className="mt-2"></div>
      </div>
    </div>
  );
}
export default DTDetail;
