import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import CustomTabs from "../../../Common/CustomTabs";
import { INFIRSSectionProps, useSectionTabs } from "../NFIRSEntry";
import NFIRSValidationButton from "../NFIRSValidationButton";
import NFIRSEntryAES from "./NFIRSEntryAES";
import NFIRSEntryDamage from "./NFIRSEntryDamage";
import NFIRSEntryDetector from "./NFIRSEntryDetector";
import NFIRSEntryStructureType from "./NFIRSEntryStructureType";

function NFIRSSectionStructureFires(props: INFIRSSectionProps) {
  const { onTabSelect, activeTab, loadedTabs, changeTabStatus, getTabLabel } =
    useSectionTabs(props, "Structure Fire", "StructureType");

  const [model, setModel] =
    useState<ApsModels.INfirsStructureFireStructureTypeOutputDto>();
  useEffect(() => {
    const getData = async () => {
      await ApsServices.http.nfirsStructureFireStructureType
        .get(props.id)
        .then((data) => {
          setModel(data);
          nfirsStore.setNfirsStructureFireType(data.nfirsStructureTypeEntryId);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Structure Type", err);
        });
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NFIRSValidationButton
        module={ApsModels.NfirsValidationModulesEnum.StructureFire}
        onVerify={props.onVerify}
      ></NFIRSValidationButton>
      {!model && <CommonSpinner></CommonSpinner>}
      {!!model && (
        <CustomTabs onMenuTabClick={onTabSelect} activeTab={activeTab}>
          <Tabs
            id="nfirs-structure-fire-page"
            activeKey={activeTab}
            onSelect={onTabSelect}
          >
            <Tab
              eventKey="StructureType"
              title={getTabLabel("StructureType", "Structure Type")}
            >
              {loadedTabs["StructureType"] && (
                <div className="pt-4">
                  <NFIRSEntryStructureType
                    id={props.id}
                    data={null}
                    onChange={(data, hasChanges) => {
                      changeTabStatus("StructureType", hasChanges ? 1 : 0);
                    }}
                  ></NFIRSEntryStructureType>
                </div>
              )}
            </Tab>
            <Tab eventKey="Damage" title={getTabLabel("Damage", "Damage")}>
              {loadedTabs["Damage"] && (
                <div className="pt-4">
                  <NFIRSEntryDamage
                    id={props.id}
                    data={null}
                    onChange={(data, hasChanges) => {
                      changeTabStatus("Damage", hasChanges ? 1 : 0);
                    }}
                  ></NFIRSEntryDamage>
                </div>
              )}
            </Tab>
            <Tab
              eventKey="Detector"
              title={getTabLabel("Detector", "Detector")}
            >
              {loadedTabs["Detector"] && (
                <div className="pt-4">
                  <NFIRSEntryDetector
                    id={props.id}
                    data={null}
                    onChange={(data, hasChanges) => {
                      changeTabStatus("Detector", hasChanges ? 1 : 0);
                    }}
                  ></NFIRSEntryDetector>
                </div>
              )}
            </Tab>
            <Tab eventKey="AES" title={getTabLabel("AES", "AES")}>
              {loadedTabs["AES"] && (
                <div className="pt-4">
                  <NFIRSEntryAES
                    id={props.id}
                    data={null}
                    onChange={(data, hasChanges) => {
                      changeTabStatus("AES", hasChanges ? 1 : 0);
                    }}
                  ></NFIRSEntryAES>
                </div>
              )}
            </Tab>
          </Tabs>
        </CustomTabs>
      )}
    </>
  );
}

export default NFIRSSectionStructureFires;
