import React, { useEffect, useState } from "react";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import { AgGridReact } from "ag-grid-react";
import ApsModels from "../../models";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { useGridFilters } from "../../stores/SystemStore";
import LoadMoreIndicator from "../Common/LoadMoreIndicator";
import toastStore from "../../stores/ToastStore";
import ConfirmDialog from "../Common/ConfirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

function SavedReports(props: any) {
  const ActionCellComponent = (rowProps: {
    data: ApsModels.ITrainingReportGridItemDto;
  }) => {
    return (
      <div className="flex flex-row now-wrap">
        <div>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm me-4"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.history.push(`/reports/view/${rowProps.data.id}`);
            }}
          >
            <span className="px-4">Run</span>
          </button>
        </div>
        <div className="flex-1">
          <FontAwesomeIcon
            className="pointer text-primary me-4"
            icon={faPencilAlt}
            title="Edit"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.history.push(`/reports/build/${rowProps.data.id}`);
            }}
          />
          {(rowProps.data as any)["_showSchedule"] && (
            <FontAwesomeIcon
              className="pointer text-primary me-4"
              icon={faCalendarAlt}
              title="Schedule"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.history.push(
                  `/reports/build/${rowProps.data.id}/schedule`
                );
              }}
            />
          )}
          <i
            className="fa fa-trash pointer text-danger"
            title="Delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setRptToDel(rowProps.data);
              setConfirmDel(true);
            }}
          ></i>
        </div>
      </div>
    );
  };

  const [ready, setReady] = useState(false);
  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        field: "reportName",
        headerName: "Report Name",
      },
      {
        field: "owner",
        headerName: "Owner",
      },
      {
        field: "reportType",
        headerName: "Report Type",
      },
      {
        field: "_dateCreated",
        headerName: "Created On",
        ...commonService.agGridHelpers.dateValueFormatter(
          "dateCreated",
          "",
          "MMM DD, YYYY"
        ),
      },
      {
        field: "_dateUpdated",
        headerName: "Last Modified On",
        ...commonService.agGridHelpers.dateValueFormatter(
          "dateUpdated",
          "",
          "MMM DD, YYYY"
        ),
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "actionCellComponent",
        width: 50,
        ...commonService.agGridHelpers.actionColPinnedDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      minWidth: 100,
    },
    frameworkComponents: {
      actionCellComponent: ActionCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ITrainingReportGridItemDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const [list, setList] = useState<ApsModels.ITrainingReportGridDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);

  const gridFilters = useGridFilters(
    "saved-reports-table",
    gridApi,
    (others) => {
      if (others) {
      }
    }
  );

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    gridFilters.init(params);
  };

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageToLoad, setPageToLoad] = useState(1);

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();
    setLoading(true);
    setPageToLoad(1);

    await ApsServices.http.trainingReport
      .list(1, commonService.gridPageSize.firstPage)
      .then((data) => {
        setList(data);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        }

        if (!ready) {
          setTimeout(() => {
            setReady(true);
          }, 500);
          if (
            data.isScheduleReportAvailable 
            &&  gridState.columnDefs?.length === 6
          ) {
            setGridState((prev) => {
              return {
                ...prev,
                columnDefs: [
                  ...prev.columnDefs,
                  {
                    field: "reportScheduleEnum",
                    headerName: "Schedule",
                  },
                  {
                    field: "_nextTimeScheduleRun",
                    headerName: "Next Schedule Run",
                    ...commonService.agGridHelpers.dateValueFormatter(
                      "nextTimeScheduleRun",
                      ""
                    ),
                  },
                ],
              };
            });
          }
        }
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Saved Reports", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);
      });
  };

  const loadMore = () => {
    if (list && list.totalRecords > list.trainingReportGridItemDtos?.length) {
      setLoadingMore(true);
      setLoading(false);

      let pageNum = pageToLoad + 1;
      if (
        commonService.gridPageSize.firstPage <
          commonService.gridPageSize.otherPages &&
        list.trainingReportGridItemDtos?.length ===
          commonService.gridPageSize.firstPage
      ) {
        pageNum = 1;
      }

      setPageToLoad(pageNum);
      ApsServices.http.trainingReport
        .list(pageNum, commonService.gridPageSize.otherPages)
        .then((data) => {
          if (!loading && data.trainingReportGridItemDtos?.length > 0) {
            setList((prev) => {
              return {
                totalRecords: prev?.totalRecords || data.totalRecords,
                isScheduleReportAvailable: data.isScheduleReportAvailable,
                trainingReportGridItemDtos:
                  pageNum === 1
                    ? data.trainingReportGridItemDtos
                    : [
                        ...(prev?.trainingReportGridItemDtos || []),
                        ...data.trainingReportGridItemDtos,
                      ],
              };
            });
          }
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Saved Reports", err);
        })
        .finally(() => {
          setLoadingMore(false);
          setLoading(false);
        });
    } else {
      gridFilters.reApplyFilters(); //need to reapply filter after adding more data
      setLoadingMore(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (loading || loadingMore) {
      return;
    }
    if (list?.totalRecords) {
      gridApi && gridApi.hideOverlay();
    }
    setGridState({
      ...gridState,
      rowData: (list?.trainingReportGridItemDtos || []).map((row) => {
        return {
          ...row,
          _dateUpdated: commonService.agGridHelpers.toExportDate(
            row.dateUpdated
          ),
          _dateCreated: commonService.agGridHelpers.toExportDate(
            row.dateCreated
          ),
          _nextTimeScheduleRun: commonService.agGridHelpers.toExportDate(
            row.nextTimeScheduleRun
          ),
          _showSchedule: list?.isScheduleReportAvailable || false,
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, loadingMore]);

  useEffect(() => {
    if (gridApi && gridFilters.ready) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, gridFilters.ready]);

  const [confirmDel, setConfirmDel] = useState(false);
  const [rptToDel, setRptToDel] =
    useState<ApsModels.ITrainingReportGridItemDto>();
  const doDeleteReport = async () => {
    if (rptToDel) {
      await ApsServices.http.trainingReport
        .delete(rptToDel.id)
        .then((data) => {
          toastStore.showToast("Report Deleted.", "success");
          getList();
        })
        .catch((err) => {
          toastStore.showError("Failed Deleting Report", err);
        })
        .finally(() => {
          setRptToDel(undefined);
        });
    }
  };

  return (
    <>
      <ConfirmDialog
        show={confirmDel}
        title="Delete custom report"
        message={`You're about to delete this custom report. You can't undo this action.<br/>Do you want to continue?`}
        done={(rtn) => {
          if (rtn === "yes") {
            doDeleteReport();
          }
          setConfirmDel(false);
        }}
        buttons={[
          {
            label: "Cancel",
            value: "cancel",
            variant: "primary",
          },
          {
            label: "Delete custom report",
            value: "yes",
            variant: "danger",
          },
        ]}
      />
      <div className="flex-1 flex flex-col pt-2">
        <h4 className="mb-3">Saved Reports (Training Hours Only)</h4>

        <div className="flex-1 flex flex-col">
          <div style={{ width: "100%", height: "100%" }}>
            <div
              id="myGrid"
              style={{
                height: "100%",
                minHeight: "400px",
                position: "relative",
              }}
              className="ag-theme-alpine flex-1"
            >
              <AgGridReact
                key={ready ? "ready" : ""}
                columnDefs={gridState.columnDefs}
                defaultColDef={gridState.defaultColDef}
                autoGroupColumnDef={gridState.autoGroupColumnDef}
                enableRangeSelection={true}
                animateRows={true}
                onGridReady={onGridReady}
                rowSelection="multiple"
                rowMultiSelectWithClick={false}
                suppressRowDeselection={true}
                suppressRowClickSelection={true}
                frameworkComponents={gridState.frameworkComponents}
                rowData={gridState.rowData || []}
                onRowDoubleClicked={(event) => {
                  props.history.push(`/reports/view/${event.data.id}`);
                }}
                overlayLoadingTemplate={gridState.overlayLoadingTemplate}
                overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
                {...gridFilters.props}
              />
            </div>
          </div>
          <LoadMoreIndicator
            loadingMore={loadingMore}
            currentCount={list?.trainingReportGridItemDtos?.length}
            totalExpected={list?.totalRecords}
          ></LoadMoreIndicator>
        </div>
      </div>
    </>
  );
}

export default SavedReports;
