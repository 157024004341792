import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { UserService } from "../../../../services/UserService";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import commonService from "../../../services/CommonService";
import nfirsStore, { useGotoValidatedTab } from "../../../stores/NFIRSStore";
import toastStore from "../../../stores/ToastStore";
import CommonSpinner from "../../Common/CommonSpinner";
import { IChildComponentProps } from "../../Common/FormGroups";
import NFIRSAllValidationDialog from "../NFIRSAllValidationDialog";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../NFIRSHelper";
import NFIRSModuleValidationDialog from "../NFIRSModuleValidationDialog";
import NFIRSSectionApparatus from "./Apparatus/NFIRSSectionApparatus";
import NFIRSSectionArson from "./Arson/NFIRSSectionArson";
import NFIRSSectionBasic from "./Basic/NFIRSSectionBasic";
import NFIRSSectionFire from "./Fire/NFIRSSectionFire";
import NFIRSSectionHazmat from "./HAZMAT/NFIRSSectionHazmat";
import NFIRSChangeLogDialog from "./NFIRSChangeLogDialog";
import NFIRSSectionStructureFires from "./StructureFire/NFIRSSectionStructureFires";
import NFIRSSectionWildlandFire from "./WildlandFire/NFIRSSectionWildlandFire";

export type NFIRS_SECTION =
  | "Basic"
  | "Apparatus/Personnel"
  | "Fire"
  | "Structure Fire"
  | "HAZMAT"
  | "Arson"
  | "Wildland Fire";

interface ISection {
  name: NFIRS_SECTION;
  status: number; //could be incomplete/complete/done
  current: boolean;
  visible: boolean;
  loaded?: boolean; //indicates if contents should be or has been loaded to DOM
  activeTab?: string;
  tabs: string[];
  tabStatus?: { [key: string]: number };
  tabEnums: ApsModels.NfirsValidationTabsEnum[];
  sectionEnum: ApsModels.NfirsValidationModulesEnum;
}

export const GetSectionEnumFromName = (sec: NFIRS_SECTION) => {
  if (sec === "Basic") {
    return ApsModels.NfirsValidationModulesEnum.Basic;
  }
  if (sec === "Apparatus/Personnel") {
    return ApsModels.NfirsValidationModulesEnum.ApparatusPersonnel;
  }
  if (sec === "Fire") {
    return ApsModels.NfirsValidationModulesEnum.Fire;
  }
  if (sec === "Structure Fire") {
    return ApsModels.NfirsValidationModulesEnum.StructureFire;
  }
  if (sec === "HAZMAT") {
    return ApsModels.NfirsValidationModulesEnum.Hazmat;
  }
  if (sec === "Arson") {
    return ApsModels.NfirsValidationModulesEnum.Arson;
  }
  if (sec === "Wildland Fire") {
    return ApsModels.NfirsValidationModulesEnum.Wildland;
  }
  return null;
};

export interface INFIRSSectionProps {
  id: any;
  activeTab: string;
  onTabChanged: (tab: string) => void;
  onTabStatusChanged?: (tab: string, status: number) => void;
  header?: ApsModels.INfirsReportOutputDto;
  onVerify?: () => void;
}

export interface INFIRSTabProps<T> extends IChildComponentProps<T> {
  id: any;
  header?: ApsModels.INfirsReportOutputDto;
}

export const useSectionTabs = (
  props: INFIRSSectionProps,
  section: NFIRS_SECTION,
  defaultTab: string
) => {
  const tmp = JSON.parse(`{ "${defaultTab}": true }`);
  const [loadedTabs, setLoadedTabs] = useState<{ [key: string]: boolean }>(tmp);
  const [tabStatuses, setTabStatuses] = useState<{ [key: string]: number }>({});
  const [activeTab, setActiveTab] = useState(defaultTab);
  const onTabSelect = (tab: string | null, fromParent?: any) => {
    if (tab) {
      const copy = { ...loadedTabs };
      copy[tab] = true;
      setActiveTab(tab);
      setLoadedTabs(copy);
      if (fromParent === true) {
        //do nothing...
      } else {
        props.onTabChanged(tab);
      }
    }
  };

  useEffect(() => {
    if (props.activeTab) {
      onTabSelect(props.activeTab, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab]);

  useEffect(() => {
    if (tabStatuses) {
      const tabs: string[] = [];
      for (let p in tabStatuses) {
        if (tabStatuses[p]) {
          tabs.push(p);
        }
      }
      nfirsStore.setUnsavedTabs(section, tabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabStatuses]);

  return {
    onTabSelect: (tab: string | null, fromParent?: any) => {
      tab &&
        nfirsStore.setTabChangedOnClick(
          nfirsStore.activeTabSection.section,
          nfirsStore.activeTabSection.tab
        );

      onTabSelect(tab, fromParent);
    },
    activeTab,
    loadedTabs,
    tabStatuses,
    changeTabStatus: (tab: string, status: number) => {
      const tmp = JSON.parse(`{ "${tab}": ${status} }`);
      setTabStatuses({ ...tabStatuses, ...tmp });
    },
    getTabLabel(tabId: string, label: string) {
      return `${label}${tabStatuses[tabId] === 1 ? " *" : ""}`;
    },
  };
};

export const useIsOtherModuleDataRequired = () => {
  const [isOtherModuleDataRequired, setIsOtherModuleDataRequired] = useState(
    nfirsStore.isOtherModuleDataRequired
  );

  useEffect(() => {
    const disposer = reaction(
      () => nfirsStore.isOtherModuleDataRequired,
      (data) => {
        setIsOtherModuleDataRequired(data);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isOtherModuleDataRequired;
};

export const useNfirsTabChangedOnClick = (
  section: NFIRS_SECTION,
  tab: string,
  onSave: () => void
) => {
  const [isSaveOnTabChanged, setIsSaveOnTabChanged] = useState(false);
  useEffect(() => {
    if (isSaveOnTabChanged) {
      console.log(`Saving tab: ${tab}`);
      onSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveOnTabChanged]);
  useEffect(() => {
    const disposeReaction = reaction(
      () => nfirsStore.tabChangedOnClick,
      (data, prev) => {
        const newVal = data.section === section && data.tab === tab;
        setIsSaveOnTabChanged(false);
        if (newVal && (nfirsStore.unsavedTabs[section] || []).includes(tab)) {
          setIsSaveOnTabChanged(true);
        }
      }
    );
    return () => {
      disposeReaction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    isSaveOnTabChanged,
    setIsSaveOnTabChanged,
  };
};

export const useSaveOnNext = (
  section: NFIRS_SECTION,
  tab: string,
  submit?: (lock?: boolean, saveAll?: boolean) => void
) => {
  let cnt = 0;
  const [onSubmit, setOnSubmit] = useState<number>(cnt);
  const [lock, setLock] = useState(false);
  useEffect(() => {
    if (onSubmit) {
      if (submit) {
        submit(lock);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    const disposeReaction = reaction(
      () => nfirsStore.moveTabSection,
      (data) => {
        if (data?.prev) {
          if (data.prev.section === section && data.prev.tab === tab) {
            cnt++;
            setLock(data.prev.lock || false);
            setOnSubmit(cnt);
          }
        }
      }
    );

    const disposeReaction2 = reaction(
      () => nfirsStore.currentTabForSaveAll,
      (data) => {
        if (
          data &&
          data.section === section &&
          data.tab === tab &&
          !data.next &&
          !data.failed
        ) {
          cnt++;
          setLock(false);
          setOnSubmit(cnt);
          //console.log(`SUBMIT: ${section} - ${tab} - ${new Date()}`);
        }
      }
    );

    return () => {
      //console.log("disposeReaction: " + tab);
      disposeReaction();
      disposeReaction2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

function NFIRSEntry(props: RouteComponentProps<any>) {
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    nfirsStore.clearUnsavedTabs();
    nfirsStore.setActiveTabSection("Basic", "Authority");
    nfirsStore.setPreventNextModuleValidation(false);
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  useEffect(() => {
    if (departmentId > 0) {
      header.getData();
      nfirsStore.setNfirsBasicIncidentType(undefined);
      nfirsStore.setNfirsPropertyUseTypeId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const reportId: any = props?.match?.params?.id || null;
  const [currentSection, setCurrentSection] = useState<ISection>();
  const [sections, setSections] = useState<ISection[]>([
    {
      name: "Basic",
      status: 0,
      current: true,
      visible: true,
      loaded: true,
      activeTab: "Authority",
      tabs: [
        "Authority",
        "Incident",
        "Location",
        "Dates",
        "Actions",
        "Property",
        "Involved",
        "Remarks",
      ],
      sectionEnum: ApsModels.NfirsValidationModulesEnum.Basic,
      tabEnums: [
        ApsModels.NfirsValidationTabsEnum.BasicAuthority,
        ApsModels.NfirsValidationTabsEnum.BasicIncident,
        ApsModels.NfirsValidationTabsEnum.BasicLocation,
        ApsModels.NfirsValidationTabsEnum.BasicDates,
        ApsModels.NfirsValidationTabsEnum.BasicActions,
        ApsModels.NfirsValidationTabsEnum.BasicProperty,
        ApsModels.NfirsValidationTabsEnum.BasicInvolved,
        ApsModels.NfirsValidationTabsEnum.BasicRemarks,
      ],
    },
    {
      name: "Apparatus/Personnel",
      status: 0,
      current: false,
      visible: true,
      activeTab: "Apparatus",
      tabs: ["Apparatus", "Personnel"],
      sectionEnum: ApsModels.NfirsValidationModulesEnum.ApparatusPersonnel,
      tabEnums: [
        ApsModels.NfirsValidationTabsEnum.ApparatusApparatus,
        ApsModels.NfirsValidationTabsEnum.ApparatusPersonnel,
      ],
    },
    {
      name: "Fire",
      status: 0,
      current: false,
      visible: true,
      activeTab: "Property",
      tabs: ["Property", "Ignition", "EquipmentInvolved", "MobileProperty"],
      sectionEnum: ApsModels.NfirsValidationModulesEnum.Fire,
      tabEnums: [
        ApsModels.NfirsValidationTabsEnum.FireProperty,
        ApsModels.NfirsValidationTabsEnum.FireIgnition,
        ApsModels.NfirsValidationTabsEnum.FireEquipmentInvolved,
        ApsModels.NfirsValidationTabsEnum.FireMobileProperty,
      ],
    },
    {
      name: "Structure Fire",
      status: 0,
      current: false,
      visible: true,
      activeTab: "StructureType",
      tabs: ["StructureType", "Damage", "Detector", "AES"],
      sectionEnum: ApsModels.NfirsValidationModulesEnum.StructureFire,
      tabEnums: [
        ApsModels.NfirsValidationTabsEnum.StructureFireStructureType,
        ApsModels.NfirsValidationTabsEnum.StructureFireDamage,
        ApsModels.NfirsValidationTabsEnum.StructureFireDetector,
        ApsModels.NfirsValidationTabsEnum.StructureFireAes,
      ],
    },
    {
      name: "HAZMAT",
      status: 0,
      current: false,
      visible: true,
      activeTab: "Area",
      tabs: [
        "Area",
        "Cause",
        "Chemical",
        "MobileProperty",
        "EquipmentInvolved",
      ],
      sectionEnum: ApsModels.NfirsValidationModulesEnum.Hazmat,
      tabEnums: [
        ApsModels.NfirsValidationTabsEnum.HazmatArea,
        ApsModels.NfirsValidationTabsEnum.HazmatCause,
        ApsModels.NfirsValidationTabsEnum.HazmatChemical,
        ApsModels.NfirsValidationTabsEnum.HazmatMobileProperty,
        ApsModels.NfirsValidationTabsEnum.HazmatEquipmentInvolved,
      ],
    },
    {
      name: "Arson",
      status: 0,
      current: false,
      visible: true,
      activeTab: "Investigation",
      tabs: [
        "Investigation",
        "Details",
        "AgencyReferral",
        "Juvenile",
        "Remarks",
      ],
      sectionEnum: ApsModels.NfirsValidationModulesEnum.Arson,
      tabEnums: [
        ApsModels.NfirsValidationTabsEnum.ArsonInvestigation,
        ApsModels.NfirsValidationTabsEnum.ArsonDetails,
        ApsModels.NfirsValidationTabsEnum.ArsonAgencyReferral,
        ApsModels.NfirsValidationTabsEnum.ArsonJuvenile,
        ApsModels.NfirsValidationTabsEnum.ArsonRemarks,
      ],
    },
    {
      name: "Wildland Fire",
      status: 0,
      current: false,
      visible: true,
      activeTab: "Location",
      tabs: ["Location", "Ignition", "Property", "PeopleInvolved", "Details"],
      sectionEnum: ApsModels.NfirsValidationModulesEnum.Wildland,
      tabEnums: [
        ApsModels.NfirsValidationTabsEnum.WildlandLocation,
        ApsModels.NfirsValidationTabsEnum.WildlandIgnition,
        ApsModels.NfirsValidationTabsEnum.WildlandProperty,
        ApsModels.NfirsValidationTabsEnum.WildlandPeopleInvolved,
        ApsModels.NfirsValidationTabsEnum.WildlandDetail,
      ],
    },
  ]);

  const moveSection = (section: ISection, activeTab = "") => {
    const copy = [...sections]; //commonService.deepCloneJsonArray(sections);
    copy.forEach((s) => {
      s.current = s.name === section.name;
      if (s.current && !s.loaded) {
        s.loaded = true;
      }
      //console.log(`${s.name}: ${s.loaded}`);
      if (s.current) {
        if (!!activeTab) {
          s.activeTab = activeTab;
        }
        setCurrentSection(commonService.deepCloneJsonObject(s));
      }
    });
    setSections(copy);
    if (activeTab) {
      nfirsStore.setActiveTabSection(section.name, activeTab);
    } else {
      copy.forEach((s) => {
        if (s.current && s.activeTab) {
          nfirsStore.setActiveTabSection(s.name, s.activeTab);
        }
      });
    }
  };

  const disableBackButton = () => {
    return (
      sections
        .filter((s) => s.visible)
        .filter(
          (s, i) =>
            i === 0 &&
            s.current &&
            s.tabs &&
            s.activeTab &&
            s.tabs.indexOf(s.activeTab) === 0
        ).length > 0
    );
  };

  const isLastTabReached = () => {
    return (
      sections
        .filter((s) => s.visible)
        .filter((s, i) => {
          return (
            i === sections.filter((s) => s.visible).length - 1 &&
            s.current &&
            s.tabs &&
            s.activeTab &&
            s.tabs.indexOf(s.activeTab) === s.tabs.length - 1
          );
        }).length > 0
    );
  };

  const getSectionClassName = (sec: ISection) => {
    if (sec && currentSection) {
      if (sec.current) {
        return "current-section";
      }
    }
    if (sec.name !== "Basic" && !isOtherModuleDataRequired) {
      return "complete-section";
    }
    if (validation && validation.data?.nfirsVerificationModuleOutputDtos) {
      const opt = validation.data.nfirsVerificationModuleOutputDtos.find(
        (o) => GetSectionEnumFromName(sec.name) === o.nfirsValidationModulesEnum
      );
      if (opt?.valid) {
        return "complete-section";
      }
    }
    return "";
  };

  const getSectionIconClassName = (sec: ISection) => {
    if (
      (sec.name === "Basic" &&
        validateBasic.status === NfirsFetchStatus.InProgress) ||
      (sec.name === "Apparatus/Personnel" &&
        validateApparatus.status === NfirsFetchStatus.InProgress) ||
      (sec.name === "Fire" &&
        validateFire.status === NfirsFetchStatus.InProgress) ||
      (sec.name === "Structure Fire" &&
        validateStructureFire.status === NfirsFetchStatus.InProgress) ||
      (sec.name === "HAZMAT" &&
        validateHazmat.status === NfirsFetchStatus.InProgress) ||
      (sec.name === "Arson" &&
        validateArson.status === NfirsFetchStatus.InProgress) ||
      (sec.name === "Wildland Fire" &&
        validateWildland.status === NfirsFetchStatus.InProgress)
    ) {
      return "fa-spin fa-spinner";
    }
    if (sec.name !== "Basic" && !isOtherModuleDataRequired) {
      return "fa-check-circle";
    }
    if (validation && validation.data?.nfirsVerificationModuleOutputDtos) {
      const opt = validation.data.nfirsVerificationModuleOutputDtos.find(
        (o) => GetSectionEnumFromName(sec.name) === o.nfirsValidationModulesEnum
      );
      if (opt?.valid) {
        return "fa-check-circle";
      }
    }
    return "fa-circle";
  };

  const onBackTab = () => {
    if (disableBackButton()) {
      return;
    }
    const copy = [...sections];
    copy
      .filter((s) => s.visible)
      .forEach((sec, i) => {
        if (sec.current) {
          const idx = sec.tabs.indexOf(sec.activeTab || "");

          if (idx === 0) {
            const prevSec = sections.filter((s) => s.visible)[i - 1];
            nfirsStore.setMoveTabSection({
              next: {
                section: prevSec.name,
                tab: prevSec.tabs[prevSec.tabs.length - 1],
              },
              prev: {
                section: sec.name,
                tab: sec.tabs[idx],
              },
              navBack: true,
            });
          } else {
            nfirsStore.setMoveTabSection({
              next: {
                section: sec.name,
                tab: sec.tabs[idx - 1],
              },
              prev: {
                section: sec.name,
                tab: sec.tabs[idx],
              },
              navBack: true,
            });
          }
        }
      });
    /*==================================================================
    let doNothing = false;
    const copy = [...sections];
    nfirsStore.resetMoveTabSection();
    copy
      .filter((s) => s.visible)
      .forEach((sec, i) => {
        if (sec.current) {
          const idx = sec.tabs?.indexOf(sec.activeTab || "");
          if (sec.tabs && idx !== undefined && idx > -1) {
            if (idx === 0) {
              doNothing = true;
              if (i > 0) {
                const prevSec = sections.filter((s) => s.visible)[i - 1];
                moveSection(prevSec, prevSec?.tabs[prevSec.tabs.length - 1]);
              }
            } else {
              sec.activeTab = sec.tabs[idx - 1];
              nfirsStore.setActiveTabSection(sec.name, sec.activeTab);
            }
          } else {
            doNothing = true;
            if (i > 0) {
              const prevSec = sections.filter((s) => s.visible)[i - 1];
              moveSection(prevSec, prevSec?.tabs[prevSec.tabs.length - 1]);
            }
          }
        }
      });
    if (!doNothing) {
      setSections(copy);
    } 
    ==================================================================*/
  };

  const onNextTab = (lock = false) => {
    const isLastTab = isLastTabReached();
    const copy = [...sections];
    copy
      .filter((s) => s.visible)
      .forEach((sec, i) => {
        if (sec.current) {
          const idx = sec.tabs.indexOf(sec.activeTab || "");
          if (isLastTab) {
            nfirsStore.setMoveTabSection({
              next: {
                section: sec.name,
                tab: sec.tabs[idx],
              },
              prev: {
                section: sec.name,
                tab: sec.tabs[idx],
                lock: lock,
              },
            });
            return;
          }

          if (idx === sec.tabs.length - 1) {
            const nxtSec = sections.filter((s) => s.visible)[i + 1];
            nfirsStore.setMoveTabSection({
              next: {
                section: nxtSec.name,
                tab: nxtSec.tabs[0],
              },
              prev: {
                section: sec.name,
                tab: sec.tabs[idx],
                lock: lock,
              },
            });
          } else {
            nfirsStore.setMoveTabSection({
              next: {
                section: sec.name,
                tab: sec.tabs[idx + 1],
              },
              prev: {
                section: sec.name,
                tab: sec.tabs[idx],
                lock: lock,
              },
            });
          }
        }
      });
  };

  const [saving, setSaving] = useState(false);

  const header = useNfirsGetData<ApsModels.INfirsReportOutputDto>(async () => {
    return await ApsServices.http.nfirsReport.get(reportId);
  }, "Report Header Detail");

  const [isManualValidation, setIsManualValidation] = useState(false);
  const validation = useNfirsGetData<ApsModels.INfirsValidationOutputDto>(
    async () => {
      return await ApsServices.http.nfirsValidation
        .validateReport(reportId)
        .then(
          (rtn) =>
            new Promise((resolve) =>
              setTimeout(() => {
                setIsManualValidation(false);
                resolve(rtn);
              }, 500)
            )
        );
    },
    "Validation information"
  );

  useEffect(() => {
    if (isManualValidation) {
      validation.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualValidation]);

  useEffect(() => {
    if (
      header.status === NfirsFetchStatus.Complete &&
      header.data &&
      header.data?.id
    ) {
      nfirsStore.setCurrentNfirsReport(header.data);

      const copy = [...sections];
      copy.forEach((sec) => {
        if (sec.sectionEnum === ApsModels.NfirsValidationModulesEnum.Fire) {
          sec.visible =
            header.data?.isModuleRequiredHelper?.isFireModuleRequired ||
            header.data?.isModuleRequiredHelper?.doesFireModuleAppear ||
            false;
        } else if (
          sec.sectionEnum === ApsModels.NfirsValidationModulesEnum.StructureFire
        ) {
          sec.visible =
            header.data?.isModuleRequiredHelper
              ?.isStructureFireModuleRequired ||
            header.data?.isModuleRequiredHelper
              ?.doesStructureFireModuleAppear ||
            false;
        } else if (
          sec.sectionEnum === ApsModels.NfirsValidationModulesEnum.Hazmat
        ) {
          sec.visible =
            header.data?.isModuleRequiredHelper?.doesHazmatModuleAppear ||
            false;
        } else if (
          sec.sectionEnum === ApsModels.NfirsValidationModulesEnum.Arson
        ) {
          sec.visible =
            header.data?.isModuleRequiredHelper?.doesArsonModuleAppear || false;
        } else if (
          sec.sectionEnum === ApsModels.NfirsValidationModulesEnum.Wildland
        ) {
          sec.visible =
            header.data?.isModuleRequiredHelper?.doesWildlandModuleAppear ||
            false;
        } else {
          sec.visible = true;
        }
        nfirsStore.setCanAcccessModule(sec.sectionEnum, sec.visible);
        setSections(copy);
      });

      validation.getData();
    } else if (header.status === NfirsFetchStatus.Failed) {
      props.history.push("/nfirs");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header.status]);

  const [canAcccessModules, setCanAcccessModules] = useState(
    nfirsStore.canAcccessModules
  );

  useEffect(() => {
    const copy = [...sections];
    copy.forEach((sec) => {
      sec.visible = canAcccessModules[sec.sectionEnum];
    });
    setSections(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAcccessModules]);

  //NEXT TAB
  const [nextTab, setNextTab] = useState(0);
  useEffect(() => {
    if (nextTab) {
      if (nfirsStore.moveTabSection.navBack) {
        //console.log(`### Back: ${nextTab}`);
        onBackTab();
      } else {
        //console.log(`### Next: ${nextTab}`);
        onNextTab();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTab]);

  useEffect(() => {
    nfirsStore.updateCurrentTabForSaveAll();

    if (reportId) {
      header.getData();
      tabNames.getData();
    }

    const disposeReaction = reaction(
      () => nfirsStore.moveTabSection,
      (data) => {
        //setSaving(!!data?.prev && !!data?.next);
        if (!data?.prev && !!data?.next) {
          const copy = commonService.deepCloneJsonArray(sections);
          let hasMatch = false;
          copy.forEach((s) => {
            if (!hasMatch && data.next?.section === s.name) {
              if (s.tabs?.indexOf(data.next?.tab) > -1) {
                s.activeTab = data.next.tab;
                hasMatch = true;
                moveSection(s, data.next.tab);
              }
            }
          });
        }
      }
    );

    sections.forEach((s) => {
      if (s.current) {
        setCurrentSection(commonService.deepCloneJsonObject(s));
      }
    });

    const disposeReaction2 = reaction(
      () => nfirsStore.nextTab,
      (data) => {
        setNextTab(data);
      }
    );

    let lastTabSaved: string | undefined;
    const disposeReaction3 = reaction(
      () => nfirsStore.sectionTabBeingSaved,
      async (data) => {
        //VALIDATE MODULE AFTER SAVING TAB DATA
        if (lastTabSaved && !data) {
          const sec = GetSectionEnumFromName(lastTabSaved.split("/")[0] as any);
          if (sec !== null) {
            //console.log(`VALIDATE: ${lastTabSaved} : ${sec}`);
            await validateModule(sec);
          }
        }
        lastTabSaved = data;
        setSaving(!!data);
      }
    );

    const disposeReaction4 = reaction(
      () => nfirsStore.canAcccessModules,
      async (data) => {
        setCanAcccessModules(data);
      }
    );

    const disposeReaction5 = reaction(
      () => nfirsStore.currentTabForSaveAll,
      async (data) => {
        if (data && data.next) {
          setSavingAll("Saving...");
          return;
        }
        if (!data || data?.failed) {
          setSavingAll(undefined);

          if (data && data.failed) {
            nfirsStore.setMoveTabSection({
              next: {
                section: data.section,
                tab: data.tab,
              },
              prev: null,
            });
            nfirsStore.updateCurrentTabForSaveAll();
          }
        }
      }
    );

    const disposeReaction6 = reaction(
      () => nfirsStore.manualValidateModule,
      async (data) => {
        if (data) {
          nfirsStore.setManualValidateModule();
          const sec = GetSectionEnumFromName(data as any);
          if (sec !== null) {
            await validateModule(sec);
          }
        }
      }
    );

    const disposeReaction7 = reaction(
      () => nfirsStore.manualValidateAll,
      async (data) => {
        if (data) {
          setIsManualValidation(true);
        }
      }
    );

    return () => {
      disposeReaction();
      disposeReaction2();
      disposeReaction3();
      disposeReaction4();
      disposeReaction5();
      disposeReaction6();
      disposeReaction7();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SectionLabel = observer(({ name }: { name: NFIRS_SECTION }) => {
    return (
      <span>
        {name}
        {nfirsStore.unsavedTabs[name]?.length > 0 ? <strong>*</strong> : ""}
      </span>
    );
  });

  const tabNames = useNfirsLookupEnum("NfirsValidationTabsEnum", "Tab Names");
  const validateBasic =
    useNfirsGetData<ApsModels.INfirsValidationModuleOutputDto>(async () => {
      return await ApsServices.http.nfirsValidation.validateBasic(reportId);
    }, "Validation information");
  const validateApparatus =
    useNfirsGetData<ApsModels.INfirsValidationModuleOutputDto>(async () => {
      return await ApsServices.http.nfirsValidation.validateApparatusPersonnel(
        reportId
      );
    }, "Validation information");
  const validateFire =
    useNfirsGetData<ApsModels.INfirsValidationModuleOutputDto>(async () => {
      return await ApsServices.http.nfirsValidation.validateFire(reportId);
    }, "Validation information");
  const validateStructureFire =
    useNfirsGetData<ApsModels.INfirsValidationModuleOutputDto>(async () => {
      return await ApsServices.http.nfirsValidation.validateStructureFire(
        reportId
      );
    }, "Validation information");
  const validateHazmat =
    useNfirsGetData<ApsModels.INfirsValidationModuleOutputDto>(async () => {
      return await ApsServices.http.nfirsValidation.validateHazmat(reportId);
    }, "Validation information");
  const validateArson =
    useNfirsGetData<ApsModels.INfirsValidationModuleOutputDto>(async () => {
      return await ApsServices.http.nfirsValidation.validateArson(reportId);
    }, "Validation information");
  const validateWildland =
    useNfirsGetData<ApsModels.INfirsValidationModuleOutputDto>(async () => {
      return await ApsServices.http.nfirsValidation.validateWildland(reportId);
    }, "Validation information");

  const [openModuleValidation, setOpenModuleValidation] =
    useState<ApsModels.NfirsValidationModulesEnum>();
  const [moduleToOpen, setModuleToOpen] =
    useState<ApsModels.INfirsValidationModuleOutputDto>();

  useEffect(() => {
    const list = [
      validateBasic.data,
      validateApparatus.data,
      validateFire.data,
      validateStructureFire.data,
      validateHazmat.data,
      validateArson.data,
      validateWildland.data,
    ];

    nfirsStore.setModuleValidationStatus({
      0: validateBasic.status,
      1: validateApparatus.status,
      2: validateFire.status,
      3: validateStructureFire.status,
      4: validateHazmat.status,
      5: validateArson.status,
      6: validateWildland.status,
    });

    if (validation?.data) {
      const copy = commonService.deepCloneJsonObject(validation.data);

      //Update Existing General Validation
      copy.nfirsVerificationModuleOutputDtos?.forEach((data) => {
        const newData = list.find(
          (m) =>
            !!m &&
            m.nfirsValidationModulesEnum === data.nfirsValidationModulesEnum
        );
        if (newData) {
          data.nfirsVerificationTabOutputDtos =
            newData.nfirsVerificationTabOutputDtos;
          data.valid = newData.valid;
        }
      });

      const newData = list.find(
        (m) =>
          !!m &&
          openModuleValidation !== undefined &&
          openModuleValidation === m.nfirsValidationModulesEnum
      );

      if (newData) {
        if (
          //Insert validation data if missing
          !copy.nfirsVerificationModuleOutputDtos.find(
            (d) =>
              d.nfirsValidationModulesEnum ===
              newData.nfirsValidationModulesEnum
          )
        ) {
          copy.nfirsVerificationModuleOutputDtos.push({
            ...newData,
          });
        }

        //Notify or open dialog
        if (newData.valid) {
          setModuleToOpen(undefined);
          setOpenModuleValidation(undefined);
          toastStore.showToast(
            `All data in ${currentSection?.name} tabs are valid.`,
            "success"
          );
        } else {
          setModuleToOpen(newData);
        }
      }

      validation.setData(copy);
      nfirsStore.setNfirsValidations(copy);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validateBasic.status,
    validateApparatus.status,
    validateFire.status,
    validateStructureFire.status,
    validateHazmat.status,
    validateArson.status,
    validateWildland.status,
    openModuleValidation,
  ]);

  const validateModule = async (
    sec: ApsModels.NfirsValidationModulesEnum,
    showDialog = false
  ) => {
    if (showDialog) {
      const secName = sections.find((s) => s.sectionEnum === sec)?.name || "";
      if (nfirsStore.unsavedTabs[secName].length > 0) {
        setSaveSpecificModule(sec);
        return;
      }
    }
    if (nfirsStore.preventNextModuleValidation) {
      nfirsStore.setPreventNextModuleValidation(false);
    } else {
      await doValidateModule(sec, showDialog);
    }
  };

  const doValidateModule = async (
    sec: ApsModels.NfirsValidationModulesEnum,
    showDialog = false
  ) => {
    if (sec === ApsModels.NfirsValidationModulesEnum.Basic) {
      await validateBasic.getData();
    } else if (
      sec === ApsModels.NfirsValidationModulesEnum.ApparatusPersonnel
    ) {
      await validateApparatus.getData();
    } else if (sec === ApsModels.NfirsValidationModulesEnum.Fire) {
      await validateFire.getData();
    } else if (sec === ApsModels.NfirsValidationModulesEnum.StructureFire) {
      await validateStructureFire.getData();
    } else if (sec === ApsModels.NfirsValidationModulesEnum.Hazmat) {
      await validateHazmat.getData();
    } else if (sec === ApsModels.NfirsValidationModulesEnum.Arson) {
      await validateArson.getData();
    } else if (sec === ApsModels.NfirsValidationModulesEnum.Wildland) {
      await validateWildland.getData();
    }
    if (showDialog) {
      setOpenModuleValidation(sec);
    }
  };

  const [lockReport, setLockReport] = useState(false);
  const [lockingReport, setLockingReport] = useState<string>();
  const [openAllValidation, setOpenAllValidation] = useState(false);
  const [openChangeLog, setOpenChangLog] = useState(false);
  const [savingAll, setSavingAll] = useState<string>(); //Set Message if Saving...

  const doLockIncident = async (action: ApsModels.NfirsLoggingActionEnum) => {
    if (header.data && validation.data) {
      console.log("Lock Incident...");
      setLockingReport("Locking Incident...");
      await ApsServices.http.nfirsReport
        .lock({
          id: null as any,
          reportId: header.data.id,
          concurrencyToken: validation.data.reportConcurrencyToken,
          action: action,
        })
        .then((data) => {
          props.history.push("/nfirs" + commonService.doneUrls.submitted);
          toastStore.showToast("Incident Locked", "success");
        })
        .catch((error) => {
          toastStore.showError("Failed Locking Incident", error);
          setLockingReport(undefined);
        });
    }
  };

  useEffect(() => {
    if (
      openAllValidation &&
      validation.status === NfirsFetchStatus.Complete &&
      validation.data?.valid
    ) {
      doLockIncident(ApsModels.NfirsLoggingActionEnum.Lock);
    }
    nfirsStore.setNfirsValidations(validation.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.status, openAllValidation]);

  useEffect(() => {
    if (lockReport) {
      setSavingAll("Saving...");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockReport]);

  const [saveSpecificModule, setSaveSpecificModule] =
    useState<ApsModels.NfirsValidationModulesEnum>();

  useEffect(() => {
    if (saveSpecificModule !== undefined) {
      setSavingAll("Saving...");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSpecificModule]);

  useEffect(() => {
    if (!savingAll) {
      setSaveSpecificModule(undefined);
      //do nothing...
      return;
    }
    const specificModule =
      sections.find((s) => s.sectionEnum === saveSpecificModule)?.name || "";

    if (savingAll === "Saving..." || specificModule) {
      let skipNext = false;
      sections
        .filter((s) => !specificModule || s.sectionEnum === saveSpecificModule)
        .forEach((sec) => {
          if (skipNext) {
            //skip, wait for the current to finish
            return;
          }
          if (
            sec.visible &&
            sec.loaded &&
            nfirsStore.unsavedTabs[sec.name]?.length > 0
          ) {
            skipNext = true;
            const tab = nfirsStore.unsavedTabs[sec.name][0];
            setSavingAll(`Saving ${sec.name} / ${tab}...`);
            setTimeout(() => {
              nfirsStore.setCurrentTabForSaveAll(sec.name, tab);
            }, 100);
          }
        });
      if (!skipNext) {
        if (saveSpecificModule !== undefined) {
          setSavingAll(undefined);
          setSaveSpecificModule(undefined);
          nfirsStore.setPreventNextModuleValidation(true);
          doValidateModule(saveSpecificModule, true);
          return;
        }
        if (lockReport) {
          const startValidate = async () => {
            await validation.getData();
            setOpenAllValidation(true);
          };
          startValidate();
          setSavingAll(undefined);
          setLockReport(false);
        } else {
          //no changes...
          props.history.push("/nfirs" + commonService.doneUrls.submitted);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingAll]);

  const showGlobalSpinner = () => {
    return (
      (!isManualValidation &&
        validation.status !== NfirsFetchStatus.Complete &&
        !!validation.data) ||
      !!savingAll ||
      !!lockingReport
    );
  };

  const gotoTab = useGotoValidatedTab();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      nfirsStore.setGotoValidatedTab(undefined);
      return;
    }
    if (gotoTab !== undefined) {
      const sec = sections.find((s) => s.tabEnums.indexOf(gotoTab) > -1);
      nfirsStore.setMoveTabSection({
        next: {
          section: sec?.name || "Basic",
          tab: sec?.tabs[sec?.tabEnums.indexOf(gotoTab)] || "Authority",
        },
        prev: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gotoTab]);

  return (
    <>
      {openChangeLog && (
        <NFIRSChangeLogDialog
          onClose={(data) => {
            setOpenChangLog(false);
          }}
          data={{
            reportId: reportId,
          }}
          onChange={(data) => {
            setOpenChangLog(false);

            if (data?.tab) {
              const sec = sections.find(
                (s) => s.tabEnums.indexOf(data.tab as any) > -1
              );
              nfirsStore.setMoveTabSection({
                next: {
                  section: sec?.name || "Basic",
                  tab:
                    sec?.tabs[sec?.tabEnums.indexOf(data.tab)] || "Authority",
                },
                prev: null,
              });
            }
          }}
        ></NFIRSChangeLogDialog>
      )}
      {openAllValidation &&
        validation.data &&
        !validation.data.valid &&
        validation.status === NfirsFetchStatus.Complete && (
          <NFIRSAllValidationDialog
            onClose={(data) => {
              setOpenAllValidation(false);
              setLockReport(false);
              nfirsStore.updateCurrentTabForSaveAll();
              // if (data) {
              //   doLockIncident(
              //     ApsModels.NfirsLoggingActionEnum.LockWithInvalid
              //   );
              // }
            }}
            data={{
              reportId: reportId,
              validations: validation.data,
              tabNames: tabNames?.data || [],
            }}
            onChange={(data) => {
              setOpenAllValidation(false);

              if (data.selected) {
                const sec = sections.find(
                  (s) => s.sectionEnum === data.selected?.section
                );
                nfirsStore.setMoveTabSection({
                  next: {
                    section: sec?.name || "Basic",
                    tab:
                      sec?.tabs[sec?.tabEnums.indexOf(data.selected.tab)] ||
                      "Authority",
                  },
                  prev: null,
                });
              }
            }}
          ></NFIRSAllValidationDialog>
        )}
      {!!moduleToOpen && currentSection && (
        <NFIRSModuleValidationDialog
          onClose={() => {
            setModuleToOpen(undefined);
            setOpenModuleValidation(undefined);
            setLockReport(false);
            nfirsStore.updateCurrentTabForSaveAll();
          }}
          data={{
            reportId: reportId,
            validations: moduleToOpen,
            tabNames: tabNames?.data || [],
            section: currentSection.name,
          }}
        ></NFIRSModuleValidationDialog>
      )}
      {showGlobalSpinner() && (
        <CommonSpinner
          overlay={true}
          message={savingAll || lockingReport}
        ></CommonSpinner>
      )}
      <div
        className="flex flex-1 flex-column"
        style={{ marginBottom: "-60px" }}
      >
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  EDIT NFIRS
                </span>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => setOpenChangLog(true)}
                >
                  Changes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setSavingAll("Saving...");
                  }}
                >
                  Close Incident
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={async () => {
                    //onNextTab(true);
                    if (lockReport) {
                      setLockReport(false);
                      setTimeout(() => {
                        setLockReport(true);
                      }, 100);
                    } else {
                      setLockReport(true);
                    }
                  }}
                >
                  Lock Incident
                </button>
              </div>
            </div>
          </div>

          <div className="flex-0 nfirs-header">
            <div className="row">
              <div className="col-sm-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <label>Case Number</label>
                    <div>{header.data?.number}</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <label>Event Number</label>
                    <div>{header.data?.eventNumber}</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <label>Date</label>
                    <div>
                      {header.data?.date && (
                        <>
                          {moment
                            .utc(header.data.date)
                            .local()
                            .format("MMM DD, YYYY")}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row h-full flex-1">
            <div className="col-12 flex flex-1 nfirs-main-container">
              <div className="nfirs-left-nav">
                {header.status !== NfirsFetchStatus.Complete && (
                  <div className="card">
                    <div className="card-body">
                      <CommonSpinner></CommonSpinner>
                      <div>Loading...</div>
                    </div>
                  </div>
                )}
                {header.status === NfirsFetchStatus.Complete &&
                  sections
                    .filter((s) => s.visible)
                    .map((section) => (
                      <div
                        className={`card ${getSectionClassName(section)}`}
                        key={section.name}
                        onClick={() => {
                          nfirsStore.setTabChangedOnClick(
                            nfirsStore.activeTabSection.section,
                            nfirsStore.activeTabSection.tab
                          );
                          nfirsStore.resetMoveTabSection();
                          moveSection(section);
                        }}
                      >
                        <div className="card-body">
                          {validation.status !== NfirsFetchStatus.Complete && (
                            <div>
                              <small>Validating...</small>
                            </div>
                          )}
                          {validation.status === NfirsFetchStatus.Complete && (
                            <i
                              className={`fa ${getSectionIconClassName(
                                section
                              )}`}
                            ></i>
                          )}
                          <div>
                            <SectionLabel name={section.name}></SectionLabel>

                            {/* <div>
                            {canAcccessModules[section.sectionEnum]
                              ? "YES"
                              : "NO"}
                          </div> */}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              <div className="flex flex-1 flex-col">
                <div className="card">
                  <div className="card-body flex flex-col">
                    {!header.data && (
                      <section>
                        <div className="card-body">
                          <CommonSpinner></CommonSpinner>
                          <div className="text-center">Loading...</div>
                        </div>
                      </section>
                    )}

                    {!!header.data &&
                      sections
                        .filter((s) => s.visible && s.loaded)
                        .map((stage) => (
                          <div
                            key={stage.name}
                            className={`flex-1 ${
                              stage.current ? "" : "display-none"
                            }`}
                          >
                            <>
                              {stage.name === "Basic" && (
                                <NFIRSSectionBasic
                                  id={reportId}
                                  header={header.data}
                                  activeTab={stage.activeTab || ""}
                                  onTabChanged={(tab) => {
                                    stage.activeTab = tab;
                                    nfirsStore.resetMoveTabSection();
                                    moveSection(stage);
                                  }}
                                  onVerify={() => {
                                    validateModule(
                                      ApsModels.NfirsValidationModulesEnum
                                        .Basic,
                                      true
                                    );
                                  }}
                                ></NFIRSSectionBasic>
                              )}
                              {stage.name === "Apparatus/Personnel" && (
                                <NFIRSSectionApparatus
                                  id={reportId}
                                  header={header.data}
                                  activeTab={stage.activeTab || ""}
                                  onTabChanged={(tab) => {
                                    stage.activeTab = tab;
                                    nfirsStore.resetMoveTabSection();
                                    moveSection(stage);
                                  }}
                                  onVerify={() => {
                                    validateModule(
                                      ApsModels.NfirsValidationModulesEnum
                                        .ApparatusPersonnel,
                                      true
                                    );
                                  }}
                                ></NFIRSSectionApparatus>
                              )}
                              {stage.name === "Fire" && (
                                <NFIRSSectionFire
                                  id={reportId}
                                  header={header.data}
                                  activeTab={stage.activeTab || ""}
                                  onTabChanged={(tab) => {
                                    stage.activeTab = tab;
                                    nfirsStore.resetMoveTabSection();
                                    moveSection(stage);
                                  }}
                                  onVerify={() => {
                                    validateModule(
                                      ApsModels.NfirsValidationModulesEnum.Fire,
                                      true
                                    );
                                  }}
                                ></NFIRSSectionFire>
                              )}
                              {stage.name === "Structure Fire" && (
                                <NFIRSSectionStructureFires
                                  id={reportId}
                                  header={header.data}
                                  activeTab={stage.activeTab || ""}
                                  onTabChanged={(tab) => {
                                    stage.activeTab = tab;
                                    nfirsStore.resetMoveTabSection();
                                    moveSection(stage);
                                  }}
                                  onVerify={() => {
                                    validateModule(
                                      ApsModels.NfirsValidationModulesEnum
                                        .StructureFire,
                                      true
                                    );
                                  }}
                                ></NFIRSSectionStructureFires>
                              )}
                              {stage.name === "HAZMAT" && (
                                <NFIRSSectionHazmat
                                  id={reportId}
                                  header={header.data}
                                  activeTab={stage.activeTab || ""}
                                  onTabChanged={(tab) => {
                                    stage.activeTab = tab;
                                    nfirsStore.resetMoveTabSection();
                                    moveSection(stage);
                                  }}
                                  onVerify={() => {
                                    validateModule(
                                      ApsModels.NfirsValidationModulesEnum
                                        .Hazmat,
                                      true
                                    );
                                  }}
                                ></NFIRSSectionHazmat>
                              )}
                              {stage.name === "Arson" && (
                                <NFIRSSectionArson
                                  id={reportId}
                                  header={header.data}
                                  activeTab={stage.activeTab || ""}
                                  onTabChanged={(tab) => {
                                    stage.activeTab = tab;
                                    nfirsStore.resetMoveTabSection();
                                    moveSection(stage);
                                  }}
                                  onVerify={() => {
                                    validateModule(
                                      ApsModels.NfirsValidationModulesEnum
                                        .Arson,
                                      true
                                    );
                                  }}
                                ></NFIRSSectionArson>
                              )}
                              {stage.name === "Wildland Fire" && (
                                <NFIRSSectionWildlandFire
                                  id={reportId}
                                  header={header.data}
                                  activeTab={stage.activeTab || ""}
                                  onTabChanged={(tab) => {
                                    stage.activeTab = tab;
                                    nfirsStore.resetMoveTabSection();
                                    moveSection(stage);
                                  }}
                                  onVerify={() => {
                                    validateModule(
                                      ApsModels.NfirsValidationModulesEnum
                                        .Wildland,
                                      true
                                    );
                                  }}
                                ></NFIRSSectionWildlandFire>
                              )}
                            </>
                          </div>
                        ))}
                    {!!header.data && (
                      <div className="text-center pt-4 nfirs-nav">
                        <button
                          type="button"
                          className="btn btn-secondary mr-2"
                          style={{ minWidth: "100px" }}
                          disabled={saving || disableBackButton()}
                          onClick={() => {
                            onBackTab();
                          }}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ minWidth: "100px" }}
                          disabled={saving} // || disableNextButton()}
                          onClick={() => {
                            if (isLastTabReached()) {
                              setSavingAll("Saving...");
                            } else {
                              onNextTab();
                            }
                          }}
                        >
                          {saving
                            ? "Saving..."
                            : isLastTabReached()
                            ? "Close Incident"
                            : "Next"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NFIRSEntry;
