import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { UserService } from "../../../services/UserService";
import toastStore from "../../stores/ToastStore";
import { usePaging } from "../Common/Pagination";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFile } from "@fortawesome/free-solid-svg-icons";
import CourseRecordImportDialog from "./CourseRecordImportDialog";
import ApsServices from "../../services";
import ApsModels from "../../models";
import commonService from "../../services/CommonService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { RouteComponentProps } from "react-router-dom";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import { useGridFilters } from "../../stores/SystemStore";
import LoadMoreIndicator from "../Common/LoadMoreIndicator";

function CourseRecordList(
  props: RouteComponentProps<any> & { employeeId?: any; asChild?: boolean }
) {
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentId > 0) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [list, setList] = useState<ApsModels.ICredTakenCourseOutputGridDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridFilters.init(params);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    // if (gridApi && gridColumnApi && list) {
    //   setTimeout(() => {
    //     gridApi.sizeColumnsToFit();
    //     let allColumnIds: any[] = [];
    //     gridColumnApi.getAllColumns()?.forEach(function (column: any) {
    //       allColumnIds.push(column.colId);
    //     });
    //     gridColumnApi.autoSizeColumns(allColumnIds, false);
    //   }, 500);
    // }
  };

  const StatusCellComponent = (rowProps: {
    data: ApsModels.ICredTakenCourseGridItemOutputDto;
  }) => {
    if (
      rowProps.data.courseTakenStatusEnum ===
      ApsModels.CourseTakenStatusEnum.Assigned
    ) {
      return <span className="alert-chip alert alert-secondary">Assigned</span>;
    }
    if (
      rowProps.data.courseTakenStatusEnum ===
      ApsModels.CourseTakenStatusEnum.Completed
    ) {
      return <span className="alert-chip alert alert-success">Completed</span>;
    }
    if (
      rowProps.data.courseTakenStatusEnum ===
      ApsModels.CourseTakenStatusEnum.Pending
    ) {
      return <span className="alert-chip alert alert-dark">Pending</span>;
    }
    if (
      rowProps.data.courseTakenStatusEnum ===
      ApsModels.CourseTakenStatusEnum.Rejected
    ) {
      return <span className="alert-chip alert alert-danger">Rejected</span>;
    }
    if (
      rowProps.data.courseTakenStatusEnum ===
      ApsModels.CourseTakenStatusEnum.Validate
    ) {
      return <span className="alert-chip alert alert-primary">Validate</span>;
    }
    return "Unknown";
  };

  const AttachmentCellComponent = (rowProps: {
    data: ApsModels.ICredTakenCourseGridItemOutputDto;
  }) => {
    if (rowProps.data.hasAttachment) {
      return <FontAwesomeIcon icon={faFile} />;
    }
    return null;
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ICredTakenCourseGridItemOutputDto;
  }) => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-outlined text-primary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.history.push(`/courses/${rowProps.data.id}`);
        }}
      >
        <FontAwesomeIcon icon={faEye} />
      </button>
    );
  };

  const NameCellComponent = (rowProps: {
    data: ApsModels.ICredTakenCourseGridItemOutputDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.history.push(`/courses/${rowProps.data.id}`);
        }}
      >
        {rowProps.data.courseName}
      </span>
    );
  };

  const CourseNumberCellComponent = (rowProps: {
    data: ApsModels.ICredTakenCourseGridItemOutputDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.history.push(`/courses/${rowProps.data.id}`);
        }}
      >
        {rowProps.data.courseNumber}
      </span>
    );
  };

  const EmployeeNameCellComponent = (rowProps: {
    data: ApsModels.ICredTakenCourseGridItemOutputDto;
  }) => {
    if (claims.credentials.canAccess) {
      return (
        <span
          className="txt-primary pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.history.push(`/credentials/employee/${rowProps.data.userId}`);
          }}
        >
          {rowProps.data.employeeName}
        </span>
      );
    }

    return <span>{rowProps.data.employeeName}</span>;
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          return true;
        },
        field: "_selected",
        headerName: "",
        width: 10,
        ...commonService.agGridHelpers.checkboxColDef,
      },
      {
        field: "id",
        headerName: "Course ID",
      },
      {
        field: "lastName",
        headerName: "Last Name",
      },
      {
        field: "firstName",
        headerName: "First Name",
      },
      {
        field: "courseName",
        headerName: "Course Name",
        cellRenderer: "nameCellComponent",
      },
      {
        field: "courseNumber",
        headerName: "Course Code",
        cellRenderer: "courseNumberCellComponent",
      },
      {
        field: "categoryName",
        headerName: "Course Category",
      },
      {
        field: "library",
        headerName: "Library",
      },
      {
        field: "_takenFromDate",
        headerName: "Started Date",
        ...commonService.agGridHelpers.dateValueFormatter("takenFromDate"),
      },
      {
        field: "_dateUpdated",
        headerName: "Last Modified Date",
        ...commonService.agGridHelpers.dateValueFormatter("dateUpdated"),
      },
      {
        field: "_status",
        headerName: "Status",
        cellRenderer: "statusCellComponent",
      },
      {
        field: "hours",
        headerName: "Course Hours",
        hide: true,
      },
      {
        field: "hasAttachment",
        headerName: "Attachment",
        cellRenderer: "attachmentCellComponent",
        hide: true,
      },
      { field: "groups", headerName: "Groups", hide: true },
      { field: "rank", headerName: "Rank", hide: true },
      { field: "employeeId", headerName: "EID", hide: true },
      { field: "supervisor", headerName: "Supervisor", hide: true },
      { field: "email", headerName: "Email Address", hide: true },

      {
        field: "courseCharacteristics",
        headerName: "Characteristics",
        hide: true,
      },
      { field: "courseDescription", headerName: "Description", hide: true },
      { field: "courseObjective", headerName: "Objectives", hide: true },
      { field: "location", headerName: "Location", hide: true },
      { field: "instructor", headerName: "Instructor", hide: true },

      { field: "providerNumber", headerName: "CE Provider Number", hide: true },
      { field: "isoTrainingType", headerName: "ISO Training Type", hide: true },

      {
        field: "_takenToDate",
        headerName: "Completed Date",
        ...commonService.agGridHelpers.dateValueFormatter("takenToDate"),
        hide: true,
      },
      {
        field: "drillReportEligibility",
        headerName: "Reimbursement Eligibility",
        hide: true,
      },
      { field: "jacCode", headerName: "JAC Code", hide: true },
      { field: "agencyJacId", headerName: "Agency JAC ID", hide: true },
      { field: "userJacId", headerName: "User JAC ID", hide: true },
      {
        field: "_todo_isaCourse",
        headerName: "ISA Details of Course",
        hide: true,
      },

      { field: "updatedByName", headerName: "Last Modified By", hide: true },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "viewButtonCellComponent",
        width: 50,
        ...commonService.agGridHelpers.actionColPinnedDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      minWidth: 100,
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      nameCellComponent: NameCellComponent,
      courseNumberCellComponent: CourseNumberCellComponent,
      employeeNameCellComponent: EmployeeNameCellComponent,
      statusCellComponent: StatusCellComponent,
      attachmentCellComponent: AttachmentCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ICredTakenCourseGridItemOutputDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState<{
    name: string;
    id: number;
  }>();

  const statuses = useNfirsGetData(
    async () =>
      ApsServices.http.credCommon.credGenericEnumLookUp(
        "CourseTakenStatusEnum"
      ),
    "Statuses"
  );

  useEffect(() => {
    statuses.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isMyReportsOnly, setIsMyReportsOnly] = useState(true);
  const gridFilters = useGridFilters("courses-table", gridApi, (others) => {
    if (others) {
      setIsMyReportsOnly(
        others.isMyReportsOnly === undefined ? true : others.isMyReportsOnly
      );
      if (others.userName && !props.employeeId) {
        setMember({
          id: others.userId,
          name: others.userName,
        });
      }
    }
  });

  const [loadingMore, setLoadingMore] = useState(false);
  const [pageToLoad, setPageToLoad] = useState(1);

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();
    setLoading(true);
    setPageToLoad(1);
    const userId = Number(props.employeeId) || member?.id || (undefined as any);
    await ApsServices.http.credCourseRecord //paging.page, paging.pageSize,
      .listCourseRecords(1, commonService.gridPageSize.firstPage, {
        userId: userId,
        isMyReportsOnly: isMyReportsOnly,
      })
      .then((data) => {
        setList(data);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        }

        gridFilters.saveOthers({
          isMyReportsOnly,
          userId: userId,
          userName: member?.name,
        });
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Course Records", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);
      });
  };

  const loadMore = () => {
    if (list && list.totalRecords > list.credTakenCourseOuputDtos?.length) {
      setLoadingMore(true);
      setLoading(false);

      let pageNum = pageToLoad + 1;
      if (
        commonService.gridPageSize.firstPage <
          commonService.gridPageSize.otherPages &&
        list.credTakenCourseOuputDtos?.length ===
          commonService.gridPageSize.firstPage
      ) {
        pageNum = 1;
      }

      setPageToLoad(pageNum);
      const userId =
        Number(props.employeeId) || member?.id || (undefined as any);
      ApsServices.http.credCourseRecord
        .listCourseRecords(pageNum, commonService.gridPageSize.otherPages, {
          userId: userId,
          isMyReportsOnly: isMyReportsOnly,
        })
        .then((data) => {
          if (!isLoading && data.credTakenCourseOuputDtos?.length > 0) {
            setList((prev) => {
              return {
                totalRecords: prev?.totalRecords || data.totalRecords,
                credTakenCourseOuputDtos:
                  pageNum === 1
                    ? data.credTakenCourseOuputDtos
                    : [
                        ...(prev?.credTakenCourseOuputDtos || []),
                        ...data.credTakenCourseOuputDtos,
                      ],
              };
            });
          }
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Course Records", err);
        })
        .finally(() => {
          setLoadingMore(false);
          setLoading(false);
        });
    } else {
      gridFilters.reApplyFilters(); //need to reapply filter after adding more data
      toggleColSizes();
      setLoadingMore(false);
      setLoading(false);
    }
  };

  const [rowsSelectedCount, setRowSelectedCount] = useState(0);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (loading || loadingMore) {
      return;
    }
    if (list?.totalRecords) {
      gridApi && gridApi.hideOverlay();
    }
    setGridState({
      ...gridState,
      rowData: (list?.credTakenCourseOuputDtos || []).map((row) => {
        return {
          ...row,
          lastName: row.employeeName.split(",")[0] || "",
          firstName: row.employeeName.split(",")[1] || "",
          _takenToDate: commonService.agGridHelpers.toExportDate(
            row.takenToDate
          ),
          _takenFromDate: commonService.agGridHelpers.toExportDate(
            row.takenFromDate
          ),
          _dateUpdated: commonService.agGridHelpers.toExportDate(
            row.dateUpdated
          ),
          _status: (() => {
            if (
              row.courseTakenStatusEnum ===
              ApsModels.CourseTakenStatusEnum.Assigned
            ) {
              return "Assigned";
            }
            if (
              row.courseTakenStatusEnum ===
              ApsModels.CourseTakenStatusEnum.Completed
            ) {
              return "Completed";
            }
            if (
              row.courseTakenStatusEnum ===
              ApsModels.CourseTakenStatusEnum.Pending
            ) {
              return "Pending";
            }
            if (
              row.courseTakenStatusEnum ===
              ApsModels.CourseTakenStatusEnum.Rejected
            ) {
              return "Rejected";
            }
            if (
              row.courseTakenStatusEnum ===
              ApsModels.CourseTakenStatusEnum.Validate
            ) {
              return "Validate";
            }
            return "";
          })(),
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, loadingMore]);

  useEffect(() => {
    if (
      gridApi &&
      gridFilters.ready &&
      statuses.status === NfirsFetchStatus.Complete
    ) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gridApi,
    member,
    isMyReportsOnly,
    paging,
    statuses.status,
    gridFilters.ready,
  ]);

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    return gridApi.getSelectedRows().filter((row) => {
      return (
        !isAnyFilterPresent ||
        !!filteredRows.find((r: any) => r.data.id === row.id)
      );
    });
  };

  const [showImport, setShowImport] = useState(false);
  const [claims] = useState(commonService.friendlyClaims);
  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setUsers([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await ApsServices.http.credCommon
      .coursesTypeAheadUser({
        search: query,
        isAnd: false,
        recordCount: 10,
        shiftId: null as any,
        rankId: null as any,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));

        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const [exporting, setExporting] = useState(false);
  const startExport = async () => {
    gridFilters.exportCSV();

    /** DISABLE BACKEND EXPORT 
    setExporting(true);
    await ApsServices.http.credCourseRecord
      .extractCourseRecords({
        ids: getSelectedRows().map((r) => r.id),
      })
      .then((data) => {
        const file = commonService.b64toBlob(data.fileContents, "text/plain");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((error) => {
        toastStore.showError("Failed Exporting Course Records", error);
      })
      .finally(() => {
        setExporting(false);
      });
      **/
  };

  const canAddCourse = () => {
    if (claims.course.canAddAll) {
      return true;
    }
    if (claims.course.canAccess) {
      if (props.asChild) {
        return props.employeeId === commonService.userInfo.id;
      } else {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {showImport && (
        <CourseRecordImportDialog
          onClose={(e) => {
            setShowImport(false);
          }}
        ></CourseRecordImportDialog>
      )}

      <div
        className="flex flex-1 flex-column course-records-list"
        style={
          props.asChild ? {} : { marginBottom: "-60px", position: "relative" }
        }
      >
        <div
          className="container-fluid flex-card-container"
          style={props.asChild ? { padding: 0 } : {}}
        >
          <div className="flex-0">
            {!!props.asChild && (
              <div className="px-4 pt-4 bg-white text-uppercase">
                <strong>Courses</strong>
              </div>
            )}
            {!props.asChild && (
              <div className="headerControls">
                <div>
                  <span className="h4 mb-0 font-size-18 text-uppercase">
                    Courses
                  </span>
                </div>
                <div></div>
              </div>
            )}
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div
                    className="flex flex-row mb-3"
                    style={{ flexWrap: "wrap", gap: "10px" }}
                  >
                    <div
                      className="flex-1 flex"
                      style={{
                        flexWrap: "wrap",
                        gap: "10px",
                        alignItems: "center",
                        minWidth: "250px",
                      }}
                    >
                      <div className="mr-2">
                        {/* {!claims.course.canAddAll && (
                          <button
                            className="btn btn-outline-secondary"
                            onClick={(e) => {
                              startExport();
                            }}
                            disabled={rowsSelectedCount === 0 || exporting}
                          >
                            {exporting
                              ? `Exporting...`
                              : `Export (${rowsSelectedCount})`}
                          </button>
                        )} */}
                        <Dropdown drop="down">
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id={`ddMenu-${gridFilters.props._key}Picker`}
                            disabled={loading}
                          >
                            {exporting ? "Exporting..." : "Export or Import"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                gridFilters.exportAllCSV();
                              }}
                            >
                              Export All Data
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                startExport();
                              }}
                            >
                              Export Visible Data
                            </Dropdown.Item>
                            {claims.course.canAddAll && (
                              <Dropdown.Item
                                onClick={(e) => {
                                  setShowImport(true);
                                }}
                              >
                                Import CSV
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      {claims.course.canViewAll && !props.asChild && (
                        <>
                          <div>
                            <strong>Filter</strong>
                          </div>
                          <div style={{ minWidth: "200px" }}>
                            <AsyncTypeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              onSearch={handleSearch}
                              onChange={(data) => {
                                if (data && data.length > 0) {
                                  setMember({
                                    id: data[0].id,
                                    name: data[0].name,
                                  });
                                  setUsers(data);
                                  (ref.current as any)?.clear();
                                }
                              }}
                              searchText={"Searching..."}
                              isLoading={isLoading}
                              options={users}
                              placeholder="Search User"
                              minLength={1}
                              delay={500}
                              useCache={false}
                              ref={ref}
                              //disabled={loadingMore}
                            />
                          </div>
                          {!!member && (
                            <div className="alert alert-secondary m-0 p-2">
                              {member?.name}{" "}
                              <i
                                className="fa fa-times ml-2 pointer"
                                onClick={() => {
                                  setMember(undefined);
                                }}
                              ></i>
                            </div>
                          )}
                        </>
                      )}

                      {claims.course.canViewAll && (
                        <div className="flex flex-center">
                          <strong className="pr-2">View</strong>
                          <Dropdown drop="down">
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdownCourseViewFilter"
                              //disabled={loadingMore}
                            >
                              {isMyReportsOnly ? "My Courses" : "All Courses"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu align="right">
                              <Dropdown.Item
                                onClick={(e) => {
                                  setIsMyReportsOnly(true);
                                }}
                              >
                                My Courses
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) => {
                                  setIsMyReportsOnly(false);
                                }}
                              >
                                All Courses
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}

                      <div>
                        {/* <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={(e) => {
                            setMember(undefined);
                            setIsMyReportsOnly(true);
                            gridFilters.reset();
                            toggleColSizes();
                          }}
                          disabled={loadingMore}
                        >
                          Reset
                        </button> */}
                      </div>
                    </div>
                    <div>
                      {canAddCourse() && (
                        <Button
                          variant="primary"
                          type="button"
                          onClick={(e) => {
                            props.history.push(`/courses/new`);
                          }}
                        >
                          Add Course
                        </Button>
                      )}
                    </div>
                  </div>

                  <div style={{ width: "100%", height: "100%" }}>
                    <div
                      id="myGrid"
                      style={{
                        height: "100%",
                        minHeight: "300px",
                      }}
                      className="ag-theme-alpine flex-1"
                    >
                      <AgGridReact
                        columnDefs={gridState.columnDefs}
                        defaultColDef={gridState.defaultColDef}
                        autoGroupColumnDef={gridState.autoGroupColumnDef}
                        enableRangeSelection={true}
                        animateRows={true}
                        onGridReady={onGridReady}
                        rowSelection={"multiple"}
                        rowMultiSelectWithClick={false}
                        suppressRowDeselection={true}
                        suppressRowClickSelection={true}
                        frameworkComponents={gridState.frameworkComponents}
                        rowData={gridState.rowData || []}
                        onRowDoubleClicked={(event) => {
                          props.history.push(`/courses/${event.data.id}`);
                        }}
                        overlayLoadingTemplate={
                          gridState.overlayLoadingTemplate
                        }
                        overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
                        onRowSelected={() => {
                          setRowSelectedCount(getSelectedRows().length);
                        }}
                        onRowDataChanged={() => {
                          setRowSelectedCount(getSelectedRows().length);
                        }}
                        onFilterChanged={() => {
                          setRowSelectedCount(getSelectedRows().length);
                        }}
                        {...gridFilters.props}
                      />
                    </div>
                  </div>
                  <LoadMoreIndicator
                    loadingMore={loadingMore}
                    currentCount={list?.credTakenCourseOuputDtos?.length}
                    totalExpected={list?.totalRecords}
                  ></LoadMoreIndicator>
                  {/* <Pagination
                    length={list?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="Records"
                    sizes={[10, 20, 50, 100]}
                  ></Pagination> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseRecordList;
