import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSStructureFireAesService {
  get = async (reportId: any) => {
    const url = `/api/NfirsStructureFireAes/${reportId}`;
    return fetcher.get<ApsModels.INfirsStructureFireAesOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsStructureFireAesInputDto) => {
    const url = `/api/NfirsStructureFireAes`;
    return fetcher.post<ApsModels.INfirsStructureFireAesOutputDto>(url, data);
  };
}

const nfirsStructureFireAesService = new NFIRSStructureFireAesService();
export default nfirsStructureFireAesService;
