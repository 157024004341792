import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";

function NFIRSHazmatChemical(props: INFIRSTabProps<any>) {
  const saveAction = (): Promise<any> => {
    //do model pre-processing here
    const dataCopy = { ...model.new };
    if (isContainerNone()) {
      dataCopy.estimatedContainerCapacityUnitsEntryId = undefined as any;
      dataCopy.estimatedContainerCapacity = undefined as any;
    }
    return ApsServices.http.nfirsHazmatChemical.update(dataCopy);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsHazmatChemicalInputDto>({
    objectName: "Chemical",
    nfirsSectionTab: "HAZMAT/Chemical",
    promise: saveAction,
    afterSave: (data) => {
      const dataCopy = { ...model.new };
      if (isContainerNone()) {
        dataCopy.estimatedContainerCapacityUnitsEntryId = undefined as any;
        dataCopy.estimatedContainerCapacity = undefined as any;
        setValue("estimatedContainerCapacityUnitsEntryId", undefined);
        setValue("estimatedContainerCapacity", undefined);
      }
      initModels({ ...dataCopy, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (newModel: ApsModels.INfirsHazmatChemicalInputDto) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick("HAZMAT", "Chemical", () => {
    triggerSubmitForm();
  });
  useSaveOnNext("HAZMAT", "Chemical", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const [physicalState, setPhysicalState] = useState<
    ApsModels.INfirsPhysicalStateEntryOutputDto[]
  >([]);
  const getPhysicalState = async () => {
    await ApsServices.http.nfirsGenericLookupService
      .getNfirsPhysicalStateEntries()
      .then((rtn) => {
        setPhysicalState(
          rtn.sort(commonService.sortByStringProperty("description"))
        );
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Physical States", err);
        setProgress({ loading: false });
      });
  };

  const unitGroups = [
    {
      id: ApsModels.NfirsUnitTypeSectionEnum.Volume,
      label: "Volume",
    },
    {
      id: ApsModels.NfirsUnitTypeSectionEnum.Weight,
      label: "Weight",
    },
  ];
  const [units, setUnits] = useState<
    ApsModels.INfirsHazmatUnitsEntryOutputDto[]
  >([]);
  const getUnits = async () => {
    await ApsServices.http.nfirsGenericLookupService
      .getNfirsHazmatUnitsEntries()
      .then((rtn) => {
        setUnits(rtn.sort(commonService.sortByStringProperty("description")));
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Units", err);
        setProgress({ loading: false });
      });
  };

  const containerTypes = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getChemicalContainerTypes(false),
    "Chemical Containter Types"
  );
  const [classificationSections, setClassificationSections] = useState<
    ApsModels.ILookupIntDto[]
  >([]);
  const getClassificationSections = async () => {
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp("NfirsHazmatDotClassificationSectionEnum")
      .then((rtn) => {
        setClassificationSections(
          rtn.sort(commonService.sortByNumericProperty("value"))
        );
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Release Info List", err);
        setProgress({ loading: false });
      });
  };

  const [classifications, setClassifications] = useState<
    ApsModels.INfirsHazmatDotClassificationEntryOutputDto[]
  >([]);
  const getClassifications = async () => {
    await ApsServices.http.nfirsGenericLookupService
      .getNfirsHazmatDotClassificationEntries()
      .then((rtn) => {
        setClassifications(
          rtn.sort(commonService.sortByStringProperty("externalId"))
        );
      })
      .catch((err) => {
        toastStore.showError("Failed Getting DOT HAZMAT Classifications", err);
        setProgress({ loading: false });
      });
  };

  const [releaseInfoList, setReleaseInfoList] = useState<
    ApsModels.ILookupIntDto[]
  >([]);
  const getReleaseInfoList = async () => {
    await ApsServices.http.nfirsGenericEnumLookupService
      .nfirsGenericEnumLookUp("NfirsReleasedIntoEnum")
      .then((rtn) => {
        setReleaseInfoList(
          rtn.sort(commonService.sortByNumericProperty("value"))
        );
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Release Info List", err);
        setProgress({ loading: false });
      });
  };

  useEffect(() => {
    setProgress({ loading: true });
    getPhysicalState();
    getUnits();
    containerTypes.getData();
    getClassifications();
    getClassificationSections();
    getReleaseInfoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsHazmatChemical
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Chemical", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      units.length > 0 &&
      physicalState.length > 0 &&
      containerTypes.status === NfirsFetchStatus.Complete &&
      classifications.length > 0 &&
      classificationSections.length > 0 &&
      releaseInfoList.length > 0
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    units,
    physicalState,
    containerTypes.status,
    classifications,
    classificationSections,
    releaseInfoList,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
    setValue,
  } = FgUseForm({
    unNumber: {
      displayName: "UN Number",
      validation: {
        required: false,
        maxLength: 4,
      },
    },
    nfirsHazmatDotClassificationEntryId: {
      displayName: "DOT Hazard Classification",
      validation: {
        required: false,
      },
    },
    casRegistrationNumber: {
      displayName: "CAS Registration Number",
      validation: {
        required: false,
        maxLength: 10,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    name: {
      displayName: "Chemical Name",
      validation: {
        required: false,
        maxLength: 50,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsChemicalContainerTypeEntryId: {
      displayName: "Chemical Continer Type",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    estimatedAmountReleasedUnitsId: {
      displayName: "Unit", //Estimated Amount Release Unit
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    estimatedAmountReleased: {
      displayName: "Estimated Amount Release",
      validation: {
        required: false,
        max: 999999999,
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    estimatedContainerCapacityUnitsEntryId: {
      displayName: "Unit", //Estimated Container Capacity Unit
      validation: {
        required: false,
      },
    },
    estimatedContainerCapacity: {
      displayName: "Estimated Container Capacity",
      validation: {
        required: false,
        max: 999999999,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    nfirsReleasedInto: {
      displayName: "Released Info",
      validation: {
        required: false,
      },
    },
    nfirsPhysicalStateEntryId: {
      displayName: "Physical State When Released",
      validation: {
        required: false,
      },
    },
  });

  const isContainerNone = () => {
    return (
      !!model.new &&
      !!containerTypes?.data?.find(
        (ct) =>
          ct.id === model.new.nfirsChemicalContainerTypeEntryId &&
          ct.externalId === "NN"
      )
    );
  };

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 ">
          <strong>Cause</strong>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div className="">
            <FgInput
              id="unNumber"
              label="UN Number"
              placeHolder="UN Number"
              registeredField={registry.unNumber}
              formState={formState}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  unNumber: data,
                });
              }}
            ></FgInput>
          </div>

          <div className="">
            <FgSelect
              id="nfirsHazmatDotClassificationEntryId"
              label="DOT Hazard Classification"
              selectMessage="Select Classification"
              registeredField={registry.nfirsHazmatDotClassificationEntryId}
              formState={formState}
              groups={classificationSections.map((g) => {
                return {
                  label: g.label,
                  id: g.value,
                };
              })}
              options={classifications.map((o) => {
                return {
                  label: `${o.externalId}. ${o.description}`,
                  value: o.id,
                  groupId: o.nfirsHazmatDotClassificationSection,
                };
              })}
              onChange={(data) =>
                onModelChange({
                  ...model.new,
                  nfirsHazmatDotClassificationEntryId:
                    Number(data) || (null as any),
                })
              }
            ></FgSelect>
          </div>

          <div className="">
            <FgInput
              id="casRegistrationNumber"
              label="CAS Registration Number"
              placeHolder="CAS Registration Number"
              registeredField={registry.casRegistrationNumber}
              formState={formState}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  casRegistrationNumber: data,
                });
              }}
            ></FgInput>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6 mt-3">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <FgInput
                id="name"
                label="Chemical Name"
                placeHolder="Chemical Name"
                registeredField={registry.name}
                formState={formState}
                showRequiredLabel={isOtherModuleDataRequired}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    name: data,
                  });
                }}
              ></FgInput>
            </div>
            <div className="col-sm-12 col-lg-6">
              <label>Container Type</label>
              <AdvancedDropdown
                domId="nfirsChemicalContainerTypeEntryId"
                value={model.new.nfirsChemicalContainerTypeEntryId}
                favoritesHandler={{
                  add: ApsServices.http.nfirsFavorite
                    .addFavoriteChemicalContainerType,
                  remove:
                    ApsServices.http.nfirsFavorite
                      .removeFavoriteChemicalContainerType,
                  afterUpdate: (list: any[]) => containerTypes.setData(list),
                }}
                options={(containerTypes.data || [])
                  .sort(commonService.sortByStringProperty("description"))
                  .map((o) => {
                    return {
                      label: `${o.description} (${o.externalId})`,
                      value: o.id,
                      originalObject: o,
                      isFavorite: o.isFavorite,
                      groupId: 0,
                      subGroupId: 0,
                    };
                  })}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    nfirsChemicalContainerTypeEntryId:
                      Number(data) || (null as any),
                  });
                }}
              ></AdvancedDropdown>
            </div>
          </div>

          <div className={`row ${isContainerNone() ? "display-none" : ""}`}>
            <div className="col-sm-12">
              <label>Estimated Container Capacity</label>
            </div>
            <div className="col-sm-12 col-lg-6">
              <FgSelect
                id="estimatedContainerCapacityUnitsEntryId"
                selectMessage="Select Unit"
                registeredField={
                  registry.estimatedContainerCapacityUnitsEntryId
                }
                formState={formState}
                groups={unitGroups}
                options={units
                  .sort(commonService.sortByStringProperty("externalId"))
                  .map((o) => {
                    return {
                      label: `${o.externalId}. ${o.description}`,
                      value: o.id,
                      groupId: o.nfirsUnitTypeSection,
                    };
                  })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    estimatedContainerCapacityUnitsEntryId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className="col-sm-12 col-lg-6">
              <FgInput
                id="estimatedContainerCapacity"
                placeHolder="Estimated Container Capacity"
                registeredField={registry.estimatedContainerCapacity}
                formState={formState}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    estimatedContainerCapacity: Number(data) || (null as any),
                  });
                }}
                type="number"
              ></FgInput>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <label
                className={isOtherModuleDataRequired ? "required-label" : ""}
              >
                Estimated Amount Released
              </label>
            </div>
            <div className="col-sm-12 col-lg-6">
              <FgSelect
                id="estimatedAmountReleasedUnitsId"
                selectMessage="Select Unit"
                registeredField={registry.estimatedAmountReleasedUnitsId}
                formState={formState}
                groups={unitGroups}
                options={units
                  .sort(commonService.sortByStringProperty("externalId"))
                  .map((o) => {
                    return {
                      label: `${o.externalId}. ${o.description}`,
                      value: o.id,
                      groupId: o.nfirsUnitTypeSection,
                    };
                  })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    estimatedAmountReleasedUnitsId:
                      Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className="col-sm-12 col-lg-6">
              <FgInput
                id="estimatedAmountReleased"
                placeHolder="Estimated Amount Released"
                registeredField={registry.estimatedAmountReleased}
                formState={formState}
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    estimatedAmountReleased: Number(data) || (null as any),
                  });
                }}
                type="number"
              ></FgInput>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <FgSelect
                id="nfirsPhysicalStateEntryId"
                label="Physical State When Released"
                selectMessage="Select Physical State"
                registeredField={registry.nfirsPhysicalStateEntryId}
                formState={formState}
                options={physicalState.map((o) => {
                  return {
                    label: `${o.externalId}. ${o.description}`,
                    value: o.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsPhysicalStateEntryId: Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className="col-sm-12 col-lg-6">
              <FgSelect
                id="nfirsReleasedInto"
                label="Released Info"
                selectMessage="Select Released Info"
                registeredField={registry.nfirsReleasedInto}
                formState={formState}
                options={releaseInfoList.map((o) => {
                  return {
                    label: `${o.value}. ${o.label}`,
                    value: o.value,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsReleasedInto: Number(data) || (null as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSHazmatChemical;
