import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSHazmatEquipmentInvolvedService {
  get = async (reportId: any) => {
    const url = `/api/NfirsHazmatEquipmentInvolved/${reportId}`;
    return fetcher.get<ApsModels.INfirsHazmatEquipmentInvolvedOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsHazmatEquipmentInvolvedInputDto) => {
    const url = `/api/NfirsHazmatEquipmentInvolved`;
    return fetcher.post<ApsModels.INfirsHazmatEquipmentInvolvedOutputDto>(
      url,
      data
    );
  };
}

const nfirsHazmatEquipmentInvolved = new NFIRSHazmatEquipmentInvolvedService();
export default nfirsHazmatEquipmentInvolved;
