import React, { useEffect, useState } from "react";
import CommonSpinner from "../../../../aps2/components/Common/CommonSpinner";
import commonService from "../../../../aps2/services/CommonService";
import {
  AnswerDto,
  AnswerUserReferenceDto,
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { OptionGroupDto } from "../../../../data/OptionGroup";
import { UserDto } from "../../../../data/User";
import { GroupService } from "../../../../services/GroupService";
import { OptionService } from "../../../../services/OptionService";
import { UserService } from "../../../../services/UserService";
import GenericIsolatedInputListCallList from "./GenericIsolatedInputListCallList";

interface IGenericOnCallListProps {
  questionGroup?: QuestionGroupDto;
  date: Date;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  optionGroups: OptionGroupDto[];
  moduleOrder: number;
  departmentId: number;
  groupName?: string | string[];
}

interface IGenericOnCallListState {
  //wsaOptions: OptionDto[];
  userDto: UserDto[];
  ready: boolean;
}

function GenericOnCallList(props: IGenericOnCallListProps) {
  const [state, setState] = useState<Partial<IGenericOnCallListState>>({
    //wsaOptions: new Array<OptionDto>(),
    userDto: [],
    ready: false,
  });
  
  const [answers, setAnswers] = useState<string[]>([]);

  const getValidUserAnswers = (ans: AnswerDto[]) => {
     return ans.map(a => a.user?.userName || "").filter(a => a.length > 0)
  }

  const initialize = async () => {
    setAnswers((props.questionGroup?.questions ?? []).flatMap(q => getValidUserAnswers(q.answers)))

    const allGroup = await GroupService.getAllGroups();
    const groupName = props.groupName || []; //New Renderer could send Array
    const grpList = Array.isArray(groupName) ? [...groupName] : [groupName];
    const users = await UserService.getUsersInMultipleGroupsByDepartmentId(
      grpList
        .map((grpName) => {
          return allGroup.find((gx) => gx.name === grpName)?.groupId || 0;
        })
        .filter((g) => g > 0),
      props.departmentId
    );
    //const wsaOptions = await getOptions();
    setState((prev) => {
      return {
        ...prev,
        userDto: users.sort(commonService.sortByStringProperty("firstName")),
        //wsaOptions: wsaOptions,
        ready: true,
      };
    });
  };

  // const getOptions = async (): Promise<OptionDto[]> => {
  //   const wsaOptionsGroup = props.optionGroups?.find(
  //     (g) => g.name === "WSA Options"
  //   );
  //   return wsaOptionsGroup
  //     ? await OptionService.getGroupOptions(wsaOptionsGroup.optionGroupId)
  //     : [];
  // };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRowsNumber = (
    expected: number,
    actual: AnswerDto[] | undefined,
    left: boolean
  ) => {
    let multiples = 0;
    if (actual?.length) {
      for (let i = 0; i < actual.length; i++) {
        if (actual[i].order % 1000 === 0) {
          multiples++;
        }
      }
      if (left) {
        if (expected <= actual.length - multiples) {
          return actual.length - multiples + 1;
        }
      } else {
        if (multiples <= actual.length) {
          return multiples + 1;
        }
      }
    }
    return expected;
  };

  const factorySubGroup = (value: QuestionDto | undefined) => {
    setAnswers(getValidUserAnswers(value?.answers ?? []))
    const questionGroup = props.questionGroup;
    const index = props.questionGroup?.questions.findIndex(
      (x) => x.order === value?.order
    );
    if (index !== undefined) {
      if (questionGroup?.questions[index] && value) {
        questionGroup.questions[index] = value;
      }
    }
    props.setData(questionGroup, props.moduleOrder);
  };

  return (
    <div className="grid-item card box-card flex-grow-1">
      {!state.ready && <CommonSpinner overlay={true}></CommonSpinner>}
      <div className="card-body">
        <h4 className="mb-0 font-size-16">{props.questionGroup?.name || ""}</h4>
        <div className="row mt-4">
          <div className="response-first-column col-lg-6">
            {props.questionGroup?.questions.find((x) => x.order === 0)
              ?.answers && (
                <GenericIsolatedInputListCallList
                  preventExpanding={false}
                  separateNumber={1}
                  left={true}
                  setData={factorySubGroup}
                  defaultItemsCount={getRowsNumber(
                    2,
                    props.questionGroup?.questions.find((x) => x.order === 0)
                      ?.answers,
                    true
                  )}
                  answerDto={
                    props.questionGroup?.questions.find((x) => x.order === 0)
                      ?.answers
                  }
                  userDto={state.userDto}
                  question={props.questionGroup?.questions.find(
                    (x) => x.order === 0
                  )}
                  date={props.date}
                />
              )}
          </div>
          <div className="response-first-column col-lg-6">
            {props.questionGroup?.questions.find((x) => x.order === 0)
              ?.answers && (
                <GenericIsolatedInputListCallList
                  preventExpanding={false}
                  separateNumber={1000}
                  left={true}
                  setData={factorySubGroup}
                  defaultItemsCount={getRowsNumber(
                    2,
                    props.questionGroup?.questions.find((x) => x.order === 0)
                      ?.answers,
                    false
                  )}
                  answerDto={
                    props.questionGroup?.questions.find((x) => x.order === 0)
                      ?.answers
                  }
                  userDto={state.userDto}
                  question={props.questionGroup?.questions.find(
                    (x) => x.order === 0
                  )}
                  date={props.date}
                />
              )}
          </div>
        </div>
      </div>
      <div className="card-body d-none childsection4">
        <h4 className="mb-0 font-size-22">{props.questionGroup?.name || ""} <hr /></h4>
        <div className="row">
            { answers.map((a) => {
                return (
                  <div className="d-flex  mx-3 font-size-20">
                      <span>{commonService.getUserShortName(a)}</span>
                  </div>
                )
              }) }
          </div>
      </div>
    </div>
  );
}

export default GenericOnCallList;
