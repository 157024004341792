import React from "react";
import FormModal from "../Common/FormModal";
import NFIRSDynamicReportList from "./NFIRSDynamicReportList";

function NFIRSDynamicReportListDialog(props: { onClose?: () => void }) {
  return (
    <div>
      <FormModal
        title="Report Requests"
        isOpen={true}
        close={() => {
          props.onClose && props.onClose();
        }}
        submit={(data) => {
          props.onClose && props.onClose();
        }}
        primaryButtonOnly={true}
        submitButtonLabel="Done"
        size="lg"
      >
        <NFIRSDynamicReportList dialog={true}></NFIRSDynamicReportList>
      </FormModal>
    </div>
  );
}

export default NFIRSDynamicReportListDialog;
