import React from 'react';
import {
    AnswerDto,
    AnswerType,
    AnswerUserReferenceDto,
    IsolatedInputDetails,
    IsolatedSelectItem,
    QuestionDto
} from '../../../data/DailyOperational';
import { OptionDto } from '../../../data/Option';
import { UserDto } from '../../../data/User';
import { UserService } from '../../../services/UserService';
import {AppContext} from "../../../AppContext";
import {IsolatedSelect} from "./IsolatedSelect";

type Props = {
    key: number,
    isFirst: boolean,
    isLast: boolean,
    onChange: (isLast: boolean) => void,
    setData: (question: QuestionDto | undefined) => void,
    answerDto?: AnswerDto,
    userDto?: UserDto[],
    optionsDto?: OptionDto[],
    userSelected?: UserDto,
    usersSelected?: UserDto[],
    question?: QuestionDto,
    order: number,
    date: Date
}

type State = {}

export class IsolatedInput extends React.Component<Props, State>
{
    private getAnswerUserId(): number | undefined {
        return this.props.question && this.props.question.answers.find(y => y.order === this.props.order)?.user?.userId;
    }

    private getAnswerUserName(): string | undefined {
        return this.props.question && this.props.question.answers.find(y => y.order === this.props.order)?.user?.userName;
    }

    getItemClassName(): string {
        if (this.props.isFirst) {
            return 'rounded-top';
        }

        if (this.props.isLast) {
            return 'rounded-bottom';
        }

        return '';
    }

    getFullClassName(): string {
        return `col-12 border rounded-10 h-8 ${this.getItemClassName()}`;
    }

    handleInputChanged = (userId?: number, userName?: string): void => {
        const question = this.props.question
        const answer = this.props.question?.answers.find(x => x.order === this.props.order)
        if (answer) {
            const answerDto: AnswerDto | undefined = {
                ...answer,
                user: { userId: userId, userName: userName, answerId: answer.answerId },
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date
            }
            const index = question?.answers.findIndex(x => x.order === answerDto.order)
            if ((index !== undefined) && question) {
                if (question.answers[index]) {
                    question.answers[index] = answerDto
                }
            }
        } else {
            const answerDto: AnswerDto | undefined = {
                answerId: 0,
                answerType: AnswerType.User,
                questionId: Number(question?.questionId),
                order: this.props.order,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                user: { answerId: 0, userId: userId, userName: userName }
            }
            question?.answers.push(answerDto)
        }
        this.props.setData(question)
        this.props.onChange(this.props.isLast);
    }

    render() {
        const items: IsolatedSelectItem[] = this.props.userDto ? this.props.userDto.map(u => { return { id: u.userId, name: `${u.lastName}, ${u.firstName}` }}) : [];
        const userId: number | undefined = this.getAnswerUserId();
        const userName: string | undefined = this.getAnswerUserName();
        return (
            <IsolatedSelect onChange={this.handleInputChanged} availableOptions={items} id={userId} name={userName} className={this.getFullClassName()}/>
        )
    }
}
