import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSBasicIncidentService {
  get = async (id: any) => {
    const url = `/api/NFIRSBasicIncident/${id}`;
    return fetcher.get<ApsModels.INfirsBasicIncidentOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsBasicIncidentInputDto) => {
    const url = `/api/NFIRSBasicIncident`;
    return fetcher.post<ApsModels.INfirsBasicIncidentOutputDto>(url, data);
  };
}

const nfirsBasicIncidentService = new NFIRSBasicIncidentService();
export default nfirsBasicIncidentService;
