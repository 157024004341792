import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import { FgSelect, FgUseForm, useFgModel } from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";
import {
  INFIRSTabProps,
  useIsOtherModuleDataRequired,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";

function NFIRSWildlandIgnition(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsWildlandIgnition.update(model.new);
  };
  const {
    model,
    getModel,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsWildlandIgnitionInputDto>({
    objectName: "Ignition",
    nfirsSectionTab: "Wildland Fire/Ignition",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsWildlandIgnitionInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const getData = async () => {
    setProgress({ loading: true, errorCode: 0 });
    await ApsServices.http.nfirsWildlandIgnition
      .get(props.id)
      .then((data) => {
        const mdl = { ...model.new, ...data, reportId: Number(props.id) };
        initModels(mdl);
        setValuesFromModel(data);
        setProgress({ loading: false });
        props.onChange(mdl);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Ignition", err);
        setProgress({ loading: false });
      });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Wildland Fire",
    "Ignition",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Wildland Fire", "Ignition", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const contributingFactors = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getContributingFactors(false),
    "Contributing Factors"
  );
  const contributingFactorSections = useNfirsLookupEnum(
    "NfirsContributingFactorSectionEnum",
    "Contributing Factor Sections"
  );
  const humanFactors = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsHumanFactorsEntries,
    "Human Factors"
  );
  const fireCauses = useNfirsGetData(
    ApsServices.http.nfirsGenericLookupService.getNfirsWildlandFireCauseEntries,
    "Fire Causes"
  );
  const heatSources = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getHeatSources(false),
    "Heat Sources"
  );
  const heatSourceSections = useNfirsLookupEnum(
    "NfirsHeatSourceSectionEnum",
    "Heat Source Sections"
  );
  const factors = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getFireSuppressions(false),
    "Fire Suppression Factors"
  );
  const factorSections = useNfirsLookupEnum(
    "NfirsFireSuppressionSectionEnum",
    "Fire Suppression Factor Sections"
  );

  useEffect(() => {
    setProgress({ loading: true });
    contributingFactors.getData();
    contributingFactorSections.getData();
    heatSources.getData();
    heatSourceSections.getData();
    humanFactors.getData();
    factors.getData();
    factorSections.getData();
    fireCauses.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      contributingFactors.status === NfirsFetchStatus.Complete &&
      contributingFactorSections.status === NfirsFetchStatus.Complete &&
      heatSources.status === NfirsFetchStatus.Complete &&
      heatSourceSections.status === NfirsFetchStatus.Complete &&
      humanFactors.status === NfirsFetchStatus.Complete &&
      factors.status === NfirsFetchStatus.Complete &&
      factorSections.status === NfirsFetchStatus.Complete &&
      fireCauses.status === NfirsFetchStatus.Complete
    ) {
      setProgress({ loading: true });
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contributingFactors.status,
    contributingFactorSections.status,
    heatSources.status,
    heatSourceSections.status,
    humanFactors.status,
    factors.status,
    factorSections.status,
    fireCauses.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsWildlandFireCauseEntryId: {
      displayName: "Wildland Fire Cause",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsHumanFactorsEntryId: {
      displayName: "Human Factors",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsContributingFactorEntry1Id: {
      displayName: "Factors",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsFireSuppressionEntry1Id: {
      displayName: "Fire Suppression",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
    nfirsHeatSourceEntryId: {
      displayName: "Heat Source",
      validation: {
        validate: {
          required: (val: any) => {
            if (cntLock) {
              return !commonService.isNullOrEmpty(val);
            }
            return true;
          },
        },
      },
    },
  });

  const adjustMultiSelectItems = (
    data: ApsModels.INfirsWildlandIgnitionInputDto
  ) => {
    const items = [
      data.nfirsContributingFactorEntry1Id,
      data.nfirsContributingFactorEntry2Id,
    ].filter((i) => !!i);
    onModelChange({
      ...model.new,
      ...data,
      nfirsContributingFactorEntry1Id: items[0],
      nfirsContributingFactorEntry2Id: items[1],
    });
  };

  const isOtherModuleDataRequired = useIsOtherModuleDataRequired();

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 mb-2">
          <strong>Ignition</strong>
        </div>
        <div className="col-sm-12">
          <div className="row mt-2">
            <div className="col-sm-6">
              <FgSelect
                id="nfirsWildlandFireCauseEntryId"
                label="Fire Cause"
                selectMessage="- Select Fire Cause -"
                registeredField={registry.nfirsWildlandFireCauseEntryId}
                formState={formState}
                showRequiredLabel={isOtherModuleDataRequired}
                options={(fireCauses?.data || []).map((item) => {
                  return {
                    label: `${item.externalId}. ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsWildlandFireCauseEntryId:
                      Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
            <div className="col-sm-6">
              <FgSelect
                id="nfirsHumanFactorsEntryId"
                label="Human Factors"
                selectMessage="- Select Human Factors -"
                registeredField={registry.nfirsHumanFactorsEntryId}
                formState={formState}
                showRequiredLabel={isOtherModuleDataRequired}
                options={(humanFactors?.data || []).map((item) => {
                  return {
                    label: `${item.externalId}. ${item.description}`,
                    value: item.id,
                  };
                })}
                onChange={(data) =>
                  onModelChange({
                    ...model.new,
                    nfirsHumanFactorsEntryId:
                      Number(data) || (undefined as any),
                  })
                }
              ></FgSelect>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="row mt-2">
            <div className="col-sm-6">
              <div>
                <label
                  className={isOtherModuleDataRequired ? "required-label" : ""}
                >
                  Factors Contributing to Ignition
                </label>

                <div className="actionTakenList m-0">
                  {[
                    getModel?.new?.nfirsContributingFactorEntry1Id,
                    getModel?.new?.nfirsContributingFactorEntry2Id,
                  ].map((item, i) => (
                    <React.Fragment key={i}>
                      {item && (
                        <div>
                          <div>
                            {
                              contributingFactors.data?.find(
                                (a) => item === a.id
                              )?.description
                            }{" "}
                            (
                            {
                              contributingFactors.data?.find(
                                (a) => item === a.id
                              )?.externalId
                            }
                            )
                          </div>
                          <div>
                            <i
                              className="fa fa-times pointer"
                              onClick={() => {
                                if (i === 0) {
                                  adjustMultiSelectItems({
                                    ...model.new,
                                    nfirsContributingFactorEntry1Id:
                                      null as any,
                                  });
                                }
                                if (i === 1) {
                                  adjustMultiSelectItems({
                                    ...model.new,
                                    nfirsContributingFactorEntry2Id:
                                      null as any,
                                  });
                                }
                              }}
                            ></i>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>

                {(!getModel?.new?.nfirsContributingFactorEntry1Id ||
                  !getModel?.new?.nfirsContributingFactorEntry2Id) && (
                  <AdvancedDropdown
                    domId="contributingFactorEntryIds"
                    value={null}
                    searchPlaceholder="Search and Add More Factors"
                    disabledOptionValues={[
                      getModel?.new?.nfirsContributingFactorEntry1Id,
                      getModel?.new?.nfirsContributingFactorEntry2Id,
                    ]}
                    favoritesHandler={{
                      add: ApsServices.http.nfirsFavorite
                        .addFavoriteContributingFactor,
                      remove:
                        ApsServices.http.nfirsFavorite
                          .removeFavoriteContributingFactor,
                      afterUpdate: (list: any[]) =>
                        contributingFactors.setData(list),
                    }}
                    options={(contributingFactors.data || []).map((o) => {
                      return {
                        label: `${o.description} (${o.externalId})`,
                        value: o.id,
                        originalObject: o,
                        isFavorite: o.isFavorite,
                        groupId: o.nfirsContributingFactorSection,
                        subGroupId: 0,
                      };
                    })}
                    groups={(contributingFactorSections.data || []).map((o) => {
                      return {
                        groupId: o.value,
                        groupLabel: o.label,
                      };
                    })}
                    subGroups={(contributingFactorSections.data || []).map(
                      (o) => {
                        return {
                          groupId: o.value,
                          subGroupId: 0,
                        };
                      }
                    )}
                    onChange={(data) => {
                      if (!getModel?.new?.nfirsContributingFactorEntry1Id) {
                        onModelChange({
                          ...model.new,
                          nfirsContributingFactorEntry1Id:
                            Number(data) || (null as any),
                        });
                      } else if (
                        !getModel?.new?.nfirsContributingFactorEntry2Id
                      ) {
                        onModelChange({
                          ...model.new,
                          nfirsContributingFactorEntry2Id:
                            Number(data) || (null as any),
                        });
                      }
                    }}
                  ></AdvancedDropdown>
                )}
              </div>

              <div className="mt-4">
                <label
                  className={isOtherModuleDataRequired ? "required-label" : ""}
                >
                  Heat Source
                </label>
                <AdvancedDropdown
                  domId="nfirsHeatSourceEntryId"
                  value={model.new.nfirsHeatSourceEntryId}
                  favoritesHandler={{
                    add: ApsServices.http.nfirsFavorite.addFavoriteHeatSource,
                    remove:
                      ApsServices.http.nfirsFavorite.removeFavoriteHeatSource,
                    afterUpdate: (list: any[]) => heatSources.setData(list),
                  }}
                  options={(heatSources.data || []).map((o) => {
                    return {
                      label: `${o.description} (${o.externalId})`,
                      value: o.id,
                      originalObject: o,
                      isFavorite: o.isFavorite,
                      groupId: o.nfirsHeatSourceSection,
                      subGroupId: 0,
                    };
                  })}
                  groups={(heatSourceSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      groupLabel: o.label,
                    };
                  })}
                  subGroups={(heatSourceSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      subGroupId: 0,
                    };
                  })}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      nfirsHeatSourceEntryId: Number(data) || (null as any),
                    });
                  }}
                ></AdvancedDropdown>
              </div>
            </div>
            <div className="col-sm-6">
              <label>Fire Suppression</label>

              <div className="actionTakenList m-0">
                {[
                  getModel?.new?.nfirsFireSuppressionEntry1Id,
                  getModel?.new?.nfirsFireSuppressionEntry2Id,
                  getModel?.new?.nfirsFireSuppressionEntry3Id,
                ].map((item, i) => (
                  <React.Fragment key={i}>
                    {item && (
                      <div>
                        <div>
                          {
                            factors.data?.find((a) => item === a.id)
                              ?.description
                          }{" "}
                          (
                          {factors.data?.find((a) => item === a.id)?.externalId}
                          )
                        </div>
                        <div>
                          <i
                            className="fa fa-times pointer"
                            onClick={() => {
                              if (i === 0) {
                                onModelChange({
                                  ...model.new,
                                  nfirsFireSuppressionEntry1Id: null as any,
                                });
                              } else if (i === 1) {
                                onModelChange({
                                  ...model.new,
                                  nfirsFireSuppressionEntry2Id: null as any,
                                });
                              } else if (i === 2) {
                                onModelChange({
                                  ...model.new,
                                  nfirsFireSuppressionEntry3Id: null as any,
                                });
                              }
                            }}
                          ></i>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>

              {(!getModel?.new?.nfirsFireSuppressionEntry1Id ||
                !getModel?.new?.nfirsFireSuppressionEntry2Id ||
                !getModel?.new?.nfirsFireSuppressionEntry3Id) && (
                <AdvancedDropdown
                  domId="nfirsFireSuppressionEntry1Id"
                  value={null}
                  searchPlaceholder="Search and Fire Suppressions"
                  favoritesHandler={{
                    add: ApsServices.http.nfirsFavorite
                      .addFavoriteFireSuppression,
                    remove:
                      ApsServices.http.nfirsFavorite
                        .removeFavoriteFireSuppression,
                    afterUpdate: (list: any[]) => factors.setData(list),
                  }}
                  options={(factors.data || []).map((o) => {
                    return {
                      label: `${o.description} (${o.externalId})`,
                      value: o.id,
                      originalObject: o,
                      isFavorite: o.isFavorite,
                      groupId: o.nfirsFireSuppressionSection,
                      subGroupId: 0,
                    };
                  })}
                  groups={(factorSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      groupLabel: o.label,
                    };
                  })}
                  subGroups={(factorSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      subGroupId: 0,
                    };
                  })}
                  onChange={(data) => {
                    if (!getModel?.new?.nfirsFireSuppressionEntry1Id) {
                      onModelChange({
                        ...model.new,
                        nfirsFireSuppressionEntry1Id:
                          Number(data) || (null as any),
                      });
                    } else if (!getModel?.new?.nfirsFireSuppressionEntry2Id) {
                      onModelChange({
                        ...model.new,
                        nfirsFireSuppressionEntry2Id:
                          Number(data) || (null as any),
                      });
                    } else if (!getModel?.new?.nfirsFireSuppressionEntry3Id) {
                      onModelChange({
                        ...model.new,
                        nfirsFireSuppressionEntry3Id:
                          Number(data) || (null as any),
                      });
                    }
                  }}
                ></AdvancedDropdown>
              )}
            </div>
          </div>
        </div>

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSWildlandIgnition;
