import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSBasicInvolvedService {
  get = async (id: any, reportId: any) => {
    const url = `/api/NfirsBasicInvolved/${id}/${reportId}`;
    return fetcher.get<ApsModels.INfirsBasicInvolvedInputDto>(url);
  };

  delete = async (id: any, reportId: any, concurrencyToken: any) => {
    const url = `/api/NfirsBasicInvolved/${id}/${reportId}/${concurrencyToken}`;
    return fetcher.delete<any>(url);
  };

//   getBasicInvolvedIds = async () => {
//     const url = `/api/NfirsBasicInvolved/GetBasicInvolvedIds`;
//     return fetcher.get<any[]>(url);
//   };

  list = async (page: any, pageSize: any, reportId: any) => {
    const url = `/api/NfirsBasicInvolved/List/${page}/${pageSize}/${reportId}`;
    return fetcher.get<ApsModels.INfirsBasicInvolvedGridOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsBasicInvolvedInputDto) => {
    const url = `/api/NfirsBasicInvolved`;
    return fetcher.post<ApsModels.INfirsBasicInvolvedInputDto>(url, data);
  };
}

const nfirsBasicInvolved = new NFIRSBasicInvolvedService();
export default nfirsBasicInvolved;
