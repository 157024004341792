import React from "react";
import { IChildComponentProps } from "./FormGroups";
import { FgCheckbox } from "./FormGroups";

interface ICheckboxListProps
  extends IChildComponentProps<{ label: string; value: boolean; id?: any }[]> {
  id: string;
  disabled?: boolean;
  noContainer?: boolean;
  noWrapLabel?: boolean;
  disabledIds?: any[];
  singleSelectOnly?: boolean;
  maxHeight?: number;
}

function CheckboxList({
  id,
  data,
  onChange,
  disabled,
  noContainer,
  noWrapLabel,
  disabledIds,
  singleSelectOnly,
  maxHeight,
}: ICheckboxListProps) {
  return (
    <div
      style={
        noContainer
          ? {}
          : {
              ...listContainerStyles,
              maxHeight: maxHeight ? `${maxHeight}px` : "120px",
            }
      }
    >
      {(!data || data.length === 0) && <i>No Options</i>}
      {data.map((item, i) => {
        return (
          <div style={{ marginBottom: "5px" }} key={item.id || i}>
            <FgCheckbox
              onChange={(val) => {
                const list = [...data];
                if (singleSelectOnly && val) {
                  list.forEach((o) => {
                    o.value = false;
                  });
                }
                list[i].value = val;
                onChange(list);
              }}
              data={item.value}
              label={item.label}
              id={`${id}ChkBox${i}`}
              disabled={disabled || (disabledIds || []).indexOf(item.id) > -1}
              noWrapLabel={noWrapLabel}
            />
          </div>
        );
      })}
    </div>
  );
}

const listContainerStyles: React.CSSProperties = {
  maxHeight: "120px",
  overflow: "auto",
  border: "solid 1px #ddd",
  //borderRadius: "3px",
  padding: "5px 8px",
};

export default CheckboxList;
