import moment from 'moment';

class PreviousDayService {
    public isFeatureAvailable(utcDate: Date): boolean {
        // `date` is 12:00AM UTC of the selected calendar date.
        const date = moment.utc(utcDate).local(true) // convert to local midnight
        const deadline = date.add(8, 'hours'); // 8:00 AM local
        const now = moment();
        //now.hours(7).minutes(59); // uncoment to debug
        return now.isBefore(deadline);
    }
}

const previousDayService = new PreviousDayService();
export { previousDayService as PreviousDayService };