import React, { useEffect, useState } from "react";
import ApsModels from "../../models";
import commonService from "../../services/CommonService";

const REPORT_SCHEDULE = [
  {
    id: ApsModels.TrainingReportScheduleEnum.None,
    label: "None",
  },
  {
    id: ApsModels.TrainingReportScheduleEnum.Daily,
    label: "Daily",
  },
  {
    id: ApsModels.TrainingReportScheduleEnum.Weekly,
    label: "Weekly",
  },
  {
    id: ApsModels.TrainingReportScheduleEnum.Monthly,
    label: "Monthly",
  },
];

const WEEK_DAYS = [
  {
    id: ApsModels.TrainingReportWeeklyRepeatOnEnum.Monday,
    label: "Monday",
  },
  {
    id: ApsModels.TrainingReportWeeklyRepeatOnEnum.Tuesday,
    label: "Tuesday",
  },
  {
    id: ApsModels.TrainingReportWeeklyRepeatOnEnum.Wednesday,
    label: "Wednesday",
  },
  {
    id: ApsModels.TrainingReportWeeklyRepeatOnEnum.Thursday,
    label: "Thursday",
  },
  {
    id: ApsModels.TrainingReportWeeklyRepeatOnEnum.Friday,
    label: "Friday",
  },
  {
    id: ApsModels.TrainingReportWeeklyRepeatOnEnum.Saturday,
    label: "Saturday",
  },
  {
    id: ApsModels.TrainingReportWeeklyRepeatOnEnum.Sunday,
    label: "Sunday",
  },
];

const MONTH_DAYS: { id: number; label: string }[] = [];
let cnt = 0;
while (cnt < 28) {
  cnt++;
  let i = `${cnt}`;
  MONTH_DAYS.push({
    id: cnt,
    label: i,
  });
}
MONTH_DAYS.push({
  id: 0,
  label: "Last Day",
});

function ReportScheduleTab(props: {
  model: ApsModels.ITrainingReportScheduleDto;
  onChange: (model: ApsModels.ITrainingReportScheduleDto) => void;
  onError: (err: string) => void;
}) {
  const [model, setModel] = useState(props.model);
  const [errors, setErrors] = useState({
    email: "",
    frequency: "",
  });

  useEffect(() => {
    let emailError = "";
    let freqErr = "";
    if (!!model.reportScheduleEnum) {
      const err = commonService.validateEmailCSV(
        model.sendToEmailAddresses || ""
      );
      emailError = err === true ? "" : err;
      if (
        !model.sendToReportOwner &&
        commonService.isNullOrWhitespace(model.sendToEmailAddresses || "")
      ) {
        emailError =
          "Please select either 'Send Report to Owner' or at least one email address";
      }

      if (
        model.reportScheduleEnum ===
          ApsModels.TrainingReportScheduleEnum.Weekly &&
        model.weeklyRepeatOnEnum === null
      ) {
        freqErr = "Please select a day";
      } else if (
        model.reportScheduleEnum ===
          ApsModels.TrainingReportScheduleEnum.Monthly &&
        model.monthlyRepeatOnDay === null
      ) {
        freqErr = "Please select a day";
      }
    }

    setErrors({
      email: emailError,
      frequency: freqErr,
    });

    if (!commonService.isNullOrEmpty(freqErr)) {
      props.onError(freqErr);
    } else if (!commonService.isNullOrEmpty(emailError)) {
      props.onError(emailError);
    } else {
      props.onError("");
    }

    props.onChange(model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <div className="p-4">
      <strong>Scheduling Options</strong>
      <div className="pt-2 row">
        <div className="col-sm-4 pt-4 pb-4">
          <div className="row">
            <div className="col-sm-6">
              <label>Frequency</label>
              <select
                className="form-control"
                value={model?.reportScheduleEnum || 0}
                onChange={(e) => {
                  const val = Number(e.target.value);
                  setModel((prev) => {
                    return {
                      ...prev,
                      reportScheduleEnum: val,
                      sendToReportOwner: !!(
                        val &&
                        (prev.sendToReportOwner === null ||
                          prev.sendToReportOwner === undefined)
                      )
                        ? true
                        : prev.sendToReportOwner,
                    };
                  });
                }}
              >
                {REPORT_SCHEDULE.map((f) => (
                  <option value={f.id}>{f.label}</option>
                ))}
              </select>
            </div>
            <div className="col-sm-6">
              {!(
                !model.reportScheduleEnum ||
                model.reportScheduleEnum ===
                  ApsModels.TrainingReportScheduleEnum.Daily
              ) && <label>Repeat On</label>}
              {model.reportScheduleEnum ===
                ApsModels.TrainingReportScheduleEnum.Weekly && (
                <select
                  className="form-control"
                  value={
                    model?.weeklyRepeatOnEnum === null
                      ? ""
                      : model?.weeklyRepeatOnEnum
                  }
                  placeholder="Select Day"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModel((prev) => {
                      return {
                        ...prev,
                        weeklyRepeatOnEnum:
                          val === "" ? (null as any) : Number(val),
                      };
                    });
                  }}
                >
                  <option value="">Select Day</option>
                  {WEEK_DAYS.map((f) => (
                    <option value={f.id}>{f.label}</option>
                  ))}
                </select>
              )}
              {model.reportScheduleEnum ===
                ApsModels.TrainingReportScheduleEnum.Monthly && (
                <select
                  className="form-control"
                  value={
                    model?.monthlyRepeatOnDay === null
                      ? ""
                      : model?.monthlyRepeatOnDay
                  }
                  placeholder="Select Day"
                  onChange={(e) => {
                    const val = e.target.value;
                    setModel((prev) => {
                      return {
                        ...prev,
                        monthlyRepeatOnDay:
                          val === "" ? (null as any) : Number(val),
                        monthlyRepeatOnLastDay:
                          val === "" ? (null as any) : Number(val) === 0,
                      };
                    });
                  }}
                >
                  <option value="">Select Day</option>
                  {MONTH_DAYS.map((f) => (
                    <option value={f.id}>{f.label}</option>
                  ))}
                </select>
              )}
              {errors.frequency && (
                <div className="mt-1">
                  <span className="text-danger">{errors.frequency}</span>
                </div>
              )}
            </div>
          </div>
          <hr className="mt-4 mb-0" />
        </div>
      </div>
      <strong>Email Options</strong>
      <div className="row">
        <div className="col-sm-4 pt-4 pb-4">
          <div className="flex flex-row flex-center pb-2">
            <input
              type="checkbox"
              checked={
                model.reportScheduleEnum
                  ? model.sendToReportOwner || false
                  : false
              }
              id="sendToReportOwner"
              className="me-2"
              onChange={(e) => {
                const checked = e.target.checked || false;
                setModel((prev) => {
                  return {
                    ...prev,
                    sendToReportOwner: checked,
                  };
                });
              }}
              disabled={!model.reportScheduleEnum}
            />
            <label htmlFor="sendToReportOwner" className="m-0">
              Send to Report Owner
            </label>
          </div>
          <div className="my-2">
            <label className="m-0">Send to Email(s)</label>
          </div>
          <textarea
            className="form-control"
            rows={3}
            value={model.sendToEmailAddresses || ""}
            placeholder="Enter email addresses of each individual separated by comma"
            disabled={!model.reportScheduleEnum}
            onChange={(e) => {
              const val = e.target.value || "";
              setModel((prev) => {
                return {
                  ...prev,
                  sendToEmailAddresses: val,
                };
              });
            }}
          ></textarea>
          {errors.email && (
            <div className="mt-1">
              <span className="text-danger">{errors.email}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportScheduleTab;
