import React from "react";

function TemporaryLinkPage(props: any) {
  const title = decodeURIComponent(props.match.params.title || "Unknown");
  document.title = `APS | ${title}`;
  return (
    <div className="p-4">
      <h5>Temporary Page for:</h5>
      <h4 className="text-primary">{title}</h4>
    </div>
  );
}

export default TemporaryLinkPage;
