import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSArsonJuvenileServices {
  get = async (reportId: any) => {
    const url = `/api/NFIRSArsonJuvenile/${reportId}`;
    return fetcher.get<ApsModels.INfirsArsonJuvenileOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsArsonJuvenileInputDto) => {
    const url = `/api/NFIRSArsonJuvenile`;
    return fetcher.post<ApsModels.INfirsArsonJuvenileOutputDto>(url, data);
  };
}

const nfirsArsonJuvenile = new NFIRSArsonJuvenileServices();
export default nfirsArsonJuvenile;
