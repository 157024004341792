import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import DateTimePicker from "../../Common/DateTimePicker";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  IBasicDialogProps,
} from "../../Common/FormGroups";
import FormModal from "../../Common/FormModal";
import { useNfirsLookupEnum } from "../NFIRSHelper";

function NFIRSSummaryDialog(
  props: IBasicDialogProps<{
    reportId: number;
  }>
) {
  const { registry, formState, setValuesFromModel } = FgUseForm({
    date: {
      displayName: "Date",
      validation: { required: true },
    },
    fdid: {
      displayName: "FDID",
      validation: { required: true },
    },
    state: {
      displayName: "State",
      validation: { required: true },
    },
    reportType: {
      displayName: "Report Type",
      validation: { required: true },
    },
    incidentNumber: {
      displayName: "Incident Number",
      validation: { required: true },
    },
    station: {
      displayName: "Station",
      validation: { required: true },
    },
    exposure: {
      displayName: "Exposure",
      validation: { required: true },
    },
  });
  const [model, setModel] = useState({} as any);
  const [saving, setSaving] = useState(false);
  const submit = async (form: any) => {
    const data = {};

    setSaving(true);
    //TODO
  };

  const onModelChange = (newModel: any) => {
    setModel({ ...model, new: { ...newModel } });
    setValuesFromModel(newModel);
  };

  const sections = useNfirsLookupEnum("NfirsValidationModulesEnum", "Modules");
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };
  useEffect(() => {
    sections.getData();
  }, []);
  return (
    <div>
      <FormModal
        title="Report Summary"
        isOpen={true}
        close={() => close()}
        submit={() => close()}
        size="lg"
        submitButtonLabel="Done"
        primaryButtonOnly={true}
      >
        <div className="pb-4">
          <label>The following is a quick report summary and shortcuts.</label>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="pb-3">
              <label>
                <span className="required-label">*</span>Date
              </label>
              <DateTimePicker
                onChange={(data) => {
                  onModelChange({
                    ...model.new,
                    date: data
                      ? moment(data).format("YYYY-MM-DD HH:mm")
                      : (null as any),
                  });
                }}
                data={model.date}
                dateOnly={true}
              />
            </div>
            <FgInput
              id="fdid"
              label="FDID"
              placeHolder="FDID"
              registeredField={registry.fdid}
              showRequiredLabel={true}
              formState={formState}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  fdid: data,
                });
              }}
            ></FgInput>
            <FgSelect
              id="state"
              label="State"
              selectMessage="State"
              registeredField={registry.state}
              showRequiredLabel={true}
              formState={formState}
              onChange={(data) => {}}
              options={[]}
            ></FgSelect>
            {/* <FgSelect
              id="reportType"
              label="Report Type"
              selectMessage="Report Type"
              registeredField={registry.reportType}
              formState={formState}
              onChange={(data) => {}}
              options={[]}
            ></FgSelect> */}
          </div>
          <div className="col-sm-12 col-md-6">
            <FgInput
              id="incidentNumber"
              label="Incident Number"
              placeHolder="Incident Number"
              registeredField={registry.incidentNumber}
              showRequiredLabel={true}
              formState={formState}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  incidentNumber: data,
                });
              }}
            ></FgInput>
            <FgInput
              id="station"
              label="Station"
              placeHolder="Station"
              registeredField={registry.station}
              showRequiredLabel={true}
              formState={formState}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  station: data,
                });
              }}
            ></FgInput>
            <FgInput
              id="Exposure"
              label="Exposure"
              placeHolder="Exposure"
              registeredField={registry.exposure}
              showRequiredLabel={true}
              formState={formState}
              onChange={(data) => {
                onModelChange({
                  ...model.new,
                  exposure: data,
                });
              }}
            ></FgInput>
          </div>
        </div>

        {/* <div>
          <h4 className="pb-1 text-primary">
            <i>Modules Included</i>
          </h4>
          <div>
            {sections?.data?.map((item) => (
              <button key={item.value} className="btn-sm-rounded mr-2">
                {item.label}
              </button>
            ))}
          </div>
          <h4 className="pt-3 pb-1 text-primary">
            <i>Modules Not Included</i>
          </h4>
          <div>
            {sections?.data?.map((item) => (
              <button key={item.value} className="btn-sm-rounded mr-2">
                {item.label}
              </button>
            ))}
          </div>
        </div> */}
        <div className="pt-4">
          <button className="btn btn-danger">Delete Report</button>
        </div>
      </FormModal>
    </div>
  );
}

export default NFIRSSummaryDialog;
