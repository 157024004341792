import React from "react";
import ApsModels from "../../../../models";
import systemStore from "../../../../stores/SystemStore";
import { FgUseForm, IBasicDialogProps } from "../../../Common/FormGroups";
import { FgInput } from "../../../Common/FormGroups/FgInput";
import FormModal from "../../../Common/FormModal";

function DTTrainingTypesDialog(
  props: IBasicDialogProps<ApsModels.ITrainingTypeOutputDto>
) {
  const { registry, handleSubmit, formState } = FgUseForm({
    name: {
      displayName: "Training Type Name",
      validation: {
        required: true,
        validate: {
          exists: (val: string) => {
            if (props.existingItems) {
              return (
                props.existingItems.filter(
                  (item) =>
                    item.trim().toLowerCase() ===
                    (val || "").trim().toLowerCase()
                ).length === 0
              );
            }
            return true;
          },
        },
      },
    },
  });
  const close = (saved?: boolean) => {
    props.onClose(saved);
  };

  const submit = async (form: ApsModels.ITrainingTypeOutputDto) => {
    const data = {
      ...form,
      departmentId: systemStore.currentDrillTemplateDepartment,
      isEnabled: true,
    };
    props.onChange && props.onChange(data);
    close(true);
  };

  return (
    <div>
      <FormModal
        title="New Training Type"
        isOpen={true}
        close={() => close()}
        submit={handleSubmit(submit)}
      >
        <form onSubmit={handleSubmit(submit)}>
          <FgInput
            id="trainingTypeName"
            placeHolder="Training Type Name"
            registeredField={registry.name}
            formState={formState}
            autoFocus={true}
          ></FgInput>
        </form>
      </FormModal>
    </div>
  );
}

export default DTTrainingTypesDialog;
