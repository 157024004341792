import React, { Component } from "react";
import moment from "moment";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { AuthenticationService } from "../../services/AuthenticationService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../assets/images/aps.jpg';

type Props = RouteComponentProps & {}

type State = {
    username: string,
    temporaryPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    isSubmitting: boolean,
    validationErrors: ValidationErrors,
    errorLoggingIn: boolean,
}

type ValidationErrors = {
    newPassword?: string,
    confirmPassword?: string,
}

export class ChangePassword extends Component<Props, State> {
    static displayName = ChangePassword.name;


    state: State = {
        temporaryPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        isSubmitting: false,
        errorLoggingIn: false,
        validationErrors: {},
        username: (new URLSearchParams(this.props.location.search)).get('username') ?? "",
    };

    handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ username: e.target.value });
    }
    handleTemporaryPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ temporaryPassword: e.target.value });
    }
    handlePassword1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newPassword: e.target.value });
    }
    handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ confirmNewPassword: e.target.value });
    }

    handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState({ isSubmitting: true });
        
        const isvalid = this.validateForm();
        
        if (isvalid) {
            const success = await AuthenticationService.changePassword({
                username: this.state.username, 
                newPassword: this.state.newPassword, 
                temporaryPassword: this.state.temporaryPassword});
            if (!success) {
                this.setState({ errorLoggingIn: true });
                this.setState({ isSubmitting: false });
            } 
            else {
                const params = new URLSearchParams(this.props.location.search);
                const returnUrl = params.get('ReturnUrl');
                if (returnUrl) {
                    if (returnUrl.startsWith('/connect/authorize/callback')) {
                        window.location.href = returnUrl;
                    }
                    else {
                        this.props.history.push(returnUrl);
                    }
                }
                else {
                    this.props.history.push('/links');
                }

            }
        }
        else {
            this.setState({ isSubmitting: false});
        }
    }

    validateForm = () => {
        var validationErrors: ValidationErrors = {};

        if (this.state.newPassword.length < 8) {
            validationErrors.newPassword = 'Your password must be at least 8 characters';
        }
        if (this.state.newPassword === this.state.temporaryPassword){
            validationErrors.newPassword = 'Your password may not match the temporary password';
        }
        if (this.state.confirmNewPassword !== '' &&
            this.state.newPassword !== this.state.confirmNewPassword) {
            validationErrors.confirmPassword = 'The passwords do not match';
        }

        this.setState({ validationErrors: validationErrors });

        return Object.entries(validationErrors).length === 0;
    }

    render() {
        return (
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-soft-primary">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Activate Account</h5>
                                                <p className="text-p">Updating your password is required to activate your account.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-sidebar-dark">
                                                <img src={Logo} alt="" className="rounded-circle w-16" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                            <form onSubmit={this.handleSubmit} className="form-horizontal">

                                                <div className="form-group text-secondary">
                                                    <label>Username</label>
                                                    <input
                                                        name="email"
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Username"
                                                        value={this.state.username}
                                                        onChange={this.handleUsernameChange}
                                                        required
                                                    />
                                                </div>

                                                <div className="form-group text-secondary">
                                                    <label>Temporary Password</label>
                                                    <input
                                                        name="temp-password"
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Temporary Password"
                                                        onChange={this.handleTemporaryPasswordChange}
                                                        required
                                                    />
                                                </div>

                                                <div className="form-group text-secondary">
                                                    <label>New Password</label>
                                                    <input
                                                        name="password1"
                                                        type="password"
                                                        className={`form-control ${this.state.validationErrors.newPassword ? 'is-invalid' : ''}`}
                                                        placeholder="Password"
                                                        onChange={this.handlePassword1Change}
                                                        required
                                                    />
                                                    {this.state.validationErrors.newPassword && (
                                                    <div className="invalid-feedback">
                                                        {this.state.validationErrors.newPassword}
                                                    </div>
                                                )}
                                                </div>
                                                

                                                <div className="form-group text-secondary">
                                                    <label>Confirm New Password</label>
                                                    <input
                                                        name="password2"
                                                        type="password"
                                                        className={`form-control ${this.state.validationErrors.confirmPassword ? 'is-invalid' : ''}`}
                                                        placeholder="Confirm Password"
                                                        onChange={this.handlePassword2Change}
                                                        required
                                                    />
                                                    {this.state.validationErrors.confirmPassword && (
                                                    <div className="invalid-feedback">
                                                        {this.state.validationErrors.confirmPassword}
                                                    </div>
                                                )}
                                                </div>
                                                

                                                <div className="mt-3">
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                        disabled={this.state.isSubmitting}
                                                    >
                                                        {this.state.isSubmitting && (
                                                            <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-2" />
                                                        )}
                                                        Activate
                                                    </button>
                                                </div>
                                            </form>
                                        {this.state.errorLoggingIn &&
                                            <Alert color="danger" className="mt-3">
                                                Process failed. Please verify that your username and temporary password are correct.
                                            </Alert>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center text-secondary">
                                <p>
                                    Go back to <Link to="/account/login" className="font-medium text-primary">Login</Link>
                                </p>
                                <p>
                                    &copy; {moment().year().toString()} APS.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}