/**
 * THIS IS BASED ON UsarStatus.tsx AND ADJUSTED TO BE MORE GENERIC
 * FOR THE NEW D.O.C. DESIGNER
 */
import React, { useEffect, useState } from "react";
import {
  AnswerDto,
  QuestionDto,
  QuestionGroupDto,
} from "../../../../data/DailyOperational";
import { OptionDto } from "../../../../data/Option";
import { GroupService } from "../../../../services/GroupService";
import { UserDto } from "../../../../data/User";
import { UserService } from "../../../../services/UserService";
import { PullFromPreviousDayButton } from "../../../DailyOperational/CustomInput/PullFromPreviousDayButton";
import { PreviousDayService } from "../../../../services/PreviousDayService";
import { IsolatedInputListUsarResponse } from "../UsarResponse";

interface IGenericUsarStatusProps {
  questionGroup?: QuestionGroupDto;
  date: Date;
  setData: (value: QuestionGroupDto | undefined, order: number) => void;
  moduleOrder: number;
  departmentId: number;
  groupName?: string | string[];
  instructions?: string;
}

interface IGenericUsarStatusState {
  text?: string;
  userDto: UserDto[];
  wsaOptions: OptionDto[];
  userSelected?: UserDto;
}

function GenericUsarStatus(props: IGenericUsarStatusProps) {
  const [state, setState] = useState<IGenericUsarStatusState>({
    text: "",
    userDto: [],
    wsaOptions: new Array<OptionDto>(),
    userSelected: undefined,
  });

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const allGroup = await GroupService.getAllGroups();
    const groupName = props.groupName || []; //New Renderer could send Array
    const grpList = Array.isArray(groupName) ? [...groupName] : [groupName];
    const groupIds = allGroup
      .filter((g) => grpList.includes(g.name))
      .map((g) => g.groupId);
    const users = await UserService.getUsersInMultipleGroupsByDepartmentId(
      groupIds,
      props.departmentId
    );

    setState((prev) => {
      return {
        ...prev,
        userDto: users,
      };
    });
    const userId = props.questionGroup?.questions
      .find((x) => x.order === 0)
      ?.answers.find((y) => y.order === 0)?.user?.userId;
    if (userId) {
      const user = await UserService.getUser(userId);

      setState((prev) => {
        return {
          ...prev,
          userSelected: user,
        };
      });
    }
  };

  const getRowsNumber = (expected: number, actual: AnswerDto[] | undefined) => {
    const orders = new Array<number>();
    if (actual?.length) {
      actual.map((x) => orders.push(x.order));
      const maxNumber = Math.max(...orders);
      if (expected <= actual.length) {
        if (actual.length <= maxNumber) {
          return maxNumber + 1;
        }
        return actual.length + 1;
      }
    }
    return expected;
  };

  const factorySubGroup = (value: QuestionDto | undefined) => {
    const questionGroup = props.questionGroup;
    const index = props.questionGroup?.questions.findIndex(
      (x) => x.order === value?.order
    );
    if (index !== undefined) {
      if (questionGroup?.questions[index] && value) {
        questionGroup.questions[index] = value;
      }
    }
    props.setData(questionGroup, props.moduleOrder);
  };

  const handlePullFromPreviousDay = async (
    newDayQuestionGroup: QuestionGroupDto
  ) => {
    props.setData(newDayQuestionGroup, props.moduleOrder);
  };

  return (
    <div className="grid-item card box-card flex-grow-1">
      <div className="card-body">
        <div className="row">
          <h4 className="mb-0 font-size-16 col-xl-11 col-10">
            {props.questionGroup?.name || ""}
          </h4>
          {PreviousDayService.isFeatureAvailable(props.date) && (
            <PullFromPreviousDayButton
              questionGroupId={props.questionGroup?.questionGroupId!}
              calendarDate={props.date}
              setData={handlePullFromPreviousDay}
            />
          )}
        </div>
        <div className="mt-3">{props.instructions || ""}</div>
        <div className="row mt-3">
          <div className="response-first-column col-lg-4">
            <span className="font-size-16">
              {
                props.questionGroup?.questions.find((x) => x.order === 1)
                  ?.questionText
              }
            </span>
            {props.questionGroup?.questions.find((x) => x.order === 1)
              ?.answers && (
              <IsolatedInputListUsarResponse
                setData={factorySubGroup}
                defaultItemsCount={getRowsNumber(
                  2,
                  props.questionGroup?.questions.find((x) => x.order === 1)
                    ?.answers
                )}
                question={props.questionGroup?.questions.find(
                  (x) => x.order === 1
                )}
                answerDto={
                  props.questionGroup?.questions.find((x) => x.order === 1)
                    ?.answers
                }
                userDto={state.userDto}
                isDropdown={true}
                date={props.date}
              />
            )}
          </div>
          <div className="response-first-column col-lg-4">
            <span className="font-size-16">
              {
                props.questionGroup?.questions.find((x) => x.order === 2)
                  ?.questionText
              }
            </span>
            {props.questionGroup?.questions.find((x) => x.order === 2)
              ?.answers && (
              <IsolatedInputListUsarResponse
                setData={factorySubGroup}
                defaultItemsCount={getRowsNumber(
                  2,
                  props.questionGroup?.questions.find((x) => x.order === 2)
                    ?.answers
                )}
                question={props.questionGroup?.questions.find(
                  (x) => x.order === 2
                )}
                answerDto={
                  props.questionGroup?.questions.find((x) => x.order === 2)
                    ?.answers
                }
                userDto={state.userDto}
                isDropdown={true}
                date={props.date}
              />
            )}
          </div>
          <div className="col-lg-4">
            <h4 className="font-size-16">
              {
                props.questionGroup?.questions.find((x) => x.order === 3)
                  ?.questionText
              }
            </h4>
            {props.questionGroup?.questions.find((x) => x.order === 3)
              ?.answers && (
              <IsolatedInputListUsarResponse
                setData={factorySubGroup}
                defaultItemsCount={getRowsNumber(1, undefined)}
                preventExpanding={true}
                question={props.questionGroup?.questions.find(
                  (x) => x.order === 3
                )}
                answerDto={
                  props.questionGroup?.questions.find((x) => x.order === 3)
                    ?.answers
                }
                userDto={state.userDto}
                isDropdown={false}
                date={props.date}
                questionGroup={props.questionGroup.questions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenericUsarStatus;
