import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRSHelper";

function NFIRSArsonAgencyReferral(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsArsonAgencyReferral.update(model.new);
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsArsonAgencyReferralInputDto>({
    objectName: "Agency Referral",
    nfirsSectionTab: "Arson/AgencyReferral",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsArsonAgencyReferralInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "Arson",
    "AgencyReferral",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("Arson", "AgencyReferral", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const states = useNfirsGetData<ApsModels.ILookupStatesOutputDto[]>(
    async () => {
      return await ApsServices.http.genericLookupService.getStates(1);
    },
    "States"
  );

  useEffect(() => {
    setProgress({ loading: true });
    states.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsArsonAgencyReferral
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Agency Referral", err);
          setProgress({ loading: false });
        });
    };

    if (!progress.errorCode && states.status === NfirsFetchStatus.Complete) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress.errorCode, states.status]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    triggerSubmitForm,
  } = FgUseForm({
    agencyName: {
      displayName: "Agency Name",
      validation: {
        required: false,
        maxLength: 30,
      },
    },
    caseNumber: {
      displayName: "Agency Case Number",
      validation: {
        required: false,
        maxLength: 12,
      },
    },
    number: {
      displayName: "Number",
      validation: {
        required: false,
        maxLength: 8,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    prefix: {
      displayName: "Prefix",
      validation: {
        required: false,
        maxLength: 2,
      },
    },
    suffix: {
      displayName: "Suffix",
      validation: {
        required: false,
        maxLength: 2,
      },
    },
    street: {
      displayName: "Street or Highway",
      validation: {
        required: false,
        maxLength: 30,
      },
    },
    streetType: {
      displayName: "Street Type",
      validation: {
        required: false,
      },
    },
    ori: {
      displayName: "Their ORI",
      validation: {
        required: false,
        maxLength: 5,
      },
    },
    postOfficeBox: {
      displayName: "Post Office Box",
      validation: {
        required: false,
      },
    },
    apt: {
      displayName: "Apt./Suite/Room",
      validation: {
        required: false,
        maxLength: 15,
      },
    },
    city: {
      displayName: "City",
      validation: {
        required: false,
        maxLength: 20,
        validate: {
          lettersDashAndSpace: commonService.validations.lettersDashAndSpace,
        },
      },
    },
    fid: {
      displayName: "Federal Identifier (FID)",
      validation: {
        required: false,
        maxLength: 2,
      },
    },
    stateId: {
      displayName: "State",
      validation: {
        required: false,
      },
    },
    zipCode: {
      displayName: "Zip Code",
      validation: {
        required: false,
        maxLength: 9,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    agencyPhoneNumber: {
      displayName: "Agency Phone Number",
      validation: {
        required: false,
        maxLength: 10,
        validate: {
          numberOnly: commonService.validations.numberOnly,
        },
      },
    },
    fdid: {
      displayName: "FDID",
      validation: {
        required: false,
        maxLength: 5,
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12 col-lg-6">
          <strong>Agency Referral</strong>

          <div className="mt-3 mb-2">
            <label>Is this an agency referral?</label>
            {!progress.loading && (
              <div className="inline-radio-box-group">
                <div>
                  <input
                    type="radio"
                    name="isAgencyReferral"
                    id="isAgencyReferralYes"
                    value="1"
                    checked={model.new.isAgencyReferral}
                    onChange={(event) => {
                      onModelChange({
                        ...model.new,
                        isAgencyReferral: Number(event.target.value) === 1,
                      });
                    }}
                  ></input>
                  <label htmlFor="isAgencyReferralYes">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="isAgencyReferral"
                    id="isAgencyReferralNo"
                    value="0"
                    checked={!model.new.isAgencyReferral}
                    onChange={(event) => {
                      onModelChange({
                        ...model.new,
                        isAgencyReferral: Number(event.target.value) === 1,
                      });
                    }}
                  ></input>
                  <label htmlFor="isAgencyReferralNo">No</label>
                </div>
              </div>
            )}
          </div>

          <div className="row">
            {model.new.isAgencyReferral && (
              <>
                <div className="col-sm-12">
                  <div className="mt-3">
                    <FgInput
                      id="agencyName"
                      label="Agency Name"
                      placeHolder="Agency Name"
                      registeredField={registry.agencyName}
                      formState={formState}
                      onChange={(data) => {
                        onModelChange({
                          ...model.new,
                          agencyName: data,
                        });
                      }}
                      //maxlength={40}
                    ></FgInput>
                    <FgInput
                      id="caseNumber"
                      label="Agency Case Number"
                      placeHolder="Agency Case Number"
                      registeredField={registry.caseNumber}
                      formState={formState}
                      onChange={(data) => {
                        onModelChange({
                          ...model.new,
                          caseNumber: data,
                        });
                      }}
                      //maxlength={12}
                    ></FgInput>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {model.new.isAgencyReferral && (
          <>
            <div className="col-sm-12 col-lg-6">
              <strong>Agency Referral Tent</strong>

              <div className="row mt-3">
                <div className="col-sm-4">
                  <FgInput
                    id="number"
                    label="Number"
                    placeHolder="Number"
                    registeredField={registry.number}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        number: data,
                      });
                    }}
                    //maxlength={15}
                  ></FgInput>
                </div>
                <div className="col-sm-2">
                  <FgInput
                    id="prefix"
                    label="Prefix"
                    placeHolder="Prefix"
                    registeredField={registry.prefix}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        prefix: data,
                      });
                    }}
                    //maxlength={2}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgInput
                    id="street"
                    label="Street or Highway"
                    placeHolder="Street or Highway"
                    registeredField={registry.street}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        street: data,
                      });
                    }}
                  ></FgInput>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-2">
                  <FgInput
                    id="suffix"
                    label="Suffix"
                    placeHolder="Suffix"
                    registeredField={registry.suffix}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        suffix: data,
                      });
                    }}
                    //maxlength={2}
                  ></FgInput>
                </div>
                <div className="col-sm-4">
                  <FgInput
                    id="ori"
                    label="Their ORI"
                    placeHolder="Their ORI"
                    registeredField={registry.ori}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        ori: data,
                      });
                    }}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgInput
                    id="postOfficeBox"
                    label="Post Office Box"
                    placeHolder="Post Office Box"
                    registeredField={registry.postOfficeBox}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        postOfficeBox: data,
                      });
                    }}
                    //maxlength={4}
                  ></FgInput>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <FgInput
                    id="apt"
                    label="Apt./Suite/Room"
                    placeHolder="Apt./Suite/Room"
                    registeredField={registry.apt}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        apt: data,
                      });
                    }}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgInput
                    id="city"
                    label="City"
                    placeHolder="City"
                    registeredField={registry.city}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        city: data,
                      });
                    }}
                  ></FgInput>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <FgInput
                    id="fid"
                    label="Federal Identifier (FID)"
                    placeHolder="Federal Identifier (FID)"
                    registeredField={registry.fid}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        fid: data,
                      });
                    }}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgSelect
                    id="stateId"
                    label="State"
                    selectMessage="Select a State"
                    registeredField={registry.stateId}
                    formState={formState}
                    options={(states.data || []).map((o) => {
                      return {
                        label: `${o.abbreviation} - ${o.description}`,
                        value: o.id,
                      };
                    })}
                    onChange={(data) =>
                      onModelChange({
                        ...model.new,
                        stateId: Number(data) || (null as any),
                      })
                    }
                  ></FgSelect>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <FgInput
                    id="zipCode"
                    label="Zip Code"
                    placeHolder="Zip Code"
                    registeredField={registry.zipCode}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        zipCode: data,
                      });
                    }}
                    //maxlength={9}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgInput
                    id="agencyPhoneNumber"
                    label="Agency Phone Number"
                    placeHolder="Agency Phone Number"
                    registeredField={registry.agencyPhoneNumber}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        agencyPhoneNumber: data,
                      });
                    }}
                    //maxlength={10}
                  ></FgInput>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <FgInput
                    id="fdid"
                    label="FDID"
                    placeHolder="FDID"
                    registeredField={registry.fdid}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        fdid: data,
                      });
                    }}
                    //maxlength={5}
                  ></FgInput>
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </>
        )}

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSArsonAgencyReferral;
