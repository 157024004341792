import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSArsonDetailServices {
  get = async (reportId: any) => {
    const url = `/api/NFIRSArsonDetail/${reportId}`;
    return fetcher.get<ApsModels.INfirsArsonDetailOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsArsonDetailInputDto) => {
    const url = `/api/NFIRSArsonDetail`;
    return fetcher.post<ApsModels.INfirsArsonDetailOutputDto>(url, data);
  };
}

const nfirsArsonDetail = new NFIRSArsonDetailServices();
export default nfirsArsonDetail;
