import React from 'react';
import { OptionDto } from '../../../data/Option';
import {
    AnswerDto,
    AnswerType,
    IsolatedSelectItem,
    QuestionDto,
    QuestionGroupDto
} from '../../../data/DailyOperational';
import { AppContext } from "../../../AppContext";
import { IsolatedSelect } from "../CustomInput/IsolatedSelect";

type Props = {
    questionGroup?: QuestionGroupDto
    wsaOptions: OptionDto[],
    order: number,
    setData?: (value: QuestionDto | undefined) => void,
    question: QuestionDto | undefined,
    date: Date,
    moduleOrder: number 
}

type State = {}

export class SelectOption extends React.Component<Props, State>{
    static contextType = AppContext;

    handleInputChanged = (id?: number, name?: string): void => {
        const question = this.props.question
        const answer = this.props.question?.answers.find(x => x.order === 0)
        if (answer) {
            const answerDto: AnswerDto | undefined = {
                ...answer,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                option: { optionId: id, optionName: name, answerId: Number(answer.answerId) }
            }
            const index = question?.answers.findIndex(x => x.answerId === answer?.answerId)
            if ((index !== undefined) && question) {
                if (question.answers[index]) {
                    question.answers[index] = answerDto
                }
            }
        } else {
            const answerDto: AnswerDto | undefined = {
                answerId: 0,
                answerType: AnswerType.Option,
                questionId: Number(question?.questionId),
                order: 0,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                option: { answerId: 0, optionId: id, optionName: name }
            }
            question?.answers.push(answerDto)
        }
        if (this.props.setData) {
            this.props.setData(question)
        }
    }

    componentDidMount = async () => {
        this.setState({
            wsaOptions: this.props.wsaOptions,
            optionSelected: null
        })
    }


    private getOptionByOptionId(): number | undefined {
        return this.props.question && this.props.question.answers.find(y => y.order === 0)?.option?.optionId;
    }

    private getOptionByOptionName(): string | undefined {
        return this.props.question && this.props.question.answers.find(y => y.order === 0)?.option?.optionName;
    }

    render() {
        const items: IsolatedSelectItem[] = this.props.wsaOptions?.map(o => { return { id: o.optionId, name: o.name }});
        const optionId: number | undefined = this.getOptionByOptionId();
        const optionName: string | undefined = this.getOptionByOptionName();
        return (
            <IsolatedSelect onChange={this.handleInputChanged} availableOptions={items} id={optionId} name={optionName} className="form-control" />
        )

    }

}
