import { faClock, faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import commonService from "../../services/CommonService";
import { Doughnut } from "react-chartjs-2";
import { Dropdown, ProgressBar } from "react-bootstrap";
import moment from "moment";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ApsServices from "../../services";
import ApsModels from "../../models";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import CommonSpinner from "../Common/CommonSpinner";
import { DepartmentService } from "../../../services/DepartmentService";
import toastStore from "../../stores/ToastStore";

const getProgress = (val: number) => {
  if (val < 0) {
    return 0;
  }
  return commonService.toNumberWithComma(val);
};

const getMaxProgress = (val: number) => {
  if (val < 1) {
    return 1;
  }
  return commonService.toNumberWithComma(val);
};

const ProgressLabel = ({
  label,
  color,
  now: val,
  maxVal,
}: {
  label: string;
  color: string;
  now: number;
  maxVal: number;
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex-1">{label}</div>
        <div className="flex-0 no-wrap">
          {getProgress(val)} out of {getMaxProgress(maxVal)} hours
        </div>
      </div>
      <div className="py-2 mb-3 my-iso-training-widget">
        <ProgressBar
          variant={color}
          now={val < 0 ? 0 : val}
          max={maxVal}
          label={
            <div
              className={`progress-bar-label text-${color} ${
                val > 0 ? "" : "no-progress"
              }`}
            >
              {Math.min((val / maxVal) * 100, 100).toFixed(0)}%
            </div>
          }
        />
      </div>
      <hr style={{ margin: "0 -8px", marginBottom: "10px" }} />
    </>
  );
};

const StatusComponent = (props: {
  daysUntilNextRenewal: number;
  useExpiredLabel?: boolean;
}) => {
  const days = props.daysUntilNextRenewal;
  const getClassName = () => {
    if (days >= 90) {
      return "success";
    }
    if (days >= 30) {
      return "warning";
    }
    return "danger";
  };

  const useExpiredLabel = props.useExpiredLabel && days <= 0;
  return (
    <div
      className={`alert alert-${getClassName()} m-0 px-2 py-0 text-center`}
      style={{ width: "100px" }}
    >
      {useExpiredLabel ? (
        <>EXPIRED</>
      ) : (
        <>
          {commonService.toNumberWithComma(Math.floor(days), 0)}{" "}
          {days > 1 ? "days" : "day"}
        </>
      )}
    </div>
  );
};

function Dashboard(props: RouteComponentProps<any>) {
  const GetProgressBarVariant = (daysUntilNextRenewal: number) => {
    if (daysUntilNextRenewal >= 90) {
      return "success";
    }
    if (daysUntilNextRenewal >= 30) {
      return "warning";
    }
    return "danger";
  };

  const myEmsCred = useNfirsGetData(
    ApsServices.http.credDashboard.getOwnEmsCredentials,
    "EMS Credentials"
  );
  const otherEmsCred = useNfirsGetData(
    async () =>
      ApsServices.http.credDashboard.getUserEmsCredentials(filters.id),
    "EMS Credentials"
  );
  const getEmsCred = () => {
    return filters.id ? otherEmsCred : myEmsCred;
  };

  const myDrills = useNfirsGetData(
    async () =>
      ApsServices.http.credDashboard.getOwnDrillHours(filters.drillFilter),
    "Drills"
  );
  const otherDrills = useNfirsGetData(
    async () =>
      ApsServices.http.credDashboard.getUserDrillHours(
        filters.id,
        filters.drillFilter
      ),
    "Drills"
  );
  const getDrills = () => {
    return filters.id ? otherDrills : myDrills;
  };

  const myIncAssignments = useNfirsGetData(
    async () => ApsServices.http.credDashboard.getOwnLmsIncompleteCourses(),
    "Incomplete Assignments"
  );
  const otherIncAssignments = useNfirsGetData(
    async () =>
      ApsServices.http.credDashboard.getUserLmsIncompleteCourses(filters.id),
    "Incomplete Assignments"
  );
  const getIncAssignments = () => {
    return filters.id ? otherIncAssignments : myIncAssignments;
  };

  const myExpCreds = useNfirsGetData(
    async () => ApsServices.http.credDashboard.getOwnExpiringCategory(),
    "Expiring Credentials"
  );
  const otherExpCreds = useNfirsGetData(
    async () =>
      ApsServices.http.credDashboard.getUserExpiringCategory(filters.id),
    "Expiring Credentials"
  );
  const getExpCreds = () => {
    return filters.id ? otherExpCreds : myExpCreds;
  };

  const getMyAssignments = useNfirsGetData(
    async () => ApsServices.http.credDashboard.getOwnAssigned(),
    "My Assignments"
  );
  const getUserAssignments = useNfirsGetData(
    async () => ApsServices.http.credDashboard.getUserAssigned(filters.id),
    "Assignments"
  );
  const getAssignments = () => {
    return filters.id ? getUserAssignments : getMyAssignments;
  };

  const getISOReport = useNfirsGetData(async () => {
    if (!myDashboard() && filters.id) {
      return ApsServices.http.isoReport.userDashboardReportAsync(filters.id);
    }
    return ApsServices.http.isoReport.ownDashboardReportAsync();
  }, "ISO Report");

  const drillFilterOptions = useNfirsGetData(
    async () =>
      ApsServices.http.credCommon.credGenericEnumLookUp(
        "CredDashboardDrillTimeFilterEnum"
      ),
    "Filters"
  );

  // const expCredfilterOptions = useNfirsGetData(
  //   async () =>
  //     ApsServices.http.credCommon.credGenericEnumLookUp(
  //       "CredDashboardExpiringCategoriesFilterEnum"
  //     ),
  //   "Filters"
  // );

  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const [filters, setFilters] = useState({
    id: 0, //0 - Own, N - Other
    name: "",
    dashboard: "",
    drillFilter: ApsModels.CredDashboardDrillTimeFilterEnum.ThisMonth,
    expCredFilter: ApsModels.CredDashboardExpiringCategoriesFilterEnum.ThisYear,
  });

  const getDropdownDisplayName = (
    user: ApsModels.ICourseRecordParticipantOutputDto
  ) => {
    const lastName = `${user.firstName || ""}`.toLowerCase();
    if (lastName[lastName.length - 1] === "s") {
      return `${user.firstName}' Dashboard`;
    }
    return `${user.firstName}'s Dashboard`;
  };

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setUsers([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await ApsServices.http.credCommon
      .dashboardTypeAheadUser({
        search: query,
        isAnd: false,
        recordCount: 10,
        shiftId: null as any,
        rankId: null as any,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
          dashboard: getDropdownDisplayName(i),
        }));

        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const [modules, setModules] = useState(DepartmentService.modules.get());
  const isModuleActive = (moduleName: string) => {
    if (!modules?.find((x) => x.name === moduleName)?.isSelected) {
      return false;
    }
    return true;
  };
  const checkIfCanShowFireTraining = () => {
    return isModuleActive("Training Portal") && isModuleActive("Log Drills");
  };
  const checkIfCanShowCredentials = () => {
    return isModuleActive("Credentials");
  };
  const checkIfCanShowISOTraining = () => {
    return isModuleActive("ISO Training");
  };

  const showAssignments = () => {
    return (
      isModuleActive("Credentials") ||
      isModuleActive("Course Records") ||
      isModuleActive("Log Drills")
    );
  };

  //TODO: Disabled for now APS100-99
  // const openNfirsReports = useNfirsGetData(async () => {
  //   if (filters.id) {
  //     return ApsServices.http.nfirsDashboard.userOpenReportsCount(filters.id);
  //   }
  //   return ApsServices.http.nfirsDashboard.myOpenReportsCount();
  // }, "Open NFIRS Reports");

  useEffect(() => {
    drillFilterOptions.getData();
    //expCredfilterOptions.getData();

    const modulesSub = DepartmentService.modules.subscribe((modules) => {
      setTimeout(() => {
        setModules(modules);
      }, 500);
    });

    return () => {
      modulesSub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modulesReady, setModulesReady] = useState(false);

  useEffect(() => {
    !modulesReady && setModulesReady(modules.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules]);

  const [credsBeingFetched, setCredsBeingFetched] = useState<number[]>([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (!modulesReady) return;

    if (checkIfCanShowFireTraining()) {
      getIncAssignments().getData();
    }

    if (
      checkIfCanShowCredentials() &&
      (commonService.canViewAllCETrackers() ||
        (myDashboard() && commonService.canViewOwnCETrackers()))
    ) {
      getEmsCred().getData();
    }

    if (filters.drillFilter < 0) {
      setFilters((p) => {
        return {
          ...p,
          drillFilter: ApsModels.CredDashboardDrillTimeFilterEnum.ThisMonth,
        };
      });
    }
    if (filters.expCredFilter < 0) {
      setFilters((p) => {
        return {
          ...p,
          expCredFilter:
            ApsModels.CredDashboardExpiringCategoriesFilterEnum.ThisYear,
        };
      });
    }

    //TODO: Disabled for now APS100-99
    //openNfirsReports.getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.id, modulesReady, refresh]);

  useEffect(() => {
    if (!modulesReady) return;

    if (checkIfCanShowFireTraining() && filters.drillFilter > -1) {
      getDrills().getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.drillFilter, modulesReady]);

  useEffect(() => {
    if (!modulesReady) return;

    if (filters.expCredFilter > -1) {
      if (showAssignments()) {
        getAssignments().getData();
      }
      if (checkIfCanShowCredentials()) {
        getExpCreds().getData();
      }
    }
    if (
      checkIfCanShowISOTraining() &&
      (commonService.canViewAllISOTrackers() ||
        (myDashboard() && commonService.canViewOwnISOTrackers()))
    ) {
      getISOReport.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.expCredFilter, modulesReady, refresh]);

  const myDashboard = () => {
    return !filters.id || filters.id === commonService.userInfo.userId;
  };

  const getNumberOrNull = (val: any) => {
    if (val === null || val === undefined) {
      return null;
    }
    return Number(val);
  };

  //Will call GetTakenCredential to update data
  const refreshCredential = async (id: number) => {
    setCredsBeingFetched((p) => [...p, id]);
    await ApsServices.http.credCredential
      .getTakenCredential(id)
      .then((data) => {
        setRefresh((p) => p + 1);
      })
      .catch((err) => {
        toastStore.showToast("Failed Getting Taken Credential", err);
      })
      .finally(() => {
        setCredsBeingFetched((p) => {
          return p.filter((x) => x != id);
        });
      });
  };

  return (
    <div className="container-fluid flex-card-container">
      <div className="flex-0">
        <div className="headerControls">
          <div className="flex-1">
            <span className="h4 mb-0 font-size-18 text-uppercase">
              {myDashboard() ? "My Home" : `${filters.dashboard}`}
            </span>
          </div>

          <div
            className="flex-0 flex flex-wrap gap-5"
            style={{ justifyContent: "right" }}
          >
            {commonService.friendlyClaims.dashboard.canViewOthers && (
              <>
                <div style={{ minWidth: "250px" }}>
                  <AsyncTypeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onSearch={handleSearch}
                    onChange={(data) => {
                      if (data && data.length > 0) {
                        setFilters((p) => {
                          return {
                            ...p,
                            id: data[0].id,
                            name: data[0].name,
                            dashboard: data[0].dashboard,
                            drillFilter: -1 as any,
                            expCredFilter: -1 as any,
                          };
                        });
                        setUsers(data);
                        (ref.current as any)?.clear();
                      }
                    }}
                    searchText={"Searching..."}
                    isLoading={isLoading}
                    options={users}
                    placeholder="Search Member"
                    minLength={1}
                    delay={500}
                    useCache={false}
                    ref={ref}
                  />
                </div>
                {!!filters.id && (
                  <div className="alert alert-info m-0 p-2">
                    {filters.name}{" "}
                    <i
                      className="fa fa-times ml-2 pointer"
                      onClick={() => {
                        setFilters((p) => {
                          return {
                            ...p,
                            id: 0,
                            name: "",
                            dashboard: "",
                            drillFilter: -1 as any,
                            expCredFilter: -1 as any,
                          };
                        });
                      }}
                    ></i>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 flex flex-col">
          <div className="card dashboard-welcome">
            <img alt="" src="/dashboard-welcome.png" />
            <div className="card-body flex flex-col">
              {filters.id ? (
                <>
                  <h4>
                    <strong>{filters.name}</strong>
                  </h4>
                </>
              ) : (
                <>
                  <h4>Welcome Back</h4>
                  <h4>
                    <strong>{commonService.userInfo.firstName}</strong>
                  </h4>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-8 flex flex-col">
          {checkIfCanShowFireTraining() && (
            <div className="card">
              <div className="card-body flex flex-col">
                <div className="flex">
                  {/* <h4 className="flex-1 mb-4">My Performance</h4> */}
                  <h4 className="flex-1 mb-4">Fire Training</h4>
                  <div>
                    <Dropdown drop="down">
                      <Dropdown.Toggle
                        variant="outline-secondary"
                        id="dropdownDrillsFilter"
                        className="p-1 px-2"
                      >
                        {
                          drillFilterOptions.data?.find(
                            (f) => f.value === filters.drillFilter
                          )?.label
                        }
                      </Dropdown.Toggle>
                      <Dropdown.Menu align={"right"}>
                        {drillFilterOptions.data?.map((f) => (
                          <Dropdown.Item
                            key={f.value}
                            onClick={(e) => {
                              setFilters((p) => {
                                return {
                                  ...p,
                                  drillFilter: f.value,
                                };
                              });
                            }}
                          >
                            {f.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {(getDrills().status === NfirsFetchStatus.InProgress ||
                  getIncAssignments().status ===
                    NfirsFetchStatus.InProgress) && (
                  <CommonSpinner></CommonSpinner>
                )}
                {getDrills().status === NfirsFetchStatus.Complete &&
                  getIncAssignments().status === NfirsFetchStatus.Complete && (
                    <>
                      <div className="row">
                        <div className="cols-12 col-sm-12 col-lg-6 flex gap-5">
                          <div className="dashboard-icon">
                            <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                          </div>
                          <div className="flex-1">
                            <div className="mb-2">
                              Incomplete <br />
                              Assignments
                            </div>
                            <h3>
                              {commonService.toNumberWithComma(
                                getIncAssignments().data || 0
                              )}
                            </h3>
                          </div>
                        </div>
                        <div className="cols-12 col-sm-12 col-lg-6 flex gap-5">
                          <div className="dashboard-icon">
                            <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                          </div>
                          <div>
                            <div className="mb-2">
                              {/* Monthly Drill <br />
                              Hours Logged */}
                              Drill Hours <br />
                              Logged
                            </div>
                            <h3>
                              {commonService.toNumberWithComma(
                                getDrills().data?.drillHoursLogged || 0
                              )}
                            </h3>
                          </div>
                        </div>
                        {/* <div className="cols-12 col-sm-12 col-lg-6 flex gap-5">
                          <div className="dashboard-icon">
                            <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                          </div>
                          <div className="flex-1">
                            <div className="mb-2">
                              Open NFIRS <br />
                              Reports
                            </div>
                            <h3>
                              {commonService.toNumberWithComma(
                                openNfirsReports?.data || 0
                              )}
                            </h3>
                          </div>
                        </div> */}
                      </div>
                    </>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 flex flex-col">
          {showAssignments() && (
            <div className="card">
              <div className="card-body flex flex-col">
                <div className="flex">
                  <h4 className="flex-1 mb-4">
                    {myDashboard() ? "My" : ""} Assignments
                  </h4>
                  <div></div>
                </div>

                <div className="mt-3">
                  {getAssignments().status === NfirsFetchStatus.InProgress && (
                    <CommonSpinner></CommonSpinner>
                  )}

                  {getAssignments().status === NfirsFetchStatus.Complete && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <small>ASSIGNMENTS</small>
                          </th>
                          <th>
                            <small>DUE DATE</small>
                          </th>
                          <th>
                            <small># DAYS UNTIL DUE</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getAssignments().data?.map((d, i) => (
                          <tr key={i}>
                            <td>
                              {d.lmsEnrollmentUrl ? (
                                <a
                                  className="text-primary"
                                  href={d.lmsEnrollmentUrl}
                                  target="_blank"
                                >
                                  {d.assignment}
                                </a>
                              ) : (
                                <>
                                  {d.canAccessAssignment ? (
                                    <span
                                      className="pointer txt-primary"
                                      onClick={() => {
                                        if (
                                          d.assignmentType ===
                                          ApsModels.AssignmentTypeEnum.Course
                                        ) {
                                          props.history.push(`/courses/${d.id}`);
                                          return;
                                        }
                                        if (
                                          d.assignmentType ===
                                          ApsModels.AssignmentTypeEnum
                                            .Credential
                                        ) {
                                          props.history.push(
                                            `/credentials/view/${d.id}`
                                          );
                                          return;
                                        }
                                        if (
                                          d.assignmentType ===
                                          ApsModels.AssignmentTypeEnum.Drill
                                        ) {
                                          props.history.push(`/drills/${d.id}`);
                                          return;
                                        }
                                      }}
                                    >
                                      {d.assignment}
                                    </span>
                                  ) : (
                                    <span>{d.assignment}*</span>
                                  )}
                                </>
                              )}
                            </td>
                            <td>
                              <span className="no-wrap">
                                {d.dueDate
                                  ? moment(d.dueDate).format("MMM DD, YYYY")
                                  : ""}{" "}
                              </span>
                            </td>
                            <td>
                              <StatusComponent
                                daysUntilNextRenewal={
                                  d.numberOfDaysToExpiration
                                }
                                useExpiredLabel={true}
                              ></StatusComponent>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div>
                  <small>
                    * A supervisor will have to record the completion of this
                    assignment.
                  </small>
                </div>
              </div>
            </div>
          )}

          {/*  //COMMENTING OUT FOR NOW AS PER https://aps-software.atlassian.net/browse/APS120-6
          {checkIfCanShowCredentials() && (
            <div className="card">
              <div className="card-body flex flex-col">
                <div className="flex">
                  <h4 className="flex-1 mb-4">
                    {myDashboard() ? "My" : ""} Expiring Credentials
                  </h4>
                  <div></div>
                </div>

                <div className="mt-3">
                  {getExpCreds().status === NfirsFetchStatus.InProgress && (
                    <CommonSpinner></CommonSpinner>
                  )}

                  {getExpCreds().status === NfirsFetchStatus.Complete && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <small>CREDENTIAL</small>
                          </th>
                          <th>
                            <small>EXPIRATION</small>
                          </th>
                          <th>
                            <small># DAYS UNTIL RENEWAL</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getExpCreds().data?.map((d, i) => (
                          <tr key={i}>
                            <td className="txt-primary">
                              <span
                                className="pointer"
                                onClick={() => {
                                  if (d.isCourse) {
                                    props.history.push(`/courses/${d.id}`);
                                    return;
                                  }
                                  if (
                                    d.credCategoryTypeEnum ===
                                    ApsModels.CredCategoryTypeEnum
                                      .ExistingExternalCredential
                                  ) {
                                    props.history.push(`/Credential/${d.id}`);
                                  } else {
                                    props.history.push(
                                      `/credentials/view/${d.id}`
                                    );
                                  }
                                }}
                              >
                                {d.name}
                              </span>
                            </td>
                            <td>
                              <span className="no-wrap">
                                {moment(d.expirationDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </span>
                            </td>
                            <td>
                              <StatusComponent
                                daysUntilNextRenewal={d.daysUntilNextRenewal}
                              ></StatusComponent>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          )} */}
        </div>

        {getEmsCred().status === NfirsFetchStatus.InProgress && (
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 flex flex-col">
            <div className="card mb-3" style={{ flex: "0" }}>
              <div className="card-body flex flex-col">
                <CommonSpinner></CommonSpinner>
              </div>
            </div>
          </div>
        )}
        {checkIfCanShowCredentials() &&
          (commonService.canViewAllCETrackers() ||
            (myDashboard() && commonService.canViewOwnCETrackers())) &&
          getEmsCred().status === NfirsFetchStatus.Complete &&
          getEmsCred().data?.map((cred) => (
            <div
              key={cred.id}
              className="col-12 col-sm-12 col-md-6 col-lg-4 flex flex-col"
            >
              <div className="card mb-3" style={{ flex: "0" }}>
                {credsBeingFetched.includes(cred.inProgressCredentialId) ? (
                  <CommonSpinner></CommonSpinner>
                ) : (
                  <div className="card-body flex flex-col">
                    <div className="flex flex-center">
                      <h4 className="flex-1">
                        {myDashboard() ? "My" : ""} CE Tracker for {cred.name}
                      </h4>
                      <span>
                        <i
                          title="Refresh"
                          className="fa fa-sync text-primary pointer"
                          onClick={(e) => {
                            refreshCredential(cred.inProgressCredentialId);
                          }}
                        ></i>
                      </span>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-12 col-sm-12 col-lg-6">
                        <h5>Completed</h5>
                        <div className="pb-2">to Date</div>
                        <div
                          style={{ maxWidth: "80px" }}
                          className="chart-doughnut"
                        >
                          <Doughnut
                            data={{
                              labels: ["Completed", "Remaining"],
                              datasets: [
                                cred.hoursTaken >= cred.hoursNeeded
                                  ? {
                                      label: "Hours Completed",
                                      data: [cred.hoursNeeded],
                                      backgroundColor: ["#189ad6"],
                                      borderWidth: 1,
                                    }
                                  : {
                                      label: "Hours Completed",
                                      data: [
                                        cred.hoursTaken,
                                        cred.hoursNeeded - cred.hoursTaken,
                                      ],
                                      backgroundColor: ["#189ad6", "#ddd"],
                                      borderWidth: 1,
                                    },
                              ],
                            }}
                            options={{
                              cutout: 25,
                              responsive: true,
                              maintainAspectRatio: true,
                              plugins: {
                                tooltip: {
                                  enabled: false,
                                },
                                legend: {
                                  display: false,
                                },
                              },
                            }}
                          ></Doughnut>
                          <strong>
                            {cred.hoursTaken > cred.hoursNeeded
                              ? 100
                              : (
                                  100 *
                                  (cred.hoursTaken / cred.hoursNeeded)
                                ).toFixed(0)}
                            %
                            {/* {cred.hoursTaken > cred.hoursNeeded
                            ? cred.hoursNeeded
                            : cred.hoursTaken}{" "}
                          {cred.hoursTaken > 1 ? "hrs" : "hr"} */}
                          </strong>
                        </div>
                        <div className="pt-2">
                          {cred.hoursTaken > cred.hoursNeeded
                            ? cred.hoursNeeded
                            : cred.hoursTaken}{" "}
                          of {cred.hoursNeeded} hours completed
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-6">
                        <div className="flex flex-column h-100">
                          <h5># of Remaining Days</h5>
                          <div className="pb-2">Until Renewal</div>
                          <div className="py-2 flex-1 gap-5 flex flex-row flex-center">
                            <div
                              className="w-100"
                              style={{ maxWidth: "200px" }}
                            >
                              <ProgressBar
                                variant={GetProgressBarVariant(
                                  cred.daysUntilNextRenewal || 0
                                )}
                                now={
                                  ((cred.daysUntilNextRenewal || 0) /
                                    (cred.totalValidDays || 0)) *
                                  100
                                }
                              />
                            </div>
                            <div className="no-wrap">
                              {getNumberOrNull(cred.daysUntilNextRenewal) ===
                              null ? (
                                <>N/A</>
                              ) : (
                                <>
                                  {(getNumberOrNull(
                                    cred.daysUntilNextRenewal
                                  ) || 0) <= 0 ? (
                                    <span className="alert alert-sm alert-danger p-0 px-2">
                                      EXPIRED
                                    </span>
                                  ) : (
                                    <>
                                      <strong>
                                        {commonService.toNumberWithComma(
                                          Math.floor(cred.daysUntilNextRenewal),
                                          0
                                        )}{" "}
                                        {cred.daysUntilNextRenewal > 1
                                          ? "days"
                                          : "day"}
                                      </strong>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="pt-2">
                            Renew By:{" "}
                            {cred.expirationDate
                              ? moment(cred.expirationDate).format(
                                  "MMMM DD, YYYY"
                                )
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

        {getISOReport.status === NfirsFetchStatus.InProgress && (
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 flex flex-col">
            <div className="card mb-3" style={{ flex: "0" }}>
              <div className="card-body flex flex-col">
                <CommonSpinner></CommonSpinner>
              </div>
            </div>
          </div>
        )}
        {checkIfCanShowISOTraining() &&
          (commonService.canViewAllISOTrackers() ||
            (myDashboard() && commonService.canViewOwnISOTrackers())) &&
          getISOReport.status === NfirsFetchStatus.Complete &&
          getISOReport.data && (
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 flex flex-col">
              <div
                className="card"
                style={{
                  paddingBottom: "30px",
                }}
              >
                <div className="card-body flex flex-col">
                  <div className="flex">
                    <h4 className="flex-1 mb-2">
                      My ISO Training for This Year
                    </h4>
                  </div>
                  <hr />
                  <div className="flex mb-2 px-2">
                    <div className="flex-1">
                      <strong>Category</strong>
                    </div>
                    <div className="flex-0 no-wrap">
                      <strong>Completed Requirements</strong>
                    </div>
                  </div>
                  <div className="p-1">
                    <div
                      className="px-2"
                      style={{
                        position: "relative",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: `${(
                            (moment().dayOfYear() /
                              moment().endOf("year").dayOfYear()) *
                            100
                          ).toFixed(2)}%`,
                          borderLeft: "dashed 2px #888",
                          zIndex: 10,
                        }}
                      >
                        <small
                          style={{
                            position: "absolute",
                            bottom: "-35px",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        >
                          Expected
                          <br />
                          progress
                        </small>
                      </div>

                      <ProgressLabel
                        label="Company Training"
                        color="primary"
                        now={getISOReport.data.companyTraining}
                        maxVal={getISOReport.data.maxCompanyTraining}
                      ></ProgressLabel>

                      <ProgressLabel
                        label="Facilities Training"
                        color="warning"
                        now={getISOReport.data.facilitiesTraining}
                        maxVal={getISOReport.data.maxFacilitiesTraining}
                      ></ProgressLabel>

                      {getISOReport.data?.displayNewDriverTraining ? (
                        <ProgressLabel
                          label="(New) Driver Training"
                          color="success"
                          now={getISOReport.data.newDriverTraining}
                          maxVal={getISOReport.data.maxNewDriverTraining}
                        ></ProgressLabel>
                      ) : (
                        <ProgressLabel
                          label="(Existing) Driver Training"
                          color="success"
                          now={getISOReport.data.driverTraining}
                          maxVal={getISOReport.data.maxDriverTraining}
                        ></ProgressLabel>
                      )}

                      <ProgressLabel
                        label="Officer Training"
                        color="danger"
                        now={getISOReport.data.officerTraining}
                        maxVal={getISOReport.data.maxOfficerTraining}
                      ></ProgressLabel>

                      <ProgressLabel
                        label="HAZMAT Training"
                        color="dark"
                        now={getISOReport.data.hazmatTraining}
                        maxVal={getISOReport.data.maxHazmatTraining}
                      ></ProgressLabel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default Dashboard;
