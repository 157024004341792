import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import { usePaging } from "../Common/Pagination";
import CredentialImportDialog from "./CredentialImportDialog";
import CredentialExpirationDateCell from "./CredentialExpirationDateCell";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { GetCredStatusEnumStatusLabel } from "./CredentialList";
import { useGridFilters } from "../../stores/SystemStore";
import LoadMoreIndicator from "../Common/LoadMoreIndicator";

function CredentialListByLastModified(props: RouteComponentProps<any>) {
  const employeeid = props.match?.params?.employeeid;
  const [claims] = useState(commonService.friendlyClaims);
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentId > 0) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [list, setList] =
    useState<ApsModels.ICredCredentialByLastModifiedGridOutputDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridFilters.init(params);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && list) {
      setTimeout(() => {
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach(function (column: any) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
        gridApi.sizeColumnsToFit();
      }, 500);
    }
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto;
  }) => {
    if (claims.credentials.canViewAll) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-outlined text-primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.history.push(
              `/credentials/credential/${rowProps.data.credentialId}`
            );
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
      );
    }
    return <span></span>;
  };

  const NameCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.history.push(
            `/credentials/credential/${rowProps.data.credentialId}`
          );
        }}
      >
        {rowProps.data.credentialName}
      </span>
    );
  };

  const EmpNameCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.history.push(`/credentials/employee/${rowProps.data.userId}`);
        }}
      >
        {rowProps.data.employeeName}
      </span>
    );
  };

  const ExpirationCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto;
  }) => {
    if (!rowProps.data.dateOfExpiration) {
      return <span>Not Applicable</span>;
    }
    return (
      <CredentialExpirationDateCell
        date={rowProps.data.dateOfExpiration}
        defaultClass="alert alert-danger"
      ></CredentialExpirationDateCell>
    );
  };

  const StatusCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto;
  }) => {
    if (rowProps.data.status === ApsModels.CredStatusEnum.Active) {
      return (
        <small className="alert alert-success" style={{ padding: "0 5px" }}>
          Active
        </small>
      );
    }
    if (rowProps.data.status === ApsModels.CredStatusEnum.InProgress) {
      return (
        <small className="alert alert-warning" style={{ padding: "0 5px" }}>
          In Progress
        </small>
      );
    }
    if (rowProps.data.status === ApsModels.CredStatusEnum.Expired) {
      return (
        <small className="alert alert-danger" style={{ padding: "0 5px" }}>
          Expired
        </small>
      );
    }
    if (rowProps.data.status === ApsModels.CredStatusEnum.Archived) {
      return (
        <small className="alert alert-secondary" style={{ padding: "0 5px" }}>
          Archived
        </small>
      );
    }
    return <span></span>;
  };

  const AttachmentCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto;
  }) => {
    if (rowProps.data.hasAttachment) {
      return (
        <span className="alert alert-success" style={{ padding: "0 5px" }}>
          Yes
        </span>
      ); // <FontAwesomeIcon icon={faFile} />;
    }
    return (
      <span className="alert alert-danger" style={{ padding: "0 5px" }}>
        No
      </span>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          return true;
        },
        field: "_selected",
        headerName: "",
        ...commonService.agGridHelpers.checkboxColDef,
      },
      {
        field: "lastName",
        headerName: "Last Name",
      },
      {
        field: "firstName",
        headerName: "First Name",
      },
      {
        field: "credentialName",
        headerName: "Credential Name",
        cellRenderer: "nameCellComponent",
      },
      {
        field: "credentialCategory",
        headerName: "Credential Category",
      },
      {
        field: "expirationDateString",
        headerName: "Active Expiration Date",
        cellRenderer: "expirationCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: "dueDateString",
        headerName: "In Progress Due Date",
        ...commonService.agGridHelpers.dateValueFormatter(
          "dueDate",
          "Not Applicable"
        ),
      },
      {
        field: "percentageComplete",
        headerName: "% Complete",
        valueFormatter: (param: {
          data: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto;
        }) => {
          return (
            commonService.toNumberWithComma(param.data.percentageComplete) + "%"
          );
        },
      },
      {
        field: "hasAttachmentYesNo",
        headerName: "Attachment",
        cellRenderer: "attachmentCellComponent",
      },
      {
        field: "lastModifiedString",
        headerName: "Last Modified Date",
        ...commonService.agGridHelpers.dateValueFormatter("lastModified"),
      },
      {
        field: "statusName",
        headerName: "Status",
        cellRenderer: "statusCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: "credentialCode",
        headerName: "Credential Code",
        hide: true,
      },

      { field: "groups", headerName: "Groups", hide: true },
      { field: "rank", headerName: "Rank", hide: true },
      { field: "employeeId", headerName: "EID", hide: true },
      { field: "supervisor", headerName: "Supervisor", hide: true },
      { field: "email", headerName: "Email Address", hide: true },

      { field: "library", headerName: "library", hide: true },
      { field: "hours", headerName: "Hours", hide: true },
      { field: "hoursCompleted", headerName: "Hours Completed", hide: true },
      {
        field: "periodOfValidity",
        headerName: "Period of Validity",
        hide: true,
      },
      { field: "stateIssued", headerName: "State Issued", hide: true },
      {
        field: "effectiveDateString",
        headerName: "Effective Date",
        ...commonService.agGridHelpers.dateValueFormatter(
          "effectiveDate",
          "Not Applicable"
        ),
        hide: true,
      },
      { field: "lastModifiedBy", headerName: "Last Modified By", hide: true },

      {
        field: "action",
        headerName: "Action",
        cellRenderer: "viewButtonCellComponent",
        width: 100,
        ...commonService.agGridHelpers.actionColPinnedDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      expirationCellComponent: ExpirationCellComponent,
      nameCellComponent: NameCellComponent,
      statusCellComponent: StatusCellComponent,
      empNameCellComponent: EmpNameCellComponent,
      attachmentCellComponent: AttachmentCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ICredCredentialByLastModifiedGridItemOutputDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const [member, setMember] = useState<{
    name: string;
    id: number;
  }>();
  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setUsers([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await ApsServices.http.credCommon
      .credentialsTypeAheadUser({
        search: query,
        isAnd: false,
        recordCount: 10,
        rankId: null as any,
        shiftId: null as any,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));

        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const [filters, setFilters] =
    useState<ApsModels.ICredCredentialByCredentialSearchInputDto>({
      userId: null as any,
      credStatusEnum: null as any,
      isMyReportsOnly: false,
      byLastModified: true,
      credentialId: null as any,
    });

  const [isMyReportsOnly, setIsMyReportsOnly] = useState(true);

  const [loading, setLoading] = useState(false);
  const gridFilters = useGridFilters(
    "all-credentials-table",
    gridApi,
    (others) => {
      if (others) {
        setIsMyReportsOnly(
          others.isMyReportsOnly === undefined ? true : others.isMyReportsOnly
        );
        if (others.userId) {
          setMember({
            id: others.userId,
            name: others.userName,
          });
          setFilters((prev) => {
            return {
              ...prev,
              userId: others.userId,
            };
          });
        }
      }
    }
  );
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageToLoad, setPageToLoad] = useState(1);

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();
    setLoading(true);
    await ApsServices.http.credCredential //paging.page, paging.pageSize,
      .listCredentialsByLastModified(1, commonService.gridPageSize.firstPage, {
        userId: filters.userId || (null as any),
        credStatusEnum: null as any, //ApsModels.CredStatusEnum.Active,
        isMyReportsOnly: isMyReportsOnly,
        credentialId: null as any,
      })
      .then((data) => {
        setList(data as any);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        }

        gridFilters.saveOthers({
          userId: filters.userId || (null as any),
          userName: member?.name,
          credStatusEnum: null as any,
          isMyReportsOnly: isMyReportsOnly,
          credentialId: null as any,
        });
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Credentials", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);
      });
  };

  const loadMore = async () => {
    if (
      list &&
      list.totalRecords >
        list.credCredentialByLastModifiedGridItemOutputDtos?.length
    ) {
      setLoadingMore(true);
      setLoading(false);

      let pageNum = pageToLoad + 1;
      if (
        commonService.gridPageSize.firstPage <
          commonService.gridPageSize.otherPages &&
        list.credCredentialByLastModifiedGridItemOutputDtos?.length ===
          commonService.gridPageSize.firstPage
      ) {
        pageNum = 1;
      }

      setPageToLoad(pageNum);
      await ApsServices.http.credCredential
        .listCredentialsByLastModified(
          pageNum,
          commonService.gridPageSize.otherPages,
          {
            userId: filters.userId || (null as any),
            credStatusEnum: null as any,
            isMyReportsOnly: isMyReportsOnly,
            credentialId: null as any,
          }
        )
        .then((data) => {
          setList((prev) => {
            return {
              totalRecords: prev?.totalRecords || data.totalRecords,
              credCredentialByLastModifiedGridItemOutputDtos:
                pageNum === 1
                  ? data.credCredentialByLastModifiedGridItemOutputDtos
                  : [
                      ...(prev?.credCredentialByLastModifiedGridItemOutputDtos ||
                        []),
                      ...data.credCredentialByLastModifiedGridItemOutputDtos,
                    ],
            };
          });
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Credentials", err);
          setLoadingMore(false);
        })
        .finally(() => {
          setLoadingMore(false);
          setLoading(false);
        });
    } else {
      gridFilters.reApplyFilters(); //need to reapply filter after adding more data
      toggleColSizes();
      setLoadingMore(false);
      setLoading(false);
    }
  };

  const [rowsSelectedCount, setRowSelectedCount] = useState(0);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (loading || loadingMore) {
      return;
    }
    if (list?.totalRecords) {
      gridApi && gridApi.hideOverlay();
    }
    setGridState({
      ...gridState,
      rowData: (list?.credCredentialByLastModifiedGridItemOutputDtos || []).map(
        (row) => {
          return {
            ...row,
            lastName: row.employeeName.split(",")[0] || "",
            firstName: row.employeeName.split(",")[1] || "",
            hasAttachmentYesNo: row.hasAttachment ? "Yes" : "No",
          };
        }
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading, loadingMore]);

  useEffect(() => {
    if (gridApi && gridFilters.ready) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, paging, filters, isMyReportsOnly, gridFilters.ready]);

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    return gridApi.getSelectedRows().filter((row) => {
      return (
        !isAnyFilterPresent ||
        !!filteredRows.find(
          (r: any) => r.data.credentialId === row.credentialId
        )
      );
    });
  };

  const canAddCredentials = () => {
    if (claims.credentials.canAddAll) {
      return true;
    }
    return (
      claims.credentials.canAccess &&
      (!employeeid ||
        (Number(employeeid) === commonService.userInfo.userId &&
          claims.credentials.canEditOwn))
    );
  };

  const [showImport, setShowImport] = useState(false);
  const [exporting, setExporting] = useState(false);
  const startExport = async () => {
    gridFilters.exportCSV();
    /** DISABLE BACKEND EXPORT 
    setExporting(true);
    await ApsServices.http.credCredential
      .extractCredentialsByLastModified({
        ids: getSelectedRows().map((r) => r.credentialId),
      })
      .then((data) => {
        const file = commonService.b64toBlob(data.fileContents, "text/plain");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((error) => {
        toastStore.showError("Failed Exporting Credentials", error);
      })
      .finally(() => {
        setExporting(false);
      });
      **/
  };

  return (
    <>
      {showImport && (
        <CredentialImportDialog
          onClose={(e) => {
            setShowImport(false);
          }}
        ></CredentialImportDialog>
      )}
      <div className="flex flex-col h-100 p-4">
        <div
          className="flex flex-row mb-3"
          style={{ flexWrap: "wrap", gap: "10px" }}
        >
          <div
            className="flex-1 flex"
            style={{
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              minWidth: "250px",
            }}
          >
            <div className="mr-2">
              {/* {!claims.credentials.canAddAll && (
                <button
                  className="btn btn-outline-secondary"
                  onClick={(e) => {
                    startExport();
                  }}
                  disabled={rowsSelectedCount === 0 || exporting}
                >
                  {exporting
                    ? `Exporting...`
                    : `Export to CSV (${rowsSelectedCount})`}
                </button>
              )} */}
              <Dropdown drop="down">
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id={`ddMenu-${gridFilters.props._key}Picker`}
                  disabled={loading}
                >
                  {exporting ? "Exporting..." : "Export or Import"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) => {
                      gridFilters.exportAllCSV();
                    }}
                  >
                    Export All Data
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      gridFilters.exportCSV();
                    }}
                  >
                    Export Visible Data
                  </Dropdown.Item>
                  {claims.credentials.canAddAll && (
                    <Dropdown.Item
                      onClick={(e) => {
                        setShowImport(true);
                      }}
                    >
                      Import CSV
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {claims.credentials.canViewAll && (
              <>
                <div>
                  <strong>Filter</strong>
                </div>

                <div style={{ minWidth: "200px" }}>
                  <AsyncTypeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onSearch={handleSearch}
                    onChange={(data) => {
                      if (data && data.length > 0) {
                        setMember({
                          id: data[0].id,
                          name: data[0].name,
                        });
                        setFilters((f) => {
                          return {
                            ...f,
                            userId: data[0].id,
                          };
                        });
                        setUsers(data);
                        (ref.current as any)?.clear();
                      }
                    }}
                    searchText={"Searching..."}
                    isLoading={isLoading}
                    options={users}
                    placeholder="Search User"
                    minLength={1}
                    delay={500}
                    useCache={false}
                    ref={ref}
                  />
                </div>
                {!!member && (
                  <div className="alert alert-secondary m-0 p-2">
                    {member?.name}{" "}
                    <i
                      className="fa fa-times ml-2 pointer"
                      onClick={() => {
                        setMember(undefined);
                        setFilters((f) => {
                          return {
                            ...f,
                            userId: null as any,
                          };
                        });
                      }}
                    ></i>
                  </div>
                )}
              </>
            )}

            {claims.credentials.canViewAll && (
              <div className="flex flex-center">
                <strong className="pr-2">View</strong>
                <Dropdown drop="down">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdownCredentialsViewFilter"
                  >
                    {isMyReportsOnly ? "My Credentials" : "All Credentials"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right">
                    <Dropdown.Item
                      onClick={(e) => {
                        setIsMyReportsOnly(true);
                      }}
                    >
                      My Credentials
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setIsMyReportsOnly(false);
                      }}
                    >
                      All Credentials
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            <div>
              {/* <button
                type="button"
                className="btn btn-outline-danger"
                onClick={(e) => {
                  setMember(undefined);
                  setIsMyReportsOnly(true);
                  gridFilters.reset();
                  toggleColSizes();
                }}
              >
                Reset
              </button> */}
            </div>

            <div>{/* <strong>Filter</strong> */}</div>

            {/* <button
                className="btn btn-outline-secondary"
                onClick={getList}
                disabled={loading}
              >
                <i className="fa fa-sync"></i>
              </button> */}

            {/* <Dropdown drop="down">
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdownTeamsFilter"
                disabled={loading}
              >
                All Teams
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>To Do...</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>

          <div>
            {canAddCredentials() && (
              <Button
                variant="primary"
                type="button"
                onClick={(e) => {
                  //setShowAddCred(true);
                  props.history.push(`/credentials/new`);
                }}
              >
                Add Credential
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              minHeight: "300px",
            }}
            className="ag-theme-alpine flex-1"
          >
            <AgGridReact
              columnDefs={gridState.columnDefs}
              defaultColDef={gridState.defaultColDef}
              autoGroupColumnDef={gridState.autoGroupColumnDef}
              enableRangeSelection={true}
              animateRows={true}
              onGridReady={onGridReady}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={false}
              suppressRowDeselection={true}
              suppressRowClickSelection={true}
              frameworkComponents={gridState.frameworkComponents}
              rowData={(gridState.rowData || []).map(
                (
                  row: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto
                ) => {
                  return {
                    ...row,
                    statusName: GetCredStatusEnumStatusLabel(row.status),
                    dueDateString: commonService.agGridHelpers.toExportDate(
                      row.dueDate
                    ),
                    expirationDateString:
                      commonService.agGridHelpers.toExportDate(
                        row.dateOfExpiration
                      ),
                    effectiveDateString:
                      commonService.agGridHelpers.toExportDate(
                        row.effectiveDate
                      ),
                    lastModifiedString:
                      commonService.agGridHelpers.toExportDate(
                        row.lastModified
                      ),
                  };
                }
              )}
              onRowDoubleClicked={(event: {
                data: ApsModels.ICredCredentialByLastModifiedGridItemOutputDto;
              }) => {
                if (claims.credentials.canViewAll) {
                  props.history.push(
                    `/credentials/credential/${event.data.credentialId}`
                  );
                }
              }}
              overlayLoadingTemplate={gridState.overlayLoadingTemplate}
              overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
              onRowSelected={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onRowDataChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onFilterChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              {...gridFilters.props}
            />
          </div>
        </div>
        <LoadMoreIndicator
          loadingMore={loadingMore}
          currentCount={
            list?.credCredentialByLastModifiedGridItemOutputDtos?.length
          }
          totalExpected={list?.totalRecords}
        ></LoadMoreIndicator>
        {/* {
          <Pagination
            length={list?.totalRecords || 0}
            page={paging.page}
            pageSize={paging.pageSize}
            pageChange={pageChange}
            showingOfWhatLabel="Records"
            sizes={[10, 20, 50, 100]}
          ></Pagination>
        } */}
      </div>
    </>
  );
}

export default CredentialListByLastModified;
