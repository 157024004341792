import { makeAutoObservable, reaction } from "mobx";
import { useEffect, useState } from "react";
import { IBaseDOCComponent } from "../../components/DailyOperationalDesigner/ComponentGalleryDefinitions";
import { NfirsFetchStatus } from "../components/NFIRS/NFIRSHelper";
import ApsModels from "../models";
import commonService from "../services/CommonService";

interface IAddComponent {
  component: IBaseDOCComponent;
  columnId: string;
}

class DesignerStore {
  private _addComponent?: IAddComponent;

  get addComponent() {
    return this._addComponent;
  }

  constructor() {
    makeAutoObservable(this);
  }

  clearAddComponent() {
    this._addComponent = undefined;
  }

  setAddComponent(data?: IAddComponent) {
    if (data) {
      this._addComponent = {
        ...(this._addComponent || {}),
        ...data,
      };
    } else {
      this._addComponent = undefined;
    }
  }
}

const designerStore = new DesignerStore();
export default designerStore;

export const useAddComponent = () => {
  const [addComponent, setAddComponent] = useState(designerStore.addComponent);
  useEffect(() => {
    const disposer = reaction(
      () => designerStore.addComponent,
      (n, p, i) => {
        setAddComponent(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return addComponent;
};
