import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSBasicDatesService {
  get = async (id: any) => {
    const url = `/api/NfirsBasicDate/${id}`;
    return fetcher.get<ApsModels.INfirsBasicDateOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsBasicDateInputDto) => {
    const url = `/api/NfirsBasicDate`;
    return fetcher.post<ApsModels.INfirsBasicDateOutputDto>(url, data);
  };
}

const nfirsBasicDatesService = new NFIRSBasicDatesService();
export default nfirsBasicDatesService;
