import React, { Component } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { DepartmentDto, DepartmentSummaryDto, ModulesDto } from "../../../data/Department";
import { DepartmentService } from "../../../services/DepartmentService";
import noImage from "../../../assets/images/noimage.png";
import './Departments.scss'
import DrillTemplate from "../../../aps2/components/Drills/DrillTemplate";
import RouteChangedGuard from "../../../aps2/components/Common/RouteChangedGuard";
import ConfirmDialog from "../../../aps2/components/Common/ConfirmDialog";
import commonService from "../../../aps2/services/CommonService";
import { UserConsoleAdmin } from "./UserConsoleAdmin";
import { SSOAdmin } from "./SSOAdmin";
import { AppContext } from '../../../AppContext';
import ApsModels from "../../../aps2/models";
import ApsServices from "../../../aps2/services";
import DailyOperationalDesigner from "../../DailyOperationalDesigner/DailyOperationalDesigner";
import ShiftScheduler from "../../ShiftScheduler/ShiftScheduler";
import moment from "moment";
import toastStore from "../../../aps2/stores/ToastStore";
import CommonSpinner from "../../../aps2/components/Common/CommonSpinner";
import CredDepartmentSettings from "../CredDepartmentSettings/CredDepartmentSettings";
import CustomIframeSettings from "./CustomIframeSettings";

type ParamsId = { id?: string };

type Props = RouteComponentProps<ParamsId> & {}

const CustomIframeDefaultName = "Custom iframe (Special access)";

type State = {
    name: string,
    departmentCode: string,
    learnUponUrl: string,
    customIframeSettings?: CustomIframeSettings,
    showCustomIframeSettings: boolean
    timeZoneId?: number,
    isActive: boolean,
    reload: boolean,
    appsModules: ModulesDto[] | null,
    allTimeZones: ApsModels.ITimeZoneDto[] | null,
    logo: string,
    collapse: boolean,
    originalData: any,
    hasLogChanges: boolean,
    showDiscardOthers: boolean,
    ignoreChanges: boolean,
    ready: boolean,
    hasUCASectionChanges: boolean,
    hasUCALinkChanges: boolean,
    
    //LOAD DEMO
    allowLoadDemoData?: boolean,
    lastDemoDataLoaded?: Date,
    demoDataStatus?: ApsModels.DemoDataStatusEnum,
    gettingDemoDataInfo?: boolean,
    confirmLoadDemoData?: boolean,
}
export class DepartmentsActions extends Component<Props, State> {
    static displayName = DepartmentsActions.name;
    static contextType = AppContext;

    state: State = {
        name: "",
        departmentCode: "",
        learnUponUrl: "",
        customIframeSettings: undefined,
        showCustomIframeSettings: false,
        timeZoneId: 1,
        isActive: true,
        reload: false,
        appsModules: null,
        allTimeZones: null,
        logo: "",
        collapse: false,
        originalData: {},
        hasLogChanges: false,
        showDiscardOthers: false,
        ignoreChanges: false,
        ready: false,
        hasUCASectionChanges: false,
        hasUCALinkChanges: false
    }

    handleDepartmentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ name: e.target.value })
    }

    handleDepartmentCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ departmentCode: e.target.value })
    }

    handleTimeZoneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ timeZoneId: parseInt(e.target.value) })
    }

    handleUrlLMSDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ learnUponUrl: e.target.value })
    }

    handleCustomIframeUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({customIframeSettings: {...this.state.customIframeSettings!, iframeLink: e.target.value}})
    }

    handleCustomIframeLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const modules = [...this.state.appsModules!];
        const customIframeModule = modules.find(x => x.name === this.state.customIframeSettings?.iframeModuleName);
        if (customIframeModule !== undefined) {
            customIframeModule.name = e.target.value
        }
        this.setState({
            customIframeSettings: {...this.state.customIframeSettings!, iframeModuleName: e.target.value},
            appsModules: modules
        })
    }

    handleIsActiveChange = () => {
        this.setState({ isActive: !this.state.isActive })
    }

    handleReload = () => {
        const showCustomIframeSettings = this.state.appsModules?.find(x => x.name === this.state.customIframeSettings?.iframeModuleName)?.isSelected === true;
        this.setState({ reload: !this.state.reload, showCustomIframeSettings })
    }

    handleModulesChange = (modules: ModulesDto[]) => {
        this.setState({ appsModules: modules, showCustomIframeSettings: this.canAccessCustomIframe() })
    }

    validationUrl = (): boolean => {
        if (!!this.state.appsModules?.find(x => x.name === "Training Portal")?.isSelected === true) {
            if (this.state.learnUponUrl.endsWith(".com")
                && !this.state.learnUponUrl.startsWith("http")
                && !this.state.learnUponUrl.startsWith("www")
            ) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    }

    validationSubmit = (): boolean => {
        if (this.state.name && this.validationUrl() && this.state.logo) {
            return false;
        }
        return true;
    }

    // This puts our modules in the correct order with the correct display names we desire on the front end
    orderModules = (modules: ModulesDto[]): ModulesDto[] => {

        // key value is the actual name of the module, right column is the desired name.. Order here is also different than module order in DB.
        const modulesMap = Object.entries({
            "Dashboard": "Home",
            "User Console": "Links",
            "Daily Operational": "Daily Operational",
            "Custom IFrame": this.state.customIframeSettings?.iframeModuleName || CustomIframeDefaultName,
            "Assignments": "Assignments",
            "Training Portal": "Online training (Special access)",
            "Log Drills": "Drills",
            "Course Records": "Courses",
            "Credentials": "Credentials",
            "NFIRS": "NFIRS",
            "Personnel": "Personnel",
            "Data Visualization": "Data Visualization",
            "ISO Training": "Reports",
            "Templates": "Templates",
            "Notifications": "Notifications"
        });

        let orderedModules: ModulesDto[] = new Array();
        for (var i = 0; i < modulesMap.length; i++) {
            const res = modules.find(m => m.name == modulesMap[i][0]);
            if (res) {
                res.name = modulesMap[i][1];
                orderedModules.push(res);
            }
        }
        return orderedModules;
    }


    getModules = async (): Promise<ModulesDto[]> => {
        let response: ModulesDto[]
        let department: DepartmentSummaryDto;
        if (this.props.match.params.id) {
            response = await DepartmentService.getModulesByDepartmentId(Number(this.props.match.params.id))
            response = this.orderModules(response);
            department = await DepartmentService.getDepartmentById(Number(this.props.match.params.id))
            this.setState({
                name: department.name,
                departmentCode: department.departmentCode,
                learnUponUrl: department.learnUponUrl,
                timeZoneId: department.timeZoneId,
                isActive: department.isActive,
                logo: department.logo,
                originalData: commonService.deepCloneJsonObject({ ...department, appsModules: response }),
                ready: true
            })
        } else {
            response = await DepartmentService.getModules();
            response = this.orderModules(response);
            this.setState({
                originalData: commonService.deepCloneJsonObject({ ...this.state, appsModules: response }),
                ready: true
            });
        }
        return response;
    }

    getAllTimeZones = async() => {
        var response = await ApsServices.http.timezone.getAll();
        this.setState({
            allTimeZones: response
        });
    }

    hasChanges = () => {
        const orig = this.state.originalData as State;
        const cur = this.state;
        if (!this.state.ready || !cur || !this.state.originalData) {
            return false;
        }
        return orig.name !== cur.name ||
            orig.departmentCode !== cur.departmentCode ||
            orig.timeZoneId != cur.timeZoneId ||
            orig.learnUponUrl !== cur.learnUponUrl ||
            orig.isActive !== cur.isActive ||
            orig.logo !== cur.logo ||
            (orig.appsModules || []).length !== (cur.appsModules || []).length ||
            JSON.stringify((orig.appsModules || [])) !== JSON.stringify((cur.appsModules || []));
    }

    reset = () => {
        let arr = commonService.deepCloneJsonObject({ temp: this.state.originalData.appsModules || [] }).temp;
        this.setState({
            name: this.state.originalData.name,
            departmentCode: this.state.originalData.departmentCode,
            timeZoneId: this.state.originalData.timeZoneId,
            learnUponUrl: this.state.originalData.learnUponUrl,
            isActive: this.state.originalData.isActive,
            logo: this.state.originalData.logo,
        });
        this.handleModulesChange(arr);
    }

    getConfirmMessage() {
        const sections: string[] = [];
        if (this.hasChanges()) {
            sections.push("Department Settings");
        }
        if (this.state.hasLogChanges && this.canAccessLogDrills()) {
            sections.push("Log Drills");
        }
        if (this.state.hasUCASectionChanges || this.state.hasUCALinkChanges) {
            sections.push("User Console Admin");
        }
        if (sections.length === 0) {
            return "";
        }
        const msg = `You have unsaved changes to the following section(s), 
        do you really want to leave the page and discard changes?
        <br/><br/>
        ${sections.map((s, i) => `${i + 1}. ${s}`).join('<br/>')}`;
        return msg;
    }

    getConfirmMessageSaveSettings() {
        const sections: string[] = [];
        if (this.state.hasLogChanges && this.canAccessLogDrills()) {
            sections.push("Log Drills");
        }
        if (sections.length === 0) {
            return "";
        }
        const msg = `Saving the Settings section will reload the application. Do you want to discard unsaved changes in the following section(s)?
        <br/><br/>
        ${sections.map((s, i) => `${i + 1}. ${s}`).join('<br/>')}`;
        return msg;
    }

    saveCustomIframeSettings = async () => {
        await ApsServices.http.customIframe.save(
            this.state.customIframeSettings as any
        ).then(() => {
            toastStore.showToast("Custom Iframe Settings saved successfully", "success")
        }).catch((error) => {
            toastStore.showError("Custom Iframe Settings were not able to be saved", error);
        });
    }

    updateDepartment = async () => {
        await this.saveCustomIframeSettings();
        const modules: ModulesDto[] = new Array<ModulesDto>();
        this.state.appsModules?.map(x => {
            modules.push({ moduleId: x.moduleId, isSelected: x.isSelected, name: x.name })
        })
        const newDepartment: DepartmentDto = {
            departmentId: Number(this.props.match.params.id),
            name: this.state.name,
            departmentCode: this.state.departmentCode,
            learnUponUrl: this.state.learnUponUrl,
            timeZoneId: this.state.timeZoneId,
            isActive: this.state.isActive,
            modules: modules,
            logo: this.state.logo
        }
        await DepartmentService.save(newDepartment);
        this.props.history.push("/admin/departments" + commonService.doneUrls.submitted);
    }

    insertDepartment = async () => {
        await this.saveCustomIframeSettings();
        const modules: ModulesDto[] = new Array<ModulesDto>();
        this.state.appsModules?.filter(x => x.isSelected).map(x => {
            modules.push({
                moduleId: x.moduleId,
                name: x.name,
                isSelected: true
            })
        })
        const newDepartment: DepartmentDto = {
            name: this.state.name,
            departmentCode: this.state.departmentCode,
            learnUponUrl: this.state.learnUponUrl,
            timeZoneId: this.state.timeZoneId,
            isActive: this.state.isActive,
            modules: modules,
            logo: this.state.logo
        }
        await DepartmentService.save(newDepartment);
        this.props.history.push("/admin/departments" + commonService.doneUrls.created);
    }

    submit = async () => {
        if (this.state.hasLogChanges && this.canAccessLogDrills()) {
            this.setState({ showDiscardOthers: true });
            return;
        }
        this.doSubmit();
    }

    doSubmit = async () => {
        this.setState({ ignoreChanges: true }); //will need to be updated when error handling is added eventually in saving Dept Settings
        if (this.props.match.params.id) {
            await this.updateDepartment();
        } else {
            await this.insertDepartment()
        }
    }

    goBack = () => {
        this.props.history.push("/admin/departments")
    }

    async componentDidMount() {
        await this.initializeCustomIframeSettings();
        const modules = await this.getModules();
        await this.getAllTimeZones();
        this.handleModulesChange(modules);
        this.handleReload();
        this.checkIfAllowLoadDemoData();
    }

    initializeCustomIframeSettings = async () => {
        const customIframeSettings = await ApsServices.http.customIframe.get(Number(this.props.match.params.id));
        this.setState({
            customIframeSettings: { 
                ...customIframeSettings,
                iframeModuleName: customIframeSettings.iframeModuleName || CustomIframeDefaultName,
                iframeLink: customIframeSettings.iframeLink || ""
            }
        })
    }

    uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const photo = e.target.files?.[0];
        if (photo) {
            try {
                var maxSize = 400;
                var fileSize = photo.size / maxSize;
                if (fileSize > maxSize) {
                    alert("The file size is too large");
                    return;
                }
                const base64: string | ArrayBuffer | null = await this.transformBase64(photo)
                this.setState({ logo: base64 as string })
            } catch (error) {
                alert("This format is not correct")
            }
        }
    }

    transformBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
            if (!(/\.(jpg|png|gif)$/i).test(file.name)) {
                reject("The file is not an image");
            }
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = (event: Event) => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        })
    }

    canAccessLogDrills = () => {
        return !!this.state?.appsModules?.find(m => m.isSelected && m.name === "Drills");
    }

    canAccessCredentials = () => {
        return !!this.state?.appsModules?.find(m => m.isSelected && m.name === "Credentials");
    }

    canAccessShiftScheduler = () => {
        return !!this.state?.appsModules?.find(m => m.isSelected && m.name === "Daily Operational");
    }

    canAccessCustomIframe = () => {
        return !!this.state?.appsModules?.find(m => m.isSelected && m.name === this.state.customIframeSettings?.iframeModuleName); // we have to use the name it is mapped to rather than Custom IFrame which the actual module is named
    }

    canAccessDailyOperational = () => {
        const departmentId = Number(this.props.match.params.id);
        if(!commonService.shouldUseNewDOC(departmentId)) {
            return false;
        }        
        return !!this.state?.appsModules?.find(m => m.isSelected && m.name === "Daily Operational");
    }

    canAccessOnlineTraining = () => {
        return !!this.state?.appsModules?.find(m => m.isSelected && m.name === "Online training (Special access)")
    }

    canAccessLinks = () => {
        return !!this.state?.appsModules?.find(m => m.isSelected && m.name === "Links")
    }

    checkIfAllowLoadDemoData = async () => {
        const departmentId = Number(this.props.match.params.id);
        if(departmentId) { 
            this.setState({  gettingDemoDataInfo: true });
            await ApsServices.http.demoData.getInformation(departmentId)
                .then(data => {
                    this.setState({ 
                        allowLoadDemoData: data.canDisplayButton || false,
                        demoDataStatus: data.status,
                        lastDemoDataLoaded: data.lastUpdated,
                        gettingDemoDataInfo: false
                    });
                })
                .catch(error => {
                    this.setState({  gettingDemoDataInfo: false });
                    toastStore.showError("Failed Getting Demo Data Information", error);
                });
        }
    }

    loadDemoData = async () => {
        const departmentId = Number(this.props.match.params.id);
        if(departmentId && this.state.allowLoadDemoData) { 
            this.setState({  demoDataStatus: ApsModels.DemoDataStatusEnum.InProgress });
            await ApsServices.http.demoData.loadAllData(departmentId)
                .then(data => {
                    this.setState({ 
                        allowLoadDemoData: data.canDisplayButton || false,
                        demoDataStatus: data.status,
                        lastDemoDataLoaded: data.lastUpdated,
                    });
                    toastStore.showToast("Loading of demo data has been initiated, this might take up to 15 minutes.", "info");
                })
                .catch(error => {
                    this.setState({  demoDataStatus: ApsModels.DemoDataStatusEnum.NotLoaded });
                    toastStore.showError("Failed Initiating Loading Demo Data", error);
                });
        }        
    }

    render() {
        return (
            <div className="container-fluid">

                <RouteChangedGuard
                    when={true}
                    navigate={path => this.props.history.push(path)}
                    shouldBlockNavigation={location => {
                        return !this.state.ignoreChanges && ((this.state.hasLogChanges && this.canAccessLogDrills()) || this.hasChanges() || (this.state.hasUCASectionChanges || this.state.hasUCALinkChanges));
                    }}
                    message={this.getConfirmMessage()}
                />

                <ConfirmDialog
                    show={this.state.showDiscardOthers}
                    buttons="yesno"
                    title="Confirmation"
                    message={this.getConfirmMessageSaveSettings()}
                    done={rtn => {
                        if (rtn === "yes") {
                            this.doSubmit();
                            this.setState({ showDiscardOthers: false, hasLogChanges: false });
                        } else {
                            this.setState({ showDiscardOthers: false });
                        }
                    }}></ConfirmDialog>

                <ConfirmDialog
                    show={this.state.confirmLoadDemoData}
                    buttons="yesno"
                    title="Confirmation"
                    message={this.state.lastDemoDataLoaded ? "Do you really want to Reset Demo Data?" : "Do you really want to Load Demo Data?"}
                    done={rtn => {
                        if (rtn === "yes") {
                            this.loadDemoData();
                        } 
                        this.setState({ confirmLoadDemoData: false });
                    }}></ConfirmDialog>

                <div className="page-title-box">
                    <div className="max-w-full">
                        <div className="row">
                            <div className="col-4 col-xl-4">
                                <span className="h4 mb-0 font-size-18 float-left text-uppercase px-0">{this.props.match.params.id ? "Edit Department" : "Add Department"}</span>
                            </div>
                            <div className="col-8 col-xl-8">
                                {/* <button onClick={this.submit} disabled={this.validationSubmit()} className="btn btn-primary float-right">
                                    <span className="font-size-12">Submit</span>
                                </button> */}
                                <span className="float-right invisible" >&nbsp;&nbsp;</span>
                                <button onClick={this.goBack} className="btn btn-secondary float-right">
                                    <span className="font-size-12">Cancel and Close</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="container-fluid flex-card-container">

                                <div className="flex-0">
                                    <div className="headerControls">
                                        <div>
                                            <span className="pointer" onClick={() => this.setState({ collapse: !this.state.collapse })}>
                                                <i
                                                    className={`fa fa-chevron-${this.state.collapse ? "up" : "down"
                                                        } pl-2 pr-2`}
                                                ></i>
                                                <h4 className="pt-3 pl-2 m-0 inline-block">
                                                    Department Settings
                                                    {this.hasChanges() && <span style={{ color: "red" }}>*</span>}
                                                </h4>
                                            </span>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>

                                <div className={`row ${this.state.collapse ? "display-none" : ""}`}>
                                    <div className="col-12 col-xl-4">
                                        <div className="card" style={{ height: "92%" }}>
                                            <div className="card-body">
                                                <span>1. Department</span>
                                                <div className="mt-4"></div>
                                                <span>Department Name</span>
                                                <div className="mt-2"></div>
                                                <input type="text" className="col-12 department-input" onChange={this.handleDepartmentNameChange} value={this.state.name} />
                                                <div className="mt-2"></div>
                                                <span>Department ID</span>
                                                <div className="mt-1"></div>
                                                <input type="text" className="col-12 department-input" onChange={this.handleDepartmentCodeChange} value={this.state.departmentCode} />
                                                <div className="mt-2"></div>
                                                <span>Department Timezone</span>
                                                <div className="mt-1"></div>
                                                <select className="department-input w-100" id="slct-timezones" value={this.state.timeZoneId} onChange={this.handleTimeZoneChange}>
                                                    <option value={undefined}></option>
                                                    {this.state.allTimeZones?.map(tz => (
                                                        <option key={tz.id} value={tz.id}>{tz.name}</option>
                                                    ))}
                                                </select>
                                                <div className="mt-3"></div>
                                                <span>Upload Logo</span>
                                                <div>
                                                    <input type="file" id="files" className="hidden" onChange={async (e) => {
                                                        await this.uploadImage(e)
                                                    }}>
                                                    </input>
                                                </div>
                                                {!!this.state.logo ?
                                                    <label htmlFor="files" className="department-logo-edit mt-3 cursor-pointer rounded-md border-2 border-gray-400">
                                                        <img src={this.state.logo} />
                                                    </label> :
                                                    <label htmlFor="files" className="department-logo-edit mt-3 cursor-pointer rounded-md border-2 border-gray-400">
                                                        <img src={noImage} />
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-4">
                                        <div className="card" style={{ height: "92%" }}>
                                            <div className="card-body">
                                                <span>2. Status</span>
                                                <div className="mt-3"></div>
                                                <div className="container">
                                                    <div className="row">
                                                        <span className="float-right cursor-pointer" onClick={this.handleIsActiveChange}>Inactive</span>
                                                        <span className="float-right invisible" >&nbsp;&nbsp;</span>
                                                        <div className="custom-control custom-switch cursor-pointer">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input cursor-pointer"
                                                                id="customSwitch"
                                                                checked={this.state.isActive}
                                                                onChange={() => { }} />
                                                            <label className="custom-control-label cursor-pointer" htmlFor="customSwitch" onClick={this.handleIsActiveChange}></label>
                                                        </div>
                                                        <span className="mr-2 cursor-pointer" onClick={this.handleIsActiveChange}>Active</span>
                                                    </div>
                                                </div>
                                                {this.state.appsModules &&
                                                    <div className="container">
                                                        <div className="mt-3"></div>
                                                        <div className="row">
                                                            <span>Module Access</span>
                                                        </div>
                                                        <div className="mt-2"></div>
                                                        <div className="row">
                                                            <div className="virtual-scrolling border px-0">
                                                                <div className="mt-2"></div>
                                                                {this.state.appsModules.map((x, i) => {
                                                                    return (
                                                                        <React.Fragment key={i}>
                                                                            <div className="container">
                                                                                <div className="row px-1">
                                                                                    <div className="col-12">
                                                                                        <div className="row">
                                                                                            <input type="checkbox"
                                                                                                className="cursor-pointer"
                                                                                                onChange={event => {
                                                                                                    x.isSelected = event.target.checked;
                                                                                                    this.handleReload()
                                                                                                }}
                                                                                                id={`app_module${i}`}
                                                                                                checked={x.isSelected}
                                                                                            //defaultChecked={x.isSelected}                                                                                                                                                                                                
                                                                                            />
                                                                                            <label className="module-name m-0"
                                                                                                htmlFor={`app_module${i}`}
                                                                                                style={{ lineHeight: "13px" }}>
                                                                                                {x.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-3"></div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.allowLoadDemoData && !this.state.gettingDemoDataInfo &&
                                                <div className="py-4 flex flex-center">
                                                    <div className="pr-2">
                                                        <button type="button" className="btn btn-pill btn-primary" 
                                                            disabled={this.state.demoDataStatus === ApsModels.DemoDataStatusEnum.InProgress}
                                                            onClick={e => {
                                                                this.setState({ confirmLoadDemoData: true });
                                                            }}>
                                                            <span className="px-2">
                                                                {this.state.demoDataStatus === ApsModels.DemoDataStatusEnum.InProgress ? 
                                                                "Loading in Process..." :
                                                                <>{this.state.lastDemoDataLoaded ? "Reset Demo Data" : "Load Demo Data"}</>}
                                                            </span>
                                                        </button>
                                                        {!this.state.gettingDemoDataInfo && this.state.demoDataStatus === ApsModels.DemoDataStatusEnum.InProgress && <>
                                                            <button type="button" title="Refresh"
                                                                className="btn btn-pill btn-outline-primary ml-2" 
                                                                onClick={e => {
                                                                    this.checkIfAllowLoadDemoData();
                                                                }}>
                                                                <i className="fa fa-sync"></i>
                                                            </button>
                                                        </>}
                                                    </div>
                                                    <div className="flex-1">
                                                        {this.state.demoDataStatus === ApsModels.DemoDataStatusEnum.Failed && <>
                                                            <span className="pr-2 text-danger">Last Process Failed. Please try again.</span>
                                                        </>}
                                                        {this.state.demoDataStatus !== ApsModels.DemoDataStatusEnum.Failed && <>
                                                            {this.state.lastDemoDataLoaded && <>
                                                                <span className="pr-2">Last Updated:</span>
                                                                {moment.utc(this.state.lastDemoDataLoaded).local().format("MM/DD/YYYY hh:mm A")}                                                                
                                                            </>}
                                                        </>}
                                                    </div>
                                                </div>}
                                                {this.state.gettingDemoDataInfo && 
                                                    <div className="py-4 flex">
                                                        <div><CommonSpinner></CommonSpinner></div>
                                                        <div className="flex-1"></div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={!!this.state.appsModules?.find(x => x.name === "Online training (Special access)")?.isSelected === true ? "col-12 col-xl-4" : "d-none"}>
                                        <div className="card" style={{ height: "92%" }}>
                                            <div className="card-body">
                                                <span>3. Special Access</span>
                                                <div className="mt-4"></div>
                                                <span>Online Training | LMS Portal Domain</span>
                                                <div className="mt-2"></div>

                                                <div className="d-flex">
                                                    <input
                                                        type="text"
                                                        className="flex-grow-1 department-input"
                                                        onChange={this.handleUrlLMSDepartmentChange}
                                                        value={this.state.learnUponUrl}
                                                        placeholder="glendalefd.online-training-aps.software"
                                                    />
                                                </div>
                                                
                                                {this.state.showCustomIframeSettings && <>
                                                    <div className="mt-2"></div>
                                                    <span>Custom iframe | Menu Label</span>
                                                    <div className="mt-2"></div>
                                                    <div className="d-flex">
                                                        <input
                                                            type="text"
                                                            className="flex-grow-1 department-input"
                                                            onChange={this.handleCustomIframeLabelChange}
                                                            value={this.state.customIframeSettings?.iframeModuleName}
                                                            placeholder="Telestaff"
                                                        />
                                                    </div>

                                                    <div className="mt-2"></div>
                                                    <span>Custom iframe | iframe URL</span>
                                                    <div className="mt-2"></div>
                                                    <div className="d-flex">
                                                        <input
                                                            type="text"
                                                            className="flex-grow-1 department-input"
                                                            onChange={this.handleCustomIframeUrlChange}
                                                            value={this.state.customIframeSettings?.iframeLink}
                                                            placeholder="https://www.link.com"
                                                        />
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={`flex-0 ${this.state.collapse ? "display-none" : ""}`}>
                                    <div className="headerControls">
                                        <div></div>
                                        <div>
                                            {this.props.match?.params?.id &&
                                                <button type="button" className="btn btn-danger"
                                                    disabled={!this.hasChanges()} onClick={() => this.reset()}>
                                                    Reset
                                                </button>}
                                            <button onClick={this.submit} disabled={this.validationSubmit()} className="btn btn-primary">
                                                <span className="font-size-12">Save Section</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                {this.props.match.params.id && <div className="row" style={this.canAccessCredentials() ? {} : { display: "none" }}>
                    <div className="col-12">
                        <div className="card">                      
                            <CredDepartmentSettings departmentId={Number(this.props.match.params.id)}></CredDepartmentSettings>
                        </div>
                    </div>
                </div>}

                {this.props.match.params.id && <div className="row" style={this.canAccessDailyOperational() ? {} : { display: "none" }}>
                    <div className="col-12">
                        <div className="card">                      
                            <DailyOperationalDesigner departmentId={Number(this.props.match.params.id)}></DailyOperationalDesigner>
                        </div>
                    </div>
                </div>}

                {/* LOG DRILLS TEMPLATE */}
                {this.props.match.params.id && <div className="row" style={this.canAccessLogDrills() ? {} : { display: "none" }}>
                    <div className="col-12">
                        <div className="card">
                            <DrillTemplate id={this.props.match.params.id} hasChanges={val => {
                                this.setState({ hasLogChanges: val });
                            }} />
                        </div>
                    </div>
                </div>}



                {this.props.match.params.id && <div className="row" style={this.canAccessLinks() ? {} : { display: "none" }}>
                    <div className="col-12">
                        <div className="card">
                        <UserConsoleAdmin
                            departmentName={this.state.name}
                            departmentId={Number(this.props.match.params.id)}
                            hasSectionChanges={val => {
                                this.setState({ hasUCASectionChanges: val });
                            }}
                            hasLinkChanges={val=> {
                                this.setState({ hasUCALinkChanges: val });
                            }}
                        />
                        </div>
                    </div>
                </div>}

                {this.props.match.params.id && this.context.isAdmin && <div className="row" style={this.canAccessOnlineTraining() ? {} : { display: "none" }}>
                    <div className="col-12">
                        <div className="card">
                            <SSOAdmin departmentId={Number(this.props.match.params.id)} />
                        </div>
                    </div>
                </div>}

                
                {this.props.match.params.id && <div className="row" style={this.canAccessShiftScheduler() ? {} : { display: "none" }}>
                    <div className="col-12">
                        <div className="card">                      
                            <ShiftScheduler departmentId={Number(this.props.match.params.id)}></ShiftScheduler>
                        </div>
                    </div>
                </div>}

                {/* {this.props.match.params.id && <div className="row" style={this.canAccessCustomIframe() ? {} : { display: "none" }}>
                    <div className="col-12">
                        <div className="card">
                            <CustomIframeSettings departmentId={Number(this.props.match.params.id)}></CustomIframeSettings>
                        </div>
                    </div>
                </div>} */}
            
            </div>
        )
    }
}