import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSWildlandDetailService {
  get = async (id: any) => {
    const url = `/api/NfirsWildlandDetail/${id}`;
    return fetcher.get<ApsModels.INfirsWildlandDetailOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsWildlandDetailInputDto) => {
    const url = `/api/NfirsWildlandDetail`;
    return fetcher.post<ApsModels.INfirsWildlandDetailOutputDto>(url, data);
  };
}

const nfirsWildlandDetail = new NFIRSWildlandDetailService();
export default nfirsWildlandDetail;
