
import React, {
	useState,
	useEffect,
	useRef,
	TextareaHTMLAttributes, useContext,
} from "react";
import {AppContext} from "../AppContext";

const AutoTextArea = (props: TextareaHTMLAttributes<HTMLTextAreaElement>) => {

	const context = useContext(AppContext);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const [text, setText] = useState("");
	const [textAreaHeight, setTextAreaHeight] = useState("auto");
	const [parentHeight, setParentHeight] = useState("auto");

	useEffect(() => {
		setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
		setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
	}, [text]);

	const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setTextAreaHeight("auto");
		setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
		setText(event.target.value);

		if (props.onChange) {
			props.onChange(event);
		}
	};

	return (
		<div
			style={{
				minHeight: parentHeight,
				overflow: "hidden"
			}}
		>
			<textarea disabled={!context.isEditableDailyOperational}
				{...props}
				ref={textAreaRef}
				rows={3}
				style={{
					height: textAreaHeight,
				}}
				onChange={onChangeHandler}
			/>
		</div>
	);
};

export default AutoTextArea;
