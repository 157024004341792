import React, { useEffect, useState } from "react";
import ApsModels from "../../../../models";
import ApsServices from "../../../../services";
import commonService from "../../../../services/CommonService";
import nfirsStore from "../../../../stores/NFIRSStore";
import toastStore from "../../../../stores/ToastStore";
import CommonSpinner from "../../../Common/CommonSpinner";
import {
  INFIRSTabProps,
  useNfirsTabChangedOnClick,
  useSaveOnNext,
} from "../NFIRSEntry";
import {
  FgInput,
  FgSelect,
  FgUseForm,
  useFgModel,
} from "../../../Common/FormGroups";
import {
  NfirsFetchStatus,
  useNfirsGetData,
  useNfirsLookupEnum,
} from "../../NFIRSHelper";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import AdvancedDropdown from "../../../Common/AdvancedDropdown";

function NFIRSHazmatMobileProperty(props: INFIRSTabProps<any>) {
  const saveAction = () => {
    //do model pre-processing here
    return ApsServices.http.nfirsHazmatMobileProperty.update({
      ...model.new,
      year: model.new.year || (null as any),
    });
  };
  const {
    model,
    setModel,
    progress,
    setProgress,
    initModels,
    hasChanges,
    saveData,
  } = useFgModel<ApsModels.INfirsHazmatMobilePropertyInputDto>({
    objectName: "Mobile Property",
    nfirsSectionTab: "HAZMAT/MobileProperty",
    promise: saveAction,
    afterSave: (data) => {
      initModels({ ...model.new, concurrencyToken: data.concurrencyToken });
    },
  });

  const onModelChange = (
    newModel: ApsModels.INfirsHazmatMobilePropertyInputDto
  ) => {
    setModel({ ...model, new: { ...newModel } });
  };

  const submit = async (form: any) => {
    if (!hasChanges()) {
      if (!tabChanged.isSaveOnTabChanged) {
        nfirsStore.setMoveToNextTab();
      }
      tabChanged.setIsSaveOnTabChanged(false);
      return;
    }
    saveData(tabChanged.isSaveOnTabChanged);
  };

  const [cntLock, setCntLock] = useState(0);
  const tabChanged = useNfirsTabChangedOnClick(
    "HAZMAT",
    "MobileProperty",
    () => {
      triggerSubmitForm();
    }
  );
  useSaveOnNext("HAZMAT", "MobileProperty", (lock) => {
    setCntLock(lock ? cntLock + 1 : 0);
    if (lock) {
      return;
    }

    if (!hasChanges()) {
      nfirsStore.setMoveToNextTab();
      return;
    }

    triggerSubmitForm();
    if (!formState.isValid) {
      nfirsStore.resetMoveTabSection();
    }
  });

  useEffect(() => {
    if (cntLock) {
      triggerSubmitForm();
      setTimeout(() => {
        if (!formState.isValid) {
          nfirsStore.resetMoveTabSection();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cntLock]);

  useEffect(() => {
    if (!progress.loading && model?.new?.id !== null) {
      const mdl = JSON.stringify(model);
      if (hasChanges()) {
        props.onChange(mdl, true);
      } else {
        props.onChange(mdl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const types = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getMobilePropertyTypes(false),
    "Mobile Property Types"
  );
  const makes = useNfirsGetData(
    () => ApsServices.http.nfirsFavorite.getMobilePropertyMakes(false),
    "Mobile Property Makes"
  );
  const states = useNfirsGetData<ApsModels.ILookupStatesOutputDto[]>(
    async () => {
      return await ApsServices.http.genericLookupService.getStates(1);
    },
    "States"
  );
  const typeSections = useNfirsLookupEnum(
    "MobilePropertyTypeSectionEnum",
    "Mobile Property Type Sections"
  );

  useEffect(() => {
    setProgress({ loading: true });
    types.getData();
    makes.getData();
    typeSections.getData();
    states.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setProgress({ loading: true, errorCode: 0 });
      await ApsServices.http.nfirsHazmatMobileProperty
        .get(props.id)
        .then((data) => {
          const mdl = {
            ...model.new,
            ...data,
            reportId: Number(props.id),
            year: `${data.year || ""}`.trim(),
          };

          initModels(mdl);
          setValuesFromModel(mdl);
          setProgress({ loading: false });
          props.onChange(mdl);

          trigger(); //force validate so users can see error labels
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Mobile Property", err);
          setProgress({ loading: false });
        });
    };

    if (
      !progress.errorCode &&
      states.status === NfirsFetchStatus.Complete &&
      typeSections.status === NfirsFetchStatus.Complete &&
      types.status === NfirsFetchStatus.Complete &&
      makes.status === NfirsFetchStatus.Complete
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    progress.errorCode,
    types.status,
    makes.status,
    states.status,
    typeSections.status,
  ]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    formRef,
    trigger,
    triggerSubmitForm,
  } = FgUseForm({
    nfirsMobilePropertyTypeEntryId: {
      displayName: "Type",
      validation: {
        required: false,
      },
    },
    nfirsMobilePropertyMakeEntryId: {
      displayName: "Make",
      validation: {
        required: false,
      },
    },
    dotNumber: {
      displayName: "DOT Registration Number",
      validation: {
        required: false,
        maxLength: 17,
      },
    },
    model: {
      displayName: "Model",
      validation: {
        required: false,
        maxLength: 25,
      },
    },
    year: {
      displayName: "Year",
      validation: {
        required: false,
        minLength: 4,
        maxLength: 4,
        validate: {
          numberOnly: commonService.validations.numberOnly,
          acceptedYearRange: commonService.validations.acceptedYearRange,
        },
      },
    },
    stateId: {
      displayName: "State",
      validation: {
        required: false,
      },
    },
    licencePlateNumber: {
      displayName: "License Plate Number",
      validation: {
        required: false,
        maxLength: 10,
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        show={!!progress.errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(progress.errorCode)}
        message={commonService.getConcurrencyMessage(progress.errorCode)}
        done={(rtn) => {
          if (progress.errorCode === 409) {
            setProgress({ errorCode: 0 });
          }
        }}
      />
      {progress.loading && (
        <div className="row">
          <div className="col-sm-12 mb-2">
            <CommonSpinner />
          </div>
        </div>
      )}
      <form
        className={`row ${progress.loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="col-sm-12">
          <strong>Mobile Property Involved</strong>

          <div className="mt-3 mb-2">
            <label>Was mobile property involved in this incident?</label>

            <div className="inline-radio-box-group">
              <div>
                <input
                  type="radio"
                  name="isMobilePropertyInvolved"
                  id="isMobilePropertyInvolvedYes"
                  value="1"
                  checked={model.new.isMobilePropertyInvolved || false}
                  onChange={(event) => {
                    onModelChange({
                      ...model.new,
                      isMobilePropertyInvolved:
                        Number(event.target.value) === 1,
                    });
                  }}
                ></input>
                <label htmlFor="isMobilePropertyInvolvedYes">Yes</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="isMobilePropertyInvolved"
                  id="isMobilePropertyInvolvedNo"
                  value="0"
                  checked={!model.new.isMobilePropertyInvolved || false}
                  onChange={(event) => {
                    onModelChange({
                      ...model.new,
                      isMobilePropertyInvolved:
                        Number(event.target.value) === 1,
                    });
                  }}
                ></input>
                <label htmlFor="isMobilePropertyInvolvedNo">No</label>
              </div>
            </div>
          </div>
        </div>

        {model.new.isMobilePropertyInvolved && (
          <>
            <div className="col-sm-12 col-lg-6">
              <div className="mt-3 mb-3">
                <label>Type</label>
                <AdvancedDropdown
                  domId="nfirsMobilePropertyTypeEntryId"
                  value={model.new.nfirsMobilePropertyTypeEntryId}
                  favoritesHandler={{
                    add: ApsServices.http.nfirsFavorite
                      .addFavoriteMobilePropertyType,
                    remove:
                      ApsServices.http.nfirsFavorite
                        .removeFavoriteMobilePropertyType,
                    afterUpdate: (list: any[]) => types.setData(list),
                  }}
                  options={(types.data || [])
                    .sort(commonService.sortByStringProperty("description"))
                    .map((o) => {
                      return {
                        label: `${o.description} (${o.externalId})`,
                        value: o.id,
                        originalObject: o,
                        isFavorite: o.isFavorite,
                        groupId: o.mobilePropertyTypeSection,
                        subGroupId: 0,
                      };
                    })}
                  groups={(typeSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      groupLabel: o.label,
                    };
                  })}
                  subGroups={(typeSections.data || []).map((o) => {
                    return {
                      groupId: o.value,
                      subGroupId: 0,
                    };
                  })}
                  onChange={(data) => {
                    onModelChange({
                      ...model.new,
                      nfirsMobilePropertyTypeEntryId:
                        Number(data) || (null as any),
                    });
                  }}
                ></AdvancedDropdown>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <label>Make</label>
                  <AdvancedDropdown
                    domId="nfirsMobilePropertyMakeEntryId"
                    value={model.new.nfirsMobilePropertyMakeEntryId}
                    favoritesHandler={{
                      add: ApsServices.http.nfirsFavorite
                        .addFavoriteMobilePropertyMake,
                      remove:
                        ApsServices.http.nfirsFavorite
                          .removeFavoriteMobilePropertyMake,
                      afterUpdate: (list: any[]) => makes.setData(list),
                    }}
                    options={(makes.data || [])
                      .sort(commonService.sortByStringProperty("externalId"))
                      .map((o) => {
                        return {
                          label: `${o.externalId}. ${o.description}`,
                          value: o.id,
                          originalObject: o,
                          isFavorite: o.isFavorite,
                          groupId: 0,
                          subGroupId: 0,
                        };
                      })}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        nfirsMobilePropertyMakeEntryId:
                          Number(data) || (null as any),
                      });
                    }}
                  ></AdvancedDropdown>
                </div>
                <div className="col-sm-6">
                  <FgInput
                    id="dotNumber"
                    label="DOT Registration Number"
                    placeHolder="DOT Registration Number"
                    registeredField={registry.dotNumber}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        dotNumber: data,
                      });
                    }}
                  ></FgInput>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="row mt-3">
                <div className="col-sm-6">
                  <FgInput
                    id="model"
                    label="Model"
                    placeHolder="Model"
                    registeredField={registry.model}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        model: data,
                      });
                    }}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgInput
                    id="year"
                    label="Year"
                    placeHolder="Year"
                    registeredField={registry.year}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        year: data,
                      });
                    }}
                    //maxlength={4}
                  ></FgInput>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <FgInput
                    id="licencePlateNumber"
                    label="License Plate Number"
                    placeHolder="License Plate Number"
                    registeredField={registry.licencePlateNumber}
                    formState={formState}
                    onChange={(data) => {
                      onModelChange({
                        ...model.new,
                        licencePlateNumber: data,
                      });
                    }}
                  ></FgInput>
                </div>
                <div className="col-sm-6">
                  <FgSelect
                    id="stateId"
                    label="State"
                    selectMessage="Select a State"
                    registeredField={registry.stateId}
                    formState={formState}
                    options={(states.data || []).map((o) => {
                      return {
                        label: `${o.abbreviation} - ${o.description}`,
                        value: o.id,
                      };
                    })}
                    onChange={(data) =>
                      onModelChange({
                        ...model.new,
                        stateId: Number(data) || (null as any),
                      })
                    }
                  ></FgSelect>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6"></div>
                <div className="col-sm-6"></div>
              </div>

              {/* <div className="mt-3">
                <FgSelect
                  id="nfirsPowerSourceEntryId"
                  label="Equipment Power Source"
                  selectMessage="Select equipment power source"
                  registeredField={registry.nfirsPowerSourceEntryId}
                  formState={formState}
                  options={powerSources.map((o) => {
                    return {
                      label: `${o.value}. ${o.label}`,
                      value: o.value,
                    };
                  })}
                  onChange={(data) =>
                    onModelChange({
                      ...model.new,
                      nfirsPowerSourceEntryId: Number(data) || (null as any),
                    })
                  }
                ></FgSelect>
              </div> */}
            </div>
          </>
        )}

        <button type="submit" hidden></button>
      </form>
    </>
  );
}

export default NFIRSHazmatMobileProperty;
