import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import Modal from 'react-modal';
import { AuthenticationService } from "../../services/AuthenticationService";

type Props = {};
type State = {
    modalIsOpen: boolean,
};
Modal.setAppElement('#root');

export class TimeoutPopUp extends Component<Props, State> {
    state: State = {
        modalIsOpen: false,
    };
    customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    onIdle = async () => {
        await AuthenticationService.logout();
        this.setState({
            modalIsOpen: true
        });
    }
    redirectToLogin = () => {
        window.location.reload();
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.modalIsOpen} style={this.customStyles}>
                    <h2>Session timeout</h2>
                    <p>We've logged you out for your protection. To get back in, please log in again</p>
                    <button className="btn btn-primary d-flex mx-auto" onClick={this.redirectToLogin}>Login</button>
                </Modal>
                <IdleTimer
                    timeout={3600000}
                    onIdle={this.onIdle}
                />
            </div>
        );
    }
}
