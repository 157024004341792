import moment, { Moment } from "moment";
import { Departments } from "../data/Department";
import { Shift, ShiftWindow } from "../data/Shift";
import { ShiftColor } from "../data/ShiftColor";

type District = 'C' | 'D'

class ShiftService {
    private readonly departmentToDistrictLookup = new Map<Departments, District>([
        [Departments.Glendale, 'C'],
        [Departments.MontereyPark, 'C'],
        [Departments.Monrovia, 'C'],
        [Departments.WestCovina, 'D']
    ]);

    /*
    The beginning of a cycle is specific to each department.
    Here we supply a past date that represents the first A shift (cycle start date).
    Using the difference between today's date and this CycleStartDate, we can calculate
    where today's date is located in the cycle.
    */
    private readonly districtToCycleStartDayLookup = new Map<District, Moment>([
        ['C', moment.utc("2021-01-08")], 
        ['D', moment.utc("2021-09-21")]
    ])

    /*
    Shifts are 48 hours long (2 days).
    Every 6 days is an AA BB CC pattern.
    These values are all static -- the date "hole" will need to be filled in dynamically
    */
    private readonly shifts: Omit<Shift, 'date'>[] = [
        { name: 'A', color: ShiftColor.Red,  colorStr: 'red', hexValue: '#db2a00' },
        { name: 'B', color: ShiftColor.Blue, colorStr: 'blue', hexValue: '#58b5e2' },
        { name: 'C', color: ShiftColor.Green, colorStr: 'green', hexValue: '#2DB539' }
    ]

    /* 
    Return a % b, adjusting correctly if a is negative.
    Since we're hardcoding the cycle start dates, it's possible that a user could
    select a date that occurs BEFORE the cycle start date. We want to make sure the 
    result of the modulus is positive since Javascript doesn't support negative indexing 
    (at least not in the way you'd expect!)
    */
    private positiveModulus = (a: number, b: number) => {
        return a - (Math.floor(a / b) * b);
    }

    public getShift = (day: Moment, department: Departments): Shift => {
        const district = this.departmentToDistrictLookup.get(department)!
        const cycleStartDay = this.districtToCycleStartDayLookup.get(district)!;
        const daysDifference = day.startOf('day').diff(cycleStartDay, 'days');
        const index = Math.floor(this.positiveModulus(daysDifference, this.shifts.length*2) / 2);
        const partialShift = this.shifts[index];
        const completeShift: Shift = { ...partialShift, date: day.toDate() }
        return completeShift;
    }

    /*
    Computes a rolling 3-day window of shifts that includes yesterday, today, and tomorrow.
    */
    public getShiftWindow = (today: Moment, department: Departments): ShiftWindow => {
        const yesterday = moment(today).add(-1, 'days');
        const tomorrow = moment(today).add(1, 'days');
        return {
            yesterday: this.getShift(yesterday, department),
            today: this.getShift(today, department),
            tomorrow: this.getShift(tomorrow, department)
        }
    }
}

const shiftService = new ShiftService();
export { shiftService as ShiftService };
