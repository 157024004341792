import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSApparatusService {
  get = async (id: any, reportId: any) => {
    const url = `/api/NfirsApparatus/${id}/${reportId}`;
    return fetcher.get<ApsModels.INfirsApparatusOutputDto>(url);
  };

  delete = async (id: any, reportId: any, concurrencyToken: any) => {
    const url = `/api/NfirsApparatus/${id}/${reportId}/${concurrencyToken}`;
    return fetcher.delete<any>(url);
  };

  getApparatusIds = async () => {
    const url = `/api/NfirsApparatus/GetApparatusIds`;
    return fetcher.get<any[]>(url);
  };

  list = async (page: any, pageSize: any, reportId: any) => {
    const url = `/api/NfirsApparatus/List/${page}/${pageSize}/${reportId}`;
    return fetcher.get<ApsModels.INfirsApparatusGridOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsApparatusInputDto) => {
    const url = `/api/NfirsApparatus`;
    return fetcher.post<ApsModels.INfirsApparatusOutputDto>(url, data);
  };
}

const nfirsApparatusService = new NFIRSApparatusService();
export default nfirsApparatusService;
