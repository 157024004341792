import ApsModels from "../../../models";
import fetcher from "../Fetcher";

class NFIRSWildlandPeopleInvolvedService {
  get = async (id: any) => {
    const url = `/api/NfirsWildlandPeopleInvolved/${id}`;
    return fetcher.get<ApsModels.INfirsWildlandPeopleInvolvedOutputDto>(url);
  };

  update = async (data: ApsModels.INfirsWildlandPeopleInvolvedInputDto) => {
    const url = `/api/NfirsWildlandPeopleInvolved`;
    return fetcher.post<ApsModels.INfirsWildlandPeopleInvolvedOutputDto>(
      url,
      data
    );
  };
}

const nfirsWildlandPeopleInvolved = new NFIRSWildlandPeopleInvolvedService();
export default nfirsWildlandPeopleInvolved;
