import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ConfirmDialog from "../../aps2/components/Common/ConfirmDialog";
import ApsServices from "../../aps2/services";
import commonService from "../../aps2/services/CommonService";
import { useAddComponent } from "../../aps2/stores/DesignerStore";
import toastStore from "../../aps2/stores/ToastStore";
import { FormDto, QuestionGroupDto } from "../../data/DailyOperational";
import { OptionGroupDto } from "../../data/OptionGroup";
import {
  BuiltInDOCComponents,
  IBaseDOCComponent,
} from "./ComponentGalleryDefinitions";
import ComponentGalleryDialog from "./ComponentGalleryDialog";
import {
  DOCLayoutColumn,
  DOCLayoutRow,
  IDOCComponent,
  IDOCLayoutColumn,
  IDOCLayoutModel,
  IDOCLayoutRow,
  layoutHelper,
} from "./DailyOperationalDesigner";

function CoreLayout({
  model,
  onChange,
  onDelete,
  root,
  designMode,
  formDto,
  factoryForm,
  selectedDate,
  optionGroups,
  departmentId,
  saveResetButtons,
}: {
  model: IDOCLayoutModel;
  onChange: (data: IDOCLayoutModel) => void;
  onDelete: (data: IDOCLayoutModel) => void;
  root?: boolean;
  designMode?: boolean;
  formDto?: FormDto;
  factoryForm?: (
    value: QuestionGroupDto | undefined,
    order: number,
    componentName?: string
  ) => void;
  selectedDate?: Date;
  optionGroups?: OptionGroupDto[];
  departmentId: number;
  saveResetButtons?: {
    saveLabel: string;
    resetLabel: string;
    disableSave?: boolean;
    disableReset?: boolean;
    onSave: () => void;
    onReset: () => void;
  };
}) {
  const setModel = (func: (data: IDOCLayoutModel) => any) => {
    onChange(func(model));
  };

  const [openGallery, setOpenGallery] = useState<{
    show: boolean;
    columnId?: string;
    docComponent?: IDOCComponent;
  }>({ show: false });

  const getQuestionGroup = (comp: IBaseDOCComponent) => {
    if (!designMode && formDto && formDto.questionGroups) {
      return {
        ...comp.questionGroup,
        ...(formDto.questionGroups
          .map((grp) => {
            return {
              ...grp,
              questions: grp.questions.map((q) => {
                return {
                  ...q,
                  answers: [...q.answers],
                };
              }),
            };
          })
          .find((x) => x.order === comp.questionGroup?.order) || {}),
      };
    }
    //create temp answer for strike teams for preview
    if (comp.questionGroup && comp.id === "StrikeTeams") {
      return {
        ...comp.questionGroup,
        questions: [
          ...comp.questionGroup.questions.map((q) => {
            return {
              ...q,
              answers: [
                {
                  answerId: -1,
                  answerType: 4,
                  order: 0,
                  strikeTeamReferences: [],
                },
              ],
            };
          }),
        ],
      };
    }
    return comp.questionGroup;
  };

  const getColSpan = (row: IDOCLayoutRow, col: IDOCLayoutColumn) => {
    const total = row.columns
      .map((x) => Number(x.span) || 1)
      .reduce((partialSum, a) => partialSum + a, 0);
    return Math.floor((12 / total) * (Number(col.span) || 1));
  };

  const [compToDelete, setCompToDelete] = useState<{
    row: IDOCLayoutRow;
    component: IDOCComponent;
  }>();
  const deleteField = (row: IDOCLayoutRow, comp: IDOCComponent) => {
    setModel((data) => {
      return {
        ...data,
        rows: [
          ...data.rows.map((r) => {
            if (r.id === row.id) {
              return {
                ...r,
                columns: [
                  ...r.columns.map((col) => {
                    return {
                      ...col,
                      components: [
                        ...col.components.filter((c) => c.id !== comp.id),
                      ],
                    };
                  }),
                ],
              };
            }
            return r;
          }),
        ],
      };
    });
  };

  const deleteQuestionGroup = async (comp: IDOCComponent) => {
    const order = (comp.data as IBaseDOCComponent).questionGroup?.order;
    if (order !== undefined && order > -1) {
      await ApsServices.http.form
        .getForm({
          date: new Date(),
          departmentId: departmentId,
        })
        .then((form) => {
          const grp = form.questionGroups.find((grp) => grp.order === order);
          if (grp) {
            ApsServices.http.questionGroup
              .delete(grp.questionGroupId)
              .then((data) => {
                toastStore.showToast("Question Group Deleted.", "success");
              });
          }
        });
    }
  };

  const onComponentChanged = (
    newData: IBaseDOCComponent,
    relaunch?: boolean,
    columnnId?: string
  ) => {
    columnnId = columnnId || openGallery.columnId;
    const newComp = {
      id: commonService.getUniqueId(),
      data: newData,
    };

    //console.log(JSON.stringify(newComp));

    setModel((data) => {
      return {
        ...data,
        rows: [
          ...data.rows.map((r) => {
            return {
              ...r,
              columns: r.columns.map((c) => {
                if (c.id === columnnId) {
                  return {
                    ...c,
                    components: (c.components || []).find(
                      (cx) => cx.id === openGallery.docComponent?.id
                    )
                      ? (c.components || []).map((cx) => {
                          if (cx.id === openGallery.docComponent?.id) {
                            return {
                              ...cx,
                              data: newData,
                            };
                          }
                          return cx;
                        })
                      : [...(c.components || []), newComp],
                  };
                }
                return c;
              }),
            };
          }),
        ],
      };
    });
    const copy = { ...openGallery };
    setOpenGallery({ show: false, columnId: undefined });
    if (relaunch) {
      //Maybe better way to this in the future
      setTimeout(() => {
        setOpenGallery({
          ...copy,
          docComponent: newComp as any,
        });
      }, 200);
    }
  };

  const addComponent = useAddComponent();
  useEffect(() => {
    if (addComponent?.component && addComponent?.columnId) {
      if (
        model.rows.find((r) =>
          r.columns.find((c) => c.id === addComponent.columnId)
        )
      ) {
        const data = JSON.parse(JSON.stringify(addComponent.component)); //cleans mobx stuff
        onComponentChanged(data, false, addComponent?.columnId);
      }
    }
    //
  }, [addComponent?.component, addComponent?.columnId]);

  return (
    <>
      {compToDelete && (
        <ConfirmDialog
          show={true}
          title="Delete Field"
          message="Do you really want to delete this field? All data linked to this field will be lost."
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              //console.log(compToDelete.component);
              deleteQuestionGroup(compToDelete.component);
              deleteField(compToDelete.row, compToDelete.component);
            }
            setCompToDelete(undefined);
          }}
        ></ConfirmDialog>
      )}
      {openGallery.show && (
        <div style={{ position: "absolute" }}>
          <ComponentGalleryDialog
            data={openGallery.docComponent?.data}
            departmentId={departmentId}
            onClose={(e) => {
              setOpenGallery({ show: false, columnId: undefined });
            }}
            onChange={(newData, relaunch) => {
              onComponentChanged(newData, relaunch);
            }}
          ></ComponentGalleryDialog>
        </div>
      )}
      <div className="core-layout d-print-none">
        {model.rows.map((row) => (
          <div key={row.id} className="flex">
            <div className="flex-1">
              <div className={`row ${designMode ? "design-mode" : ""}`}>
                {row.columns.map((col) => (
                  <div
                    key={col.id}
                    id={col.id}
                    data-span={col.span || 1}
                    className={`col-xl-${getColSpan(row, col)}`}
                  >
                    <section className={designMode ? "design-mode" : ""}>
                      {designMode && col.components.length === 0 && (
                        <>
                          <div className="p-2 mb-2">
                            <div className="py-1">
                              <i className="fa fa-puzzle-piece mr-1"></i> Fields
                              Here
                            </div>
                          </div>
                        </>
                      )}
                      {!designMode && !col.components.length && (
                        <div className="component-wrapper">
                          <div
                            className="p-4 bg-white text-center txt-grey"
                            style={{
                              marginBottom: "20px",
                              borderRadius: "5px",
                            }}
                          >
                            ...
                          </div>
                        </div>
                      )}
                      {col.components.map((comp, i) => {
                        const compObj = BuiltInDOCComponents.find(
                          (b) => b.id === (comp.data as IBaseDOCComponent).id
                        )?.component;

                        return (
                          <React.Fragment key={comp.id}>
                            {comp.type !== "core-layout" && (
                              <div
                                className={`component-wrapper ${
                                  designMode ? "pointer design-mode" : ""
                                }`}
                                onClick={(e) => {
                                  if (designMode && compObj) {
                                    setOpenGallery({
                                      show: true,
                                      columnId: col.id,
                                      docComponent: comp,
                                    });
                                  }
                                }}
                              >
                                {designMode && (
                                  <div className="component-controls">
                                    {compObj && (
                                      <Button
                                        variant="default"
                                        size="sm"
                                        title="Edit Field"
                                      >
                                        <i className="fa fa-edit text-primary"></i>
                                      </Button>
                                    )}
                                    <Button
                                      variant="default"
                                      size="sm"
                                      title="Delete Field"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setCompToDelete({
                                          row: row,
                                          component: comp,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-trash text-danger"></i>
                                    </Button>
                                  </div>
                                )}
                                <React.Fragment>
                                  {compObj ? (
                                    React.createElement(compObj, {
                                      departmentId: departmentId,
                                      questionGroup: getQuestionGroup(
                                        comp.data as IBaseDOCComponent
                                      ),
                                      setData: (v: any, o: any) => {
                                        if (!designMode && factoryForm) {
                                          factoryForm(v, o, comp.data?.id);
                                        }
                                      },
                                      date: designMode
                                        ? new Date()
                                        : selectedDate,
                                      moduleOrder: designMode
                                        ? -1
                                        : (comp.data as IBaseDOCComponent)
                                            .questionGroup?.order,

                                      ...((comp.data as IBaseDOCComponent)
                                        .moreProps?.values || {}),
                                      optionGroups: optionGroups || [], //NOTE: Might need adjustments on designMode
                                    })
                                  ) : (
                                    <div className="component-wrapper">
                                      <div className="grid-item card box-card flex-grow-1">
                                        <div className="card-body text-center p-3">
                                          Unkonwn Field
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>

                                {/* {JSON.stringify(
                                (comp.data as IBaseDOCComponent).questionGroup
                              )} */}
                              </div>
                            )}
                            {comp.type === "core-layout" && (
                              <CoreLayout
                                departmentId={departmentId}
                                formDto={formDto}
                                factoryForm={factoryForm}
                                optionGroups={optionGroups}
                                selectedDate={selectedDate}
                                designMode={designMode}
                                model={comp.data}
                                saveResetButtons={saveResetButtons}
                                onChange={(newData) => {
                                  if (!designMode) return;
                                  setModel((data) => {
                                    return {
                                      ...data,
                                      rows: [
                                        ...data.rows.map((r) => {
                                          if (r.id === row.id) {
                                            return {
                                              ...r,
                                              columns: [
                                                ...r.columns.map((col) => {
                                                  return {
                                                    ...col,
                                                    components: [
                                                      ...col.components.map(
                                                        (c) => {
                                                          if (
                                                            c.id === comp.id
                                                          ) {
                                                            return {
                                                              ...c,
                                                              data: newData,
                                                            };
                                                          }
                                                          return c;
                                                        }
                                                      ),
                                                    ],
                                                  };
                                                }),
                                              ],
                                            };
                                          }
                                          return r;
                                        }),
                                      ],
                                    };
                                  });
                                }}
                                onDelete={(newData) => {
                                  if (!designMode) return;
                                  setModel((data) => {
                                    return {
                                      ...data,
                                      rows: [
                                        ...data.rows.map((r) => {
                                          if (r.id === row.id) {
                                            return {
                                              ...r,
                                              columns: [
                                                ...r.columns.map((col) => {
                                                  return {
                                                    ...col,
                                                    components: [
                                                      ...col.components.filter(
                                                        (c) => c.id !== comp.id
                                                      ),
                                                    ],
                                                  };
                                                }),
                                              ],
                                            };
                                          }
                                          return r;
                                        }),
                                      ],
                                    };
                                  });
                                }}
                              ></CoreLayout>
                            )}
                          </React.Fragment>
                        );
                      })}

                      {designMode && (
                        <section>
                          <Button
                            title="Delete Column"
                            className="mr-2 mb-1"
                            variant="danger"
                            size="sm"
                            disabled={row.columns.length === 1}
                            onClick={(e) => {
                              if (
                                layoutHelper.checkIfRowHasComponents(
                                  model,
                                  undefined,
                                  col
                                )
                              ) {
                                toastStore.showToast(
                                  "Unable to delete this column. Please delete all fields in this column first.",
                                  "warning"
                                );
                                return;
                              }

                              setModel((data) => {
                                return {
                                  ...data,
                                  rows: [
                                    ...data.rows.map((r) => {
                                      if (r.id === row.id) {
                                        return {
                                          ...r,
                                          columns: [
                                            ...r.columns.filter(
                                              (c) => c.id !== col.id
                                            ),
                                          ],
                                        };
                                      }
                                      return r;
                                    }),
                                  ],
                                };
                              });
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                          <Button
                            className="mr-2 mb-1"
                            variant="success"
                            size="sm"
                            onClick={(e) => {
                              setOpenGallery({ show: true, columnId: col.id });
                            }}
                          >
                            <i className="fa fa-plus"></i> Field
                          </Button>
                          {true && (
                            <Button
                              className="mr-2 mb-1"
                              variant="success"
                              size="sm"
                              onClick={(e) => {
                                setModel((data) => {
                                  return {
                                    ...data,
                                    rows: [
                                      ...data.rows.map((r) => {
                                        if (r.id === row.id) {
                                          return {
                                            ...r,
                                            columns: [
                                              ...r.columns.map((c) => {
                                                if (c.id !== col.id) {
                                                  return c;
                                                }
                                                return {
                                                  ...c,
                                                  components: [
                                                    ...c.components,
                                                    {
                                                      id: commonService.getUniqueId(),
                                                      type: "core-layout",
                                                      data: {
                                                        rows: [
                                                          new DOCLayoutRow([
                                                            new DOCLayoutColumn(),
                                                          ]),
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                };
                                              }),
                                            ],
                                          };
                                        }
                                        return r;
                                      }),
                                    ],
                                  };
                                });
                              }}
                            >
                              <i className="fa fa-plus"></i> Grid
                            </Button>
                          )}
                          {row.columns.length > 1 && (
                            <select
                              title="Column Size"
                              className="form-control form-control-sm px-1 mb-1"
                              style={{
                                width: "45px",
                                border: "none",
                                display: "inline-block",
                                backgroundColor: "#ddd",
                              }}
                              value={col.span || 1}
                              onChange={(e) => {
                                setModel((data) => {
                                  return {
                                    ...data,
                                    rows: [
                                      ...data.rows.map((r) => {
                                        if (r.id === row.id) {
                                          return {
                                            ...r,
                                            columns: r.columns.map((c) => {
                                              if (c.id === col.id) {
                                                return {
                                                  ...c,
                                                  span:
                                                    Number(e.target.value) || 1,
                                                };
                                              }
                                              return c;
                                            }),
                                          };
                                        }
                                        return r;
                                      }),
                                    ],
                                  };
                                });
                              }}
                            >
                              <option value={1}>x1</option>
                              <option value={2}>x2</option>
                              <option value={3}>x3</option>
                            </select>
                          )}
                        </section>
                      )}
                    </section>
                  </div>
                ))}
              </div>
            </div>
            {designMode && (
              <div className="pl-3">
                <Button
                  title="Add Column"
                  size="sm"
                  variant="primary"
                  disabled={row.columns.length >= 4}
                  onClick={(e) => {
                    setModel((data) => {
                      return {
                        ...data,
                        rows: [
                          ...data.rows.map((r) => {
                            if (r.id === row.id) {
                              return {
                                ...r,
                                columns: [...r.columns, new DOCLayoutColumn()],
                              };
                            }
                            return r;
                          }),
                        ],
                      };
                    });
                  }}
                >
                  <i className="fa fa-plus"></i>
                </Button>
                <br />
                <Button
                  title="Delete Row"
                  size="sm"
                  className="mt-2"
                  variant="danger"
                  disabled={model.rows.length === 1}
                  onClick={(e) => {
                    if (layoutHelper.checkIfRowHasComponents(model, row)) {
                      toastStore.showToast(
                        "Unable to delete this row. Please delete all fields in this row first.",
                        "warning"
                      );
                      return;
                    }
                    setModel((data) => {
                      return {
                        ...data,
                        rows: [...data.rows.filter((r) => r.id !== row.id)],
                      };
                    });
                  }}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </div>
            )}
          </div>
        ))}

        {designMode && (
          <div className="flex">
            <div className="flex-1">
              <Button
                className="mr-2"
                size={root ? undefined : "sm"}
                variant="primary"
                onClick={(e) => {
                  setModel((data) => {
                    return {
                      ...data,
                      rows: [
                        ...data.rows,
                        new DOCLayoutRow([new DOCLayoutColumn()]),
                      ],
                    };
                  });
                }}
              >
                {/* <i className="fa fa-plus"></i>  */}
                Add Row
              </Button>
              {!root && (
                <Button
                  title="Delete Grid"
                  size="sm"
                  variant="danger"
                  onClick={(e) => {
                    if (layoutHelper.checkIfRowHasComponents(model)) {
                      toastStore.showToast(
                        "Unable to delete this grid. Please delete all fields in this grid first.",
                        "warning"
                      );
                      return;
                    }
                    onDelete(model);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              )}
            </div>
            {/* NEED TO CONFIRM IF THIS IS REALLY NECESSARY SINCE WE HAVE */}
            {/* {root && saveResetButtons && (
              <div className="pr-10">
                <Button
                  variant="danger"
                  type="button"
                  disabled={saveResetButtons.disableReset}
                  className="mr-2"
                >
                  {saveResetButtons.resetLabel}
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  disabled={saveResetButtons.disableSave}
                  onClick={(e) => {}}
                >
                  {saveResetButtons.saveLabel}
                </Button>
              </div>
            )} */}
          </div>
        )}
      </div>
    </>
  );
}

export default CoreLayout;
